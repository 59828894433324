import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { isShowGraph } from '../helpers/util';
import { GenericPieChartData } from '../objects/chart';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { VehiclePurchasingPowerTooltipsComponent } from '../shared-components/general/vehicle-purchasing-power-tooltips/vehicle-purchasing-power-tooltips.component';
import { GraphResolverBase } from './GraphResolverBase';


export class VehiclePurchasingPowerResolver extends GraphResolverBase {
    public readonly THIS_CHART_COLOR_LIST = ['#B9CEF8', '#5384F4', '#013AC2', '#002D96', '#001A56'];
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService,
        graphDataService: GraphDataService, 
        popoverController: PopoverController, 
        _viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
        authenicationService: AuthenticationService
        ) { 
            graphDataService.baseGraphData.addDependency(this.dataDependency);
            const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
            const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;

            const pieData$ = new BehaviorSubject<GenericPieChartData[]>([{
                data:  0,
                color: PIE_CHART_COLOR_LIST[0],
                label: null,
            }, {
                data:  0,
                color: PIE_CHART_COLOR_LIST[1],
                label: null
            }, {
                data:  0,
                color: PIE_CHART_COLOR_LIST[2],
                label: null
            }]);

            subscription.add(graphDataService.tierPowerData$.subscribe((tierPowerData) => {
                if (!tierPowerData) { return; }
                const defaultKeys = ['economy', 'premium', 'luxury'];
                const chartLabel = defaultKeys.map(bn => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[bn]);
                const chartData = defaultKeys.map((key, idx) => ({
                        data: Object.keys(tierPowerData).length === 0 ? 0 : tierPowerData[key],
                        color: PIE_CHART_COLOR_LIST[idx],
                        label: chartLabel[idx]
                    }));
                pieData$.next(chartData);
            }));

            const componentRef = viewContainerRef.createComponent(componentFactory);
            const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
            comInstance.title = configDataService.DISPLAY_LANGUAGE.VEHICLE_PURCHASING_POWER;
            comInstance.isLock = this.isLock;
            comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
            const textValueProcess = 'percentage';
            comInstance.customToolTipFuction = function(tooltipModel) {
                showPieChartCustomTooltips(tooltipModel, this , textValueProcess);
            };
            comInstance.isShowLegendOnPercent = true;
            comInstance.infoPopover = async (e: any) => {
                const popover = await popoverController.create({
                    component: VehiclePurchasingPowerTooltipsComponent,
                    /*componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.VEHICLE_PURCHASING_POWER,
                        toolTipDetails: ['The implied financial willingness to buy products and services based on visitor vehicle\'s car brand and type.', 
                        'Economy: Designed for low-cost purchase and ownership. (e.g. Toyota Sedan)',
                        'Premium: Designed with high product quality and features, but not as well perceived with status. (e.g. Kia SUV)',
                        'Luxury: Designed with the highest levels of comfort, quality, and status at a higher price. (e.g. Mercedes Coupe)']
                    },*/
                    cssClass: 'customer-segment-details-popover',
                    event: e
                });
                return popover.present();
            };
            comInstance.data$ = pieData$;
            return comInstance;
        }

}
