import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_TOURISTS_COLOR, CHART_LOCAL_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { isScreenSmallerThanXS } from '../helpers/util';

export class CustomSegmentationResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        await configDataService.loadAppConfig();

        const isLocal = (keyName: string) => keyName ? keyName.startsWith('local_') : false;

        const chartLabel = configDataService.SEGREGATION_CLASS.map(segregation => configDataService.DISPLAY_LANGUAGE.CUSTOMER_SEGREGATION_CLASS[segregation]);
        const initialSegregatedData = graphDataService.segregatedEthinicityData$.value;
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: configDataService.SEGREGATION_CLASS.map(segregation => !isLocal(segregation) ? initialSegregatedData[segregation] : null),
            color: CHART_TOURISTS_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.tourist,
            calPercentFrommAllData: true
        }, {
            data: configDataService.SEGREGATION_CLASS.map(segregation => isLocal(segregation) ? initialSegregatedData[segregation] : null),
            color: CHART_LOCAL_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.local,
            calPercentFrommAllData: true
        }]);
        subscription.add(graphDataService.segregatedEthinicityData$.subscribe(segregationData => {
            dualBarData$.next([{
                data: configDataService.SEGREGATION_CLASS.map(segregation => !isLocal(segregation) ? segregationData[segregation] : null),
                color: CHART_TOURISTS_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.tourist,
                calPercentFrommAllData: true
            }, {
                data: configDataService.SEGREGATION_CLASS.map(segregation => isLocal(segregation) ? segregationData[segregation] : null),
                color: CHART_LOCAL_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.local,
                calPercentFrommAllData: true
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.CUSTOMER_ESTIMATE_SEGMENTATION;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.valueTextOffsetX = isScreenSmallerThanXS(window) ? 1.5 : 3;
        comInstance.valueTextOffsetY = 5;
        comInstance.aspectRatio = 1.1;
        comInstance.valueTextAlign = 'left';
        comInstance.isHorizontal = true;
        // comInstance.xAxesRotation = 90;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'Y';
        comInstance.isStacked = 'Y';
        return comInstance;
    }

}
