let genderTooltipTimeout;

export const showGenderCustomTooltips = (tooltipModel, thisChart, imageList) => {

  window.clearTimeout(genderTooltipTimeout);
  
  // Tooltip Element
  let tooltipEl = document.getElementById('gender-chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'gender-chartjs-tooltip';
      tooltipEl.innerHTML = '<table style="margin-left: 10px;"></table><div></div>';
      document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    window.clearTimeout(genderTooltipTimeout);
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
      tooltipEl.classList.add('no-transform');
  }

  // function getBody(bodyItem) {
  //   return bodyItem.lines;
  // }

  const chartDatasets = thisChart._data.datasets;
  const chartLabels = thisChart._data.labels;
  let activeGenderClass;
  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    // var bodyLines = tooltipModel.body.map(getBody);
    const bodyLines = tooltipModel.dataPoints.map((datapoint) => {
      const rawValue = chartDatasets[datapoint.datasetIndex].data[datapoint.index].toLocaleString();
      const label = chartLabels[datapoint.index];
      activeGenderClass = label;
      return `${label}: ${rawValue}`;
    });

    let innerHtml = '<thead>';

    titleLines.forEach(function(title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function(body, i) {
      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      style += '; display: inline-block';
      style += '; width: 15px';
      style += '; height: 15px';
      style += '; margin-right: 5px';
      style += '; vertical-align: middle';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td>' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const imageRoot = tooltipEl.querySelector('div');
  const genderImageSrc_1 = imageList[activeGenderClass][0];
  const genderImageSrc_2 = imageList[activeGenderClass][1];
  const genderImageSrc_3 = imageList[activeGenderClass][2];
  const genderImageSrc_4 = imageList[activeGenderClass][3];
  const genderImageSrc_5 = imageList[activeGenderClass][4];
  const genderImageSrc_6 = imageList[activeGenderClass][5];

  imageRoot.innerHTML = `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${genderImageSrc_1}"></img>` +
  `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${genderImageSrc_2}"></img>` + 
  `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${genderImageSrc_3}"></img>` +
  `<br/>` +
  `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${genderImageSrc_4}"></img>` +
  `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${genderImageSrc_5}"></img>` + 
  `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${genderImageSrc_6}"></img>`;

  // `this` will be the overall tooltip
  const position = thisChart._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX - 120) + 'px';
  tooltipEl.style.top = (position.top + window.pageYOffset + tooltipModel.caretY - 200) + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.backgroundColor = 'rgba(0,0,0, 0.8)';
  tooltipEl.style.color = 'white';
  tooltipEl.style.borderRadius = '5px';

  genderTooltipTimeout = setTimeout(() => {
    tooltipEl.style.opacity = '0';
  }, 2500);

};
