import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData, assertNullUndefined, isShowGraph } from '../helpers/util';
import { DynamicGraphAdditionalInput, DynamicGraphConfig } from '../objects/config';
import { SelectableSankeyDataName } from '../objects/selectableData';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { GraphResolverBase } from './GraphResolverBase';
import { DynamicSankeyWrapperComponent } from '../shared-components/dynamic/dynamic-sankey-wrapper/dynamic-sankey-wrapper.component';
import { SankeyRawLinks } from '../objects/fetchObejct';
import { GenericSankeyChartData, SankeyData } from '../objects/chart';
import { AuthenticationService } from '../services/authentication.service';

export class CustomSankeyChartResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService,
    ) {
        const sankeyChartConfig = (getAdditionalInput(additionalInput, 'dynamicGraphConfig') || {}) as DynamicGraphConfig;
        const areaLevel = (getAdditionalInput(additionalInput, 'level') || 'building') as 'building' | 'floor' | 'zone';
        const useSelectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory') || false) as boolean;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const excludeList = (getAdditionalInput(additionalInput, 'excludeList') || []) as string[];
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        assertNullUndefined(sankeyChartConfig);
        const chartTitleKey = sankeyChartConfig.graphName;
        const chartState = (sankeyChartConfig.graphState || 'ENABLE');
        const chartDataConfig = sankeyChartConfig.graphData;
        const chartOptionsConfig = sankeyChartConfig.graphOptions;

        const sankeyDisplayMode: 'LEGACY' | 'DYNAMIC' = chartOptionsConfig?.sankeyDisplayMode || 'LEGACY';
        const includeAreaList: string[] = chartOptionsConfig?.includeAreaList || [];
        const fixedDepth: number = chartOptionsConfig?.fixedDepth;
        const filterFirstLevel: boolean = chartOptionsConfig?.filterFirstLevel === true;
        const selectedArea: string = chartOptionsConfig?.selectedArea;
        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = Object.keys(chartDataConfig).map(key => {
            const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as SelectableSankeyDataName, graphDataService);
            graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
            return dataConfigResolved.data;
        });

        type DepthData = {
            [name: string]: number;
        } & {
            _total: number;
        };
        type EntraceExitData = { entrance: DepthData; exit: DepthData };

        // const getEntraceExitData = (isEntrance: boolean, data: { entrance: DepthData; exit: DepthData }) => isEntrance ? data.entrance : data.exit;

        const getDepthData = (
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0);
            }
            // if (Object.keys(retData).includes('entrance')) {
            //     retData = getEntraceExitData(true, retData as EntraceExitData);
            // }
            return retData;
        };

        const chartData$ = new BehaviorSubject<GenericSankeyChartData>({ data: { nodes: null, links: null } });
        const sankeyRawData$ = new BehaviorSubject<SankeyRawLinks[]>(null);
        // const chartOption$ = new BehaviorSubject<ChartOptions>({});
        // const isLock$ = new BehaviorSubject<boolean>(chartState === 'LOCK');
        const isLock = chartState === 'LOCK';

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicSankeyWrapperComponent;
        const allDataBehaviourSubject$: BehaviorSubject<unknown>[] = useSelectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, ...chartDataBehaviourSubject$] : chartDataBehaviourSubject$;
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);

        if (isMockData) {
            subscription.add(combineLatest([viewPeriodService.dayList, graphDataService.baseGraphData.selectedInteractable$]).subscribe(([dayList, selectedInteractable]) => {
                const selectedInteractableName = selectedInteractable?.name || configDataService.MAIN_BUILDING;
                const configName = 'AREA_FLOW';
                const flowData = configDataService.MOCK_CONFIG?.[configName]?.[selectedInteractableName] || configDataService.MOCK_CONFIG?.[configName]?.default;
                chartData$.next({ data: flowData });
            }));
        } else {
            subscription.add(combineLatest(allDataBehaviourSubject$).subscribe((combinedResult) => {

                combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, graphDataService.baseGraphData.selectedInteractable$]).subscribe(([selectedDirectory, selectedLevel, selectedInteractable]) => {
                    if (excludedDirectory !== undefined) {
                        if (excludedDirectory === 'BUILDING') {
                            comInstance.isShow = selectedDirectory?.floor !== 'ALL';
                        }
                        else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                            if (selectedDirectory?.floor === 'ALL' && excludedDirectory === 'FLOOR') {
                                comInstance.isShow = true;
                            } else {
                                comInstance.isShow = selectedLevel !== excludedDirectory;
                            }
                        }
                        // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                        else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') < excludedDirectory.length - 1) {
                            const excludedDirectoryList = excludedDirectory.split('_AND_');
                            if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                                comInstance.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                            }
                            else if (selectedDirectory?.floor === 'ALL' && excludedDirectoryList.includes('FLOOR')) {
                                comInstance.isShow = true;
                            } else {
                                comInstance.isShow = !excludedDirectoryList.includes(selectedLevel);
                            }
                        }
                    }
                    else if (includeAreaList.length > 0) {
                        const selectedInteractableName = selectedInteractable?.name;
                        if (includeAreaList.length > 0 && selectedInteractableName) {
                            comInstance.isShow = includeAreaList.includes(selectedInteractableName);
                        } else {
                            comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && includeAreaList.includes(selectedDirectory?.zone);
                        }
                    }
                });
                if (combinedResult.some(val => !val)) { return; }
                const flowData: SankeyData = {
                    nodes: [],
                    links: []
                };
                if (useSelectedDirectory) {
                    const selectDirectory = combinedResult[0] as { building: string; floor: string; zone: string };
                    const selectLevel = combinedResult[1] as 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA';
                    if (!selectDirectory) {
                        comInstance.isShow = false;
                        return;
                    }
                    if (excludedDirectory !== undefined) {
                        comInstance.isShow = selectDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : selectLevel !== excludedDirectory;
                    }
                    const rawData = (selectDirectory.floor === 'ALL' ? combinedResult[2] : selectDirectory?.zone ? combinedResult[4] : combinedResult[3]) as Record<string, unknown>[];
                    const buildingName = selectDirectory.building;
                    const floorName = selectDirectory.floor === 'ALL' ? null : selectDirectory.floor;
                    const zoneName = selectDirectory.zone;
                    if (sankeyDisplayMode === 'LEGACY') {
                        const mainArea = selectDirectory.floor === 'ALL' ? buildingName : zoneName ? zoneName : floorName;
                        const displayNameMainArea = configDataService.DISPLAY_LANGUAGE.SANKEY_NODE_NAME[mainArea] || mainArea;
                        flowData.nodes.push({
                            id: 'total',
                            name: displayNameMainArea,
                            depth: 1,
                            // value: 100
                        });
                        const flattenData = getDepthData(rawData, buildingName, floorName, zoneName);
                        Object.keys(flattenData).forEach(flow_key => {
                            Object.entries(flattenData[flow_key]).forEach(([key, val]) => {
                                const displayNameKey = configDataService.DISPLAY_LANGUAGE.SANKEY_NODE_NAME[key] || key;
                                if (key !== '_total') {
                                    flowData.nodes.push({
                                        id: `${flow_key}-${key}`,
                                        name: displayNameKey,
                                        depth: flow_key === 'entrance' ? 0 : 2,
                                        value: Math.floor(((val as number) / flattenData[flow_key]._total) * 100),
                                    });
                                    flowData.links.push({
                                        name: flow_key === 'entrance' ? `${displayNameKey} to ${displayNameMainArea}` : `${displayNameMainArea} to ${displayNameKey}`,
                                        source: flow_key === 'entrance' ? `${flow_key}-${key}` : 'total',
                                        target: flow_key === 'entrance' ? 'total' : `${flow_key}-${key}`,
                                        value: Math.floor(((val as number) / flattenData[flow_key]._total) * 100),
                                    });
                                }
                            });
                        });
                    }
                }
                else {
                    // CASE: Legacy Sankey Mode
                    if (sankeyDisplayMode === 'LEGACY') {
                        const rawDatas = combinedResult as Record<string, unknown>[];
                        const mainArea = areaLevel === 'building' ? chartDataConfig[0].args[0] : areaLevel === 'floor' ? chartDataConfig[0].args[1] : chartDataConfig[0].args[2];
                        const displayNameMainArea = configDataService.DISPLAY_LANGUAGE.SANKEY_NODE_NAME[mainArea] || mainArea;
                        flowData.nodes.push({
                            id: 'total',
                            name: displayNameMainArea,
                            depth: 1,
                            // value: 100
                        });
                        const flattenData = getDepthData(rawDatas[0], chartDataConfig[0].args[0], chartDataConfig[0].args[1], chartDataConfig[0].args[2]) as EntraceExitData;
                        Object.keys(flattenData).forEach(flow_key => {
                            Object.entries(flattenData[flow_key]).forEach(([key, val]) => {
                                const displayNameKey = configDataService.DISPLAY_LANGUAGE.SANKEY_NODE_NAME[key] || key;
                                if (key !== '_total') {
                                    flowData.nodes.push({
                                        id: `${flow_key}-${key}`,
                                        name: displayNameKey,
                                        depth: flow_key === 'entrance' ? 0 : 2,
                                        value: Math.floor(((val as number) / flattenData[flow_key]._total) * 100),
                                    });
                                    flowData.links.push({
                                        name: flow_key === 'entrance' ? `${displayNameKey} to ${displayNameMainArea}` : `${displayNameMainArea} to ${displayNameKey}`,
                                        source: flow_key === 'entrance' ? `${flow_key}-${key}` : 'total',
                                        target: flow_key === 'entrance' ? 'total' : `${flow_key}-${key}`,
                                        value: Math.floor(((val as number) / flattenData[flow_key]._total) * 100),
                                    });
                                }
                            });
                        });
                        // CASE: Dynamic Sankey Mode
                    } else {
                        // const flattenData = getDepthData(rawDatas[0], chartDataConfig[0].args[0], chartDataConfig[0].args[1], chartDataConfig[0].args[2]) as SankeyRawLinks[];
                        // sample data from seacon
                        const rawData = fixedDepth ? (combinedResult[0] as SankeyRawLinks[]).filter(link => link.previous_node.depth < fixedDepth).sort((a, b) => b.current_node.depth - a.current_node.depth)
                            : (combinedResult[0] as SankeyRawLinks[]).sort((a, b) => b.current_node.depth - a.current_node.depth);
                        const originalData = fixedDepth ? (combinedResult[0] as SankeyRawLinks[]).filter(link => link.previous_node.depth < fixedDepth).sort((a, b) => b.current_node.depth - a.current_node.depth)
                            : rawData;
                        let flattenData = originalData;
                        if (filterFirstLevel) {
                            flattenData = flattenData.filter(sankeyData => sankeyData.previous_node.depth > 0);
                        }
                        if (selectedArea) {
                            // Create a Set to hold the previous nodes
                            const filteredNodesSet: Set<SankeyRawLinks> = new Set();
                            for (const item of flattenData) {
                                // Check if the previous node has the name "munx2"
                                const depth = filterFirstLevel ? 1 : 0;
                                if (item.previous_node.depth === depth && item.previous_node.node === selectedArea) {
                                    filteredNodesSet.add(item);
                                }
                            }
                            flattenData = Array.from(filteredNodesSet);
                        }
                        flattenData.filter(sankeyData => !excludeList.includes(sankeyData.current_node.node)).filter(sankeyData => !excludeList.includes(sankeyData.previous_node.node)).forEach(sankeyRawLinks => {
                            const prevNodeDisplayName = configDataService.DISPLAY_LANGUAGE.SANKEY_NODE_NAME[sankeyRawLinks.previous_node?.node || sankeyRawLinks.previous_node?.area];
                            const currentNodeDisplayName = configDataService.DISPLAY_LANGUAGE.SANKEY_NODE_NAME[sankeyRawLinks.current_node?.node || sankeyRawLinks.current_node?.area];
                            if (flowData.nodes.find(node => node.id === sankeyRawLinks.current_node.id) === undefined) {
                                flowData.nodes.push({
                                    id: sankeyRawLinks.current_node.id,
                                    // name: sankeyRawLinks.current_node.area,
                                    name: currentNodeDisplayName,
                                    depth: filterFirstLevel ? sankeyRawLinks.current_node.depth - 1 : sankeyRawLinks.current_node.depth,
                                    itemStyle: {
                                        color: '#346DF3'
                                    }
                                });
                            }
                            if (flowData.nodes.find(node => node.id === sankeyRawLinks.previous_node.id) === undefined) {
                                flowData.nodes.push({
                                    id: sankeyRawLinks.previous_node.id,
                                    // name: sankeyRawLinks.current_node.area,
                                    name: prevNodeDisplayName,
                                    depth: filterFirstLevel ? sankeyRawLinks.previous_node.depth - 1 : sankeyRawLinks.previous_node.depth,
                                    itemStyle: {
                                        color: '#346DF3'
                                    }
                                });
                            }
                            if (Object.keys(sankeyRawLinks.previous_node).length > 0) {
                                flowData.links.push({
                                    name: `${prevNodeDisplayName} to ${currentNodeDisplayName}`,
                                    // name: `${sankeyRawLinks.previous_node.area} to ${sankeyRawLinks.current_node.area}`,
                                    source: sankeyRawLinks.previous_node.id,
                                    target: sankeyRawLinks.current_node.id,
                                    // value: sankeyRawLinks.count,
                                    value: sankeyRawLinks.count,
                                    lineStyle: {
                                        // color: '#a2a2a2',
                                        color: 'source',
                                        opacity: 0.4
                                    }
                                });
                            }
                        });
                        flowData.nodes.push({
                            id: 'exit',
                            name: 'Exit',
                            depth: fixedDepth ? filterFirstLevel ? fixedDepth - 1 : fixedDepth : filterFirstLevel ? flattenData[0].previous_node.depth - 1 : flattenData[0].previous_node.depth,
                            itemStyle: {
                                color: '#DC143C'
                            }
                        });
                        const maxDepth = fixedDepth ? fixedDepth - 1 : flattenData[0].previous_node.depth;
                        flowData.nodes.filter(n => n.depth < maxDepth).forEach(node => {
                            const sumIncomeNode = originalData.filter(item => item.current_node.depth === node.depth + 1 && item.current_node.id === node.id).reduce((acc, obj) => acc + obj.count, 0);
                            const sumOutcomeNode = flowData.links.filter(link => link.source === node.id).reduce((acc, obj) => acc + obj.value, 0);
                            const dropoffNode = sumIncomeNode - sumOutcomeNode;
                            if (dropoffNode > 0 && sumOutcomeNode > 0) {
                                const dropoffPath = {
                                    name: node.name + ' to Exit',
                                    source: node.id,
                                    target: 'exit',
                                    value: dropoffNode,
                                    lineStyle: {
                                        //   color: '#a2a2a2',
                                        color: 'target',
                                        opacity: 0.4
                                    }
                                };
                                flowData.links.push(dropoffPath);
                            }
                        });
                        console.log(flowData);
                        sankeyRawData$.next(rawData);
                    }
                }
                chartData$.next({ data: flowData });
            }));
        }

        if (sankeyChartConfig.graphInfoPopover) {
            const infoPopoverText = sankeyChartConfig.graphInfoPopover;
            comInstance.infoPopover = async (e: any) => {
                const stackBarPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE[infoPopoverText.title] || infoPopoverText.title,
                        toolTipDetails: infoPopoverText.details,
                    },
                    event: e,
                });
                return await stackBarPopover.present();
            };
        }
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        // comInstance.chartOptions$ = chartOption$;
        // comInstance.chartLabel$ = chartLabel$;
        comInstance.filterFirstLevel = filterFirstLevel;
        comInstance.data$ = chartData$;
        comInstance.sankeyDisplayMode = sankeyDisplayMode;
        comInstance.sankeyRawData$ = sankeyRawData$;
        // comInstance.isShowLegend = isShowLegend;
        // comInstance.isLock = isLock$;
        comInstance.isLock = isLock;
        return comInstance;
    }
}
