import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Label } from 'ng2-charts';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { GenericLineChartData } from 'src/app/objects/chart';
import { SelectableLineChartDataName } from 'src/app/objects/selectableData';
import { GraphDataService } from 'src/app/services/graph-data-service.service';
import { ViewPeriodService } from 'src/app/services/view-period.service';
import { GraphDependency } from 'src/app/enum/graph-dependency.enum';
import { BaseDataDependency } from 'src/app/objects/baseGraphData';
import { isScreenSmallerThanMD } from 'src/app/helpers/util';

@Component({
  selector: 'app-dynamic-line-chart-wrapper',
  templateUrl: './dynamic-line-chart-wrapper.component.html',
  styleUrls: ['./dynamic-line-chart-wrapper.component.scss'],
})
export class DynamicLineChartWrapperComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isLock: boolean | BehaviorSubject<boolean> = false;
  @Input() data$: BehaviorSubject<GenericLineChartData[]>;
  @Input() label$: BehaviorSubject<Label[]>;
  @Input() selectedLine$: BehaviorSubject<{ [selectorName: string]: { name: SelectableLineChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } }>;
  @Input() selector: { [selectorName: string]: { name: SelectableLineChartDataName; selected?: boolean; args: string[] } } = {};
  @Input() enableTBD = false;
  @Input() sizeXS: string;
  @Input() sizeMD: string;
  @Input() offsetMD: string;
  @Input() selectInterface: 'action-sheet' | 'popover' | 'alert' = 'popover';
  @Input() isShow = true;
  @Input() isShowingLegend = true;
  @Input() isShowOnPeakTime: boolean;
  @Input() numberData$?: BehaviorSubject<number>;
  @Input() labelCustomLegend$?: BehaviorSubject<string>;
  @Input() labelStyle?: { fontSize: string; fontWeight: string; color: string };
  @Input() labelIcon?: string;
  @Input() isShowCustomLegend: boolean;
  @Input() labelHeader$?: BehaviorSubject<string>;
  @Input() isShowLegendOnPercent = false;
  @Input() showAveragePerDay = false;
  @Input() multipleSelector = true;
  
  lockOpacity = 0.35;
  selectedName: string[] = [];
  selectorName: string[] = [];
  shouldShowSelector = false;
  showAverage = false;
  uniqueGraphIds = new Set<BaseDataDependency>();

  subscription = new Subscription();

  constructor(
    public configDataService: ConfigDataService,
    private graphDataService: GraphDataService,
    private viewPeriodService: ViewPeriodService,
    private ref: ChangeDetectorRef
  ) { 
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  private update() {
    this.selectorName = Object.keys(this.selector);
    this.shouldShowSelector = this.selectorName && this.selectorName.length > 1;
    this.selectedName = Object.entries(this.selector).filter(([_, selectorDetail]) => selectorDetail.selected).map(([name, _]) => name);
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.update();
  }

  changeSelectedGraph(event: any) {
    const selectedName = event.detail.value as string[];
    this.selectedName = selectedName;
    // TODO: instead of next one value we need to add more here
    const currentSelectedLine = this.selectedLine$.value;
    Object.keys(currentSelectedLine).forEach(selectorName => {
      currentSelectedLine[selectorName].selected = selectedName.includes(selectorName);
    });
    this.selectedLine$.next(currentSelectedLine);
  }

  get isShowLegend() {
    return this.isShowingLegend;
  }

  legendNumber(data: number) {
    if (!data) {
      return '0';
    }
    return this.isShowLegendOnPercent ? `${data.toFixed(0)}%` : data.toLocaleString();
  }

  get isShowAveragePerDayButton() {
    return this.showAveragePerDay && (this.viewPeriodService.isWeekPeriod || this.viewPeriodService.isMonthPeriod);
  }

  toggleAveragePerDayFilter(): void {
    this.showAverage = !this.showAverage;
    const currentSelectedLine = this.selectedLine$.value;
    let isHavePrediction = false;
    // Add logic to update the data based on the showAverage state.
    Object.keys(currentSelectedLine).forEach(selectorName => {
      const graphId = currentSelectedLine[selectorName].name;
      const graphData = this.graphDataService.baseGraphData.getSelectedGraph(graphId, this.graphDataService);
      this.uniqueGraphIds.add(graphData.dependencies as BaseDataDependency);
    });
    this.uniqueGraphIds.forEach(graphId => {
      const currentValue = this.graphDataService.baseGraphData.getIsDisplayAverageValueForGraph(graphId).value;
      if (graphId === GraphDependency.ENTRANCE_EXIT) {
        isHavePrediction = true;
      }
      this.graphDataService.baseGraphData.setIsDisplayAverageValueForGraph(graphId, !currentValue);

    });
    // Case have prediction
    if (isHavePrediction) {
      const currentValue = this.graphDataService.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.PREDICTION_ENTRANCE_EXIT).value;
      this.graphDataService.baseGraphData.setIsDisplayAverageValueForGraph(GraphDependency.PREDICTION_ENTRANCE_EXIT, !currentValue);
    }
  }

  get displayIconOnMiddle() {
    return isScreenSmallerThanMD(window);
  }
}
