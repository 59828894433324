<ion-item-divider class="divider-header ion-activatable ripple-parent" [style]="'color: ' + textColor + '; --background: ' + color + ';'" [sticky]="sticky" (click)="handleToggleState()">
  <ion-label [style]="'font-size: ' + fontSize + '; font-weight: ' + fontWeight + '; text-align: center; margin: 10px auto;'">{{ title }}</ion-label>
  <ion-icon [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }" [name]="state === 'OPEN' ? 'chevron-up-outline' : 'chevron-down-outline'" style="margin-right: 1rem;" [ngStyle]="{color: textColor}"></ion-icon>
  <ion-ripple-effect></ion-ripple-effect>
</ion-item-divider>
<app-dynamic-graph-container
  [class.isOpen]="state === 'OPEN'"
  [componentName]="componentName"
  [additionalInput]="groupInput"
  *ngFor="let componentName of children; index as i"
></app-dynamic-graph-container>
