import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicDualNumberCardComponent } from '../shared-components/dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { GraphResolverBase } from './GraphResolverBase';


export class AverageTrafficDayTypeNumberCardResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        _additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService, 
        graphDataService: GraphDataService, 
        _popoverController: PopoverController, 
        viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription
    ) 
    {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        await configDataService.loadAppConfig();
        
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicDualNumberCardComponent;

        const headcountWeekdayNumberData$ = new BehaviorSubject('0');
        const headcountWeekdayLabel$ = new BehaviorSubject('0 % (0)');
        const headcountWeekdayLabelIcon$ = new BehaviorSubject('trending-up');
        const headcountWeekdayLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        const headcountWeekendNumberData$ = new BehaviorSubject('0');
        const headcountWeekendLabel$ = new BehaviorSubject('0 % (0)');
        const headcountWeekendLabelIcon$ = new BehaviorSubject('trending-up');
        const headcountWeekendLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        const headcountisLock$ = new BehaviorSubject<boolean>(true);
        const lockText$ = new BehaviorSubject('Available in Weekly / Monthly View, and after end of selected week');

        subscription.add(combineLatest([graphDataService.currentBuildingAverageWeekDayData$, graphDataService.currentBuildingAverageWeekEndData$]).subscribe(
            ([buildingAverageWeekDayData, buildingAverageWeekEndData]) => {
                const mainBuilding = configDataService.MAIN_BUILDING;
                if (!buildingAverageWeekDayData?.[mainBuilding] || !buildingAverageWeekEndData?.[mainBuilding]) { 
                    if (viewPeriodService.isDayPeriod) {
                        lockText$.next('Available in Weekly / Monthly View, and after end of selected week');
                        headcountisLock$.next(true);
                    }
                    return; 
                }
                const mainBuildingAverageWeekdayData = buildingAverageWeekDayData[mainBuilding];
                const mainBuildingAverageWeekendData = buildingAverageWeekEndData[mainBuilding];

                headcountWeekdayNumberData$.next(mainBuildingAverageWeekdayData?.headCount.toLocaleString());
                headcountWeekdayLabel$.next(`${mainBuildingAverageWeekdayData.diffPercent?.toFixed(1)} % (${mainBuildingAverageWeekdayData.diff?.toLocaleString()})`);
                headcountWeekdayLabelIcon$.next(mainBuildingAverageWeekdayData.diff > 0 ? 'trending-up' : 'trending-down');
                headcountWeekdayLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: mainBuildingAverageWeekdayData.diff >= 0 ? '#5BBF93' : '#E25144' });
            
                headcountWeekendNumberData$.next(mainBuildingAverageWeekendData?.headCount.toLocaleString());
                headcountWeekendLabel$.next(`${mainBuildingAverageWeekendData.diffPercent?.toFixed(1)} % (${mainBuildingAverageWeekendData.diff?.toLocaleString()})`);
                headcountWeekendLabelIcon$.next(mainBuildingAverageWeekendData.diff > 0 ? 'trending-up' : 'trending-down');
                headcountWeekendLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: mainBuildingAverageWeekendData.diff >= 0 ? '#5BBF93' : '#E25144' });
            
                const startOfWeekDate = moment().startOf('week').add(1, 'day');
                if (viewPeriodService.isDayPeriod) {
                    lockText$.next('Available in Weekly / Monthly View, and after end of selected week');
                    headcountisLock$.next(true);
                }
                else if (viewPeriodService.selectedDate.isSame(startOfWeekDate) && viewPeriodService.isWeekPeriod) {
                    lockText$.next('Available in Weekly / Monthly View, and after end of selected week');
                    headcountisLock$.next(true);
                }
                else {
                    headcountisLock$.next(false);
                }
            }
        ));

        subscription.add(viewPeriodService.isLiveMode$.subscribe((isLiveMode) => {
            if (isLiveMode) {
                headcountisLock$.next(true);
            }
        }));

        const headcountAverageWeekdayCard = {
            isLock: headcountisLock$,
            iconStyle: 'fontSize: 22px',
            title: 'Avg. Weekday Traffic',
            numberData$: headcountWeekdayNumberData$,
            label$: headcountWeekdayLabel$,
            labelIcon$: headcountWeekdayLabelIcon$,
            labelStyle$: headcountWeekdayLabelStyle$,
            sizeXS: '6',
            isFirst: true,
            inputLockText: lockText$,
        };

        
        const headcountAverageWeekendCard = {
            isLock: headcountisLock$,
            iconStyle: 'fontSize: 22px',
            title: 'Avg. Weekend Traffic',
            numberData$: headcountWeekendNumberData$,
            label$: headcountWeekendLabel$,
            labelIcon$: headcountWeekendLabelIcon$,
            labelStyle$: headcountWeekendLabelStyle$,
            sizeXS: '6',
            inputLockText: lockText$,
        };

        comInstance.cards = [headcountAverageWeekdayCard, headcountAverageWeekendCard];

        return comInstance;

    }
    
}
