<ion-grid style="margin: 20px 20px;">
  <ion-row class="table-header ion-text-center">
    <ion-col size="2" class="cell-border cell-attribute ion-align-self-center">
      QTR
    </ion-col>
    <ion-col size="10" class="cell-border cell-attribute ion-align-self-center">
      Criteria
    </ion-col>
  </ion-row>
  <ion-row
    class="table-content ion-text-center ion-align-items-center cell-attribute"
    *ngFor="let qtr of QUARTER_LIST"
  >
    <ion-col
      class="ion-align-self-center" 
      size="2"
      class="cell-border"
    >
      <label>{{ qtr }}</label>
    </ion-col>
    <ion-col
      class="ion-align-self-center" 
      *ngFor="
        let criterian_value of REVERSED_HEADCOUNT_CRITERION[qtr];
        let i = index
      "
      size="2"
      class="cell-border"
      [ngStyle]="getStyle(i)"
    >
      <label class="criterian-text-responsive">{{
        criterian_value | number
      }}</label>
    </ion-col>
  </ion-row>
</ion-grid>
