import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { showGateCustomTooltips } from '../helpers/gateTooltips';
import { accessDepthData, isScreenSmallerThanXL } from '../helpers/util';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class FloorSummaryResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;

        const initialFloorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value || 'ALL';
        const initialZoneName = graphDataService.baseGraphData.selectedDirectory$.value?.zone || getAdditionalInput(additionalInput, 'zone') as string;
        const initialIsAll = initialFloorName === 'ALL';
        const selectedLevel = graphDataService.baseGraphData.selectedLevel$.getValue() as 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA' || 'FLOOR';
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';

        type GateData = { [gateName: string]: number };

        const getDepthData = (
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            building?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (building === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, building, floorName, depthZoneName, 0);
            }
            // if (Object.keys(retData).includes('entrance')) {
            //     retData = getEntraceExitData(true, retData as EntraceExitData);
            // }
            return retData as GateData;
        };

        const getFloorGateData = (value: { [buildingName: string]: { [floorName: string]: GateData } }, defaultValue = {}) => (value && value[buildingName]) ? value[buildingName] : defaultValue as { [floorName: string]: GateData };
        const getZoneGateData = (value: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: GateData } } }, floorName?: string, zoneName?: string, defaultValue = {}) => 
        (value && value[buildingName][floorName][zoneName]) ? value[buildingName][floorName][zoneName] : defaultValue as { [floorName: string]: { [zoneName: string]: GateData } };

        const mergeAllReducer = (prev: GateData, [_floorName, floorData]: [string, GateData]) => {
            Object.entries(floorData).forEach(([gateName, value]) => {
                prev[gateName] = value;
            });
            return prev;
        };

        const floorInitialEntranceRawData = graphDataService.entranceFloorPin$.value || graphDataService.floorGateEntranceData$.value;
        const floorInitialExitRawData = graphDataService.exitFloorPin$.value || graphDataService.floorGateExitData$.value;
        const zoneInitialEntranceRawData = graphDataService.allPinByAllZoneEntrance$.value;
        const zoneInitialExitRawData = graphDataService.allPinByAllZoneExit$.value;

        const floorInitialEntranceData = initialIsAll ? Object.entries(getFloorGateData(floorInitialEntranceRawData)).reduce(mergeAllReducer, {} as GateData)
            : selectedLevel === 'FLOOR' ? getFloorGateData(floorInitialEntranceRawData)[initialFloorName] || {}
            : configDataService.isFeatureEnabled('traffic_page', 'heatmap_v2') ? zoneInitialEntranceRawData[initialZoneName] || {} : getDepthData(graphDataService.entranceZonePin$.value, buildingName, initialFloorName, initialZoneName);
        const floorInitialExitData = initialIsAll ? Object.entries(getFloorGateData(floorInitialExitRawData)).reduce(mergeAllReducer, {} as GateData)
            : selectedLevel === 'FLOOR' ? getFloorGateData(floorInitialExitRawData)[initialFloorName] || {}
            : configDataService.isFeatureEnabled('traffic_page', 'heatmap_v2') ? zoneInitialExitRawData[initialZoneName] || {} : getDepthData(graphDataService.exitZonePin$.value, buildingName, initialFloorName, initialZoneName);
        const initialGateNames = Object.keys(floorInitialEntranceData).sort();
        const chartLabel = initialGateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName.toUpperCase());

        const entranceInitialData = initialGateNames.map(gateName => floorInitialEntranceData[gateName] || null);
        const exitInitialData = initialGateNames.map(gateName => floorInitialExitData[gateName] || null);

        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: entranceInitialData,
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            calPercentFrommAllData: false
        }, {
            data: exitInitialData,
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.EXIT,
            calPercentFrommAllData: false
        }]);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        // eslint-disable-next-line max-len
        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, graphDataService.floorGateEntranceData$, graphDataService.floorGateExitData$,
            combineLatest([graphDataService.entranceFloorPin$, graphDataService.exitFloorPin$, graphDataService.allPinByAllZoneEntrance$, graphDataService.allPinByAllZoneExit$, graphDataService.entranceZonePin$, graphDataService.exitZonePin$,
                graphDataService.entranceBuildingFloorPin$, graphDataService.exitBuildingFloorPin$, graphDataService.spEntranceBuildingFloorPin$, graphDataService.spExitBuildingFloorPin$
            ])])
            .subscribe(([selectedFloorName, selectedDirectory, nextLevel, floorGateEntranceData, floorGateExitData,
                [entranceFloorPin, exitFloorPin, entranceAllZoneByPin, exitAllZoneByPin, entranceZoneByPin, exitZoneByPin, entranceBuildingFloorPin, exitBuildingFloorPin, spEntranceBuildingFloorPin, spExitBuildingFloorPin]]) => {
            let floorName = selectedDirectory?.floor || selectedFloorName || 'ALL';
            const floorEntranceRawData = configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f' ? spEntranceBuildingFloorPin : entranceFloorPin || floorGateEntranceData;
            const floorExitRawData = configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f' ? spExitBuildingFloorPin : exitFloorPin || floorGateExitData;
            const zoneName = selectedDirectory?.zone;
            if (zoneName === 'pavilion_000_g_001_2nd_reception') {
                floorName = 'pavilion_000_g_001_2nd';
            }
            const zoneEntranceRawData = entranceZoneByPin;
            const zoneExitRawData = exitZoneByPin;
            const isAll = floorName === 'ALL';
            if (configDataService.isFeatureEnabled('multiple_organization') && floorName !== 'ALL') {
                const gateLabel: string[] = [];
                const buildingEntranceData: number[] = [];
                const buildingExitData: number[] = [];
                const excludeArea: string[] = configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
                for (const building of Object.keys(configDataService.FLOOR_OBJECTS)) {
                    if (selectedDirectory?.zone !== '' && selectedDirectory?.zone !== null) {
                        if (building === selectedDirectory?.zone) {
                            const floorIndexMainBuilding = Object.keys(configDataService.FLOOR_OBJECTS[configDataService.MAIN_BUILDING]).findIndex(k => k === floorName);
                            const updatedFloorName = Object.keys(configDataService.FLOOR_OBJECTS[building])[floorIndexMainBuilding];
                            const buildingFloorEntranceData = getDepthData(floorEntranceRawData, building, updatedFloorName);
                            const buildingFloorExitData = getDepthData(floorExitRawData, building, updatedFloorName);
                            const buildingGateNames = Object.keys(buildingFloorEntranceData).sort();
                            const gateLabelList = buildingGateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName.toUpperCase());
                            const entranceGateData = buildingGateNames.map(gateName => buildingFloorEntranceData[gateName] || null);
                            const exitGateData = buildingGateNames.map(gateName => buildingFloorExitData[gateName] || null);
                            gateLabel.push(...gateLabelList);
                            buildingEntranceData.push(...entranceGateData);
                            buildingExitData.push(...exitGateData);
                        }
                    } else {
                        if (!excludeArea.includes(building)) {
                            const buildingFloorEntranceData = getDepthData(floorEntranceRawData, building, floorName);
                            const buildingFloorExitData = getDepthData(floorExitRawData, building, floorName);
                            const buildingGateNames = Object.keys(buildingFloorEntranceData).sort();
                            const gateLabelList = buildingGateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName.toUpperCase());
                            const entranceGateData = buildingGateNames.map(gateName => buildingFloorEntranceData[gateName] || null);
                            const exitGateData = buildingGateNames.map(gateName => buildingFloorExitData[gateName] || null);
                            gateLabel.push(...gateLabelList);
                            buildingEntranceData.push(...entranceGateData);
                            buildingExitData.push(...exitGateData);
                        }
                    }
                }
                comInstance.chartLabel = gateLabel;
                dualBarData$.next([{
                    data: buildingEntranceData,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                    calPercentFrommAllData: false
                }, {
                    data: buildingExitData,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.EXIT,
                    calPercentFrommAllData: false
                }]);
            } else {
                const floorEntranceData = isAll ? Object.entries(getFloorGateData(floorEntranceRawData)).reduce(mergeAllReducer, {} as GateData)
                    : nextLevel === 'FLOOR' ? getFloorGateData(floorEntranceRawData)[floorName] || {} 
                    : configDataService.isFeatureEnabled('traffic_page', 'heatmap_v2') ? entranceAllZoneByPin[zoneName] || {} : getDepthData(zoneEntranceRawData, buildingName, floorName, zoneName);
                const floorExitData = isAll ? Object.entries(getFloorGateData(floorExitRawData)).reduce(mergeAllReducer, {} as GateData)
                    : nextLevel === 'FLOOR' ? getFloorGateData(floorExitRawData)[floorName] || {} 
                    : configDataService.isFeatureEnabled('traffic_page', 'heatmap_v2') ? exitAllZoneByPin[zoneName] || {} : getDepthData(zoneExitRawData, buildingName, floorName, zoneName);
                
                const gateNames = Object.keys(floorEntranceData).sort();

                comInstance.chartLabel = gateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName.toUpperCase());
                const entranceData = gateNames.map(gateName => floorEntranceData[gateName] || null);
                const exitData = gateNames.map(gateName => floorExitData[gateName] || null);
                dualBarData$.next([{
                    data: entranceData,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                    calPercentFrommAllData: false
                }, {
                    data: exitData,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.EXIT,
                    calPercentFrommAllData: false
                }]);
            }
            comInstance.title = isAll ? configDataService.DISPLAY_LANGUAGE.FLOOR_SUMMARY 
            : nextLevel === 'FLOOR' ? `${configDataService.DISPLAY_LANGUAGE.FLOOR_SUMMARY}: ${configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[floorName] || floorName}`
            : `${configDataService.DISPLAY_LANGUAGE.ZONE_SUMMARY}: ${configDataService.DISPLAY_LANGUAGE.ZONE_NAME[zoneName] || zoneName}`;
            comInstance.aspectRatio = isAll ? 0.5 : (isScreenSmallerThanXL(window) ? 0.7 : 1.0);
            if (excludedDirectory) {
                if (excludedDirectory === 'BUILDING') {
                    comInstance.isShow =  selectedDirectory?.floor !== 'ALL';
                  }
                  else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                    comInstance.isShow =  selectedLevel !== excludedDirectory;
                  }
                  // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                  else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') <excludedDirectory.length - 1) {
                    const excludeList = excludedDirectory.split('_AND_');
                    if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                      comInstance.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                    }
                    else if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                      comInstance.isShow = true;
                    } else {
                      comInstance.isShow = !excludeList.includes(selectedLevel);
                    }
                  }
            } else {
                comInstance.isShow = !isAll && (nextLevel === 'FLOOR' || nextLevel === 'ZONE') ? true : false;
            }
        }));

        comInstance.title = initialIsAll ? configDataService.DISPLAY_LANGUAGE.FLOOR_SUMMARY
        : selectedLevel === 'FLOOR' ? `${configDataService.DISPLAY_LANGUAGE.FLOOR_SUMMARY}: ${configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[initialFloorName] || initialFloorName}`
        : `${configDataService.DISPLAY_LANGUAGE.ZONE_SUMMARY}: ${configDataService.DISPLAY_LANGUAGE.ZONE_NAME[initialZoneName] || initialZoneName}`;
        comInstance.aspectRatio = initialIsAll ? 0.5 : (isScreenSmallerThanXL(window) ? 0.7 : 1.0);
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = true;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.isHorizontal = true;
        comInstance.valueTextAlign = 'left';
        comInstance.valueTextOffsetX = 5;
        comInstance.valueTextProcess = 'toLocaleString';
        comInstance.displayAxis = 'Y';
        comInstance.isStacked = '!XY';
        comInstance.displayGrid = false;
        comInstance.displayTextValue = false;
        comInstance.isOnTrafficPage = true;
        comInstance.isShow = !initialIsAll && graphDataService.baseGraphData.selectedLevel$.value === 'FLOOR' || graphDataService.baseGraphData.selectedLevel$.value === 'ZONE' ? true : false; 
        const imageSrc = configDataService.isFeatureEnabled('traffic_page', 'gate_image_flatten') ? configDataService.entranceExitPinImageSrc : configDataService.entranceExitGateImageSrc;
        comInstance.customToolTipFuction = function(tooltipModel) {
            (tooltipModel as any).floorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value || 'ALL';
            showGateCustomTooltips(tooltipModel, this, imageSrc, configDataService.DISPLAY_LANGUAGE.GATE_NAME);
        };
        return comInstance;
    }

}
