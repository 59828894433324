import { ErrorHandler } from '@angular/core';
import { ErrorService } from '../../../services/error.service';
import { Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorhandler extends ErrorHandler {
    constructor(
        // private errorService: ErrorService
    ) {
        super();
    }

    handleError(error: Error) {
        throw new Error(error.stack);
        // this.errorService.postError(error.stack);
    }
}
