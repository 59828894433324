<div #heatmapContainer class="heatmap-container ion-text-center" style="display: grid;">
  <ngx-charts-heat-map
    #heatmapChart
    [scheme]="colorScheme"
    [results]="data$ | async"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [innerPadding]="2">
  </ngx-charts-heat-map>
</div>
