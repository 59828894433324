import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput, DynamicGoalCardConfig } from '../objects/config';
import { SelectableGoalDataName } from '../objects/selectableData';
import { DynamicProgressNumberCardComponent } from '../shared-components/dynamic/dynamic-progress-number-card/dynamic-progress-number-card.component';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import * as moment from 'moment';
import { accessDepthData, calculateProgressPercentage } from '../helpers/util';
import { GoalDataService } from '../services/goal-data.service';
import { AuthenticationService } from '../services/authentication.service';
import { generateNestedData } from '../helpers/mock-data-generator';

export class DynamicCustomGoalCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService,
        goalDataService?: GoalDataService,
    ) {
        // await configDataService.loadAppConfig();
        // const graphState = (getAdditionalInput(additionalInput, 'graphState') || 'ENABLE') as 'ENABLE' | 'DISABLE' | 'LOCK' | 'LOCK_COND';
        // const goalConfigs: DynamicGoalCardConfig[] = (getAdditionalInput(additionalInput, 'dynamicGoalConfig') || {}) as DynamicGoalCardConfig[];
        await goalDataService.loadGoalList();
        const goalConfigs: DynamicGoalCardConfig[] = goalDataService.goalList$.getValue();
        type DepthData = {
            [name: string]: number;
        } & {
            _total: number;
        };
        type EntraceExitData = { entrance: DepthData; exit: DepthData };

        const getEntraceExitData = (isEntrance: boolean, data: { entrance: DepthData; exit: DepthData }) => isEntrance ? data.entrance : data.exit;

        const getDepthData = (
            isEntrance: boolean,
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0);
            }
            if (retData instanceof Object && Object.keys(retData).includes('entrance')) {
                retData = getEntraceExitData(isEntrance, retData as EntraceExitData);
            }
            return retData;
        };

        const parseUnit = (value: number, unit: 'hour' | 'min' | 'percent' | 'decimal' | 'default', decimalPoint?: number) => {
            if (!value) {
                return 'N/A';
            }
            if (unit === 'percent') {
                return value.toFixed(1) + '%';
            }
            else if (unit === 'min') {
                if (value < 60) {
                    return '0.0 mins';
                }
                return (value / 60).toFixed(1) + ' mins';
            }
            else if (unit === 'hour') {
                return value.toLocaleString() + ' hrs';
            }
            else if (unit === 'decimal') {
                if (!decimalPoint) {
                    return value.toFixed(0);
                }
                return value.toFixed(decimalPoint);
            }
            return value.toLocaleString();
        };

        const filledCard = [];
        const subscriptions = [];
        configDataService.isHaveGoal$.next(goalConfigs.length > 0);
        goalConfigs.forEach(goalConfig => {
            // init config
            const dataConfig = goalConfig.dataConfig;
            const textConfig = goalConfig.textConfig;
            const durationTimeConfig = goalConfig.durationTime;
            const goalSettingConfig = goalConfig.goalSetting;
            const displayText = goalConfig.textConfig.displayMode || 'default';
            const decimalPoint = goalConfig.textConfig?.decimalPoint;
            const dataBehaviourSubject$: BehaviorSubject<unknown>[] = goalSettingConfig.is_mock_data ? [] : dataConfig.map(config => {
                const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(config.name as SelectableGoalDataName, graphDataService);
                graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
                return dataConfigResolved.data;
            });


            const appDate = viewPeriodService.isLivePeriod ? viewPeriodService.selectedDate.subtract(1, 'd') : viewPeriodService.selectedDate;
            const momentStartDate = moment(durationTimeConfig.startDate, 'MM-DD-YYYY');
            const momentEndDate = moment(durationTimeConfig.endDate, 'MM-DD-YYYY');

            //init card
            const isShow$ = new BehaviorSubject<boolean>(goalConfig.goalSetting?.display_all_time ? goalConfig.goalSetting?.display_all_time : appDate.isSameOrAfter(momentStartDate) && appDate.isSameOrBefore(momentEndDate));
            const labelActualVal = '0';
            const labelTrend = '0';
            const labelTrendIcon = 'triangle';
            const labelNumberIcon = 'person';
            const labelTrendStyle = { color: '#5BBF93' };
            const progressWidth = { width: '0%', 'background-color': goalSettingConfig.progressBarColor, 'border-right': '2px solid black' };
            const progressNumber = '0 %';
            const forecastLine = { left: 0 };
            const labelTargetVal = typeof goalSettingConfig.target === 'number' ? parseUnit(goalSettingConfig.target, textConfig.unit, decimalPoint) : null;
            const dayLeft = momentEndDate.diff(appDate, 'd');
            const isShowForecast = goalSettingConfig.showForecast !== undefined ? goalSettingConfig.showForecast : true;
            if (goalConfig?.goalSetting.sep_sub_value) {
                graphDataService.goalDurationDate2$.next(durationTimeConfig);
            } else {
                graphDataService.goalDurationDate$.next(durationTimeConfig);
            }
            const updatedProgress$ = new BehaviorSubject<{
                progressWidth: { width: string; 'background-color': string; 'border-right': string };
                progressNumber: string;
                dayLeft: number;
                labelActualVal: string;
                labelTrend: string;
                forecastLine: { left: number };
                targetPosition: Array<{ label: string; value: number; position: { left: number } }>;
                progressLabelPosition: { left: number };
            }>({
                progressWidth,
                progressNumber,
                dayLeft,
                labelActualVal,
                labelTrend,
                forecastLine,
                targetPosition: [],
                progressLabelPosition: { left: 0 },
            });
            const initGoalCard = goalConfig?.infoPopover.details.length === 0 ? {
                isLock: this.isLock,
                isShow: goalConfig.goalSetting?.display_all_time ? goalConfig.goalSetting?.display_all_time : appDate.isSameOrAfter(momentStartDate) && appDate.isSameOrBefore(momentEndDate),
                isShow$,
                title: goalConfig.titleName,
                title_area: textConfig.areaName,
                progressWidth,
                progressNumber,
                labelTrend,
                labelTrendStyle,
                labelActualVal,
                labelTargetVal,
                labelTrendIcon,
                labelNumberIcon,
                forecastLine,
                dayLeft,
                updatedProgress$,
                showForecast: isShowForecast,
                goalDuration: {
                    start_date: momentStartDate,
                    end_date: momentEndDate
                },
                displayLabel: {
                    currentValue: textConfig.displayLabel.currentValue,
                    trendValue: textConfig.displayLabel.trendValue,
                    location: textConfig.displayLabel.location
                },
            } : {
                isLock: this.isLock,
                // isShow: appDate.isSameOrAfter(momentStartDate) && appDate.isSameOrBefore(momentEndDate),
                isShow: goalConfig.goalSetting?.display_all_time ? goalConfig.goalSetting?.display_all_time : appDate.isSameOrAfter(momentStartDate) && appDate.isSameOrBefore(momentEndDate),
                isShow$,
                title: goalConfig.titleName,
                title_area: textConfig.areaName,
                progressWidth,
                progressNumber,
                labelTrend,
                labelTrendStyle,
                labelActualVal,
                labelTargetVal,
                labelTrendIcon,
                labelNumberIcon,
                forecastLine,
                dayLeft,
                updatedProgress$,
                infoPopover: async (e: any) => {
                    const goalCardPopover = await popoverController.create({
                        component: CustomTextTooltipsComponent,
                        componentProps: {
                            toolTipTitle: goalConfig?.infoPopover.titleName,
                            toolTipDetails: goalConfig?.infoPopover.details,
                        },
                        cssClass: 'customer-segment-details-popover',
                        event: e,
                    });
                    return await goalCardPopover.present();
                },
                showForecast: isShowForecast,
                goalDuration: {
                    start_date: momentStartDate,
                    end_date: momentEndDate
                },
                displayLabel: {
                    currentValue: textConfig.displayLabel.currentValue,
                    trendValue: textConfig.displayLabel.trendValue,
                    location: textConfig.displayLabel.location
                },
            };

            if (goalSettingConfig.is_mock_data) {
                // subscription data
                subscription.add(combineLatest([viewPeriodService.dayList]).subscribe(async ([dayList]) => {
                    const mockChartDataList: any[] = [];
                    const mockChartPredDataList: any[] = [];
                    const num_interval = 2;
                    for (const dc of dataConfig) {
                        const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, dc.name, 'count', num_interval, false, true);
                        const mockChartPredDataValue = await generateNestedData(viewPeriodService.selectedDate.add(1, 'months'), viewPeriodService, configDataService, dc.name, 'count', num_interval, false, true);
                        mockChartDataList.push(mockChartDataValue);
                        mockChartPredDataList.push(mockChartPredDataValue);
                    }
                    let parseData: number[] = [];
                    let parsePredData: number[] = [];
                    const data = mockChartDataList[0];
                    const predData = mockChartPredDataList[0];
                    const sumData = Object.values(data)[0] as number[];
                    const predFlattenData = Object.values(predData)[0] as number[];
                    const sumChangedData = (sumData[1] as number) - (sumData[0] as number);
                    const sumPredData = (predFlattenData[1] as number);
                    parseData = [sumData[1], sumChangedData];
                    parsePredData = [sumPredData];

                    if (goalSettingConfig.targetList) {
                        const progressNum = calculateProgressPercentage(parseData[0], goalSettingConfig.minValue, goalSettingConfig.maxValue);
                        const progressLabelPosition = { left: progressNum };
                        const targetPosition: Array<{ label: string; value: number; position: { left: number } }> = [];
                        for (const goalTarget of goalSettingConfig.targetList) {
                            const leftPosition = calculateProgressPercentage(goalTarget.value, goalSettingConfig.minValue, goalSettingConfig.maxValue);
                            targetPosition.push({
                                label: goalTarget.displayName,
                                value: goalTarget.value,
                                position: {
                                    left: leftPosition
                                }
                            });
                        }
                        const forecastVal = goalSettingConfig?.prediction ? goalSettingConfig.prediction : parsePredData;

                        const forecastPercent = goalSettingConfig?.prediction ? calculateProgressPercentage(goalSettingConfig?.prediction, goalSettingConfig.minValue, goalSettingConfig.maxValue)
                            : calculateProgressPercentage(forecastVal[0], goalSettingConfig.minValue, goalSettingConfig.maxValue);
                        updatedProgress$.next({
                            progressWidth: { width: `${progressNum}%`, 'background-color': goalConfig.goalSetting.progressBarColor, 'border-right': '2px solid black' },
                            progressNumber: parseUnit(progressNum, 'percent', textConfig.decimalPoint),
                            dayLeft,
                            progressLabelPosition: { left: progressLabelPosition.left },
                            labelActualVal: parseUnit(parseData[0], textConfig.unit, textConfig.decimalPoint),
                            labelTrend: parseUnit(parseData[1], textConfig.unit, textConfig.decimalPoint),
                            forecastLine: { left: forecastPercent < 1 ? 1 : forecastPercent },
                            targetPosition
                        });
                    }
                    else {
                        const progressNum = calculateProgressPercentage(parseData[0], goalSettingConfig.minValue, goalSettingConfig.target);
                        const progressLabelPosition = { left: progressNum };
                        // const progressNum = Math.round((parseData[0] / goalSettingConfig.target) * 100);
                        const forecastVal = goalSettingConfig?.prediction ? goalSettingConfig.prediction : parsePredData;
                        const forecastPercent = goalSettingConfig?.prediction ? calculateProgressPercentage(goalSettingConfig?.prediction, goalSettingConfig.minValue, goalSettingConfig.target)
                            : calculateProgressPercentage(forecastVal[0], goalSettingConfig.minValue, goalSettingConfig.target);
                        updatedProgress$.next({
                            progressWidth: { width: `${progressNum}%`, 'background-color': goalConfig.goalSetting.progressBarColor, 'border-right': '2px solid black' },
                            progressNumber: parseUnit(progressNum, 'percent', textConfig.decimalPoint),
                            dayLeft,
                            labelActualVal: parseUnit(parseData[0], textConfig.unit, textConfig.decimalPoint),
                            labelTrend: parseUnit(parseData[1], textConfig.unit, textConfig.decimalPoint),
                            forecastLine: { left: forecastPercent },
                            targetPosition: [],
                            progressLabelPosition: { left: progressLabelPosition.left }
                        });
                    }
                    isShow$.next(appDate.isSameOrAfter(momentStartDate) && appDate.isSameOrBefore(momentEndDate));
                }));
            } else {
                // subscription data
                subscription.add(combineLatest(dataBehaviourSubject$).subscribe((combinedRes) => {
                    const rawDatas = combinedRes;
                    const isEntrance = true;
                    if (rawDatas.some(val => !val)) { return; }
                    // let parseData: number[] = [];
                    let parseData: number[] = [];
                    let parsePredData: number[] = [];
                    rawDatas.forEach(rawData => {
                        let sumData = 0;
                        let sumChangedData = 0;
                        let sumPredData = 0;
                        // let sumChangedPredData = 0;
                        dataConfig.forEach(config => {
                            const data = getDepthData(isEntrance, rawData[0], config.args[0], config.args[1], config.args[2]);
                            const predData = getDepthData(isEntrance, rawData[1], config.args[0], config.args[1], config.args[2]);
                            sumData += Object.values(data)[0];
                            sumChangedData += Object.values(data)[1];
                            sumPredData += Object.values(predData)[0];
                        });
                        parseData = [sumData, sumChangedData];
                        parsePredData = [sumPredData];
                    });
                    if (goalSettingConfig.targetList) {
                        const progressNum = calculateProgressPercentage(parseData[0], goalSettingConfig.minValue, goalSettingConfig.maxValue);
                        const progressLabelPosition = { left: progressNum };
                        const targetPosition: Array<{ label: string; value: number; position: { left: number } }> = [];
                        for (const goalTarget of goalSettingConfig.targetList) {
                            const leftPosition = calculateProgressPercentage(goalTarget.value, goalSettingConfig.minValue, goalSettingConfig.maxValue);
                            targetPosition.push({
                                label: goalTarget.displayName,
                                value: goalTarget.value,
                                position: {
                                    left: leftPosition
                                }
                            });
                        }
                        const forecastVal = goalSettingConfig?.prediction ? goalSettingConfig.prediction : parsePredData;
                        const forecastPercent = goalSettingConfig?.prediction ? calculateProgressPercentage(goalSettingConfig?.prediction, goalSettingConfig.minValue, goalSettingConfig.maxValue)
                            : calculateProgressPercentage(forecastVal[0], goalSettingConfig.minValue, goalSettingConfig.maxValue);
                        if (goalConfig.titleName === 'ICS Half 2 average daily traffic VS. KPI') {
                            console.log(forecastVal, forecastPercent, goalSettingConfig.minValue, goalSettingConfig.maxValue);
                        }
                        updatedProgress$.next({
                            progressWidth: { width: `${progressNum}%`, 'background-color': goalConfig.goalSetting.progressBarColor, 'border-right': '2px solid black' },
                            progressNumber: parseUnit(progressNum, 'percent', textConfig.decimalPoint),
                            dayLeft,
                            progressLabelPosition: { left: progressLabelPosition.left },
                            labelActualVal: parseUnit(parseData[0], textConfig.unit, textConfig.decimalPoint),
                            labelTrend: parseUnit(parseData[1], textConfig.unit, textConfig.decimalPoint),
                            forecastLine: { left: forecastPercent < 1 ? 1 : forecastPercent },
                            targetPosition
                        });
                    }
                    else {
                        const progressNum = calculateProgressPercentage(parseData[0], goalSettingConfig.minValue, goalSettingConfig.target);
                        const progressLabelPosition = { left: progressNum };
                        // const progressNum = Math.round((parseData[0] / goalSettingConfig.target) * 100);
                        const forecastVal = goalSettingConfig?.prediction ? goalSettingConfig.prediction : parsePredData;
                        const forecastPercent = goalSettingConfig?.prediction ? calculateProgressPercentage(goalSettingConfig?.prediction, goalSettingConfig.minValue, goalSettingConfig.target)
                            : calculateProgressPercentage(forecastVal[0], goalSettingConfig.minValue, goalSettingConfig.target);
                        updatedProgress$.next({
                            progressWidth: { width: `${progressNum}%`, 'background-color': goalConfig.goalSetting.progressBarColor, 'border-right': '2px solid black' },
                            progressNumber: parseUnit(progressNum, 'percent', textConfig.decimalPoint),
                            dayLeft,
                            labelActualVal: parseUnit(parseData[0], textConfig.unit, textConfig.decimalPoint),
                            labelTrend: parseUnit(parseData[1], textConfig.unit, textConfig.decimalPoint),
                            forecastLine: { left: forecastPercent },
                            targetPosition: [],
                            progressLabelPosition: { left: progressLabelPosition.left }
                        });
                    }
                    isShow$.next(appDate.isSameOrAfter(momentStartDate) && appDate.isSameOrBefore(momentEndDate));
                }));
            }
            filledCard.push(initGoalCard);
        });

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicProgressNumberCardComponent;
        comInstance.cards = filledCard;
        return comInstance;
    }

}
