import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { PurchaseBagTooltipsComponent } from '../shared-components/general/purchase-bag-tooltips/purchase-bag-tooltips.component';
import { isSelectionVisitorProfileAll } from '../helpers/util';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class VisitorPurchaseRateNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        // load data here
        if (isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency); 
        }

        // await configDataService.loadAppConfig();
        configDataService.loadExampleImages();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const numberData$ = new BehaviorSubject('0 %');
        const label$ = new BehaviorSubject('0 %');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        const isLock$ = new BehaviorSubject(this.isLock);

        if (isMockData) {
            subscription.add(combineLatest([graphDataService.selectedVisitorProfile$, viewPeriodService.dayList]).subscribe(async ([selectedVisitorProfile, dayList]) => {
                const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'VISITOR_PURCHASE_RATE_TREND', 'count', 2);
                const headCounts = Object.values(mockData) as any;
                const headCount = headCounts[0][1];
                const diff = headCounts[0][1] - headCounts[0][0];
                const diffPercent = diff / headCounts[0][0] * 100;
                numberData$.next(headCount.toLocaleString() + ' %');
                label$.next(`${diffPercent?.toFixed(1)} %`);
                labelIcon$.next(diff > 0 ? 'trending-up' : 'trending-down');
                labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: diff >= 0 ? '#5BBF93' : '#E25144' });
                isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(selectedVisitorProfile)));
            }));
        } else {
            subscription.add(combineLatest([graphDataService.currentpurchaseRateData$, graphDataService.currentpurchaseRateProfileData$]).subscribe(
                ([currentpurchaseRateData, currentpurchaseRateProfileData]) => {
                    const selectedProfile = graphDataService.selectedVisitorProfile$.value;
                    const purchaseRateData = isSelectionVisitorProfileAll(selectedProfile) ? currentpurchaseRateData : currentpurchaseRateProfileData;
                    numberData$.next(`${purchaseRateData.purchase.toFixed(1)} %`);
                    label$.next(`${purchaseRateData.diff.toFixed(1)} %`);
                    labelIcon$.next(purchaseRateData.diff > 0 ? 'trending-up' : 'trending-down');
                    labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: purchaseRateData.diff >= 0 ? '#5BBF93' : '#E25144' });
                    isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(graphDataService.selectedVisitorProfile$.value)));
                }
            ));
        }



        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;
        comInstance.sizeXS = '12';
        comInstance.sizeMD = '10';
        comInstance.offsetMD = '1';
        comInstance.infoPopover = async (e: any) => {
            const shoppingBagPopover = await popoverController.create({
                component: PurchaseBagTooltipsComponent,
                cssClass: 'customer-segment-details-popover',
                event: e,
            });
            return await shoppingBagPopover.present();
        };

        return comInstance;
    }

}
