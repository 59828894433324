import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GenericBarChartData } from 'src/app/objects/chart';
import { ChartTooltipModel, ChartDataSets, ChartOptions} from 'chart.js';
import { Label } from 'ng2-charts';
import { isScreenSmallerThanLG } from 'src/app/helpers/util';
import { getHoverTooltipBarChartOptions } from 'src/app/helpers/chartJsHelper';

@Component({
  selector: 'app-generic-bar-chart',
  templateUrl: './generic-bar-chart.component.html',
  styleUrls: ['./generic-bar-chart.component.scss'],
})
export class GenericBarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data$: Observable<GenericBarChartData[]>;
  @Input() chartLabel: Label[];
  @Input() isShowLegend = false;
  @Input() customToolTipFuction: (tooltipModel: ChartTooltipModel) => void;
  @Input() isHorizontal = false;
  @Input() aspectRatio = isScreenSmallerThanLG(window) ? 0.5 : 1.4;
  @Input() valueTextAlign: 'center' | 'left' | 'right' = 'center';
  @Input() valueTextOffsetX = 0;
  @Input() valueTextOffsetY = 0;
  @Input() valueTextProcess: 'numberFormatter' | 'toLocaleString' | 'percentFormatter' = 'toLocaleString';
  @Input() xAxesRotation = 0;
  @Input() yAxesRotation = 0;
  @Input() displayAxis: 'X' | 'Y' | 'XY' = 'X';
  @Input() displayGrid = false;
  @Input() gridColor = '#424242';
  @Input() isStacked: 'X' | 'Y' | 'XY' | '!XY' = '!XY';
  @Input() suggestedTickMax_X = 0;
  @Input() displayTextValue = true;
  @Input() isOnTrafficPage = false;
  @Input() paddingRight: number;
  

  chartData: ChartDataSets[] = [{ data: [null] }];
  chartOptions: ChartOptions;
  subscription = new Subscription();

  get chartType() {
    return this.isHorizontal ? 'horizontalBar' : 'bar';
  }

  constructor() { }

  ngOnInit() {
    this.chartOptions = getHoverTooltipBarChartOptions(this.aspectRatio, this.valueTextAlign, this.valueTextProcess, this.valueTextOffsetX, this.valueTextOffsetY, this.xAxesRotation, this.yAxesRotation, this.displayAxis, this.displayGrid, this.gridColor, 'numberFormatter', this.isStacked
    , this.suggestedTickMax_X, this.displayTextValue, this.isOnTrafficPage, this.paddingRight);
    if (this.customToolTipFuction) {
      this.chartOptions.tooltips.custom = this.customToolTipFuction;
      this.chartOptions.tooltips.enabled = false;
    }
    this.subscription.add(this.data$.subscribe(barChartDataSets => {
      const chartData: ChartDataSets[] = [];
      barChartDataSets.forEach(barChartData => {
        chartData.push({
          barPercentage: barChartData.barPercentage || 0.9,
          categoryPercentage: barChartData.categoryPercentage || 0.8,
          data: barChartData.data,
          calPercentFrommAllData: barChartData.calPercentFrommAllData,
          calPercentFromIndexArray: barChartData.calPercentFromIndexArray,
          label: barChartData.label,
          backgroundColor: barChartData.color,
          hoverBackgroundColor: undefined,
          hidden: barChartData.isHidden ? true : false,
          calPercentFromLabelProperty: barChartData.calPercentFromLabelProperty,
        } as ChartDataSets);
      });
      this.chartData = chartData.length > 0 ? chartData : [{ data: [null] }];
    }));
  }

  ngOnChanges(): void {
    this.chartOptions = getHoverTooltipBarChartOptions(this.aspectRatio, this.valueTextAlign, this.valueTextProcess, this.valueTextOffsetX, this.valueTextOffsetY, this.xAxesRotation, this.yAxesRotation, this.displayAxis, this.displayGrid, this.gridColor, 'numberFormatter', this.isStacked
    , this.suggestedTickMax_X,  this.displayTextValue, this.isOnTrafficPage, this.paddingRight);
    if (this.customToolTipFuction) {
      this.chartOptions.tooltips.custom = this.customToolTipFuction;
      this.chartOptions.tooltips.enabled = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
