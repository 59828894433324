import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, concat, first, interval, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {

  public updateIsAvailable = false;
  public updateIsFinishing$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public appRef: ApplicationRef, 
    public updates: SwUpdate
  ) { }

  logUpdate() {
    this.updates.available.subscribe(event => {
      this.updateIsAvailable = true;
      /*console.log('current version is', event.current);
      console.log('available version is', event.available);*/
    });
    this.updates.activated.subscribe(event => {
      this.updateIsFinishing$.next(true);
      /*console.log('old version was', event.previous);
      console.log('new version is', event.current);*/
    });
  }

  checkForUpdate() {
    if (this.updates.isEnabled) {
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
    }
  }

  forceUpdate() {
    this.updates.available.subscribe(event => {
      this.updates.activateUpdate().then(() => console.log('finished update version'));
    });
  }

  async updatingFinished() {
    const updated = await lastValueFrom(this.updateIsFinishing$);
    return updated;
  }

}
