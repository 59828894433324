import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { PopoverController } from '@ionic/angular';
import { CustomerGenderTooltipsComponent } from '../pages/home/general/customer-gender-tooltips/customer-gender-tooltips.component';
import { GenericPieChartData } from '../objects/chart';
import { showGenderCustomTooltips } from '../helpers/genderTooltips';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { accessDepthData } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class DynamicGenderProfileResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE';
        const useselectedInteractable = (getAdditionalInput(additionalInput, 'useSelectedInteractable') || false) as boolean;
        // await configDataService.loadAppConfig();

        const getDepthData = (
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
            gateName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined && gateName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0, gateName);
            }
            // if (Object.keys(retData).includes('entrance')) {
            //     retData = getEntraceExitData(true, retData as EntraceExitData);
            // }
            return retData as { male: number; female: number };
        };

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        const initialSelectedDirectory = graphDataService.baseGraphData.selectedDirectory$.getValue() || { building: configDataService.MAIN_BUILDING, floor: 'ALL', zone: '' };
        const initalSelectedLevel = graphDataService.baseGraphData.selectedLevel$.getValue();
        const initialGenderData =  useselectedInteractable ? graphDataService.zoneGenderProfileByPinBreakdown$.getValue() 
        : initialSelectedDirectory?.floor === 'ALL' ? graphDataService.genderProfileData$.getValue() 
        : initalSelectedLevel === 'ZONE' ? graphDataService.allZoneGenderProfileBreakdown$.getValue() 
        : graphDataService.genderProfileData$.getValue();
        const initialFlattenData = useselectedInteractable ? getDepthData(initialGenderData) : initalSelectedLevel === 'ZONE' ? getDepthData(initialGenderData, initialSelectedDirectory.zone) : getDepthData(initialGenderData);
        const sumInitData = initialFlattenData ? Object.values(initialFlattenData).reduce((a, b) => a + b, 0) : 0;

        const pieData$ = new BehaviorSubject<GenericPieChartData[]>([{
            data: ((initialFlattenData?.female || 0) / sumInitData) * 100,
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
        }, {
            data: ((initialFlattenData?.male || 0) / sumInitData) * 100,
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
        }]);
        subscription.add(combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, graphDataService.genderProfileData$, graphDataService.allZoneGenderProfileRawBreakdown$, graphDataService.zoneGenderProfileByPinBreakdown$])
        .subscribe(([selectedDirectory, selectedLevel, genderProfileBreakdown, allZoneGenderProfileBreakdown, zoneGenderProfileByPinBreakdown]) => {
            const genderData = useselectedInteractable ? zoneGenderProfileByPinBreakdown : selectedDirectory?.floor === 'ALL' ? genderProfileBreakdown : selectedLevel === 'ZONE' ? allZoneGenderProfileBreakdown : genderProfileBreakdown;
            if (!genderData) { return; }
            // const selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
            // if (includeAreaList.length > 0 && selectedInteractableName) {
            //     comInstance.isShow = includeAreaList.includes(selectedInteractableName);
            // }
            const flattenData = useselectedInteractable ? getDepthData(genderData) : selectedLevel === 'ZONE' ? getDepthData(genderData, selectedDirectory.zone) : getDepthData(genderData);
            if (excludedDirectory) {
                comInstance.isShow = selectedDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : selectedLevel !== excludedDirectory;
            }
            const sumData = Object.values(flattenData).reduce((a, b) => a + b, 0);
            pieData$.next([{
                data: (flattenData.female / sumData) * 100,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
            }, {
                data: (flattenData.male  / sumData)  * 100,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
            }]);
        }));

        comInstance.title = configDataService.DISPLAY_LANGUAGE.GENDER_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.infoPopover = async (e: any) => {
            const customerGenderPopover = await popoverController.create({
                component: CustomerGenderTooltipsComponent,
                cssClass: 'customer-segment-details-popover',
                event: e
            });
            return customerGenderPopover.present();
        };
        if (excludedDirectory) {
            comInstance.isShow = initialSelectedDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : initalSelectedLevel !== excludedDirectory;
        }
        comInstance.isShowLegend = false;
        comInstance.isShowLegendOnPercent = true;
        comInstance.data$ = pieData$;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showGenderCustomTooltips(tooltipModel, this, configDataService.genderImageSrc);
        };
        return comInstance;
    }

}
