import { Component, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-purchase-bag-tooltips',
  templateUrl: './purchase-bag-tooltips.component.html',
  styleUrls: ['./purchase-bag-tooltips.component.scss']
})
export class PurchaseBagTooltipsComponent implements OnInit {
  toolTipTitle: string;
  toolTipDetails: string[];
  BAG_LIST: Array<string> = [
    'Shopping',
    'Non-Shopping'
  ];

  constructor(
    private configDataService: ConfigDataService
  ) { }

  ngOnInit() {
  }

  getImageSrc(segment, index) {
    return this.configDataService.bagImageSrc[segment][index - 1];
  }


}
