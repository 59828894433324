import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { PopoverController } from '@ionic/angular';
import { GenericPieChartData } from '../objects/chart';
import { CHART_SECONDARY_COLOR, CHART_PRIMARY_COLOR } from '../configs/color';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { isShowGraph } from '../helpers/util';
import { AuthenticationService } from '../services/authentication.service';

export class RepeatedVisitorsResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;

        const pieData$ = new BehaviorSubject<GenericPieChartData[]>([{
            data: graphDataService.repeatedVisitorsData$.value.repeated_percentage * 100,
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.VISITORS_CLASS.repeated
        }, {
            data: graphDataService.repeatedVisitorsData$.value.new_percentage * 100,
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.VISITORS_CLASS.new
        }]);
        subscription.add(graphDataService.repeatedVisitorsData$.subscribe(repeatedVisitorData => {
            pieData$.next([{
                data: repeatedVisitorData.repeated_percentage * 100,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.VISITORS_CLASS.repeated
            }, {
                data: repeatedVisitorData.new_percentage * 100,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.VISITORS_CLASS.new
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.REPETATED_VISITORS;
        comInstance.isLock = this.isLock;
        
        // comInstance.isShowLegendTitleValue = false;
        // comInstance.paddingRigt = '3.5rem';
        comInstance.isShowLegendOnPercent = true;
        const textValueProcess = 'percentage';
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        // comInstance.infoPopover = async (e: any) => {
        //     const touristsPopover = await popoverController.create({
        //         component: TouristsTooltipsComponent,
        //         cssClass: 'calendar-criteria-tooltips',
        //         event: e
        //     });
        //     return touristsPopover.present();
        // };
        comInstance.data$ = pieData$;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this, textValueProcess);
        };
        return comInstance;
    }

}
