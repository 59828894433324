import { GraphDependency } from '../enum/graph-dependency.enum';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { GraphResolverBase } from './GraphResolverBase';

export abstract class OverallResolverBase extends GraphResolverBase {
    // Becareful not to add input that changes between graph
    protected configDataService: ConfigDataService;
    protected graphDataService: GraphDataService;

    public abstract get graphDependencyMap(): { 'building': GraphDependency[]; 'floor': GraphDependency[]; 'zone': GraphDependency[] };

    public proceedAddDependency(level: 'building' | 'floor' | 'zone'): void {
        return this.graphDataService.baseGraphData.addDependency(this.graphDependencyMap[level]);
    }

    protected getDisplayName(name: string) {
        return this.configDataService.DISPLAY_LANGUAGE.BUILDING_NAME[name] || this.configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[name] || this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME[name] || name;
    }

    protected getLeveledData<T>(
        dataBuilding: { [buildingName: string]: T },
        dataFloor: { [buildingName: string]: { [floorName: string]: T } },
        dataZone: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: T } } },
        level: 'building' | 'floor' | 'zone',
    ) {
        let flattenData: { [name: string]: T };
        if (level === 'building') {
            flattenData = ((dataBuilding || {}) as { [name: string]: T });
        } else if (level === 'floor') {
            Object.values(dataFloor || {}).forEach((buildingData) => {
                flattenData = { ...flattenData, ...buildingData };
            });
            flattenData = flattenData;
        } else {
            Object.values(dataZone || {}).forEach((buildingData) => {
                Object.values(buildingData || {}).forEach((floorData) => {
                    flattenData = { ...flattenData, ...floorData };
                });
            });
            flattenData = flattenData;
        }
        return flattenData || {};
    }

    protected getComparingNames(comparingState: { [levelName: string]: boolean }) {
        return Object.entries(comparingState).reduce((prev, [levelName, state]) => {
            if (state) { prev.push(levelName); }
            return prev;
        }, [] as string[]);
    }

    public processDynamicDependency(
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        level: 'building' | 'floor' | 'zone',
    ) {
        this.configDataService = configDataService;
        this.graphDataService = graphDataService;
        this.proceedAddDependency(level);
    }

}
