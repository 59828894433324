import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CHART_PRIMARY_COLOR } from '../configs/color';
import { showGenericBarChartCustomTooltips } from '../helpers/barChartTooltips';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { isShowGraph } from '../helpers/util';
import { GenericBarChartData } from '../objects/chart';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { GraphResolverBase } from './GraphResolverBase';


export class TopTenCarBrandResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService,
        graphDataService: GraphDataService, 
        popoverController: PopoverController, 
        _viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
        authenicationService: AuthenticationService
        ) { 
            graphDataService.baseGraphData.addDependency(this.dataDependency);
            const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
            const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;

            const barChartData$ = new BehaviorSubject<GenericBarChartData[]>([{
                data: [0],
                color: CHART_PRIMARY_COLOR,
                label: 'Total',
                calPercentFrommAllData: true,
            }]);
            const componentRef = viewContainerRef.createComponent(componentFactory);
            const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;

            subscription.add(graphDataService.topTenCarBrandData$.subscribe((topTenCarBrandData) => {
                if (!topTenCarBrandData) { return; }
                const barChartLabel = Object.keys(topTenCarBrandData).map(str => str.charAt(0).toUpperCase() + str.slice(1));
                const sortVal = Object.values(topTenCarBrandData).sort((a, b) => a[1] - b[1]);
                const maxSortVal = Math.max(...sortVal);
                const suggestedTickMax_X = maxSortVal + (maxSortVal / 10);
                barChartData$.next([{
                    data: Object.values(topTenCarBrandData),
                    color: CHART_PRIMARY_COLOR,
                    label: 'Total',
                    calPercentFrommAllData: false,
                }]);
                comInstance.chartLabel = barChartLabel;
                comInstance.suggestedTickMax_X = suggestedTickMax_X;
            }));

            comInstance.title = configDataService.DISPLAY_LANGUAGE.TOP_TEN_CAR_BRAND;
            comInstance.isLock = this.isLock;
            comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
            comInstance.data$ = barChartData$;
            comInstance.isHorizontal = true;
            comInstance.displayAxis = 'Y';
            comInstance.isStacked = 'Y';
            comInstance.valueTextAlign = 'left';
            comInstance.valueTextOffsetX = 2;
            comInstance.valueTextOffsetY = 5;
            comInstance.valueTextProcess = 'percentFormatter';
            comInstance.displayGrid = false;
            // comInstance.isShowLegend = true;
            comInstance.customToolTipFuction = function(tooltipModel) {
                showGenericBarChartCustomTooltips(tooltipModel, this, null, 'percentFormatter');
            };
            comInstance.infoPopover = async (e: any) => {
                const customerGenderPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.TOP_TEN_CAR_BRAND,
                        toolTipDetails: ['Ten most popular incoming/outgoing vehicle\'brands during a given time period.', 'based on available daytime traffic.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e
                });
                return customerGenderPopover.present();
            };
            return comInstance;
        }

}
