import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericPieChartData } from '../objects/chart';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';

export class StoreAgeProfileResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService, 
        graphDataService: GraphDataService, 
        popoverController: PopoverController, 
        viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription
    ){
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const mapAgeData = graphDataService.storeAgeProfileData$.value?.male.map((num, idx) => num + graphDataService.storeAgeProfileData$?.value.female[idx]) || [];
        const pieData$ = new BehaviorSubject<GenericPieChartData[]>([{
            data: mapAgeData[0] || 0,
            color: PIE_CHART_COLOR_LIST[0],
            label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.children,
        }, {
            data: mapAgeData[1] || 0,
            color: PIE_CHART_COLOR_LIST[1],
            label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.teenagers
        }, {
            data: mapAgeData[2] || 0,
            color: PIE_CHART_COLOR_LIST[2],
            label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.young_adults
        }, {
            data: mapAgeData[3] || 0,
            color: PIE_CHART_COLOR_LIST[3],
            label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.adults
        }, {
            data: mapAgeData[4] || 0,
            color: PIE_CHART_COLOR_LIST[4],
            label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.seniors
        }]);
        subscription.add(graphDataService.storeAgeProfileData$.subscribe(ageData => {
            const nextMapAgeData = ageData?.male.map((num, idx) => num + ageData.female[idx]) || [];
            pieData$.next([{
                data: nextMapAgeData[0] || 0,
                color: PIE_CHART_COLOR_LIST[0],
                label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.children
            }, {
                data: nextMapAgeData[1] || 0,
                color: PIE_CHART_COLOR_LIST[1],
                label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.teenagers
            }, {
                data: nextMapAgeData[2] || 0,
                color: PIE_CHART_COLOR_LIST[2],
                label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.young_adults
            }, {
                data: nextMapAgeData[3] || 0,
                color: PIE_CHART_COLOR_LIST[3],
                label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.adults
            }, {
                data: nextMapAgeData[4] || 0,
                color: PIE_CHART_COLOR_LIST[4],
                label: configDataService.DISPLAY_LANGUAGE.AGE_CLASS.seniors
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.AGE_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegendTitleValue = false; 
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this, 'percentage');
        };
        comInstance.isShowLegendOnPercent = false;
        comInstance.isShowLegend = true;
        comInstance.data$ = pieData$;
        return comInstance;

    }
    
}
