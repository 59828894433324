import { Component, OnInit } from '@angular/core';
import { isScreenSmallerThanMD } from 'src/app/helpers/util';

@Component({
  selector: 'app-customer-age-tooltips',
  templateUrl: './customer-age-tooltips.component.html',
  styleUrls: ['./customer-age-tooltips.component.scss']
})
export class CustomerAgeTooltipsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  get childrenText() {
    if (isScreenSmallerThanMD(window)) { return 'Child'; }
    else { return 'Children'; }
  }

  get teenagersText() {
    if (isScreenSmallerThanMD(window)) { return 'Teen'; }
    else { return 'Teenagers'; }
  }

  get youngAdultsText() {
    if (isScreenSmallerThanMD(window)) { return 'YA'; }
    else { return 'Young Adults'; }
  }

  get adultsText() {
    if (isScreenSmallerThanMD(window)) { return 'Adult'; }
    else { return 'Adults'; }
  }

  get seniorsText() {
    if (isScreenSmallerThanMD(window)) { return 'Senior'; }
    else { return 'Seniors'; }
  }
}
