import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { getHeatmapColorForCSS } from 'src/app/helpers/util';

@Component({
  selector: 'app-dynamic-gauge-chart-wrapper',
  templateUrl: './dynamic-gauge-chart-wrapper.component.html',
  styleUrls: ['./dynamic-gauge-chart-wrapper.component.scss'],
})
export class DynamicGaugeChartWrapperComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() data$: BehaviorSubject<{ current: number; min: number; max: number }>;
  @Input() isLock: boolean | BehaviorSubject<boolean> = false;
  @Input() isShow$ = new BehaviorSubject<boolean>(true);
  @Input() reverseArcColor: boolean;

  subscription = new Subscription();
  gagueChartOption = {
    hasNeedle: true,
    outerNeedle: true,
    needleColor: 'white',
    needleUpdateSpeed: 0,
    arcColors: ['#de5e56', '#2F2F2F'],
    arcPaddingColor: 'white',
    arcDelimiters: [50],
    arcLabelColor: 'white',
    rangeLabel: ['0', '28000'],
    needleStartValue: 0,
    textColor: new BehaviorSubject<[string, string, string]>(['var(--ion-text-color)', 'var(--ion-text-color)', 'var(--ion-text-color)']),
  };

  constructor(
    private ref: ChangeDetectorRef
  ) { 
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {
    this.subscription.add(this.data$.subscribe(data => {
      const fraction = Math.max(0.0001, Math.min(0.9999, data.current / data.max));
      this.gagueChartOption.arcDelimiters = [fraction * 100];
      this.gagueChartOption.rangeLabel = [data.min.toString(), data.max.toLocaleString()];
      // const cssColor = getHeatmapColorForCSS(fraction);
      let cssColor: string;
      if (this.reverseArcColor) {
      cssColor = fraction < 0.5 ? '#5BBF93' : '#E25144';
      } else {
      cssColor = fraction < 0.5 ? '#E25144' : '#5BBF93';
      }
    // this.gagueChartOption.arcColors = [cssColor, '#2f2f2f'];
      // const cssColor = fraction < 0.5 ? '#E25144' : '#5BBF93';
      this.gagueChartOption.arcColors = [cssColor, '#2f2f2f'];
      this.gagueChartOption.textColor.next(['var(--ion-text-color)', 'var(--ion-text-color)', cssColor]);
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
