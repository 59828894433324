import { Directive, Input, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appDataTbd]'
})
export class DataTbdDirective implements AfterViewInit, OnDestroy {
  @Input() textColor = 'var(--ion-text-color)';
  @Input() lockOpacity = 0.15;
  @Input() isEnableTBD = true;
  @Input() forceDisplayTBD: boolean;
  @Input() inputLockText: string;

  element: ElementRef;
  renderer: Renderer2;
  tbdDivContainer: HTMLDivElement = null;
  tbdDiv: HTMLDivElement = null;
  tbdText: HTMLDivElement = null;
  tbdDescriptionText: HTMLDivElement = null;
  subscription = new Subscription();

  constructor(
    private viewPeriodService: ViewPeriodService,
    elementRef: ElementRef,
    renderer: Renderer2,
  ) {
    this.element = elementRef;
    this.renderer = renderer;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.subscription.add(this.viewPeriodService.isLiveMode$.subscribe(isLiveMode => {
      if (this.forceDisplayTBD) {
        this.applyTbdPopup(this.isEnableTBD);
      }
      else if (this.isEnableTBD) {
        this.applyTbdPopup(isLiveMode);
      }
    }));
  }

  private initTbdPopup() {
    this.tbdDivContainer = this.renderer.createElement('div');
    this.tbdDivContainer.style.cssText = `position: absolute;
                                          display: flex;
                                          top: 0px;
                                          left: 0px;
                                          width: 100%;
                                          height: 100%;
                                          justify-content: center;
                                          background: rgba(0,0,0,${this.lockOpacity});
                                          align-items: center;`;

    this.tbdDiv = this.renderer.createElement('div');
    this.tbdDiv.style.cssText = `text-align: center;
                                 padding: 2em;
                                 border-radius: 1em;`;

    this.tbdText = this.renderer.createElement('div');
    this.tbdText.innerText = 'TBD';
    this.tbdText.style.cssText = `padding-bottom: 0.5em;
                                  color: ${this.textColor};
                                  font-size: 4em;
                                  font-weight: bold;`;

    this.tbdDescriptionText = this.renderer.createElement('div');
    this.tbdDescriptionText.innerText = this?.inputLockText ? this.inputLockText : 'data will be available at the end of the day.';
    this.tbdDescriptionText.style.cssText = `color: ${this.textColor};
                                             font-size: 1em;`;
    this.tbdDiv.appendChild(this.tbdText);
    this.tbdDiv.appendChild(this.tbdDescriptionText);
    this.tbdDivContainer.appendChild(this.tbdDiv);
  }

  private applyTbdPopup(tbdState: boolean) {
    if (tbdState) {
      if (this.tbdDiv === null) {
        this.initTbdPopup();
      }
      this.renderer.appendChild(this.element.nativeElement, this.tbdDivContainer);
    }
    else {
      if (!this.tbdDiv) { return; }
      this.renderer.removeChild(this.element.nativeElement, this.tbdDivContainer);
    }
  }

}
