<ion-grid>
  <ion-row>
    <ion-col size-xs="12">
      <span style="color: var(--ion-color-primary); font-weight: 700; font-size: 18px;">{{ toolTipTitle }} Definition</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-text-left">
    <ion-col size="12" class="responsive-font-size ion-align-self-center">
      <div *ngFor="let toolTipDetail of toolTipDetails">
        <p>{{ toolTipDetail }}</p> 
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-xs="12">
      <span style="color: white; font-weight: 700; font-size: 18px;">Courier Image Examples</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-no-padding ion-float-start">
    <ion-col *ngFor="let messenger of exampleMessgenerClass">
      <img style="width: 64px; height: 176px;min-height: max-content;" [src]="getImageSrc(messenger, 0)"/>
    </ion-col>
  </ion-row>
</ion-grid>
