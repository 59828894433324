<ion-row  class="progress-number-card" >
  <ion-col *ngFor="let card of cards; first as isFirst" size="12" [ngStyle]="(card.isShow$ | async) || card.isShow ? { display: 'block' } : { display: 'none' }">
    <ion-card appLockIcon [isLock]="card.isLock">
      <ion-card-header>
        <ion-card-title class="ion-text-left">
          {{ card.title }} 
          <ion-icon *ngIf="card.infoPopover" (click)="card.infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon> 
          <span *ngIf="card.displayLabel.location && card.title_area" class="ion-float-right" style="display: inline-flex; margin-top: 5px;"> {{ card.title_area }} </span> 
        </ion-card-title>
      </ion-card-header>
      <ion-card-content class="ion-margin-horizontal" style="margin-top: 2rem;margin-bottom: 2rem;">
        <ion-row *ngIf="card.displayLabel.currentValue" class="ion-justify-content-between ion-text-md-center">
          <ion-col  [ngStyle]="NumberBigStyle" class="ion-no-padding">
            <label style="display: inline-flex; flex-wrap: wrap; gap: 6px;"> 
              <ion-icon style="display: inline-block; vertical-align: middle; margin-top: 1.5px;" [name]="card.labelNumberIcon" ></ion-icon>
              {{ (card.updatedProgress$ | async)?.labelActualVal || card.labelActualVal }} 
            </label>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="card.displayLabel.trendValue" [ngStyle]="labelTitleStyle" class="ion-text-md-center">
          <ion-col class="ion-no-padding" style="z-index: 102;padding-bottom: 3rem;">
            <label [ngStyle]="card.labelTrendStyle" style="display: inline-flex; flex-wrap: wrap; gap: 6px;"> 
              <ion-icon style="display: inline-block; vertical-align: middle;" [name]="card.labelTrendIcon" [style]="card.iconStyle"></ion-icon> 
              {{ (card.updatedProgress$ | async)?.labelTrend || card.labelTrend }} 
            </label>
          </ion-col>
        </ion-row>
        <ion-row>
          <!-- <div class="vl-current-value-text" [style.left.%]="calculateLeftPositionOnWidthDevice((card.updatedProgress$ | async)?.progressLabelPosition.left, 'current_progress')">
            <p style="font-weight: 700; font-size: 18px; text-align: left;">{{ (card.updatedProgress$ | async)?.labelActualVal || card.labelActualVal }}</p> 
          </div> -->
          <div *ngFor="let targetObj of (card.updatedProgress$ | async)?.targetPosition">
            <!-- <div class="vl" [style.width.%]="widthVL" [style.left.%]="targetObj.position.left"></div> -->
            <div class="vl" [style.left.%]="targetObj.position.left"></div>
            <div class="vl-target-text" [style.left.%]="calculateLeftPositionOnWidthDevice(targetObj.position.left, 'target')">
              <p>{{ targetObj.label }}</p>
              <p>{{ parseUnit(targetObj.value, 'si') }}</p>
            </div>
          </div>
          <div *ngIf="card.showForecast" class="vl-forecast" [style.left.%]="calculateLeftPositionOnWidthDevice((card.updatedProgress$ | async)?.forecastLine.left || card.forecastLine.left, 'forecast')"></div>
          <div class="progress-outer">
            <div class="progress-inner" [ngStyle]="(card.updatedProgress$ | async)?.progressWidth || card.progressWidth">
              <div class="updated-progress-container">
                <p class="updated-progress-text">
                  {{ (card.updatedProgress$ | async)?.labelActualVal || card.labelActualVal }}
                </p>
              </div>
              <ion-icon name="caret-down-outline" class="updated-progress-marker"></ion-icon>
              <!-- <span style="text-align: right; font-weight: 700"> {{ (card.updatedProgress$ | async)?.progressNumber || card.progressNumber }} </span> -->
            </div>
          </div>
        </ion-row>
        <!-- <ion-row class="ion-text-md-center" style="margin-bottom: 0.5rem">
          <ion-col [ngStyle]="labelTitleStyle" class="ion-no-padding">
            <label style="display: inline-flex; flex-wrap: wrap; gap: 6px;">
              <ion-icon style="display: inline-block; vertical-align: middle;" name="goal" [style]="card.iconStyle"></ion-icon> 
              {{ card.labelTargetVal }}
            </label>
          </ion-col>
        </ion-row> -->
      </ion-card-content>
      <ion-row style="margin: 4.5rem 0.5rem 0.5rem 0.5rem;">
        <ion-col class="ion-float-left ion-no-padding">
          <label> {{ getGoalDuration(card.goalDuration) }}</label>
        </ion-col>
      </ion-row>
    </ion-card>
  </ion-col>
</ion-row>
