<ion-grid>
  <ion-row>
    <ion-col size-xs="12">
      <span style="color: var(--ion-color-primary); font-weight: 700; font-size: 18px;">{{ toolTipTitle }} Definition</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-text-left">
    <ion-col size="12" class="responsive-font-size ion-align-self-center">
      <div *ngFor="let toolTipDetail of toolTipDetails">
        <p>{{ toolTipDetail }}</p> 
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-xs="12" style="margin-bottom: 10px;">
      <span style="color: white; font-weight: 700; font-size: 18px;">Brand Image Examples</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-no-padding ion-align-items-center" *ngFor="let messengerGroup of classList">
    <ion-col class="ion-no-padding ion-text-center" style="color: white; font-weight: 600; font-size: 14px;" size-xs="3">
      {{ configDataService.DISPLAY_LANGUAGE.MESSENGER_GROUP_CLASS[messengerGroup] || messengerGroup }}
    </ion-col>
    <ion-col size-xs="3">
      <div class="img-container">
        <img [src]="getImageSrc(messengerGroup, 1)"/>
      </div>
      <div class="img-container">
        <img [src]="getImageSrc(messengerGroup, 4)"/>
      </div>
    </ion-col>
    <ion-col size-xs="3">
      <div class="img-container">
        <img [src]="getImageSrc(messengerGroup, 2)"/>
      </div>
      <div class="img-container">
        <img [src]="getImageSrc(messengerGroup, 5)"/>
      </div>
    </ion-col>
    <ion-col size-xs="3">
      <div class="img-container">
        <img [src]="getImageSrc(messengerGroup, 3)"/>
      </div>
      <div class="img-container">
        <img [src]="getImageSrc(messengerGroup, 6)"/>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
