<ion-grid class="ion-no-padding">
  <ion-row class="ion-no-padding">
    <!-- <ion-col size-xs="12" size-xl="10" offset-xl="1" class="ion-no-padding"> -->
    <ion-col size-xs="12" [sizeXl]="sizeXL" [offsetXl]="offsetXL" class="ion-no-padding">
      <div class="line-chart-container ion-text-center">
        <canvas *ngIf="lineChartData" chartType="line" baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [legend]="isShowLegend">
        </canvas>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
