import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { isSelectionVisitorProfileAll } from '../helpers/util';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class StoreTrafficHeadcountNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const channel = (getAdditionalInput(additionalInput, 'channel') || 'auto') as 'entrance' | 'exit' | 'auto';
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        // await configDataService.loadAppConfig();

        const getChannelData = <T>(isEntranceDataMode: boolean, entranceData: T, exitData: T): T => {
            if (channel === 'auto') {
                return isEntranceDataMode ? entranceData : exitData;
            }
            return channel === 'entrance' ? entranceData : exitData;
        };

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const numberData$ = new BehaviorSubject('0');
        const label$ = new BehaviorSubject('0 % (0)');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        const isLock$ = new BehaviorSubject(this.isLock);

        subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.baseGraphData.selectedInteractable$, graphDataService.currentStoreVisitorTrafficTrendtEntranceData$,
            graphDataService.currentStoreVisitorTrafficTrendtExitData$, graphDataService.currentStoreEntranceData$, graphDataService.currentStoreExitData$]).subscribe(
            ([isEntranceDataMode, selectedInteractable, currentStoreVisitorTrafficTrendtEntranceData, currentStoreVisitorTrafficTrendtExitData, currentStoreEntranceData, currentStoreExitData]) => {
                let storeOfTokyuEntranceData: { headCount: number; diff: number; diffPercent: number };
                let storeOfTokyuExitData: { headCount: number; diff: number; diffPercent: number };
                if (configDataService.currentOrganization === 'MBK') {
                    if (selectedInteractable?.name === 'S_0_341') {
                        storeOfTokyuEntranceData = currentStoreEntranceData?.tokyu?.tokyu_000_g?.tokyu_000_g_ga?.tokyu_000_g_ga_s101;
                        storeOfTokyuExitData = currentStoreExitData?.tokyu?.tokyu_000_g?.tokyu_000_g_ga?.tokyu_000_g_ga_s101;
                    }
                    else if (selectedInteractable?.name === 'S_1_271') {
                        storeOfTokyuEntranceData = currentStoreEntranceData?.tokyu?.tokyu_001_2nd?.tokyu_001_2nd_2a?.tokyu_001_2nd_2a_s201;
                        storeOfTokyuExitData = currentStoreExitData?.tokyu?.tokyu_001_2nd.tokyu_001_2nd_2a?.tokyu_001_2nd_2a_s201;
                    }
                    else if (selectedInteractable?.name === 'S_2_590') {
                        storeOfTokyuEntranceData = currentStoreEntranceData?.tokyu?.tokyu_002_3rd?.tokyu_002_3rd_3a?.tokyu_002_3rd_3a_s301;
                        storeOfTokyuExitData = currentStoreExitData?.tokyu.tokyu_002_3rd?.tokyu_002_3rd_3a?.tokyu_002_3rd_3a_s301;
                    } 
                    else if (selectedInteractable?.name === 'S_3_606') {
                        storeOfTokyuEntranceData = currentStoreEntranceData?.tokyu?.tokyu_003_4th?.tokyu_003_4th_4a?.tokyu_003_4th_4a_s401;
                        storeOfTokyuExitData = currentStoreExitData?.tokyu?.tokyu_003_4th?.tokyu_003_4th_4a?.tokyu_003_4th_4a_s401;
                    }
                }
                const headCountData = storeOfTokyuEntranceData && storeOfTokyuExitData ? getChannelData(isEntranceDataMode, storeOfTokyuEntranceData, storeOfTokyuExitData) : getChannelData(isEntranceDataMode, currentStoreVisitorTrafficTrendtEntranceData, currentStoreVisitorTrafficTrendtExitData);
                numberData$.next(headCountData.headCount?.toLocaleString());
                label$.next(`${headCountData.diffPercent?.toFixed(1)} % (${headCountData.diff?.toLocaleString()})`);
                labelIcon$.next(headCountData.diff > 0 ? 'trending-up' : 'trending-down');
                labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: headCountData.diff >= 0 ? '#5BBF93' : '#E25144' });
                isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(graphDataService.selectedStoreVisitorProfile$.value)));
            }
        ));

        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.STORE_TRAFFIC_VOLUME;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;
        // comInstance.sizeXS = '12';
        // comInstance.sizeMD = '10';
        // comInstance.offsetMD = '1';
        return comInstance;
    }

}
