import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartPluginsOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-generic-custom-line-chart',
  templateUrl: './generic-custom-line-chart.component.html',
  styleUrls: ['./generic-custom-line-chart.component.scss'],
})
export class GenericCustomLineChartComponent implements OnInit, OnDestroy {

  @Input() lineChartLabels$: Observable<Label[]>;
  @Input() isHorizontal = false;
  @Input() data$: Observable<ChartDataSets[]>;
  @Input() chartOptions$: Observable<ChartOptions>;
  @Input() isShowLegend = false;

  private subscription = new Subscription();
  lineChartPlugins: ChartPluginsOptions = [ChartDataLabels];
  lineChartDatas: ChartDataSets[] = [{ data: [null] }];
  lineChartOptions: ChartOptions;
  lineChartLabels: Label[];

  constructor() { }

  ngOnInit() {
    this.subscription.add(this.data$.subscribe(lineChartDataSets => {
      if (!lineChartDataSets) { return; }
      const chartData: ChartDataSets[] = [];
      lineChartDataSets.forEach(lineChartData => {
        chartData.push(lineChartData);
      });
      this.lineChartDatas = chartData.length > 0 ? chartData : [{ data: [null] }];
    }));
    this.subscription.add(this.chartOptions$.subscribe(lineChartOptions => {
      if (!lineChartOptions) { return; }
      this.lineChartOptions = lineChartOptions;
    }));
    this.subscription.add(this.lineChartLabels$.subscribe(lineChartLabels => {
      if (!lineChartLabels) { return; }
      this.lineChartLabels = lineChartLabels;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
