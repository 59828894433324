import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR, CHART_TERTIARY_COLOR, CHART_QUATERNARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { isScreenSmallerThanMD } from '../helpers/util';

const COLOR_LIST = [
    CHART_PRIMARY_COLOR,
    CHART_SECONDARY_COLOR,
    CHART_TERTIARY_COLOR,
    CHART_QUATERNARY_COLOR
];

export class NonAsianEthnicityProfileResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        await configDataService.loadAppConfig();
        const initialEthnicityData = graphDataService.ethnicityProfileData$.value;
        const initialEthnicityClass = configDataService.ETHNICITY_CLASS.filter(ethnicity => ethnicity !== 'asian');
        const chartLabel: string[] = initialEthnicityClass.map(ethnicity => configDataService.DISPLAY_LANGUAGE.ETHNICITY_CLASS[ethnicity]);
        const barData$ = new BehaviorSubject<GenericBarChartData[]>(initialEthnicityClass.map((ethnicity, lebelIndex) => ({
            data: initialEthnicityClass.map((ethnicityIt, eId) => ethnicity === ethnicityIt ? initialEthnicityData.male[eId] + initialEthnicityData.female[eId] : null),
            color: COLOR_LIST[lebelIndex],
            label: configDataService.DISPLAY_LANGUAGE.TRAFFIC,
        })));

        // {
        //     data: initialEthnicityClass.map((_ethnicity, eId) => initialEthnicityData.male[eId] + initialEthnicityData.female[eId]),
        //     color: CHART_PRIMARY_COLOR,
        //     label: configDataService.DISPLAY_LANGUAGE.ETHNICITY_CLASS[],
        // }
        subscription.add(graphDataService.ethnicityProfileData$.subscribe((ethnicityProfileData) => {
            const ethnicityClass = configDataService.ETHNICITY_CLASS.filter(ethnicity => ethnicity !== 'asian');
            barData$.next(ethnicityClass.map((ethnicity, lebelIndex) => ({
                data: ethnicityClass.map((ethnicityIt, eId) => ethnicity === ethnicityIt ? ethnicityProfileData.male[eId] + ethnicityProfileData.female[eId] : null),
                color: COLOR_LIST[lebelIndex],
                label: configDataService.DISPLAY_LANGUAGE.TRAFFIC,
            })));
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NON_ASIAN_ETHNICITY_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = false;
        comInstance.data$ = barData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.isStacked = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.4;
        return comInstance;
    }

}
