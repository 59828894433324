import { Component, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-customer-messenger-tooltips',
  templateUrl: './customer-messenger-tooltips.component.html',
  styleUrls: ['./customer-messenger-tooltips.component.scss'],
})
export class CustomerMessengerTooltipsComponent implements OnInit {
  
  toolTipTitle: string;
  toolTipDetails: string[];

  constructor(
    public configDataService: ConfigDataService
  ) { }

  ngOnInit() { }

  getImageSrc(segment, idx) {
    return this.configDataService.messengerImageSrc[segment][idx];
  }

  get exampleMessgenerClass() {
    return this.configDataService.MESSENGER_CLASS.slice(1, 7);
  }

}
