import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';

const CHART_COLOR = '#4A6FDD';

export class FloorTrafficTrendResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency); // ENTRANCE_EXIT, ENTRANCE_EXIT_FLOOR, ENTRANCE_EXIT_ZONE
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const zoneName = graphDataService.baseGraphData.selectedDirectory$.value?.zone || (getAdditionalInput(additionalInput, 'zone')) as string;
        const initialfloorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const initialIsEntrance = configDataService.isEntranceDataMode;
        const getEntraceExitData = (isEntrance: boolean, data: { entrance: number[]; exit: number[] }) => isEntrance ? data.entrance : data.exit;
        const fillData: { entrance: number[]; exit: number[] } = { entrance: Array.from({ length: 7 }).map(() => null), exit: Array.from({ length: 7 }).map(() => null) };

        type EntraceExitData = { entrance: number[]; exit: number[] };
        const getBuildingFloorData = (
            data: { [buildingName: string]: EntraceExitData | { [floorName: string]: EntraceExitData } | { [floorName: string]: { [zoneName: string]: EntraceExitData } } },
            floorName?: string,
            depthZoneName?: string,
        ) => accessDepthData<EntraceExitData>(data, buildingName, floorName, depthZoneName, fillData);

        const initialAllData = getEntraceExitData(initialIsEntrance, getBuildingFloorData(graphDataService.buildingEntranceExitData$.value));
        const initialEntranceExitFloorData = getEntraceExitData(initialIsEntrance, getBuildingFloorData(graphDataService.entranceExitFloorData$.value, initialfloorName));
        const initialZoneData = getEntraceExitData(initialIsEntrance, getBuildingFloorData(graphDataService.zoneEntranceExitFloorData$.value, initialfloorName, zoneName));

        const initialPredictAllData = getEntraceExitData(initialIsEntrance, getBuildingFloorData(graphDataService.buildingEntranceExitData$.value));
        const initialPredictEntranceExitFloorData = getEntraceExitData(initialIsEntrance, getBuildingFloorData(graphDataService.entranceExitFloorData$.value, initialfloorName));
        const initialPredictZoneData = getEntraceExitData(initialIsEntrance, getBuildingFloorData(graphDataService.zoneEntranceExitFloorData$.value, initialfloorName, zoneName));


        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: initialIsAll ? initialAllData : (!zoneName ? initialEntranceExitFloorData : initialZoneData),
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            prediction: initialIsAll ? initialPredictAllData : (!zoneName ? initialPredictEntranceExitFloorData : initialPredictZoneData),
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST,
            label: 'Traffic',
        }]);

        subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedDirectory$, combineLatest([graphDataService.buildingEntranceExitData$, graphDataService.entranceExitFloorData$, graphDataService.zoneEntranceExitFloorData$])
        , combineLatest([graphDataService.predictedBuildingTrafficNumber$, graphDataService.predictedFloorTrafficNumber$, graphDataService.predictedZoneTrafficNumber$])]).subscribe(
            ([isEntranceDataMode, selectedFloorName, selectedDirectory, [buildingEntranceExitData, entranceExitFloorData, entranceExitZoneData], [predictEntranceExitData, predictFloorEntranceExitData, predictZoneEntranceExitData]]) => {
                if (!buildingEntranceExitData || !entranceExitFloorData) { return; }
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const floorName = selectedDirectory?.floor || selectedFloorName || 'ALL';
                const nextZoneName = selectedDirectory?.zone || zoneName;
                const isAll = floorName === 'ALL';
                const diffDate = viewPeriodService.selectedDate.diff(todayDate, viewPeriodService.viewPeriod.toMomentCompareString());
                const predData = isAll ? getEntraceExitData(isEntranceDataMode, getBuildingFloorData(predictEntranceExitData))
                : (!nextZoneName ? getEntraceExitData(isEntranceDataMode, getBuildingFloorData(predictFloorEntranceExitData, floorName)) : getEntraceExitData(isEntranceDataMode, getBuildingFloorData(predictZoneEntranceExitData, floorName, nextZoneName)));
                const lineChartData: GenericLineChartData[] = [{
                    points: isAll ? getEntraceExitData(isEntranceDataMode, getBuildingFloorData(buildingEntranceExitData))
                        : (!nextZoneName ? getEntraceExitData(isEntranceDataMode, getBuildingFloorData(entranceExitFloorData, floorName)) : getEntraceExitData(isEntranceDataMode, getBuildingFloorData(entranceExitZoneData, floorName, nextZoneName))),
                    backgroundColor: `${CHART_COLOR}22`,
                    prediction: (!(predData) || diffDate < -1) ? null : ((diffDate > -1) ? predData : [predData[predData.length - 1]]), 
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `Traffic,${time}`),
                    label: 'Traffic',
                }];
                chartData$.next(lineChartData);
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.TRAFFIC_TREND;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        // comInstance.sizeXS = '12';
        // comInstance.sizeMD = '10';
        // comInstance.offsetMD = '1';
        return comInstance;
    }

}
