import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChildren } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isScreenSmallerThanMD } from 'src/app/helpers/util';
import { BaseDataDependency } from 'src/app/objects/baseGraphData';
import { GraphDataConfig } from 'src/app/objects/config';
import { SelectableDataName } from 'src/app/objects/selectableData';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { GraphDataService } from 'src/app/services/graph-data-service.service';
import { ViewPeriodService } from 'src/app/services/view-period.service';

@Component({
  selector: 'app-dynamic-number-card',
  templateUrl: './dynamic-dual-numbercard.component.html',
  styleUrls: ['./dynamic-dual-numbercard.component.scss'],
})
export class DynamicDualNumberCardComponent implements OnInit, OnChanges {
  @Input() selectedNumberCard$: BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>;
  @Input() selector: { [selectorName: string]: GraphDataConfig } = {};
  @Input() overrideShouldShowSelector: boolean;
  @Input() showAveragePerDay = false;
  @Input() cards: {
    isShow?: boolean | BehaviorSubject<boolean>;
    isLock: boolean | BehaviorSubject<boolean>;
    title: string | BehaviorSubject<string>;
    numberData$: BehaviorSubject<string>;
    label$?: BehaviorSubject<string>;
    labelStyle$?: BehaviorSubject<{ fontSize: string; fontWeight: string; color: string }>;
    labelIcon$?: BehaviorSubject<string>;
    iconStyle?: string;
    sizeXS?: string;
    sizeMD?: string;
    offsetMD?: string;
    headerStyle?: string;
    isFirst?: boolean;
    sizeLG?: string;
    height?: {min?: number; max?: number};
    infoPopover?: (e: any) => Promise<any>;
    inputLockText?: BehaviorSubject<string>;
    isShowInfoIcon?: boolean;
    labelHeaderDiff$?: BehaviorSubject<string>;
  }[];

  showAverage = false;
  uniqueGraphIds = new Set<BaseDataDependency>();
  selectedName: string[] = [];
  selectorName: string[] = [];
  shouldShowSelector = false;
  title: string;
  // Initial grid template columns
  gridTemplateColumns = 'auto 1fr 1fr 1fr';

  constructor(
    private ref: ChangeDetectorRef,
    public configDataService: ConfigDataService,
    private graphDataService: GraphDataService,
    private viewPeriodService: ViewPeriodService,
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {
    this.update();
  }

  private update() {
    this.selectorName = Object.keys(this.selector);
    if (this.overrideShouldShowSelector !== undefined) {
      this.shouldShowSelector = this.overrideShouldShowSelector;
    } else {
      this.shouldShowSelector = this.selectorName && this.selectorName.length > 1;
    }
    this.selectedName = Object.entries(this.selector).filter(([_, selectorDetail]) => selectorDetail.selected).map(([name, _]) => name);
    this.parseGroupTitle(this.selectedName[0]);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.update();
  }

  updateGridLayout(isInfoIconVisible: boolean, isSelectorVisible: boolean) {
    if (!isInfoIconVisible && !isSelectorVisible) {
      this.gridTemplateColumns = '1fr';
    } else if (!isInfoIconVisible || !isSelectorVisible) {
      this.gridTemplateColumns = 'auto 1fr';
    } else {
      this.gridTemplateColumns = '1fr 1fr 2fr';
    }
    return this.gridTemplateColumns;
  }

  changeSelectedGraph(event: any) {
    const selectedName = event.detail.value as string[];
    this.selectedName = selectedName;
    const currentSelectedNumberCard = this.selectedNumberCard$.value;
    Object.keys(currentSelectedNumberCard).forEach(selectorName => {
      currentSelectedNumberCard[selectorName].selected = selectedName.includes(selectorName);
    });
    this.selectedNumberCard$.next(currentSelectedNumberCard);
    const [barKey, barDataConfig] = Object.entries(currentSelectedNumberCard).find(([barName, barData]) => barData.selected);
    this.parseGroupTitle(barKey);
  }

  getHeaderStyle(style: string){
    if (isScreenSmallerThanMD(window)) {
      return style;
    }
    return;
  }

  parseTitle(title: any) {
    let parseText: string;
    if (title instanceof BehaviorSubject) {
      title.subscribe(text => {
        parseText = this.overrideShouldShowSelector ? `${text} (${this.title})` : text;
      });
    } else {
      parseText = this.overrideShouldShowSelector ? `${title} (${this.title})` : title;
    }
    return parseText;
  }

  parseGroupTitle(title: string) {
    this.title = this.configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[title] || title;
  }

  getNumberCardSize(sizeXS: string, height?: any) {
    if(!height) {
      return;
    }
    if (Number(sizeXS) === 6) {
      if (this.showAverage) {
        return { 'min-height': `${height.max}px` || '200px', 'max-height': `${height.min}px` || '200px' };
      } else {
        return { 'min-height': `${height.max}px` || '155px', 'max-height': `${height.min}px` || '155px' };
      }
    } else if (height?.max && height.max) {
      return { 'min-height': `${height.max}px`, 'max-height': `${height.min}px` };
    }
    return;
  }

  isShowGraph(card) {
    let showGraph = false;
    if (card.isShow instanceof Observable) {
      card.isShow.subscribe(show => {
        showGraph = show;
      });
    } else {
      showGraph = card?.isShow;
    }
    return showGraph;
  }

  get isShowAveragePerDayButton() {
    return this.showAveragePerDay && (this.viewPeriodService.isWeekPeriod || this.viewPeriodService.isMonthPeriod);
  }

  toggleAveragePerDayFilter(): void {
    this.showAverage = !this.showAverage;
    const currentSelectedNumberCard = this.selectedNumberCard$.value;
    // Add logic to update the data based on the showAverage state.
    Object.keys(currentSelectedNumberCard).forEach(selectorName => {
      const graphId = currentSelectedNumberCard[selectorName].name as SelectableDataName;
      const graphData = this.graphDataService.baseGraphData.getSelectedGraph(graphId, this.graphDataService);
      this.uniqueGraphIds.add(graphData.dependencies as BaseDataDependency);
    });
    this.uniqueGraphIds.forEach(graphId => {
      const currentValue = this.graphDataService.baseGraphData.getIsDisplayAverageValueForGraph(graphId).value;
      this.graphDataService.baseGraphData.setIsDisplayAverageValueForGraph(graphId, !currentValue);
    });
  }

  get displayIconOnMiddle() {
    return isScreenSmallerThanMD(window);
  }

  get titleColSize() {
    if (this.shouldShowSelector) {
      return 5;
    } else {
      if (this.displayIconOnMiddle) {
        return 'auto'
      }
      return 9
    }
  }
}
