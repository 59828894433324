<ion-grid>
  <ion-row>
    <ion-col size-xs="12">
      <span style="color: var(--ion-color-primary); font-weight: 700; font-size: 18px;">{{ toolTipTitle }} Definition</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-text-left">
    <ion-col size="12" class="responsive-font-size ion-align-self-center">
      <div *ngFor="let toolTipDetail of toolTipDetails">
        <p>{{ toolTipDetail }}</p> 
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size-xs="12" style="margin-bottom: 10px;">
      <span style="color: white; font-weight: 700; font-size: 18px;">Purchase Image Examples</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-no-padding ion-align-items-center" *ngFor="let bag of BAG_LIST">
    <ion-col class="ion-no-padding ion-text-center" style="color: white; font-weight: 600; font-size: 14px;" size-xs="3">
      {{ bag }}
    </ion-col>
    <ion-col size-xs="3">
      <img [src]="getImageSrc(bag, 1)"/>
      <img [src]="getImageSrc(bag, 4)"/>
    </ion-col>
    <ion-col size-xs="3">
      <img [src]="getImageSrc(bag, 2)"/>
      <img [src]="getImageSrc(bag, 5)"/>
    </ion-col>
    <ion-col size-xs="3">
      <img [src]="getImageSrc(bag, 3)"/>
      <img [src]="getImageSrc(bag, 6)"/>
    </ion-col>
  </ion-row>
</ion-grid>