export interface Shelf {
  id: string;
  name: string;
  entrance_image: string;
  exit_image: string;
}

export enum ShelfType {
  OneSide = 'one-side',
  DoubleSide = 'double-side',
  OmniSide = 'omni-side'
}

export interface IShelfData { id: string; name: string; type: ShelfType; transFormationMatrix: [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number] }
