import { Component, OnInit, Input, OnDestroy ,ViewChild ,ElementRef} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GenericPieChartData } from 'src/app/objects/chart';
import { Label, SingleDataSet, Color } from 'ng2-charts';
import { ChartOptions, ChartTooltipModel } from 'chart.js';
import { hoverTooltipPieChartOptions } from 'src/app/helpers/chartJsHelper';
import { DARK_THEME_BASE_COLOR } from 'src/app/configs/color';

@Component({
  selector: 'app-generic-pie-chart',
  templateUrl: './generic-pie-chart.component.html',
  styleUrls: ['./generic-pie-chart.component.scss'],
})
export class GenericPieChartComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<GenericPieChartData[]>;
  @Input() isShowLegend: boolean;
  @Input() customToolTipFuction: (tooltipModel: ChartTooltipModel) => void;
  @Input() isOverallPage: boolean;
  @ViewChild('appGenericPieChart', { static: true }) appGenericPieChart: ElementRef;

  chartData: SingleDataSet;
  chartLabel: Label[];
  chartColor: Color[];
  chartOptions: ChartOptions = hoverTooltipPieChartOptions;
  subscription = new Subscription();

  constructor() { }

  ngOnInit() {
    if (this.customToolTipFuction) {
      this.chartOptions.tooltips.custom = this.customToolTipFuction;
      this.chartOptions.tooltips.enabled = false;
    }

    this.subscription.add(this.data$.subscribe(pieChartDataSets => {
      const chartData: SingleDataSet = [];
      const chartLabel: Label[] = [];
      const chartColor: Color[] = [{ backgroundColor: [], borderColor: [] }];
      pieChartDataSets.slice().forEach(pieChartData => {
        chartData.push(pieChartData.data);
        (chartColor[0].backgroundColor as string[]).push(pieChartData.color);
        (chartColor[0].borderColor as string[]).push(DARK_THEME_BASE_COLOR);
        chartLabel.push(pieChartData.label || null);
      });
      this.chartData = chartData;
      this.chartLabel = chartLabel;
      this.chartColor = chartColor;
    }));
    const width = this.appGenericPieChart.nativeElement.offsetWidth;
    if(this.isOverallPage){
      if(400 > width && width> 250) {
        this.chartOptions.maintainAspectRatio = false,
        this.chartOptions.aspectRatio = 1.5
      }else{
        this.chartOptions.maintainAspectRatio = false,
        this.chartOptions.aspectRatio = 0.75
      }
    }else{
      if(width> 250) {
        this.chartOptions.maintainAspectRatio = false,
        this.chartOptions.aspectRatio = 0.75
      } else if ( 250 > width && width > 100){
        this.chartOptions.maintainAspectRatio = false,
        this.chartOptions.aspectRatio = 0.75
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
