import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { IonicConfig } from '@ionic/core';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { firebaseConfig, APP_VERSION as app_version } from './configs/api';
import { Market } from '@ionic-native/market/ngx';
import { SharedModule } from './shared-components/shared.module';

const getConfig = () => ({
  mode: 'md' as ('md' | 'ios'),
} as IonicConfig);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(getConfig()),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    SharedModule,
  ],
  providers: [
    ScreenOrientation,
    PerformanceMonitoringService,
    ScreenTrackingService,
    UserTrackingService,
    { provide: PERSISTENCE, useValue: 'local' },
    {
      provide: APP_VERSION,
      useValue: app_version,
    },
    {
      provide: APP_NAME,
      useValue: 'DeepVision',
    },
    FirebaseX,
    Market,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
