import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { isSelectionVisitorProfileAll } from '../helpers/util';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

const CHART_COLOR = '#4A6FDD';

export class StoreVisitorTrafficTrendResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const channel = (getAdditionalInput(additionalInput, 'channel') || 'auto') as 'entrance' | 'exit' | 'auto';
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();

        const getChannelData = <T>(isEntranceDataMode: boolean, entranceData: T, exitData: T): T => {
            if (channel === 'auto') {
                return isEntranceDataMode ? entranceData : exitData;
            }
            return channel === 'entrance' ? entranceData : exitData;
        };

        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: getChannelData(configDataService.isEntranceDataMode, graphDataService.storeVisitorTrafficTrendData$.value?.entrance, graphDataService.storeVisitorTrafficTrendData$.value?.exit),
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST.map(time => `Traffic,${time}`),
        }]);
        const isLock$ = new BehaviorSubject(this.isLock);

        subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.storeVisitorTrafficTrendData$, graphDataService.storeEntranceExitData$, graphDataService.baseGraphData.selectedInteractable$])
        .subscribe(([isEntranceDataMode, storeVisitorTrafficTrendData, storeEntranceExitData, selectedInteractable]) => {
            if (!selectedInteractable || !storeEntranceExitData) { return; }
            let storeOfTokyuData: { entrance: number[]; exit: number[] } = { entrance: [], exit: [] };
            if (configDataService.currentOrganization === 'MBK') {
                if (selectedInteractable.name === 'S_0_341') {
                    storeOfTokyuData = storeEntranceExitData?.tokyu?.tokyu_000_g?.tokyu_000_g_ga?.tokyu_000_g_ga_s101;
                }
                else if (selectedInteractable.name === 'S_1_271') {
                    storeOfTokyuData = storeEntranceExitData.tokyu.tokyu_001_2nd.tokyu_001_2nd_2a.tokyu_001_2nd_2a_s201;
                }
                else if (selectedInteractable.name === 'S_2_590') {
                    storeOfTokyuData = storeEntranceExitData.tokyu.tokyu_002_3rd.tokyu_002_3rd_3a.tokyu_002_3rd_3a_s301;
                } 
                else if (selectedInteractable.name === 'S_3_606') {
                    storeOfTokyuData = storeEntranceExitData.tokyu.tokyu_003_4th.tokyu_003_4th_4a.tokyu_003_4th_4a_s401;
                }
            }
            const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
            const lineChartData: GenericLineChartData[] = [{
                points: storeOfTokyuData.entrance.length > 0 && storeOfTokyuData.exit.length > 0 ? getChannelData(isEntranceDataMode, storeOfTokyuData.entrance, storeOfTokyuData.exit) :
                getChannelData(isEntranceDataMode, storeVisitorTrafficTrendData.entrance, storeVisitorTrafficTrendData.exit),
                backgroundColor: `${CHART_COLOR}22`,
                color: CHART_COLOR,
                isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                toolTipLabel: viewPeriodService.DAY_LIST.map(time => `Traffic,${time}`),
                label: configDataService.DISPLAY_LANGUAGE.TRAFFIC,
            }];
            chartData$.next(lineChartData);
            isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(graphDataService.selectedStoreVisitorProfile$.value)));
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.STORE_TRAFFIC_TREND;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        // comInstance.sizeXS = '12';
        // comInstance.sizeMD = '10';
        // comInstance.offsetMD = '1';
        return comInstance;
    }

}
