import { Injectable } from '@angular/core';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import { PushNotifications, Token } from '@capacitor/push-notifications';

import { AuthenticationService } from './authentication.service';

import { BACKENDV2_URL } from '../configs/api';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { combineLatest, lastValueFrom, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    public firebaseNative: FirebaseX,
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) { }

  firebaseUserSubscription: Subscription;

  // Get permission from the user
  async getToken() {
    let deviceToken: string;

    if (this.platform.is('android') || this.platform.is('ios')) {

      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
          console.log('Push Notification Permission denied');
        }
      });

      PushNotifications.addListener('registration', (token: Token) => {
        deviceToken = token.value;
      },
      );

    }
    if (this.firebaseUserSubscription) {
      this.firebaseUserSubscription.unsubscribe();
    }
    this.firebaseUserSubscription = new Subscription();
    this.firebaseUserSubscription.add(this.authenticationService.user$.subscribe(user => {
      if (!user) { return; }
      this.saveTokenToFirestore(deviceToken);
    }));
  }

  // Save the token to firestore
  private async saveTokenToFirestore(deviceToken) {
    if (!deviceToken) { return; }
    const requestURL = `${BACKENDV2_URL}/api/v2/push/device-register`;
    const idToken = this.authenticationService.getIdToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const deviceData = {
      idToken,
      deviceToken,
      platform: this.platform.platforms()
    };
    return lastValueFrom(this.http.put(requestURL, deviceData, httpOptions));
  }

  // Listen to incoming FCM messages
  listenToNotifications() {
    return this.firebaseNative.onMessageReceived();
  }

  listenToDeviceTokenChanged() {
    combineLatest([this.firebaseNative.onTokenRefresh(), this.authenticationService.user$]).subscribe(([token, user]) => {
      if (!token || !user) { return; }
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.saveTokenToFirestore(token);
      }
    });
  }
}
