import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class AverageCongestionNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        await configDataService.loadAppConfig();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const numberData$ = new BehaviorSubject('0 %');
        const label$ = new BehaviorSubject(configDataService.DISPLAY_LANGUAGE.EXTRA_TRAVEL_TIME);
        const labelStyle$ = new BehaviorSubject({ fontSize: '18px', fontWeight: '500', color: undefined });

        subscription.add(graphDataService.trafficCongestionData$.subscribe((trafficData) => {
            if (!trafficData) { return; }
            numberData$.next(`${trafficData.averageExtraTravelTime.toFixed(1)} %`);
        }));

        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.AVERAGE_CONGESTION;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelStyle$ = labelStyle$;
        return comInstance;
    }

}
