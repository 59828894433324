let ageTooltipTimeout;

export const showAgeCustomTooltips = (tooltipModel, thisChart, imageList) => {
  window.clearTimeout(ageTooltipTimeout);

  // Tooltip Element
  let tooltipEl = document.getElementById('age-chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'age-chartjs-tooltip';
    tooltipEl.innerHTML =
      '<table style="margin-left: 10px; margin-top: 5px;"></table><div></div>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    window.clearTimeout(ageTooltipTimeout);
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  const chartDatasets = thisChart._data.datasets;
  const chartLabels = thisChart._data.labels;
  let activeAgeClass;
  let activeGenderLabel;
  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.dataPoints.map(datapoint => {
      const rawValue = chartDatasets[datapoint.datasetIndex].data[
        datapoint.index
      ].toLocaleString();
      const genderLabel = chartDatasets[datapoint.datasetIndex].label;
      const ageLabel = chartLabels[datapoint.index];
      activeAgeClass = ageLabel;
      activeGenderLabel = genderLabel;
      return `${genderLabel}: ${rawValue}`;
    });

    let innerHtml = '<thead>';

    titleLines.forEach(function(title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function(body, i) {
      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      style += '; display: inline-block';
      style += '; width: 15px';
      style += '; height: 15px';
      style += '; margin-right: 5px';
      style += '; vertical-align: middle';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td>' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const imageRoot = tooltipEl.querySelector('div');
  const ageImageSrc_1 = imageList[activeGenderLabel][activeAgeClass][0];
  const ageImageSrc_2 = imageList[activeGenderLabel][activeAgeClass][1];
  const ageImageSrc_3 = imageList[activeGenderLabel][activeAgeClass][2];
  const ageImageSrc_4 = imageList[activeGenderLabel][activeAgeClass][3];
  const ageImageSrc_5 = imageList[activeGenderLabel][activeAgeClass][4];
  const ageImageSrc_6 = imageList[activeGenderLabel][activeAgeClass][5];

  imageRoot.innerHTML =
    `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${ageImageSrc_1}"></img>` +
    `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${ageImageSrc_2}"></img>` +
    `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${ageImageSrc_3}"></img>` +
    `<br/>` +
    `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${ageImageSrc_4}"></img>` +
    `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${ageImageSrc_5}"></img>` +
    `<img style="width: 50px; height: 50px; margin: 5px 5px;" src="${ageImageSrc_6}"></img>`;

  // `this` will be the overall tooltip
  const position = thisChart._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  // tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX ) + 'px';
  // tooltipEl.style.top = (position.top + window.pageYOffset + tooltipModel.caretY - 100) + 'px';
  tooltipEl.style.left = position.left + 100 + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding =
    tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.backgroundColor = 'rgba(0,0,0, 0.8)';
  tooltipEl.style.color = 'white';
  tooltipEl.style.borderRadius = '5px';

  ageTooltipTimeout = setTimeout(() => {
    tooltipEl.style.opacity = '0';
  }, 2500);
};
