import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable } from 'rxjs';
import { isScreenSmallerThanLG } from 'src/app/helpers/util';
import { GenericSankeyChartData } from 'src/app/objects/chart';
import { SankeyRawLinks } from 'src/app/objects/fetchObejct';

@Component({
  selector: 'app-generic-sankey-chart',
  templateUrl: './generic-sankey-chart.component.html',
  styleUrls: ['./generic-sankey-chart.component.scss'],
})
export class GenericSankeyChartComponent implements OnInit {

  @Input() chartData$: Observable<GenericSankeyChartData>;
  @Input() sankeyRawData$: Observable<SankeyRawLinks[]>;
  @Input() sankeyDisplayMode: 'LEGACY' | 'DYNAMIC' = 'LEGACY';
  @Input() filterFirstLevel: boolean;
  @ViewChild('SankeyChart') sankeyChart: ElementRef<HTMLDivElement>;

  sampleData = {
    nodes: [
      { id: 'enc', name: 'Entrance', depth: 0 },
      { id: 'z_a_1', name: 'Zone A', depth: 1 },
      { id: 'z_b_1', name: 'Zone B', depth: 1 },
      { id: 'z_c_1', name: 'Zone C', depth: 1 },
      { id: 'z_d_1', name: 'Zone D', depth: 1 },
      { id: 'z_e_1', name: 'Zone E', depth: 1 },
      { id: 'z_a_2', name: 'Zone A', depth: 2 },
      { id: 'z_b_2', name: 'Zone B', depth: 2 },
      { id: 'z_c_2', name: 'Zone C', depth: 2 },
      { id: 'z_d_2', name: 'Zone D', depth: 2 },
      { id: 'z_e_2', name: 'Zone E', depth: 2 },
      { id: 'z_a_3', name: 'Zone A', depth: 3 },
      { id: 'z_b_3', name: 'Zone B', depth: 3 },
      { id: 'z_c_3', name: 'Zone C', depth: 3 },
      { id: 'z_d_3', name: 'Zone D', depth: 3 },
      { id: 'z_e_3', name: 'Zone E', depth: 3 },
      { id: 'z_a_4', name: 'Zone A', depth: 4 },
      { id: 'z_b_4', name: 'Zone B', depth: 4 },
      { id: 'z_c_4', name: 'Zone C', depth: 4 },
      { id: 'z_d_4', name: 'Zone D', depth: 4 },
      { id: 'z_e_4', name: 'Zone E', depth: 4 },
      { id: 'exit', name: 'Exit', depth: 5 },
    ],
    links: [
      // depth = 0
      { name: 'Entrance to Zone A', source: 'enc', target: 'z_a_1', value: 30 },
      { name: 'Entrance to Zone B', source: 'enc', target: 'z_b_1', value: 20 },
      { name: 'Entrance to Zone C', source: 'enc', target: 'z_c_1', value: 40 },
      { name: 'Entrance to Zone D', source: 'enc', target: 'z_d_1', value: 5 },
      { name: 'Entrance to Zone E', source: 'enc', target: 'z_e_1', value: 5 },
      // depth = 1, zone a
      { name: 'Zone A to Zone A', source: 'z_a_1', target: 'z_a_2', value: 0 },
      { name: 'Zone A to Zone B', source: 'z_a_1', target: 'z_b_2', value: 10 },
      { name: 'Zone A to Zone C', source: 'z_a_1', target: 'z_c_2', value: 15 },
      { name: 'Zone A to Zone D', source: 'z_a_1', target: 'z_d_2', value: 2 },
      { name: 'Zone A to Zone E', source: 'z_a_1', target: 'z_e_2', value: 3 },
      // depth = 1, zone b
      { name: 'Zone B to Zone A', source: 'z_b_1', target: 'z_a_2', value: 5 },
      { name: 'Zone B to Zone B', source: 'z_b_1', target: 'z_b_2', value: 0 },
      { name: 'Zone B to Zone C', source: 'z_b_1', target: 'z_c_2', value: 5 },
      { name: 'Zone B to Zone D', source: 'z_b_1', target: 'z_d_2', value: 5 },
      { name: 'Zone B to Zone E', source: 'z_b_1', target: 'z_e_2', value: 0 },
      { name: 'Zone B to Exit', source: 'z_b_1', target: 'exit', value: 5 },
      // depth = 1, zone c
      { name: 'Zone C to Zone A', source: 'z_c_1', target: 'z_a_2', value: 10 },
      { name: 'Zone C to Zone B', source: 'z_c_1', target: 'z_b_2', value: 15 },
      { name: 'Zone C to Zone C', source: 'z_c_1', target: 'z_c_2', value: 5 },
      { name: 'Zone C to Zone D', source: 'z_c_1', target: 'z_d_2', value: 2 },
      { name: 'Zone C to Zone E', source: 'z_c_1', target: 'z_e_2', value: 8 },
      // depth = 1, zone d
      { name: 'Zone D to Zone A', source: 'z_d_1', target: 'z_a_2', value: 0 },
      { name: 'Zone D to Zone B', source: 'z_d_1', target: 'z_b_2', value: 3 },
      { name: 'Zone D to Zone C', source: 'z_d_1', target: 'z_c_2', value: 4 },
      { name: 'Zone D to Zone D', source: 'z_d_1', target: 'z_d_2', value: 0 },
      { name: 'Zone D to Zone E', source: 'z_d_1', target: 'z_e_2', value: 1 },
      // depth = 1, zone e
      { name: 'Zone E to Zone A', source: 'z_e_1', target: 'z_a_2', value: 1 },
      { name: 'Zone E to Zone B', source: 'z_e_1', target: 'z_b_2', value: 2 },
      { name: 'Zone E to Zone C', source: 'z_e_1', target: 'z_c_2', value: 2 },
      { name: 'Zone E to Zone D', source: 'z_e_1', target: 'z_d_2', value: 0 },
      { name: 'Zone E to Zone E', source: 'z_e_1', target: 'z_e_2', value: 2 },

      // depth = 2, zone a
      { name: 'Zone A to Zone A', source: 'z_a_2', target: 'z_a_3', value: 3 },
      { name: 'Zone A to Zone B', source: 'z_a_2', target: 'z_b_3', value: 2 },
      { name: 'Zone A to Zone C', source: 'z_a_2', target: 'z_c_3', value: 10 },
      { name: 'Zone A to Zone D', source: 'z_a_2', target: 'z_d_3', value: 0 },
      { name: 'Zone A to Zone E', source: 'z_a_2', target: 'z_e_3', value: 0 },
      { name: 'Zone A to Exit', source: 'z_a_2', target: 'exit', value: 1 },
      // depth = 2, zone b
      { name: 'Zone B to Zone A', source: 'z_b_2', target: 'z_a_3', value: 3 },
      { name: 'Zone B to Zone B', source: 'z_b_2', target: 'z_b_3', value: 0 },
      { name: 'Zone B to Zone C', source: 'z_b_2', target: 'z_c_3', value: 2 },
      { name: 'Zone B to Zone D', source: 'z_b_2', target: 'z_d_3', value: 10 },
      { name: 'Zone B to Zone E', source: 'z_b_2', target: 'z_e_3', value: 5 },
      { name: 'Zone B to Exit', source: 'z_b_2', target: 'exit', value: 10 },
      // depth = 2, zone c
      { name: 'Zone C to Zone A', source: 'z_c_2', target: 'z_a_3', value: 10 },
      { name: 'Zone C to Zone B', source: 'z_c_2', target: 'z_b_3', value: 6 },
      { name: 'Zone C to Zone C', source: 'z_c_2', target: 'z_c_3', value: 0 },
      { name: 'Zone C to Zone D', source: 'z_c_2', target: 'z_d_3', value: 0 },
      { name: 'Zone C to Zone E', source: 'z_c_2', target: 'z_e_3', value: 5 },
      { name: 'Zone C to Exit', source: 'z_c_2', target: 'exit', value: 10 },
      // depth = 2, zone d
      { name: 'Zone D to Zone A', source: 'z_d_2', target: 'z_a_3', value: 0 },
      { name: 'Zone D to Zone B', source: 'z_d_2', target: 'z_b_3', value: 3 },
      { name: 'Zone D to Zone C', source: 'z_d_2', target: 'z_c_3', value: 3 },
      { name: 'Zone D to Zone D', source: 'z_d_2', target: 'z_d_3', value: 0 },
      { name: 'Zone D to Zone E', source: 'z_d_2', target: 'z_e_3', value: 0 },
      { name: 'Zone D to Exit', source: 'z_d_2', target: 'exit', value: 3 },
      // depth = 2, zone e
      { name: 'Zone E to Zone A', source: 'z_e_2', target: 'z_a_3', value: 1 },
      { name: 'Zone E to Zone B', source: 'z_e_2', target: 'z_b_3', value: 2 },
      { name: 'Zone E to Zone C', source: 'z_e_2', target: 'z_c_3', value: 5 },
      { name: 'Zone E to Zone D', source: 'z_e_2', target: 'z_d_3', value: 0 },
      { name: 'Zone E to Zone E', source: 'z_e_2', target: 'z_e_3', value: 0 },
      { name: 'Zone E to Exit', source: 'z_e_2', target: 'exit', value: 4 },

      // depth = 3, zone a
      { name: 'Zone A to Zone A', source: 'z_a_3', target: 'z_a_4', value: 0 },
      { name: 'Zone A to Zone B', source: 'z_a_3', target: 'z_b_4', value: 0 },
      { name: 'Zone A to Zone C', source: 'z_a_3', target: 'z_c_4', value: 4 },
      { name: 'Zone A to Zone D', source: 'z_a_3', target: 'z_d_4', value: 3 },
      { name: 'Zone A to Zone E', source: 'z_a_3', target: 'z_e_4', value: 3 },
      { name: 'Zone A to Exit', source: 'z_a_3', target: 'exit', value: 10 },
      // depth = 3, zone b
      { name: 'Zone B to Zone A', source: 'z_b_3', target: 'z_a_4', value: 5 },
      { name: 'Zone B to Zone B', source: 'z_b_3', target: 'z_b_4', value: 0 },
      { name: 'Zone B to Zone C', source: 'z_b_3', target: 'z_c_4', value: 0 },
      { name: 'Zone B to Zone D', source: 'z_b_3', target: 'z_d_4', value: 5 },
      { name: 'Zone B to Zone E', source: 'z_b_3', target: 'z_e_4', value: 0 },
      { name: 'Zone B to Exit', source: 'z_b_3', target: 'exit', value: 3 },
      // depth = 3, zone c
      { name: 'Zone C to Zone A', source: 'z_c_3', target: 'z_a_4', value: 0 },
      { name: 'Zone C to Zone B', source: 'z_c_3', target: 'z_b_4', value: 5 },
      { name: 'Zone C to Zone C', source: 'z_c_3', target: 'z_c_4', value: 0 },
      { name: 'Zone C to Zone D', source: 'z_c_3', target: 'z_d_4', value: 0 },
      { name: 'Zone C to Zone E', source: 'z_c_3', target: 'z_e_4', value: 5 },
      { name: 'Zone C to Exit', source: 'z_c_3', target: 'exit', value: 10 },
      // depth = 3, zone d
      { name: 'Zone D to Zone A', source: 'z_d_3', target: 'z_a_4', value: 0 },
      { name: 'Zone D to Zone B', source: 'z_d_3', target: 'z_b_4', value: 0 },
      { name: 'Zone D to Zone C', source: 'z_d_3', target: 'z_c_4', value: 0 },
      { name: 'Zone D to Zone D', source: 'z_d_3', target: 'z_d_4', value: 0 },
      { name: 'Zone D to Zone E', source: 'z_d_3', target: 'z_e_4', value: 5 },
      { name: 'Zone D to Exit', source: 'z_d_3', target: 'exit', value: 5 },
      // depth = 3, zone e
      { name: 'Zone E to Zone A', source: 'z_e_3', target: 'z_a_4', value: 2 },
      { name: 'Zone E to Zone B', source: 'z_e_3', target: 'z_b_4', value: 3 },
      { name: 'Zone E to Zone C', source: 'z_e_3', target: 'z_c_4', value: 0 },
      { name: 'Zone E to Zone D', source: 'z_e_3', target: 'z_d_4', value: 0 },
      { name: 'Zone E to Zone E', source: 'z_e_3', target: 'z_e_4', value: 0 },
      { name: 'Zone E to Exit', source: 'z_e_3', target: 'exit', value: 5 },

      // depth = 4, to all exit
      { name: 'Zone A to Exit', source: 'z_a_4', target: 'exit', value: 10 },
      { name: 'Zone B to Exit', source: 'z_b_4', target: 'exit', value: 10 },
      { name: 'Zone C to Exit', source: 'z_c_4', target: 'exit', value: 10 },
      { name: 'Zone D to Exit', source: 'z_d_4', target: 'exit', value: 5 },
      { name: 'Zone E to Exit', source: 'z_e_4', target: 'exit', value: 5 },
    ]
  };

  flowData: { 
    nodes: Array<{ id: string; name: string; depth: number; value?: number }>; 
    links: Array<{ name: string; source: string; target: string; value: number }>; 
  } = {
    nodes: [],
    links: []
  };

  chartOption: EChartsOption = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      appendToBody: true,
      textStyle: {
        fontWeight: 'bold'
      },
      // ref: https://echarts.apache.org/en/option.html#series-sankey.tooltip.formatter
      formatter: (params: any, _ticket: string) => {
        const sumIncomeNode = this.chartData.data.links.filter((link) => link.source === params.data.source).reduce((acc, cur) => acc + cur.value, 0);
        const percentageVal = Math.round((params.value / (sumIncomeNode + 0.001)) * 100);
        return `${params.data.name}: ${percentageVal > 100 ? 100 : percentageVal < 1 ? '<1' : percentageVal}%`;
        // return `${params.data.name}: ${params.value}`;
      },
    },
    series: [
      {
        type: 'sankey',
        data: this.flowData.nodes,
        links: this.flowData.links,
        top: '1.5%',
        // top: '5%',
        left: '3%',
        // left: '12%',
        right: '3%',
        // right: isScreenSmallerThanLG(window) ? '15%' : '7%',
        bottom: '3%',
        draggable: false,
        nodeWidth: 20,
        // nodeWidth: 10,
        // orient: 'vertical',
        label: {
          position: 'right',
          fontWeight: 'bold',
          // position: 'left',
          // ref: https://echarts.apache.org/en/option.html#series-sankey.label
          // formatter: '{b}',
          // overflow: 'break'
          formatter: (params: any) => `${params.name}`,
          // color: 'black'
          // formatter: (params: any) => `${params.name}\n${params.value}%`
        },
        emphasis: {
          focus: 'adjacency'
        },
        //TODO: Dynamic level style from config
        // levels: [
        //   {
        //     depth: 0,
        //     itemStyle: {
        //       color: '#fbb4ae'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 1,
        //     itemStyle: {
        //       color: '#b3cde3'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 2,
        //     itemStyle: {
        //       color: '#ccebc5'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 3,
        //     itemStyle: {
        //       color: '#decbe4'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 4,
        //     itemStyle: {
        //       color: '#40E0D0'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 5,
        //     itemStyle: {
        //       color: '#FF0000'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 6,
        //     itemStyle: {
        //       color: '#FF8C00'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 7,
        //     itemStyle: {
        //       color: '#228B22'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 8,
        //     itemStyle: {
        //       color: '#008080'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 9,
        //     itemStyle: {
        //       color: '#F4A460'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 10,
        //     itemStyle: {
        //       color: '#DC143C'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 11,
        //     itemStyle: {
        //       color: '#DB7093'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 12,
        //     itemStyle: {
        //       color: '#9400D3'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        //   {
        //     depth: 13,
        //     itemStyle: {
        //       color: '#808000'
        //     },
        //     lineStyle: {
        //       color: 'source',
        //       opacity: 0.6
        //     }
        //   },
        // ],
        lineStyle: {
          curveness: 0.5
        },
      }
    ],
    backgroundColor: '#212121'
    // backgroundColor: '#fff'
  };
  startNode: { id: string; name: string; depth: number };
  endNode: { id: string; name: string; depth: number };

  sankeyRawData: SankeyRawLinks[];
  chartData: GenericSankeyChartData;
  tempChartData: GenericSankeyChartData;
  mergeOptions: EChartsOption = { };
  totalVal = 0;
  sumAllNode = 0;

  echartsInstance: any;

  constructor( ) { }

  ngOnInit() {
    this.chartData$.subscribe(chartData => {
      if(!chartData.data || !chartData.data.nodes || !chartData.data.links) { return; }
      this.chartData = chartData;
      this.tempChartData = chartData;
      this.sumAllNode = this.chartData.data.links.reduce((acc, obj) => obj.value + acc, 0);
      this.mergeOptions = {
        series: [{
          data: chartData.data.nodes,
          links: chartData.data.links,
        }],
        media: this.sankeyDisplayMode === 'LEGACY' ? [] : 
        [
          {
            query: {
              // when container width is greater than 600
              minWidth: 600
            },
            option: {
              series: [{
                top: '1.5%',
                bottom: '3%',
                left: '10%',
                orient: 'horizontal',
                nodeWidth: 20,
                label: {
                  // show: false,
                  position: 'left',
                },
                labelLayout: {
                  hideOverlap: true
                },
                lineStyle: {
                  curveness: 0.5
                },
                tooltip: {
                  // position: 'right',
                  position: 'top',
                  textStyle: {
                    fontSize: 14
                  }
                }
              }],
              tooltip: {
                appendToBody: true,
              }
            }
          },
          {
            query: {
              // when container width is smaller than 600
              maxWidth: 600
            },
            option: {
              series: [{
                top: '5%',
                bottom: '7%',
                // left: '5%',
                left: '1.5%',
                right: '9%',
                orient: 'vertical',
                nodeWidth: 10,
                label: {
                  // show: false,
                  position: 'top'
                },
                labelLayout: {
                  hideOverlap: true
                },
                lineStyle: {
                  curveness: 0.3
                },
                tooltip: {
                  position: isScreenSmallerThanLG(window) ? 'top' : 'left',
                  textStyle: {
                    fontSize: 12
                  }
                }
              }],
              tooltip: {
                appendToBody: true,
              }
            }
          }
        ],
      };
      if (this.sankeyDisplayMode === 'LEGACY') {
        if (chartData.data.links.length < 5) {
          this.sankeyChart.nativeElement.style.height = '20rem';
        }
        //TODO: Dynamic level style from config
        this.mergeOptions.series[0].levels = [
          {
            depth: 0,
            itemStyle: {
              color: '#fbb4ae'
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          },
          {
            depth: 1,
            itemStyle: {
              color: '#b3cde3'
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          },
          {
            depth: 2,
            itemStyle: {
              color: '#ccebc5'
            },
            label: {
              position: 'left'
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          }
        ];
      }
    });
    this.sankeyRawData$.subscribe(sankeyRawData => {
      if (!sankeyRawData) { return; }
      this.sankeyRawData = sankeyRawData;
      const startDepth = this.filterFirstLevel ? 1 : 0;
      this.sumAllNode = this.sankeyRawData.reduce((acc, obj) => obj.previous_node.depth === startDepth ? acc + obj.count : acc + 0, 0);
      // const maxDepth = this.sankeyRawData[0].previous_node.depth;
      // const initialNode = this.sankeyRawData.sort((a, b) => a.previous_node.depth - b.previous_node.depth);
      // let i = 0;
      // const arrAllPath = [];
      // while (i < maxDepth) {
      //   i++;
      // }
    });
  }

  getDepthPath(rawData: SankeyRawLinks[], currentNodeID: string, endNodeID?: string, arrPath: { [pathName: string]: any[] } = {}){
    const filteredData = rawData.filter(d => d.previous_node.id === currentNodeID);
    if (filteredData.length === 0) {
      return arrPath;
    }
    const nextNodeID = filteredData[0].current_node.id;
    if (nextNodeID === endNodeID) {
      return arrPath;
    }
    if (Object.keys(arrPath).length === 0) {
      arrPath[filteredData[0].previous_node.single_id] = new Array(filteredData[0]);
      return this.getDepthPath(rawData, nextNodeID, endNodeID, arrPath);
    }
    for (const [key, path] of Object.entries(arrPath)) {
      if (path.find(p => p.current_node.id === filteredData[0].previous_node.id)) {
        arrPath[key].push(filteredData[0]);
      } else {
        arrPath[filteredData[0].previous_node.single_id] = new Array(filteredData[0]);
      }
    }
    return this.getDepthPath(rawData, nextNodeID, endNodeID, arrPath);
  };

  sankeyTraversal() {

  }

  onChartEvent(event: any) {
    if (event.dataType === 'node') {
      if (this.startNode && this.endNode) {
        return;
      }
      else if (!this.startNode) {
        this.startNode = event.data;
      } else {
        this.endNode = event.data;
      }
    }
    // console.log(this.startNode, this.endNode);
    // if (!this.sankeyRawData || !this.chartData || event.dataType !== 'node') { return; }
    // const selectedNode = event.data;
    // const generatedPath = this.getDepthPath(this.sankeyRawData, selectedNode.id) as { [pathName: string]: any[] };
    // // console.log('chartData', this.chartData);
    // // console.log('path', generatedPath);
    // // const foundLinks: Array<{ name: string; source: string; target: string; value: number }> = [];
    // this.chartData.data.links.forEach((link, idx) => {
    //   Object.values(generatedPath)[0].forEach(path => {
    //     if (path.previous_node.id === link.source && path.current_node.id === link.target) {
          
    //     } else {

    //     }
    //   });
    // });
    // this.mergeOptions = {
    //   series: [{
    //     data: this.chartData.data.nodes,
    //     links: this.chartData.data.links,
    //   }],
    //   media: this.sankeyDisplayMode === 'LEGACY' ? [] : 
    //   [
    //     {
    //       query: {
    //         // when container width is greater than 600
    //         minWidth: 600
    //       },
    //       option: {
    //         series: [{
    //           top: '1.5%',
    //           bottom: '3%',
    //           left: '10%',
    //           orient: 'horizontal',
    //           nodeWidth: 20,
    //           label: {
    //             // show: false,
    //             position: 'left',
    //           },
    //           labelLayout: {
    //             hideOverlap: true
    //           },
    //           lineStyle: {
    //             curveness: 0.5
    //           },
    //           tooltip: {
    //             // position: 'right',
    //             position: 'top',
    //             textStyle: {
    //               fontSize: 14
    //             }
    //           }
    //         }],
    //         tooltip: {
    //           appendToBody: true,
    //         }
    //       }
    //     },
    //     {
    //       query: {
    //         // when container width is smaller than 600
    //         maxWidth: 600
    //       },
    //       option: {
    //         series: [{
    //           top: '5%',
    //           bottom: '7%',
    //           // left: '5%',
    //           left: '1.5%',
    //           right: '7%',
    //           orient: 'vertical',
    //           nodeWidth: 10,
    //           label: {
    //             // show: false,
    //             position: 'top'
    //           },
    //           labelLayout: {
    //             hideOverlap: true
    //           },
    //           lineStyle: {
    //             curveness: 0.3
    //           },
    //           tooltip: {
    //             position: 'bottom',
    //             textStyle: {
    //               fontSize: 12
    //             }
    //           }
    //         }],
    //         tooltip: {
    //           appendToBody: true,
    //         }
    //       }
    //     }
    //   ],
    // };
  }

  genPathfromNodeSelection() {
    console.log(this.echartsInstance);
    console.log(this.startNode, this.endNode);
    // console.log(this.chartData.data.links);
    let sumValue = 0;
    // const samplePath = ['robinson@3', 'main_atrium_g@2', 'robinson@1', 'seacon@0'];
    const samplePath = [{
      source: 'seacon@0',
      target: 'robinson@1_seacon@0'
    },
    {
      source: 'robinson@1_seacon@0',
      target: 'main_atrium_g@2_robinson@1_seacon@0'
    },
    {
      source: 'main_atrium_g@2_robinson@1_seacon@0',
      target: 'robinson@3_main_atrium_g@2_robinson@1_seacon@0'
    },
    {
      source: 'robinson@3_main_atrium_g@2_robinson@1_seacon@0',
      target: 'main_atrium_g@4_robinson@3_main_atrium_g@2_robinson@1_seacon@0'
    }];
    this.chartData.data.links.forEach(link => {
      if (samplePath.find(p => p.source === link.source) && samplePath.find(p => p.target === link.target)) {
        link.lineStyle = {
          color: '#a2a2a2',
          // opacity: 0.6
        };
        sumValue += link.value;
      }
    });
    this.chartData.data.nodes.forEach(node => {
      if (node.id === this.startNode.id || node.id === this.endNode.id) {
        node.label = {
          color: 'yellow'
        };
      }
      if (node.id === this.endNode.id) {
        node.name = `${node.name}\n${sumValue}`;
        node.tooltip = {
          formatter: (params: any) => `${this.endNode.name}: ${sumValue}`
        };
      }
    });
    // console.log(this.chartData);
    this.mergeOptions = {
      series: [{
        data: this.chartData.data.nodes,
        links: this.chartData.data.links,
      }],
      labelLayout: {
        hideOverlap: true
      },
    };
    this.totalVal = sumValue;
  }

  clearPathfromNodeSelection() {
    const tempStartNode = this.startNode;
    const tempEndNode = this.endNode;
    this.startNode = null;
    this.endNode = null;
    this.totalVal = 0;
    this.chartData.data.links.forEach(link => {
      if (link.target === 'exit') {
        link.lineStyle = {
          color: 'target',
          opacity: 0.4
        };
      } else {
        link.lineStyle = {
          color: 'source',
          opacity: 0.4
        };
      }
    });
    this.chartData.data.nodes.forEach(node => {
      node.label = {
        color: 'inherit'
      };
      node.name =  node.id === tempEndNode.id ? tempEndNode.name : node.id === tempStartNode.id ? tempStartNode.name : node.name;
      node.tooltip = {
        formatter: (params: any) => `${params.name}: ${params.value}%` 
      };
    });
    this.mergeOptions = {
      series: [{
        data: this.chartData.data.nodes,
        links: this.chartData.data.links,
      }],
      labelLayout: {
        hideOverlap: true
      },
    };
  }

}
