import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CustomerSegmentDetailsComponent } from '../pages/home/general/customer-segment-details/customer-segment-details.component';
import { showEthnicityCustomTooltips } from '../helpers/ethnicityTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { isScreenSmallerThanMD } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class EthnicityProfilePurchaseRateResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(_additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'ETHNICITY_PROFILE_PURCHASE_RATE', 'count', 3);
        // await configDataService.loadAppConfig();
        const ethnicityList = configDataService.DISPLAY_LANGUAGE.ETHNICITY_CLASS;
        const chartLabel = ['Local Adult Shoppers', 'Local Young Shoppers', 'Tourist'];
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);
        if (isMockData) {
            dualBarData$.next([{
                data: [...mockData.purchase_rate],
                color: CHART_PRIMARY_COLOR,
                label: '% Purchase Rate',
                calPercentFrommAllData: false
            }]);
            subscription.add((viewPeriodService.dayList).subscribe(dayList => {
                dualBarData$.next([{
                    data: [...mockData.purchase_rate],
                    // data: [1,2,3,4,5],
                    color: CHART_PRIMARY_COLOR,
                    // label: configDataService.ETHNICITY_CLASS[asian],
                    label: '% Purchase Rate',
                    calPercentFrommAllData: false
                }]);
            }));
        } else {
            dualBarData$.next([{
                data: [],
                color: CHART_PRIMARY_COLOR,
                label: '% Purchase Rate',
                calPercentFrommAllData: false
            }]);
            subscription.add(graphDataService.ethnicityCrossProfilelevelFourPurchaseRate$.subscribe(ethnicityRate => {
                dualBarData$.next([{
                    data: [...ethnicityRate],
                    // data: [1,2,3,4,5],
                    color: CHART_PRIMARY_COLOR,
                    // label: configDataService.ETHNICITY_CLASS[asian],
                    label: '% Purchase Rate',
                    calPercentFrommAllData: false
                }]);
            }));
        }

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = 'Ethnicity Purchase Rate';
        comInstance.isLock = this.isLock;
        // comInstance.infoPopover = async (e: any) => {
        //     const ethnicityDetailPopover = await popoverController.create({
        //         component: CustomerSegmentDetailsComponent,
        //         cssClass: 'customer-segment-details-popover',
        //         event: e
        //     });
        //     return ethnicityDetailPopover.present();
        // };
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.4;
        comInstance.valueTextProcess = 'percentFormatter';
        // comInstance.customToolTipFuction = function(tooltipModel) {
        //     showEthnicityCustomTooltips(tooltipModel, this, configDataService.ethnicityImageSrc, ethnicityList);
        // };
        return comInstance;
    }

}
