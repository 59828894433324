import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';

export class StoreFootTrafficResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const buildingNameInput = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const initialSelectedInteractable = graphDataService.baseGraphData.selectedInteractable$.value;
        const initialStoreName = initialSelectedInteractable?.type === 'store' ? (initialSelectedInteractable?.store?.store_area_name) : undefined;
        const initialFloorName = initialSelectedInteractable?.type === 'store' ? (initialSelectedInteractable?.store?.floor_name) : undefined;
        const initialZoneName = initialSelectedInteractable?.type === 'store' ? (initialSelectedInteractable?.store?.zone_name) : undefined;

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;

        const getTrafficData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [zoneName: string]: number[] } } | { [floorName: string]: { [zoneName: string]: { [storeName: string]: number[] } } } },
            floorName?: string,
            zoneName?: string,
            storeName?: string,
        ) => (data?.[buildingNameInput]?.[floorName]?.[zoneName]?.[storeName] || Array<number>()) as number[];

        const initialEntranceData = getTrafficData(graphDataService.storeEntranceByHour$.value, initialFloorName, initialZoneName, initialStoreName);
        comInstance.chartLabel = configDataService.TIME_LIST.map(time => `${time}`);
        comInstance.isShow = (graphDataService.baseGraphData.selectedInteractable$.value || {}).type === 'store';
        const barData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: initialEntranceData,
            color: PIE_CHART_COLOR_LIST[0],
            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            calPercentFrommAllData: false,
        }]);

        subscription.add(combineLatest([graphDataService.baseGraphData.selectedFloorName$, graphDataService.baseGraphData.selectedInteractable$, graphDataService.storeEntranceByHour$]).subscribe(
            ([selectedFloorName, selectedInteractable, storeEntranceByHour]) => {
                comInstance.isShow = (selectedInteractable || {}).type === 'store';
                if (!selectedFloorName || !selectedInteractable || selectedInteractable.type !== 'store' || selectedInteractable.store === undefined) { return; }
                const zoneName = selectedInteractable?.store?.zone_name;
                const floorName = selectedInteractable?.store?.floor_name;
                const entranceData = getTrafficData(storeEntranceByHour, floorName, zoneName, selectedInteractable?.store?.store_area_name);
                barData$.next([{
                    data: entranceData,
                    color: PIE_CHART_COLOR_LIST[0],
                    label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                    calPercentFrommAllData: false,
                }]);
            }
        ));
        comInstance.title = configDataService.DISPLAY_LANGUAGE.STORE_FOOT_TRAFFIC;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = false;
        comInstance.data$ = barData$;
        comInstance.isStacked = 'XY';
        comInstance.xAxesRotation = 90;
        // comInstance.customToolTipFuction
        return comInstance;
    }

}
