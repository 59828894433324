import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ChartColor, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Subscription } from 'rxjs';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined, isScreenSmallerThanMD, isShowGraph } from '../helpers/util';
import { DynamicGraphAdditionalInput, DynamicGraphConfig} from '../objects/config';
import { SelectableCustomDataName } from '../objects/selectableData';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { CustomerAgeTooltipsComponent } from '../pages/home/general/customer-age-tooltips/customer-age-tooltips.component';
import { CustomerGenderTooltipsComponent } from '../pages/home/general/customer-gender-tooltips/customer-gender-tooltips.component';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicStackBarChartComponent } from '../shared-components/dynamic/dynamic-stack-bar-chart/dynamic-stack-bar-chart.component';
import { GraphResolverBase } from './GraphResolverBase';


export class Custom100StackBarChartResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService, 
        graphDataService: GraphDataService, 
        popoverController: PopoverController, 
        viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
        authenicationService: AuthenticationService,
        ) 
    {
        // await configDataService.loadAppConfig();
        const stackBarChartConfig = (getAdditionalInput(additionalInput, 'dynamicGraphConfig') || {}) as DynamicGraphConfig;
        const stackBarChartLockDate = (getAdditionalInput(additionalInput, 'useZoneDateLock')) === true as boolean;
        const useselectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory')) === true as boolean;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        assertNullUndefined(stackBarChartConfig);
        
        const chartTitleKey = stackBarChartConfig.graphName;
        const chartState = (stackBarChartConfig.graphState || 'ENABLE');
        const chartDataConfig = stackBarChartConfig.graphData;
        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = Object.keys(chartDataConfig).map(key => {
          const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as SelectableCustomDataName, graphDataService);
          graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
          return dataConfigResolved.data;
        });
        const chartOptionsConfig = stackBarChartConfig.graphOptions;
        const chartSelectOptions = stackBarChartConfig?.graphSelectOptions || [];
        const chartType = (stackBarChartConfig.graphType || 'HORIZONTAL');
        const chartIsHorizontal = chartType === 'HORIZONTAL';

        const useSelector = chartOptionsConfig?.useSelector === true;
        const profileMode = chartOptionsConfig?.profileMode as string;

        const chartData$ = new BehaviorSubject<ChartDataSets[]>([]);
        const chartOption$ = new BehaviorSubject<ChartOptions>({});
        const isLock$ = new BehaviorSubject<boolean>(chartState === 'LOCK');
        const isShow$ = new BehaviorSubject<boolean>(false);
        const chartLabel$ = new BehaviorSubject<Label[]>(stackBarChartConfig.graphTooltipLabel);
        const stackBarChartSelector$ = new BehaviorSubject<string[]>(chartSelectOptions);
        const COLOR_LIST = [...PIE_CHART_COLOR_LIST];

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicStackBarChartComponent;
        const allDataBehaviourSubject$: BehaviorSubject<unknown>[] = useselectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, ...chartDataBehaviourSubject$] : useSelector ? [stackBarChartSelector$, ...chartDataBehaviourSubject$] : chartDataBehaviourSubject$;
        const ageInfoPopover = async (e: any) => {
          const ageDetailPopover = await popoverController.create({
              component: CustomerAgeTooltipsComponent,
              cssClass: 'calendar-criteria-tooltips',
              event: e
          });
          return ageDetailPopover.present();
        };
        const genderInfoPopover = async (e: any) => {
          const customerGenderPopover = await popoverController.create({
              component: CustomerGenderTooltipsComponent,
              cssClass: 'customer-segment-details-popover',
              event: e
          });
          return customerGenderPopover.present();
        };
        const profileDefinitions = {
          AGE: configDataService.AGE_CLASS,
          GENDER: configDataService.GENDER_CLASS,
        };
        const profileDisplayDefinitions = {
          AGE: configDataService.DISPLAY_LANGUAGE.AGE_CLASS,
          GENDER: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS,
        };
        const profileInfoPopover = {
          AGE: ageInfoPopover,
          GENDER: genderInfoPopover,
        };
        subscription.add(combineLatest(allDataBehaviourSubject$).subscribe((combinedResult) => {
            if (combinedResult.some(val => !val)) { return; }
            if (useselectedDirectory) {
              const selectDirectory = combinedResult[0] as { building: string; floor: string; zone: string };
              if (Object.keys(selectDirectory).some(key => selectDirectory[key] === null)) {
                isShow$.next(false);
                return;
              }
              else if (Object.values(selectDirectory).some(val => val.length === 0)) {
                isShow$.next(false);
                return;
              } else {
                isShow$.next(true);
              }
              if (stackBarChartLockDate) {
                if (selectDirectory?.zone === '5d') {
                  viewPeriodService.selectedDate.isBetween('2021-03-14', '2021-03-17') ? isLock$.next(true) : (viewPeriodService.selectedDate.isBefore('2021-03-08') ? isLock$.next(true)  : isLock$.next(false) );
                } else if (selectDirectory?.zone === '4d' || selectDirectory?.zone === '6d') {
                    viewPeriodService.selectedDate.isBefore('2021-03-17') ? isLock$.next(true) : isLock$.next(false);
                } else {
                    isLock$.next(true);
                }            
              }
            }
            const rawDatas = combinedResult.slice(1) as object[];
            if (useSelector) {
              const selected = combinedResult[0] as string[];
              const rawChartData = rawDatas[0];
              if (Object.values(rawChartData).some(val => !val)) {
                return;
              }
              isShow$.next(isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization));
              const initClass = profileDefinitions[profileMode] as string[];
              const initClassData: ChartDataSets[] = initClass.map((className, idx) => ({
                  categoryPercentage: 0.8,
                  barPercentage: 0.9,
                  hoverBackgroundColor: configDataService.HOVER_BG_COLOR_LIST?.[chartTitleKey]?.[className] || COLOR_LIST[idx % COLOR_LIST.length],
                  label: className, 
                  data: [], 
                  backgroundColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[className] || COLOR_LIST[idx % COLOR_LIST.length],
                }));
              const labels = selected.map(name => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[name]);
              chartLabel$.next(labels);
              Object.entries(rawChartData).forEach(([zoneName, zoneProfileData]) => {
                if (!selected.includes(zoneName)) {
                  return;
                } else {
                  Object.entries(zoneProfileData).forEach(([className, val]) => {
                    if (initClassData.find(data => data.label === className).data.length < 1 ) {
                      initClassData.find(data => data.label === className).data[0] = val;
                    } else {
                      initClassData.find(data => data.label === className).data.push(val as number);
                    }
                  });
                }
              });
              initClassData.forEach(data => {
                const oldLabel = data.label;
                const newLabel = profileDisplayDefinitions[profileMode][oldLabel];
                data.label = newLabel;
              });
              chartData$.next(initClassData);
            } else {
              rawDatas.forEach((rawData) => {
                const chartData: ChartDataSets[] = Object.entries(rawData).map(([className, val], idx) => ({
                        categoryPercentage: 0.7,
                        barPercentage: 0.6,
                        hoverBackgroundColor: configDataService.HOVER_BG_COLOR_LIST?.[chartTitleKey]?.[className] || COLOR_LIST[idx % COLOR_LIST.length],
                        label: className.includes('_') ? (className.replace('_', ' ')).charAt(0).toLocaleUpperCase() + (className.replace('_', ' ')).slice(1) : (className === 'teenagers' ? 'Teens' : className.charAt(0).toLocaleUpperCase() + className.slice(1)), 
                        data: [val], 
                        backgroundColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[className] || COLOR_LIST[idx % COLOR_LIST.length],
                      }));
                chartData$.next(chartData);
              });
            }
        }));

        chartOption$.next({
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: useselectedDirectory ? 1.1 : 1.4,
            animation: {
                duration: 1000,
                easing: 'easeOutCubic',
            },
            responsiveAnimationDuration: 1000,
            layout: {
              padding: {
                top: 0,
                left: chartIsHorizontal ? 0 : 5,
                right: (chartIsHorizontal && isScreenSmallerThanMD(window)) && (!useselectedDirectory) ? 35 : 5,
                bottom: 5,
              }
            },
            legend: {
                display: true,
                labels: {
                  boxWidth: 20,
                  fontColor: 'white',
                  fontSize: 14,
                },
                onClick: (e) => e.stopPropagation(),
            },
            scales: {
              xAxes: [{
                ticks: {
                    callback: (value, _index, _values) => chartIsHorizontal ? value + '%' : value,
                    max: 100,
                    beginAtZero: true,
                    fontColor: '#efefef',
                },
                gridLines: {
                  display: chartIsHorizontal,
                  color: '#424242',
                  drawOnChartArea: false,
                },
                stacked: true
              }],
              yAxes: [{
                ticks: {
                  callback: (value, _index, _values) => chartIsHorizontal ? value : value + '%',
                  max: 100,
                  beginAtZero: true,
                  fontColor: '#efefef',
                },
                gridLines: {
                  display: !chartIsHorizontal,
                  color: '#424242',
                  drawOnChartArea: false,
                },
                display: stackBarChartConfig.graphTooltipLabel.length > 1 ? true : !chartIsHorizontal,
                stacked: true
              }]
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  const datasetIndex = tooltipItem.datasetIndex;
                  const datasetLabel = data.datasets[datasetIndex].label;
                  const datasetData = data.datasets[datasetIndex].data[tooltipItem.index];
                  return datasetData > 1 ? `${datasetLabel}: ${(datasetData as number).toFixed(0)}%` : `${datasetLabel}: <1%`;
                },
                labelColor: (tooltipItem, chart) => {
                    const chartColor = chart.data.datasets[tooltipItem.datasetIndex].backgroundColor as ChartColor;
                    return {
                        borderColor: 'black',
                        backgroundColor: chartColor,
                    };
                }
              },
              titleAlign: 'center',
              titleFontSize: 13,
              bodyFontSize: 12,
              mode: 'label',
              position: 'average',
            },
            plugins: {
              datalabels: {
                color: 'white',
                display: (context) => {
                  const avgSize = Math.round((context.chart.height + context.chart.width) / 2);
                  const size = useselectedDirectory ? avgSize / 8 : avgSize / 24;
                  return isScreenSmallerThanMD(window) ? context.dataset.data[context.dataIndex] > size : context.dataset.data[context.dataIndex] >= 20 ;
                },
                formatter: (value, context) => {
                  if (!value) {
                    return;
                  }
                  // const val = context.dataset.data[context.dataIndex] as ;
                  return `${value.toFixed(0)}%`;
                },
                font: (context) => {
                  const avgSize = Math.round((context.chart.height + context.chart.width) / 2);
                  const size = Math.round(avgSize / 18);
                  const newSize = size > 16 ? 16 : size; // setting max limit to 12
                  return {
                    size: newSize,
                    weight: 'bold'
                  };
                },
                clamp: true,
              },
            }
        });
        
        if (stackBarChartConfig.graphInfoPopover) {
            const infoPopoverText = stackBarChartConfig.graphInfoPopover;
            comInstance.infoPopover = async (e: any) => {
                const stackBarPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: infoPopoverText.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: infoPopoverText.details,
                    },
                    event: e,
                });
                return await stackBarPopover.present();
            };
        }
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.isShow = isShow$;
        comInstance.chartOptions$ = chartOption$;
        comInstance.chartLabel$ = chartLabel$;
        comInstance.data$ = chartData$;
        comInstance.isShowLegend = true;
        comInstance.isLock = isLock$;
        comInstance.isHorizontal = chartIsHorizontal;
        comInstance.isShowSelector = useSelector;
        comInstance.stackBarChartSelector$ = stackBarChartSelector$;
        comInstance.chartSelectorOptions = chartSelectOptions;
        comInstance.infoPopover = profileInfoPopover[profileMode];
        return comInstance;
    }
    
}
