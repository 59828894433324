import { ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartTooltipModel } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject, Observable } from 'rxjs';
import { isScreenSmallerThanMD } from 'src/app/helpers/util';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-dynamic-custom-bar-chart',
  templateUrl: './dynamic-custom-bar-chart.component.html',
  styleUrls: ['./dynamic-custom-bar-chart.component.scss'],
})
export class DynamicCustomBarChartComponent implements OnInit {

  @Input() data$: BehaviorSubject<ChartDataSets[]>;
  @Input() chartLabel$: BehaviorSubject<Label[]>;
  @Input() chartOptions$: BehaviorSubject<ChartOptions>;
  @Input() isShow: boolean | Observable<boolean>;
  @Input() isLock: boolean | BehaviorSubject<boolean>;
  @Input() title: string;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isHorizontal = true;
  @Input() isShowSelector: boolean;
  @Input() isShowLegend: boolean;
  @Input() selectInterface: 'action-sheet' | 'popover' | 'alert' = 'alert';
  @Input() chartSelectorOptions: string[];
  @Input() barChartSelector$: BehaviorSubject<string[]>;
  @Input() inputLockText: string;
  @Input() defaultValue: string;
  @Input() customToolTipFuction: (tooltipModel: ChartTooltipModel) => void;
  @Input() labelOption: 'default' | 'logo';
  @Input() labelImages: object;
  @Input() labelDisplayList: { [label: string]: string };
  @Input() multipleSelectedDropdown: boolean;

  selectorName: string[] = []; 

  constructor(
    public configDataService: ConfigDataService,
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {
    this.selectorName = this.chartSelectorOptions;
  }

  changeSelectedGraph(ev: any) {
    const selectedName = ev.detail.value as string[];
    this.barChartSelector$.next(selectedName);
  }

  getDefaultValue() {
    return this.barChartSelector$.value;
  }

  get isShowGraph() {
    let showGraph = false;
    if (this.isShow instanceof Observable) {
      this.isShow.subscribe(show => {
        showGraph = show;
      });
    } else {
      showGraph = this.isShow;
    }
    return showGraph;
  }

  get displayIconOnMiddle() {
    return isScreenSmallerThanMD(window);
  }

}
