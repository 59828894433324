<ion-row style="margin-bottom: 0px">
  <ion-col
    *ngFor="let card of cards; first as isFirst"
    [hidden]="!isShowGraph(card)"
    [sizeXs]="card.sizeXS"
    [sizeMd]="card.sizeMD"
    [offsetMd]="card.offsetMD"
    [sizeLg]="card.sizeLG"
    style="height: 100%"
  >
    <!-- <ion-card style="display: grid;" class="ion-margin-vertical" appLockIcon [isLock]="card.isLock" [inputLockText]="card.inputLockText | async" [ngStyle]="getNumberCardSize(card.sizeXS, card?.height)" [class.notFirstCard]="!card.isFirst" > -->
    <ion-card
      class="ion-margin-vertical"
      appLockIcon
      [isLock]="card.isLock"
      [inputLockText]="card.inputLockText | async"
      [ngStyle]="getNumberCardSize(card.sizeXS, card?.height)"
      [class.notFirstCard]="!card.isFirst"
    >
      <ion-card-header [style]="getHeaderStyle(card.headerStyle)">
        <ion-card-title>
          <ion-grid class="ion-no-padding">
            <div class="grid-container" style="width: 100%; display: grid; align-items: center" [style.grid-template-columns]="updateGridLayout(card.isShowInfoIcon, shouldShowSelector)">
              <div class="grid-title" id="col-title-name" style="display: flex; justify-content: start; align-items: center;">
                <ion-label style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ parseTitle(card.title) }}</ion-label>
              </div>
              <ng-container *ngIf="card.isShowInfoIcon">
                <div style="display: flex; justify-content: flex-end; align-items: center">
                  <ion-icon (click)="card.infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="shouldShowSelector">
                <div id="col-selector" style="display: flex; justify-content: flex-end; align-items: center;">
                  <ion-select [value]="selectedName" style="font-size: 16px; color: var(--ion-color-primary); text-align: justify" (ionChange)="changeSelectedGraph($event)" #ionGraphSelector>
                    <ion-select-option *ngFor="let name of selectorName; index as i" [value]="name">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[name] }}</ion-select-option>
                  </ion-select>
                </div>
              </ng-container>
            </div>
            <ion-row *ngIf="isShowAveragePerDayButton">
              <ion-col>
                <ion-button size="6" (click)="toggleAveragePerDayFilter()" [color]="showAverage ? 'primary' : 'secondary'">
                  Average Per Day
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content style="margin-top: 16px" class="card-content-text-large ion-text-center ion-align-items-center" appLockBlur [isLock]="card.isLock">
        <label>{{ card.numberData$ | async }}</label>
      </ion-card-content>
      <div style="text-align: center; margin-bottom: 8px; margin-top: 16px">
        <label class="label-diff-container" [ngStyle]="card.labelStyle$ | async">
          <span *ngIf="card.labelHeaderDiff$ | async" style="margin-right: 6px">{{ card.labelHeaderDiff$ | async }}</span>
          <ion-icon [name]="card.labelIcon$ | async" [style]="card.iconStyle" class="icon-vertical-center"></ion-icon>
          {{ (card.label$ | async) }}
        </label>
      </div>
    </ion-card>
  </ion-col>
</ion-row>
