import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { isScreenSmallerThanMD, numberFormatter } from 'src/app/helpers/util';
import { ViewPeriodService } from 'src/app/services/view-period.service';

@Component({
  selector: 'app-dynamic-progress-number-card',
  templateUrl: './dynamic-progress-number-card.component.html',
  styleUrls: ['./dynamic-progress-number-card.component.scss'],
})
export class DynamicProgressNumberCardComponent implements OnInit {
  @Input() cards: {
    isLock: boolean | BehaviorSubject<boolean>;
    title: string;
    title_area: string;
    dayLeft: number;
    updatedProgress$: BehaviorSubject<{
      progressWidth: { width: string; 'background-color': string };
      progressNumber: string;
      dayLeft: number;
      labelActualVal: string;
      labelTrend: string;
      forecastLine: { left: number };
      targetPosition: Array<{ label: string; value: number; position: { left: number } }>;
      progressLabelPosition: { left: number };
    }>;
    isShow$: BehaviorSubject<boolean>;
    progressWidth: { width: string; 'background-color': string; 'border-right': string };
    progressNumber: string;
    forecastLine: { left: number };
    labelTrend: string;
    labelTrendStyle: BehaviorSubject<{color: string}>;
    labelActualVal: string;
    labelTargetVal: string;
    labelNumberIcon: string;
    labelTrendIcon: string;
    sizeXS?: string;
    sizeMD?: string;
    offsetMD?: string;
    iconStyle?: string;
    infoPopover?: (e: any) => Promise<any>;
    periodGoal?: string;
    isShow?: boolean;
    showForecast: boolean;
    goalDuration: {
      start_date: moment.Moment;
      end_date: moment.Moment;
    };
    displayLabel: {
      currentValue: boolean;
      trendValue: boolean;
      location: boolean;
    };
  }[];
  private selectedDate: moment.Moment;

  constructor(
    private viewPeriodService: ViewPeriodService
  ) { }

  ngOnInit() {
    this.selectedDate = this.viewPeriodService.selectedDate;
    this.viewPeriodService.subscribeSelectedDate(selectedDate => {
      if (this.viewPeriodService.isLiveMode) {
        this.selectedDate = selectedDate.date;
      }
    });
  }

  get NumberBigStyle() {
    const style = isScreenSmallerThanMD(window) ? {'font-size': '28px', 'font-weight': 700, 'text-align': 'center'} : {'font-size': '32px', 'font-weight': 700};
    return style;
  }

  get labelTitleStyle() {
    const style = isScreenSmallerThanMD(window) ? {'font-size': '16px', 'text-align': 'center'} : {'font-size': '20px'};
    return style;
  }

  getDayLeft(day: number){
    return day > 0 ? `${day} days left` : `Completed`;
  }

  getGoalDuration(duration: {
    start_date: moment.Moment;
    end_date: moment.Moment;
  }) {
    const current_date = moment().utc(false).clone().subtract(1, 'd');
    if (current_date.isBefore(duration.end_date)) {
      return `${duration.start_date.format('D MMM[\']YY')} - ${current_date.format('D MMM[\']YY')}`;
    }
    return `${duration.start_date.format('D MMM[\']YY')} - ${duration.end_date.format('D MMM[\']YY')}`;
  }

  parseUnit(value: number, unit: 'hour' | 'min' | 'percent' | 'default' | 'decimal' | 'si' | 'spots', decimalPoint: number = 0, calculateHour?: boolean, ) {
    if (!value) {
        return 'N/A';
    }
    if (unit === 'percent') {
        if (value >= 0 && value < 1) {
            return '<1%';
        } 
        else if (isNaN(value)) {
            return 'N/A';
        }
        return value.toFixed(decimalPoint) + '%';
    }
    else if (unit === 'min') {
        if (value < 60) {
            return '0 mins';
        }
        return (value / 60).toFixed(0) + ' mins';
    } 
    else if (unit === 'hour') {
        if (calculateHour) {
            return Math.round(value / 3600).toLocaleString() + ' hrs';
        }
        return value.toLocaleString() + ' hrs';
    }
    else if (unit === 'decimal') {
        return value.toFixed(decimalPoint);
    } 
    else if (unit === 'si') {
        return numberFormatter(value, 1);
    }
    else if (unit ==='spots'){
        return value +' spots';
    }
    return value.toLocaleString(); 
  };

  calculateLeftPositionOnWidthDevice(leftPos: number, type: 'current_progress' | 'target' | 'forecast') {
    let leftNumber = 1.5;
    if (!leftPos) {
      return leftNumber;
    }
    if (type === 'target') {
      leftNumber = isScreenSmallerThanMD(window) ? leftPos < 3 ? 1 : leftPos - 3 : leftPos < 1 ? 1 : leftPos - 1;
      return Math.round(leftNumber);
    }
    if (type === 'forecast') {
      leftNumber = isScreenSmallerThanMD(window) ? leftPos : leftPos < 1.5 ? 1.5 : leftPos;
      return Math.round(leftNumber);
    }
    leftNumber = isScreenSmallerThanMD(window) ? leftPos - 9.5 < 0 ? -3 : leftPos - 9.5 : leftPos - 3 < 0 ? -3 : leftPos - 3;
    return Math.round(leftNumber);
  }

  get widthVL() {
    return isScreenSmallerThanMD(window) ? 0.75 : 0.4;
  }

}
