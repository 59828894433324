import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { showGateCustomTooltips } from '../helpers/gateTooltips';
import { getDepthData, isScreenSmallerThanMD, isScreenSmallerThanXL } from '../helpers/util';
import { DynamicGraphAdditionalInput, GraphDataConfig } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';

export class FloorSummaryToiletUsedResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // await configDataService.loadAppConfig();
        const additionnalAspectRatioInput = (getAdditionalInput(additionalInput, 'aspectRatio') || { desktop: 1.0, mobile: 0.7 }) as { desktop: number; mobile: number };
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey') as string);
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const floorName = (getAdditionalInput(additionalInput, 'floor') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.FLOOR) as string;
        const sortBy = (getAdditionalInput(additionalInput, 'sortBy') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.SORT_BY || 'auto') as 'entrance' | 'exit' | 'auto';
        const filterBy = (getAdditionalInput(additionalInput, 'filterBy') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.FILTER_BY) as string;
        const contributeLevel = (getAdditionalInput(additionalInput, 'contributeLevel') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.CONTRIBUTE_LEVEL) as string;
        const channel = (getAdditionalInput(additionalInput, 'channel') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.CHANNEL || 'auto') as 'entrance' | 'exit' | 'auto';
        const dynamicGraphDataSelector = (getAdditionalInput(additionalInput, 'dynamicGraphDataSelector') || {}) as { [selectorName: string]: GraphDataConfig };
        const showPercentOfTotal = (getAdditionalInput(additionalInput, 'showPercentOfTotal') || {}) as boolean;
        const getFloorGateData = (value: { [buildingName: string]: { [floorName: string]: { [gateName: string]: number } } }, defaultValue = {}) => (value && value[buildingName]) ? value[buildingName] : defaultValue as { [floorName: string]: { [gateName: string]: number } };

        const round_max_val = (val: number) => val > 10000 ? Math.round(val / 5) : Math.round(val / 10); // TODO: properly handle data not just random if check

        const selectedDataSource$ = new BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>(dynamicGraphDataSelector);
        const floorInitialEntranceData = contributeLevel === 'building' ? graphDataService.allPinByBuildingEntrance$.getValue() || {} : contributeLevel === 'zone' ? graphDataService.allPinByZoneEntrance$.value || {} : getFloorGateData(graphDataService.floorGateEntranceData$.value)[floorName] || {};
        const floorInitialExitData = contributeLevel === 'building' ? graphDataService.allPinByBuildingExit$.getValue() || {} : contributeLevel === 'zone' ? graphDataService.allPinByZoneExit$.value || {} : getFloorGateData(graphDataService.floorGateExitData$.value)[floorName] || {};
        const sortedGateInitialData = sortBy === 'entrance' ? Object.fromEntries(Object.entries(floorInitialEntranceData).sort((a, b) => b[1] - a[1])) : Object.fromEntries(Object.entries(floorInitialExitData).sort((a, b) => b[1] - a[1]));
        const filteredGateInitialData = filterBy === 'TOP_5' ? Object.fromEntries(Object.entries(sortedGateInitialData).slice(0, 5)) : sortedGateInitialData;
        const initialGateNames = sortBy === 'auto' ? Object.keys(floorInitialEntranceData).sort() : Object.keys(filteredGateInitialData);
        const chartLabel = ['Toilet 2B','Toilet 2C'];

        const buildingChannelTotal: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
        const entranceInitialData = initialGateNames.map(gateName => sortBy === 'entrance' ? filteredGateInitialData[gateName] || null : floorInitialEntranceData[gateName] || null);
        const exitInitialData = initialGateNames.map(gateName => sortBy === 'exit' ? filteredGateInitialData[gateName] || null :  floorInitialExitData[gateName] || null);
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: [],
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            calPercentFrommAllData: false
        }]);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        subscription.add(combineLatest([graphDataService.baseGraphData.selectedInteractable$])
        .subscribe(([selectedInteractable]) => {
            const dualBarData: GenericBarChartData[] =
            [{
                data: [678,754],
                color: '#346DF3',
                label: 'TOILET 2C',
                calPercentFrommAllData: false,
                calPercentFromLabelProperty: false,
            }]
            dualBarData$.next(dualBarData);
        }));
        comInstance.suggestedTickMax_X = sortBy === 'auto' ? 0 : sortBy === 'entrance' ? entranceInitialData[0] + round_max_val(entranceInitialData[0]) : exitInitialData[0] + round_max_val(exitInitialData[0]);  
        comInstance.title = 'Toilet Overall';
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.isHorizontal = true;
        comInstance.aspectRatio = isScreenSmallerThanXL(window) ? additionnalAspectRatioInput.mobile : additionnalAspectRatioInput.desktop;
        comInstance.valueTextAlign = 'left';
        comInstance.valueTextOffsetX = isScreenSmallerThanMD(window) ? 1 : 5;
        comInstance.valueTextOffsetY = 5;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.displayAxis = 'Y';
        comInstance.isStacked = '!XY';
        comInstance.displayGrid = channel === 'auto';
        comInstance.selectedBar$ = selectedDataSource$;
        comInstance.selectInterface = 'alert';
        comInstance.selector = dynamicGraphDataSelector;
        comInstance.multipleSelector = false;
        // comInstance.showAveragePerDay = showAveragePerDay;
        const imageSrc = configDataService.isFeatureEnabled('traffic_page', 'gate_image_flatten') ? configDataService.entranceExitPinImageSrc : configDataService.entranceExitGateImageSrc;
        comInstance.customToolTipFuction = function(tooltipModel) {
            (tooltipModel as any).floorName = 'ALL';
            const channelName: 'entrance' | 'exit' | 'auto' = channel === 'auto' ? 'entrance' : channel;
            if (Object.keys(dynamicGraphDataSelector).length > 0) {
                showGateCustomTooltips(tooltipModel, this, imageSrc, configDataService.DISPLAY_LANGUAGE.GATE_NAME, channelName, showPercentOfTotal, buildingChannelTotal);
            } else {
                showGateCustomTooltips(tooltipModel, this, imageSrc, configDataService.DISPLAY_LANGUAGE.GATE_NAME, channelName);
            }
        };
        comInstance.infoPopover = async (e: any) => {
            const popover = await popoverController.create({
                component: CustomTextTooltipsComponent,
                componentProps: {
                    toolTipTitle: configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                    toolTipDetails: []
                },
                cssClass: 'customer-segment-details-popover',
                event: e,
            });
            return await popover.present();
        };
        return comInstance;
    }

}
