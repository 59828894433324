import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

const CHART_COLOR = '#E63739';

export class DurationOfVisitTrendResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const getAverageTimeSpentChartData = (data: { [buildingName: string]: number[] }, bldName: string, defaultValue: number[] = Array.from({ length: 7 }).map(() => null)) => {
            if (!data || !data[bldName]) { return defaultValue; }
            return data[bldName];
        };
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: getAverageTimeSpentChartData(graphDataService.averageTimeSpentChartData$.value, buildingName).map(val => val ? Math.round(val / 60) : val),
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST,
            toolTipLabelUnit: 'min',
            label: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND,
        }]);

        subscription.add(graphDataService.averageTimeSpentChartData$.subscribe((dataPoints) => {
            const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
            const lineChartData: GenericLineChartData[] = [{
                points: getAverageTimeSpentChartData(dataPoints, buildingName).map(val => val ? Math.round(val / 60) : val),
                backgroundColor: `${CHART_COLOR}22`,
                color: CHART_COLOR,
                isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                toolTipLabel: viewPeriodService.DAY_LIST.map((day) => `Overall,${day}`),
                toolTipLabelUnit: 'min',
                label: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND,
            }];
            chartData$.next(lineChartData);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = this.isLock;
        comInstance.enableTBD = true;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        comInstance.isShowingLegend = false;
        // comInstance.selectedLine$ = selectedGraph$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND;
        return comInstance;
    }

}
