import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { GenericBarChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { LINE_CHART_COLOR_LIST } from '../configs/color';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { showGenericBarChartCustomTooltips } from '../helpers/barChartTooltips';

export class VehicleByProvinceBarResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const getProvinceName = (provinceName: string) => (configDataService.DISPLAY_LANGUAGE.VEHICLE_PROVINCE_CLASS[provinceName] || provinceName) as string;
        const initialProvinces = Object.keys(graphDataService.currentVehicleByProvinceData$.value || {}).filter((provinceName) => provinceName !== 'total').sort();
        const initialChartData: GenericBarChartData[] = Object.entries(graphDataService.currentVehicleByProvinceData$.value || {}).filter(([provinceName, _]) => provinceName !== 'total').sort().map(([provinceName, count], idx) => ({
            data: initialProvinces.map((nameIt) => nameIt === provinceName ? count : null),
            color: LINE_CHART_COLOR_LIST[idx % LINE_CHART_COLOR_LIST.length],
            label: getProvinceName(provinceName),
            calPercentFrommAllData: false,
        }));
        const chartData$ = new BehaviorSubject<GenericBarChartData[]>(initialChartData);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.chartLabel = initialProvinces.map((name) => getProvinceName(name));
        comInstance.data$ = chartData$;

        subscription.add(graphDataService.currentVehicleByProvinceData$.subscribe((currentVehicleByProvinceData) => {
            const provinces = Object.keys(currentVehicleByProvinceData || {}).filter((provinceName) => provinceName !== 'total').sort();
            const chartData: GenericBarChartData[] = Object.entries(currentVehicleByProvinceData || {}).filter(([provinceName, _]) => provinceName !== 'total').sort().map(([provinceName, count], idx) => ({
                data: provinces.map((nameIt) => nameIt === provinceName ? count : null),
                color: LINE_CHART_COLOR_LIST[idx % LINE_CHART_COLOR_LIST.length],
                label: getProvinceName(provinceName),
                calPercentFrommAllData: false,
            }));
            chartData$.next(chartData);
            comInstance.chartLabel = provinces.map((name) => getProvinceName(name));
        }));

        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.VEHICLE_PROVINCE;
        comInstance.isShowLegend = false;
        comInstance.isHorizontal = true;
        comInstance.valueTextAlign = 'left';
        comInstance.valueTextOffsetX = 10;
        comInstance.valueTextProcess = 'toLocaleString';
        comInstance.displayAxis = 'XY';
        comInstance.isStacked = 'XY';
        comInstance.displayGrid = false;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showGenericBarChartCustomTooltips(tooltipModel, this);
        };
        return comInstance;
    }

}
