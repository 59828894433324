<ion-row *ngIf="configDataService.isConfigReady" [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }">
  <ion-col>
    <ion-card appLockIcon [isLock]="isLock" [inputLockText]="inputLockText" appDataTbd [isEnableTBD]="isEnableTBD" [lockOpacity]="lockOpacity" class="ion-padding">
      <ion-card-header *ngIf="shouldShowSelector; else normalTitle">
        <ion-grid class="ion-no-padding">
          <div class="grid-container" style="width: 100%; display: grid; grid-template-columns: auto 1fr; align-items: center">
            <div class="grid-title" style="display: flex; justify-content: start; align-items: center">
              <ion-card-title id="col-title-name">
                {{ title }}
                <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
              </ion-card-title>
            </div>
            <div id="col-selector" style="display: flex; justify-content: flex-end; align-items: center">
              <ion-select
                [value]="selectedName"
                style="font-size: 16px; color: var(--ion-color-primary); text-align: justify;"
                (ionChange)="changeSelectedGraph($event)"
                #ionGraphSelector
              >
                <ion-select-option *ngFor="let name of selectorName; index as i" [value]="name">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[name] }}</ion-select-option>
              </ion-select>
            </div>
          </div>
          <ion-row *ngIf="isShowAveragePerDayButton">
            <ion-col>
              <ion-button size="6" (click)="toggleAveragePerDayFilter()" [color]="showAverage ? 'primary' : 'secondary'">
                Average Per Day
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-header>
      <ng-template #normalTitle>
        <ion-card-header>
          <ion-card-title id="col-title-name">
            {{ title }}
            <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
          </ion-card-title>
          <ion-row *ngIf="isShowAveragePerDayButton">
            <ion-col>
              <ion-button size="6" (click)="toggleAveragePerDayFilter()" [color]="showAverage ? 'primary' : 'secondary'">
                <!-- {{ showAverage ? 'Hide Average' : 'Show Average' }} -->
                Average Per Day
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-header>
      </ng-template>
      <!-- <div [ngStyle]="shouldShowSelector ? { 'padding-top': '6px' } : { 'padding-top': '0px' }"> -->
      <div>
        <ion-grid>
          <!-- <ion-row *ngIf="isShowLegend" [ngStyle]="isShowLegend ? {'display': 'flex', 'justify-content': 'center', 'flex-flow': 'row wrap'} : {'display': 'none'}" >
            <div *ngIf="!isShowLegendOnPercent" class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
              <label *ngIf="isShowLegend" class="label-number">{{ isShowLegendOnPercent ? '100%' : ((sumOfDatasets | numberSuffix: "1.3") || 0) + unit}}</label> <br />
              <label *ngIf="isShowLegend" class="label-text">TOTAL</label>
            </div>
            <div *ngFor="let data of datasets; index as i;" class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
              <label *ngIf="isShowLegend" class="label-number">{{ isShowLegendOnPercent ? legendNumber(data) : ((data | numberSuffix: "1.3") || 0) + unit}}</label> <br />
              <label *ngIf="isShowLegend" class="label-text" [ngStyle]="{ color: backgroundColorData(i) }">{{ presentLabelData(i) }}</label>
            </div>
          </ion-row> -->
          <ion-row>
            <ion-col appLockBlur [isLock]="isLock">
              <app-generic-custom-pie-chart
                [data$]="data$"
                [chartLabels$]="chartLabel$"
                [chartOptions$]="chartOptions$"
              ></app-generic-custom-pie-chart>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-card>
  </ion-col>
</ion-row>
