import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { GenericLineChartData } from '../objects/chart';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { Label } from 'ng2-charts';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';
import { generateNestedData } from '../helpers/mock-data-generator';

const CHART_COLOR = '#6BC26F';

export class MaleFemaleLineChartResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        // ENTRANCE_EXIT_HOUR, ENTRANCE_EXIT_FLOOR_HOUR, ENTRANCE_EXIT_ZONE_HOUR
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const zoneName = graphDataService.baseGraphData.selectedDirectory$.value?.zone || (getAdditionalInput(additionalInput, 'zone')) as string;
        const initialfloorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';
        const getFloorAccumulateHeadcountByHour = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [zoneName: string]: number[] } } },
            floorName?: string,
            depthZoneName?: string,
        ) => accessDepthData<number[]>(data, buildingName, floorName, depthZoneName, Array.from({ length: configDataService.TIME_LIST.length }).map(() => null));

        const chartData$ = new BehaviorSubject<GenericLineChartData[]>(null)
        const chartLable$ = new BehaviorSubject<Label[]>(['10.00','11.00','12.00','13.00','14.00','15.00','16.00','17.00','18.00','19.00','20.00','21.00','22.00']);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        if (isMockData) {
            subscription.add(combineLatest([viewPeriodService.dayList,graphDataService.selectedInteractable$]).subscribe(
                async ([selectedInteractable]) => {
                    const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'MALE_FEMALE_LINE_CHART', 'count', 12);
                    const lineChartData: GenericLineChartData[] = [{
                        points:mockData.male,
                        backgroundColor: '#0A54FF22',
                        color: '#0A54FF',
                        toolTipLabel: ['male, 10.00','male, 11.00','male, 12.00','male, 13.00','male, 14.00,','male, 15.00','male, 16.00','male, 17.00','male, 18.00','male, 19.00','male, 20.00','male, 21.00','male, 22.00'],
                        label: 'Male',
                        },
                        {
                        points:mockData.female,
                        backgroundColor: '#ff000022',
                        color: '#ff0000',
                        toolTipLabel: ['Female, 10.00','Female, 11.00','Female, 12.00','Female, 13.00','Female, 14.00,','Female, 15.00','Female, 16.00','Female, 17.00','Female, 18.00','Female, 19.00','Female, 20.00','Female, 21.00','Female, 22.00'],
                        label: 'Female',
                        }
                    ];
                    chartData$.next(lineChartData);
                }));
        }else{
            subscription.add(combineLatest([graphDataService.selectedInteractable$,graphDataService.storeGenderProfileDataByHour$]).subscribe(
                ([selectedInteractable,storeGenderProfileData]) => {
                    const maleData = storeGenderProfileData.male
                    const femaleData = storeGenderProfileData.female
                    const lineChartData: GenericLineChartData[] = [{
                        points:maleData,
                        backgroundColor: '#0A54FF22',
                        color: '#0A54FF',
                        toolTipLabel: ['male, 10.00','male, 11.00','male, 12.00','male, 13.00','male, 14.00,','male, 15.00','male, 16.00','male, 17.00','male, 18.00','male, 19.00','male, 20.00','male, 21.00','male, 22.00'],
                        label: 'Male',
                        },
                        {
                        points:femaleData,
                        backgroundColor: '#ff000022',
                        color: '#ff0000',
                        toolTipLabel: ['Female, 10.00','Female, 11.00','Female, 12.00','Female, 13.00','Female, 14.00,','Female, 15.00','Female, 16.00','Female, 17.00','Female, 18.00','Female, 19.00','Female, 20.00','Female, 21.00','Female, 22.00'],
                        label: 'Female',
                        }
                    ]
                    chartData$.next(lineChartData);
                }));
        }
        // comInstance.enableTBD = true;
        comInstance.data$ = chartData$;
        comInstance.label$ = chartLable$;
        // comInstance.selectedLine$ = selectedGraph$;
        comInstance.title = 'Toilet Hourly Used';
        comInstance.isLock = this.isLock;
        return comInstance;
    }

}
