import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicGraphAdditionalInput, GraphDataConfig } from '../objects/config';
import { CalendarViewComponent } from '../pages/home/general/calendar-view/calendar-view.component';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { GraphResolverBase } from './GraphResolverBase';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class CalendarPerformanceResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput,
        _configDataService: ConfigDataService,
        _graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        _subscription: Subscription
    ) 
    {
        const useSelector = getAdditionalInput(additionalInput, 'useSelector') as boolean;
        const chartDataSelector = (getAdditionalInput(additionalInput, 'dynamicGraphDataSelector') || {}) as { [selectorName: string]: GraphDataConfig };
        const selectedDataSelector$ = new BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>(chartDataSelector);
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as CalendarViewComponent;
        comInstance.multipleSelector = false;
        comInstance.selectInterface = 'alert';
        comInstance.overrideShouldShowSelector = useSelector;
        comInstance.selectedCalendarView$ = selectedDataSelector$;
        comInstance.selector = chartDataSelector;
        comInstance.isMockData = isMockData;
        return comInstance;
    }
    
}
