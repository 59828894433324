import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { Label } from 'ng2-charts';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { OverallResolverBase } from './OverallResolverBase';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { assertNullUndefined } from '../helpers/util';
import { PIE_CHART_COLOR_LIST } from '../configs/color';

export class FloorZoneFootTrafficResolver extends OverallResolverBase{

    public get graphDependencyMap(): { building: GraphDependency[]; floor: GraphDependency[]; zone: GraphDependency[] } {
        return {
            building: [GraphDependency.ENTRANCE_EXIT_HOUR],
            floor: [GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR],
            zone: [GraphDependency.ENTRANCE_EXIT_ZONE_HOUR]
        };
    }

    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService, 
        graphDataService: GraphDataService, 
        _popoverController: PopoverController, 
        _viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
    ){
        // load data here
        const level = getAdditionalInput(additionalInput, 'level') as DynamicGraphAdditionalInput['level'] | undefined;
        assertNullUndefined(level);
        this.processDynamicDependency(configDataService, graphDataService, level);
        await configDataService.loadAppConfig();

        const getTrafficData = (
            dataBuilding: { [buildingName: string]: number[] },
            dataFloor: { [buildingName: string]: { [floorName: string]: number[] } },
            dataZone: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } },
        ) => this.getLeveledData(dataBuilding, dataFloor, dataZone, level);
        
        // eslint-disable-next-line max-len
        const initialComparingNames = level === 'building' ? this.getComparingNames(graphDataService.baseGraphData.comparingBuildingState$.value) : (level === 'floor' ? this.getComparingNames(graphDataService.baseGraphData.comparingFloorState$.value) : this.getComparingNames(graphDataService.baseGraphData.comparingZoneState$.value));
        const initialData = getTrafficData(graphDataService.buildingEntranceByHour$.value, graphDataService.floorEntranceByHour$.value, graphDataService.zoneEntranceByHour$.value);
        const chartLabel$ = new BehaviorSubject<Label[]>(configDataService.TIME_LIST);
        const initialColor: string[] = [];
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>(Object.entries(initialData).sort().filter(([name, _data], idx) => {
            if (initialComparingNames.includes(name)) {
                if (level === 'floor' && this.configDataService.currentOrganization === 'MBK'){
                    initialColor.push(configDataService.FLOOR_LINE_CHART_COLOR_LIST[name]);   
                } else if (level === 'zone' && this.configDataService.currentOrganization === 'MBK') {
                    initialColor.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                } else {
                    initialColor.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                }
                return true;
            }
            return false;
        }).map(([name, data], idx) => ({
            points: data,
            color: initialColor[idx],
            // isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: configDataService.TIME_LIST.map(time => `${this.getDisplayName(name)}: ${time}`),
        })));

        // eslint-disable-next-line max-len
        subscription.add(combineLatest([graphDataService.buildingEntranceByHour$, graphDataService.floorEntranceByHour$, graphDataService.zoneEntranceByHour$, combineLatest([graphDataService.baseGraphData.comparingBuildingState$, graphDataService.baseGraphData.comparingFloorState$, graphDataService.baseGraphData.comparingZoneState$])]).subscribe(
            ([buildingEntranceByHourData, floorEntranceByHourData, zoneEntranceByHourData, [comparingBuildingState, comparingFloorState, comparingZoneState]]) => {
                if ((!buildingEntranceByHourData && level === 'building') || (!floorEntranceByHourData && level === 'floor') || (!zoneEntranceByHourData && level === 'zone')) { return; }
                const comparingNames = level === 'building' ? this.getComparingNames(comparingBuildingState) : (level === 'floor' ? this.getComparingNames(comparingFloorState) : this.getComparingNames(comparingZoneState));
                const fullData = getTrafficData(buildingEntranceByHourData, floorEntranceByHourData, zoneEntranceByHourData);
                const chartColors: string[] = [];
                const lineChartData: GenericLineChartData[] = Object.entries(fullData).sort().filter(([name, _data], idx) => {
                    if (comparingNames.includes(name)) {
                        if (level === 'floor' && this.configDataService.currentOrganization === 'MBK'){
                            chartColors.push(configDataService.FLOOR_LINE_CHART_COLOR_LIST[name]);   
                        } else if (level === 'zone' && this.configDataService.currentOrganization === 'MBK') {
                            chartColors.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                        } else {
                            chartColors.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                        }
                        return true;
                    }
                    return false;
                }).map(([name, data], idx) => ({
                    points: data,
                    color: chartColors[idx],
                    // isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: configDataService.TIME_LIST.map(time => `${this.getDisplayName(name)}: ${time}`),
                }));
                chartData$.next(lineChartData);
            }));
        
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.data$ = chartData$;
        comInstance.label$ = chartLabel$;
        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.FOOT_TRAFFIC;
        return comInstance;
    }
}
