import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest, distinctUntilChanged } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { showGateCustomTooltips } from '../helpers/gateTooltips';
import { getDepthData, isScreenSmallerThanMD, isScreenSmallerThanXL } from '../helpers/util';
import { DynamicGraphAdditionalInput, GraphDataConfig, GraphInfoPopoverByPeriod } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { generateNestedData } from '../helpers/mock-data-generator';

export class EntranceExitAnalysisResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        const useVehicleParkingData = (getAdditionalInput(additionalInput, 'useVehicleParkingData')) as boolean;
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        const gateList = (getAdditionalInput(additionalInput, 'gateList') ?? []) as string[];
        // load data here
        if (useVehicleParkingData) {
            graphDataService.baseGraphData.addDependency(GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_ALL_PIN);
        } else if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }

        // await configDataService.loadAppConfig();
        const additionnalAspectRatioInput = (getAdditionalInput(additionalInput, 'aspectRatio') || { desktop: 1.0, mobile: 0.7 }) as { desktop: number; mobile: number };
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey') as string);
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const floorName = (getAdditionalInput(additionalInput, 'floor') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.FLOOR) as string;
        const sortBy = (getAdditionalInput(additionalInput, 'sortBy') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.SORT_BY || 'auto') as 'entrance' | 'exit' | 'auto';
        const filterBy = (getAdditionalInput(additionalInput, 'filterBy') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.FILTER_BY) as string;
        const contributeLevel = (getAdditionalInput(additionalInput, 'contributeLevel') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.CONTRIBUTE_LEVEL) as string;
        const channel = (getAdditionalInput(additionalInput, 'channel') || configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.CHANNEL || 'auto') as 'entrance' | 'exit' | 'auto';
        const dynamicGraphDataSelector = (getAdditionalInput(additionalInput, 'dynamicGraphDataSelector') || {}) as { [selectorName: string]: GraphDataConfig };
        const showPercentOfTotal = (getAdditionalInput(additionalInput, 'showPercentOfTotal') || {}) as boolean;
        const getFloorGateData = (value: { [buildingName: string]: { [floorName: string]: { [gateName: string]: number } } }, defaultValue = {}) => (value && value[buildingName]) ? value[buildingName] : defaultValue as { [floorName: string]: { [gateName: string]: number } };
        const showAveragePerDay = (getAdditionalInput(additionalInput, 'showAveragePerDay') || false) as boolean;
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        const toolTipDetail = (getAdditionalInput(additionalInput, 'toolTipDetail')) as string[];
        const round_max_val = (val: number) => val > 10000 ? Math.round(val / 5) : Math.round(val / 10); // TODO: properly handle data not just random if check
        const getColor = (lineName: string) => ((((configDataService.GRAPH_CONFIG.INTERACTABLE_BAR_CHART || {})[chartTitleKey] || {}).barConfig || {})[lineName] || {}) as { color: string; backgroundColor?: string };
        const graphInfoPopoverByPeriod = (getAdditionalInput(additionalInput, 'graphInfoPopoverByPeriod')) as GraphInfoPopoverByPeriod;
        const updateTextByPeriod = (getAdditionalInput(additionalInput, 'updateTextByPeriod') || false) as boolean;

        const isMock = configDataService.isFeatureEnabled('mock_data', 'building_entrance_exit_by_pin');
        let mockEntranceData: { [gateName: string]: number };
        let mockExitData: { [gateName: string]: number };
        if (isMock) {
            mockEntranceData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'BUILDING_TRAFFIC_BY_PIN', 'count', 1);
            mockExitData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'BUILDING_TRAFFIC_BY_PIN', 'count', 1);
        }

        let graphKeyName: string;
        const selectedDataSource$ = new BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>(dynamicGraphDataSelector);
        const floorInitialEntranceData = contributeLevel === 'building' ? graphDataService.allPinByBuildingEntrance$.getValue() || {} : contributeLevel === 'zone' ? graphDataService.allPinByZoneEntrance$.value || {} : getFloorGateData(graphDataService.floorGateEntranceData$.value)[floorName] || {};
        const floorInitialExitData = contributeLevel === 'building' ? graphDataService.allPinByBuildingExit$.getValue() || {} : contributeLevel === 'zone' ? graphDataService.allPinByZoneExit$.value || {} : getFloorGateData(graphDataService.floorGateExitData$.value)[floorName] || {};
        const sortedGateInitialData = sortBy === 'entrance' ? Object.fromEntries(Object.entries(floorInitialEntranceData).sort((a, b) => b[1] - a[1])) : Object.fromEntries(Object.entries(floorInitialExitData).sort((a, b) => b[1] - a[1]));
        const filteredGateInitialData = filterBy === 'TOP_5' ? Object.fromEntries(Object.entries(sortedGateInitialData).slice(0, 5)) : sortedGateInitialData;
        const initialGateNames = sortBy === 'auto' ? Object.keys(floorInitialEntranceData).sort() : Object.keys(filteredGateInitialData);
        const chartLabel = initialGateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName);

        const buildingChannelTotal: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
        const entranceInitialData = initialGateNames.map(gateName => sortBy === 'entrance' ? filteredGateInitialData[gateName] || null : floorInitialEntranceData[gateName] || null);
        const exitInitialData = initialGateNames.map(gateName => sortBy === 'exit' ? filteredGateInitialData[gateName] || null : floorInitialExitData[gateName] || null);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);

        if (isMockData) {
            let mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'ENTRANCE_EXIT_ANALYSIS', 'count', 5);
            const mockGateArray = gateList;
            dualBarData$.next([{
                data: mockData.entrance,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                calPercentFrommAllData: false
            }, {
                data: mockData.exit,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.EXIT,
                calPercentFrommAllData: false
            }]);
            subscription.add(combineLatest([viewPeriodService.dayList])
                .subscribe(async ([dayList]) => {
                    mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'ENTRANCE_EXIT_ANALYSIS', 'count', 5);

                    if (displayOnPeriodType) {
                        comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                    }
                    comInstance.chartLabel = mockGateArray.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName);
                    const dualBarData: GenericBarChartData[] = channel === 'auto' ?
                        [{
                            data: mockData.entrance,
                            color: CHART_PRIMARY_COLOR,
                            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                            calPercentFrommAllData: true,
                            calPercentFromLabelProperty: true,
                        }, {
                            data: mockData.exit,
                            color: CHART_SECONDARY_COLOR,
                            label: configDataService.DISPLAY_LANGUAGE.EXIT,
                            calPercentFrommAllData: true,
                            calPercentFromLabelProperty: true,
                        }]
                        : channel === 'entrance' ?
                            [{
                                data: mockData.entrance,
                                color: CHART_PRIMARY_COLOR,
                                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                                calPercentFrommAllData: false,
                                barPercentage: 0.7
                            }]
                            :
                            [{
                                data: mockData.exit,
                                color: CHART_PRIMARY_COLOR,
                                label: configDataService.DISPLAY_LANGUAGE.EXIT,
                                calPercentFrommAllData: false,
                                barPercentage: 0.7
                            }];
                    comInstance.suggestedTickMax_X = sortBy === 'auto' ? 0 : sortBy === 'entrance' ? mockData.entrance[0] + round_max_val(mockData.entrance[0]) : mockData.exit[0] + round_max_val(mockData.exit[0]);
                    dualBarData$.next(dualBarData);
                }));
        } else {
            dualBarData$.next([{
                data: entranceInitialData,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                calPercentFrommAllData: false
            }, {
                data: exitInitialData,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.EXIT,
                calPercentFrommAllData: false
            }]);
            subscription.add(combineLatest([
                selectedDataSource$,
                graphDataService.entranceFloorPin$, graphDataService.exitFloorPin$, graphDataService.allPinByBuildingEntrance$, graphDataService.allPinByBuildingExit$, graphDataService.allPinByZoneEntrance$, graphDataService.allPinByZoneExit$,
                graphDataService.buildingEntranceExitByPinData$, graphDataService.buildingEntranceExitByPinAvgPerDayData$,
            ])
                .pipe(
                    distinctUntilChanged()
                )
                .subscribe(([graphDataSelector, floorGateEntranceData, floorGateExitData, allPinByBuildingEntrance, allPinByBuildingExit, allPinByZoneEntrance, allPinByZoneExit, buildingEntranceExitByPinData, buildingEntranceExitByPinAvgPerDayData]) => {
                    if (graphInfoPopoverByPeriod) {
                        const infoPopoverText = graphInfoPopoverByPeriod;
                        let infoPopoverPeriod = infoPopoverText.default;
                        if (updateTextByPeriod) {
                            if (viewPeriodService.isLiveMode) {
                                infoPopoverPeriod = infoPopoverText.default;
                            } else if (viewPeriodService.isDayPeriod) {
                                infoPopoverPeriod = infoPopoverText.day;
                            } else if (viewPeriodService.isWeekPeriod) {
                                infoPopoverPeriod = infoPopoverText.week;
                            } else if (viewPeriodService.isMonthPeriod) {
                                infoPopoverPeriod = infoPopoverText.month;
                            }
                        }
                        comInstance.infoPopover = async (e: any) => {
                            const popover = await popoverController.create({
                                component: CustomTextTooltipsComponent,
                                componentProps: {
                                    toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                                    toolTipDetails: infoPopoverPeriod.details
                                },
                                cssClass: 'customer-segment-details-popover',
                                event: e,
                            });
                            return await popover.present();
                        };
                    }
                    const buildingEntranceByPinData: { [gateName: string]: number } = {};
                    const buildingExitByPinData: { [gateName: string]: number } = {};
                    if (displayOnPeriodType) {
                        comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                    }
                    if (Object.keys(dynamicGraphDataSelector).length > 0) {
                        const [graphKey, graphDataConfig] = Object.entries(graphDataSelector).find(([key, graphData]) => graphData.selected) || [];
                        graphKeyName = graphKey;
                        const filteredData = showAveragePerDay ? buildingEntranceExitByPinAvgPerDayData : buildingEntranceExitByPinData;
                        const entranceExitByPinData = getDepthData(true, filteredData, { buildingName: graphDataConfig.args[0] }) as { [pinName: string]: { entrance: number; exit: number } };
                        Object.keys(entranceExitByPinData).forEach(key => {
                            if (key !== '_total') {
                                buildingEntranceByPinData[key] = entranceExitByPinData[key].entrance;
                                buildingExitByPinData[key] = entranceExitByPinData[key].exit;
                            } else {
                                buildingChannelTotal.entrance = entranceExitByPinData[key].entrance;
                                buildingChannelTotal.exit = entranceExitByPinData[key].exit;
                            }
                        });
                    }
                    const floorEntranceData = (contributeLevel === 'building' ? allPinByBuildingEntrance : contributeLevel === 'zone' ? allPinByZoneEntrance : getFloorGateData(floorGateEntranceData)[floorName]) || {};
                    const floorExitData = (contributeLevel === 'building' ? allPinByBuildingExit : contributeLevel === 'zone' ? allPinByZoneExit : getFloorGateData(floorGateExitData)[floorName]) || {};
                    const areaEntranceData = isMock ? mockEntranceData : Object.keys(dynamicGraphDataSelector).length > 0 ? buildingEntranceByPinData : floorEntranceData;
                    const areaExitData = isMock ? mockExitData : Object.keys(dynamicGraphDataSelector).length > 0 ? buildingExitByPinData : floorExitData;
                    if (!areaEntranceData || !areaExitData) { return; }
                    const sortedGateData = sortBy === 'entrance' ? Object.fromEntries(Object.entries(areaEntranceData).sort((a, b) => b[1] - a[1])) : Object.fromEntries(Object.entries(areaExitData).sort((a, b) => b[1] - a[1]));
                    const filteredGateData = filterBy === 'TOP_5' ? Object.fromEntries(Object.entries(sortedGateData).slice(0, 5)) : sortedGateData;
                    const gateNames = gateList.length > 0 ? gateList : sortBy === 'auto' ? Object.keys(areaEntranceData).sort() : Object.keys(filteredGateData);
                    comInstance.chartLabel = gateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName);
                    const entranceData = gateNames.map(gateName => sortBy === 'entrance' ? filteredGateData[gateName] || null : areaEntranceData[gateName] || null);
                    const exitData = gateNames.map(gateName => sortBy === 'exit' ? filteredGateData[gateName] || null : areaExitData[gateName] || null);
                    const dualBarData: GenericBarChartData[] = channel === 'auto' ?
                        [{
                            data: entranceData,
                            color: CHART_PRIMARY_COLOR,
                            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                            calPercentFrommAllData: false,
                            calPercentFromLabelProperty: false,
                        }, {
                            data: exitData,
                            color: CHART_SECONDARY_COLOR,
                            label: configDataService.DISPLAY_LANGUAGE.EXIT,
                            calPercentFrommAllData: false,
                            calPercentFromLabelProperty: false,
                        }]
                        : channel === 'entrance' ?
                            [{
                                data: entranceData,
                                color: Object.keys(dynamicGraphDataSelector).length > 0 ? getColor(graphKeyName).color : CHART_PRIMARY_COLOR,
                                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                                calPercentFrommAllData: false,
                                barPercentage: 0.7
                            }]
                            :
                            [{
                                data: exitData,
                                color: Object.keys(dynamicGraphDataSelector).length > 0 ? getColor(graphKeyName).color : CHART_PRIMARY_COLOR,
                                label: configDataService.DISPLAY_LANGUAGE.EXIT,
                                calPercentFrommAllData: false,
                                barPercentage: 0.7
                            }];
                    comInstance.suggestedTickMax_X = sortBy === 'auto' ? 0 : sortBy === 'entrance' ? entranceData[0] + round_max_val(entranceData[0]) : exitData[0] + round_max_val(exitData[0]);
                    dualBarData$.next(dualBarData);
                }));
        }
        comInstance.suggestedTickMax_X = sortBy === 'auto' ? 0 : sortBy === 'entrance' ? entranceInitialData[0] + round_max_val(entranceInitialData[0]) : exitInitialData[0] + round_max_val(exitInitialData[0]);
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || configDataService.DISPLAY_LANGUAGE.ENTRANCE_EXIT_ANALYSIS;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = channel === 'auto';
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.isHorizontal = true;
        comInstance.aspectRatio = isScreenSmallerThanXL(window) ? additionnalAspectRatioInput.mobile : additionnalAspectRatioInput.desktop;
        comInstance.valueTextAlign = 'left';
        comInstance.valueTextOffsetX = isScreenSmallerThanMD(window) ? 1 : 5;
        comInstance.valueTextOffsetY = 5;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.displayAxis = 'Y';
        comInstance.isStacked = '!XY';
        comInstance.displayGrid = channel === 'auto';
        comInstance.selectedBar$ = selectedDataSource$;
        comInstance.selectInterface = 'alert';
        comInstance.selector = dynamicGraphDataSelector;
        comInstance.multipleSelector = false;
        // comInstance.showAveragePerDay = showAveragePerDay;
        const imageSrc = configDataService.isFeatureEnabled('traffic_page', 'gate_image_flatten') ? configDataService.entranceExitPinImageSrc : configDataService.entranceExitGateImageSrc;
        comInstance.customToolTipFuction = function(tooltipModel) {
            (tooltipModel as any).floorName = floorName;
            const channelName: 'entrance' | 'exit' | 'auto' = channel === 'auto' ? 'entrance' : channel;
            if (Object.keys(dynamicGraphDataSelector).length > 0) {
                showGateCustomTooltips(tooltipModel, this, imageSrc, configDataService.DISPLAY_LANGUAGE.GATE_NAME, channelName, showPercentOfTotal, buildingChannelTotal);
            } else {
                showGateCustomTooltips(tooltipModel, this, imageSrc, configDataService.DISPLAY_LANGUAGE.GATE_NAME, channelName);
            }
        };
        if (graphInfoPopoverByPeriod) {
            const infoPopoverText = graphInfoPopoverByPeriod;
            let infoPopoverPeriod = infoPopoverText.default;
            if (updateTextByPeriod) {
                if (viewPeriodService.isLiveMode) {
                    infoPopoverPeriod = infoPopoverText.default;
                } else if (viewPeriodService.isDayPeriod) {
                    infoPopoverPeriod = infoPopoverText.day;
                } else if (viewPeriodService.isWeekPeriod) {
                    infoPopoverPeriod = infoPopoverText.week;
                } else if (viewPeriodService.isMonthPeriod) {
                    infoPopoverPeriod = infoPopoverText.month;
                }
            }
            comInstance.infoPopover = async (e: any) => {
                const popover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: infoPopoverPeriod.details
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await popover.present();
            };
        }
        return comInstance;
    }
}

