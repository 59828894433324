import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput, GraphDataConfig, GraphInfoPopoverByPeriod } from '../objects/config';
import { showGenericBarChartCustomTooltips } from '../helpers/barChartTooltips';
import { getDepthData } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { generateNestedData } from '../helpers/mock-data-generator';

export class EntranceExitPerTwoHourResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const channelType = (getAdditionalInput(additionalInput, 'channel') || 'auto') as 'entrance' | 'exit' | 'auto';
        const dynamicGraphDataSelector = (getAdditionalInput(additionalInput, 'dynamicGraphDataSelector') || {}) as { [selectorName: string]: GraphDataConfig };
        const showAveragePerDay = (getAdditionalInput(additionalInput, 'showAveragePerDay') || false) as boolean;
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey') as string);
        const toolTipDetail = (getAdditionalInput(additionalInput, 'toolTipDetail') as string[]);
        const groupDataByQuad = (getAdditionalInput(additionalInput, 'groupDataByQuad') as boolean);
        const graphInfoPopoverByPin = (getAdditionalInput(additionalInput, 'graphInfoPopoverByPeriod')) as GraphInfoPopoverByPeriod;
        const updateTextByPeriod = (getAdditionalInput(additionalInput, 'updateTextByPeriod') || false) as boolean;

        const wantedTimeList = ['10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', '19.00', '20.00', '21.00', '22.00'];
        const wantedTimeListBreakPoint = ['10.00', '14.00', '18.00', '22.00'];
        const wantedTrafficDataIndex: any[] = [];
        const biDataGrouper = (prev: any[], current: any, idx: number) => {
            const currentArray = prev[prev.length - 1];
            if (currentArray.length === 3) {
                prev.push([currentArray[currentArray.length - 1]]);
                prev[prev.length - 1].push(current);
            } else {
                prev[prev.length - 1].push(current);
            }
            return prev;
        };
        const quadDataGrouper = (prev: any[], current: any, idx: number) => {
            if (!wantedTimeList.includes(current)) {
                return prev;
            } else if (wantedTimeListBreakPoint.includes(current)) {
                wantedTrafficDataIndex.push(idx);
            }

            const currentArray = prev[prev.length - 1];
            if (currentArray.length === 5) {
                // if (currentArray.length === 3) {
                prev.push([currentArray[currentArray.length - 1]]);
                prev[prev.length - 1].push(current);
            } else {
                prev[prev.length - 1].push(current);
            }
            return prev;
        };
        const calBiDataSumShiftOne = (prev: number[], current: any, idx: number) => {
            if (idx === 0) { return prev; } // shift by one value
            if (idx % 2 === 0) {
                prev.push(prev.pop() + current);
            } else {
                prev.push(current);
            }
            return prev;
        };
        const calBiDataSum = (prev: number[], current: any, idx: number, data: number[]) => {
            if (idx % 2 === 1) {
                prev.push(prev.pop() + current);
            } else {
                if (idx <= data.length - 1) {
                    prev.push(current);
                }
            }
            // if (idx === data.length - 1) {
            //     prev[prev.length - 1] += data[data.length - 1];
            // }
            return prev;
        };
        const calQuadDataSum = (prev: number[], current: any, idx: number, data: number[]) => {
            if (idx < wantedTrafficDataIndex[0] || idx > wantedTrafficDataIndex[wantedTrafficDataIndex.length - 1]) {
                return prev;
            }

            if (wantedTrafficDataIndex.includes(idx + 1) && idx !== wantedTrafficDataIndex[0]) {
                prev.push(prev.pop() + prev.pop() + prev.pop() + current);
            } else {
                prev.push(current);
            }
            return prev;
        };
        // calculate sum of first 3 data and sum other data by the other 2 data at a time.
        const calBiDataWithFirstThreeDataSumShiftOne = (prev: number[], current: any, idx: number) => {
            if (idx < 2) {
                prev.push(current);
            } // shift by one value
            else if (idx === 2) {
                prev.push(prev[0] + prev[1] + current);
                prev.shift();
                prev.shift();
            }
            else if (idx % 2 === 0) {
                prev.push(prev.pop() + current);
            } else {
                prev.push(current);
            }
            return prev;
        };
        const getColor = (lineName: string) => ((((configDataService.GRAPH_CONFIG.INTERACTABLE_BAR_CHART || {})[chartTitleKey] || {}).barConfig || {})[lineName] || {}) as { color: string; backgroundColor?: string };
        // const chartLabel = configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 3).map(([t1, _, t2]) => `${t1}-${t2}`);
        const chartLabel = configDataService.TIME_LIST.reduce(groupDataByQuad ? quadDataGrouper : biDataGrouper, [[]]).filter((arr: any[]) => arr.length === (groupDataByQuad ? 5 : 3)).map((arr) => `${arr[0]}-${arr[arr.length - 1]}`);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);
        const selectedDataSource$ = new BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>(dynamicGraphDataSelector);
        if (isMockData) {
            let mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'ENTRANCE_EXIT_BIHOURS', 'count', 14);
            const initialdualBarData = [{
                data: mockData.entrance.reduce(calBiDataSum, []),
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            }, {
                data: mockData.exit.reduce(calBiDataSum, []),
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.EXIT,
            }];
            let filteredInitialBarData = initialdualBarData;
            if (channelType !== 'auto') {
                filteredInitialBarData = initialdualBarData.filter(barData => barData.label.toLocaleLowerCase() === channelType);
            }
            dualBarData$.next(filteredInitialBarData);

            subscription.add(combineLatest([viewPeriodService.dayList]).subscribe(async combineResult => {
                mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'ENTRANCE_EXIT_BIHOURS', 'count', 14);
                if (displayOnPeriodType) {
                    comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                }
                const dualBarData = [{
                    data: mockData.entrance.reduce(calBiDataSum, []),
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                }, {
                    data: mockData.exit.reduce(calBiDataSum, []),
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.EXIT,
                }];
                let filteredBarData = dualBarData.map(dataBar => {
                    if (dataBar.data.length > chartLabel.length) {
                        const lastIndexOfData = dataBar.data.pop();
                        dataBar.data[dataBar.data.length - 1] = dataBar.data[dataBar.data.length - 1] + lastIndexOfData;
                        return {
                            data: dataBar.data,
                            color: dataBar.color,
                            label: dataBar.label
                        };
                    }
                    return dataBar;
                });
                if (channelType !== 'auto') {
                    filteredBarData = dualBarData.filter(barData => barData.label.toLocaleLowerCase() === channelType);
                }
                dualBarData$.next(filteredBarData);
            }));
        } else {
            const entranceData = configDataService.isFeatureEnabled('graph_data', 'contribute_area') ? getDepthData(true, graphDataService.buildingAreaEntranceExitByHourBreakdown$.value, { buildingName }) as number[] : graphDataService.entranceByHour$.value;
            const exitData = configDataService.isFeatureEnabled('graph_data', 'contribute_area') ? getDepthData(false, graphDataService.buildingAreaEntranceExitByHourBreakdown$.value, { buildingName }) as number[] : graphDataService.exitByHour$.value;

            const initialEntranceData = entranceData instanceof Array
                ? configDataService.isFeatureEnabled('mall_traffic_overview', 'calculate_hourly_data') === 'bi_shift_first_data'
                    ? entranceData.reduce(calBiDataSumShiftOne, []) : entranceData.reduce(groupDataByQuad ? calQuadDataSum : calBiDataSum, []) : [];
            const initialExitData = exitData instanceof Array ? configDataService.isFeatureEnabled('mall_traffic_overview', 'calculate_hourly_data') === 'bi_shift_first_data' ? exitData.reduce(calBiDataSumShiftOne, []) : exitData.reduce(groupDataByQuad ? calQuadDataSum : calBiDataSum, []) : [];
            selectedDataSource$.next(dynamicGraphDataSelector);
            const initialdualBarData = [{
                data: initialEntranceData,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            }, {
                data: initialExitData,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.EXIT,
            }];
            let filteredInitialBarData = initialdualBarData;
            if (channelType !== 'auto') {
                filteredInitialBarData = initialdualBarData.filter(barData => barData.label.toLocaleLowerCase() === channelType);
            }

            dualBarData$.next(filteredInitialBarData);
            const dataBehaviorSubjects$: BehaviorSubject<unknown>[] = dynamicGraphDataSelector
                ? [selectedDataSource$, graphDataService.entranceByHour$, graphDataService.exitByHour$, graphDataService.buildingAreaEntranceExitByHourBreakdown$, graphDataService.buildingEntranceExitByHourAvgPerDay$]
                : [graphDataService.entranceByHour$, graphDataService.exitByHour$, graphDataService.buildingAreaEntranceExitByHourBreakdown$];
            subscription.add(combineLatest(dataBehaviorSubjects$).subscribe(combineResult => {
                if (graphInfoPopoverByPin) {
                    const infoPopoverText = graphInfoPopoverByPin;
                    let infoPopoverPeriod = infoPopoverText.default
                    if (updateTextByPeriod) {
                        if (viewPeriodService.isLiveMode) {
                            infoPopoverPeriod = infoPopoverText.default
                        } else if (viewPeriodService.isDayPeriod) {
                            infoPopoverPeriod = infoPopoverText.day
                        } else if (viewPeriodService.isWeekPeriod) {
                            infoPopoverPeriod = infoPopoverText.week
                        } else if (viewPeriodService.isMonthPeriod) {
                            infoPopoverPeriod = infoPopoverText.month
                        }
                    }
                    comInstance.infoPopover = async (e: any) => {
                        const popover = await popoverController.create({
                            component: CustomTextTooltipsComponent,
                            componentProps: {
                                toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                                toolTipDetails: infoPopoverPeriod.details,
                            },
                            cssClass: 'customer-segment-details-popover',
                            event: e,
                        });
                        return await popover.present();
                    };
                }
                const buildingEntranceExitByHour = dynamicGraphDataSelector ? combineResult[3] : combineResult[2];
                const buildingAvgEntranceExitByHour = combineResult[4];
                const entranceExitByHour = showAveragePerDay ? buildingAvgEntranceExitByHour : buildingEntranceExitByHour;
                const entraceByHour = dynamicGraphDataSelector ? combineResult[1] : combineResult[0];
                const exitByHour = dynamicGraphDataSelector ? combineResult[2] : combineResult[1];
                const graphDataSelector = combineResult[0] as { [selectorName: string]: GraphDataConfig };
                if (displayOnPeriodType) {
                    comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                }
                let dualBarEntranceData: number[];
                let dualBarExitData: number[];
                let graphKeyName: string;
                if (Object.keys(dynamicGraphDataSelector).length > 0) {
                    const [graphKey, graphDataConfig] = Object.entries(graphDataSelector).find(([key, graphData]) => graphData.selected) || [];
                    graphKeyName = graphKey;
                    dualBarEntranceData = configDataService.isFeatureEnabled('graph_data', 'contribute_area') ? getDepthData(true, entranceExitByHour, { buildingName: graphDataConfig.args[0] }) as number[] : entraceByHour as number[];
                    dualBarExitData = configDataService.isFeatureEnabled('graph_data', 'contribute_area') ? getDepthData(false, entranceExitByHour, { buildingName: graphDataConfig.args[0] }) as number[] : exitByHour as number[];
                } else {
                    dualBarEntranceData = configDataService.isFeatureEnabled('graph_data', 'contribute_area') ? getDepthData(true, buildingEntranceExitByHour, { buildingName }) as number[] : entraceByHour as number[];
                    dualBarExitData = configDataService.isFeatureEnabled('graph_data', 'contribute_area') ? getDepthData(false, buildingEntranceExitByHour, { buildingName }) as number[] : exitByHour as number[];
                }
                const dualBarData = [{
                    data: dualBarEntranceData instanceof Array
                        ? configDataService.isFeatureEnabled('mall_traffic_overview', 'calculate_hourly_data') === 'bi_shift_first_data'
                            ? dualBarEntranceData.reduce(calBiDataSumShiftOne, [])
                            : dualBarEntranceData.reduce(groupDataByQuad ? calQuadDataSum : calBiDataSum, []) : [],
                    color: Object.keys(dynamicGraphDataSelector).length > 0 ? getColor(graphKeyName).color : CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                }, {
                    data: dualBarExitData instanceof Array
                        ? configDataService.isFeatureEnabled('mall_traffic_overview', 'calculate_hourly_data') === 'bi_shift_first_data'
                            ? dualBarExitData.reduce(calBiDataSumShiftOne, [])
                            : dualBarExitData.reduce(groupDataByQuad ? calQuadDataSum : calBiDataSum, []) : [],
                    color: Object.keys(dynamicGraphDataSelector).length > 0 ? getColor(graphKeyName).color : CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.EXIT,
                }];
                let filteredBarData = dualBarData.map(dataBar => {
                    if (dataBar.data.length > chartLabel.length) {
                        const lastIndexOfData = dataBar.data.pop();
                        dataBar.data[dataBar.data.length - 1] = dataBar.data[dataBar.data.length - 1] + lastIndexOfData;
                        return {
                            data: dataBar.data,
                            color: dataBar.color,
                            label: dataBar.label
                        };
                    }
                    return dataBar;
                });
                if (channelType !== 'auto') {
                    filteredBarData = dualBarData.filter(barData => barData.label.toLocaleLowerCase() === channelType);
                }
                dualBarData$.next(filteredBarData);
            }));
        }

        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || configDataService.DISPLAY_LANGUAGE.ENTRANCE_EXIT_BIHOURS;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = channelType === 'auto';
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.xAxesRotation = 90;
        comInstance.displayAxis = 'X';
        comInstance.valueTextAlign = 'center';
        comInstance.valueTextOffsetX = 1;
        comInstance.displayGrid = false;
        comInstance.selectedBar$ = selectedDataSource$;
        comInstance.selector = dynamicGraphDataSelector;
        comInstance.multipleSelector = false;
        comInstance.selectInterface = 'alert';
        // comInstance.showAveragePerDay = showAveragePerDay;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showGenericBarChartCustomTooltips(tooltipModel, this, null, 'toLocaleString', 'chartDataLabel', 3);
        };
        if (graphInfoPopoverByPin) {
            const infoPopoverText = graphInfoPopoverByPin;
            let infoPopoverPeriod = infoPopoverText.default
            if (updateTextByPeriod) {
                if (viewPeriodService.isLiveMode) {
                    infoPopoverPeriod = infoPopoverText.default
                } else if (viewPeriodService.isDayPeriod) {
                    infoPopoverPeriod = infoPopoverText.day
                } else if (viewPeriodService.isWeekPeriod) {
                    infoPopoverPeriod = infoPopoverText.week
                } else if (viewPeriodService.isMonthPeriod) {
                    infoPopoverPeriod = infoPopoverText.month
                }
            }
            comInstance.infoPopover = async (e: any) => {
                const popover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: infoPopoverPeriod.details,
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await popover.present();
            };
        }
        return comInstance;
    }

}
