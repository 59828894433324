import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';

const CHART_COLOR = '#E63739';

export class FloorAvgTimeSpentResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency); // ENTRANCE_EXIT, ENTRANCE_EXIT_FLOOR, ENTRANCE_EXIT_ZONE
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const zoneName = graphDataService.baseGraphData.selectedDirectory$.value?.zone || (getAdditionalInput(additionalInput, 'zone')) as string;
        const initialfloorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const getAvgTimeSpentData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [zoneName: string]: number[] } } },
            floorName?: string,
            depthZoneName?: string,
        ) => accessDepthData<number[]>(data, buildingName, floorName, depthZoneName, Array.from({ length: 7 }).map(() => null));
        const initialAllData = getAvgTimeSpentData(graphDataService.averageTimeSpentChartData$.value);
        const initialFloorData = getAvgTimeSpentData(graphDataService.avgTimeSpentFloorData$.value, initialfloorName);
        const initialZoneData = getAvgTimeSpentData(graphDataService.avgTimeSpentZoneData$.value, initialfloorName, zoneName);

        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: (initialIsAll ? initialAllData : (!zoneName ? initialFloorData : initialZoneData)).map(val => val ? Math.round(val / 60) : val),
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST,
            label: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND,
        }]);

        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.averageTimeSpentChartData$, graphDataService.avgTimeSpentFloorData$, graphDataService.avgTimeSpentZoneData$]).subscribe(
            ([selectedFloorName, selectedDirectory, allData, floorData, zoneData]) => {
                if (!allData || !floorData) { return; }
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const floorName = selectedDirectory?.floor || selectedFloorName || 'ALL';
                const isAll = floorName === 'ALL';
                const nextZoneName = selectedDirectory?.zone || zoneName;
                const lineChartData: GenericLineChartData[] = [{
                    points: (isAll ? getAvgTimeSpentData(allData) : (!nextZoneName ? getAvgTimeSpentData(floorData, floorName) : getAvgTimeSpentData(zoneData, floorName, nextZoneName))).map(val => val ? Math.round(val / 60) : val),
                    backgroundColor: `${CHART_COLOR}22`,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND},${time}`),
                    label: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND,
                }];
                chartData$.next(lineChartData);
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        comInstance.enableTBD = true;
        // comInstance.sizeXS = '12';
        // comInstance.sizeMD = '10';
        // comInstance.offsetMD = '1';
        return comInstance;
    }

}
