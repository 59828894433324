/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { filterSelectedDateData, compare1DepthObjects, isSelectionVisitorProfileAll, sumArrays, manipulateArrays } from '../helpers/util';
import { ViewPeriodService } from './view-period.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { BehaviorSubject, Subscription, lastValueFrom } from 'rxjs';
import {
  IFetchData, TouristData, EntranceExitByHourData, EntranceExitAccumulate, VisitorProfileData, CustomerSegregationData, RepeatedVisitorData, FrequencyOfVisitData,
  ModeOfTransportData, VehicleByProvinceData, EntranceExitByFloorByGateByHourData, PurchaseRateData, GroupProfile, PurchaseRateProfileData, AverageTimeSpentProfileData,
  EntranceExitNetShopTimeSpent, NetShoppingTimeProfileData, CarEntranceExitByHourData, BuildingEntranceExitByFloorData, EntranceExitByFloorByHourData, WeatherData,
  TrafficCongestionData, BuildingEntranceExitNetShopTimeSpent, BuildingZoneEntranceExitData, BuildingEntranceExitFlowData, ZoneEntranceExitFlowData, FloorEntranceExitFlowData,
  EntranceExitByFloorByZoneByHourData, ProvinceUnGroupData, EntranceExitByFloorByZoneByStoreByHourData, AreaVisitorProfileData, Fetchable, BuildingStoreEntranceExitData, AreaPurchaseRateProfileData, EntranceExitByPinByHourData, EntranceExitByFloorByPinByHourData,
  EntranceExitFloorZoneByPinByHourData, MaskGroupData, AreaProximityGroupData, CarBrandData, VehiclePurchasingPowerData, FloorHeatMapData, Model3dDetail, PlateTimespentData, BuildingAverageDayTypeData, FrequencyOfVisitWithPurchasingPowerData,
  AreaVisitorProfileSampleData, UniqueVisitorsData, GroupData, areaAverageDayTypeData, vehicleProfileGroup, AreaVehicleParkingData, MockStoreCountData, LidarData, LidarByHourData, SankeyRawLinks, GroupCountData, AreaEntranceExitProximityGroupData, StoreAreaData, AreaTrafficBreakdownData, VehicleParkingUniqueAreaVisitData, RecencyFrequencyData, HourlyDataAverages, DayOfWeekAverages, FrequencyOfVisitProfileData, RepeatedVisitorProfileData, RecencyFrequencyProfileData, PlateTimespentProfileData
} from '../objects/fetchObejct';
import { ConfigDataService, TIME_LIST } from './config-data.service';
import { VisitorProfileSelection } from '../objects/visitor-profile';
import { ViewPeriod } from '../objects/view-period';
import { LoadingService } from './loading.service';
import { SelectableData, SelectableDataName } from '../objects/selectableData';
import { InteractableType } from '../objects/trafficInteractable';
import { HeatMapPoints } from '../helpers/floorMapCanvasManipulator';
import { GlobalUiService } from './global-ui.service';
import { Router } from '@angular/router';
import { storeType } from '../objects/store';
import { Billboard } from '../objects/billboard';
import { randFloat, randInt } from 'three/src/math/MathUtils';
import { BaseDataDependency, BaseGraphData } from '../objects/baseGraphData';
import { processChartData } from '../helpers/chartUtils';
import { DataService } from '../data.service';
import { generateNestedData } from '../helpers/mock-data-generator';

const MAX_RETRY_FETCH = 30;
type CurrentTrendNumberCard = { current: number; diff: number; diffPercent: number };

@Injectable({
  providedIn: 'root'
})
export class GraphDataService {
  readonly availableDependency = new Set<GraphDependency>();
  readonly subscription = new Subscription();
  readonly graphDependencyToLoader: { [dependency in GraphDependency]: (date: moment.Moment, instance?: GraphDataService) => Promise<void> } = {
    [GraphDependency.DYNAMIC]: null,
    [GraphDependency.ENTRANCE_EXIT]: this.loadEntranceExitData,
    [GraphDependency.ENTRANCE_EXIT_HOUR]: this.loadEntranceExitByHourData,
    [GraphDependency.TOURIST]: this.loadTouristData,
    [GraphDependency.PARKING_CUSTOMER]: this.loadParkingCustomerData,
    [GraphDependency.VISITOR_PROFILE]: this.loadVisitorProfileData,
    [GraphDependency.CUSTOMER_SEGREGATION]: this.loadCustomerSegregationData,
    [GraphDependency.REPETATED_VISITORS]: this.loadRepeatedVisitorsData,
    [GraphDependency.FREQUENCY_OF_VISIT]: this.loadFrequencyOfVisitData,
    [GraphDependency.MODE_OF_TRANSPORTATION]: this.loadModeOfTransportData,
    [GraphDependency.VEHICLE_BY_PROVINCE]: this.loadVehicleByProvinceData,
    [GraphDependency.FLOOR_ENTRANCE_EXIT_GATE_HOUR]: this.loadEntranceExitByFloorByGateByHourData,
    [GraphDependency.PURCHASE_RATE]: this.loadPurchaseRateData,
    [GraphDependency.PURCHASE_RATE_PROFILE]: this.loadPurchaseRateProfileData,
    [GraphDependency.AVERAGE_TIME_SPENT_PROFILE]: this.loadAverageTimeSpentProfileData,
    [GraphDependency.NET_SHOPPING_TIME_PROFILE]: this.loadNetShoppingTimeProfileData,
    [GraphDependency.ENTRANCE_EXIT_MONTH]: this.loadEntranceExitMonthData,
    [GraphDependency.TRAFFIC_CONGESTION]: this.loadTrafficCongestionData,
    [GraphDependency.CAR_ENTRANCE_EXIT_HOUR]: this.loadCarEntranceExitByHourData,
    [GraphDependency.ENTRANCE_EXIT_FLOOR]: this.loadEntranceExitFloorData,
    [GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR]: this.loadEntranceExitFloorHourData,
    [GraphDependency.WEATHER_MONTH]: this.loadWeatherMonthData,
    [GraphDependency.ENTRANCE_EXIT_ZONE]: this.loadEntranceExitZoneData,
    [GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING]: this.loadBuildingEntranceExitFlowData,
    [GraphDependency.ENTRANCE_EXIT_FLOW_FLOOR]: this.loadFloorEntranceExitFlowData,
    [GraphDependency.ENTRANCE_EXIT_FLOW_ZONE]: this.loadZoneEntranceExitFlowData,
    [GraphDependency.ENTRANCE_EXIT_ZONE_HOUR]: this.loadEntranceExitFloorZoneHourData,
    [GraphDependency.VEHICLE_BY_PROVINCE_UNGROUP]: this.loadProvinceUngroupData,
    [GraphDependency.STORE_VISITOR_PROFILE]: this.loadStoreVisitorProfileData,
    [GraphDependency.ENTRANCE_EXIT_STORE_HOUR]: this.loadEntranceExitFloorZoneStoreHourData,
    [GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR_BREAKDOWN_2]: this.loadEntranceExitFloorZoneStoreHourData,
    [GraphDependency.ENTRANCE_EXIT_STORE]: this.loadEntranceExitStoreData,
    [GraphDependency.STORE_PURCHASE_RATE_PROFILE]: this.loadStorePurchaseRateProfileData,
    [GraphDependency.ENTRANCE_EXIT_PIN_HOUR]: this.loadEntranceExitByPinByHourData,
    [GraphDependency.ENTRANCE_EXIT_FLOOR_PIN_HOUR]: this.loadEntranceExitFloorByPinByHourData,
    [GraphDependency.ENTRANCE_EXIT_ZONE_PIN_HOUR]: this.loadEntranceExitZoneByPinByHourData,
    [GraphDependency.MASK_COUNT]: this.loadMaskCountData,
    [GraphDependency.FLOOR_HEATMAP]: this.loadFloorHeatmapData,
    [GraphDependency.STORE_PROXIMITY_TRAFFIC]: this.loadStoreProximityTrafficData,
    [GraphDependency.PREDICTION_STORE_PROXIMITY_TRAFFIC]: this.loadPredictionStoreProximityTrafficData,
    [GraphDependency.PREDICTION_ENTRANCE_EXIT]: this.loadPredictionBuildingTrafficData,
    [GraphDependency.PREDICTION_ENTRANCE_EXIT_FLOOR]: this.loadPredictionEntranceExitFloorData,
    [GraphDependency.PREDICTION_ENTRANCE_EXIT_ZONE]: this.loadPredictionEntranceExitZoneData,
    [GraphDependency.CAR_BRAND]: this.loadCarBrandData,
    [GraphDependency.VEHICLE_PURCHASING_POWER]: this.loadVehiclePurchasingPowerData,
    [GraphDependency.MODE_OF_TRANSPORTATION_BY_HOUR]: this.loadModeOfTransportByHourData,
    [GraphDependency.PREDICTION_VISITOR_PROFILE]: this.loadPredictionVisitorProfileData,
    [GraphDependency.MONTH_ENTRANCE_EXIT]: this.loadMonthEntranceExitData,
    [GraphDependency.MONTH_ENTRANCE_EXIT_ZONE]: this.loadMonthEntranceExitZoneData,
    [GraphDependency.MONTH_VISITOR_PROFILE]: this.loadMonthVisitorProfileData,
    [GraphDependency.MULTIPLE_DAY_CUSTOM_SEGMENTATION]: this.loadMultipleDayCustomerSegregationData,
    [GraphDependency.ZONE_VISITOR_PROFILE]: this.loadZoneVisitorProfileData,
    [GraphDependency.MAP_MODEL3D_INFO]: this.loadModel3dDetail,
    [GraphDependency.PLATE_TIMESPENT]: this.loadPlateTimespentData,
    [GraphDependency.BUILDING_AVERAGE_DAY_TYPE]: this.loadBuildingEntranceExitAverageDayType,
    [GraphDependency.FREQ_VISIT_PURCHASING_POWER]: this.loadFrequencyOfVisitWithPurchasingPowerData,
    [GraphDependency.FREQ_VISIT_SEMI_ANNUAL]: this.loadFrequencyOfVisitSemiAnnualData,
    [GraphDependency.ZONE_VISITOR_PROFILE_SAMPLE]: this.loadZoneVisitorProfileSampleData,
    [GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS]: this.loadVisitorProfileSimpleCrossData,
    [GraphDependency.VISITOR_PROFILE_TWO_CROSS]: this.loadVisitorProfileTwoCrossData,
    [GraphDependency.MESSENGER_BRAND]: this.loadMessengerBrandData,
    [GraphDependency.PURCHASAE_BAG_COLOR]: this.loadPurchaseBagColorData,
    [GraphDependency.MESSENGER_BRAND_BY_HOUR]: this.loadMessengerBrandbyHourData,
    [GraphDependency.BUILDING_ENTRANCE_EXIT_SUM_BY_DAY]: this.loadBuildingEntranceExitSumByDayData,
    [GraphDependency.FREQ_VISIT_SEMI_ANNUAL_V2]: this.loadFrequencyOfVisitSemiAnnualV2Data,
    [GraphDependency.FREQ_VISIT_SEMI_ANNUAL_SUM_BY_DAY]: this.loadFrequencyOfVisitSemiAnnualSumByDayData,
    [GraphDependency.VEHICLE_PURCHASING_POWER_SUM_BY_DAY]: this.loadVehiclePurchasingPowerSumByDayData,
    [GraphDependency.VEHICLE_UNIQUE_VISITORS]: this.loadVehicleUniqueVisitorsData,
    [GraphDependency.TRAFFIC_SITE_COUNT]: this.loadTrafficSiteCountData,
    [GraphDependency.TRAFFIC_SITE_UNIQUE_VISITOR]: this.loadTrafficSiteUniqueVisitorData,
    [GraphDependency.TRAFFIC_SITE_PURCHASING_POWER]: this.loadTrafficSitePurchasingPowerData,
    [GraphDependency.TRAFFIC_SITE_MODE_TRANSPORTATION]: this.loadTrafficSiteModeOfTransportData,
    [GraphDependency.TRAFFIC_SITE_CAR_BRAND]: this.loadTrafficSiteCarBrandData,
    [GraphDependency.TRAFFIC_SITE_AVG_COUNT_BY_DAY_TYPE]: this.loadTrafficSiteAvgByDayTypeCountData,
    [GraphDependency.TRAFFIC_SITE_MODE_TRANSPORTATION_PUBLIC_PRIVATE]: this.loadTrafficSiteModeOfTransportPublicPrivateData,
    [GraphDependency.TRAFFIC_SITE_COUNT_BY_HOUR]: this.loadTrafficSiteCountByHourData,
    [GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE]: this.loadTrafficSiteVehicleProfileData,
    [GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_BY_HOUR]: this.loadTrafficSiteVehicleProfileByHourData,
    [GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE]: this.loadTrafficSiteVehicleProfilePublicPrivateData,
    [GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR]: this.loadTrafficSiteVehicleProfileUniqueVisitorData,
    [GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE]: this.loadTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData,
    [GraphDependency.GOAL_ZONE_ENTRANCE_EXIT]: this.loadGoalEntranceExitZoneData,
    [GraphDependency.VEHICLE_PARKING_CAR_BRAND]: this.loadVehicleParkingCarBrandData,
    [GraphDependency.VEHICLE_PARKING_PROVINCE]: this.loadVehicleParkingProvinceData,
    [GraphDependency.VEHICLE_PARKING_PROVINCE_UNGROUP]: this.loadVehicleParkingProvinceUngroupData,
    [GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION]: this.loadVehicleParkingModeofTransportData,
    [GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_BY_HOUR]: this.loadVehicleParkingModeofTransportbyHourData,
    [GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT]: this.loadVehicleParkingEntranceExitData,
    [GraphDependency.VEHICLE_PARKING_PURCHASING_POWER]: this.loadVehicleParkingPurchasingPowerData,
    [GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_P2]: this.loadVehicleParkingEntranceExitPKP2Data,
    [GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_P2]: this.loadVehicleParkingPKP2ModeofTransportData,
    [GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_P3]: this.loadVehicleParkingEntranceExitPKP3Data,
    [GraphDependency.MOCK_STORE_COUNT]: this.loadMockStoreCountData,
    [GraphDependency.MOCK_STORE_VISITOR]: this.loadMockStoreVisitorData,
    [GraphDependency.MOCK_STORE_VISITOR_BY_HOUR]: this.loadMockStoreVisitorByHourData,
    [GraphDependency.MOCK_STORE_COUNT_AVG_BY_DAY_TYPE]: this.loadMockStoreCountAverageByDayTypeData,
    [GraphDependency.MOCK_STORE_VISITOR_AVG_BY_DAY_TYPE]: this.loadMockStoreVisitorAverageByDayTypeData,
    [GraphDependency.MOCK_ADS_VISITOR_PROFILE]: this.loadMockAdsVisitorProfileData,
    [GraphDependency.MOCK_ADS_VISITOR_PROFILE_BY_HOUR]: this.loadMockAdsVisitorProfileByHourData,
    [GraphDependency.TRAFFIC_SITE_VISUALIZATION_VIDEOS]: this.loadTrafficSiteVisualizationVideoData,
    [GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT]: this.loadLidarAreaEngagementCountData,
    [GraphDependency.LIDAR_AREA_CONVERSION_RATE]: this.loadLidarAreaConversionRateData,
    [GraphDependency.LIDAR_AREA_ENGAGEMENT_MINUTES]: this.loadLidarAreaEngagementMinutesData,
    [GraphDependency.LIDAR_AREA_IMPRESSION]: this.loadLidarAreaImpressionData,
    [GraphDependency.LIDAR_AREA_REACH]: this.loadLidarAreaReachData,
    [GraphDependency.LIDAR_AREA_IMPRESSION_BY_HOUR]: this.loadLidarAreaImpressionByHourData,
    [GraphDependency.LIDAR_AREA_REACH_BY_HOUR]: this.loadLidarAreaReachByHourData,
    [GraphDependency.LIDAR_AREA_AVG_ENGAGEMENT_MINUTES]: this.loadLidarAreaAvgEngagementMinutesData,
    [GraphDependency.LIDAR_AREA_VISITOR_PROFILE]: this.loadLidarAreaVisitorProfileData,
    [GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT_BY_HOUR]: this.loadLidarAreaEngagementCountByHourData,
    [GraphDependency.TRAFFIC_SITE_ALL_VISUALIZATION_VIDEOS]: this.loadAllTrafficSiteVisualizationVideoData,
    [GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_ONE_DIRECTION_UNMERGED_BY_PIN]: this.loadBuildingToZoneTrafficFlowOneDirectionUnmergedByPin,
    [GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_UNMERGED_BY_PIN]: this.loadBuildingToZoneTrafficFlowUnmergedByPin,
    [GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_BY_PIN]: this.loadBuildingToZoneTrafficFlowByPin,
    [GraphDependency.BUILDING_ZONE_SYNERGY_BY_PIN]: this.loadBuildingToZoneSynergyByPinData,
    [GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN]: this.loadBuildingVisitorProfileByPin,
    [GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_NO_LOOKBACK_BY_PIN]: this.loadBuildingToZoneTrafficFlowNoLookbackByPin,
    [GraphDependency.BUILDING_TIMESPENT_BY_PIN]: this.loadBuildingTimespentByPinData,
    [GraphDependency.BUILDING_MESSENGER_BRAND_BY_PIN]: this.loadBuildingMessengerBrandByPinData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_BY_PIN]: this.loadBuildingAreaEntranceExitByPinData,
    [GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN]: this.loadZoneAreaEntranceExitByPinData,
    [GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT]: this.loadZoneAreaEntranceExitData,
    [GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_ONE]: this.loadZoneAreaVisitorProfileData,
    [GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO]: this.loadZoneAreaVisitorProfileCrossLevelTwoData,
    [GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN]: this.loadBuildingVisitorProfileCrossLevelTwoByPin,
    [GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR]: this.loadZoneAreaEntranceExitByPinByHourData,
    [GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_BY_HOUR]: this.loadAllZoneAreaEntranceExitByHourData,
    [GraphDependency.ALL_ZONE_TIMESPENT]: this.loadAllZoneTimespentData,
    [GraphDependency.ALL_ZONE_TO_ZONE_SYNERGY]: this.loadAllZoneToZoneSynergyData,
    [GraphDependency.ZONE_AREA_ENTRANCE_EXIT_ALL_PIN]: this.loadZoneAreaEntranceExitAllPinData,
    [GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_AVG_DAY_TYPE]: this.loadZoneAreaEntranceExitByPinAvgByDayTypeData,
    [GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE]: this.loadAllZoneAreaEntranceExitAvgByDayTypeData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN]: this.loadBuildingAreaEntranceExitAllPinData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_BY_HOUR_ALL_PIN]: this.loadBuildingAreaEntranceExitByHourAllPinData,
    [GraphDependency.FLOOR_AREA_ENTRANCE_EXIT_ALL_PIN]: this.loadFloorAreaEntranceExitAllPinData,
    [GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_ALL_PIN]: this.loadAllZoneAreaEntranceExitAllPinData,
    [GraphDependency.BUILDING_TIMESPENT]: this.loadBuildingTimespentData,
    [GraphDependency.BUILDING_ZONE_SYNERGY]: this.loadBuildingToZoneSynergyData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE]: this.loadBuildingAreaEntranceExitAvgByDayTypeData,
    [GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]: this.loadBuildingVisitorProfileCrossLevelFourData,
    [GraphDependency.BUILDING_ZONE_SYNERGY_ALL_PIN]: this.loadBuildingToZoneSynergyAllPinData,
    [GraphDependency.BUILDING_ENTRANCE_EXIT_AVG_DAY_TYPE]: this.loadEntranceExitAvgByDayTypeData,
    [GraphDependency.FLOOR_AREA_ENTRANCE_EXIT_BY_PIN]: this.loadFloorAreaEntranceExitByPinData,
    [GraphDependency.STORE_AREA_ENTRANCE_EXIT]: this.loadStoreAreaEntranceExitData,
    [GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR]: this.loadStoreAreaEntranceExitByHourData,
    [GraphDependency.STORE_AREA_ZONE_SYNERGY]: this.loadStoreAreaToZoneSynergyData,
    [GraphDependency.STORE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE]: this.loadStoreAreaEntranceExitAvgDayTypeData,
    [GraphDependency.ZONE_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN]: this.loadZoneVisitorProfileCrossLevelTwoByPin,
    [GraphDependency.BUILDING_AREA_VISTIOR_PROFILE_CROSS_LEVEL_TWO]: this.loadBuildingAreaVisitorProfileCrossLevelTwoData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR]: this.loadBuildingAreaEntranceExitByHourData,
    [GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_HOUR]: this.loadVehicleParkingEntranceExitByHourData,
    [GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_ALL_AREA]: this.loadVehicleParkingModeofTransportAllAreaData,
    [GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_ALL_AREA_HOUR]: this.loadVehicleParkingModeofTransportByHourAllAreaData,
    [GraphDependency.BUILDING_TO_BUILDING_SYNERGY]: this.loadBuildingToBuildingSynergyData,
    [GraphDependency.FLOOR_STORE_AREA]: this.loadStoreFloorStoreAreaData,
    [GraphDependency.FLOOR_STORE_AREA_CATEGORY]: this.loadStoreFloorStoreAreaCategoryData,
    [GraphDependency.BUILDING_TRAFFIC_BREAKDOWN]: this.loadBuildingTrafficBreakdownData,
    [GraphDependency.BUILDING_UNIQUE_TRAFFIC_BREAKDOWN]: this.loadBuildingUniqueTrafficBreakdownData,
    [GraphDependency.TRAFFIC_SITE_COUNT_ALL_AREA]: this.loadAllTrafficSiteCountData,
    [GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE]: this.loadPredictiveTrafficSiteVehicleProfileData,
    [GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE]: this.loadPredictionTrafficSiteVehicleProfileData,
    [GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE]: this.loadPredictionTrafficSiteVehicleProfilePublicPrivateData,
    [GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR]: this.loadPredictionTrafficSiteVehicleProfileUniqueVisitorData,
    [GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE]: this.loadPredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData,
    [GraphDependency.PREDICTIVE_TRAFFIC_SITE_COUNT]: this.loadPredictiveTrafficSiteCountData,
    [GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR]: this.loadPredictiveTrafficSiteVehicleProfileUniqueVisitorData,
    [GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT_BY_HOUR]: this.loadTrafficSitePackageCountByHourData,
    [GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT]: this.loadTrafficSitePackageCountData,
    [GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE]: this.loadTrafficSitePackageVehicleProfileData,
    [GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR]: this.loadTrafficSitePackageVehicleProfileByHourData,
    [GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_PUBLIC_PRIVATE]: this.loadTrafficSitePackageVehicleProfilePublicPrivateData,
    [GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_MONTHLY]: this.loadVehicleParkingEntranceExitMonthData,
    [GraphDependency.VEHICLE_PARKING_ALL_AREA_PROVINCE_UNGROUP]: this.loadVehicleParkingProvinceUngroupAllAreaData,
    [GraphDependency.VEHCILE_PARKING_ENTRANCE_EXIT_AVG_DAY_TYPE]: this.loadVehicleParkingEntranceExitAvgDayTypeData,
    [GraphDependency.VEHICLE_PARKING_ALL_AREA_PURCHASING_POWER]: this.loadVehicleParkingAllAreaPurchasingPowerData,
    [GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY]: this.loadVehicleParkingToVehicleParkingSynergyData,
    [GraphDependency.VEHICLE_PARKING_ALL_AREA_PLATE_TIMESPENT]: this.loadVehicleParkingAllAreaPlateTimespentData,
    [GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_UNIQUE_AREA_VISIT]: this.loadVehicleParkingToVehicleParkingUniqueAreaVisitData,
    [GraphDependency.VEHICLE_PARKING_PLATE_NUMBER_DEFINITION]: this.loadVehicleParkingPlateNumberDefinitionData,
    [GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE]: this.loadVehicleParkingVehicleProfileData,
    [GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA]: this.loadVehicleParkingVehicleProfileAllAreaData,
    [GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_AVG_DAY_TYPE]: this.loadVehicleParkingVehicleProfileAvgDayTypeData,
    [GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR]: this.loadVehicleParkingVehicleProfileByHourData,
    [GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN]: this.loadVehicleParkingVehicleProfileByPinData,
    [GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN_HOUR]: this.loadVehicleParkingVehicleProfileByPinByHourData,
    [GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_ALL_PIN]: this.loadVehicleParkingEntranceExitAllPinData,
    [GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY_ALL_AREA]: this.loadVehicleParkingSynergyAllAreaData,
    [GraphDependency.BUILDING_FLOOR_TIMESPENT]: this.loadBuildingFloorTimespentData,
    [GraphDependency.STORE_PROXIMITY_FLOOR_TRAFFIC]: this.loadStoreProximityTrafficFloorData,
    [GraphDependency.STORE_AREA_ZONE_TRAFFIC_FLOW]: this.loadStoreAreaToZoneTrafficFlowData,
    [GraphDependency.BUILDING_AREA_ZONE_TRAFFIC_FLOW]: this.loadBuildingAreaToZoneTrafficFlowData,
    [GraphDependency.VEHICLE_PARKING_FREQ_VISIT]: this.loadVehicleParkingFrequencyOfVisitData,
    [GraphDependency.VEHICLE_PARKING_REPEATED_VISITORS]: this.loadVehicleParkingRepeatedVisitorsData,
    [GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY]: this.loadVehicleParkingRecencyFrequencyData,
    [GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT]: this.loadMonthEntranceExitData,
    [GraphDependency.ENTRANCE_EXIT_HOUR_NUMBER_CARD]: this.loadEntranceExitByHourNumberCardData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK]: this.loadBuildingAreaEntranceExitByHourAverageDayOfWeekData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_WEEK]: this.loadBuildingAreaEntranceExitByHourDayOfWeekData,
    [GraphDependency.TRAFFIC_SITE_ADS_FREQUENCY]: this.loadTrafficAdsFrequency,
    [GraphDependency.BUILDING_ENTRANCE_EXIT_AVG]: this.loadEntranceExitAvgByDayData,
    [GraphDependency.PREDICTION_BUILDING_ENTRANCE_EXIT_AVG]: this.loadPredictionBuildingTrafficAvgData,
    [GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING_AVG]: this.loadBuildingEntranceExitFlowAvgData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN_AVG]: this.loadBuildingAreaEntranceExitAllPinAvgData,
    [GraphDependency.STAFF_TRAFFIC_COUNT]: this.loadStaffTrafficCount,
    [GraphDependency.STAFF_TRAFFIC_COUNT_2]: this.loadStaffTrafficCount2,
    [GraphDependency.FEMALE_TRAFFIC_COUNT]: this.loadFemaleTrafficCount,
    [GraphDependency.MALE_TRAFFIC_COUNT]: this.loadMaleTrafficCount,
    [GraphDependency.STORE_SHOPPING_BAG_RATE]: this.loadStoreVisitorProfileData,
    [GraphDependency.MALE_FEMALE_LINE_CHART]: this.loadStoreVisitorProfileDataByHour,
    [GraphDependency.MALE_COUNT_BY_PIN]: this.loadStoreVisitorProfileData,
    [GraphDependency.FEMALE_COUNT_BY_PIN]: this.loadStoreVisitorProfileData,
    [GraphDependency.STORE_AGE_PROFILE]: this.loadStoreVisitorProfileData,
    [GraphDependency.MALE_BUILDING_COUNT]: this.loadVisitorProfileSimpleCrossData,
    [GraphDependency.FEMALE_BUILDING_COUNT]: this.loadVisitorProfileTwoCrossData,
    [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY]: this.loadBuildingAreaEntranceExitByHourDayByDayData,
    [GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY]: this.loadVehicleParkingEntranceExitByHourDayOfWeekData,
    [GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT_2]: this.loadMonthEntranceExitData2,
    [GraphDependency.VEHICLE_PARKING_FREQ_VISIT_PROFILE]: this.loadVehicleParkingFrequencyOfVisitProfileData,
    [GraphDependency.VEHICLE_PARKING_REPEATED_VISITORS_PROFILE]: this.loadVehicleParkingRepeatedVisitorsProfileData,
    [GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE]: this.loadVehicleParkingRecencyFrequencyProfileData,
    [GraphDependency.VEHICLE_PARKING_TIMESPENT_PROFILE]: this.loadVehicleParkingTimespentProfileData,
    [GraphDependency.FLOOR_AREA_ENTRANCE_EXIT]: this.loadFloorAreaEntranceExitData,
  };
  readonly retryCounter: { [dependency in GraphDependency]?: number } = {};
  baseGraphData: BaseGraphData;

  //#region chartData
  // general
  comparingBuildingState$ = new BehaviorSubject<{ [buildingName: string]: boolean }>({});
  comparingFloorState$ = new BehaviorSubject<{ [floorName: string]: boolean }>({});
  comparingZoneState$ = new BehaviorSubject<{ [zoneName: string]: boolean }>({});
  isDisplayClickableInfo$ = new BehaviorSubject<boolean>(false);
  billboardList$ = new BehaviorSubject<Billboard[]>(null);
  // building/entrance-exit
  private entranceExitLock = 0;
  /** @deprecated use `buildingEntranceExitData$` instead */
  entranceChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  /** @deprecated use `buildingEntranceExitData$` instead */
  exitChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  buildingEntranceExitData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: number[]; exit: number[] } }>(null);
  buildingEntranceExitPairData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } };
  buildingEntranceExitPairData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: [number, number]; exit: [number, number] } }>(null);
  averageTimeSpentChartData$ = new BehaviorSubject<{ [buildingName: string]: number[] }>(null);
  netShoppingTimeChartData$ = new BehaviorSubject<{ [buildingName: string]: number[] }>(null);
  currentNetShoppingTimeChartPercentageData$ = new BehaviorSubject<{ [buildingName: string]: number }>(null);
  currentBuildingHeadCountData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  currentBuildingHeadCountLastWeekData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  prevBuildingHeadCountData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  currentBuildingNetShoppingHourData$ = new BehaviorSubject<{ [buildingName: string]: { netShoppingTime: number; diff: number; diffPercent: number } }>(null);
  currentNetVisitorHourData$ = new BehaviorSubject({ netVisitorTime: 0, diff: 0, diffPercent: 0 });
  currentBuildingAverageTimeSpentData$ = new BehaviorSubject<{ [buildingName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } }>(null);
  buildingAverageWeekDayLast7DaysData$ = new BehaviorSubject<{ [buildingName: string]: { headCount: number; diff: number; diffPercent: number } }>(null);
  buildingAverageWeekEndLast7DaysData$ = new BehaviorSubject<{ [buildingName: string]: { headCount: number; diff: number; diffPercent: number } }>(null);
  /** @deprecated use `currentBuildingHeadCountData$` instead */
  currentHeadCountEntranceData$ = new BehaviorSubject({ headCount: 0, diff: 0, diffPercent: 0 });
  /** @deprecated use `currentBuildingHeadCountData$` instead */
  currentHeadCountExitData$ = new BehaviorSubject({ headCount: 0, diff: 0, diffPercent: 0 });
  /** @deprecated use `currentBuildingAverageTimeSpentData$` instead */
  currentAverageTimeSpentData$ = new BehaviorSubject({ avgTimeSpent: 0, diff: 0, diffPercent: 0 });
  /** @deprecated use `currentBuildingNetShoppingHourData$` instead */
  currentNetShoppingTimeData$ = new BehaviorSubject({ net_shopping_time: 0, diff: 0, diffPercent: 0 });
  // building/entrance-exit (daily average)
  private entranceExitAvgByDayLock = 0;
  buildingEntranceExitAvgByDayData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: number[]; exit: number[] } }>(null);
  currentBuildingEntranceExitAvgByDayData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  currentBuildingHeadCountLastWeekAvgByDayData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  // entrance-exit month
  private entranceExitMonthLock = 0;
  private entranceExitMonthLastFetch = { month: -1, year: -1 };
  private lastEntranceExitMonthTime = 0;
  entranceMonthData$ = new BehaviorSubject<{ [day: string]: number }>({});
  exitMonthData$ = new BehaviorSubject<{ [day: string]: number }>({});
  buildingEntranceMonthData$ = new BehaviorSubject<{ [buildingName: string]: { [day: string]: number } }>({});
  buildingExitMonthData$ = new BehaviorSubject<{ [buildingName: string]: { [day: string]: number } }>({});
  // weather month
  private weatherDataMonthLock = 0;
  private weatherDataMonthLastFetch = { month: -1, year: -1 };
  private lastWeatherDataMonthTime = 0;
  weatherMonthData$ = new BehaviorSubject<{ [day: string]: number }>({});
  // entrance-exit-by-hour
  private entranceExitByHourLock = 0;
  accumulateHeadcountByHour$ = new BehaviorSubject<{ [buildingName: string]: number[] }>(null);
  buildingEntranceByHour$ = new BehaviorSubject<{ [buildingName: string]: number[] }>(null);
  currentBuildingEntranceByHour$ = new BehaviorSubject<{ [buildingName: string]: number }>(null);
  currentAccumulateHeadcountData$ = new BehaviorSubject<{ [buildingName: string]: number }>(null);
  entranceByHour$ = new BehaviorSubject<number[]>(Array.from({ length: TIME_LIST.length }).map(() => null));
  exitByHour$ = new BehaviorSubject<number[]>(Array.from({ length: TIME_LIST.length }).map(() => null));
  // TODO: implement this
  // entranceByFloorByGateByHour$ = new BehaviorSubject<number[]>(Array.from({ length: TIME_LIST.length }).map(() => null));
  // exitByFloorByGateByHour$ = new BehaviorSubject<number[]>(Array.from({ length: TIME_LIST.length }).map(() => null));
  busiestTime$ = new BehaviorSubject({ headcount: 0, hour: '10 PM', });
  timeVisitHeatmapEntranceData$ = new BehaviorSubject<number[][]>(Array.from({ length: 8 }).map(() => Array.from({ length: TIME_LIST.length }).map(() => null)));
  timeVisitHeatmapExitData$ = new BehaviorSubject<number[][]>(Array.from({ length: 8 }).map(() => Array.from({ length: TIME_LIST.length }).map(() => null)));
  // entrance-exit-by-hour (number card)
  private entranceExitByHourNumberCardLock = 0;
  buildingEntranceByHourAvgPerDay$ = new BehaviorSubject<{ [buildingName: string]: number[] }>(null);
  buildingEntranceExitByHourAvgPerDay$ = new BehaviorSubject<{ [buildingName: string]: { entrance: number[]; exit: number[] } }>(null);
  buildingAvgBusiestTime$ = new BehaviorSubject<{ [areaName: string]: { headcount: number; hour: string } }>(null);

  // tourist
  private touristLock = 0;
  mallTrafficTouristsChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  // parking customer
  private parkingCustomerLock = 0;
  parkingCustomerEntranceExitChartData$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>({ entrance: Array.from({ length: 7 }).map(() => null), exit: Array.from({ length: 7 }).map(() => null) });
  parkingCustomerAverageTimeSpentChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  parkingCustomerNetShoppingEntranceChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  parkingCustomerNetShoppingExitChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  // visitor-profile
  private visitorProfileLock = 0;
  unfilteredVisitorProfileData$ = new BehaviorSubject<IFetchData<VisitorProfileData[]>[]>(null);
  selectedVisitorProfile$ = new BehaviorSubject<VisitorProfileSelection>({ organization: 'all', gender: 'all', ethnicity: 'all', age: 'all' });
  visitorTrafficTrendData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  currentVisitorTrafficTrendtData$ = new BehaviorSubject({ headCount: 0, diff: 0, diffPercent: 0 });
  genderProfileData$ = new BehaviorSubject<{ male: number; female: number }>({ male: null, female: null });
  ethnicityProfileData$ = new BehaviorSubject<{ male: number[]; female: number[] }>({ male: [], female: [] });
  ageProfileData$ = new BehaviorSubject<{ male: number[]; female: number[] }>({ male: [], female: [] });
  currentTeenagerProfileData$ = new BehaviorSubject({ teenager: 0, diff: 0 });
  currentVisitorProfileData$ = new BehaviorSubject<{ visitor: number; diff: number; diffPercent: number }>(null);
  currentStaffProfileData$ = new BehaviorSubject<{ staff: number; diff: number; diffPercent: number }>(null);
  currentMessengerProfileData$ = new BehaviorSubject<{ messenger: number; diff: number; diffPercent: number }>(null);
  prevStaffProfileData$ = new BehaviorSubject<{ staff: number; diff: number; diffPercent: number }>(null);
  ageProfileStackData$ = new BehaviorSubject<{ [ageClass: string]: number }>(null);
  genderProfileStackData$ = new BehaviorSubject<{ [genderClass: string]: number }>(null);
  ethnicityProfileStackData$ = new BehaviorSubject<{ [ethnicityClass: string]: number }>(null);
  staffVisitorClassTrendData$ = new BehaviorSubject<{ staff: number[]; visitor: number[]; all_visitor: number[] }>(null);
  currentStaffVisitorClassData$ = new BehaviorSubject<{ staff: CurrentTrendNumberCard; visitor: CurrentTrendNumberCard; all_visitor: CurrentTrendNumberCard }>(null);

  // store-visitor-profile
  private storeVisitorProfileLock = 0;
  unfilteredStoreVisitorProfileData$ = new BehaviorSubject<IFetchData<AreaVisitorProfileData[]>[]>(null);
  selectedStoreVisitorProfile$ = new BehaviorSubject<VisitorProfileSelection>({ organization: 'all', gender: 'all', ethnicity: 'all', age: 'all' });
  storeVisitorTrafficTrendData$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>({ entrance: Array.from({ length: 7 }).map(() => null), exit: Array.from({ length: 7 }).map(() => null) });
  currentStoreVisitorTrafficTrendtEntranceData$ = new BehaviorSubject({ headCount: 0, diff: 0, diffPercent: 0 });
  currentStoreVisitorTrafficTrendtExitData$ = new BehaviorSubject({ headCount: 0, diff: 0, diffPercent: 0 });
  storeVisitorAvgTimespentData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  storeVisitorNetShoppingHourData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  storeAgeProfileData$ = new BehaviorSubject<{ male: number[]; female: number[] }>({ male: [], female: [] });
  storeAgeProfileDataByNumber$ = new BehaviorSubject<{ male: number[]; female: number[] }>({ male: [], female: [] });
  storeGenderProfileData$ = new BehaviorSubject<{ male: number; female: number }>({ male: null, female: null });
  storeEthnicityProfileData$ = new BehaviorSubject<{ male: number[]; female: number[] }>({ male: [], female: [] });
  storeEthnicityProfileBreakdown$ = new BehaviorSubject<{ male: number[]; female: number[] }>({ male: [], female: [] });
  storeCustomSegmentPurchasingBreakdown$ = new BehaviorSubject<{ [className: string]: number[] }>(null);

  // floor/building-floor-heatmap
  private floorHeatmapLock = 0;
  floorHeatmapData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: HeatMapPoints[] } }>(null);

  // store-proximity-traffic
  private storeProximityLock = 0;
  storeProximityTrafficData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  currentStoreProximityTrafficData$ = new BehaviorSubject<number>(null);
  currentStoreConfidence$ = new BehaviorSubject<number>(null);
  // store-proximity-traffic by-floor
  private storeProximityFloorLock = 0;
  currentStoreFrontTrafficData$ = new BehaviorSubject<{ headCount: number; diff: number; diffPercent: number }>(null);
  currentStoreBackTrafficData$ = new BehaviorSubject<{ headCount: number; diff: number; diffPercent: number }>(null);
  storeFrontBackTrafficData$ = new BehaviorSubject<{ [channelName: string]: number }>(null);
  storeEstProxTrafficData$ = new BehaviorSubject<{ [storeName: string]: number }>(null);
  storeEstProxTrafficRangeData$ = new BehaviorSubject<{ [storeName: string]: { min: number; max: number } }>(null);
  storeEstProximityTrafficNormalize$ = new BehaviorSubject<{ [storeName: string]: number }>(null);
  // storeProximityTrafficData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  // currentStoreProximityTrafficData$ = new BehaviorSubject<number>(null);
  // currentStoreConfidence$ = new BehaviorSubject<number>(null);

  // prediction-store-proximity-traffic
  private predictedStoreProximityLock = 0;
  predictionStoreProximityTrafficData$ = new BehaviorSubject<number[]>(Array.from({ length: 8 }).map(() => null));
  predictionStoreProximityTrafficNumber$ = new BehaviorSubject<number[]>(null);

  // customer-segregation
  private customerSegregationLock = 0;
  segregatedEthinicityData$ = new BehaviorSubject<{
    white_tourist: number;
    middle_eastern_tourist: number;
    indian_tourist: number;
    asian_tourist: number;
    other_tourist: number;
    local_adult_shoppers: number;
    local_young_social_strollers: number;
  }>({
    white_tourist: null,
    middle_eastern_tourist: null,
    indian_tourist: null,
    asian_tourist: null,
    other_tourist: null,
    local_adult_shoppers: null,
    local_young_social_strollers: null
  });
  localTouristData$ = new BehaviorSubject<{ local: number; asian: number; non_asian: number }>({ local: null, asian: null, non_asian: null });
  // repeated-visitors
  private repeatedVisitorsLock = 0;
  repeatedVisitorsData$ = new BehaviorSubject<{ repeated_percentage: number; new_percentage: number }>({ repeated_percentage: null, new_percentage: null });
  currentRepeatedVisitorsData$ = new BehaviorSubject<{ current: number; diff: number }>(null);
  // frequency-of-visit
  private frequencyOfVisitLock = 0;
  frequencyOfVisitData$ = new BehaviorSubject<{ one: number; two_five: number; six_ten: number; eleven_twenty: number; twenty_up: number }>({ one: null, two_five: null, six_ten: null, eleven_twenty: null, twenty_up: null });
  // mode-of-transportation
  private modeOfTransportationLock = 0;
  modeOfTransportationChartData$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  currentModeofTransportBarChartData$ = new BehaviorSubject<{ [vehicle_type: string]: number }>(null);
  customerVehicleTrafficChartData$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  staffVehicleTrafficChartData$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  currentTotalTransportationData$ = new BehaviorSubject<{ traffic: number; diff: number; diffPercent: number }>(null);
  currentTotalStaffTransportationData$ = new BehaviorSubject<{ traffic: number; diff: number; diffPercent: number }>(null);
  currentTotalCustomerTransportationData$ = new BehaviorSubject<{ traffic: number; diff: number; diffPercent: number }>(null);
  allVehicleTrafficWeekdayLast7daysData$ = new BehaviorSubject<{ traffic: number; diff: number; diffPercent: number }>(null);
  allVehicleTrafficWeekendLast7daysData$ = new BehaviorSubject<{ traffic: number; diff: number; diffPercent: number }>(null);
  // vehicle-by-province
  private vehicleByProvinceLock = 0;
  vehicleByProvinceChartData$ = new BehaviorSubject<{ [province: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  staffVehicleByProvinceChartData$ = new BehaviorSubject<{ [province: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  customerVehicleByProvinceChartData$ = new BehaviorSubject<{ [province: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  currentVehicleByProvinceData$ = new BehaviorSubject<{ [province: string]: number; total: number }>({ total: 0 });
  // car-vehicle-entrance-exit-by-hour
  private carEntranceExitByHourLock = 0;
  parkingLotOccupancyData$ = new BehaviorSubject(0);
  /** @deprecated use buildingEntranceExitFloorPinHourLock instead for newer version ( > 4.9.1) */
  private entranceExitGateHourLock = 0;
  // entrance-exit-by-gate-by-hour
  /** @deprecated use entranceFloorPinData$ instead for newer version ( > 4.9.1) */
  floorGateEntranceData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [gateName: string]: number } } }>({});
  /** @deprecated use exitFloorPinData$ instead for newer version ( > 4.9.1) */
  floorGateExitData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [gateName: string]: number } } }>({});
  /** @deprecated use entranceFloorPinByHour$ instead for newer version ( > 4.9.1) */
  entranceByGateByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [gateName: string]: number[] } } }>(null);
  /** @deprecated use exitFloorPinByHour$ instead for newer version ( > 4.9.1) */
  exitByGateByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [gateName: string]: number[] } } }>(null);
  // purchase-rate
  private purchaseRateLock = 0;
  purchaseRateChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => 0));
  currentpurchaseRateData$ = new BehaviorSubject({ purchase: 0, diff: 0, diffPercent: 0 });
  // purchase-rate-by-profile
  private purchaseRateProfileLock = 0;
  unfilteredPurchaseRateProfileData$ = new BehaviorSubject<IFetchData<PurchaseRateProfileData[]>[]>(null);
  purchaseRateProfiletData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => 0));
  currentpurchaseRateProfileData$ = new BehaviorSubject({ purchase: 0, diff: 0, diffPercent: 0 });
  // store-purchase-rate-by-profile
  private storePurchaseRateProfileLock = 0;
  unfilteredStorePurchaseRateProfileData$ = new BehaviorSubject<IFetchData<PurchaseRateProfileData[]>[]>(null);
  storePurchaseRateProfiletData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => 0));
  currentStorePurchaseRateProfileData$ = new BehaviorSubject({ purchase: 0, diff: 0, diffPercent: 0 });
  // average-timespent-by-profile
  private averageTimeSpentProfileLock = 0;
  unfilteredAverageTimeSpentProfileData$ = new BehaviorSubject<IFetchData<AverageTimeSpentProfileData[]>[]>(null);
  averageTimeSpentProfileChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  currentAverageTimeSpentProfileData$ = new BehaviorSubject({ avgTimeSpent: 0, diff: 0, diffPercent: 0 });
  // net-shopping-time-by-profile
  private netShoppingTimeProfileLock = 0;
  unfilteredNetShoppingTimeProfileData$ = new BehaviorSubject<IFetchData<NetShoppingTimeProfileData[]>[]>(null);
  netShoppingTimeProfileChartData$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  currentNetShoppingTimeProfileData$ = new BehaviorSubject({ netShoppingTime: 0, diff: 0, diffPercent: 0 });

  // traffic-congestion
  private trafficCongestionDataLock = 0;
  trafficCongestionData$ = new BehaviorSubject<{ averageExtraTravelTime?: number }>(null);
  // general-traffic-data
  /**
   * Name of the floor of which is selected
   *
   * @example ALL, GROUND
   */
  /** @deprecated use selectedDirectory instead in newer version (> 4.9.1) */
  selectedFloorName$ = new BehaviorSubject<string>(null);
  selectedDirectory$ = new BehaviorSubject<{ building: string; floor: string; zone: string }>(null);
  selectedLevel$ = new BehaviorSubject<'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA'>(null);
  /**
   * Selected Interactable
   *
   * @example null, {name: c_0_0_G1A, type: 'gate', object:...}
   */
  public selectedInteractable$ = new BehaviorSubject<InteractableType>(null);
  public selectedPackageName$ = new BehaviorSubject<string>(null);
  public selectedFilteredBillboard$ = new BehaviorSubject<{ location: string[]; package: string[]; type: string[] }>({ location: [], package: ['all'], type: [] });
  // floor/building-floor-entrance-exit
  private entranceExitFloorLock = 0;
  entranceExitFloorData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { entrance: number[]; exit: number[] } } }>(null);
  avgTimeSpentFloorData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: number[] } }>(null);
  netShoppingTimeFloorData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: number[] } }>(null);
  currentAvgTimeSpentFloorData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } } }>(null);
  currentNetShoppingTimeFloorData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { netShoppingTime: number; diff: number; diffPercent: number } } }>(null);
  currentBuildingHeadCountFloorData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } } }>(null);
  // floor/building-floor-entrance-exit-by-hour
  private entranceExitFloorHourLock = 0;
  currentFloorHeadcountData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: number } }>(null);
  currentFloorEntranceByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: number } }>(null);
  floorAccumulateHeadcountByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: number[] } }>(null);
  floorEntranceByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: number[] } }>(null);
  // zone/entrance-exit-by-hour
  private entranceExitFloorZoneHourLock = 0;
  currentZoneHeadcountData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: number } } }>(null);
  currentZoneEntranceByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: number } } }>(null);
  zoneAccumulateHeadcountByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } }>(null);
  zoneEntranceByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } }>(null);
  // zone/entrance-exit
  private zoneEntranceExitLock = 0;
  zoneDataCompletely$ = new BehaviorSubject<boolean>(false);
  zoneEntranceExitFloorData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: number[]; exit: number[] } } } }>(null);
  avgTimeSpentZoneData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } }>(null);
  netShoppingTimeZoneData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } }>(null);
  currentBuildingHeadCountZoneData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } } } }>(null);
  currentAvgTimeSpentZoneData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } } } }>(null);
  currentNetShoppingTimeZoneData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { netShoppingTime: number; diff: number; diffPercent: number } } } }>(null);
  zoneNetShoppingHourPercentageData$ = new BehaviorSubject<{ [zoneName: string]: number }>(null);
  currentBuildingZoneTrafficPairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: [number, number]; exit: [number, number] } } } } = {};

  // store/entrance-exit
  private storeEntranceExitLock = 0;
  currentStoreNetShoppingTimeData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { netShoppingTime: number; diff: number; diffPercent: number } } } } }>(null);
  currentStoreAvgTimeSpentTimeData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } } } } }>(null);
  currentStoreEntranceData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { headCount: number; diff: number; diffPercent: number } } } } }>(null);
  currentStoreExitData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { headCount: number; diff: number; diffPercent: number } } } } }>(null);
  storeEntranceExitData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { entrance: number[]; exit: number[] } } } } }>(null);
  // store/entrace-exit-by-hour
  private entranceExitFloorZoneStoreHourLock = 0;
  currentStoreHeadcountData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: number } } } }>(null);
  storeAccumulateHeadcountByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: number[] } } } }>(null);
  storeEntranceByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: number[] } } } }>(null);
  // building/entrance-exit-flow
  private buildingEntranceExitFlowLock = 0;
  buildingEntranceExitFlowData$ = new BehaviorSubject<{ [buildingName: string]: { entrance: { [name: string]: number } & { _total: number }; exit: { [name: string]: number } & { _total: number } } }>(null);
  buildingFlowEntranceExitData$ = new BehaviorSubject<{ [buildingName: string]: { [name: string]: { entrance: number; exit: number } } }>(null);
  buildingFlowEntranceExitRawData$ = new BehaviorSubject<{ [buildingName: string]: { [name: string]: { entrance: number; exit: number } } }>(null);
  buildingFlowEntranceExitTrendData$ = new BehaviorSubject<{ [buildingName: string]: { [name: string]: { entrance: number[]; exit: number[] } } }>(null);
  // building/entrance-exit-flow daily average
  private buildingEntranceExitFlowAvgLock = 0;
  buildingFlowEntranceExitRawAvgData$ = new BehaviorSubject<{ [buildingName: string]: { [name: string]: { entrance: number; exit: number } } }>(null);
  // floor/building-floor-entrance-exit-flow
  private floorEntranceExitFlowLock = 0;
  floorEntranceExitFlowData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { entrance: { [name: string]: number } & { _total: number }; exit: { [name: string]: number } & { _total: number } } } }>(null);
  // zone/entrance-exit-flow
  private zoneEntranceExitFlowLock = 0;
  zoneEntranceExitFlowData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: { [name: string]: number } & { _total: number }; exit: { [name: string]: number } & { _total: number } } } } }>(null);
  // plate/provinces-ungroup
  private provinceLock = 0;
  vehicleProvinceData$ = new BehaviorSubject<{ [provinceName: string]: number } & { _total: number }>(null);
  // building/entrance-exit-by-pin-by-hour
  private buildingEntranceExitPinHourLock = 0;
  entrancePin$ = new BehaviorSubject<{ [buildingName: string]: { [pinName: string]: number } }>(null);
  exitPin$ = new BehaviorSubject<{ [buildingName: string]: { [pinName: string]: number } }>(null);
  entrancePinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [pinName: string]: number[] } }>(null);
  exitPinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [pinName: string]: number[] } }>(null);
  // floor/entrance-exit-by-pin-by-hour
  private buildingEntranceExitFloorPinHourLock = 0;
  floorEntranceExitPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: { entrance: number; exit: number } } } }>(null);
  buildingFloorEntranceExitGroupbyPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { entrance: number; exit: number } } }>(null);
  // floor/entrance-exit-by-pin-by-hour daily average
  private buildingEntranceExitFloorPinHourAvgLock = 0;
  buildingFloorEntranceExitGroupbyPinAvgPerDay$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { entrance: number; exit: number } } }>(null);
  // TO-DO: seperate building floor grouping pin data
  spFloorEntranceExitPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: { entrance: number; exit: number } } } }>(null);
  spEntranceBuildingFloorPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  spExitBuildingFloorPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  spEntranceBuildingFloorPinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } }>(null);
  spExitBuildingFloorPinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } }>(null);

  entranceBuildingFloorPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  exitBuildingFloorPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  // group pin by building to floor
  entranceFloorPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  exitFloorPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  entranceFloorPinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } }>(null);
  exitFloorPinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } }>(null);
  // zone/entrance-exit-by-pin-by-hour
  private buildingEntranceExitZonePinHourLock = 0;
  entranceZonePin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number } } } }>(null);
  exitZonePin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number } } } }>(null);
  entranceZonePinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number[] } } } }>(null);
  exitZonePinByHour$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number[] } } } }>(null);
  // overall/mask-count-entrance
  private maskCountLock = 0;
  currentMaskCount$ = new BehaviorSubject({ maskCount: 0, diff: 0, diffPercent: 0 });
  // prediction/building/entrance-exit
  private predictedBuildingTrafficLock = 0;
  predictedBuildingTrafficNumber$ = new BehaviorSubject<{ [buildingName: string]: { entrance: number[]; exit: number[] } }>(null);
  predictedBuildingNetShoppingHourNumber$ = new BehaviorSubject<{ [buildingName: string]: { netShopping: number[] } }>(null);
  // prediction/building/entrance-exit daily average
  private predictedBuildingTrafficAvgLock = 0;
  predictedBuildingTrafficNumberAvg$ = new BehaviorSubject<{ [buildingName: string]: { entrance: number[]; exit: number[] } }>(null);
  // prediction/floor/entrance-exit-by-floor
  private predictedFloorTrafficLock = 0;
  predictedFloorTrafficNumber$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { entrance: number[]; exit: number[] } } }>(null);
  // prediction/zone/entrance-exit-by-floor
  private predictedZoneTrafficLock = 0;
  predictedZoneNetShoppingNumber$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { netShopping: number[] } } } }>(null);
  predictedZoneTrafficNumber$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: number[]; exit: number[] } } } }>(null);
  // overall/car-brand
  private carBrandLock = 0;
  topTenCarBrandData$ = new BehaviorSubject<{ [brandName: string]: number }>(null);
  topTenCarBrandTrendData$ = new BehaviorSubject<{ [brandName: string]: number[] }>(null);
  // overall/vehicle-purchasing-power
  private vehiclePurchasingPowerLock = 0;
  tierPowerData$ = new BehaviorSubject<{ [tierName: string]: number }>(null);
  currentLuxuryTierData$ = new BehaviorSubject<{ current: number; diff: number }>(null);
  currentPremiumTierData$ = new BehaviorSubject<{ current: number; diff: number }>(null);
  vehiclePurchasingPowerData$ = new BehaviorSubject<{ [tierName: string]: number }>(null);
  // overall/mode-of-transportation-by-hour
  private modeOfTransportationByHourLock = 0;
  modeOfTransportByHour$ = new BehaviorSubject<{ [vehicleTypeName: string]: { [hourKey: number]: number }; total: { [hourKey: number]: number } }>(null);
  // prediction/visitor-profile
  private predictedVisitorProfileLock = 0;
  predictedTeenagerProfileNumber$ = new BehaviorSubject<{ teenager: { entrance: number[] } }>(null);
  // goal entrance-exit
  private monthEntranceExitLock = 0;
  currentNetShoppingHourGoalData$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }>(null);
  currentTrafficGoalData$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }>(null);
  currentAvgTrafficGoalData$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }>(null);
  currentGoalAvgTrafficBuildingData: { [buildingName: string]: { val: number; changed: number } } = {};
  currentGoalAvgTrafficBuildingData$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }[]>(null);
  currentGoalTrafficBuildingData: { [buildingName: string]: { val: number; changed: number } } = {};
  currentGoalTrafficBuildingData$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }[]>(null);
  // prediction goal building/entrance-exit
  private predGoalBuildingEntranceExitLock = 0;
  // goal entrance-exit 2
  private monthEntranceExitLock2 = 0;
  currentNetShoppingHourGoalData2$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }>(null);
  currentTrafficGoalData2$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }>(null);
  currentAvgTrafficGoalData2$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }>(null);
  currentGoalAvgTrafficBuildingData2: { [buildingName: string]: { val: number; changed: number } } = {};
  currentGoalAvgTrafficBuildingData2$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }[]>(null);
  currentGoalTrafficBuildingData2: { [buildingName: string]: { val: number; changed: number } } = {};
  currentGoalTrafficBuildingData2$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }[]>(null);
  // prediction goal building/entrance-exit 2
  private predGoalBuildingEntranceExitLock2 = 0;
  // override zone-entrance-exit
  private monthZoneEntranceExitLock = 0;
  currentZoneTrafficGoalData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } }>(null);
  currentZoneNetShoppingHourGoalData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } }>(null);
  // override visitor-profile
  private monthVisitorProfileLock = 0;
  currentTeenagerGoalData$ = new BehaviorSubject<{ teenager: { val: number; changed: number } }>({ teenager: { val: 0, changed: 0 } });
  // tourist for daily report
  private multipleDayCustomerSegregationLock = 0;
  currentTouristData$ = new BehaviorSubject({ tourist: 0, diffPercent: 0 });
  // zone/visitor-profile
  private zoneVisitorProfileLock = 0;
  zoneAgeProfileData$ = new BehaviorSubject<{ [ageClass: string]: number }>(null);
  zoneGenderProfileData$ = new BehaviorSubject<{ [genderClass: string]: number }>(null);
  zoneEthnicityProfileData$ = new BehaviorSubject<{ [enthnicityClass: string]: number }>(null);
  zoneAgeProfileLineData$ = new BehaviorSubject<{ [ageClass: string]: number[] }>(null);
  // info/model3d
  private model3dLoadingLock = 0;
  model3dDetail$ = new BehaviorSubject<Model3dDetail>(null);
  // plate/timespent
  private plateTimespentLock = 0;
  plateTimespentBinData$ = new BehaviorSubject<{ [binName: string]: number }>(null);
  // building/entrance-exit-average-by-day-type
  private buildingAverageDayTypeLock = 0;
  currentBuildingAverageWeekDayData$ = new BehaviorSubject<{ [buildingName: string]: { headCount: number; diff: number; diffPercent: number } }>(null);
  currentBuildingAverageWeekEndData$ = new BehaviorSubject<{ [buildingName: string]: { headCount: number; diff: number; diffPercent: number } }>(null);
  // plate/frequency-of-visit-semi-annual/
  private frequencyOfVisitSemiAnnualLock = 0;
  frequencyOfVisitSemiAnnualData$ = new BehaviorSubject<{ one: number; two_three: number; four_up: number }>({ one: null, two_three: null, four_up: null });
  // plate/frequency-of-visit-by-purchasing-power-semi-annual/
  private frequencyOfVisitWithPurchasingPowerLock = 0;
  frequencyOfVisitWithPurchasingPowerData$ = new BehaviorSubject<{ [freqName: string]: { [tierName: string]: number } }>(null);
  // zone/visitor-profile-sample
  private zoneVisitorProfileSampleLock = 0;
  areaAgeProfileSampleData: { [areaName: string]: { [ageClass: string]: number } } = {};
  areaGenderProfileSampleData: { [areaName: string]: { [genderClass: string]: number } } = {};
  areaAgeProfileSampleData$ = new BehaviorSubject<{ [areaName: string]: { [ageClass: string]: number } }>(null);
  areaGenderProfileSampleData$ = new BehaviorSubject<{ [areaName: string]: { [genderClass: string]: number } }>(null);
  // overall/visitor-profile profile-cross-level = 1
  private visitorProfileSampleCrossLock = 0;
  // overall/visitor-profile profile-cross-level = 2
  private visitorProfileTwoCrossLock = 0;
  // overall/messenger-brand
  private messengerBrandLock = 0;
  currentTotalMessengerData$ = new BehaviorSubject<{ messenger: number; diff: number; diffPercent: number }>(null);
  messengerBrandBarChartData$ = new BehaviorSubject<{ [brandName: string]: number }>(null);
  // overall/purchase-bag-color
  private purchaseBagColorLock = 0;
  purchaseBagColorListData$ = new BehaviorSubject<{ [colorName: string]: number }>(null);
  // overall/messenger-brand-by-hour
  private messengerBrandbyHourLock = 0;
  messengerBrandbyHourChartData$ = new BehaviorSubject<{ [brandName: string]: number[] }>(null);
  // building/entrance-exit (sum by total day)
  private buildingEntranceExitSumByDayLock = 0;
  currentBuildingTrafficGoalData$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }>(null);
  // plate/frequency-of-visit-semi-annual (for goal page)
  private frequencyOfVisitSemiAnnualtV2Lock = 0;
  current4UpRevisitedGoalData$ = new BehaviorSubject<{ val: number; changed: number }>(null);
  // plate/frequency-of-visit-semi-annual (for goal page)
  private frequencyOfVisitSemiAnnualtSumByDayLock = 0;
  current4UpRevisitedGoalSumData$ = new BehaviorSubject<{ val: number; changed: number }>(null);
  // overall/vehicle-purchasing-power (sum by total day)
  private vehiclePurchasingPowerSumByDayLock = 0;
  currentVehicleLuxuryGoalData$ = new BehaviorSubject<{ val: number; changed: number }>(null);
  // plate/unique-visitor 
  private vehiclePlateUniqueVisitorsLock = 0;
  currentVehicleUniqueVisitors$ = new BehaviorSubject<{ vehicles: number; diff: number; diffPercent: number }>(null);
  // traffic-site/count
  private trafficSiteCountLock = 0;
  currentTrafficSiteCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentTrafficSiteExposureTimeCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentTrafficSiteAvgWeekdayLast7DaysCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentTrafficSiteAvgWeekendLast7DaysCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  trafficSiteCountTrendexcludePred: number[] = [];
  trafficSiteTrend$ = new BehaviorSubject<number[]>(null);
  // traffic-site/unique-visitor
  private trafficSiteUniqueVisitorLock = 0;
  currentTrafficSiteUniqueVisitor$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentTrafficSiteAvgVisitor$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  trafficSiteUniqueVisitorTrend: number[];
  trafficSiteUniqueVisitorTrend$ = new BehaviorSubject<number[]>(null);
  // traffic-site/purchasing-power
  private trafficSitePurchasingPowerLock = 0;
  trafficSitePurchasingPowerPercentage$ = new BehaviorSubject<{ [tierName: string]: number }>(null);
  trafficSitePurchasingPowerTrend: { [tierName: string]: number[] };
  trafficSitePurchasingPowerTrend$ = new BehaviorSubject<{ [tierName: string]: number[] }>(null);
  // traffic-site/mode-of-transportation
  private trafficSiteModeOfTransportLock = 0;
  trafficSiteModeOfTransportTrend: { [vehicle_type: string]: number[]; total: number[] };
  trafficSiteModeOfTransportTrend$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  trafficSiteModeOfTransportBreakdown$ = new BehaviorSubject<{ [vehicle_type: string]: number }>(null);
  // traffic-site/mode-of-transportation-public-private
  private trafficSiteModeOfTransportPublicPrivateLock = 0;
  trafficSiteModeOfTransportPublicPrivateTrend: { [vehicle_type: string]: number[]; total: number[] };
  trafficSiteModeOfTransportPublicPrivateTrend$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  trafficSiteModeOfTransportPublicPrivateBreakdown$ = new BehaviorSubject<{ [vehicle_type: string]: number }>(null);
  // traffic-site/car-brand
  private trafficSiteCarBrandLock = 0;
  trafficSiteTopTenCarBrandData$ = new BehaviorSubject<{ [brandName: string]: number }>(null);
  trafficSiteAllCarBrandData$ = new BehaviorSubject<{ [brandName: string]: number }>(null);
  // traffic-site/count-average-by-day-type
  private trafficSiteCountAvgByDayTypeLock = 0;
  trafficSiteAvgWeekdayCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  trafficSiteAvgWeekendCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // traffic-site/count-by-hour
  private trafficSiteCountByHourLock = 0;
  trafficSiteCountByHour$ = new BehaviorSubject<number[]>(null);
  trafficSitePeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  trafficSiteOffPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  trafficSiteAdsExposureTimebyHour$ = new BehaviorSubject<number[]>(null);
  // prediction traffic-site/count
  private predictionTrafficSiteCountLock = 0;
  // prediction traffic-site/unique-visitor
  private predictionTrafficSiteUniqueVisitorLock = 0;
  // prediction traffic-site/mode-of-transportation
  private predictionTrafficSiteModeOfTransportLock = 0;
  // prediction traffic-site/mode-of-transportation-public-private
  private predictionTrafficSiteModeOfTransportPublicPrivateLock = 0;
  // prediction traffic-site/purchasing-power
  private predictionTrafficSitePurchasingPowerLock = 0;
  // traffic-site/vehicle-profile
  private trafficSiteVehicleProfileLock = 0;
  unfilteredVehicleProfileData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  selectedVehicleProfile$ = new BehaviorSubject<{ [selectorName: string]: string }>({ vehicle_type: undefined, car_brand: undefined, purchasing_power: undefined, vehicle_parking: undefined });
  selectedVehicleParking: string;
  unfilteredVehicleParkingProfileData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  selectedVehicleParkingProfile$ = new BehaviorSubject<{ [selectorName: string]: string }>({ vehicle_type: undefined, car_brand: undefined, purchasing_power: undefined });
  callTrafficSiteVehicleProfilePrediction$ = new BehaviorSubject<boolean>(false);
  trafficSiteProfileCountTrend$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  trafficSiteProfilePurchasingPowerTrend$ = new BehaviorSubject<{ [tier: string]: number[] }>(null);
  trafficSiteProfileModeOfTransportTrend$ = new BehaviorSubject<{ [type: string]: number[]; total: number[] }>(null);
  trafficSiteProfileCarBrandTrend$ = new BehaviorSubject<{ [brand: string]: number[] }>(null);
  trafficSiteProfilePurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  trafficSiteProfileModeOfTransportBreakdown$ = new BehaviorSubject<{ [type: string]: number }>(null);
  trafficSiteProfileTopTenCarBrandBreakdown$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  vehicleProfilePlateDefinitionTrend$ = new BehaviorSubject<{ [definition: string]: number[] }>(null);
  vehicleProfilePlateDefinitionBreakdown$ = new BehaviorSubject<{ [definition: string]: number }>(null);
  vehicleProfilePlateDefinitionTrend: { [definition: string]: number[] };
  currentTrafficSiteProfileCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentTrafficSiteProfileAvgTimespent$ = new BehaviorSubject<{ avgTimespent: number; diff: number; diffPercent: number }>(null);
  trafficSiteProfileCountTrend: number[] = [];
  trafficSiteProfilePurchasingPowerTrend: { [tier: string]: number[] };
  trafficSiteProfileModeOfTransportTrend: { [type: string]: number[]; total: number[] };
  trafficSiteProfileCarBrandTrend: { [brand: string]: number[] };

  // traffic-site/vehicle-profile-by-hour
  private trafficSiteVehicleProfileByHourLock = 0;
  trafficSiteProfileCountByHour$ = new BehaviorSubject<number[]>(null);
  unfilteredVehicleProfileByHourData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  trafficSiteProfilePeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  trafficSiteProfileOffPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);

  // traffic-site/vehicle-profile-public-private
  private trafficSiteVehicleProfilePublicPrivateLock = 0;
  callTrafficSiteVehicleProfilePublicPrivatePrediction$ = new BehaviorSubject<boolean>(false);
  unfilteredVehicleProfilePublicPrivateData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  trafficSiteProfileModeOfTransportPublicPrivateTrend$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  trafficSiteProfileModeOfTransportPublicPrivateBreakdown$ = new BehaviorSubject<{ [vehicle_type: string]: number }>(null);
  trafficSiteProfileModeOfTransportPublicPrivateTrend: { [vehicle_type: string]: number[]; total: number[] };
  // prediction traffic-site/vehicle-profile
  private predictionTrafficSiteVehicleProfileLock = 0;
  unfilteredPredictionVehicleProfileData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  // prediction traffic-site/vehicle-profile-public-private
  private predictionTrafficSiteVehicleProfilePublicPrivateLock = 0;
  unfilteredPredictionVehicleProfilePublicPrivateData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  // traffic-site/vehicle-profile-unique-visitor
  private trafficSiteVehicleProfileUniqueVisitorLock = 0;
  callTrafficSiteVehicleProfileUniqueVisitorPrediction$ = new BehaviorSubject<boolean>(false);
  unfilteredVehicleProfileUniqueVisitorData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  trafficSiteProfileUniqueVisitorCountTrend$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  trafficSiteProfileUniqueVisitorPurchasingPowerTrend$ = new BehaviorSubject<{ [tier: string]: number[] }>(null);
  trafficSiteProfileUniqueVisitorModeOfTransportTrend$ = new BehaviorSubject<{ [type: string]: number[]; total: number[] }>(null);
  trafficSiteProfileUniqueVisitorCarBrandTrend$ = new BehaviorSubject<{ [brand: string]: number[] }>(null);
  trafficSiteProfileUniqueVisitorPurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  trafficSiteProfileUniqueVisitorModeOfTransportBreakdown$ = new BehaviorSubject<{ [type: string]: number }>(null);
  trafficSiteProfileUniqueVisitorTopTenCarBrandBreakdown$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  currentTrafficSiteProfileUniqueVisitorCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentTrafficSiteAvgVisitorCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  trafficSiteProfileUniqueVisitorCountTrend: number[] = [];
  trafficSiteProfileUniqueVisitorPurchasingPowerTrend: { [tier: string]: number[] };
  trafficSiteProfileUniqueVisitorModeOfTransportTrend: { [type: string]: number[]; total: number[] };
  trafficSiteProfileUniqueVisitorCarBrandTrend: { [brand: string]: number[] };
  // prediction traffic-site/vehicle-profile-unique-visitor
  private predictionTrafficSiteVehicleProfileUniqueVisitorLock = 0;
  unfilteredPredictionVehicleProfileUniqueVisitorData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  // traffic-site/vehicle-profile-unique-visitor-public-private
  private trafficSiteVehicleProfileUniqueVisitorPublicPrivateLock = 0;
  callTrafficSiteVehicleProfileUniqueVisitorPublicPrivatePrediction$ = new BehaviorSubject<boolean>(false);
  unfilteredVehicleProfileUniqueVisitorPublicPrivateData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  trafficSiteModeOfTransportUniqueVisitorPublicPrivateBreakdown$ = new BehaviorSubject<{ [vehicle_type: string]: number }>(null);
  trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend: { [vehicle_type: string]: number[]; total: number[] };
  // prediction traffic-site/vehicle-profile-unique-visitor-public-private
  private predictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateLock = 0;
  unfilteredPredictionVehicleProfileUniqueVisitorPublicPrivateData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  // goal zone/entrance-exit
  private goalZoneEntranceExitLock = 0;
  goalStartDate$ = new BehaviorSubject<string>(null);
  goalEndDate$ = new BehaviorSubject<string>(null);
  goalExcludeDate$ = new BehaviorSubject<string[]>(null);
  goalDurationDate$ = new BehaviorSubject<{ startDate: string; endDate: string; excludeDate?: string[] }>(null);
  goalDurationDate2$ = new BehaviorSubject<{ startDate: string; endDate: string; excludeDate?: string[] }>(null);
  currentGoalTrafficZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
  currentGoalAvgTrafficZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
  currentGoalAvgTrafficBrandData: { [buildingName: string]: { val: number; changed: number } } = {};
  currentGoalTrafficZoneData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } }[]>(null);
  currentGoalAvgTrafficZoneData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } }[]>(null);
  currentGoalAvgTrafficBrandData$ = new BehaviorSubject<{ [buildingName: string]: { val: number; changed: number } }[]>(null);
  // prediction goal zone/entrance-exit
  private predGoalZoneEntranceExitLock = 0;
  // vehicle-parking/car-brand
  private vehicleParkingCarBrandLock = 0;
  vehicleParkingCarBrandBreakdownTopTen$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  // vehicle-parking/provinces
  private vehicleParkingProvinceLock = 0;
  vehicleParkingProvinceTrend$ = new BehaviorSubject<{ [province: string]: number[] }>(null);
  // vehicleParkingProvinceBreakdownTopFive$ = new BehaviorSubject<{ [province: string]: number }>(null);
  // vehicle-parking/provinces-ungroup
  private vehicleParkingProvinceUngroupLock = 0;
  vehicleParkingProvinceUngroupBreakdown$ = new BehaviorSubject<{ [province: string]: number }>(null);
  // vehicle-parking/mode-of-transportation
  private vehicleParkingModeofTransportLock = 0;
  vehicleParkingModeofTransportTrend$ = new BehaviorSubject<{ [vehicleType: string]: number[] }>(null);
  vehicleParkingModeofTransportProxTrend$ = new BehaviorSubject<{ [vehicleType: string]: number[] }>(null);
  // vehicle-parking/mode-of-transportation all area
  private vehicleParkingModeofTransportAllAreaLock = 0;
  vehicleParkingModeofTransportTrendAllArea$ = new BehaviorSubject<{ [areaName: string]: { [vehicleType: string]: number[] } }>(null);
  vehicleParkingModeofTransportProxTrendAllArea$ = new BehaviorSubject<{ [areaName: string]: { [vehicleType: string]: number[] } }>(null);
  // vehicle-parking/mode-of-transportation-by-hour
  private vehicleParkingModeofTransportbyHourLock = 0;
  vehicleParkingModeofTransportbyHourTrend$ = new BehaviorSubject<{ [vehicleType: string]: { [hour: string]: number } }>(null);
  // vehicle-parking/mode-of-transportation-by-hour all area
  private vehicleParkingModeofTransportByHourAllAreaLock = 0;
  vehicleParkingModeofTransportByHourTrendAllArea$ = new BehaviorSubject<{ [areaName: string]: { [vehicleType: string]: number[] } }>(null);
  // vehicle-parking/vehicle-purchasing-power
  private vehicleParkingPurchasingPowerLock = 0;
  vehicleParkingPurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  // vehicle-parking/entrance-exit
  private vehicleParkingEntranceExitLock = 0;
  vehicleParkingEntranceExitTrend$ = new BehaviorSubject<{ [areaName: string]: { entrance: number[]; exit: number[] } }>(null);
  vehicleParkingEntranceExitBreakdown$ = new BehaviorSubject<{ [areaName: string]: { entrance: number; exit: number } }>(null);
  vehicleParkingAvgTimespent$ = new BehaviorSubject<{ [areaName: string]: number }>(null);
  vehicleParkingVehicleConversionRateTrend$ = new BehaviorSubject<number[]>(null);
  currentVehicleParkingProximity$ = new BehaviorSubject<{ proximity: number; diff: number; diffPercent: number }>(null);
  currentVehicleParkingEntranceExit$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentAreaVehicleParkingEntranceExit$ = new BehaviorSubject<{ [areaName: string]: { count: number; diff: number; diffPercent: number } }>(null);
  currentVehicleParkingAvgTimespent$ = new BehaviorSubject<{ [areaName: string]: { avgTimespent: number; diff: number; diffPercent: number } }>(null);
  currentVehicleParkingConversionRate$ = new BehaviorSubject<{ conversion_rate: number; diff: number; diffPercent: number }>(null);
  // vehicle-parking/entrance-exit-by-hour
  private vehicleParkingEntranceExitByHourLock = 0;
  vehicleParkingVehicleByHourTrend$ = new BehaviorSubject<{ [areaName: string]: number[] }>(null);
  vehicleParkingBusiestTime$ = new BehaviorSubject<{ [areaName: string]: { headcount: number; hour: string } }>(null);
  vehicleParkingEntranceExitHour$ = new BehaviorSubject<{ [areaName: string]: { entrance: number[]; exit: number[] } }>(null);
  vehicleParkingEntranceExitBusiestTime$ = new BehaviorSubject<{ [areaName: string]: { entrance: { headcount: number; hour: string }; exit: { headcount: number; hour: string } } }>(null);

  // vehicle-parking/entrance-exit pk-p2
  private vehicleParkingPKP2EntranceExitLock = 0;
  vehicleParkingPKP2VehicleConversionRateTrend$ = new BehaviorSubject<number[]>(null);
  currentVehicleParkingPKP2Proximity$ = new BehaviorSubject<{ proximity: number; diff: number; diffPercent: number }>(null);
  currentVehicleParkingPKP2EntranceExit$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentVehicleParkingPKP2ConversionRate$ = new BehaviorSubject<{ conversion_rate: number; diff: number; diffPercent: number }>(null);
  // vehicle-parking/mode-of-transportation pk-p2
  private vehicleParkingPKP2ModeofTransportLock = 0;
  vehicleParkingPKP2ModeofTransportTrend$ = new BehaviorSubject<{ [vehicleType: string]: number[] }>(null);
  vehicleParkingPKP2ModeofTransportProxTrend$ = new BehaviorSubject<{ [vehicleType: string]: number[] }>(null);
  // vehicle-parking/entrance-exit pk-p3
  private vehicleParkingPKP3EntranceExitLock = 0;
  currentVehicleParkingPKP3EntranceExit$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // mock/store-count
  private mockStoreCountLock = 0;
  mockStoreCountTrend$ = new BehaviorSubject<number[]>(null);
  currentMockStoreCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // mock/store-visitor
  private mockStoreVisitorLock = 0;
  mockStoreVisitorTrend$ = new BehaviorSubject<number[]>(null);
  currentMockStoreVisitor$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentMockStoreVisitorAvgTimeSpent$ = new BehaviorSubject<{ timespent: number; diff: number; diffPercent: number }>(null);
  currentMockConversionRate$ = new BehaviorSubject<{ rate: number; diff: number; diffPercent: number }>(null);
  // mock/store-visitor-by-hour
  private mockStoreVisitorByHourLock = 0;
  mockStoreVisitorByhourTrend$ = new BehaviorSubject<number[]>(null);
  mockStoreVisitorPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  // mock/store-count-average-by-day-type
  private mockStoreCountAverageByDayTypeLock = 0;
  currentMockStoreCountAverageWeekday$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentMockStoreCountAverageWeekend$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // mock/store-visitor-average-by-day-type
  private mockStoreVisitorAverageByDayTypeLock = 0;
  currentMockStoreVisitorAverageWeekday$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentMockStoreVisitorAverageWeekend$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // mock/ads-visitor-profile
  private mockAdsVisitorProfileLock = 0;
  mockAdsVisitorProfileTrend$ = new BehaviorSubject<number[]>(null);
  currentMockAdsVisitorProfile$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentMockAdsAvgTimeExpose$ = new BehaviorSubject<{ avg: number; diff: number; diffPercent: number }>(null);
  mockAdsVisitorProfileByGenderTrend$ = new BehaviorSubject<{ [gender: string]: number[] }>(null);
  mockAdsVisitorProfileByAgeTrend$ = new BehaviorSubject<{ [age: string]: number[] }>(null);
  mockAdsVisitorProfileByLocalTrend$ = new BehaviorSubject<{ [eth: string]: number[] }>(null);
  mockAdsVisitorProfileByProfessionTrend$ = new BehaviorSubject<{ [profess: string]: number[] }>(null);
  mockAdsVisitorProfileByGenderBreakdown$ = new BehaviorSubject<{ [gender: string]: number }>(null);
  mockAdsVisitorProfileByAgeBreakdown$ = new BehaviorSubject<{ [age: string]: number }>(null);
  mockAdsVisitorProfileByLocalBreakdown$ = new BehaviorSubject<{ [eth: string]: number }>(null);
  mockAdsVisitorProfileByProfessionBreakdown$ = new BehaviorSubject<{ [profess: string]: number }>(null);
  // mock/ads-visitor-profile-by-hour
  private mockAdsVisitorProfileByHourLock = 0;
  mockAdsVisitorProfileByHourTrend$ = new BehaviorSubject<number[]>(null);
  // visualization/traffic-site-videos
  private trafficSiteVisualizationVideoLock = 0;
  trafficSiteVisualVideo$ = new BehaviorSubject<{ camera: string; url: string }[]>(null);
  // all visualization/traffic-site-videos
  private trafficSiteAllVisualizationVideoLock = 0;
  trafficSiteAllVisualVideo$ = new BehaviorSubject<{ camera: string; url: string }[]>(null);
  // lidar/lidar-area-engagement-count
  private lidarAreaEngagementCountLock = 0;
  lidarAreaEngagementCountTrend$ = new BehaviorSubject<number[]>(null);
  currentLidarAreaEngagementCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentLidarAreaEngagementCountAvgWeekDayLast7Day$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentLidarAreaEngagementCountAvgWeekEndLast7Day$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // lidar/lidar-area-conversion-rate
  private lidarAreaConversionRateLock = 0;
  currentLidarAreaConversionRate$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // lidar/lidar-area-impression
  private lidarAreaImpressionLock = 0;
  lidarAreaImpressionTrend$ = new BehaviorSubject<number[]>(null);
  currentLidarAreaImpression$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentLidarAreaImpressionAvgWeekDayLast7Day$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentLidarAreaImpressionAvgWeekEndLast7Day$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // lidar/lidar-area-impression-by-hour
  private lidarAreaImpressionByHourLock = 0;
  lidarAreaImpressionByHourTrend$ = new BehaviorSubject<number[]>(null);
  // lidar/lidar-area-reach
  private lidarAreaReachLock = 0;
  lidarAreaReachTrend$ = new BehaviorSubject<number[]>(null);
  currentLidarAreaReach$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentLidarAreaReachAvgWeekDayLast7Day$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentLidarAreaReachAvgWeekEndLast7Day$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // lidar/lidar-area-reach-by-hour
  private lidarAreaReachByHourLock = 0;
  lidarAreaReachByhourTrend$ = new BehaviorSubject<number[]>(null);
  lidarAreaReachPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  // lidar/lidar-area-engagement-count-by-hour
  private lidarAreaEngagementCountByHourLock = 0;
  lidarAreaEngagementCountByhourTrend$ = new BehaviorSubject<number[]>(null);
  lidarAreaEngagementCountPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  // lidar/lidar-area-engagement-minutes
  private lidarAreaEngagementMinutesLock = 0;
  // lidar/lidar-area-avg-engagement-minutes
  private lidarAreaAvgEngagementMinutesLock = 0;
  currentLidarAreaAvgEngagementMinutes$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // lidar/lidar-area-visitor-profile
  private lidarAreaVisitorProfileLock = 0;
  lidarAreaVisitorProfileByGenderBreakdown$ = new BehaviorSubject<{ [gender: string]: number }>(null);
  lidarAreaVisitorProfileByAgeBreakdown$ = new BehaviorSubject<{ [age: string]: number }>(null);
  lidarAreaVisitorProfileByLocalBreakdown$ = new BehaviorSubject<{ [eth: string]: number }>(null);
  lidarAreaVisitorProfileByProfessionBreakdown$ = new BehaviorSubject<{ [profess: string]: number }>(null);
  // building/zone-traffic-flow-one-direction-unmerged-by-pin
  private buildingZoneTrafficFlowOneDirectionUnmergedByPinLock = 0;
  buildingZoneTrafficFlowOneDirectionUnmergedByPin$ = new BehaviorSubject<SankeyRawLinks[]>(null);
  // building/zone-traffic-flow-unmerged-by-pin
  private buildingZoneTrafficFlowUnmergedByPinLock = 0;
  buildingZoneTrafficFlowUnmergedByPin$ = new BehaviorSubject<SankeyRawLinks[]>(null);
  // building/zone-traffic-flow-by-pin
  private buildingZoneTrafficFlowByPinLock = 0;
  buildingZoneTrafficFlowByPin$ = new BehaviorSubject<SankeyRawLinks[]>(null);
  // building/zone-traffic-flow-no-lookback-by-pin
  private buildingZoneTrafficFlowNoLookbackByPinLock = 0;
  buildingZoneTrafficFlowNoLookbackByPin$ = new BehaviorSubject<SankeyRawLinks[]>(null);
  // building/zone-synergy-by-pin
  private buildingZoneSynergyByPinLock = 0;
  buildingZoneSynergyByPin$ = new BehaviorSubject<{ [zoneName: string]: number }>(null);
  // building/visitor-profile-by-pin profile-cross-level=1
  private buildingVisitorProfileCrossLevelOneByPinLock = 0;
  buildingGenderProfileByPinBreakdown$ = new BehaviorSubject<{ [gender: string]: number }>(null);
  buildingPurchaseRateByPinTrend$ = new BehaviorSubject<number[]>(null);
  currentBuildingPurchaseRateByPin$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // building/visitor-profile-by-pin profile-cross-level=2
  private buildingVisitorProfileCrossLevelTwoByPinLock = 0;
  buildingEthnicityProfileByPinBreakdown$ = new BehaviorSubject<{ [gender: string]: number[] }>(null);
  buildingAgeProfileByPinBreakdown$ = new BehaviorSubject<{ [gender: string]: number[] }>(null);
  buildingEthnicityPurchasingByPinBreakdown$ = new BehaviorSubject<{ [className: string]: number[] }>(null);
  buildingCustomSegmentPurchasingByPinBreakdown$ = new BehaviorSubject<{ [className: string]: number[] }>(null);
  // building/timespent-by-pin
  private buildingTimespentByPinLock = 0;
  buildingTimespentByPinBinData$ = new BehaviorSubject<{ [binName: string]: number }>(null);
  buildingAvgTimespentByPinData$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // building/messenger-brand-by-pin
  private buildingMessengerBrandByPinLock = 0;
  buildingMessengerBrandByPinBreakdownData$ = new BehaviorSubject<{ [brandName: string]: number }>(null);
  // building/area-entrance-exit-by-pin
  private buildingAreaEntranceExitByPinLock = 0;
  buildingAreaEntranceExitByPinTrend$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  buildingAreaEntranceExitByPinBreakdown$ = new BehaviorSubject<{ entrance: number; exit: number }>(null);
  currentBuildingAreaEntranceExitByPin$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentBuildingAreaAvgTimespentByPin$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // floor/area-entrance-exit-by-pin
  private buildingFloorAreaEntranceExitByPinLock = 0;
  buildingFloorAreaEntranceExitByPinTrend$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  buildingFloorAreaEntranceExitByPinBreakdown$ = new BehaviorSubject<{ entrance: number; exit: number }>(null);
  currentBuildingFloorAreaEntranceExitByPin$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentBuildingFloorAreaAvgTimespentByPin$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // zone/area-entrance-exit-by-pin
  private zoneAreaEntranceExitByPinLock = 0;
  zoneAreaEntranceExitByPinTrend$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  zoneAreaEntranceExitByPinBreakdown$ = new BehaviorSubject<{ entrance: number; exit: number }>(null);
  currentZoneAreaEntranceExitByPin$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentZoneAreaAvgTimespentByPin$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // currentLast7daysZoneAreaEntranceExitByPin$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // zone/visitor-profile all-zone cross-level = 1
  private allZoneVisitorProfileCrossLevelOneLock = 0;
  allZoneGenderProfileBreakdown: { [zoneName: string]: { [gender: string]: number } } = {};
  allZoneGenderProfileRawBreakdown: { [zoneName: string]: { [gender: string]: number } } = {};
  allZonePurchaseRateTrend: { [zoneName: string]: number[] } = {};
  currentAllZonePurchaseRate: { [zoneName: string]: { current: number; diff: number; diffPercent: number } } = {};
  allZoneGenderProfileBreakdown$ = new BehaviorSubject<{ [zoneName: string]: { [gender: string]: number } }>(null);
  allZoneGenderProfileRawBreakdown$ = new BehaviorSubject<{ [zoneName: string]: { [gender: string]: number } }>(null);
  allZonePurchaseRateTrend$ = new BehaviorSubject<{ [zoneName: string]: number[] }>(null);
  currentallZonePurchaseRate$ = new BehaviorSubject<{ [zoneName: string]: { current: number; diff: number; diffPercent: number } }>(null);
  // zone/visitor-profile all-zone cross-level = 2
  private allZoneVisitorProfileCrossLevelTwoLock = 0;
  allZoneAgeProfileBreakdown: { [zoneName: string]: { [gender: string]: number[] } } = {};
  allZoneAgeProfileBreakdown$ = new BehaviorSubject<{ [zoneName: string]: { [gender: string]: number[] } }>(null);
  // zone/area-entrance-exit
  private zoneAreaEntranceExitLock = 0;
  zoneAreaEntranceExitTrend$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  currentZoneAreaEntranceExit$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentZoneAreaAvgTimespent$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  allZoneAreaEntranceExitTrend: { [zoneName: string]: { entrance: number[]; exit: number[] } } = {};
  allZoneAreaAvgTimespentTrend: { [zoneName: string]: number[] } = {};
  allZoneAreaEntranceExitBreakdown: { [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } } = {};
  allZoneAreaAvgTimespentBreakdown: { [zoneName: string]: number } = {};
  allZoneAreaAvgTimespent: { [zoneName: string]: { current: number; diff: number; diffPercent: number } } = {};
  allZoneAreaConversionRate: { [zoneName: string]: { current: number; diff: number; diffPercent: number } } = {};
  allZoneAreaEntranceExitTrend$ = new BehaviorSubject<{ [zoneName: string]: { entrance: number[]; exit: number[] } }>(null);
  brandEntranceExitBreakdown$ = new BehaviorSubject<{ [brandName: string]: { entrance: number; exit: number } }>(null);
  allZoneAreaAvgTimespentTrend$ = new BehaviorSubject<{ [zoneName: string]: number[] }>(null);
  allZoneAreaAvgTimespentBreakdown$ = new BehaviorSubject<{ [zoneName: string]: number }>(null);
  allZoneAreaEntranceExitBreakdown$ = new BehaviorSubject<{ [zoneName: string]: { entrance: number[]; exit: number[] } }>(null);
  currentAllZoneAreaEntranceExit$ = new BehaviorSubject<{ [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  currentAllZoneAreaAvgTimespent$ = new BehaviorSubject<{ [zoneName: string]: { current: number; diff: number; diffPercent: number } }>(null);
  currentAllZoneAreaConversionRate$ = new BehaviorSubject<{ [zoneName: string]: { current: number; diff: number; diffPercent: number } }>(null);
  // zone/area-entrance-exit-by-pin-by-hour
  private zoneAreaEntranceExitByPinByHourLock = 0;
  zoneAreaEntranceExitByPinByHourTrend$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  zoneAreaByPinBusiestTime$ = new BehaviorSubject<{ headcount: number; hour: string }>(null);
  // zone/area-entrance-exit-by-hour (all-zone)
  private allZoneAreaEntranceExitByHourLock = 0;
  allZoneEntranceExitByHour: { [zoneName: string]: { entrance: number[]; exit: number[] } } = {};
  allZoneBusiestTime: { [zoneName: string]: { headcount: number; hour: string } } = {};
  allZoneBusiestTime$ = new BehaviorSubject<{ [zoneName: string]: { headcount: number; hour: string } }>(null);
  allZoneEntranceExitByHour$ = new BehaviorSubject<{ [zoneName: string]: { entrance: number[]; exit: number[] } }>(null);
  // zone/timespent (all-zone)
  private allZoneTimespentLock = 0;
  allZoneTimespentData: { [zoneName: string]: { [binName: string]: number } } = {};
  allZoneAvgTimespentDataReID: { [zoneName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } } = {};
  allZoneTimespentData$ = new BehaviorSubject<{ [zoneName: string]: { [binName: string]: number } }>(null);
  allZoneAvgTimespentDataReID$ = new BehaviorSubject<{ [zoneName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } }>(null);
  // zone/zone-synergy (all-zone)
  private allZoneToZoneSynergyLock = 0;
  allZoneToZoneSynergyData: { [zoneName: string]: { [zoneName: string]: number } } = {};
  allZoneToZoneSynergyData$ = new BehaviorSubject<{ [zoneName: string]: { [zoneName: string]: number } }>(null);
  // zone/area-entrance-exit-by-pin all-pin by floor
  private zoneAreaEntranceExitAllPinLock = 0;
  allPinByZoneEntrance$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  allPinByZoneExit$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  zoneEntranceExitByPinData$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: { entrance: number; exit: number } } } } }>(null);
  // zone/area-entrance-exit-by-pin avg-by-day-type
  private zoneAreaEntranceExitByPinAvgByDayTypeLock = 0;
  currentZoneAvgWeekdayLast7DaysEntranceExitByPin$ = new BehaviorSubject<{ entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } }>(null);
  currentZoneAvgWeekendLast7DaysEntranceExitByPin$ = new BehaviorSubject<{ entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } }>(null);
  // zone/area-entrance-exit all-zone avg-by-day-type
  private allZoneAreaEntranceExitAvgByDayTypeLock = 0;
  allZoneAvgWeekdayLast7DaysEntranceExit: { [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } } = {};
  allZoneAvgWeekendLast7DaysEntranceExit: { [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } } = {};
  currentAllZoneAvgWeekdayLast7DaysEntranceExit$ = new BehaviorSubject<{ [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  currentAllZoneAvgWeekendLast7DaysEntranceExit$ = new BehaviorSubject<{ [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  // building/area-entrance-exit-by-pin all-pin
  private buildingAreaEntranceExitAllPinLock = 0;
  allPinByBuildingEntrance$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  allPinByBuildingExit$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  buildingEntranceExitByPinData$ = new BehaviorSubject<{ [buildingName: string]: { [pinName: string]: { entrance: number; exit: number } } }>(null);
  buildingEntranceExitByPinAvgPerDayData$ = new BehaviorSubject<{ [buildingName: string]: { [pinName: string]: { entrance: number; exit: number } } }>(null);
  entranceBuildingPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  exitBuildingPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } }>(null);
  // floor/area-entrance-exit-by-pin all-pin
  private floorAreaEntranceExitAllPinLock = 0;
  allPinByFloorEntrance$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  allPinByFloorExit$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  spAllPinByFloorEntrance$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  spAllPinByFloorExit$ = new BehaviorSubject<{ [pinName: string]: number }>(null);
  // zone/area-entrance-exit-by-pin all-zone
  private allZoneAreaEntranceExitAllPinLock = 0;
  allPinByAllZoneEntrance: { [zoneName: string]: { [pinName: string]: number } } = {};
  allPinByAllZoneExit: { [zoneName: string]: { [pinName: string]: number } } = {};
  allPinByAllZoneEntrance$ = new BehaviorSubject<{ [zoneName: string]: { [pinName: string]: number } }>(null);
  allPinByAllZoneExit$ = new BehaviorSubject<{ [zoneName: string]: { [pinName: string]: number } }>(null);
  // building/timespent
  private buildingTimespentLock = 0;
  buildingTimespentBinData$ = new BehaviorSubject<{ [binName: string]: number }>(null);
  avgTimespentTimePairData$ = new BehaviorSubject<[number, number]>(null);
  buildingAvgTimespentData$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  buildingAvgTimespentTrendData$ = new BehaviorSubject<{ [buildingName: string]: number[] }>(null);
  // building/zone-synergy
  private buildingZoneSynergyLock = 0;
  buildingZoneSynergy$ = new BehaviorSubject<{ [zoneName: string]: number }>(null);
  buildingZoneSynergyRaw$ = new BehaviorSubject<{ [zoneName: string]: number }>(null);
  // overall/visitor-profile profile-cross-level=3
  private visitorProfileCrossLevelFourLock = 0;
  unfilteredBuildingVisitorProfileData$ = new BehaviorSubject<IFetchData<AreaVisitorProfileData[]>[]>(null);
  // building/area-entrance-exit avg-by-day-type
  private buildingAreaEntranceExitAvgByDayTypeLock = 0;
  currentBuildingAvgWeekdayEntranceExit$ = new BehaviorSubject<{ entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } }>(null);
  currentBuildingAvgWeekendEntranceExit$ = new BehaviorSubject<{ entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } }>(null);
  // building/zone-synergy-by-pin all-pin
  private buildingZoneSynergyAllPinLock = 0;
  buildingZoneSynergyAllPin$ = new BehaviorSubject<{ [pinName: string]: { [zoneName: string]: number } }>(null);
  // building/entrance-exit avg-by-day-type
  private entranceExitAvgByDayTypeLock = 0;
  currentAllBuildingAvgWeekdayEntranceExit$ = new BehaviorSubject<{ [building: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  currentAllBuildingAvgWeekendEntranceExit$ = new BehaviorSubject<{ [building: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } }>(null);
  // building/area-entrance-exit-by-pin-by-hour all-pin
  private buildingAreaEntranceExitByHourAllPinLock = 0;
  entranceBuildingByHourPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } }>(null);
  exitBuildingByHourPin$ = new BehaviorSubject<{ [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } }>(null);
  // store/area-entrance-exit
  private storeAreaEntranceExitLock = 0;
  storeAreaEntranceExitTrend$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  storeAreaProximityTrend$ = new BehaviorSubject<number[]>(null);
  storeAreaEntranceExitBreakdown$ = new BehaviorSubject<{ entrance: number; exit: number }>(null);
  currentStoreAreaEntranceExit$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentStoreAreaAvgTimespent$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  storeAllAreaEntranceExitTrend$ = new BehaviorSubject<{ [storeName: string]: { entrance: number[]; exit: number[] } }>(null);
  storeAllAreaEntranceExitBreakdown$ = new BehaviorSubject<{ [storeName: string]: { entrance: number; exit: number } }>(null);
  // store/area-entrance-exit-by-hour
  private storeAreaEntranceExitByHourLock = 0;
  // storeAreaEntranceExitTrend$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  storeAreaEntranceExitByHourBreakdown$ = new BehaviorSubject<{ entrance: number[]; exit: number[] }>(null);
  storeAreaEntranceByHourBreakdown$ = new BehaviorSubject<number[]>(null);
  storeAreaProximityByHourBreakdown$ = new BehaviorSubject<number[]>(null);
  storeAreaBusiestTime$ = new BehaviorSubject<{ headcount: number; hour: string }>(null);
  // store/area-entrance-exit avg-by-day-type
  private storeAreaEntranceExitAvgDayTypeLock = 0;
  currentStoreAreaEntranceExitAvgWeekday$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentStoreAreaEntranceExitAvgWeekend$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentStoreAreaAvgTimespentAvgWeekday$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  currentStoreAreaAvgTimespentAvgWeekend$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // store/zone-synergy
  private storeAreaToZoneSynergyLock = 0;
  // storeAreaToZoneSynergyData: { [zoneName: string]: { [zoneName: string]: number } } = {} ;
  storeAreaToZoneSynergyData$ = new BehaviorSubject<{ [zoneName: string]: number }>(null);
  storeAreaToZoneSynergyRawData$ = new BehaviorSubject<{ [zoneName: string]: number }>(null);
  // zone/visitor-profile-by-pin profile-cross-level=2
  private zoneVisitorProfileCrossLevelTwoByPinLock = 0;
  zoneAgeProfileByPinBreakdown$ = new BehaviorSubject<{ [gender: string]: number[] }>(null);
  zoneGenderProfileByPinBreakdown$ = new BehaviorSubject<{ [gender: string]: number }>(null);
  // building/visitor-profile profile-cross-level = 1
  private buildingAreaVisitorProfileCrossLevelTwoLock = 0;
  currentBuildingAreaStudentProfileData$ = new BehaviorSubject<{ [buildingName: string]: { current: number; diff: number; diffPercent: number } }>(null);
  currentBuildingAreaMaskProfileData$ = new BehaviorSubject<{ [buildingName: string]: { current: number; diff: number; diffPercent: number } }>(null);
  // building/area-entrance-exit-by-hour
  private buildingAreaEntranceExitByHourLock = 0;
  buildingAreaEntranceExitTrend$ = new BehaviorSubject<{ [buildingName: string]: { entrance: number[]; exit: number[] } }>(null);
  buildingAreaEntranceExitByHourBreakdown$ = new BehaviorSubject<{ [buildingName: string]: { entrance: number[]; exit: number[] } }>(null);
  buildingAreaBusiestTime$ = new BehaviorSubject<{ [buildingName: string]: { headcount: number; hour: string } }>(null);
  buildingAreaBusiestTime: { [buildingName: string]: { headcount: number; hour: string } } = {};
  buildingAreaBusiestTimeAvgPerDay: { [buildingName: string]: { headcount: number; hour: string } } = {};
  // building/area-entrance-exit-by-hour average-day-of-week
  private buildingAreaEntranceExitByHourAverageDayOfWeekLock = 0;
  buildingAreaAverageDayOfWeekData$ = new BehaviorSubject<{ [day_of_week: string]: { [buildingName: string]: { [channelName: string]: number[] } } }>(null);
  buildingAreaAverageDayOfWeekGroupDayWeekData$ = new BehaviorSubject<{ [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } }>(null);
  // building/area-entrance-exit-by-hour day-of-week
  private buildingAreaEntranceExitByHourDayOfWeekLock = 0;
  buildingAreaDayOfWeekData$ = new BehaviorSubject<{ [day_of_week: string]: { [buildingName: string]: { [channelName: string]: number[] } } }>(null);
  buildingAreaDayOfWeekGroupDayWeekData$ = new BehaviorSubject<{ [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } }>(null);
  // vehicle-parking/area-entrance-exit-by-hour day-of-week
  private vehicleParkingEntranceExitByHourDayOfWeekLock = 0;
  vehicleParkingDayOfWeekData$ = new BehaviorSubject<{ [day_of_week: string]: { [buildingName: string]: { [channelName: string]: number[] } } }>(null);
  vehicleParkingDayOfWeekGroupDayWeekData$ = new BehaviorSubject<{ [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } }>(null);
  // building/building-synergy
  private buildingToBuildingSynergyLock = 0;
  buildingToBuildingSynergy$ = new BehaviorSubject<{ [buildingName: string]: { [buildingName: string]: number } }>(null);
  buildingToBuildingSynergyRaw$ = new BehaviorSubject<{ [buildingName: string]: { [buildingName: string]: number } }>(null);
  // floor/store-area
  private floorStoreAreaLock = 0;
  floorStoreAreaPercentage$ = new BehaviorSubject<{ [floorName: string]: number }>(null);
  floorStoreArea$ = new BehaviorSubject<{ [floorName: string]: number }>(null);
  // floor/store-area-by-category
  private floorStoreAreaCategoryLock = 0;
  floorStoreAreaCategoryPercentage$ = new BehaviorSubject<{ [floorName: string]: { [categoryName: string]: number } }>(null);
  floorStoreAreaCategory$ = new BehaviorSubject<{ [floorName: string]: { [categoryName: string]: number } }>(null);
  // building/traffic-breakdown
  private buildingTrafficBreakdownLock = 0;
  buildingTrafficBreakdown$ = new BehaviorSubject<{ [buildingName: string]: { count: number } }>(null);
  buildingVisitorsBreakdown$ = new BehaviorSubject<{ 'one': number; 'two': number; 'three': number }>(null);
  // building/unique-traffic-breakdown
  private buildingUniqueTrafficBreakdownLock = 0;
  buildingUniqueTrafficBreakdown$ = new BehaviorSubject<{ [buildingName: string]: number }>(null);
  currentBuildingUniqueTrafficBreakdown$ = new BehaviorSubject<{ [buildingName: string]: { count: number; diff: number; diffPercent: number } }>(null);
  // traffic-site/count all-area
  private trafficSiteCountAllAreaLock = 0;
  trafficSiteCountByArea$ = new BehaviorSubject<{ [areaName: string]: number }>(null);
  // traffic-site/vehicle-profile (for ads planner)
  private predTrafficSiteVehicleProfileLock = 0;
  predUnfilteredVehicleProfileData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  predTrafficSiteProfilePurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  predTrafficSiteProfileModeOfTransportBreakdown$ = new BehaviorSubject<{ [type: string]: number }>(null);
  predTrafficSiteProfileTopTenCarBrandBreakdown$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  predCurrentTrafficSiteProfileCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  predCurrentTrafficSiteProfileCountbyRange$ = new BehaviorSubject<{ minCount: number; maxCount: number }>(null);
  // traffic-site/count (for ads planner)
  private predTrafficSiteCountLock = 0;
  predCurrentTrafficSiteAdsExposurebyRange$ = new BehaviorSubject<{ minCount: number; maxCount: number }>(null);
  // traffic-site/vehicle-profile-unique-visitor (for ads planner)
  private predTrafficSiteVehicleProfileUniqueVisitorLock = 0;
  predUnfilteredVehicleProfileUniqueVisitorData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  predTrafficSiteProfileUniqueVisitorPurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  predTrafficSiteProfileUniqueVisitorModeOfTransportBreakdown$ = new BehaviorSubject<{ [type: string]: number }>(null);
  predTrafficSiteProfileUniqueVisitorTopTenCarBrandBreakdown$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  predCurrentTrafficSiteUniqueVisitorCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  predCurrentTrafficSiteAvgVisitorCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  predCurrentTrafficSiteProfileUniqueVisitorCountbyRange$ = new BehaviorSubject<{ minCount: number; maxCount: number }>(null);
  predCurrentTrafficSiteAvgVisitorCountbyRange$ = new BehaviorSubject<{ minCount: number; maxCount: number }>(null);
  // traffic-site/package-count
  private trafficSitePackageCountLock = 0;
  currentTrafficSitePackageCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentTrafficSitePackageExposureTimeCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  trafficSitePackageCount$ = new BehaviorSubject<number[]>(null);
  trafficSitePackageAdsExposureTimebyHour$ = new BehaviorSubject<number[]>(null);
  // traffic-site/package-count-by-hour
  private trafficSitePackageCountByHourLock = 0;
  trafficSitePackageCountByHour$ = new BehaviorSubject<number[]>(null);
  trafficSitePackagePeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  trafficSitePackageOffPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  // traffic-site/package-vehicle-profile
  private trafficSitePackageVehicleProfileLock = 0;
  unfilteredPackageVehicleProfileData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  callTrafficSitePackageVehicleProfilePrediction$ = new BehaviorSubject<boolean>(false);
  trafficSitePackageProfileCountTrend$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  trafficSitePackageProfilePurchasingPowerTrend$ = new BehaviorSubject<{ [tier: string]: number[] }>(null);
  trafficSitePackageProfileModeOfTransportTrend$ = new BehaviorSubject<{ [type: string]: number[]; total: number[] }>(null);
  trafficSitePackageProfileCarBrandTrend$ = new BehaviorSubject<{ [brand: string]: number[] }>(null);
  trafficSitePackageProfilePurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  trafficSitePackageProfileModeOfTransportBreakdown$ = new BehaviorSubject<{ [type: string]: number }>(null);
  trafficSitePackageProfileTopTenCarBrandBreakdown$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  currentTrafficSitePackageProfileCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  trafficSitePackageProfileCountTrend: number[] = [];
  trafficSitePackageProfilePurchasingPowerTrend: { [tier: string]: number[] };
  trafficSitePackageProfileModeOfTransportTrend: { [type: string]: number[]; total: number[] };
  trafficSitePackageProfileCarBrandTrend: { [brand: string]: number[] };
  // traffic-site/package-vehicle-profile-by-hour
  private trafficSitePackageVehicleProfileByHourLock = 0;
  trafficSitePackageProfileCountByHour$ = new BehaviorSubject<number[]>(null);
  unfilteredPackageVehicleProfileByHourData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  trafficSitePackageProfilePeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  trafficSitePackageProfileOffPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  // traffic-site/package-vehicle-profile-public-private
  private trafficSitePackageVehicleProfilePublicPrivateLock = 0;
  callTrafficSitePackageVehicleProfilePublicPrivatePrediction$ = new BehaviorSubject<boolean>(false);
  unfilteredPackageVehicleProfilePublicPrivateData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  trafficSitePackageProfileModeOfTransportPublicPrivateTrend$ = new BehaviorSubject<{ [vehicle_type: string]: number[]; total: number[] }>({ total: Array.from({ length: 7 }).map(() => null) });
  trafficSitePackageProfileModeOfTransportPublicPrivateBreakdown$ = new BehaviorSubject<{ [vehicle_type: string]: number }>(null);
  trafficSitePackageProfileModeOfTransportPublicPrivateTrend: { [vehicle_type: string]: number[]; total: number[] };
  // vehicle-parking/entrance-exit day in month
  private vehicleParkingEntranceExitMonthLock = 0;
  private vehicleParkingEntranceExitMonthLastFetch = { month: -1, year: -1 };
  private lastVehicleParkingEntranceExitMonthTime = 0;
  vehicleParkingEntranceMonthData$ = new BehaviorSubject<{ [day: string]: number }>({});
  vehicleParkingExitMonthData$ = new BehaviorSubject<{ [day: string]: number }>({});
  // vehicle-parking/provinces all-area
  private vehicleParkingProvinceUngroupAllAreaLock = 0;
  vehicleParkingProvinceEntranceExitTrend$ = new BehaviorSubject<{ [areaName: string]: { [province: string]: number[] } }>(null);
  vehicleParkingProvinceEntranceExitBreakdown$ = new BehaviorSubject<{ [areaName: string]: { [province: string]: number } }>(null);
  // vehicle-parking/entrance-exit avg day type
  private vehicleParkingEntranceExitAvgDayTypeLock = 0;
  currentAreaVehicleParkingEntranceExitAvgDayType$ = new BehaviorSubject<{ [areaName: string]: { count: number; diff: number; diffPercent: number } }>(null);
  currentVehicleParkingEntranceExitAvgWeekday$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentVehicleParkingEntranceExitAvgWeekend$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  // vehicle-parking/vehicle-purchasing-power all-area
  private vehicleParkingPurchasingPowerAllAreaLock = 0;
  vehicleParkingAllAreaPurchasingPowerBreakdown$ = new BehaviorSubject<{ [areaName: string]: { [tier: string]: number } }>(null);
  // vehicle-parking/vehicle-parking-synergy
  private vehicleParkingToVehicleParkingSynergyLock = 0;
  vehicleParkingToVehicleParkingSynergyData$ = new BehaviorSubject<{ [areaName: string]: number }>(null);
  // vehicle-parking/plate-timespent all-area
  private vehicleParkingAllAreaPlateTimespentLock = 0;
  vehicleParkingAllAreaPlateTimespentBinData$ = new BehaviorSubject<{ [areaName: string]: { [binName: string]: number } }>(null);
  vehicleParkingAllAreaPlateTimespentBinSelectedData$ = new BehaviorSubject<{ [binName: string]: number }>(null);
  // vehicle-parking/vehicle-parking-unique-area-visit
  private vehicleParkingUniqueAreaVisitLock = 0;
  vehicleParkingToVehicleParkingUniqueAreaVisitData$ = new BehaviorSubject<{ one: number; two_three: number; four_up: number }>({ one: null, two_three: null, four_up: null });
  // vehicle-parking/plate-number-definition
  private vehicleParkingPlateNumberDefinitionLock = 0;
  vehicleParkingPlateNumberDefinitonData$ = new BehaviorSubject<{ [definitionName: string]: number }>(null);
  currentVehicleParkingBuyerData$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentVehicleParkingPlateNumberDefinitonData$ = new BehaviorSubject<{ [definitionName: string]: { count: number; diff: number; diffPercent: number } }>(null);
  currentVehicleParkingSellerData$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  // vehicle-parking/vehicle-profile
  public vehicleParkingVehicleProfileLock = 0;
  vehicleParkingProfileEntranceExit$ = new BehaviorSubject<{ entrance: number; exit: number }>(null);
  vehicleParkingProfilePurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  vehicleParkingProfileModeOfTransportBreakdown$ = new BehaviorSubject<{ [type: string]: number }>(null);
  vehicleParkingProfileTopTenCarBrandBreakdown$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  // vehicle-parking/vehicle-profile all-area
  public vehicleParkingVehicleProfileAllAreaLock = 0;
  unfilteredVehicleProfileAllAreaData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  vehicleParkingVehicleProfileAvgTimespent$ = new BehaviorSubject<{ [areaName: string]: number }>(null);
  vehicleParkingVehicleProfileEntranceExitBreakdown$ = new BehaviorSubject<{ [areaName: string]: { entrance: number; exit: number } }>(null);
  // vehicle-parking/vehicle-profile avg-by-day-type
  public vehicleParkingVehicleProfileAvgDayTypeLock = 0;
  unfilteredVehicleParkingVehicleProfileAvgDayTypeData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  currentVehicleParkingVehicleProfileAvgByDayTypeCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentVehicleParkingVehicleProfileAvgByDayTypeAvgTimespent$ = new BehaviorSubject<{ avgTimespent: number; diff: number; diffPercent: number }>(null);
  currentVehicleParkingVehicleProfileEntranceExitAvgWeekday$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  currentVehicleParkingVehicleProfileEntranceExitAvgWeekend$ = new BehaviorSubject<{ entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } }>(null);
  // vehicle-parking/vehicle-profile-by-hour
  public vehicleParkingVehicleProfileByHourLock = 0;
  vehicleParkingVehicleProfilePeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  // vehicle-parking/vehicle-profile-by-pin
  private vehicleParkingVehicleProfileByPinLock = 0;
  unfilteredVehicleParkingProfileByPinData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  callVehicleParkingProfileByPinPrediction$ = new BehaviorSubject<boolean>(false);
  vehicleParkingProfileByPinCountTrend$ = new BehaviorSubject<number[]>(Array.from({ length: 7 }).map(() => null));
  vehicleParkingProfileByPinEntranceExit$ = new BehaviorSubject<{ entrance: number; exit: number }>(null);
  vehicleParkingProfileByPinPurchasingPowerTrend$ = new BehaviorSubject<{ [tier: string]: number[] }>(null);
  vehicleParkingProfileByPinModeOfTransportTrend$ = new BehaviorSubject<{ [type: string]: number[]; total: number[] }>(null);
  vehicleParkingProfileByPinCarBrandTrend$ = new BehaviorSubject<{ [brand: string]: number[] }>(null);
  vehicleParkingProfileByPinPurchasingPowerBreakdown$ = new BehaviorSubject<{ [tier: string]: number }>(null);
  vehicleParkingProfileByPinModeOfTransportBreakdown$ = new BehaviorSubject<{ [type: string]: number }>(null);
  vehicleParkingProfileByPinTopTenCarBrandBreakdown$ = new BehaviorSubject<{ [brand: string]: number }>(null);
  currentvehicleParkingProfileByPinCount$ = new BehaviorSubject<{ count: number; diff: number; diffPercent: number }>(null);
  currentvehicleParkingProfileByPinAvgTimespent$ = new BehaviorSubject<{ avgTimespent: number; diff: number; diffPercent: number }>(null);
  vehicleParkingProfileByPinCountTrend: number[] = [];
  vehicleParkingProfileByPinPurchasingPowerTrend: { [tier: string]: number[] };
  vehicleParkingProfileByPinModeOfTransportTrend: { [type: string]: number[]; total: number[] };
  vehicleParkingProfileByPinCarBrandTrend: { [brand: string]: number[] };
  vehicleProfileByPinPlateDefinitionTrend$ = new BehaviorSubject<{ [definition: string]: number[] }>(null);
  vehicleProfileByPinPlateDefinitionBreakdown$ = new BehaviorSubject<{ [definition: string]: number }>(null);
  vehicleProfileByPinPlateDefinitionTrend: { [definition: string]: number[] };
  // vehicle-parking/vehicle-profile-by-pin-by-hour
  private vehicleParkingVehicleProfileByPinByHourLock = 0;
  vehicleParkingVehicleProfileByPinCountByHour$ = new BehaviorSubject<number[]>(null);
  unfilteredVehicleParkingVehicleProfileByPinByHourData$ = new BehaviorSubject<IFetchData<ModeOfTransportData[]>[]>(null);
  vehicleParkingVehicleProfileByPinPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  vehicleParkingVehicleProfileByPinOffPeakTime$ = new BehaviorSubject<{ timeKey: string; count: number }>(null);
  // vehicle-parking/entrance-exit-by-pin all area
  private vehicleParkingEntranceExitAllPinLock = 0;
  // vehicle-parking/vehicle-parking-synergy all area
  private vehicleParkingToVehicleParkingSynergyAllAreaLock = 0;
  vehicleParkingToVehicleParkingSynergyAllareaData$ = new BehaviorSubject<{ [areaName: string]: { [areaName: string]: number } }>(null);
  // building/floor-timespent
  private buildingFloorTimespentLock = 0;
  buildingFloorTimespentData$ = new BehaviorSubject<{ [binName: string]: number }>(null);
  currentBuildingFloorAvgTimespentUpper5$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  currentBuildingFloorVisitorAvgTimespentLower5$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  buildingFloorVisitorPercentage$ = new BehaviorSubject<{ [groupName: string]: number }>(null);
  buildingFloorVisitor$ = new BehaviorSubject<{ [groupName: string]: number }>(null);
  // store/zone-traffic-flow
  private storeAreatoZoneTrafficFlowLock = 0;
  storeAreaZoneTrafficFlow$ = new BehaviorSubject<SankeyRawLinks[]>(null);
  // building/zone-traffic-flow
  private buildingAreatoZoneTrafficFlowLock = 0;
  buildingAreaZoneTrafficFlow$ = new BehaviorSubject<SankeyRawLinks[]>(null);
  // vehicle-parking/repeated-visitors
  private vehicleParkingRepeatedVisitorsLock = 0;
  vehicleParkingRepeatedVisitorsData$ = new BehaviorSubject<{ new_percentage: number; repeated_percentage: number }>({ new_percentage: null, repeated_percentage: null });
  vehicleParkingCurrentRepeatedVisitorsData$ = new BehaviorSubject<{ current: number; diff: number }>(null);
  // vehicle-parking/frequency-of-visit
  private vehicleParkingFrequencyOfVisitLock = 0;
  vehicleParkingFrequencyOfVisitData$ = new BehaviorSubject<{ one: number; two_three: number; four_up: number }>({ one: null, two_three: null, four_up: null });
  // vehicle-parking/recency-frequency
  private vehicleParkingRecencyFrequencyLock = 0;
  // vehicleParkingRetentionData$ = new BehaviorSubject<Array<[number, number, number, string]>>(
  private trafficAdsFrequencyLock = 0;
  trafficAdsFrequency$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  private staffTrafficCountLock = 0;
  staffTrafficCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  private staffTrafficCountLock_2 = 0;
  staffTrafficCount_2$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  private maleTrafficCountLock = 0;
  maleTrafficCount = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  private femaleTrafficCountLock = 0;
  femaleTrafficCount = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  storePurchaseRateData$ = new BehaviorSubject({ purchase: 0, diff: 0, diffPercent: 0 });
  private storeVisitorProfileByHourLock = 0;
  storeGenderProfileDataByHour$ = new BehaviorSubject<{ male: number[]; female: number[] }>({ male: [], female: [] });
  staffTrafficCountTwoHour$ = new BehaviorSubject<number[]>(null);
  maleBuildingTrafficCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  femaleBuildingTrafficCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  ethnicityProfilePurchaseRate$ = new BehaviorSubject<number[]>(null);
  ethnicityCrossProfilelevelFourPurchaseRate$ = new BehaviorSubject<number[]>(null);
  buildingAverageWeekDayWeekEndCount$ = new BehaviorSubject<number[]>(null);
  // buildingAverageWeek7DayWeekEndCount$ = new BehaviorSubject<number[]>(null);
  private buildingAreaEntranceExitByHourDayByDayLock = 0;
  buildingAreaDayOfWeekGroupDayByDayData$ = new BehaviorSubject<{ [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } }>(null);

  private goalStartDate: string;
  //   [
  //     [0, 0, 5, 'group1'],
  //     [0, 1, 2, 'group1'],
  //     [1, 1, 2, 'group1'],
  //     [1, 0, 4, 'group1'],
  //     [2, 0, 10, 'group2'],
  //     [2, 1, 10, 'group2'],
  //     [3, 0, 10, 'group2'],
  //     [3, 1, 12, 'group2'],
  //     [1, 2, 16, 'group3'],
  //     [1, 3, 16, 'group3'],
  //     [0, 2, 16, 'group3'],
  //     [0, 3, 16, 'group3'],
  //     [2, 2, 14, 'group4'],
  //     [2, 3, 14, 'group4'],
  //     [3, 3, 55, 'group4'],
  //     [3, 2, 25, 'group4'],
  //   ]
  // );
  vehicleParkingRetentionData$ = new BehaviorSubject<Array<[number, number, number, string]>>([]);
  vehicleParkingNewVisitorCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  vehicleParkingRegularVisitorCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  vehicleParkingChurnVisitorCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  vehicleParkingLossVisitorCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // vehicle-parking/frequency-of-visit-profile
  vehicleParkingProfileFrequencyOfVisitLock = 0;
  unfilteredVehicleParkingFrequencyOfVisitProfileData$ = new BehaviorSubject<IFetchData<FrequencyOfVisitProfileData[]>[]>(null);
  vehicleParkingProfileFrequencyOfVisitData$ = new BehaviorSubject<{ one: number; two_three: number; four_up: number }>({ one: null, two_three: null, four_up: null });
  // vehicle-parking/repeated-visitors-profile
  vehicleParkingRepeatedVisitorsProfileLock = 0;
  vehicleParkingRepeatedVisitorsProfilePercentageData$ = new BehaviorSubject<{ new_percentage: number; repeated_percentage: number }>({ new_percentage: null, repeated_percentage: null });
  vehicleParkingCurrentRepeatedVisitorsProfileData$ = new BehaviorSubject<{ current: number; diff: number }>(null);
  // vehicle-parking/recency-frequency-profile
  vehicleParkingRecencyFrequencyProfileLock = 0;
  vehicleParkingRetentionProfileData$ = new BehaviorSubject<Array<[number, number, number, string]>>([]);
  vehicleParkingNewVisitorProfileCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  vehicleParkingRegularVisitorProfileCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  vehicleParkingChurnVisitorProfileCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  vehicleParkingLossVisitorProfileCount$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // vehicle-parking/timespent-profile
  vehicleParkingTimespentProfileLock = 0;
  unfilteredVehicleParkingPlateTimespentBinProfileData$ = new BehaviorSubject<IFetchData<PlateTimespentProfileData[]>[]>(null);
  vehicleParkingPlateTimespentBinProfileData$ = new BehaviorSubject<{ [binName: string]: number }>(null);
  currentVehicleParkingPlateTimespentProfileData$ = new BehaviorSubject<{ current: number; diff: number; diffPercent: number }>(null);
  // floor/area-entrance-exit
  private floorAreaEntranceExitLock = 0;
  currentFloorAreaEntranceExitData$ = new BehaviorSubject<{ [floorName: string]: { entrance: { current: number; diff: number; diffPercent: number }; exit: { current: number; diff: number; diffPercent: number } } }>(null);
  currentFloorAreaAvgTimespentData$ = new BehaviorSubject<{ [floorName: string]: { current: number; diff: number; diffPercent: number } }>(null);
  floorAreaEntranceExitTrendData$ = new BehaviorSubject<{ [floorName: string]: { entrance: number[]; exit: number[] } }>(null);
  floorAreaEntranceExitBreakdownData$ = new BehaviorSubject<{ [floorName: string]: { entrance: number; exit: number } }>(null);
  floorAreaAvgTimespentBreakdownData$ = new BehaviorSubject<{ [floorName: string]: number }>(null);

  //#endregion chartData

  //#region static helpers

  /**
   * Iteratively call callback over past 8 data point from oldder to newer
   *
   * @param processCallback what to do with each data point
   */
  private static mapSevenDayLineChartData<T extends Fetchable>(data: IFetchData<T>[], viewPeriodService: ViewPeriodService, processCallback: (dataFiltered: IFetchData<T>, isLivePeriod?: boolean, isPrediction?: boolean, diffToSelectedDate?: number) => void) {
    // const latest_date_moment = moment(selectedDate, 'YYYY-MM-DD').add(1, periodType.toMomentCompareString());
    // for (let subtract_day = 7; subtract_day >= 0; subtract_day--) {
    //   const momentIt = latest_date_moment.clone().subtract(subtract_day, periodType.toMomentCompareString());
    //   const dataIt: IFetchData = filterSelectedDateData(data, momentIt, periodType)[0];
    //   processCallback(dataIt, momentIt.clone().isSameOrAfter(moment(), periodType.toMomentString()), momentIt.clone().isAfter(moment(), periodType.toMomentString()));
    // }
    const labelList = viewPeriodService.DAY_LIST_MOMENT;
    const periodType = viewPeriodService.viewPeriod;
    const selectedDate = viewPeriodService.selectedDate;
    for (const label of labelList) {
      const momentIt = label.clone();
      const dataIt: IFetchData<T> = filterSelectedDateData(data, momentIt, periodType)[0];
      const isLivePeriod = momentIt.clone().isSameOrAfter(moment(), periodType.toMomentString());
      const isPrediction = momentIt.clone().isAfter(moment(), periodType.toMomentString());
      const diffToSelectedDate = momentIt.clone().diff(moment(selectedDate), periodType.toMomentCompareString());
      processCallback(dataIt, isLivePeriod, isPrediction, diffToSelectedDate);
    }
  }
  private static procesChartData<T extends number | number[]>(data: T, allowNegative?: boolean, allowDecimal?: boolean): T {
    const processCallback = (val: number) => {
      if (!val) { return val; }
      if (!allowNegative) { val = Math.max(0, val); }
      if (!allowDecimal) { val = Math.round(val); }
      return val;
    };
    return (Array.isArray(data) ? (data as number[]).map(processCallback) : processCallback(data as number)) as T;
  }
  private static createObjectComprehension(list: any[], defaultValue = 0) {
    return list.reduce((total, key) => {
      total[key] = defaultValue;
      return total;
    }, {});
  }
  private static createFilterDictObject(age?: string, ethnicity?: string, gender?: 'male' | 'female' | 'all', profession?: string): GroupProfile {
    const result: GroupProfile = {}; // { gender: selectedProfile.gender, ethnicity: selectedProfile.ethnicity };
    // student and profession is shared, ikr.
    if (age) {
      if (age === 'student') {
        result.profession = age;
      } else if (age !== 'all') {
        result.age = age;
      }
    }
    if (ethnicity && ethnicity !== 'all') {
      result.ethnicity = ethnicity;
    }
    if (gender && gender !== 'all') {
      result.gender = gender;
    }
    if (profession && profession !== 'all') {
      result.profession = profession;
    }
    return result;
  }
  private static createFilterDictVehicleObject(vehicle_type?: string, car_brand?: string, purchasing_power?: string, plate_number_definition?: string): vehicleProfileGroup {
    const result: vehicleProfileGroup = {};
    if (vehicle_type) {
      if (vehicle_type && vehicle_type !== 'vehicle_type') {
        result.vehicle_type = vehicle_type;
      }
    }
    if (car_brand && car_brand !== 'car_brand') {
      result.car_brand = car_brand;
    }
    if (purchasing_power && purchasing_power !== 'purchasing_power') {
      result.purchasing_power = purchasing_power;
    }
    if (plate_number_definition && plate_number_definition !== 'plate_number_definition') {
      result.plate_number_definition = plate_number_definition;
    }
    return result;
  }
  private adjustDateTime(input: moment.Moment, periodType: ViewPeriod): string {
    const currentDate = this.viewPeriodService.isLiveMode ? moment() : moment().subtract(1, 'day');
    const currentWeek = moment().isoWeek();
    const currentMonth = moment().month();
    const inputWeek = input.isoWeek();
    const inputMonth = input.month();
    if (periodType.toBackendV2String() === ViewPeriod.WEEKS.toBackendV2String()) {
      return currentWeek === inputWeek ? currentDate.format('YYYY-MM-DD') : input.endOf('week').format('YYYY-MM-DD');
    }

    if (periodType.toBackendV2String() === ViewPeriod.MONTHS.toBackendV2String()) {
      return currentMonth === inputMonth ? currentDate.format('YYYY-MM-DD') : input.endOf('month').format('YYYY-MM-DD');
    }
    return input.format('YYYY-MM-DD');

  }
  //#endregion static helpers

  constructor(
    private http: HttpClient,
    private viewPeriodService: ViewPeriodService,
    private authenticationService: AuthenticationService,
    public configDataService: ConfigDataService,
    private loadingService: LoadingService,
    public globalUiService: GlobalUiService,
    private router: Router,
    private dataService: DataService,
  ) {
    this.baseGraphData = new BaseGraphData(
      this.graphDependencyToLoader,
      viewPeriodService,
      loadingService,
      this,
      globalUiService
    );
    this.baseGraphData.setSubscribeSelectedDate();
    // this.subscription.add(this.viewPeriodService.subscribeSelectedDate(newDate => {
    //   this.availableDependency.forEach((graphDependency: GraphDependency) => {
    //     this.loadCorespondDependency(graphDependency, newDate.date.clone());
    //   });
    // }));
  }

  //#region helpers
  private getLineChartQueryParameter(date: moment.Moment) {
    const periodType = this.viewPeriodService.viewPeriod;
    const shouldNotFetchLast = this.viewPeriodService.isCurrentPeriod && this.viewPeriodService.isDayPeriod && !this.viewPeriodService.isLiveMode;
    const start_date = date.clone().add(shouldNotFetchLast ? 0 : 1, this.viewPeriodService.viewPeriod.toMomentCompareString()).format('YYYY-MM-DD');
    // const start_date = date.clone().add(0, this.viewPeriodService.viewPeriod.toMomentCompareString()).format('YYYY-MM-DD');
    const num_interval = shouldNotFetchLast ? 7 : 8;
    return { periodType, start_date, num_interval };
  }

  private getPredictionLineChartQueryParameter(date: moment.Moment) {
    const periodType = this.viewPeriodService.viewPeriod;
    const shouldNotFetchLast = false;
    const start_date = date.clone().add(shouldNotFetchLast ? 0 : 1, this.viewPeriodService.viewPeriod.toMomentCompareString()).format('YYYY-MM-DD');
    const num_interval = shouldNotFetchLast ? 7 : 8;
    return { periodType, start_date, num_interval };
  }

  private getPredictionMonthQueryParameter(date: moment.Moment) {
    const periodType = ViewPeriod.MONTHS;
    const shouldNotFetchLast = false;
    const start_date = date.clone().add(shouldNotFetchLast ? 0 : 1, this.viewPeriodService.viewPeriod.toMomentCompareString()).format('YYYY-MM-DD');
    const num_interval = 2;
    return { periodType, start_date, num_interval };
  }

  private getOneSelectedQueryParameter(date: moment.Moment) {
    const periodType = this.viewPeriodService.viewPeriod;
    const start_date = date.clone().format('YYYY-MM-DD');
    const num_interval = 1;
    return { periodType, start_date, num_interval };
  }

  private getCustomSelectedQueryParameter(date: moment.Moment, numInterVal: number = 1) {
    const periodType = this.viewPeriodService.viewPeriod;
    const start_date = date.clone().format('YYYY-MM-DD');
    const num_interval = numInterVal;
    return { periodType, start_date, num_interval };
  }

  private getSelectedQueryAvgByDayTypeParameter(date: moment.Moment) {
    const periodType = this.viewPeriodService.viewPeriod;
    const shouldNotFetchLast = this.viewPeriodService.isCurrentPeriod && this.viewPeriodService.isDayPeriod && !this.viewPeriodService.isLiveMode;
    const start_date = periodType.toBackendV2String() === ViewPeriod.MONTHS.toBackendV2String()
      ? date.clone().endOf('month').format('YYYY-MM-DD')
      : periodType.toBackendV2String() === ViewPeriod.WEEKS.toBackendV2String()
        ? date.clone().endOf('isoWeek').format('YYYY-MM-DD')
        : date.clone().add(shouldNotFetchLast ? 0 : 1, this.viewPeriodService.viewPeriod.toMomentCompareString()).format('YYYY-MM-DD');
    const num_interval = periodType.toBackendV2String() === ViewPeriod.MONTHS.toBackendV2String() ? date.clone().daysInMonth() + date.clone().subtract(1, 'month').daysInMonth() : 14;
    return { periodType, start_date, num_interval };
  }

  public getSelectedGraph<T>(name: SelectableDataName): { dependencies: BaseDataDependency | BaseDataDependency[]; data: BehaviorSubject<T> } {
    const selectorDetail = SelectableData.SelectableDataMapper[name];
    return {
      dependencies: selectorDetail.dependecies,
      data: selectorDetail.data(this) as BehaviorSubject<T>,
    };
  }
  //#endregion helpers

  //#region building/entrance-exit
  async loadEntranceExitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('mock_data', 'building_entrance_exit')) {
      graphDataServiceInstance.deriveEntranceExitMockData(date);
      return;
    } else {
      return graphDataServiceInstance.fetchEntranceExitData(date, ++graphDataServiceInstance.entranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitData(data, lockNum));
    }
  }

  async deriveEntranceExitMockData(date: moment.Moment) {
    const num_interval = 8;
    const buildingEntranceExitData = await generateNestedData(date, this.viewPeriodService, this.configDataService, 'BUILDING_TRAFFIC', 'traffic', num_interval);
    const buildingTimespentData = await generateNestedData(date, this.viewPeriodService, this.configDataService, 'BUILDING_TIMEPSENT', 'count', 2);
    const currentBuildingHeadCountPairData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } } = Object.keys(buildingEntranceExitData).reduce((prev, buildingName) => {
      prev[buildingName] = {
        entrance: [buildingEntranceExitData[buildingName].entrance[num_interval - 3], buildingEntranceExitData[buildingName].entrance[num_interval - 2]],
        exit: [buildingEntranceExitData[buildingName].exit[num_interval - 3], buildingEntranceExitData[buildingName].exit[num_interval - 2]]
      };
      return prev;
    }, {});
    const currentBuildingAvgTimeSpentPairData: { [buildingName: string]: [number, number] } = Object.keys(buildingTimespentData).reduce((prev, buildingName) => {
      prev[buildingName] = [buildingTimespentData[buildingName][0], buildingTimespentData[buildingName][1]];
      return prev;
    }, {});
    const currentBuildingNetShoppingTimePairData: { [buildingName: string]: [number, number] } = Object.keys(buildingTimespentData).reduce((prev, buildingName) => {
      prev[buildingName] = [(buildingTimespentData[buildingName][0] * buildingEntranceExitData[buildingName].entrance[num_interval - 3]) / 3600, (buildingTimespentData[buildingName][1] * buildingEntranceExitData[buildingName].entrance[num_interval - 2]) / 3600];
      return prev;
    }, {});
    const currentBuildingHeadCountData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(currentBuildingHeadCountPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingPairData.entrance[1]),
          diff: GraphDataService.procesChartData(buildingPairData.entrance[1] - buildingPairData.entrance[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.entrance[1] - buildingPairData.entrance[0]) / buildingPairData.entrance[0] * 100, true, true)
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingPairData.exit[1]),
          diff: GraphDataService.procesChartData(buildingPairData.exit[1] - buildingPairData.exit[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.exit[1] - buildingPairData.exit[0]) / buildingPairData.exit[0] * 100, true, true)
        }
      };
      return prev;
    }, {});
    const currentBuildingAverageTimeSpentData: {
      [buildingName: string]: { avgTimeSpent: number; diff: number; diffPercent: number };
    } = Object.entries(currentBuildingAvgTimeSpentPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        avgTimeSpent: GraphDataService.procesChartData(buildingPairData[1]),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0] * 100, true, true)
      };
      return prev;
    }, {});
    const currentBuildingNetShoppingTimeData: {
      [buildingName: string]: { netShoppingTime: number; diff: number; diffPercent: number };
    } = Object.entries(currentBuildingNetShoppingTimePairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        netShoppingTime: GraphDataService.procesChartData(buildingPairData[1]),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0] * 100, true, true)
      };
      return prev;
    }, {});
    this.currentBuildingHeadCountData$.next(currentBuildingHeadCountData);
    this.currentBuildingAverageTimeSpentData$.next(currentBuildingAverageTimeSpentData);
    this.currentBuildingNetShoppingHourData$.next(currentBuildingNetShoppingTimeData);
    this.buildingEntranceExitData$.next(buildingEntranceExitData);
  }

  async deriveEntranceExitData(entranceExitDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    const buildingNameSet = new Set<string>();
    const headCountEntrancePassCurrentPair: [number, number] = [0, 0];
    const headCountExitPassCurrentPair: [number, number] = [0, 0];
    const averageTimeSpentPair: [number, number] = [0, 0];
    const currentNetShoppingTimePair: [number, number] = [0, 0];
    const currentNetVisitorHourPair: [number, number] = [0, 0];
    const entranceChartData: number[] = [];
    const exitChartData: number[] = [];
    const averageWeekDayWeekEndCount: number[] = [];
    Object.keys(this.configDataService.FLOOR_OBJECTS).forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const averageTimeSpentChartData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const netShoppingTimeChartData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const currentNetShoppingTimeData: { [buildingName: string]: number } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = 0;
      return prev;
    }, {});
    const netShoppingHourChartData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const buildingEntranceExitData: { [buildingName: string]: { entrance: number[]; exit: number[] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [], exit: [] };
      return prev;
    }, {});
    const currentBuildingHeadCountPairData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [0, 0], exit: [0, 0] };
      return prev;
    }, {});
    const currentBuildingHeadCountPairLastWeekData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [0, 0], exit: [0, 0] };
      return prev;
    }, {});
    const currentBuildingNetShoppingHourPairData: { [buildingName: string]: [number, number] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [0, 0];
      return prev;
    }, {});
    const currentBuildingAvgTimeSpentPairData: { [buildingName: string]: [number, number] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [0, 0];
      return prev;
    }, {});
    const buildingWeekDayLast7DaysData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const buildingWeekEndLast7DaysData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const prevBuildingWeekDayLast7DaysData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const prevBuildingWeekEndLast7DaysData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});

    GraphDataService.mapSevenDayLineChartData(entranceExitDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      if (!dataFiltered || !dataFiltered.data || isPred) {
        entranceChartData.push(fillValue);
        exitChartData.push(fillValue);
        Object.keys(buildingEntranceExitData).forEach(buildingName => {
          buildingEntranceExitData[buildingName].entrance.push(fillValue);
          buildingEntranceExitData[buildingName].exit.push(fillValue);
          averageTimeSpentChartData[buildingName].push(fillValue);
          netShoppingTimeChartData[buildingName].push(fillValue);
          netShoppingHourChartData[buildingName].push(fillValue);
        });
        return;
      }
      const entranceExitData = dataFiltered.data;
      Object.keys(buildingEntranceExitData).forEach(buildingName => {
        const entranceExitBuildingData = entranceExitData[buildingName] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue };
        buildingEntranceExitData[buildingName].entrance.push(GraphDataService.procesChartData(entranceExitBuildingData.entrance));
        buildingEntranceExitData[buildingName].exit.push(GraphDataService.procesChartData(entranceExitBuildingData.exit));
        averageTimeSpentChartData[buildingName].push(GraphDataService.procesChartData(entranceExitBuildingData.average_timespent, false, true));
        netShoppingTimeChartData[buildingName].push(GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time, false, true));
        netShoppingHourChartData[buildingName].push(GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time / (60 * 60), false, false));
        if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
          currentBuildingHeadCountPairLastWeekData[buildingName].entrance[diffToSelectedDate + 1] = entranceExitBuildingData.entrance;
          currentBuildingHeadCountPairLastWeekData[buildingName].exit[diffToSelectedDate + 1] = entranceExitBuildingData.exit;
          currentBuildingHeadCountPairData[buildingName].entrance[diffToSelectedDate + 1] = entranceExitBuildingData.entrance;
          currentBuildingHeadCountPairData[buildingName].exit[diffToSelectedDate + 1] = entranceExitBuildingData.exit;
          currentBuildingNetShoppingHourPairData[buildingName][diffToSelectedDate + 1] = GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time / (60 * 60), false, false);
          currentBuildingAvgTimeSpentPairData[buildingName][diffToSelectedDate + 1] = entranceExitBuildingData.average_timespent;
        }
        if (diffToSelectedDate === 0) {
          if (this.configDataService.isFeatureEnabled('multiple_organization') && buildingName !== 'onesiam') {
            currentNetShoppingTimeData[buildingName] = GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time / (60 * 60), false, false);
          }
        }
      });
      const mainBuildingEntranceExitData = entranceExitData[mainBuilding] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue };
      entranceChartData.push(mainBuildingEntranceExitData.entrance);
      exitChartData.push(mainBuildingEntranceExitData.exit);
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        headCountEntrancePassCurrentPair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.entrance;
        headCountExitPassCurrentPair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.exit;
        averageTimeSpentPair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.average_timespent;
        currentNetShoppingTimePair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.net_shopping_time;
        currentNetVisitorHourPair[diffToSelectedDate + 1] = ((mainBuildingEntranceExitData.entrance * mainBuildingEntranceExitData.average_timespent) / 60) / 60;
      }
    });
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    entranceExitDatas.forEach((entranceExitData, idx) => {
      if (!entranceExitData.data || Object.keys(entranceExitData.data).length < 1) {
        return;
      }
      const date = moment(`${entranceExitData.year}-${entranceExitData.month}-${entranceExitData.day}`, 'YYYY-MM-DD');
      const todayDate = this.viewPeriodService.selectedDate;
      const diffDate = todayDate.diff(date, 'd');
      const numInWeekDay = date.isoWeekday();
      if (diffDate === 7) {
        Object.keys(currentBuildingHeadCountPairLastWeekData).forEach(buildingName => {
          currentBuildingHeadCountPairLastWeekData[buildingName].entrance[0] = entranceExitData.data?.[buildingName]?.entrance || 0;
          currentBuildingHeadCountPairLastWeekData[buildingName].exit[0] = entranceExitData.data?.[buildingName]?.entrance || 0;
        });
      }
      if (diffDate < 7) {
        Object.keys(buildingWeekDayLast7DaysData).forEach(buildingName => {
          if (numInWeekDay < 6) {
            buildingWeekDayLast7DaysData[buildingName].push(entranceExitData.data?.[buildingName]?.entrance || 0);
          } else {
            buildingWeekEndLast7DaysData[buildingName].push(entranceExitData.data?.[buildingName]?.entrance || 0);
          }
        });
      } else if (diffDate < 14) {
        Object.keys(buildingWeekDayLast7DaysData).forEach(buildingName => {
          if (numInWeekDay < 6) {
            prevBuildingWeekDayLast7DaysData[buildingName].push(entranceExitData.data?.[buildingName]?.entrance || 0);
          } else {
            prevBuildingWeekEndLast7DaysData[buildingName].push(entranceExitData.data?.[buildingName]?.entrance || 0);
          }
        });
      }
    });
    this.buildingEntranceExitPairData = currentBuildingHeadCountPairData;
    this.buildingEntranceExitPairData$.next(currentBuildingHeadCountPairData);
    const currentBuildingHeadCountData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(currentBuildingHeadCountPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingPairData.entrance[1]),
          diff: GraphDataService.procesChartData(buildingPairData.entrance[1] - buildingPairData.entrance[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.entrance[1] - buildingPairData.entrance[0]) / buildingPairData.entrance[0] * 100, true, true)
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingPairData.exit[1]),
          diff: GraphDataService.procesChartData(buildingPairData.exit[1] - buildingPairData.exit[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.exit[1] - buildingPairData.exit[0]) / buildingPairData.exit[0] * 100, true, true)
        }
      };
      return prev;
    }, {});
    const currentBuildingHeadCountLastWeekData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(currentBuildingHeadCountPairLastWeekData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingPairData.entrance[1]),
          diff: GraphDataService.procesChartData(buildingPairData.entrance[1] - buildingPairData.entrance[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.entrance[1] - buildingPairData.entrance[0]) / buildingPairData.entrance[0] * 100, true, true)
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingPairData.exit[1]),
          diff: GraphDataService.procesChartData(buildingPairData.exit[1] - buildingPairData.exit[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.exit[1] - buildingPairData.exit[0]) / buildingPairData.exit[0] * 100, true, true)
        }
      };
      return prev;
    }, {});
    const excludeBuildingArea: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const filteredBuildingArea = Object.fromEntries(Object.entries(currentNetShoppingTimeData).filter(([key, _value]) => !excludeBuildingArea.includes(key)));
    const sumAll = Object.values(filteredBuildingArea).reduce(reducer, 0);
    const currentBuildingNetShoppingHourPercentageData: {
      [buildingName: string]: number;
    } = Object.entries(currentNetShoppingTimeData).reduce((prev, [buildingName, buildingPairData]) => {
      if (!excludeBuildingArea.includes(buildingName)) {
        prev[buildingName] = GraphDataService.procesChartData((buildingPairData / sumAll) * 100, false, true);
      }
      return prev;
    }, {});
    const currentBuildingNetShoppingHourData: {
      [buildingName: string]: { netShoppingTime: number; diff: number; diffPercent: number };
    } = Object.entries(currentBuildingNetShoppingHourPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        netShoppingTime: GraphDataService.procesChartData(buildingPairData[1]),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0] * 100, true, true)
      };
      return prev;
    }, {});
    const currentBuildingAverageTimeSpentData: {
      [buildingName: string]: { avgTimeSpent: number; diff: number; diffPercent: number };
    } = Object.entries(currentBuildingAvgTimeSpentPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        avgTimeSpent: GraphDataService.procesChartData(buildingPairData[1]),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0] * 100, true, true)
      };
      return prev;
    }, {});
    const prevBuildingHeadCountData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(currentBuildingHeadCountPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingPairData.entrance[0]),
          diff: 0,
          diffPercent: 0
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingPairData.exit[0]),
          diff: 0,
          diffPercent: 0
        }
      };
      return prev;
    }, {});

    const avgBuildingWeekDayLast7DaysData: {
      [buildingName: string]: { headCount: number; diff: number; diffPercent: number };
    } = Object.entries(buildingWeekDayLast7DaysData).reduce((prev, [buildingName, buildingData]) => {
      if ((this.viewPeriodService.isDayPeriod || this.viewPeriodService.isLiveMode)) {
        const sum = buildingData.reduce(reducer, 0);
        const prevSum = prevBuildingWeekDayLast7DaysData[buildingName].reduce(reducer, 0);
        const avg = sum / buildingData.length;
        const prevAvg = prevSum / prevBuildingWeekDayLast7DaysData[buildingName].length;
        const diffAvg = avg - prevAvg;
        prev[buildingName] = {
          headCount: GraphDataService.procesChartData(avg, false, false),
          diff: GraphDataService.procesChartData(diffAvg, true, false),
          diffPercent: GraphDataService.procesChartData((diffAvg / 100), true, true),
        } || { headCount: 0, diff: 0, diffPercent: 0 };
        // averageWeekDayWeekEndCount.push(GraphDataService.procesChartData(avg, false, false));
        return prev;
      }
    }, {});
    const avgBuildingWeekEndLast7DaysData: {
      [buildingName: string]: { headCount: number; diff: number; diffPercent: number };
    } = Object.entries(buildingWeekEndLast7DaysData).reduce((prev, [buildingName, buildingData]) => {
      if (buildingData.length === 0) {
        return 0;
      }
      if ((this.viewPeriodService.isDayPeriod || this.viewPeriodService.isLiveMode)) {
        const sum = buildingData.reduce(reducer, 0);
        const prevSum = prevBuildingWeekEndLast7DaysData[buildingName].reduce(reducer, 0);
        const avg = sum / buildingData.length;
        const prevAvg = prevSum / prevBuildingWeekEndLast7DaysData[buildingName].length;
        const diffAvg = avg - prevAvg;
        prev[buildingName] = {
          headCount: GraphDataService.procesChartData(avg, false, false),
          diff: GraphDataService.procesChartData(diffAvg, true, false),
          diffPercent: GraphDataService.procesChartData((diffAvg / 100), true, true),
        } || { headCount: 0, diff: 0, diffPercent: 0 };
        // averageWeekDayWeekEndCount.push(GraphDataService.procesChartData(avg, false, false));
        return prev;
      }
    }, {});
    if (lockNum < this.entranceExitLock) { return; }
    this.entranceChartData$.next(GraphDataService.procesChartData(entranceChartData));
    this.exitChartData$.next(GraphDataService.procesChartData(exitChartData));
    this.averageTimeSpentChartData$.next(averageTimeSpentChartData);
    this.netShoppingTimeChartData$.next(netShoppingTimeChartData);
    this.currentNetShoppingTimeChartPercentageData$.next(currentBuildingNetShoppingHourPercentageData);
    let checked = false;
    Object.keys(buildingEntranceExitData).forEach(key => {
      if (buildingEntranceExitData[key].entrance.slice(0, 7).every(val => val !== null) && buildingEntranceExitData[key].exit.slice(0, 7).every(val => val !== null)) {
        checked = true;
      }
    });
    if (checked) {
      this.buildingEntranceExitData$.next(buildingEntranceExitData);
    }
    if (!this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.ENTRANCE_EXIT).value) {
      this.currentBuildingHeadCountData$.next(currentBuildingHeadCountData);
    }
    this.currentBuildingHeadCountLastWeekData$.next(currentBuildingHeadCountLastWeekData);
    this.prevBuildingHeadCountData$.next(prevBuildingHeadCountData);
    this.currentBuildingNetShoppingHourData$.next(currentBuildingNetShoppingHourData);
    this.currentBuildingAverageTimeSpentData$.next(currentBuildingAverageTimeSpentData);
    this.currentNetVisitorHourData$.next({
      netVisitorTime: GraphDataService.procesChartData(currentNetVisitorHourPair[1]),
      diff: GraphDataService.procesChartData(currentNetVisitorHourPair[1] - currentNetVisitorHourPair[0], true, false),
      diffPercent: GraphDataService.procesChartData((currentNetVisitorHourPair[1] - currentNetVisitorHourPair[0]) / currentNetVisitorHourPair[0] * 100, true, true)
    });

    this.currentHeadCountEntranceData$.next({
      headCount: GraphDataService.procesChartData(headCountEntrancePassCurrentPair[1]),
      diff: GraphDataService.procesChartData(Math.round(headCountEntrancePassCurrentPair[1] - headCountEntrancePassCurrentPair[0]), true),
      diffPercent: GraphDataService.procesChartData((headCountEntrancePassCurrentPair[1] - headCountEntrancePassCurrentPair[0]) / headCountEntrancePassCurrentPair[0] * 100, true, true)
    });
    this.currentHeadCountExitData$.next({
      headCount: GraphDataService.procesChartData(headCountExitPassCurrentPair[1]),
      diff: GraphDataService.procesChartData(Math.round(headCountExitPassCurrentPair[1] - headCountExitPassCurrentPair[0]), true),
      diffPercent: GraphDataService.procesChartData((headCountExitPassCurrentPair[1] - headCountExitPassCurrentPair[0]) / headCountExitPassCurrentPair[0] * 100, true, true)
    });
    this.currentAverageTimeSpentData$.next({
      avgTimeSpent: GraphDataService.procesChartData(averageTimeSpentPair[1], false, true),
      diff: GraphDataService.procesChartData(Math.round(averageTimeSpentPair[1] - averageTimeSpentPair[0]), true, true),
      diffPercent: GraphDataService.procesChartData((averageTimeSpentPair[1] - averageTimeSpentPair[0]) / averageTimeSpentPair[0] * 100, true, true)
    });
    this.currentNetShoppingTimeData$.next({
      net_shopping_time: GraphDataService.procesChartData(currentNetShoppingTimePair[1], false, true),
      diff: GraphDataService.procesChartData(currentNetShoppingTimePair[1] - currentNetShoppingTimePair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((currentNetShoppingTimePair[1] - currentNetShoppingTimePair[0]) / currentNetShoppingTimePair[0] * 100) || 0, true, true)
    });
    if (this.viewPeriodService.isDayPeriod || this.viewPeriodService.isLiveMode) {
      // this.buildingAverageWeek7DayWeekEndCount$.next(averageWeekDayWeekEndCount);
      this.buildingAverageWeekDayLast7DaysData$.next(avgBuildingWeekDayLast7DaysData);
      this.buildingAverageWeekEndLast7DaysData$.next(avgBuildingWeekEndLast7DaysData);
    }
  }

  async fetchEntranceExitData(date: moment.Moment, lockNum: number) {
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.ENTRANCE_EXIT).value) {
      const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
      if (agg_type !== undefined) {
        fetchURL += `&aggregation_type=${agg_type}`;
      }
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion building/entrance-exit

  //#region building/entrance-exit daily average
  async loadEntranceExitAvgByDayData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitAvgByDayData(date, ++graphDataServiceInstance.entranceExitAvgByDayLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitAvgByDayData(data, lockNum));
  }

  async deriveEntranceExitAvgByDayData(entranceExitDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number) {
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    const buildingNameSet = new Set<string>();
    const headCountEntrancePassCurrentPair: [number, number] = [0, 0];
    const headCountExitPassCurrentPair: [number, number] = [0, 0];
    const averageTimeSpentPair: [number, number] = [0, 0];
    const currentNetShoppingTimePair: [number, number] = [0, 0];
    const currentNetVisitorHourPair: [number, number] = [0, 0];
    const entranceChartData: number[] = [];
    const exitChartData: number[] = [];
    Object.keys(this.configDataService.FLOOR_OBJECTS).forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const averageTimeSpentChartData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const netShoppingTimeChartData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const currentNetShoppingTimeData: { [buildingName: string]: number } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = 0;
      return prev;
    }, {});
    const netShoppingHourChartData: { [buildingName: string]: number[] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [];
      return prev;
    }, {});
    const buildingEntranceExitData: { [buildingName: string]: { entrance: number[]; exit: number[] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [], exit: [] };
      return prev;
    }, {});
    const currentBuildingHeadCountPairData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [0, 0], exit: [0, 0] };
      return prev;
    }, {});
    const currentBuildingHeadCountPairLastWeekData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [0, 0], exit: [0, 0] };
      return prev;
    }, {});
    const currentBuildingNetShoppingHourPairData: { [buildingName: string]: [number, number] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [0, 0];
      return prev;
    }, {});
    const currentBuildingAvgTimeSpentPairData: { [buildingName: string]: [number, number] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [0, 0];
      return prev;
    }, {});

    GraphDataService.mapSevenDayLineChartData(entranceExitDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      if (!dataFiltered || !dataFiltered.data || isPred) {
        entranceChartData.push(fillValue);
        exitChartData.push(fillValue);
        Object.keys(buildingEntranceExitData).forEach(buildingName => {
          buildingEntranceExitData[buildingName].entrance.push(fillValue);
          buildingEntranceExitData[buildingName].exit.push(fillValue);
          averageTimeSpentChartData[buildingName].push(fillValue);
          netShoppingTimeChartData[buildingName].push(fillValue);
          netShoppingHourChartData[buildingName].push(fillValue);
        });
        return;
      }
      const entranceExitData = dataFiltered.data;
      Object.keys(buildingEntranceExitData).forEach(buildingName => {
        const entranceExitBuildingData = entranceExitData[buildingName] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue };
        buildingEntranceExitData[buildingName].entrance.push(GraphDataService.procesChartData(entranceExitBuildingData.entrance));
        buildingEntranceExitData[buildingName].exit.push(GraphDataService.procesChartData(entranceExitBuildingData.exit));
        averageTimeSpentChartData[buildingName].push(GraphDataService.procesChartData(entranceExitBuildingData.average_timespent, false, true));
        netShoppingTimeChartData[buildingName].push(GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time, false, true));
        netShoppingHourChartData[buildingName].push(GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time / (60 * 60), false, false));
        if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
          currentBuildingHeadCountPairLastWeekData[buildingName].entrance[diffToSelectedDate + 1] = entranceExitBuildingData.entrance;
          currentBuildingHeadCountPairLastWeekData[buildingName].exit[diffToSelectedDate + 1] = entranceExitBuildingData.exit;
          currentBuildingHeadCountPairData[buildingName].entrance[diffToSelectedDate + 1] = entranceExitBuildingData.entrance;
          currentBuildingHeadCountPairData[buildingName].exit[diffToSelectedDate + 1] = entranceExitBuildingData.exit;
          currentBuildingNetShoppingHourPairData[buildingName][diffToSelectedDate + 1] = GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time / (60 * 60), false, false);
          currentBuildingAvgTimeSpentPairData[buildingName][diffToSelectedDate + 1] = entranceExitBuildingData.average_timespent;
        }
        if (diffToSelectedDate === 0) {
          if (this.configDataService.isFeatureEnabled('multiple_organization') && buildingName !== 'onesiam') {
            currentNetShoppingTimeData[buildingName] = GraphDataService.procesChartData(entranceExitBuildingData.net_shopping_time / (60 * 60), false, false);
          }
        }
      });
      const mainBuildingEntranceExitData = entranceExitData[mainBuilding] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue };
      entranceChartData.push(mainBuildingEntranceExitData.entrance);
      exitChartData.push(mainBuildingEntranceExitData.exit);
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        headCountEntrancePassCurrentPair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.entrance;
        headCountExitPassCurrentPair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.exit;
        averageTimeSpentPair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.average_timespent;
        currentNetShoppingTimePair[diffToSelectedDate + 1] = mainBuildingEntranceExitData.net_shopping_time;
        currentNetVisitorHourPair[diffToSelectedDate + 1] = ((mainBuildingEntranceExitData.entrance * mainBuildingEntranceExitData.average_timespent) / 60) / 60;
      }
    });
    // const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    entranceExitDatas.forEach((entranceExitData, idx) => {
      if (!entranceExitData.data || Object.keys(entranceExitData.data).length < 1) {
        return;
      }
      const date = moment(`${entranceExitData.year}-${entranceExitData.month}-${entranceExitData.day}`, 'YYYY-MM-DD');
      const todayDate = this.viewPeriodService.selectedDate;
      const diffDate = todayDate.diff(date, 'd');
      if (diffDate === 7) {
        Object.keys(currentBuildingHeadCountPairLastWeekData).forEach(buildingName => {
          currentBuildingHeadCountPairLastWeekData[buildingName].entrance[0] = entranceExitData.data?.[buildingName]?.entrance || 0;
          currentBuildingHeadCountPairLastWeekData[buildingName].exit[0] = entranceExitData.data?.[buildingName]?.entrance || 0;
        });
      }
    });
    const currentBuildingHeadCountData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(currentBuildingHeadCountPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingPairData.entrance[1]),
          diff: GraphDataService.procesChartData(buildingPairData.entrance[1] - buildingPairData.entrance[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.entrance[1] - buildingPairData.entrance[0]) / buildingPairData.entrance[0] * 100, true, true)
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingPairData.exit[1]),
          diff: GraphDataService.procesChartData(buildingPairData.exit[1] - buildingPairData.exit[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.exit[1] - buildingPairData.exit[0]) / buildingPairData.exit[0] * 100, true, true)
        }
      };
      return prev;
    }, {});
    const currentBuildingHeadCountLastWeekData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(currentBuildingHeadCountPairLastWeekData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingPairData.entrance[1]),
          diff: GraphDataService.procesChartData(buildingPairData.entrance[1] - buildingPairData.entrance[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.entrance[1] - buildingPairData.entrance[0]) / buildingPairData.entrance[0] * 100, true, true)
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingPairData.exit[1]),
          diff: GraphDataService.procesChartData(buildingPairData.exit[1] - buildingPairData.exit[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData.exit[1] - buildingPairData.exit[0]) / buildingPairData.exit[0] * 100, true, true)
        }
      };
      return prev;
    }, {});
    const excludeBuildingArea: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const filteredBuildingArea = Object.fromEntries(Object.entries(currentNetShoppingTimeData).filter(([key, _value]) => !excludeBuildingArea.includes(key)));

    if (lockNum < this.entranceExitAvgByDayLock) { return; }
    this.buildingEntranceExitAvgByDayData$.next(buildingEntranceExitData);
    this.currentBuildingEntranceExitAvgByDayData$.next(currentBuildingHeadCountData);
    // this.currentBuildingHeadCountLastWeekAvgByDayData$.next(currentBuildingHeadCountLastWeekData);
  }

  async fetchEntranceExitAvgByDayData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
    let fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (agg_type !== undefined) {
      fetchURL += `&aggregation_type=${agg_type}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion building/entrance-exit daily average

  //#region floor/building-floor-entrance-exit
  async loadEntranceExitFloorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('mock_data', 'building_floor_entrance_exit')) {
      graphDataServiceInstance.deriveEntranceExitFloorMockData(date);
      return;
    } else {
      return graphDataServiceInstance.fetchEntranceExitFloorData(date, ++graphDataServiceInstance.entranceExitFloorLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitFloorData(data, lockNum));
    }
  }

  async deriveEntranceExitFloorMockData(date: moment.Moment) {
    const num_interval = 8;
    const buildingFloorEntranceExitData = await generateNestedData(date, this.viewPeriodService, this.configDataService, 'FLOOR_TRAFFIC', 'traffic', num_interval);
    const floorEntranceExitBreakdownData: { [floorName: string]: { entrance: number; exit: number } } = Object.keys(buildingFloorEntranceExitData).reduce((prev, floorName) => {
      prev[floorName] = {
        entrance: buildingFloorEntranceExitData[floorName].entrance[num_interval - 2],
        exit: buildingFloorEntranceExitData[floorName].exit[num_interval - 2]
      };
      return prev;
    }, {});
  }

  deriveEntranceExitFloorData(entranceExitFloorDatas: IFetchData<BuildingEntranceExitByFloorData>[], lockNum: number) {
    // initialize data
    const entranceExitFloorData: { [buildingName: string]: { [floorName: string]: { entrance: number[]; exit: number[] } } } = {};
    const avgTimeSpentFloorData: { [buildingName: string]: { [floorName: string]: number[] } } = {};
    const netShoppingTimeFloorData: { [buildingName: string]: { [floorName: string]: number[] } } = {};

    const currentBuildingHeadCountPairFloorData: { [buildingName: string]: { [floorName: string]: { entrance: [number, number]; exit: [number, number] } } } = {};
    const currentNetShoppingTimePairFloorData: { [buildingName: string]: { [floorName: string]: [number, number] } } = {};
    const currentAvgTimeSpentPairFloorData: { [buildingName: string]: { [floorName: string]: [number, number] } } = {};
    entranceExitFloorDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        if (!entranceExitFloorData[buildingName]) {
          entranceExitFloorData[buildingName] = {};
          avgTimeSpentFloorData[buildingName] = {};
          netShoppingTimeFloorData[buildingName] = {};
          currentBuildingHeadCountPairFloorData[buildingName] = {};
          currentNetShoppingTimePairFloorData[buildingName] = {};
          currentAvgTimeSpentPairFloorData[buildingName] = {};
        }
        Object.keys(floorData).forEach(floorName => {
          entranceExitFloorData[buildingName][floorName] = { entrance: [], exit: [] };
          avgTimeSpentFloorData[buildingName][floorName] = [];
          netShoppingTimeFloorData[buildingName][floorName] = [];
          currentBuildingHeadCountPairFloorData[buildingName][floorName] = { entrance: [0, 0], exit: [0, 0] };
          currentNetShoppingTimePairFloorData[buildingName][floorName] = [0, 0];
          currentAvgTimeSpentPairFloorData[buildingName][floorName] = [0, 0];
        });
      });
    });
    GraphDataService.mapSevenDayLineChartData(entranceExitFloorDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(entranceExitFloorData).forEach(buildingName => {
          Object.keys(entranceExitFloorData[buildingName]).forEach(floorName => {
            entranceExitFloorData[buildingName][floorName].entrance.push(fillValue);
            entranceExitFloorData[buildingName][floorName].exit.push(fillValue);
            avgTimeSpentFloorData[buildingName][floorName].push(fillValue);
            netShoppingTimeFloorData[buildingName][floorName].push(fillValue);
          });
        });
        return;
      }
      const buildingEntranceExitFloorData = dataFiltered.data;
      Object.keys(entranceExitFloorData).forEach(buildingName => {
        Object.keys(entranceExitFloorData[buildingName]).forEach(floorName => {
          const dataIt = (buildingEntranceExitFloorData[buildingName] || {})[floorName] || { entrance: 0, exit: 0, average_timespent: 0, net_shopping_time: 0 };
          entranceExitFloorData[buildingName][floorName].entrance.push(GraphDataService.procesChartData(dataIt.entrance));
          entranceExitFloorData[buildingName][floorName].exit.push(GraphDataService.procesChartData(dataIt.exit));
          avgTimeSpentFloorData[buildingName][floorName].push(GraphDataService.procesChartData(dataIt.average_timespent));
          netShoppingTimeFloorData[buildingName][floorName].push(GraphDataService.procesChartData(dataIt.net_shopping_time));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentBuildingHeadCountPairFloorData[buildingName][floorName].entrance[diffToSelectedDate + 1] = dataIt.entrance;
            currentBuildingHeadCountPairFloorData[buildingName][floorName].exit[diffToSelectedDate + 1] = dataIt.exit;
            currentNetShoppingTimePairFloorData[buildingName][floorName][diffToSelectedDate + 1] = dataIt.net_shopping_time;
            currentAvgTimeSpentPairFloorData[buildingName][floorName][diffToSelectedDate + 1] = dataIt.average_timespent;
          }
        });
      });
    });

    const currentBuildingHeadCountFloorData: {
      [buildingName: string]: { [floorName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } };
    } = Object.entries(currentBuildingHeadCountPairFloorData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, buildingPairData]) => {
        prev[buildingName][floorName] = {
          entrance: {
            headCount: GraphDataService.procesChartData(buildingPairData.entrance[1]),
            diff: GraphDataService.procesChartData(buildingPairData.entrance[1] - buildingPairData.entrance[0], true, false),
            diffPercent: GraphDataService.procesChartData((buildingPairData.entrance[1] - buildingPairData.entrance[0]) / buildingPairData.entrance[0] * 100, true, true)
          },
          exit: {
            headCount: GraphDataService.procesChartData(buildingPairData.exit[1]),
            diff: GraphDataService.procesChartData(buildingPairData.exit[1] - buildingPairData.exit[0], true, false),
            diffPercent: GraphDataService.procesChartData((buildingPairData.exit[1] - buildingPairData.exit[0]) / buildingPairData.exit[0] * 100, true, true)
          }
        };
      });
      return prev;
    }, Object.keys(currentBuildingHeadCountPairFloorData).reduce((prev, bldName) => {
      prev[bldName] = {};
      return prev;
    }, {}));

    const currentNetShoppingTimeFloorData: {
      [buildingName: string]: { [floorName: string]: { netShoppingTime: number; diff: number; diffPercent: number } };
    } = Object.entries(currentNetShoppingTimePairFloorData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, buildingPairData]) => {
        prev[buildingName][floorName] = {
          netShoppingTime: GraphDataService.procesChartData(buildingPairData[1]),
          diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0] * 100, true, true)
        };
      });
      return prev;
    }, Object.keys(currentNetShoppingTimePairFloorData).reduce((prev, bldName) => {
      prev[bldName] = {};
      return prev;
    }, {}));

    const currentAvgTimeSpentFloorData: {
      [buildingName: string]: { [floorName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } };
    } = Object.entries(currentAvgTimeSpentPairFloorData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, buildingPairData]) => {
        prev[buildingName][floorName] = {
          avgTimeSpent: GraphDataService.procesChartData(buildingPairData[1]),
          diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
          diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0] * 100, true, true)
        };
      });
      return prev;
    }, Object.keys(currentAvgTimeSpentPairFloorData).reduce((prev, bldName) => {
      prev[bldName] = {};
      return prev;
    }, {}));

    if (lockNum < this.entranceExitFloorLock) { return; }

    let checked = false;
    Object.keys(entranceExitFloorData).forEach(buildingName => {
      Object.keys(entranceExitFloorData[buildingName]).forEach(floorName => {
        if (entranceExitFloorData[buildingName][floorName].entrance.slice(0, 7).every(val => val !== null) && entranceExitFloorData[buildingName][floorName].exit.slice(0, 7).every(val => val !== null)) {
          checked = true;
        }
      });
    });
    if (checked) {
      this.entranceExitFloorData$.next(entranceExitFloorData);
    }
    this.avgTimeSpentFloorData$.next(avgTimeSpentFloorData);
    this.netShoppingTimeFloorData$.next(netShoppingTimeFloorData);
    this.currentBuildingHeadCountFloorData$.next(currentBuildingHeadCountFloorData);
    this.currentNetShoppingTimeFloorData$.next(currentNetShoppingTimeFloorData);
    this.currentAvgTimeSpentFloorData$.next(currentAvgTimeSpentFloorData);
  }

  async fetchEntranceExitFloorData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const requestURL = `/retail_customer_api_v2/api/v2/floor/building-floor-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(requestURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitByFloorData>[], number];
  }
  //#endregion floor/building-floor-entrance-exit

  //#region zone/entrance-exit
  async loadEntranceExitZoneData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('mock_data', 'zone_entrance_exit')) {
      graphDataServiceInstance.deriveEntranceExitZoneMockData(date);
      return;
    } else {
      return graphDataServiceInstance.fetchEntranceExitZoneData(date, ++graphDataServiceInstance.zoneEntranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitZoneData(data, lockNum));
    }
  }

  async deriveEntranceExitZoneMockData(date: moment.Moment) {
    const num_interval = 8;
    const zoneEntranceExitData = await generateNestedData(date, this.viewPeriodService, this.configDataService, 'ZONE_TRAFFIC', 'traffic', num_interval) as { [zoneName: string]: { entrance: number[]; exit: number[] } };
    const zoneEntranceExitBreakdownData: { [zoneName: string]: { entrance: number; exit: number } } = Object.keys(zoneEntranceExitData).reduce((prev, zoneName) => {
      prev[zoneName] = {
        entrance: zoneEntranceExitData[zoneName].entrance[num_interval - 2],
        exit: zoneEntranceExitData[zoneName].exit[num_interval - 2]
      };
      return prev;
    }, {});
  }

  deriveEntranceExitZoneData(entranceExitZoneDatas: IFetchData<BuildingZoneEntranceExitData>[], lockNum: number) {
    // initialize data
    const currentBuildingHeadCountZonePairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: [number, number]; exit: [number, number] } } } } = {};
    const currentNetShoppingTimeZonePairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: [number, number] } } } = {};
    const currentAvgTimeSpentZonePairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: [number, number] } } } = {};
    const zoneEntranceExitFloorData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: number[]; exit: number[] } } } } = {};
    const avgTimeSpentZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } } = {};
    const netShoppingTimeZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } } = {};
    const zoneNetShoppingHourPercentage: { [zoneName: string]: number } = {};
    for (const zoneName of this.configDataService.ZONE_NAME_LIST) {
      zoneNetShoppingHourPercentage[zoneName] = 0;
    }
    entranceExitZoneDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        if (!currentNetShoppingTimeZonePairData[buildingName]) {
          currentBuildingHeadCountZonePairData[buildingName] = {};
          currentNetShoppingTimeZonePairData[buildingName] = {};
          currentAvgTimeSpentZonePairData[buildingName] = {};
          zoneEntranceExitFloorData[buildingName] = {};
          avgTimeSpentZoneData[buildingName] = {};
          netShoppingTimeZoneData[buildingName] = {};
        }
        Object.entries(floorData).forEach(([floorName, zoneData]) => {
          if (!currentNetShoppingTimeZonePairData[buildingName][floorName]) {
            currentBuildingHeadCountZonePairData[buildingName][floorName] = {};
            currentNetShoppingTimeZonePairData[buildingName][floorName] = {};
            currentAvgTimeSpentZonePairData[buildingName][floorName] = {};
            zoneEntranceExitFloorData[buildingName][floorName] = {};
            avgTimeSpentZoneData[buildingName][floorName] = {};
            netShoppingTimeZoneData[buildingName][floorName] = {};
          }
          Object.keys(zoneData).forEach((zoneName) => {
            currentNetShoppingTimeZonePairData[buildingName][floorName][zoneName] = [0, 0];
            currentAvgTimeSpentZonePairData[buildingName][floorName][zoneName] = [0, 0];
            currentBuildingHeadCountZonePairData[buildingName][floorName][zoneName] = { entrance: [0, 0], exit: [0, 0] };
            zoneEntranceExitFloorData[buildingName][floorName][zoneName] = { entrance: [], exit: [] };
            avgTimeSpentZoneData[buildingName][floorName][zoneName] = [];
            netShoppingTimeZoneData[buildingName][floorName][zoneName] = [];
          });
        });
      });
    });

    GraphDataService.mapSevenDayLineChartData(entranceExitZoneDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      if (!dataFiltered || !dataFiltered.data || isPred) {
        Object.entries(zoneEntranceExitFloorData).forEach(([buildingName, buildingData]) => {
          Object.entries(buildingData).forEach(([floorName, floorData]) => {
            Object.keys(floorData).forEach((zoneName) => {
              zoneEntranceExitFloorData[buildingName][floorName][zoneName].entrance.push(fillValue);
              zoneEntranceExitFloorData[buildingName][floorName][zoneName].exit.push(fillValue);
              avgTimeSpentZoneData[buildingName][floorName][zoneName].push(fillValue);
              netShoppingTimeZoneData[buildingName][floorName][zoneName].push(fillValue);
            });
          });
        });
        return;
      }
      const buildingEntranceExitZoneData = dataFiltered.data;
      Object.keys(zoneEntranceExitFloorData).forEach((buildingName) => {
        Object.keys(zoneEntranceExitFloorData[buildingName]).forEach((floorName) => {
          Object.keys(zoneEntranceExitFloorData[buildingName][floorName]).forEach((zoneName) => {
            const zoneData = (((buildingEntranceExitZoneData[buildingName] || {})[floorName] || {})[zoneName] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue });
            zoneEntranceExitFloorData[buildingName][floorName][zoneName].entrance.push(GraphDataService.procesChartData(zoneData.entrance));
            zoneEntranceExitFloorData[buildingName][floorName][zoneName].exit.push(GraphDataService.procesChartData(zoneData.exit));
            avgTimeSpentZoneData[buildingName][floorName][zoneName].push(GraphDataService.procesChartData(zoneData.average_timespent));
            netShoppingTimeZoneData[buildingName][floorName][zoneName].push(GraphDataService.procesChartData(zoneData.net_shopping_time));
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              currentNetShoppingTimeZonePairData[buildingName][floorName][zoneName][diffToSelectedDate + 1] = GraphDataService.procesChartData(zoneData.net_shopping_time / (60 * 60), false, false);
              currentAvgTimeSpentZonePairData[buildingName][floorName][zoneName][diffToSelectedDate + 1] = zoneData.average_timespent;
              currentBuildingHeadCountZonePairData[buildingName][floorName][zoneName].entrance[diffToSelectedDate + 1] = zoneData.entrance;
              currentBuildingHeadCountZonePairData[buildingName][floorName][zoneName].exit[diffToSelectedDate + 1] = zoneData.exit;
            }
          });
        });
      });
    });

    let sumZoneNetShoppingHour = 0;
    const currentNetShoppingTimeZoneData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { netShoppingTime: number; diff: number; diffPercent: number } } };
    } = Object.entries(currentNetShoppingTimeZonePairData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, zonePairData]) => {
          sumZoneNetShoppingHour += zonePairData[1];
          prev[buildingName][floorName][zoneName] = {
            netShoppingTime: GraphDataService.procesChartData(zonePairData[1]),
            diff: GraphDataService.procesChartData(zonePairData[1] - zonePairData[0], true, false),
            diffPercent: GraphDataService.procesChartData((zonePairData[1] - zonePairData[0]) / zonePairData[0] * 100, true, true)
          };
        });
      });
      return prev;
    }, Object.entries(currentNetShoppingTimeZonePairData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.keys(zoneData).reduce((accZone, zoneName) => {
          accZone[zoneName] = {};
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    for (const [buildingName, buildingData] of Object.entries(currentNetShoppingTimeZonePairData)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        for (const [zoneName, zoneData] of Object.entries(floorData)) {
          zoneNetShoppingHourPercentage[zoneName] = GraphDataService.procesChartData((zoneData[1] / sumZoneNetShoppingHour) * 100, false, true);
        }
      }
    }

    const currentBuildingHeadCountZoneData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } } } };
    } = Object.entries(currentBuildingHeadCountZonePairData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, zonePairData]) => {
          prev[buildingName][floorName][zoneName] = {
            entrance: {
              headCount: GraphDataService.procesChartData(zonePairData.entrance[1]),
              diff: GraphDataService.procesChartData(zonePairData.entrance[1] - zonePairData.entrance[0], true, false),
              diffPercent: GraphDataService.procesChartData((zonePairData.entrance[1] - zonePairData.entrance[0]) / zonePairData.entrance[0] * 100, true, true)
            },
            exit: {
              headCount: GraphDataService.procesChartData(zonePairData.exit[1]),
              diff: GraphDataService.procesChartData(zonePairData.exit[1] - zonePairData.exit[0], true, false),
              diffPercent: GraphDataService.procesChartData((zonePairData.exit[1] - zonePairData.exit[0]) / zonePairData.exit[0] * 100, true, true)
            }
          };
        });
      });
      return prev;
    }, Object.entries(currentBuildingHeadCountZonePairData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.keys(zoneData).reduce((accZone, zoneName) => {
          accZone[zoneName] = { entrance: {}, exit: {} };
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    const currentAvgTimeSpentZoneData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } } };
    } = Object.entries(currentAvgTimeSpentZonePairData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, zonePairData]) => {
          prev[buildingName][floorName][zoneName] = {
            avgTimeSpent: GraphDataService.procesChartData(zonePairData[1]),
            diff: GraphDataService.procesChartData(zonePairData[1] - zonePairData[0], true, false),
            diffPercent: GraphDataService.procesChartData((zonePairData[1] - zonePairData[0]) / zonePairData[0] * 100, true, true)
          };
        });
      });
      return prev;
    }, Object.entries(currentAvgTimeSpentZonePairData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.keys(zoneData).reduce((accZone, zoneName) => {
          accZone[zoneName] = {};
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    if (lockNum < this.zoneEntranceExitLock) { return; }

    let checked = false;
    Object.keys(zoneEntranceExitFloorData).forEach(buildingName => {
      Object.keys(zoneEntranceExitFloorData[buildingName]).forEach(floorName => {
        Object.keys(zoneEntranceExitFloorData[buildingName][floorName]).forEach(zoneName => {
          if (zoneEntranceExitFloorData[buildingName][floorName][zoneName].entrance.slice(0, 7).every(val => val !== null) && zoneEntranceExitFloorData[buildingName][floorName][zoneName].exit.slice(0, 7).every(val => val !== null)) {
            checked = true;
          }
        });
      });
    });
    if (checked) {
      this.zoneEntranceExitFloorData$.next(zoneEntranceExitFloorData);
    }
    this.currentBuildingZoneTrafficPairData = currentBuildingHeadCountZonePairData;
    this.currentBuildingHeadCountZoneData$.next(currentBuildingHeadCountZoneData);
    this.currentNetShoppingTimeZoneData$.next(currentNetShoppingTimeZoneData);
    this.avgTimeSpentZoneData$.next(avgTimeSpentZoneData);
    this.netShoppingTimeZoneData$.next(netShoppingTimeZoneData);
    this.currentAvgTimeSpentZoneData$.next(currentAvgTimeSpentZoneData);
    // this.zoneNetShoppingHourPercentageData$.next(zoneNetShoppingHourPercentage);
    this.zoneDataCompletely$.next(true);
  }

  async fetchEntranceExitZoneData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const requestURL = `/retail_customer_api_v2/api/v2/zone/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(requestURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingZoneEntranceExitData>[], number];
  }
  //#endregion zone/entrance-exit

  //#region store/entrance-exit
  async loadEntranceExitStoreData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    // get store name
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const selectedDate = graphDataServiceInstance.viewPeriodService.selectedDate;
    // const clearedData = Array.from({ length: 7 }).map<number>(() => null);
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_area_breakpoint')) {
      if (graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_area_breakpoint_date')) {
        const storeAreaBreakpointDateString = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_area_breakpoint_date');
        const storeAreaBreakpointDate = moment(storeAreaBreakpointDateString, 'YYYY-MM-DD');
        if (selectedDate.isSameOrAfter(storeAreaBreakpointDate)) {
          ++graphDataServiceInstance.storeEntranceExitLock;
          return Promise.resolve();
        }
      }
    }
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeEntranceExitLock;
      // graphDataServiceInstance.storeVisitorTrafficTrendData$.next({
      //   entrance: clearedData,
      //   exit: clearedData
      // });
      // graphDataServiceInstance.currentStoreVisitorTrafficTrendtEntranceData$.next({ headCount: 0, diff: 0, diffPercent: 0 });
      // graphDataServiceInstance.currentStoreVisitorTrafficTrendtExitData$.next({ headCount: 0, diff: 0, diffPercent: 0 });
      // graphDataServiceInstance.storeVisitorAvgTimespentData$.next(clearedData);
      // graphDataServiceInstance.storeVisitorNetShoppingHourData$.next(clearedData);
      return Promise.resolve();
    }
    // const storeAreaName = selectedInteractable.store.id;
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    return graphDataServiceInstance.fetchEntranceExitStoreData(date, ++graphDataServiceInstance.storeEntranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitStoreData(data, lockNum, parseSpeicalAreaName));
  }

  deriveEntranceExitStoreData(entranceExitStoreDatas: IFetchData<BuildingStoreEntranceExitData>[], lockNum: number, storeInstanceName?: string) {
    // initialize data
    const currentStoreNetShoppingTimePairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: [number, number] } } } } = {};
    const currentStoreEntrancePairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: [number, number] } } } } = {};
    const currentStoreExitPairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: [number, number] } } } } = {};
    const currentStoreAvgTimeSpentPairData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: [number, number] } } } } = {};
    const storeEntranceExitData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { entrance: number[]; exit: number[] } } } } } = {};
    const storeAreaEntranceExitTrend: { entrance: number[]; exit: number[] } = { entrance: [], exit: [] };
    const storeAreaEntranceExitBreakdown: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
    const storeAreaEntranceTimePairData: [number, number] = [0, 0];
    const storeAreaExitTimePairData: [number, number] = [0, 0];
    const storeAreaAvgTimespentTimePairData: [number, number] = [0, 0];
    const storeAreaProximityTrend: number[] = [];
    entranceExitStoreDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        if (!currentStoreNetShoppingTimePairData[buildingName]) {
          currentStoreNetShoppingTimePairData[buildingName] = {};
          currentStoreAvgTimeSpentPairData[buildingName] = {};
          currentStoreEntrancePairData[buildingName] = {};
          currentStoreExitPairData[buildingName] = {};
          storeEntranceExitData[buildingName] = {};
        }
        Object.entries(floorData).forEach(([floorName, zoneData]) => {
          if (!currentStoreNetShoppingTimePairData[buildingName][floorName]) {
            currentStoreNetShoppingTimePairData[buildingName][floorName] = {};
            currentStoreAvgTimeSpentPairData[buildingName][floorName] = {};
            currentStoreEntrancePairData[buildingName][floorName] = {};
            currentStoreExitPairData[buildingName][floorName] = {};
            storeEntranceExitData[buildingName][floorName] = {};
          }
          Object.entries(zoneData).forEach(([zoneName, storeData]) => {
            if (!currentStoreNetShoppingTimePairData[buildingName][floorName][zoneName]) {
              currentStoreNetShoppingTimePairData[buildingName][floorName][zoneName] = {};
              currentStoreAvgTimeSpentPairData[buildingName][floorName][zoneName] = {};
              currentStoreEntrancePairData[buildingName][floorName][zoneName] = {};
              currentStoreExitPairData[buildingName][floorName][zoneName] = {};
              storeEntranceExitData[buildingName][floorName][zoneName] = {};
            }
            Object.keys(storeData).forEach((storeName) => {
              currentStoreNetShoppingTimePairData[buildingName][floorName][zoneName][storeName] = [0, 0];
              currentStoreAvgTimeSpentPairData[buildingName][floorName][zoneName][storeName] = [0, 0];
              currentStoreEntrancePairData[buildingName][floorName][zoneName][storeName] = [0, 0];
              currentStoreExitPairData[buildingName][floorName][zoneName][storeName] = [0, 0];
              storeEntranceExitData[buildingName][floorName][zoneName][storeName] = { entrance: [], exit: [] };
            });
          });
        });
      });
    });
    GraphDataService.mapSevenDayLineChartData(entranceExitStoreDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      if (!dataFiltered || !dataFiltered.data || isPred) {
        Object.entries(storeEntranceExitData).forEach(([buildingName, buildingData]) => {
          Object.entries(buildingData).forEach(([floorName, floorData]) => {
            Object.entries(floorData).forEach(([zoneName, storeData]) => {
              Object.keys(storeData).forEach((storeName) => {
                storeEntranceExitData[buildingName][floorName][zoneName][storeName].entrance.push(fillValue);
                storeEntranceExitData[buildingName][floorName][zoneName][storeName].exit.push(fillValue);
              });
            });
          });
        });
        return;
      }
      const buildingEntranceExitStoreData = dataFiltered.data;
      Object.keys(storeEntranceExitData).forEach((buildingName) => {
        Object.keys(storeEntranceExitData[buildingName]).forEach((floorName) => {
          Object.keys(storeEntranceExitData[buildingName][floorName]).forEach((zoneName) => {
            Object.keys(storeEntranceExitData[buildingName][floorName][zoneName]).forEach((storeName) => {
              const storeData = ((((buildingEntranceExitStoreData[buildingName] || {})[floorName] || {})[zoneName] || {})[storeName] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue, proximity: fillValue });
              storeEntranceExitData[buildingName][floorName][zoneName][storeName].entrance.push(GraphDataService.procesChartData(storeData.entrance));
              storeEntranceExitData[buildingName][floorName][zoneName][storeName].exit.push(GraphDataService.procesChartData(storeData.exit));
              if (storeName === storeInstanceName) {
                storeAreaEntranceExitTrend.entrance.push(GraphDataService.procesChartData(storeData.entrance));
                storeAreaEntranceExitTrend.exit.push(GraphDataService.procesChartData(storeData.exit));
                storeAreaProximityTrend.push(GraphDataService.procesChartData(storeData.proximity));
                if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
                  storeAreaEntranceExitBreakdown.entrance = GraphDataService.procesChartData(storeData.entrance);
                  storeAreaEntranceExitBreakdown.exit = GraphDataService.procesChartData(storeData.exit);
                  storeAreaEntranceTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(storeData.entrance);
                  storeAreaExitTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(storeData.exit);
                  storeAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(storeData.average_timespent, false, false);
                }
              }
              if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
                currentStoreNetShoppingTimePairData[buildingName][floorName][zoneName][storeName][diffToSelectedDate + 1] = storeData.net_shopping_time;
                currentStoreAvgTimeSpentPairData[buildingName][floorName][zoneName][storeName][diffToSelectedDate + 1] = storeData.average_timespent;
                currentStoreEntrancePairData[buildingName][floorName][zoneName][storeName][diffToSelectedDate + 1] = storeData.entrance;
                currentStoreExitPairData[buildingName][floorName][zoneName][storeName][diffToSelectedDate + 1] = storeData.exit;
              }
            });
          });
        });
      });
    });
    const currentNetShoppingTimeStoreData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { netShoppingTime: number; diff: number; diffPercent: number } } } };
    } = Object.entries(currentStoreNetShoppingTimePairData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, storeData]) => {
          Object.entries(storeData).forEach(([storeName, storePairData]) => {
            prev[buildingName][floorName][zoneName][storeName] = {
              netShoppingTime: GraphDataService.procesChartData(storePairData[1]),
              diff: GraphDataService.procesChartData(storePairData[1] - storePairData[0], true, false),
              diffPercent: GraphDataService.procesChartData((storePairData[1] - storePairData[0]) / storePairData[0] * 100, true, true)
            };
          });
        });
      });
      return prev;
    }, Object.entries(currentStoreNetShoppingTimePairData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.entries(zoneData).reduce((accZone, [zoneName, storeData]) => {
          accZone[zoneName] = Object.keys(storeData).reduce((accStore, storeName) => {
            accStore[storeName] = {};
            return accStore;
          }, {});
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    const currentAvgTimeSpentStoreData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } } } };
    } = Object.entries(currentStoreAvgTimeSpentPairData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, storeData]) => {
          Object.entries(storeData).forEach(([storeName, storePairData]) => {
            prev[buildingName][floorName][zoneName][storeName] = {
              avgTimeSpent: GraphDataService.procesChartData(storePairData[1]),
              diff: GraphDataService.procesChartData(storePairData[1] - storePairData[0], true, false),
              diffPercent: GraphDataService.procesChartData((storePairData[1] - storePairData[0]) / storePairData[0] * 100, true, true)
            };
          });
        });
      });
      return prev;
    }, Object.entries(currentStoreAvgTimeSpentPairData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.entries(zoneData).reduce((accZone, [zoneName, storeData]) => {
          accZone[zoneName] = Object.keys(storeData).reduce((accStore, storeName) => {
            accStore[storeName] = {};
            return accStore;
          }, {});
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    const currentStoreExitData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { headCount: number; diff: number; diffPercent: number } } } };
    } = Object.entries(currentStoreExitPairData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, storeData]) => {
          Object.entries(storeData).forEach(([storeName, storePairData]) => {
            prev[buildingName][floorName][zoneName][storeName] = {
              headCount: GraphDataService.procesChartData(storePairData[1]),
              diff: GraphDataService.procesChartData(storePairData[1] - storePairData[0], true, false),
              diffPercent: GraphDataService.procesChartData((storePairData[1] - storePairData[0]) / storePairData[0] * 100, true, true)
            };
          });
        });
      });
      return prev;
    }, Object.entries(currentStoreExitPairData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.entries(zoneData).reduce((accZone, [zoneName, storeData]) => {
          accZone[zoneName] = Object.keys(storeData).reduce((accStore, storeName) => {
            accStore[storeName] = {};
            return accStore;
          }, {});
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    const currentStoreEntranceData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: { headCount: number; diff: number; diffPercent: number } } } };
    } = Object.entries(currentStoreEntrancePairData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, storeData]) => {
          Object.entries(storeData).forEach(([storeName, storePairData]) => {
            prev[buildingName][floorName][zoneName][storeName] = {
              headCount: GraphDataService.procesChartData(storePairData[1]),
              diff: GraphDataService.procesChartData(storePairData[1] - storePairData[0], true, false),
              diffPercent: GraphDataService.procesChartData((storePairData[1] - storePairData[0]) / storePairData[0] * 100, true, true)
            };
          });
        });
      });
      return prev;
    }, Object.entries(currentStoreEntrancePairData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.entries(zoneData).reduce((accZone, [zoneName, storeData]) => {
          accZone[zoneName] = Object.keys(storeData).reduce((accStore, storeName) => {
            accStore[storeName] = {};
            return accStore;
          }, {});
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    if (lockNum < this.storeEntranceExitLock) { return; }

    const diffEntrance = storeAreaEntranceTimePairData[1] - storeAreaEntranceTimePairData[0];
    const diffEntrancePercentage = storeAreaEntranceTimePairData[0] === 0 ? 0 : (diffEntrance / storeAreaEntranceTimePairData[0]) * 100;
    const diffExit = storeAreaExitTimePairData[1] - storeAreaExitTimePairData[0];
    const diffExitPercentage = storeAreaExitTimePairData[0] === 0 ? 0 : (diffExit / storeAreaExitTimePairData[0]) * 100;
    const diffAvgTimespent = storeAreaAvgTimespentTimePairData[1] - storeAreaAvgTimespentTimePairData[0];
    const diffAvgTimespentPercentage = storeAreaAvgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / storeAreaAvgTimespentTimePairData[0]) * 100;

    // this.storeAreaEntranceExitTrend$.next(storeAreaEntranceExitTrend);

    // // this.currentBuildingHeadCountZoneData$.next(currentBuildingHeadCountZoneData);
    // this.currentStoreAreaEntranceExit$.next({
    //   entrance: {

    //     current: storeAreaEntranceTimePairData[1],
    //     diff: GraphDataService.procesChartData(diffEntrance, true, true),
    //     diffPercent: GraphDataService.procesChartData(diffEntrancePercentage, true, true)
    //   },
    //   exit: {
    //     current: storeAreaExitTimePairData[1],
    //     diff: GraphDataService.procesChartData(diffExit, true, true),
    //     diffPercent: GraphDataService.procesChartData(diffExitPercentage, true, true)
    //   }
    // });
    // this.currentStoreAreaAvgTimespent$.next({
    //   current: storeAreaAvgTimespentTimePairData[1],
    //   diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
    //   diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
    // });
    this.storeAreaEntranceExitBreakdown$.next(storeAreaEntranceExitBreakdown);
    this.storeAreaProximityTrend$.next(storeAreaProximityTrend);
    this.currentStoreEntranceData$.next(currentStoreEntranceData);
    this.currentStoreExitData$.next(currentStoreExitData);
    this.storeEntranceExitData$.next(storeEntranceExitData);
    this.currentStoreNetShoppingTimeData$.next(currentNetShoppingTimeStoreData);
    this.currentStoreAvgTimeSpentTimeData$.next(currentAvgTimeSpentStoreData);
  }

  async fetchEntranceExitStoreData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const requestURL = `/retail_customer_api_v2/api/v2/store/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(requestURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingStoreEntranceExitData>[], number];
  }

  //#endregion store/entrance-exit

  //#region entrance-exit month
  async loadEntranceExitMonthData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const queryDate = moment(date);
    // if (graphDataServiceInstance.entranceExitMonthLastFetch.month === queryDate.month() && graphDataServiceInstance.entranceExitMonthLastFetch.year === queryDate.year() && (Date.now() - graphDataServiceInstance.lastEntranceExitMonthTime <= 5 * 60 * 1000)) { return; }
    return graphDataServiceInstance.fetchEntranceExitMonthData(date, ++graphDataServiceInstance.entranceExitMonthLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitMonthData(date, data, lockNum));
  }

  deriveEntranceExitMonthData(date: moment.Moment, entranceExitMonthDatas: IFetchData<EntranceExitNetShopTimeSpent>[], lockNum: number) {
    const queryDate = date.clone();
    const entranceMonthData: { [date: string]: number } = {};
    const exitMonthData: { [date: string]: number } = {};
    const buildingEntranceMonthData: { [buildingName: string]: { [date: string]: number } } = {};
    const buildingExitMonthData: { [buildingName: string]: { [date: string]: number } } = {};
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    const buildingNameSet = new Set<string>();
    Object.keys(this.configDataService.FLOOR_OBJECTS).forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    for (const building of buildingNames) {
      buildingEntranceMonthData[building] = {};
      buildingExitMonthData[building] = {};
      const filteredData = entranceExitMonthDatas.filter(data => data.area === building);
      for (const momentIt = date.clone().startOf('month'); momentIt <= date.clone().endOf('month'); momentIt.add(1, 'day')) {
        const dataIt = filterSelectedDateData(filteredData, momentIt, ViewPeriod.DAYS)[0];
        if (!dataIt || !dataIt.data) {
          buildingEntranceMonthData[building][momentIt.date()] = 0;
          buildingExitMonthData[building][momentIt.date()] = 0;
          continue;
        } else {
          const entranceExitData = this.configDataService.isFeatureEnabled('month_performance', 'area_data') ? dataIt.data : dataIt.data?.[mainBuilding];
          buildingEntranceMonthData[building][momentIt.date()] = GraphDataService.procesChartData(entranceExitData.entrance);
          buildingExitMonthData[building][momentIt.date()] = GraphDataService.procesChartData(entranceExitData.exit);
        }
      }
    }
    for (const momentIt = date.clone().startOf('month'); momentIt <= date.clone().endOf('month'); momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitMonthDatas, momentIt, ViewPeriod.DAYS)[0];
      if (!dataIt || !dataIt.data) {
        entranceMonthData[momentIt.date()] = 0;
        exitMonthData[momentIt.date()] = 0;
        continue;
      } else {
        const entranceExitData = this.configDataService.isFeatureEnabled('month_performance', 'area_data') ? dataIt.data : dataIt.data?.[mainBuilding];
        entranceMonthData[momentIt.date()] = GraphDataService.procesChartData(entranceExitData?.entrance || 0);
        exitMonthData[momentIt.date()] = GraphDataService.procesChartData(entranceExitData?.exit || 0);
      }
    }

    if (lockNum < this.entranceExitMonthLock) { return; }
    this.entranceExitMonthLastFetch = { month: queryDate.month(), year: queryDate.year() };
    this.lastEntranceExitMonthTime = Date.now();
    this.entranceMonthData$.next(this.configDataService.isFeatureEnabled('month_performance', 'area_data') ? buildingEntranceMonthData[mainBuilding] : entranceMonthData);
    this.exitMonthData$.next(this.configDataService.isFeatureEnabled('month_performance', 'area_data') ? buildingExitMonthData[mainBuilding] : exitMonthData);
    this.buildingEntranceMonthData$.next(buildingEntranceMonthData);
    this.buildingExitMonthData$.next(buildingExitMonthData);
  }

  async fetchEntranceExitMonthData(date: moment.Moment, lockNum: number) {
    const queryDate = date.clone();
    const currentDate = this.viewPeriodService.isLiveMode ? moment().clone() : moment().clone().subtract(1, 'd');
    const startOfMonth = queryDate.startOf('month');
    let qParams = {
      periodType: 'day',
      start_date: currentDate.format('YYYY-MM-DD'),
      num_interval: currentDate.diff(startOfMonth, 'd') + 1,
    };
    // check if start of month is same month as current date
    if (queryDate.isBefore(currentDate, 'month') && queryDate.isBefore(currentDate, 'year')) {
      qParams = {
        periodType: 'day',
        start_date: queryDate.endOf('month').format('YYYY-MM-DD'),
        num_interval: queryDate.daysInMonth(),
      };
    }
    else if (currentDate.isBefore(queryDate, 'month')) {
      qParams = {
        periodType: 'day',
        start_date: queryDate.endOf('month').format('YYYY-MM-DD'),
        num_interval: queryDate.daysInMonth(),
      };
    }
    const fetchURL = this.configDataService.isFeatureEnabled('month_performance', 'area_data')
      ? `/retail_customer_api_v2/api/v2/building/area-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`
      : `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion entrance-exit month

  //#region weather month
  async loadWeatherMonthData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    // if (graphDataServiceInstance.weatherDataMonthLastFetch.month === queryDate.month() && graphDataServiceInstance.weatherDataMonthLastFetch.year === queryDate.year() && (Date.now() - graphDataServiceInstance.lastWeatherDataMonthTime <= 5 * 60 * 1000)) { return; }
    return graphDataServiceInstance.fetchWeatherMonthData(date, ++graphDataServiceInstance.weatherDataMonthLock).then(([data, lockNum]) => graphDataServiceInstance.deriveWeatherMonthData(date, data, lockNum));
  }

  deriveWeatherMonthData(date: moment.Moment, entranceExitMonthDatas: IFetchData<WeatherData>[], lockNum: number) {
    const queryDate = date.clone();
    const weatherMonthData: { [date: string]: number } = {};
    for (const momentIt = date.clone().startOf('month'); momentIt <= date.clone().endOf('month'); momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitMonthDatas, momentIt, ViewPeriod.DAYS)[0];
      if (!dataIt || !dataIt.data) {
        weatherMonthData[momentIt.date()] = 116;
        continue;
      } else {
        const entranceExitData = dataIt.data;
        // hour selected
        const selectedHour = this.configDataService.isFeatureEnabled('graph_data', 'weather_hour') || 5;
        weatherMonthData[momentIt.date()] = GraphDataService.procesChartData((entranceExitData?.[selectedHour]?.weather_code || 116));
      }
    }
    if (lockNum < this.weatherDataMonthLock) { return; }
    this.weatherDataMonthLastFetch = { month: queryDate.month(), year: queryDate.year() };
    this.lastWeatherDataMonthTime = Date.now();
    this.weatherMonthData$.next(weatherMonthData);
  }

  async fetchWeatherMonthData(date: moment.Moment, lockNum: number) {
    const queryDate = date.clone();
    const currentDate = this.viewPeriodService.isLiveMode ? moment().clone() : moment().clone().subtract(1, 'd');
    const startOfMonth = queryDate.startOf('month');
    let qParams = {
      periodType: 'day',
      start_date: queryDate.format('YYYY-MM-DD'),
      num_interval: queryDate.diff(startOfMonth, 'd'),
    };
    if (currentDate.isBefore(queryDate, 'M')) {
      qParams = {
        periodType: 'day',
        start_date: queryDate.endOf('month').format('YYYY-MM-DD'),
        num_interval: queryDate.daysInMonth(),
      };
    }
    const requestURL = `/retail_customer_api_v2/api/v2/info/weather?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(requestURL);
    return [fetchObj, lockNum] as [IFetchData<WeatherData>[], number];
  }
  //#endregion weather month

  //#region entrance-exit-by-hour
  async loadEntranceExitByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('mock_data', 'building_entrance_exit_by_hour')) {
      graphDataServiceInstance.deriveEntranceExitByHourMockData(date);
      return;
    } else {
      return graphDataServiceInstance.fetchEntranceExitByHourData(date, ++graphDataServiceInstance.entranceExitByHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitByHourData(data, lockNum));
    }
  }

  async deriveEntranceExitByHourMockData(date: moment.Moment) {
    const num_interval = 1;
    const buildingEntranceExitByHourData = await generateNestedData(date, this.viewPeriodService, this.configDataService, 'BUILDING_TRAFFIC_BY_HOUR', 'traffic', num_interval);
    const buildingEntranceByHour: { [buildingName: string]: number[] } = {};
    const busiestTimeByEntranceList: { [buildingName: string]: { headcount: number; hour: string } } = {};
    const entranceByHour: number[] = [];
    const exitByHour: number[] = [];
    for (const [buildingName, buildingHourData] of Object.entries(buildingEntranceExitByHourData)) {
      buildingEntranceByHour[buildingName] = [];
      busiestTimeByEntranceList[buildingName] = { headcount: 0, hour: '10 AM' };
      for (const [hourKey, hourEntranceExit] of Object.entries(buildingHourData)) {
        if (buildingName === this.configDataService.MAIN_BUILDING) {
          entranceByHour.push(hourEntranceExit.entrance);
          exitByHour.push(hourEntranceExit.exit);
        }
        buildingEntranceByHour[buildingName].push(hourEntranceExit.entrance);
        if (busiestTimeByEntranceList[buildingName].headcount < hourEntranceExit.entrance) {
          busiestTimeByEntranceList[buildingName].headcount = GraphDataService.procesChartData(hourEntranceExit.entrance);
          busiestTimeByEntranceList[buildingName].hour = new Date(2020, 1, 1, parseInt(hourKey, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
        }
      }
    }
    this.entranceByHour$.next(entranceByHour);
    this.exitByHour$.next(exitByHour);
    this.buildingEntranceByHour$.next(buildingEntranceByHour);
    this.buildingAreaBusiestTime$.next(busiestTimeByEntranceList);
  }

  async deriveEntranceExitByHourData(entranceExitByHourDatas: IFetchData<EntranceExitByHourData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    const accumulateList: { [buildingName: string]: number[] } = {};
    const buildingEntranceList: { [buildingName: string]: number[] } = {};
    const entraceList: number[] = Array.of(this.configDataService.TIME_LIST.length).map(_ => 0);
    const exitList: number[] = Array.of(this.configDataService.TIME_LIST.length).map(_ => 0);
    const busiestTime = { headcount: 0, hour: '10 AM', };
    const busiestTimeByEntranceList: { [buildingName: string]: { headcount: number; hour: string } } = {};
    const busiestTimeByAccumulateList: { [buildingName: string]: { headcount: number; hour: string } } = {};
    const busiestTimeByEntrance = { headcount: 0, hour: '10 AM', };
    const timeVisitHeatmapEntranceData: number[][] = [];
    const timeVisitHeatmapExitData: number[][] = [];
    let currentAccumulateHeadcountData: { [buildingName: string]: number } = {};
    let currentBuildingEntranceByHourData: { [buildingName: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(entranceExitByHourDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        timeVisitHeatmapEntranceData.push(Array(this.configDataService.TIME_LIST.length).fill(fillValue));
        timeVisitHeatmapExitData.push(Array(this.configDataService.TIME_LIST.length).fill(fillValue));
        return;
      }
      const entranceExitByHourData = dataFiltered.data;
      const timeVisitHeatmapEntranceDataIt: number[] = [];
      const timeVisitHeatmapExitDataIt: number[] = [];
      this.configDataService.TIME_LIST.map(time => {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
        const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
        const pushData = entranceExitByHourData?.[mainBuilding]?.[timeKey] ? entranceExitByHourData?.[mainBuilding]?.[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue } as EntranceExitAccumulate;
        timeVisitHeatmapEntranceDataIt.push(pushData.entrance);
        timeVisitHeatmapExitDataIt.push(pushData.exit);
      });
      timeVisitHeatmapEntranceData.push(GraphDataService.procesChartData(timeVisitHeatmapEntranceDataIt));
      timeVisitHeatmapExitData.push(GraphDataService.procesChartData(timeVisitHeatmapExitDataIt));
      if (diffToSelectedDate === 0) {
        currentAccumulateHeadcountData = Object.entries(entranceExitByHourData).reduce((prev, [buildingName, buildingData]) => {
          prev[buildingName] = GraphDataService.procesChartData(buildingData._total.accumulate);
          return prev;
        }, {});
        currentBuildingEntranceByHourData = Object.entries(entranceExitByHourData).reduce((prev, [buildingName, buildingData]) => {
          prev[buildingName] = GraphDataService.procesChartData(buildingData._total.entrance);
          return prev;
        }, {});
        for (const buildingName of Object.keys(entranceExitByHourData)) {
          accumulateList[buildingName] = [];
          buildingEntranceList[buildingName] = [];
          busiestTimeByEntranceList[buildingName] = { headcount: 0, hour: '10 AM' };
          busiestTimeByAccumulateList[buildingName] = { headcount: 0, hour: '10 AM' };
          this.configDataService.TIME_LIST.map((time, idx) => {
            // const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
            // This section refers to the use of entrance data instead accumulate data.
            const timeKey = this.configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance') ? parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC : parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
            const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
            if (buildingName === mainBuilding) {
              const pushData = entranceExitByHourData?.[mainBuilding]?.[timeKey] ? entranceExitByHourData?.[mainBuilding]?.[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue };
              entraceList.push(GraphDataService.procesChartData(pushData.entrance));
              exitList.push(GraphDataService.procesChartData(pushData.exit));
              buildingEntranceList[mainBuilding].push(GraphDataService.procesChartData(pushData.entrance));
              accumulateList[mainBuilding].push(GraphDataService.procesChartData(pushData.accumulate));
              if (busiestTime.headcount < pushData.accumulate) {
                busiestTime.headcount = GraphDataService.procesChartData(pushData.accumulate);
                busiestTime.hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
              if (busiestTimeByEntrance.headcount < pushData.entrance) {
                busiestTimeByEntrance.headcount = GraphDataService.procesChartData(pushData.entrance);
                busiestTimeByEntrance.hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
              if (busiestTimeByEntranceList[mainBuilding].headcount < pushData.entrance) {
                busiestTimeByEntranceList[mainBuilding].headcount = GraphDataService.procesChartData(pushData.entrance);
                busiestTimeByEntranceList[mainBuilding].hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
              if (busiestTimeByAccumulateList[mainBuilding].headcount < pushData.accumulate) {
                busiestTimeByAccumulateList[mainBuilding].headcount = GraphDataService.procesChartData(pushData.accumulate);
                busiestTimeByAccumulateList[mainBuilding].hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
            } else {
              const pushData = entranceExitByHourData?.[buildingName]?.[timeKey] ? entranceExitByHourData?.[buildingName]?.[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue };
              accumulateList[buildingName].push(GraphDataService.procesChartData(pushData.accumulate));
              buildingEntranceList[buildingName].push(GraphDataService.procesChartData(pushData.entrance));
              if (busiestTimeByAccumulateList[buildingName].headcount < pushData.accumulate) {
                busiestTimeByAccumulateList[buildingName].headcount = GraphDataService.procesChartData(pushData.accumulate);
                busiestTimeByAccumulateList[buildingName].hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
              if (busiestTimeByEntranceList[buildingName].headcount < pushData.entrance) {
                busiestTimeByEntranceList[buildingName].headcount = GraphDataService.procesChartData(pushData.entrance);
                busiestTimeByEntranceList[buildingName].hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
            }
          });
        }
      }
    });
    if (lockNum < this.entranceExitByHourLock) { return; }
    this.accumulateHeadcountByHour$.next(accumulateList);
    this.buildingEntranceByHour$.next(buildingEntranceList);
    // if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.ENTRANCE_EXIT_HOUR).value) {
    //   this.buildingEntranceByHourAvgPerDay$.next(buildingEntranceList);
    // } else {
    //   this.buildingEntranceByHour$.next(buildingEntranceList);
    // }
    this.entranceByHour$.next(entraceList);
    this.exitByHour$.next(exitList);
    if (this.configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance')) {
      this.busiestTime$.next(busiestTimeByEntrance);
      this.buildingAreaBusiestTime = busiestTimeByEntranceList;
      this.buildingAreaBusiestTime$.next(this.buildingAreaBusiestTime);
      // if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.ENTRANCE_EXIT_HOUR).value) {
      //   this.buildingAreaBusiestTimeAvgPerDay = busiestTimeByEntranceList;
      //   this.buildingAreaBusiestTime$.next(this.buildingAreaBusiestTimeAvgPerDay);
      // } else {
      //   this.buildingAreaBusiestTime = busiestTimeByEntranceList;
      //   this.buildingAreaBusiestTime$.next(this.buildingAreaBusiestTime);
      // }
    } else {
      this.buildingAreaBusiestTime$.next(busiestTimeByAccumulateList);
      this.busiestTime$.next(busiestTime);
    }
    this.timeVisitHeatmapEntranceData$.next(timeVisitHeatmapEntranceData);
    this.timeVisitHeatmapExitData$.next(timeVisitHeatmapExitData);
    this.currentAccumulateHeadcountData$.next(currentAccumulateHeadcountData);
    this.currentBuildingEntranceByHour$.next(currentBuildingEntranceByHourData);
  }

  async fetchEntranceExitByHourData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.ENTRANCE_EXIT_HOUR).value) {
      const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
      if (agg_type !== undefined) {
        fetchURL += `&aggregation_type=${agg_type}`;
      }
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByHourData>[], number];
  }
  //#endregion entrance-exit-by-hour

  // TO-DO: Find the way to extract between card and graph data
  //#region entrance-exit-by-hour number-card
  async loadEntranceExitByHourNumberCardData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitByHourNumberCardData(date, ++graphDataServiceInstance.entranceExitByHourNumberCardLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitByHourNumberCardData(data, lockNum));
  }

  async deriveEntranceExitByHourNumberCardData(entranceExitByHourDatas: IFetchData<EntranceExitByHourData>[], lockNum: number) {
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    const busiestTime = { headcount: 0, hour: '10 AM', };
    const busiestTimeByEntranceList: { [buildingName: string]: { headcount: number; hour: string } } = {};
    const buildingEntranceList: { [buildingName: string]: number[] } = {};
    const buildingExitList: { [buildingName: string]: number[] } = {};
    const busiestTimeByEntrance = { headcount: 0, hour: '10 AM', };
    const buildingAreaEntranceExitBreakdownByHour: { [buildingName: string]: { entrance: number[]; exit: number[] } } = {};

    GraphDataService.mapSevenDayLineChartData(entranceExitByHourDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const entranceExitByHourData = dataFiltered.data;
      const timeVisitHeatmapEntranceDataIt: number[] = [];
      const timeVisitHeatmapExitDataIt: number[] = [];
      this.configDataService.TIME_LIST.map(time => {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
        const fillValue = 0;
        const pushData = entranceExitByHourData?.[mainBuilding]?.[timeKey] ? entranceExitByHourData?.[mainBuilding]?.[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue } as EntranceExitAccumulate;
        timeVisitHeatmapEntranceDataIt.push(pushData.entrance);
        timeVisitHeatmapExitDataIt.push(pushData.exit);
      });
      if (diffToSelectedDate === 0) {
        for (const buildingName of Object.keys(entranceExitByHourData)) {
          buildingAreaEntranceExitBreakdownByHour[buildingName] = { entrance: [], exit: [] };
          buildingEntranceList[buildingName] = [];
          buildingExitList[buildingName] = [];
          busiestTimeByEntranceList[buildingName] = { headcount: 0, hour: '10 AM' };
          this.configDataService.TIME_LIST.map(time => {
            // const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
            // This section refers to the use of entrance data instead accumulate data.
            const timeKey = this.configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance') ? parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC : parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
            const fillValue = isLiveFillZero || !this.viewPeriodService.isLiveMode ? 0 : null;
            if (buildingName === mainBuilding) {
              const pushData = entranceExitByHourData?.[mainBuilding]?.[timeKey] ? entranceExitByHourData?.[mainBuilding]?.[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue };
              buildingEntranceList[mainBuilding].push(GraphDataService.procesChartData(pushData.entrance));
              buildingExitList[mainBuilding].push(GraphDataService.procesChartData(pushData.exit));
              buildingAreaEntranceExitBreakdownByHour[mainBuilding].entrance.push(GraphDataService.procesChartData(pushData.entrance));
              buildingAreaEntranceExitBreakdownByHour[mainBuilding].exit.push(GraphDataService.procesChartData(pushData.exit));
              if (busiestTime.headcount < pushData.accumulate) {
                busiestTime.headcount = GraphDataService.procesChartData(pushData.accumulate);
                busiestTime.hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
              if (busiestTimeByEntrance.headcount < pushData.entrance) {
                busiestTimeByEntrance.headcount = GraphDataService.procesChartData(pushData.entrance);
                busiestTimeByEntrance.hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
              if (busiestTimeByEntranceList[mainBuilding].headcount < pushData.entrance) {
                busiestTimeByEntranceList[mainBuilding].headcount = GraphDataService.procesChartData(pushData.entrance);
                busiestTimeByEntranceList[mainBuilding].hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
            } else {
              const pushData = entranceExitByHourData?.[buildingName]?.[timeKey] ? entranceExitByHourData?.[buildingName]?.[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue };
              buildingEntranceList[buildingName].push(GraphDataService.procesChartData(pushData.entrance));
              buildingExitList[buildingName].push(GraphDataService.procesChartData(pushData.exit));
              buildingAreaEntranceExitBreakdownByHour[buildingName].entrance.push(GraphDataService.procesChartData(pushData.entrance));
              buildingAreaEntranceExitBreakdownByHour[buildingName].exit.push(GraphDataService.procesChartData(pushData.exit));
              if (busiestTimeByEntranceList[buildingName].headcount < pushData.entrance) {
                busiestTimeByEntranceList[buildingName].headcount = GraphDataService.procesChartData(pushData.entrance);
                busiestTimeByEntranceList[buildingName].hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
              }
            }
          });
        }
      }
    });
    if (lockNum < this.entranceExitByHourNumberCardLock) { return; }
    this.buildingEntranceByHourAvgPerDay$.next(buildingEntranceList);
    this.buildingEntranceExitByHourAvgPerDay$.next(buildingAreaEntranceExitBreakdownByHour);
    this.buildingAvgBusiestTime$.next(busiestTimeByEntranceList);
  }

  async fetchEntranceExitByHourNumberCardData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&aggregation_type=divide_by_day`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByHourData>[], number];
  }
  //#endregion entrance-exit-by-hour

  //#region floor/building-floor-entrance-exit-by-hour
  async loadEntranceExitFloorHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitFloorHourData(date, ++graphDataServiceInstance.entranceExitFloorHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitFloorHourData(data, lockNum));
  }

  async deriveEntranceExitFloorHourData(entranceExitFloorHourDatas: IFetchData<EntranceExitByFloorByHourData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(entranceExitFloorHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    const currentEntranceExitFloorData: { [buildingName: string]: { [floorName: string]: number } } = {};
    const currentEntranceFloorByHourData: { [buildingName: string]: { [floorName: string]: number } } = {};
    const floorAccumulateHeadcountByHour: { [buildingName: string]: { [floorName: string]: number[] } } = {};
    const floorEntranceByHour: { [buildingName: string]: { [floorName: string]: number[] } } = {};
    if (!dataIt || !dataIt.data) {
      this.currentFloorHeadcountData$.next(currentEntranceExitFloorData);
      this.currentFloorEntranceByHour$.next(currentEntranceFloorByHourData);
      this.floorAccumulateHeadcountByHour$.next(floorAccumulateHeadcountByHour);
      this.floorEntranceByHour$.next(floorEntranceByHour);
      return;
    }
    const entranceExitFloorHourData = dataIt.data;
    Object.entries(entranceExitFloorHourData).forEach(([buildingName, buildingData]) => {
      currentEntranceExitFloorData[buildingName] = {};
      currentEntranceFloorByHourData[buildingName] = {};
      floorAccumulateHeadcountByHour[buildingName] = {};
      floorEntranceByHour[buildingName] = {};
      Object.entries(buildingData).forEach(([floorName, floorData]) => {
        currentEntranceExitFloorData[buildingName][floorName] = GraphDataService.procesChartData(floorData._total.accumulate);
        currentEntranceFloorByHourData[buildingName][floorName] = GraphDataService.procesChartData(floorData._total.entrance);
        floorAccumulateHeadcountByHour[buildingName][floorName] = [];
        floorEntranceByHour[buildingName][floorName] = [];
        this.configDataService.TIME_LIST.map(time => {
          const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
          const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
          const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
          const pushData = floorData[timeKey] ? floorData[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue } as EntranceExitAccumulate;
          floorAccumulateHeadcountByHour[buildingName][floorName].push(GraphDataService.procesChartData(pushData.accumulate));
          floorEntranceByHour[buildingName][floorName].push(GraphDataService.procesChartData(pushData.entrance));
        });
      });
    });
    if (lockNum < this.entranceExitFloorHourLock) { return; }
    this.currentFloorHeadcountData$.next(currentEntranceFloorByHourData);
    this.currentFloorEntranceByHour$.next(currentEntranceExitFloorData);
    this.floorAccumulateHeadcountByHour$.next(floorAccumulateHeadcountByHour);
    this.floorEntranceByHour$.next(floorEntranceByHour);
  }

  async fetchEntranceExitFloorHourData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/floor/building-floor-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByFloorByHourData>[], number];
  }
  //#endregion floor/building-floor-entrance-exit-by-hour

  //#region zone/entrance-exit-by-hour
  async loadEntranceExitFloorZoneHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitFloorZoneHourData(date, ++graphDataServiceInstance.entranceExitFloorZoneHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitFloorZoneHourData(data, lockNum));
  }

  async deriveEntranceExitFloorZoneHourData(entranceExitFloorZoneHourDatas: IFetchData<EntranceExitByFloorByZoneByHourData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(entranceExitFloorZoneHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    const currentZoneHeadcountData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number } } } = {};
    const currentZoneEntranceByHourData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number } } } = {};
    const zoneAccumulateHeadcountByHour: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } } = {};
    const zoneEntranceByHour: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } } = {};
    if (!dataIt || !dataIt.data) {
      this.currentZoneHeadcountData$.next(currentZoneHeadcountData);
      this.currentZoneEntranceByHour$.next(currentZoneEntranceByHourData);
      this.zoneAccumulateHeadcountByHour$.next(zoneAccumulateHeadcountByHour);
      this.zoneEntranceByHour$.next(zoneEntranceByHour);
      return;
    }
    const entranceExitFloorZoneHourData = dataIt.data;
    Object.entries(entranceExitFloorZoneHourData).forEach(([buildingName, buildingData]) => {
      currentZoneHeadcountData[buildingName] = {};
      currentZoneEntranceByHourData[buildingName] = {};
      zoneAccumulateHeadcountByHour[buildingName] = {};
      zoneEntranceByHour[buildingName] = {};
      Object.entries(buildingData).forEach(([floorName, floorData]) => {
        currentZoneHeadcountData[buildingName][floorName] = {};
        currentZoneEntranceByHourData[buildingName][floorName] = {};
        zoneAccumulateHeadcountByHour[buildingName][floorName] = {};
        zoneEntranceByHour[buildingName][floorName] = {};
        Object.entries(floorData).forEach(([zoneName, zoneData]) => {
          currentZoneHeadcountData[buildingName][floorName][zoneName] = GraphDataService.procesChartData(zoneData._total.accumulate);
          currentZoneEntranceByHourData[buildingName][floorName][zoneName] = GraphDataService.procesChartData(zoneData._total.entrance);
          zoneAccumulateHeadcountByHour[buildingName][floorName][zoneName] = [];
          zoneEntranceByHour[buildingName][floorName][zoneName] = [];
          this.configDataService.TIME_LIST.map(time => {
            const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
            const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
            const pushData = zoneData[timeKey] ? zoneData[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue } as EntranceExitAccumulate;
            zoneAccumulateHeadcountByHour[buildingName][floorName][zoneName].push(GraphDataService.procesChartData(pushData.accumulate));
            zoneEntranceByHour[buildingName][floorName][zoneName].push(GraphDataService.procesChartData(pushData.entrance));
          });
        });
      });
    });
    if (lockNum < this.entranceExitFloorZoneHourLock) { return; }
    this.currentZoneHeadcountData$.next(currentZoneHeadcountData);
    this.currentZoneEntranceByHour$.next(currentZoneEntranceByHourData);
    this.zoneAccumulateHeadcountByHour$.next(zoneAccumulateHeadcountByHour);
    this.zoneEntranceByHour$.next(zoneEntranceByHour);
  }

  async fetchEntranceExitFloorZoneHourData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByFloorByZoneByHourData>[], number];
  }
  //#endregion zone/entrance-exit-by-hour

  //#region store/entrance-exit-by-hour

  async loadEntranceExitFloorZoneStoreHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    // get store name
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const selectedDate = graphDataServiceInstance.viewPeriodService.selectedDate;
    // const clearedData = Array.from({ length: 7 }).map<number>(() => null);
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_area_breakpoint')) {
      if (graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_area_breakpoint_date')) {
        const storeAreaBreakpointDateString = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_area_breakpoint_date');
        const storeAreaBreakpointDate = moment(storeAreaBreakpointDateString, 'YYYY-MM-DD');
        if (selectedDate.isSameOrAfter(storeAreaBreakpointDate)) {
          ++graphDataServiceInstance.entranceExitFloorZoneStoreHourLock;
          return Promise.resolve();
        }
      }
    }
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.entranceExitFloorZoneStoreHourLock;
      // graphDataServiceInstance.storeVisitorTrafficTrendData$.next({
      //   entrance: clearedData,
      //   exit: clearedData
      // });
      // graphDataServiceInstance.currentStoreVisitorTrafficTrendtEntranceData$.next({ headCount: 0, diff: 0, diffPercent: 0 });
      // graphDataServiceInstance.currentStoreVisitorTrafficTrendtExitData$.next({ headCount: 0, diff: 0, diffPercent: 0 });
      // graphDataServiceInstance.storeVisitorAvgTimespentData$.next(clearedData);
      // graphDataServiceInstance.storeVisitorNetShoppingHourData$.next(clearedData);
      return Promise.resolve();
    }
    // const storeAreaName = selectedInteractable.store.id;
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    return graphDataServiceInstance.fetchEntranceExitFloorZoneStoreHourData(date, ++graphDataServiceInstance.entranceExitFloorZoneStoreHourLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitFloorZoneStoreHourData(data, lockNum, parseSpeicalAreaName));
  }

  async deriveEntranceExitFloorZoneStoreHourData(entranceExitFloorZoneStoreHourDatas: IFetchData<EntranceExitByFloorByZoneByStoreByHourData>[], lockNum: number, storeInstanceName?: string) {
    // await this.configDataService.loadAppConfig();
    const storeAreaEntranceExitBreakdownByHour: { entrance: number[]; exit: number[] } = { entrance: [], exit: [] };
    const storeAreaProximityBreakdownByHour: number[] = [];
    const storeAreaBusiestTime: { hour: string; headcount: number } = { hour: '10 AM', headcount: 0 };
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(entranceExitFloorZoneStoreHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const entranceExitFloorZoneStoreHourData = dataIt.data;
    const currentStoreHeadcountData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: number } } } } = {};
    const storeAccumulateHeadcountByHour: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: number[] } } } } = {};
    const storeEntranceByHour: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [storeName: string]: number[] } } } } = {};
    for (const [buildingName, buildingData] of Object.entries(entranceExitFloorZoneStoreHourData)) {
      currentStoreHeadcountData[buildingName] = {};
      storeAccumulateHeadcountByHour[buildingName] = {};
      storeEntranceByHour[buildingName] = {};
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        currentStoreHeadcountData[buildingName][floorName] = {};
        storeAccumulateHeadcountByHour[buildingName][floorName] = {};
        storeEntranceByHour[buildingName][floorName] = {};
        for (const [zoneName, zoneData] of Object.entries(floorData)) {
          currentStoreHeadcountData[buildingName][floorName][zoneName] = {};
          storeAccumulateHeadcountByHour[buildingName][floorName][zoneName] = {};
          storeEntranceByHour[buildingName][floorName][zoneName] = {};
          for (const [storeName, storeData] of Object.entries(zoneData)) {
            storeAccumulateHeadcountByHour[buildingName][floorName][zoneName][storeName] = [];
            storeEntranceByHour[buildingName][floorName][zoneName][storeName] = [];
            this.configDataService.TIME_LIST.map((time, idx) => {
              const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
              const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
              const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
              const pushData = storeData[timeKey] ? storeData[timeKey] : { entrance: fillValue, exit: fillValue, accumulate: fillValue, proximity: fillValue } as EntranceExitAccumulate;
              storeEntranceByHour[buildingName][floorName][zoneName][storeName].push(GraphDataService.procesChartData(pushData.entrance));
              storeAccumulateHeadcountByHour[buildingName][floorName][zoneName][storeName].push(GraphDataService.procesChartData(pushData.accumulate));
              if (storeName === storeInstanceName && idx > 0) {
                storeAreaEntranceExitBreakdownByHour.entrance.push(GraphDataService.procesChartData(pushData.entrance, false, false));
                storeAreaEntranceExitBreakdownByHour.exit.push(GraphDataService.procesChartData(pushData.exit, false, false));
                storeAreaProximityBreakdownByHour.push(GraphDataService.procesChartData(pushData.proximity, false, false));
                if (storeAreaBusiestTime.headcount < pushData.entrance) {
                  storeAreaBusiestTime.headcount = GraphDataService.procesChartData(pushData.entrance);
                  storeAreaBusiestTime.hour = `${new Date(2020, 1, 1, parseInt(time, 10) - 1).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })} - ${new Date(2020, 1, 1, parseInt(time, 10) + 1).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
                }
              }
            });
          }
        }
      }
    }
    if (lockNum < this.entranceExitFloorZoneStoreHourLock) { return; }
    this.currentStoreHeadcountData$.next(currentStoreHeadcountData);
    this.storeAccumulateHeadcountByHour$.next(storeAccumulateHeadcountByHour);
    this.storeEntranceByHour$.next(storeEntranceByHour);
    // this.storeAreaBusiestTime$.next(storeAreaBusiestTime);  
    // this.storeAreaEntranceExitByHourBreakdown$.next(storeAreaEntranceExitBreakdownByHour);
    // this.storeAreaProximityByHourBreakdown$.next(storeAreaProximityBreakdownByHour);
  }

  async fetchEntranceExitFloorZoneStoreHourData(date: moment.Moment, lockNum: number, storeArea: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${storeArea}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByFloorByZoneByStoreByHourData>[], number];
  }

  //#endregion

  //#region Tourist data
  async loadTouristData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchTouristData(date, ++graphDataServiceInstance.touristLock).then(([data, lockNum]) => graphDataServiceInstance.deriveTouristData(data, lockNum));
  }

  deriveTouristData(touristDatas: IFetchData<TouristData>[], lockNum: number) {
    const touristChartData: number[] = [];
    GraphDataService.mapSevenDayLineChartData(touristDatas, this.viewPeriodService, (dataFiltered, isLivePeriod) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isLivePeriod) {
        const fillValue = isLivePeriod ? null : 0;
        touristChartData.push(fillValue);
        return;
      }
      const touristData = dataFiltered.data;
      touristChartData.push(touristData.count);
    });
    if (lockNum < this.touristLock) { return; }
    this.mallTrafficTouristsChartData$.next(GraphDataService.procesChartData(touristChartData));
  }

  async fetchTouristData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/tourists?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<TouristData>[], number];
  }
  //#endregion Tourist data

  //#region parking customer
  async loadParkingCustomerData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchParkingCustomerData(date, ++graphDataServiceInstance.parkingCustomerLock).then(([data, lockNum]) => graphDataServiceInstance.deriveParkingCustomerData(data, lockNum));
  }

  deriveParkingCustomerData(parkingCustomerDatas: IFetchData<EntranceExitNetShopTimeSpent>[], lockNum: number) {
    const parkingCustomerEntranceExitChartData: { entrance: number[]; exit: number[] } = { entrance: [], exit: [] };
    const parkingCustomerAverageTimeSpentChartData: number[] = [];
    const parkingCustomerNetShoppingEntranceChartData: number[] = [];
    const parkingCustomerNetShoppingExitChartData: number[] = [];
    GraphDataService.mapSevenDayLineChartData(parkingCustomerDatas, this.viewPeriodService, (dataFiltered, isLivePeriod) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isLivePeriod) {
        const fillValue = isLivePeriod ? null : 0;
        parkingCustomerEntranceExitChartData.entrance.push(fillValue);
        parkingCustomerEntranceExitChartData.exit.push(fillValue);
        parkingCustomerAverageTimeSpentChartData.push(fillValue);
        parkingCustomerNetShoppingEntranceChartData.push(fillValue);
        parkingCustomerNetShoppingExitChartData.push(fillValue);
        return;
      }
      const parkingCustomerData = dataFiltered.data;
      parkingCustomerEntranceExitChartData.entrance.push(parkingCustomerData.entrance);
      parkingCustomerEntranceExitChartData.exit.push(parkingCustomerData.exit);
      parkingCustomerAverageTimeSpentChartData.push(parkingCustomerData.average_timespent);
      parkingCustomerNetShoppingEntranceChartData.push(parkingCustomerData.net_shopping_time_entrance);
      parkingCustomerNetShoppingExitChartData.push(parkingCustomerData.net_shopping_time_exit);
    });
    if (lockNum < this.parkingCustomerLock) { return; }
    this.parkingCustomerEntranceExitChartData$.next({
      entrance: GraphDataService.procesChartData(parkingCustomerEntranceExitChartData.entrance),
      exit: GraphDataService.procesChartData(parkingCustomerEntranceExitChartData.exit)
    });
    this.parkingCustomerAverageTimeSpentChartData$.next(GraphDataService.procesChartData(parkingCustomerAverageTimeSpentChartData));
    this.parkingCustomerNetShoppingEntranceChartData$.next(GraphDataService.procesChartData(parkingCustomerNetShoppingEntranceChartData));
    this.parkingCustomerNetShoppingExitChartData$.next(GraphDataService.procesChartData(parkingCustomerNetShoppingExitChartData));
  }

  async fetchParkingCustomerData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/parking-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion parking customer

  //#region visitor-profile
  /** @deprecated use loadVisitorProfileSimpleCrossData or loadVisitorProfileTwoCrossData instead */
  async loadVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVisitorProfileData(date, ++graphDataServiceInstance.visitorProfileLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVisitorProfileData(data, lockNum));
  }

  /** @deprecated use deriveVisitorProfileSimpleCrossData or deriveVisitorProfileTwoCrossData instead */
  async deriveVisitorProfileData(visitorProfileDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const genderProfileData = { male: null as number, female: null as number };
    const ethnicityProfileData = { male: [] as number[], female: [] as number[] };
    const ageProfileData = { male: [] as number[], female: [] as number[] };
    const messengerTimePairData: [number, number] = [0, 0];
    const teenagerTimePairData: [number, number] = [0, 0];
    const staffTimePairData: [number, number] = [0, 0];
    const ageProfileStackData = {};
    const genderProfileStackData = {};
    const ethnicityProfileStackData = {};
    this.unfilteredVisitorProfileData$.next(visitorProfileDatas);
    // await this.configDataService.loadAppConfig();
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        // entranceChartData.push(fillValue);
        return;
      }
      this.configDataService.AGE_CLASS.forEach((age) => {
        ageProfileStackData[age] = 0;
      });
      const visitorProfileData = dataFiltered.data;
      if (visitorProfileData.length < 1) {
        return;
      }
      if (diffToSelectedDate === 0) {
        // const [maleData, femaleData] = visitorProfileData.filter(profile => compare1DepthObjects(profile.group, { gender: 'male' }) || compare1DepthObjects(profile.group, { gender: 'female' })).sort((a, b) => a.group.gender > b.group.gender ? -1 : 1);
        const maleEthnicityProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
        const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
        const femaleEthnicityProfile = { ...maleEthnicityProfile };
        const femaleAgeProfile = { ...maleAgeProfile };
        visitorProfileData.forEach(profile => {
          if (compare1DepthObjects(profile.group, { profession: 'messenger' })) {
            messengerTimePairData[diffToSelectedDate + 1] = profile.count;
          }
          if (compare1DepthObjects(profile.group, { profession: 'staff' })) {
            staffTimePairData[diffToSelectedDate + 1] = profile.count;
          }
          if (compare1DepthObjects(profile.group, { age: 'teenagers' })) {
            teenagerTimePairData[diffToSelectedDate + 1] = profile.count;
          }
          // Gender Profile data
          if (compare1DepthObjects(profile.group, { gender: 'male' })) {
            genderProfileData.male = GraphDataService.procesChartData(profile.count);
          } else if (compare1DepthObjects(profile.group, { gender: 'female' })) {
            genderProfileData.female = GraphDataService.procesChartData(profile.count);
          }
          this.configDataService.GENDER_CLASS.forEach(gender => {
            if (compare1DepthObjects(profile.group, { gender })) {
              genderProfileStackData[gender] = GraphDataService.procesChartData(profile.count, false, true);
            }
          });
          // Ethnicity Profile data
          this.configDataService.ETHNICITY_CLASS.forEach(ethnicity => {
            if (compare1DepthObjects(profile.group, { ethnicity })) {
              if (ethnicity !== 'asian') {
                ethnicityProfileStackData[ethnicity] = GraphDataService.procesChartData(profile.count, false, true);
              }
            }
            if (compare1DepthObjects(profile.group, { gender: 'male', ethnicity })) {
              maleEthnicityProfile[ethnicity] = GraphDataService.procesChartData(profile.count);
            } else if (compare1DepthObjects(profile.group, { gender: 'female', ethnicity })) {
              femaleEthnicityProfile[ethnicity] = GraphDataService.procesChartData(profile.count);
            }
          });
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profile.group, { gender: 'male', age })) {
              maleAgeProfile[age] = GraphDataService.procesChartData(profile.count);
            } else if (compare1DepthObjects(profile.group, { gender: 'female', age })) {
              femaleAgeProfile[age] = GraphDataService.procesChartData(profile.count);
            } else if (compare1DepthObjects(profile.group, { age })) {
              ageProfileStackData[age] = GraphDataService.procesChartData(profile.count, false, false);
            }
          });
        });
        ethnicityProfileData.male = this.configDataService.ETHNICITY_CLASS.map(ethnicity => maleEthnicityProfile[ethnicity]);
        ethnicityProfileData.female = this.configDataService.ETHNICITY_CLASS.map(ethnicity => femaleEthnicityProfile[ethnicity]);
        ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
        ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
        const allAgeProfileStackData = Object.values(ageProfileStackData).reduce((a: number, b: number) => a + b) as number;
        const allGenderProfileStackData = Object.values(genderProfileStackData).reduce((a: number, b: number) => a + b) as number;
        const allEthnicityProfileStackData = Object.values(ethnicityProfileStackData).reduce((a: number, b: number) => a + b) as number;
        Object.entries(ageProfileStackData).forEach(([age, val]) => {
          const calPercecnt = ((val as number) / allAgeProfileStackData) * 100;
          ageProfileStackData[age] = GraphDataService.procesChartData(calPercecnt, false, false);
        });
        Object.entries(genderProfileStackData).forEach(([gender, val]) => {
          const calPercecnt = ((val as number) / allGenderProfileStackData) * 100;
          genderProfileStackData[gender] = GraphDataService.procesChartData(calPercecnt, false, false);
        });
        Object.entries(ethnicityProfileStackData).forEach(([ethnicity, val]) => {
          const calPercecnt = ((val as number) / allEthnicityProfileStackData) * 100;
          ethnicityProfileStackData[ethnicity] = GraphDataService.procesChartData(calPercecnt, false, false);
        });
      }
      if (diffToSelectedDate === -1) {
        visitorProfileData.forEach(prevDateProfile => {
          if (compare1DepthObjects(prevDateProfile.group, { age: 'teenagers' })) {
            teenagerTimePairData[diffToSelectedDate + 1] = prevDateProfile.count;
          }
          if (compare1DepthObjects(prevDateProfile.group, { profession: 'messenger' })) {
            messengerTimePairData[diffToSelectedDate + 1] = prevDateProfile.count;
          }
          if (compare1DepthObjects(prevDateProfile.group, { profession: 'staff' })) {
            staffTimePairData[diffToSelectedDate + 1] = prevDateProfile.count;
          }
        });
      }

    });
    /*const mainBuilding = this.configDataService.MAIN_BUILDING;
    this.areaAgeProfileSampleData[mainBuilding] = ageProfileStackData;
    this.areaGenderProfileSampleData[mainBuilding] = genderProfileStackData;*/
    if (lockNum < this.visitorProfileLock) { return; }
    /*this.ageProfileStackData$.next(ageProfileStackData);
    this.areaAgeProfileSampleData$.next(this.areaAgeProfileSampleData);
    this.areaGenderProfileSampleData$.next(this.areaGenderProfileSampleData);
    this.genderProfileStackData$.next(genderProfileStackData);
    this.ethnicityProfileStackData$.next(ethnicityProfileStackData);
    this.genderProfileData$.next(genderProfileData);
    this.ethnicityProfileData$.next(ethnicityProfileData);
    this.ageProfileData$.next(ageProfileData);
    this.currentStaffProfileData$.next({
      staff: GraphDataService.procesChartData(staffTimePairData[1], false, false),
      diff: GraphDataService.procesChartData(staffTimePairData[1] - staffTimePairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((staffTimePairData[1] - staffTimePairData[0]) / staffTimePairData[0]) * 100, true, true),
    });
    this.prevStaffProfileData$.next({
      staff: GraphDataService.procesChartData(staffTimePairData[0], false, false),
      diff: 0,
      diffPercent: 0,
    });
    this.currentTeenagerProfileData$.next({
      teenager: GraphDataService.procesChartData(teenagerTimePairData[1], false, false),
      diff: GraphDataService.procesChartData((teenagerTimePairData[1] - teenagerTimePairData[0]), true, false)
    });*/
  }

  /** @deprecated use deriveSelectedVisitorProfileSimpleCrossData instead */
  async deriveSelectedVisitorProfileData(visitorProfileDatas: IFetchData<VisitorProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const trafficTrendLineChartData: number[] = [];
    const currentVisitorTrafficTrendtPair: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficTrendLineChartData.push(fillValue);
        return;
      }
      let isFround = false;
      const visitorProfileData = dataFiltered.data;
      for (const profileData of visitorProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFround = true;
          trafficTrendLineChartData.push(profileData.count);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentVisitorTrafficTrendtPair[diffToSelectedDate + 1] = profileData.count;
          }
          break;
        }
      }
      if (!isFround) {
        trafficTrendLineChartData.push(0);
      }
    });
    // if (lockNum < this.entranceExitLock) { return; }
    this.visitorTrafficTrendData$.next(GraphDataService.procesChartData(trafficTrendLineChartData));
    this.currentVisitorTrafficTrendtData$.next({
      headCount: GraphDataService.procesChartData(currentVisitorTrafficTrendtPair[1]),
      diff: GraphDataService.procesChartData(Math.round(currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]), true),
      diffPercent: GraphDataService.procesChartData(((currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]) / currentVisitorTrafficTrendtPair[0] * 100) || 0, true, true)
    });
  }

  /** @deprecated use fetchVisitorProfileSimpleCrossData or fetchVisitorProfileTwoCrossData instead */
  async fetchVisitorProfileData(date: moment.Moment, lockNum: number) {
    const qParams = this.globalUiService.currentPage === 'Home' ? this.getCustomSelectedQueryParameter(date, 2) : this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }
  //#endregion

  //#region store-visitor-profile
  async loadStoreVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' && selectedInteractable?.type !== 'toilet' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeVisitorProfileLock;
      const clearedData = Array.from({ length: 7 }).map<number>(() => null);
      graphDataServiceInstance.storeVisitorTrafficTrendData$.next({
        entrance: clearedData,
        exit: clearedData
      });
      graphDataServiceInstance.currentStoreVisitorTrafficTrendtEntranceData$.next({ headCount: 0, diff: 0, diffPercent: 0 });
      graphDataServiceInstance.currentStoreVisitorTrafficTrendtExitData$.next({ headCount: 0, diff: 0, diffPercent: 0 });
      graphDataServiceInstance.storeVisitorAvgTimespentData$.next(clearedData);
      graphDataServiceInstance.storeVisitorNetShoppingHourData$.next(clearedData);
      graphDataServiceInstance.storeGenderProfileData$.next(null);
      graphDataServiceInstance.storeEthnicityProfileData$.next(null);
      graphDataServiceInstance.storeAgeProfileData$.next(null);
      return Promise.resolve();
    }
    // const storeAreaName = selectedInteractable.store.id;
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    return graphDataServiceInstance.fetchStoreVisitorProfileData(date, ++graphDataServiceInstance.storeVisitorProfileLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreVisitorProfileData(data, lockNum));
  }

  async deriveStoreVisitorProfileData(visitorStoreProfileDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    if (lockNum < this.storeVisitorProfileLock) { return; }
    const customSegmentation = ['local_young_social_strollers', 'local_adult_shoppers', 'tourist'];
    const storeGenderProfileData = { male: null as number, female: null as number };
    const maleCountPairData: [number, number] = [0, 0];
    const femaleCountPairData: [number, number] = [0, 0];
    const storeEthnicityProfileData = { male: [] as number[], female: [] as number[] };
    const storeAgeProfileData = { male: [] as number[], female: [] as number[] };
    const storeAgeProfileDataByNumber = { male: [] as number[], female: [] as number[] };
    let totalProfileVisitorCount = 0;
    const currentStorepurchaseRatePairData: [number, number] = [0, 0];
    const currentStoreTotalVisitor: [number, number] = [0, 0];
    const ethnicityProfileData = { male: [] as number[], female: [] as number[] };
    const maleEthnicityProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
    const femaleEthnicityProfile = { ...maleEthnicityProfile };
    const ethnicityPurchaseProfileData = { purchase: [] as number[], non_purchase: [] as number[] };
    const ethnicityPurchaseProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
    const ethnicityNonPurchaseProfile = { ...ethnicityPurchaseProfile };
    const customSegmentPurchaseProfileData = { purchase: [] as number[], non_purchase: [] as number[] };
    const customSegmentPurchaseProfile = GraphDataService.createObjectComprehension(customSegmentation, 0);
    const customSegmentNonPurchaseProfile = { ...customSegmentPurchaseProfile };
    this.unfilteredStoreVisitorProfileData$.next(visitorStoreProfileDatas);
    // await this.configDataService.loadAppConfig();
    GraphDataService.mapSevenDayLineChartData(visitorStoreProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        // entranceChartData.push(fillValue);
        return;
      }
      const visitorProfileData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        // const [maleData, femaleData] = visitorProfileData.filter(profile => compare1DepthObjects(profile.group, { gender: 'male' }) || compare1DepthObjects(profile.group, { gender: 'female' })).sort((a, b) => a.group.gender > b.group.gender ? -1 : 1);
        const storeMaleEthnicityProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
        const storeMaleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
        const storeMaleAgeProfileByNumber = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
        const storeFemaleEthnicityProfile = { ...storeMaleEthnicityProfile };
        const storeFemaleAgeProfile = { ...storeMaleAgeProfile };
        const storeFemaleAgeProfileByNumber = { ...storeMaleAgeProfileByNumber };
        visitorProfileData.forEach(profile => {
          if (compare1DepthObjects(profile.group, { purchase: true })) {
            currentStorepurchaseRatePairData[diffToSelectedDate + 1] = profile.exit;
          }
          if (compare1DepthObjects(profile.group, {})) {
            currentStoreTotalVisitor[diffToSelectedDate + 1] = profile.exit;
          }
          if (compare1DepthObjects(profile.group, { gender: 'male' })) {
            maleCountPairData[diffToSelectedDate + 1] = profile.exit;
          }
          if (compare1DepthObjects(profile.group, { gender: 'female' })) {
            femaleCountPairData[diffToSelectedDate + 1] = profile.exit;
          }
          if (compare1DepthObjects(profile.group, {})) {
            totalProfileVisitorCount = GraphDataService.procesChartData(profile.exit);
          }
          // Gender Profile data
          if (compare1DepthObjects(profile.group, { gender: 'male' })) {
            storeGenderProfileData.male = GraphDataService.procesChartData((profile.exit / totalProfileVisitorCount) * 100, false, true);
          } else if (compare1DepthObjects(profile.group, { gender: 'female' })) {
            storeGenderProfileData.female = GraphDataService.procesChartData((profile.exit / totalProfileVisitorCount) * 100, false, true);
          }
          // Ethnicity Profile data
          this.configDataService.ETHNICITY_CLASS.forEach(ethnicity => {
            if (compare1DepthObjects(profile.group, { gender: 'male', ethnicity })) {
              storeMaleEthnicityProfile[ethnicity] = GraphDataService.procesChartData((profile.exit / totalProfileVisitorCount) * 100, false, true);
            } else if (compare1DepthObjects(profile.group, { gender: 'female', ethnicity })) {
              storeFemaleEthnicityProfile[ethnicity] = GraphDataService.procesChartData((profile.exit / totalProfileVisitorCount) * 100, false, true);
            }
            if (compare1DepthObjects(profile.group, { purchase: true, ethnicity })) {
              ethnicityPurchaseProfile[ethnicity] = profile.exit;
            }
            if (compare1DepthObjects(profile.group, { purchase: false, ethnicity })) {
              ethnicityNonPurchaseProfile[ethnicity] = profile.exit;
            }
            if (compare1DepthObjects(profile.group, { gender: 'male', ethnicity })) {
              maleEthnicityProfile[ethnicity] = profile.exit;
            }
            if (compare1DepthObjects(profile.group, { gender: 'female', ethnicity })) {
              femaleEthnicityProfile[ethnicity] = profile.exit;
            }
          });
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profile.group, { gender: 'male', age })) {
              storeMaleAgeProfile[age] = GraphDataService.procesChartData((profile.exit / totalProfileVisitorCount) * 100, false, true);
              storeMaleAgeProfileByNumber[age] = GraphDataService.procesChartData(profile.exit, false, true);
            } else if (compare1DepthObjects(profile.group, { gender: 'female', age })) {
              storeFemaleAgeProfile[age] = GraphDataService.procesChartData((profile.exit / totalProfileVisitorCount) * 100, false, true);
              storeFemaleAgeProfileByNumber[age] = GraphDataService.procesChartData(profile.exit, false, true);
            }
          });
          if (compare1DepthObjects(profile.group, { purchase: true, ethnicity: 'asian', age: 'teenagers' })) {
            if (diffToSelectedDate === 0) {
              customSegmentPurchaseProfile.local_young_social_strollers = profile.exit;
            }
          }
          if (compare1DepthObjects(profile.group, { purchase: true, ethnicity: 'asian', age: 'adults' })) {
            if (diffToSelectedDate === 0) {
              customSegmentPurchaseProfile.local_adult_shoppers = profile.exit;
            }
          }
          if (compare1DepthObjects(profile.group, { purchase: true, ethnicity: 'white' })) {
            if (diffToSelectedDate === 0) {
              customSegmentPurchaseProfile.tourist = profile.exit;
            }
          }
          if (compare1DepthObjects(profile.group, { purchase: false, ethnicity: 'asian', age: 'teenagers' })) {
            if (diffToSelectedDate === 0) {
              customSegmentNonPurchaseProfile.local_young_social_strollers = profile.exit;
            }
          }
          if (compare1DepthObjects(profile.group, { purchase: false, ethnicity: 'asian', age: 'adults' })) {
            if (diffToSelectedDate === 0) {
              customSegmentNonPurchaseProfile.local_adult_shoppers = profile.exit;
            }
          }
          if (compare1DepthObjects(profile.group, { purchase: false, ethnicity: 'white' })) {
            if (diffToSelectedDate === 0) {
              customSegmentNonPurchaseProfile.tourist = profile.exit;
            }
          }
        });
        // genderProfileData = { male: GraphDataService.procesChartData(maleData.count), female: GraphDataService.procesChartData(femaleData.count) };
        storeEthnicityProfileData.male = this.configDataService.ETHNICITY_CLASS.map(ethnicity => storeMaleEthnicityProfile[ethnicity]);
        storeEthnicityProfileData.female = this.configDataService.ETHNICITY_CLASS.map(ethnicity => storeFemaleEthnicityProfile[ethnicity]);
        ethnicityProfileData.male = this.configDataService.ETHNICITY_CLASS.map(ethnicity => maleEthnicityProfile[ethnicity]);
        ethnicityProfileData.female = this.configDataService.ETHNICITY_CLASS.map(ethnicity => femaleEthnicityProfile[ethnicity]);
        storeAgeProfileData.male = this.configDataService.AGE_CLASS.map(age => storeMaleAgeProfile[age]);
        storeAgeProfileData.female = this.configDataService.AGE_CLASS.map(age => storeFemaleAgeProfile[age]);
        storeAgeProfileDataByNumber.male = this.configDataService.AGE_CLASS.map(age => storeMaleAgeProfileByNumber[age]);
        storeAgeProfileDataByNumber.female = this.configDataService.AGE_CLASS.map(age => storeFemaleAgeProfileByNumber[age]);
        customSegmentPurchaseProfileData.purchase = customSegmentation.map(segment => processChartData(customSegmentPurchaseProfile[segment] / (customSegmentPurchaseProfile[segment] + customSegmentNonPurchaseProfile[segment]) * 100, false, false));
        customSegmentPurchaseProfileData.non_purchase = customSegmentation.map(segment => processChartData(customSegmentNonPurchaseProfile[segment] / (customSegmentPurchaseProfile[segment] + customSegmentNonPurchaseProfile[segment]) * 100, false, false));
      }
      if (diffToSelectedDate === -1) {
        visitorProfileData.forEach(profile => {
          if (compare1DepthObjects(profile.group, { purchase: true })) {
            currentStorepurchaseRatePairData[diffToSelectedDate + 1] = profile.exit;
          }
          if (compare1DepthObjects(profile.group, {})) {
            currentStoreTotalVisitor[diffToSelectedDate + 1] = profile.exit;
          }
          if (compare1DepthObjects(profile.group, { gender: 'male' })) {
            maleCountPairData[diffToSelectedDate + 1] = profile.exit;
          }
          if (compare1DepthObjects(profile.group, { gender: 'female' })) {
            femaleCountPairData[diffToSelectedDate + 1] = profile.exit;
          }
        });
      }
    });
    const purchasePercentage = [(currentStorepurchaseRatePairData[0] / currentStoreTotalVisitor[0]) * 100, (currentStorepurchaseRatePairData[1] / currentStoreTotalVisitor[1]) * 100];
    this.storePurchaseRateData$.next({
      purchase: GraphDataService.procesChartData(purchasePercentage[1], false, true),
      diff: GraphDataService.procesChartData(purchasePercentage[1] - purchasePercentage[0], true, false),
      diffPercent: GraphDataService.procesChartData(((purchasePercentage[1] - purchasePercentage[0]) / purchasePercentage[0] * 100) || 0, true, true)
    });
    this.storeGenderProfileData$.next(storeGenderProfileData);
    this.storeEthnicityProfileData$.next(storeEthnicityProfileData);
    this.storeAgeProfileData$.next(storeAgeProfileData);
    this.storeAgeProfileDataByNumber$.next(storeAgeProfileDataByNumber);
    const malePercentage = [(maleCountPairData[0] / currentStoreTotalVisitor[0] * 100), (maleCountPairData[1] / currentStoreTotalVisitor[1] * 100)];
    const femalePercentage = [(femaleCountPairData[0] / currentStoreTotalVisitor[0] * 100), (femaleCountPairData[1] / currentStoreTotalVisitor[1] * 100)];
    this.storeCustomSegmentPurchasingBreakdown$.next(customSegmentPurchaseProfileData);
    this.storeEthnicityProfileBreakdown$.next(ethnicityProfileData);
    this.maleTrafficCount.next({
      current: GraphDataService.procesChartData(maleCountPairData[1], false, true),
      diff: GraphDataService.procesChartData(maleCountPairData[1] - maleCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((maleCountPairData[1] - maleCountPairData[0]) / maleCountPairData[0] * 100) || 0, true, true)
    });
    this.femaleTrafficCount.next({
      current: GraphDataService.procesChartData(femaleCountPairData[1], false, true),
      diff: GraphDataService.procesChartData(femaleCountPairData[1] - femaleCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(femaleCountPairData[0] !== 0 ? (((femaleCountPairData[1] - femaleCountPairData[0]) / femaleCountPairData[0]) * 100) : 0, true, true)
    });
  }

  async deriveSelectedStoreVisitorProfileData(visitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const storeTrafficTrendLineChartData = { entrance: Array<number>(), exit: Array<number>() };
    const storeVisitorAvgTimespentData: number[] = [];
    const storeVisitorNetShoppingHourData: number[] = [];
    const currentVisitorTrafficTrendtEntrancePair: [number, number] = [0, 0];
    const currentVisitorTrafficTrendtExitPair: [number, number] = [0, 0];
    const selectedStoreProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        storeTrafficTrendLineChartData.entrance.push(fillValue);
        storeTrafficTrendLineChartData.exit.push(fillValue);
        storeVisitorAvgTimespentData.push(fillValue);
        storeVisitorNetShoppingHourData.push(fillValue);
        return;
      }
      let isFround = false;
      const visitorProfileData = dataFiltered.data;
      for (const profileData of visitorProfileData) {
        if (compare1DepthObjects(profileData.group, selectedStoreProfilefilter)) {
          isFround = true;
          storeTrafficTrendLineChartData.entrance.push(profileData.entrance);
          storeTrafficTrendLineChartData.exit.push(profileData.exit);
          storeVisitorAvgTimespentData.push(profileData.average_timespent);
          storeVisitorNetShoppingHourData.push(profileData.net_shopping_time);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentVisitorTrafficTrendtEntrancePair[diffToSelectedDate + 1] = profileData.entrance;
            currentVisitorTrafficTrendtExitPair[diffToSelectedDate + 1] = profileData.exit;
          }
          break;
        }
      }
      if (!isFround) {
        storeTrafficTrendLineChartData.entrance.push(0);
        storeTrafficTrendLineChartData.exit.push(0);
        storeVisitorAvgTimespentData.push(0);
        storeVisitorNetShoppingHourData.push(0);
      }
    });
    // if (lockNum < this.entranceExitLock) { return; }
    this.storeVisitorTrafficTrendData$.next({
      entrance: GraphDataService.procesChartData(storeTrafficTrendLineChartData.entrance),
      exit: GraphDataService.procesChartData(storeTrafficTrendLineChartData.exit)
    });
    this.currentStoreVisitorTrafficTrendtEntranceData$.next({
      headCount: GraphDataService.procesChartData(currentVisitorTrafficTrendtEntrancePair[1]),
      diff: GraphDataService.procesChartData(Math.round(currentVisitorTrafficTrendtEntrancePair[1] - currentVisitorTrafficTrendtEntrancePair[0]), true),
      diffPercent: GraphDataService.procesChartData(((currentVisitorTrafficTrendtEntrancePair[1] - currentVisitorTrafficTrendtEntrancePair[0]) / currentVisitorTrafficTrendtEntrancePair[0] * 100) || 0, true, true)
    });
    this.currentStoreVisitorTrafficTrendtExitData$.next({
      headCount: GraphDataService.procesChartData(currentVisitorTrafficTrendtExitPair[1]),
      diff: GraphDataService.procesChartData(Math.round(currentVisitorTrafficTrendtExitPair[1] - currentVisitorTrafficTrendtExitPair[0]), true),
      diffPercent: GraphDataService.procesChartData(((currentVisitorTrafficTrendtExitPair[1] - currentVisitorTrafficTrendtExitPair[0]) / currentVisitorTrafficTrendtExitPair[0] * 100) || 0, true, true)
    });
    this.storeVisitorAvgTimespentData$.next(storeVisitorAvgTimespentData);
    this.storeVisitorNetShoppingHourData$.next(storeVisitorNetShoppingHourData);
  }

  async fetchStoreVisitorProfileData(date: moment.Moment, lockNum: number, storeArea: string) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${storeArea}&profile_cross_level=3`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion store-visitor-profile

  //#region store/visitor-profile-by-hour
  async loadStoreVisitorProfileDataByHour(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' && selectedInteractable?.type !== 'toilet' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeVisitorProfileByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    // return graphDataServiceInstance.fetchStoreVisitorProfileDataByHour(date, ++graphDataServiceInstance.storeVisitorProfileByHourLock, parseSpeicalAreaName)
    return graphDataServiceInstance.fetchStoreVisitorProfileDataByHour(date, ++graphDataServiceInstance.storeVisitorProfileByHourLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreVisitorProfileDataByHour(data, lockNum));
  }

  deriveStoreVisitorProfileDataByHour(storeEntranceExitDatasByHour: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const storeGenderProfileDataByHour: { male: number[]; female: number[] } = { male: [], female: [] };
    const staffTrafficCountTwoHour: number[] = [];
    if (lockNum < this.storeVisitorProfileByHourLock) { return; }
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const storeEntranceExitDataByHour = storeEntranceExitDatasByHour.find(k => k.hour === timeKey);
      if (!storeEntranceExitDataByHour) { return; }
      const visitorProfileData = storeEntranceExitDataByHour.data;
      let trafficStaffinclude = false;
      visitorProfileData.forEach((profile) => {
        if (compare1DepthObjects(profile.group, { gender: 'male' })) {
          storeGenderProfileDataByHour.male.push(GraphDataService.procesChartData(profile.exit, false, true));
        } else if (compare1DepthObjects(profile.group, { gender: 'female' })) {
          storeGenderProfileDataByHour.female.push(GraphDataService.procesChartData(profile.exit, false, true));
        }
        if (compare1DepthObjects(profile.group, { profession: 'staff' })) {
          staffTrafficCountTwoHour.push(GraphDataService.procesChartData(profile.exit, false, true));
          trafficStaffinclude = true;
        }
      });
      if (!trafficStaffinclude) {
        staffTrafficCountTwoHour.push(0);
      }
    });
    const reducedArray = [];
    for (let i = 0; i < staffTrafficCountTwoHour.length; i += 2) {
      const sum = staffTrafficCountTwoHour[i] + staffTrafficCountTwoHour[i + 1];
      reducedArray.push(sum);
    }
    this.storeGenderProfileDataByHour$.next(storeGenderProfileDataByHour);
    this.staffTrafficCountTwoHour$.next(reducedArray);
  }

  async fetchStoreVisitorProfileDataByHour(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/visitor-profile-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&profile_cross_level=1`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion store/visitor-profile-by-hour

  //#region customer-segregation
  async loadCustomerSegregationData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchCustomerSegregationData(date, ++graphDataServiceInstance.customerSegregationLock).then(([data, lockNum]) => graphDataServiceInstance.deriveCustomerSegregationData(data, lockNum));
  }

  deriveCustomerSegregationData(customerSegregationDatas: IFetchData<CustomerSegregationData>[], lockNum: number) {
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(customerSegregationDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const customerSegregationData = dataIt.data;

    const segregatedEthinicityData = {
      white_tourist: GraphDataService.procesChartData(customerSegregationData.white_tourists),
      middle_eastern_tourist: GraphDataService.procesChartData(customerSegregationData.middle_eastern_tourists),
      indian_tourist: GraphDataService.procesChartData(customerSegregationData.indian_tourists),
      asian_tourist: GraphDataService.procesChartData(customerSegregationData.asian_tourists),
      other_tourist: GraphDataService.procesChartData(customerSegregationData.black_tourists),
      local_adult_shoppers: GraphDataService.procesChartData(customerSegregationData.local_adult_shoppers),
      local_young_social_strollers: GraphDataService.procesChartData(customerSegregationData.local_young_social_strollers)
    };
    const asianTouristCount = customerSegregationData.asian_tourists;
    const allTouristCount = customerSegregationData.asian_tourists + customerSegregationData.black_tourists + customerSegregationData.indian_tourists +
      customerSegregationData.middle_eastern_tourists + customerSegregationData.white_tourists;
    const nonAsianTouristCount = allTouristCount - asianTouristCount;
    const localCount = customerSegregationData.local_adult_shoppers + customerSegregationData.local_young_social_strollers;
    const localAndTouristData = {
      local: GraphDataService.procesChartData(localCount),
      asian: GraphDataService.procesChartData(asianTouristCount),
      non_asian: GraphDataService.procesChartData(nonAsianTouristCount)
    };
    if (lockNum < this.customerSegregationLock) { return; }
    this.localTouristData$.next(localAndTouristData);
    this.segregatedEthinicityData$.next(segregatedEthinicityData);
  }

  async fetchCustomerSegregationData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/customer-segregation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<CustomerSegregationData>[], number];
  }
  //#endregion customer-segregation

  //#region repeated-visitors
  async loadRepeatedVisitorsData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchRepeatedVisitorsData(date, ++graphDataServiceInstance.repeatedVisitorsLock).then(([data, lockNum]) => graphDataServiceInstance.deriveRepeatedVisitorsData(data, lockNum));
  }

  deriveRepeatedVisitorsData(repeatedVisitorsDatas: IFetchData<RepeatedVisitorData>[], lockNum: number) {
    const repeatedVisitorPairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(repeatedVisitorsDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      const repeatedVisitorsData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        repeatedVisitorPairData[diffToSelectedDate + 1] = repeatedVisitorsData.repeated_percentage;
      }
    });
    if (lockNum < this.repeatedVisitorsLock) { return; }
    this.repeatedVisitorsData$.next({
      repeated_percentage: GraphDataService.procesChartData(repeatedVisitorPairData[1], false, true),
      new_percentage: GraphDataService.procesChartData(1 - repeatedVisitorPairData[1], false, true)
    });
    this.currentRepeatedVisitorsData$.next({
      current: GraphDataService.procesChartData(repeatedVisitorPairData[1] * 100, false, true),
      diff: GraphDataService.procesChartData((repeatedVisitorPairData[1] - repeatedVisitorPairData[0]) * 100, true, true)
    });
  }

  async fetchRepeatedVisitorsData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/repeated-visitors?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<RepeatedVisitorData>[], number];
  }
  //#endregion repeated-visitors

  //#region frequency-of-visit
  async loadFrequencyOfVisitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFrequencyOfVisitData(date, ++graphDataServiceInstance.frequencyOfVisitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFrequencyOfVisitData(data, lockNum));
  }

  deriveFrequencyOfVisitData(frequencyOfVisitDatas: IFetchData<FrequencyOfVisitData>[], lockNum: number) {
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(frequencyOfVisitDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const frequencyOfVisitData = dataIt.data;
    const frequencyOfVisitChartData = { one: 0, two_five: 0, six_ten: 0, eleven_twenty: 0, twenty_up: 0 };

    for (const [timesString, count] of Object.entries(frequencyOfVisitData.frequency_of_visit)) {
      const times = parseInt(timesString, 10);
      if (times === 1) {
        frequencyOfVisitChartData.one = (frequencyOfVisitChartData.one || 0) + count;
      } else if (times >= 2 && times <= 5) {
        frequencyOfVisitChartData.two_five = (frequencyOfVisitChartData.two_five || 0) + count;
      } else if (times >= 6 && times <= 10) {
        frequencyOfVisitChartData.six_ten = (frequencyOfVisitChartData.six_ten || 0) + count;
      } else if (times >= 11 && times <= 20) {
        frequencyOfVisitChartData.eleven_twenty = (frequencyOfVisitChartData.eleven_twenty || 0) + count;
      } else if (times >= 20) {
        frequencyOfVisitChartData.twenty_up = (frequencyOfVisitChartData.twenty_up || 0) + count;
      }
    }
    if (lockNum < this.frequencyOfVisitLock) { return; }
    this.frequencyOfVisitData$.next(frequencyOfVisitChartData);
  }

  async fetchFrequencyOfVisitData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<FrequencyOfVisitData>[], number];
  }
  //#endregion frequency-of-visit

  //#region mode-of-transportation
  async loadModeOfTransportData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchModeOfTransportData(date, ++graphDataServiceInstance.modeOfTransportationLock).then(([data, lockNum]) => graphDataServiceInstance.deriveModeOfTransportData(data, lockNum));
  }

  async deriveModeOfTransportData(modeOfTransDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeData: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBarChartData: { [vehicleTypeName: string]: number } = {};
    const customerTranSportModeData: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const staffTranSportModeData: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const totalTransportModePairData: [number, number] = [0, 0];
    const totalCustomerTransportModePairData: [number, number] = [0, 0];
    const totalStaffTransportModePairData: [number, number] = [0, 0];
    const totalModeofTransportWeekdayLast7Days: number[] = [];
    const totalModeofTransportWeekendLast7Days: number[] = [];
    const prevTotalModeofTransportWeekdayLast7Days: number[] = [];
    const prevTotalModeofTransportWeekendLast7Days: number[] = [];
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      dataFiltered.data.forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName] = [];
        customerTranSportModeData[vehicleTypeName] = [];
        staffTranSportModeData[vehicleTypeName] = [];
      });
    });
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered, _, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        Object.keys(tranSportModeData).map(key => {
          tranSportModeData[key].push(null);
          customerTranSportModeData[key].push(null);
          staffTranSportModeData[key].push(null);
        });
        return;
      }
      const vehicleTypeUsed: string[] = [];
      const currentModeOfTransportVehicleData: { [vehicleTypeName: string]: number; total: number } = { total: 0 };
      (dataFiltered.data as ModeOfTransportData[]).forEach(vehicleTypeData => {
        let vehicleTypeName: string;
        if (!vehicleTypeData.group.vehicle_type && !vehicleTypeData.group.plate_number_definition) {
          vehicleTypeName = 'total';
        } else {
          vehicleTypeName = vehicleTypeData.group.vehicle_type;
        }
        // const vehicleTypeName = !vehicleTypeData.group.vehicle_type && !vehicleTypeData.group.plate_number_definition  ? vehicleTypeData.group.vehicle_type : 'total';
        if ((diffToSelectedDate === -1 || diffToSelectedDate === 0)) {
          if (diffToSelectedDate === 0) {
            currentModeOfTransportVehicleData[vehicleTypeName] = GraphDataService.procesChartData(vehicleTypeData.count);
          }
          if (compare1DepthObjects(vehicleTypeData.group, { plate_number_definition: 'customer' })) {
            totalCustomerTransportModePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleTypeData.count);
          }
          else if (compare1DepthObjects(vehicleTypeData.group, { plate_number_definition: 'staff' })) {
            totalStaffTransportModePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleTypeData.count);
          }
          else if (compare1DepthObjects(vehicleTypeData.group, {})) {
            currentModeOfTransportVehicleData.total = GraphDataService.procesChartData(vehicleTypeData.count);
            totalTransportModePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleTypeData.count);
          }
        }
        if (vehicleTypeData.group?.plate_number_definition) {
          if (compare1DepthObjects(vehicleTypeData.group, { plate_number_definition: 'customer' })) {
            customerTranSportModeData.total.push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
          else if (compare1DepthObjects(vehicleTypeData.group, { vehicle_type: vehicleTypeName, plate_number_definition: 'customer' })) {
            customerTranSportModeData[vehicleTypeName].push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
          else if (compare1DepthObjects(vehicleTypeData.group, { plate_number_definition: 'staff' })) {
            staffTranSportModeData.total.push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
          else if (compare1DepthObjects(vehicleTypeData.group, { vehicle_type: vehicleTypeName, plate_number_definition: 'staff' })) {
            staffTranSportModeData[vehicleTypeName].push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
        } else {
          tranSportModeData[vehicleTypeName].push(GraphDataService.procesChartData(vehicleTypeData.count));
        }
        vehicleTypeUsed.push(vehicleTypeName);
      });
      const sortableCurrentModeOfTransportVehicleData = Object.entries(currentModeOfTransportVehicleData)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      Object.keys(sortableCurrentModeOfTransportVehicleData).filter(key => key !== 'total' && key !== 'undefined').forEach(vehicleClass => {
        const percentage = (currentModeOfTransportVehicleData[vehicleClass] / currentModeOfTransportVehicleData.total) * 100;
        tranSportModeBarChartData[vehicleClass] = GraphDataService.procesChartData(percentage, false, true);
      });
      const fillData = (data: { [vehicleTypeName: string]: number[]; total: number[] }) => {
        Object.keys(data).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          data[noDataType].push(0);
        });
      };
      fillData(tranSportModeData);
      fillData(staffTranSportModeData);
      fillData(customerTranSportModeData);
    });
    modeOfTransDatas.forEach((modeOfTransData, idx) => {
      if (!modeOfTransData.data || Object.keys(modeOfTransData.data).length < 1) {
        return;
      }
      const date = moment(`${modeOfTransData.year}-${modeOfTransData.month}-${modeOfTransData.day}`, 'YYYY-MM-DD');
      const numInWeekDay = date.isoWeekday();
      if (idx >= Math.round(modeOfTransDatas.length / 2)) {
        // last 7 days
        modeOfTransData.data.forEach(vehicleTypeData => {
          const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
          if (vehicleTypeName === 'total') {
            if (numInWeekDay < 6) {
              totalModeofTransportWeekdayLast7Days.push(GraphDataService.procesChartData(vehicleTypeData.count));
            } else {
              totalModeofTransportWeekendLast7Days.push(GraphDataService.procesChartData(vehicleTypeData.count));
            }
          }
        });
      } else {
        // prev 7 days
        modeOfTransData.data.forEach(vehicleTypeData => {
          const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
          if (vehicleTypeName === 'total') {
            if (numInWeekDay < 6) {
              prevTotalModeofTransportWeekdayLast7Days.push(GraphDataService.procesChartData(vehicleTypeData.count));
            } else {
              prevTotalModeofTransportWeekendLast7Days.push(GraphDataService.procesChartData(vehicleTypeData.count));
            }
          }
        });
      }
    });
    if (lockNum < this.modeOfTransportationLock) { return; }
    const avgVehicleTrafficWeekdayLast7days = totalModeofTransportWeekdayLast7Days.reduce(reducer, 0) / totalModeofTransportWeekdayLast7Days.length;
    const avgVehicleTrafficWeekendLast7days = totalModeofTransportWeekendLast7Days.reduce(reducer, 0) / totalModeofTransportWeekendLast7Days.length;
    const prevAvgVehicleTrafficWeekdayLast7days = prevTotalModeofTransportWeekdayLast7Days.reduce(reducer, 0) / prevTotalModeofTransportWeekdayLast7Days.length;
    const prevAvgVehicleTrafficWeekendLast7days = prevTotalModeofTransportWeekendLast7Days.reduce(reducer, 0) / prevTotalModeofTransportWeekendLast7Days.length;
    const diffVehicleTrafficWeekdayLast7days = avgVehicleTrafficWeekdayLast7days - prevAvgVehicleTrafficWeekdayLast7days;
    const diffVehicleTrafficWeekendLast7days = avgVehicleTrafficWeekendLast7days - prevAvgVehicleTrafficWeekendLast7days;
    this.currentModeofTransportBarChartData$.next(tranSportModeBarChartData);
    this.currentTotalTransportationData$.next({
      traffic: GraphDataService.procesChartData(totalTransportModePairData[1], false, false),
      diff: GraphDataService.procesChartData(totalTransportModePairData[1] - totalTransportModePairData[0], true, true),
      diffPercent: GraphDataService.procesChartData(((totalTransportModePairData[1] - totalTransportModePairData[0]) / totalTransportModePairData[0]) * 100, true, true)
    });
    this.currentTotalStaffTransportationData$.next({
      traffic: GraphDataService.procesChartData(totalStaffTransportModePairData[1], false, false),
      diff: GraphDataService.procesChartData(totalStaffTransportModePairData[1] - totalStaffTransportModePairData[0], true, true),
      diffPercent: GraphDataService.procesChartData(((totalStaffTransportModePairData[1] - totalStaffTransportModePairData[0]) / totalStaffTransportModePairData[0]) * 100, true, true)
    });
    this.currentTotalCustomerTransportationData$.next({
      traffic: GraphDataService.procesChartData(totalCustomerTransportModePairData[1], false, false),
      diff: GraphDataService.procesChartData(totalCustomerTransportModePairData[1] - totalCustomerTransportModePairData[0], true, true),
      diffPercent: GraphDataService.procesChartData(((totalCustomerTransportModePairData[1] - totalCustomerTransportModePairData[0]) / totalCustomerTransportModePairData[0]) * 100, true, true)
    });
    this.modeOfTransportationChartData$.next(tranSportModeData);
    this.staffVehicleTrafficChartData$.next(staffTranSportModeData);
    this.customerVehicleTrafficChartData$.next(customerTranSportModeData);
    this.allVehicleTrafficWeekdayLast7daysData$.next({
      traffic: GraphDataService.procesChartData(avgVehicleTrafficWeekdayLast7days, false, false),
      diff: GraphDataService.procesChartData(diffVehicleTrafficWeekdayLast7days, true, true),
      diffPercent: GraphDataService.procesChartData((diffVehicleTrafficWeekdayLast7days / prevAvgVehicleTrafficWeekdayLast7days) * 100, true, true)
    });
    this.allVehicleTrafficWeekendLast7daysData$.next({
      traffic: GraphDataService.procesChartData(avgVehicleTrafficWeekendLast7days, false, false),
      diff: GraphDataService.procesChartData(diffVehicleTrafficWeekendLast7days, true, true),
      diffPercent: GraphDataService.procesChartData((diffVehicleTrafficWeekendLast7days / prevAvgVehicleTrafficWeekendLast7days) * 100, true, true)
    });

  }

  async fetchModeOfTransportData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/mode-of-transportation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion mode-of-transportation

  //#region vehicle-by-province
  async loadVehicleByProvinceData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleByProvinceData(date, ++graphDataServiceInstance.vehicleByProvinceLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleByProvinceData(data, lockNum));
  }

  async deriveVehicleByProvinceData(vehicleByProvinceDatas: IFetchData<VehicleByProvinceData[]>[], lockNum: number) {
    const vehicleByProvinceData: { [province: string]: number[]; total: number[] } = { total: [] };
    const vehicleByProvinceStaffData: { [province: string]: number[]; total: number[] } = { total: [] };
    const vehicleByProvinceCustomerData: { [province: string]: number[]; total: number[] } = { total: [] };
    const currentVehicleByProvinceData: { [province: string]: number; total: number } = { total: 0 };
    GraphDataService.mapSevenDayLineChartData(vehicleByProvinceDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      dataFiltered.data.forEach(vehicleTypeData => {
        const province = vehicleTypeData.group.province ? vehicleTypeData.group.province : 'total';
        vehicleByProvinceData[province] = [];
        vehicleByProvinceStaffData[province] = [];
        vehicleByProvinceCustomerData[province] = [];
        currentVehicleByProvinceData[province] = 0;
      });
    });

    GraphDataService.mapSevenDayLineChartData(vehicleByProvinceDatas, this.viewPeriodService, (dataFiltered, _, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        Object.keys(vehicleByProvinceData).map(key => {
          vehicleByProvinceData[key].push(null);
          vehicleByProvinceStaffData[key].push(null);
          vehicleByProvinceCustomerData[key].push(null);
        });
        return;
      }
      const vehicleProvinceUsed: string[] = [];
      (dataFiltered.data as VehicleByProvinceData[]).forEach(vehicleTypeData => {
        const provinceName = vehicleTypeData.group.province ? vehicleTypeData.group.province : 'total';
        if (vehicleTypeData.group?.plate_number_definition) {
          if (compare1DepthObjects(vehicleTypeData.group, { plate_number_definition: 'customer' })) {
            vehicleByProvinceCustomerData.total.push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
          else if (compare1DepthObjects(vehicleTypeData.group, { province: provinceName, plate_number_definition: 'customer' })) {
            vehicleByProvinceCustomerData[provinceName].push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
          else if (compare1DepthObjects(vehicleTypeData.group, { plate_number_definition: 'staff' })) {
            vehicleByProvinceStaffData.total.push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
          else if (compare1DepthObjects(vehicleTypeData.group, { province: provinceName, plate_number_definition: 'staff' })) {
            vehicleByProvinceStaffData[provinceName].push(GraphDataService.procesChartData(vehicleTypeData.count));
          }
        } else {
          vehicleByProvinceData[provinceName].push(GraphDataService.procesChartData(vehicleTypeData.count));
        }
        vehicleProvinceUsed.push(provinceName);
        if (diffToSelectedDate === 0) {
          currentVehicleByProvinceData[provinceName] = GraphDataService.procesChartData(vehicleTypeData.count);
        }
      });
      // fill 0 for no data
      const fillData = (data: { [vehicleTypeName: string]: number[]; total: number[] }) => {
        Object.keys(data).filter(typeName => !vehicleProvinceUsed.includes(typeName)).forEach(noDataType => {
          data[noDataType].push(0);
        });
      };
      fillData(vehicleByProvinceData);
      fillData(vehicleByProvinceStaffData);
      fillData(vehicleByProvinceCustomerData);
    });
    if (lockNum < this.vehicleByProvinceLock) { return; }
    this.vehicleByProvinceChartData$.next(vehicleByProvinceData);
    this.customerVehicleByProvinceChartData$.next(vehicleByProvinceCustomerData);
    this.currentVehicleByProvinceData$.next(currentVehicleByProvinceData);
  }

  async fetchVehicleByProvinceData(date: moment.Moment, lockNum: number): Promise<[IFetchData<VehicleByProvinceData[]>[], number]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/provinces?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VehicleByProvinceData[]>[], number];
  }
  //#endregion vehicle-by-province

  //#region entrance-exit-by-gate-by-hour
  async loadEntranceExitByFloorByGateByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitByFloorByGateByHourData(date, ++graphDataServiceInstance.entranceExitGateHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitByFloorByGateByHourData(data, lockNum));
  }

  async deriveEntranceExitByFloorByGateByHourData(entranceExitGateHourDatas: IFetchData<EntranceExitByFloorByGateByHourData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(entranceExitGateHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const entranceExitGateHourData = dataIt.data;
    const floorGateEntranceData: { [buildingName: string]: { [floorName: string]: { [gateName: string]: number } } } = {};
    const floorGateExitData: { [buildingName: string]: { [floorName: string]: { [gateName: string]: number } } } = {};
    const entranceByGateByHour: { [buildingName: string]: { [floorName: string]: { [gateName: string]: number[] } } } = {};
    const exitByGateByHour: { [buildingName: string]: { [floorName: string]: { [gateName: string]: number[] } } } = {};

    for (const [buildingName, floorData] of Object.entries(entranceExitGateHourData)) {
      floorGateEntranceData[buildingName] = {};
      floorGateExitData[buildingName] = {};
      entranceByGateByHour[buildingName] = {};
      exitByGateByHour[buildingName] = {};
      for (const [floorName, gatesData] of Object.entries(floorData)) {
        floorGateEntranceData[buildingName][floorName] = {};
        floorGateExitData[buildingName][floorName] = {};
        entranceByGateByHour[buildingName][floorName] = {};
        exitByGateByHour[buildingName][floorName] = {};
        for (const [gateName, gateData] of Object.entries(gatesData)) {
          floorGateEntranceData[buildingName][floorName][gateName] = gateData._total.entrance;
          floorGateExitData[buildingName][floorName][gateName] = gateData._total.exit;
          entranceByGateByHour[buildingName][floorName][gateName] = [];
          exitByGateByHour[buildingName][floorName][gateName] = [];
          this.configDataService.TIME_LIST.map(time => {
            const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
            const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
            const pushData = gateData[timeKey] ? gateData[timeKey] : { entrance: fillValue, exit: fillValue };
            entranceByGateByHour[buildingName][floorName][gateName].push(GraphDataService.procesChartData(pushData.entrance));
            exitByGateByHour[buildingName][floorName][gateName].push(GraphDataService.procesChartData(pushData.exit));
          });
        }
      }
    }
    if (lockNum < this.entranceExitGateHourLock) { return; }
    this.floorGateEntranceData$.next(floorGateEntranceData);
    this.floorGateExitData$.next(floorGateExitData);
    this.entranceByGateByHour$.next(entranceByGateByHour);
    this.exitByGateByHour$.next(exitByGateByHour);
  }

  async fetchEntranceExitByFloorByGateByHourData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/floor/building-floor-entrance-exit-by-gate-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByFloorByGateByHourData>[], number];
  }
  //#endregion entrance-exit-by-gate-by-hour

  //#region purchase-rate
  async loadPurchaseRateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPurchaseRateData(date, ++graphDataServiceInstance.purchaseRateLock).then(([data, lockNum]) => graphDataServiceInstance.derivePurchaseRateData(data, lockNum));
  }

  async derivePurchaseRateData(purchaseRateDatas: IFetchData<PurchaseRateData>[], lockNum: number) {
    const purchaseRateChartData: number[] = [];
    // const currentpurchaseRatePairData: [number, number] = [0, 0];
    // const currentpurchaseRateCountPairData: [number, number] = [0, 0];
    // await this.configDataService.loadAppConfig();
    GraphDataService.mapSevenDayLineChartData(purchaseRateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        purchaseRateChartData.push(fillValue);
        return;
      }
      const purchaseData = dataFiltered.data;
      purchaseRateChartData.push(purchaseData.purchase / purchaseData.count * 100);
      // if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
      //   currentpurchaseRateCountPairData[diffToSelectedDate + 1] = purchaseData.purchase;
      //   currentpurchaseRatePairData[diffToSelectedDate + 1] = purchaseData.purchase / purchaseData.count * 100;
      // }
    });
    if (lockNum < this.purchaseRateLock) { return; }
    // this.purchaseRateChartData$.next(GraphDataService.procesChartData(purchaseRateChartData, false, false));
    // this.currentpurchaseRateData$.next({
    //   purchase: GraphDataService.procesChartData(currentpurchaseRatePairData[1], false, true),
    //   diff: GraphDataService.procesChartData(currentpurchaseRateCountPairData[1] - currentpurchaseRateCountPairData[0], true, false),
    //   diffPercent: GraphDataService.procesChartData(((currentpurchaseRatePairData[1] - currentpurchaseRatePairData[0]) / currentpurchaseRatePairData[0] * 100) || 0, true, true)
    // });
  }

  async fetchPurchaseRateData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/purchase-rate?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<PurchaseRateData>[], number];
  }
  //#endregion purchase-rate

  //#region purchase-rate-by-profile
  async loadPurchaseRateProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('customer_page', 'profile_data_v2')) {
      return;
    }
    if (!graphDataServiceInstance.selectedVisitorProfile$.getValue()) {
      return graphDataServiceInstance.fetchPurchaseRateProfileData(date, ++graphDataServiceInstance.purchaseRateProfileLock).then(([data, lockNum]) => graphDataServiceInstance.derivePurchaseRateProfileData(data, lockNum));
    }
    return graphDataServiceInstance.fetchPurchaseRateProfileData(date, ++graphDataServiceInstance.purchaseRateProfileLock).then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedPurchaseRateProfileData(data, graphDataServiceInstance.selectedStoreVisitorProfile$.getValue()));
  }

  async derivePurchaseRateProfileData(purchaseRateProfileDatas: IFetchData<PurchaseRateProfileData[]>[], lockNum: number) {
    if (lockNum < this.purchaseRateProfileLock) { return; }
    this.unfilteredPurchaseRateProfileData$.next(purchaseRateProfileDatas);
  }

  async deriveSelectedPurchaseRateProfileData(purchaseRateProfileDatas: IFetchData<PurchaseRateProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const purchaseRateProfiletData: number[] = [];
    const currentpurchaseRateProfilePair: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(purchaseRateProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        purchaseRateProfiletData.push(fillValue);
        return;
      }
      let isFround = false;
      const purchaseRateProfileData = dataFiltered.data;
      for (const profileData of purchaseRateProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFround = true;
          purchaseRateProfiletData.push(profileData.purchase / profileData.count * 100);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentpurchaseRateProfilePair[diffToSelectedDate + 1] = profileData.purchase / profileData.count * 100;
          }
          break;
        }
      }
      if (!isFround) {
        purchaseRateProfiletData.push(0);
      }
    });
    // if (lockNum < this.entranceExitLock) { return; }
    this.purchaseRateProfiletData$.next(GraphDataService.procesChartData(purchaseRateProfiletData, false, false));
    this.currentpurchaseRateProfileData$.next({
      purchase: GraphDataService.procesChartData(currentpurchaseRateProfilePair[1], false, true),
      diff: GraphDataService.procesChartData(currentpurchaseRateProfilePair[1] - currentpurchaseRateProfilePair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((currentpurchaseRateProfilePair[1] - currentpurchaseRateProfilePair[0]) / currentpurchaseRateProfilePair[0] * 100) || 0, true, true)
    });
  }

  async fetchPurchaseRateProfileData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/purchase-rate-by-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<PurchaseRateProfileData[]>[], number];
  }
  //#endregion purchase-rate-by-profile

  //#region store-purchase-rate-by-profile
  async loadStorePurchaseRateProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchStorePurchaseRateProfileData(date, ++graphDataServiceInstance.purchaseRateProfileLock).then(([data, lockNum]) => graphDataServiceInstance.deriveStorePurchaseRateProfileData(data, lockNum));
  }

  async deriveStorePurchaseRateProfileData(storePurchaseRateProfileDatas: IFetchData<AreaPurchaseRateProfileData[]>[], lockNum: number) {
    if (lockNum < this.storePurchaseRateProfileLock) { return; }
    this.unfilteredStorePurchaseRateProfileData$.next(storePurchaseRateProfileDatas);
  }

  async deriveSelectedStorePurchaseRateProfileData(storePurchaseRateProfileDatas: IFetchData<AreaPurchaseRateProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const storePurchaseRateProfiletData: number[] = [];
    const currentStorePurchaseRateProfilePair: [number, number] = [0, 0];
    const selectedStoreProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(storePurchaseRateProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        storePurchaseRateProfiletData.push(fillValue);
        return;
      }
      let isFround = false;
      const storePurchaseRateProfileData = dataFiltered.data;
      for (const profileData of storePurchaseRateProfileData) {
        if (compare1DepthObjects(profileData.group, selectedStoreProfilefilter)) {
          isFround = true;
          storePurchaseRateProfiletData.push(profileData.purchase / profileData.count * 100);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentStorePurchaseRateProfilePair[diffToSelectedDate + 1] = profileData.purchase / profileData.count * 100;
          }
          break;
        }
      }
      if (!isFround) {
        storePurchaseRateProfiletData.push(0);
      }
    });
    // if (lockNum < this.entranceExitLock) { return; }
    this.storePurchaseRateProfiletData$.next(GraphDataService.procesChartData(storePurchaseRateProfiletData, false, true));
    this.currentStorePurchaseRateProfileData$.next({
      purchase: GraphDataService.procesChartData(currentStorePurchaseRateProfilePair[1], false, true),
      diff: GraphDataService.procesChartData(currentStorePurchaseRateProfilePair[1] - currentStorePurchaseRateProfilePair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((currentStorePurchaseRateProfilePair[1] - currentStorePurchaseRateProfilePair[0]) / currentStorePurchaseRateProfilePair[0] * 100) || 0, true, true)
    });
  }

  async fetchStorePurchaseRateProfileData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/purchase-rate-by-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaPurchaseRateProfileData[]>[], number];
  }

  //#endregion store-purchase-rate-by-profile

  //#region average-timespent-by-profile
  async loadAverageTimeSpentProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('customer_page', 'profile_data_v2')) {
      return;
    }
    if (!graphDataServiceInstance.selectedVisitorProfile$.getValue()) {
      return graphDataServiceInstance.fetchAverageTimeSpentProfileData(date, ++graphDataServiceInstance.averageTimeSpentProfileLock).then(([data, lockNum]) => graphDataServiceInstance.deriveAverageTimeSpentProfileData(data, lockNum));
    }
    return graphDataServiceInstance.fetchAverageTimeSpentProfileData(date, ++graphDataServiceInstance.averageTimeSpentProfileLock).then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedAverageTimeSpentProfileData(data, graphDataServiceInstance.selectedVisitorProfile$.getValue()));
  }

  async deriveAverageTimeSpentProfileData(averageTimeSpentProfileDatas: IFetchData<AverageTimeSpentProfileData[]>[], lockNum: number) {
    if (lockNum < this.averageTimeSpentProfileLock) { return; }
    this.unfilteredAverageTimeSpentProfileData$.next(averageTimeSpentProfileDatas);
  }

  async deriveSelectedAverageTimeSpentProfileData(averageTimeSpentProfileDatas: IFetchData<AverageTimeSpentProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const averageTimeSpentProfileChartData: number[] = [];
    const currentAverageTimeSpentProfilePair: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(averageTimeSpentProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        averageTimeSpentProfileChartData.push(fillValue);
        return;
      }
      let isFround = false;
      const averageTimeSpentProfileData = dataFiltered.data; // TODO: not VisitorProfileData
      for (const profileData of averageTimeSpentProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFround = true;
          averageTimeSpentProfileChartData.push(profileData.average_timespent);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentAverageTimeSpentProfilePair[diffToSelectedDate + 1] = profileData.average_timespent;
          }
          break;
        }
      }
      if (!isFround) {
        averageTimeSpentProfileChartData.push(0);
      }
    });
    // if (lockNum < this.entranceExitLock) { return; }
    this.averageTimeSpentProfileChartData$.next(GraphDataService.procesChartData(averageTimeSpentProfileChartData, false, true));
    this.currentAverageTimeSpentProfileData$.next({
      avgTimeSpent: GraphDataService.procesChartData(currentAverageTimeSpentProfilePair[1], false, true),
      diff: GraphDataService.procesChartData(currentAverageTimeSpentProfilePair[1] - currentAverageTimeSpentProfilePair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((currentAverageTimeSpentProfilePair[1] - currentAverageTimeSpentProfilePair[0]) / currentAverageTimeSpentProfilePair[0] * 100) || 0, true, true)
    });
  }

  async fetchAverageTimeSpentProfileData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/average-timespent-by-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AverageTimeSpentProfileData[]>[], number];
  }
  //#endregion average-timespent-by-profile

  //#region net-shopping-time-by-profile
  async loadNetShoppingTimeProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('customer_page', 'profile_data_v2')) {
      return;
    }
    if (!graphDataServiceInstance.selectedVisitorProfile$.getValue()) {
      return graphDataServiceInstance.fetchNetShoppingTimeProfileData(date, ++graphDataServiceInstance.netShoppingTimeProfileLock).then(([data, lockNum]) => graphDataServiceInstance.deriveNetShoppingTimeProfileData(data, lockNum));
    }
    return graphDataServiceInstance.fetchNetShoppingTimeProfileData(date, ++graphDataServiceInstance.netShoppingTimeProfileLock).then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedNetShoppingTimeProfileData(data, graphDataServiceInstance.selectedVisitorProfile$.getValue()));
  }

  async deriveNetShoppingTimeProfileData(netShoppingTimeProfileDatas: IFetchData<NetShoppingTimeProfileData[]>[], lockNum: number) {
    if (lockNum < this.netShoppingTimeProfileLock) { return; }
    this.unfilteredNetShoppingTimeProfileData$.next(netShoppingTimeProfileDatas);
  }

  async deriveSelectedNetShoppingTimeProfileData(netShoppingTimeProfileDatas: IFetchData<NetShoppingTimeProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const netShoppingTimeProfileChartData: number[] = [];
    const currentNetShoppingTimeProfilePair: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(netShoppingTimeProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        netShoppingTimeProfileChartData.push(fillValue);
        return;
      }
      let isFround = false;
      const netShoppingTimeProfileData = dataFiltered.data; // TODO: not VisitorProfileData
      for (const profileData of netShoppingTimeProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFround = true;
          netShoppingTimeProfileChartData.push(profileData.net_shopping_time);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentNetShoppingTimeProfilePair[diffToSelectedDate + 1] = profileData.net_shopping_time;
          }
          break;
        }
      }
      if (!isFround) {
        netShoppingTimeProfileChartData.push(0);
      }
    });
    // if (lockNum < this.netShoppingTimeProfileLock) { return; }
    this.netShoppingTimeProfileChartData$.next(GraphDataService.procesChartData(netShoppingTimeProfileChartData, false, false));
    this.currentNetShoppingTimeProfileData$.next({
      netShoppingTime: GraphDataService.procesChartData(currentNetShoppingTimeProfilePair[1], false, true),
      diff: GraphDataService.procesChartData(currentNetShoppingTimeProfilePair[1] - currentNetShoppingTimeProfilePair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((currentNetShoppingTimeProfilePair[1] - currentNetShoppingTimeProfilePair[0]) / currentNetShoppingTimeProfilePair[0] * 100) || 0, true, true)
    });
  }

  async fetchNetShoppingTimeProfileData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/net-shopping-time-by-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<NetShoppingTimeProfileData[]>[], number];
  }
  //#endregion net-shopping-time-by-profile

  //#region car-vehicle-entrance-exit-by-hour
  async loadCarEntranceExitByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchCarEntranceExitByHourData(date, ++graphDataServiceInstance.carEntranceExitByHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveCarEntranceExitByHourData(data, lockNum));
  }

  deriveCarEntranceExitByHourData(carEntranceExitByHourDatas: IFetchData<CarEntranceExitByHourData>[], lockNum: number) {
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(carEntranceExitByHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) {
      return;
    }
    const carEntranceExitByHourData = dataIt.data;
    const parkingLotOccupancyData = GraphDataService.procesChartData(carEntranceExitByHourData._total.accumulate, false, false);
    if (lockNum < this.carEntranceExitByHourLock) { return; }
    this.parkingLotOccupancyData$.next(parkingLotOccupancyData);
  }

  async fetchCarEntranceExitByHourData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/car-vehicle-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<CarEntranceExitByHourData>[], number];
  }
  //#endregion car-vehicle-entrance-exit-by-hour

  //#region traffic-congestion
  async loadTrafficCongestionData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchTrafficCongestionData(date, ++graphDataServiceInstance.trafficCongestionDataLock).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficCongestionData(data, lockNum));
  }

  deriveTrafficCongestionData(trafficCongestionDatas: IFetchData<TrafficCongestionData>[], lockNum: number) {
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(trafficCongestionDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const trafficCongestionData = dataIt.data;
    if (lockNum < this.trafficCongestionDataLock) { return; }
    this.trafficCongestionData$.next({ averageExtraTravelTime: GraphDataService.procesChartData(trafficCongestionData.averageExtraTravelTime, false, true) });
  }

  async fetchTrafficCongestionData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/info/traffic-congestion?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<TrafficCongestionData>[], number];
  }
  //#endregion traffic-congestion

  //#region building/entrance-exit-flow
  async loadBuildingEntranceExitFlowData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingEntranceExitFlowData(date, ++graphDataServiceInstance.buildingEntranceExitFlowLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingEntranceExitFlowData(data, lockNum));
  }

  deriveBuildingEntranceExitFlowData(buildingEntranceExitFlowDatas: IFetchData<BuildingEntranceExitFlowData>[], lockNum: number) {
    const buildingEntranceExitFlowData: { [buildingName: string]: { entrance: { [name: string]: number } & { _total: number }; exit: { [name: string]: number } & { _total: number } } } = {};
    const buildingFlowEntranceExitData: { [buildingName: string]: { [name: string]: { entrance: number; exit: number } } } = {};
    const buildingFlowEntranceExitPercentageData: { [buildingName: string]: { [name: string]: { entrance: number; exit: number } } } = {};
    const buildingFlowEntranceExitTrendData: { [buildingName: string]: { [name: string]: { entrance: number[]; exit: number[] } } } = {};
    const exlcudeList: string[] = this.configDataService.isFeatureEnabled('graph_data', 'building_flow_data')?.exclude_area || [];
    const sumBuildingFlowEntranceExit: { [buildingName: string]: { entrance: number; exit: number } } = {};
    GraphDataService.mapSevenDayLineChartData(buildingEntranceExitFlowDatas, this.viewPeriodService, (dataFiltered, _isLive, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      const dataIt = dataFiltered.data;
      Object.entries(dataIt).forEach(([buildingName, buildingData]) => {
        buildingEntranceExitFlowData[buildingName] = { entrance: { _total: 0 }, exit: { _total: 0 } };
        buildingFlowEntranceExitTrendData[buildingName] = {};
        buildingFlowEntranceExitData[buildingName] = {};
        (['entrance', 'exit'] as ('entrance' | 'exit')[]).forEach(channel => {
          Object.entries(buildingData[channel]).forEach(([name, _flowData]) => {
            if (name !== '_total' && !exlcudeList.includes(name)) {
              buildingFlowEntranceExitTrendData[buildingName][name] = { entrance: [], exit: [] };
              buildingFlowEntranceExitData[buildingName][name] = { entrance: null, exit: null };
              sumBuildingFlowEntranceExit[buildingName] = { entrance: 0, exit: 0 };
            }
          });
        });
      });
    });
    GraphDataService.mapSevenDayLineChartData(buildingEntranceExitFlowDatas, this.viewPeriodService, (dataFiltered, _isLive, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || Object.keys(dataFiltered.data).length === 0 || diffToSelectedDate > 0) {
        const fillValue = diffToSelectedDate > 0 ? null : 0;
        Object.entries(buildingFlowEntranceExitTrendData).forEach(([buildingName, buildingData]) => {
          Object.entries(buildingData).forEach(([name, _flowData]) => {
            buildingFlowEntranceExitTrendData[buildingName][name].entrance.push(GraphDataService.procesChartData(fillValue));
            buildingFlowEntranceExitTrendData[buildingName][name].exit.push(GraphDataService.procesChartData(fillValue));
          });
        });
        return;
      }
      const dataIt = dataFiltered.data;
      Object.entries(dataIt).forEach(([buildingName, buildingData]) => {
        buildingEntranceExitFlowData[buildingName] = { entrance: { _total: 0 }, exit: { _total: 0 } };
        sumBuildingFlowEntranceExit[buildingName] = { entrance: 0, exit: 0 };
        (['entrance', 'exit'] as ('entrance' | 'exit')[]).forEach(channel => {
          Object.entries(buildingData[channel]).forEach(([name, flowData]) => {
            if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
              buildingEntranceExitFlowData[buildingName][channel][name] = GraphDataService.procesChartData(flowData.count);
              if (name !== '_total' && !exlcudeList.includes(name) && (name in buildingFlowEntranceExitData[buildingName])) {
                sumBuildingFlowEntranceExit[buildingName][channel] += GraphDataService.procesChartData(flowData.count);
                buildingFlowEntranceExitData[buildingName][name][channel] = GraphDataService.procesChartData(flowData.count);
              }
            }
            if (name !== '_total' && !exlcudeList.includes(name) && (name in buildingFlowEntranceExitTrendData[buildingName])) {
              buildingFlowEntranceExitTrendData[buildingName][name][channel].push(GraphDataService.procesChartData(flowData.count));
            }
          });
        });
      });
    });
    for (const [buildingName, buildingData] of Object.entries(buildingFlowEntranceExitData)) {
      buildingFlowEntranceExitPercentageData[buildingName] = {};
      for (const [flowName, flowData] of Object.entries(buildingData)) {
        buildingFlowEntranceExitPercentageData[buildingName][flowName] = { entrance: GraphDataService.procesChartData((flowData.entrance / sumBuildingFlowEntranceExit[buildingName].entrance) * 100, false, true), exit: GraphDataService.procesChartData((flowData.exit / sumBuildingFlowEntranceExit[buildingName].exit) * 100, false, true) };
      }
    }
    if (lockNum < this.buildingEntranceExitFlowLock) { return; }
    this.buildingFlowEntranceExitData$.next(buildingFlowEntranceExitPercentageData);
    this.buildingFlowEntranceExitRawData$.next(buildingFlowEntranceExitData);
    this.buildingFlowEntranceExitTrendData$.next(buildingFlowEntranceExitTrendData);
    this.buildingEntranceExitFlowData$.next(buildingEntranceExitFlowData);
  }

  async fetchBuildingEntranceExitFlowData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit-flow?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING).value) {
      const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
      if (agg_type !== undefined) {
        fetchURL += `&aggregation_type=${agg_type}`;
      }
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitFlowData>[], number];
  }
  //#endregion building/entrance-exit-flow

  //#region building/entrance-exit-flow daily average
  async loadBuildingEntranceExitFlowAvgData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingEntranceExitFlowAvgData(date, ++graphDataServiceInstance.buildingEntranceExitFlowAvgLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingEntranceExitFlowAvgData(data, lockNum));
  }

  deriveBuildingEntranceExitFlowAvgData(buildingEntranceExitFlowDatas: IFetchData<BuildingEntranceExitFlowData>[], lockNum: number) {
    const buildingEntranceExitFlowData: { [buildingName: string]: { entrance: { [name: string]: number } & { _total: number }; exit: { [name: string]: number } & { _total: number } } } = {};
    const buildingFlowEntranceExitData: { [buildingName: string]: { [name: string]: { entrance: number; exit: number } } } = {};
    const buildingFlowEntranceExitPercentageData: { [buildingName: string]: { [name: string]: { entrance: number; exit: number } } } = {};
    const buildingFlowEntranceExitTrendData: { [buildingName: string]: { [name: string]: { entrance: number[]; exit: number[] } } } = {};
    const exlcudeList: string[] = this.configDataService.isFeatureEnabled('graph_data', 'building_flow_data')?.exclude_area || [];
    const sumBuildingFlowEntranceExit: { [buildingName: string]: { entrance: number; exit: number } } = {};
    GraphDataService.mapSevenDayLineChartData(buildingEntranceExitFlowDatas, this.viewPeriodService, (dataFiltered, _isLive, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      const dataIt = dataFiltered.data;
      Object.entries(dataIt).forEach(([buildingName, buildingData]) => {
        buildingEntranceExitFlowData[buildingName] = { entrance: { _total: 0 }, exit: { _total: 0 } };
        buildingFlowEntranceExitTrendData[buildingName] = {};
        buildingFlowEntranceExitData[buildingName] = {};
        (['entrance', 'exit'] as ('entrance' | 'exit')[]).forEach(channel => {
          Object.entries(buildingData[channel]).forEach(([name, _flowData]) => {
            if (name !== '_total' && !exlcudeList.includes(name)) {
              buildingFlowEntranceExitTrendData[buildingName][name] = { entrance: [], exit: [] };
              buildingFlowEntranceExitData[buildingName][name] = { entrance: null, exit: null };
              sumBuildingFlowEntranceExit[buildingName] = { entrance: 0, exit: 0 };
            }
          });
        });
      });
    });
    GraphDataService.mapSevenDayLineChartData(buildingEntranceExitFlowDatas, this.viewPeriodService, (dataFiltered, _isLive, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || Object.keys(dataFiltered.data).length === 0 || diffToSelectedDate > 0) {
        const fillValue = diffToSelectedDate > 0 ? null : 0;
        Object.entries(buildingFlowEntranceExitTrendData).forEach(([buildingName, buildingData]) => {
          Object.entries(buildingData).forEach(([name, _flowData]) => {
            buildingFlowEntranceExitTrendData[buildingName][name].entrance.push(GraphDataService.procesChartData(fillValue));
            buildingFlowEntranceExitTrendData[buildingName][name].exit.push(GraphDataService.procesChartData(fillValue));
          });
        });
        return;
      }
      const dataIt = dataFiltered.data;
      Object.entries(dataIt).forEach(([buildingName, buildingData]) => {
        buildingEntranceExitFlowData[buildingName] = { entrance: { _total: 0 }, exit: { _total: 0 } };
        sumBuildingFlowEntranceExit[buildingName] = { entrance: 0, exit: 0 };
        (['entrance', 'exit'] as ('entrance' | 'exit')[]).forEach(channel => {
          Object.entries(buildingData[channel]).forEach(([name, flowData]) => {
            if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
              buildingEntranceExitFlowData[buildingName][channel][name] = GraphDataService.procesChartData(flowData.count);
              if (name !== '_total' && !exlcudeList.includes(name) && (name in buildingFlowEntranceExitData[buildingName])) {
                sumBuildingFlowEntranceExit[buildingName][channel] += GraphDataService.procesChartData(flowData.count);
                buildingFlowEntranceExitData[buildingName][name][channel] = GraphDataService.procesChartData(flowData.count);
              }
            }
            if (name !== '_total' && !exlcudeList.includes(name) && (name in buildingFlowEntranceExitTrendData[buildingName])) {
              buildingFlowEntranceExitTrendData[buildingName][name][channel].push(GraphDataService.procesChartData(flowData.count));
            }
          });
        });
      });
    });
    for (const [buildingName, buildingData] of Object.entries(buildingFlowEntranceExitData)) {
      buildingFlowEntranceExitPercentageData[buildingName] = {};
      for (const [flowName, flowData] of Object.entries(buildingData)) {
        buildingFlowEntranceExitPercentageData[buildingName][flowName] = { entrance: GraphDataService.procesChartData((flowData.entrance / sumBuildingFlowEntranceExit[buildingName].entrance) * 100, false, true), exit: GraphDataService.procesChartData((flowData.exit / sumBuildingFlowEntranceExit[buildingName].exit) * 100, false, true) };
      }
    }
    if (lockNum < this.buildingEntranceExitFlowAvgLock) { return; }
    this.buildingFlowEntranceExitRawAvgData$.next(buildingFlowEntranceExitData);
  }

  async fetchBuildingEntranceExitFlowAvgData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit-flow?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
    if (agg_type !== undefined) {
      fetchURL += `&aggregation_type=${agg_type}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitFlowData>[], number];
  }
  //#endregion building/entrance-exit-flow

  //#region floor/building-floor-entrance-exit-flow
  async loadFloorEntranceExitFlowData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFloorEntranceExitFlowData(date, ++graphDataServiceInstance.floorEntranceExitFlowLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFloorEntranceExitFlowData(data, lockNum));
  }

  deriveFloorEntranceExitFlowData(floorEntranceExitFlowDatas: IFetchData<FloorEntranceExitFlowData>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(floorEntranceExitFlowDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const rawfloorEntranceExitFlowData = dataIt.data;
    const floorEntranceExitFlowData: { [buildingName: string]: { [floorName: string]: { entrance: { [name: string]: number } & { _total: number }; exit: { [name: string]: number } & { _total: number } } } } = {};
    Object.entries(rawfloorEntranceExitFlowData).forEach(([buildingName, buildingData]) => {
      floorEntranceExitFlowData[buildingName] = {};
      Object.entries(buildingData).forEach(([floorName, floorData]) => {
        floorEntranceExitFlowData[buildingName][floorName] = { entrance: { _total: 0 }, exit: { _total: 0 } };
        (['entrance', 'exit'] as ('entrance' | 'exit')[]).forEach(channel => {
          Object.entries(floorData[channel]).forEach(([name, flowData]) => {
            floorEntranceExitFlowData[buildingName][floorName][channel][name] = GraphDataService.procesChartData(flowData.count);
          });
        });
      });
    });
    if (lockNum < this.floorEntranceExitFlowLock) { return; }
    this.floorEntranceExitFlowData$.next(floorEntranceExitFlowData);
  }

  async fetchFloorEntranceExitFlowData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/floor/building-floor-entrance-exit-flow?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<FloorEntranceExitFlowData>[], number];
  }
  //#endregion floor/building-floor-entrance-exit-flow

  //#region zone/entrance-exit-flow
  async loadZoneEntranceExitFlowData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchZoneEntranceExitFlowData(date, ++graphDataServiceInstance.zoneEntranceExitFlowLock).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneEntranceExitFlowData(data, lockNum));
  }

  deriveZoneEntranceExitFlowData(zoneEntranceExitFlowDatas: IFetchData<ZoneEntranceExitFlowData>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(zoneEntranceExitFlowDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const rawZoneEntranceExitFlowData = dataIt.data;
    const zoneEntranceExitFlowData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: { [name: string]: number } & { _total: number }; exit: { [name: string]: number } & { _total: number } } } } } = {};
    Object.entries(rawZoneEntranceExitFlowData).forEach(([buildingName, buildingData]) => {
      zoneEntranceExitFlowData[buildingName] = {};
      Object.entries(buildingData).forEach(([floorName, floorData]) => {
        zoneEntranceExitFlowData[buildingName][floorName] = {};
        Object.entries(floorData).forEach(([zoneName, zoneData]) => {
          zoneEntranceExitFlowData[buildingName][floorName][zoneName] = { entrance: { _total: 0 }, exit: { _total: 0 } };
          (['entrance', 'exit'] as ('entrance' | 'exit')[]).forEach(channel => {
            Object.entries(zoneData[channel]).forEach(([name, flowData]) => {
              zoneEntranceExitFlowData[buildingName][floorName][zoneName][channel][name] = GraphDataService.procesChartData(flowData.count);
            });
          });
        });
      });
    });
    if (lockNum < this.zoneEntranceExitFlowLock) { return; }
    this.zoneEntranceExitFlowData$.next(zoneEntranceExitFlowData);
  }

  async fetchZoneEntranceExitFlowData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/entrance-exit-flow?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ZoneEntranceExitFlowData>[], number];
  }
  //#endregion zone/entrance-exit-flow

  //#region plate/provinces-ungroup
  async loadProvinceUngroupData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchProvinceUngroupData(date, ++graphDataServiceInstance.provinceLock).then(([data, lockNum]) => graphDataServiceInstance.deriveProvinceUngroupData(data, lockNum));
  }

  deriveProvinceUngroupData(provinceUngroupDatas: IFetchData<ProvinceUnGroupData>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(provinceUngroupDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const rawProvinceUngroupData = dataIt.data;
    const vehicleProvinceData: { [provinceName: string]: number } & { _total: number } = { _total: 0 };
    Object.entries(rawProvinceUngroupData).forEach(([provinceName, provinceData]) => { // _total should have been in provinceName
      vehicleProvinceData[provinceName] = GraphDataService.procesChartData(provinceData.count);
    });
    const filteredTotal: { [provinceName: string]: number } = Object.keys(vehicleProvinceData)
      .filter(key => key !== '_total')
      .reduce((obj, key) => {
        obj[key] = vehicleProvinceData[key];
        return obj;
      }, {});
    const sortableProvinceDataByVal: { [provinceName: string]: number } = Object.entries(filteredTotal)
      .sort(([, a], [, b]) => a - b).reverse()
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const top5VehicleProvinceData: { [provinceName: string]: number } & { other: number } & { _total: number } = { other: 0, _total: vehicleProvinceData._total };
    const sumTotal = Object.values(sortableProvinceDataByVal).reduce(((a, b) => a + b), 0);
    Object.entries(sortableProvinceDataByVal).forEach(([provinceName, value], idx) => {
      const percentageValue = (value / sumTotal) * 100;
      if (idx < 5) {
        top5VehicleProvinceData[provinceName] = GraphDataService.procesChartData(percentageValue, false, true);
      } else {
        top5VehicleProvinceData.other += GraphDataService.procesChartData(percentageValue, false, true);
      }
    });
    if (lockNum < this.provinceLock) { return; }
    this.vehicleProvinceData$.next(top5VehicleProvinceData);
  }

  async fetchProvinceUngroupData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/provinces-ungroup?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ProvinceUnGroupData>[], number];
  }
  //#endregion plate/provinces-ungroup

  //#region building/entrance-exit-by-pin-by-hour
  async loadEntranceExitByPinByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitByPinByHourData(date, ++graphDataServiceInstance.buildingEntranceExitPinHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitByPinByHourData(data, lockNum));
  }

  async deriveEntranceExitByPinByHourData(entranceExitPinHourDatas: IFetchData<EntranceExitByPinByHourData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(entranceExitPinHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const entranceExitPinHourData = dataIt.data;
    const entranceByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const exitByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const entranceByPinByHour: { [buildingName: string]: { [pinName: string]: number[] } } = {};
    const exitByPinByHour: { [buildingName: string]: { [pinName: string]: number[] } } = {};
    const entranceAllPin: { [pinName: string]: number } = {};
    const exitAllPin: { [pinName: string]: number } = {};

    for (const [buildingName, pinDatas] of Object.entries(entranceExitPinHourData)) {
      entranceByPinByHour[buildingName] = {};
      exitByPinByHour[buildingName] = {};
      entranceByPin[buildingName] = {};
      exitByPin[buildingName] = {};
      for (const [pinName, pinData] of Object.entries(pinDatas)) {
        entranceByPin[buildingName][pinName] = pinData._total.entrance;
        exitByPin[buildingName][pinName] = pinData._total.exit;
        entranceByPinByHour[buildingName][pinName] = [];
        exitByPinByHour[buildingName][pinName] = [];
        if (buildingName === this.configDataService.MAIN_BUILDING) {
          entranceAllPin[pinName] = pinData._total.entrance;
          exitAllPin[pinName] = pinData._total.exit;
        }
        this.configDataService.TIME_LIST.map(time => {
          const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
          const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
          const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
          const pushData = pinData[timeKey] ? pinData[timeKey] : { entrance: fillValue, exit: fillValue };
          entranceByPinByHour[buildingName][pinName].push(GraphDataService.procesChartData(pushData.entrance));
          exitByPinByHour[buildingName][pinName].push(GraphDataService.procesChartData(pushData.exit));
        });
      }
    }
    if (lockNum < this.buildingEntranceExitPinHourLock) { return; }
    if (!this.configDataService.isFeatureEnabled('traffic_page', 'heatmap_data_v2')) {
      this.allPinByBuildingEntrance$.next(entranceAllPin);
      this.allPinByBuildingExit$.next(exitAllPin);
    }
    this.entrancePinByHour$.next(entranceByPinByHour);
    this.exitPinByHour$.next(exitByPinByHour);
    this.entrancePin$.next(entranceByPin);
    this.exitPin$.next(exitByPin);
  }

  async fetchEntranceExitByPinByHourData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit-by-pin-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByPinByHourData>[], number];
  }
  //#endregion entrance-exit-by-pin-by-hour

  //#region floor/entrance-exit-by-pin-by-hour
  async loadEntranceExitFloorByPinByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitFloorByPinByHourData(date, ++graphDataServiceInstance.buildingEntranceExitFloorPinHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitFloorByPinByHourData(data, lockNum));
  }

  async deriveEntranceExitFloorByPinByHourData(entranceExitFloorPinHourDatas: IFetchData<EntranceExitByFloorByPinByHourData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(entranceExitFloorPinHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const entranceExitFloorPinHourData = dataIt.data;
    const entranceFloorByPin: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    const exitFloorByPin: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    const spEntranceFloorByPin: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    const spExitFloorByPin: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    const spEntranceFloorByPinByHour: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } } = {};
    const spExitFloorByPinByHour: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } } = {};
    const entranceFloorByPinByHour: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } } = {};
    const exitFloorByPinByHour: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } } = {};
    const floorEntranceExitByPinData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: { entrance: number; exit: number } } } } = {};
    const entranceGroupOldAreaByPin = {};
    const exitGroupOldAreaByPin = {};

    for (const [buildingName, floorData] of Object.entries(entranceExitFloorPinHourData)) {
      entranceFloorByPinByHour[buildingName] = {};
      exitFloorByPinByHour[buildingName] = {};
      entranceFloorByPin[buildingName] = {};
      exitFloorByPin[buildingName] = {};
      floorEntranceExitByPinData[buildingName] = {};
      spEntranceFloorByPin[buildingName] = {};
      spExitFloorByPin[buildingName] = {};
      spEntranceFloorByPinByHour[buildingName] = {};
      spExitFloorByPinByHour[buildingName] = {};
      for (const [floorName, pinDatas] of Object.entries(floorData)) {
        entranceFloorByPinByHour[buildingName][floorName] = {};
        exitFloorByPinByHour[buildingName][floorName] = {};
        entranceFloorByPin[buildingName][floorName] = {};
        exitFloorByPin[buildingName][floorName] = {};
        floorEntranceExitByPinData[buildingName][floorName] = {};
        spEntranceFloorByPin[buildingName][floorName] = {};
        spExitFloorByPin[buildingName][floorName] = {};
        spEntranceFloorByPinByHour[buildingName][floorName] = {};
        spExitFloorByPinByHour[buildingName][floorName] = {};
        const sumAllPinByFloor = { entrance: 0, exit: 0 };
        for (const [pinName, pinData] of Object.entries(pinDatas)) {
          entranceFloorByPinByHour[buildingName][floorName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
          exitFloorByPinByHour[buildingName][floorName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
          spEntranceFloorByPinByHour[buildingName][floorName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
          spExitFloorByPinByHour[buildingName][floorName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
          floorEntranceExitByPinData[buildingName][floorName][pinName] = { entrance: 0, exit: 0 };
          spEntranceFloorByPin[buildingName][floorName][pinName] = pinData._total.entrance;
          spExitFloorByPin[buildingName][floorName][pinName] = pinData._total.exit;
          entranceGroupOldAreaByPin[pinName] = pinData._total.entrance;
          exitGroupOldAreaByPin[pinName] = pinData._total.exit;
          sumAllPinByFloor.entrance += pinData._total.entrance;
          sumAllPinByFloor.exit += pinData._total.exit;
          entranceFloorByPin[buildingName][floorName][pinName] = pinData._total.entrance;
          exitFloorByPin[buildingName][floorName][pinName] = pinData._total.exit;
          floorEntranceExitByPinData[buildingName][floorName][pinName].entrance = pinData._total.exit;
          floorEntranceExitByPinData[buildingName][floorName][pinName].exit = pinData._total.exit;
          this.configDataService.TIME_LIST.map((time, idx) => {
            const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
            const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
            const pushData = pinData[timeKey] ? pinData[timeKey] : { entrance: fillValue, exit: fillValue };
            entranceFloorByPinByHour[buildingName][floorName][pinName][idx] = GraphDataService.procesChartData(pushData.entrance);
            exitFloorByPinByHour[buildingName][floorName][pinName][idx] = GraphDataService.procesChartData(pushData.exit);
            spEntranceFloorByPinByHour[buildingName][floorName][pinName][idx] = GraphDataService.procesChartData(pushData.entrance);
            spExitFloorByPinByHour[buildingName][floorName][pinName][idx] = GraphDataService.procesChartData(pushData.exit);
          });
        }
        floorEntranceExitByPinData[buildingName][floorName]._total = sumAllPinByFloor;
      }
    }
    if (lockNum < this.buildingEntranceExitFloorPinHourLock) { return; }
    this.baseGraphData.selectedDirectory$.subscribe(selectDirectory => {
      if (this.configDataService.isFeatureEnabled('multiple_organization') && selectDirectory?.floor === 'onesiam_plus_007_5f' && selectDirectory?.zone === 'siam_paragon') {
        this.allPinByBuildingEntrance$.next(entranceGroupOldAreaByPin);
        this.allPinByBuildingExit$.next(entranceGroupOldAreaByPin);
      }
    });
    if (!this.configDataService.isFeatureEnabled('multiple_organization')) {
      this.entranceFloorPin$.next(entranceFloorByPin);
      this.exitFloorPin$.next(exitFloorByPin);
      this.entranceBuildingFloorPin$.next(entranceFloorByPin);
      this.exitBuildingFloorPin$.next(exitFloorByPin);
      this.entranceFloorPinByHour$.next(entranceFloorByPinByHour);
      this.exitFloorPinByHour$.next(exitFloorByPinByHour);
    } else {
      this.spFloorEntranceExitPin$.next(floorEntranceExitByPinData);
      this.spEntranceBuildingFloorPin$.next(spEntranceFloorByPin);
      this.spExitBuildingFloorPin$.next(spExitFloorByPin);
      this.spEntranceBuildingFloorPinByHour$.next(spEntranceFloorByPinByHour);
      this.spExitBuildingFloorPinByHour$.next(spExitFloorByPinByHour);
    }

  }

  async fetchEntranceExitFloorByPinByHourData(date: moment.Moment, lockNum: number) {
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/floor/building-floor-entrance-exit-by-pin-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitByFloorByPinByHourData>[], number];
  }
  //#endregion floor/entrance-exit-by-pin-by-hour

  //#region zone/entrance-exit-by-pin-by-hour
  async loadEntranceExitZoneByPinByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitZoneByPinByHourData(date, ++graphDataServiceInstance.buildingEntranceExitZonePinHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitZoneByPinByHourData(data, lockNum));
  }

  async deriveEntranceExitZoneByPinByHourData(entranceExitZonePinHourDatas: IFetchData<EntranceExitFloorZoneByPinByHourData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const momentIt = moment(this.viewPeriodService.selectedDate);
    const dataIt = filterSelectedDateData(entranceExitZonePinHourDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) { return; }
    const entranceExitZonePinHourData = dataIt.data;
    const entranceZoneByPin: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number } } } } = {};
    const exitZoneByPin: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number } } } } = {};
    const entranceZoneByPinByHour: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number[] } } } } = {};
    const exitZoneByPinByHour: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: number[] } } } } = {};
    const entranceExitZoneByPin: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [pinName: string]: { entrance: number; exit: number } } } } } = {};

    for (const [buildingName, floorData] of Object.entries(entranceExitZonePinHourData)) {
      entranceZoneByPinByHour[buildingName] = {};
      exitZoneByPinByHour[buildingName] = {};
      entranceZoneByPin[buildingName] = {};
      exitZoneByPin[buildingName] = {};
      entranceExitZoneByPin[buildingName] = {};
      for (const [floorName, zoneData] of Object.entries(floorData)) {
        entranceZoneByPinByHour[buildingName][floorName] = {};
        exitZoneByPinByHour[buildingName][floorName] = {};
        entranceZoneByPin[buildingName][floorName] = {};
        exitZoneByPin[buildingName][floorName] = {};
        entranceExitZoneByPin[buildingName][floorName] = {};
        for (const [zoneName, pinDatas] of Object.entries(zoneData)) {
          entranceZoneByPinByHour[buildingName][floorName][zoneName] = {};
          exitZoneByPinByHour[buildingName][floorName][zoneName] = {};
          entranceZoneByPin[buildingName][floorName][zoneName] = {};
          exitZoneByPin[buildingName][floorName][zoneName] = {};
          entranceExitZoneByPin[buildingName][floorName][zoneName] = {};
          const sumAllPinbyZone = { entrance: 0, exit: 0 };
          for (const [pinName, pinData] of Object.entries(pinDatas)) {
            entranceExitZoneByPin[buildingName][floorName][zoneName][pinName] = { entrance: 0, exit: 0 };
            entranceZoneByPin[buildingName][floorName][zoneName][pinName] = pinData?._total?.entrance || 0;
            exitZoneByPin[buildingName][floorName][zoneName][pinName] = pinData?._total?.exit || 0;
            entranceZoneByPinByHour[buildingName][floorName][zoneName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
            exitZoneByPinByHour[buildingName][floorName][zoneName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
            this.configDataService.TIME_LIST.map((time, idx) => {
              const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC - 1; // -1 for shift to end of hour
              const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour() - 1;
              const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
              const pushData = pinData[timeKey] ? pinData[timeKey] : { entrance: fillValue, exit: fillValue };
              entranceZoneByPinByHour[buildingName][floorName][zoneName][pinName][idx] = GraphDataService.procesChartData(pushData.entrance);
              exitZoneByPinByHour[buildingName][floorName][zoneName][pinName][idx] = GraphDataService.procesChartData(pushData.exit);
            });
            entranceExitZoneByPin[buildingName][floorName][zoneName][pinName].entrance = pinData?._total?.entrance;
            entranceExitZoneByPin[buildingName][floorName][zoneName][pinName].exit = pinData?._total?.exit;
            sumAllPinbyZone.entrance += pinData?._total?.entrance;
            sumAllPinbyZone.exit += pinData?._total?.exit;
          }
          entranceExitZoneByPin[buildingName][floorName][zoneName]._total = sumAllPinbyZone;
        }
      }
    }
    if (lockNum < this.buildingEntranceExitZonePinHourLock) { return; }
    this.entranceZonePinByHour$.next(entranceZoneByPinByHour);
    this.exitZonePinByHour$.next(exitZoneByPinByHour);
    this.entranceZonePin$.next(entranceZoneByPin);
    this.exitZonePin$.next(exitZoneByPin);
    this.zoneEntranceExitByPinData$.next(entranceExitZoneByPin);
  }

  async fetchEntranceExitZoneByPinByHourData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/entrance-exit-by-pin-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<EntranceExitFloorZoneByPinByHourData>[], number];
  }
  //#endregion entrance-exit-by-pin-by-hour

  //#region overall/mask-count
  async loadMaskCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchMaskCountData(date, ++graphDataServiceInstance.maskCountLock).then(([data, lockNum]) => graphDataServiceInstance.deriveMaskCountData(data, lockNum));
  }

  async deriveMaskCountData(maskCountDatas: IFetchData<MaskGroupData>[], lockNum: number) {
    const currentMaskCountPair: [number, number] = [0, 0];
    // await this.configDataService.loadAppConfig();
    GraphDataService.mapSevenDayLineChartData(maskCountDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const maskCountData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        currentMaskCountPair[diffToSelectedDate + 1] = (maskCountData.mask / maskCountData.count) * 100;
      }
    });
    if (lockNum < this.maskCountLock) { return; }
    this.currentMaskCount$.next({
      maskCount: GraphDataService.procesChartData(currentMaskCountPair[1], false, true),
      diff: GraphDataService.procesChartData(currentMaskCountPair[1] - currentMaskCountPair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((currentMaskCountPair[1] - currentMaskCountPair[0]) / currentMaskCountPair[0] * 100) || 0, true, true)
    });
  }

  async fetchMaskCountData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/mask-count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<MaskGroupData>[], number];
  }

  //#endregion overall/mask-count

  //#region store/proximity-traffic
  async loadStoreProximityTrafficData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeProximityLock;
      const clearedData = Array.from({ length: 7 }).map<number>(() => null);
      graphDataServiceInstance.storeProximityTrafficData$.next(clearedData);
      graphDataServiceInstance.currentStoreConfidence$.next(null);
      graphDataServiceInstance.currentStoreProximityTrafficData$.next(null);
      return Promise.resolve();
    }
    const storeAreaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchStoreProximityTrafficData(date, ++graphDataServiceInstance.storeProximityLock, storeAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreProximityTrafficData(data, lockNum));
  }

  async deriveStoreProximityTrafficData(StoreProximityTrafficDatas: IFetchData<AreaProximityGroupData>[], lockNum: number) {
    // // await this.configDataService.loadAppConfig();
    const storeProximityTrafficTrendLineChartData: number[] = [];
    const currentStoreProximityTraffic: number[] = [];
    const currentStoreConfidence: number[] = [];
    GraphDataService.mapSevenDayLineChartData(StoreProximityTrafficDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        storeProximityTrafficTrendLineChartData.push(fillValue);
        return;
      }
      const storeProximityTrafficData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        currentStoreProximityTraffic.push(GraphDataService.procesChartData(storeProximityTrafficData.traffic));
        currentStoreConfidence.push(GraphDataService.procesChartData(storeProximityTrafficData.confidence));
      }
      storeProximityTrafficTrendLineChartData.push(GraphDataService.procesChartData(storeProximityTrafficData.traffic));
    });
    if (lockNum < this.storeProximityLock) { return; }
    this.storeProximityTrafficData$.next(storeProximityTrafficTrendLineChartData);
    this.currentStoreProximityTrafficData$.next(currentStoreProximityTraffic[0]);
    this.currentStoreConfidence$.next(currentStoreConfidence[0]);
  }

  async fetchStoreProximityTrafficData(date: moment.Moment, lockNum: number, storeArea: string) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/proximity-traffic?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${storeArea}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaProximityGroupData>[], number];
  }
  //#endregion store/proximity-traffic

  //#region floor/building-floor-heatmap
  async loadFloorHeatmapData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFloorHeatmapData(date, ++graphDataServiceInstance.floorHeatmapLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFloorHeatmapData(data, lockNum));
  }

  deriveFloorHeatmapData(floorHeatmapDatas: IFetchData<FloorHeatMapData>[], lockNum: number) {
    // initialize data
    const fetchedFloorHeatmapData = floorHeatmapDatas?.[0]?.data;
    if (!fetchedFloorHeatmapData) { return; }

    const floorHeatmapData: { [buildingName: string]: { [floorName: string]: HeatMapPoints[] } } = {};
    Object.entries(fetchedFloorHeatmapData).forEach(([buildingName, buildingFloorData]) => {
      floorHeatmapData[buildingName] = {};
      Object.entries(buildingFloorData).forEach(([floorName, floorData]) => {
        floorHeatmapData[buildingName][floorName] = Object.entries(floorData).filter(([keyName]) => keyName !== 'type').map(([_nodePathName, heatmapDataPoints]) => heatmapDataPoints).flat() as HeatMapPoints[];
      });
    });

    if (lockNum < this.floorHeatmapLock) { return; }
    this.floorHeatmapData$.next(floorHeatmapData);
  }

  async fetchFloorHeatmapData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/floor/building-floor-heatmap?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<FloorHeatMapData>[], number];
  }
  //#endregion floor/building-floor-heatmap

  //#region prediction/store/proximity-traffic
  async loadPredictionStoreProximityTrafficData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictedStoreProximityLock;
      const clearedData = Array.from({ length: 8 }).map<number>(() => null);
      graphDataServiceInstance.predictionStoreProximityTrafficData$.next(clearedData);
      return Promise.resolve();
    }
    const storeAreaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionStoreProximityTrafficData(date, ++graphDataServiceInstance.predictedStoreProximityLock, storeAreaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionStoreProximityTrafficData(data, lockNum));
  }

  async derivePredictionStoreProximityTrafficData(predictionStoreProximityTrafficDatas: IFetchData<AreaProximityGroupData>[], lockNum: number) {
    // // await this.configDataService.loadAppConfig();
    const predictionStoreProxTrafficTrendData: number[] = [];
    const predictionStoreProxTraffic: number[] = [];
    GraphDataService.mapSevenDayLineChartData(predictionStoreProximityTrafficDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        const fillValue = 0;
        predictionStoreProxTrafficTrendData.push(fillValue);
        return;
      }
      const predictionStoreProximityTrafficData = dataFiltered.data;
      if (diffToSelectedDate === 0 || diffToSelectedDate === 1) {
        predictionStoreProxTraffic.push(GraphDataService.procesChartData(predictionStoreProximityTrafficData.traffic));
      }
    });
    if (lockNum < this.predictedStoreProximityLock) { return; }
    this.predictionStoreProximityTrafficData$.next(predictionStoreProxTrafficTrendData);
    this.predictionStoreProximityTrafficNumber$.next(predictionStoreProxTraffic);
  }

  async fetchPredictionStoreProximityTrafficData(date: moment.Moment, lockNum: number, storeArea: string) {
    const qParams = this.getPredictionLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/store/proximity-traffic?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${storeArea}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaProximityGroupData>[], number];
  }
  //#endregion store/proximity-traffic

  //#region prediction/building/entrance-exit
  async loadPredictionBuildingTrafficData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPredictionEntranceExitData(date, ++graphDataServiceInstance.predictedBuildingTrafficLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionEntranceExitData(data, lockNum));
  }

  async derivePredictionEntranceExitData(predictedEntranceExitDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number) {
    const buildingNameSet = new Set<string>();
    Object.keys(this.configDataService.FLOOR_OBJECTS).forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const buildingEntranceExitData: { [buildingName: string]: { entrance: number[]; exit: number[] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [], exit: [] };
      return prev;
    }, {});
    const buildingNetShoppingData: { [buildingName: string]: { netShopping: number[] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { netShopping: [] };
      return prev;
    }, {});
    GraphDataService.mapSevenDayLineChartData(predictedEntranceExitDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = 0;
      if (!dataFiltered || !dataFiltered.data) {
        /*Object.keys(buildingEntranceExitData).forEach(buildingName => {
          buildingEntranceExitData[buildingName].entrance.push(fillValue);
          buildingEntranceExitData[buildingName].exit.push(fillValue);
        });*/
        return;
      }
      const entranceExitData = dataFiltered.data;
      Object.keys(buildingEntranceExitData).forEach(buildingName => {
        const entranceExitBuildingData = entranceExitData[buildingName] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue };
        if (diffToSelectedDate === 0 || diffToSelectedDate === 1) {
          buildingEntranceExitData[buildingName].entrance.push(GraphDataService.procesChartData(entranceExitBuildingData.entrance));
          buildingEntranceExitData[buildingName].exit.push(GraphDataService.procesChartData(entranceExitBuildingData.exit));
          buildingNetShoppingData[buildingName].netShopping.push(GraphDataService.procesChartData((entranceExitBuildingData.net_shopping_time / (60 * 60)), false, false));
        }
      });
    });
    if (lockNum < this.predictedBuildingTrafficLock) { return; }
    this.predictedBuildingTrafficNumber$.next(buildingEntranceExitData);
    this.predictedBuildingNetShoppingHourNumber$.next(buildingNetShoppingData);
  }

  async fetchPredictionEntranceExitData(date: moment.Moment, lockNum: number) {
    const nextDate = date.add(1, (this.viewPeriodService.viewPeriod.backendName as 'day' | 'week' | 'month'));
    const qParams = this.getOneSelectedQueryParameter(nextDate);
    let fetchURL = `/retail_customer_api_v2/api/v2/prediction/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.PREDICTION_ENTRANCE_EXIT).value) {
      const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
      if (agg_type !== undefined) {
        fetchURL += `&aggregation_type=${agg_type}`;
      }
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion prediction/building/entrance-exit

  //#region prediction/building/entrance-exit daily average
  async loadPredictionBuildingTrafficAvgData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPredictionEntranceExitAvgData(date, ++graphDataServiceInstance.predictedBuildingTrafficAvgLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionEntranceExitAvgData(data, lockNum));
  }

  async derivePredictionEntranceExitAvgData(predictedEntranceExitDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number) {
    const buildingNameSet = new Set<string>();
    Object.keys(this.configDataService.FLOOR_OBJECTS).forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const buildingEntranceExitData: { [buildingName: string]: { entrance: number[]; exit: number[] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [], exit: [] };
      return prev;
    }, {});
    const buildingNetShoppingData: { [buildingName: string]: { netShopping: number[] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { netShopping: [] };
      return prev;
    }, {});
    GraphDataService.mapSevenDayLineChartData(predictedEntranceExitDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = 0;
      if (!dataFiltered || !dataFiltered.data) {
        /*Object.keys(buildingEntranceExitData).forEach(buildingName => {
          buildingEntranceExitData[buildingName].entrance.push(fillValue);
          buildingEntranceExitData[buildingName].exit.push(fillValue);
        });*/
        return;
      }
      const entranceExitData = dataFiltered.data;
      Object.keys(buildingEntranceExitData).forEach(buildingName => {
        const entranceExitBuildingData = entranceExitData[buildingName] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue };
        if (diffToSelectedDate === 0 || diffToSelectedDate === 1) {
          buildingEntranceExitData[buildingName].entrance.push(GraphDataService.procesChartData(entranceExitBuildingData.entrance));
          buildingEntranceExitData[buildingName].exit.push(GraphDataService.procesChartData(entranceExitBuildingData.exit));
          buildingNetShoppingData[buildingName].netShopping.push(GraphDataService.procesChartData((entranceExitBuildingData.net_shopping_time / (60 * 60)), false, false));
        }
      });
    });
    if (lockNum < this.predictedBuildingTrafficAvgLock) { return; }
    this.predictedBuildingTrafficNumberAvg$.next(buildingEntranceExitData);
  }

  async fetchPredictionEntranceExitAvgData(date: moment.Moment, lockNum: number) {
    const nextDate = date.add(1, (this.viewPeriodService.viewPeriod.backendName as 'day' | 'week' | 'month'));
    const qParams = this.getOneSelectedQueryParameter(nextDate);
    let fetchURL = `/retail_customer_api_v2/api/v2/prediction/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
    if (agg_type !== undefined) {
      fetchURL += `&aggregation_type=${agg_type}&prediction=true`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion prediction/building/entrance-exit

  //#region prediction/floor/entrance-exit-by-floor
  async loadPredictionEntranceExitFloorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPredictionEntranceExitFloorData(date, ++graphDataServiceInstance.predictedFloorTrafficLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionEntranceExitFloorData(data, lockNum));
  }

  async derivePredictionEntranceExitFloorData(entranceExitFloorDatas: IFetchData<BuildingEntranceExitByFloorData>[], lockNum: number) {
    // initialize data
    const entranceExitFloorData: { [buildingName: string]: { [floorName: string]: { entrance: number[]; exit: number[] } } } = {};

    entranceExitFloorDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        /*if (!entranceExitFloorData[buildingName]) {
          entranceExitFloorData[buildingName] = {};
        }*/
        entranceExitFloorData[buildingName] = {};
        Object.keys(floorData).forEach(floorName => {
          entranceExitFloorData[buildingName][floorName] = { entrance: [], exit: [] };
        });
      });
    });
    GraphDataService.mapSevenDayLineChartData(entranceExitFloorDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        /*const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(entranceExitFloorData).forEach(buildingName => {
          Object.keys(entranceExitFloorData[buildingName]).forEach(floorName => {
            entranceExitFloorData[buildingName][floorName].entrance.push(fillValue);
            entranceExitFloorData[buildingName][floorName].exit.push(fillValue);
          });
        });*/
        return;
      }
      const buildingEntranceExitFloorData = dataFiltered.data;
      Object.keys(entranceExitFloorData).forEach(buildingName => {
        Object.keys(entranceExitFloorData[buildingName]).forEach(floorName => {
          const dataIt = (buildingEntranceExitFloorData[buildingName] || {})[floorName] || { entrance: 0, exit: 0, average_timespent: 0, net_shopping_time: 0 };
          if (diffToSelectedDate === 0 || diffToSelectedDate === 1) {
            entranceExitFloorData[buildingName][floorName].entrance.push(GraphDataService.procesChartData(dataIt.entrance));
            entranceExitFloorData[buildingName][floorName].exit.push(GraphDataService.procesChartData(dataIt.exit));
          }
        });
      });
    });
    if (lockNum < this.predictedFloorTrafficLock) { return; }
    this.predictedFloorTrafficNumber$.next(entranceExitFloorData);
  }

  async fetchPredictionEntranceExitFloorData(date: moment.Moment, lockNum: number) {
    const nextDate = date.add(1, (this.viewPeriodService.viewPeriod.backendName as 'day' | 'week' | 'month'));
    const qParams = this.getOneSelectedQueryParameter(nextDate);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/floor/entrance-exit-by-floor?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitByFloorData>[], number];
  }

  //#endregion prediction/floor/entrance-exit-by-floor

  //#region prediction/zone/entrance-exit
  async loadPredictionEntranceExitZoneData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPredictionEntranceExitZoneData(date, ++graphDataServiceInstance.predictedZoneTrafficLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionEntranceExitZoneData(data, lockNum));
  }

  async derivePredictionEntranceExitZoneData(entranceExitZoneDatas: IFetchData<BuildingZoneEntranceExitData>[], lockNum: number) {
    // initialize data
    const zoneEntranceExitFloorData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { entrance: number[]; exit: number[] } } } } = {};
    const zoneNetShoppingData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { netShopping: number[] } } } } = {};
    entranceExitZoneDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        zoneEntranceExitFloorData[buildingName] = {};
        zoneNetShoppingData[buildingName] = {};
        Object.entries(floorData).forEach(([floorName, zoneData]) => {
          zoneEntranceExitFloorData[buildingName][floorName] = {};
          zoneNetShoppingData[buildingName][floorName] = {};
          Object.keys(zoneData).forEach((zoneName) => {
            zoneEntranceExitFloorData[buildingName][floorName][zoneName] = { entrance: [], exit: [] };
            zoneNetShoppingData[buildingName][floorName][zoneName] = { netShopping: [] };
          });
        });
      });
    });
    GraphDataService.mapSevenDayLineChartData(entranceExitZoneDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const buildingEntranceExitZoneData = dataFiltered.data;
      Object.keys(zoneEntranceExitFloorData).forEach((buildingName) => {
        Object.keys(zoneEntranceExitFloorData[buildingName]).forEach((floorName) => {
          Object.keys(zoneEntranceExitFloorData[buildingName][floorName]).forEach((zoneName) => {
            const zoneData = (((buildingEntranceExitZoneData[buildingName] || {})[floorName] || {})[zoneName] || { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue });
            if (diffToSelectedDate === 0 || diffToSelectedDate === 1) {
              zoneNetShoppingData[buildingName][floorName][zoneName].netShopping.push(GraphDataService.procesChartData(zoneData.net_shopping_time / (60 * 60), false, false));
              zoneEntranceExitFloorData[buildingName][floorName][zoneName].entrance.push(GraphDataService.procesChartData(zoneData.entrance));
              zoneEntranceExitFloorData[buildingName][floorName][zoneName].exit.push(GraphDataService.procesChartData(zoneData.exit));
            }
          });
        });
      });
    });

    if (lockNum < this.predictedZoneTrafficLock) { return; }
    this.predictedZoneTrafficNumber$.next(zoneEntranceExitFloorData);
    this.predictedZoneNetShoppingNumber$.next(zoneNetShoppingData);
  }

  async fetchPredictionEntranceExitZoneData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const nextDate = date.add(1, (this.viewPeriodService.viewPeriod.backendName as 'day' | 'week' | 'month'));
    const qParams = this.getOneSelectedQueryParameter(nextDate);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/zone/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingZoneEntranceExitData>[], number];
  }

  //#endregion

  //#region overall/car-brand
  async loadCarBrandData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchCarBrandData(date, ++graphDataServiceInstance.carBrandLock).then(([data, lockNum]) => graphDataServiceInstance.deriveCarBrandData(data, lockNum));
  }

  deriveCarBrandData(carBrandDatas: IFetchData<CarBrandData[]>[], lockNum: number) {
    const filteredExcludeCarBrand: { [brandName: string]: number } = {};
    const filteredExcludeCarBrandMultipleDays: { [brandName: string]: number[] } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const filteredCustomerCarBrand: { [brandName: string]: number } = {};
    const filteredCustomerCarBrandMultipleDays: { [brandName: string]: number[] } = {};
    const totalCarBrand = [];
    GraphDataService.mapSevenDayLineChartData(carBrandDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      let totalCustomerCar = 0;
      dataFiltered.data.forEach(carBrandData => {
        if (filteredCustomerCarBrandMultipleDays[carBrandData.group.car_brand] === undefined) {
          filteredCustomerCarBrandMultipleDays[carBrandData.group.car_brand] = [];
        }
        if (filteredExcludeCarBrandMultipleDays[carBrandData.group.car_brand] === undefined) {
          filteredExcludeCarBrandMultipleDays[carBrandData.group.car_brand] = [];
        }
        if (compare1DepthObjects(carBrandData.group, { plate_number_definition: 'customer' })) {
          totalCustomerCar = carBrandData.count;
        }
        if (!carBrandData.group.car_brand) {
          totalCarBrand[0] = carBrandData.count;
          return;
        }
        if (excludeCarBrand.includes(carBrandData.group.car_brand)) {
          return;
        }
        if (carBrandData.group.plate_number_definition === 'customer') {
          filteredCustomerCarBrand[carBrandData.group.car_brand] = (carBrandData.count / totalCustomerCar) * 100;
          filteredCustomerCarBrandMultipleDays[carBrandData.group.car_brand].push(carBrandData.count);
        }
        filteredExcludeCarBrand[carBrandData.group.car_brand] = (carBrandData.count / totalCarBrand[0]) * 100;
        filteredExcludeCarBrandMultipleDays[carBrandData.group.car_brand].push(carBrandData.count);
      });
    });
    const topTenCarBrandData = Object.entries(this.configDataService.currentOrganization === 'MBK' ? filteredCustomerCarBrand : filteredExcludeCarBrand)
      .sort(([, a], [, b]) => b - a).slice(0, 10)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const topTenCarBrandTrendData = Object.entries(this.configDataService.currentOrganization === 'MBK' ? filteredCustomerCarBrandMultipleDays : filteredExcludeCarBrandMultipleDays)
      .filter(([k,]) => k !== 'undefined')
      .sort(([, a], [, b]) => (b.reduce(((c, d) => c + d)) / b.length) - (a.reduce(((c, d) => c + d)) / a.length)).slice(0, 10)
      // .filter(([, v]) => (v.reduce(((a, b) => a + b)) / v.length) > 5)
      .reduce((r, [k, v]) => ({ ...r, [k]: [...v, 0] }), {});
    if (lockNum < this.carBrandLock) { return; }
    this.topTenCarBrandData$.next(topTenCarBrandData);
    this.topTenCarBrandTrendData$.next(topTenCarBrandTrendData);
  }

  async fetchCarBrandData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    // const qParams = this.getOneSelectedQueryParameter(date);
    const qParams = this.getLineChartQueryParameter(date);
    const channel = this.configDataService.currentOrganization === 'THENINE' ? 'exit' : 'entrance';
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/car-brand?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&channel=${channel}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<CarBrandData[]>[], number];
  }

  //#endregion overall/car-brand

  //#region overall/vehicle-purchasing-power
  async loadVehiclePurchasingPowerData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehiclePurchasingPowerData(date, ++graphDataServiceInstance.vehiclePurchasingPowerLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehiclePurchasingPowerData(data, lockNum));
  }

  deriveVehiclePurchasingPowerData(vehiclePurchasingPowerDatas: IFetchData<VehiclePurchasingPowerData>[], lockNum: number) {
    const tierListPercentage: { [tierName: string]: number } = {};
    const tierList: { [tierName: string]: number } = {};
    const premiumTimePairData: [number, number] = [0, 0];
    const luxuryTimePairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(vehiclePurchasingPowerDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      Object.entries(dataFiltered.data).forEach(([tierName, tierData]) => {
        if (tierName.includes('count')) {
          return;
        }
        if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
          if (tierName === 'premium') {
            premiumTimePairData[diffToSelectedDate + 1] = (tierData / dataFiltered.data.count) * 100;
          }
          if (tierName === 'luxury') {
            luxuryTimePairData[diffToSelectedDate + 1] = (tierData / dataFiltered.data.count) * 100;
          }
        }
        tierListPercentage[tierName] = (tierData / dataFiltered.data.count) * 100;
        tierList[tierName] = Math.round(tierData);
      });
    });
    if (lockNum < this.vehiclePurchasingPowerLock) { return; }
    const sortableTierListByVal = Object.entries(tierList)
      .sort(([, a], [, b]) => a - b).reverse()
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const sortableByVal = Object.entries(tierListPercentage)
      .sort(([, a], [, b]) => a - b).reverse()
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.tierPowerData$.next(sortableByVal);
    this.currentPremiumTierData$.next({
      current: GraphDataService.procesChartData(premiumTimePairData[1], false, true),
      diff: GraphDataService.procesChartData(premiumTimePairData[1] - premiumTimePairData[0], true, true)
    });
    this.currentLuxuryTierData$.next({
      current: GraphDataService.procesChartData(luxuryTimePairData[1], false, true),
      diff: GraphDataService.procesChartData(luxuryTimePairData[1] - luxuryTimePairData[0], true, true),
    });
    this.vehiclePurchasingPowerData$.next(sortableTierListByVal);
  }

  async fetchVehiclePurchasingPowerData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const channel = this.configDataService.currentOrganization === 'THENINE' ? 'exit' : 'entrance';
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/vehicle-purchasing-power?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&channel=${channel}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<VehiclePurchasingPowerData>[], number];
  }

  //#endregion overall/vehicle-purchasing-power

  //#region overall/mode-of-transportation-by-hour
  async loadModeOfTransportByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchModeOfTransportByHourData(date, ++graphDataServiceInstance.modeOfTransportationByHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveModeOfTransportByHourData(data, lockNum));
  }

  async deriveModeOfTransportByHourData(modeOfTransByHourDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeByHourData: { [vehicleTypeName: string]: { [hourKey: number]: number }; total: { [hourKey: number]: number } } = { total: {} };
    Object.values(modeOfTransByHourDatas).forEach(modeOfTransByHourData => {
      modeOfTransByHourData.data.forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeByHourData[vehicleTypeName] = {};
      });
    });
    Object.values(modeOfTransByHourDatas).forEach(modeOfTransByHourData => {
      const vehicleTypeUsed: string[] = [];
      this.configDataService.VEHICLE_TIME_LIST.map(time => {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        if (modeOfTransByHourData.hour === timeKey) {
          modeOfTransByHourData.data.forEach(vehicleTypeData => {
            const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
            tranSportModeByHourData[vehicleTypeName][timeKey] = GraphDataService.procesChartData(vehicleTypeData.count);
            vehicleTypeUsed.push(vehicleTypeName);
          });
          // fill 0 for no data
          Object.keys(tranSportModeByHourData).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
            tranSportModeByHourData[noDataType][timeKey] = 0;
          });
        }
      });
    });
    if (lockNum < this.modeOfTransportationLock) { return; }
    this.modeOfTransportByHour$.next(tranSportModeByHourData);
  }

  async fetchModeOfTransportByHourData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/mode-of-transportation-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion overall/mode-of-transportation-by-hour

  //#region prediction/overall/visitor-profile 
  async loadPredictionVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPredictionVisitorProfileData(date, ++graphDataServiceInstance.predictedVisitorProfileLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionVisitorProfileData(data, lockNum));
  }

  async derivePredictionVisitorProfileData(predictedVisitorProfileDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const teenagerTimeData = [];
    // this.unfilteredVisitorProfileData$.next(predictedVisitorProfileDatas);
    // await this.configDataService.loadAppConfig();
    predictedVisitorProfileDatas.forEach(predVisitorProfile => {
      const visitorProfileData = predVisitorProfile.data;
      visitorProfileData.forEach(profile => {
        if (compare1DepthObjects(profile.group, { age: 'teenagers' })) {
          teenagerTimeData.push(GraphDataService.procesChartData(profile.count, false, false));
        }
      });
    });
    if (lockNum < this.predictedVisitorProfileLock) { return; }
    this.predictedTeenagerProfileNumber$.next({
      teenager: { entrance: teenagerTimeData },
    });
  }

  async fetchPredictionVisitorProfileData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getPredictionMonthQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/overall/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion prediction/overall/visitor-profile

  //#region goal building/entrance-exit
  async loadMonthEntranceExitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const goalDuration = graphDataServiceInstance.goalDurationDate$.getValue();
    if (!goalDuration.startDate && graphDataServiceInstance.viewPeriodService.isWeekPeriod && graphDataServiceInstance.viewPeriodService.isMonthPeriod) {
      ++graphDataServiceInstance.monthEntranceExitLock;
      return;
    }
    const momentGoalStartDate = moment(goalDuration.startDate, 'MM-DD-YYYY');
    const current_date = moment().clone().subtract(1, 'd');
    return graphDataServiceInstance.fetchMonthEntranceExitData(current_date, ++graphDataServiceInstance.monthEntranceExitLock, momentGoalStartDate)
      .then(([data, lockNum]) => graphDataServiceInstance.deriveMonthEntranceExitData(data, lockNum, current_date, momentGoalStartDate, goalDuration.excludeDate))
      .then(() => graphDataServiceInstance.loadPredictionGoalBuildingEntranceExitData(current_date, graphDataServiceInstance, goalDuration));
  }

  async deriveMonthEntranceExitData(entranceExitMonthDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number, date: moment.Moment, startDate: moment.Moment, excludeDate: string[]) {
    const buildingNameSet = new Set<string>();
    const momentExcludeDate = excludeDate.map(d => moment(d, 'MM-DD-YYYY'));
    const buildingList = Object.keys(this.configDataService.FLOOR_OBJECTS);

    buildingList.forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const buildingTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingAvgTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingNetShoppingData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const diffDate = date.diff(startDate, 'day') < 1 ? 1 : date.diff(startDate, 'day');
    if (!entranceExitMonthDatas) {
      return;
    }
    for (const momentIt = startDate; momentIt <= date; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitMonthDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = startDate.diff(date, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const dataItDate = moment(`${dataIt.month}-${dataIt.day}-${dataIt.year}`, 'MM-DD-YYYY');
        if (momentExcludeDate.some(d => d.isSame(dataItDate))) {
          continue;
        }
        const entranceExitData = dataIt.data;
        Object.keys(buildingTrafficData).forEach(building => {
          if (diffPrevDate === -1) {
            if (!entranceExitData[building]) {
              buildingTrafficData[building].changed = 0;
              buildingNetShoppingData[building].changed = 0;
            } else {
              buildingTrafficData[building].changed = entranceExitData[building].entrance;
              buildingNetShoppingData[building].changed = GraphDataService.procesChartData((entranceExitData[building].net_shopping_time / (60 * 60)), false, false);
            }
          }
          if (!entranceExitData[building]) {
            buildingTrafficData[building].val += 0;
            buildingNetShoppingData[building].val += 0;
          } else {
            buildingTrafficData[building].val += entranceExitData[building].entrance;
            buildingNetShoppingData[building].val += entranceExitData[building].net_shopping_time;
          }

        });
      }
    }

    Object.keys(buildingTrafficData).forEach(building => {
      buildingTrafficData[building].val = GraphDataService.procesChartData(buildingTrafficData[building].val, false, false);
      buildingAvgTrafficData[building].val = GraphDataService.procesChartData((buildingTrafficData[building].val / diffDate), false, false);
      buildingAvgTrafficData[building].changed = GraphDataService.procesChartData((buildingTrafficData[building].changed / (diffDate - 1)), false, false);
      buildingNetShoppingData[building].val = GraphDataService.procesChartData(buildingNetShoppingData[building].val / (60 * 60), false, false);
    });
    // if (lockNum < this.monthEntranceExitLock) { return; }
    this.currentGoalAvgTrafficBuildingData = buildingAvgTrafficData;
    this.currentGoalTrafficBuildingData = buildingTrafficData;
  }

  async fetchMonthEntranceExitData(date: moment.Moment, lockNum: number, goalStartDate: moment.Moment) {
    const numInterval = date.isSame(goalStartDate, 'd') ? 1 : date.diff(goalStartDate, 'd') + 1; //(for any goal)
    const qParams = { start_date: date.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }

  //#endregion goal building/entrance-exit

  //#region prediction goal building/entrance-exit
  async loadPredictionGoalBuildingEntranceExitData(date: moment.Moment, instance?: GraphDataService, goalDuration?: { startDate: string; endDate: string; excludeDate?: string[] }) {
    const graphDataServiceInstance = instance || this;
    const goalStartDate = goalDuration.startDate;
    const goalEndDate = goalDuration.endDate;
    const excludeDate = goalDuration.excludeDate || [];
    if (!goalStartDate || !goalEndDate) {
      ++graphDataServiceInstance.predGoalBuildingEntranceExitLock;
      return;
    }
    const current_date = moment().clone().subtract(1, 'd');
    const momentGoalEndDate = moment(goalEndDate, 'MM-DD-YYYY');
    return graphDataServiceInstance.fetchPredictionGoalBuildingEntranceExitData(date, ++graphDataServiceInstance.predGoalBuildingEntranceExitLock, current_date, momentGoalEndDate)
      .then(([data, lockNum]) => graphDataServiceInstance.derivePredictionGoalBuildingEntranceExitData(data, lockNum, momentGoalEndDate, current_date, goalDuration));
  }

  derivePredictionGoalBuildingEntranceExitData(entranceExitMonthDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number, date: moment.Moment, startDate: moment.Moment, goalDuration: { startDate: string; endDate: string; excludeDate?: string[] }) {
    const buildingNameSet = new Set<string>();
    const momentExcludeDate = goalDuration?.excludeDate.map(d => moment(d, 'MM-DD-YYYY'));
    const tempGoalAvgTrafficBuildingData = this.currentGoalAvgTrafficBuildingData;
    const tempGoalTrafficBuildingData = this.currentGoalTrafficBuildingData;
    const buildingList = Object.keys(this.configDataService.FLOOR_OBJECTS);
    buildingList.forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const buildingTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const finalBuildingTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingAvgTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingNetShoppingData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    if (!entranceExitMonthDatas) {
      return;
    }
    if (entranceExitMonthDatas.length === 0) {
      return;
    }
    const latest_data = entranceExitMonthDatas[0];
    const latest_moment = moment(`${latest_data.year}-${latest_data.month}-${latest_data.day}`, 'YYYY-MM-DD');
    const diffDate = latest_moment.diff(startDate, 'day') < 1 ? 1 : latest_moment.diff(startDate, 'day');
    for (const momentIt = startDate; momentIt <= latest_moment; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitMonthDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = latest_moment.diff(startDate, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const dataItDate = moment(`${dataIt.month}-${dataIt.day}-${dataIt.year}`, 'MM-DD-YYYY');
        if (momentExcludeDate.some(d => d.isSame(dataItDate))) {
          continue;
        }
        const entranceExitData = dataIt.data;
        Object.keys(buildingTrafficData).forEach(building => {
          if (diffPrevDate === -1) {
            if (!entranceExitData[building]) {
              buildingTrafficData[building].changed = 0;
              buildingNetShoppingData[building].changed = 0;
            } else {
              buildingTrafficData[building].changed = entranceExitData[building].entrance;
              buildingNetShoppingData[building].changed = GraphDataService.procesChartData((entranceExitData[building].net_shopping_time / (60 * 60)), false, false);
            }
          }
          if (!entranceExitData[building]) {
            buildingTrafficData[building].val += 0;
            buildingNetShoppingData[building].val += 0;
          } else {
            buildingTrafficData[building].val += entranceExitData[building].entrance;
            buildingNetShoppingData[building].val += entranceExitData[building].net_shopping_time;
          }

        });
      }
    }
    Object.keys(buildingTrafficData).forEach(building => {
      const avgPredBuildingTraffic = buildingTrafficData[building].val / diffDate;
      const avgPredBuildingTrafficChanged = (buildingTrafficData[building]?.changed || 0) / diffDate;
      const avgBuildingTrafficData = (((tempGoalAvgTrafficBuildingData?.[building]?.val || 0) + avgPredBuildingTraffic)) / 2;
      const avgBuildingTrafficChangedData = (((tempGoalAvgTrafficBuildingData?.[building]?.changed || 0) + (avgPredBuildingTrafficChanged[building]?.changed || 0))) / 2;
      finalBuildingTrafficData[building].val = GraphDataService.procesChartData(buildingTrafficData[building].val, false, false);
      buildingAvgTrafficData[building].val = GraphDataService.procesChartData(avgBuildingTrafficData, false, false);
      buildingAvgTrafficData[building].changed = GraphDataService.procesChartData(avgBuildingTrafficChangedData, false, false);
      buildingNetShoppingData[building].val = GraphDataService.procesChartData(buildingNetShoppingData[building].val / (60 * 60), false, false);
    });
    // if (lockNum < this.predGoalBuildingEntranceExitLock) { return; }
    this.currentGoalAvgTrafficBuildingData$.next([tempGoalAvgTrafficBuildingData, buildingAvgTrafficData]);
    this.currentGoalTrafficBuildingData$.next([tempGoalTrafficBuildingData, finalBuildingTrafficData]);
  }

  async fetchPredictionGoalBuildingEntranceExitData(date: moment.Moment, lockNum: number, goalStartDate: moment.Moment, goalEndDate: moment.Moment) {
    const numInterval = Math.abs(goalEndDate.diff(date, 'd')) + 1; //(for any goal)
    const qParams = { start_date: goalEndDate.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }

  //#endregion prediction goal building/entrance-exit tricky

  //#region goal building/entrance-exit tricky
  async loadMonthEntranceExitData2(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const goalDuration = graphDataServiceInstance.goalDurationDate2$.getValue();
    if (!goalDuration.startDate && graphDataServiceInstance.viewPeriodService.isWeekPeriod && graphDataServiceInstance.viewPeriodService.isMonthPeriod) {
      ++graphDataServiceInstance.monthEntranceExitLock2;
      return;
    }
    const momentGoalStartDate = moment(goalDuration.startDate, 'MM-DD-YYYY');
    const current_date = moment().clone().subtract(1, 'd');
    return graphDataServiceInstance.fetchMonthEntranceExitData2(current_date, ++graphDataServiceInstance.monthEntranceExitLock2, momentGoalStartDate)
      .then(([data, lockNum]) => graphDataServiceInstance.deriveMonthEntranceExitData2(data, lockNum, current_date, momentGoalStartDate, goalDuration.excludeDate))
      .then(() => graphDataServiceInstance.loadPredictionGoalBuildingEntranceExitData2(current_date, graphDataServiceInstance, goalDuration));
  }

  async deriveMonthEntranceExitData2(entranceExitMonthDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number, date: moment.Moment, startDate: moment.Moment, excludeDate: string[]) {
    const buildingNameSet = new Set<string>();
    const momentExcludeDate = excludeDate.map(d => moment(d, 'MM-DD-YYYY'));
    const buildingList = Object.keys(this.configDataService.FLOOR_OBJECTS);

    buildingList.forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const buildingTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingAvgTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingNetShoppingData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const diffDate = date.diff(startDate, 'day') < 1 ? 1 : date.diff(startDate, 'day');
    if (!entranceExitMonthDatas) {
      return;
    }
    for (const momentIt = startDate; momentIt <= date; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitMonthDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = startDate.diff(date, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const dataItDate = moment(`${dataIt.month}-${dataIt.day}-${dataIt.year}`, 'MM-DD-YYYY');
        if (momentExcludeDate.some(d => d.isSame(dataItDate))) {
          continue;
        }
        const entranceExitData = dataIt.data;
        Object.keys(buildingTrafficData).forEach(building => {
          if (diffPrevDate === -1) {
            if (!entranceExitData[building]) {
              buildingTrafficData[building].changed = 0;
              buildingNetShoppingData[building].changed = 0;
            } else {
              buildingTrafficData[building].changed = entranceExitData[building].entrance;
              buildingNetShoppingData[building].changed = GraphDataService.procesChartData((entranceExitData[building].net_shopping_time / (60 * 60)), false, false);
            }
          }
          if (!entranceExitData[building]) {
            buildingTrafficData[building].val += 0;
            buildingNetShoppingData[building].val += 0;
          } else {
            buildingTrafficData[building].val += entranceExitData[building].entrance;
            buildingNetShoppingData[building].val += entranceExitData[building].net_shopping_time;
          }

        });
      }
    }

    Object.keys(buildingTrafficData).forEach(building => {
      buildingTrafficData[building].val = GraphDataService.procesChartData(buildingTrafficData[building].val, false, false);
      buildingAvgTrafficData[building].val = GraphDataService.procesChartData((buildingTrafficData[building].val / diffDate), false, false);
      buildingAvgTrafficData[building].changed = GraphDataService.procesChartData((buildingTrafficData[building].changed / (diffDate - 1)), false, false);
      buildingNetShoppingData[building].val = GraphDataService.procesChartData(buildingNetShoppingData[building].val / (60 * 60), false, false);
    });
    // if (lockNum < this.monthEntranceExitLock) { return; }
    this.currentGoalAvgTrafficBuildingData2 = buildingAvgTrafficData;
    this.currentGoalTrafficBuildingData2 = buildingTrafficData;
  }

  async fetchMonthEntranceExitData2(date: moment.Moment, lockNum: number, goalStartDate: moment.Moment) {
    const numInterval = date.isSame(goalStartDate, 'd') ? 1 : date.diff(goalStartDate, 'd') + 1; //(for any goal)
    const qParams = { start_date: date.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }

  //#endregion goal building/entrance-exit

  //#region prediction goal building/entrance-exit tricky
  async loadPredictionGoalBuildingEntranceExitData2(date: moment.Moment, instance?: GraphDataService, goalDuration?: { startDate: string; endDate: string; excludeDate?: string[] }) {
    const graphDataServiceInstance = instance || this;
    const goalStartDate = goalDuration.startDate;
    const goalEndDate = goalDuration.endDate;
    if (!goalStartDate || !goalEndDate) {
      ++graphDataServiceInstance.predGoalBuildingEntranceExitLock2;
      return;
    }
    const current_date = moment().clone().subtract(1, 'd');
    const momentGoalEndDate = moment(goalEndDate, 'MM-DD-YYYY');
    return graphDataServiceInstance.fetchPredictionGoalBuildingEntranceExitData2(date, ++graphDataServiceInstance.predGoalBuildingEntranceExitLock2, current_date, momentGoalEndDate)
      .then(([data, lockNum]) => graphDataServiceInstance.derivePredictionGoalBuildingEntranceExitData2(data, lockNum, momentGoalEndDate, current_date, goalDuration));
  }

  derivePredictionGoalBuildingEntranceExitData2(entranceExitMonthDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number, date: moment.Moment, startDate: moment.Moment, goalDuration: { startDate: string; endDate: string; excludeDate?: string[] }) {
    const buildingNameSet = new Set<string>();
    const momentExcludeDate = goalDuration?.excludeDate.map(d => moment(d, 'MM-DD-YYYY'));
    const tempGoalAvgTrafficBuildingData = this.currentGoalAvgTrafficBuildingData2;
    const tempGoalTrafficBuildingData = this.currentGoalTrafficBuildingData2;
    const buildingList = Object.keys(this.configDataService.FLOOR_OBJECTS);
    buildingList.forEach(buildingName => {
      buildingNameSet.add(buildingName);
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const buildingTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const finalBuildingTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingAvgTrafficData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    const buildingNetShoppingData: { [buildingName: string]: { val: number; changed: number } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { val: 0, changed: 0 };
      return prev;
    }, {});
    if (!entranceExitMonthDatas) {
      return;
    }
    if (entranceExitMonthDatas.length === 0) {
      return;
    }
    const latest_data = entranceExitMonthDatas[0];
    const latest_moment = moment(`${latest_data.year}-${latest_data.month}-${latest_data.day}`, 'YYYY-MM-DD');
    const diffDate = latest_moment.diff(startDate, 'day') < 1 ? 1 : latest_moment.diff(startDate, 'day');
    for (const momentIt = startDate; momentIt <= latest_moment; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitMonthDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = latest_moment.diff(startDate, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const dataItDate = moment(`${dataIt.month}-${dataIt.day}-${dataIt.year}`, 'MM-DD-YYYY');
        if (momentExcludeDate.some(d => d.isSame(dataItDate))) {
          continue;
        }
        const entranceExitData = dataIt.data;
        Object.keys(buildingTrafficData).forEach(building => {
          if (diffPrevDate === -1) {
            if (!entranceExitData[building]) {
              buildingTrafficData[building].changed = 0;
              buildingNetShoppingData[building].changed = 0;
            } else {
              buildingTrafficData[building].changed = entranceExitData[building].entrance;
              buildingNetShoppingData[building].changed = GraphDataService.procesChartData((entranceExitData[building].net_shopping_time / (60 * 60)), false, false);
            }
          }
          if (!entranceExitData[building]) {
            buildingTrafficData[building].val += 0;
            buildingNetShoppingData[building].val += 0;
          } else {
            buildingTrafficData[building].val += entranceExitData[building].entrance;
            buildingNetShoppingData[building].val += entranceExitData[building].net_shopping_time;
          }

        });
      }
    }
    Object.keys(buildingTrafficData).forEach(building => {
      const avgPredBuildingTraffic = buildingTrafficData[building].val / diffDate;
      const avgPredBuildingTrafficChanged = (buildingTrafficData[building]?.changed || 0) / diffDate;
      const avgBuildingTrafficData = (((tempGoalAvgTrafficBuildingData?.[building]?.val || 0) + avgPredBuildingTraffic)) / 2;
      const avgBuildingTrafficChangedData = (((tempGoalAvgTrafficBuildingData?.[building]?.changed || 0) + (avgPredBuildingTrafficChanged[building]?.changed || 0))) / 2;
      finalBuildingTrafficData[building].val = GraphDataService.procesChartData(buildingTrafficData[building].val, false, false);
      buildingAvgTrafficData[building].val = GraphDataService.procesChartData(avgBuildingTrafficData, false, false);
      buildingAvgTrafficData[building].changed = GraphDataService.procesChartData(avgBuildingTrafficChangedData, false, false);
      buildingNetShoppingData[building].val = GraphDataService.procesChartData(buildingNetShoppingData[building].val / (60 * 60), false, false);
    });
    // if (lockNum < this.predGoalBuildingEntranceExitLock) { return; }
    this.currentGoalAvgTrafficBuildingData2$.next([tempGoalAvgTrafficBuildingData, buildingAvgTrafficData]);
    this.currentGoalTrafficBuildingData2$.next([tempGoalTrafficBuildingData, finalBuildingTrafficData]);
  }

  async fetchPredictionGoalBuildingEntranceExitData2(date: moment.Moment, lockNum: number, goalStartDate: moment.Moment, goalEndDate: moment.Moment) {
    const numInterval = Math.abs(goalEndDate.diff(date, 'd')) + 1; //(for any goal)
    const qParams = { start_date: goalEndDate.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }

  //#endregion prediction goal building/entrance-exit tricky

  //#region overload zone/entrance-exit
  async loadMonthEntranceExitZoneData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    // if (graphDataServiceInstance.entranceExitMonthLastFetch.month === queryDate.month() && graphDataServiceInstance.entranceExitMonthLastFetch.year === queryDate.year() && (Date.now() - graphDataServiceInstance.lastEntranceExitMonthTime <= 5 * 60 * 1000)) { return; }
    return graphDataServiceInstance.fetchMonthEntranceExitZoneData(date, ++graphDataServiceInstance.monthZoneEntranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveMonthEntranceExitZoneData(date, data, lockNum));
  }

  deriveMonthEntranceExitZoneData(date: moment.Moment, monthEntranceExitZoneDatas: IFetchData<BuildingZoneEntranceExitData>[], lockNum: number) {
    // initialize data
    const today = moment().subtract(1, 'day');
    const prevDate = moment().subtract(2, 'day');
    const currentGoalTrafficZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
    const currentGoalNetShoppingTimeZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
    monthEntranceExitZoneDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        if (!currentGoalTrafficZoneData[buildingName]) {
          currentGoalTrafficZoneData[buildingName] = {};
          currentGoalNetShoppingTimeZoneData[buildingName] = {};
        }
        Object.entries(floorData).forEach(([floorName, zoneData]) => {
          if (!currentGoalTrafficZoneData[buildingName][floorName]) {
            currentGoalTrafficZoneData[buildingName][floorName] = {};
            currentGoalNetShoppingTimeZoneData[buildingName][floorName] = {};
          }
          Object.keys(zoneData).forEach((zoneName) => {
            currentGoalTrafficZoneData[buildingName][floorName][zoneName] = { val: 0, changed: 0 };
            currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName] = { val: 0, changed: 0 };
          });
        });
      });
    });

    for (const momentIt = date.clone().startOf('month'); momentIt <= today; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(monthEntranceExitZoneDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = momentIt.diff(prevDate, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const entranceExitZoneData = dataIt.data;
        Object.entries(entranceExitZoneData).forEach(([buildingName, floorData]) => {
          if (!currentGoalTrafficZoneData[buildingName]) {
            currentGoalTrafficZoneData[buildingName] = {};
            currentGoalNetShoppingTimeZoneData[buildingName] = {};
          }
          Object.entries(floorData).forEach(([floorName, zoneData]) => {
            if (!currentGoalTrafficZoneData[buildingName][floorName]) {
              currentGoalTrafficZoneData[buildingName][floorName] = {};
              currentGoalNetShoppingTimeZoneData[buildingName][floorName] = {};
            }
            Object.keys(zoneData).forEach((zoneName) => {
              if (diffPrevDate === -1) {
                currentGoalTrafficZoneData[buildingName][floorName][zoneName].changed = entranceExitZoneData[buildingName][floorName][zoneName].entrance;
                currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName].changed = entranceExitZoneData[buildingName][floorName][zoneName].net_shopping_time;
              }
              currentGoalTrafficZoneData[buildingName][floorName][zoneName].val += entranceExitZoneData[buildingName][floorName][zoneName].entrance;
              currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName].val += entranceExitZoneData[buildingName][floorName][zoneName].net_shopping_time;
            });
          });
        });
      }
    }

    const exportCurrentGoalNetShoppingTimeZoneData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } };
    } = Object.entries(currentGoalNetShoppingTimeZoneData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, zoneCurrentData]) => {
          prev[buildingName][floorName][zoneName] = {
            val: GraphDataService.procesChartData(zoneCurrentData.val / (60 * 60), false, false),
            changed: GraphDataService.procesChartData(zoneCurrentData.changed / (60 * 60), false, false),
          };
        });
      });
      return prev;
    }, Object.entries(currentGoalNetShoppingTimeZoneData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.keys(zoneData).reduce((accZone, zoneName) => {
          accZone[zoneName] = {};
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    if (lockNum < this.monthZoneEntranceExitLock) { return; }
    this.currentZoneTrafficGoalData$.next(currentGoalTrafficZoneData);
    this.currentZoneNetShoppingHourGoalData$.next(exportCurrentGoalNetShoppingTimeZoneData);
  }

  async fetchMonthEntranceExitZoneData(date: moment.Moment, lockNum: number) {
    const queryDate = date.clone();
    const qParams = {
      periodType: 'day',
      start_date: queryDate.endOf('months').format('YYYY-MM-DD'),
      num_interval: queryDate.daysInMonth(),
    };
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingZoneEntranceExitData>[], number];
  }

  //#endregion overload zone-entrance-exit

  //#region overload overall/visitor-profile
  async loadMonthVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    // if (graphDataServiceInstance.entranceExitMonthLastFetch.month === queryDate.month() && graphDataServiceInstance.entranceExitMonthLastFetch.year === queryDate.year() && (Date.now() - graphDataServiceInstance.lastEntranceExitMonthTime <= 5 * 60 * 1000)) { return; }
    return graphDataServiceInstance.fetchMonthVisitorProfileData(date, ++graphDataServiceInstance.visitorProfileLock).then(([data, lockNum]) => graphDataServiceInstance.deriveMonthVisitorProfileData(date, data, lockNum));
  }

  async deriveMonthVisitorProfileData(date: moment.Moment, monthVisitorProfileDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const today = moment().subtract(1, 'day');
    const prevDate = moment().subtract(2, 'day');
    const currentTeenagerGoalData: { teenager: { traffic: number; changed: number } } = { teenager: { traffic: 0, changed: 0 } };
    for (const momentIt = date.clone().startOf('month'); momentIt <= today; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(monthVisitorProfileDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = momentIt.diff(prevDate, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const visitorProfileData = dataIt.data;
        visitorProfileData.forEach(profile => {
          if (compare1DepthObjects(profile.group, { age: 'teenagers' })) {
            if (diffPrevDate === -1) {
              currentTeenagerGoalData.teenager.changed = profile.count;
            }
            currentTeenagerGoalData.teenager.traffic += profile.count;
          }
        });
      }
    }
    if (lockNum < this.monthVisitorProfileLock) { return; }
    this.currentTeenagerGoalData$.next({
      teenager: {
        val: GraphDataService.procesChartData(currentTeenagerGoalData.teenager.traffic, false, false),
        changed: GraphDataService.procesChartData(currentTeenagerGoalData.teenager.changed, false, false),
      }
    });
  }

  async fetchMonthVisitorProfileData(date: moment.Moment, lockNum: number) {
    const queryDate = date.clone();
    const qParams = {
      periodType: 'day',
      start_date: queryDate.endOf('month').format('YYYY-MM-DD'),
      num_interval: queryDate.daysInMonth(),
    };
    const profile_cross_level = 1;
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}&profile_cross_level=${profile_cross_level}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion overload overall/visitor-profile

  //#region customer-segregation overload
  async loadMultipleDayCustomerSegregationData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchMultipleDayCustomerSegregationData(date, ++graphDataServiceInstance.customerSegregationLock).then(([data, lockNum]) => graphDataServiceInstance.deriveMultipleDayCustomerSegregationData(data, lockNum));
  }

  deriveMultipleDayCustomerSegregationData(customerSegregationDatas: IFetchData<CustomerSegregationData>[], lockNum: number) {
    const pairDayCustomerSegreationData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(customerSegregationDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const customerSegregationData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        pairDayCustomerSegreationData[diffToSelectedDate + 1] = customerSegregationData.asian_tourists + customerSegregationData.black_tourists + customerSegregationData.indian_tourists + customerSegregationData.middle_eastern_tourists + customerSegregationData.white_tourists;
      }
    });
    if (lockNum < this.multipleDayCustomerSegregationLock) { return; }
    const diffInPercent = ((pairDayCustomerSegreationData[1] - pairDayCustomerSegreationData[0]) / pairDayCustomerSegreationData[1]) * 100;
    this.currentTouristData$.next({
      tourist: GraphDataService.procesChartData(pairDayCustomerSegreationData[1], false, false),
      diffPercent: GraphDataService.procesChartData(diffInPercent, true, true)
    });
  }

  async fetchMultipleDayCustomerSegregationData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/customer-segregation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<CustomerSegregationData>[], number];
  }
  //#endregion customer-segregation for scorecard-report

  //#region zone/visitor-profile profile-cross = 1
  async loadZoneVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedDirectory = graphDataServiceInstance.selectedDirectory$.getValue();
    if (!selectedDirectory || !selectedDirectory.zone) {
      // clear data
      ++graphDataServiceInstance.zoneVisitorProfileLock;
      const clearData = {};
      graphDataServiceInstance.zoneAgeProfileData$.next(clearData);
      return Promise.resolve();
    }
    const area = selectedDirectory.zone;
    return graphDataServiceInstance.fetchZoneVisitorProfileData(date, ++graphDataServiceInstance.zoneVisitorProfileLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneVisitorProfileData(data, lockNum));
  }

  async deriveZoneVisitorProfileData(zoneVisitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const ageProfileDatas = {};
    const ageProfileLineChartData = {};
    const genderProileDatas = {};
    const ethnicityProfileDatas = {};
    // await this.configDataService.loadAppConfig();
    this.configDataService.AGE_CLASS.forEach((age) => {
      ageProfileLineChartData[age] = [];
    });
    GraphDataService.mapSevenDayLineChartData(zoneVisitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || diffToSelectedDate > 0 ? null : 0;
        this.configDataService.AGE_CLASS.forEach((age) => {
          ageProfileLineChartData[age].push(fillValue);
        });
        return;
      }
      const zoneVisitorProfileData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        this.configDataService.AGE_CLASS.forEach((age) => {
          ageProfileDatas[age] = 0;
        });
        zoneVisitorProfileData.forEach(profile => {
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profile.group, { age })) {
              ageProfileDatas[age] = GraphDataService.procesChartData(profile.entrance, false, true);
            }
          });

          this.configDataService.GENDER_CLASS.forEach(gender => {
            if (compare1DepthObjects(profile.group, { gender })) {
              genderProileDatas[gender] = GraphDataService.procesChartData(profile.entrance, false, true);
            }
          });
          // Non-Asian data
          this.configDataService.ETHNICITY_CLASS.forEach(ethnicity => {
            if (compare1DepthObjects(profile.group, { ethnicity })) {
              if (ethnicity !== 'asian') {
                ethnicityProfileDatas[ethnicity] = GraphDataService.procesChartData(profile.entrance, false, true);
              }
            }
          });
        });
      }
      if (diffToSelectedDate < 1) {
        zoneVisitorProfileData.forEach(profile => {
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profile.group, { age })) {
              ageProfileLineChartData[age].push(GraphDataService.procesChartData(profile.entrance, false, false));
            }
          });
        });
      }
    });
    if (Object.keys(ageProfileDatas).length === 0 || Object.keys(ethnicityProfileDatas).length === 0 || Object.keys(genderProileDatas).length === 0) {
      return;
    }
    const allGenderProfiles = Object.values(genderProileDatas).reduce((a: number, b: number) => a + b) as number;
    const allEnthicityProfiles = Object.values(ethnicityProfileDatas).reduce((a: number, b: number) => a + b) as number;
    const allAgeProfile = Object.values(ageProfileDatas).reduce((a: number, b: number) => a + b) as number;
    Object.entries(ageProfileDatas).forEach(([age, val]) => {
      const calPercecnt = ((val as number) / allAgeProfile) * 100;
      ageProfileDatas[age] = GraphDataService.procesChartData(calPercecnt, false, true);
    });
    Object.entries(genderProileDatas).forEach(([gender, val]) => {
      const calPercecnt = ((val as number) / allGenderProfiles) * 100;
      genderProileDatas[gender] = GraphDataService.procesChartData(calPercecnt, false, false);
    });
    Object.entries(ethnicityProfileDatas).forEach(([enthnicity, val]) => {
      const calPercecnt = ((val as number) / allEnthicityProfiles) * 100;
      ethnicityProfileDatas[enthnicity] = GraphDataService.procesChartData(calPercecnt, false, false);
    });
    if (lockNum < this.zoneVisitorProfileLock) { return; }
    this.zoneAgeProfileData$.next(ageProfileDatas);
    this.zoneGenderProfileData$.next(genderProileDatas);
    this.zoneEthnicityProfileData$.next(ethnicityProfileDatas);
    this.zoneAgeProfileLineData$.next(ageProfileLineChartData);
  }

  async fetchZoneVisitorProfileData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getLineChartQueryParameter(date);
    const profile_cross_level = 1;
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&profile_cross_level=${profile_cross_level}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }

  //#endregion zone/visitor-profile

  //#region floor-traffic-data
  // async loadTrafficFloorData(date: moment.Moment, instance?: GraphDataService) {
  //   const graphDataServiceInstance = instance || this;
  //   if (await graphDataServiceInstance.isMock(GraphDependency.FLOOR_TRAFFIC)) {
  //     const floorHeadcountData = await generateTrafficFloorData(date, graphDataServiceInstance.configDataService);
  //     graphDataServiceInstance.floorHeadcountData$.next(floorHeadcountData);
  //     console.log('mocked data', floorHeadcountData);
  //     return;
  //   } else {
  //     // TODO: waiting for endpoint
  //     // return graphDataServiceInstance.fetchTrafficFloorData(date, ++graphDataServiceInstance.trafficDataLock).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficFloorData(data, lockNum));
  //   }
  // }

  // async deriveTrafficFloorData(trafficFloorDatas: IFetchData[], lockNum: number) {
  //   // TODO: waiting for endpoint
  // }

  // async fetchTrafficFloorData(date: moment.Moment, lockNum: number) {
  //   // TODO: waiting for endpoint
  // }
  //#endregion floor-traffic-data

  //#region model3d
  async loadModel3dDetail(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchModel3dDetail(date, ++graphDataServiceInstance.model3dLoadingLock).then(([data, lockNum]) => graphDataServiceInstance.deriveModel3dDetail(data, lockNum));
  }

  deriveModel3dDetail(model3dDetail: Model3dDetail, lockNum: number) {
    if (!model3dDetail) { return; }
    if (lockNum < this.model3dLoadingLock) { return; }
    if (this.model3dDetail$.value !== model3dDetail) {
      this.model3dDetail$.next(model3dDetail);
    }
  }

  async fetchModel3dDetail(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const building = this.configDataService.selectedOrganization$.getValue();
    const fetchURL = `/retail_customer_api_v2/api/v2/info/model3d?start_date=${qParams.start_date}${building ? `&building=${building}` : ''}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [Model3dDetail, number];
  }
  //#endregion model3d

  //#region plate/timespent
  async loadPlateTimespentData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPlateTimespentData(date, ++graphDataServiceInstance.plateTimespentLock).then(([data, lockNum]) => graphDataServiceInstance.derivePlateTimespentData(data, lockNum));
  }

  async derivePlateTimespentData(plateTimespentDatas: IFetchData<PlateTimespentData>[], lockNum: number) {
    const binNameSet = new Set<string>();
    const binNameList = this.configDataService.BIN_TIMESPENT_LIST;
    binNameList.forEach(binName => {
      binNameSet.add(binName);
    });
    const binNames = Array.from(binNameSet.values());
    const plateTimespentBinData: { [binName: string]: number } = binNames.reduce((prev, binName) => {
      prev[binName] = 0;
      return prev;
    }, {});
    if (Object.keys(plateTimespentBinData).length < 1) {
      this.plateTimespentBinData$.next(null);
      return;
    }
    GraphDataService.mapSevenDayLineChartData(plateTimespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const plateTimespentData = dataFiltered.data;
      Object.keys(plateTimespentBinData).forEach(binKey => {
        if (!plateTimespentData[binKey]) {
          return;
        }
        plateTimespentBinData[binKey] = GraphDataService.procesChartData((plateTimespentData[binKey].count / plateTimespentData._total.count) * 100, false, false);
      });
    });
    if (lockNum < this.plateTimespentLock) { return; }
    this.plateTimespentBinData$.next(plateTimespentBinData);
  }

  async fetchPlateTimespentData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/timespent?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<PlateTimespentData>[], number];
  }

  //#endregion plage/timespent

  //#region building/entrance-exit-average-by-day-type
  async loadBuildingEntranceExitAverageDayType(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingEntranceExitAverageDayTypeData(date, ++graphDataServiceInstance.buildingAverageDayTypeLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingEntranceExitAverageDayTypeData(data, lockNum));
  }

  async deriveBuildingEntranceExitAverageDayTypeData(buildingAverageDayTypeDatas: IFetchData<BuildingAverageDayTypeData>[], lockNum: number) {
    const averageWeekDayWeekEndCount: number[] = [];
    // await this.configDataService.loadAppConfig();
    const buildingNameSet = new Set<string>();
    buildingAverageDayTypeDatas.forEach(data => {
      Object.keys(data.data).forEach(binName => {
        buildingNameSet.add(binName);
      });
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const currentBuildingAverageWeekDayPairData: { [buildingName: string]: [number, number] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [0, 0];
      return prev;
    }, {});
    const currentBuildingAverageWeekEndPairData: { [buildingName: string]: [number, number] } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = [0, 0];
      return prev;
    }, {});
    GraphDataService.mapSevenDayLineChartData(buildingAverageDayTypeDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const buildingAverageDayTypeData = dataFiltered.data;
      Object.keys(buildingAverageDayTypeData).forEach(buildingName => {
        const fillObj = { entrance: fillValue, exit: fillValue, average_timespent: fillValue, net_shopping_time: fillValue };
        const entranceExitBuildingAverageDayTypeData = buildingAverageDayTypeData[buildingName] || { weekday: fillObj, weekend: fillObj };
        if ((diffToSelectedDate === -1 || diffToSelectedDate === 0)) {
          currentBuildingAverageWeekDayPairData[buildingName][diffToSelectedDate + 1] = entranceExitBuildingAverageDayTypeData?.weekday?.entrance || 0;
          currentBuildingAverageWeekEndPairData[buildingName][diffToSelectedDate + 1] = entranceExitBuildingAverageDayTypeData?.weekend?.entrance || 0;
        }
      });
    });
    const currentBuildingAverageWeekDayData: { [buildingName: string]: { headCount: number; diff: number; diffPercent: number } } = Object.entries(currentBuildingAverageWeekDayPairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        headCount: GraphDataService.procesChartData(buildingPairData[1]),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[1] * 100, true, true)
      };
      // averageWeekDayWeekEndCount.push(prev[buildingName].headCount)
      return prev;
    }, {});
    const currentBuildingAverageWeekEndData: { [buildingName: string]: { headCount: number; diff: number; diffPercent: number } } = Object.entries(currentBuildingAverageWeekEndPairData).reduce((prev, [buildingName, buildingPairData]) => {
      const percentage = buildingPairData[1] === 0 ? ((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0]) * 100 : ((buildingPairData[1] - buildingPairData[0]) / buildingPairData[1]) * 100;
      prev[buildingName] = {
        headCount: GraphDataService.procesChartData(buildingPairData[1]),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData(percentage, true, true)
      };
      // averageWeekDayWeekEndCount.push(prev[buildingName].headCount)
      return prev;
    }, {});
    // this.buildingAverageWeekDayWeekEndCount$.next(averageWeekDayWeekEndCount);
    if (lockNum < this.buildingAverageDayTypeLock) { return; }
    this.buildingAverageWeekDayLast7DaysData$.next(currentBuildingAverageWeekDayData);
    this.buildingAverageWeekEndLast7DaysData$.next(currentBuildingAverageWeekEndData);
  }

  async fetchBuildingEntranceExitAverageDayTypeData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit-average-by-day-type?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingAverageDayTypeData>[], number];
  }

  //#endregion building/entrance-exit-average-by-day-type

  //#region plate/frequency-of-visit-semi-annual/ (frequency of visit last 180 days)
  async loadFrequencyOfVisitSemiAnnualData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'vehicle_parking') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.fetchFrequencyOfVisitSemiAnnualData(date, ++graphDataServiceInstance.frequencyOfVisitSemiAnnualLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveFrequencyOfVisitSemiAnnualData(data, lockNum));
    } else {
      return graphDataServiceInstance.fetchFrequencyOfVisitSemiAnnualData(date, ++graphDataServiceInstance.frequencyOfVisitSemiAnnualLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFrequencyOfVisitSemiAnnualData(data, lockNum));
    }
    // return graphDataServiceInstance.fetchFrequencyOfVisitSemiAnnualData(date, ++graphDataServiceInstance.frequencyOfVisitSemiAnnualLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFrequencyOfVisitSemiAnnualData(data, lockNum));
  }

  deriveFrequencyOfVisitSemiAnnualData(frequencyOfVisitDatas: IFetchData<FrequencyOfVisitData>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(frequencyOfVisitDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) {
      this.frequencyOfVisitSemiAnnualData$.next({ one: 0, two_three: 0, four_up: 0 });
      return;
    }
    if (this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT) {
      if (momentIt.isBefore(this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT)) {
        this.frequencyOfVisitSemiAnnualData$.next({ one: 0, two_three: 0, four_up: 0 });
        return;
      }
    }
    const frequencyOfVisitData = dataIt.data;
    const frequencyOfVisitChartData = { one: 0, two_three: 0, four_up: 0 };
    for (const [timesString, count] of Object.entries(frequencyOfVisitData.frequency_of_visit)) {
      const times = parseInt(timesString, 10);
      if (times === 1) {
        frequencyOfVisitChartData.one = (frequencyOfVisitChartData.one || 0) + count;
      } else if (times >= 2 && times <= 3) {
        frequencyOfVisitChartData.two_three = (frequencyOfVisitChartData.two_three || 0) + count;
      } else if (times >= 4 && times <= 50) {
        frequencyOfVisitChartData.four_up = (frequencyOfVisitChartData.four_up || 0) + count;
      }
    }
    const totalUniqueVistors = frequencyOfVisitData.total_unique_visitors;

    Object.keys(frequencyOfVisitChartData).forEach(key => {
      const freqChartPercentage = (frequencyOfVisitChartData[key] / totalUniqueVistors) * 100;
      frequencyOfVisitChartData[key] = GraphDataService.procesChartData(freqChartPercentage, false, false);
    });
    if (lockNum < this.frequencyOfVisitSemiAnnualLock) { return; }
    this.frequencyOfVisitSemiAnnualData$.next(frequencyOfVisitChartData);
  }

  async fetchFrequencyOfVisitSemiAnnualData(date: moment.Moment, lockNum: number, area?: string) {
    let fetchURL = '';
    const qParams = this.getOneSelectedQueryParameter(date);
    if (area) {
      fetchURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit-v2?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    } else {
      fetchURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit-v2?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    }
    // const fetchURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit-v2?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<FrequencyOfVisitData>[], number];
  }

  //#endregion plate/frequency-of-visit-semi-annual/

  //#region plate/frequency-of-visit-by-purchasing-power-semi-annual/ (Frequency of visit by purchasing power last 180 days)
  async loadFrequencyOfVisitWithPurchasingPowerData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFrequencyOfVisitWithPurchasingPowerData(date, ++graphDataServiceInstance.frequencyOfVisitWithPurchasingPowerLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFrequencyOfVisitWithPurchasingPowerData(data, lockNum));
  }

  async deriveFrequencyOfVisitWithPurchasingPowerData(frequencyOfVisitWithPurchasingPowerDatas: IFetchData<FrequencyOfVisitWithPurchasingPowerData>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(frequencyOfVisitWithPurchasingPowerDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    const defaultTierName = ['economy', 'premium', 'luxury'];
    const defaultFrequencyOfVisits = ['one', 'two', 'three', 'four', 'four', 'five', 'six_up'];
    const defaultFrequencyOfVisitTripleBarData: { [freqName: string]: { [tierName: string]: number } } = defaultFrequencyOfVisits.reduce((prev, freqName) => {
      const tierObj = defaultTierName.reduce((prevSmall, tierName) => {
        prevSmall[tierName] = 0;
        return prevSmall;
      }, {});
      prev[freqName] = tierObj;
      return prev;
    }, {});
    if (!dataIt || !dataIt.data) {
      this.frequencyOfVisitWithPurchasingPowerData$.next(defaultFrequencyOfVisitTripleBarData);
      return;
    }
    if (this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT) {
      if (momentIt.isBefore(this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT)) {
        this.frequencyOfVisitWithPurchasingPowerData$.next(defaultFrequencyOfVisitTripleBarData);
        return;
      }
    }
    const frequencyOfVisitPurchasingData = dataIt.data;
    defaultTierName.forEach(key => {
      if (!frequencyOfVisitPurchasingData[key]) {
        return;
      }
      for (const [timesString, count] of Object.entries(frequencyOfVisitPurchasingData[key].frequency_of_visit)) {
        const times = parseInt(timesString, 10);
        if (times === 1) {
          defaultFrequencyOfVisitTripleBarData.one[key] = (defaultFrequencyOfVisitTripleBarData.one[key] || 0) + count;
        } else if (times === 2) {
          defaultFrequencyOfVisitTripleBarData.two[key] = (defaultFrequencyOfVisitTripleBarData.two[key] || 0) + count;
        } else if (times === 3) {
          defaultFrequencyOfVisitTripleBarData.three[key] = (defaultFrequencyOfVisitTripleBarData.three[key] || 0) + count;
        } else if (times === 4) {
          defaultFrequencyOfVisitTripleBarData.four[key] = (defaultFrequencyOfVisitTripleBarData.four[key] || 0) + count;
        } else if (times === 5) {
          defaultFrequencyOfVisitTripleBarData.five[key] = (defaultFrequencyOfVisitTripleBarData.five[key] || 0) + count;
        } else if (times >= 6 && times <= 50) {
          defaultFrequencyOfVisitTripleBarData.six_up[key] = (defaultFrequencyOfVisitTripleBarData.six_up[key] || 0) + count;
        }
      }
    });
    defaultTierName.forEach(tier => {
      if (!frequencyOfVisitPurchasingData[tier]) {
        return;
      }
      const totalUniqueVistors = frequencyOfVisitPurchasingData[tier].total_unique_visitors;
      Object.keys(defaultFrequencyOfVisitTripleBarData).forEach(freqKey => {
        const freqChartPercentage = (defaultFrequencyOfVisitTripleBarData[freqKey][tier] / totalUniqueVistors) * 100;
        defaultFrequencyOfVisitTripleBarData[freqKey][tier] = GraphDataService.procesChartData(freqChartPercentage, false, false);
      });
    });
    if (lockNum < this.frequencyOfVisitWithPurchasingPowerLock) { return; }
    this.frequencyOfVisitWithPurchasingPowerData$.next(defaultFrequencyOfVisitTripleBarData);
  }

  async fetchFrequencyOfVisitWithPurchasingPowerData(date: moment.Moment, lockNum: number) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit-by-purchasing-power-v2?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<FrequencyOfVisitWithPurchasingPowerData>[], number];
  }
  //#endregion plate/frequency-of-visit-by-purchasing-power-semi-annual/

  //#region zone/visitor-profile-sample profile_cross_level=1 
  async loadZoneVisitorProfileSampleData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaList = graphDataServiceInstance.configDataService.ZONE_NAME_LIST;
    const areas = graphDataServiceInstance.configDataService.PROFILE_BY_AREA_LIST.filter(area => areaList.includes(area));
    if (areas.length === 0) {
      return;
    }
    areas.forEach(area => {
      let initzoneVisitorProfileSampleLock = 0;
      graphDataServiceInstance.fetchZoneVisitorProfileSampleData(date, ++initzoneVisitorProfileSampleLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneVisitorProfileSampleData(data, lockNum));
    });
    return Promise.resolve();
  }

  deriveZoneVisitorProfileSampleData(zoneVisitorProfileDatas: IFetchData<AreaVisitorProfileSampleData[]>[], lockNum: number) {
    const ageProfileDatas = {};
    const genderProfileDatas = {};
    const ethnicityProfileDatas = {};
    GraphDataService.mapSevenDayLineChartData(zoneVisitorProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        // const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const zoneVisitorProfileData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        this.configDataService.AGE_CLASS.forEach((age) => {
          ageProfileDatas[age] = 0;
        });
        zoneVisitorProfileData.forEach(profile => {
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profile.group, { age })) {
              ageProfileDatas[age] = GraphDataService.procesChartData(profile.count_percentage * 100, false, true);
            }
          });

          this.configDataService.GENDER_CLASS.forEach(gender => {
            if (compare1DepthObjects(profile.group, { gender })) {
              genderProfileDatas[gender] = GraphDataService.procesChartData(profile.count_percentage * 100, false, true);
            }
          });
          // Non-Asian data
          this.configDataService.ETHNICITY_CLASS.forEach(ethnicity => {
            if (compare1DepthObjects(profile.group, { ethnicity })) {
              if (ethnicity !== 'asian') {
                ethnicityProfileDatas[ethnicity] = GraphDataService.procesChartData(profile.count_percentage * 100, false, true);
              }
            }
          });
        });
      }
      this.areaAgeProfileSampleData[dataFiltered.zone] = ageProfileDatas;
      this.areaGenderProfileSampleData[dataFiltered.zone] = genderProfileDatas;
    });
    if (lockNum < this.zoneVisitorProfileSampleLock) { return; }
    if (Object.keys(ageProfileDatas).length === this.configDataService.AGE_CLASS.length) {
      let check = false;
      Object.keys(this.areaAgeProfileSampleData).forEach(areakey => {
        if (Object.values(this.areaAgeProfileSampleData[areakey]).some(ele => ele !== null)) {
          check = true;
        }
        else {
          check = false;
        }
      });
      if (check) {
        this.areaAgeProfileSampleData$.next(this.areaAgeProfileSampleData);
      }
    }
    if (Object.keys(genderProfileDatas).length === this.configDataService.GENDER_CLASS.length) {
      let check = false;
      Object.keys(this.areaGenderProfileSampleData).forEach(areakey => {
        if (Object.values(this.areaGenderProfileSampleData[areakey]).some(ele => ele !== null)) {
          check = true;
        }
        else {
          check = false;
        }
      });
      if (check) {
        this.areaGenderProfileSampleData$.next(this.areaGenderProfileSampleData);
      }
    }
    // this.areaAgeProfileSampleData$.next(this.areaAgeProfileSampleData);
    // this.areaGenderProfileSampleData$.next(this.areaGenderProfileSampleData);
  }

  async fetchZoneVisitorProfileSampleData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const profile_cross_level = 1;
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/visitor-profile-sample?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&profile_cross_level=${profile_cross_level}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileSampleData[]>[], number];
  }
  //#endregion zone/visitor-profile-sample

  //#region overall/visitor-profile profile_cross_level=1

  async loadVisitorProfileSimpleCrossData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVisitorProfileSimpleCrossData(date, ++graphDataServiceInstance.visitorProfileSampleCrossLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVisitorProfileSimpleCrossData(data, lockNum));
  }

  async deriveVisitorProfileSimpleCrossData(visitorProfileDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const genderProfileData = { male: null as number, female: null as number };
    const messengerTimePairData: [number, number] = [0, 0];
    const teenagerTimePairData: [number, number] = [0, 0];
    const staffTimePairData: [number, number] = [0, 0];
    const maleCountPairData: [number, number] = [0, 0];
    const femaleCountPairData: [number, number] = [0, 0];
    const currentStoreTotalVisitor: [number, number] = [0, 0];
    const maskTimePairData: [number, number] = [0, 0];
    const allMaskCountTimePairData: [number, number] = [0, 0];
    let visitorTimePairData: [number, number] = [0, 0];
    const staffVisitorClassTrendData: { staff: number[]; visitor: number[]; all_visitor: number[] } = { staff: [], visitor: [], all_visitor: [] };
    let ageProfileStackData: { [ageClass: string]: number } = {};
    let genderProfileStackData: { [genderClass: string]: number } = {};
    let ethnicityProfileStackData: { [ethnicityClass: string]: number } = {};
    const currentpurchaseRatePairData: [number, number] = [0, 0];
    const currentTotalVisitor: [number, number] = [0, 0];
    this.unfilteredVisitorProfileData$.next(visitorProfileDatas);
    // // await this.configDataService.loadAppConfig();
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || dataFiltered.data.length < 1 || isPred) {
        // Object.keys(staffVisitorClassTrendData).forEach(k => staffVisitorClassTrendData[k].push(fillValue));
        return;
      }
      genderProfileStackData = GraphDataService.createObjectComprehension(this.configDataService.GENDER_CLASS, 0);
      ageProfileStackData = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
      ethnicityProfileStackData = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
      const visitorProfileData = dataFiltered.data;
      visitorProfileData.forEach(profileData => {
        if (compare1DepthObjects(profileData.group, { profession: 'staff' })) {
          staffVisitorClassTrendData.staff.push(processChartData(profileData.count));
        }
        if (diffToSelectedDate === 0) {
          if (compare1DepthObjects(profileData.group, { gender: 'male' })) {
            maleCountPairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { gender: 'female' })) {
            femaleCountPairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, {})) {
            currentTotalVisitor[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { profession: 'messenger' })) {
            messengerTimePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { profession: 'staff' })) {
            staffTimePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { age: 'teenagers' })) {
            teenagerTimePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { purchase: true })) {
            currentpurchaseRatePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { mask: 'yes' })) {
            maskTimePairData[diffToSelectedDate + 1] = profileData.count;
            allMaskCountTimePairData[diffToSelectedDate + 1] += profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { mask: 'no' })) {
            allMaskCountTimePairData[diffToSelectedDate + 1] += profileData.count;
          }
          // Gender Profile data
          if (compare1DepthObjects(profileData.group, { gender: 'male' })) {
            genderProfileData.male = GraphDataService.procesChartData(profileData.count);
          } else if (compare1DepthObjects(profileData.group, { gender: 'female' })) {
            genderProfileData.female = GraphDataService.procesChartData(profileData.count);
          }
          this.configDataService.GENDER_CLASS.forEach(gender => {
            if (compare1DepthObjects(profileData.group, { gender })) {
              genderProfileStackData[gender] = GraphDataService.procesChartData(profileData.count, false, true);
            }
          });
          // Ethnicity Profile data
          this.configDataService.ETHNICITY_CLASS.forEach(ethnicity => {
            if (compare1DepthObjects(profileData.group, { ethnicity })) {
              if (ethnicity !== 'asian') {
                ethnicityProfileStackData[ethnicity] = GraphDataService.procesChartData(profileData.count, false, true);
              }
            }
          });
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profileData.group, { age })) {
              ageProfileStackData[age] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          });
          const allAgeProfileStackData = Object.values(ageProfileStackData).reduce((a: number, b: number) => a + b) as number;
          const allGenderProfileStackData = Object.values(genderProfileStackData).reduce((a: number, b: number) => a + b) as number;
          const allEthnicityProfileStackData = Object.values(ethnicityProfileStackData).reduce((a: number, b: number) => a + b) as number;
          Object.entries(ageProfileStackData).forEach(([age, val]) => {
            const calPercecnt = ((val as number) / allAgeProfileStackData) * 100;
            ageProfileStackData[age] = GraphDataService.procesChartData(calPercecnt, false, false);
          });
          Object.entries(genderProfileStackData).forEach(([gender, val]) => {
            const calPercecnt = ((val as number) / allGenderProfileStackData) * 100;
            genderProfileStackData[gender] = GraphDataService.procesChartData(calPercecnt, false, false);
          });
          Object.entries(ethnicityProfileStackData).forEach(([ethnicity, val]) => {
            const calPercecnt = ((val as number) / allEthnicityProfileStackData) * 100;
            ethnicityProfileStackData[ethnicity] = GraphDataService.procesChartData(calPercecnt, false, false);
          });
        }
        if (diffToSelectedDate === -1) {
          if (compare1DepthObjects(profileData.group, { gender: 'male' })) {
            maleCountPairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { gender: 'female' })) {
            femaleCountPairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, {})) {
            currentTotalVisitor[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { age: 'teenagers' })) {
            teenagerTimePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { purchase: true })) {
            currentpurchaseRatePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { profession: 'messenger' })) {
            messengerTimePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { profession: 'staff' })) {
            staffTimePairData[diffToSelectedDate + 1] = profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { mask: 'yes' })) {
            maskTimePairData[diffToSelectedDate + 1] = profileData.count;
            allMaskCountTimePairData[diffToSelectedDate + 1] += profileData.count;
          }
          if (compare1DepthObjects(profileData.group, { mask: 'no' })) {
            allMaskCountTimePairData[diffToSelectedDate + 1] += profileData.count;
          }
        }
      });
    });
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    this.areaAgeProfileSampleData[mainBuilding] = ageProfileStackData;
    this.areaGenderProfileSampleData[mainBuilding] = genderProfileStackData;
    if (lockNum < this.visitorProfileSampleCrossLock) { return; }

    if (Object.keys(ageProfileStackData).length === this.configDataService.AGE_CLASS.length) {
      let check = false;
      Object.keys(this.areaAgeProfileSampleData).forEach(areakey => {
        if (Object.values(this.areaAgeProfileSampleData[areakey]).some(ele => ele !== null)) {
          check = true;
        }
        else {
          check = false;
        }
      });
      if (check) {
        this.areaAgeProfileSampleData$.next(this.areaAgeProfileSampleData);
      }
    }
    if (Object.keys(genderProfileStackData).length === this.configDataService.GENDER_CLASS.length) {
      let check = false;
      Object.keys(this.areaGenderProfileSampleData).forEach(areakey => {
        if (Object.values(this.areaGenderProfileSampleData[areakey]).some(ele => ele !== null)) {
          check = true;
        }
        else {
          check = false;
        }
      });
      if (check) {
        this.areaGenderProfileSampleData$.next(this.areaGenderProfileSampleData);
      }
    }

    this.buildingEntranceExitData$.subscribe(buildingEntranceExit => {
      if (buildingEntranceExit) {
        const mainBuildingTraffic = this.configDataService.isEntranceDataMode ? buildingEntranceExit[mainBuilding].entrance : buildingEntranceExit[mainBuilding].exit;
        if (mainBuildingTraffic.length > 0) {
          staffVisitorClassTrendData.all_visitor = mainBuildingTraffic;
          staffVisitorClassTrendData.visitor = manipulateArrays(mainBuildingTraffic.filter(val => val !== null), staffVisitorClassTrendData.staff, 'subtract');
          if (staffVisitorClassTrendData.visitor.length > 0) {
            visitorTimePairData = [staffVisitorClassTrendData.visitor[staffVisitorClassTrendData.visitor.length - 3], staffVisitorClassTrendData.visitor[staffVisitorClassTrendData.visitor.length - 2]];
          }
          const diffVisitorData = visitorTimePairData[1] - visitorTimePairData[0];
          const diffVisitorPercentageData = (diffVisitorData / visitorTimePairData[0]) * 100;
          this.staffVisitorClassTrendData$.next(staffVisitorClassTrendData);
          this.currentVisitorProfileData$.next({
            visitor: processChartData(visitorTimePairData[1], false, false),
            diff: processChartData(diffVisitorData, true, false),
            diffPercent: processChartData(diffVisitorPercentageData, true, true)
          });
        }
      }
    });
    // this.areaGenderProfileSampleData$.next(this.areaGenderProfileSampleData);
    this.genderProfileStackData$.next(genderProfileStackData);
    const maskPercentage = [(maskTimePairData[0] / allMaskCountTimePairData[0]) * 100, (maskTimePairData[1] / allMaskCountTimePairData[1]) * 100];
    this.currentMaskCount$.next({
      maskCount: GraphDataService.procesChartData(maskPercentage[1], false, true),
      diff: GraphDataService.procesChartData(maskTimePairData[1] - maskTimePairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((maskPercentage[1] - maskPercentage[0]) / maskPercentage[0]) * 100, true, true),
    });
    this.ethnicityProfileStackData$.next(ethnicityProfileStackData);
    this.genderProfileData$.next(genderProfileData);
    this.currentMessengerProfileData$.next({
      messenger: GraphDataService.procesChartData(messengerTimePairData[1], false, false),
      diff: GraphDataService.procesChartData((messengerTimePairData[1] - messengerTimePairData[0]), true, false),
      diffPercent: GraphDataService.procesChartData(((messengerTimePairData[1] - messengerTimePairData[0]) / messengerTimePairData[1]) * 100, true, true),
    });
    this.currentStaffProfileData$.next({
      staff: GraphDataService.procesChartData(staffTimePairData[1], false, false),
      diff: GraphDataService.procesChartData(staffTimePairData[1] - staffTimePairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((staffTimePairData[1] - staffTimePairData[0]) / staffTimePairData[0]) * 100, true, true),
    });
    this.prevStaffProfileData$.next({
      staff: GraphDataService.procesChartData(staffTimePairData[0], false, false),
      diff: 0,
      diffPercent: 0,
    });
    const purchasePercentage = [(currentpurchaseRatePairData[0] / currentTotalVisitor[0]) * 100, (currentpurchaseRatePairData[1] / currentTotalVisitor[1]) * 100];
    this.currentpurchaseRateData$.next({
      purchase: GraphDataService.procesChartData(purchasePercentage[1], false, true),
      diff: GraphDataService.procesChartData(purchasePercentage[1] - purchasePercentage[0], true, false),
      diffPercent: GraphDataService.procesChartData(((purchasePercentage[1] - purchasePercentage[0]) / purchasePercentage[0] * 100) || 0, true, true)
    });
    const malePercentage = [(maleCountPairData[0] / currentTotalVisitor[0] * 100), (maleCountPairData[1] / currentTotalVisitor[1] * 100)];
    const femalePercentage = [(femaleCountPairData[0] / currentTotalVisitor[0] * 100), (femaleCountPairData[1] / currentTotalVisitor[1] * 100)];
    this.maleBuildingTrafficCount$.next({
      current: GraphDataService.procesChartData(maleCountPairData[1], false, false),
      diff: GraphDataService.procesChartData(maleCountPairData[1] - maleCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((malePercentage[1] - malePercentage[0]) / malePercentage[0] * 100) || 0, true, true)
    });
    this.femaleBuildingTrafficCount$.next({
      current: GraphDataService.procesChartData(femaleCountPairData[1], false, false),
      diff: GraphDataService.procesChartData(femaleCountPairData[1] - femaleCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(femalePercentage[0] !== 0 ? ((femalePercentage[1] - femalePercentage[0]) / femalePercentage[0] * 100) : 0, true, true)
    });
  }

  async deriveSelectedVisitorProfileSimpleCrossData(visitorProfileDatas: IFetchData<VisitorProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const trafficTrendLineChartData: number[] = [];
    const currentVisitorTrafficTrendtPair: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficTrendLineChartData.push(fillValue);
        return;
      }
      let isFound = false;
      const visitorProfileData = dataFiltered.data;
      for (const profileData of visitorProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficTrendLineChartData.push(profileData.count);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentVisitorTrafficTrendtPair[diffToSelectedDate + 1] = profileData.count;
          }
          break;
        }
      }
      if (!isFound) {
        trafficTrendLineChartData.push(0);
      }
    });
    // if (lockNum < this.entranceExitLock) { return; }
    this.visitorTrafficTrendData$.next(GraphDataService.procesChartData(trafficTrendLineChartData));
    this.currentVisitorTrafficTrendtData$.next({
      headCount: GraphDataService.procesChartData(currentVisitorTrafficTrendtPair[1]),
      diff: GraphDataService.procesChartData(Math.round(currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]), true),
      diffPercent: GraphDataService.procesChartData(((currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]) / currentVisitorTrafficTrendtPair[0] * 100) || 0, true, true)
    });
  }

  async fetchVisitorProfileSimpleCrossData(date: moment.Moment, lockNum: number) {
    const qParams = this.getLineChartQueryParameter(date);
    /**
     * profile_cross_level: Number | null | undefined is added to endpoints that return profile information (data is a list of objects that have key group that is a dict)
     */
    const profile_cross_level = 1;
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&profile_cross_level=${profile_cross_level}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion overall/visitor-profile profile_cross_level=1

  //#region overall/visitor-profile profile_cross_level=2

  async loadVisitorProfileTwoCrossData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.router.url === '/customer') {
      if (graphDataServiceInstance.configDataService.isFeatureEnabled('customer_page', 'profile_data_v2')) {
        return;
      }
      return graphDataServiceInstance.fetchVisitorProfileTwoCrossData(date, ++graphDataServiceInstance.visitorProfileTwoCrossLock).then(([data, lockNum]) => {
        graphDataServiceInstance.deriveVisitorProfileTwoCrossData(data, lockNum);
        graphDataServiceInstance.deriveSelectedVisitorProfileTwoCrossData(data, graphDataServiceInstance.selectedVisitorProfile$.getValue());
      });
    };
    return graphDataServiceInstance.fetchVisitorProfileTwoCrossData(date, ++graphDataServiceInstance.visitorProfileTwoCrossLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVisitorProfileTwoCrossData(data, lockNum));
  }

  async deriveVisitorProfileTwoCrossData(visitorProfileDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const ethnicityProfileData = { male: [] as number[], female: [] as number[] };
    const ageProfileData = { male: [] as number[], female: [] as number[] };
    const EthnicityProfilePurchaseTrue: { white: number; indian: number; middle_eastern: number; black: number; asian: number } = { white: 0, indian: 0, middle_eastern: 0, black: 0, asian: 0 };
    const EthnicityProfilePurchaseFalse: { white: number; indian: number; middle_eastern: number; black: number; asian: number } = { white: 0, indian: 0, middle_eastern: 0, black: 0, asian: 0 };
    const EthnicityProfilePurchaseRate: number[] = [];
    this.unfilteredVisitorProfileData$.next(visitorProfileDatas);
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || dataFiltered.data.length < 1 || isPred) {
        return;
      }
      const maleEthnicityProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
      const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
      const visitorProfileData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        const femaleEthnicityProfile = { ...maleEthnicityProfile };
        const femaleAgeProfile = { ...maleAgeProfile };
        visitorProfileData.forEach(profile => {
          // Ethnicity Profile data
          this.configDataService.ETHNICITY_CLASS.forEach(ethnicity => {
            if (compare1DepthObjects(profile.group, { gender: 'male', ethnicity })) {
              maleEthnicityProfile[ethnicity] = GraphDataService.procesChartData(profile.count);
            } else if (compare1DepthObjects(profile.group, { gender: 'female', ethnicity })) {
              femaleEthnicityProfile[ethnicity] = GraphDataService.procesChartData(profile.count);
            }
            if (compare1DepthObjects(profile.group, { purchase: true, ethnicity })) {
              EthnicityProfilePurchaseTrue[ethnicity] = GraphDataService.procesChartData(profile.count);
            } else if (compare1DepthObjects(profile.group, { purchase: false, ethnicity })) {
              EthnicityProfilePurchaseFalse[ethnicity] = GraphDataService.procesChartData(profile.count);
            }
          });
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profile.group, { gender: 'male', age })) {
              maleAgeProfile[age] = GraphDataService.procesChartData(profile.count);
            } else if (compare1DepthObjects(profile.group, { gender: 'female', age })) {
              femaleAgeProfile[age] = GraphDataService.procesChartData(profile.count);
            }
          });
        });

        ethnicityProfileData.male = this.configDataService.ETHNICITY_CLASS.map(ethnicity => maleEthnicityProfile[ethnicity]);
        ethnicityProfileData.female = this.configDataService.ETHNICITY_CLASS.map(ethnicity => femaleEthnicityProfile[ethnicity]);
        ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
        ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
        this.configDataService.ETHNICITY_CLASS.map(ethnicity => {
          const rate = Math.round((EthnicityProfilePurchaseTrue[ethnicity] / (EthnicityProfilePurchaseTrue[ethnicity] + EthnicityProfilePurchaseFalse[ethnicity])) * 100);
          EthnicityProfilePurchaseRate.push(rate);
        });
      }
    });
    if (lockNum < this.visitorProfileTwoCrossLock) { return; }
    this.ethnicityProfilePurchaseRate$.next(EthnicityProfilePurchaseRate);
    this.ethnicityProfileData$.next(ethnicityProfileData);
    this.ageProfileData$.next(ageProfileData);
  }

  async deriveSelectedVisitorProfileTwoCrossData(visitorProfileDatas: IFetchData<VisitorProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const trafficTrendLineChartData: number[] = [];
    const currentVisitorTrafficTrendtPair: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficTrendLineChartData.push(fillValue);
        return;
      }
      let isFound = false;
      const visitorProfileData = dataFiltered.data;
      for (const profileData of visitorProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficTrendLineChartData.push(profileData.count);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentVisitorTrafficTrendtPair[diffToSelectedDate + 1] = profileData.count;
          }
          break;
        }
      }
      if (!isFound) {
        trafficTrendLineChartData.push(0);
      }
    });
    // if (lockNum < this.entranceExitLock) { return; }
    this.visitorTrafficTrendData$.next(GraphDataService.procesChartData(trafficTrendLineChartData));
    this.currentVisitorTrafficTrendtData$.next({
      headCount: GraphDataService.procesChartData(currentVisitorTrafficTrendtPair[1]),
      diff: GraphDataService.procesChartData(Math.round(currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]), true),
      diffPercent: GraphDataService.procesChartData(((currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]) / currentVisitorTrafficTrendtPair[0] * 100) || 0, true, true)
    });
  }

  async fetchVisitorProfileTwoCrossData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = window.location.pathname === '/home' ? this.getCustomSelectedQueryParameter(date, 2) : this.getLineChartQueryParameter(date);
    /**
     * profile_cross_level: Number | null | undefined is added to endpoints that return profile information (data is a list of objects that have key group that is a dict)
     */
    const profile_cross_level = 2;
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&profile_cross_level=${profile_cross_level}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion overall/visitor-profile profile_cross_level=2

  //#region overall/messenger-brand
  async loadMessengerBrandData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchMessgenerBrandData(date, ++graphDataServiceInstance.messengerBrandLock).then(([data, lockNum]) => graphDataServiceInstance.deriveMessengerBrandData(data, lockNum));
  }

  deriveMessengerBrandData(messengerBrandDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const messengerBrandBarChartData: { [brandName: string]: number } = {};
    const messengerTotalTimePairData: [number, number] = [0, 0];
    this.configDataService.MESSENGER_CLASS.forEach(brand => {
      // The value -1 is used to display 'N/A'. (only range positive value)
      messengerBrandBarChartData[brand] = -1;
    });
    GraphDataService.mapSevenDayLineChartData(messengerBrandDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || dataFiltered.data.length < 1 || isPred) {
        return;
      }
      const messengerBrandData = dataFiltered.data;
      let otherData = 0;
      let totalAllData = 0;
      if (diffToSelectedDate === 0) {
        messengerBrandData.forEach(profile => {
          Object.keys(messengerBrandBarChartData).forEach(brand => {
            if (compare1DepthObjects(profile.group, { messenger_brand: brand })) {
              if (brand !== 'other') {
                messengerBrandBarChartData[brand] = GraphDataService.procesChartData(profile.count, false, false);
              }
            }
          });
          if (compare1DepthObjects(profile.group, { messenger_brand: 'other' })) {
            otherData = GraphDataService.procesChartData(profile.count, false, false);
          }
          if (compare1DepthObjects(profile.group, {})) {
            totalAllData = GraphDataService.procesChartData(profile.count, false, false);
          }
        });
      }
      else if (diffToSelectedDate === -1) {
        messengerBrandData.forEach(profile => {
          if (compare1DepthObjects(profile.group, { messenger_brand: 'other' })) {
            otherData = GraphDataService.procesChartData(profile.count, false, false);
          }
          if (compare1DepthObjects(profile.group, {})) {
            totalAllData = GraphDataService.procesChartData(profile.count, false, false);
          }
        });
      }
      const totalMessengerBrandData = totalAllData - otherData;
      messengerTotalTimePairData[diffToSelectedDate + 1] = totalMessengerBrandData;
    });
    const sortMessengerBarChartData = Object.entries(messengerBrandBarChartData).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    if (lockNum < this.messengerBrandLock) { return; }
    this.currentTotalMessengerData$.next({
      messenger: GraphDataService.procesChartData(messengerTotalTimePairData[1], false, false),
      diff: GraphDataService.procesChartData((messengerTotalTimePairData[1] - messengerTotalTimePairData[0]), true, false),
      diffPercent: GraphDataService.procesChartData(((messengerTotalTimePairData[1] - messengerTotalTimePairData[0]) / messengerTotalTimePairData[1]) * 100, true, true),
    });
    this.messengerBrandBarChartData$.next(sortMessengerBarChartData);
  }

  async fetchMessgenerBrandData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/messenger-brand?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion overall/messenger-brand

  //#region overall/purchase-bag-color
  async loadPurchaseBagColorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchPurchaseBagColorData(date, ++graphDataServiceInstance.purchaseBagColorLock).then(([data, lockNum]) => graphDataServiceInstance.derivePurchaseBagColorData(data, lockNum));
  }

  derivePurchaseBagColorData(purchaseBagColorDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    // The value -1 is used to display 'N/A'. (only range positive value)
    const purchaseBagColorListData: { [colorName: string]: number } = this.configDataService.SHOPPING_BAG_COLOR_LIST.reduce((o, key) => Object.assign(o, { [key]: -1 }), {});
    GraphDataService.mapSevenDayLineChartData(purchaseBagColorDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || dataFiltered.data.length < 1 || isPred) {
        return;
      }
      const purchaseBagColorData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        purchaseBagColorData.forEach(profile => {
          Object.keys(purchaseBagColorListData).forEach(color => {
            if (compare1DepthObjects(profile.group, { bag_color: color })) {
              if (color !== 'other') {
                purchaseBagColorListData[color] = GraphDataService.procesChartData(profile.count, false, false);
              }
            }
          });
        });
      }
    });
    if (lockNum < this.purchaseBagColorLock) { return; }
    this.purchaseBagColorListData$.next(purchaseBagColorListData);
  }

  async fetchPurchaseBagColorData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/purchase-bag-color?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion overall/purchase-bag-color

  //#region overall/messenger-brand-by-hour

  async loadMessengerBrandbyHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchMessengerBrandbyHourData(date, ++graphDataServiceInstance.messengerBrandbyHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveMessengerBrandbyHourData(data, lockNum));
  }

  deriveMessengerBrandbyHourData(messengerBrandbyHourDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const messengerGroupBrandChartData: { [brandName: string]: number[] } = {};
    this.configDataService.MESSENGER_GROUP_CLASS_LIST.forEach(groupName => {
      messengerGroupBrandChartData[groupName] = this.configDataService.VEHICLE_TIME_LIST.map(time => 0);
    });
    messengerBrandbyHourDatas.forEach((messengerBrandbyHourData, idx) => {
      const messengerBrandData = messengerBrandbyHourData.data;
      const sumMessengerGroup: { [groupName: string]: number } = Object.keys(this.configDataService.MESSENGER_GROUP_CLASS).reduce((o, key) => Object.assign(o, { [key]: 0 }), {});;
      messengerBrandData.forEach(profile => {
        this.configDataService.MESSENGER_CLASS.forEach(brand => {
          if (compare1DepthObjects(profile.group, { messenger_brand: brand })) {
            if (brand !== 'other') {
              Object.entries(this.configDataService.MESSENGER_GROUP_CLASS).forEach(([groupName, messengerBrand]) => {
                if (messengerBrand.includes(brand)) {
                  sumMessengerGroup[groupName] += GraphDataService.procesChartData(profile.count, false, false);
                }
              });
            }
          }
        });
      });
      Object.keys(sumMessengerGroup).forEach(groupName => {
        messengerGroupBrandChartData[groupName][idx] = sumMessengerGroup[groupName];
      });
      messengerGroupBrandChartData.total[idx] = Object.values(sumMessengerGroup).reduce((a, b) => a + b);
    });
    if (lockNum < this.messengerBrandbyHourLock) { return; }
    this.messengerBrandbyHourChartData$.next(messengerGroupBrandChartData);
  }

  async fetchMessengerBrandbyHourData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/overall/messenger-brand-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion overall/messenger-brand-by-hour

  //#region building/entrance-exit sum by total day
  async loadBuildingEntranceExitSumByDayData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingEntranceExitSumByDayData(date, ++graphDataServiceInstance.monthZoneEntranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingEntranceExitSumByDayData(data, lockNum));
  }

  deriveBuildingEntranceExitSumByDayData(entranceExitSumByDayDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number) {
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    if (!entranceExitSumByDayDatas) {
      return;
    }
    const currentBuildingSumByTodayValue = entranceExitSumByDayDatas[1].data[mainBuilding].entrance;
    const changedValue = entranceExitSumByDayDatas[1].data[mainBuilding].entrance - entranceExitSumByDayDatas[0].data[mainBuilding].entrance;
    const currentBuildingSumByToday = {
      [mainBuilding]: {
        val: GraphDataService.procesChartData(currentBuildingSumByTodayValue, false, false),
        changed: GraphDataService.procesChartData(changedValue, false, false),
      }
    };
    if (lockNum < this.buildingEntranceExitSumByDayLock) { return; }
    this.currentBuildingTrafficGoalData$.next(currentBuildingSumByToday);
  }

  async fetchBuildingEntranceExitSumByDayData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const todayInApp = moment().subtract(1, 'days');
    const prevTodayInApp = moment().subtract(2, 'days');
    const diffDate = todayInApp.diff(date.startOf('M'), 'days');
    const diffPrevDate = prevTodayInApp.diff(date.startOf('M'), 'days');
    const qParamsToday = {
      periodType: 'day',
      start_date: todayInApp.format('YYYY-MM-DD'),
      num_interval: diffDate,
    };
    const qParamsPrev = {
      periodType: 'day',
      start_date: prevTodayInApp.format('YYYY-MM-DD'),
      num_interval: diffPrevDate,
    };
    const fetchTodayURL = `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParamsToday.start_date}&mode=${qParamsToday.periodType}&num_interval=${qParamsToday.num_interval}&aggregation_type=sum`;
    const fetchPrevURL = `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParamsPrev.start_date}&mode=${qParamsPrev.periodType}&num_interval=${qParamsPrev.num_interval}&aggregation_type=sum`;
    const fetchTodayURLwithIdToken = await this.authenticationService.requestURLwithIdToken(fetchTodayURL, true);
    const fetchPrevURLwithIdToken = await this.authenticationService.requestURLwithIdToken(fetchPrevURL, true);
    const fetchTodayObj = await lastValueFrom(this.http.get<any>(fetchTodayURLwithIdToken, httpOptions));
    const fetchPrevObj = await lastValueFrom(this.http.get<any>(fetchPrevURLwithIdToken, httpOptions));
    const fetchObj = fetchPrevObj.concat(fetchTodayObj);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion building/entrance-exit sum by total day

  //#region plate/frequency-of-visit-semi-annual for goal page
  async loadFrequencyOfVisitSemiAnnualV2Data(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFrequencyOfVisitSemiAnnualV2Data(date, ++graphDataServiceInstance.frequencyOfVisitSemiAnnualtV2Lock).then(([data, lockNum]) => graphDataServiceInstance.deriveFrequencyOfVisitSemiAnnualV2Data(data, lockNum));
  }

  deriveFrequencyOfVisitSemiAnnualV2Data(frequencyOfVisitSemiAnnualDatas: IFetchData<FrequencyOfVisitData>[], lockNum: number) {
    const frequencyOfVisitTime4UpPairData: [number, number] = [0, 0];
    if (!frequencyOfVisitSemiAnnualDatas) {
      return;
    }
    frequencyOfVisitSemiAnnualDatas.forEach((frequencyOfVisitSemiAnnualData, idx) => {
      const frequencyOfVisitData = frequencyOfVisitSemiAnnualData.data;
      const frequencyOfVisitChartData = { one: 0, two_three: 0, four_up: 0 };
      for (const [timesString, count] of Object.entries(frequencyOfVisitData.frequency_of_visit)) {
        const times = parseInt(timesString, 10);
        if (times === 1) {
          frequencyOfVisitChartData.one = (frequencyOfVisitChartData.one || 0) + count;
        } else if (times >= 2 && times <= 3) {
          frequencyOfVisitChartData.two_three = (frequencyOfVisitChartData.two_three || 0) + count;
        } else if (times >= 4 && times <= 50) {
          frequencyOfVisitChartData.four_up = (frequencyOfVisitChartData.four_up || 0) + count;
        }
      }
      const totalUniqueVistors = frequencyOfVisitData.total_unique_visitors;
      frequencyOfVisitTime4UpPairData[idx] = frequencyOfVisitChartData.four_up;
      Object.keys(frequencyOfVisitChartData).forEach(key => {
        const freqChartPercentage = (frequencyOfVisitChartData[key] / totalUniqueVistors) * 100;
        frequencyOfVisitChartData[key] = GraphDataService.procesChartData(freqChartPercentage, false, false);
      });
    });
    const current4UpRevisitedSumByTodayValue = frequencyOfVisitTime4UpPairData[1];
    const changedValue = frequencyOfVisitTime4UpPairData[1] - frequencyOfVisitTime4UpPairData[0];
    const current4UpRevisitedSumByToday = {
      val: GraphDataService.procesChartData(current4UpRevisitedSumByTodayValue, false, false),
      changed: GraphDataService.procesChartData(changedValue, true, false),
    };
    if (lockNum < this.frequencyOfVisitSemiAnnualtV2Lock) { return; }
    // this.current4UpRevisitedGoalData$.next(current4UpRevisitedSumByToday);

  }

  async fetchFrequencyOfVisitSemiAnnualV2Data(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const todayInApp = moment().subtract(1, 'days');
    const qParams = {
      periodType: 'day',
      start_date: todayInApp.format('YYYY-MM-DD'),
      num_interval: 2,
    };
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit-v2?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<FrequencyOfVisitData>[], number];
  }
  //#endregion plate/frequency-of-visit-semi-annual for goal page

  //#region overall/vehicle-purchasing-power sum by total day
  async loadVehiclePurchasingPowerSumByDayData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehiclePurchasingPowerSumByDayData(date, ++graphDataServiceInstance.vehiclePurchasingPowerSumByDayLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehiclePurchasingPowerSumByDayData(data, lockNum));
  }

  deriveVehiclePurchasingPowerSumByDayData(vehiclePurchasingPowerSumByDayDatas: IFetchData<VehiclePurchasingPowerData>[], lockNum: number) {
    if (!vehiclePurchasingPowerSumByDayDatas) {
      return;
    }
    const currentVehicleLuxurySumByTodayValue = vehiclePurchasingPowerSumByDayDatas[1].data?.luxury;
    const changedValue = vehiclePurchasingPowerSumByDayDatas[1].data?.luxury - vehiclePurchasingPowerSumByDayDatas[0].data?.luxury;
    const currentVehicleLuxurySumByToday = {
      val: GraphDataService.procesChartData(currentVehicleLuxurySumByTodayValue, false, false),
      changed: GraphDataService.procesChartData(changedValue, false, false),
    };
    if (lockNum < this.vehiclePurchasingPowerSumByDayLock) { return; }
    this.currentVehicleLuxuryGoalData$.next(currentVehicleLuxurySumByToday);
  }

  async fetchVehiclePurchasingPowerSumByDayData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const todayInApp = moment().subtract(1, 'days');
    const prevTodayInApp = moment().subtract(2, 'days');
    const diffDate = todayInApp.diff(date.startOf('M'), 'days');
    const diffPrevDate = prevTodayInApp.diff(date.startOf('M'), 'days');
    const qParamsToday = {
      periodType: 'day',
      start_date: todayInApp.format('YYYY-MM-DD'),
      num_interval: diffDate,
    };
    const qParamsPrev = {
      periodType: 'day',
      start_date: prevTodayInApp.format('YYYY-MM-DD'),
      num_interval: diffPrevDate,
    };
    const channel = this.configDataService.currentOrganization === 'THENINE' ? 'exit' : 'entrance';
    const fetchTodayURL = `/retail_customer_api_v2/api/v2/overall/vehicle-purchasing-power?start_date=${qParamsToday.start_date}&mode=${qParamsToday.periodType}&num_interval=${qParamsToday.num_interval}&aggregation_type=sum&channel=${channel}`;
    const fetchPrevURL = `/retail_customer_api_v2/api/v2/overall/vehicle-purchasing-power?start_date=${qParamsPrev.start_date}&mode=${qParamsPrev.periodType}&num_interval=${qParamsPrev.num_interval}&aggregation_type=sum&channel=${channel}`;
    const fetchTodayURLwithIdToken = await this.authenticationService.requestURLwithIdToken(fetchTodayURL, true);
    const fetchPrevURLwithIdToken = await this.authenticationService.requestURLwithIdToken(fetchPrevURL, true);
    const fetchTodayObj = await lastValueFrom(this.http.get<any>(fetchTodayURLwithIdToken, httpOptions));
    const fetchPrevObj = await lastValueFrom(this.http.get<any>(fetchPrevURLwithIdToken, httpOptions));
    const fetchObj = fetchPrevObj.concat(fetchTodayObj);
    return [fetchObj, lockNum] as [IFetchData<VehiclePurchasingPowerData>[], number];
  }
  //#endregion overall/vehicle-purchasing-power sum by total day

  //#region plate/frequency-of-visit-semi-annual sum by day
  async loadFrequencyOfVisitSemiAnnualSumByDayData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFrequencyOfVisitSemiAnnualSumByDayData(date, ++graphDataServiceInstance.frequencyOfVisitSemiAnnualtSumByDayLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFrequencyOfVisitSemiAnnualSumByDayData(data, lockNum));
  }

  deriveFrequencyOfVisitSemiAnnualSumByDayData(frequencyOfVisitSemiAnnualDatas: IFetchData<FrequencyOfVisitData>[], lockNum: number) {
    const frequencyOfVisitTime4UpPairData: [number, number] = [0, 0];
    if (!frequencyOfVisitSemiAnnualDatas) {
      return;
    }
    frequencyOfVisitSemiAnnualDatas.forEach((frequencyOfVisitSemiAnnualData, idx) => {
      const frequencyOfVisitData = frequencyOfVisitSemiAnnualData.data;
      const frequencyOfVisitChartData = { one: 0, two_three: 0, four_up: 0 };
      for (const [timesString, count] of Object.entries(frequencyOfVisitData.frequency_of_visit)) {
        const times = parseInt(timesString, 10);
        if (times === 1) {
          frequencyOfVisitChartData.one = (frequencyOfVisitChartData.one || 0) + count;
        } else if (times >= 2 && times <= 3) {
          frequencyOfVisitChartData.two_three = (frequencyOfVisitChartData.two_three || 0) + count;
        } else if (times >= 4 && times <= 50) {
          frequencyOfVisitChartData.four_up = (frequencyOfVisitChartData.four_up || 0) + count;
        }
      }
      const totalUniqueVistors = frequencyOfVisitData.total_unique_visitors;
      frequencyOfVisitTime4UpPairData[idx] = frequencyOfVisitChartData.four_up;
      Object.keys(frequencyOfVisitChartData).forEach(key => {
        const freqChartPercentage = (frequencyOfVisitChartData[key] / totalUniqueVistors) * 100;
        frequencyOfVisitChartData[key] = GraphDataService.procesChartData(freqChartPercentage, false, false);
      });
    });
    const current4UpRevisitedSumByTodayValue = frequencyOfVisitTime4UpPairData[1];
    const changedValue = frequencyOfVisitTime4UpPairData[1] - frequencyOfVisitTime4UpPairData[0];
    const current4UpRevisitedSumByToday = {
      val: GraphDataService.procesChartData(current4UpRevisitedSumByTodayValue, false, false),
      changed: GraphDataService.procesChartData(changedValue, true, false),
    };
    if (lockNum < this.frequencyOfVisitSemiAnnualtSumByDayLock) { return; }
    this.current4UpRevisitedGoalSumData$.next(current4UpRevisitedSumByToday);

  }

  async fetchFrequencyOfVisitSemiAnnualSumByDayData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const todayInApp = moment().subtract(1, 'days');
    const prevTodayInApp = moment().subtract(2, 'days');
    const diffDate = todayInApp.diff(date.startOf('M'), 'days');
    const diffPrevDate = prevTodayInApp.diff(date.startOf('M'), 'days');
    const qParamsToday = {
      periodType: 'day',
      start_date: todayInApp.format('YYYY-MM-DD'),
      num_interval: diffDate,
    };
    const qParamsPrev = {
      periodType: 'day',
      start_date: prevTodayInApp.format('YYYY-MM-DD'),
      num_interval: diffPrevDate,
    };
    const channel = this.configDataService.currentOrganization === 'THENINE' ? 'exit' : 'entrance';
    const fetchTodayURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit-v2?start_date=${qParamsToday.start_date}&mode=${qParamsToday.periodType}&num_interval=${qParamsToday.num_interval}&aggregation_type=sum&channel=${channel}`;
    const fetchPrevURL = `/retail_customer_api_v2/api/v2/plate/frequency-of-visit-v2?start_date=${qParamsPrev.start_date}&mode=${qParamsPrev.periodType}&num_interval=${qParamsPrev.num_interval}&aggregation_type=sum&channel=${channel}`;
    const fetchTodayURLwithIdToken = await this.authenticationService.requestURLwithIdToken(fetchTodayURL, true);
    const fetchPrevURLwithIdToken = await this.authenticationService.requestURLwithIdToken(fetchPrevURL, true);
    const fetchTodayObj = await lastValueFrom(this.http.get<any>(fetchTodayURLwithIdToken, httpOptions));
    const fetchPrevObj = await lastValueFrom(this.http.get<any>(fetchPrevURLwithIdToken, httpOptions));
    const fetchObj = fetchPrevObj.concat(fetchTodayObj);
    return [fetchObj, lockNum] as [IFetchData<FrequencyOfVisitData>[], number];
  }
  //#endregion plate/frequency-of-visit-semi-annual for goal page

  //#region plate/unique-visitors
  async loadVehicleUniqueVisitorsData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleUniqueVisitorsData(date, ++graphDataServiceInstance.frequencyOfVisitSemiAnnualtSumByDayLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleUniqueVisitorsData(data, lockNum));
  }

  async deriveVehicleUniqueVisitorsData(plateUniqueVisitorDatas: IFetchData<UniqueVisitorsData>[], lockNum: number) {
    const vehiclePlateUniqueVisitorsTimePairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(plateUniqueVisitorDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        this.currentVehicleUniqueVisitors$.next({
          vehicles: GraphDataService.procesChartData(0, false, false),
          diff: GraphDataService.procesChartData(0, true, false),
          diffPercent: GraphDataService.procesChartData(0.0, true, true)
        });
        return;
      }
      const plateUniqueVisitorData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        vehiclePlateUniqueVisitorsTimePairData[diffToSelectedDate + 1] = plateUniqueVisitorData.unique_vehicles_count;
      }
    });
    if (lockNum < this.vehiclePlateUniqueVisitorsLock) { return; }
    const diffValue = vehiclePlateUniqueVisitorsTimePairData[1] - vehiclePlateUniqueVisitorsTimePairData[0];
    const diffPercentValue = (diffValue / vehiclePlateUniqueVisitorsTimePairData[0]) * 100;
    this.currentVehicleUniqueVisitors$.next({
      vehicles: GraphDataService.procesChartData(vehiclePlateUniqueVisitorsTimePairData[1], false, false),
      diff: GraphDataService.procesChartData(diffValue, true, false),
      diffPercent: GraphDataService.procesChartData(diffPercentValue, true, true)
    });
  }

  async fetchVehicleUniqueVisitorsData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const fetchURL = `/retail_customer_api_v2/api/v2/plate/unique-visitors?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<UniqueVisitorsData>[], number];
  }

  //#endregion plate/unique-visitors

  //#region traffic-site/count
  async loadTrafficSiteCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    graphDataServiceInstance.trafficSiteCountTrendexcludePred = [];
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteCountLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteCountData(date, ++graphDataServiceInstance.trafficSiteCountLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteCountData(data, lockNum))
        .then(() => graphDataServiceInstance.loadPredictionTrafficSiteCountData(date));
    }
    return graphDataServiceInstance.fetchTrafficSiteCountData(date, ++graphDataServiceInstance.trafficSiteCountLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteCountData(data, lockNum));
  }

  deriveTrafficSiteCountData(trafficSiteCountDatas: IFetchData<GroupData>[], lockNum: number) {
    const trafficSiteCountPairData: [number, number] = [0, 0];
    const trafficSiteExposureTimeCountPairData: [number, number] = [0, 0];
    const trafficSiteCountAvgWeekdayData: number[] = [];
    const trafficSiteCountAvgWeekendData: number[] = [];
    const prevTrafficSiteCountAvgWeekdayData: number[] = [];
    const prevTrafficSiteCountAvgWeekendData: number[] = [];
    const trafficSiteCountTrendData: number[] = [];
    const exposureTimeUnit = this.configDataService.isFeatureEnabled('graph_data', 'traffic_site_count')?.exposure_time_unit;
    GraphDataService.mapSevenDayLineChartData(trafficSiteCountDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        diffToSelectedDate < 1 ? trafficSiteCountTrendData.push(0) : trafficSiteCountTrendData.push(null);
        return;
      }
      const trafficSiteCountData = dataFiltered.data;
      if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
        trafficSiteCountPairData[diffToSelectedDate + 1] = trafficSiteCountData.count;
        if (exposureTimeUnit === 'minute') {
          trafficSiteExposureTimeCountPairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(trafficSiteCountData.exposure_time_count / 60, false, false);
        } else if (exposureTimeUnit === 'hour') {
          trafficSiteExposureTimeCountPairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(trafficSiteCountData.exposure_time_count / (60 * 60), false, false);
        } else {
          trafficSiteExposureTimeCountPairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(trafficSiteCountData.exposure_time_count, false, false);
        }
      }
      if (diffToSelectedDate < 1) {
        trafficSiteCountTrendData.push(GraphDataService.procesChartData(trafficSiteCountData.count, false, false));
      }
    });
    trafficSiteCountDatas.forEach(trafficSiteCountData => {
      if (!trafficSiteCountData || Object.keys(trafficSiteCountData.data).length < 1) {
        return;
      }
      const parseDate = moment(`${trafficSiteCountData.year}-${trafficSiteCountData.month}-${trafficSiteCountData.day}`, 'YYYY-MM-DD');
      const todayDate = this.viewPeriodService.selectedDate;
      const diffDate = todayDate.diff(parseDate, 'd');
      const numInWeekDay = parseDate.isoWeekday();
      if (diffDate < 7) {
        if (numInWeekDay < 6) {
          trafficSiteCountAvgWeekdayData.push(trafficSiteCountData.data.count);
        } else {
          trafficSiteCountAvgWeekendData.push(trafficSiteCountData.data.count);
        }
      } else if (diffDate < 14) {
        if (numInWeekDay < 6) {
          prevTrafficSiteCountAvgWeekdayData.push(trafficSiteCountData.data.count);
        } else {
          prevTrafficSiteCountAvgWeekendData.push(trafficSiteCountData.data.count);
        }
      }
    });
    this.trafficSiteCountTrendexcludePred = trafficSiteCountTrendData;
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteTrend$.next(trafficSiteCountTrendData);
    }
    // this.trafficSiteTrend$.next(trafficSiteCountTrendData);
    if ((this.viewPeriodService.isDayPeriod || this.viewPeriodService.isLiveMode)) {
      const findAvg = (prevData: number[], currentData: number[]) => {
        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        const sum = currentData.reduce(reducer, 0);
        const prevSum = prevData.reduce(reducer, 0);
        const avg = sum / currentData.length;
        const prevAvg = prevSum / prevData.length;
        const diffAvg = avg - prevAvg;
        return { avg, diffAvg };
      };
      const avgWeekday = findAvg(prevTrafficSiteCountAvgWeekdayData, trafficSiteCountAvgWeekdayData).avg;
      const diffAvgWeekday = findAvg(prevTrafficSiteCountAvgWeekdayData, trafficSiteCountAvgWeekdayData).diffAvg;
      const avgWeekend = findAvg(prevTrafficSiteCountAvgWeekdayData, trafficSiteCountAvgWeekendData).avg;
      const diffAvgWeekend = findAvg(prevTrafficSiteCountAvgWeekendData, trafficSiteCountAvgWeekendData).diffAvg;
      this.currentTrafficSiteAvgWeekdayLast7DaysCount$.next({
        count: GraphDataService.procesChartData(avgWeekday, false, false),
        diff: GraphDataService.procesChartData(diffAvgWeekday, true, false),
        diffPercent: GraphDataService.procesChartData((diffAvgWeekday / 100), true, true),
      });
      this.currentTrafficSiteAvgWeekendLast7DaysCount$.next({
        count: GraphDataService.procesChartData(avgWeekend, false, false),
        diff: GraphDataService.procesChartData(diffAvgWeekend, true, false),
        diffPercent: GraphDataService.procesChartData((diffAvgWeekend / 100), true, true),
      });
    }
    this.currentTrafficSiteCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPairData[1] - trafficSiteCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPairData[1] - trafficSiteCountPairData[0]) / trafficSiteCountPairData[0]) * 100, true, false)
    });
    this.currentTrafficSiteExposureTimeCount$.next({
      count: GraphDataService.procesChartData(trafficSiteExposureTimeCountPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteExposureTimeCountPairData[1] - trafficSiteExposureTimeCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteExposureTimeCountPairData[1] - trafficSiteExposureTimeCountPairData[0]) / trafficSiteCountPairData[0]) * 100, true, true)
    });
    if (lockNum < this.trafficSiteCountLock) { return; }
  }

  async fetchTrafficSiteCountData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupData>[], number];
  }

  //#endregion traffic-site/count

  //#region traffic-site/unique-visitor
  async loadTrafficSiteUniqueVisitorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    graphDataServiceInstance.trafficSiteUniqueVisitorTrend = [];
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteUniqueVisitorLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteUniqueVisitorData(date, ++graphDataServiceInstance.trafficSiteUniqueVisitorLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteUniqueVisitorData(data, lockNum))
        .then(() => graphDataServiceInstance.loadPredictionTrafficSiteUniqueVisitorData(date));
    }
    return graphDataServiceInstance.fetchTrafficSiteUniqueVisitorData(date, ++graphDataServiceInstance.trafficSiteUniqueVisitorLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteUniqueVisitorData(data, lockNum));
  }

  deriveTrafficSiteUniqueVisitorData(trafficSiteUniqueVisitorDatas: IFetchData<UniqueVisitorsData>[], lockNum: number) {
    const trafficSiteUniqueVisitorPairData: [number, number] = [0, 0];
    const trafficSiteAvgVisitorPairData: [number, number] = [0, 0];
    const trafficSiteUniqueVisitorTrendData: number[] = [];
    GraphDataService.mapSevenDayLineChartData(trafficSiteUniqueVisitorDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        diffToSelectedDate < 1 ? trafficSiteUniqueVisitorTrendData.push(0) : trafficSiteUniqueVisitorTrendData.push(null);
        return;
      }
      const trafficSiteUniqueVisitorData = dataFiltered.data;
      if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
        trafficSiteUniqueVisitorPairData[diffToSelectedDate + 1] = trafficSiteUniqueVisitorData.unique_vehicles_count;
        trafficSiteAvgVisitorPairData[diffToSelectedDate + 1] = trafficSiteUniqueVisitorData.average_visitation_count;
      }
      if (diffToSelectedDate < 1) {
        trafficSiteUniqueVisitorTrendData.push(GraphDataService.procesChartData(trafficSiteUniqueVisitorData.unique_vehicles_count, false, false));
      }
    });
    if (lockNum < this.trafficSiteUniqueVisitorLock) { return; }
    this.trafficSiteUniqueVisitorTrend = trafficSiteUniqueVisitorTrendData;
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteUniqueVisitorTrend$.next(trafficSiteUniqueVisitorTrendData);
    }
    this.currentTrafficSiteUniqueVisitor$.next({
      count: GraphDataService.procesChartData(trafficSiteUniqueVisitorPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteUniqueVisitorPairData[1] - trafficSiteUniqueVisitorPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteUniqueVisitorPairData[1] - trafficSiteUniqueVisitorPairData[0]) / trafficSiteUniqueVisitorPairData[0]) * 100, true, false)
    });
    this.currentTrafficSiteAvgVisitor$.next({
      count: GraphDataService.procesChartData(trafficSiteAvgVisitorPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteAvgVisitorPairData[1] - trafficSiteAvgVisitorPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteAvgVisitorPairData[1] - trafficSiteAvgVisitorPairData[0]) / trafficSiteAvgVisitorPairData[0]) * 100, true, false)
    });
  }

  async fetchTrafficSiteUniqueVisitorData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/unique-visitor?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<UniqueVisitorsData>[], number];
  }

  //#endregion traffic-site/unique-visitor

  //#region traffic-site/purchasing-power
  async loadTrafficSitePurchasingPowerData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    graphDataServiceInstance.trafficSitePurchasingPowerTrend = {};
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSitePurchasingPowerLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSitePurchasingPowerData(date, ++graphDataServiceInstance.trafficSitePurchasingPowerLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePurchasingPowerData(data, lockNum))
        .then(() => graphDataServiceInstance.loadPredictionTrafficSitePurchasingPowerData(date));
    }
    return graphDataServiceInstance.fetchTrafficSitePurchasingPowerData(date, ++graphDataServiceInstance.trafficSitePurchasingPowerLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePurchasingPowerData(data, lockNum));
  }

  deriveTrafficSitePurchasingPowerData(vehiclePurchasingPowerDatas: IFetchData<VehiclePurchasingPowerData>[], lockNum: number) {
    const tierListPercentage: { [tierName: string]: number } = {};
    const tierList: { [tierName: string]: number } = {};
    const tierListTrend: { [tierName: string]: number[] } = {};
    GraphDataService.mapSevenDayLineChartData(vehiclePurchasingPowerDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      Object.entries(dataFiltered.data).forEach(([tierName, _]) => {
        if (tierName.includes('count')) {
          return;
        }
        tierListTrend[tierName] = [];
      });
    });
    GraphDataService.mapSevenDayLineChartData(vehiclePurchasingPowerDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        Object.keys(tierListTrend).forEach(tier => {
          tierListTrend[tier].push(0);
        });
        return;
      }
      Object.entries(dataFiltered.data).forEach(([tierName, tierData]) => {
        if (tierName.includes('count')) {
          return;
        }
        if (diffToSelectedDate === 0) {
          tierListPercentage[tierName] = (tierData / dataFiltered.data.count) * 100;
          tierList[tierName] = Math.round(tierData);
        }
        tierListTrend[tierName].push((tierData / dataFiltered.data.count) * 100);
      });
    });
    if (lockNum < this.trafficSitePurchasingPowerLock) { return; }
    this.trafficSitePurchasingPowerTrend = tierListTrend;
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSitePurchasingPowerTrend$.next(tierListTrend);
    }
    const sortableByVal = Object.entries(tierListPercentage)
      .sort(([, a], [, b]) => a - b).reverse()
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.trafficSitePurchasingPowerPercentage$.next(sortableByVal);

  }

  async fetchTrafficSitePurchasingPowerData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/purchasing-power?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<VehiclePurchasingPowerData>[], number];
  }

  //#endregion traffic-site/purchasing-power

  //#region traffic-site/mode-of-transportation
  async loadTrafficSiteModeOfTransportData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    graphDataServiceInstance.trafficSiteModeOfTransportTrend = { total: Array.from({ length: 7 }).map(() => null) };
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteModeOfTransportLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteModeOfTransportData(date, ++graphDataServiceInstance.trafficSiteModeOfTransportLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteModeOfTransportData(data, lockNum))
        .then(() => graphDataServiceInstance.loadPredictionTrafficSiteModeOfTransportData(date));
    }
    return graphDataServiceInstance.fetchTrafficSiteModeOfTransportData(date, ++graphDataServiceInstance.trafficSiteModeOfTransportLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteModeOfTransportData(data, lockNum));
  }

  async deriveTrafficSiteModeOfTransportData(modeOfTransDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeData: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      dataFiltered.data.forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName] = [];
        if (vehicleTypeName !== 'total') {
          tranSportModeBreakdown[vehicleTypeName] = 0;
        }
      });
    });
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered, _, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        Object.keys(tranSportModeData).map(key => {
          diffToSelectedDate < 1 ? tranSportModeData[key].push(0) : tranSportModeData[key].push(null);
          if (diffToSelectedDate === 0) {
            tranSportModeBreakdown[key] = 0;
          }
        });
        return;
      }
      const vehicleTypeUsed: string[] = [];
      let totalCount = 0;
      (dataFiltered.data as ModeOfTransportData[]).forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName].push(GraphDataService.procesChartData(vehicleTypeData.count));
        if (diffToSelectedDate === 0) {
          if (vehicleTypeName === 'total') {
            totalCount = vehicleTypeData.count;
          }
          else {
            tranSportModeBreakdown[vehicleTypeName] = GraphDataService.procesChartData(((vehicleTypeData.count / totalCount) * 100), false, false);
          }
        }
        vehicleTypeUsed.push(vehicleTypeName);
      });
      const fillData = (data: { [vehicleTypeName: string]: number[]; total: number[] }) => {
        Object.keys(data).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          data[noDataType].push(0);
        });
      };
      fillData(tranSportModeData);
    });
    if (lockNum < this.trafficSiteModeOfTransportLock) { return; }
    this.trafficSiteModeOfTransportTrend = tranSportModeData;
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteModeOfTransportTrend$.next(tranSportModeData);
    }
    const sortableByVal = Object.entries(tranSportModeBreakdown)
      .sort(([, a], [, b]) => a - b).reverse()
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    this.trafficSiteModeOfTransportBreakdown$.next(sortableByVal);
  }

  async fetchTrafficSiteModeOfTransportData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/mode-of-transportation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/mode-of-transportation

  //#region traffic-site/car-brand
  async loadTrafficSiteCarBrandData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteCarBrandLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchTrafficSiteCarBrandData(date, ++graphDataServiceInstance.trafficSiteCarBrandLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteCarBrandData(data, lockNum));
  }

  deriveTrafficSiteCarBrandData(carBrandDatas: IFetchData<CarBrandData[]>[], lockNum: number) {
    const filterExcludeCarBrand: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const filteredCustomerCarBrand: { [brandName: string]: number } = {};
    const totalCarBrand = [];
    GraphDataService.mapSevenDayLineChartData(carBrandDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      let totalCustomerCar = 0;
      dataFiltered.data.forEach(carBrandData => {
        if (compare1DepthObjects(carBrandData.group, { plate_number_definition: 'customer' })) {
          totalCustomerCar = carBrandData.count;
        }
        if (!carBrandData.group.car_brand) {
          totalCarBrand[0] = carBrandData.count;
          return;
        }
        if (excludeCarBrand.includes(carBrandData.group.car_brand)) {
          return;
        }
        if (carBrandData.group.plate_number_definition === 'customer') {
          filteredCustomerCarBrand[carBrandData.group.car_brand] = (carBrandData.count / totalCustomerCar) * 100;
        }
        filterExcludeCarBrand[carBrandData.group.car_brand] = (carBrandData.count / totalCarBrand[0]) * 100;
      });
    });
    const topTenCarBrandData = Object.entries(this.configDataService.currentOrganization === 'MBK' ? filteredCustomerCarBrand : filterExcludeCarBrand)
      .sort(([, a], [, b]) => b - a).slice(0, 10)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    if (lockNum < this.trafficSiteCarBrandLock) { return; }
    this.trafficSiteAllCarBrandData$.next(filterExcludeCarBrand);
    this.trafficSiteTopTenCarBrandData$.next(topTenCarBrandData);

  }

  async fetchTrafficSiteCarBrandData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/car-brand?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`; (this.http.get<IFetchData<CarBrandData[]>[]>(fetchURL, httpOptions));
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<CarBrandData[]>[], number];
  }

  //#endregion traffic-site/car-brand

  //#region traffic-site/count-average-by-day-type
  async loadTrafficSiteAvgByDayTypeCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteCountAvgByDayTypeLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchTrafficSiteAvgByDayTypeCountData(date, ++graphDataServiceInstance.trafficSiteCountLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteAvgByDayTypeCountData(data, lockNum));

  }

  deriveTrafficSiteAvgByDayTypeCountData(trafficSiteCountDatas: IFetchData<areaAverageDayTypeData>[], lockNum: number) {
    const trafficSiteCountAvgWeekdayPairData: [number, number] = [0, 0];
    const trafficSiteCountAvgWeekendPairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(trafficSiteCountDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const trafficSiteCountData = dataFiltered.data;
      if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
        trafficSiteCountAvgWeekdayPairData[diffToSelectedDate + 1] = trafficSiteCountData?.weekday?.count || 0;
        trafficSiteCountAvgWeekendPairData[diffToSelectedDate + 1] = trafficSiteCountData?.weekend?.count || 0;
      }
    });
    if (lockNum < this.trafficSiteCountAvgByDayTypeLock) { return; }
    this.trafficSiteAvgWeekdayCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountAvgWeekdayPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountAvgWeekdayPairData[1] - trafficSiteCountAvgWeekdayPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData((((trafficSiteCountAvgWeekdayPairData[1] - trafficSiteCountAvgWeekdayPairData[0]) / trafficSiteCountAvgWeekdayPairData[0]) * 100), true, true),
    });
    this.trafficSiteAvgWeekendCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountAvgWeekendPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountAvgWeekendPairData[1] - trafficSiteCountAvgWeekendPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData((((trafficSiteCountAvgWeekendPairData[1] - trafficSiteCountAvgWeekendPairData[0]) / trafficSiteCountAvgWeekendPairData[0]) * 100), true, true),
    });
  }

  async fetchTrafficSiteAvgByDayTypeCountData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/count-average-by-day-type?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<areaAverageDayTypeData>[], number];
  }

  //#endregion traffic-site/count-average-by-day-type

  //#region traffic-site/count-by-hour
  async loadTrafficSiteCountByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteCountByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchTrafficSiteCountByHourData(date, ++graphDataServiceInstance.trafficSiteCountByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteCountByHourData(data, lockNum));
  }

  deriveTrafficSiteCountByHourData(trafficSiteCountByHourDatas: IFetchData<GroupData>[], lockNum: number) {
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    const trafficByHourCount: number[] = [];
    const adsExposureByHour: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      const filteredData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
      const pushData = filteredData !== undefined ? filteredData?.data?.count : fillValue;
      const adsExposureData = filteredData !== undefined ? filteredData?.data?.exposure_time_count : fillValue;
      adsExposureByHour.push(GraphDataService.procesChartData((adsExposureData / 60), false, false));
      trafficByHourCount.push(pushData);
      trafficByHour[time] = pushData;
    });
    const peakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    if (lockNum < this.trafficSiteCountByHourLock) { return; }
    this.trafficSiteAdsExposureTimebyHour$.next(adsExposureByHour);
    // this.trafficSiteCountByHour$.next(trafficByHourCount);
    // this.trafficSitePeakTime$.next({
    //   timeKey: peakTime,
    //   count: trafficByHour[peakTime]
    // });
    // this.trafficSiteOffPeakTime$.next({
    //   timeKey: offPeakTime,
    //   count: trafficByHour[offPeakTime]
    // });
  }

  async fetchTrafficSiteCountByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/count-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupData>[], number];
  }
  //#endregion traffic-site/count-by-hour

  //#region traffic-site/mode-of-transportation-public-private
  async loadTrafficSiteModeOfTransportPublicPrivateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    graphDataServiceInstance.trafficSiteModeOfTransportPublicPrivateTrend = { total: Array.from({ length: 7 }).map(() => null) };
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteModeOfTransportPublicPrivateLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteModeOfTransportPublicPrivateData(date, ++graphDataServiceInstance.trafficSiteModeOfTransportPublicPrivateLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteModeOfTransportPublicPrivateData(data, lockNum))
        .then(() => graphDataServiceInstance.loadPredictionTrafficSiteModeOfTransportPublicPrivateData(date));
    }
    return graphDataServiceInstance.fetchTrafficSiteModeOfTransportPublicPrivateData(date, ++graphDataServiceInstance.trafficSiteModeOfTransportPublicPrivateLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteModeOfTransportPublicPrivateData(data, lockNum));
  }

  async deriveTrafficSiteModeOfTransportPublicPrivateData(modeOfTransDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeData: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      dataFiltered.data.forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        if (vehicleTypeName !== 'total') {
          tranSportModeBreakdown[vehicleTypeName] = 0;
        }
        tranSportModeData[vehicleTypeName] = [];
      });
    });
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered, _, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        Object.keys(tranSportModeData).map(key => {
          diffToSelectedDate < 1 ? tranSportModeData[key].push(0) : tranSportModeData[key].push(null);
          if (diffToSelectedDate === 0) {
            tranSportModeBreakdown[key] = 0;
          }
        });
        return;
      }
      const vehicleTypeUsed: string[] = [];
      let totalCount = 0;
      (dataFiltered.data as ModeOfTransportData[]).forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName].push(GraphDataService.procesChartData(vehicleTypeData.count));
        if (diffToSelectedDate === 0) {
          if (vehicleTypeName === 'total') {
            totalCount = vehicleTypeData.count;
          } else if (totalCount !== 0) {
            tranSportModeBreakdown[vehicleTypeName] = GraphDataService.procesChartData(((vehicleTypeData.count / totalCount) * 100), false, false);
          }
        }
        vehicleTypeUsed.push(vehicleTypeName);
      });
      const fillData = (data: { [vehicleTypeName: string]: number[]; total: number[] }) => {
        Object.keys(data).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          data[noDataType].push(0);
        });
      };
      fillData(tranSportModeData);
    });
    if (lockNum < this.trafficSiteModeOfTransportPublicPrivateLock) { return; }
    this.trafficSiteModeOfTransportPublicPrivateTrend = tranSportModeData;
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteModeOfTransportPublicPrivateTrend$.next(tranSportModeData);
    }
    this.trafficSiteModeOfTransportPublicPrivateBreakdown$.next(tranSportModeBreakdown);
  }

  async fetchTrafficSiteModeOfTransportPublicPrivateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/mode-of-transportation-public-private?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/mode-of-transportation-public-private

  //#region prediction traffic-site/count
  async loadPredictionTrafficSiteCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteCountLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSiteCountData(date, ++graphDataServiceInstance.predictionTrafficSiteCountLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteCountData(data, lockNum));
  }

  derivePredictionTrafficSiteCountData(trafficSiteCountDatas: IFetchData<GroupData>[], lockNum: number) {
    // const trafficSiteCountTrendData: number[] = [];
    const tempTrafficSiteTrend = this.trafficSiteCountTrendexcludePred || [0];
    GraphDataService.mapSevenDayLineChartData(trafficSiteCountDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const trafficSiteCountData = dataFiltered.data;
      tempTrafficSiteTrend[tempTrafficSiteTrend.length - 1] = GraphDataService.procesChartData(trafficSiteCountData.count, false, false);
    });
    this.trafficSiteTrend$.next(tempTrafficSiteTrend);
    if (lockNum < this.predictionTrafficSiteCountLock) { return; }
  }

  async fetchPredictionTrafficSiteCountData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupData>[], number];
  }
  //#endregion prediction traffic-site/count

  //#region prediction traffic-site/unique-visitor
  async loadPredictionTrafficSiteUniqueVisitorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteUniqueVisitorLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSiteUniqueVisitorData(date, ++graphDataServiceInstance.predictionTrafficSiteUniqueVisitorLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteUniqueVisitorData(data, lockNum));
  }

  derivePredictionTrafficSiteUniqueVisitorData(trafficSiteUniqueVisitorDatas: IFetchData<UniqueVisitorsData>[], lockNum: number) {
    const trafficSiteUniqueVisitorTrendData: number[] = this.trafficSiteUniqueVisitorTrend || [0];
    GraphDataService.mapSevenDayLineChartData(trafficSiteUniqueVisitorDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const trafficSiteUniqueVisitorData = dataFiltered.data;
      trafficSiteUniqueVisitorTrendData[trafficSiteUniqueVisitorTrendData.length - 1] = GraphDataService.procesChartData(trafficSiteUniqueVisitorData.unique_vehicles_count, false, false);
    });
    this.trafficSiteUniqueVisitorTrend$.next(trafficSiteUniqueVisitorTrendData);
    if (lockNum < this.trafficSiteUniqueVisitorLock) { return; }
  }

  async fetchPredictionTrafficSiteUniqueVisitorData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/unique-visitor?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<UniqueVisitorsData>[], number];
  }

  //#endregion prediction traffic-site/unique-visitor

  //#region prediction traffic-site/mode-of-transportation
  async loadPredictionTrafficSiteModeOfTransportData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteModeOfTransportLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSiteModeOfTransportData(date, ++graphDataServiceInstance.predictionTrafficSiteModeOfTransportLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteModeOfTransportData(data, lockNum));
  }

  async derivePredictionTrafficSiteModeOfTransportData(modeOfTransDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeData: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tempTranSportModeData = this.trafficSiteModeOfTransportTrend || { total: [] };
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      dataFiltered.data.forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName] = [];
      });
    });
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered, _, _isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleTypeUsed: string[] = [];
      (dataFiltered.data as ModeOfTransportData[]).forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName].push(GraphDataService.procesChartData(vehicleTypeData.count));
        vehicleTypeUsed.push(vehicleTypeName);
      });
      const fillData = (data: { [vehicleTypeName: string]: number[]; total: number[] }) => {
        Object.keys(data).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          data[noDataType].push(0);
        });
      };
      fillData(tranSportModeData);
    });
    if (lockNum < this.predictionTrafficSiteModeOfTransportLock) { return; }
    Object.keys(tempTranSportModeData).forEach(key => {
      if (Object.keys(tranSportModeData).find(k => k === key)) {
        tempTranSportModeData[key][tempTranSportModeData[key].length - 1] = tranSportModeData[key][0];
      }
      else {
        tempTranSportModeData[key][tempTranSportModeData[key].length - 1] = 0;
      }
    });
    this.trafficSiteModeOfTransportTrend$.next(tempTranSportModeData);
  }

  async fetchPredictionTrafficSiteModeOfTransportData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/mode-of-transportation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion prediction traffic-site/mode-of-transportation

  //#region prediction traffic-site/mode-of-transportation-public-private
  async loadPredictionTrafficSiteModeOfTransportPublicPrivateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteModeOfTransportPublicPrivateLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSiteModeOfTransportPublicPrivateData(date, ++graphDataServiceInstance.predictionTrafficSiteModeOfTransportPublicPrivateLock, areaName)
      .then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteModeOfTransportPublicPrivateData(data, lockNum));
  }

  async derivePredictionTrafficSiteModeOfTransportPublicPrivateData(modeOfTransDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeData: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tempTranSportModeData = this.trafficSiteModeOfTransportPublicPrivateTrend || { total: [] };
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      dataFiltered.data.forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName] = [];
      });
    });
    GraphDataService.mapSevenDayLineChartData(modeOfTransDatas, this.viewPeriodService, (dataFiltered, _, _isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleTypeUsed: string[] = [];
      (dataFiltered.data as ModeOfTransportData[]).forEach(vehicleTypeData => {
        const vehicleTypeName = vehicleTypeData.group.vehicle_type ? vehicleTypeData.group.vehicle_type : 'total';
        tranSportModeData[vehicleTypeName].push(GraphDataService.procesChartData(vehicleTypeData.count));
        vehicleTypeUsed.push(vehicleTypeName);
      });
      const fillData = (data: { [vehicleTypeName: string]: number[]; total: number[] }) => {
        Object.keys(data).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          data[noDataType].push(0);
        });
      };
      fillData(tranSportModeData);
    });
    if (lockNum < this.predictionTrafficSiteModeOfTransportPublicPrivateLock) { return; }
    Object.keys(tempTranSportModeData).forEach(key => {
      if (Object.keys(tranSportModeData).find(k => k === key)) {
        tempTranSportModeData[key][tempTranSportModeData[key].length - 1] = tranSportModeData[key][0];
      }
      else {
        tempTranSportModeData[key][tempTranSportModeData[key].length - 1] = 0;
      }
    });
    this.trafficSiteModeOfTransportPublicPrivateTrend$.next(tempTranSportModeData);
  }

  async fetchPredictionTrafficSiteModeOfTransportPublicPrivateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/mode-of-transportation-public-private?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion prediction traffic-site/mode-of-transportation-public-private

  //#region prediction traffic-site/purchasing-power
  async loadPredictionTrafficSitePurchasingPowerData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSitePurchasingPowerLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSitePurchasingPowerData(date, ++graphDataServiceInstance.predictionTrafficSitePurchasingPowerLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSitePurchasingPowerData(data, lockNum));
  }

  derivePredictionTrafficSitePurchasingPowerData(vehiclePurchasingPowerDatas: IFetchData<VehiclePurchasingPowerData>[], lockNum: number) {
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tempTierListTrend = this.trafficSitePurchasingPowerTrend || { economy: [], luxury: [], premium: [] };
    GraphDataService.mapSevenDayLineChartData(vehiclePurchasingPowerDatas, this.viewPeriodService, (dataFiltered) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      Object.entries(dataFiltered.data).forEach(([tierName, _]) => {
        if (tierName.includes('count')) {
          return;
        }
        tierListTrend[tierName] = [];
      });
    });
    GraphDataService.mapSevenDayLineChartData(vehiclePurchasingPowerDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      Object.entries(dataFiltered.data).forEach(([tierName, tierData]) => {
        if (tierName.includes('count')) {
          return;
        }
        tierListTrend[tierName].push(GraphDataService.procesChartData((tierData / dataFiltered.data.count) * 100, false, false));
      });
    });
    if (lockNum < this.predictionTrafficSitePurchasingPowerLock) { return; }
    Object.keys(tempTierListTrend).forEach(key => {
      if (Object.keys(tierListTrend).find(k => k === key)) {
        tempTierListTrend[key][tempTierListTrend[key].length - 1] = tierListTrend[key][0];
      } else {
        tempTierListTrend[key][tempTierListTrend[key].length - 1] = 0;
      }
    });
    this.trafficSitePurchasingPowerTrend$.next(tempTierListTrend);
  }

  async fetchPredictionTrafficSitePurchasingPowerData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/purchasing-power?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VehiclePurchasingPowerData>[], number];
  }
  //#endregion prediction traffic-site/purchasing-power

  //#region traffic-site/vehicle-profile
  async loadTrafficSiteVehicleProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteVehicleProfileLock;
      return Promise.resolve();
    }
    graphDataServiceInstance.trafficSiteProfileCarBrandTrend = {};
    graphDataServiceInstance.trafficSiteProfileCountTrend = Array.from({ length: 7 }).map(() => null);
    graphDataServiceInstance.trafficSiteProfileModeOfTransportTrend = { total: Array.from({ length: 7 }).map(() => null) };
    graphDataServiceInstance.trafficSiteProfilePurchasingPowerTrend = {};
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.value;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteVehicleProfileData(date, ++graphDataServiceInstance.trafficSiteVehicleProfileLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfileData(data, lockNum))
        .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE));
    }
    return graphDataServiceInstance.fetchTrafficSiteVehicleProfileData(date, ++graphDataServiceInstance.trafficSiteVehicleProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfileData(data, lockNum));
  }

  deriveTrafficSiteVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    this.unfilteredVehicleProfileData$.next(trafficSiteVehicleProfileDatas);
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.purchasing_power) {
      tierListBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.car_brand) {
      carBrandBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.vehicle_type) {
      tranSportModeBreakdown[profileName] = 0;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
          this.trafficSiteProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
          this.trafficSiteProfileCarBrandTrend$.next(Object.entries(carBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
          this.trafficSiteProfilePurchasingPowerTrend$.next(Object.entries(tranSportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
          this.trafficSiteProfileModeOfTransportTrend$.next(Object.entries(tierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
        }
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        Object.keys(carBrandTrend).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tierListTrend).forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            tierListTrend[tier].push(profileData.count);
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSiteVehicleProfileLock) { return; }
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    // this.trafficSiteProfileCountTrend = trafficSiteVehicleProfileTrend;
    // this.trafficSiteProfileCarBrandTrend = Object.entries(carBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    // this.trafficSiteProfileModeOfTransportTrend = Object.entries(tranSportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    // this.trafficSiteProfilePurchasingPowerTrend = Object.entries(tierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    // if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
    //   this.trafficSiteProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
    //   this.trafficSiteProfileCarBrandTrend$.next(Object.entries(carBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    //   this.trafficSiteProfilePurchasingPowerTrend$.next(Object.entries(tranSportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    //   this.trafficSiteProfileModeOfTransportTrend$.next(Object.entries(tierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });  
    // }
    // this.trafficSiteProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    // this.trafficSiteProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    // this.trafficSiteProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    // this.currentTrafficSiteProfileCount$.next({
    //   count: GraphDataService.procesChartData(trafficSiteCountPair[1], false, false),
    //   diff: GraphDataService.procesChartData(trafficSiteCountPair[1] - trafficSiteCountPair[0], true, false),
    //   diffPercent: GraphDataService.procesChartData(((trafficSiteCountPair[1] - trafficSiteCountPair[0]) / trafficSiteCountPair[0]) * 100, true, false)
    // });
    /*console.log('TOTAL IMPRESSION TREND', trafficSiteVehicleProfileTrend);
    console.log('CAR BRAND TREND:', carBrandTrend);
    console.log('TIER LIST TREND:', tierListTrend);
    console.log('TRANSPORT TREND:', tranSportModeTrend);
    console.log('CAR BRAND BREAKDOWN:', sortableByVal(carBrandBreakdownPercentage));
    console.log('TIER LIST BREAKDOWN:', sortableByVal(tierListBreakdownPercentage));
    console.log('TRANSPORT BREAKDOWN:', sortableByVal(tranSportModeBreakdownPercentage));*/
  }

  deriveSelectedTrafficSiteVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    if (!trafficSiteVehicleProfileDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        if (isFound) {
          Object.keys(tierListTrend).forEach(tier => {
            if (selectedProfilefilter?.purchasing_power) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tierListTrend[tier].push(GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
                tierListTrend[tier].push(GraphDataService.procesChartData(profileData.count, false, false));
                if (diffToSelectedDate === 0) {
                  tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
                }
              }
            }
          });
          Object.keys(carBrandTrend).forEach(brand => {
            if (selectedProfilefilter?.car_brand) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
                }
              }
            }
          });
          Object.keys(tranSportModeTrend).forEach(type => {
            if (selectedProfilefilter?.vehicle_type) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tranSportModeTrend[type].push(GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
                if (type !== 'total') {
                  tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
                  if (diffToSelectedDate === 0) {
                    tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
                  }
                }
              }
            }
          });
        }
      }
      if (!isFound) {
        trafficSiteVehicleProfileTrend.push(0);
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(0));
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(0));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = selectedProfilefilter?.purchasing_power ? filteringList(tierListTrend, selectedProfilefilter?.purchasing_power) : tierListTrend;
    const filteredCarBrandTrend = selectedProfilefilter?.car_brand ? filteringList(carBrandTrend, selectedProfilefilter?.car_brand) : carBrandTrend;
    // eslint-disable-next-line max-len
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = selectedProfilefilter?.vehicle_type ? Object.entries(tranSportModeTrend).filter(([k, _v]) => k === selectedProfilefilter?.vehicle_type).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] } : tranSportModeTrend;
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteProfileCountTrend = trafficSiteVehicleProfileTrend;
    this.trafficSiteProfileCarBrandTrend = Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.trafficSiteProfileModeOfTransportTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    this.trafficSiteProfilePurchasingPowerTrend = Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
      this.trafficSiteProfileCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfilePurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfileModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    this.callTrafficSiteVehicleProfilePrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));
    this.trafficSiteProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.trafficSiteProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.trafficSiteProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    this.currentTrafficSiteProfileCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPair[1] - trafficSiteCountPair[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPair[1] - trafficSiteCountPair[0]) / trafficSiteCountPair[0]) * 100, true, true)
    });
  }

  async fetchTrafficSiteVehicleProfileData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/vehicle-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/vehicle-profile

  //#region traffic-site/vehicle-profile-by-hour
  async loadTrafficSiteVehicleProfileByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteVehicleProfileByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchTrafficSiteVehicleProfileByHourData(date, ++graphDataServiceInstance.trafficSiteVehicleProfileByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfileByHourData(data, lockNum));
  }

  deriveTrafficSiteVehicleProfileByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.unfilteredVehicleProfileByHourData$.next(trafficSiteCountByHourDatas);
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey)) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, {})) {
            const pushData = GraphDataService.procesChartData(profileData.count, false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(fillValue);
        trafficByHour[time] = fillValue;
      }
    });
    const peakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.keys(trafficByHour).filter(k => k !== '6.00' && k !== '24.00').reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    if (lockNum < this.trafficSiteVehicleProfileByHourLock) { return; }
    /*this.trafficSiteCountByHour$.next(trafficByHourCount);
    this.trafficSitePeakTime$.next({
      timeKey: peakTime,
      count: trafficByHour[peakTime]
    });
    this.trafficSiteOffPeakTime$.next({
      timeKey: offPeakTime,
      count: trafficByHour[offPeakTime]
    });*/
    // this.trafficSiteProfileCountByHour$.next(trafficByHourCount);
    // this.trafficSiteProfilePeakTime$.next({
    //   timeKey: peakTime,
    //   count: trafficByHour[peakTime]
    // });
    // this.trafficSiteProfileOffPeakTime$.next({
    //   timeKey: offPeakTime,
    //   count: trafficByHour[offPeakTime]
    // });
  }

  deriveSelectedTrafficSiteVehicleProfileByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey) !== undefined) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            const pushData = GraphDataService.procesChartData(profileData.count, false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(fillValue);
        trafficByHour[time] = fillValue;
      }
    });
    const peakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).filter(k => k !== '6.00' && k !== '24.00').reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    this.trafficSiteProfileCountByHour$.next(trafficByHourCount);
    this.trafficSiteProfilePeakTime$.next({
      timeKey: peakTime,
      count: trafficByHour[peakTime]
    });
    this.trafficSiteProfileOffPeakTime$.next({
      timeKey: offPeakTime,
      count: trafficByHour[offPeakTime]
    });
    // this.trafficSiteAdsExposureTimebyHour$.next(adsExposureByHour);
  }

  async fetchTrafficSiteVehicleProfileByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/vehicle-profile-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/vehicle-profile-by-hour

  //#region traffic-site/vehicle-profile-public-private
  async loadTrafficSiteVehicleProfilePublicPrivateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteVehicleProfilePublicPrivateLock;
      return Promise.resolve();
    }
    graphDataServiceInstance.trafficSiteProfileModeOfTransportPublicPrivateTrend = { total: Array.from({ length: 7 }).map(() => null) };
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteVehicleProfilePublicPrivateData(date, ++graphDataServiceInstance.trafficSiteVehicleProfilePublicPrivateLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfilePublicPrivateData(data, lockNum))
        .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE));
    }
    return graphDataServiceInstance.fetchTrafficSiteVehicleProfilePublicPrivateData(date, ++graphDataServiceInstance.trafficSiteVehicleProfilePublicPrivateLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfilePublicPrivateData(data, lockNum));
  }

  deriveTrafficSiteVehicleProfilePublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    this.unfilteredVehicleProfilePublicPrivateData$.next(trafficSiteVehicleProfilePublicPrivateDatas);
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
        }
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSiteVehicleProfilePublicPrivateLock) { return; }
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteProfileModeOfTransportPublicPrivateTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteProfileModeOfTransportPublicPrivateTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    this.trafficSiteProfileModeOfTransportPublicPrivateBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
  }

  deriveSelectedTrafficSiteVehicleProfilePublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    if (!trafficSiteVehicleProfilePublicPrivateDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type === 'private' || profileData.group?.vehicle_type === 'public') {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      let vehiclePrivate = 0;
      let vehiclePublic = 0;
      for (const profileData of vehicleProfileData) {
        Object.keys(tranSportModeTrend).forEach(type => {
          selectedProfilefilter.vehicle_type = type;
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            isFound = true;
            type === 'public' ? vehiclePublic = GraphDataService.procesChartData(profileData.count, false, false) : vehiclePrivate = GraphDataService.procesChartData(profileData.count, false, false);
            tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
      }
      const sumTotal = vehiclePrivate + vehiclePublic;
      tranSportModeTrend.total.push(GraphDataService.procesChartData(sumTotal, false, false));
      if (!isFound) {
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteProfileModeOfTransportPublicPrivateTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteProfileModeOfTransportPublicPrivateTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    this.callTrafficSiteVehicleProfilePublicPrivatePrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));
    this.trafficSiteProfileModeOfTransportPublicPrivateBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
  }

  async fetchTrafficSiteVehicleProfilePublicPrivateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/vehicle-profile-public-private?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion traffic-site/vehicle-profile-public-private

  //#region prediction traffic-site/vehicle-profile
  async loadPredictionTrafficSiteVehicleProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteVehicleProfileLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSiteVehicleProfileData(date, ++graphDataServiceInstance.predictionTrafficSiteVehicleProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteVehicleProfileData(data, lockNum));
  }

  derivePredictionTrafficSiteVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    this.unfilteredPredictionVehicleProfileData$.next(trafficSiteVehicleProfileDatas);
    const tempTrafficSiteProfileCarBrandTrend = this.trafficSiteProfileCarBrandTrend;
    const tempTrafficSiteProfileCountTrend = this.trafficSiteProfileCountTrend;
    const tempTrafficSiteProfileModeOfTransportTrend = this.trafficSiteProfileModeOfTransportTrend;
    const tempTrafficSiteProfilePurchasingPowerTrend = this.trafficSiteProfilePurchasingPowerTrend;
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        Object.keys(carBrandTrend).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tierListTrend).forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            tierListTrend[tier].push(profileData.count);
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSiteVehicleProfileLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }) => Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  }

  deriveSelectedPredictionTrafficSiteVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficSiteVehicleProfileTrend: number[] = [];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const tempTrafficSiteProfileCarBrandTrend = this.trafficSiteProfileCarBrandTrend;
    const tempTrafficSiteProfileCountTrend = this.trafficSiteProfileCountTrend;
    const tempTrafficSiteProfileModeOfTransportTrend = this.trafficSiteProfileModeOfTransportTrend;
    const tempTrafficSiteProfilePurchasingPowerTrend = this.trafficSiteProfilePurchasingPowerTrend;
    if (!trafficSiteVehicleProfileDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          tempTrafficSiteProfileCountTrend[tempTrafficSiteProfileCountTrend.length - 1] = GraphDataService.procesChartData(profileData.count, false, false);
        }
        if (isFound) {
          Object.keys(tierListTrend).forEach(tier => {
            if (selectedProfilefilter?.purchasing_power) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tierListTrend[tier].push(GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false));
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
                tierListTrend[tier].push(GraphDataService.procesChartData(profileData.count, false, false));
              }
            }
          });
          Object.keys(carBrandTrend).forEach(brand => {
            if (selectedProfilefilter?.car_brand) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false));
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
              }
            }
          });
          Object.keys(tranSportModeTrend).forEach(type => {
            if (selectedProfilefilter?.vehicle_type) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tranSportModeTrend[type].push(GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false));
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
                if (type !== 'total') {
                  tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
                }
              }
            }
          });
        }
      }
      if (!isFound) {
        trafficSiteVehicleProfileTrend.push(0);
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(0));
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(0));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = selectedProfilefilter?.purchasing_power ? filteringList(tierListTrend, selectedProfilefilter?.purchasing_power) : tierListTrend;
    const filteredCarBrandTrend = selectedProfilefilter?.car_brand ? filteringList(carBrandTrend, selectedProfilefilter?.car_brand) : carBrandTrend;
    // eslint-disable-next-line max-len
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = selectedProfilefilter?.vehicle_type ? Object.entries(tranSportModeTrend).filter(([k, _v]) => k === selectedProfilefilter?.vehicle_type).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] } : tranSportModeTrend;
    Object.keys(tempTrafficSiteProfilePurchasingPowerTrend).forEach(key => {
      if (Object.keys(filteredTierListTrend).find(k => k === key)) {
        tempTrafficSiteProfilePurchasingPowerTrend[key][tempTrafficSiteProfilePurchasingPowerTrend[key].length - 1] = filteredTierListTrend[key][0];
      } else {
        tempTrafficSiteProfilePurchasingPowerTrend[key][tempTrafficSiteProfilePurchasingPowerTrend[key].length - 1] = 0;
      }
    });
    Object.keys(tempTrafficSiteProfileModeOfTransportTrend).forEach(key => {
      if (Object.keys(filteredTransportModeTrend).find(k => k === key)) {
        tempTrafficSiteProfileModeOfTransportTrend[key][tempTrafficSiteProfileModeOfTransportTrend[key].length - 1] = filteredTransportModeTrend[key][0];
      } else {
        tempTrafficSiteProfileModeOfTransportTrend[key][tempTrafficSiteProfileModeOfTransportTrend[key].length - 1] = 0;
      }
    });
    Object.keys(tempTrafficSiteProfileCarBrandTrend).forEach(key => {
      if (Object.keys(filteredCarBrandTrend).find(k => k === key)) {
        tempTrafficSiteProfileCarBrandTrend[key][tempTrafficSiteProfileCarBrandTrend[key].length - 1] = filteredCarBrandTrend[key][0];
      } else {
        tempTrafficSiteProfileCarBrandTrend[key][tempTrafficSiteProfileCarBrandTrend[key].length - 1] = 0;
      }
    });
    this.trafficSiteProfileCountTrend$.next(tempTrafficSiteProfileCountTrend);
    this.trafficSiteProfileCarBrandTrend$.next(Object.entries(tempTrafficSiteProfileCarBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSiteProfilePurchasingPowerTrend$.next(Object.entries(tempTrafficSiteProfilePurchasingPowerTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSiteProfileModeOfTransportTrend$.next(Object.entries(tempTrafficSiteProfileModeOfTransportTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
  }

  async fetchPredictionTrafficSiteVehicleProfileData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/vehicle-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion prediction traffic-site/vehicle-profile

  //#region prediction traffic-site/vehicle-profile-public-private
  async loadPredictionTrafficSiteVehicleProfilePublicPrivateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteVehicleProfilePublicPrivateLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    // eslint-disable-next-line max-len
    return graphDataServiceInstance.fetchPredictionTrafficSiteVehicleProfilePublicPrivateData(date, ++graphDataServiceInstance.predictionTrafficSiteVehicleProfilePublicPrivateLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteVehicleProfilePublicPrivateData(data, lockNum));
  }

  derivePredictionTrafficSiteVehicleProfilePublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    this.unfilteredPredictionVehicleProfilePublicPrivateData$.next(trafficSiteVehicleProfilePublicPrivateDatas);
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
        }
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.predictionTrafficSiteVehicleProfilePublicPrivateLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }) => Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  }

  deriveSelectedPredictionTrafficSiteVehicleProfilePublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tempTranSportModeTrend = this.trafficSiteProfileModeOfTransportPublicPrivateTrend;
    if (!trafficSiteVehicleProfilePublicPrivateDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type === 'private' || profileData.group?.vehicle_type === 'public') {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      let vehiclePrivate = 0;
      let vehiclePublic = 0;
      for (const profileData of vehicleProfileData) {
        Object.keys(tranSportModeTrend).forEach(type => {
          selectedProfilefilter.vehicle_type = type;
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            isFound = true;
            type === 'public' ? vehiclePublic = GraphDataService.procesChartData(profileData.count, false, false) : vehiclePrivate = GraphDataService.procesChartData(profileData.count, false, false);
            tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
          }
        });
      }
      const sumTotal = vehiclePrivate + vehiclePublic;
      tranSportModeTrend.total.push(GraphDataService.procesChartData(sumTotal, false, false));
      if (!isFound) {
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    Object.keys(tempTranSportModeTrend).forEach(key => {
      if (Object.keys(filteredTransportModeTrend).find(k => k === key)) {
        tempTranSportModeTrend[key][tempTranSportModeTrend[key].length - 1] = filteredTransportModeTrend[key][0];
      } else {
        tempTranSportModeTrend[key][tempTranSportModeTrend[key].length - 1] = 0;
      }
    });
    this.trafficSiteProfileModeOfTransportPublicPrivateTrend$.next(Object.entries(tempTranSportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
  }

  async fetchPredictionTrafficSiteVehicleProfilePublicPrivateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/vehicle-profile-public-private?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion prediction traffic-site/vehicle-profile-public-private

  //#region traffic-site/vehicle-profile-unique-visitor
  async loadTrafficSiteVehicleProfileUniqueVisitorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteVehicleProfileUniqueVisitorLock;
      return Promise.resolve();
    }
    graphDataServiceInstance.trafficSiteProfileUniqueVisitorCarBrandTrend = {};
    graphDataServiceInstance.trafficSiteProfileUniqueVisitorCountTrend = Array.from({ length: 7 }).map(() => null);
    graphDataServiceInstance.trafficSiteProfileUniqueVisitorModeOfTransportTrend = { total: Array.from({ length: 7 }).map(() => null) };
    graphDataServiceInstance.trafficSiteProfileUniqueVisitorPurchasingPowerTrend = {};
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteVehicleProfileUniqueVisitorData(date, ++graphDataServiceInstance.trafficSiteVehicleProfileUniqueVisitorLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfileUniqueVisitorData(data, lockNum))
        .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR));
    }
    return graphDataServiceInstance.fetchTrafficSiteVehicleProfileUniqueVisitorData(date, ++graphDataServiceInstance.trafficSiteVehicleProfileUniqueVisitorLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfileUniqueVisitorData(data, lockNum));
  }

  deriveTrafficSiteVehicleProfileUniqueVisitorData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const trafficSiteAvgVisitorPair: [number, number] = [0, 0];
    this.unfilteredVehicleProfileUniqueVisitorData$.next(trafficSiteVehicleProfileDatas);
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
            trafficSiteAvgVisitorPair[diffToSelectedDate + 1] = GraphDataService.procesChartData(profileData.average_visitation_count, false, true);
          }
        }
        Object.keys(carBrandTrend).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tierListTrend).forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            tierListTrend[tier].push(profileData.count);
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSiteVehicleProfileUniqueVisitorLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = tierListTrend;
    const filteredCarBrandTrend = carBrandTrend;
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteProfileUniqueVisitorCountTrend = trafficSiteVehicleProfileTrend;
    this.trafficSiteProfileUniqueVisitorCarBrandTrend = Object.entries(filteredCarBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.trafficSiteProfileUniqueVisitorModeOfTransportTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    this.trafficSiteProfileUniqueVisitorPurchasingPowerTrend = Object.entries(filteredTierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteProfileUniqueVisitorCountTrend$.next(trafficSiteVehicleProfileTrend);
      this.trafficSiteProfileUniqueVisitorCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfileUniqueVisitorPurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfileUniqueVisitorModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    this.trafficSiteProfileUniqueVisitorModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.trafficSiteProfileUniqueVisitorTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.trafficSiteProfileUniqueVisitorPurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    this.currentTrafficSiteProfileUniqueVisitorCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPair[1] - trafficSiteCountPair[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPair[1] - trafficSiteCountPair[0]) / trafficSiteCountPair[0]) * 100, true, false)
    });
    this.currentTrafficSiteAvgVisitorCount$.next({
      count: GraphDataService.procesChartData(trafficSiteAvgVisitorPair[1], false, true),
      diff: GraphDataService.procesChartData(trafficSiteAvgVisitorPair[1] - trafficSiteAvgVisitorPair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((trafficSiteAvgVisitorPair[1] - trafficSiteAvgVisitorPair[0]) / trafficSiteAvgVisitorPair[0]) * 100, true, true)
    });
  }

  deriveSelectedTrafficSiteVehicleProfileUniqueVisitorData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const trafficSiteAvgVisitorPair: [number, number] = [0, 0];
    if (!trafficSiteVehicleProfileDatas) { return; }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
            trafficSiteAvgVisitorPair[diffToSelectedDate + 1] = GraphDataService.procesChartData(profileData.average_visitation_count, false, true);
          }
        }
        if (isFound) {
          Object.keys(tierListTrend).forEach(tier => {
            if (selectedProfilefilter?.purchasing_power) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tierListTrend[tier].push(GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
                tierListTrend[tier].push(GraphDataService.procesChartData(profileData.count, false, false));
                if (diffToSelectedDate === 0) {
                  tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
                }
              }
            }
          });
          Object.keys(carBrandTrend).forEach(brand => {
            if (selectedProfilefilter?.car_brand) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
                }
              }
            }
          });
          Object.keys(tranSportModeTrend).forEach(type => {
            if (selectedProfilefilter?.vehicle_type) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tranSportModeTrend[type].push(GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
                if (type !== 'total') {
                  tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
                  if (diffToSelectedDate === 0) {
                    tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
                  }
                }
              }
            }
          });
        }
      }
      if (!isFound) {
        trafficSiteVehicleProfileTrend.push(0);
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(0));
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(0));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = selectedProfilefilter?.purchasing_power ? filteringList(tierListTrend, selectedProfilefilter?.purchasing_power) : tierListTrend;
    const filteredCarBrandTrend = selectedProfilefilter?.car_brand ? filteringList(carBrandTrend, selectedProfilefilter?.car_brand) : carBrandTrend;
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = selectedProfilefilter?.vehicle_type ? Object.entries(tranSportModeTrend).filter(([k, _v]) => k === selectedProfilefilter?.vehicle_type).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] } : tranSportModeTrend;
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteProfileUniqueVisitorCountTrend = trafficSiteVehicleProfileTrend;
    this.trafficSiteProfileUniqueVisitorCarBrandTrend = Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.trafficSiteProfileUniqueVisitorModeOfTransportTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    this.trafficSiteProfileUniqueVisitorPurchasingPowerTrend = Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteProfileUniqueVisitorCountTrend$.next(trafficSiteVehicleProfileTrend);
      this.trafficSiteProfileUniqueVisitorCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfileUniqueVisitorPurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfileUniqueVisitorModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    this.callTrafficSiteVehicleProfileUniqueVisitorPrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));
    this.trafficSiteProfileUniqueVisitorModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.trafficSiteProfileUniqueVisitorTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.trafficSiteProfileUniqueVisitorPurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    this.currentTrafficSiteProfileUniqueVisitorCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPair[1] - trafficSiteCountPair[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPair[1] - trafficSiteCountPair[0]) / trafficSiteCountPair[0]) * 100, true, true)
    });
    this.currentTrafficSiteAvgVisitorCount$.next({
      count: GraphDataService.procesChartData(trafficSiteAvgVisitorPair[1], false, true),
      diff: GraphDataService.procesChartData(trafficSiteAvgVisitorPair[1] - trafficSiteAvgVisitorPair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((trafficSiteAvgVisitorPair[1] - trafficSiteAvgVisitorPair[0]) / trafficSiteAvgVisitorPair[0]) * 100, true, true)
    });
  }

  async fetchTrafficSiteVehicleProfileUniqueVisitorData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/vehicle-profile-unique-visitor?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/vehicle-profile-unique-visitor

  //#region prediction traffic-site/vehicle-profile-unique-visitor
  async loadPredictionTrafficSiteVehicleProfileUniqueVisitorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if ((selectedInteractable?.type !== 'area') || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteVehicleProfileUniqueVisitorLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSiteVehicleProfileUniqueVisitorData(date, ++graphDataServiceInstance.predictionTrafficSiteVehicleProfileUniqueVisitorLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteVehicleProfileUniqueVisitorData(data, lockNum));
  }

  derivePredictionTrafficSiteVehicleProfileUniqueVisitorData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    this.unfilteredPredictionVehicleProfileUniqueVisitorData$.next(trafficSiteVehicleProfileDatas);
    const tempTrafficSiteProfileCarBrandTrend = this.trafficSiteProfileCarBrandTrend;
    const tempTrafficSiteProfileCountTrend = this.trafficSiteProfileCountTrend;
    const tempTrafficSiteProfileModeOfTransportTrend = this.trafficSiteProfileModeOfTransportTrend;
    const tempTrafficSiteProfilePurchasingPowerTrend = this.trafficSiteProfilePurchasingPowerTrend;
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        Object.keys(carBrandTrend).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tierListTrend).forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            tierListTrend[tier].push(profileData.count);
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSiteVehicleProfileLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }) => Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  }

  deriveSelectedPredictionTrafficSiteVehicleProfileUniqueVisitorData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficSiteVehicleProfileTrend: number[] = [];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const tempTrafficSiteProfileCarBrandTrend = this.trafficSiteProfileUniqueVisitorCarBrandTrend;
    const tempTrafficSiteProfileCountTrend = this.trafficSiteProfileUniqueVisitorCountTrend;
    const tempTrafficSiteProfileModeOfTransportTrend = this.trafficSiteProfileUniqueVisitorModeOfTransportTrend;
    const tempTrafficSiteProfilePurchasingPowerTrend = this.trafficSiteProfileUniqueVisitorPurchasingPowerTrend;
    if (!trafficSiteVehicleProfileDatas) { return; }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          tempTrafficSiteProfileCountTrend[tempTrafficSiteProfileCountTrend.length - 1] = GraphDataService.procesChartData(profileData.count, false, false);
        }
        if (isFound) {
          Object.keys(tierListTrend).forEach(tier => {
            if (selectedProfilefilter?.purchasing_power) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tierListTrend[tier].push(GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false));
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
                tierListTrend[tier].push(GraphDataService.procesChartData(profileData.count, false, false));
              }
            }
          });
          Object.keys(carBrandTrend).forEach(brand => {
            if (selectedProfilefilter?.car_brand) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false));
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
              }
            }
          });
          Object.keys(tranSportModeTrend).forEach(type => {
            if (selectedProfilefilter?.vehicle_type) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tranSportModeTrend[type].push(GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false));
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
                if (type !== 'total') {
                  tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
                }
              }
            }
          });
        }
      }
      if (!isFound) {
        trafficSiteVehicleProfileTrend.push(0);
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(0));
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(0));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = selectedProfilefilter?.purchasing_power ? filteringList(tierListTrend, selectedProfilefilter?.purchasing_power) : tierListTrend;
    const filteredCarBrandTrend = selectedProfilefilter?.car_brand ? filteringList(carBrandTrend, selectedProfilefilter?.car_brand) : carBrandTrend;
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = selectedProfilefilter?.vehicle_type ? Object.entries(tranSportModeTrend).filter(([k, _v]) => k === selectedProfilefilter?.vehicle_type).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] } : tranSportModeTrend;
    Object.keys(tempTrafficSiteProfilePurchasingPowerTrend).forEach(key => {
      if (Object.keys(filteredTierListTrend).find(k => k === key)) {
        tempTrafficSiteProfilePurchasingPowerTrend[key][tempTrafficSiteProfilePurchasingPowerTrend[key].length - 1] = filteredTierListTrend[key][0];
      } else {
        tempTrafficSiteProfilePurchasingPowerTrend[key][tempTrafficSiteProfilePurchasingPowerTrend[key].length - 1] = 0;
      }
    });
    Object.keys(tempTrafficSiteProfileModeOfTransportTrend).forEach(key => {
      if (Object.keys(filteredTransportModeTrend).find(k => k === key)) {
        tempTrafficSiteProfileModeOfTransportTrend[key][tempTrafficSiteProfileModeOfTransportTrend[key].length - 1] = filteredTransportModeTrend[key][0];
      } else {
        tempTrafficSiteProfileModeOfTransportTrend[key][tempTrafficSiteProfileModeOfTransportTrend[key].length - 1] = 0;
      }
    });
    Object.keys(tempTrafficSiteProfileCarBrandTrend).forEach(key => {
      if (Object.keys(filteredCarBrandTrend).find(k => k === key)) {
        tempTrafficSiteProfileCarBrandTrend[key][tempTrafficSiteProfileCarBrandTrend[key].length - 1] = filteredCarBrandTrend[key][0];
      } else {
        tempTrafficSiteProfileCarBrandTrend[key][tempTrafficSiteProfileCarBrandTrend[key].length - 1] = 0;
      }
    });
    this.trafficSiteProfileUniqueVisitorCountTrend$.next(tempTrafficSiteProfileCountTrend);
    this.trafficSiteProfileUniqueVisitorCarBrandTrend$.next(Object.entries(tempTrafficSiteProfileCarBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSiteProfileUniqueVisitorPurchasingPowerTrend$.next(Object.entries(tempTrafficSiteProfilePurchasingPowerTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSiteProfileUniqueVisitorModeOfTransportTrend$.next(Object.entries(tempTrafficSiteProfileModeOfTransportTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
  }

  async fetchPredictionTrafficSiteVehicleProfileUniqueVisitorData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/vehicle-profile-unique-visitor?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion prediction traffic-site/vehicle-profile-unique-visitor

  //#region traffic-site/vehicle-profile-unique-visitor-public-private
  async loadTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteVehicleProfileUniqueVisitorPublicPrivateLock;
      return Promise.resolve();
    }
    graphDataServiceInstance.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend = { total: Array.from({ length: 7 }).map(() => null) };
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
      return graphDataServiceInstance.fetchTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(date, ++graphDataServiceInstance.trafficSiteVehicleProfilePublicPrivateLock, areaName)
        .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(data, lockNum))
        .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE));
    }
    return graphDataServiceInstance.fetchTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(date, ++graphDataServiceInstance.trafficSiteVehicleProfileUniqueVisitorPublicPrivateLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(data, lockNum));
  }

  deriveTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    this.unfilteredVehicleProfileUniqueVisitorPublicPrivateData$.next(trafficSiteVehicleProfilePublicPrivateDatas);
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
        }
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSiteVehicleProfilePublicPrivateLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
  }

  deriveSelectedTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    if (!trafficSiteVehicleProfilePublicPrivateDatas) { return; }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type === 'private' || profileData.group?.vehicle_type === 'public') {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      let vehiclePrivate = 0;
      let vehiclePublic = 0;
      for (const profileData of vehicleProfileData) {
        Object.keys(tranSportModeTrend).forEach(type => {
          selectedProfilefilter.vehicle_type = type;
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            isFound = true;
            type === 'public' ? vehiclePublic = GraphDataService.procesChartData(profileData.count, false, false) : vehiclePrivate = GraphDataService.procesChartData(profileData.count, false, false);
            tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
      }
      const sumTotal = vehiclePrivate + vehiclePublic;
      tranSportModeTrend.total.push(GraphDataService.procesChartData(sumTotal, false, false));
      if (!isFound) {
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    this.callTrafficSiteVehicleProfileUniqueVisitorPublicPrivatePrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));

    this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
  }

  async fetchTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/vehicle-profile-unique-visitor-public-private?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion traffic-site/vehicle-profile-unique-visitor-public-private

  //#region prediction traffic-site/vehicle-profile-unique-visitor-public-private
  async loadPredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchPredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(date, ++graphDataServiceInstance.predictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.derivePredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(data, lockNum));
  }

  derivePredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    this.unfilteredPredictionVehicleProfileUniqueVisitorPublicPrivateData$.next(trafficSiteVehicleProfilePublicPrivateDatas);
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
        }
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.predictionTrafficSiteVehicleProfilePublicPrivateLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }) => Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  }

  deriveSelectedPredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tempTranSportModeTrend = this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend;
    if (!trafficSiteVehicleProfilePublicPrivateDatas) { return; }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type === 'private' || profileData.group?.vehicle_type === 'public') {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      let vehiclePrivate = 0;
      let vehiclePublic = 0;
      for (const profileData of vehicleProfileData) {
        Object.keys(tranSportModeTrend).forEach(type => {
          selectedProfilefilter.vehicle_type = type;
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            isFound = true;
            type === 'public' ? vehiclePublic = GraphDataService.procesChartData(profileData.count, false, false) : vehiclePrivate = GraphDataService.procesChartData(profileData.count, false, false);
            tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
          }
        });
      }
      const sumTotal = vehiclePrivate + vehiclePublic;
      tranSportModeTrend.total.push(GraphDataService.procesChartData(sumTotal, false, false));
      if (!isFound) {
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    Object.keys(tempTranSportModeTrend).forEach(key => {
      if (Object.keys(filteredTransportModeTrend).find(k => k === key)) {
        tempTranSportModeTrend[key][tempTranSportModeTrend[key].length - 1] = filteredTransportModeTrend[key][0];
      } else {
        tempTranSportModeTrend[key][tempTranSportModeTrend[key].length - 1] = 0;
      }
    });
    this.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend$.next(Object.entries(tempTranSportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
  }

  async fetchPredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/vehicle-profile-unique-visitor-public-private?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion prediction traffic-site/vehicle-profile-unique-visitor-public-private

  //#region goal zone/entrance-exit
  async loadGoalEntranceExitZoneData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const goalStartDate = graphDataServiceInstance.goalStartDate$.getValue();
    const excludeDate = graphDataServiceInstance.goalExcludeDate$.getValue() || [];
    if (!goalStartDate && graphDataServiceInstance.viewPeriodService.isWeekPeriod && graphDataServiceInstance.viewPeriodService.isMonthPeriod) {
      ++graphDataServiceInstance.goalZoneEntranceExitLock;
      return;
    }
    const momentGoalStartDate = moment(goalStartDate, 'MM-DD-YYYY');
    const current_date = graphDataServiceInstance.viewPeriodService.isLiveMode ? date.clone().subtract(1, 'd') : date.clone();
    return graphDataServiceInstance.fetchGoalEntranceExitZoneData(current_date, ++graphDataServiceInstance.goalZoneEntranceExitLock, momentGoalStartDate)
      .then(([data, lockNum]) => graphDataServiceInstance.deriveGoalEntranceExitZoneData(data, lockNum, current_date, momentGoalStartDate, excludeDate))
      .then(() => graphDataServiceInstance.loadPredictionGoalEntranceExitZoneData(current_date));
  }

  deriveGoalEntranceExitZoneData(entranceExitZoneDatas: IFetchData<BuildingZoneEntranceExitData>[], lockNum: number, date: moment.Moment, startDate: moment.Moment, excludeDate: string[]) {
    const currentGoalTrafficZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
    const currentGoalNetShoppingTimeZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
    const momentExcludeDate = excludeDate.map(d => moment(d, 'MM-DD-YYYY'));
    const brandList = this.configDataService.BRAND_LIST || [];
    const currentGoalBrandTrafficData: { [key: string]: any } = brandList.reduce((obj, key) => {
      obj[key] = { val: 0, changed: 0 };
      return obj;
    }, {});
    entranceExitZoneDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        if (!currentGoalTrafficZoneData[buildingName]) {
          currentGoalTrafficZoneData[buildingName] = {};
          currentGoalNetShoppingTimeZoneData[buildingName] = {};
        }
        Object.entries(floorData).forEach(([floorName, zoneData]) => {
          if (!currentGoalTrafficZoneData[buildingName][floorName]) {
            currentGoalTrafficZoneData[buildingName][floorName] = {};
            currentGoalNetShoppingTimeZoneData[buildingName][floorName] = {};
          }
          Object.keys(zoneData).forEach((zoneName) => {
            currentGoalTrafficZoneData[buildingName][floorName][zoneName] = { val: 0, changed: 0 };
            currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName] = { val: 0, changed: 0 };
          });
        });
      });
    });
    const diffDate = date.diff(startDate, 'day') < 1 ? 1 : date.diff(startDate, 'day');
    for (const momentIt = startDate; momentIt <= date; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitZoneDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = startDate.diff(date, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const dataItDate = moment(`${dataIt.month}-${dataIt.day}-${dataIt.year}`, 'MM-DD-YYYY');
        if (momentExcludeDate.some(d => d.isSame(dataItDate))) {
          continue;
        }
        const entranceExitZoneData = dataIt.data;
        Object.entries(entranceExitZoneData).forEach(([buildingName, floorData]) => {
          if (!currentGoalTrafficZoneData[buildingName]) {
            currentGoalTrafficZoneData[buildingName] = {};
            currentGoalNetShoppingTimeZoneData[buildingName] = {};
          }
          Object.entries(floorData).forEach(([floorName, zoneData]) => {
            if (!currentGoalTrafficZoneData[buildingName][floorName]) {
              currentGoalTrafficZoneData[buildingName][floorName] = {};
              currentGoalNetShoppingTimeZoneData[buildingName][floorName] = {};
            }
            Object.keys(zoneData).forEach((zoneName) => {
              const findedBrand = brandList.find(brand => zoneName.endsWith(brand));
              if (diffPrevDate === -1) {
                if (findedBrand !== undefined) {
                  currentGoalBrandTrafficData[findedBrand].changed = entranceExitZoneData[buildingName][floorName][zoneName].entrance;
                }
                currentGoalTrafficZoneData[buildingName][floorName][zoneName].changed = entranceExitZoneData[buildingName][floorName][zoneName].entrance;
                currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName].changed = entranceExitZoneData[buildingName][floorName][zoneName].net_shopping_time;
              }
              if (findedBrand !== undefined) {
                currentGoalBrandTrafficData[findedBrand].val += entranceExitZoneData[buildingName][floorName][zoneName].entrance;
              }
              currentGoalTrafficZoneData[buildingName][floorName][zoneName].val += entranceExitZoneData[buildingName][floorName][zoneName].entrance;
              currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName].val += entranceExitZoneData[buildingName][floorName][zoneName].net_shopping_time;
            });
          });
        });
      }
    }

    const exportCurrentGoalAvgTrafficZoneData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } };
    } = Object.entries(currentGoalTrafficZoneData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, zoneCurrentData]) => {
          prev[buildingName][floorName][zoneName] = {
            val: GraphDataService.procesChartData(zoneCurrentData.val / diffDate, false, false),
            changed: GraphDataService.procesChartData(zoneCurrentData.changed / diffDate, false, false),
          };
        });
      });
      return prev;
    }, Object.entries(currentGoalTrafficZoneData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.keys(zoneData).reduce((accZone, zoneName) => {
          accZone[zoneName] = {};
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    Object.keys(currentGoalBrandTrafficData).forEach(brand => {
      currentGoalBrandTrafficData[brand].val = GraphDataService.procesChartData((currentGoalBrandTrafficData[brand].val / diffDate), false, false);
      currentGoalBrandTrafficData[brand].changed = GraphDataService.procesChartData((currentGoalBrandTrafficData[brand].changed / (diffDate - 1)), false, false);
    });

    if (lockNum < this.goalZoneEntranceExitLock) { return; }
    this.currentGoalTrafficZoneData = currentGoalTrafficZoneData;
    this.currentGoalAvgTrafficZoneData = exportCurrentGoalAvgTrafficZoneData;
    this.currentGoalAvgTrafficBrandData = currentGoalBrandTrafficData;
    //this.currentGoalTrafficZoneData$.next(currentGoalTrafficZoneData);
  }

  async fetchGoalEntranceExitZoneData(date: moment.Moment, lockNum: number, goalStartDate: moment.Moment) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const numInterval = date.isSame(goalStartDate, 'd') ? 1 : date.diff(goalStartDate, 'd') + 1; //(for any goal)
    const qParams = { start_date: date.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingZoneEntranceExitData>[], number];
  }
  //#endregion goal zone/entrance-exit

  //#region prediction goal zone/entrance-exit
  async loadPredictionGoalEntranceExitZoneData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const goalStartDate = graphDataServiceInstance.goalStartDate$.getValue();
    const goalEndDate = graphDataServiceInstance.goalEndDate$.getValue();
    const excludeDate = graphDataServiceInstance.goalExcludeDate$.getValue() || [];
    if (!goalStartDate || !goalEndDate) {
      ++graphDataServiceInstance.predGoalZoneEntranceExitLock;
      return;
    }
    const momentGoalStartDate = moment(goalStartDate, 'MM-DD-YYYY');
    const momentGoalEndDate = moment(goalEndDate, 'MM-DD-YYYY');
    return graphDataServiceInstance.fetchPredictionGoalEntranceExitZoneData(date, ++graphDataServiceInstance.predGoalZoneEntranceExitLock, momentGoalStartDate, momentGoalEndDate)
      .then(([data, lockNum]) => graphDataServiceInstance.derivePredictionGoalEntranceExitZoneData(data, lockNum, momentGoalEndDate, momentGoalStartDate, excludeDate));
  }

  derivePredictionGoalEntranceExitZoneData(entranceExitZoneDatas: IFetchData<BuildingZoneEntranceExitData>[], lockNum: number, date: moment.Moment, startDate: moment.Moment, excludeDate: string[]) {
    const currentGoalTrafficZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
    const currentGoalNetShoppingTimeZoneData: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } } } = {};
    const brandList = this.configDataService.BRAND_LIST || [];
    const currentGoalBrandTrafficData = brandList.reduce((obj, key) => {
      obj[key] = { val: 0, changed: 0 };
      return obj;
    }, {});
    const momentExcludeDate = excludeDate.map(d => moment(d, 'MM-DD-YYYY'));
    const tempGoalTrafficZoneData = this.currentGoalTrafficZoneData;
    const tempGoalAvgTrafficZoneData = this.currentGoalAvgTrafficZoneData;
    const tempGoalAvgTrafficBrandData = this.currentGoalAvgTrafficBrandData;
    if (!entranceExitZoneDatas) {
      return;
    }
    if (entranceExitZoneDatas.length === 0) {
      return;
    }
    entranceExitZoneDatas.forEach(dataIt => {
      Object.entries(dataIt.data).forEach(([buildingName, floorData]) => {
        if (!currentGoalTrafficZoneData[buildingName]) {
          currentGoalTrafficZoneData[buildingName] = {};
          currentGoalNetShoppingTimeZoneData[buildingName] = {};
        }
        Object.entries(floorData).forEach(([floorName, zoneData]) => {
          if (!currentGoalTrafficZoneData[buildingName][floorName]) {
            currentGoalTrafficZoneData[buildingName][floorName] = {};
            currentGoalNetShoppingTimeZoneData[buildingName][floorName] = {};
          }
          Object.keys(zoneData).forEach((zoneName) => {
            currentGoalTrafficZoneData[buildingName][floorName][zoneName] = { val: 0, changed: 0 };
            currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName] = { val: 0, changed: 0 };
          });
        });
      });
    });
    const latest_data = entranceExitZoneDatas[0];
    const latest_moment = moment(`${latest_data.year}-${latest_data.month}-${latest_data.day}`, 'YYYY-MM-DD');
    const diffDate = latest_moment.diff(startDate, 'day') < 1 ? 1 : latest_moment.diff(startDate, 'day');
    for (const momentIt = startDate; momentIt <= date; momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(entranceExitZoneDatas, momentIt, ViewPeriod.DAYS)[0];
      const diffPrevDate = startDate.diff(date, 'day');
      if (!dataIt || !dataIt.data) {
        continue;
      } else {
        const dataItDate = moment(`${dataIt.month}-${dataIt.day}-${dataIt.year}`, 'MM-DD-YYYY');
        if (momentExcludeDate.some(d => d.isSame(dataItDate))) {
          continue;
        }
        const entranceExitZoneData = dataIt.data;
        Object.entries(entranceExitZoneData).forEach(([buildingName, floorData]) => {
          if (!currentGoalTrafficZoneData[buildingName]) {
            currentGoalTrafficZoneData[buildingName] = {};
            currentGoalNetShoppingTimeZoneData[buildingName] = {};
          }
          Object.entries(floorData).forEach(([floorName, zoneData]) => {
            if (!currentGoalTrafficZoneData[buildingName][floorName]) {
              currentGoalTrafficZoneData[buildingName][floorName] = {};
              currentGoalNetShoppingTimeZoneData[buildingName][floorName] = {};
            }
            Object.keys(zoneData).forEach((zoneName) => {
              const findedBrand = brandList.find(brand => zoneName.endsWith(brand));
              if (diffPrevDate === -1) {
                if (findedBrand !== undefined) {
                  currentGoalBrandTrafficData[findedBrand].changed = entranceExitZoneData[buildingName][floorName][zoneName].entrance;
                }
                currentGoalTrafficZoneData[buildingName][floorName][zoneName].changed = GraphDataService.procesChartData(entranceExitZoneData[buildingName][floorName][zoneName].entrance, false, false);
                currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName].changed = entranceExitZoneData[buildingName][floorName][zoneName].net_shopping_time;
              }
              if (findedBrand !== undefined) {
                currentGoalBrandTrafficData[findedBrand].val += entranceExitZoneData[buildingName][floorName][zoneName].entrance;
              }
              currentGoalTrafficZoneData[buildingName][floorName][zoneName].val += GraphDataService.procesChartData(entranceExitZoneData[buildingName][floorName][zoneName].entrance, false, false);
              currentGoalNetShoppingTimeZoneData[buildingName][floorName][zoneName].val += entranceExitZoneData[buildingName][floorName][zoneName].net_shopping_time;
            });
          });
        });
      }
    }

    const exportCurrentGoalAvgTrafficZoneData: {
      [buildingName: string]: { [floorName: string]: { [zoneName: string]: { val: number; changed: number } } };
    } = Object.entries(currentGoalTrafficZoneData).reduce((prev, [buildingName, floorData]) => {
      Object.entries(floorData).forEach(([floorName, zoneData]) => {
        Object.entries(zoneData).forEach(([zoneName, zoneCurrentData]) => {
          prev[buildingName][floorName][zoneName] = {
            val: GraphDataService.procesChartData(zoneCurrentData.val / diffDate, false, false),
            changed: GraphDataService.procesChartData(zoneCurrentData.changed / diffDate, false, false),
          };
        });
      });
      return prev;
    }, Object.entries(currentGoalNetShoppingTimeZoneData).reduce((prev, [bldName, floorData]) => {
      prev[bldName] = Object.entries(floorData).reduce((acc, [floorName, zoneData]) => {
        acc[floorName] = Object.keys(zoneData).reduce((accZone, zoneName) => {
          accZone[zoneName] = {};
          return accZone;
        }, {});
        return acc;
      }, {});
      return prev;
    }, {}));

    Object.keys(currentGoalBrandTrafficData).forEach(brand => {
      currentGoalBrandTrafficData[brand].val = GraphDataService.procesChartData((currentGoalBrandTrafficData[brand].val / diffDate), false, false);
      currentGoalBrandTrafficData[brand].changed = GraphDataService.procesChartData((currentGoalBrandTrafficData[brand].changed / (diffDate - 1)), false, false);
    });

    if (lockNum < this.predGoalZoneEntranceExitLock) { return; }
    this.currentGoalAvgTrafficBrandData$.next([tempGoalAvgTrafficBrandData, currentGoalBrandTrafficData]);
    this.currentGoalTrafficZoneData$.next([tempGoalTrafficZoneData, currentGoalTrafficZoneData]);
    this.currentGoalAvgTrafficZoneData$.next([tempGoalAvgTrafficZoneData, exportCurrentGoalAvgTrafficZoneData]);
  }

  async fetchPredictionGoalEntranceExitZoneData(date: moment.Moment, lockNum: number, goalStartDate: moment.Moment, goalEndDate: moment.Moment) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const numInterval = goalEndDate.diff(goalStartDate, 'd') + 1; //(for any goal)
    const qParams = { start_date: goalEndDate.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/zone/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingZoneEntranceExitData>[], number];
  }

  //#endregion prediction goal zone/entrance-exit

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/car-brand
  async loadVehicleParkingCarBrandData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    const areaName = graphDataServiceInstance.configDataService.currentOrganization !== 'PAV' ? graphDataServiceInstance.configDataService.currentOrganization.toLocaleLowerCase() : 'pavilion_vp1';
    return graphDataServiceInstance.fetchVehicleParkingCarBrandData(date, ++graphDataServiceInstance.vehicleParkingCarBrandLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingCarBrandData(data, lockNum));
  }

  deriveVehicleParkingCarBrandData(vehicleParkingCarBrandData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const carBrandBreakdown: { [brandName: string]: number } = {};
    let carBrandTotal = 0;
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    GraphDataService.mapSevenDayLineChartData(vehicleParkingCarBrandData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        const carBrandKey = profileData.group?.car_brand || 'total';
        if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
          if (carBrandKey === 'total') {
            carBrandTotal = GraphDataService.procesChartData(profileData.entrance, false, false);
          }
          if (carBrandKey !== 'total' && !excludeCarBrand.includes(carBrandKey)) {
            carBrandBreakdown[carBrandKey] = GraphDataService.procesChartData(profileData.entrance, false, false);
          }
        }
      }
    });
    if (lockNum < this.vehicleParkingCarBrandLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / carBrandTotal) * 100, false, false) }), {});
    this.vehicleParkingCarBrandBreakdownTopTen$.next(sortableByVal(carBrandBreakdownPercentage, 10));
  }

  async fetchVehicleParkingCarBrandData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/car-brand?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }

  //#endregion vehicle-parking/car-brand

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/provinces
  async loadVehicleParkingProvinceData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    const areaName = graphDataServiceInstance.configDataService.currentOrganization.toLocaleLowerCase();
    return graphDataServiceInstance.fetchVehicleParkingProvinceData(date, ++graphDataServiceInstance.vehicleParkingProvinceLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingProvinceData(data, lockNum));
  }

  deriveVehicleParkingProvinceData(vehicleParkingProvinceData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const provinceTrend: { [provinceName: string]: number[] } = {};
    GraphDataService.mapSevenDayLineChartData(vehicleParkingProvinceData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        const provinceKey = profileData.group?.province || 'total';
        provinceTrend[provinceKey] = [];
      }
    });
    GraphDataService.mapSevenDayLineChartData(vehicleParkingProvinceData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(provinceTrend).map(k => provinceTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      const vehicleProvinceUsed: string[] = [];
      for (const profileData of vehicleProfileData) {
        const provinceKey = profileData.group?.province || 'total';
        provinceTrend[provinceKey].push(GraphDataService.procesChartData(profileData.entrance, false, false));
        vehicleProvinceUsed.push(provinceKey);
      }
      Object.keys(provinceTrend).filter(typeName => !vehicleProvinceUsed.includes(typeName)).forEach(noDataType => {
        provinceTrend[noDataType].push(0);
      });
    });
    if (lockNum < this.vehicleParkingProvinceLock) { return; }
    // const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [ , b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [ , b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleParkingProvinceTrend$.next(provinceTrend);
  }

  async fetchVehicleParkingProvinceData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/provinces?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }

  //#endregion vehicle-parking/provinces

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/provinces-ungroup
  async loadVehicleParkingProvinceUngroupData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    const areaName = graphDataServiceInstance.configDataService.currentOrganization.toLocaleLowerCase();
    return graphDataServiceInstance.fetchVehicleParkingProvinceUngroupData(date, ++graphDataServiceInstance.vehicleParkingProvinceUngroupLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingProvinceUngroupData(data, lockNum));
  }

  deriveVehicleParkingProvinceUngroupData(vehicleParkingProvinceUngroupData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const provinceBreakdown: { [provinceName: string]: number } = {};
    const top5VehicleProvinceData: { [provinceName: string]: number } = {};
    let sumTotal = 0;
    GraphDataService.mapSevenDayLineChartData(vehicleParkingProvinceUngroupData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        const provinceKey = profileData.group?.province || '_total';
        if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
          if (provinceKey === '_total') {
            provinceBreakdown[provinceKey] = GraphDataService.procesChartData(profileData.entrance, false, false);
            sumTotal = GraphDataService.procesChartData(profileData.entrance, false, false);
          }
          provinceBreakdown[provinceKey] = GraphDataService.procesChartData(profileData.entrance, false, false);
        }
      }
    });
    if (lockNum < this.vehicleParkingProvinceUngroupLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTotal: { [provinceName: string]: number } = Object.keys(provinceBreakdown)
      .filter(key => key !== '_total')
      .reduce((obj, key) => {
        obj[key] = provinceBreakdown[key];
        return obj;
      }, {});
    const sortableProvinceDataByVal: { [key: string]: number } = sortableByVal(filteredTotal);
    top5VehicleProvinceData.other = 0;
    Object.entries(sortableProvinceDataByVal).forEach(([provinceName, value], idx) => {
      if (provinceName !== '_total') {
        const percentageValue = (value / sumTotal) * 100;
        if (idx < 5) {
          top5VehicleProvinceData[provinceName] = GraphDataService.procesChartData(percentageValue, false, true);
        } else {
          top5VehicleProvinceData.other += GraphDataService.procesChartData(percentageValue, false, true);
        }
      }
    });
    top5VehicleProvinceData._total = provinceBreakdown._total;
    this.vehicleParkingProvinceUngroupBreakdown$.next(top5VehicleProvinceData);
  }

  async fetchVehicleParkingProvinceUngroupData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/provinces-ungroup?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }

  //#endregion vehicle-parking/provinces-ungroup

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/mode-of-transportation
  async loadVehicleParkingModeofTransportData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    const areaName = graphDataServiceInstance.configDataService.currentOrganization.toLocaleLowerCase();
    return graphDataServiceInstance.fetchVehicleParkingModeofTransportData(date, ++graphDataServiceInstance.vehicleParkingModeofTransportLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingModeofTransportData(data, lockNum));
  }

  deriveVehicleParkingModeofTransportData(vehicleParkingModeofTransportData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const modeofTransportTrend: { [vehicleType: string]: number[] } = {};
    const modeofTransportProxTrend: { [vehicleType: string]: number[] } = {};
    GraphDataService.mapSevenDayLineChartData(vehicleParkingModeofTransportData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
        modeofTransportTrend[vehicleTypeKey] = [];
        modeofTransportProxTrend[vehicleTypeKey] = [];
      }
    });
    GraphDataService.mapSevenDayLineChartData(vehicleParkingModeofTransportData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(modeofTransportTrend).map(k => modeofTransportTrend[k].push(fillValue));
        Object.keys(modeofTransportProxTrend).map(k => modeofTransportProxTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      const vehicleTypeUsed: string[] = [];
      for (const profileData of vehicleProfileData) {
        const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
        modeofTransportTrend[vehicleTypeKey].push(GraphDataService.procesChartData(profileData.entrance, false, false));
        modeofTransportProxTrend[vehicleTypeKey].push(GraphDataService.procesChartData(profileData.proximity, false, false));
        vehicleTypeUsed.push(vehicleTypeKey);
      }
      Object.keys(modeofTransportTrend).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
        modeofTransportTrend[noDataType].push(0);
      });
      Object.keys(modeofTransportProxTrend).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
        modeofTransportProxTrend[noDataType].push(0);
      });
    });
    if (lockNum < this.vehicleParkingModeofTransportLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleParkingModeofTransportTrend$.next(modeofTransportTrend);
    this.vehicleParkingModeofTransportProxTrend$.next(modeofTransportProxTrend);
  }

  async fetchVehicleParkingModeofTransportData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/mode-of-transportation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }
  //#endregion vehicle-parking/mode-of-transportation

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/mode-of-transportation-by-hour
  async loadVehicleParkingModeofTransportbyHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    const areaName = graphDataServiceInstance.configDataService.currentOrganization.toLocaleLowerCase();
    return graphDataServiceInstance.fetchVehicleParkingModeofTransportbyHourData(date, ++graphDataServiceInstance.vehicleParkingModeofTransportbyHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingModeofTransportbyHourData(data, lockNum));
  }

  deriveVehicleParkingModeofTransportbyHourData(vehicleParkingModeofTransportbyHourData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const modeofTransportTrend: { [vehicleType: string]: { [hour: string]: number } } = {};
    for (const dataFiltered of vehicleParkingModeofTransportbyHourData) {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
        modeofTransportTrend[vehicleTypeKey] = {};
      }
    }
    for (const dataFiltered of vehicleParkingModeofTransportbyHourData) {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        const fillValue = (this.viewPeriodService.isLiveMode && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(modeofTransportTrend).map(k => Object.keys(modeofTransportTrend[k]).map(h => modeofTransportTrend[k][h] = fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      this.configDataService.VEHICLE_TIME_LIST.map(time => {
        const vehicleTypeUsed: string[] = [];
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        if (dataFiltered.hour === timeKey) {
          for (const profileData of vehicleProfileData) {
            const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
            modeofTransportTrend[vehicleTypeKey][timeKey] = GraphDataService.procesChartData(profileData.entrance, false, false);
            vehicleTypeUsed.push(vehicleTypeKey);
          }
          // fill 0 for no data
          Object.keys(modeofTransportTrend).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
            modeofTransportTrend[noDataType][timeKey] = 0;
          });
        }
      });
    }
    if (lockNum < this.vehicleParkingModeofTransportbyHourLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleParkingModeofTransportbyHourTrend$.next(modeofTransportTrend);
  }

  async fetchVehicleParkingModeofTransportbyHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/mode-of-transportation-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }

  //#endregion vehicle-parking/mode-of-transportation-by-hour

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/entrance-exit
  async loadVehicleParkingEntranceExitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (graphDataServiceInstance.configDataService.currentOrganization === 'TALAAD') {
      return graphDataServiceInstance.fetchVehicleParkingEntranceExitData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitData(data, lockNum));
    }
    if (selectedInteractable?.type === 'vehicle_parking') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.fetchVehicleParkingEntranceExitData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitData(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name;
      return graphDataServiceInstance.fetchVehicleParkingEntranceExitData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitData(data, lockNum));
    }
    // return graphDataServiceInstance.fetchVehicleParkingEntranceExitData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitData(data, lockNum));
  }

  deriveVehicleParkingEntranceExitData(vehicleParkingEntranceExitDatas: IFetchData<AreaVehicleParkingData>[], lockNum: number) {
    const vehicleConversionRateTrend: number[] = [];
    const vehicleProximityDatePair: [number, number] = [0, 0];
    const vehicleConversionRatePair: [number, number] = [0, 0];
    const vehicleEntranceExitDatePair: [number, number] = [0, 0];
    const vehicleParkingEntranceExitData: { [areaName: string]: { entrance: number[]; exit: number[] } } = {};
    const vehicleParkingEntranceExitBreakdownData: { [areaName: string]: { entrance: number; exit: number } } = {};
    const vehicleParkingAvgTimespentData: { [areaName: string]: number } = {};
    const areaVehicleEntranceExitDatePair: { [areaName: string]: [number, number] } = {};
    const vehicleParkingAvgTimespentDatePair: { [areaName: string]: [number, number] } = {};
    const parkingAreaKey: string[] = [];
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        if (!floorData.parking || floorData.parking.length === 0) {
          continue;
        }
        const filteredParkingAreas = floorData.parking.filter((parkingArea: string) =>
          vehicleParkingEntranceExitDatas.some((data) => data.area === parkingArea)
        );
        for (const parkingArea of filteredParkingAreas) {
          parkingAreaKey.push(parkingArea);
          vehicleParkingAvgTimespentDatePair[parkingArea] = [0, 0];
          areaVehicleEntranceExitDatePair[parkingArea] = [0, 0];
          vehicleParkingEntranceExitData[parkingArea] = { entrance: [], exit: [] };
          vehicleParkingAvgTimespentData[parkingArea] = 0;
          vehicleParkingEntranceExitBreakdownData[parkingArea] = { entrance: 0, exit: 0 };
        }
      }
    }
    for (const areaName of parkingAreaKey) {
      const filteredAreaData = vehicleParkingEntranceExitDatas.filter(data => data.area === areaName);
      if (!filteredAreaData || filteredAreaData.length === 0) {
        return;
      }
      GraphDataService.mapSevenDayLineChartData(filteredAreaData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
          vehicleParkingEntranceExitData[areaName].entrance.push(fillValue);
          vehicleParkingEntranceExitData[areaName].exit.push(fillValue);
          vehicleConversionRateTrend.push(fillValue);
          return;
        }
        const vehicleProfileData = dataFiltered.data;
        const conversionRate = (vehicleProfileData.entrance / vehicleProfileData.proximity) * 100;
        vehicleParkingEntranceExitData[areaName].entrance.push(GraphDataService.procesChartData(vehicleProfileData.entrance, false, false));
        vehicleParkingEntranceExitData[areaName].exit.push(GraphDataService.procesChartData(vehicleProfileData.exit, false, false));
        if (diffToSelectedDate === 0) {
          vehicleParkingAvgTimespentData[areaName] = GraphDataService.procesChartData(vehicleProfileData.average_timespent / 60, false, false);
          vehicleParkingEntranceExitBreakdownData[areaName].entrance = GraphDataService.procesChartData(vehicleProfileData.entrance, false, false);
          vehicleParkingEntranceExitBreakdownData[areaName].exit = GraphDataService.procesChartData(vehicleProfileData.exit, false, false);
        }
        if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
          vehicleParkingAvgTimespentDatePair[areaName][diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.average_timespent, false, false);
          if (areaName === this.configDataService.MAIN_BUILDING) {
            vehicleProximityDatePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.proximity, false, false);
            vehicleEntranceExitDatePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.entrance, false, false);
            vehicleConversionRatePair[diffToSelectedDate + 1] = isFinite(conversionRate) ? GraphDataService.procesChartData(conversionRate, false, true) : 0;
          } else {
            areaVehicleEntranceExitDatePair[areaName][diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.entrance, false, false);
          }
        }
        if (areaName === this.configDataService.MAIN_BUILDING) {
          vehicleConversionRateTrend.push(isFinite(conversionRate) ? GraphDataService.procesChartData(conversionRate, false, true) : 0);
        }
      });
    }
    if (lockNum < this.vehicleParkingEntranceExitLock) { return; }
    const vehicleEntranceExitDiff = vehicleEntranceExitDatePair[1] - vehicleEntranceExitDatePair[0];
    const vehicleProximityDiff = vehicleProximityDatePair[1] - vehicleProximityDatePair[0];
    const vehicleConversionRateDiff = vehicleConversionRatePair[1] - vehicleConversionRatePair[0];

    const currentAreaVehicleEntranceExitData: {
      [areaName: string]: { count: number; diff: number; diffPercent: number };
    } = Object.entries(areaVehicleEntranceExitDatePair).reduce((prev, [areaName, areaPairData]) => {
      prev[areaName] = {
        count: GraphDataService.procesChartData(areaPairData[1]),
        diff: GraphDataService.procesChartData(areaPairData[1] - areaPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((areaPairData[1] - areaPairData[0]) / areaPairData[0] * 100, true, true)
      };
      return prev;
    }, {});
    const currentVehicleParkingAvgTimespentData: {
      [areaName: string]: { avgTimespent: number; diff: number; diffPercent: number };
    } = Object.entries(vehicleParkingAvgTimespentDatePair).reduce((prev, [areaName, areaPairData]) => {
      prev[areaName] = {
        count: GraphDataService.procesChartData(areaPairData[1]),
        diff: GraphDataService.procesChartData(areaPairData[1] - areaPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((areaPairData[1] - areaPairData[0]) / areaPairData[0] * 100, true, true)
      };
      return prev;
    }, {});
    this.vehicleParkingEntranceExitTrend$.next(vehicleParkingEntranceExitData);
    this.vehicleParkingAvgTimespent$.next(vehicleParkingAvgTimespentData);
    this.currentAreaVehicleParkingEntranceExit$.next(currentAreaVehicleEntranceExitData);
    this.currentVehicleParkingAvgTimespent$.next(currentVehicleParkingAvgTimespentData);
    this.vehicleParkingEntranceExitBreakdown$.next(vehicleParkingEntranceExitBreakdownData);
    this.currentVehicleParkingEntranceExit$.next({
      count: vehicleEntranceExitDatePair[1],
      diff: vehicleEntranceExitDiff,
      diffPercent: GraphDataService.procesChartData((vehicleEntranceExitDiff / 100), true, true)
    });
    this.currentVehicleParkingProximity$.next({
      proximity: vehicleProximityDatePair[1],
      diff: vehicleProximityDiff,
      diffPercent: GraphDataService.procesChartData((vehicleProximityDiff / 100), true, true)
    });
    this.currentVehicleParkingConversionRate$.next({
      conversion_rate: vehicleConversionRatePair[1],
      diff: vehicleConversionRateDiff,
      diffPercent: GraphDataService.procesChartData((vehicleConversionRateDiff), true, true)
    });
    this.vehicleParkingVehicleConversionRateTrend$.next(vehicleConversionRateTrend);
  }

  async fetchVehicleParkingEntranceExitData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    let fetchURL = '';
    const qParams = this.getLineChartQueryParameter(date);
    if (area) {
      fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    } else {
      fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData>[], number];
  }

  //#endregion vehicle-parking/entrance-exit

  //#region vehicle-parking/vehicle-purchasing-power
  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  async loadVehicleParkingPurchasingPowerData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    const areaName = graphDataServiceInstance.configDataService.currentOrganization.toLocaleLowerCase();
    return graphDataServiceInstance.fetchVehicleParkingPurchasingPowerData(date, ++graphDataServiceInstance.vehicleParkingPurchasingPowerLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingPurchasingPowerData(data, lockNum));
  }

  deriveVehicleParkingPurchasingPowerData(vehiclePurchasingPowerDatas: IFetchData<VehiclePurchasingPowerData>[], lockNum: number) {
    const tierListPercentage: { [tierName: string]: number } = {};
    const tierList: { [tierName: string]: number } = {};
    const premiumTimePairData: [number, number] = [0, 0];
    const luxuryTimePairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(vehiclePurchasingPowerDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      Object.entries(dataFiltered.data).forEach(([tierName, tierData]) => {
        if (tierName.includes('count')) {
          return;
        }
        if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
          if (tierName === 'premium') {
            premiumTimePairData[diffToSelectedDate + 1] = (tierData / dataFiltered.data.count) * 100;
          }
          if (tierName === 'luxury') {
            luxuryTimePairData[diffToSelectedDate + 1] = (tierData / dataFiltered.data.count) * 100;
          }
        }
        tierListPercentage[tierName] = (tierData / dataFiltered.data.count) * 100;
        tierList[tierName] = Math.round(tierData);
      });
    });
    if (lockNum < this.vehicleParkingPurchasingPowerLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleParkingPurchasingPowerBreakdown$.next(sortableByVal(tierList));
  }

  async fetchVehicleParkingPurchasingPowerData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-purchasing-power?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VehiclePurchasingPowerData>[], number];
  }

  //#endregion vehicle-parking/vehicle-purchasing-power

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/entrance-exit area=pk-p2
  async loadVehicleParkingEntranceExitPKP2Data(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    //TO-DO: build dynamic area
    const areaName = 'pk-p2';
    return graphDataServiceInstance.fetchVehicleParkingEntranceExitPKP2Data(date, ++graphDataServiceInstance.vehicleParkingPKP2EntranceExitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitPKP2Data(data, lockNum));
  }

  deriveVehicleParkingEntranceExitPKP2Data(vehicleParkingEntranceExitDatas: IFetchData<AreaVehicleParkingData>[], lockNum: number) {
    const vehicleConversionRateTrend: number[] = [];
    const vehicleProximityDatePair: [number, number] = [0, 0];
    const vehicleConversionRatePair: [number, number] = [0, 0];
    const vehicleEntranceExitDatePair: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(vehicleParkingEntranceExitDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        vehicleConversionRateTrend.push(fillValue);
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      const conversionRate = (vehicleProfileData.entrance / vehicleProfileData.proximity) * 100;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        vehicleProximityDatePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.proximity, false, false);
        vehicleEntranceExitDatePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.entrance, false, false);
        vehicleConversionRatePair[diffToSelectedDate + 1] = isFinite(conversionRate) ? GraphDataService.procesChartData(conversionRate, false, true) : 0;
      }
      vehicleConversionRateTrend.push(isFinite(conversionRate) ? GraphDataService.procesChartData(conversionRate, false, true) : 0);
    });
    if (lockNum < this.vehicleParkingPKP2EntranceExitLock) { return; }
    const vehicleEntranceExitDiff = vehicleEntranceExitDatePair[1] - vehicleEntranceExitDatePair[0];
    const vehicleProximityDiff = vehicleProximityDatePair[1] - vehicleProximityDatePair[0];
    const vehicleConversionRateDiff = vehicleConversionRatePair[1] - vehicleConversionRatePair[0];
    this.currentVehicleParkingPKP2EntranceExit$.next({
      count: vehicleEntranceExitDatePair[1],
      diff: vehicleEntranceExitDiff,
      diffPercent: GraphDataService.procesChartData((vehicleEntranceExitDiff / 100), true, true)
    });
    this.currentVehicleParkingPKP2Proximity$.next({
      proximity: vehicleProximityDatePair[1],
      diff: vehicleProximityDiff,
      diffPercent: GraphDataService.procesChartData((vehicleProximityDiff / 100), true, true)
    });
    this.currentVehicleParkingPKP2ConversionRate$.next({
      conversion_rate: vehicleConversionRatePair[1],
      diff: vehicleConversionRateDiff,
      diffPercent: GraphDataService.procesChartData((vehicleConversionRateDiff), true, true)
    });
    this.vehicleParkingPKP2VehicleConversionRateTrend$.next(vehicleConversionRateTrend);
  }

  async fetchVehicleParkingEntranceExitPKP2Data(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData>[], number];
  }

  //#endregion vehicle-parking/entrance-exit area=pk-p2

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/entrance-exit area=pk-p3
  async loadVehicleParkingEntranceExitPKP3Data(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    //TO-DO: build dynamic area
    const areaName = 'pk-p3';
    return graphDataServiceInstance.fetchVehicleParkingEntranceExitPKP3Data(date, ++graphDataServiceInstance.vehicleParkingPKP3EntranceExitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitPKP3Data(data, lockNum));
  }

  deriveVehicleParkingEntranceExitPKP3Data(vehicleParkingEntranceExitDatas: IFetchData<AreaVehicleParkingData>[], lockNum: number) {
    const vehicleConversionRateTrend: number[] = [];
    const vehicleProximityDatePair: [number, number] = [0, 0];
    const vehicleConversionRatePair: [number, number] = [0, 0];
    const vehicleEntranceExitDatePair: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(vehicleParkingEntranceExitDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        vehicleConversionRateTrend.push(fillValue);
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      const conversionRate = (vehicleProfileData.entrance / vehicleProfileData.proximity) * 100;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        vehicleProximityDatePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.proximity, false, false);
        vehicleEntranceExitDatePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(vehicleProfileData.entrance, false, false);
        vehicleConversionRatePair[diffToSelectedDate + 1] = isFinite(conversionRate) ? GraphDataService.procesChartData(conversionRate, false, true) : 0;
      }
      vehicleConversionRateTrend.push(isFinite(conversionRate) ? GraphDataService.procesChartData(conversionRate, false, true) : 0);
    });
    if (lockNum < this.vehicleParkingPKP3EntranceExitLock) { return; }
    const vehicleEntranceExitDiff = vehicleEntranceExitDatePair[1] - vehicleEntranceExitDatePair[0];
    this.currentVehicleParkingPKP3EntranceExit$.next({
      count: vehicleEntranceExitDatePair[1],
      diff: vehicleEntranceExitDiff,
      diffPercent: GraphDataService.procesChartData((vehicleEntranceExitDiff / 100), true, true)
    });
  }

  async fetchVehicleParkingEntranceExitPKP3Data(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData>[], number];
  }

  //#endregion vehicle-parking/entrance-exit area=pk-p3

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/mode-of-transportation area=pk-p2
  async loadVehicleParkingPKP2ModeofTransportData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    /*const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingCarBrandLock;
      return Promise.resolve();
    }*/
    const areaName = 'pk-p2';
    return graphDataServiceInstance.fetchVehicleParkingPKP2ModeofTransportData(date, ++graphDataServiceInstance.vehicleParkingPKP2ModeofTransportLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingPKP2ModeofTransportData(data, lockNum));
  }

  deriveVehicleParkingPKP2ModeofTransportData(vehicleParkingModeofTransportData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const modeofTransportTrend: { [vehicleType: string]: number[] } = {};
    const modeofTransportProxTrend: { [vehicleType: string]: number[] } = {};
    GraphDataService.mapSevenDayLineChartData(vehicleParkingModeofTransportData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
        modeofTransportTrend[vehicleTypeKey] = [];
        modeofTransportProxTrend[vehicleTypeKey] = [];
      }
    });
    GraphDataService.mapSevenDayLineChartData(vehicleParkingModeofTransportData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(modeofTransportTrend).map(k => modeofTransportTrend[k].push(fillValue));
        Object.keys(modeofTransportProxTrend).map(k => modeofTransportProxTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      const vehicleTypeUsed: string[] = [];
      for (const profileData of vehicleProfileData) {
        const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
        modeofTransportTrend[vehicleTypeKey].push(GraphDataService.procesChartData(profileData.entrance, false, false));
        modeofTransportProxTrend[vehicleTypeKey].push(GraphDataService.procesChartData(profileData.proximity, false, false));
        vehicleTypeUsed.push(vehicleTypeKey);
      }
      Object.keys(modeofTransportTrend).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
        modeofTransportTrend[noDataType].push(0);
      });
      Object.keys(modeofTransportProxTrend).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
        modeofTransportProxTrend[noDataType].push(0);
      });
    });
    if (lockNum < this.vehicleParkingPKP2ModeofTransportLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleParkingPKP2ModeofTransportTrend$.next(modeofTransportTrend);
    this.vehicleParkingPKP2ModeofTransportProxTrend$.next(modeofTransportProxTrend);
  }

  async fetchVehicleParkingPKP2ModeofTransportData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/mode-of-transportation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }
  //#endregion vehicle-parking/mode-of-transportation area=pk-p2

  //#region mock/store-count
  async loadMockStoreCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name || selectedInteractable?.name === 'S_0_19') {
      // clear data
      ++graphDataServiceInstance.mockStoreCountLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.replace(/_/g, '-').toLocaleLowerCase();
    return graphDataServiceInstance.fetchMockStoreCountData(date, ++graphDataServiceInstance.mockStoreCountLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveMockStoreCountData(data, lockNum));

  }

  deriveMockStoreCountData(mockStoreCountDatas: IFetchData<MockStoreCountData>[], lockNum: number) {
    const storeCountTrend: number[] = [];
    const storeCountTimePair: [number, number] = [0, 0];
    const prevStoreCountWeekDayLast7DayTrend: number[] = [];
    const prevStoreCountWeekEndLast7DayTrend: number[] = [];
    const currentStoreCountWeekDayLast7DayTrend: number[] = [];
    const currentStoreCountWeekEndLast7DayTrend: number[] = [];
    GraphDataService.mapSevenDayLineChartData(mockStoreCountDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        storeCountTrend.push(fillValue);
        return;
      }
      const mockStoreCountData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        storeCountTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreCountData.count, false, false);
      }
      storeCountTrend.push(GraphDataService.procesChartData(mockStoreCountData.count, false, false));
    });
    for (const mockStoreCountData of mockStoreCountDatas) {
      const dateNowInApp = this.viewPeriodService.selectedDate;
      const mockStoreCountMoment = moment(`${mockStoreCountData.year}-${mockStoreCountData.month}-${mockStoreCountData.day}`, 'YYYY-MM-DD');
      if (dateNowInApp.diff(mockStoreCountMoment, 'day') < 7) {
        if (mockStoreCountMoment.isoWeekday() > 5) {
          currentStoreCountWeekEndLast7DayTrend.push(mockStoreCountData.data.count);
        } else {
          currentStoreCountWeekDayLast7DayTrend.push(mockStoreCountData.data.count);
        }
      } else {
        if (mockStoreCountMoment.isoWeekday() > 5) {
          prevStoreCountWeekEndLast7DayTrend.push(mockStoreCountData.data.count);
        } else {
          prevStoreCountWeekDayLast7DayTrend.push(mockStoreCountData.data.count);
        }
      }
    }
    const sumPrevStoreCountWeekEndLast7DayTrend = prevStoreCountWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumPrevStoreCountWeekDayLast7DayTrend = prevStoreCountWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekEndLast7DayTrend = currentStoreCountWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekDayLast7DayTrend = currentStoreCountWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const avgPrevStoreCountWeekEndLast7Day = sumPrevStoreCountWeekEndLast7DayTrend / 2;
    const avgPrevStoreCountWeekDayLast7Day = sumPrevStoreCountWeekDayLast7DayTrend / 5;
    const avgCurrentStoreCountWeekEndLast7Day = sumCurrentStoreCountWeekEndLast7DayTrend / 2;
    const avgCurrentStoreCountWeekDayLast7Day = sumCurrentStoreCountWeekDayLast7DayTrend / 5;
    const diffAvgStoreCountWeekDayLast7Day = avgCurrentStoreCountWeekDayLast7Day - avgPrevStoreCountWeekDayLast7Day;
    const diffAvgStoreCountWeekEndLast7Day = avgCurrentStoreCountWeekEndLast7Day - avgPrevStoreCountWeekEndLast7Day;
    if (lockNum < this.mockStoreCountLock) { return; }
    if (this.viewPeriodService.isDayPeriod || this.viewPeriodService.isLiveMode) {
      this.currentMockStoreCountAverageWeekday$.next({
        count: GraphDataService.procesChartData(avgCurrentStoreCountWeekDayLast7Day, false, false),
        diff: GraphDataService.procesChartData(diffAvgStoreCountWeekDayLast7Day, true, false),
        diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekDayLast7Day / avgPrevStoreCountWeekDayLast7Day) * 100, true, false)
      });
      this.currentMockStoreCountAverageWeekend$.next({
        count: GraphDataService.procesChartData(avgCurrentStoreCountWeekEndLast7Day, false, false),
        diff: GraphDataService.procesChartData(diffAvgStoreCountWeekEndLast7Day, true, false),
        diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekEndLast7Day / avgPrevStoreCountWeekEndLast7Day) * 100, true, false)
      });
    }
    this.mockStoreCountTrend$.next(storeCountTrend);
    const diffTimePair = storeCountTimePair[1] - storeCountTimePair[0];
    this.currentMockStoreCount$.next({
      count: storeCountTimePair[1],
      diff: GraphDataService.procesChartData(diffTimePair, true, false),
      diffPercent: GraphDataService.procesChartData((diffTimePair / storeCountTimePair[0]) * 100, true, true)
    });
  }

  async fetchMockStoreCountData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/mock/store-count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<MockStoreCountData>[], number];
  }
  //#endregion mock/store-count

  //#region mock/store-visitor
  async loadMockStoreVisitorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name || selectedInteractable?.name === 'S_0_19') {
      // clear data
      ++graphDataServiceInstance.mockStoreVisitorLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.replace(/_/g, '-').toLocaleLowerCase();
    return graphDataServiceInstance.fetchMockStoreVisitorData(date, ++graphDataServiceInstance.mockStoreVisitorLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveMockStoreVisitorData(data, lockNum));

  }

  deriveMockStoreVisitorData(mockStoreVisitorDatas: IFetchData<MockStoreCountData>[], lockNum: number) {
    const storeVisitorTrend: number[] = [];
    const storeVisitorTimePair: [number, number] = [0, 0];
    const storeVisitorAvgTimeSpentTimePair: [number, number] = [0, 0];
    const conversionRateTimePair: [number, number] = [0, 0];
    const prevStoreVisitorWeekDayLast7DayTrend: number[] = [];
    const prevStoreVisitorWeekEndLast7DayTrend: number[] = [];
    const currentStoreVisitorWeekDayLast7DayTrend: number[] = [];
    const currentStoreVisitorWeekEndLast7DayTrend: number[] = [];
    GraphDataService.mapSevenDayLineChartData(mockStoreVisitorDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        storeVisitorTrend.push(fillValue);
        return;
      }
      const mockStoreVisitorData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        storeVisitorTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreVisitorData.count, false, false);
        conversionRateTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreVisitorData.conversion_rate, true, true);
        // milisec to second (Time)
        storeVisitorAvgTimeSpentTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreVisitorData.average_timespent / 36000, false, false);

      }
      storeVisitorTrend.push(GraphDataService.procesChartData(mockStoreVisitorData.count, false, false));
    });
    for (const mockStoreVisitorData of mockStoreVisitorDatas) {
      const dateNowInApp = this.viewPeriodService.selectedDate;
      const mockStoreVisitorMoment = moment(`${mockStoreVisitorData.year}-${mockStoreVisitorData.month}-${mockStoreVisitorData.day}`, 'YYYY-MM-DD');
      if (dateNowInApp.diff(mockStoreVisitorMoment, 'day') < 7) {
        if (mockStoreVisitorMoment.isoWeekday() > 5) {
          currentStoreVisitorWeekEndLast7DayTrend.push(mockStoreVisitorData.data.count);
        } else {
          currentStoreVisitorWeekDayLast7DayTrend.push(mockStoreVisitorData.data.count);
        }
      } else {
        if (mockStoreVisitorMoment.isoWeekday() > 5) {
          prevStoreVisitorWeekEndLast7DayTrend.push(mockStoreVisitorData.data.count);
        } else {
          prevStoreVisitorWeekDayLast7DayTrend.push(mockStoreVisitorData.data.count);
        }
      }
    }
    const sumPrevStoreVisitorWeekEndLast7DayTrend = prevStoreVisitorWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumPrevStoreVisitorWeekDayLast7DayTrend = prevStoreVisitorWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreVisitorWeekEndLast7DayTrend = currentStoreVisitorWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreVisitorWeekDayLast7DayTrend = currentStoreVisitorWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const avgPrevStoreVisitorWeekEndLast7Day = sumPrevStoreVisitorWeekEndLast7DayTrend / 2;
    const avgPrevStoreVisitorWeekDayLast7Day = sumPrevStoreVisitorWeekDayLast7DayTrend / 5;
    const avgCurrentStoreVisitorWeekEndLast7Day = sumCurrentStoreVisitorWeekEndLast7DayTrend / 2;
    const avgCurrentStoreVisitorWeekDayLast7Day = sumCurrentStoreVisitorWeekDayLast7DayTrend / 5;
    const diffAvgStoreVisitorWeekDayLast7Day = avgCurrentStoreVisitorWeekDayLast7Day - avgPrevStoreVisitorWeekDayLast7Day;
    const diffAvgStoreVisitorWeekEndLast7Day = avgCurrentStoreVisitorWeekEndLast7Day - avgPrevStoreVisitorWeekEndLast7Day;
    if (lockNum < this.mockStoreVisitorLock) { return; }
    if (this.viewPeriodService.isDayPeriod || this.viewPeriodService.isLiveMode) {
      this.currentMockStoreVisitorAverageWeekday$.next({
        count: GraphDataService.procesChartData(avgCurrentStoreVisitorWeekDayLast7Day, false, false),
        diff: GraphDataService.procesChartData(diffAvgStoreVisitorWeekDayLast7Day, true, false),
        diffPercent: GraphDataService.procesChartData((diffAvgStoreVisitorWeekDayLast7Day / avgPrevStoreVisitorWeekDayLast7Day) * 100, true, false)
      });
      this.currentMockStoreVisitorAverageWeekend$.next({
        count: GraphDataService.procesChartData(avgCurrentStoreVisitorWeekEndLast7Day, false, false),
        diff: GraphDataService.procesChartData(diffAvgStoreVisitorWeekEndLast7Day, true, false),
        diffPercent: GraphDataService.procesChartData((diffAvgStoreVisitorWeekEndLast7Day / avgPrevStoreVisitorWeekEndLast7Day) * 100, true, false)
      });
    }
    this.mockStoreVisitorTrend$.next(storeVisitorTrend);
    const diffTimePair = storeVisitorTimePair[1] - storeVisitorTimePair[0];
    const diffAvgTimeSpentTimePair = storeVisitorAvgTimeSpentTimePair[1] - storeVisitorAvgTimeSpentTimePair[0];
    const diffConversionRateTimePair = conversionRateTimePair[1] - conversionRateTimePair[0];
    this.currentMockConversionRate$.next({
      rate: GraphDataService.procesChartData(conversionRateTimePair[1], true, true),
      diff: GraphDataService.procesChartData(diffConversionRateTimePair, true, true),
      diffPercent: GraphDataService.procesChartData((diffConversionRateTimePair / conversionRateTimePair[0]) * 100, true, true)
    });
    this.currentMockStoreVisitor$.next({
      count: storeVisitorTimePair[1],
      diff: GraphDataService.procesChartData(diffTimePair, true, false),
      diffPercent: GraphDataService.procesChartData((diffTimePair / storeVisitorTimePair[0]) * 100, true, true)
    });
    this.currentMockStoreVisitorAvgTimeSpent$.next({
      timespent: storeVisitorAvgTimeSpentTimePair[1],
      diff: GraphDataService.procesChartData(diffAvgTimeSpentTimePair, true, true),
      diffPercent: GraphDataService.procesChartData((diffAvgTimeSpentTimePair / storeVisitorTimePair[0]) * 100, true, true)
    });
  }

  async fetchMockStoreVisitorData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/mock/store-visitor?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<MockStoreCountData>[], number];
  }
  //#endregion mock/store-visitor

  //#region mock/store-visitor-by-hour
  async loadMockStoreVisitorByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name || selectedInteractable?.name === 'S_0_19') {
      // clear data
      ++graphDataServiceInstance.mockStoreVisitorByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.replace(/_/g, '-').toLocaleLowerCase();
    return graphDataServiceInstance.fetchMockStoreVisitorByHourData(date, ++graphDataServiceInstance.mockStoreVisitorByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveMockStoreVisitorByHourData(data, lockNum));

  }

  deriveMockStoreVisitorByHourData(mockStoreVisitorByHourDatas: IFetchData<MockStoreCountData>[], lockNum: number) {
    const storeVisitorByHourTrend: number[] = [];
    const storeVisitorByHour: { [timeKey: string]: number } = {};
    if (!mockStoreVisitorByHourDatas) {
      return;
    }
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      if (mockStoreVisitorByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey)) {
        const trafficSiteCountByHourData = mockStoreVisitorByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        const pushData = GraphDataService.procesChartData(trafficSiteCountByHourData.data.count, false, false);
        storeVisitorByHourTrend.push(pushData);
        storeVisitorByHour[time] = pushData;
      } else {
        storeVisitorByHourTrend.push(fillValue);
        storeVisitorByHour[time] = fillValue;
      }
    });
    if (lockNum < this.mockStoreVisitorByHourLock) { return; }
    const peakTime = Object.keys(storeVisitorByHour).reduce((a, b) => storeVisitorByHour[a] > storeVisitorByHour[b] ? a : b);
    this.mockStoreVisitorByhourTrend$.next(storeVisitorByHourTrend);
    this.mockStoreVisitorPeakTime$.next({
      timeKey: peakTime,
      count: storeVisitorByHour[peakTime]
    });
  }

  async fetchMockStoreVisitorByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/mock/store-visitor-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<MockStoreCountData>[], number];
  }
  //#endregion mock/store-visitor

  //#region mock/store-count-average-by-day-type
  async loadMockStoreCountAverageByDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name || selectedInteractable?.name === 'S_0_19') {
      // clear data
      ++graphDataServiceInstance.mockStoreCountAverageByDayTypeLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.replace(/_/g, '-').toLocaleLowerCase();
    return graphDataServiceInstance.fetchMockStoreCountAverageByDayTypeData(date, ++graphDataServiceInstance.mockStoreCountAverageByDayTypeLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveMockStoreCountAverageByDayTypeData(data, lockNum));

  }

  deriveMockStoreCountAverageByDayTypeData(mockStoreCountAverageByDayTypeDatas: IFetchData<areaAverageDayTypeData>[], lockNum: number) {
    const storeCountWeekdayTimePair: [number, number] = [0, 0];
    const storeCountWeekendTimePair: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(mockStoreCountAverageByDayTypeDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const mockStoreCountData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        storeCountWeekdayTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreCountData?.weekday?.count || 0, false, false);
        storeCountWeekendTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreCountData?.weekend?.count || 0, false, false);
      }
    });
    const diffWeekdayTimePair = storeCountWeekdayTimePair[1] - storeCountWeekdayTimePair[0];
    const diffWeekendTimePair = storeCountWeekendTimePair[1] - storeCountWeekendTimePair[0];

    if (this.viewPeriodService.isWeekPeriod || this.viewPeriodService.isMonthPeriod) {
      this.currentMockStoreCountAverageWeekday$.next({
        count: storeCountWeekdayTimePair[1],
        diff: GraphDataService.procesChartData(diffWeekdayTimePair, true, false),
        diffPercent: GraphDataService.procesChartData((diffWeekdayTimePair / storeCountWeekdayTimePair[0]) * 100, true, false)
      });
      this.currentMockStoreCountAverageWeekend$.next({
        count: storeCountWeekendTimePair[1],
        diff: GraphDataService.procesChartData(diffWeekendTimePair, true, false),
        diffPercent: GraphDataService.procesChartData((diffWeekendTimePair / storeCountWeekendTimePair[0]) * 100, true, false)
      });
    }
    if (lockNum < this.mockStoreCountAverageByDayTypeLock) { return; }

  }

  async fetchMockStoreCountAverageByDayTypeData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/mock/store-count-average-by-day-type?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<areaAverageDayTypeData>[], number];
  }
  //#endregion mock/store-count-average-by-day-type

  //#region mock/store-visitor-average-by-day-type
  async loadMockStoreVisitorAverageByDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name || selectedInteractable?.name === 'S_0_19') {
      // clear data
      ++graphDataServiceInstance.mockStoreVisitorAverageByDayTypeLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.replace(/_/g, '-').toLocaleLowerCase();
    return graphDataServiceInstance.fetchMockStoreVisitorAverageByDayTypeData(date, ++graphDataServiceInstance.mockStoreCountAverageByDayTypeLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveMockStoreVisitorAverageByDayTypeData(data, lockNum));
  }

  deriveMockStoreVisitorAverageByDayTypeData(mockStoreVisitorAverageByDayTypeDatas: IFetchData<areaAverageDayTypeData>[], lockNum: number) {
    const storeVisitorWeekdayTimePair: [number, number] = [0, 0];
    const storeVisitorWeekendTimePair: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(mockStoreVisitorAverageByDayTypeDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const mockStoreVisitorData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        storeVisitorWeekdayTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreVisitorData?.weekday?.count || 0, false, false);
        storeVisitorWeekendTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(mockStoreVisitorData?.weekend?.count || 0, false, false);
      }
    });
    if (lockNum < this.mockStoreVisitorAverageByDayTypeLock) { return; }
    const diffWeekdayTimePair = storeVisitorWeekdayTimePair[1] - storeVisitorWeekdayTimePair[0];
    const diffWeekendTimePair = storeVisitorWeekendTimePair[1] - storeVisitorWeekendTimePair[0];
    if (this.viewPeriodService.isWeekPeriod || this.viewPeriodService.isMonthPeriod) {
      this.currentMockStoreVisitorAverageWeekday$.next({
        count: storeVisitorWeekdayTimePair[1],
        diff: GraphDataService.procesChartData(diffWeekdayTimePair, true, false),
        diffPercent: GraphDataService.procesChartData((diffWeekdayTimePair / storeVisitorWeekdayTimePair[0]) * 100, true, false)
      });
      this.currentMockStoreVisitorAverageWeekend$.next({
        count: storeVisitorWeekendTimePair[1],
        diff: GraphDataService.procesChartData(diffWeekendTimePair, true, false),
        diffPercent: GraphDataService.procesChartData((diffWeekendTimePair / storeVisitorWeekendTimePair[0]) * 100, true, false)
      });
    }
  }

  async fetchMockStoreVisitorAverageByDayTypeData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/mock/store-visitor-average-by-day-type?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<areaAverageDayTypeData>[], number];
  }
  //#endregion mock/store-count-average-by-day-type

  //#region mock/ads-visitor-profile
  async loadMockAdsVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name || selectedInteractable?.name !== 'S_0_19') {
      // clear data
      ++graphDataServiceInstance.mockAdsVisitorProfileLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.replace(/_/g, '-').toLocaleLowerCase();
    return graphDataServiceInstance.fetchMockAdsVisitorProfileData(date, ++graphDataServiceInstance.mockAdsVisitorProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveMockAdsVisitorProfileData(data, lockNum));
  }

  deriveMockAdsVisitorProfileData(adsVisitorProfileDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const mockAdsProfileTrend: number[] = [];
    const mockAdsProfileTimePair: [number, number] = [0, 0];
    const mockAdsProfileAvgTimeExposeTimePair: [number, number] = [0, 0];
    const mockGenderProfileTrend: { [gender: string]: number[] } = {};
    const mockAgeProfileTrend: { [age: string]: number[] } = {};
    const mockEthnicityProfileTrend: { [eth: string]: number[] } = {};
    const mockProfessionProfileTrend: { [profession: string]: number[] } = {};
    const mockGenderProfileBreakdown: { [gender: string]: number } = {};
    const mockAgeProfileBreakdown: { [age: string]: number } = {};
    const mockEthnicityProfileBreakdown: { [eth: string]: number } = {};
    const mockProfessionProfileBreakdown: { [profession: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(adsVisitorProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      this.configDataService.GENDER_CLASS.forEach(gen => {
        mockGenderProfileTrend[gen] = [];
      });
      this.configDataService.AGE_CLASS.forEach(age => {
        mockAgeProfileTrend[age] = [];
      });
      this.configDataService.PROFESSION_CLASS.forEach(profess => {
        mockProfessionProfileTrend[profess] = [];
      });
      this.configDataService.ETHNICITY_CLASS.forEach(eth => {
        mockEthnicityProfileTrend[eth] = [];
      });
    });
    GraphDataService.mapSevenDayLineChartData(adsVisitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        mockAdsProfileTrend.push(fillValue);
        Object.keys(mockGenderProfileTrend).map(k => mockGenderProfileTrend[k].push(fillValue));
        Object.keys(mockAgeProfileTrend).map(k => mockAgeProfileTrend[k].push(fillValue));
        Object.keys(mockEthnicityProfileTrend).map(k => mockEthnicityProfileTrend[k].push(fillValue));
        Object.keys(mockProfessionProfileTrend).map(k => mockProfessionProfileTrend[k].push(fillValue));
        return;
      }
      const visitorProfileData = dataFiltered.data;
      for (const profileData of visitorProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          mockAdsProfileTrend.push(GraphDataService.procesChartData(profileData.count, true, true));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            mockAdsProfileAvgTimeExposeTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(profileData.average_time_expose / (60 * 60), true, true);
            mockAdsProfileTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(profileData.count, true, true);
          }
        }
        this.configDataService.GENDER_CLASS.forEach(gen => {
          if (compare1DepthObjects(profileData.group, { gender: gen })) {
            mockGenderProfileTrend[gen].push(GraphDataService.procesChartData(profileData.count, true, true));
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              mockGenderProfileBreakdown[gen] = GraphDataService.procesChartData(profileData.count, true, true);
            }
          }
        });
        // Ethnicity Profile data
        this.configDataService.ETHNICITY_CLASS.forEach(eth => {
          if (compare1DepthObjects(profileData.group, { ethnicity: eth })) {
            mockEthnicityProfileTrend[eth].push(GraphDataService.procesChartData(profileData.count, true, true));
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              mockEthnicityProfileBreakdown[eth] = GraphDataService.procesChartData(profileData.count, true, true);
            }
          }
        });
        // Age Profile data
        this.configDataService.AGE_CLASS.forEach(a => {
          if (compare1DepthObjects(profileData.group, { age: a })) {
            mockAgeProfileTrend[a].push(GraphDataService.procesChartData(profileData.count, true, true));
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              mockAgeProfileBreakdown[a] = GraphDataService.procesChartData(profileData.count, true, true);
            }
          }
        });
        this.configDataService.PROFESSION_CLASS.forEach(profess => {
          if (compare1DepthObjects(profileData.group, { profession: profess })) {
            mockProfessionProfileTrend[profess].push(GraphDataService.procesChartData(profileData.count, true, true));
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              mockProfessionProfileBreakdown[profess] = GraphDataService.procesChartData(profileData.count, true, true);
            }
          }
        });
      }
    });
    if (lockNum < this.mockAdsVisitorProfileLock) { return; }
    const diffTimePair = mockAdsProfileTimePair[1] - mockAdsProfileTimePair[0];
    const diffAvgTimeExposeTimePair = mockAdsProfileAvgTimeExposeTimePair[1] - mockAdsProfileAvgTimeExposeTimePair[0];
    const sumOfAgeProfileBreakdown = Object.values(mockAgeProfileBreakdown).reduce((a, b) => a + b, 0);
    const sumOfGenderProfileBreakdown = Object.values(mockGenderProfileBreakdown).reduce((a, b) => a + b, 0);
    const sumOfEthnicityProfileBreakdown = Object.values(mockEthnicityProfileBreakdown).reduce((a, b) => a + b, 0);
    const sumOfProfessionProfileBreakdown = Object.values(mockProfessionProfileBreakdown).reduce((a, b) => a + b, 0);
    const mockGenderProfileBreakdownPercent = Object.keys(mockGenderProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((mockGenderProfileBreakdown[key] / sumOfGenderProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    const mockAgeProfileBreakdownPercent = Object.keys(mockAgeProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((mockAgeProfileBreakdown[key] / sumOfAgeProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    const mockEthnicityProfileBreakdownPercent = Object.keys(mockEthnicityProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((mockEthnicityProfileBreakdown[key] / sumOfEthnicityProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    const mockProfessionProfileBreakdownPercent = Object.keys(mockProfessionProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((mockProfessionProfileBreakdown[key] / sumOfProfessionProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    this.mockAdsVisitorProfileTrend$.next(mockAdsProfileTrend);
    this.mockAdsVisitorProfileByAgeTrend$.next(mockAgeProfileTrend);
    this.mockAdsVisitorProfileByGenderTrend$.next(mockGenderProfileTrend);
    this.mockAdsVisitorProfileByProfessionTrend$.next(mockProfessionProfileTrend);
    this.mockAdsVisitorProfileByLocalTrend$.next(mockEthnicityProfileTrend);
    this.mockAdsVisitorProfileByAgeBreakdown$.next(mockAgeProfileBreakdownPercent);
    this.mockAdsVisitorProfileByGenderBreakdown$.next(mockGenderProfileBreakdownPercent);
    this.mockAdsVisitorProfileByProfessionBreakdown$.next(mockProfessionProfileBreakdownPercent);
    this.mockAdsVisitorProfileByLocalBreakdown$.next(mockEthnicityProfileBreakdownPercent);
    this.currentMockAdsVisitorProfile$.next({
      count: mockAdsProfileTimePair[1],
      diff: GraphDataService.procesChartData(diffTimePair, true, true),
      diffPercent: GraphDataService.procesChartData((diffTimePair / mockAdsProfileTimePair[0]) * 100, true, true)
    });
    this.currentMockAdsAvgTimeExpose$.next({
      avg: mockAdsProfileAvgTimeExposeTimePair[1],
      diff: GraphDataService.procesChartData(diffAvgTimeExposeTimePair, true, true),
      diffPercent: GraphDataService.procesChartData((diffAvgTimeExposeTimePair / diffAvgTimeExposeTimePair[0]) * 100, true, true)
    });
  }

  async fetchMockAdsVisitorProfileData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/mock/ads-visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion mock/ads-visitor-profile

  //#region mock/ads-visitor-profile-by-hour
  async loadMockAdsVisitorProfileByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name || selectedInteractable?.name !== 'S_0_19') {
      // clear data
      ++graphDataServiceInstance.mockAdsVisitorProfileByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.replace(/_/g, '-').toLocaleLowerCase();
    return graphDataServiceInstance.fetchMockAdsVisitorProfileByHourData(date, ++graphDataServiceInstance.mockAdsVisitorProfileByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveMockAdsVisitorProfileByHourData(data, lockNum));
  }

  deriveMockAdsVisitorProfileByHourData(adsVisitorProfileByHourDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const mockAdsProfileTrend: number[] = [];
    if (!adsVisitorProfileByHourDatas) {
      mockAdsProfileTrend.push(null);
      return;
    }
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      if (adsVisitorProfileByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey)) {
        const adsVisitorProfileByHourData = adsVisitorProfileByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of adsVisitorProfileByHourData.data) {
          if (compare1DepthObjects(profileData.group, {})) {
            mockAdsProfileTrend.push(GraphDataService.procesChartData(profileData.count, true, true));
          }
        }
      } else {
        mockAdsProfileTrend.push(fillValue);
      }
    });
    if (lockNum < this.mockAdsVisitorProfileByHourLock) { return; }
    this.mockAdsVisitorProfileByHourTrend$.next(mockAdsProfileTrend);
  }

  async fetchMockAdsVisitorProfileByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/mock/ads-visitor-profile-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion mock/ads-visitor-profile-by-hour

  //#region visualization/traffic-site-videos
  async loadTrafficSiteVisualizationVideoData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSiteVisualizationVideoLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const liveDate = moment();
    return graphDataServiceInstance.fetchTrafficSiteVisualizationVideoData(liveDate, ++graphDataServiceInstance.trafficSiteVisualizationVideoLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteVisualizationVideoData(data, lockNum));
  }

  deriveTrafficSiteVisualizationVideoData(trafficSiteVisualizationVideoDatas: any, lockNum: number) {
    if (!trafficSiteVisualizationVideoDatas) { return; }
    this.trafficSiteVisualVideo$.next(trafficSiteVisualizationVideoDatas);
  }

  async fetchTrafficSiteVisualizationVideoData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/visualization/traffic-site-videos?start_date=${qParams.start_date}&area=${area}&hour=${date.hour()}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [any, number];
  }
  //#endregion visualization/traffic-site-videos

  //#region lidar/lidar-area-engagement-count
  async loadLidarAreaEngagementCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaEngagementCountLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaEngagementCountData(date, ++graphDataServiceInstance.lidarAreaEngagementCountLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaEngagementCountData(data, lockNum));
  }

  deriveLidarAreaEngagementCountData(lidarAreaEngagementCountDatas: IFetchData<LidarData>[], lockNum: number) {
    const lidarAreaEngagementCountTrend: number[] = [];
    const lidarAreaEngagementCountTimePair: [number, number] = [0, 0];
    const currentLidarAreaEngagementCountWeekDayLast7DayTrend: number[] = [];
    const currentLidarAreaEngagementCountWeekEndLast7DayTrend: number[] = [];
    const prevLidarAreaEngagementCountWeekDayLast7DayTrend: number[] = [];
    const prevLidarAreaEngagementCountWeekEndLast7DayTrend: number[] = [];
    GraphDataService.mapSevenDayLineChartData(lidarAreaEngagementCountDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        lidarAreaEngagementCountTrend.push(fillValue);
        return;
      }
      const lidarAreaEngagementCountData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        lidarAreaEngagementCountTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(lidarAreaEngagementCountData._total, false, false);
      }
      lidarAreaEngagementCountTrend.push(lidarAreaEngagementCountData._total);
    });
    for (const lidarAreaEngagementCountData of lidarAreaEngagementCountDatas) {
      const dateNowInApp = this.viewPeriodService.selectedDate;
      const lidarAreaEngagementCountMoment = moment(`${lidarAreaEngagementCountData.year}-${lidarAreaEngagementCountData.month}-${lidarAreaEngagementCountData.day}`, 'YYYY-MM-DD');
      if (dateNowInApp.diff(lidarAreaEngagementCountMoment, 'day') < 7) {
        if (lidarAreaEngagementCountMoment.isoWeekday() > 5) {
          currentLidarAreaEngagementCountWeekEndLast7DayTrend.push(lidarAreaEngagementCountData.data._total);
        } else {
          currentLidarAreaEngagementCountWeekDayLast7DayTrend.push(lidarAreaEngagementCountData.data._total);
        }
      } else {
        if (lidarAreaEngagementCountMoment.isoWeekday() > 5) {
          prevLidarAreaEngagementCountWeekEndLast7DayTrend.push(lidarAreaEngagementCountData.data._total);
        } else {
          prevLidarAreaEngagementCountWeekDayLast7DayTrend.push(lidarAreaEngagementCountData.data._total);
        }
      }
    }
    const sumPrevStoreCountWeekEndLast7DayTrend = prevLidarAreaEngagementCountWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumPrevStoreCountWeekDayLast7DayTrend = prevLidarAreaEngagementCountWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekEndLast7DayTrend = currentLidarAreaEngagementCountWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekDayLast7DayTrend = currentLidarAreaEngagementCountWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const avgPrevStoreCountWeekEndLast7Day = sumPrevStoreCountWeekEndLast7DayTrend / 2;
    const avgPrevStoreCountWeekDayLast7Day = sumPrevStoreCountWeekDayLast7DayTrend / 5;
    const avgCurrentStoreCountWeekEndLast7Day = sumCurrentStoreCountWeekEndLast7DayTrend / 2;
    const avgCurrentStoreCountWeekDayLast7Day = sumCurrentStoreCountWeekDayLast7DayTrend / 5;
    const diffAvgStoreCountWeekDayLast7Day = avgCurrentStoreCountWeekDayLast7Day - avgPrevStoreCountWeekDayLast7Day;
    const diffAvgStoreCountWeekEndLast7Day = avgCurrentStoreCountWeekEndLast7Day - avgPrevStoreCountWeekEndLast7Day;
    if (lockNum < this.lidarAreaEngagementCountLock) { return; }
    this.lidarAreaEngagementCountTrend$.next(lidarAreaEngagementCountTrend);
    const diffLidarAreaImpression = GraphDataService.procesChartData(lidarAreaEngagementCountTimePair[1] - lidarAreaEngagementCountTimePair[0], true, false);
    this.currentLidarAreaEngagementCount$.next({
      count: GraphDataService.procesChartData(lidarAreaEngagementCountTimePair[1], false, false),
      diff: diffLidarAreaImpression,
      diffPercent: GraphDataService.procesChartData((diffLidarAreaImpression / lidarAreaEngagementCountTimePair[0]) * 100, true, true)
    });
    this.currentLidarAreaEngagementCountAvgWeekDayLast7Day$.next({
      count: GraphDataService.procesChartData(avgCurrentStoreCountWeekDayLast7Day, false, false),
      diff: GraphDataService.procesChartData(diffAvgStoreCountWeekDayLast7Day, true, false),
      diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekDayLast7Day / avgPrevStoreCountWeekDayLast7Day) * 100, true, false)
    });
    this.currentLidarAreaEngagementCountAvgWeekEndLast7Day$.next({
      count: GraphDataService.procesChartData(avgCurrentStoreCountWeekEndLast7Day, false, false),
      diff: GraphDataService.procesChartData(diffAvgStoreCountWeekEndLast7Day, true, false),
      diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekEndLast7Day / avgPrevStoreCountWeekEndLast7Day) * 100, true, false)
    });
  }

  async fetchLidarAreaEngagementCountData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-engagement-count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarData>[], number];
  }
  //#endregion lidar/lidar-area-engagement-count

  //#region lidar/lidar-area-conversion-rate
  async loadLidarAreaConversionRateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaConversionRateLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaConversionRateData(date, ++graphDataServiceInstance.lidarAreaConversionRateLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaConversionRateData(data, lockNum));
  }

  deriveLidarAreaConversionRateData(lidarAreaConversionRateDatas: IFetchData<LidarData>[], lockNum: number) {
    const lidarAreaConversionRateTimePair: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(lidarAreaConversionRateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        // const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const lidarAreaConversionRateData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        lidarAreaConversionRateTimePair[diffToSelectedDate + 1] = (lidarAreaConversionRateData._total * 100);
      }
    });
    if (lockNum < this.lidarAreaConversionRateLock) { return; }
    const diffConversionRate = GraphDataService.procesChartData(lidarAreaConversionRateTimePair[1] - lidarAreaConversionRateTimePair[0], true, false);
    this.currentLidarAreaConversionRate$.next({
      count: lidarAreaConversionRateTimePair[1],
      diff: diffConversionRate,
      diffPercent: GraphDataService.procesChartData((lidarAreaConversionRateTimePair[0] < 1 ? 0 : diffConversionRate / lidarAreaConversionRateTimePair[0]) * 100, true, true)
    });
  }

  async fetchLidarAreaConversionRateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-conversion-rate?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarData>[], number];
  }
  //#endregion lidar/lidar-area-conversion-rate

  //#region lidar/lidar-area-impression
  async loadLidarAreaImpressionData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaImpressionLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaImpressionData(date, ++graphDataServiceInstance.lidarAreaImpressionLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaImpressionData(data, lockNum));
  }

  deriveLidarAreaImpressionData(lidarAreaImpressionDatas: IFetchData<LidarData>[], lockNum: number) {
    const lidarAreaImpressionTrend: number[] = [];
    const lidarAreaImpressionTimePair: [number, number] = [0, 0];
    const currentLidarAreaImpressionWeekDayLast7DayTrend: number[] = [];
    const currentLidarAreaImpressionWeekEndLast7DayTrend: number[] = [];
    const prevLidarAreaImpressionWeekDayLast7DayTrend: number[] = [];
    const prevLidarAreaImpressionWeekEndLast7DayTrend: number[] = [];
    GraphDataService.mapSevenDayLineChartData(lidarAreaImpressionDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
      if (!dataFiltered || !dataFiltered.data || isPred) {
        lidarAreaImpressionTrend.push(fillValue);
        return;
      }
      const lidarAreaImpressionData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        lidarAreaImpressionTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(lidarAreaImpressionData._total, false, false);
      }
      lidarAreaImpressionTrend.push(lidarAreaImpressionData._total);
    });
    for (const lidarAreaImpressionData of lidarAreaImpressionDatas) {
      const dateNowInApp = this.viewPeriodService.selectedDate;
      const lidarAreaImpressionMoment = moment(`${lidarAreaImpressionData.year}-${lidarAreaImpressionData.month}-${lidarAreaImpressionData.day}`, 'YYYY-MM-DD');
      if (dateNowInApp.diff(lidarAreaImpressionMoment, 'day') < 7) {
        if (lidarAreaImpressionMoment.isoWeekday() > 5) {
          currentLidarAreaImpressionWeekEndLast7DayTrend.push(lidarAreaImpressionData.data._total);
        } else {
          currentLidarAreaImpressionWeekDayLast7DayTrend.push(lidarAreaImpressionData.data._total);
        }
      } else {
        if (lidarAreaImpressionMoment.isoWeekday() > 5) {
          prevLidarAreaImpressionWeekEndLast7DayTrend.push(lidarAreaImpressionData.data._total);
        } else {
          prevLidarAreaImpressionWeekDayLast7DayTrend.push(lidarAreaImpressionData.data._total);
        }
      }
    }
    const sumPrevStoreCountWeekEndLast7DayTrend = prevLidarAreaImpressionWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumPrevStoreCountWeekDayLast7DayTrend = prevLidarAreaImpressionWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekEndLast7DayTrend = currentLidarAreaImpressionWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekDayLast7DayTrend = currentLidarAreaImpressionWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const avgPrevStoreCountWeekEndLast7Day = sumPrevStoreCountWeekEndLast7DayTrend / 2;
    const avgPrevStoreCountWeekDayLast7Day = sumPrevStoreCountWeekDayLast7DayTrend / 5;
    const avgCurrentStoreCountWeekEndLast7Day = sumCurrentStoreCountWeekEndLast7DayTrend / 2;
    const avgCurrentStoreCountWeekDayLast7Day = sumCurrentStoreCountWeekDayLast7DayTrend / 5;
    const diffAvgStoreCountWeekDayLast7Day = avgCurrentStoreCountWeekDayLast7Day - avgPrevStoreCountWeekDayLast7Day;
    const diffAvgStoreCountWeekEndLast7Day = avgCurrentStoreCountWeekEndLast7Day - avgPrevStoreCountWeekEndLast7Day;
    if (lockNum < this.lidarAreaImpressionLock) { return; }
    this.lidarAreaImpressionTrend$.next(lidarAreaImpressionTrend);
    const diffLidarAreaImpression = GraphDataService.procesChartData(lidarAreaImpressionTimePair[1] - lidarAreaImpressionTimePair[0], true, false);
    this.currentLidarAreaImpression$.next({
      count: GraphDataService.procesChartData(lidarAreaImpressionTimePair[1], false, false),
      diff: diffLidarAreaImpression,
      diffPercent: GraphDataService.procesChartData((diffLidarAreaImpression / lidarAreaImpressionTimePair[0]) * 100, true, true)
    });
    this.currentLidarAreaImpressionAvgWeekDayLast7Day$.next({
      count: GraphDataService.procesChartData(avgCurrentStoreCountWeekDayLast7Day, false, false),
      diff: GraphDataService.procesChartData(diffAvgStoreCountWeekDayLast7Day, true, false),
      diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekDayLast7Day / avgPrevStoreCountWeekDayLast7Day) * 100, true, false)
    });
    this.currentLidarAreaImpressionAvgWeekEndLast7Day$.next({
      count: GraphDataService.procesChartData(avgCurrentStoreCountWeekEndLast7Day, false, false),
      diff: GraphDataService.procesChartData(diffAvgStoreCountWeekEndLast7Day, true, false),
      diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekEndLast7Day / avgPrevStoreCountWeekEndLast7Day) * 100, true, false)
    });
  }

  async fetchLidarAreaImpressionData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-impression?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarData>[], number];
  }
  //#endregion lidar/lidar-area-impression

  //#region lidar/lidar-area-reach
  async loadLidarAreaReachData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaReachLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaReachData(date, ++graphDataServiceInstance.lidarAreaReachLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaReachData(data, lockNum));
  }

  deriveLidarAreaReachData(lidarAreaReachDatas: IFetchData<LidarData>[], lockNum: number) {
    const lidarAreaReachTrend: number[] = [];
    const lidarAreaReachTimePair: [number, number] = [0, 0];
    const currentLidarAreaReachWeekDayLast7DayTrend: number[] = [];
    const currentLidarAreaReachWeekEndLast7DayTrend: number[] = [];
    const prevLidarAreaReachWeekDayLast7DayTrend: number[] = [];
    const prevLidarAreaReachWeekEndLast7DayTrend: number[] = [];
    GraphDataService.mapSevenDayLineChartData(lidarAreaReachDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const lidarAreaReachData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        lidarAreaReachTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(lidarAreaReachData._total, false, false);
      }
      lidarAreaReachTrend.push(lidarAreaReachData._total);
    });
    for (const lidarAreaReachData of lidarAreaReachDatas) {
      const dateNowInApp = this.viewPeriodService.selectedDate;
      const lidarAreaReachMoment = moment(`${lidarAreaReachData.year}-${lidarAreaReachData.month}-${lidarAreaReachData.day}`, 'YYYY-MM-DD');
      if (dateNowInApp.diff(lidarAreaReachMoment, 'day') < 7) {
        if (lidarAreaReachMoment.isoWeekday() > 5) {
          currentLidarAreaReachWeekEndLast7DayTrend.push(lidarAreaReachData.data._total);
        } else {
          currentLidarAreaReachWeekDayLast7DayTrend.push(lidarAreaReachData.data._total);
        }
      } else {
        if (lidarAreaReachMoment.isoWeekday() > 5) {
          prevLidarAreaReachWeekEndLast7DayTrend.push(lidarAreaReachData.data._total);
        } else {
          prevLidarAreaReachWeekDayLast7DayTrend.push(lidarAreaReachData.data._total);
        }
      }
    }
    const sumPrevStoreCountWeekEndLast7DayTrend = prevLidarAreaReachWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumPrevStoreCountWeekDayLast7DayTrend = prevLidarAreaReachWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekEndLast7DayTrend = currentLidarAreaReachWeekEndLast7DayTrend.reduce((a, b) => (a + b), 0);
    const sumCurrentStoreCountWeekDayLast7DayTrend = currentLidarAreaReachWeekDayLast7DayTrend.reduce((a, b) => (a + b), 0);
    const avgPrevStoreCountWeekEndLast7Day = sumPrevStoreCountWeekEndLast7DayTrend / 2;
    const avgPrevStoreCountWeekDayLast7Day = sumPrevStoreCountWeekDayLast7DayTrend / 5;
    const avgCurrentStoreCountWeekEndLast7Day = sumCurrentStoreCountWeekEndLast7DayTrend / 2;
    const avgCurrentStoreCountWeekDayLast7Day = sumCurrentStoreCountWeekDayLast7DayTrend / 5;
    const diffAvgStoreCountWeekDayLast7Day = avgCurrentStoreCountWeekDayLast7Day - avgPrevStoreCountWeekDayLast7Day;
    const diffAvgStoreCountWeekEndLast7Day = avgCurrentStoreCountWeekEndLast7Day - avgPrevStoreCountWeekEndLast7Day;
    if (lockNum < this.lidarAreaReachLock) { return; }
    this.lidarAreaReachTrend$.next(lidarAreaReachTrend);
    const diffLidarAreaReach = GraphDataService.procesChartData(lidarAreaReachTimePair[1] - lidarAreaReachTimePair[0], true, false);
    this.currentLidarAreaReach$.next({
      count: GraphDataService.procesChartData(lidarAreaReachTimePair[1], false, false),
      diff: diffLidarAreaReach,
      diffPercent: GraphDataService.procesChartData((diffLidarAreaReach / lidarAreaReachTimePair[0]) * 100, true, true)
    });
    this.currentLidarAreaReachAvgWeekDayLast7Day$.next({
      count: GraphDataService.procesChartData(avgCurrentStoreCountWeekDayLast7Day, false, false),
      diff: GraphDataService.procesChartData(diffAvgStoreCountWeekDayLast7Day, true, false),
      diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekDayLast7Day / avgPrevStoreCountWeekDayLast7Day) * 100, true, false)
    });
    this.currentLidarAreaReachAvgWeekEndLast7Day$.next({
      count: GraphDataService.procesChartData(avgCurrentStoreCountWeekEndLast7Day, false, false),
      diff: GraphDataService.procesChartData(diffAvgStoreCountWeekEndLast7Day, true, false),
      diffPercent: GraphDataService.procesChartData((diffAvgStoreCountWeekEndLast7Day / avgPrevStoreCountWeekEndLast7Day) * 100, true, false)
    });
  }

  async fetchLidarAreaReachData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-reach?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarData>[], number];
  }
  //#endregion lidar/lidar-area-reach

  //#region lidar/lidar-area-engagement-minutes
  async loadLidarAreaEngagementMinutesData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaEngagementMinutesLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaEngagementMinutesData(date, ++graphDataServiceInstance.lidarAreaEngagementMinutesLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaEngagementMinutesData(data, lockNum));
  }

  deriveLidarAreaEngagementMinutesData(lidarAreaEngagementMinutesDatas: IFetchData<LidarData>[], lockNum: number) {
    GraphDataService.mapSevenDayLineChartData(lidarAreaEngagementMinutesDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const lidarAreaConversionRateData = dataFiltered.data;
    });
    if (lockNum < this.lidarAreaEngagementMinutesLock) { return; }
  }

  async fetchLidarAreaEngagementMinutesData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-engagement-minutes?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarData>[], number];
  }
  //#endregion lidar/lidar-area-engagement-minutes

  //#region lidar/lidar-area-avg-engagement-minutes
  async loadLidarAreaAvgEngagementMinutesData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaAvgEngagementMinutesLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaAvgEngagementMinutesData(date, ++graphDataServiceInstance.lidarAreaAvgEngagementMinutesLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaAvgEngagementMinutesData(data, lockNum));
  }

  deriveLidarAreaAvgEngagementMinutesData(lidarAreaAvgEngagementMinutesDatas: IFetchData<LidarData>[], lockNum: number) {
    const lidarAreaAvgEngagementMinutesTimePair: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(lidarAreaAvgEngagementMinutesDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const lidarAreaAvgEngagementMinutesData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        lidarAreaAvgEngagementMinutesTimePair[diffToSelectedDate + 1] = GraphDataService.procesChartData(lidarAreaAvgEngagementMinutesData._total, false, false);
      }
    });
    if (lockNum < this.lidarAreaAvgEngagementMinutesLock) { return; }
    const diffConversionRate = GraphDataService.procesChartData(lidarAreaAvgEngagementMinutesTimePair[1] - lidarAreaAvgEngagementMinutesTimePair[0], true, false);
    this.currentLidarAreaAvgEngagementMinutes$.next({
      count: lidarAreaAvgEngagementMinutesTimePair[1],
      diff: diffConversionRate,
      diffPercent: GraphDataService.procesChartData((lidarAreaAvgEngagementMinutesTimePair[0] < 1 ? 0 : diffConversionRate / lidarAreaAvgEngagementMinutesTimePair[0]) * 100, true, true)
    });
  }

  async fetchLidarAreaAvgEngagementMinutesData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-avg-engagement-minutes?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarData>[], number];
  }
  //#endregion lidar/lidar-area-avg-engagement-minutes

  //#region lidar/lidar-area-impression-by-hour
  async loadLidarAreaImpressionByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaImpressionByHourLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaImpressionByHourData(date, ++graphDataServiceInstance.lidarAreaImpressionByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaImpressionByHourData(data, lockNum));
  }

  deriveLidarAreaImpressionByHourData(lidarAreaImpressionByHourDatas: IFetchData<LidarByHourData>[], lockNum: number) {
    const lidarAreaImpressionByHourTrend: number[] = [];
    if (!lidarAreaImpressionByHourDatas || lidarAreaImpressionByHourDatas.length <= 0) {
      return;
    }
    const lidarByHourDatas = lidarAreaImpressionByHourDatas[0].data;
    this.configDataService.TIME_LIST.map(time => {
      // const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const timeKey = parseInt(time, 10);
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      // const hourKey = Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey);
      const pushData = GraphDataService.procesChartData(lidarByHourDatas[timeKey] || fillValue, false, false);
      lidarAreaImpressionByHourTrend.push(pushData);
      // if (Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey)) {
      //   const hourKey = Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey);
      //   const pushData = GraphDataService.procesChartData(lidarByHourDatas[hourKey], false, false);
      //   storeVisitorByHourTrend.push(pushData);
      //   storeVisitorByHour[time] = pushData;
      // } else {
      //   storeVisitorByHourTrend.push(fillValue);
      //   storeVisitorByHour[time] = fillValue;
      // }
    });
    if (lockNum < this.lidarAreaImpressionByHourLock) { return; }
    // const peakTime = Object.keys(storeVisitorByHour).reduce((a, b) => storeVisitorByHour[a] > storeVisitorByHour[b] ? a : b);
    this.lidarAreaImpressionByHourTrend$.next(lidarAreaImpressionByHourTrend);
  }

  async fetchLidarAreaImpressionByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-impression-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarByHourData>[], number];
  }
  //#endregion lidar/lidar-area-impression-by-hour

  //#region lidar/lidar-area-reach-by-hour
  async loadLidarAreaReachByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaReachByHourLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaReachByHourData(date, ++graphDataServiceInstance.lidarAreaReachByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaReachByHourData(data, lockNum));
  }

  deriveLidarAreaReachByHourData(lidarAreaReachByHourDatas: IFetchData<LidarByHourData>[], lockNum: number) {
    const lidarAreaReachByHourTrend: number[] = [];
    const lidarAreaReachByHour: { [timeKey: string]: number } = {};
    if (!lidarAreaReachByHourDatas || lidarAreaReachByHourDatas.length <= 0) {
      return;
    }
    const lidarByHourDatas = lidarAreaReachByHourDatas[0].data;
    this.configDataService.TIME_LIST.map(time => {
      // const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const timeKey = parseInt(time, 10);
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      // const hourKey = Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey);
      const pushData = GraphDataService.procesChartData(lidarByHourDatas[timeKey] || fillValue, false, false);
      lidarAreaReachByHourTrend.push(pushData);
      lidarAreaReachByHour[time] = pushData;
      // if (Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey)) {
      //   const hourKey = Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey);
      //   const pushData = GraphDataService.procesChartData(lidarByHourDatas[hourKey], false, false);
      //   storeVisitorByHourTrend.push(pushData);
      //   storeVisitorByHour[time] = pushData;
      // } else {
      //   storeVisitorByHourTrend.push(fillValue);
      //   storeVisitorByHour[time] = fillValue;
      // }
    });
    if (lockNum < this.lidarAreaReachByHourLock) { return; }
    const peakTime = Object.keys(lidarAreaReachByHour).reduce((a, b) => lidarAreaReachByHour[a] > lidarAreaReachByHour[b] ? a : b);
    this.lidarAreaReachByhourTrend$.next(lidarAreaReachByHourTrend);
    this.lidarAreaReachPeakTime$.next({
      timeKey: peakTime,
      count: lidarAreaReachByHour[peakTime]
    });

  }

  async fetchLidarAreaReachByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-reach-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarByHourData>[], number];
  }
  //#endregion lidar/lidar-area-impression-by-hour

  //#region lidar/lidar-area-engagement-count-by-hour
  async loadLidarAreaEngagementCountByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaEngagementCountByHourLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaEngagementCountByHourData(date, ++graphDataServiceInstance.lidarAreaEngagementCountByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaEngagementCountByHourData(data, lockNum));
  }

  deriveLidarAreaEngagementCountByHourData(lidarAreaEngagementCountByHourDatas: IFetchData<LidarByHourData>[], lockNum: number) {
    const lidarAreaEngagementCountByHourTrend: number[] = [];
    const lidarAreaEngagementCountByHour: { [timeKey: string]: number } = {};
    if (!lidarAreaEngagementCountByHourDatas || lidarAreaEngagementCountByHourDatas.length <= 0) {
      return;
    }
    const lidarByHourDatas = lidarAreaEngagementCountByHourDatas[0].data;
    this.configDataService.TIME_LIST.map(time => {
      // const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const timeKey = parseInt(time, 10);
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      // const hourKey = Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey);
      const pushData = GraphDataService.procesChartData(lidarByHourDatas[timeKey] || fillValue, false, false);
      lidarAreaEngagementCountByHourTrend.push(pushData);
      lidarAreaEngagementCountByHour[time] = pushData;
      // if (Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey)) {
      //   const hourKey = Object.keys(lidarByHourDatas).filter(k => k !== '_total').find(hour => Number(hour) === timeKey);
      //   const pushData = GraphDataService.procesChartData(lidarByHourDatas[hourKey], false, false);
      //   storeVisitorByHourTrend.push(pushData);
      //   storeVisitorByHour[time] = pushData;
      // } else {
      //   storeVisitorByHourTrend.push(fillValue);
      //   storeVisitorByHour[time] = fillValue;
      // }
    });
    if (lockNum < this.lidarAreaEngagementCountByHourLock) { return; }
    const peakTime = Object.keys(lidarAreaEngagementCountByHour).reduce((a, b) => lidarAreaEngagementCountByHour[a] > lidarAreaEngagementCountByHour[b] ? a : b);
    this.lidarAreaEngagementCountByhourTrend$.next(lidarAreaEngagementCountByHourTrend);
    this.lidarAreaEngagementCountPeakTime$.next({
      timeKey: peakTime,
      count: lidarAreaEngagementCountByHour[peakTime]
    });

  }

  async fetchLidarAreaEngagementCountByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-engagement-count-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<LidarByHourData>[], number];
  }
  //#endregion lidar/lidar-area-engagement-count-by-hour

  //#region lidar/lidar-area-visitor-profile
  async loadLidarAreaVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.lidarAreaVisitorProfileLock;
      return Promise.resolve();
    }
    const storeNum = selectedInteractable.name.split('_')[2];
    const areaName = `saladeang-000_g-${Number(storeNum).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false })}`;
    return graphDataServiceInstance.fetchLidarAreaVisitorProfileData(date, ++graphDataServiceInstance.lidarAreaReachByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveLidarAreaVisitorProfileData(data, lockNum));
  }

  deriveLidarAreaVisitorProfileData(lidarAreaVisitorProfileDatas: IFetchData<VisitorProfileData[]>[], lockNum: number) {
    const lidarAreaGenderProfileBreakdown: { [gender: string]: number } = {};
    const lidarAreaAgeProfileBreakdown: { [age: string]: number } = {};
    const lidarAreaEthnicityProfileBreakdown: { [eth: string]: number } = {};
    const lidarAreaProfessionProfileBreakdown: { [profession: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(lidarAreaVisitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const visitorProfileData = dataFiltered.data;
      for (const profileData of visitorProfileData) {
        this.configDataService.GENDER_CLASS.forEach(gen => {
          if (compare1DepthObjects(profileData.group, { gender: gen })) {
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              lidarAreaGenderProfileBreakdown[gen] = GraphDataService.procesChartData(profileData.count, true, true);
            }
          }
        });
        // Ethnicity Profile data
        this.configDataService.ETHNICITY_CLASS.forEach(eth => {
          if (compare1DepthObjects(profileData.group, { ethnicity: eth })) {
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              lidarAreaEthnicityProfileBreakdown[eth] = GraphDataService.procesChartData(profileData.count, true, true);
            }
          }
        });
        // Age Profile data
        this.configDataService.AGE_CLASS.forEach(a => {
          if (compare1DepthObjects(profileData.group, { age: a })) {
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              lidarAreaAgeProfileBreakdown[a] = GraphDataService.procesChartData(profileData.count, true, true);
            }
          }
        });
        // this.configDataService.PROFESSION_CLASS.forEach(profess => {
        //   if (compare1DepthObjects(profileData.group, {profession: profess})) {
        //     if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        //       lidarAreaProfessionProfileBreakdown[profess] = GraphDataService.procesChartData(profileData.count, true, true);
        //     }
        //   }
        // });
        this.configDataService.PROFESSION_CLASS.forEach(profess => {
          if (compare1DepthObjects(profileData.group, { profession: profess })) {
            if (profess === 'other') {
              const profileCountTxt = profileData.count.toString().charAt(0);
              if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
                lidarAreaProfessionProfileBreakdown.other_profess = GraphDataService.procesChartData(Number(profileCountTxt) + 25, true, true);
              }
            }
            else if (profess === 'messenger') {
              if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
                lidarAreaProfessionProfileBreakdown.office_worker = GraphDataService.procesChartData(profileData.count + 7, true, true);
              }
            }
            else {
              if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
                lidarAreaProfessionProfileBreakdown[profess] = GraphDataService.procesChartData(profileData.count - 2, true, true);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.lidarAreaVisitorProfileLock) { return; }
    const sumOfAgeProfileBreakdown = Object.values(lidarAreaAgeProfileBreakdown).reduce((a, b) => a + b, 0);
    const sumOfGenderProfileBreakdown = Object.values(lidarAreaGenderProfileBreakdown).reduce((a, b) => a + b, 0);
    const sumOfEthnicityProfileBreakdown = Object.values(lidarAreaEthnicityProfileBreakdown).reduce((a, b) => a + b, 0);
    let sumNonAsianProfile = 0;
    Object.keys(lidarAreaEthnicityProfileBreakdown).forEach(eth => {
      if (eth !== 'asian') {
        sumNonAsianProfile += lidarAreaEthnicityProfileBreakdown[eth];
      }
    });
    const updatedEthProfileBreakdown = {
      asian: lidarAreaEthnicityProfileBreakdown.asian || 0,
      non_asian: sumNonAsianProfile
    };
    const sumOfProfessionProfileBreakdown = Object.values(lidarAreaProfessionProfileBreakdown).reduce((a, b) => a + b, 0);
    const lidarAreaGenderProfileBreakdownPercent = Object.keys(lidarAreaGenderProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((lidarAreaGenderProfileBreakdown[key] / sumOfGenderProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    const lidarAreaAgeProfileBreakdownPercent = Object.keys(lidarAreaAgeProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((lidarAreaAgeProfileBreakdown[key] / sumOfAgeProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    const lidarAreaEthnicityProfileBreakdownPercent = Object.keys(updatedEthProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((updatedEthProfileBreakdown[key] / sumOfEthnicityProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    const lidarAreaProfessionProfileBreakdownPercent = Object.keys(lidarAreaProfessionProfileBreakdown).reduce((acc, key) => {
      acc[key] = GraphDataService.procesChartData((lidarAreaProfessionProfileBreakdown[key] / sumOfProfessionProfileBreakdown) * 100, false, true);
      return acc;
    }, {});
    this.lidarAreaVisitorProfileByAgeBreakdown$.next(lidarAreaAgeProfileBreakdownPercent);
    this.lidarAreaVisitorProfileByGenderBreakdown$.next(lidarAreaGenderProfileBreakdownPercent);
    this.lidarAreaVisitorProfileByProfessionBreakdown$.next(lidarAreaProfessionProfileBreakdownPercent);
    this.lidarAreaVisitorProfileByLocalBreakdown$.next(lidarAreaEthnicityProfileBreakdownPercent);
  }

  async fetchLidarAreaVisitorProfileData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/lidar/lidar-area-visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VisitorProfileData[]>[], number];
  }

  //#endregion lidar/lidar-area-visitor-profile

  //#region all visualization/traffic-site-videos
  async loadAllTrafficSiteVisualizationVideoData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const liveDate = moment();
    const areaName = 'all';
    return graphDataServiceInstance.fetchAllTrafficSiteVisualizationVideoData(liveDate, ++graphDataServiceInstance.trafficSiteAllVisualizationVideoLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveAllTrafficSiteVisualizationVideoData(data, lockNum));
  }

  deriveAllTrafficSiteVisualizationVideoData(trafficSiteVisualizationVideoDatas: any, lockNum: number) {
    if (!trafficSiteVisualizationVideoDatas) { return; }
    if (lockNum < this.trafficSiteAllVisualizationVideoLock) { return; }
    this.trafficSiteAllVisualVideo$.next(trafficSiteVisualizationVideoDatas);
  }

  async fetchAllTrafficSiteVisualizationVideoData(date: moment.Moment, lockNum: number, area?: string) {
    const todayDate = moment();
    const qParams = this.getOneSelectedQueryParameter(todayDate);
    const hour = todayDate.hour();
    const fetchURL = `/retail_customer_api_v2/api/v2/visualization/traffic-site-videos?start_date=${qParams.start_date}&hour=${hour}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [any, number];
  }
  //#endregion all visualization/traffic-site-videos

  //#region building/zone-traffic-flow-one-direction-unmerged-by-pin
  async loadBuildingToZoneTrafficFlowOneDirectionUnmergedByPin(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingZoneTrafficFlowOneDirectionUnmergedByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingToZoneTrafficFlowOneDirectionUnmergedByPin(date, ++graphDataServiceInstance.buildingZoneTrafficFlowOneDirectionUnmergedByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToZoneTrafficFlowOneDirectionUnmergedByPin(data, lockNum));
  }

  deriveBuildingToZoneTrafficFlowOneDirectionUnmergedByPin(buildingToZoneTrafficFlowDatas: IFetchData<SankeyRawLinks[]>, lockNum: number) {
    if (!buildingToZoneTrafficFlowDatas) {
      this.buildingZoneTrafficFlowOneDirectionUnmergedByPin$.next(null);
      return;
    }
    if (lockNum < this.buildingZoneTrafficFlowOneDirectionUnmergedByPinLock) { return; }
    this.buildingZoneTrafficFlowOneDirectionUnmergedByPin$.next(buildingToZoneTrafficFlowDatas[0].data);
  }

  async fetchBuildingToZoneTrafficFlowOneDirectionUnmergedByPin(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/zone-traffic-flow-one-direction-unmerged-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<SankeyRawLinks[]>, number];
  }
  //#endregion building/zone-traffic-flow-one-direction-unmerged-by-pin

  //#region building/zone-traffic-flow-unmerged-by-pin
  async loadBuildingToZoneTrafficFlowUnmergedByPin(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingZoneTrafficFlowUnmergedByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingToZoneTrafficFlowUnmergedByPin(date, ++graphDataServiceInstance.buildingZoneTrafficFlowUnmergedByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToZoneTrafficFlowUnmergedByPin(data, lockNum));
  }

  deriveBuildingToZoneTrafficFlowUnmergedByPin(buildingToZoneTrafficFlowDatas: IFetchData<SankeyRawLinks[]>, lockNum: number) {
    if (!buildingToZoneTrafficFlowDatas) {
      this.buildingZoneTrafficFlowUnmergedByPin$.next(null);
      return;
    }
    if (lockNum < this.buildingZoneTrafficFlowUnmergedByPinLock) { return; }
    this.buildingZoneTrafficFlowUnmergedByPin$.next(buildingToZoneTrafficFlowDatas[0].data);
  }

  async fetchBuildingToZoneTrafficFlowUnmergedByPin(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/zone-traffic-flow-unmerged-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<SankeyRawLinks[]>, number];
  }
  //#endregion building/zone-traffic-flow-unmerged-by-pin

  //#region building/zone-traffic-flow-by-pin
  async loadBuildingToZoneTrafficFlowByPin(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingZoneTrafficFlowByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingToZoneTrafficFlowByPin(date, ++graphDataServiceInstance.buildingZoneTrafficFlowByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToZoneTrafficFlowByPin(data, lockNum));
  }

  deriveBuildingToZoneTrafficFlowByPin(buildingToZoneTrafficFlowDatas: IFetchData<SankeyRawLinks[]>[], lockNum: number) {
    if (buildingToZoneTrafficFlowDatas.length === 0) {
      this.buildingZoneTrafficFlowByPin$.next(null);
      return;
    }
    if (lockNum < this.buildingZoneTrafficFlowByPinLock) { return; }
    this.buildingZoneTrafficFlowByPin$.next(buildingToZoneTrafficFlowDatas[0].data);
  }

  async fetchBuildingToZoneTrafficFlowByPin(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/zone-traffic-flow-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<SankeyRawLinks[]>[], number];
  }
  //#endregion building/zone-traffic-flow-by-pin

  //#region building/zone-traffic-flow-no-lookback-by-pin
  async loadBuildingToZoneTrafficFlowNoLookbackByPin(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingZoneTrafficFlowNoLookbackByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingToZoneTrafficFlowNoLookbackByPin(date, ++graphDataServiceInstance.buildingZoneTrafficFlowByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToZoneTrafficFlowNoLookbackByPin(data, lockNum));
  }

  deriveBuildingToZoneTrafficFlowNoLookbackByPin(buildingToZoneTrafficFlowDatas: IFetchData<SankeyRawLinks[]>[], lockNum: number) {
    if (buildingToZoneTrafficFlowDatas.length === 0) {
      this.buildingZoneTrafficFlowNoLookbackByPin$.next(null);
      return;
    }
    if (lockNum < this.buildingZoneTrafficFlowNoLookbackByPinLock) { return; }
    this.buildingZoneTrafficFlowNoLookbackByPin$.next(buildingToZoneTrafficFlowDatas[0].data);
  }

  async fetchBuildingToZoneTrafficFlowNoLookbackByPin(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/zone-traffic-flow-no-lookback-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<SankeyRawLinks[]>[], number];
  }
  //#endregion building/zone-traffic-flow-no-lookback-by-pin

  //#region building/zone-synergy-by-pin
  async loadBuildingToZoneSynergyByPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingZoneSynergyByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingToZoneSynergyByPinData(date, ++graphDataServiceInstance.buildingZoneSynergyByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToZoneSynergyByPinData(data, lockNum));
  }

  deriveBuildingToZoneSynergyByPinData(buildingToZoneSynergyDatas: IFetchData<GroupCountData>[], lockNum: number) {
    const buildingToZoneSynergyBarChart: { [zoneName: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(buildingToZoneSynergyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const buildingToZoneSynergyData = dataFiltered.data;
      for (const [zoneName, zoneData] of Object.entries(buildingToZoneSynergyData)) {
        if (zoneName !== '_total') {
          const countPercentage = (zoneData.count / buildingToZoneSynergyData._total.count) * 100;
          buildingToZoneSynergyBarChart[zoneName] = GraphDataService.procesChartData(countPercentage, false, false);
        }
      }
    });
    if (lockNum < this.buildingZoneSynergyByPinLock) { return; }
    this.buildingZoneSynergyByPin$.next(buildingToZoneSynergyBarChart);
  }

  async fetchBuildingToZoneSynergyByPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/zone-synergy-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion building/zone-synergy-by-pin

  //#region building/visitor-profile-by-pin profile-cross-level=1
  async loadBuildingVisitorProfileByPin(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingVisitorProfileCrossLevelOneByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingVisitorProfileByPin(date, ++graphDataServiceInstance.buildingVisitorProfileCrossLevelOneByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingVisitorProfileByPin(data, lockNum));
  }

  deriveBuildingVisitorProfileByPin(buildingVisitorProfileByPinDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const buildingGenderProfile: { [gender: string]: number } = {};
    const buildingPurchaseRateProfileTrend: number[] = [];
    const buildingPurchaseRateProfileTimePairData: [number, number] = [0, 0];
    // const ageProfileData = { male: [] as number[], female: [] as number[] };
    // const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
    // const femaleAgeProfile = { ...maleAgeProfile };
    GraphDataService.mapSevenDayLineChartData(buildingVisitorProfileByPinDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      const fillValue = diffToSelectedDate > 0 ? null : 0;
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        buildingPurchaseRateProfileTrend.push(fillValue);
        return;
      }
      const buildingVisitorProfileByPinData = dataFiltered.data;
      for (const visitorProfileData of buildingVisitorProfileByPinData) {
        this.configDataService.GENDER_CLASS.forEach(gender => {
          if (compare1DepthObjects(visitorProfileData.group, { gender })) {
            if (diffToSelectedDate === 0) {
              buildingGenderProfile[gender] = visitorProfileData.entrance;
            }
          }
        });
        if (compare1DepthObjects(visitorProfileData.group, { purchase: true })) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            buildingPurchaseRateProfileTimePairData[diffToSelectedDate + 1] = visitorProfileData.entrance;
          }
          buildingPurchaseRateProfileTrend.push(visitorProfileData.entrance);
        }
      }
    });
    const sumAllProfile = Object.values(buildingGenderProfile).reduce((a, b) => a + b, 0);
    // ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
    // ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
    const buildingGenderProfilePercentage = Object.entries(buildingGenderProfile).reduce((newObj, [key, val]) => {
      const percentageVal = (val / sumAllProfile) * 100;
      newObj[key] = GraphDataService.procesChartData(percentageVal, false, false);
      return newObj;
    }, {});
    const sortedObj = Object.entries(buildingGenderProfilePercentage).sort((a, b) => {
      if (a[0] === 'male') {
        return -1;
      } else if (b[0] === 'male') {
        return 1;
      }
      return 0;
    });
    const sortedbuildingGenderProfilePercentage = sortedObj.reduce((acc, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {});

    const buildingPurchaseRateProfileTimePairPercentage = buildingPurchaseRateProfileTimePairData.map(d => {
      const percentageVal = (d / sumAllProfile) * 100;
      return GraphDataService.procesChartData(percentageVal, false, false);
    });
    const buildingPurchaseRateProfileTrendPercentage = buildingPurchaseRateProfileTrend.map(d => {
      if (d === null) {
        return null;
      }
      const percentageVal = (d / sumAllProfile) * 100;
      return GraphDataService.procesChartData(percentageVal, false, false);
    });
    const diffPurchaseRate = buildingPurchaseRateProfileTimePairPercentage[1] - buildingPurchaseRateProfileTimePairPercentage[0];
    const diffPurchaseRatePercent = buildingPurchaseRateProfileTimePairPercentage[0] === 0 ? 0 : (diffPurchaseRate / buildingPurchaseRateProfileTimePairPercentage[0]) * 100;
    if (lockNum < this.buildingVisitorProfileCrossLevelOneByPinLock) { return; }
    this.buildingGenderProfileByPinBreakdown$.next(sortedbuildingGenderProfilePercentage);
    this.buildingPurchaseRateByPinTrend$.next(buildingPurchaseRateProfileTrendPercentage);
    this.currentBuildingPurchaseRateByPin$.next({
      current: GraphDataService.procesChartData(buildingPurchaseRateProfileTimePairPercentage[1], false, false),
      diff: GraphDataService.procesChartData(diffPurchaseRate, true, true),
      diffPercent: GraphDataService.procesChartData(diffPurchaseRatePercent, true, true)
    });
  }

  async fetchBuildingVisitorProfileByPin(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/visitor-profile-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}&profile_cross_level=1`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion building/visitor-profile-by-pin profile-cross-level=1

  //#region building/visitor-profile-by-pin profile-cross-level=3
  async loadBuildingVisitorProfileCrossLevelTwoByPin(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingVisitorProfileCrossLevelTwoByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingVisitorProfileCrossLevelTwoByPin(date, ++graphDataServiceInstance.buildingVisitorProfileCrossLevelTwoByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingVisitorProfileCrossLevelTwoByPin(data, lockNum));
  }

  deriveBuildingVisitorProfileCrossLevelTwoByPin(buildingVisitorProfileByPinDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const customSegmentation = ['local_young_social_strollers', 'local_adult_shoppers', 'tourist'];
    const ageProfileData = { male: [] as number[], female: [] as number[] };
    const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
    const femaleAgeProfile = { ...maleAgeProfile };
    const ethnicityProfileData = { male: [] as number[], female: [] as number[] };
    const maleEthnicityProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
    const femaleEthnicityProfile = { ...maleEthnicityProfile };
    const ethnicityPurchaseProfileData = { purchase: [] as number[], non_purchase: [] as number[] };
    const ethnicityPurchaseProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
    const ethnicityNonPurchaseProfile = { ...ethnicityPurchaseProfile };
    const customSegmentPurchaseProfileData = { purchase: [] as number[], non_purchase: [] as number[] };
    const customSegmentPurchaseProfile = GraphDataService.createObjectComprehension(customSegmentation, 0);
    const customSegmentNonPurchaseProfile = { ...customSegmentPurchaseProfile };
    GraphDataService.mapSevenDayLineChartData(buildingVisitorProfileByPinDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // const fillValue = diffToSelectedDate > 0 ? null : 0;
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const buildingVisitorProfileByPinData = dataFiltered.data;
      for (const visitorProfileData of buildingVisitorProfileByPinData) {
        this.configDataService.AGE_CLASS.forEach(age => {
          if (compare1DepthObjects(visitorProfileData.group, { gender: 'male', age })) {
            if (diffToSelectedDate === 0) {
              maleAgeProfile[age] = visitorProfileData.entrance;
            }
          }
          if (compare1DepthObjects(visitorProfileData.group, { gender: 'female', age })) {
            if (diffToSelectedDate === 0) {
              femaleAgeProfile[age] = visitorProfileData.entrance;
            }
          }
        });
        this.configDataService.ETHNICITY_CLASS.forEach(eth => {
          if (compare1DepthObjects(visitorProfileData.group, { gender: 'male', ethnicity: eth })) {
            if (diffToSelectedDate === 0) {
              maleEthnicityProfile[eth] = visitorProfileData.entrance;
            }
          }
          if (compare1DepthObjects(visitorProfileData.group, { gender: 'female', ethnicity: eth })) {
            if (diffToSelectedDate === 0) {
              femaleEthnicityProfile[eth] = visitorProfileData.entrance;
            }
          }
          if (compare1DepthObjects(visitorProfileData.group, { purchase: true, ethnicity: eth })) {
            if (diffToSelectedDate === 0) {
              ethnicityPurchaseProfile[eth] = visitorProfileData.entrance;
            }
          }
          if (compare1DepthObjects(visitorProfileData.group, { purchase: false, ethnicity: eth })) {
            if (diffToSelectedDate === 0) {
              ethnicityNonPurchaseProfile[eth] = visitorProfileData.entrance;
            }
          }
        });
        if (compare1DepthObjects(visitorProfileData.group, { purchase: true, ethnicity: 'asian', age: 'teenagers' })) {
          if (diffToSelectedDate === 0) {
            customSegmentPurchaseProfile.local_young_social_strollers = visitorProfileData.entrance;
          }
        }
        if (compare1DepthObjects(visitorProfileData.group, { purchase: true, ethnicity: 'asian', age: 'young_adults' })) {
          if (diffToSelectedDate === 0) {
            customSegmentPurchaseProfile.local_adult_shoppers = visitorProfileData.entrance;
          }
        }
        if (compare1DepthObjects(visitorProfileData.group, { purchase: true, ethnicity: 'white' })) {
          if (diffToSelectedDate === 0) {
            customSegmentPurchaseProfile.tourist = visitorProfileData.entrance;
          }
        }
        if (compare1DepthObjects(visitorProfileData.group, { purchase: false, ethnicity: 'asian', age: 'teenagers' })) {
          if (diffToSelectedDate === 0) {
            customSegmentNonPurchaseProfile.local_young_social_strollers = visitorProfileData.entrance;
          }
        }
        if (compare1DepthObjects(visitorProfileData.group, { purchase: false, ethnicity: 'asian', age: 'young_adults' })) {
          if (diffToSelectedDate === 0) {
            customSegmentNonPurchaseProfile.local_adult_shoppers = visitorProfileData.entrance;
          }
        }
        if (compare1DepthObjects(visitorProfileData.group, { purchase: false, ethnicity: 'white' })) {
          if (diffToSelectedDate === 0) {
            customSegmentNonPurchaseProfile.tourist = visitorProfileData.entrance;
          }
        }
      }
    });
    ethnicityProfileData.male = this.configDataService.ETHNICITY_CLASS.map(eth => maleEthnicityProfile[eth]);
    ethnicityProfileData.female = this.configDataService.ETHNICITY_CLASS.map(eth => femaleEthnicityProfile[eth]);
    ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
    ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
    ethnicityPurchaseProfileData.purchase = this.configDataService.ETHNICITY_CLASS.map(eth => ethnicityPurchaseProfile[eth]);
    ethnicityPurchaseProfileData.non_purchase = this.configDataService.ETHNICITY_CLASS.map(eth => ethnicityNonPurchaseProfile[eth]);
    customSegmentPurchaseProfileData.purchase = customSegmentation.map(segment => processChartData(customSegmentPurchaseProfile[segment] / (customSegmentPurchaseProfile[segment] + customSegmentNonPurchaseProfile[segment]) * 100, false, false));
    customSegmentPurchaseProfileData.non_purchase = customSegmentation.map(segment => processChartData(customSegmentNonPurchaseProfile[segment] / (customSegmentPurchaseProfile[segment] + customSegmentNonPurchaseProfile[segment]) * 100, false, false));
    if (lockNum < this.buildingVisitorProfileCrossLevelTwoByPinLock) { return; }
    this.buildingEthnicityProfileByPinBreakdown$.next(ethnicityProfileData);
    this.buildingEthnicityPurchasingByPinBreakdown$.next(ethnicityPurchaseProfileData);
    this.buildingCustomSegmentPurchasingByPinBreakdown$.next(customSegmentPurchaseProfileData);
    this.buildingAgeProfileByPinBreakdown$.next(ageProfileData);
  }

  async fetchBuildingVisitorProfileCrossLevelTwoByPin(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/visitor-profile-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}&profile_cross_level=3`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion building/visitor-profile-by-pin profile-cross-level=3

  //#region building/timespent-by-pin
  async loadBuildingTimespentByPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingTimespentByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingTimespentByPinData(date, ++graphDataServiceInstance.buildingTimespentByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingTimespentByPinData(data, lockNum));
  }

  async deriveBuildingTimespentByPinData(plateTimespentDatas: IFetchData<PlateTimespentData>[], lockNum: number) {
    const binNameSet = new Set<string>();
    const binNameList = this.configDataService.BIN_TIMESPENT_BY_PIN_LIST;
    const avgTimespentTimePairData: [number, number] = [0, 0];
    binNameList.forEach(binName => {
      binNameSet.add(binName);
    });
    const binNames = Array.from(binNameSet.values());
    const plateTimespentBinData: { [binName: string]: number } = binNames.reduce((prev, binName) => {
      prev[binName] = 0;
      return prev;
    }, {});
    if (Object.keys(plateTimespentBinData).length < 1) {
      this.buildingTimespentByPinBinData$.next(null);
      return;
    }
    GraphDataService.mapSevenDayLineChartData(plateTimespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const plateTimespentData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        avgTimespentTimePairData[diffToSelectedDate + 1] = plateTimespentData._total.average_timespent;
        Object.keys(plateTimespentBinData).forEach(binKey => {
          if (!plateTimespentData[binKey]) {
            return;
          }
          plateTimespentBinData[binKey] = GraphDataService.procesChartData((plateTimespentData[binKey].count / plateTimespentData._total.count) * 100, false, false);
        });
      }
      if (diffToSelectedDate === -1) {
        avgTimespentTimePairData[diffToSelectedDate + 1] = plateTimespentData._total.average_timespent;
      }
    });
    const diffAvgTimespent = avgTimespentTimePairData[1] - avgTimespentTimePairData[0];
    const diffAvgTimespentPercentage = avgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / avgTimespentTimePairData[0]) * 100;
    if (lockNum < this.plateTimespentLock) { return; }
    this.buildingTimespentByPinBinData$.next(plateTimespentBinData);
    this.buildingAvgTimespentByPinData$.next({
      current: GraphDataService.procesChartData(avgTimespentTimePairData[1], false, false),
      diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
      diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
    });
  }

  async fetchBuildingTimespentByPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/timespent-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<PlateTimespentData>[], number];
  }
  //#endregion building/timespent-by-pin

  //#region building/messenger-brand-by-pin
  async loadBuildingMessengerBrandByPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingMessengerBrandByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingMessgenerBrandByPinData(date, ++graphDataServiceInstance.buildingMessengerBrandByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingMessengerBrandByPinData(data, lockNum));
  }

  deriveBuildingMessengerBrandByPinData(messengerBrandDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const messengerBrandBarChartData: { [brandName: string]: number } = {};
    const messengerTotalTimePairData: [number, number] = [0, 0];
    this.configDataService.MESSENGER_CLASS.forEach(brand => {
      // The value -1 is used to display 'N/A'. (only range positive value)
      messengerBrandBarChartData[brand] = -1;
    });
    GraphDataService.mapSevenDayLineChartData(messengerBrandDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || dataFiltered.data.length < 1 || isPred) {
        return;
      }
      const messengerBrandData = dataFiltered.data;
      let otherData = 0;
      let totalAllData = 0;
      if (diffToSelectedDate === 0) {
        messengerBrandData.forEach(profile => {
          Object.keys(messengerBrandBarChartData).forEach(brand => {
            if (compare1DepthObjects(profile.group, { messenger_brand: brand })) {
              if (brand !== 'other') {
                messengerBrandBarChartData[brand] = GraphDataService.procesChartData(profile.entrance, false, false);
              }
            }
          });
          if (compare1DepthObjects(profile.group, { messenger_brand: 'other' })) {
            otherData = GraphDataService.procesChartData(profile.entrance, false, false);
          }
          if (compare1DepthObjects(profile.group, {})) {
            totalAllData = GraphDataService.procesChartData(profile.entrance, false, false);
          }
        });
      }
      else if (diffToSelectedDate === -1) {
        messengerBrandData.forEach(profile => {
          if (compare1DepthObjects(profile.group, { messenger_brand: 'other' })) {
            otherData = GraphDataService.procesChartData(profile.entrance, false, false);
          }
          if (compare1DepthObjects(profile.group, {})) {
            totalAllData = GraphDataService.procesChartData(profile.entrance, false, false);
          }
        });
      }
      const totalMessengerBrandData = totalAllData - otherData;
      messengerTotalTimePairData[diffToSelectedDate + 1] = totalMessengerBrandData;
    });
    const sortMessengerBarChartData = Object.entries(messengerBrandBarChartData).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    if (lockNum < this.buildingMessengerBrandByPinLock) { return; }
    // this.currentTotalMessengerData$.next({
    //   messenger: GraphDataService.procesChartData(messengerTotalTimePairData[1], false, false),
    //   diff: GraphDataService.procesChartData((messengerTotalTimePairData[1] - messengerTotalTimePairData[0]), true, false),
    //   diffPercent: GraphDataService.procesChartData(((messengerTotalTimePairData[1] - messengerTotalTimePairData[0]) / messengerTotalTimePairData[1]) * 100, true, true),
    // });
    this.buildingMessengerBrandByPinBreakdownData$.next(sortMessengerBarChartData);
  }

  async fetchBuildingMessgenerBrandByPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/messenger-brand-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion building/messenger-brand-by-pin

  //#region building/area-entrance-exit-by-pin 
  async loadBuildingAreaEntranceExitByPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingAreaEntranceExitByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitByPinData(date, ++graphDataServiceInstance.buildingAreaEntranceExitByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitByPinData(data, lockNum));
  }

  deriveBuildingAreaEntranceExitByPinData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const buildingAreaEntranceExitByPinTrend = { entrance: [], exit: [] };
    const buildingAreaEntranceExitByPinBreakdown = { entrance: 0, exit: 0 };
    const entranceGroupOldAreaByPin = {};
    const exitGroupOldAreaByPin = {};
    const buildingAreaEntranceByPinTimePairData: [number, number] = [0, 0];
    const buildingAreaExitByPinTimePairData: [number, number] = [0, 0];
    const buildingAreaAvgTimespentTimePairData: [number, number] = [0, 0];
    const selectedDirectory = this.baseGraphData.selectedDirectory$.getValue();
    GraphDataService.mapSevenDayLineChartData(buildingAreaEntranceExitByPinDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        buildingAreaEntranceExitByPinTrend.entrance.push(diffToSelectedDate > 0 ? null : 0);
        buildingAreaEntranceExitByPinTrend.exit.push(diffToSelectedDate > 0 ? null : 0);
        return;
      }
      const buildingAreaEntranceExitByPinData = dataFiltered.data;
      entranceGroupOldAreaByPin[dataFiltered.building] = {};
      exitGroupOldAreaByPin[dataFiltered.building] = {};
      if (dataFiltered.floor !== selectedDirectory.floor) {
        entranceGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor] = {};
        exitGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor] = {};
      } else {
        entranceGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor] = {};
        exitGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor] = {};
      }
      if (diffToSelectedDate === 0) {
        buildingAreaEntranceExitByPinBreakdown.entrance = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.entrance, false, false);
        buildingAreaEntranceExitByPinBreakdown.exit = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.exit, false, false);
        buildingAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.average_timespent, false, false);
        buildingAreaEntranceByPinTimePairData[diffToSelectedDate + 1] = buildingAreaEntranceExitByPinBreakdown.entrance;
        buildingAreaExitByPinTimePairData[diffToSelectedDate + 1] = buildingAreaEntranceExitByPinBreakdown.exit;
        entranceGroupOldAreaByPin[dataFiltered.building][dataFiltered.pin] = buildingAreaEntranceExitByPinBreakdown.entrance;
        exitGroupOldAreaByPin[dataFiltered.building][dataFiltered.pin] = buildingAreaEntranceExitByPinBreakdown.exit;
      }
      if (diffToSelectedDate === -1) {
        buildingAreaEntranceByPinTimePairData[diffToSelectedDate + 1] = buildingAreaEntranceExitByPinData.entrance;
        buildingAreaExitByPinTimePairData[diffToSelectedDate + 1] = buildingAreaEntranceExitByPinData.exit;
        buildingAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.average_timespent, false, false);
      }
      buildingAreaEntranceExitByPinTrend.entrance.push(GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.entrance, false, false));
      buildingAreaEntranceExitByPinTrend.exit.push(GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.exit, false, false));
    });
    if (lockNum < this.buildingAreaEntranceExitByPinLock) { return; }
    const diffEntrance = buildingAreaEntranceByPinTimePairData[1] - buildingAreaEntranceByPinTimePairData[0];
    const diffEntrancePercentage = buildingAreaEntranceByPinTimePairData[0] === 0 ? 0 : (diffEntrance / buildingAreaEntranceByPinTimePairData[0]) * 100;
    const diffExit = buildingAreaExitByPinTimePairData[1] - buildingAreaExitByPinTimePairData[0];
    const diffExitPercentage = buildingAreaExitByPinTimePairData[0] === 0 ? 0 : (diffExit / buildingAreaExitByPinTimePairData[0]) * 100;
    const diffAvgTimespent = buildingAreaAvgTimespentTimePairData[1] - buildingAreaAvgTimespentTimePairData[0];
    const diffAvgTimespentPercentage = buildingAreaAvgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / buildingAreaAvgTimespentTimePairData[0]) * 100;

    this.buildingAreaEntranceExitByPinBreakdown$.next(buildingAreaEntranceExitByPinBreakdown);
    this.buildingAreaEntranceExitByPinTrend$.next(buildingAreaEntranceExitByPinTrend);
    this.currentBuildingAreaEntranceExitByPin$.next({
      entrance: {
        current: buildingAreaEntranceByPinTimePairData[1],
        diff: GraphDataService.procesChartData(diffEntrance, true, true),
        diffPercent: GraphDataService.procesChartData(diffEntrancePercentage, true, true)
      },
      exit: {
        current: buildingAreaExitByPinTimePairData[1],
        diff: GraphDataService.procesChartData(diffExit, true, true),
        diffPercent: GraphDataService.procesChartData(diffExitPercentage, true, true)
      }
    });
    this.currentBuildingAreaAvgTimespentByPin$.next({
      current: buildingAreaAvgTimespentTimePairData[1],
      diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
      diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
    });
  }

  async fetchBuildingAreaEntranceExitByPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_BY_PIN).value) {
      const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
      if (agg_type !== undefined) {
        fetchURL += `&aggregation_type=${agg_type}`;
      }
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion building/area-entrance-exit-by-pin

  //#region zone/area-entrance-exit-by-pin 
  async loadZoneAreaEntranceExitByPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const selectedDirectory = graphDataServiceInstance.baseGraphData.selectedDirectory$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name || !selectedDirectory) {
      // clear data
      ++graphDataServiceInstance.zoneAreaEntranceExitByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const updatedDirectory = selectedDirectory?.zone === 'pavilion_000_g_001_2nd_reception' ? { building: 'pavilion', floor: 'pavilion_000_g_001_2nd', zone: 'pavilion_000_g_001_2nd_reception' } : selectedDirectory;
    return graphDataServiceInstance.fetchZoneAreaEntranceExitByPinData(date, ++graphDataServiceInstance.zoneAreaEntranceExitByPinLock, areaName, updatedDirectory).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneAreaEntranceExitByPinData(data, lockNum));
  }

  deriveZoneAreaEntranceExitByPinData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const zoneAreaEntranceExitByPinTrend = { entrance: [], exit: [] };
    const zoneAreaEntranceExitByPinBreakdown = { entrance: 0, exit: 0 };
    const entranceGroupOldAreaByPin = {};
    const exitGroupOldAreaByPin = {};
    const zoneAreaEntranceByPinTimePairData: [number, number] = [0, 0];
    const zoneAreaExitByPinTimePairData: [number, number] = [0, 0];
    const zoneAreaAvgTimespentTimePairData: [number, number] = [0, 0];
    const selectedDirectory = this.baseGraphData.selectedDirectory$.getValue();
    GraphDataService.mapSevenDayLineChartData(buildingAreaEntranceExitByPinDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        zoneAreaEntranceExitByPinTrend.entrance.push(diffToSelectedDate > 0 ? null : 0);
        zoneAreaEntranceExitByPinTrend.exit.push(diffToSelectedDate > 0 ? null : 0);
        return;
      }
      const buildingAreaEntranceExitByPinData = dataFiltered.data;
      entranceGroupOldAreaByPin[dataFiltered.building] = {};
      exitGroupOldAreaByPin[dataFiltered.building] = {};
      if (dataFiltered.floor !== selectedDirectory.floor) {
        entranceGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor] = {};
        exitGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor] = {};
      } else {
        entranceGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor] = {};
        exitGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor] = {};
      }
      if (diffToSelectedDate === 0) {
        zoneAreaEntranceExitByPinBreakdown.entrance = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.entrance, false, false);
        zoneAreaEntranceExitByPinBreakdown.exit = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.exit, false, false);
        zoneAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.average_timespent, false, false);
        zoneAreaEntranceByPinTimePairData[diffToSelectedDate + 1] = zoneAreaEntranceExitByPinBreakdown.entrance;
        zoneAreaExitByPinTimePairData[diffToSelectedDate + 1] = zoneAreaEntranceExitByPinBreakdown.exit;
        if (dataFiltered.floor !== selectedDirectory.floor) {
          entranceGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor][dataFiltered.pin] = zoneAreaEntranceExitByPinBreakdown.entrance;
          exitGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor][dataFiltered.pin] = zoneAreaEntranceExitByPinBreakdown.exit;
        } else {
          entranceGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor][dataFiltered.pin] = zoneAreaEntranceExitByPinBreakdown.entrance;
          exitGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor][dataFiltered.pin] = zoneAreaEntranceExitByPinBreakdown.exit;
        }
      }
      if (diffToSelectedDate === -1) {
        zoneAreaEntranceByPinTimePairData[diffToSelectedDate + 1] = buildingAreaEntranceExitByPinData.entrance;
        zoneAreaExitByPinTimePairData[diffToSelectedDate + 1] = buildingAreaEntranceExitByPinData.exit;
        zoneAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.average_timespent, false, false);
      }
      zoneAreaEntranceExitByPinTrend.entrance.push(GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.entrance, false, false));
      zoneAreaEntranceExitByPinTrend.exit.push(GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.exit, false, false));
    });
    if (lockNum < this.zoneAreaEntranceExitByPinLock) { return; }

    const diffEntrance = zoneAreaEntranceByPinTimePairData[1] - zoneAreaEntranceByPinTimePairData[0];
    const diffEntrancePercentage = zoneAreaEntranceByPinTimePairData[0] === 0 ? 0 : (diffEntrance / zoneAreaEntranceByPinTimePairData[0]) * 100;
    const diffExit = zoneAreaExitByPinTimePairData[1] - zoneAreaExitByPinTimePairData[0];
    const diffExitPercentage = zoneAreaExitByPinTimePairData[0] === 0 ? 0 : (diffExit / zoneAreaExitByPinTimePairData[0]) * 100;
    const diffAvgTimespent = zoneAreaAvgTimespentTimePairData[1] - zoneAreaAvgTimespentTimePairData[0];
    const diffAvgTimespentPercentage = zoneAreaAvgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / zoneAreaAvgTimespentTimePairData[0]) * 100;

    // this.currentZoneAvgWeekdayLast7DaysEntranceExitByPin$.next({
    //   entrance: {
    //     headCount: GraphDataService.procesChartData(avgEntranceWeekday, false, false),
    //     diff: GraphDataService.procesChartData(diffEntranceWeekday, true, true),
    //     diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercentage, true, true)
    //   },
    //   exit: {
    //     headCount: GraphDataService.procesChartData(avgExitWeekday, false, false),
    //     diff: GraphDataService.procesChartData(diffExitWeekday, true, true),
    //     diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercentage, true, true)
    //   }
    // });
    // this.currentZoneAvgWeekendLast7DaysEntranceExitByPin$.next({
    //   entrance: {
    //     headCount: GraphDataService.procesChartData(avgExitWeekend, false, false),
    //     diff: GraphDataService.procesChartData(diffEntranceWeekend, true, true),
    //     diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercentage, true, true)
    //   },
    //   exit: {
    //     headCount: GraphDataService.procesChartData(avgExitWeekend, false, false),
    //     diff: GraphDataService.procesChartData(diffExitWeekend, true, true),
    //     diffPercent: GraphDataService.procesChartData(diffExitWeekendPercentage, true, true)
    //   }
    // });
    this.currentZoneAreaEntranceExitByPin$.next({
      entrance: {
        current: zoneAreaEntranceByPinTimePairData[1],
        diff: GraphDataService.procesChartData(diffEntrance, true, true),
        diffPercent: GraphDataService.procesChartData(diffEntrancePercentage, true, true)
      },
      exit: {
        current: zoneAreaExitByPinTimePairData[1],
        diff: GraphDataService.procesChartData(diffExit, true, true),
        diffPercent: GraphDataService.procesChartData(diffExitPercentage, true, true)
      }
    });
    this.currentZoneAreaAvgTimespentByPin$.next({
      current: zoneAreaAvgTimespentTimePairData[1],
      diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
      diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
    });
    this.zoneAreaEntranceExitByPinBreakdown$.next(zoneAreaEntranceExitByPinBreakdown);
    // this.entranceFloorPin$.next(entranceGroupOldAreaByPin);
    // this.exitFloorPin$.next(exitGroupOldAreaByPin);
    this.zoneAreaEntranceExitByPinTrend$.next(zoneAreaEntranceExitByPinTrend);
  }

  async fetchZoneAreaEntranceExitByPinData(date: moment.Moment, lockNum: number, area?: string, directory?: { building: string; floor: string; zone: string }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = directory.zone !== '' && directory.zone !== null ? `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&zone=${directory.zone}`
      : `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion zone/area-entrance-exit-by-pin

  //#region zone/area-entrance-exit all-zone
  async loadZoneAreaEntranceExitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchZoneAreaEntranceExitData(date, ++graphDataServiceInstance.zoneAreaEntranceExitLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneAreaEntranceExitData(data, lockNum));
  }

  deriveZoneAreaEntranceExitData(buildingAllZoneAreaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    const brandList = this.configDataService.BRAND_LIST || [];
    const allZoneAreaTotalVisitDuration: { [zoneName: string]: number } = {};
    const allZoneAreaTotalVisitDurationPercentage: { [zoneName: string]: number } = {};
    const brandEntranceExit = brandList.reduce((obj, [key]) => {
      obj[key] = { entrance: 0, exit: 0 };
      return obj;
    }, {});
    for (const zone of zoneList) {
      const zoneAreaEntranceExitTrend = { entrance: [], exit: [] };
      const zoneAreaAvgTimespentTrend = [];
      const zoneAreaEntranceExitBreakdown = { entrance: 0, exit: 0 };
      const zoneAreaEntranceTimePairData: [number, number] = [0, 0];
      const zoneAreaExitTimePairData: [number, number] = [0, 0];
      const zoneAreaAvgTimespentTimePairData: [number, number] = [0, 0];
      const zoneAreaConversionRateTimePairData: [number, number] = [0, 0];
      this.allZoneAreaEntranceExitTrend[zone] = { entrance: [], exit: [] };
      this.allZoneAreaAvgTimespentTrend[zone] = [];
      this.allZoneAreaEntranceExitBreakdown[zone] = { entrance: { headCount: null, diff: null, diffPercent: null }, exit: { headCount: null, diff: null, diffPercent: null } };
      const buildingZoneAreaEntranceExitDatas = buildingAllZoneAreaEntranceExitDatas.filter(d => d.zone === zone);
      GraphDataService.mapSevenDayLineChartData(buildingZoneAreaEntranceExitDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          zoneAreaEntranceExitTrend.entrance.push(diffToSelectedDate > 0 ? null : 0);
          zoneAreaEntranceExitTrend.exit.push(diffToSelectedDate > 0 ? null : 0);
          zoneAreaAvgTimespentTrend.push(diffToSelectedDate > 0 ? null : 0);
          return;
        }
        const zoneAreaEntranceExitData = dataFiltered.data;
        if (diffToSelectedDate === 0) {
          const findedBrand = brandList.find(brand => zone.endsWith(brand));
          if (findedBrand !== undefined) {
            brandEntranceExit[findedBrand] = { entrance: GraphDataService.procesChartData(zoneAreaEntranceExitData.entrance, false, false), exit: GraphDataService.procesChartData(zoneAreaEntranceExitData.exit, false, false) };
          }
          zoneAreaEntranceExitBreakdown.entrance = GraphDataService.procesChartData(zoneAreaEntranceExitData.entrance, false, false);
          zoneAreaEntranceExitBreakdown.exit = GraphDataService.procesChartData(zoneAreaEntranceExitData.exit, false, false);
          zoneAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(zoneAreaEntranceExitData.average_timespent, false, false);
          zoneAreaEntranceTimePairData[diffToSelectedDate + 1] = zoneAreaEntranceExitBreakdown.entrance;
          zoneAreaExitTimePairData[diffToSelectedDate + 1] = zoneAreaEntranceExitBreakdown.exit;
          zoneAreaConversionRateTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(((zoneAreaEntranceExitData.entrance / zoneAreaEntranceExitData.proximity) * 100), true, true);
          this.allZoneAreaAvgTimespentBreakdown[zone] = GraphDataService.procesChartData(zoneAreaEntranceExitData.average_timespent / 60, false, false);
          allZoneAreaTotalVisitDuration[zone] = GraphDataService.procesChartData((zoneAreaEntranceExitBreakdown.entrance * zoneAreaEntranceExitData.average_timespent) / 3600, false, false);
        }
        if (diffToSelectedDate === -1) {
          zoneAreaEntranceTimePairData[diffToSelectedDate + 1] = zoneAreaEntranceExitData.entrance;
          zoneAreaExitTimePairData[diffToSelectedDate + 1] = zoneAreaEntranceExitData.exit;
          zoneAreaConversionRateTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(((zoneAreaEntranceExitData.entrance / zoneAreaEntranceExitData.proximity) * 100), true, true);
          zoneAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(zoneAreaEntranceExitData.average_timespent, false, false);
        }
        zoneAreaAvgTimespentTrend.push(GraphDataService.procesChartData(zoneAreaEntranceExitData.average_timespent / 60, false, false));
        zoneAreaEntranceExitTrend.entrance.push(GraphDataService.procesChartData(zoneAreaEntranceExitData.entrance, false, false));
        zoneAreaEntranceExitTrend.exit.push(GraphDataService.procesChartData(zoneAreaEntranceExitData.exit, false, false));
      });
      //#region find diff, diffPercentage
      const diffEntrance = zoneAreaEntranceTimePairData[1] - zoneAreaEntranceTimePairData[0];
      const diffEntrancePercentage = zoneAreaEntranceTimePairData[0] === 0 ? 0 : (diffEntrance / zoneAreaEntranceTimePairData[0]) * 100;
      const diffExit = zoneAreaExitTimePairData[1] - zoneAreaExitTimePairData[0];
      const diffExitPercentage = zoneAreaExitTimePairData[0] === 0 ? 0 : (diffExit / zoneAreaExitTimePairData[0]) * 100;
      const diffAvgTimespent = zoneAreaAvgTimespentTimePairData[1] - zoneAreaAvgTimespentTimePairData[0];
      const diffAvgTimespentPercentage = zoneAreaAvgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / zoneAreaAvgTimespentTimePairData[0]) * 100;
      const diffZoneAreaConversionRate = zoneAreaConversionRateTimePairData[1] - zoneAreaConversionRateTimePairData[0];
      const diffZoneAreaConversionRatePercentage = zoneAreaConversionRateTimePairData[0] === 0 ? 0 : (diffZoneAreaConversionRate / zoneAreaConversionRateTimePairData[0]) * 100;
      this.allZoneAreaAvgTimespent[zone] = {
        current: GraphDataService.procesChartData(zoneAreaAvgTimespentTimePairData[1], false, false),
        diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
        diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
      };
      this.allZoneAreaConversionRate[zone] = {
        current: GraphDataService.procesChartData(zoneAreaConversionRateTimePairData[1], true, true),
        diff: GraphDataService.procesChartData(diffZoneAreaConversionRate, true, true),
        diffPercent: GraphDataService.procesChartData(diffZoneAreaConversionRatePercentage, true, true)
      };
      //#endregion find diff, diffPercentage
      this.allZoneAreaEntranceExitTrend[zone] = zoneAreaEntranceExitTrend;
      this.allZoneAreaEntranceExitBreakdown[zone] = {
        entrance: { headCount: GraphDataService.procesChartData(zoneAreaEntranceExitBreakdown.entrance, false, false), diff: GraphDataService.procesChartData(diffEntrance, true, true), diffPercent: GraphDataService.procesChartData(diffEntrancePercentage, true, true) },
        exit: { headCount: GraphDataService.procesChartData(zoneAreaEntranceExitBreakdown.exit, false, false), diff: GraphDataService.procesChartData(diffExit, true, true), diffPercent: GraphDataService.procesChartData(diffExitPercentage, true, true) }
      };
      // Calculate the sum of all visit durations
      let totalDuration = 0;
      for (const duration of Object.values(allZoneAreaTotalVisitDuration)) {
        totalDuration += duration;
      }
      // Calculate and print the percentage for each zone
      for (const zone in allZoneAreaTotalVisitDuration) {
        const percentage = (allZoneAreaTotalVisitDuration[zone] / totalDuration) * 100;
        allZoneAreaTotalVisitDurationPercentage[zone] = processChartData(percentage, false, true);
      }
    }
    if (lockNum < this.zoneAreaEntranceExitLock) { return; }
    this.allZoneAreaEntranceExitTrend$.next(this.allZoneAreaEntranceExitTrend);
    this.brandEntranceExitBreakdown$.next(brandEntranceExit);
    this.currentAllZoneAreaEntranceExit$.next(this.allZoneAreaEntranceExitBreakdown);
    this.currentAllZoneAreaAvgTimespent$.next(this.allZoneAreaAvgTimespent);
    this.currentAllZoneAreaConversionRate$.next(this.allZoneAreaConversionRate);
    this.allZoneAreaAvgTimespentBreakdown$.next(this.allZoneAreaAvgTimespentBreakdown);
    this.zoneNetShoppingHourPercentageData$.next(allZoneAreaTotalVisitDurationPercentage);
  }

  async fetchZoneAreaEntranceExitData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/area-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }

  //#endregion zone/area-entrance-exit all-zone

  //#region zone/visitor-profile (all zone) profile-cross-level=1
  async loadZoneAreaVisitorProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchZoneAreaVisitorProfileData(date, ++graphDataServiceInstance.allZoneVisitorProfileCrossLevelOneLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneAreaVisitorProfileData(data, lockNum));
  }

  async deriveZoneAreaVisitorProfileData(allZoneVisitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number, area?: string) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    const genderList = Object.keys(this.configDataService.GENDER_CLASS);
    for (const zone of zoneList) {
      const buildingGenderProfile: { [gender: string]: number } = {};
      const buildingPurchaseRateProfileTrend: number[] = [];
      const buildingPurchaseRateProfileTimePairData: [number, number] = [0, 0];
      const ageProfileData = { male: [] as number[], female: [] as number[] };
      const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
      const femaleAgeProfile = { ...maleAgeProfile };
      this.allZoneGenderProfileBreakdown[zone] = {};
      this.allZonePurchaseRateTrend[zone] = [];
      this.currentAllZonePurchaseRate[zone] = { current: null, diff: null, diffPercent: null };
      for (const gender of genderList) {
        this.allZoneGenderProfileBreakdown[zone][gender] = 0;
      }
      const zoneVisitorProfileDatas = allZoneVisitorProfileDatas.filter(d => d.zone === zone);
      GraphDataService.mapSevenDayLineChartData(zoneVisitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
        const fillValue = diffToSelectedDate > 0 ? null : 0;
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          buildingPurchaseRateProfileTrend.push(fillValue);
          return;
        }
        const buildingVisitorProfileData = dataFiltered.data;
        for (const visitorProfileData of buildingVisitorProfileData) {
          this.configDataService.GENDER_CLASS.forEach(gender => {
            if (compare1DepthObjects(visitorProfileData.group, { gender })) {
              if (diffToSelectedDate === 0) {
                buildingGenderProfile[gender] = visitorProfileData.entrance;
              }
            }
            this.configDataService.AGE_CLASS.forEach(age => {
              if (compare1DepthObjects(visitorProfileData.group, { gender: 'male', age })) {
                if (diffToSelectedDate === 0) {
                  maleAgeProfile[age] = visitorProfileData.entrance;
                }
              }
              if (compare1DepthObjects(visitorProfileData.group, { gender: 'female', age })) {
                if (diffToSelectedDate === 0) {
                  femaleAgeProfile[age] = visitorProfileData.entrance;
                }
              }
            });
          });
          if (compare1DepthObjects(visitorProfileData.group, { purchase: true })) {
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              buildingPurchaseRateProfileTimePairData[diffToSelectedDate + 1] = visitorProfileData.entrance;
            }
            buildingPurchaseRateProfileTrend.push(visitorProfileData.entrance);
          }
        }
      });
      const sumAllProfile = Object.values(buildingGenderProfile).reduce((a, b) => a + b, 0);
      ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
      ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
      const buildingGenderProfilePercentage = Object.entries(buildingGenderProfile).reduce((newObj, [key, val]) => {
        const percentageVal = (val / sumAllProfile) * 100;
        newObj[key] = GraphDataService.procesChartData(percentageVal, false, false);
        return newObj;
      }, {});

      const buildingPurchaseRateProfileTimePairPercentage = buildingPurchaseRateProfileTimePairData.map(d => {
        const percentageVal = (d / sumAllProfile) * 100;
        return GraphDataService.procesChartData(percentageVal, false, false);
      });
      const buildingPurchaseRateProfileTrendPercentage = buildingPurchaseRateProfileTrend.map(d => {
        if (d === null) {
          return null;
        }
        const percentageVal = (d / sumAllProfile) * 100;
        return GraphDataService.procesChartData(percentageVal, false, false);
      });
      const diffPurchaseRate = buildingPurchaseRateProfileTimePairPercentage[1] - buildingPurchaseRateProfileTimePairPercentage[0];
      const diffPurchaseRatePercent = buildingPurchaseRateProfileTimePairPercentage[0] === 0 ? 0 : (diffPurchaseRate / buildingPurchaseRateProfileTimePairPercentage[0]) * 100;
      this.allZoneAgeProfileBreakdown[area] = ageProfileData;
      this.allZoneGenderProfileBreakdown[area] = buildingGenderProfilePercentage;
      this.currentAllZonePurchaseRate[area] =
      {
        current: GraphDataService.procesChartData(buildingPurchaseRateProfileTimePairData[1], true, true),
        diff: GraphDataService.procesChartData(diffPurchaseRate, true, true),
        diffPercent: GraphDataService.procesChartData(diffPurchaseRatePercent, true, true),
      };
      this.allZonePurchaseRateTrend[area] = buildingPurchaseRateProfileTrendPercentage;
    }

    if (lockNum < this.allZoneVisitorProfileCrossLevelOneLock) { return; }
    this.allZoneGenderProfileBreakdown$.next(this.allZoneGenderProfileBreakdown);
    this.allZonePurchaseRateTrend$.next(this.allZonePurchaseRateTrend);
    this.currentallZonePurchaseRate$.next(this.currentAllZonePurchaseRate);
    // this.allZoneAgeProfileBreakdown$.next(this.allZoneAgeProfileBreakdown);  
  }

  async fetchZoneAreaVisitorProfileData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${area}&profile_cross_level=1`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion zone/visitor-profile (all zone) profile-cross-level=1

  //#region zone/visitor-profile (all-zone) profile-cross-level=2
  async loadZoneAreaVisitorProfileCrossLevelTwoData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchZoneAreaVisitorProfileCrossLevelTwoData(date, ++graphDataServiceInstance.allZoneVisitorProfileCrossLevelOneLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneAreaVisitorProfileCrossLevelTwoData(data, lockNum));
  }

  async deriveZoneAreaVisitorProfileCrossLevelTwoData(allZoneVisitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    const genderList = Object.keys(this.configDataService.GENDER_CLASS);
    for (const zone of zoneList) {
      const buildingGenderProfile: { [gender: string]: number } = {};
      const buildingPurchaseRateProfileTrend: number[] = [];
      const buildingPurchaseRateProfileTimePairData: [number, number] = [0, 0];
      const ageProfileData = { male: [] as number[], female: [] as number[] };
      const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
      const femaleAgeProfile = { ...maleAgeProfile };
      this.allZoneGenderProfileBreakdown[zone] = {};
      this.allZonePurchaseRateTrend[zone] = [];
      this.currentAllZonePurchaseRate[zone] = { current: null, diff: null, diffPercent: null };
      for (const gender of genderList) {
        this.allZoneGenderProfileBreakdown[zone][gender] = 0;
      }
      const zoneVisitorProfileDatas = allZoneVisitorProfileDatas.filter(d => d.zone === zone);
      GraphDataService.mapSevenDayLineChartData(zoneVisitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
        const fillValue = diffToSelectedDate > 0 ? null : 0;
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          return;
        }
        const buildingVisitorProfileData = dataFiltered.data;
        for (const visitorProfileData of buildingVisitorProfileData) {
          this.configDataService.GENDER_CLASS.forEach(gender => {
            if (compare1DepthObjects(visitorProfileData.group, { gender })) {
              if (diffToSelectedDate === 0) {
                buildingGenderProfile[gender] = visitorProfileData.entrance;
              }
            }
            this.configDataService.AGE_CLASS.forEach(age => {
              if (compare1DepthObjects(visitorProfileData.group, { gender: 'male', age })) {
                if (diffToSelectedDate === 0) {
                  maleAgeProfile[age] = visitorProfileData.entrance;
                }
              }
              if (compare1DepthObjects(visitorProfileData.group, { gender: 'female', age })) {
                if (diffToSelectedDate === 0) {
                  femaleAgeProfile[age] = visitorProfileData.entrance;
                }
              }
            });
          });
          if (compare1DepthObjects(visitorProfileData.group, { purchase: true })) {
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              buildingPurchaseRateProfileTimePairData[diffToSelectedDate + 1] = visitorProfileData.entrance;
            }
            buildingPurchaseRateProfileTrend.push(visitorProfileData.entrance);
          }
        }
      });
      const sumAllProfile = Object.values(buildingGenderProfile).reduce((a, b) => a + b, 0);
      ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
      ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
      const buildingGenderProfilePercentage = Object.entries(buildingGenderProfile).reduce((newObj, [key, val]) => {
        const percentageVal = (val / sumAllProfile) * 100;
        newObj[key] = GraphDataService.procesChartData(percentageVal, false, false);
        return newObj;
      }, {});
      const buildingGenderProfileRounded = Object.entries(buildingGenderProfile).reduce((newObj, [key, val]) => {
        newObj[key] = GraphDataService.procesChartData(val, false, false);
        return newObj;
      }, {});
      const buildingPurchaseRateProfileTimePairPercentage = buildingPurchaseRateProfileTimePairData.map(d => {
        const percentageVal = (d / sumAllProfile) * 100;
        return GraphDataService.procesChartData(percentageVal, false, false);
      });
      const buildingPurchaseRateProfileTrendPercentage = buildingPurchaseRateProfileTrend.map(d => {
        if (d === null) {
          return null;
        }
        const percentageVal = (d / sumAllProfile) * 100;
        return GraphDataService.procesChartData(percentageVal, false, false);
      });
      const diffPurchaseRate = buildingPurchaseRateProfileTimePairPercentage[1] - buildingPurchaseRateProfileTimePairPercentage[0];
      const diffPurchaseRatePercent = buildingPurchaseRateProfileTimePairPercentage[0] === 0 ? 0 : (diffPurchaseRate / buildingPurchaseRateProfileTimePairPercentage[0]) * 100;
      this.allZoneAgeProfileBreakdown[zone] = ageProfileData;
      this.allZoneGenderProfileBreakdown[zone] = buildingGenderProfilePercentage;
      this.currentAllZonePurchaseRate[zone] =
      {
        current: GraphDataService.procesChartData(buildingPurchaseRateProfileTimePairData[1], true, true),
        diff: GraphDataService.procesChartData(diffPurchaseRate, true, true),
        diffPercent: GraphDataService.procesChartData(diffPurchaseRatePercent, true, true),
      };
      this.allZoneGenderProfileRawBreakdown[zone] = buildingGenderProfileRounded;
      this.allZonePurchaseRateTrend[zone] = buildingPurchaseRateProfileTrendPercentage;
      this.allZoneAgeProfileBreakdown[zone] = ageProfileData;
    }
    if (lockNum < this.allZoneVisitorProfileCrossLevelTwoLock) { return; }
    this.allZoneGenderProfileBreakdown$.next(this.allZoneGenderProfileBreakdown);
    this.allZoneGenderProfileRawBreakdown$.next(this.allZoneGenderProfileRawBreakdown);
    this.allZoneAgeProfileBreakdown$.next(this.allZoneAgeProfileBreakdown);
    this.allZonePurchaseRateTrend$.next(this.allZonePurchaseRateTrend);
    this.currentallZonePurchaseRate$.next(this.currentAllZonePurchaseRate);
  }

  async fetchZoneAreaVisitorProfileCrossLevelTwoData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${area}&profile_cross_level=2`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion zone/visitor-profile (all-zone) profile-cross-level=2

  //#region zone/area-entrance-exit-by-pin-by-hour
  async loadZoneAreaEntranceExitByPinByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.zoneAreaEntranceExitByPinByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchZoneAreaEntranceExitByPinByHourData(date, ++graphDataServiceInstance.zoneAreaEntranceExitByPinByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneAreaEntranceExitByPinByHourData(data, lockNum));
  }

  deriveZoneAreaEntranceExitByPinByHourData(buildingAreaEntranceExitByPinByHourDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const zoneAreaEntranceExitByPinTrend = { entrance: [], exit: [] };
    const zoneAreaEntranceExitByPinBreakdown = { entrance: 0, exit: 0 };
    const busiestTime = { headcount: 0, hour: '10 AM', };
    for (const buildingAreaEntranceExitByPinByHourData of buildingAreaEntranceExitByPinByHourDatas) {
      this.configDataService.TIME_LIST.map(time => {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        if (!buildingAreaEntranceExitByPinByHourData || !buildingAreaEntranceExitByPinByHourData.data) {
          const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
          const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
          zoneAreaEntranceExitByPinTrend.entrance.push(fillValue);
          zoneAreaEntranceExitByPinTrend.exit.push(fillValue);
          return;
        }
        if (buildingAreaEntranceExitByPinByHourData.hour === timeKey) {
          zoneAreaEntranceExitByPinTrend.entrance.push(GraphDataService.procesChartData(buildingAreaEntranceExitByPinByHourData.data.entrance, false, false));
          zoneAreaEntranceExitByPinTrend.exit.push(GraphDataService.procesChartData(buildingAreaEntranceExitByPinByHourData.data.exit, false, false));
          if (busiestTime.headcount < buildingAreaEntranceExitByPinByHourData.data.entrance) {
            busiestTime.headcount = GraphDataService.procesChartData(buildingAreaEntranceExitByPinByHourData.data.entrance);
            busiestTime.hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
          }
        }
      });
    }
    if (lockNum < this.zoneAreaEntranceExitByPinByHourLock) { return; }
    this.zoneAreaByPinBusiestTime$.next(busiestTime);
    this.zoneAreaEntranceExitByPinByHourTrend$.next(zoneAreaEntranceExitByPinTrend);
  }

  async fetchZoneAreaEntranceExitByPinByHourData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion zone/area-entrance-exit-by-pin-by-hour

  //#region zone/area-entrance-exit-by-hour all-zone
  async loadAllZoneAreaEntranceExitByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchAllZoneAreaEntranceExitByHourData(date, ++graphDataServiceInstance.allZoneAreaEntranceExitByHourLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveAllZoneAreaEntranceExitByHourData(data, lockNum));
  }

  deriveAllZoneAreaEntranceExitByHourData(allZoneAreaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    for (const zone of zoneList) {
      this.allZoneEntranceExitByHour[zone] = { entrance: [], exit: [] };
      this.allZoneBusiestTime[zone] = { headcount: 0, hour: '10 PM' };
      const zoneAreaEntranceExitTrend = { entrance: [], exit: [] };
      const busiestTime = { headcount: 0, hour: '10 AM', };
      const zoneAreaEntranceExitDatas = allZoneAreaEntranceExitDatas.filter(d => d.zone === zone);
      for (const zoneAreaEntranceExitData of zoneAreaEntranceExitDatas) {
        this.configDataService.TIME_LIST.map(time => {
          const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
          if (!zoneAreaEntranceExitData || !zoneAreaEntranceExitData.data) {
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
            const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
            zoneAreaEntranceExitTrend.entrance.push(fillValue);
            zoneAreaEntranceExitTrend.exit.push(fillValue);
            return;
          }
          if (zoneAreaEntranceExitData.hour === timeKey) {
            zoneAreaEntranceExitTrend.entrance.push(GraphDataService.procesChartData(zoneAreaEntranceExitData.data.entrance, false, false));
            zoneAreaEntranceExitTrend.exit.push(GraphDataService.procesChartData(zoneAreaEntranceExitData.data.exit, false, false));
            if (busiestTime.headcount < zoneAreaEntranceExitData.data.entrance) {
              busiestTime.headcount = GraphDataService.procesChartData(zoneAreaEntranceExitData.data.entrance);
              busiestTime.hour = new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
            }
          }
        });
      }
      this.allZoneEntranceExitByHour[zone] = zoneAreaEntranceExitTrend;
      this.allZoneBusiestTime[zone] = busiestTime;
    }
    if (lockNum < this.allZoneAreaEntranceExitByHourLock) { return; }
    this.allZoneBusiestTime$.next(this.allZoneBusiestTime);
    this.allZoneEntranceExitByHour$.next(this.allZoneEntranceExitByHour);
  }

  async fetchAllZoneAreaEntranceExitByHourData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }

  //#endregion zone/area-entrance-exit-by-hour all-zone

  //#region zone/timespent all-zone
  async loadAllZoneTimespentData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchAllZoneTimespentData(date, ++graphDataServiceInstance.allZoneTimespentLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveAllZoneTimespentData(data, lockNum));
  }

  async deriveAllZoneTimespentData(allZonePlateTimespentDatas: IFetchData<PlateTimespentData>[], lockNum: number) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    for (const zone of zoneList) {
      this.allZoneTimespentData[zone] = {};
      const binNameSet = new Set<string>();
      const binNameList = this.configDataService.BIN_TIMESPENT_BY_PIN_LIST;
      const avgTimespentTimePairData: [number, number] = [0, 0];
      binNameList.forEach(binName => {
        binNameSet.add(binName);
      });
      const binNames = Array.from(binNameSet.values());
      const plateTimespentBinData: { [binName: string]: number } = binNames.reduce((prev, binName) => {
        prev[binName] = 0;
        return prev;
      }, {});
      if (Object.keys(plateTimespentBinData).length < 1) { return; }
      const zonePlateTimespentDatas = allZonePlateTimespentDatas.filter(d => d.zone === zone);
      GraphDataService.mapSevenDayLineChartData(zonePlateTimespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
        if (!dataFiltered || !dataFiltered.data) {
          return;
        }
        const plateTimespentData = dataFiltered.data;
        if (diffToSelectedDate === 0) {
          avgTimespentTimePairData[diffToSelectedDate + 1] = plateTimespentData._total.average_timespent;
          Object.keys(plateTimespentBinData).forEach(binKey => {
            if (!plateTimespentData[binKey]) {
              return;
            }
            plateTimespentBinData[binKey] = GraphDataService.procesChartData((plateTimespentData[binKey].count / plateTimespentData._total.count) * 100, false, false);
          });
        }
        if (diffToSelectedDate === -1) {
          avgTimespentTimePairData[diffToSelectedDate + 1] = plateTimespentData._total.average_timespent;
        }
      });
      this.allZoneTimespentData[zone] = plateTimespentBinData;
      const diffAvgTimespent = avgTimespentTimePairData[1] - avgTimespentTimePairData[0];
      const diffAvgTimespentPercentage = avgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / avgTimespentTimePairData[0]) * 100;
      this.allZoneAvgTimespentDataReID[zone] = {
        avgTimeSpent: GraphDataService.procesChartData(avgTimespentTimePairData[1], false, false),
        diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
        diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
      };
    }
    if (lockNum < this.allZoneTimespentLock) { return; }
    this.allZoneAvgTimespentDataReID$.next(this.allZoneAvgTimespentDataReID);
    this.allZoneTimespentData$.next(this.allZoneTimespentData);
  }

  async fetchAllZoneTimespentData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/timespent?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<PlateTimespentData>[], number];
  }
  //#endregion zone/timespent all-zone

  //#region zone/zone-synergy all-zone
  async loadAllZoneToZoneSynergyData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchAllZoneToZoneSynergyData(date, ++graphDataServiceInstance.allZoneToZoneSynergyLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveAllZoneToZoneSynergyData(data, lockNum));
  }

  deriveAllZoneToZoneSynergyData(allZoneToZoneSynergyDatas: IFetchData<GroupCountData>[], lockNum: number) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    for (const fromZone of zoneList) {
      this.allZoneToZoneSynergyData[fromZone] = {};
      for (const toZone of zoneList) {
        if (fromZone !== toZone) {
          this.allZoneToZoneSynergyData[fromZone][toZone] = 0;
        }
      }
    }
    for (const zone of zoneList) {
      const zoneToZoneSynergyBarChart: { [zoneName: string]: number } = {};
      const zoneToZoneSynergyDatas = allZoneToZoneSynergyDatas.filter(d => d.zone === zone);
      GraphDataService.mapSevenDayLineChartData(zoneToZoneSynergyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          return;
        }
        const buildingToZoneSynergyData = dataFiltered.data;
        for (const [zoneName, zoneData] of Object.entries(buildingToZoneSynergyData)) {
          zoneList.forEach(z => {
            if (zoneName !== '_total' && zoneName !== zone) {
              const countPercentage = (zoneData.count / buildingToZoneSynergyData._total.count) * 100;
              this.allZoneToZoneSynergyData[zone][zoneName] = GraphDataService.procesChartData(countPercentage, false, false);
              // zoneToZoneSynergyBarChart[zoneName] = GraphDataService.procesChartData(countPercentage, false, false);
            }
          });
        }
      });
      // this.allZoneToZoneSynergyData[zone] = zoneToZoneSynergyBarChart;
    }
    if (lockNum < this.allZoneToZoneSynergyLock) { return; }
    this.allZoneToZoneSynergyData$.next(this.allZoneToZoneSynergyData);
  }

  async fetchAllZoneToZoneSynergyData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/zone-synergy?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&building=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion zone/zone-synergy all-zone

  //#region zone/area-entrance-exit-by-pin all-pin
  async loadZoneAreaEntranceExitAllPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchZoneAreaEntranceExitAllPinData(date, ++graphDataServiceInstance.zoneAreaEntranceExitAllPinLock).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneAreaEntranceExitAllPinData(data, lockNum));
  }

  deriveZoneAreaEntranceExitAllPinData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const zoneAreaEntranceExitByPinBreakdown = { entrance: 0, exit: 0 };
    const entranceGroupOldAreaByPin = {};
    const exitGroupOldAreaByPin = {};

    for (const buildingAreaEntranceExitByPinData of buildingAreaEntranceExitByPinDatas) {
      if (!buildingAreaEntranceExitByPinData || !buildingAreaEntranceExitByPinData.data) {
        return;
      }
      entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = {};
      exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = {};
      entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData.data.entrance;
      exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData.data.exit;
    }
    if (lockNum < this.zoneAreaEntranceExitAllPinLock) { return; }
    this.allPinByZoneEntrance$.next(entranceGroupOldAreaByPin);
    this.allPinByZoneExit$.next(exitGroupOldAreaByPin);
  }

  async fetchZoneAreaEntranceExitAllPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion zone/area-entrance-exit-by-pin all-pin

  //#region zone/area-entrance-exit-by-pin average-by-day-type
  async loadZoneAreaEntranceExitByPinAvgByDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const selectedDirectory = graphDataServiceInstance.baseGraphData.selectedDirectory$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name || !selectedDirectory) {
      // clear data
      ++graphDataServiceInstance.zoneAreaEntranceExitByPinAvgByDayTypeLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const updatedDirectory = selectedDirectory?.zone === 'pavilion_000_g_001_2nd_reception' ? { building: 'pavilion', floor: 'pavilion_000_g_001_2nd', zone: 'pavilion_000_g_001_2nd_reception' } : selectedDirectory;
    return graphDataServiceInstance.fetchZoneAreaEntranceExitByPinAvgByDayTypeData(date, ++graphDataServiceInstance.zoneAreaEntranceExitByPinAvgByDayTypeLock, areaName, updatedDirectory).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneAreaEntranceExitByPinAvgByDayTypeData(data, lockNum));
  }

  deriveZoneAreaEntranceExitByPinAvgByDayTypeData(zoneAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const entranceWeekdayTimePairData: [number, number] = [0, 0];
    const exitWeekdayTimePairData: [number, number] = [0, 0];
    const entranceWeekendTimePairData: [number, number] = [0, 0];
    const exitWeekendTimePairData: [number, number] = [0, 0];
    if (zoneAreaEntranceExitByPinDatas.length > 0) {
      const zoneAreaEntranceExitByPinWeekdayDatas = zoneAreaEntranceExitByPinDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() < 6).sort((a, b) => new Date(b.iso_year, b.month, b.day).getTime() - new Date(a.iso_year, a.month, a.day).getTime());
      const zoneAreaEntranceExitByPinWeekendDatas = zoneAreaEntranceExitByPinDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() >= 6).sort((a, b) => new Date(b.iso_year, b.month, b.day).getTime() - new Date(a.iso_year, a.month, a.day).getTime());
      let indexWeekday = 0;
      let indexWeekend = 0;
      for (const zoneAreaEntranceExitByPinWeekdayData of zoneAreaEntranceExitByPinWeekdayDatas) {
        if (!zoneAreaEntranceExitByPinWeekdayData.data) {
          return;
        }
        const dataFiltered = zoneAreaEntranceExitByPinWeekdayData.data;
        const momentIt = moment(`${zoneAreaEntranceExitByPinWeekdayData.iso_year}-${zoneAreaEntranceExitByPinWeekdayData.month}-${zoneAreaEntranceExitByPinWeekdayData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (zoneAreaEntranceExitByPinWeekdayDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekdayTimePairData[1] = dataFiltered.entrance;
            exitWeekdayTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekdayTimePairData[0] = dataFiltered.entrance;
            exitWeekdayTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekdayTimePairData[indexWeekday] = dataFiltered.entrance;
          exitWeekdayTimePairData[indexWeekday] = dataFiltered.exit;
        }
        indexWeekday++;
      }
      for (const zoneAreaEntranceExitByPinWeekendData of zoneAreaEntranceExitByPinWeekendDatas) {
        if (!zoneAreaEntranceExitByPinWeekendData.data) {
          return;
        }
        const dataFiltered = zoneAreaEntranceExitByPinWeekendData.data;
        const momentIt = moment(`${zoneAreaEntranceExitByPinWeekendData.iso_year}-${zoneAreaEntranceExitByPinWeekendData.month}-${zoneAreaEntranceExitByPinWeekendData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (zoneAreaEntranceExitByPinWeekendDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekendTimePairData[1] = dataFiltered.entrance;
            exitWeekendTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekendTimePairData[0] = dataFiltered.entrance;
            exitWeekendTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekendTimePairData[indexWeekend] = dataFiltered.entrance;
          exitWeekendTimePairData[indexWeekend] = dataFiltered.exit;
        }
        indexWeekend++;
      }
    } else {
      this.currentZoneAvgWeekdayLast7DaysEntranceExitByPin$.next({
        entrance: {
          headCount: GraphDataService.procesChartData(0, false, false),
          diff: GraphDataService.procesChartData(0, true, true),
          diffPercent: GraphDataService.procesChartData(0, true, true)
        },
        exit: {
          headCount: GraphDataService.procesChartData(0, false, false),
          diff: GraphDataService.procesChartData(0, true, true),
          diffPercent: GraphDataService.procesChartData(0, true, true)
        }
      });
      this.currentZoneAvgWeekendLast7DaysEntranceExitByPin$.next({
        entrance: {
          headCount: GraphDataService.procesChartData(0, false, false),
          diff: GraphDataService.procesChartData(0, true, false),
          diffPercent: GraphDataService.procesChartData(0, true, true)
        },
        exit: {
          headCount: GraphDataService.procesChartData(0, false, false),
          diff: GraphDataService.procesChartData(0, true, false),
          diffPercent: GraphDataService.procesChartData(0, true, true)
        }
      });
    }
    // weekday
    const diffEntranceWeekday = entranceWeekdayTimePairData[1] - entranceWeekdayTimePairData[0];
    const diffEntranceWeekdayPercent = entranceWeekdayTimePairData[0] === 0 ? 0 : (diffEntranceWeekday / entranceWeekdayTimePairData[0]) * 100;
    const diffExitWeekday = exitWeekdayTimePairData[1] - exitWeekdayTimePairData[0];
    const diffExitWeekdayPercent = exitWeekdayTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekdayTimePairData[0]) * 100;
    // weekend
    const diffEntranceWeekend = entranceWeekendTimePairData[1] - entranceWeekendTimePairData[0];
    const diffEntranceWeekendPercent = entranceWeekendTimePairData[0] === 0 ? 0 : (diffEntranceWeekend / entranceWeekendTimePairData[0]) * 100;
    const diffExitWeekend = exitWeekendTimePairData[1] - exitWeekendTimePairData[0];
    const diffExitWeekendPercent = exitWeekendTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekendTimePairData[0]) * 100;
    if (lockNum < this.zoneAreaEntranceExitByPinAvgByDayTypeLock) { return; }
    this.currentZoneAvgWeekdayLast7DaysEntranceExitByPin$.next({
      entrance: {
        headCount: GraphDataService.procesChartData(entranceWeekdayTimePairData[1], false, false),
        diff: GraphDataService.procesChartData(diffEntranceWeekday, true, false),
        diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercent, true, true)
      },
      exit: {
        headCount: GraphDataService.procesChartData(exitWeekdayTimePairData[1], false, false),
        diff: GraphDataService.procesChartData(diffExitWeekday, true, false),
        diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercent, true, true)
      }
    });
    this.currentZoneAvgWeekendLast7DaysEntranceExitByPin$.next({
      entrance: {
        headCount: GraphDataService.procesChartData(entranceWeekendTimePairData[1], false, false),
        diff: GraphDataService.procesChartData(diffEntranceWeekend, true, false),
        diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercent, true, true)
      },
      exit: {
        headCount: GraphDataService.procesChartData(exitWeekendTimePairData[1], false, false),
        diff: GraphDataService.procesChartData(diffExitWeekend, true, false),
        diffPercent: GraphDataService.procesChartData(diffExitWeekendPercent, true, true)
      }
    });
  }

  async fetchZoneAreaEntranceExitByPinAvgByDayTypeData(date: moment.Moment, lockNum: number, area?: string, directory?: { building: string; floor: string; zone: string }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getSelectedQueryAvgByDayTypeParameter(date);
    const fetchURL = directory.zone !== null && directory.zone !== '' ? `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&aggregation_type=average&by_mode=by_day_type&zone=${directory.zone}`
      : `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&aggregation_type=average&by_mode=by_day_type`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion zone/area-entrance-exit-by-pin average-by-day-type

  //#region zone/area-entrance-exit all-zone average-by-day-type
  async loadAllZoneAreaEntranceExitAvgByDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const zoneList = Object.keys(graphDataServiceInstance.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    for (const zone of zoneList) {
      graphDataServiceInstance.allZoneAvgWeekdayLast7DaysEntranceExit[zone] = { entrance: { headCount: null, diff: null, diffPercent: null }, exit: { headCount: null, diff: null, diffPercent: null } };
      graphDataServiceInstance.allZoneAvgWeekendLast7DaysEntranceExit[zone] = { entrance: { headCount: null, diff: null, diffPercent: null }, exit: { headCount: null, diff: null, diffPercent: null } };
      graphDataServiceInstance.fetchAllZoneAreaEntranceExitAvgByDayTypeData(date, ++graphDataServiceInstance.allZoneAreaEntranceExitAvgByDayTypeLock, zone).then(([data, lockNum]) => graphDataServiceInstance.deriveAllZoneAreaEntranceExitAvgByDayTypeData(data, lockNum, zone));
    }
    return;
  }

  deriveAllZoneAreaEntranceExitAvgByDayTypeData(zoneAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number, area: string) {
    const entranceWeekdayTimePairData: [number, number] = [0, 0];
    const exitWeekdayTimePairData: [number, number] = [0, 0];
    const entranceWeekendTimePairData: [number, number] = [0, 0];
    const exitWeekendTimePairData: [number, number] = [0, 0];
    if (zoneAreaEntranceExitByPinDatas.length > 0) {
      const zoneAreaEntranceExitByPinWeekdayDatas = zoneAreaEntranceExitByPinDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() < 6).sort((a, b) => new Date(b.iso_year, b.month, b.day).getTime() - new Date(a.iso_year, a.month, a.day).getTime());
      const zoneAreaEntranceExitByPinWeekendDatas = zoneAreaEntranceExitByPinDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() >= 6).sort((a, b) => new Date(b.iso_year, b.month, b.day).getTime() - new Date(a.iso_year, a.month, a.day).getTime());
      let indexWeekday = 0;
      let indexWeekend = 0;
      for (const zoneAreaEntranceExitByPinWeekdayData of zoneAreaEntranceExitByPinWeekdayDatas) {
        if (!zoneAreaEntranceExitByPinWeekdayData.data) {
          return;
        }
        const dataFiltered = zoneAreaEntranceExitByPinWeekdayData.data;
        const momentIt = moment(`${zoneAreaEntranceExitByPinWeekdayData.iso_year}-${zoneAreaEntranceExitByPinWeekdayData.month}-${zoneAreaEntranceExitByPinWeekdayData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (zoneAreaEntranceExitByPinWeekdayDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekdayTimePairData[1] = dataFiltered.entrance;
            exitWeekdayTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekdayTimePairData[0] = dataFiltered.entrance;
            exitWeekdayTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekdayTimePairData[indexWeekday] = dataFiltered.entrance;
          exitWeekdayTimePairData[indexWeekday] = dataFiltered.exit;
        }
        indexWeekday++;
      }
      for (const zoneAreaEntranceExitByPinWeekendData of zoneAreaEntranceExitByPinWeekendDatas) {
        if (!zoneAreaEntranceExitByPinWeekendData.data) {
          return;
        }
        const dataFiltered = zoneAreaEntranceExitByPinWeekendData.data;
        const momentIt = moment(`${zoneAreaEntranceExitByPinWeekendData.iso_year}-${zoneAreaEntranceExitByPinWeekendData.month}-${zoneAreaEntranceExitByPinWeekendData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (zoneAreaEntranceExitByPinWeekendDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekendTimePairData[1] = dataFiltered.entrance;
            exitWeekendTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekendTimePairData[0] = dataFiltered.entrance;
            exitWeekendTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekendTimePairData[indexWeekend] = dataFiltered.entrance;
          exitWeekendTimePairData[indexWeekend] = dataFiltered.exit;
        }
        indexWeekend++;
      }
    }
    // weekday
    const diffEntranceWeekday = entranceWeekdayTimePairData[1] - entranceWeekdayTimePairData[0];
    const diffEntranceWeekdayPercent = entranceWeekdayTimePairData[0] === 0 ? 0 : (diffEntranceWeekday / entranceWeekdayTimePairData[0]) * 100;
    const diffExitWeekday = exitWeekdayTimePairData[1] - exitWeekdayTimePairData[0];
    const diffExitWeekdayPercent = exitWeekdayTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekdayTimePairData[0]) * 100;
    // weekend
    const diffEntranceWeekend = entranceWeekendTimePairData[1] - entranceWeekendTimePairData[0];
    const diffEntranceWeekendPercent = entranceWeekendTimePairData[0] === 0 ? 0 : (diffEntranceWeekend / entranceWeekendTimePairData[0]) * 100;
    const diffExitWeekend = exitWeekendTimePairData[1] - exitWeekendTimePairData[0];
    const diffExitWeekendPercent = exitWeekendTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekendTimePairData[0]) * 100;
    this.allZoneAvgWeekdayLast7DaysEntranceExit[area] = {
      entrance: { headCount: GraphDataService.procesChartData(entranceWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercent, true, true) },
      exit: { headCount: GraphDataService.procesChartData(exitWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercent, true, true) }
    };
    this.allZoneAvgWeekendLast7DaysEntranceExit[area] = {
      entrance: { headCount: GraphDataService.procesChartData(entranceWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercent, true, true) },
      exit: { headCount: GraphDataService.procesChartData(exitWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekendPercent, true, true) }
    };
    this.currentAllZoneAvgWeekdayLast7DaysEntranceExit$.next(this.allZoneAvgWeekdayLast7DaysEntranceExit);
    this.currentAllZoneAvgWeekendLast7DaysEntranceExit$.next(this.allZoneAvgWeekendLast7DaysEntranceExit);
    if (lockNum < this.allZoneAreaEntranceExitAvgByDayTypeLock) { return; }
  }

  async fetchAllZoneAreaEntranceExitAvgByDayTypeData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getSelectedQueryAvgByDayTypeParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/area-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&aggregation_type=average&by_mode=by_day_type`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion zone/area-entrance-exit-by-pin average-by-day-type

  //#region building/area-entrance-exit-by-pin all-pin
  async loadBuildingAreaEntranceExitAllPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitAllPinData(date, ++graphDataServiceInstance.buildingAreaEntranceExitAllPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitAllPinData(data, lockNum, areaName));
  }

  deriveBuildingAreaEntranceExitAllPinData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number, floor?: string) {
    const entranceGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const exitGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const entranceAllPin: { [pinName: string]: number } = {};
    const exitAllPin: { [pinName: string]: number } = {};
    const floorEntranceExitByPinData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: { entrance: number; exit: number } } } } = {};
    const buildingEntranceExitByPinData: { [buildingName: string]: { [pinName: string]: { entrance: number; exit: number } } } = {};
    const floorEntranceExitData: { [buildingName: string]: { [floorName: string]: { entrance: number; exit: number } } } = {};
    // const filteredData = buildingAreaEntranceExitByPinDatas;
    // const mainBuilding = this.configDataService.MAIN_BUILDING;
    // const excludeArea: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const buildingNameList = Object.keys(this.configDataService.FLOOR_OBJECTS);
    for (const buildingName of buildingNameList) {
      entranceGroupOldAreaByPin[buildingName] = {};
      exitGroupOldAreaByPin[buildingName] = {};
      buildingEntranceExitByPinData[buildingName] = {};
    }
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      floorEntranceExitByPinData[buildingName] = {};
      floorEntranceExitData[buildingName] = {};
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        floorEntranceExitByPinData[buildingName][floorName] = {};
        if (floorData.gates.length > 0) {
          floorEntranceExitData[buildingName][floorName] = { entrance: 0, exit: 0 };
        }
        for (const pinName of floorData.gates) {
          buildingEntranceExitByPinData[buildingName][pinName] = { entrance: 0, exit: 0 };
          floorEntranceExitByPinData[buildingName][floorName][pinName] = { entrance: 0, exit: 0 };
        }
      }
    }
    for (const buildingAreaEntranceExitByPinData of buildingAreaEntranceExitByPinDatas) {
      if (!buildingAreaEntranceExitByPinData || !buildingAreaEntranceExitByPinData.data) {
        return;
      }
      if (buildingNameList.includes(buildingAreaEntranceExitByPinData.building)) {
        if (this.configDataService.isFeatureEnabled('exclude_area')) {
          if (buildingAreaEntranceExitByPinData.building !== this.configDataService.isFeatureEnabled('exclude_area')) {
            entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.entrance || 0);
            exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.exit || 0);
            // entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
            // exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0;
          }
        } else {
          entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.entrance || 0);
          exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.exit || 0);
          // entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
          // exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0; 
        }
      }
    }
    // const pinList = Object.keys(entranceGroupOldAreaByPin);
    // const floorObjects = this.configDataService.FLOOR_OBJECTS[mainBuilding];
    const floorEntranceData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    const floorExitData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      floorEntranceData[buildingName] = {};
      floorExitData[buildingName] = {};
      buildingEntranceExitByPinData[buildingName] = {};
      const sumAllPinByBuilding: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        const sumAllPinByFloor: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
        if (floorData.gates.length > 0) {
          floorEntranceData[buildingName][floorName] = {};
          floorExitData[buildingName][floorName] = {};
          for (const pinName of Object.keys(entranceGroupOldAreaByPin?.[buildingName] || {})) {
            buildingEntranceExitByPinData[buildingName][pinName] = { entrance: 0, exit: 0 };
            buildingEntranceExitByPinData[buildingName][pinName].entrance = processChartData(entranceGroupOldAreaByPin[buildingName][pinName]);
            buildingEntranceExitByPinData[buildingName][pinName].exit = processChartData(exitGroupOldAreaByPin[buildingName][pinName]);
            if (this.configDataService.isFeatureEnabled('graph_data', 'contribute_area')) {
              entranceAllPin[pinName] = entranceGroupOldAreaByPin[buildingName][pinName];
              exitAllPin[pinName] = exitGroupOldAreaByPin[buildingName][pinName];
            }
            if (floorData.gates.find(gate => gate === pinName)) {
              sumAllPinByBuilding.entrance += entranceGroupOldAreaByPin[buildingName][pinName];
              sumAllPinByBuilding.exit += exitGroupOldAreaByPin[buildingName][pinName];
              sumAllPinByFloor.entrance += entranceGroupOldAreaByPin[buildingName][pinName];
              sumAllPinByFloor.exit += exitGroupOldAreaByPin[buildingName][pinName];
              floorEntranceExitByPinData[buildingName][floorName][pinName].entrance = entranceGroupOldAreaByPin[buildingName][pinName];
              floorEntranceExitByPinData[buildingName][floorName][pinName].exit = exitGroupOldAreaByPin[buildingName][pinName];
              floorEntranceData[buildingName][floorName][pinName] = entranceGroupOldAreaByPin[buildingName][pinName];
              floorExitData[buildingName][floorName][pinName] = exitGroupOldAreaByPin[buildingName][pinName];
            }
            floorEntranceExitByPinData[buildingName][floorName]._total = sumAllPinByFloor;
            floorEntranceExitData[buildingName][floorName] = sumAllPinByFloor;
          }
        }
        buildingEntranceExitByPinData[buildingName]._total = sumAllPinByBuilding;
      }
    }
    if (this.configDataService.isFeatureEnabled('graph_data', 'contribute_area_list')) {
      if (this.configDataService.isFeatureEnabled('graph_data', 'contribute_area_list')?.includes('building')) {
        this.allPinByBuildingEntrance$.next(entranceAllPin);
        this.allPinByBuildingExit$.next(exitAllPin);
        this.entranceFloorPin$.next(floorEntranceData);
        this.exitFloorPin$.next(floorExitData);
        this.floorEntranceExitPin$.next(floorEntranceExitByPinData);
      }
    }
    if (this.configDataService.isFeatureEnabled('heatmap_v2', 'contribute_level') === 'building') {
      this.allPinByBuildingEntrance$.next(entranceAllPin);
      this.allPinByBuildingExit$.next(exitAllPin);
      this.entranceFloorPin$.next(floorEntranceData);
      this.exitFloorPin$.next(floorExitData);
      this.floorEntranceExitPin$.next(floorEntranceExitByPinData);
    }

    // this.allPinByBuildingEntrance$.next(entranceAllPin);
    // this.allPinByBuildingExit$.next(exitAllPin);
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN).value) {
      this.buildingEntranceExitByPinAvgPerDayData$.next(buildingEntranceExitByPinData);
      this.buildingFloorEntranceExitGroupbyPinAvgPerDay$.next(floorEntranceExitData);

    } else {
      this.buildingEntranceExitByPinData$.next(buildingEntranceExitByPinData);
    }
    this.buildingFloorEntranceExitGroupbyPin$.next(floorEntranceExitData);
    this.entranceBuildingPin$.next(floorEntranceData);
    this.exitBuildingPin$.next(floorExitData);
    this.baseGraphData.selectedDirectory$.subscribe(selectDirectory => {
      if (!selectDirectory) { return; }
      if (this.configDataService.isFeatureEnabled('multiple_organization') && selectDirectory?.floor !== 'onesiam_plus_007_5f' && selectDirectory?.zone !== 'siam_paragon') {
        this.allPinByBuildingEntrance$.next(entranceAllPin);
        this.allPinByBuildingExit$.next(exitAllPin);
      }
    });

  }

  async fetchBuildingAreaEntranceExitAllPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN).value) {
      const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
      if (agg_type !== undefined) {
        fetchURL += `&aggregation_type=${agg_type}`;
      }
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion building/area-entrance-exit-by-pin all-pin

  //#region building/area-entrance-exit-by-pin all-pin daily average
  async loadBuildingAreaEntranceExitAllPinAvgData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitAllPinAvgData(date, ++graphDataServiceInstance.buildingEntranceExitFloorPinHourAvgLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitAllPinAvgData(data, lockNum, areaName));
  }

  deriveBuildingAreaEntranceExitAllPinAvgData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number, floor?: string) {
    const entranceGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const exitGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const entranceAllPin: { [pinName: string]: number } = {};
    const exitAllPin: { [pinName: string]: number } = {};
    const floorEntranceExitByPinData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: { entrance: number; exit: number } } } } = {};
    const buildingEntranceExitByPinData: { [buildingName: string]: { [pinName: string]: { entrance: number; exit: number } } } = {};
    const floorEntranceExitData: { [buildingName: string]: { [floorName: string]: { entrance: number; exit: number } } } = {};
    // const filteredData = buildingAreaEntranceExitByPinDatas;
    // const mainBuilding = this.configDataService.MAIN_BUILDING;
    // const excludeArea: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const buildingNameList = Object.keys(this.configDataService.FLOOR_OBJECTS);
    for (const buildingName of buildingNameList) {
      entranceGroupOldAreaByPin[buildingName] = {};
      exitGroupOldAreaByPin[buildingName] = {};
      buildingEntranceExitByPinData[buildingName] = {};
    }
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      floorEntranceExitByPinData[buildingName] = {};
      floorEntranceExitData[buildingName] = {};
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        if (floorData.gates.length > 0) {
          floorEntranceExitData[buildingName][floorName] = { entrance: 0, exit: 0 };
        }
        floorEntranceExitByPinData[buildingName][floorName] = {};
        for (const pinName of floorData.gates) {
          buildingEntranceExitByPinData[buildingName][pinName] = { entrance: 0, exit: 0 };
          floorEntranceExitByPinData[buildingName][floorName][pinName] = { entrance: 0, exit: 0 };
        }
      }
    }
    for (const buildingAreaEntranceExitByPinData of buildingAreaEntranceExitByPinDatas) {
      if (!buildingAreaEntranceExitByPinData || !buildingAreaEntranceExitByPinData.data) {
        return;
      }
      if (buildingNameList.includes(buildingAreaEntranceExitByPinData.building)) {
        if (this.configDataService.isFeatureEnabled('exclude_area')) {
          if (buildingAreaEntranceExitByPinData.building !== this.configDataService.isFeatureEnabled('exclude_area')) {
            entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.entrance || 0);
            exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.exit || 0);
            // entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
            // exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0;
          }
        } else {
          entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.entrance || 0);
          exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = processChartData(buildingAreaEntranceExitByPinData?.data?.exit || 0);
          // entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
          // exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0; 
        }
      }
    }
    // const pinList = Object.keys(entranceGroupOldAreaByPin);
    // const floorObjects = this.configDataService.FLOOR_OBJECTS[mainBuilding];
    const floorEntranceData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    const floorExitData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      floorEntranceData[buildingName] = {};
      floorExitData[buildingName] = {};
      buildingEntranceExitByPinData[buildingName] = {};
      const sumAllPinByBuilding: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        const sumAllPinByFloor: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
        if (floorData.gates.length > 0) {
          floorEntranceData[buildingName][floorName] = {};
          floorExitData[buildingName][floorName] = {};
          for (const pinName of Object.keys(entranceGroupOldAreaByPin?.[buildingName] || {})) {
            buildingEntranceExitByPinData[buildingName][pinName] = { entrance: 0, exit: 0 };
            buildingEntranceExitByPinData[buildingName][pinName].entrance = processChartData(entranceGroupOldAreaByPin[buildingName][pinName]);
            buildingEntranceExitByPinData[buildingName][pinName].exit = processChartData(exitGroupOldAreaByPin[buildingName][pinName]);
            if (this.configDataService.isFeatureEnabled('graph_data', 'contribute_area')) {
              entranceAllPin[pinName] = entranceGroupOldAreaByPin[buildingName][pinName];
              exitAllPin[pinName] = exitGroupOldAreaByPin[buildingName][pinName];
            }
            if (floorData.gates.find(gate => gate === pinName)) {
              sumAllPinByBuilding.entrance += entranceGroupOldAreaByPin[buildingName][pinName];
              sumAllPinByBuilding.exit += exitGroupOldAreaByPin[buildingName][pinName];
              sumAllPinByFloor.entrance += entranceGroupOldAreaByPin[buildingName][pinName];
              sumAllPinByFloor.exit += exitGroupOldAreaByPin[buildingName][pinName];
              floorEntranceExitByPinData[buildingName][floorName][pinName].entrance = entranceGroupOldAreaByPin[buildingName][pinName];
              floorEntranceExitByPinData[buildingName][floorName][pinName].exit = exitGroupOldAreaByPin[buildingName][pinName];
              floorEntranceData[buildingName][floorName][pinName] = entranceGroupOldAreaByPin[buildingName][pinName];
              floorExitData[buildingName][floorName][pinName] = exitGroupOldAreaByPin[buildingName][pinName];
            }
            floorEntranceExitByPinData[buildingName][floorName]._total = sumAllPinByFloor;
            floorEntranceExitData[buildingName][floorName] = sumAllPinByFloor;
          }
        }

        buildingEntranceExitByPinData[buildingName]._total = sumAllPinByBuilding;
      }
    }
    if (this.configDataService.isFeatureEnabled('graph_data', 'contribute_area_list')) {
      if (this.configDataService.isFeatureEnabled('graph_data', 'contribute_area_list')?.includes('building')) {
        this.allPinByBuildingEntrance$.next(entranceAllPin);
        this.allPinByBuildingExit$.next(exitAllPin);
        this.entranceFloorPin$.next(floorEntranceData);
        this.exitFloorPin$.next(floorExitData);
        this.floorEntranceExitPin$.next(floorEntranceExitByPinData);
      }
    }
    if (this.configDataService.isFeatureEnabled('heatmap_v2', 'contribute_level') === 'building') {
      this.allPinByBuildingEntrance$.next(entranceAllPin);
      this.allPinByBuildingExit$.next(exitAllPin);
      this.entranceFloorPin$.next(floorEntranceData);
      this.exitFloorPin$.next(floorExitData);
      this.floorEntranceExitPin$.next(floorEntranceExitByPinData);
    }
    this.buildingEntranceExitByPinAvgPerDayData$.next(buildingEntranceExitByPinData);
    this.buildingFloorEntranceExitGroupbyPinAvgPerDay$.next(floorEntranceExitData);
  }

  async fetchBuildingAreaEntranceExitAllPinAvgData(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
    if (agg_type !== undefined) {
      fetchURL += `&aggregation_type=${agg_type}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion building/area-entrance-exit-by-pin all-pin daily average

  //#region floor/area-entrance-exit-by-pin all-pin
  async loadFloorAreaEntranceExitAllPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchFloorAreaEntranceExitAllPinData(date, ++graphDataServiceInstance.floorAreaEntranceExitAllPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveFloorAreaEntranceExitAllPinData(data, lockNum, areaName));
  }

  deriveFloorAreaEntranceExitAllPinData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number, floor?: string) {
    const entranceGroupOldAreaByPin = {};
    const exitGroupOldAreaByPin = {};

    for (const buildingAreaEntranceExitByPinData of buildingAreaEntranceExitByPinDatas) {
      if (!buildingAreaEntranceExitByPinData || !buildingAreaEntranceExitByPinData.data) {
        return;
      }
      entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = {};
      exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = {};
      entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData.data.entrance;
      exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData.data.exit;
    }
    if (lockNum < this.floorAreaEntranceExitAllPinLock) { return; }
    this.allPinByFloorEntrance$.next(entranceGroupOldAreaByPin);
    this.allPinByFloorExit$.next(exitGroupOldAreaByPin);
  }

  async fetchFloorAreaEntranceExitAllPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/floor/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion building/area-entrance-exit-by-pin all-pin

  //#region zone/area-entrance-exit-by-pin all-zone
  /**
   * @deprecated using zone/area-entrance-exit-by-pin all-pin instend
   */
  async loadAllZoneAreaEntranceExitAllPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchAllZoneAreaEntranceExitAllPinData(date, ++graphDataServiceInstance.allZoneAreaEntranceExitAllPinLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveAllZoneAreaEntranceExitAllPinData(data, lockNum));
  }

  deriveAllZoneAreaEntranceExitAllPinData(allZoneAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    for (const zone of zoneList) {
      this.allPinByAllZoneEntrance[zone] = {};
      this.allPinByAllZoneExit[zone] = {};
      const entranceGroupOldAreaByPin = {};
      const exitGroupOldAreaByPin = {};
      const zoneAreaAvgTimespentTimePairData: [number, number] = [0, 0];
      const zoneAreaEntranceExitByPinDatas = allZoneAreaEntranceExitByPinDatas.filter(d => d.zone === zone);
      for (const buildingAreaEntranceExitByPinData of zoneAreaEntranceExitByPinDatas) {
        if (!buildingAreaEntranceExitByPinData || !buildingAreaEntranceExitByPinData.data) {
          return;
        }
        entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = {};
        exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = {};
        entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.data.entrance, false, false);
        exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.pin] = GraphDataService.procesChartData(buildingAreaEntranceExitByPinData.data.exit, false, false);
      }
      this.allPinByAllZoneEntrance[zone] = entranceGroupOldAreaByPin;
      this.allPinByAllZoneExit[zone] = exitGroupOldAreaByPin;
    }
    if (lockNum < this.zoneAreaEntranceExitAllPinLock) { return; }
    this.allPinByAllZoneEntrance$.next(this.allPinByAllZoneEntrance);
    this.allPinByAllZoneExit$.next(this.allPinByAllZoneExit);
  }

  async fetchAllZoneAreaEntranceExitAllPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion zone/area-entrance-exit-by-pin all-zone

  //#region building/timespent
  async loadBuildingTimespentData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchBuildingTimespentData(date, ++graphDataServiceInstance.buildingTimespentLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingTimespentData(data, lockNum));
  }

  async deriveBuildingTimespentData(plateTimespentDatas: IFetchData<PlateTimespentData>[], lockNum: number) {
    await this.configDataService.loadAppConfig();
    const binNameSet = new Set<string>();
    const binNameList = this.configDataService?.BIN_TIMESPENT_LIST ?? [];
    const avgTimespentTimePairData: [number, number] = [0, 0];
    const avgTimespentTrendData: number[] = [];
    binNameList.forEach(binName => {
      binNameSet.add(binName);
    });
    const binNames = Array.from(binNameSet.values());
    const plateTimespentBinData: { [binName: string]: number } = binNames.reduce((prev, binName) => {
      prev[binName] = 0;
      return prev;
    }, {});
    if (Object.keys(plateTimespentBinData).length < 1) {
      this.buildingTimespentBinData$.next(null);
      return;
    }
    GraphDataService.mapSevenDayLineChartData(plateTimespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        const fillValue = isPred || diffToSelectedDate > 0 ? null : 0;
        avgTimespentTrendData.push(fillValue);
        return;
      }
      const plateTimespentData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        avgTimespentTimePairData[diffToSelectedDate + 1] = plateTimespentData._total.average_timespent;
        Object.keys(plateTimespentBinData).forEach(binKey => {
          if (!plateTimespentData[binKey]) {
            return;
          }
          plateTimespentBinData[binKey] = GraphDataService.procesChartData((plateTimespentData[binKey].count / plateTimespentData._total.count) * 100, false, false);
        });
      }
      if (diffToSelectedDate === -1) {
        avgTimespentTimePairData[diffToSelectedDate + 1] = plateTimespentData._total.average_timespent;
      }
      avgTimespentTrendData.push(plateTimespentData._total.average_timespent);
    });
    const diffAvgTimespent = avgTimespentTimePairData[1] - avgTimespentTimePairData[0];
    const diffAvgTimespentPercentage = avgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / avgTimespentTimePairData[0]) * 100;
    if (lockNum < this.buildingTimespentLock) { return; }
    this.buildingTimespentBinData$.next(plateTimespentBinData);
    this.buildingAvgTimespentData$.next({
      current: GraphDataService.procesChartData(avgTimespentTimePairData[1], false, false),
      diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
      diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
    });
    this.avgTimespentTimePairData$.next(avgTimespentTimePairData);
    const buildingAvgTimepsentTrendData = { [this.configDataService.MAIN_BUILDING]: avgTimespentTrendData };
    this.buildingAvgTimespentTrendData$.next(buildingAvgTimepsentTrendData);
  }

  async fetchBuildingTimespentData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/timespent?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<PlateTimespentData>[], number];
  }
  //#endregion building/timespent

  //#region building/zone-synergy
  async loadBuildingToZoneSynergyData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchBuildingToZoneSynergyData(date, ++graphDataServiceInstance.buildingZoneSynergyLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToZoneSynergyData(data, lockNum));
  }

  deriveBuildingToZoneSynergyData(buildingToZoneSynergyDatas: IFetchData<GroupCountData>[], lockNum: number) {
    const buildingToZoneSynergyBarChart: { [zoneName: string]: number } = {};
    const buildingToZoneSynergyBarChartRaw: { [zoneName: string]: number } = {};
    const buildingToZoneSynergyRaw: { [zoneName: string]: number } = {};
    // const currentBuildingEntranceExitData = this.currentBuildingHeadCountData$.getValue();
    // const mainBuilding = this.configDataService.MAIN_BUILDING;
    // const currentMainBuildingEntranceData = currentBuildingEntranceExitData?.[mainBuilding]?.entrance?.headCount || 0;
    GraphDataService.mapSevenDayLineChartData(buildingToZoneSynergyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const buildingToZoneSynergyData = dataFiltered.data;
      for (const [zoneName, zoneData] of Object.entries(buildingToZoneSynergyData)) {
        if (zoneName !== '_total') {
          const countPercentage = zoneData.count / buildingToZoneSynergyData._total.count;
          // const roundedPercentage = Math.round(countPercentage * 100) / 100;
          buildingToZoneSynergyBarChart[zoneName] = GraphDataService.procesChartData(countPercentage * 100, false, false);
          buildingToZoneSynergyRaw[zoneName] = GraphDataService.procesChartData(countPercentage, false, true);
        }
      }
    });
    if (lockNum < this.buildingZoneSynergyLock) { return; }
    this.currentBuildingHeadCountData$.subscribe(currentBuildingHeadCountData => {
      if (!currentBuildingHeadCountData) {
        return;
      }
      const mainBuilding = this.configDataService.MAIN_BUILDING;
      const currentBuildingHeadCount = currentBuildingHeadCountData[mainBuilding].entrance.headCount;
      for (const [zoneName, zoneData] of Object.entries(buildingToZoneSynergyRaw)) {
        buildingToZoneSynergyBarChartRaw[zoneName] = GraphDataService.procesChartData(zoneData * currentBuildingHeadCount, false, false);
      }
      this.buildingZoneSynergyRaw$.next(Object.entries(buildingToZoneSynergyBarChartRaw).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    });
    this.buildingZoneSynergy$.next(Object.entries(buildingToZoneSynergyBarChart).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
  }

  async fetchBuildingToZoneSynergyData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/zone-synergy?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&building=${area}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion building/zone-synergy

  //#region building/visitor-profile profile-cross-level=4
  async loadBuildingVisitorProfileCrossLevelFourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedVistiorProfile = graphDataServiceInstance.selectedVisitorProfile$.getValue();
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('customer_page', 'profile_data_v2')) {
      if (!selectedVistiorProfile) {
        return graphDataServiceInstance.fetchBuildingVisitorProfileCrossLevelFourData(date, ++graphDataServiceInstance.visitorProfileCrossLevelFourLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingVisitorProfileCrossLevelFourData(data, lockNum));
      }
      return graphDataServiceInstance.fetchBuildingVisitorProfileCrossLevelFourData(date, ++graphDataServiceInstance.visitorProfileCrossLevelFourLock, area).then(([data, _lockNum]) => graphDataServiceInstance.deriveBuildingSelectedVisitorProfileCrossLevelFourData(data, selectedVistiorProfile));
    }
    return;
  }

  async deriveBuildingVisitorProfileCrossLevelFourData(visitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const customSegmentation = ['young_adults', 'teenagers', 'white'];
    const EthnicityProfilePurchaseTrue: { young_adults: number; teenagers: number; white: number } = { young_adults: 0, teenagers: 0, white: 0 };
    const EthnicityProfilePurchaseFalse: { young_adults: number; teenagers: number; white: number } = { young_adults: 0, teenagers: 0, white: 0 };
    const EthnicityProfilePurchaseRate: number[] = [];
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || dataFiltered.data.length < 1 || isPred) {
        return;
      }
      const visitorProfileData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        visitorProfileData.forEach(profile => {
          if (compare1DepthObjects(profile.group, { purchase: true, ethnicity: 'asian', age: 'young_adults' })) {
            EthnicityProfilePurchaseTrue.young_adults = GraphDataService.procesChartData(profile.entrance);
          }
          if (compare1DepthObjects(profile.group, { purchase: true, ethnicity: 'asian', age: 'teenagers' })) {
            EthnicityProfilePurchaseTrue.teenagers = GraphDataService.procesChartData(profile.entrance);
          }
          if (compare1DepthObjects(profile.group, { purchase: true, ethnicity: 'white' })) {
            EthnicityProfilePurchaseTrue.white = GraphDataService.procesChartData(profile.entrance);
          }
          if (compare1DepthObjects(profile.group, { purchase: false, ethnicity: 'asian', age: 'young_adults' })) {
            EthnicityProfilePurchaseFalse.young_adults = GraphDataService.procesChartData(profile.entrance);
          }
          if (compare1DepthObjects(profile.group, { purchase: false, ethnicity: 'asian', age: 'teenagers' })) {
            EthnicityProfilePurchaseFalse.teenagers = GraphDataService.procesChartData(profile.entrance);
          }
          if (compare1DepthObjects(profile.group, { purchase: false, ethnicity: 'white' })) {
            EthnicityProfilePurchaseFalse.white = GraphDataService.procesChartData(profile.entrance);
          }

        });
      }
    });
    if (lockNum < this.visitorProfileTwoCrossLock) { return; }
    this.ethnicityCrossProfilelevelFourPurchaseRate$.next(EthnicityProfilePurchaseRate);
    this.unfilteredBuildingVisitorProfileData$.next(visitorProfileDatas);
    if (lockNum < this.visitorProfileCrossLevelFourLock) { return; }
  }

  async deriveBuildingSelectedVisitorProfileCrossLevelFourData(visitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const trafficTrendLineChartData: number[] = [];
    const currentVisitorTrafficTrendtPair: [number, number] = [0, 0];
    const netShoppingTimeProfileChartData: number[] = [];
    const currentNetShoppingTimeProfilePair: [number, number] = [0, 0];
    const averageTimeSpentProfileChartData: number[] = [];
    const currentAverageTimeSpentProfilePair: [number, number] = [0, 0];
    const purchaseRateProfileData: number[] = [];
    const currentPurchaseRateProfilePair: [number, number] = [0, 0];
    const customSegmentation = ['young_adults', 'teenagers', 'white'];
    const EthnicityProfilePurchaseTrue: { young_adults: number; teenagers: number; white: number } = { young_adults: 0, teenagers: 0, white: 0 };
    const EthnicityProfilePurchaseFalse: { young_adults: number; teenagers: number; white: number } = { young_adults: 0, teenagers: 0, white: 0 };
    const EthnicityProfilePurchaseRate: number[] = [];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    const selectedProfilefilterWithPurchase = { ...selectedProfilefilter, purchase: true };
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred || diffToSelectedDate > 0) {
        const fillValue = isPred || diffToSelectedDate > 0 ? null : 0;
        trafficTrendLineChartData.push(fillValue);
        netShoppingTimeProfileChartData.push(fillValue);
        averageTimeSpentProfileChartData.push(fillValue);
        purchaseRateProfileData.push(fillValue);
        return;
      }
      let isFound = false;
      let isFoundWithPurchase = false;
      const visitorProfileData = dataFiltered.data;
      let allProfileExit = 0;
      for (const profileData of visitorProfileData) {
        if (compare1DepthObjects(profileData.group, { purchase: true, ethnicity: 'asian', age: 'young_adults' })) {
          EthnicityProfilePurchaseTrue.young_adults = GraphDataService.procesChartData(profileData.entrance);
        }
        if (compare1DepthObjects(profileData.group, { purchase: true, ethnicity: 'asian', age: 'teenagers' })) {
          EthnicityProfilePurchaseTrue.teenagers = GraphDataService.procesChartData(profileData.entrance);
        }
        if (compare1DepthObjects(profileData.group, { purchase: true, ethnicity: 'white' })) {
          EthnicityProfilePurchaseTrue.white = GraphDataService.procesChartData(profileData.entrance);
        }
        if (compare1DepthObjects(profileData.group, { purchase: false, ethnicity: 'asian', age: 'young_adults' })) {
          EthnicityProfilePurchaseFalse.young_adults = GraphDataService.procesChartData(profileData.entrance);
        }
        if (compare1DepthObjects(profileData.group, { purchase: false, ethnicity: 'asian', age: 'teenagers' })) {
          EthnicityProfilePurchaseFalse.teenagers = GraphDataService.procesChartData(profileData.entrance);
        }
        if (compare1DepthObjects(profileData.group, { purchase: false, ethnicity: 'white' })) {
          EthnicityProfilePurchaseFalse.white = GraphDataService.procesChartData(profileData.entrance);
        }
        if (compare1DepthObjects(profileData.group, {})) {
          allProfileExit = profileData.exit;
        }
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficTrendLineChartData.push(profileData.entrance);
          netShoppingTimeProfileChartData.push(profileData.net_shopping_time);
          averageTimeSpentProfileChartData.push(profileData.average_timespent);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentVisitorTrafficTrendtPair[diffToSelectedDate + 1] = profileData.entrance;
            currentAverageTimeSpentProfilePair[diffToSelectedDate + 1] = profileData.average_timespent;
            currentNetShoppingTimeProfilePair[diffToSelectedDate + 1] = profileData.net_shopping_time;
          }
        }
        if (compare1DepthObjects(profileData.group, selectedProfilefilterWithPurchase)) {
          isFoundWithPurchase = true;
          const purchaseRate = (profileData.exit / allProfileExit) * 100;
          purchaseRateProfileData.push(purchaseRate);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentPurchaseRateProfilePair[diffToSelectedDate + 1] = purchaseRate;
          }
        }
      }
      if (!isFound) {
        trafficTrendLineChartData.push(0);
        netShoppingTimeProfileChartData.push(0);
        averageTimeSpentProfileChartData.push(0);
      }
      if (!isFoundWithPurchase) {
        purchaseRateProfileData.push(0);
      }
    });
    customSegmentation.map(ethnicity => {
      const rate = Math.round((EthnicityProfilePurchaseTrue[ethnicity] / (EthnicityProfilePurchaseTrue[ethnicity] + EthnicityProfilePurchaseFalse[ethnicity])) * 100);
      EthnicityProfilePurchaseRate.push(rate);
    });
    this.ethnicityCrossProfilelevelFourPurchaseRate$.next(EthnicityProfilePurchaseRate);
    this.visitorTrafficTrendData$.next(GraphDataService.procesChartData(trafficTrendLineChartData));
    this.currentVisitorTrafficTrendtData$.next({
      headCount: GraphDataService.procesChartData(currentVisitorTrafficTrendtPair[1]),
      diff: GraphDataService.procesChartData(Math.round(currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]), true),
      diffPercent: GraphDataService.procesChartData(((currentVisitorTrafficTrendtPair[1] - currentVisitorTrafficTrendtPair[0]) / currentVisitorTrafficTrendtPair[0] * 100) || 0, true, true)
    });
    // using re-id timespent building data + profile timespent building data (for now)
    if (this.configDataService.isFeatureEnabled('reid_timespent')) {
      if (Object.keys(selectedProfilefilter).length === 0) {
        this.currentNetVisitorHourData$.subscribe(currentNetVisitorHourData => {
          if (!currentNetVisitorHourData) { return; }
          this.currentNetShoppingTimeData$.next({
            net_shopping_time: currentNetVisitorHourData.netVisitorTime * (60 * 60),
            diff: currentNetVisitorHourData.diff * (60 * 60),
            diffPercent: currentNetVisitorHourData.diffPercent
          });
        });
        const tempAvgTimespentData = this.buildingAvgTimespentData$.getValue();
        const tempAvgTimespentTrendData = this.buildingAvgTimespentTrendData$.getValue();
        // const tempNetShoppingTimeData = this.currentNetVisitorHourData$.getValue();
        // const tempNetShoppingTimeTrendData = this.buildingAvgTimespentTrendData$.getValue();
        this.averageTimeSpentChartData$.next(tempAvgTimespentTrendData);
        this.currentAverageTimeSpentData$.next({
          avgTimeSpent: GraphDataService.procesChartData(tempAvgTimespentData.current, false, true),
          diff: GraphDataService.procesChartData(tempAvgTimespentData.diff, true, true),
          diffPercent: GraphDataService.procesChartData(tempAvgTimespentData.diffPercent, true, true)
        });
        // this.currentNetShoppingTimeData$.next({
        //   net_shopping_time: tempNetShoppingTimeData.netVisitorTime * (60 * 60),
        //   diff: tempNetShoppingTimeData.diff * (60 * 60),
        //   diffPercent: tempNetShoppingTimeData.diffPercent 
        // });
        // this.netShoppingTimeChartData$.next()
      } else {
        this.averageTimeSpentProfileChartData$.next(GraphDataService.procesChartData(averageTimeSpentProfileChartData, false, true));
        this.currentAverageTimeSpentProfileData$.next({
          avgTimeSpent: GraphDataService.procesChartData(currentAverageTimeSpentProfilePair[1], false, true),
          diff: GraphDataService.procesChartData(currentAverageTimeSpentProfilePair[1] - currentAverageTimeSpentProfilePair[0], true, true),
          diffPercent: GraphDataService.procesChartData(((currentAverageTimeSpentProfilePair[1] - currentAverageTimeSpentProfilePair[0]) / currentAverageTimeSpentProfilePair[0] * 100) || 0, true, true)
        });
      }
    }
    else {
      this.averageTimeSpentProfileChartData$.next(GraphDataService.procesChartData(averageTimeSpentProfileChartData, false, true));
      this.currentAverageTimeSpentProfileData$.next({
        avgTimeSpent: GraphDataService.procesChartData(currentAverageTimeSpentProfilePair[1], false, true),
        diff: GraphDataService.procesChartData(currentAverageTimeSpentProfilePair[1] - currentAverageTimeSpentProfilePair[0], true, true),
        diffPercent: GraphDataService.procesChartData(((currentAverageTimeSpentProfilePair[1] - currentAverageTimeSpentProfilePair[0]) / currentAverageTimeSpentProfilePair[0] * 100) || 0, true, true)
      });
      this.netShoppingTimeProfileChartData$.next(GraphDataService.procesChartData(netShoppingTimeProfileChartData, false, false));
      this.currentNetShoppingTimeProfileData$.next({
        netShoppingTime: GraphDataService.procesChartData(currentNetShoppingTimeProfilePair[1], false, true),
        diff: GraphDataService.procesChartData(currentNetShoppingTimeProfilePair[1] - currentNetShoppingTimeProfilePair[0], true, true),
        diffPercent: GraphDataService.procesChartData(((currentNetShoppingTimeProfilePair[1] - currentNetShoppingTimeProfilePair[0]) / currentNetShoppingTimeProfilePair[0] * 100) || 0, true, true)
      });
    }
    this.purchaseRateProfiletData$.next(GraphDataService.procesChartData(purchaseRateProfileData, false, false));
    this.currentpurchaseRateProfileData$.next({
      purchase: GraphDataService.procesChartData(currentPurchaseRateProfilePair[1], false, true),
      diff: GraphDataService.procesChartData(currentPurchaseRateProfilePair[1] - currentPurchaseRateProfilePair[0], true, true),
      diffPercent: GraphDataService.procesChartData(((currentPurchaseRateProfilePair[1] - currentPurchaseRateProfilePair[0]) / currentPurchaseRateProfilePair[0] * 100) || 0, true, true)
    });
  }

  async fetchBuildingVisitorProfileCrossLevelFourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const profile_cross_level = 4;
    const fetchURL = `/retail_customer_api_v2/api/v2/building/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&profile_cross_level=${profile_cross_level}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }

  //#endregion building/visitor-profile profile-cross-level=4 

  //#region building/area-entrance-exit average-by-day-type
  async loadBuildingAreaEntranceExitAvgByDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitAvgByDayTypeData(date, ++graphDataServiceInstance.buildingAreaEntranceExitAvgByDayTypeLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitAvgByDayTypeData(data, lockNum));
  }

  deriveBuildingAreaEntranceExitAvgByDayTypeData(buildingAreaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const entranceWeekdayTimePairData: [number, number] = [0, 0];
    const exitWeekdayTimePairData: [number, number] = [0, 0];
    const entranceWeekendTimePairData: [number, number] = [0, 0];
    const exitWeekendTimePairData: [number, number] = [0, 0];
    if (buildingAreaEntranceExitDatas.length > 0) {
      const zoneAreaEntranceExitByPinWeekdayDatas = buildingAreaEntranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() < 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      const zoneAreaEntranceExitByPinWeekendDatas = buildingAreaEntranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() >= 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      let indexWeekday = 0;
      let indexWeekend = 0;
      for (const zoneAreaEntranceExitByPinWeekdayData of zoneAreaEntranceExitByPinWeekdayDatas) {
        if (!zoneAreaEntranceExitByPinWeekdayData.data) {
          return;
        }
        const dataFiltered = zoneAreaEntranceExitByPinWeekdayData.data;
        const momentIt = moment(`${zoneAreaEntranceExitByPinWeekdayData.iso_year}-${zoneAreaEntranceExitByPinWeekdayData.month}-${zoneAreaEntranceExitByPinWeekdayData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (zoneAreaEntranceExitByPinWeekdayDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekdayTimePairData[1] = dataFiltered.entrance;
            exitWeekdayTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekdayTimePairData[0] = dataFiltered.entrance;
            exitWeekdayTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekdayTimePairData[indexWeekday] = dataFiltered.entrance;
          exitWeekdayTimePairData[indexWeekday] = dataFiltered.exit;
        }
        indexWeekday++;
      }
      for (const zoneAreaEntranceExitByPinWeekendData of zoneAreaEntranceExitByPinWeekendDatas) {
        if (!zoneAreaEntranceExitByPinWeekendData.data) {
          return;
        }
        const dataFiltered = zoneAreaEntranceExitByPinWeekendData.data;
        const momentIt = moment(`${zoneAreaEntranceExitByPinWeekendData.iso_year}-${zoneAreaEntranceExitByPinWeekendData.month}-${zoneAreaEntranceExitByPinWeekendData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (zoneAreaEntranceExitByPinWeekendDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekendTimePairData[1] = dataFiltered.entrance;
            exitWeekendTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekendTimePairData[0] = dataFiltered.entrance;
            exitWeekendTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekendTimePairData[indexWeekend] = dataFiltered.entrance;
          exitWeekendTimePairData[indexWeekend] = dataFiltered.exit;
        }
        indexWeekend++;
      }
    }
    // weekday
    const diffEntranceWeekday = entranceWeekdayTimePairData[1] - entranceWeekdayTimePairData[0];
    const diffEntranceWeekdayPercent = entranceWeekdayTimePairData[0] === 0 ? 0 : (diffEntranceWeekday / entranceWeekdayTimePairData[0]) * 100;
    const diffExitWeekday = exitWeekdayTimePairData[1] - exitWeekdayTimePairData[0];
    const diffExitWeekdayPercent = exitWeekdayTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekdayTimePairData[0]) * 100;
    // weekend
    const diffEntranceWeekend = entranceWeekendTimePairData[1] - entranceWeekendTimePairData[0];
    const diffEntranceWeekendPercent = entranceWeekendTimePairData[0] === 0 ? 0 : (diffEntranceWeekend / entranceWeekendTimePairData[0]) * 100;
    const diffExitWeekend = exitWeekendTimePairData[1] - exitWeekendTimePairData[0];
    const diffExitWeekendPercent = exitWeekendTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekendTimePairData[0]) * 100;
    if (lockNum < this.buildingAreaEntranceExitAvgByDayTypeLock) { return; }
    this.currentBuildingAvgWeekdayEntranceExit$.next({
      entrance: { headCount: GraphDataService.procesChartData(entranceWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercent, true, true) },
      exit: { headCount: GraphDataService.procesChartData(exitWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercent, true, true) }
    });
    this.currentBuildingAvgWeekendEntranceExit$.next({
      entrance: { headCount: GraphDataService.procesChartData(entranceWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercent, true, true) },
      exit: { headCount: GraphDataService.procesChartData(exitWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekendPercent, true, true) }
    });
  }

  async fetchBuildingAreaEntranceExitAvgByDayTypeData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getSelectedQueryAvgByDayTypeParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${area}&aggregation_type=average&by_mode=by_day_type`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion building/area-entrance-exit average-by-day-type

  //#region building/entrance-exit average-by-day-type
  async loadEntranceExitAvgByDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchEntranceExitAvgByDayTypeData(date, ++graphDataServiceInstance.entranceExitAvgByDayTypeLock).then(([data, lockNum]) => graphDataServiceInstance.deriveEntranceExitAvgByDayTypeData(data, lockNum));
  }

  async deriveEntranceExitAvgByDayTypeData(entranceExitDatas: IFetchData<BuildingEntranceExitNetShopTimeSpent>[], lockNum: number) {
    const averageWeekDayWeekEndCount: number[] = [];
    // const mainBuilding = this.configDataService.MAIN_BUILDING;
    const buildingNameSet = new Set<string>();
    entranceExitDatas.forEach(data => {
      Object.keys(data.data).forEach(buildingName => {
        buildingNameSet.add(buildingName);
      });
    });
    const buildingNames = Array.from(buildingNameSet.values());
    const buildingEntranceExitWeekDayData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [0, 0], exit: [0, 0] };
      return prev;
    }, {});
    const buildingEntranceExitWeekEndData: { [buildingName: string]: { entrance: [number, number]; exit: [number, number] } } = buildingNames.reduce((prev, buildingName) => {
      prev[buildingName] = { entrance: [0, 0], exit: [0, 0] };
      return prev;
    }, {});

    if (entranceExitDatas.length > 0) {
      const entranceExitWeekdayDatas = entranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() < 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      const entranceExitWeekendDatas = entranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() >= 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      let indexWeekday = 0;
      let indexWeekend = 0;
      for (const entranceExitWeekdayData of entranceExitWeekdayDatas) {
        if (!entranceExitWeekdayData.data) {
          return;
        }
        const dataFiltered = entranceExitWeekdayData.data;
        const momentIt = moment(`${entranceExitWeekdayData.iso_year}-${entranceExitWeekdayData.month}-${entranceExitWeekdayData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (entranceExitWeekdayDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            buildingNames.forEach(building => {
              buildingEntranceExitWeekDayData[building].entrance[1] = dataFiltered?.[building]?.entrance || 0;
              buildingEntranceExitWeekDayData[building].exit[1] = dataFiltered?.[building]?.exit || 0;
            });
          }
          else {
            buildingNames.forEach(building => {
              buildingEntranceExitWeekDayData[building].entrance[0] = dataFiltered?.[building]?.entrance || 0;
              buildingEntranceExitWeekDayData[building].exit[0] = dataFiltered?.[building]?.exit || 0;
            });
          }
        } else {
          buildingNames.forEach(building => {
            buildingEntranceExitWeekDayData[building].entrance[indexWeekday] = dataFiltered?.[building]?.entrance || 0;
            buildingEntranceExitWeekDayData[building].exit[indexWeekday] = dataFiltered?.[building]?.exit || 0;
          });
        }
        indexWeekday++;
      }
      for (const entranceExitWeekendData of entranceExitWeekendDatas) {
        if (!entranceExitWeekendData.data) {
          return;
        }
        const dataFiltered = entranceExitWeekendData.data;
        const momentIt = moment(`${entranceExitWeekendData.iso_year}-${entranceExitWeekendData.month}-${entranceExitWeekendData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (entranceExitWeekdayDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            buildingNames.forEach(building => {
              buildingEntranceExitWeekEndData[building].entrance[1] = dataFiltered?.[building]?.entrance || 0;
              buildingEntranceExitWeekEndData[building].exit[1] = dataFiltered?.[building]?.exit || 0;
            });
          }
          else {
            buildingNames.forEach(building => {
              buildingEntranceExitWeekEndData[building].entrance[0] = dataFiltered?.[building]?.entrance || 0;
              buildingEntranceExitWeekEndData[building].exit[0] = dataFiltered?.[building]?.exit || 0;
            });
          }
        } else {
          buildingNames.forEach(building => {
            buildingEntranceExitWeekEndData[building].entrance[indexWeekend] = dataFiltered?.[building]?.entrance || 0;
            buildingEntranceExitWeekEndData[building].exit[indexWeekend] = dataFiltered?.[building]?.exit || 0;
          });
        }
        indexWeekend++;
      }
    }
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    const avgBuildingEntranceExitWeekDayData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(buildingEntranceExitWeekDayData).reduce((prev, [buildingName, buildingData]) => {
      // weekday
      const diffEntranceWeekday = buildingData.entrance[1] - buildingData.entrance[0];
      const diffEntranceWeekdayPercent = buildingData.entrance[0] === 0 ? 0 : (diffEntranceWeekday / buildingData.entrance[0]) * 100;
      const diffExitWeekday = buildingData.exit[1] - buildingData.exit[0];
      const diffExitWeekdayPercent = buildingData.exit[0] === 0 ? 0 : (diffExitWeekday / buildingData.exit[0]) * 100;
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingData.entrance[1], false, false),
          diff: GraphDataService.procesChartData(diffEntranceWeekday, true, false),
          diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercent, true, true),
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingData.exit[1], false, false),
          diff: GraphDataService.procesChartData(diffExitWeekday, true, false),
          diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercent, true, true),
        }
      } || { entrance: { headCount: 0, diff: 0, diffPercent: 0 }, exit: { headCount: 0, diff: 0, diffPercent: 0 } };
      averageWeekDayWeekEndCount.push(prev[buildingName].entrance.headCount);
      return prev;
    }, {});
    const avgBuildingEntranceExitWeekEndData: {
      [buildingName: string]: { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
    } = Object.entries(buildingEntranceExitWeekEndData).reduce((prev, [buildingName, buildingData]) => {
      // weekend
      const diffEntranceWeekend = buildingData.entrance[1] - buildingData.entrance[0];
      const diffEntranceWeekendPercent = buildingData.entrance[0] === 0 ? 0 : (diffEntranceWeekend / buildingData.entrance[0]) * 100;
      const diffExitWeekend = buildingData.exit[1] - buildingData.exit[0];
      const diffExitWeekendPercent = buildingData.exit[0] === 0 ? 0 : (diffExitWeekend / buildingData.exit[0]) * 100;
      prev[buildingName] = {
        entrance: {
          headCount: GraphDataService.procesChartData(buildingData.entrance[1], false, false),
          diff: GraphDataService.procesChartData(diffEntranceWeekend, true, false),
          diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercent, true, true),
        },
        exit: {
          headCount: GraphDataService.procesChartData(buildingData.exit[1], false, false),
          diff: GraphDataService.procesChartData(diffExitWeekend, true, false),
          diffPercent: GraphDataService.procesChartData(diffExitWeekendPercent, true, true),
        }
      } || { entrance: { headCount: 0, diff: 0, diffPercent: 0 }, exit: { headCount: 0, diff: 0, diffPercent: 0 } };
      averageWeekDayWeekEndCount.push(prev[buildingName].entrance.headCount);
      return prev;
    }, {});
    this.buildingAverageWeekDayWeekEndCount$.next(averageWeekDayWeekEndCount);
    if (lockNum < this.entranceExitAvgByDayTypeLock) { return; }
    this.currentAllBuildingAvgWeekdayEntranceExit$.next(avgBuildingEntranceExitWeekDayData);
    this.currentAllBuildingAvgWeekendEntranceExit$.next(avgBuildingEntranceExitWeekEndData);
  }

  async fetchEntranceExitAvgByDayTypeData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getSelectedQueryAvgByDayTypeParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&aggregation_type=average&by_mode=by_day_type`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<BuildingEntranceExitNetShopTimeSpent>[], number];
  }
  //#endregion building/entrance-exit average-by-day-type

  //#region building/zone-synergy-by-pin all-pin
  async loadBuildingToZoneSynergyAllPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchBuildingToZoneSynergyAllPinData(date, ++graphDataServiceInstance.buildingZoneSynergyAllPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToZoneSynergyAllPinData(data, lockNum));
  }

  deriveBuildingToZoneSynergyAllPinData(buildingToZoneSynergyAllPinDatas: IFetchData<GroupCountData>[], lockNum: number) {
    // const buildingToZoneSynergyBarChart: { [zoneName: string]: number } = {};
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME);
    const buildingToZoneSynergyAllPin: { [pinName: string]: { [zoneName: string]: number } } = {};
    for (const buildingAreaEntranceExitByPinData of buildingToZoneSynergyAllPinDatas) {
      if (!buildingAreaEntranceExitByPinData || !buildingAreaEntranceExitByPinData.data) {
        return;
      }
      buildingToZoneSynergyAllPin[buildingAreaEntranceExitByPinData.pin] = {};
      for (const zoneName of zoneList) {
        buildingToZoneSynergyAllPin[buildingAreaEntranceExitByPinData.pin][zoneName] = 0;
      }
      const buildingToZoneSynergyData = buildingAreaEntranceExitByPinData.data;
      for (const [zoneName, zoneData] of Object.entries(buildingToZoneSynergyData)) {
        if (zoneName !== '_total') {
          const countPercentage = (zoneData.count / buildingToZoneSynergyData._total.count) * 100;
          buildingToZoneSynergyAllPin[buildingAreaEntranceExitByPinData.pin][zoneName] = GraphDataService.procesChartData(countPercentage, false, false);
        }
      }
    }
    if (lockNum < this.buildingZoneSynergyAllPinLock) { return; }
    this.buildingZoneSynergyAllPin$.next(buildingToZoneSynergyAllPin);
  }

  async fetchBuildingToZoneSynergyAllPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/zone-synergy-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&building=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion building/zone-synergy-by-pin all-pin

  //#region building/area-entrance-exit-by-pin-by-hour all-pin
  async loadBuildingAreaEntranceExitByHourAllPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitByHourAllPinData(date, ++graphDataServiceInstance.buildingAreaEntranceExitByHourAllPinLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitByHourAllPinData(data, lockNum));
  }

  deriveBuildingAreaEntranceExitByHourAllPinData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number, floor?: string) {
    const entranceGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number[] } } = {};
    const exitGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number[] } } = {};
    const buildingNameList = Object.keys(this.configDataService.FLOOR_OBJECTS);
    for (const buildingName of buildingNameList) {
      entranceGroupOldAreaByPin[buildingName] = {};
      exitGroupOldAreaByPin[buildingName] = {};
    }
    // const pinList: string[] = [];
    // const sookPinList: string[] = [];
    // const mainBuilding = this.configDataService.MAIN_BUILDING;
    // const floorObjects = this.configDataService.FLOOR_OBJECTS[mainBuilding];
    // for (const [buildingName, floorObjects] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
    //   for(const [floorName, floorData] of Object.entries(floorObjects)) {
    //     for (const gate of floorData.gates) {
    //       if (gate.includes('sook')) {
    //         sookPinList.push(gate);
    //       } else {
    //         pinList.push(gate);
    //       }
    //     }
    //   }
    // }

    const floorEntranceTrendData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } } = {};
    const floorExitTrendData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number[] } } } = {};
    for (const [buildingName, floorObjects] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      floorEntranceTrendData[buildingName] = {};
      floorExitTrendData[buildingName] = {};
      for (const [floorName, floorData] of Object.entries(floorObjects)) {
        floorEntranceTrendData[buildingName][floorName] = {};
        floorExitTrendData[buildingName][floorName] = {};
        for (const pinName of floorData.gates) {
          entranceGroupOldAreaByPin[buildingName][pinName] = [];
          exitGroupOldAreaByPin[buildingName][pinName] = [];
          floorEntranceTrendData[buildingName][floorName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
          floorExitTrendData[buildingName][floorName][pinName] = Array.of(this.configDataService.TIME_LIST).map(_ => 0);
        }
      }
    }
    const filteredDatas = this.configDataService.isFeatureEnabled('multiple_organization') ? buildingAreaEntranceExitByPinDatas.filter(d => d.building !== 'onesiam') : buildingAreaEntranceExitByPinDatas;
    if (!filteredDatas || filteredDatas.length === 0) {
      return;
    }

    // for (const filteredData of filteredDatas) {
    //   entranceGroupOldAreaByPin[filteredData.building][filteredData.pin].push(filteredData.data.entrance);
    //   exitGroupOldAreaByPin[filteredData.building][filteredData.pin].push(filteredData.data.exit);
    //   entranceGroupOldAreaByPin[mainBuilding][filteredData.pin].push(filteredData.data.entrance);
    //   exitGroupOldAreaByPin[mainBuilding][filteredData.pin].push(filteredData.data.exit);
    // }
    for (const filteredData of filteredDatas) {
      for (const [buildingName, floorObjects] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
        for (const [floorName, floorData] of Object.entries(floorObjects)) {
          if (floorData.gates.find(gate => gate === filteredData.pin) && buildingName === filteredData.building) {
            this.configDataService.TIME_LIST.map((time, idx) => {
              const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
              if (!filteredData || !filteredData.data) {
                const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
                const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
                return;
              }
              if (filteredData.hour === timeKey) {
                floorEntranceTrendData[buildingName][floorName][filteredData.pin][idx] = filteredData.data.entrance;
                floorExitTrendData[buildingName][floorName][filteredData.pin][idx] = filteredData.data.exit;
              }
            });
          }
        }
      }
    }

    // const filteredDatas = this.configDataService.isFeatureEnabled('multiple_organization') ? buildingAreaEntranceExitByPinDatas.filter(buildingByPinData => buildingByPinData.building !== 'onesiam') : buildingAreaEntranceExitByPinDatas;
    // for (const filteredData of filteredDatas) {
    //   this.configDataService.TIME_LIST.map(time => {
    //     const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
    //     if (!filteredData || !filteredData.data) {
    //       const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
    //       const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
    //       return;
    //     }
    //     if (filteredData.hour === timeKey) {

    //     }
    //   });
    // }
    // for (const pinName of pinList) {
    //   // const filteredDatas = buildingAreaEntranceExitByPinDatas.filter(buildingByPinData => buildingByPinData.pin === pinName && (buildingByPinData.building === mainBuilding || buildingByPinData.building === 'pier'));

    // }
    // console.log(buildingAreaEntranceExitByPinDatas.filter(buildingByPinData => buildingByPinData.building === 'sook_siam'));
    if (lockNum < this.buildingAreaEntranceExitByHourAllPinLock) { return; }
    this.entranceBuildingByHourPin$.next(floorEntranceTrendData);
    this.exitBuildingByHourPin$.next(floorExitTrendData);
  }

  async fetchBuildingAreaEntranceExitByHourAllPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-pin-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion building/area-entrance-exit-by-pin-by-hour all-pin

  //#region floor/area-entrance-exit-by-pin 
  async loadFloorAreaEntranceExitByPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.buildingFloorAreaEntranceExitByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchFloorAreaEntranceExitByPinData(date, ++graphDataServiceInstance.buildingFloorAreaEntranceExitByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveFloorAreaEntranceExitByPinData(data, lockNum));
  }

  deriveFloorAreaEntranceExitByPinData(areaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const floorAreaEntranceExitByPinTrend = { entrance: [], exit: [] };
    const floorAreaEntranceExitByPinBreakdown = { entrance: 0, exit: 0 };
    const entranceGroupOldAreaByPin = {};
    const exitGroupOldAreaByPin = {};
    const floorAreaEntranceByPinTimePairData: [number, number] = [0, 0];
    const floorAreaExitByPinTimePairData: [number, number] = [0, 0];
    const floorAreaAvgTimespentTimePairData: [number, number] = [0, 0];
    const selectedDirectory = this.baseGraphData.selectedDirectory$.getValue();
    GraphDataService.mapSevenDayLineChartData(areaEntranceExitByPinDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        floorAreaEntranceExitByPinTrend.entrance.push(diffToSelectedDate > 0 ? null : 0);
        floorAreaEntranceExitByPinTrend.exit.push(diffToSelectedDate > 0 ? null : 0);
        return;
      }
      const floorAreaEntranceExitByPinData = dataFiltered.data;
      entranceGroupOldAreaByPin[dataFiltered.building] = {};
      exitGroupOldAreaByPin[dataFiltered.building] = {};
      if (dataFiltered.floor !== selectedDirectory.floor) {
        entranceGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor] = {};
        exitGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor] = {};
      } else {
        entranceGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor] = {};
        exitGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor] = {};
      }
      if (diffToSelectedDate === 0) {
        floorAreaEntranceExitByPinBreakdown.entrance = GraphDataService.procesChartData(floorAreaEntranceExitByPinData.entrance, false, false);
        floorAreaEntranceExitByPinBreakdown.exit = GraphDataService.procesChartData(floorAreaEntranceExitByPinData.exit, false, false);
        floorAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(floorAreaEntranceExitByPinData.average_timespent, false, false);
        floorAreaEntranceByPinTimePairData[diffToSelectedDate + 1] = floorAreaEntranceExitByPinBreakdown.entrance;
        floorAreaExitByPinTimePairData[diffToSelectedDate + 1] = floorAreaEntranceExitByPinBreakdown.exit;
        if (dataFiltered.floor !== selectedDirectory.floor) {
          entranceGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor][dataFiltered.pin] = floorAreaEntranceExitByPinBreakdown.entrance;
          exitGroupOldAreaByPin[dataFiltered.building][selectedDirectory.floor][dataFiltered.pin] = floorAreaEntranceExitByPinBreakdown.exit;
        } else {
          entranceGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor][dataFiltered.pin] = floorAreaEntranceExitByPinBreakdown.entrance;
          exitGroupOldAreaByPin[dataFiltered.building][dataFiltered.floor][dataFiltered.pin] = floorAreaEntranceExitByPinBreakdown.exit;
        }
      }
      if (diffToSelectedDate === -1) {
        floorAreaEntranceByPinTimePairData[diffToSelectedDate + 1] = floorAreaEntranceExitByPinData.entrance;
        floorAreaExitByPinTimePairData[diffToSelectedDate + 1] = floorAreaEntranceExitByPinData.exit;
        floorAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(floorAreaEntranceExitByPinData.average_timespent, false, false);
      }
      floorAreaEntranceExitByPinTrend.entrance.push(GraphDataService.procesChartData(floorAreaEntranceExitByPinData.entrance, false, false));
      floorAreaEntranceExitByPinTrend.exit.push(GraphDataService.procesChartData(floorAreaEntranceExitByPinData.exit, false, false));
    });
    if (lockNum < this.buildingFloorAreaEntranceExitByPinLock) { return; }

    const diffEntrance = floorAreaEntranceByPinTimePairData[1] - floorAreaEntranceByPinTimePairData[0];
    const diffEntrancePercentage = floorAreaEntranceByPinTimePairData[0] === 0 ? 0 : (diffEntrance / floorAreaEntranceByPinTimePairData[0]) * 100;
    const diffExit = floorAreaExitByPinTimePairData[1] - floorAreaExitByPinTimePairData[0];
    const diffExitPercentage = floorAreaExitByPinTimePairData[0] === 0 ? 0 : (diffExit / floorAreaExitByPinTimePairData[0]) * 100;
    const diffAvgTimespent = floorAreaAvgTimespentTimePairData[1] - floorAreaAvgTimespentTimePairData[0];
    const diffAvgTimespentPercentage = floorAreaAvgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / floorAreaAvgTimespentTimePairData[0]) * 100;

    this.currentBuildingFloorAreaEntranceExitByPin$.next({
      entrance: {
        current: floorAreaEntranceByPinTimePairData[1],
        diff: GraphDataService.procesChartData(diffEntrance, true, true),
        diffPercent: GraphDataService.procesChartData(diffEntrancePercentage, true, true)
      },
      exit: {
        current: floorAreaExitByPinTimePairData[1],
        diff: GraphDataService.procesChartData(diffExit, true, true),
        diffPercent: GraphDataService.procesChartData(diffExitPercentage, true, true)
      }
    });
    // this.currentZoneAreaAvgTimespentByPin$.next({
    //   current: zoneAreaAvgTimespentTimePairData[1],
    //   diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
    //   diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
    // });
    this.buildingFloorAreaEntranceExitByPinBreakdown$.next(floorAreaEntranceExitByPinBreakdown);
    this.buildingFloorAreaEntranceExitByPinTrend$.next(floorAreaEntranceExitByPinTrend);
  }

  async fetchFloorAreaEntranceExitByPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/area-entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion floor/area-entrance-exit-by-pin

  //#region store/area-entrance-exit
  async loadStoreAreaEntranceExitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable !== null) {
      if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
        // clear data
        ++graphDataServiceInstance.storeAreaEntranceExitLock;
        return Promise.resolve();
      }
      const areaName = selectedInteractable.name.toLocaleLowerCase();
      const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
      const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
      return graphDataServiceInstance.fetchStoreAreaEntranceExitData(date, ++graphDataServiceInstance.storeAreaEntranceExitLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreAreaEntranceExitData(data, lockNum));
    }
    return graphDataServiceInstance.fetchStoreAreaEntranceExitData(date, ++graphDataServiceInstance.storeAreaEntranceExitLock).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreAllAreaEntranceExitData(data, lockNum));
  }

  deriveStoreAllAreaEntranceExitData(storeAreaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const storeAreaEntranceExitTrend: { [storeName: string]: { entrance: number[]; exit: number[] } } = {};
    const storeAreaEntranceExitBreakdown: { [storeName: string]: { entrance: number; exit: number } } = {};
    const storeAreas = Object.keys(this.configDataService.DISPLAY_LANGUAGE.STORE_NAME);
    for (const storeArea of storeAreas) {
      storeAreaEntranceExitTrend[storeArea] = { entrance: [], exit: [] };
      storeAreaEntranceExitBreakdown[storeArea] = { entrance: 0, exit: 0 };
      const storeAreaEntranceExitData = storeAreaEntranceExitDatas.filter(storeAreaData => storeAreaData.area === storeArea);
      GraphDataService.mapSevenDayLineChartData(storeAreaEntranceExitData, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          storeAreaEntranceExitTrend[storeArea].entrance.push(diffToSelectedDate > 0 ? null : 0);
          storeAreaEntranceExitTrend[storeArea].exit.push(diffToSelectedDate > 0 ? null : 0);
          return;
        }
        const storeAreaEntranceExitData = dataFiltered.data;
        if (diffToSelectedDate === 0) {
          storeAreaEntranceExitBreakdown[storeArea].entrance = GraphDataService.procesChartData(storeAreaEntranceExitData.entrance, false, false);
          storeAreaEntranceExitBreakdown[storeArea].exit = GraphDataService.procesChartData(storeAreaEntranceExitData.exit, false, false);
        }
        storeAreaEntranceExitTrend[storeArea].entrance.push(GraphDataService.procesChartData(storeAreaEntranceExitData.entrance, false, false));
        storeAreaEntranceExitTrend[storeArea].exit.push(GraphDataService.procesChartData(storeAreaEntranceExitData.exit, false, false));
      });
    }
    if (lockNum < this.storeAreaEntranceExitLock) { return; }
    this.storeAllAreaEntranceExitTrend$.next(storeAreaEntranceExitTrend);
    this.storeAllAreaEntranceExitBreakdown$.next(storeAreaEntranceExitBreakdown);
  }

  deriveStoreAreaEntranceExitData(areaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const storeAreaEntranceExitTrend = { entrance: [], exit: [] };
    const storeAreaProximityTrend: number[] = [];
    const storeAreaEntranceExitBreakdown = { entrance: 0, exit: 0 };
    const storeAreaEntranceTimePairData: [number, number] = [0, 0];
    const storeAreaExitTimePairData: [number, number] = [0, 0];
    const storeAreaAvgTimespentTimePairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(areaEntranceExitByPinDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        storeAreaEntranceExitTrend.entrance.push(diffToSelectedDate > 0 ? null : 0);
        storeAreaEntranceExitTrend.exit.push(diffToSelectedDate > 0 ? null : 0);
        storeAreaProximityTrend.push(diffToSelectedDate > 0 ? null : 0);
        return;
      }
      const storeAreaEntranceExitData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        storeAreaEntranceExitBreakdown.entrance = GraphDataService.procesChartData(storeAreaEntranceExitData.entrance, false, false);
        storeAreaEntranceExitBreakdown.exit = GraphDataService.procesChartData(storeAreaEntranceExitData.exit, false, false);
        storeAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(storeAreaEntranceExitData.average_timespent, false, false);
        storeAreaEntranceTimePairData[diffToSelectedDate + 1] = storeAreaEntranceExitBreakdown.entrance;
        storeAreaExitTimePairData[diffToSelectedDate + 1] = storeAreaEntranceExitBreakdown.exit;
      }
      if (diffToSelectedDate === -1) {
        storeAreaEntranceTimePairData[diffToSelectedDate + 1] = storeAreaEntranceExitData.entrance;
        storeAreaExitTimePairData[diffToSelectedDate + 1] = storeAreaEntranceExitData.exit;
        storeAreaAvgTimespentTimePairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(storeAreaEntranceExitData.average_timespent, false, false);
      }
      storeAreaProximityTrend.push(GraphDataService.procesChartData(storeAreaEntranceExitData.proximity, false, false));
      storeAreaEntranceExitTrend.entrance.push(GraphDataService.procesChartData(storeAreaEntranceExitData.entrance, false, false));
      storeAreaEntranceExitTrend.exit.push(GraphDataService.procesChartData(storeAreaEntranceExitData.exit, false, false));
    });
    if (lockNum < this.storeAreaEntranceExitLock) { return; }

    const diffEntrance = storeAreaEntranceTimePairData[1] - storeAreaEntranceTimePairData[0];
    const diffEntrancePercentage = storeAreaEntranceTimePairData[0] === 0 ? 0 : (diffEntrance / storeAreaEntranceTimePairData[0]) * 100;
    const diffExit = storeAreaExitTimePairData[1] - storeAreaExitTimePairData[0];
    const diffExitPercentage = storeAreaExitTimePairData[0] === 0 ? 0 : (diffExit / storeAreaExitTimePairData[0]) * 100;
    const diffAvgTimespent = storeAreaAvgTimespentTimePairData[1] - storeAreaAvgTimespentTimePairData[0];
    const diffAvgTimespentPercentage = storeAreaAvgTimespentTimePairData[0] === 0 ? 0 : (diffAvgTimespent / storeAreaAvgTimespentTimePairData[0]) * 100;

    this.currentStoreAreaEntranceExit$.next({
      entrance: {
        current: storeAreaEntranceTimePairData[1],
        diff: GraphDataService.procesChartData(diffEntrance, true, true),
        diffPercent: GraphDataService.procesChartData(diffEntrancePercentage, true, true)
      },
      exit: {
        current: storeAreaExitTimePairData[1],
        diff: GraphDataService.procesChartData(diffExit, true, true),
        diffPercent: GraphDataService.procesChartData(diffExitPercentage, true, true)
      }
    });
    this.currentStoreAreaAvgTimespent$.next({
      current: storeAreaAvgTimespentTimePairData[1],
      diff: GraphDataService.procesChartData(diffAvgTimespent, true, true),
      diffPercent: GraphDataService.procesChartData(diffAvgTimespentPercentage, true, true)
    });
    this.storeAreaEntranceExitBreakdown$.next(storeAreaEntranceExitBreakdown);
    this.storeAreaEntranceExitTrend$.next(storeAreaEntranceExitTrend);
    this.storeAreaProximityTrend$.next(storeAreaProximityTrend);
  }

  async fetchStoreAreaEntranceExitData(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getLineChartQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/store/area-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (area) {
      fetchURL += `&area=${area}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion store/area-entrance-exit

  //#region store/area-entrance-exit average-by-day-type
  async loadStoreAreaEntranceExitAvgDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeAreaEntranceExitAvgDayTypeLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    return graphDataServiceInstance.fetchStoreAreaEntranceExitAvgDayTypeData(date, ++graphDataServiceInstance.storeAreaEntranceExitAvgDayTypeLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreAreaEntranceExitAvgDayTypeData(data, lockNum));
  }

  deriveStoreAreaEntranceExitAvgDayTypeData(areaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const entranceWeekdayTimePairData: [number, number] = [0, 0];
    const exitWeekdayTimePairData: [number, number] = [0, 0];
    const entranceWeekendTimePairData: [number, number] = [0, 0];
    const exitWeekendTimePairData: [number, number] = [0, 0];
    if (areaEntranceExitDatas.length > 0) {
      const storeAreaEntranceExitWeekdayDatas = areaEntranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() < 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      const storeAreaEntranceExitWeekendDatas = areaEntranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() >= 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      let indexWeekday = 0;
      let indexWeekend = 0;
      for (const storeAreaEntranceExitWeekdayData of storeAreaEntranceExitWeekdayDatas) {
        if (!storeAreaEntranceExitWeekdayData.data) {
          return;
        }
        const dataFiltered = storeAreaEntranceExitWeekdayData.data;
        const momentIt = moment(`${storeAreaEntranceExitWeekdayData.iso_year}-${storeAreaEntranceExitWeekdayData.month}-${storeAreaEntranceExitWeekdayData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (storeAreaEntranceExitWeekdayDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekdayTimePairData[1] = dataFiltered.entrance;
            exitWeekdayTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekdayTimePairData[0] = dataFiltered.entrance;
            exitWeekdayTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekdayTimePairData[indexWeekday] = dataFiltered.entrance;
          exitWeekdayTimePairData[indexWeekday] = dataFiltered.exit;
        }
        indexWeekday++;
      }
      for (const storeAreaEntranceExitWeekendData of storeAreaEntranceExitWeekendDatas) {
        if (!storeAreaEntranceExitWeekendData.data) {
          return;
        }
        const dataFiltered = storeAreaEntranceExitWeekendData.data;
        const momentIt = moment(`${storeAreaEntranceExitWeekendData.iso_year}-${storeAreaEntranceExitWeekendData.month}-${storeAreaEntranceExitWeekendData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (storeAreaEntranceExitWeekendDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekendTimePairData[1] = dataFiltered.entrance;
            exitWeekendTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekendTimePairData[0] = dataFiltered.entrance;
            exitWeekendTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekendTimePairData[indexWeekend] = dataFiltered.entrance;
          exitWeekendTimePairData[indexWeekend] = dataFiltered.exit;
        }
        indexWeekend++;
      }
    }
    // weekday
    const diffEntranceWeekday = entranceWeekdayTimePairData[1] - entranceWeekdayTimePairData[0];
    const diffEntranceWeekdayPercent = entranceWeekdayTimePairData[0] === 0 ? 0 : (diffEntranceWeekday / entranceWeekdayTimePairData[0]) * 100;
    const diffExitWeekday = exitWeekdayTimePairData[1] - exitWeekdayTimePairData[0];
    const diffExitWeekdayPercent = exitWeekdayTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekdayTimePairData[0]) * 100;
    // weekend
    const diffEntranceWeekend = entranceWeekendTimePairData[1] - entranceWeekendTimePairData[0];
    const diffEntranceWeekendPercent = entranceWeekendTimePairData[0] === 0 ? 0 : (diffEntranceWeekend / entranceWeekendTimePairData[0]) * 100;
    const diffExitWeekend = exitWeekendTimePairData[1] - exitWeekendTimePairData[0];
    const diffExitWeekendPercent = exitWeekendTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekendTimePairData[0]) * 100;

    if (lockNum < this.storeAreaEntranceExitAvgDayTypeLock) { return; }

    this.currentStoreAreaEntranceExitAvgWeekday$.next({
      entrance: { current: GraphDataService.procesChartData(entranceWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercent, true, true) },
      exit: { current: GraphDataService.procesChartData(exitWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercent, true, true) }
    });
    this.currentStoreAreaEntranceExitAvgWeekend$.next({
      entrance: { current: GraphDataService.procesChartData(entranceWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercent, true, true) },
      exit: { current: GraphDataService.procesChartData(exitWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekendPercent, true, true) }
    });
  }

  async fetchStoreAreaEntranceExitAvgDayTypeData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getSelectedQueryAvgByDayTypeParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/area-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&aggregation_type=average&by_mode=by_day_type`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion store/area-entrance-exit average-by-day-type

  //#region store/area-entrance-exit-by-hour
  async loadStoreAreaEntranceExitByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeAreaEntranceExitByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    return graphDataServiceInstance.fetchStoreAreaEntranceExitByHourData(date, ++graphDataServiceInstance.storeAreaEntranceExitByHourLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreAreaEntranceExitByHourData(data, lockNum));
  }

  deriveStoreAreaEntranceExitByHourData(areaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const storeAreaEntranceExitBreakdownByHour: { entrance: number[]; exit: number[] } = { entrance: [], exit: [] };
    const storeAreaEntranceBreakdownByHour: number[] = [];
    const storeAreaProximityBreakdownByHour: number[] = [];
    const storeAreaBusiestTime: { hour: string; headcount: number } = { hour: '10 AM', headcount: 0 };
    for (const areaEntranceExitData of areaEntranceExitDatas) {
      this.configDataService.TIME_LIST.map(time => {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        if (!areaEntranceExitData || !areaEntranceExitData.data) {
          const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
          const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
          storeAreaEntranceExitBreakdownByHour.entrance.push(fillValue);
          storeAreaEntranceExitBreakdownByHour.exit.push(fillValue);
          storeAreaProximityBreakdownByHour.push(fillValue);
          storeAreaEntranceBreakdownByHour.push(fillValue);
          return;
        }
        if (areaEntranceExitData.hour === timeKey) {
          storeAreaEntranceExitBreakdownByHour.entrance.push(GraphDataService.procesChartData(areaEntranceExitData.data.entrance, false, false));
          storeAreaEntranceExitBreakdownByHour.exit.push(GraphDataService.procesChartData(areaEntranceExitData.data.exit, false, false));
          storeAreaProximityBreakdownByHour.push(GraphDataService.procesChartData(areaEntranceExitData.data.proximity, false, false));
          storeAreaEntranceBreakdownByHour.push(GraphDataService.procesChartData(areaEntranceExitData.data.entrance, false, false));
          if (storeAreaBusiestTime.headcount < areaEntranceExitData.data.entrance) {
            storeAreaBusiestTime.headcount = GraphDataService.procesChartData(areaEntranceExitData.data.entrance);
            storeAreaBusiestTime.hour = `${new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })} - ${new Date(2020, 1, 1, parseInt(time, 10) + 1).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
          }
        }
      });
    }
    if (lockNum < this.storeAreaEntranceExitByHourLock) { return; }
    this.storeAreaBusiestTime$.next(storeAreaBusiestTime);
    this.storeAreaEntranceExitByHourBreakdown$.next(storeAreaEntranceExitBreakdownByHour); // entrance and exit
    this.storeAreaEntranceByHourBreakdown$.next(storeAreaEntranceBreakdownByHour); // entrance only
    this.storeAreaProximityByHourBreakdown$.next(storeAreaProximityBreakdownByHour);
  }

  async fetchStoreAreaEntranceExitByHourData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/area-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion store/area-entrance-exit-by-hour

  //#region store/zone-synergy
  async loadStoreAreaToZoneSynergyData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeAreaToZoneSynergyLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_reid') ? graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_reid_key') : graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    return graphDataServiceInstance.fetchStoreAreaToZoneSynergyData(date, ++graphDataServiceInstance.storeAreaToZoneSynergyLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreAreaToZoneSynergyData(data, lockNum));
  }

  deriveStoreAreaToZoneSynergyData(storeToZoneSynergyDatas: IFetchData<GroupCountData>[], lockNum: number) {
    const storeAreaToZoneSynergyBarChart: { [zoneName: string]: number } = {};
    const storeAreaToZoneSynergyBarChartRaw: { [zoneName: string]: number } = {};
    const currentStoreAreaEntranceExitData = this.currentStoreAreaEntranceExit$?.getValue()?.entrance?.current || 0;
    GraphDataService.mapSevenDayLineChartData(storeToZoneSynergyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const storeAreaToZoneSynergyData = dataFiltered.data;
      for (const [zoneName, zoneData] of Object.entries(storeAreaToZoneSynergyData)) {
        if (zoneName !== '_total') {
          const countPercentage = zoneData.count / storeAreaToZoneSynergyData._total.count;
          const roundedPercentage = Math.round(countPercentage * 100) / 100;
          storeAreaToZoneSynergyBarChart[zoneName] = GraphDataService.procesChartData(countPercentage * 100, false, false);
          storeAreaToZoneSynergyBarChartRaw[zoneName] = GraphDataService.procesChartData(roundedPercentage * currentStoreAreaEntranceExitData, false, false);
        }
      }
    });
    if (lockNum < this.storeAreaToZoneSynergyLock) { return; }
    this.storeAreaToZoneSynergyData$.next(Object.entries(storeAreaToZoneSynergyBarChart).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.storeAreaToZoneSynergyRawData$.next(Object.entries(storeAreaToZoneSynergyBarChartRaw).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
  }

  async fetchStoreAreaToZoneSynergyData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/zone-synergy?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}&mode=${qParams.periodType.toBackendV2String()}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion store/zone-synergy

  //#region zone/visitor-profile-by-pin profile-cross-level=2
  async loadZoneVisitorProfileCrossLevelTwoByPin(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const selectedDirectory = graphDataServiceInstance.baseGraphData.selectedDirectory$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name || !selectedDirectory) {
      // clear data
      ++graphDataServiceInstance.zoneVisitorProfileCrossLevelTwoByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    const updatedDirectory = selectedDirectory?.zone === 'pavilion_000_g_001_2nd_reception' ? { building: 'pavilion', floor: 'pavilion_000_g_001_2nd', zone: 'pavilion_000_g_001_2nd_reception' } : selectedDirectory;
    return graphDataServiceInstance.fetchZoneVisitorProfileCrossLevelTwoByPin(date, ++graphDataServiceInstance.zoneVisitorProfileCrossLevelTwoByPinLock, areaName, updatedDirectory).then(([data, lockNum]) => graphDataServiceInstance.deriveZoneVisitorProfileCrossLevelTwoByPin(data, lockNum));
  }

  deriveZoneVisitorProfileCrossLevelTwoByPin(zoneVisitorProfileByPinDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    const ageProfileData = { male: [] as number[], female: [] as number[] };
    const genderProfile: { [gender: string]: number } = {};
    const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
    const femaleAgeProfile = { ...maleAgeProfile };
    const selectedInteractable = this.baseGraphData.selectedInteractable$.value;
    const selectedDirectory = this.baseGraphData.selectedDirectory$.value;
    const filteredData = zoneVisitorProfileByPinDatas.filter(d => d.pin === selectedInteractable?.name && d.zone === selectedDirectory.zone);
    GraphDataService.mapSevenDayLineChartData(filteredData, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // const fillValue = diffToSelectedDate > 0 ? null : 0;
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const zoneVisitorProfileByPinData = dataFiltered.data;
      for (const visitorProfileData of zoneVisitorProfileByPinData) {
        const profileData = this.configDataService.FEATURE_FLAGS?.traffic_page?.profile_exit && this.configDataService.FEATURE_FLAGS?.traffic_page?.profile_exit.find(d => d.pin === selectedInteractable?.name && d.zone === selectedDirectory.zone)
          ? visitorProfileData.exit
          : visitorProfileData.entrance;
        this.configDataService.GENDER_CLASS.forEach(gender => {
          if (compare1DepthObjects(visitorProfileData.group, { gender })) {
            if (diffToSelectedDate === 0) {
              genderProfile[gender] = profileData;
            }
          }
        });
        this.configDataService.AGE_CLASS.forEach(age => {
          if (compare1DepthObjects(visitorProfileData.group, { gender: 'male', age })) {
            if (diffToSelectedDate === 0) {
              maleAgeProfile[age] = profileData;
            }
          }
          if (compare1DepthObjects(visitorProfileData.group, { gender: 'female', age })) {
            if (diffToSelectedDate === 0) {
              femaleAgeProfile[age] = profileData;
            }
          }
        });
      }
    });
    const sumAllProfile = Object.values(genderProfile).reduce((a, b) => a + b, 0);
    // ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
    // ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
    const genderProfilePercentage = Object.entries(genderProfile).reduce((newObj, [key, val]) => {
      const percentageVal = (val / sumAllProfile) * 100;
      newObj[key] = GraphDataService.procesChartData(percentageVal, false, false);
      return newObj;
    }, {});
    ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
    ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
    if (lockNum < this.zoneVisitorProfileCrossLevelTwoByPinLock) { return; }
    this.zoneAgeProfileByPinBreakdown$.next(ageProfileData);
    this.zoneGenderProfileByPinBreakdown$.next(genderProfilePercentage);
  }

  async fetchZoneVisitorProfileCrossLevelTwoByPin(date: moment.Moment, lockNum: number, area?: string, directory?: { building: string; floor: string; zone: string }) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/zone/visitor-profile-by-pin?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}&profile_cross_level=2`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }
  //#endregion zone/visitor-profile-by-pin profile-cross-level=2

  //#region building/visitor-profile profile-cross-level = 2
  async loadBuildingAreaVisitorProfileCrossLevelTwoData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedVistiorProfile = graphDataServiceInstance.selectedVisitorProfile$.getValue();
    // const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    if (isSelectionVisitorProfileAll(selectedVistiorProfile)) {
      return graphDataServiceInstance.fetchBuildingAreaVisitorProfileCrossLevelTwoData(date, ++graphDataServiceInstance.buildingAreaVisitorProfileCrossLevelTwoLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaVisitorProfileCrossLevelTwoData(data, lockNum));
    }
    return graphDataServiceInstance.fetchBuildingAreaVisitorProfileCrossLevelTwoData(date, ++graphDataServiceInstance.buildingAreaVisitorProfileCrossLevelTwoLock).then(([data, _lockNum]) => graphDataServiceInstance.deriveBuildingAreaSelectedVisitorProfileCrossLevelTwoData(data, selectedVistiorProfile));

  }

  async deriveBuildingAreaVisitorProfileCrossLevelTwoData(visitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], lockNum: number) {
    this.unfilteredBuildingVisitorProfileData$.next(visitorProfileDatas);
    const buildingMaskProfileTimePairData: { [buildingName: string]: [number, number] } = {};
    const buildingAllMaskProfileTimePairData: { [buildingName: string]: [number, number] } = {};
    const buildingStudentProfileTimePairData: { [buildingName: string]: [number, number] } = {};
    const ethnicityProfileData = { male: [] as number[], female: [] as number[] };
    const ageProfileData = { male: [] as number[], female: [] as number[] };
    const isEntrance = this.configDataService.isEntranceDataMode;
    for (const buildingName of Object.keys(this.configDataService.FLOOR_OBJECTS)) {
      buildingMaskProfileTimePairData[buildingName] = [0, 0];
      buildingStudentProfileTimePairData[buildingName] = [0, 0];
      buildingAllMaskProfileTimePairData[buildingName] = [0, 0];
    }
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred || diffToSelectedDate > 0) {
        const fillValue = isPred || diffToSelectedDate > 0 ? null : 0;
        return;
      }
      const maleEthnicityProfile = GraphDataService.createObjectComprehension(this.configDataService.ETHNICITY_CLASS, 0);
      const maleAgeProfile = GraphDataService.createObjectComprehension(this.configDataService.AGE_CLASS, 0);
      const visitorProfileData = dataFiltered.data;
      const building = dataFiltered.building;
      const femaleEthnicityProfile = { ...maleEthnicityProfile };
      const femaleAgeProfile = { ...maleAgeProfile };
      for (const profileData of visitorProfileData) {
        if (diffToSelectedDate === 0) {
          this.configDataService.ETHNICITY_CLASS.forEach(ethnicity => {
            if (compare1DepthObjects(profileData.group, { gender: 'male', ethnicity })) {
              maleEthnicityProfile[ethnicity] = isEntrance ? GraphDataService.procesChartData(profileData.entrance) : GraphDataService.procesChartData(profileData.exit);
            } else if (compare1DepthObjects(profileData.group, { gender: 'female', ethnicity })) {
              femaleEthnicityProfile[ethnicity] = isEntrance ? GraphDataService.procesChartData(profileData.entrance) : GraphDataService.procesChartData(profileData.exit);
            }
          });
          // Age Profile data
          this.configDataService.AGE_CLASS.forEach(age => {
            if (compare1DepthObjects(profileData.group, { gender: 'male', age })) {
              maleAgeProfile[age] = isEntrance ? GraphDataService.procesChartData(profileData.entrance) : GraphDataService.procesChartData(profileData.exit);
            } else if (compare1DepthObjects(profileData.group, { gender: 'female', age })) {
              femaleAgeProfile[age] = isEntrance ? GraphDataService.procesChartData(profileData.entrance) : GraphDataService.procesChartData(profileData.exit);
            }
          });
        }
        // if (compare1DepthObjects(profileData.group, {})) {
        // }
        if (compare1DepthObjects(profileData.group, { profession: 'student' })) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            buildingStudentProfileTimePairData[building][diffToSelectedDate + 1] = isEntrance ? GraphDataService.procesChartData(profileData.entrance, false, false) : GraphDataService.procesChartData(profileData.exit, false, false);
          }
        }
        if (compare1DepthObjects(profileData.group, { mask: 'yes' })) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            buildingMaskProfileTimePairData[building][diffToSelectedDate + 1] = isEntrance ? GraphDataService.procesChartData(profileData.entrance, false, false) : GraphDataService.procesChartData(profileData.exit, false, false);
            buildingAllMaskProfileTimePairData[building][diffToSelectedDate + 1] += isEntrance ? GraphDataService.procesChartData(profileData.entrance, false, false) : GraphDataService.procesChartData(profileData.exit, false, false);

          }
        }
        if (compare1DepthObjects(profileData.group, { mask: 'no' })) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            buildingAllMaskProfileTimePairData[building][diffToSelectedDate + 1] += isEntrance ? GraphDataService.procesChartData(profileData.entrance, false, false) : GraphDataService.procesChartData(profileData.exit, false, false);
          }
        }
      }
      ethnicityProfileData.male = this.configDataService.ETHNICITY_CLASS.map(ethnicity => maleEthnicityProfile[ethnicity]);
      ethnicityProfileData.female = this.configDataService.ETHNICITY_CLASS.map(ethnicity => femaleEthnicityProfile[ethnicity]);
      ageProfileData.male = this.configDataService.AGE_CLASS.map(age => maleAgeProfile[age]);
      ageProfileData.female = this.configDataService.AGE_CLASS.map(age => femaleAgeProfile[age]);
    });
    const currentBuildingStudentProfileData: {
      [buildingName: string]: { current: number; diff: number; diffPercent: number };
    } = Object.entries(buildingStudentProfileTimePairData).reduce((prev, [buildingName, buildingPairData]) => {
      prev[buildingName] = {
        current: GraphDataService.procesChartData(buildingPairData[1]),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData((buildingPairData[1] - buildingPairData[0]) / buildingPairData[0] * 100, true, true)
      };
      return prev;
    }, {});
    const currentBuildingMaskRateProfileData: {
      [buildingName: string]: { current: number; diff: number; diffPercent: number };
    } = Object.entries(buildingMaskProfileTimePairData).reduce((prev, [buildingName, buildingPairData]) => {
      const maskPercentageTimePairData: [number, number] = [(buildingPairData[0] / buildingAllMaskProfileTimePairData[buildingName][0]) * 100, (buildingPairData[1] / buildingAllMaskProfileTimePairData[buildingName][1]) * 100];
      prev[buildingName] = {
        current: GraphDataService.procesChartData(maskPercentageTimePairData[1], false, true),
        diff: GraphDataService.procesChartData(buildingPairData[1] - buildingPairData[0], true, false),
        diffPercent: GraphDataService.procesChartData(maskPercentageTimePairData[1] - maskPercentageTimePairData[0], true, true)
      };
      return prev;
    }, {});
    if (lockNum < this.buildingAreaVisitorProfileCrossLevelTwoLock) { return; }
    this.currentBuildingAreaMaskProfileData$.next(currentBuildingMaskRateProfileData);
    this.currentBuildingAreaStudentProfileData$.next(currentBuildingStudentProfileData);
    // if (this.configDataService.isFeatureEnabled('graph_data', 'building_visitor_profile')) {
    //   this.ethnicityProfileData$.next(ethnicityProfileData);
    //   this.ageProfileData$.next(ageProfileData);
    // }
  }

  async deriveBuildingAreaSelectedVisitorProfileCrossLevelTwoData(visitorProfileDatas: IFetchData<AreaVisitorProfileData[]>[], selectedProfile: VisitorProfileSelection) {
    const trafficTrendLineChartData: number[] = [];
    const currentVisitorTrafficTrendtPair: [number, number] = [0, 0];
    const netShoppingTimeProfileChartData: number[] = [];
    const currentNetShoppingTimeProfilePair: [number, number] = [0, 0];
    const averageTimeSpentProfileChartData: number[] = [];
    const currentAverageTimeSpentProfilePair: [number, number] = [0, 0];
    const purchaseRateProfileData: number[] = [];
    const currentPurchaseRateProfilePair: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictObject(selectedProfile.age, selectedProfile.ethnicity, selectedProfile.gender as ('male' | 'female' | 'all'));
    const selectedProfilefilterWithPurchase = { ...selectedProfilefilter, purchase: true };
    GraphDataService.mapSevenDayLineChartData(visitorProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred || diffToSelectedDate > 0) {
        const fillValue = isPred || diffToSelectedDate > 0 ? null : 0;
        trafficTrendLineChartData.push(fillValue);
        netShoppingTimeProfileChartData.push(fillValue);
        averageTimeSpentProfileChartData.push(fillValue);
        purchaseRateProfileData.push(fillValue);
        return;
      }
      let isFound = false;
      let isFoundWithPurchase = false;
      const visitorProfileData = dataFiltered.data;
      let allProfileExit = 0;
      for (const profileData of visitorProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          allProfileExit = profileData.exit;
        }
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficTrendLineChartData.push(profileData.entrance);
          netShoppingTimeProfileChartData.push(profileData.net_shopping_time);
          averageTimeSpentProfileChartData.push(profileData.average_timespent);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentVisitorTrafficTrendtPair[diffToSelectedDate + 1] = profileData.entrance;
            currentAverageTimeSpentProfilePair[diffToSelectedDate + 1] = profileData.average_timespent;
            currentNetShoppingTimeProfilePair[diffToSelectedDate + 1] = profileData.net_shopping_time;
          }
        }
        if (compare1DepthObjects(profileData.group, selectedProfilefilterWithPurchase)) {
          isFoundWithPurchase = true;
          const purchaseRate = (profileData.exit / allProfileExit) * 100;
          purchaseRateProfileData.push(purchaseRate);
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentPurchaseRateProfilePair[diffToSelectedDate + 1] = purchaseRate;
          }
        }
      }
      if (!isFound) {
        trafficTrendLineChartData.push(0);
        netShoppingTimeProfileChartData.push(0);
        averageTimeSpentProfileChartData.push(0);
      }
      if (!isFoundWithPurchase) {
        purchaseRateProfileData.push(0);
      }
    });

  }

  async fetchBuildingAreaVisitorProfileCrossLevelTwoData(date: moment.Moment, lockNum: number) {
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const profile_cross_level = 2;
    const fetchURL = `/retail_customer_api_v2/api/v2/building/visitor-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&profile_cross_level=${profile_cross_level}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVisitorProfileData[]>[], number];
  }

  //#endregion building/visitor-profile profile-cross-level = 2

  //#region building/area-entrance-exit-by-hour
  async loadBuildingAreaEntranceExitByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    if (graphDataServiceInstance.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR).value) {
      graphDataServiceInstance.loadEntranceExitByHourNumberCardData(date, graphDataServiceInstance);
    }
    if (graphDataServiceInstance.configDataService.isFeatureEnabled('mock_data', 'building_entrance_exit_by_hour')) {
      graphDataServiceInstance.deriveEntranceExitByHourMockData(date);
      return;
    } else {
      return graphDataServiceInstance.fetchBuildingAreaEntranceExitByHourData(date, ++graphDataServiceInstance.buildingAreaEntranceExitByHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitByHourData(data, lockNum));
    }
  }

  deriveBuildingAreaEntranceExitByHourData(areaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number) {
    const buildingAreaEntranceExitBreakdownByHour: { [buildingName: string]: { entrance: number[]; exit: number[] } } = {};
    const buildingAreaBusiestTime: { [buildingName: string]: { headcount: number; hour: string } } = {};
    for (const buildingName of Object.keys(this.configDataService.FLOOR_OBJECTS)) {
      buildingAreaEntranceExitBreakdownByHour[buildingName] = { entrance: Array.of(this.configDataService.TIME_LIST.length).map(_ => 0), exit: Array.of(this.configDataService.TIME_LIST.length).map(_ => 0) };
      buildingAreaBusiestTime[buildingName] = { headcount: 0, hour: '10 AM' };
    }
    this.configDataService.TIME_LIST.map((time, idx) => {
      if (idx !== this.configDataService.TIME_LIST.length - 1) {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        const filteredAreaEntranceExitDatas = areaEntranceExitDatas.filter(ad => ad.hour === timeKey);
        if (filteredAreaEntranceExitDatas.length === 0) {
          return;
        } else {
          for (const areaEntranceExitData of filteredAreaEntranceExitDatas) {
            if (Object.keys(buildingAreaEntranceExitBreakdownByHour).includes(areaEntranceExitData.building)) {
              const buildingName = areaEntranceExitData.building;
              if (!areaEntranceExitData || !areaEntranceExitData.data) {
                const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
                const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
                buildingAreaEntranceExitBreakdownByHour[buildingName].entrance.push(fillValue);
                buildingAreaEntranceExitBreakdownByHour[buildingName].exit.push(fillValue);
                return;
              }
              if (areaEntranceExitData.hour === timeKey) {
                buildingAreaEntranceExitBreakdownByHour[buildingName].entrance[idx] = GraphDataService.procesChartData(areaEntranceExitData.data.entrance, false, false);
                buildingAreaEntranceExitBreakdownByHour[buildingName].exit[idx] = GraphDataService.procesChartData(areaEntranceExitData.data.exit, false, false);
              }
              if (buildingAreaBusiestTime[buildingName].headcount < areaEntranceExitData.data.entrance) {
                buildingAreaBusiestTime[buildingName].headcount = GraphDataService.procesChartData(areaEntranceExitData.data.entrance);
                buildingAreaBusiestTime[buildingName].hour = `${new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
              }
            }
          }
        }
      }
    });

    if (lockNum < this.buildingAreaEntranceExitByHourLock) { return; }
    if (this.configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance')) {
      this.buildingAreaBusiestTime$.next(buildingAreaBusiestTime);
    }
    this.buildingAreaEntranceExitByHourBreakdown$.next(buildingAreaEntranceExitBreakdownByHour);
  }

  async fetchBuildingAreaEntranceExitByHourData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    let fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    if (this.baseGraphData.getIsDisplayAverageValueForGraph(GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR).value) {
      const agg_type = qParams.periodType === ViewPeriod.DAYS ? undefined : 'divide_by_day';
      if (agg_type !== undefined) {
        fetchURL += `&aggregation_type=${agg_type}`;
      }
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }

  //#endregion building/area-entrance-exit-by-hour

  /** 
   * Vehicle parking entrance exit by-hour
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/entrance-exit-by-hour all-area
  async loadVehicleParkingEntranceExitByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleParkingEntranceExitByHourData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitByHourLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitByHourData(data, lockNum));
  }

  deriveVehicleParkingEntranceExitByHourData(vehicleParkingEntranceExitDatas: IFetchData<AreaVehicleParkingData>[], lockNum: number) {
    const vehicleParkingEntranceExitByHourTrend: { [areaName: string]: number[] } = {};
    const vehicleParkingBusiestTime: { [areaName: string]: { headcount: number; hour: string } } = {};
    const vehicleParkingEntranceExitHour: { [areaName: string]: { entrance: number[]; exit: number[] } } = {};
    const vehicleParkingEntranceExitBusiestTime: { [areaName: string]: { entrance: { headcount: number; hour: string }; exit: { headcount: number; hour: string } } } = {};
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        for (const parkingArea of floorData.parking) {
          vehicleParkingEntranceExitHour[parkingArea] = { entrance: [], exit: [] };
          vehicleParkingEntranceExitBusiestTime[parkingArea] = { entrance: { headcount: 0, hour: '10 AM' }, exit: { headcount: 0, hour: '10 AM' } };
          vehicleParkingEntranceExitByHourTrend[parkingArea] = [];
          vehicleParkingBusiestTime[parkingArea] = { headcount: 0, hour: '10 AM' };
        }
      }
    }
    for (const areaName of Object.keys(vehicleParkingEntranceExitByHourTrend)) {
      this.configDataService.VEHICLE_TIME_LIST.map(time => {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        const filteredAreaEntranceExitData = vehicleParkingEntranceExitDatas.find(ad => ad.hour === timeKey && ad.area === areaName);
        if (!filteredAreaEntranceExitData || !filteredAreaEntranceExitData.data) {
          const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
          const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
          vehicleParkingEntranceExitByHourTrend[areaName].push(fillValue);
          vehicleParkingEntranceExitHour[areaName].entrance.push(fillValue);
          vehicleParkingEntranceExitHour[areaName].exit.push(fillValue);
          return;
        }
        vehicleParkingEntranceExitHour[areaName].entrance.push(GraphDataService.procesChartData(filteredAreaEntranceExitData.data.entrance, false, false));
        vehicleParkingEntranceExitHour[areaName].exit.push(GraphDataService.procesChartData(filteredAreaEntranceExitData.data.exit, false, false));
        vehicleParkingEntranceExitByHourTrend[areaName].push(GraphDataService.procesChartData(filteredAreaEntranceExitData.data.entrance, false, false));
        if (vehicleParkingBusiestTime[areaName].headcount < filteredAreaEntranceExitData.data.entrance) {
          vehicleParkingBusiestTime[areaName].headcount = GraphDataService.procesChartData(filteredAreaEntranceExitData.data.entrance);
          // buildingAreaBusiestTime[buildingName].hour = `${new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })} - ${new Date(2020, 1, 1, parseInt(time, 10) + 1).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
          vehicleParkingBusiestTime[areaName].hour = `${new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
        }
        if (vehicleParkingEntranceExitBusiestTime[areaName].entrance.headcount < filteredAreaEntranceExitData.data.entrance) {
          vehicleParkingEntranceExitBusiestTime[areaName].entrance.headcount = GraphDataService.procesChartData(filteredAreaEntranceExitData.data.entrance);
          vehicleParkingEntranceExitBusiestTime[areaName].entrance.hour = `${new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
        }
        if (vehicleParkingEntranceExitBusiestTime[areaName].exit.headcount < filteredAreaEntranceExitData.data.exit) {
          vehicleParkingEntranceExitBusiestTime[areaName].exit.headcount = GraphDataService.procesChartData(filteredAreaEntranceExitData.data.exit);
          vehicleParkingEntranceExitBusiestTime[areaName].exit.hour = `${new Date(2020, 1, 1, parseInt(time, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
        }
      });
    }

    if (lockNum < this.vehicleParkingEntranceExitByHourLock) { return; }
    this.vehicleParkingVehicleByHourTrend$.next(vehicleParkingEntranceExitByHourTrend);
    this.vehicleParkingEntranceExitHour$.next(vehicleParkingEntranceExitHour);
    this.vehicleParkingBusiestTime$.next(vehicleParkingBusiestTime);
  }

  async fetchVehicleParkingEntranceExitByHourData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData>[], number];
  }
  //#endregion vehicle-parking/entrance-exit-by-hour

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/mode-of-transportation all area
  async loadVehicleParkingModeofTransportAllAreaData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleParkingModeofTransportAllAreaData(date, ++graphDataServiceInstance.vehicleParkingModeofTransportAllAreaLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingModeofTransportAllAreaData(data, lockNum));
  }

  deriveVehicleParkingModeofTransportAllAreaData(vehicleParkingModeofTransportData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const modeofTransportTrend: { [vehicleType: string]: number[] } = {};
    const modeofTransportProxTrend: { [vehicleType: string]: number[] } = {};
    const modeofTransportTrendAllArea: { [areaName: string]: { [vehicleType: string]: number[] } } = {};
    const modeofTransportProxTrendAllArea: { [areaName: string]: { [vehicleType: string]: number[] } } = {};
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        for (const parkingArea of floorData.parking) {
          modeofTransportTrendAllArea[parkingArea] = {};
          modeofTransportProxTrendAllArea[parkingArea] = {};
        }
      }
    }
    for (const areaName of Object.keys(modeofTransportTrendAllArea)) {
      const filteredVehicleParkingModeofTransportDatas = vehicleParkingModeofTransportData.filter(data => data.area === areaName);
      if (filteredVehicleParkingModeofTransportDatas.length === 0) {
        return;
      }
      GraphDataService.mapSevenDayLineChartData(filteredVehicleParkingModeofTransportDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          return;
        }
        const vehicleProfileData = dataFiltered.data;
        for (const profileData of vehicleProfileData) {
          const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
          modeofTransportTrendAllArea[areaName][vehicleTypeKey] = [];
          modeofTransportProxTrendAllArea[areaName][vehicleTypeKey] = [];
        }
      });
      GraphDataService.mapSevenDayLineChartData(filteredVehicleParkingModeofTransportDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
          Object.keys(modeofTransportTrendAllArea[areaName]).map(k => modeofTransportTrendAllArea[areaName][k].push(fillValue));
          Object.keys(modeofTransportProxTrendAllArea[areaName]).map(k => modeofTransportProxTrendAllArea[areaName][k].push(fillValue));
          return;
        }
        const vehicleProfileData = dataFiltered.data;
        const vehicleTypeUsed: string[] = [];
        for (const profileData of vehicleProfileData) {
          const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
          modeofTransportTrendAllArea[areaName][vehicleTypeKey].push(GraphDataService.procesChartData(profileData.entrance, false, false));
          modeofTransportProxTrendAllArea[areaName][vehicleTypeKey].push(GraphDataService.procesChartData(profileData.proximity, false, false));
          vehicleTypeUsed.push(vehicleTypeKey);
        }
        Object.keys(modeofTransportTrendAllArea[areaName]).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          modeofTransportTrendAllArea[areaName][noDataType].push(0);
        });
        Object.keys(modeofTransportProxTrend).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          modeofTransportProxTrendAllArea[areaName][noDataType].push(0);
        });
      });
    }
    if (lockNum < this.vehicleParkingModeofTransportAllAreaLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    this.vehicleParkingModeofTransportTrendAllArea$.next(modeofTransportTrendAllArea);
    this.vehicleParkingModeofTransportProxTrendAllArea$.next(modeofTransportProxTrendAllArea);
  }

  async fetchVehicleParkingModeofTransportAllAreaData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/mode-of-transportation?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }
  //#endregion vehicle-parking/mode-of-transportation all area

  //#region building/building-synergy
  async loadBuildingToBuildingSynergyData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingToBuildingSynergyData(date, ++graphDataServiceInstance.buildingToBuildingSynergyLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingToBuildingSynergyData(data, lockNum));
  }

  deriveBuildingToBuildingSynergyData(buildingToBuildingSynergyDatas: IFetchData<GroupCountData>[], lockNum: number) {
    const buildingToBuildingSynergyBarChart: { [buildingName: string]: { [buildingName: string]: number } } = {};
    const buildingToBuildingSynergyBarChartRaw: { [buildingName: string]: { [buildingName: string]: number } } = {};
    const currentBuildingEntranceExitData = this.currentBuildingHeadCountData$.getValue();
    // const mainBuilding = this.configDataService.MAIN_BUILDING;
    // const currentMainBuildingEntranceData = currentBuildingEntranceExitData?.[mainBuilding]?.entrance?.headCount || 0;
    const exlcudeList: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const buildingNameList: string[] = Object.keys(this.configDataService.FLOOR_OBJECTS).filter(k => !(exlcudeList.includes(k)));
    for (const buildingName of buildingNameList) {
      buildingToBuildingSynergyBarChart[buildingName] = {};
      buildingToBuildingSynergyBarChartRaw[buildingName] = {};
      for (const toBuildingName of buildingNameList) {
        if (buildingName !== toBuildingName) {
          buildingToBuildingSynergyBarChart[buildingName][toBuildingName] = 0;
          buildingToBuildingSynergyBarChartRaw[buildingName][toBuildingName] = 0;
        }
      }
    }

    for (const buildingName of buildingNameList) {
      const dataFiltered = buildingToBuildingSynergyDatas.find(d => d.area === buildingName);
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const buildingToBuildingSynergyData = dataFiltered.data;
      const currentMainBuildingEntranceData = currentBuildingEntranceExitData?.[buildingName]?.entrance?.headCount || 0;
      for (const toBuildingName of buildingNameList) {
        if (buildingName !== toBuildingName) {
          const countPercentage = buildingToBuildingSynergyData[toBuildingName].count / buildingToBuildingSynergyData._total.count;
          const roundedPercentage = Math.round(countPercentage * 100) / 100;
          buildingToBuildingSynergyBarChart[buildingName][toBuildingName] = GraphDataService.procesChartData(countPercentage * 100, false, false);
          buildingToBuildingSynergyBarChartRaw[buildingName][toBuildingName] = GraphDataService.procesChartData(roundedPercentage * currentMainBuildingEntranceData, false, false);
        }
      }
      // Object.entries(buildingToBuildingSynergyBarChart[buildingName]).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      // Object.entries(buildingToBuildingSynergyBarChartRaw[buildingName]).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }
    if (lockNum < this.buildingToBuildingSynergyLock) { return; }
    this.buildingToBuildingSynergy$.next(buildingToBuildingSynergyBarChart);
    this.buildingToBuildingSynergyRaw$.next(buildingToBuildingSynergyBarChartRaw);
    // this.buildingToBuildingSynergy$.next(Object.entries(buildingToBuildingSynergyBarChart).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    // this.buildingToBuildingSynergyRaw$.next(Object.entries(buildingToBuildingSynergyBarChartRaw).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
  }

  async fetchBuildingToBuildingSynergyData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/building-synergy?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}`; (this.http.get<IFetchData<GroupCountData>[]>(fetchURL, httpOptions));
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion building/building-synergy

  //#region store/floor-area
  async loadStoreFloorStoreAreaData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFloorStoreAreaData(date, ++graphDataServiceInstance.floorStoreAreaLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFloorStoreAreaData(data, lockNum));
  }

  deriveFloorStoreAreaData(floorStoreAreaDatas: IFetchData<StoreAreaData>[], lockNum: number) {
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    const floorNameList = Object.keys(this.configDataService.FLOOR_OBJECTS[mainBuilding]);
    const floorStoreAreaRaw: { [floorName: string]: number } = floorNameList.reduce((a, v) => ({ ...a, [v]: 0 }), {});
    for (const floorName of floorNameList) {
      const filteredFloorStoreAreaData = floorStoreAreaDatas.find(d => {
        const fixedAreaName = d.area.split('_');
        fixedAreaName.shift();
        return fixedAreaName.join('_') === floorName;
      });
      if (filteredFloorStoreAreaData === undefined || !filteredFloorStoreAreaData.data) {
        return;
      }
      floorStoreAreaRaw[floorName] = GraphDataService.procesChartData(filteredFloorStoreAreaData.data._total, false, false);
    }
    const sumAllStoreArea = Object.values(floorStoreAreaRaw).reduce((a, b) => a + b, 0);
    const floorStoreAreaPercentage: { [floorName: string]: number } = Object.entries(floorStoreAreaRaw).reduce((prev, [storeName, storeArea]) => {
      prev[storeName] = GraphDataService.procesChartData((storeArea / sumAllStoreArea) * 100, false, false);
      return prev;
    }, {});
    if (lockNum < this.floorStoreAreaLock) { return; }
    // this.floorStoreArea$.next(floorStoreAreaRaw);
    this.floorStoreAreaPercentage$.next(floorStoreAreaPercentage);
  }

  async fetchFloorStoreAreaData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/floor-area?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<StoreAreaData>[], number];
  }
  //#endregion store/floor-area

  //#region store/floor-area-by-category
  async loadStoreFloorStoreAreaCategoryData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchFloorStoreAreaCategoryData(date, ++graphDataServiceInstance.floorStoreAreaCategoryLock).then(([data, lockNum]) => graphDataServiceInstance.deriveFloorStoreAreaCategoryData(data, lockNum));
  }

  deriveFloorStoreAreaCategoryData(floorStoreAreaDatas: IFetchData<StoreAreaData>[], lockNum: number) {
    const mainBuilding = this.configDataService.MAIN_BUILDING;
    const floorNameList = Object.keys(this.configDataService.FLOOR_OBJECTS[mainBuilding]);
    const filteredStoreType = storeType.filter(stype => stype !== 'facilities');
    const floorStoreAreaCategoryRaw: { [floorName: string]: { [storeType: string]: number } } = floorNameList.reduce((a, v) => ({ ...a, [v]: filteredStoreType.reduce((arr, st) => ({ ...arr, [st]: 0 }), {}) }), {});
    const floorStoreAreaRawTotal: { [floorName: string]: number } = floorNameList.reduce((a, v) => ({ ...a, [v]: 0 }), {});
    for (const floorName of floorNameList) {
      const fixedFloorName = floorName.split('_');
      fixedFloorName.shift();
      const filteredFloorStoreAreaData = floorStoreAreaDatas.find(d => {
        const fixedAreaName = d.area.split('_');
        fixedAreaName.shift();
        return fixedAreaName.join('_') === floorName;
      });
      if (filteredFloorStoreAreaData === undefined || !filteredFloorStoreAreaData.data) {
        return;
      }
      floorStoreAreaRawTotal[floorName] = GraphDataService.procesChartData(filteredFloorStoreAreaData.data._total - filteredFloorStoreAreaData.data?.facilities, false, false);
      for (const storeTypeName of filteredStoreType) {
        floorStoreAreaCategoryRaw[floorName][storeTypeName] = GraphDataService.procesChartData(filteredFloorStoreAreaData.data[storeTypeName], false, false);
      }
    }
    const floorStoreAreaCategoryPercentage: { [floorName: string]: { [storeType: string]: number } } = Object.entries(floorStoreAreaCategoryRaw).reduce((prev, [floorName, storeCategory]) => {
      Object.entries(storeCategory).forEach(([storeCategoryName, storeCategoryArea]) => {
        prev[floorName][storeCategoryName] = GraphDataService.procesChartData((storeCategoryArea / floorStoreAreaRawTotal[floorName]) * 100, false, false);
      });
      return prev;
    }, Object.keys(floorStoreAreaRawTotal).reduce((prev, bldName) => {
      prev[bldName] = {};
      return prev;
    }, {}));
    if (lockNum < this.floorStoreAreaCategoryLock) { return; }
    this.floorStoreAreaCategory$.next(floorStoreAreaCategoryRaw);
    this.floorStoreArea$.next(floorStoreAreaRawTotal);
    this.floorStoreAreaCategoryPercentage$.next(floorStoreAreaCategoryPercentage);
  }

  async fetchFloorStoreAreaCategoryData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/floor-area-by-category?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<StoreAreaData>[], number];
  }
  //#endregion store/floor-area-by-category

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/mode-of-transportation-by-hour all area
  async loadVehicleParkingModeofTransportByHourAllAreaData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleParkingModeofTransportByHourAllAreaData(date, ++graphDataServiceInstance.vehicleParkingModeofTransportByHourAllAreaLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingModeofTransportByHourAllAreaData(data, lockNum));
  }

  deriveVehicleParkingModeofTransportByHourAllAreaData(vehicleParkingModeofTransportData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const modeofTransportTrend: { [vehicleType: string]: number[] } = {};
    const modeofTransportProxTrend: { [vehicleType: string]: number[] } = {};
    const modeofTransportTrendAllArea: { [areaName: string]: { [vehicleType: string]: number[] } } = {};
    const modeofTransportProxTrendAllArea: { [areaName: string]: { [vehicleType: string]: number[] } } = {};
    const vehicleModeTransportationClassData = this.configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS;
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        for (const parkingArea of floorData.parking) {
          modeofTransportTrendAllArea[parkingArea] = {};
          modeofTransportProxTrendAllArea[parkingArea] = {};
          for (const [className, displayName] of Object.entries(vehicleModeTransportationClassData)) {
            const vehicleTypeKey = className;
            modeofTransportTrendAllArea[parkingArea][vehicleTypeKey] = [];
            modeofTransportProxTrendAllArea[parkingArea][vehicleTypeKey] = [];
          }
        }
      }
    }
    // for (const areaName of Object.keys(modeofTransportTrendAllArea)) {
    //   this.configDataService.VEHICLE_TIME_LIST.map(time => {
    //     const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
    //     const filteredVehicleParkingModeofTransportDatas = vehicleParkingModeofTransportData.find(ad => ad.hour === timeKey && ad.area === areaName);
    //     if (filteredVehicleParkingModeofTransportDatas === undefined) {
    //       return;
    //     }
    //     const vehicleProfileData = filteredVehicleParkingModeofTransportDatas.data;
    //     for (const profileData of vehicleProfileData) {
    //       const vehicleTypeKey = profileData.group?.vehicle_type || 'total';
    //       modeofTransportTrendAllArea[areaName][vehicleTypeKey] = [];
    //       modeofTransportProxTrendAllArea[areaName][vehicleTypeKey] = [];
    //     }
    //   });
    // }
    for (const areaName of Object.keys(modeofTransportTrendAllArea)) {
      this.configDataService.VEHICLE_TIME_LIST.map(time => {
        const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
        const filteredVehicleParkingModeofTransportDatas = vehicleParkingModeofTransportData.find(ad => ad.hour === timeKey && ad.area === areaName);
        if (filteredVehicleParkingModeofTransportDatas === undefined) {
          for (const className of Object.keys(vehicleModeTransportationClassData)) {
            const vehicleTypeKey = className;
            modeofTransportTrendAllArea[areaName][vehicleTypeKey].push(0);
            modeofTransportProxTrendAllArea[areaName][vehicleTypeKey].push(0);
          }
          return;
        }
        const vehicleProfileData = filteredVehicleParkingModeofTransportDatas.data;
        const vehicleTypeUsed: string[] = [];
        for (const profileData of vehicleProfileData) {
          if (compare1DepthObjects(profileData.group, {})) {
            const vehicleTypeKey = 'total';
            modeofTransportTrendAllArea[areaName][vehicleTypeKey].push(GraphDataService.procesChartData(profileData.entrance, false, false));
            modeofTransportProxTrendAllArea[areaName][vehicleTypeKey].push(GraphDataService.procesChartData(profileData.proximity, false, false));
            vehicleTypeUsed.push(vehicleTypeKey);
          }
          else if (Object.keys(vehicleModeTransportationClassData).find(vc => vc === profileData.group.vehicle_type) !== undefined) {
            const vehicleTypeKey = profileData.group.vehicle_type;
            modeofTransportTrendAllArea[areaName][vehicleTypeKey].push(GraphDataService.procesChartData(profileData.entrance, false, false));
            modeofTransportProxTrendAllArea[areaName][vehicleTypeKey].push(GraphDataService.procesChartData(profileData.proximity, false, false));
            vehicleTypeUsed.push(vehicleTypeKey);
          }
        }
        Object.keys(modeofTransportTrendAllArea[areaName]).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          modeofTransportTrendAllArea[areaName][noDataType].push(0);
        });
        Object.keys(modeofTransportProxTrend).filter(typeName => !vehicleTypeUsed.includes(typeName)).forEach(noDataType => {
          modeofTransportProxTrendAllArea[areaName][noDataType].push(0);
        });
      });
    }
    if (lockNum < this.vehicleParkingModeofTransportByHourAllAreaLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleParkingModeofTransportByHourTrendAllArea$.next(modeofTransportTrendAllArea);
    // this.vehicleParkingModeofTransportProxTrendAllArea$.next(modeofTransportProxTrendAllArea);
  }

  async fetchVehicleParkingModeofTransportByHourAllAreaData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/mode-of-transportation-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }
  //#endregion vehicle-parking/mode-of-transportation-by-hour all area

  //#region building/traffic-breakdown
  async loadBuildingTrafficBreakdownData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingTrafficBreakdownData(date, ++graphDataServiceInstance.buildingTrafficBreakdownLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingTrafficBreakdownData(data, lockNum));
  }

  deriveBuildingTrafficBreakdownData(buildingTrafficBreakdownData: IFetchData<AreaTrafficBreakdownData[]>[], lockNum: number) {
    const buildingTrafficBreakdownPieData: { [buildingName: string]: { count: number } } = {};
    const areaName: string = this.configDataService.isFeatureEnabled('graph_data', 'building_traffic_breakdown')?.area[0] || this.configDataService.MAIN_BUILDING;
    const buildingTrafficBreakdownFilteredData = buildingTrafficBreakdownData.find(data => data.area === areaName);
    const buildingVisitorsBreakdown: { 'one': number; 'two': number; 'three': number } = { one: 0, two: 0, three: 0 };
    if (buildingTrafficBreakdownFilteredData === undefined || !buildingTrafficBreakdownFilteredData.data) {
      return;
    }
    if (this.viewPeriodService.isLiveMode) {
      this.buildingTrafficBreakdown$.next(buildingTrafficBreakdownPieData);
      this.buildingVisitorsBreakdown$.next(buildingVisitorsBreakdown);
      return;
    }
    const filteredDatas = buildingTrafficBreakdownFilteredData.data;
    for (const filteredData of filteredDatas) {
      if (filteredData.areas.length === 1) {
        buildingVisitorsBreakdown.one += filteredData.count;
      } else if (filteredData.areas.length === 2) {
        buildingVisitorsBreakdown.two += filteredData.count;
      } else if (filteredData.areas.length === 3) {
        buildingVisitorsBreakdown.three += filteredData.count;
      }
      buildingTrafficBreakdownPieData[filteredData.id] = { count: 0, };
      buildingTrafficBreakdownPieData[filteredData.id].count = GraphDataService.procesChartData(filteredData.count, false, false);
    }
    if (lockNum < this.buildingTrafficBreakdownLock) { return; }
    this.buildingTrafficBreakdown$.next(buildingTrafficBreakdownPieData);
    this.buildingVisitorsBreakdown$.next(buildingVisitorsBreakdown);
  }

  async fetchBuildingTrafficBreakdownData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/unique-traffic-breakdown?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaTrafficBreakdownData[]>[], number];
  }
  //#endregion building/traffic-breakdown

  //#region building/unique-traffic-breakdown
  async loadBuildingUniqueTrafficBreakdownData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingUniqueTrafficBreakdownData(date, ++graphDataServiceInstance.buildingUniqueTrafficBreakdownLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingUniqueTrafficBreakdownData(data, lockNum));
  }

  deriveBuildingUniqueTrafficBreakdownData(buildingTrafficBreakdownData: IFetchData<AreaTrafficBreakdownData[]>[], lockNum: number) {
    const buildingUniqueTrafficBreakdownPieData: { [buildingName: string]: number } = {};
    const currentBuildingUniqueTrafficBreakdownData: { [buildingName: string]: { count: number; diff: number; diffPercent: number } } = {};
    const areaName: string = this.configDataService.isFeatureEnabled('graph_data', 'building_unique_traffic_breakdown')?.area[0] || this.configDataService.MAIN_BUILDING;
    const buildingTrafficBreakdownFilteredData = buildingTrafficBreakdownData.find(data => data.area === areaName);
    const buildingNameList = Object.keys(this.configDataService.FLOOR_OBJECTS).filter(k => k !== areaName);
    for (const buildingName of buildingNameList) {
      buildingUniqueTrafficBreakdownPieData[buildingName] = 0;
    }
    if (buildingTrafficBreakdownFilteredData === undefined || !buildingTrafficBreakdownFilteredData.data) {
      return;
    }
    const filteredDatas = buildingTrafficBreakdownFilteredData.data;
    for (const filteredData of filteredDatas) {
      if (buildingNameList.find(k => k === filteredData.id) !== undefined) {
        buildingUniqueTrafficBreakdownPieData[filteredData.id] = GraphDataService.procesChartData(filteredData.count, false, false);
      }
      currentBuildingUniqueTrafficBreakdownData[filteredData.id] = {
        count: GraphDataService.procesChartData(filteredData.count, false, false),
        diff: 0,
        diffPercent: 0
      };
    }
    if (lockNum < this.buildingUniqueTrafficBreakdownLock) { return; }
    this.buildingUniqueTrafficBreakdown$.next(buildingUniqueTrafficBreakdownPieData);
    this.currentBuildingUniqueTrafficBreakdown$.next(currentBuildingUniqueTrafficBreakdownData);
  }

  async fetchBuildingUniqueTrafficBreakdownData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/unique-traffic?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaTrafficBreakdownData[]>[], number];
  }
  //#endregion building/unique-traffic-breakdown

  //#region traffic-site/count all-area
  async loadAllTrafficSiteCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchAllTrafficSiteCountData(date, ++graphDataServiceInstance.trafficSiteCountAllAreaLock).then(([data, lockNum]) => graphDataServiceInstance.deriveAllTrafficSiteCountData(data, lockNum));
  }

  deriveAllTrafficSiteCountData(trafficSiteCountDatas: IFetchData<GroupData>[], lockNum: number) {
    const trafficSiteByArea: { [areaName: string]: number } = {};
    for (const gateId of Object.keys(this.configDataService.DISPLAY_LANGUAGE.GATE_NAME)) {
      trafficSiteByArea[gateId] = -1;
    }
    for (const [areaName, areaValue] of Object.entries(trafficSiteByArea)) {
      if (trafficSiteCountDatas.find(d => d.area === areaName) !== undefined) {
        trafficSiteByArea[areaName] = GraphDataService.procesChartData(trafficSiteCountDatas.find(d => d.area === areaName).data.count);
      }
    }
    if (lockNum < this.trafficSiteCountAllAreaLock) { return; }
    this.trafficSiteCountByArea$.next(trafficSiteByArea);
  }

  async fetchAllTrafficSiteCountData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupData>[], number];
  }

  //#endregion traffic-site/count all-area

  //#region traffic-site/vehicle-profile for ads planner
  async loadPredictiveTrafficSiteVehicleProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if ((selectedInteractable?.type !== 'campaign') || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predTrafficSiteVehicleProfileLock;
      return Promise.resolve();
    }
    const startDate = moment(selectedInteractable.campaign.campaignStartDate);
    return graphDataServiceInstance.fetchPredictiveTrafficSiteVehicleProfileData(startDate, ++graphDataServiceInstance.predTrafficSiteVehicleProfileLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictiveTrafficSiteVehicleProfileData(data, lockNum));
  }

  derivePredictiveTrafficSiteVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    this.predUnfilteredVehicleProfileData$.next(trafficSiteVehicleProfileDatas);
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.purchasing_power) {
      tierListBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.car_brand) {
      carBrandBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.vehicle_type) {
      tranSportModeBreakdown[profileName] = 0;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        Object.keys(carBrandBreakdown).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tierListBreakdown).forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tranSportModeBreakdown).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.predTrafficSiteVehicleProfileLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    // this.predTrafficSiteProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    // this.predTrafficSiteProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    // this.predTrafficSiteProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
  }

  derivePredictiveSelectedTrafficSiteVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    if (!trafficSiteVehicleProfileDatas) { return; }
    const selectableCampaignData = this.baseGraphData.selectedInteractable$.getValue()?.campaign;
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.purchasing_power) {
      tierListBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.car_brand) {
      carBrandBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.vehicle_type) {
      tranSportModeBreakdown[profileName] = 0;
    }
    const dataFiltered = trafficSiteVehicleProfileDatas[0];
    if (!dataFiltered || !dataFiltered.data) {
      return;
    }
    let isFound = false;
    const vehicleProfileData = dataFiltered.data;
    for (const profileData of vehicleProfileData) {
      if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
        isFound = true;
        trafficSiteCountPair[0] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);
      }
      if (isFound) {
        Object.keys(tierListBreakdown).forEach(tier => {
          if (selectedProfilefilter?.purchasing_power) {
            if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length : 0, false, false);
            }
          } else {
            if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);

            }
          }
        });
        Object.keys(carBrandBreakdown).forEach(brand => {
          if (selectedProfilefilter?.car_brand) {
            if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length : 0, false, false);
            }
          } else {
            if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);
            }
          }
        });
        Object.keys(tranSportModeBreakdown).forEach(type => {
          if (selectedProfilefilter?.vehicle_type) {
            if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
              tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length : 0, false, false);
            }
          } else {
            if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
              if (type !== 'total') {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);
              }
            }
          }
        });
      }
    }
    // GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
    // handle missing data
    // if (!dataFiltered || !dataFiltered.data) {
    //   const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
    //   return;
    // }
    //   let isFound = false;
    //   const vehicleProfileData = dataFiltered.data;
    //   // console.log(vehicleProfileData);
    //   for (const profileData of vehicleProfileData) {
    //     if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
    //       isFound = true;
    //       // trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
    //     }
    //     if (isFound) {
    //       Object.keys(tierListBreakdown).forEach(tier => {
    //         if (selectedProfilefilter?.purchasing_power) {
    //           if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
    //             tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false);
    //           }
    //         } else {
    //           if (compare1DepthObjects(profileData.group, {...selectedProfilefilter, purchasing_power: tier})) {
    //             tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);

    //           }
    //         }
    //       });
    //       Object.keys(carBrandBreakdown).forEach(brand => {
    //         if (selectedProfilefilter?.car_brand) {
    //           if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
    //             carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false);
    //           }
    //         } else {
    //           if (compare1DepthObjects(profileData.group, {...selectedProfilefilter, car_brand: brand})) {
    //             carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
    //           }
    //         }
    //       });
    //       Object.keys(tranSportModeBreakdown).forEach(type => {
    //         if (selectedProfilefilter?.vehicle_type) {
    //           if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
    //             tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false);
    //           }
    //         } else {
    //           if (compare1DepthObjects(profileData.group, {...selectedProfilefilter, vehicle_type: type})) {
    //             if (type !== 'total') {
    //               tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
    //             }
    //           }
    //         }
    //       });
    //     }
    //   }
    // });
    // eslint-disable-next-line max-len
    trafficSiteCountPair[1] = randInt(trafficSiteCountPair[0], trafficSiteCountPair[0] + (trafficSiteCountPair[0] * 0.45));
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.predCurrentTrafficSiteProfileCountbyRange$.next({ minCount: trafficSiteCountPair[0], maxCount: trafficSiteCountPair[1] });
    this.predTrafficSiteProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.predTrafficSiteProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.predTrafficSiteProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
  }

  async fetchPredictiveTrafficSiteVehicleProfileData(date: moment.Moment, lockNum: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/vehicle-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=twintube-001`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/vehicle-profile for ads planner

  //#region traffic-site/count for ads planner
  async loadPredictiveTrafficSiteCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if ((selectedInteractable?.type !== 'campaign') || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predTrafficSiteCountLock;
      return Promise.resolve();
    }
    const startDate = moment(selectedInteractable.campaign.campaignStartDate);
    return graphDataServiceInstance.fetchPredictiveTrafficSiteCountData(startDate, ++graphDataServiceInstance.predTrafficSiteCountLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictiveTrafficSiteCountData(data, lockNum));
  }

  derivePredictiveTrafficSiteCountData(trafficSiteCountDatas: IFetchData<GroupData>[], lockNum: number) {
    if (!trafficSiteCountDatas) {
      return;
    }
    const selectableCampaignData = this.baseGraphData.selectedInteractable$.getValue()?.campaign;
    const adsExposureCountPair: [number, number] = [0, 0];
    const dataFiltered = trafficSiteCountDatas[0];
    const trafficSiteData = dataFiltered.data;
    adsExposureCountPair[0] = GraphDataService.procesChartData((randInt(3600, 7200) * (selectableCampaignData.campaignLoop * 0.5) * (selectableCampaignData.campaignPackage.length * 0.5)) / (60 * 60), false, false);
    adsExposureCountPair[1] = randInt(adsExposureCountPair[0], adsExposureCountPair[0] + adsExposureCountPair[0] + 0.45);
    if (lockNum < this.predTrafficSiteCountLock) { return; }
    this.predCurrentTrafficSiteAdsExposurebyRange$.next({ minCount: adsExposureCountPair[0], maxCount: adsExposureCountPair[1] });
  }

  async fetchPredictiveTrafficSiteCountData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=twintube-001`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupData>[], number];
  }
  //#endregion traffic-site/count for ads planner

  //#region traffic-site/vehicle-profile-unique-visitor for ads-planner
  async loadPredictiveTrafficSiteVehicleProfileUniqueVisitorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'campaign' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.predTrafficSiteVehicleProfileUniqueVisitorLock;
      return Promise.resolve();
    }
    return graphDataServiceInstance.fetchPredictiveTrafficSiteVehicleProfileUniqueVisitorData(date, ++graphDataServiceInstance.predictionTrafficSiteVehicleProfileUniqueVisitorLock).then(([data, lockNum]) => graphDataServiceInstance.derivePredictiveTrafficSiteVehicleProfileUniqueVisitorData(data, lockNum));
  }

  derivePredictiveTrafficSiteVehicleProfileUniqueVisitorData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    this.predUnfilteredVehicleProfileUniqueVisitorData$.next(trafficSiteVehicleProfileDatas);
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.purchasing_power) {
      tierListBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.car_brand) {
      carBrandBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.vehicle_type) {
      tranSportModeBreakdown[profileName] = 0;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        Object.keys(carBrandBreakdown).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tierListBreakdown).forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tranSportModeBreakdown).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.predTrafficSiteVehicleProfileUniqueVisitorLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    // this.predTrafficSiteProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    // this.predTrafficSiteProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    // this.predTrafficSiteProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
  }

  derivePredictiveSelectedTrafficSiteVehicleProfileUniqueVisitorData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficSiteCountPair: [number, number] = [0, 0];
    const trafficSiteAvgVisitorCountPair: [number, number] = [0, 0];
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    if (!trafficSiteVehicleProfileDatas) { return; }
    const selectableCampaignData = this.baseGraphData.selectedInteractable$.getValue()?.campaign;
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.purchasing_power) {
      tierListBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.car_brand) {
      carBrandBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.vehicle_type) {
      tranSportModeBreakdown[profileName] = 0;
    }
    const dataFiltered = trafficSiteVehicleProfileDatas[0];
    if (!dataFiltered || !dataFiltered.data) {
      return;
    }
    let isFound = false;
    const vehicleProfileData = dataFiltered.data;
    for (const profileData of vehicleProfileData) {
      if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
        isFound = true;
        trafficSiteCountPair[0] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);
        trafficSiteAvgVisitorCountPair[0] = GraphDataService.procesChartData(profileData.average_visitation_count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, true);
      }
      if (isFound) {
        Object.keys(tierListBreakdown).forEach(tier => {
          if (selectedProfilefilter?.purchasing_power) {
            if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length : 0, false, false);
            }
          } else {
            if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);
            }
          }
        });
        Object.keys(carBrandBreakdown).forEach(brand => {
          if (selectedProfilefilter?.car_brand) {
            if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length : 0, false, false);
            }
          } else {
            if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);
            }
          }
        });
        Object.keys(tranSportModeBreakdown).forEach(type => {
          if (selectedProfilefilter?.vehicle_type) {
            if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
              tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length : 0, false, false);
            }
          } else {
            if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
              if (type !== 'total') {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count * (selectableCampaignData.campaignLoop * 0.5) * selectableCampaignData.campaignPackage.length, false, false);
              }
            }
          }
        });
      }
    }
    // eslint-disable-next-line max-len
    trafficSiteCountPair[1] = randInt(trafficSiteCountPair[0], trafficSiteCountPair[0] + (trafficSiteCountPair[0] * 0.45));
    trafficSiteAvgVisitorCountPair[1] = randFloat(trafficSiteAvgVisitorCountPair[0], trafficSiteAvgVisitorCountPair[0] + (trafficSiteAvgVisitorCountPair[0] * 0.45));
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.predCurrentTrafficSiteProfileUniqueVisitorCountbyRange$.next({ minCount: trafficSiteCountPair[0], maxCount: trafficSiteCountPair[1] });
    this.predCurrentTrafficSiteAvgVisitorCountbyRange$.next({ minCount: trafficSiteAvgVisitorCountPair[0], maxCount: trafficSiteAvgVisitorCountPair[1] });
    this.predTrafficSiteProfileUniqueVisitorModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.predTrafficSiteProfileUniqueVisitorTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.predTrafficSiteProfileUniqueVisitorPurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
  }

  async fetchPredictiveTrafficSiteVehicleProfileUniqueVisitorData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/prediction/traffic-site/vehicle-profile-unique-visitor?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=twintube-001`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/vehicle-profile-unique-visitor for ads-planner

  //#region traffic-site/package-count-by-hour
  async loadTrafficSitePackageCountByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'package' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSitePackageCountByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchTrafficSitePackageCountByHourData(date, ++graphDataServiceInstance.trafficSitePackageCountByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePackageCountByHourData(data, lockNum));
  }

  deriveTrafficSitePackageCountByHourData(trafficSiteCountByHourDatas: IFetchData<GroupData>[], lockNum: number) {
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    const trafficByHourCount: number[] = [];
    const adsExposureByHour: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      const filteredData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
      const pushData = filteredData !== undefined ? filteredData?.data?.count : fillValue;
      const adsExposureData = filteredData !== undefined ? filteredData?.data?.exposure_time_count : fillValue;
      adsExposureByHour.push(GraphDataService.procesChartData((adsExposureData / 60), false, false));
      trafficByHourCount.push(pushData);
      trafficByHour[time] = pushData;
    });
    const peakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    if (lockNum < this.trafficSitePackageCountByHourLock) { return; }
    this.trafficSitePackageAdsExposureTimebyHour$.next(adsExposureByHour);
    // this.trafficSiteCountByHour$.next(trafficByHourCount);
    // this.trafficSitePeakTime$.next({
    //   timeKey: peakTime,
    //   count: trafficByHour[peakTime]
    // });
    // this.trafficSiteOffPeakTime$.next({
    //   timeKey: offPeakTime,
    //   count: trafficByHour[offPeakTime]
    // });
  }

  async fetchTrafficSitePackageCountByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/package-count-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupData>[], number];
  }
  //#endregion traffic-site/package-count-by-hour

  //#region traffic-site/package-vehicle-profile
  async loadTrafficSitePackageVehicleProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'package' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSitePackageVehicleProfileLock;
      return Promise.resolve();
    }
    graphDataServiceInstance.trafficSitePackageProfileCarBrandTrend = {};
    graphDataServiceInstance.trafficSitePackageProfileCountTrend = Array.from({ length: 7 }).map(() => null);
    graphDataServiceInstance.trafficSitePackageProfileModeOfTransportTrend = { total: Array.from({ length: 7 }).map(() => null) };
    graphDataServiceInstance.trafficSitePackageProfilePurchasingPowerTrend = {};
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.value;
    // if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
    //   return graphDataServiceInstance.fetchTrafficSitePackageVehicleProfileData(date, ++graphDataServiceInstance.trafficSitePackageVehicleProfileLock, areaName)
    //   .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePackageVehicleProfileData(data, lockNum))
    //   .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE));
    // }
    return graphDataServiceInstance.fetchTrafficSitePackageVehicleProfileData(date, ++graphDataServiceInstance.trafficSitePackageVehicleProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePackageVehicleProfileData(data, lockNum));
  }

  deriveTrafficSitePackageVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    this.unfilteredPackageVehicleProfileData$.next(trafficSiteVehicleProfileDatas);
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.purchasing_power) {
      tierListBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.car_brand) {
      carBrandBreakdown[profileName] = 0;
    }
    for (const profileName of this.configDataService.VEHICLE_PROFILE_CONFIG?.vehicle_type) {
      tranSportModeBreakdown[profileName] = 0;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        // if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
        //   this.trafficSitePackageProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
        //   this.trafficSitePackageProfileCarBrandTrend$.next(Object.entries(carBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
        //   this.trafficSitePackageProfilePurchasingPowerTrend$.next(Object.entries(tranSportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
        //   this.trafficSitePackageProfileModeOfTransportTrend$.next(Object.entries(tierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });  
        // }
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        Object.keys(carBrandTrend).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tierListTrend).forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            tierListTrend[tier].push(profileData.count);
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSitePackageVehicleProfileLock) { return; }
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
  }

  deriveSelectedTrafficSitePackageVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    if (!trafficSiteVehicleProfileDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData.count, false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData.count;
          }
        }
        if (isFound) {
          Object.keys(tierListTrend).forEach(tier => {
            if (selectedProfilefilter?.purchasing_power) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tierListTrend[tier].push(GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
                tierListTrend[tier].push(GraphDataService.procesChartData(profileData.count, false, false));
                if (diffToSelectedDate === 0) {
                  tierListBreakdown[tier] = GraphDataService.procesChartData(profileData.count, false, false);
                }
              }
            }
          });
          Object.keys(carBrandTrend).forEach(brand => {
            if (selectedProfilefilter?.car_brand) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(profileData.count, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData.count, false, false);
                }
              }
            }
          });
          Object.keys(tranSportModeTrend).forEach(type => {
            if (selectedProfilefilter?.vehicle_type) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tranSportModeTrend[type].push(GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData.count : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
                if (type !== 'total') {
                  tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
                  if (diffToSelectedDate === 0) {
                    tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
                  }
                }
              }
            }
          });
        }
      }
      if (!isFound) {
        trafficSiteVehicleProfileTrend.push(0);
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(0));
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(0));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend: { [tierName: string]: number[] } = selectedProfilefilter?.purchasing_power ? filteringList(tierListTrend, selectedProfilefilter?.purchasing_power) : tierListTrend;
    const filteredCarBrandTrend = selectedProfilefilter?.car_brand ? filteringList(carBrandTrend, selectedProfilefilter?.car_brand) : carBrandTrend;
    // eslint-disable-next-line max-len
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = selectedProfilefilter?.vehicle_type ? Object.entries(tranSportModeTrend).filter(([k, _v]) => k === selectedProfilefilter?.vehicle_type).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] } : tranSportModeTrend;
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSitePackageProfileCountTrend = trafficSiteVehicleProfileTrend;
    this.trafficSitePackageProfileCarBrandTrend = Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.trafficSitePackageProfileModeOfTransportTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    this.trafficSitePackageProfilePurchasingPowerTrend = Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    // if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
    //   this.trafficSitePackageProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
    //   this.trafficSitePackageProfileCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    //   this.trafficSitePackageProfilePurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    //   this.trafficSitePackageProfileModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });  
    // }
    this.trafficSitePackageProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
    this.trafficSitePackageProfileCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSitePackageProfilePurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSitePackageProfileModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });

    // this.callTrafficSitePackageVehicleProfilePrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));
    this.trafficSitePackageProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.trafficSitePackageProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.trafficSitePackageProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    this.currentTrafficSitePackageProfileCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPair[1] - trafficSiteCountPair[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPair[1] - trafficSiteCountPair[0]) / trafficSiteCountPair[0]) * 100, true, true)
    });
  }

  async fetchTrafficSitePackageVehicleProfileData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/package-vehicle-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/package-vehicle-profile

  //#region traffic-site/package-vehicle-profile-by-hour
  async loadTrafficSitePackageVehicleProfileByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'package' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSitePackageVehicleProfileByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchTrafficSitePackageVehicleProfileByHourData(date, ++graphDataServiceInstance.trafficSitePackageVehicleProfileByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePackageVehicleProfileByHourData(data, lockNum));
  }

  deriveTrafficSitePackageVehicleProfileByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.unfilteredPackageVehicleProfileByHourData$.next(trafficSiteCountByHourDatas);
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey)) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, {})) {
            const pushData = GraphDataService.procesChartData(profileData.count, false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(fillValue);
        trafficByHour[time] = fillValue;
      }
    });
    const peakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.keys(trafficByHour).filter(k => k !== '6.00' && k !== '24.00').reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    if (lockNum < this.trafficSitePackageVehicleProfileByHourLock) { return; }
    /*this.trafficSiteCountByHour$.next(trafficByHourCount);
    this.trafficSitePeakTime$.next({
      timeKey: peakTime,
      count: trafficByHour[peakTime]
    });
    this.trafficSiteOffPeakTime$.next({
      timeKey: offPeakTime,
      count: trafficByHour[offPeakTime]
    });*/
    // this.trafficSiteProfileCountByHour$.next(trafficByHourCount);
    // this.trafficSiteProfilePeakTime$.next({
    //   timeKey: peakTime,
    //   count: trafficByHour[peakTime]
    // });
    // this.trafficSiteProfileOffPeakTime$.next({
    //   timeKey: offPeakTime,
    //   count: trafficByHour[offPeakTime]
    // });
  }

  deriveSelectedTrafficSitePackageVehicleProfileByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey) !== undefined) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            const pushData = GraphDataService.procesChartData(profileData.count, false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(fillValue);
        trafficByHour[time] = fillValue;
      }
    });
    const peakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).filter(k => k !== '6.00' && k !== '24.00').reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    this.trafficSitePackageProfileCountByHour$.next(trafficByHourCount);
    this.trafficSitePackageProfilePeakTime$.next({
      timeKey: peakTime,
      count: trafficByHour[peakTime]
    });
    this.trafficSitePackageProfileOffPeakTime$.next({
      timeKey: offPeakTime,
      count: trafficByHour[offPeakTime]
    });
  }

  async fetchTrafficSitePackageVehicleProfileByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/package-vehicle-profile-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/vehicle-profile-by-hour

  //#region traffic-site/package-vehicle-profile-public-private
  async loadTrafficSitePackageVehicleProfilePublicPrivateData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'package' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSitePackageVehicleProfilePublicPrivateLock;
      return Promise.resolve();
    }
    graphDataServiceInstance.trafficSitePackageProfileModeOfTransportPublicPrivateTrend = { total: Array.from({ length: 7 }).map(() => null) };
    const areaName = selectedInteractable.name;
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    // if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
    //   return graphDataServiceInstance.fetchTrafficSitePackageVehicleProfilePublicPrivateData(date, ++graphDataServiceInstance.trafficSiteVehicleProfilePublicPrivateLock, areaName)
    //   .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePackageVehicleProfilePublicPrivateData(data, lockNum))
    //   .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE));
    // }
    return graphDataServiceInstance.fetchTrafficSitePackageVehicleProfilePublicPrivateData(date, ++graphDataServiceInstance.trafficSitePackageVehicleProfilePublicPrivateLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePackageVehicleProfilePublicPrivateData(data, lockNum));
  }

  deriveTrafficSitePackageVehicleProfilePublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    this.unfilteredPackageVehicleProfilePublicPrivateData$.next(trafficSiteVehicleProfilePublicPrivateDatas);
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type) {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData.count, false, false));
        }
        Object.keys(tranSportModeTrend).forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
              }
            }
          }
        });
      }
    });
    if (lockNum < this.trafficSitePackageVehicleProfilePublicPrivateLock) { return; }
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteProfileModeOfTransportPublicPrivateTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    // if (date.diff(moment(), periodType.toMomentCompareString()) < -1) { 
    //   this.trafficSiteProfileModeOfTransportPublicPrivateTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    // }
    // this.trafficSiteProfileModeOfTransportPublicPrivateBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));  
  }

  deriveSelectedTrafficSitePackageVehicleProfilePublicPrivateData(trafficSiteVehicleProfilePublicPrivateDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    if (!trafficSiteVehicleProfilePublicPrivateDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.vehicle_type === 'private' || profileData.group?.vehicle_type === 'public') {
          tranSportModeTrend[profileData.group?.vehicle_type] = [];
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfilePublicPrivateDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      let vehiclePrivate = 0;
      let vehiclePublic = 0;
      for (const profileData of vehicleProfileData) {
        Object.keys(tranSportModeTrend).forEach(type => {
          selectedProfilefilter.vehicle_type = type;
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            isFound = true;
            type === 'public' ? vehiclePublic = GraphDataService.procesChartData(profileData.count, false, false) : vehiclePrivate = GraphDataService.procesChartData(profileData.count, false, false);
            tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData.count, false, false));
            if (diffToSelectedDate === 0) {
              tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData.count, false, false);
            }
          }
        });
      }
      const sumTotal = vehiclePrivate + vehiclePublic;
      tranSportModeTrend.total.push(GraphDataService.procesChartData(sumTotal, false, false));
      if (!isFound) {
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSitePackageProfileModeOfTransportPublicPrivateTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    // if (date.diff(moment(), periodType.toMomentCompareString()) < -1) { 
    //   this.trafficSitePackageProfileModeOfTransportPublicPrivateTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    // }
    this.trafficSitePackageProfileModeOfTransportPublicPrivateTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    // this.callTrafficSitePackageVehicleProfilePublicPrivatePrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));
    this.trafficSitePackageProfileModeOfTransportPublicPrivateBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
  }

  async fetchTrafficSitePackageVehicleProfilePublicPrivateData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/package-vehicle-profile-public-private?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion traffic-site/package-vehicle-profile-public-private

  //#region traffic-site/package-count
  async loadTrafficSitePackageCountData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'package' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.trafficSitePackageCountLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    // const periodType =  graphDataServiceInstance.viewPeriodService.viewPeriod;
    // if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
    //   return graphDataServiceInstance.fetchTrafficSiteCountData(date, ++graphDataServiceInstance.trafficSiteCountLock, areaName)
    //   .then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSiteCountData(data, lockNum))
    //   .then(() => graphDataServiceInstance.loadPredictionTrafficSiteCountData(date));
    // }
    return graphDataServiceInstance.fetchTrafficSitePackageCountData(date, ++graphDataServiceInstance.trafficSitePackageCountLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveTrafficSitePackageCountData(data, lockNum));
  }

  deriveTrafficSitePackageCountData(trafficSiteCountDatas: IFetchData<GroupData>[], lockNum: number) {
    const trafficSiteCountPairData: [number, number] = [0, 0];
    const trafficSiteExposureTimeCountPairData: [number, number] = [0, 0];
    const exposureTimeUnit = this.configDataService.isFeatureEnabled('graph_data', 'traffic_site_package_count')?.exposure_time_unit;
    GraphDataService.mapSevenDayLineChartData(trafficSiteCountDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const trafficSiteCountData = dataFiltered.data;
      if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
        trafficSiteCountPairData[diffToSelectedDate + 1] = trafficSiteCountData.count;
        if (exposureTimeUnit === 'minute') {
          trafficSiteExposureTimeCountPairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(trafficSiteCountData.exposure_time_count / 60, false, false);
        } else if (exposureTimeUnit === 'hour') {
          trafficSiteExposureTimeCountPairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(trafficSiteCountData.exposure_time_count / (60 * 60), false, false);
        } else {
          trafficSiteExposureTimeCountPairData[diffToSelectedDate + 1] = GraphDataService.procesChartData(trafficSiteCountData.exposure_time_count, false, false);
        }
      }
    });
    this.currentTrafficSitePackageCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPairData[1] - trafficSiteCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPairData[1] - trafficSiteCountPairData[0]) / trafficSiteCountPairData[0]) * 100, true, true)
    });
    this.currentTrafficSitePackageExposureTimeCount$.next({
      count: GraphDataService.procesChartData(trafficSiteExposureTimeCountPairData[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteExposureTimeCountPairData[1] - trafficSiteExposureTimeCountPairData[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteExposureTimeCountPairData[1] - trafficSiteExposureTimeCountPairData[0]) / trafficSiteCountPairData[0]) * 100, true, true)
    });
    if (lockNum < this.trafficSitePackageCountLock) { return; }
  }

  async fetchTrafficSitePackageCountData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/traffic-site/package-count?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<GroupData>[], number];
  }

  //#endregion traffic-site/package-count

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/entrance-exit day in month
  async loadVehicleParkingEntranceExitMonthData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const queryDate = moment(date);
    const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('month_performance', 'area_name');
    if (!areaName) { return; }
    if (graphDataServiceInstance.vehicleParkingEntranceExitMonthLastFetch.month === queryDate.month() && graphDataServiceInstance.vehicleParkingEntranceExitMonthLastFetch.year === queryDate.year() && (Date.now() - graphDataServiceInstance.lastVehicleParkingEntranceExitMonthTime <= 5 * 60 * 1000)) { return; }
    return graphDataServiceInstance.fetchVehicleParkingEntranceExitMonthData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitMonthLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitMonthData(date, data, lockNum));
  }

  deriveVehicleParkingEntranceExitMonthData(date: moment.Moment, vehicleParkingEntranceExitDatas: IFetchData<AreaVehicleParkingData>[], lockNum: number) {
    const queryDate = date.clone();
    const entranceMonthData: { [date: string]: number } = {};
    const exitMonthData: { [date: string]: number } = {};
    for (const momentIt = date.clone().startOf('month'); momentIt <= date.clone().endOf('month'); momentIt.add(1, 'day')) {
      const dataIt = filterSelectedDateData(vehicleParkingEntranceExitDatas, momentIt, ViewPeriod.DAYS)[0];
      if (!dataIt || !dataIt.data) {
        entranceMonthData[momentIt.date()] = 0;
        exitMonthData[momentIt.date()] = 0;
        continue;
      } else {
        const entranceExitData = dataIt.data;
        entranceMonthData[momentIt.date()] = GraphDataService.procesChartData(entranceExitData.entrance);
        exitMonthData[momentIt.date()] = GraphDataService.procesChartData(entranceExitData.exit);
      }
    }
    if (lockNum < this.vehicleParkingEntranceExitMonthLock) { return; }
    this.vehicleParkingEntranceExitMonthLastFetch = { month: queryDate.month(), year: queryDate.year() };
    this.lastVehicleParkingEntranceExitMonthTime = Date.now();
    this.vehicleParkingEntranceMonthData$.next(entranceMonthData);
    this.vehicleParkingExitMonthData$.next(exitMonthData);
  }

  async fetchVehicleParkingEntranceExitMonthData(date: moment.Moment, lockNum: number, area?: string) {
    const queryDate = date.clone();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = {
      periodType: 'day',
      start_date: queryDate.endOf('month').format('YYYY-MM-DD'),
      num_interval: queryDate.daysInMonth(),
    };
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData>[], number];
  }

  //#endregion vehicle-parking/entrance-exit day in month

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/plate-province all area
  async loadVehicleParkingProvinceUngroupAllAreaData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'vehicle_parking') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.fetchVehicleParkingPlateProvinceUngroupAllAreaData(date, ++graphDataServiceInstance.vehicleParkingProvinceUngroupAllAreaLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingProvinceUngroupAllAreaData(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
      return graphDataServiceInstance.fetchVehicleParkingPlateProvinceUngroupAllAreaData(date, ++graphDataServiceInstance.vehicleParkingProvinceUngroupAllAreaLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingProvinceUngroupAllAreaData(data, lockNum));
    }
  }

  deriveVehicleParkingProvinceUngroupAllAreaData(vehicleParkingModeofTransportData: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const vehicleParkingProvinceEntranceExitTrend: { [areaName: string]: { [province: string]: { entrance: number[]; exit: number[] } } } = {};
    const vehicleParkingProvinceEntranceExitBreakdown: { [areaName: string]: { [province: string]: { entrance: number; exit: number } } } = {};
    const vehicleParkingProvinceAvgBreakdown: { [areaName: string]: { [province: string]: number } } = {};
    const vehicleParkingProvinceBreakdown: { [areaName: string]: { [province: string]: number } } = {};
    const vehicleParkingProvinceTop5Trend: { [areaName: string]: { [province: string]: number[] } } = {};
    const vehicleProvinceClassData = this.configDataService.DISPLAY_LANGUAGE.VEHICLE_PROVINCE_CLASS;
    const areaList: string[] = [];
    // const top5KeyVehicleParking: string[] = [];
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        if (!floorData.parking || floorData.parking.length === 0) {
          continue;
        }
        const filteredParkingAreas = floorData.parking.filter((parkingArea: string) =>
          vehicleParkingModeofTransportData.some((data) => data.area === parkingArea)
        );
        for (const parkingArea of filteredParkingAreas) {
          vehicleParkingProvinceEntranceExitTrend[parkingArea] = {};
          vehicleParkingProvinceEntranceExitBreakdown[parkingArea] = {};
          for (const provinceKey of Object.keys(vehicleProvinceClassData)) {
            vehicleParkingProvinceEntranceExitTrend[parkingArea][provinceKey] = { entrance: [], exit: [] };
            vehicleParkingProvinceEntranceExitBreakdown[parkingArea][provinceKey] = { entrance: 0, exit: 0 };
          }
          areaList.push(parkingArea);
        }
      }
    }
    for (const areaName of areaList) {
      const filteredVehicleParkingModeofTransportDatas = vehicleParkingModeofTransportData.filter(data => data.area === areaName);
      if (filteredVehicleParkingModeofTransportDatas.length === 0) {
        for (const provinceKey of Object.keys(vehicleProvinceClassData)) {
          vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].entrance.push(0);
          vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].exit.push(0);
        }
        return;
      }
      GraphDataService.mapSevenDayLineChartData(filteredVehicleParkingModeofTransportDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
          for (const provinceKey of Object.keys(vehicleProvinceClassData)) {
            vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].entrance.push(fillValue);
            vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].exit.push(fillValue);
          }
          return;
        }
        const vehicleProfileData = dataFiltered.data;
        for (const profileData of vehicleProfileData) {
          if (compare1DepthObjects(profileData.group, {})) {
            const provinceKey = 'total';
            vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].entrance.push(GraphDataService.procesChartData(profileData.entrance, false, false));
            vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].exit.push(GraphDataService.procesChartData(profileData.exit, false, false));
            if (diffToSelectedDate === 0) {
              vehicleParkingProvinceEntranceExitBreakdown[areaName][provinceKey].entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
              vehicleParkingProvinceEntranceExitBreakdown[areaName][provinceKey].exit = GraphDataService.procesChartData(profileData.exit, false, false);
            }
          }
          else if (Object.keys(vehicleProvinceClassData).find(vc => vc === profileData.group.province) !== undefined) {
            const provinceKey = profileData.group?.province;
            vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].entrance.push(GraphDataService.procesChartData(profileData.entrance, false, false));
            vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].exit.push(GraphDataService.procesChartData(profileData.exit, false, false));
            if (diffToSelectedDate === 0) {
              vehicleParkingProvinceEntranceExitBreakdown[areaName][provinceKey].entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
              vehicleParkingProvinceEntranceExitBreakdown[areaName][provinceKey].exit = GraphDataService.procesChartData(profileData.exit, false, false);
            }
          }
        }
      });
    }
    if (lockNum < this.vehicleParkingProvinceUngroupAllAreaLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    for (const [areaName, provinceObjData] of Object.entries(vehicleParkingProvinceEntranceExitTrend)) {
      vehicleParkingProvinceAvgBreakdown[areaName] = {};
      for (const [provinceName, trafficData] of Object.entries(provinceObjData)) {
        if (provinceName !== 'total') {
          vehicleParkingProvinceAvgBreakdown[areaName][provinceName] = this.configDataService.isEntranceDataMode ? Object.values(trafficData.entrance).reduce((a, b) => a + b, 0) / 7 : Object.values(trafficData.exit).reduce((a, b) => a + b, 0) / 7;
        }
      }
    }
    for (const [areaName, provinceObjData] of Object.entries(vehicleParkingProvinceAvgBreakdown)) {
      const top5KeyVehicleParking: string[] = Object.keys(sortableByVal(provinceObjData, 10));
      vehicleParkingProvinceTop5Trend[areaName] = {};
      vehicleParkingProvinceBreakdown[areaName] = {};
      vehicleParkingProvinceTop5Trend[areaName].total = this.configDataService.isEntranceDataMode ? vehicleParkingProvinceEntranceExitTrend[areaName].total.entrance : vehicleParkingProvinceEntranceExitTrend[areaName].total.exit;
      vehicleParkingProvinceBreakdown[areaName].total = this.configDataService.isEntranceDataMode ? vehicleParkingProvinceEntranceExitBreakdown[areaName].total.entrance : vehicleParkingProvinceEntranceExitBreakdown[areaName].total.exit;
      vehicleParkingProvinceTop5Trend[areaName].other = Array.from({ length: 7 }).map(() => null);
      vehicleParkingProvinceBreakdown[areaName].other = 0;
      for (const provinceKey of Object.keys(provinceObjData)) {
        const vehicleParkingProvinceTrend = this.configDataService.isEntranceDataMode ? vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].entrance : vehicleParkingProvinceEntranceExitTrend[areaName][provinceKey].exit;
        const vehicleParkingProvinceEntranceModeBreakdown = this.configDataService.isEntranceDataMode ? vehicleParkingProvinceEntranceExitBreakdown[areaName][provinceKey].entrance : vehicleParkingProvinceEntranceExitBreakdown[areaName][provinceKey].exit;
        if (top5KeyVehicleParking.includes(provinceKey)) {
          vehicleParkingProvinceTop5Trend[areaName][provinceKey] = vehicleParkingProvinceTrend;
          vehicleParkingProvinceBreakdown[areaName][provinceKey] = vehicleParkingProvinceEntranceModeBreakdown;
        }
        else {
          vehicleParkingProvinceTop5Trend[areaName].other = sumArrays(vehicleParkingProvinceTop5Trend[areaName].other, vehicleParkingProvinceTrend);
          vehicleParkingProvinceBreakdown[areaName].other += vehicleParkingProvinceEntranceModeBreakdown;
        }
      }
    }
    const vehicleParkingProvinceBreakdownnPercentageData: {
      [areaName: string]: { [provinceName: string]: number };
    } = Object.entries(vehicleParkingProvinceBreakdown).reduce((prev, [areaName, provinceObjData]) => {
      prev[areaName] = {};
      Object.entries(provinceObjData).forEach(([provinceName, trafficData]) => {
        if (provinceName !== 'total') {
          prev[areaName][provinceName] = GraphDataService.procesChartData(((trafficData / vehicleParkingProvinceBreakdown[areaName].total) * 100), false, true);
        }
      });
      return prev;
    }, {});
    this.vehicleParkingProvinceEntranceExitTrend$.next(vehicleParkingProvinceTop5Trend);
    this.vehicleParkingProvinceEntranceExitBreakdown$.next(vehicleParkingProvinceBreakdownnPercentageData);
  }

  async fetchVehicleParkingPlateProvinceUngroupAllAreaData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    let fetchURL = '';
    const qParams = this.getLineChartQueryParameter(date);
    if (area) {
      fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/provinces-ungroup?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    } else {
      fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/provinces-ungroup?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }

  //#endregion vehicle-parking/plate-province all area

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/entrance-exit avg by day type
  async loadVehicleParkingEntranceExitAvgDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'vehicle_parking') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.fetchVehicleParkingEntranceExitAvgDayTypeData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitAvgDayTypeLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitAvgDayTypeData(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
      return graphDataServiceInstance.fetchVehicleParkingEntranceExitAvgDayTypeData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitAvgDayTypeLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitAvgDayTypeData(data, lockNum));
    }
  }

  deriveVehicleParkingEntranceExitAvgDayTypeData(areaEntranceExitDatas: IFetchData<AreaVehicleParkingData>[], lockNum: number) {
    const entranceWeekdayTimePairData: [number, number] = [0, 0];
    const exitWeekdayTimePairData: [number, number] = [0, 0];
    const entranceWeekendTimePairData: [number, number] = [0, 0];
    const exitWeekendTimePairData: [number, number] = [0, 0];
    if (areaEntranceExitDatas.length > 0) {
      const storeAreaEntranceExitWeekdayDatas = areaEntranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() < 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      const storeAreaEntranceExitWeekendDatas = areaEntranceExitDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() >= 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      let indexWeekday = 0;
      let indexWeekend = 0;
      for (const storeAreaEntranceExitWeekdayData of storeAreaEntranceExitWeekdayDatas) {
        if (!storeAreaEntranceExitWeekdayData.data) {
          return;
        }
        const dataFiltered = storeAreaEntranceExitWeekdayData.data;
        const momentIt = moment(`${storeAreaEntranceExitWeekdayData.iso_year}-${storeAreaEntranceExitWeekdayData.month}-${storeAreaEntranceExitWeekdayData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (storeAreaEntranceExitWeekdayDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekdayTimePairData[1] = dataFiltered.entrance;
            exitWeekdayTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekdayTimePairData[0] = dataFiltered.entrance;
            exitWeekdayTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekdayTimePairData[indexWeekday] = dataFiltered.entrance;
          exitWeekdayTimePairData[indexWeekday] = dataFiltered.exit;
        }
        indexWeekday++;
      }
      for (const storeAreaEntranceExitWeekendData of storeAreaEntranceExitWeekendDatas) {
        if (!storeAreaEntranceExitWeekendData.data) {
          return;
        }
        const dataFiltered = storeAreaEntranceExitWeekendData.data;
        const momentIt = moment(`${storeAreaEntranceExitWeekendData.iso_year}-${storeAreaEntranceExitWeekendData.month}-${storeAreaEntranceExitWeekendData.day}`, 'YYYY-MM-DD');
        const selectedDate = this.viewPeriodService.selectedDate;
        if (storeAreaEntranceExitWeekendDatas.length === 1) {
          if (selectedDate.diff(momentIt, 'day') < 7) {
            entranceWeekendTimePairData[1] = dataFiltered.entrance;
            exitWeekendTimePairData[1] = dataFiltered.exit;
          }
          else {
            entranceWeekendTimePairData[0] = dataFiltered.entrance;
            exitWeekendTimePairData[0] = dataFiltered.exit;
          }
        } else {
          entranceWeekendTimePairData[indexWeekend] = dataFiltered.entrance;
          exitWeekendTimePairData[indexWeekend] = dataFiltered.exit;
        }
        indexWeekend++;
      }
    }
    // weekday
    const diffEntranceWeekday = entranceWeekdayTimePairData[1] - entranceWeekdayTimePairData[0];
    const diffEntranceWeekdayPercent = entranceWeekdayTimePairData[0] === 0 ? 0 : (diffEntranceWeekday / entranceWeekdayTimePairData[0]) * 100;
    const diffExitWeekday = exitWeekdayTimePairData[1] - exitWeekdayTimePairData[0];
    const diffExitWeekdayPercent = exitWeekdayTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekdayTimePairData[0]) * 100;
    // weekend
    const diffEntranceWeekend = entranceWeekendTimePairData[1] - entranceWeekendTimePairData[0];
    const diffEntranceWeekendPercent = entranceWeekendTimePairData[0] === 0 ? 0 : (diffEntranceWeekend / entranceWeekendTimePairData[0]) * 100;
    const diffExitWeekend = exitWeekendTimePairData[1] - exitWeekendTimePairData[0];
    const diffExitWeekendPercent = exitWeekendTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekendTimePairData[0]) * 100;
    this.currentVehicleParkingEntranceExitAvgWeekday$.next({
      entrance: { current: GraphDataService.procesChartData(entranceWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercent, true, true) },
      exit: { current: GraphDataService.procesChartData(exitWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercent, true, true) }
    });
    this.currentVehicleParkingEntranceExitAvgWeekend$.next({
      entrance: { current: GraphDataService.procesChartData(entranceWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercent, true, true) },
      exit: { current: GraphDataService.procesChartData(exitWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekendPercent, true, true) }
    });
  }

  async fetchVehicleParkingEntranceExitAvgDayTypeData(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&aggregation_type=average&by_mode=by_day_type&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData>[], number];
  }

  //#endregion vehicle-parking/entrance-exit

  //#region vehicle-parking/vehicle-purchasing-power
  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  async loadVehicleParkingAllAreaPurchasingPowerData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleParkingAllAreaPurchasingPowerData(date, ++graphDataServiceInstance.vehicleParkingPurchasingPowerAllAreaLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingAllAreaPurchasingPowerData(data, lockNum));
  }

  deriveVehicleParkingAllAreaPurchasingPowerData(vehiclePurchasingPowerDatas: IFetchData<VehiclePurchasingPowerData>[], lockNum: number) {
    const tierListPercentage: { [tierName: string]: number } = {};
    const tierList: { [tierName: string]: number } = {};
    const vehicleParkingTierListPercentage: { [areaName: string]: { [tierName: string]: number } } = {};
    const vehicleParkingtierList: { [areaName: string]: { [tierName: string]: number } } = {};
    const vehicleParkingPurchasingPowerClass = this.configDataService.DISPLAY_LANGUAGE.VEHICLE_PURCHASING_POWER_CLASS;
    const areaList: string[] = [];
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        for (const parkingArea of floorData.parking) {
          vehicleParkingtierList[parkingArea] = {};
          vehicleParkingTierListPercentage[parkingArea] = {};
          for (const tierKey of Object.keys(vehicleParkingPurchasingPowerClass)) {
            vehicleParkingtierList[parkingArea][tierKey] = 0;
            vehicleParkingTierListPercentage[parkingArea][tierKey] = 0;
          }
          areaList.push(parkingArea);
        }
      }
    }
    for (const areaName of areaList) {
      const filteredVehicleParkingModeofTransportDatas = vehiclePurchasingPowerDatas.filter(data => data.area === areaName);
      if (filteredVehicleParkingModeofTransportDatas.length === 0) {
        return;
      }
      GraphDataService.mapSevenDayLineChartData(vehiclePurchasingPowerDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
        if (!dataFiltered || !dataFiltered.data) {
          return;
        }
        Object.entries(dataFiltered.data).forEach(([tierName, tierData]) => {
          if (tierName.includes('count')) {
            return;
          }
          for (const tierKey of Object.keys(vehicleParkingPurchasingPowerClass)) {
            vehicleParkingtierList[areaName][tierKey] = Math.round(tierData);
            vehicleParkingTierListPercentage[areaName][tierKey] = (tierData / dataFiltered.data.count) * 100;
          }
        });
      });
    }

    if (lockNum < this.vehicleParkingPurchasingPowerAllAreaLock) { return; }
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const vehicleParkingTierListPercentageData: {
      [areaName: string]: { [tier: string]: number };
    } = Object.entries(vehicleParkingTierListPercentage).reduce((prev, [areaName, tierObjData]) => {
      prev[areaName] = sortableByVal(tierObjData);
      return prev;
    }, {});
    this.vehicleParkingAllAreaPurchasingPowerBreakdown$.next(vehicleParkingTierListPercentageData);
  }

  async fetchVehicleParkingAllAreaPurchasingPowerData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-purchasing-power?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VehiclePurchasingPowerData>[], number];
  }

  //#endregion vehicle-parking/vehicle-purchasing-power

  //#region vehicle-parking/vehicle-parking-synergy
  async loadVehicleParkingToVehicleParkingSynergyData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.getValue();
    const areaName = selectedInteractable?.name || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingToVehicleParkingSynergyData(date, ++graphDataServiceInstance.vehicleParkingToVehicleParkingSynergyLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingToVehicleParkingSynergyData(data, lockNum));
  }

  deriveVehicleParkingToVehicleParkingSynergyData(vehicleParkingToVehicleParkingSynergyDatas: IFetchData<GroupCountData>[], lockNum: number) {
    const vehicleParkingToVehicleParkingSynergyBarChart: { [areaName: string]: number } = {};
    // const vehicleParkingToVehicleParkingSynergyBarChartRaw: { [zoneName: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(vehicleParkingToVehicleParkingSynergyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const buildingToZoneSynergyData = dataFiltered.data;
      for (const [zoneName, zoneData] of Object.entries(buildingToZoneSynergyData)) {
        if (zoneName !== '_total') {
          const countPercentage = zoneData.count / buildingToZoneSynergyData._total.count;
          // const roundedPercentage = Math.round(countPercentage * 100) / 100;
          vehicleParkingToVehicleParkingSynergyBarChart[zoneName] = GraphDataService.procesChartData(countPercentage * 100, false, false);
          // vehicleParkingToVehicleParkingSynergyBarChartRaw[zoneName] = GraphDataService.procesChartData(roundedPercentage * currentMainBuildingEntranceData, false, false);
        }
      }
    });
    if (lockNum < this.vehicleParkingToVehicleParkingSynergyLock) { return; }
    this.vehicleParkingToVehicleParkingSynergyData$.next(Object.entries(vehicleParkingToVehicleParkingSynergyBarChart).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    // this.buildingZoneSynergyRaw$.next(Object.entries(buildingToZoneSynergyBarChartRaw).sort(([, a], [, b]) => b - a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
  }

  async fetchVehicleParkingToVehicleParkingSynergyData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-parking-synergy?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion vehicle-parking/vehicle-parking-synergy

  //#region vehicle-parking/plate-timespent all-area
  async loadVehicleParkingAllAreaPlateTimespentData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.getValue();
    if (selectedInteractable?.type === 'vehicle_parking') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.fetchVehicleParkingAllAreaPlateTimespentData(date, ++graphDataServiceInstance.vehicleParkingAllAreaPlateTimespentLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingAllAreaPlateTimespentData(data, lockNum));
    } else {
      const areaName = selectedVehicleProfile?.vehicle_parking ? selectedVehicleProfile?.vehicle_parking : graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
      return graphDataServiceInstance.fetchVehicleParkingAllAreaPlateTimespentData(date, ++graphDataServiceInstance.vehicleParkingAllAreaPlateTimespentLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingAllAreaPlateTimespentData(data, lockNum, areaName));
    }
  }

  async deriveVehicleParkingAllAreaPlateTimespentData(vehicleParkingPlateTimespentDatas: IFetchData<PlateTimespentData>[], lockNum: number, selectedArea?: string) {
    const binNameSet = new Set<string>();
    const binNameList = this.configDataService.BIN_TIMESPENT_LIST;
    const vehicleParkingPlateTimespentBinData: { [areaName: string]: { [binName: string]: number } } = {};
    const vehicleParkingPlateTimespentBinSelectedData: { [binName: string]: number } = {};
    const vehicleParkingTimespentPair: [number, number] = [0, 0];
    binNameList.forEach(binName => {
      binNameSet.add(binName);
    });
    const binNames = Array.from(binNameSet.values());
    const areaList: string[] = [];
    // const top5KeyVehicleParking: string[] = [];
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        if (!floorData.parking || floorData.parking.length === 0) {
          continue;
        }
        const filteredParkingAreas = floorData.parking.filter((parkingArea: string) =>
          vehicleParkingPlateTimespentDatas.some((data) => data.area === parkingArea)
        );
        for (const parkingArea of filteredParkingAreas) {
          const plateTimespentBinData: { [binName: string]: number } = binNames.reduce((prev, binName) => {
            prev[binName] = 0;
            return prev;
          }, {});
          if (Object.keys(plateTimespentBinData).length < 1) {
            // this.vehicleParkingAllAreaPlateTimespentBinData$.next(null);
            return;
          }
          vehicleParkingPlateTimespentBinData[parkingArea] = plateTimespentBinData;
          areaList.push(parkingArea);
        }
      }
    }
    for (const areaName of areaList) {
      const filteredVehicleParkingPlateTimespentDatas = vehicleParkingPlateTimespentDatas.filter(data => data.area === areaName);
      if (filteredVehicleParkingPlateTimespentDatas.length === 0) {
        this.vehicleParkingAllAreaPlateTimespentBinData$.next(vehicleParkingPlateTimespentBinData);
        return;
      }
      GraphDataService.mapSevenDayLineChartData(filteredVehicleParkingPlateTimespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
        if (!dataFiltered || !dataFiltered.data) {
          return;
        }
        const plateTimespentData = dataFiltered.data;
        Object.keys(vehicleParkingPlateTimespentBinData[areaName]).forEach(binKey => {
          if (!plateTimespentData[binKey]) {
            return;
          }
          if (areaName === selectedArea) {
            vehicleParkingPlateTimespentBinSelectedData[binKey] = GraphDataService.procesChartData((plateTimespentData[binKey].count / plateTimespentData._total.count) * 100, false, false);
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              vehicleParkingTimespentPair[diffToSelectedDate + 1] = GraphDataService.procesChartData(plateTimespentData._total.average_timespent, false, false);
            }
          }
          vehicleParkingPlateTimespentBinData[areaName][binKey] = GraphDataService.procesChartData((plateTimespentData[binKey].count / plateTimespentData._total.count) * 100, false, false);
        });
      });
    }
    if (lockNum < this.vehicleParkingAllAreaPlateTimespentLock) { return; }
    // const selectedVehicleProfile = this.selectedVehicleProfile$.getValue();
    // if (  
    //   selectedVehicleProfile.vehicle_parking !== undefined &&
    //   selectedVehicleProfile.vehicle_type === undefined &&
    //   selectedVehicleProfile.car_brand === undefined &&
    //   selectedVehicleProfile.purchasing_power === undefined
    // ) {
    //   this.currentTrafficSiteProfileAvgTimespent$.next({
    //     avgTimespent: GraphDataService.procesChartData(vehicleParkingTimespentPair[1], false, false),
    //     diff: GraphDataService.procesChartData(vehicleParkingTimespentPair[1] - vehicleParkingTimespentPair[0], true, false),
    //     diffPercent: vehicleParkingTimespentPair[0] === 0 ? 0 : GraphDataService.procesChartData(((vehicleParkingTimespentPair[1] - trafficSiteTimespentPair[0]) / trafficSiteTimespentPair[0]) * 100, true, true)
    //   });
    // }
    this.vehicleParkingAllAreaPlateTimespentBinSelectedData$.next(vehicleParkingPlateTimespentBinSelectedData);
    this.vehicleParkingAllAreaPlateTimespentBinData$.next(vehicleParkingPlateTimespentBinData);
  }

  async fetchVehicleParkingAllAreaPlateTimespentData(date: moment.Moment, lockNum: number, area?: string) {
    let fetchURL = '';
    const qParams = this.getOneSelectedQueryParameter(date);
    if (area) {
      fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/timespent?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    } else {
      fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/timespent?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<PlateTimespentData>[], number];
  }

  //#endregion vehicle-parking/plate-timespent all-area

  //#region vehicle-parking/vehicle-parking-unique-area-visit
  async loadVehicleParkingToVehicleParkingUniqueAreaVisitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.getValue();
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.getValue();
    if (selectedInteractable?.type === 'vehicle_parking') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.fetchVehicleParkingToVehicleParkingUniqueAreaVisitData(date, ++graphDataServiceInstance.vehicleParkingUniqueAreaVisitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingToVehicleParkingUniqueAreaVisitData(data, lockNum));
    } else {
      const areaName = selectedVehicleProfile?.vehicle_parking ? selectedVehicleProfile?.vehicle_parking : graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
      return graphDataServiceInstance.fetchVehicleParkingToVehicleParkingUniqueAreaVisitData(date, ++graphDataServiceInstance.vehicleParkingUniqueAreaVisitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingToVehicleParkingUniqueAreaVisitData(data, lockNum));
    }
  }

  deriveVehicleParkingToVehicleParkingUniqueAreaVisitData(vehicleParkingToVehicleParkingUniqueAreaVisitDatas: IFetchData<VehicleParkingUniqueAreaVisitData>[], lockNum: number) {
    const vehicleParkingUniqueAreaVisitCountData: { one: number; two_three: number; four_up: number } = { one: 0, two_three: 0, four_up: 0 };
    let totalVisitCount = 0;
    // const vehicleParkingToVehicleParkingSynergyBarChartRaw: { [zoneName: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(vehicleParkingToVehicleParkingUniqueAreaVisitDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        this.vehicleParkingToVehicleParkingUniqueAreaVisitData$.next(vehicleParkingUniqueAreaVisitCountData);
        return;
      }
      const vehicleParkingUniqueAreaVisitData = dataFiltered.data;
      totalVisitCount = vehicleParkingUniqueAreaVisitData.total_unique_visitors;
      for (const [visitName, visitCount] of Object.entries(vehicleParkingUniqueAreaVisitData.number_of_unique_area_visit)) {
        const times = parseInt(visitName, 10);
        if (times === 1) {
          vehicleParkingUniqueAreaVisitCountData.one = (vehicleParkingUniqueAreaVisitCountData.one || 0) + visitCount;
        } else if (times >= 2 && times <= 3) {
          vehicleParkingUniqueAreaVisitCountData.two_three = (vehicleParkingUniqueAreaVisitCountData.two_three || 0) + visitCount;
        } else if (times >= 4 && times <= 50) {
          vehicleParkingUniqueAreaVisitCountData.four_up = (vehicleParkingUniqueAreaVisitCountData.four_up || 0) + visitCount;
        }
      }
    });
    if (lockNum < this.vehicleParkingUniqueAreaVisitLock) { return; }
    const vehicleParkingUniqueAreaVisitPercentageData: {
      one: number; two_three: number; four_up: number;
    } = Object.entries(vehicleParkingUniqueAreaVisitCountData).reduce((prev, [visitName, visitCount]) => {
      const countPercentage = visitCount / totalVisitCount;
      prev[visitName] = GraphDataService.procesChartData(countPercentage * 100, false, false);
      return prev;
    }, { one: 0, two_three: 0, four_up: 0 });
    this.vehicleParkingToVehicleParkingUniqueAreaVisitData$.next(vehicleParkingUniqueAreaVisitPercentageData);
  }

  async fetchVehicleParkingToVehicleParkingUniqueAreaVisitData(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-parking-unique-area-visit?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<VehicleParkingUniqueAreaVisitData>[], number];
  }
  //#endregion vehicle-parking/vehicle-parking-unique-area-visit

  /** 
   * Vehicle parking
   * The [area] key used for data access is the same as in traffic-site. (but we using organizaiton e.g. foodie)
   * using entrance instead count
   */
  //#region vehicle-parking/plate-number-definition
  async loadVehicleParkingPlateNumberDefinitionData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.getValue();
    const areaName = selectedInteractable?.name || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingPlateNumberDefinitionData(date, ++graphDataServiceInstance.vehicleParkingPlateNumberDefinitionLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingPlateNumberDefinitionData(data, lockNum));
  }

  deriveVehicleParkingPlateNumberDefinitionData(vehicleParkingToVehicleParkingUniqueAreaVisitDatas: IFetchData<AreaVehicleParkingData[]>[], lockNum: number) {
    const vehicleParkingPlateNumberDefinitionData: { [definitionName: string]: { entrance: number; exit: number } } = {};
    const totalVehicleParkingPlateNumberDefinition: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
    const currentvehicleParkingPlateNumberDefinitionTimePairData: { [definitionName: string]: [number, number] } = {};
    const vehiclePlateDefinitionClassData = this.configDataService.DISPLAY_LANGUAGE.VEHICLE_PLATE_DEFININTON_CLASS;
    const channelEntrnaceMode = this.configDataService.isEntranceDataMode;
    for (const plateDefintionKey of Object.keys(vehiclePlateDefinitionClassData)) {
      vehicleParkingPlateNumberDefinitionData[plateDefintionKey] = { entrance: 0, exit: 0 };
      currentvehicleParkingPlateNumberDefinitionTimePairData[plateDefintionKey] = [0, 0];
    }
    // const vehicleParkingToVehicleParkingSynergyBarChartRaw: { [zoneName: string]: number } = {};
    GraphDataService.mapSevenDayLineChartData(vehicleParkingToVehicleParkingUniqueAreaVisitDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          totalVehicleParkingPlateNumberDefinition.entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
          totalVehicleParkingPlateNumberDefinition.exit = GraphDataService.procesChartData(profileData.entrance, false, false);
        }
        else if (Object.keys(vehiclePlateDefinitionClassData).find(vc => vc === profileData.group.plate_number_definition) !== undefined) {
          const plateDefintionKey = profileData.group?.plate_number_definition;
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            currentvehicleParkingPlateNumberDefinitionTimePairData[plateDefintionKey][diffToSelectedDate + 1] = channelEntrnaceMode ? GraphDataService.procesChartData(profileData.entrance, false, false) : GraphDataService.procesChartData(profileData.exit, false, false);
          }
          vehicleParkingPlateNumberDefinitionData[plateDefintionKey].entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
          vehicleParkingPlateNumberDefinitionData[plateDefintionKey].exit = GraphDataService.procesChartData(profileData.exit, false, false);
        }
      }
    });
    if (lockNum < this.vehicleParkingPlateNumberDefinitionLock) { return; }
    const vehicleParkingPlateNumberDefinitionPercentageData: {
      [definitionName: string]: number;
    } = Object.entries(vehicleParkingPlateNumberDefinitionData).reduce((prev, [definitionName, definitionObj]) => {
      const countPercentage = this.configDataService.isEntranceDataMode
        ? definitionObj.entrance / totalVehicleParkingPlateNumberDefinition.entrance
        : definitionObj.exit / totalVehicleParkingPlateNumberDefinition.exit;
      prev[definitionName] = GraphDataService.procesChartData(countPercentage * 100, false, true);
      return prev;
    }, {});
    const currentVehicleParkingPlateNumberDefinitionData: {
      [definitionName: string]: { count: number; diff: number; diffPercent: number };
    } = Object.entries(currentvehicleParkingPlateNumberDefinitionTimePairData).reduce((prev, [definitionName, definitionObj]) => {
      const diffCount = definitionObj[1] - definitionObj[0];
      const diffPercent = (diffCount / definitionObj[0]) * 100;
      prev[definitionName] = {
        count: GraphDataService.procesChartData(definitionObj[1], false, false),
        diff: GraphDataService.procesChartData(diffCount, true, true),
        diffPercent: GraphDataService.procesChartData(diffPercent, true, true),
      };
      return prev;
    }, {});
    // this.vehicleParkingPlateNumberDefinitonData$.next(vehicleParkingPlateNumberDefinitionPercentageData);
    // this.currentVehicleParkingPlateNumberDefinitonData$.next(currentVehicleParkingPlateNumberDefinitionData);
  }

  async fetchVehicleParkingPlateNumberDefinitionData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-profile?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}&profile_cross_level=1`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaVehicleParkingData[]>[], number];
  }
  //#endregion vehicle-parking/plate-number-definition

  //#region vehicle-parking/vehicle-profile
  async loadVehicleParkingVehicleProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedDirectory$.value;
    const selectedInteractableObj = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const areaName = selectedInteractableObj?.type === 'vehicle_parking' ? selectedInteractableObj?.name : selectedInteractable?.zone || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.getValue();
    // if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
    //   // clear data
    //   ++graphDataServiceInstance.vehicleParkingVehicleProfileLock;
    //   return Promise.resolve();
    // }
    graphDataServiceInstance.trafficSiteProfileCarBrandTrend = {};
    graphDataServiceInstance.trafficSiteProfileCountTrend = Array.from({ length: 7 }).map(() => null);
    graphDataServiceInstance.trafficSiteProfileModeOfTransportTrend = { total: Array.from({ length: 7 }).map(() => null) };
    graphDataServiceInstance.trafficSiteProfilePurchasingPowerTrend = {};
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    // if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
    //   return graphDataServiceInstance.fetchVehicleParkingVehicleProfileData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileLock, areaName)
    //   .then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileData(data, lockNum));
    //   // .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE));
    // }
    return graphDataServiceInstance.fetchVehicleParkingVehicleProfileData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileData(data, lockNum, selectedVehicleProfile));
  }

  deriveVehicleParkingVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number, selectedVehicleProfile: { [selectorName: string]: string }) {
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const trafficSiteTimespentPair: [number, number] = [0, 0];
    const trafficSiteEntranceExit: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const vehicleClassData = Object.keys(this.configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS);
    const vehiclePurchasingPowerClass = Object.keys(this.configDataService.DISPLAY_LANGUAGE.VEHICLE_PURCHASING_POWER_CLASS);
    const channelMode = this.configDataService.isEntranceDataMode ? 'entrance' : 'exit';
    this.unfilteredVehicleProfileData$.next(trafficSiteVehicleProfileDatas);
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          tierListTrend[profileData.group?.purchasing_power] = [];
        }
        else if (profileData.group?.vehicle_type) {
          if (vehicleClassData.includes(profileData.group?.vehicle_type)) {
            tranSportModeTrend[profileData.group?.vehicle_type] = [];
          }
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        vehiclePurchasingPowerClass.forEach(tier => {
          if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
            tierListTrend[tier].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
            if (diffToSelectedDate === 0) {
              tierListBreakdown[tier] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
            }
          }
        });
        Object.keys(carBrandTrend).forEach(brand => {
          if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
            carBrandTrend[brand].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
            if (diffToSelectedDate === 0) {
              carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
            }
          }
        });
        vehicleClassData.forEach(type => {
          if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
            if (type !== 'total') {
              tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
              if (diffToSelectedDate === 0) {
                tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
              }
            }
          }
        });
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = tierListTrend;
    const filteredCarBrandTrend = carBrandTrend;
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = tranSportModeTrend;
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.vehicleParkingProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    this.vehicleParkingProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.vehicleParkingProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    if (lockNum < this.vehicleParkingVehicleProfileLock) { return; }

  }

  deriveSelectedVehicleParkingVehicleProfileData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power, selectedVehicleProfile.plate_number_definition);
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const trafficSiteTimespentPair: [number, number] = [0, 0];
    const trafficSiteEntranceExit: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const vehiclePlateDefinitionTrend: { buyer: number[]; seller: number[] } = { buyer: [], seller: [] };
    const vehiclePlateDefinitionBreakdown: { buyer: number; seller: number } = { buyer: 0, seller: 0 };
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const vehicleClassData = Object.keys(this.configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS);
    // const vehiclePlateDefinitionClassData = Object.keys(this.configDataService.DISPLAY_LANGUAGE.VEHICLE_PLATE_DEFININTON_CLASS);
    const channelMode = this.configDataService.isEntranceDataMode ? 'entrance' : 'exit';
    const vehiclePlateDefinitionClassData = this.configDataService.DISPLAY_LANGUAGE.VEHICLE_PLATE_DEFININTON_CLASS;
    const vehicleParkingPlateNumberDefinitionData: { [definitionName: string]: { entrance: number; exit: number } } = {};
    const totalVehicleParkingPlateNumberDefinition: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
    let vehicleParkingAccumulateExcluded = 0;
    const currentvehicleParkingPlateNumberDefinitionTimePairData: { [definitionName: string]: [number, number] } = {};
    const channelEntrnaceMode = this.configDataService.isEntranceDataMode;
    const vehicleParkingAccumulateExcludedList: string[] = this.configDataService.FEATURE_FLAGS?.graph_data?.vehicle_accumulate?.exclude || [];
    for (const plateDefintionKey of Object.keys(vehiclePlateDefinitionClassData)) {
      vehicleParkingPlateNumberDefinitionData[plateDefintionKey] = { entrance: 0, exit: 0 };
      currentvehicleParkingPlateNumberDefinitionTimePairData[plateDefintionKey] = [0, 0];
    }
    if (!trafficSiteVehicleProfileDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          if (profileData.group?.purchasing_power !== 'NA') {
            tierListTrend[profileData.group?.purchasing_power] = [];
          }
        }
        else if (profileData.group?.vehicle_type) {
          if (vehicleClassData.includes(profileData.group?.vehicle_type)) {
            tranSportModeTrend[profileData.group?.vehicle_type] = [];
          }
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
        // else if (profileData.group?.plate_number_definition) {
        //   if (vehiclePlateDefinitionClassData.includes(profileData.group?.plate_number_definition)) {
        //     vehiclePlateDefinitionTrend[profileData.group?.plate_number_definition] = [];
        //   }
        // }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        Object.keys(vehiclePlateDefinitionTrend).map(k => vehiclePlateDefinitionTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, {})) {
          totalVehicleParkingPlateNumberDefinition.entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
          totalVehicleParkingPlateNumberDefinition.exit = GraphDataService.procesChartData(profileData.exit, false, false);
        }
        if (vehicleParkingAccumulateExcludedList.find(vehicleType => vehicleType === profileData?.group?.vehicle_type)) {
          const accumulate = GraphDataService.procesChartData(profileData.entrance - profileData.exit, false, false);
          vehicleParkingAccumulateExcluded += accumulate;
        }
        if (selectedProfilefilter.plate_number_definition === undefined) {
          for (const plate_number_definition of Object.keys(vehiclePlateDefinitionClassData)) {
            if (compare1DepthObjects(profileData.group, { plate_number_definition })) {
              const plateDefintionKey = profileData.group?.plate_number_definition;
              if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
                currentvehicleParkingPlateNumberDefinitionTimePairData[plateDefintionKey][diffToSelectedDate + 1] = channelEntrnaceMode ? GraphDataService.procesChartData(profileData.entrance, false, false) : GraphDataService.procesChartData(profileData.exit, false, false);
              }
              vehicleParkingPlateNumberDefinitionData[plateDefintionKey].entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
              vehicleParkingPlateNumberDefinitionData[plateDefintionKey].exit = GraphDataService.procesChartData(profileData.exit, false, false);
            }
          }
        }
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          if (Object.keys(vehiclePlateDefinitionClassData).find(vc => vc === selectedProfilefilter.plate_number_definition) !== undefined) {
            const plateDefintionKey = profileData.group?.plate_number_definition;
            if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
              currentvehicleParkingPlateNumberDefinitionTimePairData[plateDefintionKey][diffToSelectedDate + 1] = channelEntrnaceMode ? GraphDataService.procesChartData(profileData.entrance, false, false) : GraphDataService.procesChartData(profileData.exit, false, false);
            }
            vehicleParkingPlateNumberDefinitionData[plateDefintionKey].entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
            vehicleParkingPlateNumberDefinitionData[plateDefintionKey].exit = GraphDataService.procesChartData(profileData.exit, false, false);
          }
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
          if (diffToSelectedDate === 0) {
            trafficSiteEntranceExit.entrance = GraphDataService.procesChartData(profileData?.entrance, false, false);
            trafficSiteEntranceExit.exit = GraphDataService.procesChartData(profileData?.exit, false, false);
          }
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = processChartData(profileData?.[channelMode], false, false);
            trafficSiteTimespentPair[diffToSelectedDate + 1] = processChartData(profileData.average_timespent, false, false);
          }
        }
        if (isFound) {
          Object.keys(tierListTrend).forEach(tier => {
            if (selectedProfilefilter?.purchasing_power !== 'NA') {
              if (selectedProfilefilter?.purchasing_power) {
                if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                  tierListTrend[tier].push(GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData?.[channelMode] : 0, false, false));
                  if (diffToSelectedDate === 0) {
                    tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData?.[channelMode] : 0, false, false);
                  }
                }
              } else {
                if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
                  tierListTrend[tier].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                  if (diffToSelectedDate === 0) {
                    tierListBreakdown[tier] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                  }
                }
              }
            }
          });
          Object.keys(carBrandTrend).forEach(brand => {
            if (selectedProfilefilter?.car_brand) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData?.[channelMode] : 0, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData?.[channelMode] : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                }
              }
            }
          });
          Object.keys(tranSportModeTrend).forEach(type => {
            if (selectedProfilefilter?.vehicle_type) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tranSportModeTrend[type].push(GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData?.[channelMode] : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData?.[channelMode] : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
                if (type !== 'total') {
                  tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                  if (diffToSelectedDate === 0) {
                    tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                  }
                }
              }
            }
          });
          Object.keys(vehiclePlateDefinitionTrend).forEach(definition => {
            if (selectedProfilefilter?.plate_number_definition) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                vehiclePlateDefinitionTrend[definition].push(GraphDataService.procesChartData(selectedProfilefilter.plate_number_definition === definition ? profileData?.[channelMode] : 0, false, false));
                if (diffToSelectedDate === 0) {
                  vehiclePlateDefinitionBreakdown[definition] = GraphDataService.procesChartData(selectedProfilefilter.plate_number_definition === definition ? profileData?.[channelMode] : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, plate_number_definition: definition })) {
                vehiclePlateDefinitionTrend[definition].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                if (diffToSelectedDate === 0) {
                  vehiclePlateDefinitionBreakdown[definition] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                }
              }
            }
          });
        }
      }
      if (!isFound) {
        trafficSiteVehicleProfileTrend.push(0);
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(0));
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(0));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
        Object.keys(vehiclePlateDefinitionTrend).map(k => vehiclePlateDefinitionTrend[k].push(0));
      }
    });
    const totalVehicleParkingAccumulate = GraphDataService.procesChartData(GraphDataService.procesChartData(totalVehicleParkingPlateNumberDefinition.entrance - totalVehicleParkingPlateNumberDefinition.exit, false, false) - vehicleParkingAccumulateExcluded, false, false);
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = selectedProfilefilter?.purchasing_power ? filteringList(tierListTrend, selectedProfilefilter?.purchasing_power) : tierListTrend;
    const filteredCarBrandTrend = selectedProfilefilter?.car_brand ? filteringList(carBrandTrend, selectedProfilefilter?.car_brand) : carBrandTrend;
    const filteredvehiclePlateDefinitionTrend: { buyer: number[]; seller: number[] } = selectedProfilefilter?.plate_number_definition ? filteringList(vehiclePlateDefinitionTrend, selectedProfilefilter?.plate_number_definition) as { buyer: number[]; seller: number[] } : vehiclePlateDefinitionTrend;
    // eslint-disable-next-line max-len
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = selectedProfilefilter?.vehicle_type ? Object.entries(tranSportModeTrend).filter(([k, _v]) => k === selectedProfilefilter?.vehicle_type).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] } : tranSportModeTrend;
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalvehiclePlateDefinition = Object.values(vehiclePlateDefinitionBreakdown).reduce(((prev, current) => prev + current), 0);
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const vehiclePlateDefinitionBreakdownPercentage = Object.entries(vehiclePlateDefinitionBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalvehiclePlateDefinition) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    this.trafficSiteProfileCountTrend = trafficSiteVehicleProfileTrend;
    this.trafficSiteProfileCarBrandTrend = Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.trafficSiteProfileModeOfTransportTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    this.trafficSiteProfilePurchasingPowerTrend = Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleProfilePlateDefinitionTrend = Object.entries(filteredvehiclePlateDefinitionTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    // const vehicleParkingLots: number = trafficSiteEntranceExit.entrance - trafficSiteEntranceExit.exit
    if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
      this.trafficSiteProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
      this.trafficSiteProfileCarBrandTrend$.next(Object.entries(filteredCarBrandTrend as { [brand: string]: number[] }).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfilePurchasingPowerTrend$.next(Object.entries(filteredTierListTrend as { [tier: string]: number[] }).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
      this.trafficSiteProfileModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    }
    const vehicleParkingPlateNumberDefinitionPercentageData: {
      [definitionName: string]: number;
    } = Object.entries(vehicleParkingPlateNumberDefinitionData).reduce((prev, [definitionName, definitionObj]) => {
      const countPercentage = this.configDataService.isEntranceDataMode
        ? definitionObj.entrance / totalVehicleParkingPlateNumberDefinition.entrance
        : definitionObj.exit / totalVehicleParkingPlateNumberDefinition.exit;
      prev[definitionName] = GraphDataService.procesChartData(countPercentage * 100, false, true);
      return prev;
    }, {});
    const currentVehicleParkingPlateNumberDefinitionData: {
      [definitionName: string]: { count: number; diff: number; diffPercent: number };
    } = Object.entries(currentvehicleParkingPlateNumberDefinitionTimePairData).reduce((prev, [definitionName, definitionObj]) => {
      const diffCount = definitionObj[1] - definitionObj[0];
      const diffPercent = (diffCount / definitionObj[0]) * 100;
      prev[definitionName] = {
        count: GraphDataService.procesChartData(definitionObj[1], false, false),
        diff: GraphDataService.procesChartData(diffCount, true, true),
        diffPercent: GraphDataService.procesChartData(diffPercent, true, true),
      };
      return prev;
    }, {});
    this.vehicleParkingPlateNumberDefinitonData$.next(vehicleParkingPlateNumberDefinitionPercentageData);
    this.currentVehicleParkingPlateNumberDefinitonData$.next(currentVehicleParkingPlateNumberDefinitionData);
    this.parkingLotOccupancyData$.next(GraphDataService.procesChartData(totalVehicleParkingAccumulate, false, false));
    // this.parkingLotOccupancyData$.next(GraphDataService.procesChartData(50,false,false));
    this.vehicleParkingProfileEntranceExit$.next(trafficSiteEntranceExit);
    this.trafficSiteProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
    this.trafficSiteProfileCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSiteProfilePurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.trafficSiteProfileModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    this.vehicleProfilePlateDefinitionTrend$.next(Object.entries(filteredvehiclePlateDefinitionTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    // this.callTrafficSiteVehicleProfilePrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));
    this.vehicleProfilePlateDefinitionBreakdown$.next(sortableByVal(vehiclePlateDefinitionBreakdownPercentage));
    this.trafficSiteProfileModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.trafficSiteProfileTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.trafficSiteProfilePurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    this.currentTrafficSiteProfileCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPair[1] - trafficSiteCountPair[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPair[1] - trafficSiteCountPair[0]) / trafficSiteCountPair[0]) * 100, true, true)
    });
    this.currentTrafficSiteProfileAvgTimespent$.next({
      avgTimespent: GraphDataService.procesChartData(trafficSiteTimespentPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteTimespentPair[1] - trafficSiteTimespentPair[0], true, false),
      diffPercent: trafficSiteTimespentPair[0] === 0 ? 0 : GraphDataService.procesChartData(((trafficSiteTimespentPair[1] - trafficSiteTimespentPair[0]) / trafficSiteTimespentPair[0]) * 100, true, true)
    });
  }

  async fetchVehicleParkingVehicleProfileData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}&profile_cross_level=3`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion vehicle-parking/vehicle-profile

  //#region vehicle-parking/vehicle-profile all-area
  async loadVehicleParkingVehicleProfileAllAreaData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.getValue();
    return graphDataServiceInstance.fetchVehicleParkingVehicleProfileAllAreaData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileAllAreaLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileAllAreaData(data, lockNum, selectedVehicleProfile));
  }

  deriveVehicleParkingVehicleProfileAllAreaData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number, selectedVehicleProfile: { [selectorName: string]: string }) {
    const trafficSiteVehicleProfileTrend: number[] = [];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const vehicleClassData = Object.keys(this.configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS);
    const vehiclePurchasingPowerClass = Object.keys(this.configDataService.DISPLAY_LANGUAGE.VEHICLE_PURCHASING_POWER_CLASS);
    const channelMode = this.configDataService.isEntranceDataMode ? 'entrance' : 'exit';
    this.unfilteredVehicleProfileAllAreaData$.next(trafficSiteVehicleProfileDatas);
    // GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
    //   // handle missing data
    //   if (!dataFiltered || !dataFiltered.data || isPred) {
    //     const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
    //     trafficSiteVehicleProfileTrend.push(fillValue);
    //     Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
    //     Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
    //     Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
    //     return;
    //   }
    //   const vehicleProfileData = dataFiltered.data;
    //   for (const profileData of vehicleProfileData) {
    //     vehiclePurchasingPowerClass.forEach(tier => {
    //       if (compare1DepthObjects(profileData.group, { purchasing_power: tier })) {
    //         tierListTrend[tier].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
    //         if (diffToSelectedDate === 0) {
    //           tierListBreakdown[tier] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
    //         }
    //       }
    //     });
    //     Object.keys(carBrandTrend).forEach(brand => {
    //       if (compare1DepthObjects(profileData.group, { car_brand: brand })) {
    //         carBrandTrend[brand].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
    //         if (diffToSelectedDate === 0) {
    //           carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
    //         }
    //       }
    //     });
    //     vehicleClassData.forEach(type => {
    //       if (compare1DepthObjects(profileData.group, { vehicle_type: type })) {
    //         if (type !== 'total') {
    //           tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
    //           if (diffToSelectedDate === 0) {
    //             tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
    //           }
    //         }
    //       }
    //     });
    //   }
    // });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    if (lockNum < this.vehicleParkingVehicleProfileAllAreaLock) { return; }
  }

  deriveSelectedVehicleParkingVehicleProfileAllAreaData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, undefined, undefined, selectedVehicleProfile.plate_number_definition);
    const vehicleParkingVehicleProfileAvgTimespent: { [area: string]: number } = {};
    const vehicleParkingVehicleProfileEntranceExit: { [area: string]: { entrance: number; exit: number } } = {};
    const parkingList = Object.values(this.configDataService.FLOOR_OBJECTS[this.configDataService.MAIN_BUILDING])[0].parking;
    if (!trafficSiteVehicleProfileDatas) {
      return;
    }
    for (const parking of parkingList) {
      const dataFiltered = trafficSiteVehicleProfileDatas.find((d => d.area === parking));
      vehicleParkingVehicleProfileAvgTimespent[parking] = 0;
      let isFound = false;
      if (!dataFiltered) {
        continue;
      }
      vehicleParkingVehicleProfileEntranceExit[parking] = { entrance: 0, exit: 0 };
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          vehicleParkingVehicleProfileAvgTimespent[parking] = GraphDataService.procesChartData(profileData?.average_timespent, false, false);
          vehicleParkingVehicleProfileEntranceExit[parking].entrance = GraphDataService.procesChartData(profileData.entrance, false, false);
          vehicleParkingVehicleProfileEntranceExit[parking].exit = GraphDataService.procesChartData(profileData.exit, false, false);
        }
      }
      if (!isFound) {
        vehicleParkingVehicleProfileAvgTimespent[parking] = 0;
        vehicleParkingVehicleProfileEntranceExit[parking] = { entrance: 0, exit: 0 };
      }
    }
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.vehicleParkingVehicleProfileAvgTimespent$.next(vehicleParkingVehicleProfileAvgTimespent);
    this.vehicleParkingVehicleProfileEntranceExitBreakdown$.next(vehicleParkingVehicleProfileEntranceExit);
  }


  async fetchVehicleParkingVehicleProfileAllAreaData(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&profile_cross_level=3`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion vehicle-parking/vehicle-profile all-area

  //#region vehicle-parking/vehicle-profile average-by-day-type
  async loadVehicleParkingVehicleProfileAvgDayTypeData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedDirectory$.value;
    const selectedInteractableObj = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    const areaName = selectedInteractableObj?.type === 'vehicle_parking' ? selectedInteractableObj?.name : selectedInteractable?.zone || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.getValue();
    return graphDataServiceInstance.fetchVehicleParkingVehicleProfileAvgDayTypeData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileAvgDayTypeLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileAvgDayTypeData(data, lockNum, selectedVehicleProfile));
  }

  deriveVehicleParkingVehicleProfileAvgDayTypeData(vehicleParkingVehicleProfileAvgDayTypeDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number, selectedVehicleProfile: { [selectorName: string]: string }) {
    const vehicleParkingVehicleProfileAvgDayTypeTrend: number[] = [];
    this.unfilteredVehicleParkingVehicleProfileAvgDayTypeData$.next(vehicleParkingVehicleProfileAvgDayTypeDatas);
    GraphDataService.mapSevenDayLineChartData(vehicleParkingVehicleProfileAvgDayTypeDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        vehicleParkingVehicleProfileAvgDayTypeTrend.push(fillValue);
        return;
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    if (lockNum < this.vehicleParkingVehicleProfileAvgDayTypeLock) { return; }

  }

  deriveSelectedVehicleParkingVehicleProfileAvgDayTypeData(vehicleParkingVehicleProfileAvgDayTypeDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power, selectedVehicleProfile.plate_number_definition);
    if (!vehicleParkingVehicleProfileAvgDayTypeDatas) {
      return;
    }
    const entranceWeekdayTimePairData: [number, number] = [0, 0];
    const exitWeekdayTimePairData: [number, number] = [0, 0];
    const entranceWeekendTimePairData: [number, number] = [0, 0];
    const exitWeekendTimePairData: [number, number] = [0, 0];
    const selectedDate = this.viewPeriodService.selectedDate;
    if (vehicleParkingVehicleProfileAvgDayTypeDatas.length > 0) {
      const vehicleParkingVehicleProfileWeekdayDatas = vehicleParkingVehicleProfileAvgDayTypeDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() < 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      const vehicleParkingVehicleProfileWeekendDatas = vehicleParkingVehicleProfileAvgDayTypeDatas.filter(d => moment(`${d.iso_year}-${d.month}-${d.day}`, 'YYYY-MM-DD').isoWeekday() >= 6).sort((a, b) => new Date(a.iso_year, b.month, b.day).getTime() - new Date(b.iso_year, b.month, b.day).getTime());
      let indexWeekday = 0;
      let indexWeekend = 0;
      for (const vehicleParkingVehicleProfileWeekdayData of vehicleParkingVehicleProfileWeekdayDatas) {
        if (!vehicleParkingVehicleProfileWeekdayData.data) {
          return;
        }
        let isFound = false;
        const vehicleProfileData = vehicleParkingVehicleProfileWeekdayData.data;
        const momentIt = moment(`${vehicleParkingVehicleProfileWeekdayData.iso_year}-${vehicleParkingVehicleProfileWeekdayData.month}-${vehicleParkingVehicleProfileWeekdayData.day}`, 'YYYY-MM-DD');
        for (const profileData of vehicleProfileData) {
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            isFound = true;
            if (vehicleParkingVehicleProfileWeekdayDatas.length === 1) {
              if (selectedDate.diff(momentIt, 'day') < 7) {
                entranceWeekdayTimePairData[1] = profileData.entrance;
                exitWeekdayTimePairData[1] = profileData.exit;
              }
              else {
                entranceWeekdayTimePairData[0] = profileData.entrance;
                exitWeekdayTimePairData[0] = profileData.exit;
              }
            } else {
              entranceWeekdayTimePairData[indexWeekday] = profileData.entrance;
              exitWeekdayTimePairData[indexWeekday] = profileData.exit;
            }
          }
        }
        if (!isFound) {
          entranceWeekdayTimePairData[indexWeekday] = 0;
          exitWeekdayTimePairData[indexWeekday] = 0;
        }
        indexWeekday++;
      }
      for (const vehicleParkingVehicleProfileWeekendData of vehicleParkingVehicleProfileWeekendDatas) {
        if (!vehicleParkingVehicleProfileWeekendData.data) {
          return;
        }
        let isFound = false;
        const vehicleProfileData = vehicleParkingVehicleProfileWeekendData.data;
        const momentIt = moment(`${vehicleParkingVehicleProfileWeekendData.iso_year}-${vehicleParkingVehicleProfileWeekendData.month}-${vehicleParkingVehicleProfileWeekendData.day}`, 'YYYY-MM-DD');
        for (const profileData of vehicleProfileData) {
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            isFound = true;
            if (vehicleParkingVehicleProfileWeekendDatas.length === 1) {
              if (selectedDate.diff(momentIt, 'day') < 7) {
                entranceWeekendTimePairData[1] = profileData.entrance;
                exitWeekendTimePairData[1] = profileData.exit;
              }
              else {
                entranceWeekendTimePairData[0] = profileData.entrance;
                exitWeekendTimePairData[0] = profileData.exit;
              }
            } else {
              entranceWeekendTimePairData[indexWeekend] = profileData.entrance;
              exitWeekendTimePairData[indexWeekend] = profileData.exit;
            }
          }
        }
        if (!isFound) {
          entranceWeekendTimePairData[indexWeekend] = 0;
          exitWeekendTimePairData[indexWeekend] = 0;
        }
        indexWeekend++;
      }
    }
    // weekday
    const diffEntranceWeekday = entranceWeekdayTimePairData[1] - entranceWeekdayTimePairData[0];
    const diffEntranceWeekdayPercent = entranceWeekdayTimePairData[0] === 0 ? 0 : (diffEntranceWeekday / entranceWeekdayTimePairData[0]) * 100;
    const diffExitWeekday = exitWeekdayTimePairData[1] - exitWeekdayTimePairData[0];
    const diffExitWeekdayPercent = exitWeekdayTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekdayTimePairData[0]) * 100;
    // weekend
    const diffEntranceWeekend = entranceWeekendTimePairData[1] - entranceWeekendTimePairData[0];
    const diffEntranceWeekendPercent = entranceWeekendTimePairData[0] === 0 ? 0 : (diffEntranceWeekend / entranceWeekendTimePairData[0]) * 100;
    const diffExitWeekend = exitWeekendTimePairData[1] - exitWeekendTimePairData[0];
    const diffExitWeekendPercent = exitWeekendTimePairData[0] === 0 ? 0 : (diffExitWeekday / exitWeekendTimePairData[0]) * 100;

    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    this.currentVehicleParkingVehicleProfileEntranceExitAvgWeekday$.next({
      entrance: { current: GraphDataService.procesChartData(entranceWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekdayPercent, true, true) },
      exit: { current: GraphDataService.procesChartData(exitWeekdayTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekday, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekdayPercent, true, true) }
    });

    this.currentVehicleParkingVehicleProfileEntranceExitAvgWeekend$.next({
      entrance: { current: GraphDataService.procesChartData(entranceWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffEntranceWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffEntranceWeekendPercent, true, true) },
      exit: { current: GraphDataService.procesChartData(exitWeekendTimePairData[1], false, false), diff: GraphDataService.procesChartData(diffExitWeekend, true, false), diffPercent: GraphDataService.procesChartData(diffExitWeekendPercent, true, true) }
    });

  }

  async fetchVehicleParkingVehicleProfileAvgDayTypeData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getCustomSelectedQueryParameter(date, 14);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&aggregation_type=average&by_mode=by_day_type&area=${area}&profile_cross_level=3`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }
  //#endregion traffic-site/vehicle-profile average-by-day-type

  //#region vehicle-parking/vehicle-profile-by-hour
  async loadVehicleParkingVehicleProfileByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedDirectory$.value;
    const areaName = selectedInteractable?.zone || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';

    // if (selectedInteractable?.type !== 'area' || !selectedInteractable?.name) {
    //   // clear data
    //   ++graphDataServiceInstance.trafficSiteVehicleProfileByHourLock;
    //   return Promise.resolve();
    // }
    // const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchVehicleParkingVehicleProfileByHourData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileByHourData(data, lockNum));
  }

  deriveVehicleParkingVehicleProfileByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.unfilteredVehicleProfileByHourData$.next(trafficSiteCountByHourDatas);
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey)) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, {})) {
            const pushData = GraphDataService.procesChartData(profileData.count, false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(fillValue);
        trafficByHour[time] = fillValue;
      }
    });
    const peakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.keys(trafficByHour).filter(k => k !== '6.00' && k !== '24.00').reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    if (lockNum < this.vehicleParkingVehicleProfileByHourLock) { return; }
    /*this.trafficSiteCountByHour$.next(trafficByHourCount);
    this.trafficSitePeakTime$.next({
      timeKey: peakTime,
      count: trafficByHour[peakTime]
    });
    this.trafficSiteOffPeakTime$.next({
      timeKey: offPeakTime,
      count: trafficByHour[offPeakTime]
    });*/
    // this.trafficSiteProfileCountByHour$.next(trafficByHourCount);
    // this.trafficSiteProfilePeakTime$.next({
    //   timeKey: peakTime,
    //   count: trafficByHour[peakTime]
    // });
    // this.trafficSiteProfileOffPeakTime$.next({
    //   timeKey: offPeakTime,
    //   count: trafficByHour[offPeakTime]
    // });
  }

  deriveSelectedVehicleParkingVehicleProfileByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    const channelMode = this.configDataService.isEntranceDataMode ? 'entrance' : 'exit';
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey) !== undefined) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            const pushData = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(0);
        trafficByHour[time] = 0;
      }
    });
    const peakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).filter(k => k !== this.configDataService.TIME_LIST[0] && k !== this.configDataService.TIME_LIST[this.configDataService.TIME_LIST.length - 1]).reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    this.trafficSiteProfileCountByHour$.next(trafficByHourCount);
    this.vehicleParkingVehicleProfilePeakTime$.next({
      timeKey: peakTime,
      count: trafficByHour[peakTime]
    });
    this.trafficSiteProfileOffPeakTime$.next({
      timeKey: offPeakTime,
      count: trafficByHour[offPeakTime]
    });
    // this.trafficSiteAdsExposureTimebyHour$.next(adsExposureByHour);
  }

  async fetchVehicleParkingVehicleProfileByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-profile-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion vehicle-parking/vehicle-profile-by-hour

  //#region vehicle-parking/vehicle-profile-by-pin
  async loadVehicleParkingVehicleProfileByPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingVehicleProfileByPinLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable?.name;
    // graphDataServiceInstance.trafficSiteProfileCarBrandTrend = {};
    // graphDataServiceInstance.trafficSiteProfileCountTrend = Array.from({ length: 7 }).map(() => null);
    // graphDataServiceInstance.trafficSiteProfileModeOfTransportTrend = { total: Array.from({ length: 7 }).map(() => null)};
    // graphDataServiceInstance.trafficSiteProfilePurchasingPowerTrend = {};
    const periodType = graphDataServiceInstance.viewPeriodService.viewPeriod;
    const selectedVehicleProfile = graphDataServiceInstance.selectedVehicleProfile$.value;
    // if (date.diff(moment(), periodType.toMomentCompareString()) === -1 || date.diff(moment(), periodType.toMomentCompareString()) === 0) {
    //   return graphDataServiceInstance.fetchVehicleParkingVehicleProfileData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileLock, areaName)
    //   .then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileData(data, lockNum));
    //   // .then(() => graphDataServiceInstance.baseGraphData.addDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE));
    // }
    return graphDataServiceInstance.fetchVehicleParkingVehicleProfileByPinData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileByPinLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileByPinData(data, lockNum));
  }

  deriveVehicleParkingVehicleProfileByPinData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    const channelMode = this.configDataService.isEntranceDataMode ? 'entrance' : 'exit';
    this.unfilteredVehicleParkingProfileByPinData$.next(trafficSiteVehicleProfileDatas);
    if (lockNum < this.vehicleParkingVehicleProfileByPinLock) { return; }
  }

  deriveSelectedVehicleParkingVehicleProfileByPinData(trafficSiteVehicleProfileDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power, selectedVehicleProfile.plate_number_definition);
    const trafficSiteVehicleProfileTrend: number[] = [];
    const trafficSiteCountPair: [number, number] = [0, 0];
    const trafficSiteBreakdown: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
    const trafficSiteTimespentPair: [number, number] = [0, 0];
    const tierListTrend: { [tierName: string]: number[] } = {};
    const tierListBreakdown: { [tierName: string]: number } = {};
    const tranSportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = { total: [] };
    const tranSportModeBreakdown: { [vehicleTypeName: string]: number } = {};
    const carBrandTrend: { [brandName: string]: number[] } = {};
    const carBrandBreakdown: { [brandName: string]: number } = {};
    const vehiclePlateDefinitionTrend: { buyer: number[]; seller: number[] } = { buyer: [], seller: [] };
    const vehiclePlateDefinitionBreakdown: { buyer: number; seller: number } = { buyer: 0, seller: 0 };
    const excludeCarBrand = ['arthur', 'bike', 'discard'];
    const vehicleClassData = Object.keys(this.configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS);
    const channelMode = this.configDataService.isEntranceDataMode ? 'entrance' : 'exit';
    if (!trafficSiteVehicleProfileDatas) {
      return;
    }
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data || isPred) {
        return;
      }
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (profileData.group?.purchasing_power) {
          if (profileData.group?.purchasing_power !== 'NA') {
            tierListTrend[profileData.group?.purchasing_power] = [];
          }
        }
        else if (profileData.group?.vehicle_type) {
          if (vehicleClassData.includes(profileData.group?.vehicle_type)) {
            tranSportModeTrend[profileData.group?.vehicle_type] = [];
          }
        }
        else if (profileData.group?.car_brand) {
          if (!excludeCarBrand.includes(profileData.group?.car_brand)) {
            carBrandTrend[profileData.group?.car_brand] = [];
          }
        }
      }
    });
    GraphDataService.mapSevenDayLineChartData(trafficSiteVehicleProfileDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
      // handle missing data
      if (!dataFiltered || !dataFiltered.data || isPred) {
        const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
        trafficSiteVehicleProfileTrend.push(fillValue);
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(fillValue));
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(fillValue));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(fillValue));
        Object.keys(vehiclePlateDefinitionTrend).map(k => vehiclePlateDefinitionTrend[k].push(fillValue));
        return;
      }
      let isFound = false;
      const vehicleProfileData = dataFiltered.data;
      for (const profileData of vehicleProfileData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          isFound = true;
          trafficSiteVehicleProfileTrend.push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
          tranSportModeTrend.total.push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
          if (diffToSelectedDate === 0) {
            trafficSiteBreakdown.entrance = GraphDataService.procesChartData(profileData?.entrance, false, false);
            trafficSiteBreakdown.exit = GraphDataService.procesChartData(profileData?.exit, false, false);
          }
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            trafficSiteCountPair[diffToSelectedDate + 1] = profileData?.[channelMode];
            trafficSiteTimespentPair[diffToSelectedDate + 1] = profileData.average_timespent;
          }
        }
        if (isFound) {
          Object.keys(tierListTrend).forEach(tier => {
            if (selectedProfilefilter?.purchasing_power !== 'NA') {
              if (selectedProfilefilter?.purchasing_power) {
                if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                  tierListTrend[tier].push(GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData?.[channelMode] : 0, false, false));
                  if (diffToSelectedDate === 0) {
                    tierListBreakdown[tier] = GraphDataService.procesChartData(selectedProfilefilter.purchasing_power === tier ? profileData?.[channelMode] : 0, false, false);
                  }
                }
              } else {
                if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, purchasing_power: tier })) {
                  tierListTrend[tier].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                  if (diffToSelectedDate === 0) {
                    tierListBreakdown[tier] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                  }
                }
              }
            }
          });
          Object.keys(carBrandTrend).forEach(brand => {
            if (selectedProfilefilter?.car_brand) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData?.[channelMode] : 0, false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(selectedProfilefilter.car_brand === brand ? profileData?.[channelMode] : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, car_brand: brand })) {
                carBrandTrend[brand].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                if (diffToSelectedDate === 0) {
                  carBrandBreakdown[brand] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                }
              }
            }
          });
          Object.keys(tranSportModeTrend).forEach(type => {
            if (selectedProfilefilter?.vehicle_type) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                tranSportModeTrend[type].push(GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData?.[channelMode] : 0, false, false));
                if (diffToSelectedDate === 0) {
                  tranSportModeBreakdown[type] = GraphDataService.procesChartData(selectedProfilefilter.vehicle_type === type ? profileData?.[channelMode] : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, vehicle_type: type })) {
                if (type !== 'total') {
                  tranSportModeTrend[type].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                  if (diffToSelectedDate === 0) {
                    tranSportModeBreakdown[type] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                  }
                }
              }
            }
          });
          Object.keys(vehiclePlateDefinitionTrend).forEach(definition => {
            if (selectedProfilefilter?.plate_number_definition) {
              if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
                vehiclePlateDefinitionTrend[definition].push(GraphDataService.procesChartData(selectedProfilefilter.plate_number_definition === definition ? profileData?.[channelMode] : 0, false, false));
                if (diffToSelectedDate === 0) {
                  vehiclePlateDefinitionBreakdown[definition] = GraphDataService.procesChartData(selectedProfilefilter.plate_number_definition === definition ? profileData?.[channelMode] : 0, false, false);
                }
              }
            } else {
              if (compare1DepthObjects(profileData.group, { ...selectedProfilefilter, plate_number_definition: definition })) {
                vehiclePlateDefinitionTrend[definition].push(GraphDataService.procesChartData(profileData?.[channelMode], false, false));
                if (diffToSelectedDate === 0) {
                  vehiclePlateDefinitionBreakdown[definition] = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
                }
              }
            }
          });
        }
      }
      if (!isFound) {
        trafficSiteVehicleProfileTrend.push(0);
        Object.keys(carBrandTrend).map(k => carBrandTrend[k].push(0));
        Object.keys(tierListTrend).map(k => tierListTrend[k].push(0));
        Object.keys(tranSportModeTrend).map(k => tranSportModeTrend[k].push(0));
        Object.keys(vehiclePlateDefinitionTrend).map(k => vehiclePlateDefinitionTrend[k].push(0));
      }
    });
    // eslint-disable-next-line max-len
    const sortableByVal = (obj: { [key: string]: number }, n?: number) => n ? Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().slice(0, n).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) : Object.entries(obj).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteringList = (obj: { [key: string]: number[] }, filter: string) => Object.entries(obj).filter(([k, _v]) => k === filter).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const filteredTierListTrend = selectedProfilefilter?.purchasing_power ? filteringList(tierListTrend, selectedProfilefilter?.purchasing_power) : tierListTrend;
    const filteredCarBrandTrend = selectedProfilefilter?.car_brand ? filteringList(carBrandTrend, selectedProfilefilter?.car_brand) : carBrandTrend;
    const filteredvehiclePlateDefinitionTrend: { buyer: number[]; seller: number[] } = selectedProfilefilter?.plate_number_definition ? filteringList(vehiclePlateDefinitionTrend, selectedProfilefilter?.plate_number_definition) as { buyer: number[]; seller: number[] } : vehiclePlateDefinitionTrend;
    // eslint-disable-next-line max-len
    const filteredTransportModeTrend: { [vehicleTypeName: string]: number[]; total: number[] } = selectedProfilefilter?.vehicle_type ? Object.entries(tranSportModeTrend).filter(([k, _v]) => k === selectedProfilefilter?.vehicle_type).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] } : tranSportModeTrend;
    const sumTotalvehiclePlateDefinition = Object.values(vehiclePlateDefinitionBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalCarBrand = Object.values(carBrandBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotalTierList = Object.values(tierListBreakdown).reduce(((prev, current) => prev + current), 0);
    const sumTotaltranSportMode = Object.values(tranSportModeBreakdown).reduce(((prev, current) => prev + current), 0);
    const vehiclePlateDefinitionBreakdownPercentage = Object.entries(vehiclePlateDefinitionBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalvehiclePlateDefinition) * 100, false, false) }), {});
    const carBrandBreakdownPercentage = Object.entries(carBrandBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalCarBrand) * 100, false, false) }), {});
    const tierListBreakdownPercentage = Object.entries(tierListBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotalTierList) * 100, false, false) }), {});
    const tranSportModeBreakdownPercentage = Object.entries(tranSportModeBreakdown).reduce((r, [k, v]) => ({ ...r, [k]: GraphDataService.procesChartData((v / sumTotaltranSportMode) * 100, false, false) }), {});
    // this.trafficSiteProfileCountTrend = trafficSiteVehicleProfileTrend;
    // this.trafficSiteProfileCarBrandTrend = Object.entries(filteredCarBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    // this.trafficSiteProfileModeOfTransportTrend = Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] };
    // this.trafficSiteProfilePurchasingPowerTrend = Object.entries(filteredTierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    const date = this.viewPeriodService.selectedDate;
    const periodType = this.viewPeriodService.viewPeriod;
    // if (date.diff(moment(), periodType.toMomentCompareString()) < -1) {
    //   this.trafficSiteProfileCountTrend$.next(trafficSiteVehicleProfileTrend);
    //   this.trafficSiteProfileCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    //   this.trafficSiteProfilePurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    //   this.trafficSiteProfileModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });  
    // }
    this.vehicleParkingProfileByPinCountTrend$.next(trafficSiteVehicleProfileTrend);
    this.vehicleParkingProfileByPinEntranceExit$.next(trafficSiteBreakdown);
    this.vehicleProfileByPinPlateDefinitionTrend = Object.entries(filteredvehiclePlateDefinitionTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    this.vehicleParkingProfileByPinCarBrandTrend$.next(Object.entries(filteredCarBrandTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.vehicleParkingProfileByPinPurchasingPowerTrend$.next(Object.entries(filteredTierListTrend).filter(([_k, v]) => (v as any[]).length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.vehicleParkingProfileByPinModeOfTransportTrend$.next(Object.entries(filteredTransportModeTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}) as { [vehicleTypeName: string]: number[]; total: number[] });
    this.vehicleProfileByPinPlateDefinitionTrend$.next(Object.entries(filteredvehiclePlateDefinitionTrend).filter(([_k, v]) => v.length === 8).reduce((r, [k, v]) => ({ ...r, [k]: v }), {}));
    this.vehicleProfileByPinPlateDefinitionBreakdown$.next(sortableByVal(vehiclePlateDefinitionBreakdownPercentage));

    // this.callTrafficSiteVehicleProfilePrediction$.next(!(date.diff(moment(), periodType.toMomentCompareString()) < -1));
    this.vehicleParkingProfileByPinModeOfTransportBreakdown$.next(sortableByVal(tranSportModeBreakdownPercentage));
    this.vehicleParkingProfileByPinTopTenCarBrandBreakdown$.next(sortableByVal(carBrandBreakdownPercentage, 10));
    this.vehicleParkingProfileByPinPurchasingPowerBreakdown$.next(sortableByVal(tierListBreakdownPercentage));
    this.currentvehicleParkingProfileByPinCount$.next({
      count: GraphDataService.procesChartData(trafficSiteCountPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteCountPair[1] - trafficSiteCountPair[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteCountPair[1] - trafficSiteCountPair[0]) / trafficSiteCountPair[0]) * 100, true, true)
    });
    this.currentvehicleParkingProfileByPinAvgTimespent$.next({
      avgTimespent: GraphDataService.procesChartData(trafficSiteTimespentPair[1], false, false),
      diff: GraphDataService.procesChartData(trafficSiteTimespentPair[1] - trafficSiteTimespentPair[0], true, false),
      diffPercent: GraphDataService.procesChartData(((trafficSiteTimespentPair[1] - trafficSiteTimespentPair[0]) / trafficSiteTimespentPair[0]) * 100, true, true)
    });
  }

  async fetchVehicleParkingVehicleProfileByPinData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-profile-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion traffic-site/vehicle-profile-by-pin

  //#region vehicle-parking/vehicle-profile-by-pin-by-hour
  async loadVehicleParkingVehicleProfileByPinByHourData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    // const areaName = selectedInteractable?.zone || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';

    if (selectedInteractable?.type !== 'gate' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.vehicleParkingVehicleProfileByPinByHourLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name;
    return graphDataServiceInstance.fetchVehicleParkingVehicleProfileByPinByHourData(date, ++graphDataServiceInstance.vehicleParkingVehicleProfileByPinByHourLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingVehicleProfileByPinByHourData(data, lockNum));
  }

  deriveVehicleParkingVehicleProfileByPinByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], lockNum: number) {
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.unfilteredVehicleParkingVehicleProfileByPinByHourData$.next(trafficSiteCountByHourDatas);
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey)) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, {})) {
            const pushData = GraphDataService.procesChartData(profileData.count, false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(fillValue);
        trafficByHour[time] = fillValue;
      }
    });
    const peakTime = Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.keys(trafficByHour).filter(k => k !== '6.00' && k !== '24.00').reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    if (lockNum < this.vehicleParkingVehicleProfileByPinByHourLock) { return; }
  }

  deriveSelectedVehicleParkingVehicleProfileByPinByHourData(trafficSiteCountByHourDatas: IFetchData<ModeOfTransportData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power);
    const trafficByHourCount: number[] = [];
    const trafficByHour: { [timeKey: string]: number } = {};
    const channelMode = this.configDataService.isEntranceDataMode ? 'entrance' : 'exit';
    if (!trafficSiteCountByHourDatas) {
      return;
    }
    this.configDataService.TIME_LIST.map(time => {
      const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
      const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
      const fillValue = isLiveFillZero ? 0 : null;
      if (trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey) !== undefined) {
        const trafficSiteCountByHourData = trafficSiteCountByHourDatas.find(countByHourdata => countByHourdata.hour === timeKey);
        for (const profileData of trafficSiteCountByHourData.data) {
          if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
            const pushData = GraphDataService.procesChartData(profileData?.[channelMode], false, false);
            trafficByHourCount.push(pushData);
            trafficByHour[time] = pushData;
          }
        }
      } else {
        trafficByHourCount.push(0);
        trafficByHour[time] = 0;
      }
    });
    const peakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).reduce((a, b) => trafficByHour[a] > trafficByHour[b] ? a : b);
    const offPeakTime = Object.values(trafficByHour).every(v => v === 0) ? 'N/A' : Object.keys(trafficByHour).filter(k => k !== this.configDataService.TIME_LIST[0] && k !== this.configDataService.TIME_LIST[this.configDataService.TIME_LIST.length - 1]).reduce((a, b) => trafficByHour[a] < trafficByHour[b] ? a : b);
    this.vehicleParkingVehicleProfileByPinCountByHour$.next(trafficByHourCount);
    this.vehicleParkingVehicleProfileByPinPeakTime$.next({
      timeKey: peakTime,
      count: trafficByHour[peakTime]
    });
    this.vehicleParkingVehicleProfileByPinOffPeakTime$.next({
      timeKey: offPeakTime,
      count: trafficByHour[offPeakTime]
    });
    // this.trafficSiteAdsExposureTimebyHour$.next(adsExposureByHour);
  }

  async fetchVehicleParkingVehicleProfileByPinByHourData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-profile-by-pin-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<ModeOfTransportData[]>[], number];
  }

  //#endregion vehicle-parking/vehicle-profile-by-pin-by-hour

  //#region vehicle-parking/entrance-exit-by-pin all-pin
  async loadVehicleParkingEntranceExitAllPinData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleParkingEntranceExitAllPinData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitAllPinLock).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingEntranceExitAllPinData(data, lockNum));
  }

  deriveVehicleParkingEntranceExitAllPinData(buildingAreaEntranceExitByPinDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number, floor?: string) {
    const entranceGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const exitGroupOldAreaByPin: { [buildingName: string]: { [pinName: string]: number } } = {};
    const entranceAllPin: { [pinName: string]: number } = {};
    const exitAllPin: { [pinName: string]: number } = {};
    const floorEntranceExitByPinData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: { entrance: number; exit: number } } } } = {};
    const floorEntranceExitData: { [buildingName: string]: { [floorName: string]: { entrance: number; exit: number } } } = {};
    // const filteredData = buildingAreaEntranceExitByPinDatas;
    // const mainBuilding = this.configDataService.MAIN_BUILDING;
    // const excludeArea: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const buildingNameList = Object.keys(this.configDataService.FLOOR_OBJECTS);
    for (const buildingName of buildingNameList) {
      entranceGroupOldAreaByPin[buildingName] = {};
      exitGroupOldAreaByPin[buildingName] = {};
    }
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      floorEntranceExitByPinData[buildingName] = {};
      floorEntranceExitData[buildingName] = {};
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        floorEntranceExitByPinData[buildingName][floorName] = {};
        floorEntranceExitData[buildingName][floorName] = { entrance: 0, exit: 0 };
        for (const pinName of floorData.gates) {
          floorEntranceExitByPinData[buildingName][floorName][pinName] = { entrance: 0, exit: 0 };
        }
      }
    }
    for (const buildingAreaEntranceExitByPinData of buildingAreaEntranceExitByPinDatas) {
      if (!buildingAreaEntranceExitByPinData || !buildingAreaEntranceExitByPinData.data) {
        return;
      }
      const mainBuilding = this.configDataService.MAIN_BUILDING;
      if (this.configDataService.isFeatureEnabled('exclude_area')) {
        if (mainBuilding !== this.configDataService.isFeatureEnabled('exclude_area')) {
          entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
          exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0;
        }
      } else {
        entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
        exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0;
      }
      // if (buildingNameList.includes(buildingAreaEntranceExitByPinData.building)) {
      //   if (this.configDataService.isFeatureEnabled('exclude_area')) {
      //     if (buildingAreaEntranceExitByPinData.building !== this.configDataService.isFeatureEnabled('exclude_area')) {
      //       entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
      //       exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0;
      //       // entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
      //       // exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0;
      //     }
      //   } else {
      //     entranceGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
      //     exitGroupOldAreaByPin[buildingAreaEntranceExitByPinData.building][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0; 
      //     // entranceGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.entrance || 0;
      //     // exitGroupOldAreaByPin[mainBuilding][buildingAreaEntranceExitByPinData.pin] = buildingAreaEntranceExitByPinData?.data?.exit || 0; 
      //   }
      // }
    }
    // const pinList = Object.keys(entranceGroupOldAreaByPin);
    // const floorObjects = this.configDataService.FLOOR_OBJECTS[mainBuilding];
    const floorEntranceData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    const floorExitData: { [buildingName: string]: { [floorName: string]: { [pinName: string]: number } } } = {};
    for (const [buildingName, buildingData] of Object.entries(this.configDataService.FLOOR_OBJECTS)) {
      floorEntranceData[buildingName] = {};
      floorExitData[buildingName] = {};
      for (const [floorName, floorData] of Object.entries(buildingData)) {
        floorEntranceData[buildingName][floorName] = {};
        floorExitData[buildingName][floorName] = {};
        const sumAllPinByFloor: { entrance: number; exit: number } = { entrance: 0, exit: 0 };
        for (const pinName of Object.keys(entranceGroupOldAreaByPin?.[buildingName] || {})) {
          if (this.configDataService.isFeatureEnabled('graph_data', 'contribute_area')) {
            entranceAllPin[pinName] = entranceGroupOldAreaByPin[buildingName][pinName];
            exitAllPin[pinName] = exitGroupOldAreaByPin[buildingName][pinName];
          }
          if (floorData.gates.find(gate => gate === pinName)) {
            sumAllPinByFloor.entrance += entranceGroupOldAreaByPin[buildingName][pinName];
            sumAllPinByFloor.exit += exitGroupOldAreaByPin[buildingName][pinName];
            floorEntranceExitByPinData[buildingName][floorName][pinName].entrance = entranceGroupOldAreaByPin[buildingName][pinName];
            floorEntranceExitByPinData[buildingName][floorName][pinName].exit = exitGroupOldAreaByPin[buildingName][pinName];
            floorEntranceData[buildingName][floorName][pinName] = entranceGroupOldAreaByPin[buildingName][pinName];
            floorExitData[buildingName][floorName][pinName] = exitGroupOldAreaByPin[buildingName][pinName];
          }
          floorEntranceExitByPinData[buildingName][floorName]._total = sumAllPinByFloor;
          floorEntranceExitData[buildingName][floorName] = sumAllPinByFloor;
        }
      }
    }
    this.entranceBuildingPin$.next(floorEntranceData);
    this.exitBuildingPin$.next(floorExitData);
    this.allPinByBuildingEntrance$.next(entranceAllPin);
    this.allPinByBuildingExit$.next(exitAllPin);
  }

  async fetchVehicleParkingEntranceExitAllPinData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit-by-pin?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion vehicle-parking/entrance-exit-by-pin all-pin

  //#region vehicle-parking/vehicle-parking-synergy all-area
  async loadVehicleParkingSynergyAllAreaData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const area = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchVehicleParkingSynergyAllAreaData(date, ++graphDataServiceInstance.vehicleParkingToVehicleParkingSynergyAllAreaLock, area).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingSynergyAllAreaData(data, lockNum));
  }

  deriveVehicleParkingSynergyAllAreaData(allZoneToZoneSynergyDatas: IFetchData<GroupCountData>[], lockNum: number) {
    const zoneList = Object.keys(this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME).filter(k => k !== 'all');
    for (const zone of zoneList) {
      const zoneToZoneSynergyBarChart: { [zoneName: string]: number } = {};
      this.allZoneToZoneSynergyData[zone] = {};
      const zoneToZoneSynergyDatas = allZoneToZoneSynergyDatas.filter(d => d.area === zone);
      GraphDataService.mapSevenDayLineChartData(zoneToZoneSynergyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, _diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          return;
        }
        const buildingToZoneSynergyData = dataFiltered.data;
        for (const [zoneName, zoneData] of Object.entries(buildingToZoneSynergyData)) {
          zoneList.forEach(z => {
            if (zoneName !== '_total' && zoneName !== zone && zoneName !== 'all') {
              const countPercentage = (zoneData.count / buildingToZoneSynergyData._total.count) * 100;
              zoneToZoneSynergyBarChart[zoneName] = GraphDataService.procesChartData(countPercentage, false, false);
            }
          });
        }
      });
      this.allZoneToZoneSynergyData[zone] = zoneToZoneSynergyBarChart;
    }
    if (lockNum < this.vehicleParkingToVehicleParkingSynergyAllAreaLock) { return; }
    this.allZoneToZoneSynergyData$.next(this.allZoneToZoneSynergyData);
  }

  async fetchVehicleParkingSynergyAllAreaData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/vehicle-parking-synergy?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<GroupCountData>[], number];
  }
  //#endregion vehicle-parking/vehicle-parking-synergy all-area

  //#region building/floor-timespent
  async loadBuildingFloorTimespentData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    // TO-DO: updated area mapping
    const areaName = '998_reid_2a';
    return graphDataServiceInstance.fetchBuildingFloorTimespentData(date, ++graphDataServiceInstance.buildingFloorTimespentLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingFloorTimespentData(data, lockNum));
  }

  deriveBuildingFloorTimespentData(buildingFloorTimespentDatas: IFetchData<PlateTimespentData>[], lockNum: number) {
    const binNameSet = new Set<string>();
    const binNameList = this.configDataService.BIN_TIMESPENT_BY_PIN_LIST;
    const avgTimespentLower5MinsTimePairData: [number, number] = [0, 0];
    const avgTimespentUpper5MinsTimePairData: [number, number] = [0, 0];
    const totalTimespentCountTimePairData: [number, number] = [0, 0];
    const timespentCountUpper5MinsTimePairData: [number[], number[]] = [[], []];
    const timespentLower5Mins: number[] = [];
    const timespentUpper5Mins: number[] = [];
    const visitorBytimespentCount: { [groupName: string]: number } = { lower5: 0, upper5: 0 };
    const visitorBytimespentCountPercentage: { [groupName: string]: number } = { lower5: 0, upper5: 0 };
    binNameList.forEach(binName => {
      binNameSet.add(binName);
    });
    const binNames = Array.from(binNameSet.values());
    const plateTimespentBinData: { [binName: string]: number } = binNames.reduce((prev, binName) => {
      prev[binName] = 0;
      return prev;
    }, {});
    if (Object.keys(plateTimespentBinData).length < 1) {
      this.buildingFloorTimespentData$.next(null);
      return;
    }
    GraphDataService.mapSevenDayLineChartData(buildingFloorTimespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        return;
      }
      const plateTimespentData = dataFiltered.data;
      if (diffToSelectedDate === 0) {
        const avgTimespentUpper5MinsData: number[] = [];
        const avgTimespentLower5MinsData: number[] = [];
        for (const [binKey, binObj] of Object.entries(plateTimespentData)) {
          if (!plateTimespentData[binKey]) {
            return;
          }
          if (binKey === '_total') {
            totalTimespentCountTimePairData[diffToSelectedDate + 1] = binObj.count;
          }
          if (binKey !== '_total') {
            plateTimespentBinData[binKey] = GraphDataService.procesChartData((plateTimespentData[binKey].count / plateTimespentData._total.count) * 100, false, false);
            if (binObj.upper <= 300) {
              timespentLower5Mins.push(binObj.count);
              avgTimespentLower5MinsData.push(binObj.average_timespent);
            } else {
              timespentCountUpper5MinsTimePairData[diffToSelectedDate + 1].push(binObj.count);
              timespentUpper5Mins.push(binObj.count);
              avgTimespentUpper5MinsData.push(binObj.average_timespent);
            }
          }
          avgTimespentUpper5MinsTimePairData[diffToSelectedDate + 1] = avgTimespentUpper5MinsData.length > 0 ? avgTimespentUpper5MinsData.reduce((a, b) => a + b, 0) / avgTimespentUpper5MinsData.length : 0;
          avgTimespentLower5MinsTimePairData[diffToSelectedDate + 1] = avgTimespentLower5MinsData.length > 0 ? avgTimespentLower5MinsData.reduce((a, b) => a + b, 0) / avgTimespentLower5MinsData.length : 0;
        }
      }
      if (diffToSelectedDate === -1) {
        const avgTimespentUpper5MinsData: number[] = [];
        const avgTimespentLower5MinsData: number[] = [];
        for (const [binKey, binObj] of Object.entries(plateTimespentData)) {
          if (!plateTimespentData[binKey]) {
            return;
          }
          if (binKey === '_total') {
            totalTimespentCountTimePairData[diffToSelectedDate + 1] = binObj.count;
          }
          if (binKey !== '_total') {
            if (binObj.lower > 300 && binObj.upper > 300) {
              avgTimespentUpper5MinsData.push(binObj.average_timespent);
              timespentCountUpper5MinsTimePairData[diffToSelectedDate + 1].push(binObj.count);
            }
            if (binObj.upper <= 300) {
              avgTimespentLower5MinsData.push(binObj.average_timespent);
            }
          }
        }
        avgTimespentUpper5MinsTimePairData[diffToSelectedDate + 1] = avgTimespentUpper5MinsData.length > 0 ? avgTimespentUpper5MinsData.reduce((a, b) => a + b, 0) / avgTimespentUpper5MinsData.length : 0;
        avgTimespentLower5MinsTimePairData[diffToSelectedDate + 1] = avgTimespentLower5MinsData.length > 0 ? avgTimespentLower5MinsData.reduce((a, b) => a + b, 0) / avgTimespentLower5MinsData.length : 0;
      }
    });
    if (lockNum < this.buildingFloorTimespentLock) { return; }
    const diffAvgTimespentUpper5Mins = avgTimespentUpper5MinsTimePairData[1] - avgTimespentUpper5MinsTimePairData[0];
    const diffAvgTimespentUpper5MinsPercentage = avgTimespentUpper5MinsTimePairData[0] === 0 ? 0 : (diffAvgTimespentUpper5Mins / avgTimespentUpper5MinsTimePairData[0]) * 100;
    visitorBytimespentCount.lower5 = timespentLower5Mins.length > 0 ? GraphDataService.procesChartData(timespentLower5Mins.reduce((a, b) => a + b, 0), false, false) : 0;
    visitorBytimespentCount.upper5 = timespentUpper5Mins.length > 0 ? GraphDataService.procesChartData(timespentUpper5Mins.reduce((a, b) => a + b, 0), false, false) : 0;
    const sumAllVisitorByTimespent = Object.values(visitorBytimespentCount).reduce((a, b) => a + b, 0);
    visitorBytimespentCountPercentage.lower5 = timespentLower5Mins.length > 0 ? GraphDataService.procesChartData((visitorBytimespentCount.lower5 / sumAllVisitorByTimespent) * 100, false, true) : 0;
    visitorBytimespentCountPercentage.upper5 = timespentLower5Mins.length > 0 ? GraphDataService.procesChartData((visitorBytimespentCount.upper5 / sumAllVisitorByTimespent) * 100, false, true) : 0;
    this.currentBuildingFloorAvgTimespentUpper5$.next({
      current: GraphDataService.procesChartData(avgTimespentUpper5MinsTimePairData[1], false, false),
      diff: GraphDataService.procesChartData(diffAvgTimespentUpper5Mins, true, true),
      diffPercent: GraphDataService.procesChartData(diffAvgTimespentUpper5MinsPercentage, true, true)
    });
    this.buildingFloorTimespentData$.next(plateTimespentBinData);
    this.buildingFloorVisitorPercentage$.next(visitorBytimespentCountPercentage);
    this.subscription.add(this.zoneDataCompletely$.subscribe(zoneDataCompletely => {
      if (zoneDataCompletely) {
        const zoneTrafficDataWithTimespentData: { [groupName: string]: number } = { lower5: 0, upper5: 0 };
        const zoneTrafficPairDataWithTimespentData: [number, number] = [0, 0];
        const sumTimespentCountUpper5MinsTimePairData = [timespentCountUpper5MinsTimePairData[0].reduce((a, b) => a + b, 0), timespentCountUpper5MinsTimePairData[1].reduce((a, b) => a + b, 0)];
        const mainBuilding = this.configDataService.MAIN_BUILDING;
        const zoneTrafficData = this.currentBuildingHeadCountZoneData$.getValue();
        const zoneTrafficPairData = this.currentBuildingZoneTrafficPairData;
        for (const [groupName, visitorCount] of Object.entries(visitorBytimespentCount)) {
          zoneTrafficDataWithTimespentData[groupName] = zoneTrafficData?.[mainBuilding]?.['001_2nd']?.['2a'].entrance.headCount * (visitorCount / sumAllVisitorByTimespent);
        }
        zoneTrafficPairDataWithTimespentData[0] = (sumTimespentCountUpper5MinsTimePairData[0] / totalTimespentCountTimePairData[0]) * zoneTrafficPairData?.[mainBuilding]?.['001_2nd']?.['2a'].entrance[0];
        zoneTrafficPairDataWithTimespentData[1] = (sumTimespentCountUpper5MinsTimePairData[1] / totalTimespentCountTimePairData[1]) * zoneTrafficPairData?.[mainBuilding]?.['001_2nd']?.['2a'].entrance[1];
        const diffAvgTimespentLower5Mins = zoneTrafficPairDataWithTimespentData[1] - zoneTrafficPairDataWithTimespentData[0];
        const diffAvgTimespentLower5MinsPercentage = zoneTrafficPairDataWithTimespentData[0] === 0 ? 0 : (diffAvgTimespentLower5Mins / zoneTrafficPairDataWithTimespentData[0]) * 100;
        const currentZoneTrafficWithLower5Mins = {
          current: GraphDataService.procesChartData(zoneTrafficPairDataWithTimespentData[1], false, false),
          diff: GraphDataService.procesChartData(diffAvgTimespentLower5Mins, true, false),
          diffPercent: GraphDataService.procesChartData(diffAvgTimespentLower5MinsPercentage, true, true)
        };
        this.buildingFloorVisitor$.next(zoneTrafficDataWithTimespentData);
        this.currentBuildingFloorVisitorAvgTimespentLower5$.next(currentZoneTrafficWithLower5Mins);
      }
    }));
  }

  async fetchBuildingFloorTimespentData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const fetchURL = `/retail_customer_api_v2/api/v2/building/floor-timespent?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<PlateTimespentData>[], number];
  }

  //#endregion building/floor-timespent

  //#region store/proximity-traffic (by floor)
  async loadStoreProximityTrafficFloorData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    // const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    // if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
    //   // clear data
    //   ++graphDataServiceInstance.storeProximityLock;
    //   return Promise.resolve();
    // }
    // const storeAreaName = selectedInteractable.name;
    // 2nd floor only
    const floorAreaName = '001_2nd';
    return graphDataServiceInstance.fetchStoreProximityTrafficFloorData(date, ++graphDataServiceInstance.storeProximityFloorLock, floorAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreProximityTrafficFloorData(data, lockNum));
  }

  async deriveStoreProximityTrafficFloorData(StoreProximityTrafficDatas: IFetchData<AreaProximityGroupData>[], lockNum: number) {
    // await this.configDataService.loadAppConfig();
    const storeProximityTrafficTrendbyFloor: { [areaName: string]: number[] } = {};
    const currentStoreProximityTrafficbyFloorPair: { [areaName: string]: [number, number] } = {};
    const currentStoreProximityTrafficFrontBackPair: { [channelName: string]: [number, number] } = {};
    const storeEstProximityTrafficRange: { [areaName: string]: { min: number; max: number } } = {};
    const storeEstProximityTrafficNormalize: { [areaName: string]: number } = {};
    const storeFrontBackList = {
      front: [
        // 'S_1_186',
        'S_1_194',
        'S_1_196',
        'S_1_193',
        'S_1_148',
        'S_1_149',
        'S_1_150',
        'S_1_151',
        'S_1_152',
        'S_1_153',
        'S_1_185',
        // 'S_1_192',
        'S_1_117',
        'S_1_147',
      ],
      back: [
        // 'S_1_147',
        'S_1_271',
        'S_1_191',
        'S_1_183',
        // 'S_1_189',
        // 'S_1_187',
        // 'S_1_188',
        'S_1_190',
        'S_1_154',
        'S_1_155',
        // 'S_1_156',
        // 'S_1_157',
        'S_1_158',
        'S_1_159',
        // 'S_1_184',
        // 'S_1_175',
      ],
    };
    const usingOtherStoreDataList = [
      'S_1_188',
      'S_1_190',
      'S_1_154',
      'S_1_155',
      'S_1_156',
      'S_1_157',
      'S_1_158',
      'S_1_159',
    ];
    const fixedStoreKey = 'S_1_271';
    for (const [channelKey, storeList] of Object.entries(storeFrontBackList)) {
      for (const storeArea of storeList) {
        storeProximityTrafficTrendbyFloor[storeArea] = [];
        currentStoreProximityTrafficbyFloorPair[storeArea] = [0, 0];
        const storeProximityTrafficTrendLineChartData: number[] = [];
        const filteredStoreProximityTrafficDatas = StoreProximityTrafficDatas.filter(k => k.area === storeArea);
        GraphDataService.mapSevenDayLineChartData(filteredStoreProximityTrafficDatas, this.viewPeriodService, (dataFiltered, isLivePeriod, isPred, diffToSelectedDate) => {
          // handle missing data
          if (!dataFiltered || !dataFiltered.data || isPred) {
            const fillValue = isPred || (isLivePeriod && this.viewPeriodService.isDayPeriod) ? null : 0;
            storeProximityTrafficTrendLineChartData.push(fillValue);
            return;
          }
          const storeProximityTrafficData = dataFiltered.data;
          if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
            currentStoreProximityTrafficbyFloorPair[storeArea][diffToSelectedDate + 1] = GraphDataService.procesChartData(storeProximityTrafficData.traffic);
          }
          storeProximityTrafficTrendLineChartData.push(GraphDataService.procesChartData(storeProximityTrafficData.traffic));
        });
        storeProximityTrafficTrendbyFloor[storeArea] = storeProximityTrafficTrendLineChartData;
      }
    }
    for (const storeArea of Object.keys(storeProximityTrafficTrendbyFloor)) {
      storeEstProximityTrafficRange[storeArea] = { min: 0, max: 0 };
      if (usingOtherStoreDataList.includes(storeArea)) {
        storeProximityTrafficTrendbyFloor[storeArea] = storeProximityTrafficTrendbyFloor[fixedStoreKey];
        currentStoreProximityTrafficbyFloorPair[storeArea] = currentStoreProximityTrafficbyFloorPair[fixedStoreKey];
      }
      const storeProximityTrafficbyFloorDivided = Math.floor(currentStoreProximityTrafficbyFloorPair[storeArea][1] / 3000);
      if (storeProximityTrafficbyFloorDivided < 1) {
        storeEstProximityTrafficRange[storeArea] = { min: 0, max: 3000 };
      } else {
        storeEstProximityTrafficRange[storeArea] = { min: 3000 * storeProximityTrafficbyFloorDivided, max: 3000 * (storeProximityTrafficbyFloorDivided + 1) };
      }
    }
    const maxEstProximityTraffic = Math.max(...Object.values(currentStoreProximityTrafficbyFloorPair).map(arr => arr[1]));
    const minEstProximityTraffic = Math.min(...Object.values(currentStoreProximityTrafficbyFloorPair).map(arr => arr[1]));
    for (const storeArea of Object.keys(storeProximityTrafficTrendbyFloor)) {
      storeEstProximityTrafficNormalize[storeArea] = (currentStoreProximityTrafficbyFloorPair[storeArea][1] - minEstProximityTraffic) / (maxEstProximityTraffic - minEstProximityTraffic);
    }
    const totalStoreFrontBackAreaDataCount: { [channelName: string]: [number, number] } = {};
    // const totalStoreBackAreaDataCount: [number, number] = [0, 0];
    for (const [channelKey, storeList] of Object.entries(storeFrontBackList)) {
      totalStoreFrontBackAreaDataCount[channelKey] = [0, 0];
      for (const storeArea of storeList) {
        totalStoreFrontBackAreaDataCount[channelKey][0] += currentStoreProximityTrafficbyFloorPair[storeArea][0];
        totalStoreFrontBackAreaDataCount[channelKey][1] += currentStoreProximityTrafficbyFloorPair[storeArea][1];
      }
    }
    if (lockNum < this.storeProximityFloorLock) { return; }
    const avgStoreFrontBackAreaDataCountPair: { [channelName: string]: [number, number] } = {};
    const avgStoreFrontBackAreaDataCountPercentage: { [channelName: string]: number } = {};
    Object.keys(totalStoreFrontBackAreaDataCount).forEach(channelKey => {
      avgStoreFrontBackAreaDataCountPair[channelKey] = [0, 0];
      avgStoreFrontBackAreaDataCountPair[channelKey][0] = totalStoreFrontBackAreaDataCount[channelKey][0] / storeFrontBackList[channelKey].length;
      avgStoreFrontBackAreaDataCountPair[channelKey][1] = totalStoreFrontBackAreaDataCount[channelKey][1] / storeFrontBackList[channelKey].length;
    });
    const channelList = ['front', 'back'];
    const totalAvgStoreFrontBackAreaData = avgStoreFrontBackAreaDataCountPair.front[1] + avgStoreFrontBackAreaDataCountPair.back[1];
    channelList.forEach(channelKey => {
      avgStoreFrontBackAreaDataCountPercentage[channelKey] = (avgStoreFrontBackAreaDataCountPair[channelKey][1] / totalAvgStoreFrontBackAreaData) * 100;
    });
    const diffStoreFrontTraffic = avgStoreFrontBackAreaDataCountPair.front[1] - avgStoreFrontBackAreaDataCountPair.front[0];
    const diffStoreFrontTrafficPercentage = (avgStoreFrontBackAreaDataCountPair.front[1] - avgStoreFrontBackAreaDataCountPair.front[0]) / avgStoreFrontBackAreaDataCountPair.front[0];
    const diffStoreBackTraffic = avgStoreFrontBackAreaDataCountPair.back[1] - avgStoreFrontBackAreaDataCountPair.back[0];
    const diffStoreBackTrafficPercentage = (avgStoreFrontBackAreaDataCountPair.back[1] - avgStoreFrontBackAreaDataCountPair.back[0]) / avgStoreFrontBackAreaDataCountPair.back[0];
    this.currentStoreFrontTrafficData$.next({
      headCount: GraphDataService.procesChartData(avgStoreFrontBackAreaDataCountPair.front[1], false, false),
      diff: GraphDataService.procesChartData(diffStoreFrontTraffic, true, false),
      diffPercent: GraphDataService.procesChartData(diffStoreFrontTrafficPercentage * 100, true, true),
    });
    this.currentStoreBackTrafficData$.next({
      headCount: GraphDataService.procesChartData(avgStoreFrontBackAreaDataCountPair.back[1], false, false),
      diff: GraphDataService.procesChartData(diffStoreBackTraffic, true, false),
      diffPercent: GraphDataService.procesChartData(diffStoreBackTrafficPercentage * 100, true, true),
    });
    this.storeFrontBackTrafficData$.next(avgStoreFrontBackAreaDataCountPercentage);
    this.storeEstProxTrafficRangeData$.next(storeEstProximityTrafficRange);
    this.storeEstProximityTrafficNormalize$.next(storeEstProximityTrafficNormalize);
  }

  async fetchStoreProximityTrafficFloorData(date: moment.Moment, lockNum: number, floorArea: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/proximity-traffic?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&floor=${floorArea}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<AreaProximityGroupData>[], number];
  }
  //#endregion store/proximity-traffic

  //#region store/zone-traffic-flow
  async loadStoreAreaToZoneTrafficFlowData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'store' || !selectedInteractable?.name) {
      // clear data
      ++graphDataServiceInstance.storeAreatoZoneTrafficFlowLock;
      return Promise.resolve();
    }
    const areaName = selectedInteractable.name.toLocaleLowerCase();
    const mappingStoreKey = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_reid') ? graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_reid_key') : graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping_key');
    const parseSpeicalAreaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data', 'store_mapping') ? mappingStoreKey?.[areaName] || areaName : areaName;
    return graphDataServiceInstance.fetchStoreAreaToZoneTrafficFlowData(date, ++graphDataServiceInstance.storeAreatoZoneTrafficFlowLock, parseSpeicalAreaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStoreAreaToZoneTrafficFlowData(data, lockNum));
  }

  deriveStoreAreaToZoneTrafficFlowData(storeAreaZoneTrafficFlowDatas: IFetchData<SankeyRawLinks[]>[], lockNum: number) {
    if (storeAreaZoneTrafficFlowDatas.length === 0) {
      this.storeAreaZoneTrafficFlow$.next(null);
      return;
    }
    if (lockNum < this.storeAreatoZoneTrafficFlowLock) { return; }
    this.storeAreaZoneTrafficFlow$.next(storeAreaZoneTrafficFlowDatas[0].data);
  }

  async fetchStoreAreaToZoneTrafficFlowData(date: moment.Moment, lockNum: number, area?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/store/zone-traffic-flow?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<SankeyRawLinks[]>[], number];
  }
  //#endregion store/zone-traffic-flow

  //#region building/zone-traffic-flow
  async loadBuildingAreaToZoneTrafficFlowData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchBuildingAreaToZoneTrafficFlowData(date, ++graphDataServiceInstance.buildingAreatoZoneTrafficFlowLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaToZoneTrafficFlowData(data, lockNum));
  }

  deriveBuildingAreaToZoneTrafficFlowData(buildingAreaZoneTrafficFlowDatas: IFetchData<SankeyRawLinks[]>[], lockNum: number) {
    if (buildingAreaZoneTrafficFlowDatas.length === 0) {
      this.buildingAreaZoneTrafficFlow$.next(null);
      return;
    }
    if (lockNum < this.buildingAreatoZoneTrafficFlowLock) { return; }
    this.buildingAreaZoneTrafficFlow$.next(buildingAreaZoneTrafficFlowDatas[0].data);
  }

  async fetchBuildingAreaToZoneTrafficFlowData(date: moment.Moment, lockNum: number, area?: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const noLookback = this.configDataService.isFeatureEnabled('graph_data', 'building_to_zone_traffic_flow')?.no_lookback;
    const noPasserby = this.configDataService.isFeatureEnabled('graph_data', 'building_to_zone_traffic_flow')?.no_passerby;
    let fetchURL = '';
    if (noLookback) {
      fetchURL = `/retail_customer_api_v2/api/v2/building/zone-traffic-flow-no-lookback?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    } else if (noPasserby) {
      fetchURL = `/retail_customer_api_v2/api/v2/building/zone-traffic-flow-no-passerby?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    } else {
      fetchURL = `/retail_customer_api_v2/api/v2/building/zone-traffic-flow?num_interval=${qParams.num_interval}&start_date=${qParams.start_date}&area=${area}`;
    }
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<SankeyRawLinks[]>[], number];
  }
  //#endregion store/zone-traffic-flow

  //#region vehicle-parking/frequency-of-visit
  async loadVehicleParkingFrequencyOfVisitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingFrequencyOfVisitData(date, ++graphDataServiceInstance.vehicleParkingFrequencyOfVisitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingFrequencyOfVisitData(data, lockNum));
  }

  deriveVehicleParkingFrequencyOfVisitData(frequencyOfVisitDatas: IFetchData<FrequencyOfVisitData>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(frequencyOfVisitDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    if (!dataIt || !dataIt.data) {
      this.vehicleParkingFrequencyOfVisitData$.next({ one: 0, two_three: 0, four_up: 0 });
      return;
    }
    if (this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT) {
      if (momentIt.isBefore(this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT)) {
        this.vehicleParkingFrequencyOfVisitData$.next({ one: 0, two_three: 0, four_up: 0 });
        return;
      }
    }
    const frequencyOfVisitData = dataIt.data;
    const frequencyOfVisitChartData = { one: 0, two_three: 0, four_up: 0 };
    for (const [timesString, count] of Object.entries(frequencyOfVisitData.frequency_of_visit)) {
      const times = parseInt(timesString, 10);
      if (times === 1) {
        frequencyOfVisitChartData.one = (frequencyOfVisitChartData.one || 0) + count;
      } else if (times >= 2 && times <= 3) {
        frequencyOfVisitChartData.two_three = (frequencyOfVisitChartData.two_three || 0) + count;
      } else if (times >= 4 && times <= 50) {
        frequencyOfVisitChartData.four_up = (frequencyOfVisitChartData.four_up || 0) + count;
      }
    }
    const totalUniqueVistors = frequencyOfVisitData.total_unique_visitors;

    Object.keys(frequencyOfVisitChartData).forEach(key => {
      const freqChartPercentage = (frequencyOfVisitChartData[key] / totalUniqueVistors) * 100;
      frequencyOfVisitChartData[key] = GraphDataService.procesChartData(freqChartPercentage, false, false);
    });
    if (lockNum < this.vehicleParkingFrequencyOfVisitLock) { return; }
    this.vehicleParkingFrequencyOfVisitData$.next(frequencyOfVisitChartData);
  }

  async fetchVehicleParkingFrequencyOfVisitData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/frequency-of-visit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<FrequencyOfVisitData>[], number];
  }
  //#endregion vehicle-parking/frequency-of-visit

  //#region vehicle-parking/repeated-vistiors
  async loadVehicleParkingRepeatedVisitorsData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'vehicle_parking') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.fetchVehicleParkingRepeatedVisitorsData(date, ++graphDataServiceInstance.vehicleParkingRepeatedVisitorsLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingRepeatedVisitorsData(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
      return graphDataServiceInstance.fetchVehicleParkingRepeatedVisitorsData(date, ++graphDataServiceInstance.vehicleParkingRepeatedVisitorsLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingRepeatedVisitorsData(data, lockNum));
    }
  }

  deriveVehicleParkingRepeatedVisitorsData(repeatedVisitorsDatas: IFetchData<RepeatedVisitorData>[], lockNum: number) {
    const repeatedVisitorPairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(repeatedVisitorsDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      const repeatedVisitorsData = dataFiltered.data;
      if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
        repeatedVisitorPairData[diffToSelectedDate + 1] = repeatedVisitorsData.repeated_percentage;
      }
    });
    if (lockNum < this.vehicleParkingRepeatedVisitorsLock) { return; }
    this.vehicleParkingRepeatedVisitorsData$.next({
      new_percentage: GraphDataService.procesChartData((1 - repeatedVisitorPairData[1]) * 100, false, true),
      repeated_percentage: GraphDataService.procesChartData((repeatedVisitorPairData[1] * 100), false, true),
    });
    this.vehicleParkingCurrentRepeatedVisitorsData$.next({
      current: GraphDataService.procesChartData(repeatedVisitorPairData[1] * 100, false, true),
      diff: GraphDataService.procesChartData((repeatedVisitorPairData[1] - repeatedVisitorPairData[0]) * 100, true, true)
    });
  }

  async fetchVehicleParkingRepeatedVisitorsData(date: moment.Moment, lockNum: number, area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/repeated-visitors?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<RepeatedVisitorData>[], number];
  }

  //#endregion vehicle-parking/repeated-visitors

  //#region vehicle-parking/recency-frequency
  async loadVehicleParkingRecencyFrequencyData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingRecencyFrequencyData(date, ++graphDataServiceInstance.vehicleParkingRecencyFrequencyLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingRecencyFrequencyData(data, lockNum));
  }

  deriveVehicleParkingRecencyFrequencyData(recencyFrequencyDatas: IFetchData<RecencyFrequencyData>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const regularVisitorTwoPair: [number, number] = [0, 0];
    const newVisitorTwoPair: [number, number] = [0, 0];
    const churnVisitorTwoPair: [number, number] = [0, 0];
    const lossVisitorTwoPair: [number, number] = [0, 0];
    const vehicleParkingRetentionData: Array<[number, number, number, string]> = [];
    const defaultVehicleParkingRetentionData: Array<[number, number, number, string]> = [
      [0, 0, 5, 'group1'],
      [0, 1, 2, 'group1'],
      [1, 1, 2, 'group1'],
      [1, 0, 4, 'group1'],
      [2, 0, 10, 'group2'],
      [2, 1, 10, 'group2'],
      [3, 0, 10, 'group2'],
      [3, 1, 12, 'group2'],
      [1, 2, 16, 'group3'],
      [1, 3, 16, 'group3'],
      [0, 2, 16, 'group3'],
      [0, 3, 16, 'group3'],
      [2, 2, 14, 'group4'],
      [2, 3, 14, 'group4'],
      [3, 3, 55, 'group4'],
      [3, 2, 25, 'group4'],
    ];
    const parseGroupName = (xIndex: number, yIndex: number): string => {
      if (xIndex === 0 && yIndex === 0) {
        return 'group1';
      }
      if (xIndex === 0 && yIndex === 1) {
        return 'group3';
      }
      if (xIndex === 1 && yIndex === 0) {
        return 'group2';
      }
      if (xIndex === 1 && yIndex === 1) {
        return 'group4';
      }
      return 'na';
    };
    const bucketMapper = [
      { lower: 0, upper: 6 },
      { lower: 7, upper: 13 },
      { lower: 14, upper: 20 },
      { lower: 21, upper: 27 },
    ];
    const reverseBucketMapper = [
      { lower: 7, upper: 13 },
      { lower: 0, upper: 6 },
    ];
    GraphDataService.mapSevenDayLineChartData(recencyFrequencyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        this.vehicleParkingRetentionData$.next(defaultVehicleParkingRetentionData);
        return;
      }
      const recencyFrequencyData = dataFiltered.data.data;
      let totalCountData = 0;
      if (diffToSelectedDate === 0) {
        for (const rfData of recencyFrequencyData) {
          const xIndex = reverseBucketMapper.length - rfData.bucket[0] - 1;
          const yIndex = rfData.bucket[1];
          if (xIndex !== null && yIndex !== null) {
            const groupName = parseGroupName(xIndex, yIndex);
            vehicleParkingRetentionData.push([xIndex, yIndex, rfData.count, groupName]);
            totalCountData += rfData.count;
          }
        }
      }
      if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
        if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 0) !== undefined) {
          const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 0);
          newVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
        }
        if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 1) !== undefined) {
          const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 1);
          regularVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
        }
        if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 0) !== undefined) {
          const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 0);
          lossVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
        }
        if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 1) !== undefined) {
          const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 1);
          churnVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
        }
      }
    });
    // for (const xIndex of Array.of(0, 1, 2, 3)) {
    //   const recencyBucket = reverseBucketMapper[xIndex];
    //   for (const yIndex of Array.of(0, 1, 2, 3)) {
    //     const freqBucket = reverseBucketMapper[yIndex];
    //     const bucketData = recencyFrequencyData.find(d => d.recency.upper === recencyBucket.upper && d.recency.lower === recencyBucket.lower && d.frequency.upper === freqBucket.upper && d.frequency.lower === freqBucket.lower);
    //     const groupName = parseGroupName(xIndex, yIndex);
    //     if (bucketData !== undefined) {
    //       vehicleParkingRetentionData.push([xIndex, yIndex, bucketData.count, groupName]);
    //       totalCountData += bucketData.count;
    //     } else {
    //       vehicleParkingRetentionData.push([xIndex, yIndex, 0, groupName]);
    //     }
    //   }
    // }
    if (lockNum < this.vehicleParkingRecencyFrequencyLock) { return; }
    this.vehicleParkingNewVisitorCount$.next({
      current: processChartData(newVisitorTwoPair[1], false, false),
      diff: processChartData(newVisitorTwoPair[1] - newVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((newVisitorTwoPair[1] - newVisitorTwoPair[0]) / newVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingLossVisitorCount$.next({
      current: processChartData(lossVisitorTwoPair[1], false, false),
      diff: processChartData(lossVisitorTwoPair[1] - lossVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((lossVisitorTwoPair[1] - lossVisitorTwoPair[0]) / lossVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingRegularVisitorCount$.next({
      current: processChartData(regularVisitorTwoPair[1], false, false),
      diff: processChartData(regularVisitorTwoPair[1] - regularVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((regularVisitorTwoPair[1] - regularVisitorTwoPair[0]) / regularVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingChurnVisitorCount$.next({
      current: processChartData(churnVisitorTwoPair[1], false, false),
      diff: processChartData(churnVisitorTwoPair[1] - churnVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((churnVisitorTwoPair[1] - churnVisitorTwoPair[0]) / churnVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingRetentionData$.next(vehicleParkingRetentionData);
  }

  async fetchVehicleParkingRecencyFrequencyData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const periodType = this.viewPeriodService.viewPeriod;
    const updatedStartDate = this.adjustDateTime(date, periodType);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/recency-frequency?start_date=${updatedStartDate}&mode=day&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<RecencyFrequencyData>[], number];
  }

  //#endregion vehicle-parking/recency-frequency

  //#region building/area-entrance-exit-by-hour average-by-day-of-week
  async loadBuildingAreaEntranceExitByHourAverageDayOfWeekData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitByHourAverageDayOfWeekData(date, ++graphDataServiceInstance.buildingAreaEntranceExitByHourAverageDayOfWeekLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitByHourAverageDayOfWeekData(data, lockNum));
  }

  deriveBuildingAreaEntranceExitByHourAverageDayOfWeekData(buildingAreaEntranceExitByHourAverageDayOfWeekDatas: DayOfWeekAverages<HourlyDataAverages>, lockNum: number) {
    const buildingAreaAverageDayOfWeekData: { [day_of_week: string]: { [buildingName: string]: { [channelName: string]: number[] } } } = {};
    const buildingAreaAverageDayOfWeekGroupDayWeekData: { [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } } = {};
    if (!buildingAreaEntranceExitByHourAverageDayOfWeekDatas) {
      return;
    }
    const excludeList: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const buildingList = Object.keys(this.configDataService.FLOOR_OBJECTS).filter(k => !excludeList.includes(k));
    buildingList.forEach(b => {
      buildingAreaAverageDayOfWeekGroupDayWeekData[b] = {};
    });
    for (const [dayOfWeek, areaData] of Object.entries(buildingAreaEntranceExitByHourAverageDayOfWeekDatas)) {
      const displayWeek = 'dayweek-' + dayOfWeek;
      buildingAreaAverageDayOfWeekData[dayOfWeek] = {};
      for (const [areaName, hourData] of Object.entries(areaData)) {
        if (!excludeList.includes(areaName)) {
          buildingAreaAverageDayOfWeekGroupDayWeekData[areaName][displayWeek] = {};
          buildingAreaAverageDayOfWeekData[dayOfWeek][areaName] = {};
          buildingAreaAverageDayOfWeekGroupDayWeekData[areaName][displayWeek].entrance = [];
          buildingAreaAverageDayOfWeekGroupDayWeekData[areaName][displayWeek].exit = [];
          buildingAreaAverageDayOfWeekData[dayOfWeek][areaName].entrance = [];
          buildingAreaAverageDayOfWeekData[dayOfWeek][areaName].exit = [];
          this.configDataService.TIME_LIST.map((time, _idx) => {
            const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
            // const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
            const fillValue = 0;
            for (const channelMode of ['entrance', 'exit']) {
              buildingAreaAverageDayOfWeekData[dayOfWeek][areaName][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
              buildingAreaAverageDayOfWeekGroupDayWeekData[areaName][displayWeek][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
            }
          });

        }
      }
    }
    if (lockNum < this.buildingAreaEntranceExitByHourAverageDayOfWeekLock) { return; }
    this.buildingAreaAverageDayOfWeekGroupDayWeekData$.next(buildingAreaAverageDayOfWeekGroupDayWeekData);
    this.buildingAreaAverageDayOfWeekData$.next(buildingAreaAverageDayOfWeekData);
  }

  async fetchBuildingAreaEntranceExitByHourAverageDayOfWeekData(date: moment.Moment, lockNum: number) {
    const currentDate = moment().utc(false).clone();
    const start_date_clone = date.clone();
    const end_date_clone = date.clone();
    const start_date = this.viewPeriodService.isMonthPeriod ? start_date_clone.startOf('month') : this.viewPeriodService.isWeekPeriod ? start_date_clone.startOf('isoWeek') : start_date_clone.subtract(6, 'd');
    // const end_date = this.viewPeriodService.isMonthPeriod  ? end_date_clone.endOf('month') : this.viewPeriodService.isWeekPeriod  ? end_date_clone.endOf('isoWeek') : end_date_clone;
    const end_date = this.viewPeriodService.isMonthPeriod ? currentDate.isSame(end_date_clone, 'month') ? currentDate.subtract(1, 'd') : end_date_clone.endOf('month') : this.viewPeriodService.isWeekPeriod ? currentDate.isSame(end_date_clone, 'w') ? currentDate.subtract(1, 'd') : end_date_clone.endOf('isoWeek') : end_date_clone;
    const numInterval = end_date.diff(start_date, 'd') + 1;
    const qParams = { start_date: end_date.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}&aggregation_type=average_by_day_of_week`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [DayOfWeekAverages<HourlyDataAverages>, number];
  }
  //#endregion building/area-entrance-exit-by-hour average-by-day-of-week

  //#region building/area-entrance-exit-by-hour by-day-of-week
  async loadBuildingAreaEntranceExitByHourDayOfWeekData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitByHourDayOfWeekData(date, ++graphDataServiceInstance.buildingAreaEntranceExitByHourDayOfWeekLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitByHourDayOfWeekData(data, lockNum));
  }

  deriveBuildingAreaEntranceExitByHourDayOfWeekData(buildingAreaEntranceExitByHourDayOfWeekDatas: DayOfWeekAverages<HourlyDataAverages>, lockNum: number) {
    const buildingAreaDayOfWeekData: { [day_of_week: string]: { [buildingName: string]: { [channelName: string]: number[] } } } = {};
    const buildingAreaDayOfWeekGroupDayWeekData: { [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } } = {};
    if (!buildingAreaEntranceExitByHourDayOfWeekDatas) {
      return;
    }
    const excludeList: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const buildingList = Object.keys(this.configDataService.FLOOR_OBJECTS).filter(k => !excludeList.includes(k));
    buildingList.forEach(b => {
      buildingAreaDayOfWeekGroupDayWeekData[b] = {};
    });
    for (const [dayOfWeek, areaData] of Object.entries(buildingAreaEntranceExitByHourDayOfWeekDatas)) {
      const displayWeek = 'dayweek-' + dayOfWeek;
      buildingAreaDayOfWeekData[dayOfWeek] = {};
      for (const [areaName, hourData] of Object.entries(areaData)) {
        if (!excludeList.includes(areaName)) {
          buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek] = {};
          buildingAreaDayOfWeekData[dayOfWeek][areaName] = {};
          buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek].entrance = [];
          buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek].exit = [];
          buildingAreaDayOfWeekData[dayOfWeek][areaName].entrance = [];
          buildingAreaDayOfWeekData[dayOfWeek][areaName].exit = [];
          this.configDataService.TIME_LIST.map((time, _idx) => {
            const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
            const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
            // const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
            const fillValue = 0;
            for (const channelMode of ['entrance', 'exit']) {
              buildingAreaDayOfWeekData[dayOfWeek][areaName][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
              buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
            }
          });
        }
      }
    }
    if (lockNum < this.buildingAreaEntranceExitByHourDayOfWeekLock) { return; }
    this.buildingAreaDayOfWeekGroupDayWeekData$.next(buildingAreaDayOfWeekGroupDayWeekData);
    this.buildingAreaDayOfWeekData$.next(buildingAreaDayOfWeekData);
  }

  async fetchBuildingAreaEntranceExitByHourDayOfWeekData(date: moment.Moment, lockNum: number) {
    const start_date_clone = date.clone();
    const end_date_clone = date.clone();
    const start_date = this.viewPeriodService.isMonthPeriod ? start_date_clone.startOf('month') : this.viewPeriodService.isWeekPeriod ? start_date_clone.startOf('isoWeek') : start_date_clone.subtract(6, 'd');
    const end_date = this.viewPeriodService.isMonthPeriod ? end_date_clone.endOf('month') : this.viewPeriodService.isWeekPeriod ? end_date_clone.endOf('isoWeek') : end_date_clone;
    const numInterval = end_date.diff(start_date, 'd') + 1;
    const qParams = { start_date: end_date.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}&aggregation_type=sum_by_day_of_week`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    // console.log('test fetchObj Original',fetchObj)
    return [fetchObj, lockNum] as [DayOfWeekAverages<HourlyDataAverages>, number];
  }

  //#endregion building/area-entrance-exit-by-hour average-by-day-of-week

  //#region vehicle/entrance-exit-by-hour by-day-of-week
  async loadVehicleParkingEntranceExitByHourDayOfWeekData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchVehicleParkingEntranceExitByHourDayOfWeekData(date, ++graphDataServiceInstance.vehicleParkingEntranceExitByHourDayOfWeekLock).then(([data, lockNum]) => graphDataServiceInstance.derivevehicleParkingEntranceExitByHourDayOfWeekData(data, lockNum));
  }

  derivevehicleParkingEntranceExitByHourDayOfWeekData(vehicleParkingEntranceExitByHourDayOfWeekDatas: DayOfWeekAverages<HourlyDataAverages>, lockNum: number) {
    const vehicleParkingDayOfWeekData: { [day_of_week: string]: { [buildingName: string]: { [channelName: string]: number[] } } } = {};
    const vehicleParkingDayOfWeekGroupDayWeekData: { [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } } = {};
    if (!vehicleParkingEntranceExitByHourDayOfWeekDatas) { return; }
    const excludeList: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.vehicle_parking || [];
    const vehicleParkingList: string[] = [];

    Object.keys(this.configDataService.FLOOR_OBJECTS).forEach(buildingName => {
      const building = this.configDataService.FLOOR_OBJECTS?.[buildingName];
      if (building) {
        for (const floor in building) {
          if (building[floor]?.parking) {
            vehicleParkingList.push(...building[floor].parking!.filter(k => !excludeList.includes(k)));
          }
        }
      }
    });
    vehicleParkingList.forEach(b => {
      vehicleParkingDayOfWeekGroupDayWeekData[b] = {};
    });
    for (const [dayOfWeek, areaData] of Object.entries(vehicleParkingEntranceExitByHourDayOfWeekDatas)) {
      const displayWeek = 'dayweek-' + dayOfWeek;
      vehicleParkingDayOfWeekData[dayOfWeek] = {};
      for (const [areaName, hourData] of Object.entries(areaData)) {
        if (!excludeList.includes(areaName)) {
          vehicleParkingDayOfWeekGroupDayWeekData[areaName][displayWeek] = {};
          vehicleParkingDayOfWeekData[dayOfWeek][areaName] = {};
          vehicleParkingDayOfWeekGroupDayWeekData[areaName][displayWeek].entrance = [];
          vehicleParkingDayOfWeekGroupDayWeekData[areaName][displayWeek].exit = [];
          vehicleParkingDayOfWeekData[dayOfWeek][areaName].entrance = [];
          vehicleParkingDayOfWeekData[dayOfWeek][areaName].exit = [];
          this.configDataService.VEHICLE_TIME_LIST.map((time, _idx) => {
            const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
            const fillValue = 0;
            for (const channelMode of ['entrance', 'exit']) {
              vehicleParkingDayOfWeekData[dayOfWeek][areaName][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
              vehicleParkingDayOfWeekGroupDayWeekData[areaName][displayWeek][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
            }
          });
        }
      }
    }
    if (lockNum < this.vehicleParkingEntranceExitByHourDayOfWeekLock) { return; }
    this.vehicleParkingDayOfWeekGroupDayWeekData$.next(vehicleParkingDayOfWeekGroupDayWeekData);
    this.vehicleParkingDayOfWeekData$.next(vehicleParkingDayOfWeekData);
  }

  async fetchVehicleParkingEntranceExitByHourDayOfWeekData(date: moment.Moment, lockNum: number) {
    const start_date_clone = date.clone();
    const end_date_clone = date.clone();
    const start_date = this.viewPeriodService.isMonthPeriod ? start_date_clone.startOf('month') : this.viewPeriodService.isWeekPeriod ? start_date_clone.startOf('isoWeek') : start_date_clone.subtract(6, 'd');
    const end_date = this.viewPeriodService.isMonthPeriod ? end_date_clone.endOf('month') : this.viewPeriodService.isWeekPeriod ? end_date_clone.endOf('isoWeek') : end_date_clone;
    const numInterval = end_date.diff(start_date, 'd') + 1;
    const qParams = { start_date: end_date.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}&aggregation_type=sum_by_day_of_week`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    // console.log('test fetchObj Original',fetchObj)
    return [fetchObj, lockNum] as [DayOfWeekAverages<HourlyDataAverages>, number];
  }

  //#endregion vehicle/entrance-exit-by-hour average-by-day-of-week

  //#region building/area-entrance-exit-by-hour day-by-day
  async loadBuildingAreaEntranceExitByHourDayByDayData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    return graphDataServiceInstance.fetchBuildingAreaEntranceExitByHourDayByDayData(date, ++graphDataServiceInstance.buildingAreaEntranceExitByHourDayByDayLock).then(([data, lockNum]) => graphDataServiceInstance.deriveBuildingAreaEntranceExitByHourDayByDayData(data, lockNum));
  }

  deriveBuildingAreaEntranceExitByHourDayByDayData(buildingAreaEntranceExitByHourDayOfWeekDatas: DayOfWeekAverages<HourlyDataAverages>, lockNum: number) {
    const buildingAreaDayOfWeekGroupDayWeekData: { [buildingName: string]: { [day_of_week: string]: { [channelName: string]: number[] } } } = {};
    if (!buildingAreaEntranceExitByHourDayOfWeekDatas) {
      return;
    }
    const excludeList: string[] = this.configDataService.isFeatureEnabled('graph_data', 'exclude_area')?.building || [];
    const buildingList = Object.keys(this.configDataService.FLOOR_OBJECTS).filter(k => !excludeList.includes(k));
    buildingList.forEach(b => {
      buildingAreaDayOfWeekGroupDayWeekData[b] = {};
    });
    let displayLastWeek = 0; // เพิ่มบรรทัดนี้
    for (const [dayOfWeek, areaData] of Object.entries(buildingAreaEntranceExitByHourDayOfWeekDatas)) {
      let displayWeek: string;
      if (parseInt(dayOfWeek, 10) <= 6) {
        displayWeek = 'dayweek-' + dayOfWeek;
        for (const [areaName, hourData] of Object.entries(areaData)) {
          if (!excludeList.includes(areaName)) {
            buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek] = {};
            buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek].entrance = [];
            buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek].exit = [];
            this.configDataService.TIME_LIST.map((time, _idx) => {
              const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
              const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
              // const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
              const fillValue = 0;
              for (const channelMode of ['entrance', 'exit']) {
                buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
              }
            });
          }
        }
      } else if (parseInt(dayOfWeek, 10) > 6) {
        displayWeek = 'daylastweek-' + displayLastWeek.toString();
        for (const [areaName, hourData] of Object.entries(areaData)) {
          if (!excludeList.includes(areaName)) {
            buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek] = {};
            buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek].entrance = [];
            buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek].exit = [];
            this.configDataService.TIME_LIST.map((time, _idx) => {
              const timeKey = parseInt(time, 10) - this.configDataService.TIME_DIFF_FROM_UTC;
              const isLiveFillZero = (this.viewPeriodService.isLivePeriod || this.viewPeriodService.isLiveMode) && timeKey <= moment().utc(false).hour();
              // const fillValue = isLiveFillZero || !this.viewPeriodService.isLivePeriod ? 0 : null;
              const fillValue = 0;
              for (const channelMode of ['entrance', 'exit']) {
                buildingAreaDayOfWeekGroupDayWeekData[areaName][displayWeek][channelMode].push(processChartData(hourData?.[timeKey]?.[channelMode] || fillValue, false, false));
              }
            });
          }
        }
        displayLastWeek += 1;
      }
    }
    // if (lockNum < this.buildingAreaEntranceExitByHourDayByDayLock) { return; }
    this.buildingAreaDayOfWeekGroupDayByDayData$.next(buildingAreaDayOfWeekGroupDayWeekData);
    // console.log('Merge Data', this.buildingAreaDayOfWeekGroupDayByDayData$);
    // this.buildingAreaDayOfWeekData$.next(buildingAreaDayOfWeekData);
  }

  async fetchBuildingAreaEntranceExitByHourDayByDayData(date: moment.Moment, lockNum: number) {
    const start_date_clone = date.clone();
    const end_date_clone = date.clone();
    const start_date = this.viewPeriodService.isMonthPeriod ? start_date_clone.startOf('month') : this.viewPeriodService.isWeekPeriod ? start_date_clone.startOf('isoWeek') : start_date_clone.subtract(6, 'd');
    const end_date = this.viewPeriodService.isMonthPeriod ? end_date_clone.endOf('month') : this.viewPeriodService.isWeekPeriod ? end_date_clone.endOf('isoWeek') : end_date_clone;
    const numInterval = end_date.diff(start_date, 'd') + 1;
    const qParams = { start_date: end_date.format('YYYY-MM-DD'), periodType: 'day', num_interval: numInterval };
    const start_date_2 = moment(qParams.start_date).subtract(7, 'days').format('YYYY-MM-DD');
    const fetchURL = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-hour?start_date=${qParams.start_date}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}&aggregation_type=sum_by_day_of_week`;
    const fetchURL2 = `/retail_customer_api_v2/api/v2/building/area-entrance-exit-by-hour?start_date=${start_date_2}&mode=${qParams.periodType}&num_interval=${qParams.num_interval}&aggregation_type=sum_by_day_of_week`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    const fetchObj2 = await this.dataService.fetchData(fetchURL2);
    const mergeObj = {};
    Object.keys(fetchObj).forEach((key) => {
      mergeObj[key] = fetchObj[key];
    });
    Object.keys(fetchObj2).forEach((key) => {
      const newKey = parseInt(key, 10) + Object.keys(fetchObj).length;
      mergeObj[newKey] = fetchObj2[key];
    });
    return [mergeObj, lockNum] as [DayOfWeekAverages<HourlyDataAverages>, number];
  }

  //#endregion building/area-entrance-exit-by-hour day-by-day

  //#region traffic-site/ads-frequency
  async loadTrafficAdsFrequency(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type !== 'package' || !selectedInteractable?.name) {
      ++graphDataServiceInstance.trafficSitePackageCountLock;
      return Promise.resolve();
    }
    const packageName = selectedInteractable.name;
    return graphDataServiceInstance.deriveTrafficAdsFrequency(++graphDataServiceInstance.trafficSitePackageCountLock, packageName);
  }

  deriveTrafficAdsFrequency(lockNum: number, packageName: string) {
    const packageAdsFreq: { [key: string]: { current: number; diff: number; diffPercent: number } } = {
      all: { current: 54, diff: 0, diffPercent: 0 },
      'pkg-a': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-b': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-planb-tv-40': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-at-narong': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-the-base': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-ploenchit-interchange': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-show-dc': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-signature-max': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-so-bangkok': { current: 54, diff: 0, diffPercent: 0 },
      'pkg-twintube': { current: 54, diff: 0, diffPercent: 0 },
    };

    const adsFreq = packageAdsFreq[packageName] || { current: 0, diff: 0, diffPercent: 0 };
    const selectedDate = this.viewPeriodService.selectedDate;
    let updatedAdsFreq = adsFreq;
    if (this.viewPeriodService.isWeekPeriod) {
      updatedAdsFreq = { current: adsFreq.current * 7, diff: 0, diffPercent: 0 };
    }
    else if (this.viewPeriodService.isMonthPeriod) {
      const dayInMonth = selectedDate.daysInMonth();
      updatedAdsFreq = { current: adsFreq.current * dayInMonth, diff: 0, diffPercent: 0 };
    }
    this.trafficAdsFrequency$.next(updatedAdsFreq);
  }
  //#endregion traffic-site/ads-frequency

  //#region staff-traffic-count
  async loadStaffTrafficCount(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'toilet') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.deriveStaffTrafficCount(++graphDataServiceInstance.staffTrafficCountLock, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.toilet?.default_area_name || 'all';
      return graphDataServiceInstance.deriveStaffTrafficCount(++graphDataServiceInstance.staffTrafficCountLock, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    }
  }

  deriveStaffTrafficCount(lockNum: number, areaName: string) {
    const staffTrafficCount: { [key: string]: { current: number; diff: number; diffPercent: number } } = {
      'gtc-1': { current: 9, diff: 0, diffPercent: 0 },
      'gtc-2': { current: 9, diff: 0, diffPercent: 0 },
      gtb: { current: 9, diff: 0, diffPercent: 0 },
    };
    const staffCount = staffTrafficCount[areaName] || { current: 0, diff: 0, diffPercent: 0 };
    this.staffTrafficCount$.next(staffCount);
  }
  //#endregion staff-traffic-count

  //#region staff-traffic-count_2
  async loadStaffTrafficCount2(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'toilet') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.deriveStaffTrafficCount2(++graphDataServiceInstance.staffTrafficCountLock_2, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.toilet?.default_area_name || 'all';
      return graphDataServiceInstance.deriveStaffTrafficCount2(++graphDataServiceInstance.staffTrafficCountLock, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    }
  }

  deriveStaffTrafficCount2(lockNum: number, areaName: string) {
    const staffTrafficCount: { [key: string]: { current: number; diff: number; diffPercent: number } } = {
      'gtc-1': { current: 377, diff: 38, diffPercent: 10 },
      'gtc-2': { current: 377, diff: 38, diffPercent: 10 },
      gtb: { current: 377, diff: 38, diffPercent: 10 },
    };
    const staffCount = staffTrafficCount[areaName] || { current: 0, diff: 0, diffPercent: 0 };
    this.staffTrafficCount_2$.next(staffCount);
  }
  //#endregion staff-traffic-count_2

  //#region male/traffic-count
  async loadMaleTrafficCount(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'toilet') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.deriveMaleTrafficCount(++graphDataServiceInstance.maleTrafficCountLock, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.toilet?.default_area_name || 'all';
      return graphDataServiceInstance.deriveMaleTrafficCount(++graphDataServiceInstance.maleTrafficCountLock, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    }
  }

  deriveMaleTrafficCount(lockNum: number, areaName: string) {
    const staffTrafficCount: { [key: string]: { current: number; diff: number; diffPercent: number } } = {
      'gtc-1': { current: 163, diff: 8, diffPercent: 5 },
      'gtc-2': { current: 163, diff: 8, diffPercent: 5 },
      'gtc-b': { current: 163, diff: 8, diffPercent: 5 },
    };
    const staffCount = staffTrafficCount[areaName] || { current: 0, diff: 0, diffPercent: 0 };
    this.maleTrafficCount.next(staffCount);
  }
  //#endregion male/traffic-count

  //#region female/traffic-count
  async loadFemaleTrafficCount(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedInteractable = graphDataServiceInstance.baseGraphData.selectedInteractable$.value;
    if (selectedInteractable?.type === 'toilet') {
      const areaName = selectedInteractable?.name;
      return graphDataServiceInstance.deriveFemaleTrafficCount(++graphDataServiceInstance.femaleTrafficCountLock, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    } else {
      const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.toilet?.default_area_name || 'all';
      return graphDataServiceInstance.deriveFemaleTrafficCount(++graphDataServiceInstance.femaleTrafficCountLock, areaName);
      // return graphDataServiceInstance.fetchStaffTrafficCount(date, ++graphDataServiceInstance.vehicleParkingEntranceExitLock,areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveStaffTrafficCount(data, lockNum));
    }
  }

  deriveFemaleTrafficCount(lockNum: number, areaName: string) {
    const staffTrafficCount: { [key: string]: { current: number; diff: number; diffPercent: number } } = {
      'gtc-1': { current: 214, diff: 22, diffPercent: 10 },
      'gtc-2': { current: 214, diff: 22, diffPercent: 10 },
      gtb: { current: 214, diff: 22, diffPercent: 10 },
    };
    const staffCount = staffTrafficCount[areaName] || { current: 0, diff: 0, diffPercent: 0 };
    this.femaleTrafficCount.next(staffCount);
  }
  //#endregion female/traffic-count

  //#region vehicle-parking/frequency-of-visit-profile
  async loadVehicleParkingFrequencyOfVisitProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingFrequencyOfVisitProfileData(date, ++graphDataServiceInstance.vehicleParkingProfileFrequencyOfVisitLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingFrequencyOfVisitProfileData(data, lockNum));
  }

  deriveVehicleParkingFrequencyOfVisitProfileData(frequencyOfVisitDatas: IFetchData<FrequencyOfVisitProfileData[]>[], lockNum: number) {
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(frequencyOfVisitDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    const frequencyOfVisitChartData = { one: 0, two_three: 0, four_up: 0 };
    if (!dataIt || !dataIt.data) {
      this.vehicleParkingProfileFrequencyOfVisitData$.next({ one: 0, two_three: 0, four_up: 0 });
      return;
    }
    if (this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT) {
      if (momentIt.isBefore(this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT)) {
        this.vehicleParkingProfileFrequencyOfVisitData$.next({ one: 0, two_three: 0, four_up: 0 });
        return;
      }
    }
    const frequencyOfVisitProfileData = dataIt.data;
    for (const profileData of frequencyOfVisitProfileData) {
      if (Object.keys(profileData.group).length === 0) {
        for (const [timesString, count] of Object.entries(profileData.frequency_of_visit)) {
          const times = parseInt(timesString, 10);
          if (times === 1) {
            frequencyOfVisitChartData.one = (frequencyOfVisitChartData.one || 0) + count;
          } else if (times >= 2 && times <= 3) {
            frequencyOfVisitChartData.two_three = (frequencyOfVisitChartData.two_three || 0) + count;
          } else if (times >= 4 && times <= 50) {
            frequencyOfVisitChartData.four_up = (frequencyOfVisitChartData.four_up || 0) + count;
          }
        }
        const totalUniqueVistors = profileData.total_unique_visitors;
        Object.keys(frequencyOfVisitChartData).forEach(key => {
          const freqChartPercentage = (frequencyOfVisitChartData[key] / totalUniqueVistors) * 100;
          frequencyOfVisitChartData[key] = GraphDataService.procesChartData(freqChartPercentage, false, false);
        });
      }
    }
    if (lockNum < this.vehicleParkingProfileFrequencyOfVisitLock) { return; }
    this.unfilteredVehicleParkingFrequencyOfVisitProfileData$.next(frequencyOfVisitDatas);
    this.vehicleParkingProfileFrequencyOfVisitData$.next(frequencyOfVisitChartData);
  }

  deriveSelectedVehicleParkingFrequencyOfVisitProfileData(frequencyOfVisitDatas: IFetchData<FrequencyOfVisitProfileData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power, selectedVehicleProfile.plate_number_definition);
    const momentIt = this.viewPeriodService.selectedDate;
    const dataIt = filterSelectedDateData(frequencyOfVisitDatas, momentIt, this.viewPeriodService.viewPeriod)[0];
    const frequencyOfVisitChartData = { one: 0, two_three: 0, four_up: 0 };
    if (!dataIt || !dataIt.data) {
      this.vehicleParkingProfileFrequencyOfVisitData$.next({ one: 0, two_three: 0, four_up: 0 });
      return;
    }
    if (this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT) {
      if (momentIt.isBefore(this.configDataService.GRAPH_DATETIME_CONFIG?.FREQUENCY_OF_VISIT)) {
        this.vehicleParkingProfileFrequencyOfVisitData$.next({ one: 0, two_three: 0, four_up: 0 });
        return;
      }
    }
    const frequencyOfVisitProfileData = dataIt.data;
    for (const profileData of frequencyOfVisitProfileData) {
      if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
        for (const [timesString, count] of Object.entries(profileData.frequency_of_visit)) {
          const times = parseInt(timesString, 10);
          if (times === 1) {
            frequencyOfVisitChartData.one = (frequencyOfVisitChartData.one || 0) + count;
          } else if (times >= 2 && times <= 3) {
            frequencyOfVisitChartData.two_three = (frequencyOfVisitChartData.two_three || 0) + count;
          } else if (times >= 4 && times <= 50) {
            frequencyOfVisitChartData.four_up = (frequencyOfVisitChartData.four_up || 0) + count;
          }
        }
        const totalUniqueVistors = profileData.total_unique_visitors;
        Object.keys(frequencyOfVisitChartData).forEach(key => {
          const freqChartPercentage = (frequencyOfVisitChartData[key] / totalUniqueVistors) * 100;
          frequencyOfVisitChartData[key] = GraphDataService.procesChartData(freqChartPercentage, false, false);
        });
      }
    }
    // if (lockNum < this.vehicleParkingProfileFrequencyOfVisitLock) { return; }
    this.vehicleParkingProfileFrequencyOfVisitData$.next(frequencyOfVisitChartData);
  }

  async fetchVehicleParkingFrequencyOfVisitProfileData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getOneSelectedQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/frequency-of-visit-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<FrequencyOfVisitProfileData[]>[], number];
  }
  //#endregion vehicle-parking/frequency-of-visit-profile

  //#region vehicle-parking/repeated-vistiors-profile
  async loadVehicleParkingRepeatedVisitorsProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingRepeatedVisitorsProfileData(date, ++graphDataServiceInstance.vehicleParkingRepeatedVisitorsProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingRepeatedVisitorsProfileData(data, lockNum));
  }

  deriveVehicleParkingRepeatedVisitorsProfileData(repeatedVisitorsDatas: IFetchData<RepeatedVisitorProfileData[]>[], lockNum: number) {
    const repeatedVisitorPairData: [number, number] = [0, 0];
    GraphDataService.mapSevenDayLineChartData(repeatedVisitorsDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      const repeatedVisitorsData = dataFiltered.data;
      for (const profileData of repeatedVisitorsData) {
        if (Object.keys(profileData.group).length === 0) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            repeatedVisitorPairData[diffToSelectedDate + 1] = profileData.repeated_percentage;
          }
        }
      }
    });
    if (lockNum < this.vehicleParkingRepeatedVisitorsProfileLock) { return; }
    this.vehicleParkingRepeatedVisitorsProfilePercentageData$.next({
      new_percentage: GraphDataService.procesChartData((1 - repeatedVisitorPairData[1]) * 100, false, true),
      repeated_percentage: GraphDataService.procesChartData((repeatedVisitorPairData[1] * 100), false, true),
    });
    this.vehicleParkingCurrentRepeatedVisitorsProfileData$.next({
      current: GraphDataService.procesChartData(repeatedVisitorPairData[1] * 100, false, true),
      diff: GraphDataService.procesChartData((repeatedVisitorPairData[1] - repeatedVisitorPairData[0]) * 100, true, true)
    });
  }

  deriveSelectedVehicleParkingRepeatedVisitorsProfileData(repeatedVisitorsDatas: IFetchData<RepeatedVisitorProfileData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const repeatedVisitorPairData: [number, number] = [0, 0];
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power, selectedVehicleProfile.plate_number_definition);
    GraphDataService.mapSevenDayLineChartData(repeatedVisitorsDatas, this.viewPeriodService, (dataFiltered, _, __, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) { return; }
      const repeatedVisitorsData = dataFiltered.data;
      for (const profileData of repeatedVisitorsData) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          if (diffToSelectedDate === -1 || diffToSelectedDate === 0) {
            repeatedVisitorPairData[diffToSelectedDate + 1] = profileData.repeated_percentage;
          }
        }
      }
    });
    this.vehicleParkingRepeatedVisitorsProfilePercentageData$.next({
      new_percentage: GraphDataService.procesChartData((1 - repeatedVisitorPairData[1]) * 100, false, true),
      repeated_percentage: GraphDataService.procesChartData((repeatedVisitorPairData[1] * 100), false, true),
    });
    this.vehicleParkingCurrentRepeatedVisitorsProfileData$.next({
      current: GraphDataService.procesChartData(repeatedVisitorPairData[1] * 100, false, true),
      diff: GraphDataService.procesChartData((repeatedVisitorPairData[1] - repeatedVisitorPairData[0]) * 100, true, true)
    });
  }

  async fetchVehicleParkingRepeatedVisitorsProfileData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/repeated-visitors-profile?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<RepeatedVisitorProfileData[]>[], number];
  }

  //#endregion vehicle-parking/repeated-visitors-profile

  //#region vehicle-parking/recency-frequency-profile
  async loadVehicleParkingRecencyFrequencyProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingRecencyFrequencyProfileData(date, ++graphDataServiceInstance.vehicleParkingRecencyFrequencyProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingRecencyFrequencyProfileData(data, lockNum));
  }

  deriveVehicleParkingRecencyFrequencyProfileData(recencyFrequencyDatas: IFetchData<RecencyFrequencyProfileData[]>[], lockNum: number) {
    const regularVisitorTwoPair: [number, number] = [0, 0];
    const newVisitorTwoPair: [number, number] = [0, 0];
    const churnVisitorTwoPair: [number, number] = [0, 0];
    const lossVisitorTwoPair: [number, number] = [0, 0];
    const vehicleParkingRetentionData: Array<[number, number, number, string]> = [];
    const defaultVehicleParkingRetentionData: Array<[number, number, number, string]> = [
      [0, 0, 5, 'group1'],
      [0, 1, 2, 'group1'],
      [1, 1, 2, 'group1'],
      [1, 0, 4, 'group1'],
      [2, 0, 10, 'group2'],
      [2, 1, 10, 'group2'],
      [3, 0, 10, 'group2'],
      [3, 1, 12, 'group2'],
      [1, 2, 16, 'group3'],
      [1, 3, 16, 'group3'],
      [0, 2, 16, 'group3'],
      [0, 3, 16, 'group3'],
      [2, 2, 14, 'group4'],
      [2, 3, 14, 'group4'],
      [3, 3, 55, 'group4'],
      [3, 2, 25, 'group4'],
    ];
    const parseGroupName = (xIndex: number, yIndex: number): string => {
      if (xIndex === 0 && yIndex === 0) {
        return 'group1';
      }
      if (xIndex === 0 && yIndex === 1) {
        return 'group3';
      }
      if (xIndex === 1 && yIndex === 0) {
        return 'group2';
      }
      if (xIndex === 1 && yIndex === 1) {
        return 'group4';
      }
      return 'na';
    };
    const bucketMapper = [
      { lower: 0, upper: 6 },
      { lower: 7, upper: 13 },
      { lower: 14, upper: 20 },
      { lower: 21, upper: 27 },
    ];
    const reverseBucketMapper = [
      { lower: 7, upper: 13 },
      { lower: 0, upper: 6 },
    ];
    GraphDataService.mapSevenDayLineChartData(recencyFrequencyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        this.vehicleParkingRetentionProfileData$.next(defaultVehicleParkingRetentionData);
        return;
      }
      for (const profileData of dataFiltered.data) {
        if (Object.keys(profileData.group).length === 0) {
          const recencyFrequencyData = profileData.data;
          let totalCountData = 0;
          if (diffToSelectedDate === 0) {
            for (const rfData of recencyFrequencyData) {
              const xIndex = reverseBucketMapper.length - rfData.bucket[0] - 1;
              const yIndex = rfData.bucket[1];
              if (xIndex !== null && yIndex !== null) {
                const groupName = parseGroupName(xIndex, yIndex);
                vehicleParkingRetentionData.push([xIndex, yIndex, rfData.count, groupName]);
                totalCountData += rfData.count;
              }
            }
          }
          if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 0) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 0);
              newVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 1) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 1);
              regularVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 0) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 0);
              lossVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 1) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 1);
              churnVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
          }
        }
      }
    });
    if (lockNum < this.vehicleParkingRecencyFrequencyProfileLock) { return; }
    this.vehicleParkingNewVisitorProfileCount$.next({
      current: processChartData(newVisitorTwoPair[1], false, false),
      diff: processChartData(newVisitorTwoPair[1] - newVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((newVisitorTwoPair[1] - newVisitorTwoPair[0]) / newVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingLossVisitorProfileCount$.next({
      current: processChartData(lossVisitorTwoPair[1], false, false),
      diff: processChartData(lossVisitorTwoPair[1] - lossVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((lossVisitorTwoPair[1] - lossVisitorTwoPair[0]) / lossVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingRegularVisitorProfileCount$.next({
      current: processChartData(regularVisitorTwoPair[1], false, false),
      diff: processChartData(regularVisitorTwoPair[1] - regularVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((regularVisitorTwoPair[1] - regularVisitorTwoPair[0]) / regularVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingChurnVisitorProfileCount$.next({
      current: processChartData(churnVisitorTwoPair[1], false, false),
      diff: processChartData(churnVisitorTwoPair[1] - churnVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((churnVisitorTwoPair[1] - churnVisitorTwoPair[0]) / churnVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingRetentionProfileData$.next(vehicleParkingRetentionData);
  }

  deriveSelectedVehicleParkingRecencyFrequencyProfileData(recencyFrequencyDatas: IFetchData<RecencyFrequencyProfileData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power, selectedVehicleProfile.plate_number_definition);
    const regularVisitorTwoPair: [number, number] = [0, 0];
    const newVisitorTwoPair: [number, number] = [0, 0];
    const churnVisitorTwoPair: [number, number] = [0, 0];
    const lossVisitorTwoPair: [number, number] = [0, 0];
    const vehicleParkingRetentionData: Array<[number, number, number, string]> = [];
    const defaultVehicleParkingRetentionData: Array<[number, number, number, string]> = [
      [0, 0, 5, 'group1'],
      [0, 1, 2, 'group1'],
      [1, 1, 2, 'group1'],
      [1, 0, 4, 'group1'],
      [2, 0, 10, 'group2'],
      [2, 1, 10, 'group2'],
      [3, 0, 10, 'group2'],
      [3, 1, 12, 'group2'],
      [1, 2, 16, 'group3'],
      [1, 3, 16, 'group3'],
      [0, 2, 16, 'group3'],
      [0, 3, 16, 'group3'],
      [2, 2, 14, 'group4'],
      [2, 3, 14, 'group4'],
      [3, 3, 55, 'group4'],
      [3, 2, 25, 'group4'],
    ];
    const parseGroupName = (xIndex: number, yIndex: number): string => {
      if (xIndex === 0 && yIndex === 0) {
        return 'group1';
      }
      if (xIndex === 0 && yIndex === 1) {
        return 'group3';
      }
      if (xIndex === 1 && yIndex === 0) {
        return 'group2';
      }
      if (xIndex === 1 && yIndex === 1) {
        return 'group4';
      }
      return 'na';
    };
    const bucketMapper = [
      { lower: 0, upper: 6 },
      { lower: 7, upper: 13 },
      { lower: 14, upper: 20 },
      { lower: 21, upper: 27 },
    ];
    const reverseBucketMapper = [
      { lower: 7, upper: 13 },
      { lower: 0, upper: 6 },
    ];
    GraphDataService.mapSevenDayLineChartData(recencyFrequencyDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        this.vehicleParkingRetentionProfileData$.next(defaultVehicleParkingRetentionData);
        return;
      }
      for (const profileData of dataFiltered.data) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          const recencyFrequencyData = profileData.data;
          let totalCountData = 0;
          if (diffToSelectedDate === 0) {
            for (const rfData of recencyFrequencyData) {
              const xIndex = reverseBucketMapper.length - rfData.bucket[0] - 1;
              const yIndex = rfData.bucket[1];
              if (xIndex !== null && yIndex !== null) {
                const groupName = parseGroupName(xIndex, yIndex);
                vehicleParkingRetentionData.push([xIndex, yIndex, rfData.count, groupName]);
                totalCountData += rfData.count;
              }
            }
          }
          if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 0) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 0);
              newVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 1) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 0 && rfd.bucket[1] === 1);
              regularVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 0) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 0);
              lossVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
            if (recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 1) !== undefined) {
              const findedData = recencyFrequencyData.find(rfd => rfd.bucket[0] === 1 && rfd.bucket[1] === 1);
              churnVisitorTwoPair[diffToSelectedDate + 1] = findedData.count;
            }
          }
        }
      }

    });
    this.vehicleParkingNewVisitorProfileCount$.next({
      current: processChartData(newVisitorTwoPair[1], false, false),
      diff: processChartData(newVisitorTwoPair[1] - newVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((newVisitorTwoPair[1] - newVisitorTwoPair[0]) / newVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingLossVisitorProfileCount$.next({
      current: processChartData(lossVisitorTwoPair[1], false, false),
      diff: processChartData(lossVisitorTwoPair[1] - lossVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((lossVisitorTwoPair[1] - lossVisitorTwoPair[0]) / lossVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingRegularVisitorProfileCount$.next({
      current: processChartData(regularVisitorTwoPair[1], false, false),
      diff: processChartData(regularVisitorTwoPair[1] - regularVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((regularVisitorTwoPair[1] - regularVisitorTwoPair[0]) / regularVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingChurnVisitorProfileCount$.next({
      current: processChartData(churnVisitorTwoPair[1], false, false),
      diff: processChartData(churnVisitorTwoPair[1] - churnVisitorTwoPair[0], true, false),
      diffPercent: processChartData(((churnVisitorTwoPair[1] - churnVisitorTwoPair[0]) / churnVisitorTwoPair[0]) * 100, true, true),
    });
    this.vehicleParkingRetentionProfileData$.next(vehicleParkingRetentionData);
  }

  async fetchVehicleParkingRecencyFrequencyProfileData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const periodType = this.viewPeriodService.viewPeriod;
    const updatedStartDate = this.adjustDateTime(date, periodType);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/recency-frequency-profile?start_date=${updatedStartDate}&mode=day&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<RecencyFrequencyProfileData[]>[], number];
  }

  //#endregion vehicle-parking/recency-frequency-profile

  //#region vehicle-parking/timespent-profile

  async loadVehicleParkingTimespentProfileData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const areaName = graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
    return graphDataServiceInstance.fetchVehicleParkingTimespentProfileData(date, ++graphDataServiceInstance.vehicleParkingTimespentProfileLock, areaName).then(([data, lockNum]) => graphDataServiceInstance.deriveVehicleParkingTimespentProfileData(data, lockNum));
  }

  deriveVehicleParkingTimespentProfileData(timespentDatas: IFetchData<PlateTimespentProfileData[]>[], lockNum: number) {
    const binNameSet = new Set<string>();
    const binNameList = this.configDataService.BIN_TIMESPENT_LIST;
    const vehicleParkingPlateTimespentBinProfileData: { [binName: string]: number } = {};
    const vehicleParkingTimespentProfilePair: [number, number] = [0, 0];
    binNameList.forEach(binName => {
      binNameSet.add(binName);
    });
    const binNames = Array.from(binNameSet.values());
    this.unfilteredVehicleParkingPlateTimespentBinProfileData$.next(timespentDatas);
    GraphDataService.mapSevenDayLineChartData(timespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        // for (const binKey of binNames) {
        //   vehicleParkingPlateTimespentBinProfileData[binKey] = 0;
        // }
        // this.vehicleParkingPlateTimespentBinProfileData$.next(vehicleParkingPlateTimespentBinProfileData);
        return;
      }
      for (const profileData of dataFiltered.data) {
        if (Object.keys(profileData.group).length === 0) {
          if (diffToSelectedDate === 0) {
            for (const binKey of binNames) {
              if (binKey in profileData) {
                vehicleParkingPlateTimespentBinProfileData[binKey] = GraphDataService.procesChartData((profileData[binKey].count / profileData._total.count) * 100, false, false);
              } else {
                vehicleParkingPlateTimespentBinProfileData[binKey] = 0;
              }
            }
          }
          if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
            vehicleParkingTimespentProfilePair[diffToSelectedDate + 1] = profileData._total.average_timespent / 60;
          }
        }
      }
    });

    if (lockNum < this.vehicleParkingTimespentProfileLock) { return; }
    const diffTimespentProfile = vehicleParkingTimespentProfilePair[1] - vehicleParkingTimespentProfilePair[0];
    this.currentVehicleParkingPlateTimespentProfileData$.next({
      current: vehicleParkingTimespentProfilePair[1],
      diff: GraphDataService.procesChartData(diffTimespentProfile, true, false),
      diffPercent: vehicleParkingTimespentProfilePair[0] === 0 ? 0 : GraphDataService.procesChartData(diffTimespentProfile / vehicleParkingTimespentProfilePair[0], true, true)
    });
    this.vehicleParkingPlateTimespentBinProfileData$.next(vehicleParkingPlateTimespentBinProfileData);
  }

  deriveSelectedVehicleParkingTimespentProfileData(timespentDatas: IFetchData<PlateTimespentProfileData[]>[], selectedVehicleProfile: { [selectorName: string]: string }) {
    const binNameSet = new Set<string>();
    const binNameList = this.configDataService.BIN_TIMESPENT_LIST;
    const vehicleParkingPlateTimespentBinProfileData: { [binName: string]: number } = {};
    const selectedProfilefilter = GraphDataService.createFilterDictVehicleObject(selectedVehicleProfile.vehicle_type, selectedVehicleProfile.car_brand, selectedVehicleProfile.purchasing_power, selectedVehicleProfile.plate_number_definition);
    const vehicleParkingTimespentProfilePair: [number, number] = [0, 0];
    binNameList.forEach(binName => {
      binNameSet.add(binName);
    });
    const binNames = Array.from(binNameSet.values());
    GraphDataService.mapSevenDayLineChartData(timespentDatas, this.viewPeriodService, (dataFiltered, _isLivePeriod, _isPred, diffToSelectedDate) => {
      if (!dataFiltered || !dataFiltered.data) {
        // for (const binKey of binNames) {
        //   vehicleParkingPlateTimespentBinProfileData[binKey] = 0;
        // }
        // this.vehicleParkingPlateTimespentBinProfileData$.next(vehicleParkingPlateTimespentBinProfileData);
        return;
      }
      for (const profileData of dataFiltered.data) {
        if (compare1DepthObjects(profileData.group, selectedProfilefilter)) {
          if (diffToSelectedDate === 0) {
            for (const binKey of binNames) {
              if (binKey in profileData) {
                vehicleParkingPlateTimespentBinProfileData[binKey] = GraphDataService.procesChartData((profileData[binKey].count / profileData._total.count) * 100, false, false);
              } else {
                vehicleParkingPlateTimespentBinProfileData[binKey] = 0;
              }
            }
          }
          if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
            vehicleParkingTimespentProfilePair[diffToSelectedDate + 1] = profileData._total.average_timespent;
          }
        }
      }
    });
    const diffTimespentProfile = vehicleParkingTimespentProfilePair[1] - vehicleParkingTimespentProfilePair[0];
    this.currentVehicleParkingPlateTimespentProfileData$.next({
      current: vehicleParkingTimespentProfilePair[1],
      diff: GraphDataService.procesChartData(diffTimespentProfile, true, false),
      diffPercent: diffTimespentProfile === 0 && vehicleParkingTimespentProfilePair[0] === 0 ? 0 : GraphDataService.procesChartData(diffTimespentProfile / vehicleParkingTimespentProfilePair[0], true, true)
    });
    this.vehicleParkingPlateTimespentBinProfileData$.next(vehicleParkingPlateTimespentBinProfileData);
  }

  async fetchVehicleParkingTimespentProfileData(date: moment.Moment, lockNum: number, area: string) {
    const qParams = this.getCustomSelectedQueryParameter(date, 2);
    const fetchURL = `/retail_customer_api_v2/api/v2/vehicle-parking/timespent-profile?start_date=${qParams.start_date}&mode=day&num_interval=${qParams.num_interval}&area=${area}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);
    return [fetchObj, lockNum] as [IFetchData<PlateTimespentProfileData[]>[], number];
  }

  //#endregion vehicle-parking/timespent-profile

  //#region floor/area-entrance-exit
  async loadFloorAreaEntranceExitData(date: moment.Moment, instance?: GraphDataService) {
    const graphDataServiceInstance = instance || this;
    const selectedDirectory = graphDataServiceInstance.selectedDirectory$.getValue();
    const buildingName = selectedDirectory?.building || graphDataServiceInstance.configDataService.MAIN_BUILDING;
    return graphDataServiceInstance.fetchFloorAreaEntranceExitData(date, ++graphDataServiceInstance.floorAreaEntranceExitAllPinLock, buildingName).then(([data, lockNum]) => graphDataServiceInstance.deriveFloorAreaEntranceExitData(data, lockNum, buildingName));
  }

  deriveFloorAreaEntranceExitData(floorAreaEntranceExitDatas: IFetchData<AreaEntranceExitProximityGroupData>[], lockNum: number, floor?: string) {
    const floorEntranceExitBreakdownData: { [floorName: string]: { entrance: number; exit: number } } = {};
    const floorAvgTimespentBreakdownData: { [floorName: string]: number } = {};
    const floorEntranceExitTrendData: { [floorName: string]: { entrance: number[]; exit: number[] } } = {};
    const floorEntranceExitTimePairData: { [floorName: string]: { entrance: [number, number]; exit: [number, number] } } = {};
    const floorAvgTimespentTimePairData: { [floorName: string]: [number, number] } = {};
    const floorList = this.configDataService.FLOOR_NAME_LIST;
    for (const floorName of floorList) {
      floorEntranceExitBreakdownData[floorName] = { entrance: 0, exit: 0 };
      floorAvgTimespentBreakdownData[floorName] = 0;
      floorEntranceExitTrendData[floorName] = { entrance: [], exit: [] };
      floorEntranceExitTimePairData[floorName] = { entrance: [0, 0], exit: [0, 0] };
      floorAvgTimespentTimePairData[floorName] = [0, 0];
      const filteredAreaData = floorAreaEntranceExitDatas.filter(data => data.area === floorName);
      GraphDataService.mapSevenDayLineChartData(filteredAreaData, this.viewPeriodService, (dataFiltered, _isLivePeriod, isPred, diffToSelectedDate) => {
        // handle missing data
        if (!dataFiltered || !dataFiltered.data || isPred) {
          Object.keys(floorEntranceExitTrendData[floorName]).forEach(channel => {
            floorEntranceExitTrendData[floorName][channel].push(0);
          })
          return;
        }
        const floorAreaEntranceExitData = dataFiltered.data;
        if (diffToSelectedDate === 0) {
          Object.keys(floorEntranceExitBreakdownData[floorName]).forEach(channel => {
            const data = processChartData(floorAreaEntranceExitData[channel], false, false);
            floorEntranceExitBreakdownData[floorName][channel] = data;
          });
          floorAvgTimespentBreakdownData[floorName] = processChartData(floorAreaEntranceExitData.average_timespent, false, false);
        }
        if (diffToSelectedDate === 0 || diffToSelectedDate === -1) {
          Object.keys(floorEntranceExitTimePairData[floorName]).forEach(channel => {
            const data = processChartData(floorAreaEntranceExitData[channel], false, false);
            floorEntranceExitTimePairData[floorName][channel][diffToSelectedDate + 1] = data;
          });
          const avgTimeSpent = processChartData(floorAreaEntranceExitData.average_timespent, false, false);
          floorAvgTimespentTimePairData[floorName][diffToSelectedDate + 1] = avgTimeSpent;
        }
        Object.keys(floorEntranceExitTrendData[floorName]).forEach(channel => {
          const data = processChartData(floorAreaEntranceExitData[channel], false, false);
          floorEntranceExitTrendData[floorName][channel].push(data);
        });
      });
    }

    if (lockNum < this.floorAreaEntranceExitLock) { return; }
    const currentFloorAreaEntranceExitData: { [floorName: string]: { entrance: { current: number; diff: number; diffPercent: number }, exit: { current: number; diff: number; diffPercent: number } } } = Object.entries(floorEntranceExitTimePairData).reduce((acc, [floorName, channelTimePairData]) => {
      const entranceDiff = channelTimePairData.entrance[1] - channelTimePairData.entrance[0];
      const entranceDiffPercent = channelTimePairData.entrance[0] !== 0 ? (entranceDiff / channelTimePairData.entrance[0]) * 100 : 0;

      const exitDiff = channelTimePairData.exit[1] - channelTimePairData.exit[0];
      const exitDiffPercent = channelTimePairData.exit[0] !== 0 ? (exitDiff / channelTimePairData.exit[0]) * 100 : 0;

      return {
        ...acc,
        [floorName]: {
          entrance: {
            current: channelTimePairData.entrance[1],
            diff: processChartData(entranceDiff, true, false),
            diffPercent: processChartData(entranceDiffPercent, true, true)
          },
          exit: {
            current: channelTimePairData.exit[1],
            diff: processChartData(exitDiff, true, false),
            diffPercent: processChartData(exitDiffPercent, true, true)
          }
        }
      };
    }, {});

    const currentFloorAreaAvgTimespentData: { [floorName: string]: { current: number; diff: number; diffPercent: number } } = Object.entries(floorAvgTimespentTimePairData).reduce((acc, [floorName, timePairData]) => {
      const avgTimespentDiff = timePairData[1] - timePairData[0];
      const avgTimespentDiffPercent = timePairData[0] !== 0 ? (avgTimespentDiff / timePairData[0]) * 100 : 0;

      return {
        ...acc,
        [floorName]: {
          current: timePairData[1],
          diff: processChartData(avgTimespentDiff, true, false),
          diffPercent: processChartData(avgTimespentDiffPercent, true, true)
        }
      };
    }, {});

    this.currentFloorAreaEntranceExitData$.next(currentFloorAreaEntranceExitData);
    this.currentFloorAreaAvgTimespentData$.next(currentFloorAreaAvgTimespentData);
    this.floorAreaEntranceExitTrendData$.next(floorEntranceExitTrendData);
    this.floorAreaEntranceExitBreakdownData$.next(floorEntranceExitBreakdownData);
    this.floorAreaAvgTimespentBreakdownData$.next(floorAvgTimespentBreakdownData);
  }

  async fetchFloorAreaEntranceExitData(date: moment.Moment, lockNum: number, buildingName?: string) {
    const qParams = this.getLineChartQueryParameter(date);
    const fetchURL = `/retail_customer_api_v2/api/v2/floor/area-entrance-exit?start_date=${qParams.start_date}&mode=${qParams.periodType.toBackendV2String()}&num_interval=${qParams.num_interval}&building=${buildingName}`;
    const fetchObj = await this.dataService.fetchData(fetchURL);

    return [fetchObj, lockNum] as [IFetchData<AreaEntranceExitProximityGroupData>[], number];
  }
  //#endregion floor/area-entrance-exit

}
