import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { GraphDependency } from 'src/app/enum/graph-dependency.enum';
import { isScreenSmallerThanMD } from 'src/app/helpers/util';
import { BaseDataDependency } from 'src/app/objects/baseGraphData';
import { GraphDataConfig } from 'src/app/objects/config';
import { SelectableDataName } from 'src/app/objects/selectableData';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { GraphDataService } from 'src/app/services/graph-data-service.service';
import { ViewPeriodService } from 'src/app/services/view-period.service';

@Component({
  selector: 'app-dynamic-custom-pie-chart',
  templateUrl: './dynamic-custom-pie-chart.component.html',
  styleUrls: ['./dynamic-custom-pie-chart.component.scss'],
})
export class DynamicCustomPieChartComponent implements OnInit, OnChanges {

  @Input() data$: BehaviorSubject<ChartDataSets[]>;
  @Input() chartLabel$: BehaviorSubject<Label[]>;
  @Input() chartOptions$: BehaviorSubject<ChartOptions>;
  @Input() isShow = true;
  @Input() isLock: boolean | BehaviorSubject<boolean>;
  @Input() title: string;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isShowLegend: boolean;
  @Input() inputLockText: string;
  @Input() isShowLegendOnPercent: boolean;
  @Input() unitType: 'car';
  @Input() selectedPie$: BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>;
  @Input() selector: { [selectorName: string]: GraphDataConfig } = {};
  @Input() overrideShouldShowSelector: boolean;
  @Input() isEnableTBD = false;
  @Input() showAveragePerDay = false;

  lockOpacity = 0.35;

  showAverage = false;
  uniqueGraphIds = new Set<BaseDataDependency>();
  
  sumOfDatasets = 0;
  chartDataSets: ChartDataSets[] = [];
  chartLabels: Label[] = [];
  pageSubscription = new Subscription();
  selectedName: string[] = [];
  selectorName: string[] = [];
  shouldShowSelector = false;

  constructor(
    public configDataService: ConfigDataService,
    private ref: ChangeDetectorRef,
    private graphDataService: GraphDataService,
    private viewPeriodService: ViewPeriodService,
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  private update() {
    this.selectorName = Object.keys(this.selector);
    if (this.overrideShouldShowSelector !== undefined) {
      this.shouldShowSelector = this.overrideShouldShowSelector;
    } else {
      this.shouldShowSelector = this.selectorName && this.selectorName.length > 1;
    }
    this.selectedName = Object.entries(this.selector).filter(([_, selectorDetail]) => selectorDetail.selected).map(([name, _]) => name);
  }

  ngOnInit() {
    this.pageSubscription.add(combineLatest([this.data$, this.chartLabel$]).subscribe(([datas, labels]) => {
      if (!datas || !labels) { return; }
      this.chartDataSets = datas;
      if (datas[0]?.data) {
        this.sumOfDatasets = (datas[0].data as number[]).reduce((a, b) => a + b, 0);
      }
      this.chartLabels = labels;
    }));
    this.update();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.update();
  }

  legendNumber(data: number) {
    if (!data) {
      return '0%';
    }
    return  data > 1 ? `${data.toFixed(0)}%` : `<1%`;
  }

  backgroundColorData(idx: number) {
    if (this.chartDataSets.length > 0) {
      return this.chartDataSets[0]?.backgroundColor[idx];
    }
    return 'black';
  }

  presentLabelData(idx: number) {
    if (this.chartDataSets.length > 0) {
      return this.chartLabels[idx] as string;
    }
    return;
  }

  changeSelectedGraph(event: any) {
    const selectedName = event.detail.value as string[];
    this.selectedName = selectedName;
    const currentSelectedPie = this.selectedPie$.value;
    Object.keys(currentSelectedPie).forEach(selectorName => {
      currentSelectedPie[selectorName].selected = selectedName.includes(selectorName);
    });
    this.selectedPie$.next(currentSelectedPie);
  }

  get datasets() {
    return this.chartDataSets[0]?.data;
  }

  get unit() {
    return this.unitType === 'car' ? ' cars' : '';
  }

  get isShowAveragePerDayButton() {
    return this.showAveragePerDay && (this.viewPeriodService.isWeekPeriod || this.viewPeriodService.isMonthPeriod);
  }

  toggleAveragePerDayFilter(): void {
    this.showAverage = !this.showAverage;
    const currentSelectedPie = this.selectedPie$.value;
    // Add logic to update the data based on the showAverage state.
    Object.keys(currentSelectedPie).forEach(selectorName => {
      const graphId = currentSelectedPie[selectorName].name as SelectableDataName;
      const graphData = this.graphDataService.baseGraphData.getSelectedGraph(graphId, this.graphDataService);
      this.uniqueGraphIds.add(graphData.dependencies as BaseDataDependency);
    });
    this.uniqueGraphIds.forEach(graphId => {
      const currentValue = this.graphDataService.baseGraphData.getIsDisplayAverageValueForGraph(graphId).value;
      this.graphDataService.baseGraphData.setIsDisplayAverageValueForGraph(graphId, !currentValue);
    });
  }
  
  get displayIconOnMiddle() {
    return isScreenSmallerThanMD(window);
  }

}
