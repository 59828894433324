import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';

export class FloorFromToResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig(); // ENTRANCE_EXIT_FLOW_BUILDING, ENTRANCE_EXIT_FLOW_FLOOR, ENTRANCE_EXIT_FLOW_ZONE
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const channel = (getAdditionalInput(additionalInput, 'channel') || 'entrance') as 'entrance' | 'exit';
        const zoneName = (getAdditionalInput(additionalInput, 'zone')) as string;
        const initialfloorName = graphDataService.selectedFloorName$.value || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;

        type FromToData = { entrance: { [name: string]: number }; exit: { [name: string]: number } };
        const getFromToData = (
            data: { [buildingName: string]: FromToData | { [floorName: string]: FromToData } | { [floorName: string]: { [zoneName: string]: FromToData } } },
            floorName?: string,
            depthZoneName?: string,
        ) => accessDepthData<FromToData>(data, buildingName, floorName, depthZoneName);


        const initialFromToData = initialIsAll ? (getFromToData(graphDataService.buildingEntranceExitFlowData$.value)) :
            (zoneName ? getFromToData(graphDataService.zoneEntranceExitFlowData$.value, initialfloorName, zoneName) : getFromToData(graphDataService.floorEntranceExitFlowData$.value, initialfloorName));
        const initialChartClass = Object.keys(initialFromToData[channel] || {}).filter(className => className !== '_total').sort();
        comInstance.chartLabel = initialChartClass.map(name => configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[name] || configDataService.DISPLAY_LANGUAGE.ZONE_NAME[name] || name);
        const initialBarData: GenericBarChartData[] = initialChartClass.map((className, labelIndex) => ({
            data: initialChartClass.map((classIt) => classIt === className ? initialFromToData[channel][classIt] : null),
            color: PIE_CHART_COLOR_LIST[labelIndex % PIE_CHART_COLOR_LIST.length],
            label: configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[className] || configDataService.DISPLAY_LANGUAGE.ZONE_NAME[className] || className,
        }));
        const barData$ = new BehaviorSubject<GenericBarChartData[]>(initialBarData.length > 0 ? initialBarData : [{ data: [0], color: PIE_CHART_COLOR_LIST[0], label: 'N/A' }]);

        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.buildingEntranceExitFlowData$, graphDataService.floorEntranceExitFlowData$, graphDataService.zoneEntranceExitFlowData$]).subscribe(
            ([selectedFloorName, allData, floorData, zoneData]) => {
                if (!allData || !floorData || !zoneData) { return; }
                const floorName = selectedFloorName || 'ALL';
                const isAll = floorName === 'ALL';
                const fromToData = isAll ? getFromToData(allData) : (zoneName ? getFromToData(zoneData, floorName, zoneName) : getFromToData(floorData, floorName));
                const chartClass = Object.keys(fromToData[channel] || {}).filter(className => className !== '_total').sort();
                comInstance.chartLabel = chartClass.map(name => configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[name] || configDataService.DISPLAY_LANGUAGE.ZONE_NAME[name] || name);
                barData$.next(chartClass.map((className, labelIndex) => ({
                    data: chartClass.map((classIt) => classIt === className ? fromToData[channel][classIt] : null),
                    color: PIE_CHART_COLOR_LIST[labelIndex % PIE_CHART_COLOR_LIST.length],
                    label: configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[className] || configDataService.DISPLAY_LANGUAGE.ZONE_NAME[className] || className,
                })));
            }
        ));

        comInstance.title = configDataService.DISPLAY_LANGUAGE.FROM_TO[channel];
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = false;
        comInstance.data$ = barData$;
        // comInstance.chartLabel = chartLabel;
        comInstance.isStacked = 'XY';
        comInstance.xAxesRotation = 90;
        return comInstance;
    }

}
