import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { isSelectionVisitorProfileAll } from '../helpers/util';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { generateNestedData } from '../helpers/mock-data-generator';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

const CHART_COLOR = '#6BC26F';

export class VisitorPurchaseRateTrendResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        //await configDataService.loadAppConfig();
        const initialSelectedProfile = graphDataService.selectedVisitorProfile$.value;
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: isSelectionVisitorProfileAll(initialSelectedProfile) ? graphDataService.purchaseRateChartData$.value : graphDataService.purchaseRateProfiletData$.value,
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST,
            label: configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE,
            toolTipLabelUnit: '%',
        }]);
        const isLock$ = new BehaviorSubject(this.isLock);

        if (isMockData) {
            subscription.add(combineLatest([graphDataService.selectedVisitorProfile$, viewPeriodService.dayList]).subscribe(async () => {
                const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'VISITOR_PURCHASE_RATE_TREND', 'count', 8);
                const chartPoints = Object.values(mockData);
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const lineChartData: GenericLineChartData[] = [{
                    points: chartPoints[0] as number[],
                    backgroundColor: `${CHART_COLOR}22`,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE},${time}`),
                    label: configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE,
                    toolTipLabelUnit: '%',
                }];
                chartData$.next(lineChartData);
            }));
        } else {
            subscription.add(combineLatest([graphDataService.purchaseRateChartData$, graphDataService.purchaseRateProfiletData$]).subscribe(([data, dataWithProfile]) => {
                const selectedProfile = graphDataService.selectedVisitorProfile$.value;
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const lineChartData: GenericLineChartData[] = [{
                    points: isSelectionVisitorProfileAll(selectedProfile) ? data : dataWithProfile,
                    backgroundColor: `${CHART_COLOR}22`,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE},${time}`),
                    label: configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE,
                    toolTipLabelUnit: '%',
                }];
                chartData$.next(lineChartData);
                isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(graphDataService.selectedVisitorProfile$.value)));
            }));
        }


        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE_TREND;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        comInstance.sizeXS = '12';
        comInstance.sizeMD = '10';
        comInstance.offsetMD = '1';
        return comInstance;
    }

}
