import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';

export class GateTimeVisitResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? (graphDataService.baseGraphData.selectedInteractable$.value || {}).object.userData.building : configDataService.MAIN_BUILDING) as string;
        const initialFloorName = graphDataService.selectedFloorName$.value || graphDataService.baseGraphData.selectedDirectory$.value.floor || 'ALL';
        const initialLevel = graphDataService.baseGraphData.selectedLevel$.value;
        const initialGateName = (graphDataService.baseGraphData.selectedInteractable$.value || {}).name;
        const initialZoneName = graphDataService.baseGraphData.selectedDirectory$.value.zone;
        const contribute_level = getAdditionalInput(additionalInput, 'contributeLevel') || configDataService.isFeatureEnabled('heatmap_v2', 'contribute_level') as string;
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE';

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;

        const defaultVALUE = Array(configDataService.TIME_LIST.length).fill(null);

        const getTrafficData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [gateName: string]: number[] } } },
            building?: string,
            floorName?: string,
            gateName?: string,
        ) => (!data || !floorName || !gateName) ? defaultVALUE : accessDepthData<number[]>(data, building, floorName, gateName, defaultVALUE);

        const getZonePinTrafficData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [gateName: string]: number[] } } } | { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [gateName: string]: number[] } } } },
            building?: string,
            floorName?: string,
            zoneName?: string,
            gateName?: string,
        ) => {
            if (!data || !data[building]) { return defaultVALUE; }
            if (floorName) {
              if (zoneName && data[building][floorName]) {
                if (gateName && data[building][floorName][zoneName]) {
                    return (data[building][floorName][zoneName][gateName] || defaultVALUE) as number[];
                }
                return (data[building][floorName][zoneName] || defaultVALUE) as number[];
              }
              return (data[building][floorName] || defaultVALUE) as number[];
            }
            return (data[building] || defaultVALUE) as number[];
        };

        const biDataGrouper = (prev: any[], current: any, idx: number) => {
            const currentArray = prev[prev.length - 1];
            if (currentArray.length === 3) {
                prev.push([currentArray[currentArray.length - 1]]);
                prev[prev.length - 1].push(current);
            } else {
                prev[prev.length - 1].push(current);
            }
            return prev;
        };
        const calBiDataSumShiftOne = (prev: number[], current: any, idx: number) => {
            if (idx === 0) { return prev; } // shift by one value
            if (idx % 2 === 0) {
                prev.push(prev.pop() + current);
            } else {
                prev.push(current);
            }
            return prev;
        };
        const calBiDataSum = (prev: number[], current: any, idx: number) => {
            if (idx % 2 === 1) {
                prev.push(prev.pop() + current);
            } else {
                prev.push(current);
            }
            return prev;
        };

        const chartLabel = configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 3).map(([t1, _, t2]) => `${t1}-${t2}`);
        const busiest_time_by_entrance = configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance');
        const shift_first_hour = configDataService.isFeatureEnabled('graph_data', 'gate_time_visit')?.shift_first_hour;

        let initialEntranceData: any[] = [];
        let initialExitData: any[] = [];
        if (contribute_level === 'building') {
            initialEntranceData = initialLevel === 'FLOOR' 
            ? getTrafficData(graphDataService.entranceBuildingByHourPin$.value, buildingName, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.entranceZonePinByHour$.value, buildingName, initialFloorName, initialZoneName, initialGateName);
            initialExitData = initialLevel === 'FLOOR' 
            ? getTrafficData(graphDataService.exitBuildingByHourPin$.value, buildingName, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.exitZonePinByHour$.value, buildingName, initialFloorName, initialZoneName, initialGateName);
        } else {
            initialEntranceData = initialLevel === 'FLOOR' 
            ? getTrafficData(graphDataService.entranceFloorPinByHour$.value, buildingName, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.entranceZonePinByHour$.value, buildingName, initialFloorName, initialZoneName, initialGateName);
            initialExitData = initialLevel === 'FLOOR' 
            ? getTrafficData(graphDataService.exitFloorPinByHour$.value, buildingName, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.exitZonePinByHour$.value, buildingName, initialFloorName, initialZoneName, initialGateName);
        }
        
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: busiest_time_by_entrance ? shift_first_hour ? initialEntranceData.reduce(calBiDataSumShiftOne, []) : initialEntranceData.reduce(calBiDataSum, []) : initialEntranceData.reduce(calBiDataSumShiftOne, []),
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
        }, {
            data: busiest_time_by_entrance ? shift_first_hour ? initialExitData.reduce(calBiDataSumShiftOne, []) : initialExitData.reduce(calBiDataSum, []) : initialExitData.reduce(calBiDataSumShiftOne, []),
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.EXIT,
        }]);
        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedInteractable$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, 
            combineLatest([graphDataService.entranceBuildingByHourPin$, graphDataService.exitBuildingByHourPin$, graphDataService.entranceFloorPinByHour$, graphDataService.exitFloorPinByHour$, graphDataService.entranceZonePinByHour$, graphDataService.exitZonePinByHour$,
                graphDataService.spEntranceBuildingFloorPinByHour$, graphDataService.spExitBuildingFloorPinByHour$])]).subscribe(
            ([selectedFloorName, selectedInteractable, selectedDirectory, nextLevel, [entranceBuildingByHourPin, exitBuildingByHourPin, entranceFloorPinByHour, exitFloorPinByHour, entranceZonePinByHour, exitZonePinByHour,
                spEntranceFloorPinHour, spExitFloorPinHour]]) => {
                comInstance.isShow = selectedInteractable?.type === 'gate';
                if (excludedDirectory) {
                    comInstance.isShow = selectedDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : nextLevel !== excludedDirectory;
                }
                if (!selectedFloorName || !selectedInteractable || selectedInteractable.type !== 'gate') { return; }
                const zoneName = selectedDirectory.zone;
                const floorName = selectedDirectory.floor;
                const buildingUpdatedName = configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? selectedInteractable.object.userData.building : buildingName;
                let floorUpdatedName = configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? selectedInteractable.object.userData.floor : floorName;
                const zoneUpdatedName = configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? ((selectedInteractable.object.userData.zone as string).split(',')).find(str => str.includes(zoneName)) : zoneName;
                if (zoneUpdatedName === 'pavilion_000_g_001_2nd_reception') {
                    floorUpdatedName = 'pavilion_000_g_001_2nd';
                }
                const floorIndexMainBuilding = Object.keys(configDataService.FLOOR_OBJECTS[configDataService.MAIN_BUILDING]).findIndex(k => k === floorName);
                const floorNameByBuilding = configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f' ? Object.keys(configDataService.FLOOR_OBJECTS[buildingUpdatedName])[floorIndexMainBuilding] : floorUpdatedName;
                const buildingFloorEntranceData = configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f' ? spEntranceFloorPinHour : entranceBuildingByHourPin;
                const buildingFloorExitData = configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f' ? spExitFloorPinHour : exitBuildingByHourPin;
                let entranceData: any[] = [];
                let exitData: any[] = [];
                // TODO: Find setting data and config about pin into model3D instead do this!
                if (contribute_level === 'building') {
                    entranceData = nextLevel === 'FLOOR' 
                    ? getTrafficData({...entranceBuildingByHourPin, ...entranceFloorPinByHour}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f'
                    ? getTrafficData(buildingFloorEntranceData, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : configDataService.isFeatureEnabled('multiple_organization') 
                    ? getTrafficData({...entranceBuildingByHourPin, ...entranceFloorPinByHour}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(entranceZonePinByHour, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                    exitData = nextLevel === 'FLOOR' 
                    ? getTrafficData({...exitBuildingByHourPin, ...exitFloorPinByHour}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f'
                    ? getTrafficData(buildingFloorExitData , buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : configDataService.isFeatureEnabled('multiple_organization') 
                    ? getTrafficData({...exitBuildingByHourPin, ...exitFloorPinByHour}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(exitZonePinByHour, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                } else {
                    entranceData = nextLevel === 'FLOOR' 
                    ? getTrafficData({...entranceBuildingByHourPin, ...entranceFloorPinByHour}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(entranceZonePinByHour, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                    exitData = nextLevel === 'FLOOR' 
                    ? getTrafficData({...exitBuildingByHourPin, ...exitFloorPinByHour}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(exitZonePinByHour, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                }
                if (zoneUpdatedName !== '' && zoneUpdatedName !== null && zoneUpdatedName !== undefined) {
                    if (zoneUpdatedName.includes(configDataService.isFeatureEnabled('heatmap_v2', 'depth_zone'))) {
                        const buildingInstanceName = configDataService.isFeatureEnabled('heatmap_v2', 'depth_zone');
                        const floorInstanceName = zoneUpdatedName;
                        entranceData = getTrafficData(entranceFloorPinByHour, buildingInstanceName, floorInstanceName, selectedInteractable.name);
                        exitData = getTrafficData(exitFloorPinByHour, buildingInstanceName, floorInstanceName, selectedInteractable.name);
                    }
                }
                const dualEntranceData = busiest_time_by_entrance ? shift_first_hour ? entranceData?.reduce(calBiDataSumShiftOne, []) : entranceData?.reduce(calBiDataSum, []) : entranceData?.reduce(calBiDataSumShiftOne, []);
                const dualExitData =busiest_time_by_entrance ? shift_first_hour ? exitData.reduce(calBiDataSumShiftOne, []) : exitData.reduce(calBiDataSum, []) : exitData.reduce(calBiDataSumShiftOne, []);
                dualBarData$.next([{
                    data: dualEntranceData,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                }, {
                    data: dualExitData,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.EXIT,
                }]);
            }));

        comInstance.title = configDataService.DISPLAY_LANGUAGE.ENTRANCE_EXIT_BIHOURS;
        comInstance.isLock = this.isLock;
        // comInstance.infoPopover = async (e: any) => {
        //     const ageDetailPopover = await popoverController.create({
        //         component: CustomerAgeTooltipsComponent,
        //         cssClass: 'calendar-criteria-tooltips',
        //         event: e
        //     });
        //     return ageDetailPopover.present();
        // };
        comInstance.isShowLegend = true;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.xAxesRotation = 90;
        // comInstance.customToolTipFuction = function(tooltipModel) {
        //     showAgeCustomTooltips(tooltipModel, this, configDataService.ageImageSrc);
        // };
        return comInstance;
    }

}
