import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CustomerSegmentDetailsComponent } from '../pages/home/general/customer-segment-details/customer-segment-details.component';
import { showEthnicityCustomTooltips } from '../helpers/ethnicityTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { isScreenSmallerThanMD } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class EthnicityProfileResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        // await configDataService.loadAppConfig();
        const ethnicityList = configDataService.DISPLAY_LANGUAGE.ETHNICITY_CLASS;

        const chartLabel = configDataService.ETHNICITY_CLASS.map(ethnicity => configDataService.DISPLAY_LANGUAGE.ETHNICITY_CLASS[ethnicity]);
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);
        if (isMockData) {
            const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'ETHNICITY_PROFILE', 'count', 5);
            dualBarData$.next([{
                data: mockData.male,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                calPercentFrommAllData: true
            }, {
                data: mockData.female,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                calPercentFrommAllData: true
            }]);
            subscription.add(graphDataService.ethnicityProfileData$.subscribe(ethnicityData => {
                dualBarData$.next([{
                    data: mockData.male,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                    calPercentFrommAllData: true
                }, {
                    data: mockData.female,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                    calPercentFrommAllData: true
                }]);
            }));
        }else{
            dualBarData$.next([{
                data: graphDataService.ethnicityProfileData$.value.female,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                calPercentFrommAllData: true
            },
            {
                data: graphDataService.ethnicityProfileData$.value.male,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                calPercentFrommAllData: true
            }
            ]);
            subscription.add(graphDataService.ethnicityProfileData$.subscribe(ethnicityData => {
                dualBarData$.next([{
                    data: ethnicityData.female,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                    calPercentFrommAllData: true
                }, {
                    data: ethnicityData.male,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                    calPercentFrommAllData: true
                }]);
            }));
        }

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.ETHNICITY_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.infoPopover = async (e: any) => {
            const ethnicityDetailPopover = await popoverController.create({
                component: CustomerSegmentDetailsComponent,
                cssClass: 'customer-segment-details-popover',
                event: e
            });
            return ethnicityDetailPopover.present();
        };
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.4;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showEthnicityCustomTooltips(tooltipModel, this, configDataService.ethnicityImageSrc, ethnicityList);
        };
        return comInstance;
    }

}
