import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { PurchaseBagTooltipsComponent } from '../shared-components/general/purchase-bag-tooltips/purchase-bag-tooltips.component';
import { DynamicDualNumberCardComponent } from '../shared-components/dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class StoreNetShoppingHourNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        // await configDataService.loadAppConfig();
        const buildingNameInput = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;

        const getNetShoppingData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [zoneName: string]: number[] } } | { [floorName: string]: { [zoneName: string]: { [storeName: string]: { netShoppingTime: number; diff: number; diffPercent: number }} } } },
            buildingName?: string,
            floorName?: string,
            zoneName?: string,
            storeName?: string,
        ) => (data?.[buildingName]?.[floorName]?.[zoneName]?.[storeName] || { netShoppingTime: 0, diff: 0, diffPercent: 0, });

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const numberData$ = new BehaviorSubject('0 Hrs');
        const label$ = new BehaviorSubject('0 % (0)');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        const isLock$ = new BehaviorSubject(this.isLock);

        subscription.add(combineLatest([graphDataService.baseGraphData.selectedFloorName$, graphDataService.baseGraphData.selectedInteractable$, graphDataService.currentStoreNetShoppingTimeData$]).subscribe(
            ([selectedFloorName, selectedInteractable, currentNetShoppingTimeStoreData]) => {
                if (!selectedFloorName || !selectedInteractable || selectedInteractable.type !== 'store' || selectedInteractable.store === undefined || !currentNetShoppingTimeStoreData) { return; }
                let floorName = selectedInteractable?.store?.floor_name || '';
                let zoneName = selectedInteractable?.store?.zone_name || '';
                let storeName: string;
                let isTokyu = false;
                let storeOfTokyuData: any; 
                if (configDataService.currentOrganization === 'MBK') {
                    if (selectedInteractable.name === 'S_0_341') {
                        storeOfTokyuData = currentNetShoppingTimeStoreData?.tokyu?.tokyu_000_g?.tokyu_000_g_ga?.tokyu_000_g_ga_s101;
                        floorName = 'tokyu_000_g';
                        zoneName = 'tokyu_000_g_ga';
                        storeName = 'tokyu_000_g_ga_s101';
                        isTokyu = true;
                    }
                    else if (selectedInteractable.name === 'S_1_271') {
                        storeOfTokyuData = currentNetShoppingTimeStoreData?.tokyu?.tokyu_000_g?.tokyu_000_g_ga?.tokyu_000_g_ga_s101;
                        floorName = 'tokyu_001_2nd';
                        zoneName = 'tokyu_001_2nd_2a';
                        storeName = 'tokyu_001_2nd_2a_s201';
                        isTokyu = true;
                    }
                    else if (selectedInteractable.name === 'S_2_590') {
                        floorName = 'tokyu_002_3rd';
                        zoneName = 'tokyu_002_3rd_3a';
                        storeName = 'tokyu_002_3rd_3a_s301';
                        isTokyu = true;
                    } 
                    else if (selectedInteractable.name === 'S_3_606') {
                        floorName = 'tokyu_003_4th';
                        zoneName = 'tokyu_003_4th_4a';
                        storeName = 'tokyu_003_4th_4a_s401';
                        isTokyu = true;
                    }
                }
                const netShoppingTimeData = isTokyu ? getNetShoppingData(currentNetShoppingTimeStoreData, 'tokyu', floorName, zoneName, storeName) : getNetShoppingData(currentNetShoppingTimeStoreData, buildingNameInput, floorName, zoneName, selectedInteractable.store.store_area_name);
                numberData$.next(`${Math.round(netShoppingTimeData.netShoppingTime / (60 * 60)).toLocaleString()} Hrs`);
                label$.next(`${netShoppingTimeData.diffPercent.toFixed(1)} % (${Math.round(netShoppingTimeData.diff / (60 * 60)).toLocaleString()} Hrs)`);
                labelIcon$.next((netShoppingTimeData.diff / (60 * 60)) > 0 ? 'trending-up' : 'trending-down');
                labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: (netShoppingTimeData.diff / (60 * 60)) >= 0 ? '#5BBF93' : '#E25144' });
                isLock$.next(this.state === 'LOCK');
            }
        ));

        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;
        /*comInstance.sizeXS = '12';
        comInstance.sizeMD = '10';
        comInstance.offsetMD = '1';*/

        return comInstance;
    }

}

export class StorePurchaseRateAndAverageTimeSpentNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        // await configDataService.loadAppConfig();
        configDataService.loadExampleImages();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicDualNumberCardComponent;
        const buildingNameInput = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;

        const getAvgTimeSpentData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [zoneName: string]: number[] } } | { [floorName: string]: { [zoneName: string]: { [storeName: string]: { avgTimeSpent: number; diff: number; diffPercent: number } } } } },
            buildingName?: string,
            floorName?: string,
            zoneName?: string,
            storeName?: string,
        ) => (data?.[buildingName]?.[floorName]?.[zoneName]?.[storeName] || { avgTimeSpent: 0, diff: 0, diffPercent: 0, });


        const purchaseRateNumberData$ = new BehaviorSubject('0 %');
        const purchaseRateLabel$ = new BehaviorSubject('0 %');
        const purchaseRateLabelIcon$ = new BehaviorSubject('trending-up');
        const purchaseRateLabelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });

        subscription.add(graphDataService.currentStorePurchaseRateProfileData$.subscribe((purchaseRateData) => {
            purchaseRateNumberData$.next(`${purchaseRateData.purchase.toFixed(1)} %`);
            purchaseRateLabel$.next(`${purchaseRateData.diff.toFixed(1)} %`);
            purchaseRateLabelIcon$.next(purchaseRateData.diff > 0 ? 'trending-up' : 'trending-down');
            purchaseRateLabelStyle$.next({ fontSize: '14px', fontWeight: '500', color: purchaseRateData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const averageTimeSpentNumberData$ = new BehaviorSubject('0 min');
        const averageTimeSpentLabel$ = new BehaviorSubject('0 % (0)');
        const averageTimeSpentLabelIcon$ = new BehaviorSubject('trending-up');
        const averageTimeSpentLabelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });

        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedInteractable$, graphDataService.currentStoreAvgTimeSpentTimeData$]).subscribe(
            ([selectedFloorName, selectedInteractable, currentStoreAvgTimeSpentTimeData]) => {
                if (!selectedFloorName || !selectedInteractable || selectedInteractable.type !== 'store' || selectedInteractable.store === undefined || !currentStoreAvgTimeSpentTimeData) { return; }
                let floorName = selectedInteractable?.store?.floor_name || '';
                let zoneName = selectedInteractable?.store?.zone_name || '';
                let storeName: string;
                let isTokyu = false;
                if (configDataService.currentOrganization === 'MBK') {
                    if (selectedInteractable.name === 'S_0_341') {
                        floorName = 'tokyu_000_g';
                        zoneName = 'tokyu_000_g_ga';
                        storeName = 'tokyu_000_g_ga_s101';
                        isTokyu = true;
                    }
                    else if (selectedInteractable.name === 'S_1_271') {
                        floorName = 'tokyu_001_2nd';
                        zoneName = 'tokyu_001_2nd_2a';
                        storeName = 'tokyu_001_2nd_2a_s201';
                        isTokyu = true;
                    }
                    else if (selectedInteractable.name === 'S_2_590') {
                        floorName = 'tokyu_002_3rd';
                        zoneName = 'tokyu_002_3rd_3a';
                        storeName = 'tokyu_002_3rd_3a_s301';
                        isTokyu = true;
                    } 
                    else if (selectedInteractable.name === 'S_3_606') {
                        floorName = 'tokyu_003_4th';
                        zoneName = 'tokyu_003_4th_4a';
                        storeName = 'tokyu_003_4th_4a_s401';
                        isTokyu = true;
                    }
                }           
                const avgTimeSpentData = isTokyu ? currentStoreAvgTimeSpentTimeData?.tokyu?.[floorName]?.[zoneName]?.[storeName] : getAvgTimeSpentData(currentStoreAvgTimeSpentTimeData, buildingNameInput, floorName, zoneName, selectedInteractable.store.store_area_name);
                averageTimeSpentNumberData$.next(`${Math.round(avgTimeSpentData.avgTimeSpent / 60).toFixed(1)} min`);
                averageTimeSpentLabel$.next(`${avgTimeSpentData.diffPercent.toFixed(1)} % (${(avgTimeSpentData.diff / 60).toFixed(1)} min)`);
                averageTimeSpentLabelIcon$.next((avgTimeSpentData.diff / 60) > 0 ? 'trending-up' : 'trending-down');
                averageTimeSpentLabelStyle$.next({ fontSize: '14px', fontWeight: '500', color: (avgTimeSpentData.diff / 60) >= 0 ? '#5BBF93' : '#E25144' });
            }
        ));

        const storePurchaseRateCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE,
            numberData$: purchaseRateNumberData$,
            label$: purchaseRateLabel$,
            labelIcon$: purchaseRateLabelIcon$,
            labelStyle$: purchaseRateLabelStyle$,
            // sizeXS: '12',
            // sizeMD: '10',
            // offsetMD: '1',
            infoPopover: async (e: any) => {
                const shoppingBagPopover = await popoverController.create({
                    component: PurchaseBagTooltipsComponent,
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await shoppingBagPopover.present();
            }
        };

        const storeAverageTimeSpentCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_NUMBER_CARD,
            numberData$: averageTimeSpentNumberData$,
            label$: averageTimeSpentLabel$,
            labelIcon$: averageTimeSpentLabelIcon$,
            labelStyle$: averageTimeSpentLabelStyle$,
            // sizeXS: '12',
            // sizeMD: '10',
            // offsetMD: '1',
        };

        comInstance.cards = [storePurchaseRateCard, storeAverageTimeSpentCard];
        return comInstance;
    }

}
