import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { PopoverController } from '@ionic/angular';
import { GenericPieChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class StoreGenderProfileResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);        
        const isMockData = (getAdditionalInput(_additionalInput, 'isMockData')) as boolean;
        const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'STORE_VISITOR_GENDER_PROFILE', 'count', 1);
        let pieData$ = new BehaviorSubject<GenericPieChartData[]>([])
        // await configDataService.loadAppConfig();
        if(isMockData){
            const selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
            pieData$.next([{
                data: mockData[selectedInteractableName].male,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
            }, {
                data: mockData[selectedInteractableName].female,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
            }]);
            subscription.add((graphDataService.storeGenderProfileData$,viewPeriodService.dayList,graphDataService.baseGraphData.selectedInteractable$).subscribe(genderData => {
                const selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
                pieData$.next([{
                    data: mockData[selectedInteractableName].male,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
                }, {
                    data: mockData[selectedInteractableName].female,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
                }]);
            }));
        }else{
            pieData$.next([{
                data: graphDataService.storeGenderProfileData$.value?.female || 0,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
            }, {
                data: graphDataService.storeGenderProfileData$.value?.male || 0,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
            }]);
            subscription.add(graphDataService.storeGenderProfileData$.subscribe(genderData => {
                pieData$.next([{
                    data: genderData?.female || 0,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
                }, {
                    data: genderData?.male || 0,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
                }]);
            }));
        }
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.GENDER_PROFILE;
        comInstance.isLock = this.isLock;
        /*comInstance.infoPopover = async (e: any) => {
            const customerGenderPopover = await popoverController.create({
                component: CustomerGenderTooltipsComponent,
                cssClass: 'customer-segment-details-popover',
                event: e
            });
            return customerGenderPopover.present();
        };*/
        comInstance.isShowLegend = true;
        comInstance.data$ = pieData$;
        comInstance.isShowLegendOnPercent = false;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this, 'percentage');
            // showGenderCustomTooltips(tooltipModel, this, configDataService.genderImageSrc); not found image
        };
        return comInstance;
    }

}
