import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeatmapData } from 'src/app/objects/chart';

@Component({
  selector: 'app-dynamic-heatmap-wrapper',
  templateUrl: './dynamic-heatmap-wrapper.component.html',
  styleUrls: ['./dynamic-heatmap-wrapper.component.scss'],
})
export class DynamicHeatmapWrapperComponent implements OnInit {
  @Input() title: string;
  @Input() isLock: boolean | BehaviorSubject<boolean> = false;
  @Input() data$: BehaviorSubject<HeatmapData[]>;

  constructor(
    private ref: ChangeDetectorRef,
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() { }

}
