import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { PopoverController } from '@ionic/angular';
import { CustomerGenderTooltipsComponent } from '../pages/home/general/customer-gender-tooltips/customer-gender-tooltips.component';
import { GenericPieChartData } from '../objects/chart';
import { showGenderCustomTooltips } from '../helpers/genderTooltips';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class GenderProfileResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        // await configDataService.loadAppConfig();
        const pieData$ = new BehaviorSubject<GenericPieChartData[]>([]);
        if (isMockData) {
            let mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'GENDER_PROFILE', 'count', 1);
            pieData$.next([
                {
                    data: mockData.male,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
                },
                {
                    data: mockData.female,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
                }
            ]);
            subscription.add(combineLatest([viewPeriodService.dayList, graphDataService.baseGraphData.selectedInteractable$]).subscribe(async ([dayList, selectedInteractable]) => {
                mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'GENDER_PROFILE', 'count', 1);
                if (selectedInteractable) {
                    const selectedInteractableName = selectedInteractable?.name;
                    pieData$.next([{
                        data: mockData?.[selectedInteractableName]?.male || mockData.male,
                        color: CHART_PRIMARY_COLOR,
                        label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
                    },{
                        data: mockData?.[selectedInteractableName]?.female || mockData.female,
                        color: CHART_SECONDARY_COLOR,
                        label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
                    }]);
                } else {
                    pieData$.next([{
                        data: mockData.male,
                        color: CHART_PRIMARY_COLOR,
                        label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
                    },{
                        data: mockData.female,
                        color: CHART_SECONDARY_COLOR,
                        label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
                    }]);
                }
            }));
        } else {
            pieData$.next([
                {
                    data: graphDataService.genderProfileData$.value.male,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
                },
                {
                    data: graphDataService.genderProfileData$.value.female,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
                }
            ]);
            subscription.add(graphDataService.genderProfileData$.subscribe(genderData => {
                pieData$.next([{
                    data: genderData.male,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male
                },{
                    data: genderData.female,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female
                }]);
            }));
        }

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.GENDER_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.infoPopover = async (e: any) => {
            const customerGenderPopover = await popoverController.create({
                component: CustomerGenderTooltipsComponent,
                cssClass: 'customer-segment-details-popover',
                event: e
            });
            return customerGenderPopover.present();
        };
        comInstance.isShowLegend = true;
        comInstance.data$ = pieData$;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showGenderCustomTooltips(tooltipModel, this, configDataService.genderImageSrc);
        };
        return comInstance;
    }

}
