<ion-row [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }">
  <ion-col appLockIcon [isLock]="isLock" appDataTbd [isEnableTBD]="isEnableTBD" [inputLockText]="inputLockText" [lockOpacity]="lockOpacity" [forceDisplayTBD]="forceDisplayTBD">
    <ion-card>
      <ion-card-header>
        <ion-grid>
            <ion-row class="ion-align-items-center">
                <ion-col>
                    <ion-card-title>
                      {{ title }}
                      <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
                    </ion-card-title>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-header>
      <ion-card-content appLockBlur [isLock]="isLock">
        <app-generic-heatmap-echart 
          [colorSchemeIndicator]="colorSchemeIndicator"
          [heatmapData$]="data$"
          [xAxisLabelDisplayName]="xAxisLabelDisplayName"
          [yAxisLabelDisplayName]="yAxisLabelDisplayName"
          [gridSize]="gridSize"
          [visualMapType]="visualMapType"
        ></app-generic-heatmap-echart>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
