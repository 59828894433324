export class ViewPeriod {
    // public static LIVE = new ViewPeriod(0, 'live');
    public static DAYS = new ViewPeriod(0, 'days', 'days', 'day');
    public static WEEKS = new ViewPeriod(1, 'isoWeeks', 'weeks', 'week');
    public static MONTHS = new ViewPeriod(2, 'months', 'months', 'month');

    public readonly value: number;
    public readonly name: moment.unitOfTime.StartOf;
    public readonly compareName: moment.unitOfTime.Diff;
    public readonly backendName: string;

    public static fromValue(index: number): ViewPeriod {
        if (ViewPeriod.DAYS.value === index) {
            return ViewPeriod.DAYS;
        } else if (ViewPeriod.WEEKS.value === index) {
            return ViewPeriod.WEEKS;
        } else if (ViewPeriod.MONTHS.value === index) {
            return ViewPeriod.MONTHS;
        }
        throw new RangeError(`value ${index} doesn't belong to any class`);
    }

    public static isDay(viewPeriod: ViewPeriod) {
        return viewPeriod === ViewPeriod.DAYS;
    }

    public static isWeek(viewPeriod: ViewPeriod) {
        return viewPeriod === ViewPeriod.WEEKS;
    }

    public static isMonth(viewPeriod: ViewPeriod) {
        return viewPeriod === ViewPeriod.MONTHS;
    }

    private constructor(value: number, name: moment.unitOfTime.StartOf, compareName: moment.unitOfTime.Diff, backendName: string) {
        this.value = value;
        this.name = name;
        this.compareName = compareName;
        this.backendName = backendName;
    }

    public toString(): string {
        return this.value.toFixed(0);
    }

    public toMomentString() {
        return this.name;
    }

    public toMomentCompareString() {
        return this.compareName;
    }

    public toBackendV2String() {
        return this.backendName;
    }
}

export interface SelectedDatePeriod { dateDAYS: moment.Moment; dateWEEKS: moment.Moment; dateMONTHS: moment.Moment; periodType: ViewPeriod }

export const getMomentDate = (selected: SelectedDatePeriod) => {
    if (ViewPeriod.isDay(selected.periodType)) {
      return selected.dateDAYS.clone();
    } else if (ViewPeriod.isWeek(selected.periodType)) {
      return selected.dateWEEKS.clone();
    } else if (ViewPeriod.isMonth(selected.periodType)) {
      return selected.dateMONTHS.clone();
    }
    throw RangeError(`${selected.periodType} is not implemented`);
};
