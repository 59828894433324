import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { DynamicDualNumberCardComponent } from '../shared-components/dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { CustomerMaskTooltipsComponent } from '../pages/home/general/customer-mask-tooltips/customer-mask-tooltips.component';
import { CustomerMessengerTooltipsComponent } from '../pages/home/general/customer-messenger-tooltips/customer-messenger-tooltips.component';

export class MaskCountBHPRNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        await configDataService.loadAppConfig();
        configDataService.loadExampleImages();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicDualNumberCardComponent;

        const netVisitorHourNumberData$ = new BehaviorSubject('0 hours');
        const netVisitorHourLabel$ = new BehaviorSubject('0 % (0)');
        const netVisitorHourLabelIcon$ = new BehaviorSubject('trending-up');
        const netVisitorHourLabelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        const netVisitorHourisLock$ = new BehaviorSubject(this.isLock);

        subscription.add(graphDataService.currentNetVisitorHourData$.subscribe(
            (currentNetVistiorTimeData) => {
                netVisitorHourNumberData$.next(`${(currentNetVistiorTimeData.netVisitorTime).toLocaleString()}`);
                netVisitorHourLabel$.next(`${currentNetVistiorTimeData.diffPercent.toFixed(1)} % (${(currentNetVistiorTimeData.diff).toLocaleString()})`);
                netVisitorHourLabelIcon$.next((currentNetVistiorTimeData.diff) > 0 ? 'trending-up' : 'trending-down');
                netVisitorHourLabelStyle$.next({ fontSize: '16px', fontWeight: '500', color: (currentNetVistiorTimeData.diff >= 0 ? '#5BBF93' : '#E25144') });
            }
        ));

        const maskCountNumberData$ = new BehaviorSubject('0 %');
        const maskCountLabel$ = new BehaviorSubject('0 %');
        const maskCountLabelIcon$ = new BehaviorSubject('trending-up');
        const maskCountLabelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        subscription.add(graphDataService.currentMaskCount$.subscribe((maskCountData) => {
            maskCountNumberData$.next(`${maskCountData.maskCount?.toFixed(1)} %`);
            maskCountLabel$.next(`${maskCountData.diff?.toFixed(1)} %`);
            maskCountLabelIcon$.next(maskCountData.diff > 0 ? 'trending-up' : 'trending-down');
            maskCountLabelStyle$.next({ fontSize: '16px', fontWeight: '500', color: maskCountData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const currentMessengerNumberData$ = new BehaviorSubject('0');
        const currentMessengerLabel$ = new BehaviorSubject('0 % (0)');
        const currentMessengerLabelIcon$ = new BehaviorSubject('trending-up');
        const currentMessengerLabelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });

        subscription.add(graphDataService.currentTotalMessengerData$.subscribe(currentMessengerData => {
            if (!currentMessengerData) {
                return;
            }
            currentMessengerNumberData$.next(currentMessengerData.messenger.toLocaleString());
            currentMessengerLabel$.next(`${currentMessengerData.diffPercent?.toFixed(1)} % (${currentMessengerData.diff?.toLocaleString()})`);
            currentMessengerLabelIcon$.next(currentMessengerData.diff > 0 ? 'trending-up' : 'trending-down');
            currentMessengerLabelStyle$.next({ fontSize: '16px', fontWeight: '500', color: currentMessengerData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const netVisitorHourCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.NET_VISITOR_HOUR,
            numberData$: netVisitorHourNumberData$,
            label$: netVisitorHourLabel$,
            labelIcon$: netVisitorHourLabelIcon$,
            labelStyle$: netVisitorHourLabelStyle$,
            infoPopover: async (e: any) => {
                const netVisitorHourPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: 'Net Visitor Hours',
                        toolTipDetails: ['Based on total amount of hours spent by visitors.', 'Calculated by "Total Headcount" * "Average Duration of Visit (mins)"'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await netVisitorHourPopover.present();
            },
            sizeXS: '12',
            isFirst: true,
        };

        const maskCountCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.MASK_COUNT,
            numberData$: maskCountNumberData$,
            label$: maskCountLabel$,
            labelIcon$: maskCountLabelIcon$,
            labelStyle$: maskCountLabelStyle$,
            infoPopover: async (e: any) => {
                const maskCountPopover = await popoverController.create({
                    component: CustomerMaskTooltipsComponent,
                    componentProps: {
                        toolTipTitle: 'Mask Use',
                        toolTipDetails: ['Based on % of entering visitors with mask on.', '±2% error rate.']
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await maskCountPopover.present();
            },
            sizeXS: '6',
            isFirst: true,
        };

        const currentMessengerCard = {
            isLock: this.isLock,
            title: 'Courier Traffic',
            iconStyle: 'fontSize: 22px',
            numberData$: currentMessengerNumberData$,
            label$: currentMessengerLabel$,
            labelIcon$: currentMessengerLabelIcon$,
            labelStyle$: currentMessengerLabelStyle$,
            infoPopover: async (e: any) => {
                const currentMessengerPopover = await popoverController.create({
                    component: CustomerMessengerTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.MESSENGER_COUNT,
                        toolTipDetails: ['Total traffic of couriers/delivery employees in a given period, based on exit demographics.', 'Please see photos for reference.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await currentMessengerPopover.present();
            },
            sizeXS: '6',
        };

        comInstance.cards = [netVisitorHourCard, maskCountCard, currentMessengerCard, ];
        return comInstance;
    }

}
