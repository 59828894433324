import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarDateFormatter,
  CalendarMonthViewDay,
} from 'angular-calendar';
import { CustomDateFormatter } from '../../../../helpers/calendar-date-formatter-provider';
import { ViewPeriodService } from 'src/app/services/view-period.service';

import { isSameDay, isSameMonth } from 'date-fns';
import * as moment from 'moment';
import { EventService } from 'src/app/services/event.service';
import { NoteService } from 'src/app/services/note.service';
import { Note } from 'src/app/objects/note';
import { Event } from 'src/app/objects/event';
import { PopoverController } from '@ionic/angular';
import { CalendarLegendTableComponent } from '../calendar-legend-table/calendar-legend-table.component';
import { Subject, BehaviorSubject, Subscription, combineLatest, Observable } from 'rxjs';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { GraphDataService } from 'src/app/services/graph-data-service.service';
import { GraphDependency } from 'src/app/enum/graph-dependency.enum';
import { GraphDataConfig } from 'src/app/objects/config';
import { isScreenSmallerThanMD } from 'src/app/helpers/util';
import { generateNestedData } from 'src/app/helpers/mock-data-generator';

const colors = {
  red: {
    primary: '#f04141',
    secondary: '#f04141',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#ffce00',
    secondary: '#ffce00',
  },
};

@Component({
  selector: 'app-calendar-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isLock: BehaviorSubject<boolean> | boolean;
  @Input() selectedCalendarView$: BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>;
  @Input() selector: { [selectorName: string]: GraphDataConfig } = {};
  @Input() overrideShouldShowSelector: boolean;
  @Input() selectInterface: 'action-sheet' | 'popover' | 'alert' = 'popover';
  @Input() multipleSelector = true;
  @Input() isMockData: boolean;
  private calendarCriteriaTooltips: any;

  view = 'month';
  viewDate: Date;
  calendarEvents: CalendarEvent[] = [];
  calendarData: { [day: string]: number } = {};
  weatherMonthData: { [day: string]: number } = {};

  activeDayIsOpen = false;

  refresh = new Subject<any>();
  subscription = new Subscription();

  selectedName: string[] = [];
  selectorName: string[] = [];
  selectorDataArgsName: string[] = [];
  shouldShowSelector = false;
  // Initial grid template columns
  gridTemplateColumns = 'auto 1fr';

  constructor(
    private viewPeriodService: ViewPeriodService,
    private eventService: EventService,
    private noteService: NoteService,
    private popoverController: PopoverController,
    public configDataService: ConfigDataService,
    private graphDataService: GraphDataService,
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {
    if (this.configDataService.isFeatureEnabled('month_performance', 'vehicle_data')) {
      this.graphDataService.baseGraphData.addDependency(GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_MONTHLY);
    } else if (!this.isMockData) {
      this.graphDataService.baseGraphData.addDependency(GraphDependency.ENTRANCE_EXIT_MONTH);
      this.graphDataService.baseGraphData.addDependency(GraphDependency.WEATHER_MONTH);  
    }
    this.subscription.add(this.viewPeriodService.subscribeSelectedDate((selectedDate) => {
      this.viewDate = selectedDate.date.clone().toDate();
    }));
    if (this.isMockData) {
      this.graphDataService.baseGraphData.addDependency(GraphDependency.WEATHER_MONTH);
      this.subscription.add(combineLatest([this.viewPeriodService.dayList, this.graphDataService.weatherMonthData$]).subscribe(async ([dayList, weatherMonthData]) => {
        const mockData = await generateNestedData(this.viewPeriodService.selectedDate, this.viewPeriodService, this.configDataService, 'MONTH_PERFORMANCE', 'count', 1, false, true);
        this.weatherMonthData = weatherMonthData;
        const todayMoment = moment();
        const endOfMonthMoment = moment(this.viewPeriodService.selectedDate).endOf('month');
        if (this.viewPeriodService.selectedDate.isSame(todayMoment, 'month')) {
          const mockDataKeys = Object.keys(mockData);
          const difference = this.viewPeriodService.isLiveMode ? endOfMonthMoment.diff(todayMoment, 'day') + 1 : endOfMonthMoment.diff(todayMoment, 'day') + 2;
          for (let i = 0; i < difference; i++) {
            delete mockData[mockDataKeys[mockDataKeys.length - 1 - i]];
          }
        }
        else if (Object.keys(mockData).length !== Object.keys(weatherMonthData).length) {
          // decrease Object of mockData to match weatherMonthData length
          const mockDataKeys = Object.keys(mockData);
          const weatherMonthDataKeys = Object.keys(weatherMonthData);
          const difference = mockDataKeys.length - weatherMonthDataKeys.length;
          for (let i = 0; i < difference; i++) {
            delete mockData[mockDataKeys[mockDataKeys.length - 1 - i]];
          }
        }
        this.calendarData = mockData;
        this.refresh.next(true);
        this.update();

      }));
    } else {
      const dataBehaviourSubject$: Observable<{ [day: string]: number }>[] = this.configDataService.isFeatureEnabled('month_performance', 'vehicle_data') 
      ? [this.graphDataService.vehicleParkingEntranceMonthData$, this.graphDataService.vehicleParkingExitMonthData$] 
      : [this.graphDataService.entranceMonthData$, this.graphDataService.exitMonthData$];
      this.subscription.add(combineLatest([this.selectedCalendarView$, this.eventService.EventList$, this.noteService.NoteList$, this.configDataService.isEntranceDataMode$,
      this.graphDataService.weatherMonthData$, this.graphDataService.buildingEntranceMonthData$, this.graphDataService.buildingExitMonthData$, ...dataBehaviourSubject$]).subscribe(
        ([selectedCalendarView, eventList, noteList, isEntranceDataMode, weatherMonthData, buildingEntranceMonthData, buildingExitMonthData, entranceMonthData, exitMonthData]) => {
          this.weatherMonthData = weatherMonthData;
          this.filterCalendarEvent(eventList, noteList);
          this.selectorDataArgsName = Object.entries(selectedCalendarView).filter(([_, selectorDetail]) => selectorDetail.selected).map(([name, obj]) => obj.args)[0];
          if (!entranceMonthData || !exitMonthData) {
            return;
          }
          if (Object.keys(this.selector).length > 0 && this.selectorDataArgsName.length > 0) {
            this.calendarData = isEntranceDataMode ? buildingEntranceMonthData[this.selectorDataArgsName[0]] : buildingExitMonthData[this.selectorDataArgsName[0]];
          } else {
            this.calendarData = isEntranceDataMode ? entranceMonthData : exitMonthData;
          }
          this.refresh.next(true);
          this.update();
        }
      ));
    }

  }

  private update() {
    this.selectorName = Object.keys(this.selector);
    if (this.overrideShouldShowSelector !== undefined) {
      this.shouldShowSelector = this.overrideShouldShowSelector;
    } else {
      this.shouldShowSelector = this.selectorName && this.selectorName.length > 1;
    }
    this.selectedName = Object.entries(this.selector).filter(([_, selectorDetail]) => selectorDetail.selected).map(([name, _]) => name);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.update();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeSelectedGraph(event: any) {
    const selectedName = event.detail.value as string[];
    this.selectedName = selectedName;
    // TODO: instead of next one value we need to add more here
    const currentSelectedBar = this.selectedCalendarView$.value;
    Object.keys(currentSelectedBar).forEach(selectorName => {
      currentSelectedBar[selectorName].selected = selectedName.includes(selectorName);
    });
    this.selectedCalendarView$.next(currentSelectedBar);
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach((cell: any) => {
      const groups: any = {};
      cell.events.forEach((event: CalendarEvent<{ type: string }>) => {
        groups[event.meta.type] = groups[event.meta.type] || [];
        groups[event.meta.type].push(event);
      });
      cell.eventGroups = Object.entries(groups);
      const calendar_date_moment = moment(cell.date);
      const calendar_month = calendar_date_moment.month() + 1;
      const current_month = moment(this.viewPeriodService.selectedDate).month() + 1;
      if (calendar_month !== current_month) { return; }
      cell.cssClass = this.getCellStyleByHeadcount(this.getHeadcountFromDate(cell.date), cell.date);
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  shouldShowIcon(date) {
    const calendar_date_moment = moment(date);
    const calendar_month = calendar_date_moment.month() + 1;
    const current_month = moment(this.viewPeriodService.selectedDate).month() + 1;
    return calendar_month === current_month;
  }

  getHeadcountFromDate(date) {
    const calendar_date_moment = moment(date);
    const calendar_day = calendar_date_moment.date();
    const calendar_month = calendar_date_moment.month() + 1;
    const current_month = moment(this.viewPeriodService.selectedDate).month() + 1;
    if (this.calendarData !== undefined) {
      return (calendar_month !== current_month || this.calendarData[calendar_day] === 0) ? null : this.calendarData[calendar_day];
    }
  }

  getIconFromDate(date) {
    const current_day = moment().clone();
    const calendar_date_moment = moment(date).clone();
    const calendar_day = calendar_date_moment.date();
    if (!this.weatherMonthData || !this.weatherMonthData[calendar_day]) {
      return;
    } else {
      try {
        const weatherCode = this.weatherMonthData[calendar_day];
        // check if day/week/month mode will not display weather in today (only display in live mode)
        if (!this.viewPeriodService.isLiveMode && current_day.isSame(calendar_date_moment, 'month') && current_day.isSame(calendar_date_moment, 'day')) {
          return;
        }
        return `assets/image/weather/64x64/day/${weatherCode}.png`;
      } catch (err) {
        return `assets/image/weather/64x64/day/116.png`;
      }
    }
  }

  getCellStyleByHeadcount(headcount, date) {
    const HEADCOUNT_CRITERION = this.overrideShouldShowSelector ? this.configDataService.TARGET_CONFIG[this.selectorDataArgsName[0]] : this.configDataService.HEADCOUNT_CRITERION;
    if (!HEADCOUNT_CRITERION || !headcount) {
      return 'criteria-white-cell';
    }
    const month = 1 + moment(date).month();
    const numQuarter = `Q${Math.trunc(Math.ceil(month / 3))}`;

    // TODO: why mbk exit is white
    if (this.configDataService.currentOrganization === 'MBK' && !this.configDataService.isEntranceDataMode) {
      return 'criteria-white-cell';
    }

    if (headcount >= HEADCOUNT_CRITERION[numQuarter][0]) {
      return 'criteria-five-cell';
    } else if (headcount >= HEADCOUNT_CRITERION[numQuarter][1]) {
      return 'criteria-four-cell';
    } else if (headcount >= HEADCOUNT_CRITERION[numQuarter][2]) {
      return 'criteria-three-cell';
    } else if (headcount >= HEADCOUNT_CRITERION[numQuarter][3]) {
      return 'criteria-two-cell';
    } else {
      return 'criteria-one-cell';
    }
  }

  filterCalendarEvent(eventList: Event[], noteList: Note[]) {
    const calendarEventList: CalendarEvent[] = [];

    eventList.forEach((event) => {
      const calendarEvent = {
        start: new Date(event.start_date),
        end: new Date(event.end_date),
        title: 'Event: ' + event.name,
        color: colors.yellow,
        meta: {
          type: 'event',
        },
      };
      calendarEventList.push(calendarEvent);
    });

    noteList.forEach((note) => {
      const calendarEvent = {
        start: new Date(note.date),
        title: 'Note: ' + note.note_text,
        color: colors.blue,
        meta: {
          type: 'note',
        },
      };
      calendarEventList.push(calendarEvent);
    });

    this.calendarEvents = calendarEventList;
  }

  async presentCalendarCriteriaTooltips(e: any) {
    this.calendarCriteriaTooltips = await this.popoverController.create({
      component: CalendarLegendTableComponent,
      componentProps: {
        useSelector: this.shouldShowSelector,
        selectorDataArgsName: this.selectorDataArgsName, 
      },
      cssClass: 'calendar-criteria-tooltips',
      event: e,
      
    });
    return await this.calendarCriteriaTooltips.present();
  }

  updateGridLayout(isSelectorVisible: boolean) {
    if (!isSelectorVisible) {
      this.gridTemplateColumns = '1fr';
    } else {
      this.gridTemplateColumns = 'auto 1fr';
    }
    return this.gridTemplateColumns;
  }
  
  get displayIconOnMiddle() {
    return isScreenSmallerThanMD(window);
  }
}
