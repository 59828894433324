<ion-grid>
  <ion-row>
    <ion-col size-xs="12" style="margin-bottom: 10px;">
      <span style="color: white; font-weight: 700; font-size: 18px;">Gender Image Examples</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-no-padding ion-align-items-center" *ngFor="let gender of GENDER_LIST">
    <ion-col class="ion-no-padding ion-text-center" style="color: white; font-weight: 600;" size-xs="3">
      {{ gender }}
    </ion-col>
    <ion-col size-xs="3">
      <img [src]="getImageSrc(gender, 1)"/>
    </ion-col>
    <ion-col size-xs="3">
      <img [src]="getImageSrc(gender, 2)"/>
    </ion-col>
    <ion-col size-xs="3">
      <img [src]="getImageSrc(gender, 3)"/>
    </ion-col>
  </ion-row>
</ion-grid>