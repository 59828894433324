<ion-row [ngStyle]="getIsShow()">
  <ion-col>
    <ion-card appLockIcon [isLock]="isLock" class="ion-padding">
      <ion-card-header *ngIf="isShowSelector; else normalChartHeader" style="padding: 8px;">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col size="8">
              <ion-card-title>
                {{ title }}
                <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
              </ion-card-title>
            </ion-col>
            <ion-col size="4">
              <ion-select
              [value]="getDefaultValue()"
              style="font-size: 16px; color: var(--ion-color-primary)"
              [interface]="selectInterface"
              (ionChange)="changeSelectedGraph($event)"
              multiple="true"
              class="ion-align-items-baseline"
              >
                <ion-select-option *ngFor="let lineName of selectorName; index as i" [value]="lineName">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] || lineName }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-header>
      <ion-grid>
        <ion-row>
          <ion-col appLockBlur [isLock]="isLock">
            <app-generic-stack-bar-chart
              [data$]="data$"
              [chartLabel$]="chartLabel$"
              [isShowLegend]="isShowLegend"
              [isHorizontal]="isHorizontal"
              [chartOptions$]="chartOptions$"
            ></app-generic-stack-bar-chart>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </ion-col>
</ion-row>

<ng-template #normalChartHeader>
  <ion-card-header>
    <ion-card-title>
      {{ title }}
      <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
    </ion-card-title>
  </ion-card-header>
</ng-template>