import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class DurationOfVisitNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        // await configDataService.loadAppConfig();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const numberData$ = new BehaviorSubject('0 mins');
        const label$ = new BehaviorSubject('0 % (0 mins)');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });

        subscription.add(graphDataService.currentAverageTimeSpentData$.subscribe(avgTimeSpentData => {
            numberData$.next(`${Math.round(avgTimeSpentData.avgTimeSpent / 60)} mins`);
            label$.next(`${avgTimeSpentData.diffPercent.toFixed(1)} % (${Math.round(avgTimeSpentData.diff / 60)} mins)`);
            labelIcon$.next(avgTimeSpentData.diff > 0 ? 'trending-up' : 'trending-down');
            labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: avgTimeSpentData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_NUMBER_CARD;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;

        return comInstance;
    }

}
