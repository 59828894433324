import { AfterViewInit, Component, ElementRef, Input, OnInit , ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-dynamic-divider-selector',
  templateUrl: './dynamic-divider-selector.component.html',
  styleUrls: ['./dynamic-divider-selector.component.scss'],
})
export class DynamicDividerSelectorComponent implements OnInit, AfterViewInit {

  @Input() isShow = true;
  @Input() color = '#212121';
  @Input() backgroundColor = '#2e2e2e';
  @Input() textColor = 'white';
  @Input() sticky = false;
  @Input() fontSize = '18px';
  @Input() fontWeight = 600;
  @Input() displayNameLookupKey = 'DEFAULT';
  @Input() selectorConfig: [string, string[]][] = [];
  // BEWARE, this must be init with empty object {} only
  @Input() selectedSelector$: BehaviorSubject<{ [selectorName: string]: string }> = new BehaviorSubject({});

  open_state_name = 'CLOSE';

  constructor(
    private elementRef: ElementRef,
    private configDataService: ConfigDataService,
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    const elem = this.elementRef.nativeElement as HTMLDivElement;
    elem.style.setProperty('display', 'flex');
    elem.style.setProperty('flex-flow', 'column');
  }

  handleToggleState(openName: string) {
    if (this.open_state_name === openName) {
      this.open_state_name = 'CLOSE';
    } else {
      this.open_state_name = openName;
    }
  }

  handleSelectorClicked(selectorName: string, selector: string) {
    const selectedSelector = { ...this.selectedSelector$.value };
    if (selector === selectedSelector[selectorName]) {
      selectedSelector[selectorName] = undefined;
    } else {
      selectedSelector[selectorName] = selector;
    }
    this.selectedSelector$.next(selectedSelector);
  }

  getDisplayName(keyName: string): string {
    if (keyName === undefined) {
      return undefined;
    }
    return this.configDataService.DISPLAY_LANGUAGE?.[this.displayNameLookupKey]?.[keyName] || keyName;
  }

  get selectorsName() {
    return this.selectorConfig.keys();
  }

}
