<ion-grid>
  <ion-row>
    <ion-col size-xs="12">
      <span style="color: var(--ion-color-primary); font-weight: 700; font-size: 18px;">{{ parseTitle }}</span>
    </ion-col>
  </ion-row>
  <ion-row class="ion-text-left">
    <ion-col size="12" class="responsive-font-size ion-align-self-center">
      <div *ngIf="showDurationText">
        <p>{{ durationText }}</p>
      </div>
      <div *ngFor="let toolTipDetail of toolTipDetails">
        <p>{{ toolTipDetail }}</p> 
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="imageAltList.length !== 0">
    <div class="image-gallery-container">
      <div *ngFor="let imageAlt of imageAltList">
        <img class="landscape-image" (load)="loadingImage()" [class.loading]="!loadedImage" [src]="imageAlt.src" alt="image">
        <div>{{ parseDisplayName(imageAlt.title) }}</div>
      </div>
    </div>
  </ion-row>
</ion-grid>
