import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigDataService } from 'src/app/services/config-data.service';
import * as moment from 'moment';
import { ViewPeriodService } from 'src/app/services/view-period.service';

@Component({
  selector: 'app-custom-text-tooltips',
  templateUrl: './custom-text-tooltips.component.html',
  styleUrls: ['./custom-text-tooltips.component.scss'],
})
export class CustomTextTooltipsComponent implements OnInit, AfterViewInit {
  toolTipTitle: string | BehaviorSubject<string>;
  toolTipDetails: string[];
  imageGateSourceList: { [floorName: string]: { [gateName: string]: { entrance: string; exit: string } } };
  imageAltList: Array<{ title: string; src: string }> = [];
  loadedImage: boolean;
  showDurationText: boolean;
  durationText: string;
  @Input() customTooltipText: boolean;

  constructor(
    private configDataService: ConfigDataService,
    private ref: ChangeDetectorRef,
    private viewPeriodService: ViewPeriodService,
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {
    this.viewPeriodService.subscribeSelectedDate(dateSubject => {
      const startEndDateOFWeek = this.getWeekdayStartAndEndDate(dateSubject.date);
      const endDateOFWeek = dateSubject.date.format('dddd DD MMMM');
      const startDateOfWeek = dateSubject.date.subtract(6, 'd').format('dddd DD MMMM');
      const compareSevenDay = dateSubject.date.subtract(1, 'd').format('dddd DD MMMM');
      if(this.customTooltipText){
        if (this.viewPeriodService.isLiveMode) {
          this.durationText = `The graph shows hourly traffic comparison between ${endDateOFWeek} and ${compareSevenDay}`;
        } else if (this.viewPeriodService.isDayPeriod) {
          this.durationText = `The graph shows hourly traffic comparison between ${endDateOFWeek} and ${compareSevenDay}`;
        } else if (this.viewPeriodService.isMonthPeriod) {
          this.durationText = `Average hourly traffic from each day (eg. Monday, tuesday, etc.) starting from 1st of the month till the present`;
        } else {
          this.durationText = `Average hourly traffic from each day. This graph start on ${startEndDateOFWeek.startDate} - ${startEndDateOFWeek.endDate}, with the line continuing up to the present day. Please wait for the full period to complete before interpreting the data`;
        };
      }else{
        if (this.viewPeriodService.isLiveMode) {
          this.durationText = `Traffic by hour during last 7 days from ${startDateOfWeek} - ${endDateOFWeek} (unit: Traffic/hour)`;
        } else if (this.viewPeriodService.isDayPeriod) {
          this.durationText = `Traffic by hour during last 7 days from ${startDateOfWeek} - ${endDateOFWeek} (unit: Traffic/hour)` ;
        } else if (this.viewPeriodService.isMonthPeriod) {
          this.durationText = 'Average hourly traffic from each day (eg. Monday, tuesday, etc.) starting from 1st of the month till the present';
        } else {
          this.durationText = `Average hourly traffic from each day. This graph start on ${startEndDateOFWeek.startDate} - ${startEndDateOFWeek.endDate}, with the line continuing up to the present day. Please wait for the full period to complete before interpreting the data`;
        };
      }
    });
  }

  ngAfterViewInit() {
    if (this.imageGateSourceList) {
      for (const [floorName, floorData] of Object.entries(this.imageGateSourceList)) {
        Object.keys(floorData).forEach(gateName => {
          const imageAlt: { title: string; src: string } = { title: gateName, src: floorData[gateName].entrance };
          this.imageAltList.push(imageAlt);
        });
      }
    }
  }

  loadingImage() {
    const image = document.querySelector('img');
    const isLoaded = image.complete && image.naturalHeight !== 0;
    this.loadedImage = isLoaded;
  }

  parseDisplayName(imgName: string) {
    return this.configDataService.DISPLAY_LANGUAGE.BUILDING_NAME[imgName] || this.configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[imgName] || this.configDataService.DISPLAY_LANGUAGE.ZONE_NAME[imgName] || this.configDataService.DISPLAY_LANGUAGE.GATE_NAME[imgName] || imgName;
  }

  getWeekdayStartAndEndDate(inputDate: moment.Moment): { startDate: string; endDate: string } {
    const momentDate = inputDate;
    const weekStart = momentDate.clone().startOf('isoWeek');
    const weekEnd = momentDate.clone().endOf('isoWeek');

    return {
      startDate: weekStart.format('dddd DD MMMM'),
      endDate: weekEnd.format('dddd DD MMMM'),
    };
  }

  get parseTitle() {
    let titleText: string;
    if (this.toolTipTitle instanceof BehaviorSubject) {
      this.toolTipTitle.subscribe(text => {
        titleText = text;
      });
    } else {
      titleText = this.toolTipTitle;
    }
    return this.showDurationText || (this.toolTipDetails.length > 0) ? `${titleText} Definition` : titleText;
  }

}
