import { Component, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-customer-mask-tooltips',
  templateUrl: './customer-mask-tooltips.component.html',
  styleUrls: ['./customer-mask-tooltips.component.scss']
})
export class CustomerMaskTooltipsComponent implements OnInit {
  toolTipTitle: string;
  toolTipDetails: string[];

  MASK_LIST: Array<string> = [
    'Face Mask',
    'No Mask'
  ];

  constructor(
    public configDataService: ConfigDataService
  ) { }

  ngOnInit() {
  }

  getImageSrc(segment, index) {
    return this.configDataService.maskImageSrc[segment][index];
  }

}
