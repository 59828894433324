import { Component, OnInit, Input, ElementRef, AfterViewInit } from '@angular/core';
import { DynamicGraphAdditionalInput, DynamicName } from 'src/app/objects/config';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { GraphDataService } from 'src/app/services/graph-data-service.service';

@Component({
  selector: 'app-dynamic-graph-group-dropdown',
  templateUrl: './dynamic-graph-group-dropdown.component.html',
  styleUrls: ['./dynamic-graph-group-dropdown.component.scss'],
})
export class DynamicGraphGroupDropdownComponent implements OnInit, AfterViewInit {
  public readonly type = 'DROP_DOWN';
  @Input() name: string;
  @Input() children: string[];
  @Input() color = '#4a6fdd';
  @Input() textColor = 'white';
  @Input() sticky = false;
  @Input() fontSize = '18px';
  @Input() fontWeight = 600;
  @Input() defaultState: 'OPEN' | 'CLOSE';
  @Input() groupInput: string | DynamicGraphAdditionalInput | undefined;
  @Input() dynamicName: DynamicName | undefined;

  state: 'OPEN' | 'CLOSE' = 'OPEN';
  isShow = true;

  constructor(
    private elementRef: ElementRef,
    private graphDataService: GraphDataService,
    private configDataService: ConfigDataService,
  ) { }

  ngOnInit() {
    if (this.defaultState) {
      this.state = this.defaultState;
    }
  }

  handleToggleState() {
    if (this.dynamicName === 'DYNAMIC_FLOOR' && this.name === 'mbk') {
      this.isShow = false;
      return;
    }
    this.state = this.state === 'OPEN' ? 'CLOSE' : 'OPEN';
  }

  ngAfterViewInit(): void {
    const elem = this.elementRef.nativeElement as HTMLDivElement;
    elem.style.setProperty('display', 'flex');
    elem.style.setProperty('flex-flow', 'column');
    if (this.dynamicName === 'DYNAMIC_FLOOR' && this.name === 'mbk') {
      this.isShow = false;
    }
  }

  get title() {
    let dynamicKey: string;
    if (this.dynamicName === 'DYNAMIC_FLOOR') {
      dynamicKey = this.graphDataService.baseGraphData.selectedLevel$.value === 'ZONE' ? this.graphDataService.baseGraphData.selectedDirectory$.value?.zone : this.graphDataService.baseGraphData.selectedDirectory$.value?.floor || this.graphDataService.selectedFloorName$.value || 'ALL';
    }
    const dynamicName = dynamicKey ? this.graphDataService.baseGraphData.selectedLevel$.value === 'ZONE' 
    ? this.configDataService.DISPLAY_LANGUAGE.DYNAMIC_GRAPH_GROUP_NAME[dynamicKey] 
    : dynamicKey === 'ALL' ? this.configDataService.DISPLAY_LANGUAGE.DYNAMIC_GRAPH_GROUP_NAME[this.name] 
    : this.configDataService.DISPLAY_LANGUAGE.DYNAMIC_GRAPH_GROUP_DYNAMIC_NAME[this.name][dynamicKey]
    : this.configDataService.DISPLAY_LANGUAGE.DYNAMIC_GRAPH_GROUP_DYNAMIC_NAME[this.name] || this.name;
    return this.dynamicName === 'DYNAMIC_FLOOR' ?  dynamicName : this.configDataService.DISPLAY_LANGUAGE.DYNAMIC_GRAPH_GROUP_NAME[this.name] || this.name;
  }

}
