import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ChartColor, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject, combineLatest, filter, Subscription } from 'rxjs';
import { BOTH_THEME_BASE_COLOR, CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR, PIE_CHART_COLOR_LIST } from '../configs/color';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined, getDepthData, isScreenSmallerThanLG, isScreenSmallerThanMD, isShowGraph } from '../helpers/util';
import { DynamicGraphAdditionalInput, DynamicGraphConfig } from '../objects/config';
import { SelectableCustomDataName } from '../objects/selectableData';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicCustomBarChartComponent } from '../shared-components/dynamic/dynamic-custom-bar-chart/dynamic-custom-bar-chart.component';
import { GraphResolverBase } from './GraphResolverBase';
import { showGenericBarChartCustomTooltips } from '../helpers/barChartTooltips';
import { showMessengerBrandCustomTooltips } from '../helpers/messengerBrandTooltips';
import { showShoppingBagColorCustomTooltip } from '../helpers/shoppingBagColorTooltip';
import { AuthenticationService } from '../services/authentication.service';
import { showAgeCustomTooltips } from '../helpers/ageTooltips';
import { CustomerAgeTooltipsComponent } from '../pages/home/general/customer-age-tooltips/customer-age-tooltips.component';
import { showBrandCustomTooltips } from '../helpers/brandTooltips';
import { generateNestedData } from '../helpers/mock-data-generator';
import { InteractableType } from '../objects/trafficInteractable';

export class CustomBarChartResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService,
        graphDataService: GraphDataService, 
        popoverController: PopoverController, 
        viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
        authenicationService: AuthenticationService,
    )
    {
        const barChartConfig = (getAdditionalInput(additionalInput, 'dynamicGraphConfig') || {}) as DynamicGraphConfig;
        const barChartLockDate = (getAdditionalInput(additionalInput, 'useZoneDateLock') || false) as boolean;
        const useselectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory') || false) as boolean;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const buildingInstanceName = (getAdditionalInput(additionalInput, 'building')) as string;
        const floorInstanceName = (getAdditionalInput(additionalInput, 'floor')) as string;
        const zoneInstanceName = (getAdditionalInput(additionalInput, 'zone')) as string;
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        let multipleSelected = (getAdditionalInput(additionalInput, 'multipleSelected')) as boolean;
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;

        assertNullUndefined(barChartConfig);
        const chartTitleKey = barChartConfig.graphName;
        const chartState = (barChartConfig.graphState || 'ENABLE');
        const chartDataConfig = barChartConfig.graphData; 
        const chartOptionsConfig = barChartConfig.graphOptions;
        const chartSelectOptions = barChartConfig?.graphSelectOptions || [];
        const areaInstanceName: string[] = [buildingInstanceName, floorInstanceName, zoneInstanceName];
        const dataConfigFromInstance: string[] = areaInstanceName.filter(d => d !== undefined);

        const isClassData = chartOptionsConfig?.isClassData === true;
        const isShowPercent = chartOptionsConfig?.isShowPercent === true;
        const isShowLegend = chartOptionsConfig?.isShowLegend === true;
        const isShowYAxis = chartOptionsConfig?.isShowYAxis === true;
        const isHistogram = chartOptionsConfig?.isHistogram === true;
        const isClusterBarData = chartOptionsConfig?.isClusterBarData === true;
        const isStack = (chartOptionsConfig?.isStack as 'X' | 'Y' | 'XY' | '!XY') || '!XY';
        const useSelector = chartOptionsConfig?.useSelector === true;
        const isShowXAxisGridLine = (chartOptionsConfig?.isShowXAxisGridLine as boolean) === true;
        const isShowYAxisGridLine = (chartOptionsConfig?.isShowYAxisGridLine as boolean) === true;
        const initialSelected: string[] = chartOptionsConfig?.initialSelected;
        const useSuffixNumber = chartOptionsConfig?.useSuffixNumber === true;
        const useDepthDataLabel = chartOptionsConfig?.useDepthDataLabel === true;
        const labelTextFormatting: 'DEFAULT' | 'FIRST_UPPER' | 'TIME_LIST' = chartOptionsConfig?.labelTextFormatting || 'DEFAULT';
        const inputLockText = chartOptionsConfig?.inputLockText || 'This graph is available in Monthly View';
        const labelOption: 'default' | 'logo'= (chartOptionsConfig?.labelOption as 'default' | 'logo' || 'default');
        const labelGroup: 'default' | 'messenger' | 'shoppingBagColor' | 'brand' = (chartOptionsConfig?.labelGroup as 'default' | 'messenger' | 'shoppingBagColor' | 'brand' || 'default');
        const legendLabelsConfig: { boxWidth: number; fontColor: string; fontSize: number } = chartOptionsConfig?.legendLabelsConfig || { boxWidth: 20, fontColor: 'white', fontSize: 14 };
        const layoutPaddingConfig: { top: number; left: number; right: number; bottom: number } = chartOptionsConfig?.layoutPaddingConfig || { top: 0, left: 5, right: 5, bottom: 5 };
        const tooltipsConfig: { titleAlign: 'left' | 'center' | 'right'; titleFontSize: number; bodyFontSize: number; position?: string } = chartOptionsConfig?.tooltipsConfig || { titleAlign: 'center', titleFontSize: 18, bodyFontSize: 16 };
        const label: { position: 'start' | 'center' | 'end' } = chartOptionsConfig?.label || { position: 'end' };
        const zoneAsBuilding: boolean = chartOptionsConfig?.zoneAsBuilding === true;
        const toggleVehicleProfileSelector: boolean = chartOptionsConfig?.toggleVehicleProfileSelector === true;
        const includeAreaList: string[] = chartOptionsConfig?.includeAreaList || [];
        const displayLabelKey = chartOptionsConfig?.displayLabelKey;
        const usingAllTrafficChannelMode = chartOptionsConfig?.usingAllTrafficChannelMode === true;

        const mappingImages = {
          messenger: configDataService.messengerLogoImageSrc,
          shoppingBagColor: configDataService.shoppingBagColorImageSrc,
          brand: configDataService.brandImageSrc,
        };
        const labelImages = labelGroup === 'default' ? {} : mappingImages[labelGroup];

        const chartType = (barChartConfig.graphType || 'HORIZONTAL');
        const chartIsHorizontal = chartType === 'HORIZONTAL';

        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = isMockData ? [] : Object.keys(chartDataConfig).map(key => {
          const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as SelectableCustomDataName, graphDataService);
          graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
          return dataConfigResolved.data;
        });

        // type DepthData = {
        //     [name: string]: number;
        // } & {
        //     _total: number;
        // };
        // type EntraceExitData = { entrance: DepthData; exit: DepthData };

        // const getEntraceExitData = (isEntrance: boolean, data: { entrance: DepthData; exit: DepthData }) => isEntrance ? data.entrance : data.exit;

        // const getDepthData = (
        //     data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
        //     buildingName?: string,
        //     floorName?: string,
        //     depthZoneName?: string,
        // ) => {
        //     let retData: unknown;
        //     if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
        //         retData = data;
        //     } else {
        //         retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0);
        //     }
        //     // if (Object.keys(retData).includes('entrance')) {
        //     //     retData = getEntraceExitData(true, retData as EntraceExitData);
        //     // }
        //     return retData;
        // };

        const biDataGrouper = (prev: any[], current: any, idx: number) => {
          const currentArray = prev[prev.length - 1];
          if (currentArray.length === 2) {
              prev.push([currentArray[currentArray.length - 1]]);
              prev[prev.length - 1].push(current);
          } else {
              prev[prev.length - 1].push(current);
          }
          return prev;
      };

        const compareKey = (a: any, b: any) => {
          if (a.includes('-') && b.includes('-')) {
            const numA = Number(a.split('-')[0]);
            const numB = Number(b.split('-')[0]);
            if (numA < numB) {
              return -1;
            }
            if (numA > numB) {
              return 1;
            }
            return 0;
          }
          return a - b;
        };

        const presentNumberValueTxt = (value: number, args: number) => {
          if (isShowPercent) {
            return value > 1 ? `${value.toFixed(0)}%` : `<1%`;
          } else if (useSuffixNumber) {
            const suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];
        
            if (Number.isNaN(value) || value === null) {
              return null;
            }
        
            if (value < 1000) {
              return (value || 0).toLocaleString();
            }
        
            const exp = Math.floor(Math.log(value) / Math.log(1000));
        
            return (value / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
          }
          return value?.toLocaleString();
        };

        const selectedAreaData = (datas: any[], level: 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA', directory: { building: string; floor: string; zone: string }) => {
          if (directory === undefined || level === undefined) {
            return datas;
          }
          const selectedData = directory?.floor === 'ALL' ? datas[0] : level === 'FLOOR' ? datas[1] : datas[2];
          return [selectedData] as Record<string, unknown>[];
        };

        const chartData$ = new BehaviorSubject<ChartDataSets[]>([]);
        const chartOption$ = new BehaviorSubject<ChartOptions>({});
        const chartLabel$ = new BehaviorSubject<Label[]>([]);
        const isLock$ = new BehaviorSubject<boolean>(chartState === 'LOCK');
        const barChartSelector$ = new BehaviorSubject<string[]>(initialSelected);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicCustomBarChartComponent;
        const allDataBehaviourSubject$: BehaviorSubject<unknown>[] = useselectedDirectory ? 
        [graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, ...chartDataBehaviourSubject$]
        : useSelector 
        ? [barChartSelector$, ...chartDataBehaviourSubject$]
        : chartDataBehaviourSubject$;

        if (isMockData) {
          subscription.add(combineLatest([barChartSelector$, viewPeriodService.dayList, graphDataService.baseGraphData.selectedInteractable$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, ...allDataBehaviourSubject$])
          .subscribe(async ([barChartSelector, dayList, selectedInteractable, selectDirectory, selectLevel, combinedResult]) => {
            let selectedInteractableName = selectedInteractable?.name;
            // const selectDirectory = combinedResult[0] as { building: string; floor: string; zone: string };
            // const selectLevel = combinedResult[1] as 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA';
            if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
              comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
            }
            const isEntrance = true;
            const mockChartDataList: any[] = [];
            const num_interval = labelTextFormatting === 'TIME_LIST' ? configDataService.TIME_LIST.length - 1 : 1;
            if (chartDataConfig instanceof Array) {
              for (const dataConfig of chartDataConfig) {
                const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, dataConfig.name, 'count', num_interval, false, true);
                mockChartDataList.push(mockChartDataValue);
              }
            } else {
              Object.keys(chartDataConfig).map(async key => {
                const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, chartDataConfig[key].name, 'count', num_interval, false, true);
                mockChartDataList.push(mockChartDataValue);
              });
            }
            const rawDatas = mockChartDataList; 
              if (useselectedDirectory) {
                if (!selectDirectory) {
                    comInstance.isShow = false;
                    return;
                } 
                if (barChartLockDate) {
                  if (selectDirectory?.zone === '5d') {
                    viewPeriodService.selectedDate.isBetween('2021-03-14', '2021-03-17') ? isLock$.next(true) : (viewPeriodService.selectedDate.isBefore('2021-03-08') ? isLock$.next(true)  : isLock$.next(false) );
                  } else if (selectDirectory?.zone === '4d' || selectDirectory?.zone === '6d') {
                      viewPeriodService.selectedDate.isBefore('2021-03-17') ? isLock$.next(true) : isLock$.next(false);
                  } else {
                      isLock$.next(true);
                  }
                }
              }
              const chartDataValues: number[] = [];
              let keyLabels: string[];
              if (useSelector) {
                const selected = barChartSelector as string[];
                rawDatas.forEach((rawData, idx) => {
                  const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => selected.includes(key)));
                  const data = rawData as { [key: string]: number };
                  // const extractChannelModeData: { [key: string]: number } = {};
                  // if (labelGroup === 'brand') {
                  //   keyLabels = Object.keys(extractChannelModeData);
                  //   chartDataValues.push(...Object.values(extractChannelModeData));
                  // } else {
                  //   chartDataValues.push(...Object.values(data));
                  // }
                  chartDataValues.push(...Object.values(data));
                  const labels = Object.keys(filterData).sort(compareKey).map( key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                  chartLabel$.next(labels);
                });
              } else if (useselectedDirectory) {
                rawDatas.forEach((rawData, idx) => {
                  const dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useDepthDataLabel ? combinedResult[0] as string[] : chartDataConfig[idx]?.args;
                  // const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => key !== '_total') );
                  const filterData = configDataService.isFeatureEnabled('multiple_organization') && selectDirectory.floor === 'onesiam_plus_007_5f' ? graphDataService.spFloorEntranceExitPin$.getValue() : rawData;
                  let selectedFloor = selectLevel === 'FLOOR' && selectDirectory.floor === 'ALL' ? undefined : configDataService.isFeatureEnabled('multiple_organization') && selectDirectory.floor === 'onesiam_plus_007_5f' ? 'siam_paragon_5th' : selectDirectory.floor ;
                  const selectedZone = selectLevel !== 'ZONE' ? undefined : selectDirectory.zone;
                  if (selectedZone === 'pavilion_000_g_001_2nd_reception') {
                    selectedFloor = 'pavilion_000_g_001_2nd';
                  }
                  const data = getDepthData(isEntrance, filterData, { buildingName: selectLevel === 'ZONE' && zoneAsBuilding ? selectedZone : dataConfig[0], floorName: selectedFloor, zoneName: selectLevel === 'ZONE' && zoneAsBuilding ? undefined : selectedZone }, undefined, true); 
                  const parseData = (data as any)?._total as { entrance: number; exit: number } || { entrance: 0, exit: 0 };
                  const labels = labelTextFormatting === 'TIME_LIST' 
                  ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                  : Object.keys(parseData).map(key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] || key);
                  keyLabels = Object.keys(parseData).sort(compareKey);
                  chartLabel$.next(labels);
                  chartDataValues.push(...Object.values(parseData));
                  const graphTitleName = (configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey);
                  comInstance.title = selectLevel === 'FLOOR' && selectDirectory.floor === 'ALL' ? graphTitleName
                  : selectLevel === 'FLOOR' ? `${graphTitleName}: ${configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[selectDirectory.floor] || selectDirectory.floor}`
                  : `${graphTitleName}: ${configDataService.DISPLAY_LANGUAGE.ZONE_NAME[selectDirectory.zone] || selectDirectory.zone}`;
                });
              } else {               
                rawDatas.forEach((rawData, idx) => {
                  let dataConfig: string[] = [];
                  if (selectedInteractableName) {
                    if (rawData?.[selectedInteractableName] === undefined) {
                      selectedInteractableName = 'default';
                    }
                    if (typeof rawData[selectedInteractableName] === 'undefined'){
                      const labels = labelTextFormatting === 'TIME_LIST'
                      ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                      : labelTextFormatting === 'FIRST_UPPER'
                      ? Object.keys(rawData).sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                      : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                      : Object.keys(rawData).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                      : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                      keyLabels = Object.keys(rawData).sort(compareKey);
                      chartLabel$.next(labels);
                      const usedData = Object.values(rawData);
                      if (Array.isArray(usedData) && usedData.length > 0 && Array.isArray(usedData[0])) {
                        chartDataValues.push(...Object.values(usedData[0] as number[]));
                      } else {
                        chartDataValues.push(...Object.values(usedData as number[]));
                      }
                    } else {
                      if (Object.keys(rawData[selectedInteractableName]).length > 1) {
                        const usedData = rawData[selectedInteractableName];
                        const userDataKey = Object.keys(usedData);
                        const dataValues = Object.values(usedData) as number[];
                        const labels = labelTextFormatting === 'TIME_LIST'
                        ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                        : labelTextFormatting === 'FIRST_UPPER'
                        ? userDataKey.sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                        : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                        : userDataKey.sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                        : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                        keyLabels = userDataKey.sort(compareKey);
                        chartLabel$.next(labels);
                        chartDataValues.push(...dataValues);
                      } else {
                        const usedData = rawData[selectedInteractableName] ? getDepthData(isEntrance, rawData[selectedInteractableName], {}, undefined) : getDepthData(isEntrance, rawData, {}, undefined);
                        const userDataKey = Object.keys(usedData);
                        const dataValues = Object.values(usedData)[0];
                        const labels = labelTextFormatting === 'TIME_LIST'
                        ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                        : labelTextFormatting === 'FIRST_UPPER'
                        ? userDataKey.sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                        : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                        : Object.keys(usedData).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                        : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                        keyLabels = Object.keys(usedData).sort(compareKey);
                        chartLabel$.next(labels);
                        chartDataValues.push(...dataValues);
                      }
                    }
                  } else {
                    if((rawData as any).mbk_parking){
                    dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useDepthDataLabel ? combinedResult[0] as string[] : chartDataConfig[idx]?.args;
                    const filterData = Object.fromEntries(Object.entries((rawData as any).mbk_parking).filter(([key, _value]) => key !== '_total') );
                    const data = useselectedDirectory
                    ? selectLevel === 'ZONE'
                    ? getDepthData(isEntrance, filterData, { buildingName: selectDirectory.zone }, undefined, usingAllTrafficChannelMode) 
                    : selectDirectory.floor === 'ALL'
                    ? getDepthData(isEntrance, filterData, {}, undefined, usingAllTrafficChannelMode)
                    : getDepthData(isEntrance, filterData, { buildingName: selectDirectory.building, floorName: selectDirectory.floor }, undefined, usingAllTrafficChannelMode)
                    : getDepthData(isEntrance, filterData, { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }, undefined, usingAllTrafficChannelMode);
                    const labels = labelTextFormatting === 'TIME_LIST'
                    ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                    : labelTextFormatting === 'FIRST_UPPER'
                    ? Object.keys(data).sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                    : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                    : Object.keys(data).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                    : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                    keyLabels = Object.keys(data).sort(compareKey);
                    chartLabel$.next(labels);
                    chartDataValues.push(...Object.values(data));
                    } else {
                    dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useDepthDataLabel ? combinedResult[0] as string[] : chartDataConfig[idx]?.args;
                    const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => key !== '_total') );
                    const data = useselectedDirectory
                    ? selectLevel === 'ZONE'
                    ? getDepthData(isEntrance, filterData, { buildingName: selectDirectory.zone }, undefined, usingAllTrafficChannelMode) 
                    : selectDirectory.floor === 'ALL'
                    ? getDepthData(isEntrance, filterData, {}, undefined, usingAllTrafficChannelMode)
                    : getDepthData(isEntrance, filterData, { buildingName: selectDirectory.building, floorName: selectDirectory.floor }, undefined, usingAllTrafficChannelMode)
                    : getDepthData(isEntrance, filterData, { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }, undefined, usingAllTrafficChannelMode);
                    const labels = labelTextFormatting === 'TIME_LIST'
                    ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                    : labelTextFormatting === 'FIRST_UPPER'
                    ? Object.keys(data).sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                    : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                    : Object.keys(data).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                    : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                    keyLabels = Object.keys(data).sort(compareKey);
                    chartLabel$.next(labels);
                    chartDataValues.push(...Object.values(data));
                    }
                  }
                });
              }
              if (isClusterBarData) {
                const totalKeyChartDataValue: any[] = labelTextFormatting === 'TIME_LIST' ? chartDataValues : Object.keys(chartDataValues[0]);
                const chartDatas: ChartDataSets[] = totalKeyChartDataValue.map((key, idx) => ({
                    barPercentage: 0.9,
                    hoverBackgroundColor: labelTextFormatting === 'TIME_LIST' ? undefined : PIE_CHART_COLOR_LIST[idx],
                    // borderWidth: 0.25,
                    // hoverBorderWidth: 1,
                    data: labelTextFormatting === 'TIME_LIST' ? key : chartDataValues.map(dataObj => dataObj[key]), 
                    // hoverBorderColor: labelTextFormatting === 'TIME_LIST' ? idx === 0 ? `${CHART_PRIMARY_COLOR}22` : `${CHART_SECONDARY_COLOR}22` : 'rgba(0, 0, 0, 1)',
                    hoverBorderColor: 'rgba(0, 0, 0, 1)',
                    backgroundColor: labelTextFormatting === 'TIME_LIST' ? idx === 0 ? CHART_PRIMARY_COLOR : CHART_SECONDARY_COLOR : PIE_CHART_COLOR_LIST[idx],
                    label: labelTextFormatting === 'TIME_LIST' ? idx === 0 ? 'Entrance' : 'Exit' : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key],
                  }));
                chartData$.next(chartDatas);
              } else if (isClassData) {
                const chartData: ChartDataSets[] = [{
                  categoryPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.8 : 1,
                  barPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.9 : 0.5,
                  hoverBackgroundColor: keyLabels.length > 1 ? keyLabels.map(key => configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] ? configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] : PIE_CHART_COLOR_LIST[0]) : PIE_CHART_COLOR_LIST[0],
                  // borderWidth: isHistogram ? 0.25 : 0,
                  hoverBorderWidth: isHistogram ? 2 : 1,
                  data: chartDataValues, 
                  hoverBorderColor: 'rgba(0, 0, 0, 1)',
                  backgroundColor: keyLabels.length > 1 ? keyLabels.map(key => configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] ? configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] : PIE_CHART_COLOR_LIST[0]) : PIE_CHART_COLOR_LIST[0],  
                }];
                chartData$.next(chartData);
              }
              else {
                const chartData: ChartDataSets[] = [{
                  categoryPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.8 : 1,
                  barPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.9 : 0.5,
                  hoverBackgroundColor: chartDataValues.length === 2 ? PIE_CHART_COLOR_LIST : configDataService.BG_COLOR_LIST?.[chartTitleKey] ? Object.values(configDataService.BG_COLOR_LIST?.[chartTitleKey]) : PIE_CHART_COLOR_LIST[0],
                  // borderWidth: isHistogram ? 0.25 : 0,
                  hoverBorderWidth: isHistogram ? 2 : 1,
                  data: chartDataValues, 
                  hoverBorderColor: 'rgba(0, 0, 0, 1)',
                  backgroundColor: chartDataValues.length === 2 ? PIE_CHART_COLOR_LIST : configDataService.BG_COLOR_LIST?.[chartTitleKey] ? Object.values(configDataService.BG_COLOR_LIST?.[chartTitleKey]) : PIE_CHART_COLOR_LIST[0],
                }];
                chartData$.next(chartData);
              }
          }));
        } else {
          subscription.add(combineLatest(allDataBehaviourSubject$.concat(graphDataService.baseGraphData.selectedInteractable$)).subscribe((combinedResult) => {
            const selectedInteractableName = (combinedResult[combinedResult.length - 1] as InteractableType)?.name;
            const selectDirectory = combinedResult[0] as { building: string; floor: string; zone: string };
            const selectLevel = combinedResult[1] as 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA';
            if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
              comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
            }
            if (includeAreaList.length > 0 && selectedInteractableName) {
              comInstance.isShow = includeAreaList.includes(selectedInteractableName);
            }
            subscription.add(combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$])
            .subscribe(([selectedDirectory, selectedLevel]) => {
              if (excludedDirectory !== undefined) {
                if (excludedDirectory === 'BUILDING') {
                  comInstance.isShow =  selectedDirectory?.floor !== 'ALL';
                }
                else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                  if (selectedDirectory?.floor === 'ALL' && excludedDirectory === 'FLOOR') {
                    comInstance.isShow = true;
                  } else {
                    comInstance.isShow = selectedLevel !== excludedDirectory;
                  }
                }
                // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') <excludedDirectory.length - 1) {
                  const excludeList = excludedDirectory.split('_AND_');
                  if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                    comInstance.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                  }
                  else if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                    comInstance.isShow = true;
                  } else {
                    comInstance.isShow = !excludeList.includes(selectedLevel);
                  }
                }
              }
            }));
            const vehicleFilteredSelected = graphDataService.selectedVehicleProfile$.getValue();
            if (toggleVehicleProfileSelector) {
              comInstance.isShow = vehicleFilteredSelected?.vehicle_type === undefined && vehicleFilteredSelected?.car_brand === undefined  && vehicleFilteredSelected?.purchasing_power === undefined;
            }
            if (!useselectedDirectory && (combinedResult as any[]).slice(0, combinedResult.length - 1).some(val => !val)) { 
              return; 
            }
            if (useselectedDirectory) {
              if ((combinedResult as any[]).slice(2, combinedResult.length - 1).some(val => !val)) {
                return;
              }
            }
            const isEntrance = true;
            const rawDatas = useselectedDirectory ? selectedAreaData((combinedResult as any[]).slice(2, combinedResult.length - 1), selectLevel, selectDirectory)
            : useSelector ? (combinedResult as any[]).slice(1, combinedResult.length - 1) as Record<string, unknown>[] : combinedResult.slice(0, combinedResult.length - 1) as Record<string, unknown>[]; 
            if (useselectedDirectory) {
                if (!selectDirectory) {
                    comInstance.isShow = false;
                    return;
                } 
                if (barChartLockDate) {
                  if (selectDirectory?.zone === '5d') {
                    viewPeriodService.selectedDate.isBetween('2021-03-14', '2021-03-17') ? isLock$.next(true) : (viewPeriodService.selectedDate.isBefore('2021-03-08') ? isLock$.next(true)  : isLock$.next(false) );
                  } else if (selectDirectory?.zone === '4d' || selectDirectory?.zone === '6d') {
                      viewPeriodService.selectedDate.isBefore('2021-03-17') ? isLock$.next(true) : isLock$.next(false);
                  } else {
                      isLock$.next(true);
                  }
                }
              }
              const chartDataValues: number[] = [];
              let keyLabels: string[];
              
              if (useSelector) {
                const selected = combinedResult[0] as string[];
                rawDatas.forEach((rawData, idx) => {
                  const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => selected.includes(key)));
                  const data = useSelector && useDepthDataLabel 
                  ? getDepthData(isEntrance, filterData, { buildingName: (combinedResult[0] as string[])[0], floorName: (combinedResult[0] as string[])[1], zoneName: (combinedResult[0] as string[])[2] } ) 
                  : getDepthData(isEntrance, filterData, { buildingName: chartDataConfig[idx]?.args[0], floorName: chartDataConfig[idx]?.args[1], zoneName: chartDataConfig[idx]?.args[2]});
                  const extractChannelModeData: { [key: string]: number } = {};
                  for (const [key, value] of Object.entries(data)) {
                    extractChannelModeData[key] = isEntrance ? value.entrance : value.exit;
                  }
                  if (labelGroup === 'brand') {
                    keyLabels = Object.keys(extractChannelModeData);
                    chartDataValues.push(...Object.values(extractChannelModeData));
                  } else {
                    chartDataValues.push(...Object.values(data));
                  }
                  const labels = Object.keys(useDepthDataLabel ? data : filterData).sort(compareKey).map( key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                  chartLabel$.next(labels);
                });
              } else if (useselectedDirectory) {
                rawDatas.forEach((rawData, idx) => {
                  const dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useDepthDataLabel ? combinedResult[0] as string[] : chartDataConfig[idx]?.args;
                  // const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => key !== '_total') );
                  const filterData = configDataService.isFeatureEnabled('multiple_organization') && selectDirectory.floor === 'onesiam_plus_007_5f' ? graphDataService.spFloorEntranceExitPin$.getValue() : rawData;
                  let selectedFloor = selectLevel === 'FLOOR' && selectDirectory.floor === 'ALL' ? undefined : configDataService.isFeatureEnabled('multiple_organization') && selectDirectory.floor === 'onesiam_plus_007_5f' ? 'siam_paragon_5th' : selectDirectory.floor ;
                  const selectedZone = selectLevel !== 'ZONE' ? undefined : selectDirectory.zone;
                  if (selectedZone === 'pavilion_000_g_001_2nd_reception') {
                    selectedFloor = 'pavilion_000_g_001_2nd';
                  }
                  const data = getDepthData(isEntrance, filterData, { buildingName: selectLevel === 'ZONE' && zoneAsBuilding ? selectedZone : dataConfig[0], floorName: selectedFloor, zoneName: selectLevel === 'ZONE' && zoneAsBuilding ? undefined : selectedZone }, undefined, true); 
                  const parseData = (data as any)?._total as { entrance: number; exit: number } || { entrance: 0, exit: 0 };
                  const labels = labelTextFormatting === 'TIME_LIST' 
                  ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                  : Object.keys(parseData).map(key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] || key);
                  keyLabels = Object.keys(parseData).sort(compareKey);
                  chartLabel$.next(labels);
                  chartDataValues.push(...Object.values(parseData));
                  const graphTitleName = (configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey);
                  comInstance.title = selectLevel === 'FLOOR' && selectDirectory.floor === 'ALL' ? graphTitleName
                  : selectLevel === 'FLOOR' ? `${graphTitleName}: ${configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[selectDirectory.floor] || selectDirectory.floor}`
                  : `${graphTitleName}: ${configDataService.DISPLAY_LANGUAGE.ZONE_NAME[selectDirectory.zone] || selectDirectory.zone}`;
                });
              } else {
                rawDatas.forEach((rawData, idx) => {
                  let dataConfig: string[] = [];
                  if (selectedInteractableName){
                    if (typeof rawData[selectedInteractableName] === 'undefined'){
                      const labels = labelTextFormatting === 'TIME_LIST'
                      ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                      : labelTextFormatting === 'FIRST_UPPER'
                      ? Object.keys(rawData).sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                      : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                      : Object.keys(rawData).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                      : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                      keyLabels = Object.keys(rawData).sort(compareKey);
                      chartLabel$.next(labels);
                      const usedData = Object.values(rawData);
                      if (Array.isArray(usedData) && usedData.length > 0 && Array.isArray(usedData[0])) {
                        chartDataValues.push(...Object.values(usedData[0] as number[]));
                      } else {
                        chartDataValues.push(...Object.values(usedData as number[]));
                      }
                    } else {
                      // dataConfig = [...selectedNameArray];
                      // const labels = Object.keys(rawData[selectedInteractableName]? rawData[selectedInteractableName]:rawData);
                      // const data: unknown = Object.values(rawData[selectedInteractableName] ? rawData[selectedInteractableName] : rawData).sort((a: number, b: number) => b - a);
                      const usedData = rawData[selectedInteractableName] ? getDepthData(isEntrance, rawData[selectedInteractableName], {}, undefined) : getDepthData(isEntrance, rawData, {}, undefined);
                      const labels = labelTextFormatting === 'TIME_LIST'
                      ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                      : labelTextFormatting === 'FIRST_UPPER'
                      ? Object.keys(usedData).sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                      : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                      : Object.keys(usedData).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                      : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                      keyLabels = Object.keys(usedData).sort(compareKey);
                      chartLabel$.next(labels);
                      chartDataValues.push(...Object.values(usedData));
                    }
                  } else {
                    if((rawData as any).mbk_parking){
                      dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useDepthDataLabel ? combinedResult[0] as string[] : chartDataConfig[idx]?.args;
                      const filterData = Object.fromEntries(Object.entries((rawData as any).mbk_parking).filter(([key, _value]) => key !== '_total') );
                      const data = useselectedDirectory
                      ? selectLevel === 'ZONE'
                      ? getDepthData(isEntrance, filterData, { buildingName: selectDirectory.zone }, undefined, usingAllTrafficChannelMode) 
                      : selectDirectory.floor === 'ALL'
                      ? getDepthData(isEntrance, filterData, {}, undefined, usingAllTrafficChannelMode)
                      : getDepthData(isEntrance, filterData, { buildingName: selectDirectory.building, floorName: selectDirectory.floor }, undefined, usingAllTrafficChannelMode)
                      : getDepthData(isEntrance, filterData, { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }, undefined, usingAllTrafficChannelMode);
                      const labels = labelTextFormatting === 'TIME_LIST'
                      ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                      : labelTextFormatting === 'FIRST_UPPER'
                      ? Object.keys(data).sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                      : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                      : Object.keys(data).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                      : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                      keyLabels = Object.keys(data).sort(compareKey);
                      chartLabel$.next(labels);
                      chartDataValues.push(...Object.values(data));
                    } else {
                      dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useDepthDataLabel ? combinedResult[0] as string[] : chartDataConfig[idx]?.args;
                      const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => key !== '_total') );
                      const data = useselectedDirectory
                      ? selectLevel === 'ZONE'
                      ? getDepthData(isEntrance, filterData, { buildingName: selectDirectory.zone }, undefined, usingAllTrafficChannelMode) 
                      : selectDirectory.floor === 'ALL'
                      ? getDepthData(isEntrance, filterData, {}, undefined, usingAllTrafficChannelMode)
                      : getDepthData(isEntrance, filterData, { buildingName: selectDirectory.building, floorName: selectDirectory.floor }, undefined, usingAllTrafficChannelMode)
                      : getDepthData(isEntrance, filterData, { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }, undefined, usingAllTrafficChannelMode);
                      const labels = labelTextFormatting === 'TIME_LIST'
                      ? configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`)
                      : labelTextFormatting === 'FIRST_UPPER'
                      ? Object.keys(data).sort(compareKey).map(key => displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key]  
                      : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] ? configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] : key.charAt(0).toUpperCase() + key.slice(1)) 
                      : Object.keys(data).sort(compareKey).map(key => isHistogram ? configDataService.DISPLAY_LANGUAGE.BIN_NAME[key]
                      : displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[key] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key]);
                      keyLabels = Object.keys(data).sort(compareKey);
                      chartLabel$.next(labels);
                      chartDataValues.push(...Object.values(data));
                    }
                  }
                });
              }
              if (isClusterBarData) {
                const totalKeyChartDataValue: any[] = labelTextFormatting === 'TIME_LIST' ? chartDataValues : Object.keys(chartDataValues[0]);
                const chartDatas: ChartDataSets[] = totalKeyChartDataValue.map((key, idx) => ({
                    barPercentage: 0.9,
                    hoverBackgroundColor: labelTextFormatting === 'TIME_LIST' ? undefined : PIE_CHART_COLOR_LIST[idx],
                    // borderWidth: 0.25,
                    // hoverBorderWidth: 1,
                    data: labelTextFormatting === 'TIME_LIST' ? key : chartDataValues.map(dataObj => dataObj[key]), 
                    // hoverBorderColor: labelTextFormatting === 'TIME_LIST' ? idx === 0 ? `${CHART_PRIMARY_COLOR}22` : `${CHART_SECONDARY_COLOR}22` : 'rgba(0, 0, 0, 1)',
                    hoverBorderColor: 'rgba(0, 0, 0, 1)',
                    backgroundColor: labelTextFormatting === 'TIME_LIST' ? idx === 0 ? CHART_PRIMARY_COLOR : CHART_SECONDARY_COLOR : PIE_CHART_COLOR_LIST[idx],
                    label: labelTextFormatting === 'TIME_LIST' ? idx === 0 ? 'Entrance' : 'Exit' : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key],
                  }));
                chartData$.next(chartDatas);
              } else if (isClassData) {
                const chartData: ChartDataSets[] = [{
                  categoryPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.8 : 1,
                  barPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.9 : 0.5,
                  hoverBackgroundColor: keyLabels.length > 1 ? keyLabels.map(key => configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] ? configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] : PIE_CHART_COLOR_LIST[0]) : PIE_CHART_COLOR_LIST[0],
                  // borderWidth: isHistogram ? 0.25 : 0,
                  hoverBorderWidth: isHistogram ? 2 : 1,
                  data: chartDataValues, 
                  hoverBorderColor: 'rgba(0, 0, 0, 1)',
                  backgroundColor: keyLabels.length > 1 ? keyLabels.map(key => configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] ? configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[key] : PIE_CHART_COLOR_LIST[0]) : PIE_CHART_COLOR_LIST[0],  
                }];
                chartData$.next(chartData);
              }
              else {
                const chartData: ChartDataSets[] = [{
                  categoryPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.8 : 1,
                  barPercentage: isHistogram ? 1.0 : chartDataValues.length > 5 ? 0.9 : 0.5,
                  hoverBackgroundColor: chartDataValues.length === 2 ? PIE_CHART_COLOR_LIST : PIE_CHART_COLOR_LIST[0],
                  // borderWidth: isHistogram ? 0.25 : 0,
                  hoverBorderWidth: isHistogram ? 2 : 1,
                  data: chartDataValues, 
                  hoverBorderColor: 'rgba(0, 0, 0, 1)',
                  backgroundColor: chartDataValues.length === 2 ? PIE_CHART_COLOR_LIST : PIE_CHART_COLOR_LIST[0],
                }];
                chartData$.next(chartData);
              }
          }));
        }

        chartOption$.next({
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: useselectedDirectory ? 1.1 : isClusterBarData ? labelTextFormatting === 'TIME_LIST' ? 1.1 : 0.8 : 1.1,
            animation: {
                duration: 1000,
                easing: 'easeOutCubic',
            },
            responsiveAnimationDuration: 1000,
            layout: {
                padding: {
                  top: !chartIsHorizontal ? 20 : layoutPaddingConfig.top,
                  left: labelOption === 'logo' && configDataService.isMobilePlatform && chartIsHorizontal ? 10 : layoutPaddingConfig.left,
                  right: useselectedDirectory ? 0 : isHistogram ? 5 : (chartIsHorizontal && isScreenSmallerThanMD(window)) ? 35 : layoutPaddingConfig.right,
                  bottom: layoutPaddingConfig.bottom,
                }
            },
            legend: {
              display: isShowLegend,
                labels: {
                  boxWidth: legendLabelsConfig.boxWidth,
                  fontColor: legendLabelsConfig.fontColor,
                  fontSize: legendLabelsConfig.fontSize,
                },
                onClick: (e) => e.stopPropagation(),
            },
            scales: {
              xAxes: [{
                ticks: {
                    display: labelOption === 'logo' && configDataService.isMobilePlatform ? false : !chartIsHorizontal,
                    beginAtZero: true,
                    callback: (value) => {
                        if (!chartIsHorizontal) {
                            return value;
                        }
                        if (typeof value === 'string') {
                          return value;
                        }
                        return value < 1000 ? value : `${Math.round((value as number) / 1000)}K`;
                    },
                    fontColor: '#efefef',
                    fontSize: isScreenSmallerThanLG(window) ? 12 : 14,
                    minRotation: 90,
                    maxRotation: 90,
                    min: 0,
                  },
                  stacked: isStack === 'X' || isStack === 'XY',
                  gridLines: {
                    display: isShowXAxisGridLine,
                    color: BOTH_THEME_BASE_COLOR,
                  },
              }],
              yAxes: [{
                ticks: {
                    display: chartIsHorizontal,
                    callback: (value) => {
                        if (chartIsHorizontal) {
                          return value;
                        }
                        if (typeof value === 'string') {
                          return value;
                        }
                        return value < 1000 ? (isShowPercent ? `${value}%` : value) : `${Math.round((value as number) / 1000)}K`;
                    },
                    maxTicksLimit: chartIsHorizontal ? null : 6,
                    fontColor: labelOption === 'logo' && configDataService.isMobilePlatform ? '#212121' : '#efefef',
                    fontSize: labelOption === 'logo' && configDataService.isMobilePlatform ? 10 : isScreenSmallerThanLG(window) ? 12 : 14,
                    min: 0,
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: isShowYAxisGridLine,
                    color: BOTH_THEME_BASE_COLOR,
                  },
                  stacked: isStack === 'Y' || isStack === 'XY',
                  display: isShowYAxis,
              }]
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  const datasetIndex = tooltipItem.datasetIndex;
                  const datasetLabel = isClusterBarData ? data.datasets[datasetIndex].label : data.labels[tooltipItem.index];
                  const datasetData = data.datasets[datasetIndex].data[tooltipItem.index];
                  if ((datasetData as number) < 0 && !isShowPercent){
                    return `${datasetLabel}: N/A`;
                  }
                  return `${datasetLabel}: ${presentNumberValueTxt(datasetData as number, 1)}`;
                },
                labelColor: (tooltipItem, chart) => {
                    const chartColor = chart.data.datasets[tooltipItem.datasetIndex].backgroundColor as ChartColor;
                    return {
                      borderColor: 'black',
                      backgroundColor: typeof chartColor === 'string' ? chartColor : chartColor[tooltipItem.index],
                    };
                }
              },
              mode: isClusterBarData ? 'nearest' : 'index',
              titleAlign: tooltipsConfig.titleAlign,
              titleFontSize: tooltipsConfig.titleFontSize,
              bodyFontSize: tooltipsConfig.bodyFontSize,
            },
            plugins: {
              datalabels: {
                color: 'white',
                // display: (context) => context.dataset.data[context.dataIndex] > -2,
                display: (context) => context.chart.width <= 370 && label.position === 'center' ? context.dataset.data[context.dataIndex] > 10 : context.dataset.data[context.dataIndex] > -2,
                align: label.position, // default -> 'center' 
                anchor: label.position, // default -> 'center' 
                offset: 0, // default -> 4
                formatter: (value, context) => {
                  if (!value) {
                    return presentNumberValueTxt(value as number, 1);
                  }
                  if (isShowPercent && !(isShowYAxis)) {
                    return `${value.toFixed(0)}%`;
                  }
                  if (value < 0) {
                    return `N/A`;
                  }
                  return presentNumberValueTxt(value as number, 1);
                },
                font: (context) => {
                  const avgSize = Math.round((context.chart.height + context.chart.width) / 2);
                  const size = Math.round(avgSize / 18);
                  const newSize = size > 12 ? 12 : size; // setting max limit to 12
                  return {
                    size: newSize,
                    weight: 'bold'
                  };
                },
                clamp: true,
              },
            }
        });

        if (barChartConfig.graphInfoPopover) {
            const infoPopoverText = barChartConfig.graphInfoPopover;
            comInstance.infoPopover = async (e: any) => {
                if (chartTitleKey === 'AGE_PROFILE') {
                  const ageDetailPopover = await popoverController.create({
                    component: CustomerAgeTooltipsComponent,
                    cssClass: 'calendar-criteria-tooltips',
                    event: e
                  });
                  return await ageDetailPopover.present();
                }
                const stackBarPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE[infoPopoverText.title] || infoPopoverText.title,
                        toolTipDetails: infoPopoverText.details,
                    },
                    event: e,
                });
                return await stackBarPopover.present();
            };
        }
        const messengerBrandList = configDataService.DISPLAY_LANGUAGE.MESSENGER_CLASS;
        const brandList = configDataService.DISPLAY_LANGUAGE.BRAND_CLASS;
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.customToolTipFuction = labelGroup !== 'default' ? function(tooltipModel) {
          if (labelGroup === 'messenger') {
            showMessengerBrandCustomTooltips(tooltipModel, this, configDataService.messengerImageSrc, messengerBrandList);
          } else if (labelGroup === 'shoppingBagColor') {
            showShoppingBagColorCustomTooltip(tooltipModel, this, configDataService.shoppingBagColorImageSrc, viewPeriodService.selectedDate);
          } else if (labelGroup === 'brand') {
            showBrandCustomTooltips(tooltipModel, this, configDataService.brandImageSrc, brandList);
          }
        } 
        : isClusterBarData ? function(tooltipModel) {
          showGenericBarChartCustomTooltips(tooltipModel, this, null, isShowPercent ? 'percentFormatter' : useSuffixNumber ? 'numberFormatter' : 'toLocaleString', 'chartDataLabel', 3);
        } 
        : chartTitleKey === 'AGE_PROFILE' ? function(tooltipModel) {
          showAgeCustomTooltips(tooltipModel, this, configDataService.ageImageSrc);
        }
        : null;
        
        if (multipleSelected === undefined) {
          multipleSelected = true;
        }
        comInstance.labelImages = labelImages;
        comInstance.labelOption = configDataService.isMobilePlatform ? labelOption : 'default';
        comInstance.chartOptions$ = chartOption$;
        comInstance.isHorizontal = true;
        comInstance.chartLabel$ = chartLabel$;
        comInstance.data$ = chartData$;
        comInstance.isShowLegend = isShowLegend;
        comInstance.isLock = isLock$;
        comInstance.isHorizontal = chartIsHorizontal;
        comInstance.isShowSelector = useSelector;
        comInstance.barChartSelector$ = barChartSelector$;
        comInstance.chartSelectorOptions = chartSelectOptions;
        comInstance.inputLockText = inputLockText;
        comInstance.labelDisplayList = messengerBrandList;
        comInstance.multipleSelectedDropdown = multipleSelected;
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        return comInstance;
    }
}
