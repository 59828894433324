import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import { isScreenSmallerThanLG, numberFormatter } from 'src/app/helpers/util';
import { GenericHeatmapData } from 'src/app/objects/chart';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-generic-heatmap-echart',
  templateUrl: './generic-heatmap-echart.component.html',
  styleUrls: ['./generic-heatmap-echart.component.scss'],
})
export class GenericHeatmapEchartComponent implements OnInit, AfterViewInit {

  @Input() heatmapData$: BehaviorSubject<GenericHeatmapData>;
  @Input() xAxisLabelDisplayName: { [labelKey: string]: string };
  @Input() yAxisLabelDisplayName: { [labelKey: string]: string };
  @Input() gridSize: { width: string | number; height: string | number };
  @Input() visualMapType: 'continuous' | 'piecewise' = 'continuous';
  @Input() colorSchemeIndicator: string[] = ['#2a3bb0', '#506edb', '#7096f4', '#95b8fc', '#b9cff1', '#d8d7d7', '#efc2ad', '#f6a285', '#ea765d', '#cf453b'];
  textColor = '#fff';
  chartOption: EChartsOption = {
    tooltip: {
      // position: 'top',
      position: (point, _params, _dom, rect, size) => {
        const xPos = point[0] > rect.x ? point[0] < size.viewSize[0] / 2 ? rect.x + 35 : rect.x - 135 : point[0];
        const yPos = point[1] > rect.y ? point[1] < size.contentSize[1] ? size.contentSize[1] + 20 : rect.y - 50 : point[1];
        return [xPos, yPos];
      },
      // appendToBody: true,
      textStyle: {
        // fontSize: isScreenSmallerThanLG(window) ? 12 : 14 // default 14
      },
      formatter: (params: any) => {
        const displayNameXAxis = this.xAxisLabelDisplayName[params.value[0]] || params.value[0];
        const displayNameYAxis = this.yAxisLabelDisplayName[params.value[1]] || params.value[1];
        return `<b>Visit:</b> ${displayNameYAxis} </br> <b>Also Visit</b>: ${displayNameXAxis} </br> <b>Value:</b> ${params.value[2]}%`;
      },
    },
    grid: {
      // height: this.gridSize.height, // '75%'
      // width: this.gridSize.width, // '80%'
      top: '5%',
      // top: '10%',
      // left: isScreenSmallerThanLG(window) ? '20%' : '10%',
      right: '15%'
    },
    xAxis: {
      type: 'category',
      // data: hours,
      // data: heatmapData.xAxis.label,
      axisLabel: {
        color: this.textColor,
        // interval: isScreenSmallerThanLG(window) ? 'auto' : 0,
        // rotate: isScreenSmallerThanLG(window) ? 90 : 0,
        // hideOverlap: false,
        formatter: (value) => this.xAxisLabelDisplayName[value] || value
      },
      splitArea: {
        show: true,
      }
    },
    yAxis: {
      type: 'category',
      // data: days,
      splitArea: {
        show: true
      },
      axisLabel: {
        color: this.textColor,
        formatter: (value) => isScreenSmallerThanLG(window) ? (this.yAxisLabelDisplayName[value] || value).replace(' ', '\n') : this.yAxisLabelDisplayName[value] || value
      },
    },
    visualMap: {
      type: 'continuous',
      show: false,
      min: 0,
      max: 100,
      calculable: false,
      orient: 'horizontal',
      left: 'center',
      // bottom: isScreenSmallerThanLG(window) ? '1%' : '4%',
      // top: isScreenSmallerThanLG(window) ? '1%' : '4%',
      inRange: {
        // color: ['#f96a6c', '#f99192', '#fabfc3', '#fbfcfe', '#eaf5ef', '#d0ebda', '#bfdfc7', '#bcdec3', '#97ca9f', '#7bbc82'],
        color: this.colorSchemeIndicator,
      },
      text: ['High', 'Low'],
      textStyle: {
        color: this.textColor
      },
      formatter: '{value}%'
    },
    series: [
      {
        // name: 'Punch Card',
        type: 'heatmap',
        // datasetIndex: 1,
        label: {
          show: true,
          fontWeight: 'bold',
          // color: 'black',
          color: this.textColor,
          formatter: (params: any) => params.value[2] + '%',
        }, 
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ],
  };
  mergeOptions: EChartsOption = { };
  sampleData = [
    [0, 0, 5, 'group1'],
    [0, 1, 2, 'group1'],
    [1, 1, 2, 'group1'],
    [1, 0, 4, 'group1'],
    [2, 0, 10, 'group2'],
    [2, 1, 10, 'group2'],
    [3, 0, 10, 'group2'],
    [3, 1, 12, 'group2'],
    [1, 2, 16, 'group3'],
    [1, 3, 16, 'group3'],
    [0, 2, 16, 'group3'],
    [0, 3, 16, 'group3'],
    [2, 2, 14, 'group4'],
    [2, 3, 14, 'group4'],
    [3, 3, 55, 'group4'],
    [3, 2, 25, 'group4'],
  ];

  constructor(
    private configDataService: ConfigDataService,
    private cdr: ChangeDetectorRef
  ) { }

  parseUnit(value: number, unit: 'percent' | 'default' | 'si', decimalPoint?: number ) {
    if (!value) {
        // return 'N/A';
        return '<1%';
    }
    if (unit === 'percent') {
        if (value >= 0 && value < 1) {
            return '<1%';
        } 
        else if (isNaN(value)) {
            return 'N/A';
        }
        return decimalPoint ? value.toFixed(1) + '%' : value.toFixed(0) + '%';
    }
    else if (unit === 'si') {
        return numberFormatter(value, 1);
    }
    return value.toLocaleString(); 
};

  ngOnInit() {
    this.heatmapData$.subscribe(heatmapData => {
      if (!heatmapData) {
        return;
      }
      this.mergeOptions = this.visualMapType === 'continuous' ? 
      {
        grid: {
          height: this.gridSize.height, // '75%'
          width: this.gridSize.width, // '80%'
        },
        xAxis: {
          data: heatmapData.xAxis.label, 
        },
        yAxis: {
          data: heatmapData.yAxis.label,
        },
        visualMap: {
          inRange: {
            color: this.colorSchemeIndicator
          }
        },
        series: [{
          data: heatmapData.data,
          label: {
            formatter: (params: any) => this.parseUnit(params.value[2], heatmapData.dataLabel.unit),
          }
        }],
        media:[
          {
            query: {
              // when container width is greater than 600
              minWidth: 482
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 14
                }
              },
              grid: {
                left: '10%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  // rotate: 0,
                }
              }
            }
          },
          {
            query: {
              // when container width is smaller than 600
              maxWidth: 481
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 12
                }
              },
              grid: {
                left: '20%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  rotate: 90,
                }
              }
            }
          }
        ],
      } 
      : {
        tooltip: {
          show : false
        },
        visualMap: {
          type: 'piecewise',
          show: true,
          calculable: false,
          orient: 'horizontal',
          left: 'center',
          dimension: 3,
          // bottom: isScreenSmallerThanLG(window) ? '1%' : '4%',
          // top: isScreenSmallerThanLG(window) ? '1%' : '4%',
          inRange: {
            // color: ['#f96a6c', '#f99192', '#fabfc3', '#fbfcfe', '#eaf5ef', '#d0ebda', '#bfdfc7', '#bcdec3', '#97ca9f', '#7bbc82'],
            color: {
              group1: 'red',
              group2: 'green',
              group3: 'blue',
              group4: 'pink'
            }
          },
          categories: ['group1', 'group2', 'group3', 'group4'],
          textStyle: {
            color: this.textColor
          },
          formatter: (value, value2) => this.configDataService.DISPLAY_LANGUAGE.HEATMAP_CHART_NAME[value as string]
        },
        grid: {
          height: this.gridSize.height, // '75%'
          width: this.gridSize.width, // '80%'
        },
        xAxis: {
          data: heatmapData.xAxis.label,
          // name: 'XAxis',
          name: heatmapData.xAxis.axisName,
          nameTextStyle: {
            color: this.textColor
          },
          nameGap: 5
        },
        yAxis: {
          data: heatmapData.yAxis.label,
          // name: 'YAxis',
          name: heatmapData.yAxis.axisName,
          nameTextStyle: {
            color: this.textColor
          },
          nameGap: 5
        },
        series: [{
          data: heatmapData.data,
          label: {
            show: true,
            formatter: (params: any) => this.parseUnit(params.value[2], heatmapData.dataLabel.unit),
          },
        }],
        media:[
          {
            query: {
              // when container width is greater than 600
              minWidth: 482
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 14
                }
              },
              grid: {
                left: '10%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  // rotate: 0,
                }
              }
            }
          },
          {
            query: {
              // when container width is smaller than 600
              maxWidth: 481
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 12
                }
              },
              grid: {
                left: '20%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  rotate: 90,
                }
              }
            }
          }
        ],
      };
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.heatmapData$.subscribe(heatmapData => {
      if (!heatmapData) {
        return;
      }
      this.mergeOptions = this.visualMapType === 'continuous' ? 
      {
        grid: {
          height: this.gridSize.height, // '75%'
          width: this.gridSize.width, // '80%'
        },
        xAxis: {
          data: heatmapData.xAxis.label, 
        },
        yAxis: {
          data: heatmapData.yAxis.label,
        },
        visualMap: {
          inRange: {
            color: this.colorSchemeIndicator
          }
        },
        series: [{
          data: heatmapData.data,
          label: {
            formatter: (params: any) => this.parseUnit(params.value[2], heatmapData.dataLabel.unit),
          }
        }],
        media:[
          {
            query: {
              // when container width is greater than 600
              minWidth: 482
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 14
                }
              },
              grid: {
                left: '10%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  // rotate: 0,
                }
              }
            }
          },
          {
            query: {
              // when container width is smaller than 600
              maxWidth: 481
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 12
                }
              },
              grid: {
                left: '20%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  rotate: 90,
                }
              }
            }
          }
        ],
      } 
      : {
        tooltip: {
          show : false
        },
        visualMap: {
          type: 'piecewise',
          show: true,
          calculable: false,
          orient: 'horizontal',
          left: 'center',
          dimension: 3,
          // bottom: isScreenSmallerThanLG(window) ? '1%' : '4%',
          // top: isScreenSmallerThanLG(window) ? '1%' : '4%',
          inRange: {
            // color: ['#f96a6c', '#f99192', '#fabfc3', '#fbfcfe', '#eaf5ef', '#d0ebda', '#bfdfc7', '#bcdec3', '#97ca9f', '#7bbc82'],
            color: {
              group1: 'red',
              group2: 'green',
              group3: 'blue',
              group4: 'pink'
            }
          },
          categories: ['group1', 'group2', 'group3', 'group4'],
          textStyle: {
            color: this.textColor
          },
          formatter: (value, value2) => this.configDataService.DISPLAY_LANGUAGE.HEATMAP_CHART_NAME[value as string]
        },
        grid: {
          height: this.gridSize.height, // '75%'
          width: this.gridSize.width, // '80%'
        },
        xAxis: {
          data: heatmapData.xAxis.label,
          // name: 'XAxis',
          name: heatmapData.xAxis.axisName,
          nameTextStyle: {
            color: this.textColor
          },
          nameGap: 5
        },
        yAxis: {
          data: heatmapData.yAxis.label,
          // name: 'YAxis',
          name: heatmapData.yAxis.axisName,
          nameTextStyle: {
            color: this.textColor
          },
          nameGap: 5
        },
        series: [{
          data: heatmapData.data,
          label: {
            show: true,
            formatter: (params: any) => this.parseUnit(params.value[2], heatmapData.dataLabel.unit),
          },
        }],
        media:[
          {
            query: {
              // when container width is greater than 600
              minWidth: 482
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 14
                }
              },
              grid: {
                left: '10%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  // rotate: 0,
                }
              }
            }
          },
          {
            query: {
              // when container width is smaller than 600
              maxWidth: 481
            },
            option: {
              tooltip: {
                textStyle: {
                  fontSize: 12
                }
              },
              grid: {
                left: '20%',
              },
              xAxis: {
                axisLabel: {
                  interval: 0,
                  rotate: 90,
                }
              }
            }
          }
        ],
      };
    });
  }

}
