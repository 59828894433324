import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { getDepthData, isSelectionVisitorProfileAll } from '../helpers/util';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { VisitorProfileSelection } from '../objects/visitor-profile';
import { generateNestedData } from '../helpers/mock-data-generator';

const CHART_COLOR = '#ffd800';
const CHART_BACKGROUND_COLOR = '#917b0022';

export class VisitorNetShoppingHourTrendResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
            if (configDataService.isFeatureEnabled('reid_timespent')) {
                graphDataService.baseGraphData.addDependency(GraphDependency.BUILDING_TIMESPENT);
                graphDataService.baseGraphData.addDependency(GraphDependency.ENTRANCE_EXIT);
            }
        }
        // await configDataService.loadAppConfig();
        const buildingInstanceName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const getNetShoppingTimeChartData = (data: { [buildingName: string]: number[] }, bldName: string, defaultValue: number[] = Array.from({ length: 7 }).map(() => null)) => {
            if (!data || !data[bldName]) { return defaultValue; }
            return data[bldName];
        };
        const initialSelectedProfile = graphDataService.selectedVisitorProfile$.value;
        const initSelectedVisitorProfile: VisitorProfileSelection = graphDataService.selectedVisitorProfile$.getValue();
        const initBuildingName = graphDataService.selectedVisitorProfile$.getValue().organization === 'all' ? buildingInstanceName : initSelectedVisitorProfile.organization;
        const initIsEntrance = configDataService.isEntranceDataMode$.getValue();
        const fillData: number[] = Array.from({ length: 7 }).map(() => null);
        const initBuildingEntranceExitData =  graphDataService.averageTimeSpentChartData$.getValue();
        const initVisitorProfileTrafficByDepth = getDepthData<unknown>(initIsEntrance, initBuildingEntranceExitData, { buildingName: initBuildingName }, fillData) as number[];
        const initVisitorProfileData = isSelectionVisitorProfileAll(initialSelectedProfile) 
        ? initVisitorProfileTrafficByDepth
        : graphDataService.visitorTrafficTrendData$.getValue();        
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: initVisitorProfileData.map(val => val ? Math.round(val / (60 * 60)) : Math.round(val)),
            backgroundColor: CHART_BACKGROUND_COLOR,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST,
            label: configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR
        }]);
        const isLock$ = new BehaviorSubject(this.isLock);

        if (isMockData) {
            subscription.add(combineLatest([graphDataService.selectedVisitorProfile$, viewPeriodService.dayList]).subscribe(async () => {
                const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'VISITOR_TRAFFIC_TREND', 'count', 8);
                const chartPoints = Object.values(mockData);
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const lineChartData: GenericLineChartData[] = [{
                    points: chartPoints[0] as number[],
                    backgroundColor: CHART_BACKGROUND_COLOR,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR},${time}`),
                    label: configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR
                }];
                chartData$.next(lineChartData);
            }));
        } else {
            subscription.add(combineLatest([graphDataService.netShoppingTimeChartData$, graphDataService.netShoppingTimeProfileChartData$, graphDataService.buildingEntranceExitData$, graphDataService.buildingAvgTimespentTrendData$])
            .subscribe(([data, dataWithProfile, buildingEntranceExitData, buildingAvgTimepsentTrendData]) => {
                // const selectedProfile = graphDataService.selectedVisitorProfile$.value;
                // const buildingAvgTimepsentTrendData = graphDataService.buildingAvgTimespentTrendData$.getValue();
                if (configDataService.isFeatureEnabled('reid_timespent') && (!buildingEntranceExitData || !buildingAvgTimepsentTrendData)) {
                    return;
                }
                const selectedVisitorProfile = graphDataService.selectedVisitorProfile$.value;
                const buildingName = selectedVisitorProfile.organization === 'all' ? configDataService.MAIN_BUILDING : selectedVisitorProfile.organization;
                const isEntrance = configDataService.isEntranceDataMode$.getValue();
                const visitorProfileTrafficByDepth = getDepthData<unknown>(isEntrance, data, { buildingName }, fillData) as number[];
                const visitorProfileData = isSelectionVisitorProfileAll(selectedVisitorProfile) ? visitorProfileTrafficByDepth : dataWithProfile;

                // using re-id timespent data
                const buildingData = getDepthData<unknown>(isEntrance, buildingEntranceExitData, { buildingName }, fillData) as number[];
                const buildingAvgTimepsentTrendREIDData = getDepthData<unknown>(isEntrance, buildingAvgTimepsentTrendData, { buildingName }, fillData) as number[];
                const netShoppingTimeChartREIDData = configDataService.isFeatureEnabled('reid_timespent') ? buildingData.map((d, i) => (((buildingAvgTimepsentTrendREIDData[i] / 60) * d) / 60) * 60 * 60) : fillData;

                const chartData: number[] = configDataService.isFeatureEnabled('reid_timespent') ? netShoppingTimeChartREIDData : visitorProfileData;
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const lineChartData: GenericLineChartData[] = [{
                    points: chartData.map(val => val ? Math.round(val / (60 * 60)) : val),
                    backgroundColor: CHART_BACKGROUND_COLOR,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR},${time}`),
                    label: configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR
                }];
                chartData$.next(lineChartData);
                isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(graphDataService.selectedVisitorProfile$.value)));
            }));
        }



        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        comInstance.sizeXS = '12';
        comInstance.sizeMD = '10';
        comInstance.offsetMD = '1';
        return comInstance;
    }

}
