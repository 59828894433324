import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartPluginsOptions, ChartTooltipModel } from 'chart.js';
import { Label } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Observable, Subscription } from 'rxjs';
import { isScreenSmallerThanLG } from 'src/app/helpers/util';

@Component({
  selector: 'app-generic-custom-bar-chart',
  templateUrl: './generic-custom-bar-chart.component.html',
  styleUrls: ['./generic-custom-bar-chart.component.scss'],
})
export class GenericCustomBarChartComponent implements OnInit, OnDestroy{

  @Input() chartLabels$: Observable<Label[]>;
  @Input() isHorizontal = true;
  @Input() data$: Observable<ChartDataSets[]>;
  @Input() chartOptions$: Observable<ChartOptions>;
  @Input() isShowLegend = false;
  @Input() customToolTipFuction: (tooltipModel: ChartTooltipModel) => void;
  @Input() labelOption: 'default' | 'logo' = 'default';
  @Input() labelImages: Record<string, any>;
  @Input() labelDisplayList: { [label: string]: string };
  
  // To-Do: Convert it to Object key: img-name, value: img-src
  chartLabelPictures = {
    afterDraw: (chart) => {
      if (this.labelOption === 'logo' && Object.keys(this.labelImages).length > 0 && Object.keys(this.labelDisplayList).length > 0) {
        const ctx = chart.chart.ctx as CanvasRenderingContext2D; 
        const xAxis = chart.scales['x-axis-0'];
        const yAxis = chart.scales['y-axis-0'];
        const {top, left, width, height} = chart.chartArea;
        // To-Do: Responsive image size and co-or position (x,y)
        const labels = chart.chart.config.data.labels;
        labels.forEach((label, index) => {  
          const labelName = Object.keys(this.labelDisplayList).find(k => this.labelDisplayList[k] === label);
          const image = new Image();
          image.src = this.labelImages[labelName];
          if (image.complete) {
            const x = xAxis.getPixelForTick(index);
            const y = yAxis.getPixelForTick(index);
            if (labels.length > 8) {
              if (isScreenSmallerThanLG(window)) {
                if (labels.length > 10) {
                  ctx.drawImage(image, left - 30 , y - 10, 15, 15);
                } else {
                  ctx.drawImage(image, left - 50 , y - 15, 25, 25);
                }
              } else {
                ctx.drawImage(image, left - 50 , y - 17, 35, 35);
              }
            } else {
              if (isScreenSmallerThanLG(window)) {
                ctx.drawImage(image, left - 50 , y - 17, 35, 35);
              } else {
                ctx.drawImage(image, left - 60 , y - 25, 55, 55);
              }
            }
          }
        });
      }
    }      
  };

  private subscription = new Subscription();
  chartPlugins: ChartPluginsOptions = [ChartDataLabels, this.chartLabelPictures];
  chartDatas: ChartDataSets[] = [{ data: [null], }];
  chartOptions: ChartOptions;
  chartLabels: Label[];

  constructor() { }

  ngOnInit() {
    this.subscription.add(this.data$.subscribe(barChartDataSets => {
      if (!barChartDataSets) { return; }
      const chartData: ChartDataSets[] = [];
      barChartDataSets.forEach(barChartData => {
        chartData.push(barChartData);
      });
      this.chartDatas = chartData.length > 0 ? chartData : [{ data: [null] }];
    }));
    this.subscription.add(this.chartOptions$.subscribe(barChartOptions => {
      if (!barChartOptions) { return; }
      this.chartOptions = barChartOptions;
      if (this.customToolTipFuction) {
        this.chartOptions.tooltips.custom = this.customToolTipFuction;
        this.chartOptions.tooltips.enabled = false;
      }
    }));
    this.subscription.add(this.chartLabels$.subscribe(barChartLabels => {
      if (!barChartLabels) { return; }
      this.chartLabels = barChartLabels;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get chartType() {
    return this.isHorizontal ? 'horizontalBar' : 'bar';
  }

}
