import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { UpdateAppService } from 'src/app/services/update-app.service';

@Component({
  selector: 'app-updated-warning-header',
  templateUrl: './updated-warning-header.component.html',
  styleUrls: ['./updated-warning-header.component.scss'],
})
export class UpdatedWarningHeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private updateService: UpdateAppService,
    private platform: Platform,
  ) { }

  ngOnInit() { }

  reloadPage() {
    this.router.navigate(['/'])
    .then(() => {
      this.updateService.updateIsAvailable = false;
      window.location.reload();
    });
  }

  get isUpdateVersion() {
    return this.updateService.updateIsAvailable;
  }

  get isUpdatedFinish() {
    return this.updateService.updatingFinished();
  }

  get messageBanner() {
    if (this.updateService.updatingFinished()) {
      return 'A new version of DeepVision is available. Please refresh your page to access the latest features.';
    }
    return 'A new version of DeepVision is avaliable. Please wait while your app is being updated to the latest version, which be ready shortly.';
  }

  get isMobilePlatform() {
    return this.platform.is('hybrid');
  }

}
