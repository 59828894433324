import { Component, Input, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-custom-tooltip-popover',
  templateUrl: './custom-tooltip-popover.component.html',
  styleUrls: ['./custom-tooltip-popover.component.scss'],
})
export class CustomTooltipPopoverComponent implements OnInit {

  @Input() toolTipTitle: string;
  @Input() toolTipDetails: string[];
  @Input() classList: string[];
  @Input() classType: string;
  pictureList: any = {};

  constructor(
    private configDataService: ConfigDataService
  ) { }

  ngOnInit() {
    if (this.classList.length > 0) {
      this.classList.forEach(name => {
        this.pictureList[name] = [];
        for (let index = 0; index < 3; index++) {
          if (this.classType === 'staff' && this.configDataService.isFeatureEnabled('images', 'staff_source')) {
            this.pictureList[name].push(this.configDataService.staffImageSrc[name][index]);
          } else {
            this.pictureList[name].push(`assets/image/customer-segment/${this.classType}/${name}/${index}.jpg`);
          }
        }
      });
    }
  }

}
