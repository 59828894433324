import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class HeadcountNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        await configDataService.loadAppConfig();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const numberData$ = new BehaviorSubject('0');
        const label$ = new BehaviorSubject('0 % (0)');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });

        subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.currentHeadCountEntranceData$, graphDataService.currentHeadCountExitData$]).subscribe(
            ([isEntrance, headCountEntranceData, headCountExitData]) => {
                const headCountData = isEntrance ? headCountEntranceData : headCountExitData;
                numberData$.next(headCountData.headCount?.toLocaleString());
                label$.next(`${headCountData.diffPercent?.toFixed(1)} % (${headCountData.diff?.toLocaleString()})`);
                labelIcon$.next(headCountData.diff > 0 ? 'trending-up' : 'trending-down');
                labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: headCountData.diff >= 0 ? '#5BBF93' : '#E25144' });
            }
        ));

        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.HEADCONT;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;
        return comInstance;
    }

}
