import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { CHART_PRIMARY_COLOR_OPACITY, CHART_PRIMARY_COLOR, LINE_CHART_COLOR_LIST } from '../configs/color';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { Label } from 'ng2-charts';

export class ModeOfTransportByHourResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        await configDataService.loadAppConfig();
        const timeUTC = configDataService.VEHICLE_TIME_LIST.map(time => parseInt(time, 10) - configDataService.TIME_DIFF_FROM_UTC);
        const initLabelTotalText = configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total.charAt(0).toUpperCase() + configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total.slice(1);
        const initialLineChartData: GenericLineChartData[] = [{
            points: timeUTC.map(time => 0),
            backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
            color: CHART_PRIMARY_COLOR,
            toolTipLabel: configDataService.VEHICLE_TIME_LIST.map(time => `${initLabelTotalText},${time}`),
            label: initLabelTotalText,
        }];
        
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>(initialLineChartData);
        const timeLabel = new BehaviorSubject<Label[]>(configDataService.VEHICLE_TIME_LIST);

        subscription.add(graphDataService.vehicleParkingModeofTransportbyHourTrend$.subscribe((tranSportModeData) => {
            if (!tranSportModeData) {
                return;
            }
            const labelTotalText = configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total.charAt(0).toUpperCase() + configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total.slice(1);
            const lineChartData: GenericLineChartData[] = [{
                points: timeUTC.map(time => tranSportModeData.total[time] ? tranSportModeData.total[time] : 0),
                backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
                color: CHART_PRIMARY_COLOR,
                toolTipLabel: configDataService.VEHICLE_TIME_LIST.map(time => `${labelTotalText},${time}`), // configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total,
                label: labelTotalText
            }]; 
            let colIndex = 0;
            for (const [vehicleType, chartData] of Object.entries(tranSportModeData)) {
                if (vehicleType === 'total') { continue; }
                const labelText = configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS[vehicleType] ? configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS[vehicleType] : vehicleType.charAt(0).toUpperCase() + vehicleType.slice(1);
                lineChartData.push({
                    points: timeUTC.map(time => chartData[time] ? chartData[time] : 0),
                    color: LINE_CHART_COLOR_LIST[colIndex],
                    toolTipLabel: configDataService.VEHICLE_TIME_LIST.map(time => `${labelText},${time}`), // labelText,
                    label: labelText,
                    isDash: true
                });
                colIndex = (colIndex + 1) % LINE_CHART_COLOR_LIST.length;
            }
            chartData$.next(lineChartData);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        // comInstance.enableTBD = true;
        comInstance.data$ = chartData$;
        comInstance.label$ = timeLabel;
        // comInstance.selectedLine$ = selectedGraph$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.MODE_TRANSPORTATION_BY_HOUR;
        comInstance.isLock = this.isLock;
        return comInstance;
    }

}
