import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGaugeChartWrapperComponent } from '../shared-components/dynamic/dynamic-gauge-chart-wrapper/dynamic-gauge-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';

export class CurrentCustomerGaugeResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency); // ENTRANCE_EXIT_HOUR, ENTRANCE_EXIT_FLOOR_HOUR, ENTRANCE_EXIT_ZONE_HOUR
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const zoneName = graphDataService.baseGraphData.selectedDirectory$.value?.zone || (getAdditionalInput(additionalInput, 'zone')) as string;
        const initialfloorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value  || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';
        const getCurrentHeadcount = (
            data: { [buildingName: string]: number | { [floorName: string]: number } | { [floorName: string]: { [zoneName: string]: number } } },
            floorName?: string,
            depthZoneName?: string,
        ) => accessDepthData<number>(data, buildingName, floorName, depthZoneName, 0);
        const initialConfig = initialIsAll ? configDataService.GRAPH_CONFIG.CURRENT_CUSTOMER[buildingName] || { min: 0, max: 1000 } : !zoneName ? configDataService.GRAPH_CONFIG.CURRENT_CUSTOMER[initialfloorName] : configDataService.GRAPH_CONFIG.CURRENT_CUSTOMER[zoneName];
        const currentData$ = new BehaviorSubject<{ current: number; min: number; max: number }>({
            current: initialIsAll ? getCurrentHeadcount(graphDataService.currentAccumulateHeadcountData$.value)
                : (!zoneName ? getCurrentHeadcount(graphDataService.currentFloorHeadcountData$.value, initialfloorName) : getCurrentHeadcount(graphDataService.currentZoneHeadcountData$.value, initialfloorName, zoneName)),
            min: initialConfig.min,
            max: initialConfig.max
        });

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicGaugeChartWrapperComponent;
        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedDirectory$, 
            combineLatest([graphDataService.currentAccumulateHeadcountData$, graphDataService.currentFloorHeadcountData$, graphDataService.currentZoneHeadcountData$]),
            combineLatest([graphDataService.currentBuildingEntranceByHour$, graphDataService.currentFloorEntranceByHour$, graphDataService.currentZoneEntranceByHour$])
        ]).subscribe(([selectedFloorName, selectedDirectory, [currentAccumulateHeadcountData, floorHeadcountData, zoneHeadCountData], [buildingEntranceByHour, floorEntranceByHour, zoneEntranceByHour]]) => {
                const floorName = selectedFloorName || selectedDirectory?.floor || 'ALL';
                const isAll = floorName === 'ALL';
                const nextZoneName = selectedDirectory?.zone || zoneName;
                const graph_config = isAll
                ? configDataService?.GRAPH_CONFIG?.CURRENT_CUSTOMER[buildingName] || { min: 0, max: 1000 } 
                : !zoneName 
                ? configDataService?.GRAPH_CONFIG?.CURRENT_CUSTOMER[floorName] || { min: 0, max: 1000 }
                : configDataService?.GRAPH_CONFIG?.CURRENT_CUSTOMER[zoneName] || { min: 0, max: 1000 };
                if (configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance')) {
                    currentData$.next({
                        current: isAll ? getCurrentHeadcount(buildingEntranceByHour) : (!nextZoneName ? getCurrentHeadcount(floorEntranceByHour, floorName) : getCurrentHeadcount(zoneEntranceByHour, floorName, nextZoneName)),
                        min: graph_config.min,
                        max: graph_config.max
                    });
                } else {
                    currentData$.next({
                        current: isAll ? getCurrentHeadcount(currentAccumulateHeadcountData) : (!nextZoneName ? getCurrentHeadcount(floorHeadcountData, floorName) : getCurrentHeadcount(zoneHeadCountData, floorName, nextZoneName)),
                        min: graph_config.min,
                        max: graph_config.max
                    });
                }
                comInstance.title = configDataService.DISPLAY_LANGUAGE.CURRENT_CUSTOMER.default || (!nextZoneName ? configDataService.DISPLAY_LANGUAGE.CURRENT_CUSTOMER[floorName] : configDataService.DISPLAY_LANGUAGE.CURRENT_CUSTOMER[nextZoneName]);
            }));

        comInstance.title = configDataService.DISPLAY_LANGUAGE.CURRENT_CUSTOMER.default || (!zoneName ? configDataService.DISPLAY_LANGUAGE.CURRENT_CUSTOMER[initialfloorName] : configDataService.DISPLAY_LANGUAGE.CURRENT_CUSTOMER[zoneName]);
        comInstance.isLock = this.isLock;
        comInstance.data$ = currentData$;
        comInstance.isShow$ = viewPeriodService.isLiveMode$;
        return comInstance;
    }

}
