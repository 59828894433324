<div class="vehicle-purchasing-power-tooltip-container">
  <h3>Implied Car Purchasing Power Definition</h3>
  <table>
    <tr>
      <th></th>
      <th>Economy</th>
      <th>Premium</th>
      <th>Luxury</th>
    </tr>
    <tr>
      <td *ngFor="let text of rowPrice">{{ text }}</td>
    </tr>
    <tr>
      <td *ngFor="let text of rowDes">{{ text }}</td>
    </tr>
  </table>
</div>
