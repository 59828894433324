import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { isScreenSmallerThanLG } from 'src/app/helpers/util';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-organization-selector-popover',
  templateUrl: './organization-selector-popover.component.html',
  styleUrls: ['./organization-selector-popover.component.scss'],
})
export class OrganizationSelectorPopoverComponent implements OnInit {

  private loadingList = {};

  constructor(
    private configDataService: ConfigDataService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private authenicationService: AuthenticationService,
  ) { }

  ngOnInit() {
    Object.keys(this.configDataService.logoImagedListSrc).forEach(key => {
      this.loadingList[key] = false;
    });
  }

  async changeOrganization(org: string) {
    this.presentLogoutLoading()
    .then(() => this.configDataService.updateUserOrganizationSetting(org))
    .then(() => this.authenicationService.getIdToken(true))
    .then(() => this.loadingController.dismiss())
    .then(() => {
      const alertSuccessObject = {
        header: 'Switching Successfully',
        message: 'You have successfully switching organization',
      };
      return this.presentAlert(alertSuccessObject);
    })
    .then(() => Promise.resolve(setTimeout(() => {
      this.alertController.dismiss();
    }, 5000)))
    .then(() => document.location.reload())
    .catch(async err => {
      const alertErrorObject = {
        header: 'Switch Organization Failed',
        message: err.error.message,
        buttons: ['OK'],
      };
      return this.presentAlert(alertErrorObject);
    });
  }

  async presentAlert(alertObject) {
    const alert = await this.alertController.create(alertObject);
    return alert.present();
  }

  async presentLogoutLoading() {
    const loading = await this.loadingController.create({
      message: 'Processing ...',
      cssClass: 'loading-popup',
      duration: 5000,
    });
    return await loading.present();
  }

  showCheckMark(org: string) {
    return this.configDataService.currentOrganization === org;
  }

  onImageLoad(key: string) {
    this.loadingList[key] = true;
  }

  imageIsCompleted(key: string) {
    return this.loadingList[key];
  }

  get logoImageList() {
    return this.configDataService.logoImagedListSrc;
  }

  get mobileScreen() {
    return isScreenSmallerThanLG(window);
  }
  

}
