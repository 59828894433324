import { Injectable } from '@angular/core';

import { Note } from '../objects/note';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKEND_URL } from '../configs/api';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  NoteList: Note[] = [];
  NoteList$: BehaviorSubject<Note[]> = new BehaviorSubject([]);

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) { }

  addNote(newNote: Note): Promise<any> {

    const createEventPromise = new Promise((resolve, reject) => {
      this.authenticationService.getIdToken()
      .then((idToken) => {
        const requestURL = `${BACKEND_URL}/api/user/note/create/?idToken=${idToken}`;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
          })
        };
        const noteBody = {
          note: newNote
        };
        return this.http.post(requestURL, noteBody, httpOptions).toPromise();
      })
      .then((note: Note) => {
        this.NoteList.push(note);
        this.NoteList$.next(this.NoteList);
        resolve({success: true});
      })
      .catch((err) => {
        reject(err);
      });
    });

    return createEventPromise;
  }

  fetchNoteList(): void {
    this.authenticationService.getIdToken()
    .then((idToken) => {
      const apiURL = `${BACKEND_URL}/api/user/note/list?idToken=${idToken}`;
      this.http.get(apiURL)
      .subscribe((noteList: Note[]) => {
        this.NoteList = noteList;
        this.NoteList$.next(this.NoteList);
      });
    });
  }

  getNoteByDate(startDate: string, endDate: string): Observable<any[]> {
    const searchStartDate = new Date(startDate).setHours(0, 0, 0, 0);
      const searchEndDate = new Date(endDate).setHours(0, 0, 0, 0);

    const searchNoteResult = this.NoteList.filter((note) => {
      const noteDate = new Date(note.date).setHours(0, 0, 0, 0);
      return noteDate >= searchStartDate && noteDate <= searchEndDate;
    });

    return of(searchNoteResult);
  }
}
