import { Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { Observable } from 'rxjs';
import { SankeyRawLinks } from 'src/app/objects/fetchObejct';
import { GenericSankeyChartData } from 'src/app/objects/chart';

// interface SNodeExtra {
//   nodeId: number;
//   name: string;
// }

// interface SLinkExtra {
//   source: number;
//   target: number;
//   value: number;
//   uom?: string;
// }

// interface SExtra {
//     sNodes: SNodeExtra[];
//     sLinks: SLinkExtra[];
// }

// interface SankeyData { 
//   nodes: Array<{ id: string; name: string; depth: number; value?: number }>; 
//   links: Array<{ name: string; source: string; target: string; value: number }>; 
// }

@Component({
  selector: 'app-dynamic-sankey-wrapper',
  templateUrl: './dynamic-sankey-wrapper.component.html',
  styleUrls: ['./dynamic-sankey-wrapper.component.scss'],
})
export class DynamicSankeyWrapperComponent implements OnInit {

  @Input() title: string;
  @Input() isShow: Observable<boolean> | boolean;
  @Input() filterFirstLevel: boolean;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isLock = false;
  @Input() data$: Observable<GenericSankeyChartData>;
  @Input() sankeyDisplayMode: 'LEGACY' | 'DYNAMIC' = 'LEGACY';
  @Input() sankeyRawData$: Observable<SankeyRawLinks[]>;

  constructor( 
    public configDataService: ConfigDataService,
    private ref: ChangeDetectorRef
  ) { 
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {}

  get isShowGraph() {
    let showGraph = false;
    if (this.isShow instanceof Observable) {
      this.isShow.subscribe(show => {
        showGraph = show;
      });
    } else {
      showGraph = this.isShow;
    }

    return showGraph;
  }

  // overAllStyle(title: string) {
  //   if (title === this.configDataService.DISPLAY_LANGUAGE.HEADCOUNT_MALL_FLOW && !isScreenSmallerThanMD(window)) {
  //     return {
  //       width: '500px',
  //       height: '500px',
  //       'margin-left': '10rem',
  //     };
  //   }
  // }

}
