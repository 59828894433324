/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-underscore-dangle */
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Component } from '@angular/core';
import {
  Platform,
  AlertController,
  ToastController,
  ActionSheetController,
  PopoverController,
  ModalController,
  MenuController,
  NavController,
  LoadingController,
} from '@ionic/angular';

import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { AuthenticationService } from './services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { FcmService } from './services/fcm.service';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { timer } from 'rxjs';

import { ConfigDataService } from './services/config-data.service';
import { LoadingService } from './services/loading.service';
import { GlobalUiService } from './services/global-ui.service';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { APP_VERSION } from './configs/api';
import { UpdateAppService } from './services/update-app.service';
import { OrganizationSelectorPopoverComponent } from './shared-components/general/organization-selector-popover/organization-selector-popover.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  public navMenu: { title?: string; url?: string; icon?: string; type?: 'group' | 'normal'; state?: 'open' | 'collapse'; child?: { title: string; url: string }[] }[] = [];

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  organization = null;
  isShowSelectedOrg = false;
  isOpenLocalAppStore = false;

  // @ViewChild(IonHeader) header: IonHeader;

  constructor(
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private fcmService: FcmService,
    private actionSheetController: ActionSheetController,
    private popoverController: PopoverController,
    public modalController: ModalController,
    private menu: MenuController,
    private router: Router,
    private navCtrl: NavController,
    private firebase: FirebaseX,
    private configDataService: ConfigDataService,
    private loadingService: LoadingService,
    private loadingController: LoadingController,
    private globalUiService: GlobalUiService,
    private analytics: AngularFireAnalytics,
    private updateService: UpdateAppService,
  ) {
    this.initializeApp();
    this.backButtonEvent();
    // ref.detach();
    // setInterval(() => {
    //   this.ref.detectChanges();
    // }, 300);
  }

  initializeApp() {
    this.updateService.logUpdate();
    this.updateService.checkForUpdate();
    this.updateService.forceUpdate();
    // Chart.defaults.global.defaultFontColor = CHART_AXIS_TEXT_COLOR;
    this.initChartJs();
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.setStyle({ style: Style.Dark }); // light text
      }
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        SplashScreen.hide().catch();
      }
      timer(3500).subscribe(() => this.loadingService.disableSplash());
      if (this.platform.is('hybrid')) {
        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
            this.router.navigate([`/${notification.notification.data.landing_page}`]);
          }
        );
        this.fcmService.listenToDeviceTokenChanged();
      }
      this.loadingService.subscribeNetworkConnection();
      this.authenticationService.userProfile$.subscribe(async userProfile => {
        if (!userProfile) { return; }
        this.organization = userProfile.organization;
        this.initializeWebGoogleAnalytics(userProfile);
      });
      this.configDataService.isConfigReady$.subscribe(isConfigReady => {
        if (isConfigReady) {
          this.navMenu = this.configDataService.NAVIGATION_MENU;
          if (this.platform.is('hybrid')) {
            if (this.configDataService.forceCheckUpdate) {
              this.checkAppIsLatest();
            } else {
              // set checking app version every 1 hour
              setInterval(() => this.checkAppIsLatest(), 60 * 60 * 1000);
            }
          }
        }
      });
    });
  }

  ionViewDidEnter(): void {
    this.menu.enable(false);
  }

  ionViewDidLeave(): void {
    this.menu.enable(true);
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(async () => {
      this.navCtrl.back();
      try {
        const element = await this.actionSheetController.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) { }

      // close popover
      try {
        const element = await this.popoverController.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) { }

      // close modal
      try {
        const element = await this.modalController.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) { }

      // close side menua
      try {
        const element = await this.menu.getOpen();
        if (element !== null) {
          this.menu.close();
          return;
        }
      } catch (error) { }
    });
  }

  initChartJs() {
    Chart.pluginService.register(ChartAnnotation);
    Chart.pluginService.unregister(ChartDataLabels);
    const originalLineController = Chart.controllers.line;
    Chart.controllers.line = Chart.controllers.line.extend({
      draw(ease: any) {
        let startIndex = 0;
        const meta = this.getMeta();
        const points = meta.data || [];
        const colors = this.getDataset().colors;
        const borderDashs = this.getDataset().borderDashs;
        const area = this.chart.chartArea;
        const originalDatasets = meta.dataset._children.filter((data: { _view: { y: number } }) => !isNaN(data._view.y));

        // eslint-disable-next-line @typescript-eslint/no-shadow
        function _setColor(newColor: any, meta: { dataset: { _view: { borderColor: any } } }) {
          meta.dataset._view.borderColor = newColor;
        }
        // eslint-disable-next-line @typescript-eslint/no-shadow
        function _setBorderDash(newBorderDash: number[], meta: { dataset: { _view: { borderDash: number[] } } }) {
          meta.dataset._view.borderDash = newBorderDash;
        }

        if (!colors && !borderDashs) {
          originalLineController.prototype.draw.call(this, ease);
          return;
        }

        for (let i = 2; i <= (colors || borderDashs).length; i++) {
          let isChange = false;
          if (colors && colors[i - 1] !== colors[i]) {
            _setColor(colors[i - 1], meta);
            isChange = true;
          }
          if (borderDashs && borderDashs[i - 1] !== borderDashs[i]) {
            _setBorderDash(borderDashs[i - 1], meta);
            isChange = true;
          }
          if (isChange) {
            meta.dataset._children = originalDatasets.slice(startIndex, i);
            meta.dataset.draw();
            startIndex = i - 1;
          }
        }
        meta.dataset._children = originalDatasets.slice(startIndex);
        meta.dataset.draw();
        meta.dataset._children = originalDatasets;

        points.forEach((point: { draw: (arg0: any) => void }) => {
          point.draw(area);
        });
      }
    });

    // custom tooltip positioners function
    Chart.Tooltip.positioners.customFixedLineChart = (elements, eventPosition) => {
      let offsetX = 0;
      // adjust the offset left or right depending on the event position
      if (elements[0]._chart.width / 2 > eventPosition.x) {
        offsetX = 120;
      } else {
        offsetX = -100;
      }
      return {
        x: eventPosition.x + offsetX,
        y: 0,
      };
    };

  }

  initializeWebGoogleAnalytics(userProfile) {
    const platfromName = this.platform.is('android') ? 'android' : this.platform.is('ios') ? 'ios' : 'web';
    this.analytics.setUserId(userProfile.uid);
    this.analytics.setUserProperties({ organization: userProfile.organization, position: userProfile.position, uid: userProfile.uid, appVersion: APP_VERSION, platform: platfromName });
    this.analytics.logEvent('login', { userId: userProfile.uid, organization: userProfile.organization, postiton: userProfile.position, appVersion: APP_VERSION, platform: platfromName });
  }

  initializeMobileGoogleAnalytics(userProfile) {
    this.firebase.setUserProperty('organization', userProfile.organization);
    this.firebase.setUserProperty('position', userProfile.position);
    this.firebase.setUserProperty('uid', userProfile.uid);
    this.firebase.setUserProperty('appVersion', APP_VERSION);
    this.firebase.setUserId(userProfile.uid);
    this.firebase.logEvent('login', { userId: userProfile.uid, organization: userProfile.organization, postiton: userProfile.position, appVersion: APP_VERSION });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.firebase.setScreenName(event.urlAfterRedirects);
        this.firebase.logEvent('screen_view', {
          page: event.urlAfterRedirects,
        });
      }
    });
  }

  handleMenuClick(menu: { title?: string; url?: string; icon?: string; type?: 'group' | 'normal'; state?: 'open' | 'collapse'; child?: { title: string; url: string }[] }) {
    menu.state = menu.state === 'open' ? 'collapse' : 'open';
  }

  async showNotification(message) {
    const toast = await this.toastCtrl.create({
      message: message.body_message,
      duration: 4000,
      position: 'top',
    });
    return await toast.present();
  }

  get isMobileDevice(): boolean {
    return this.platform.is('ios') || this.platform.is('android');
  }

  async onClickLogOut() {
    return this.presentLogoutLoading()
      .then(() => {
        this.authenticationService.logout()
          .then(_success => {
            this.loadingController.dismiss();
          })
          .then(() => {
            const alertLogoutSuccessObject = {
              header: 'Logout Successfully',
              message: 'You have successfully logged out.',
              buttons: ['OK'],
            };
            return this.showLogoutAlert(alertLogoutSuccessObject);
          })
          .then(() => setTimeout(() => {
            this.alertController.dismiss();
          }, 2000))
          .catch(err => {
            const alertLogoutErrorObject = {
              header: 'Logout Failed',
              message: err.error.message,
              buttons: ['OK'],
            };
            this.loadingController.dismiss().then(() => this.showLogoutAlert(alertLogoutErrorObject));
          });
      });
  }

  async presentLogoutLoading() {
    const loading = await this.loadingController.create({
      message: 'Processing ...',
      cssClass: 'loading-popup',
      duration: 5000,
    });
    return await loading.present();
  }

  async showLogoutAlert(alertObject) {
    const alert = await this.alertController.create(alertObject);
    alert.onDidDismiss().then(() => {
      // TODO: find a effective solution for delete cache data when logout
      // .then(() => this.router.navigateByUrl('/login'))
      window.location.assign('/');
    });
    return alert.present();
  }

  async checkAppIsLatest() {
    if (this.configDataService.showUpdateAlert) {
      this.presentUpdateAlert();
    }
  }

  async presentUpdateAlert() {
    const alert = await this.alertController.create({
      cssClass: 'update-alert',
      header: 'A New Version of DeepVision is Avaliable',
      message: 'Should be update to get new features and improvements for best performance',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Update',
          handler: () => {
            this.isOpenLocalAppStore = true;
          }
        }
      ]
    });
    alert.onWillDismiss().then(() => {
      this.configDataService.seenCheckAppisLatest().then(() => {
        this.configDataService.showUpdateAlert = false;
        if (this.isOpenLocalAppStore) {
          this.openLocalAppStore();
        }
      });
    });
    await alert.present();
  }

  async openLocalAppStore() {
    this.isOpenLocalAppStore = !this.isOpenLocalAppStore;
    if (this.platform.is('android')) {
      window.open('https://play.google.com/store/apps/details?id=com.oxygenai.deepvisionretail');
    } else if (this.platform.is('ios')) {
      window.open('https://apps.apple.com/th/app/deepvision-retail/id1456346233');
    }
  }

  isOrganization(org: string) {
    if (this.configDataService.currentOrganization) {
      return this.configDataService.currentOrganization === org;
    }
    return false;
  }

  async presentOrgSelectorPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: OrganizationSelectorPopoverComponent,
      cssClass: 'selected-org-popover',
      event: ev,
      translucent: true
    });
    if ((this.configDataService.ORGANIZATION_LIST || this.authenticationService?.USER_ROLES?.roles?.can_view_org_data) && this.configDataService.FEATURE_FLAGS?.setting?.switch_org) {
      await popover.present();
    }
  }

  get logoImage() {
    return this.configDataService.logoImageSrc;
  }

  get isLoading() {
    if (this.loadingService.showSplash) {
      return !this.loadingService.showSplash;
    }
    if (this.authenticationService?.isUserLoggedIn) {
      return this.loadingService.isNetworkConnected && !this.configDataService.isConfigReady;
    } else {
      return false;
    }
  }

  get showSplash() {
    return this.loadingService.showSplash;
  }

  get canExportRawData() {
    if (this.authenticationService?.USER_ROLES) {
      if (this.authenticationService.USER_ROLES.roles?.can_export_data_org && this.authenticationService.USER_ROLES.roles?.can_export_data_org.length > 0) {
        return this.authenticationService.USER_ROLES.roles?.can_export_data && this.configDataService.FEATURE_FLAGS?.export_data
          && this.authenticationService.USER_ROLES.roles?.can_export_data_org.includes(this.configDataService.currentOrganization);
      }
      return this.authenticationService.USER_ROLES.roles?.can_export_data && this.configDataService.FEATURE_FLAGS?.export_data;
    }
    return false;
  }

  get canManageUser() {
    return this.authenticationService?.adminSession;
  }

  get shouldDisplayLeftMenu() {
    return this.globalUiService.currentPage !== 'Traffic' ? 'xl' : false;
  }

  get errorFetchingMessage() {
    return this.configDataService.fetchAppConfigErrorMessage;
  }

  get canSwitchOrg() {
    if (this.configDataService?.FEATURE_FLAGS) {
      return this.configDataService?.FEATURE_FLAGS?.setting?.switch_org;
    }
    return false;
  }

  get canShowSwitchOrg() {
    if (this.configDataService?.ORGANIZATION_LIST || (this.authenticationService?.USER_ROLES?.roles?.can_view_org_data && this.authenticationService?.USER_ROLES?.roles?.can_view_org_data.length > 0)) {
      const orgList = this.configDataService?.ORGANIZATION_LIST || this.authenticationService?.USER_ROLES?.roles?.can_view_org_data;
      return orgList.length > 1 && this.configDataService?.FEATURE_FLAGS?.setting?.switch_org;
    }
    return false;
  }

  get shouldDisableMenu(): boolean {
    if (this.globalUiService.currentPage === 'Traffic' && this.configDataService.isFeatureEnabled('traffic_page', 'multiple_view')) {
      return true;
    }
    return !this.authenticationService?.isUserLoggedIn;
  }


}
