<ion-row *ngIf="configDataService.isConfigReady" [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }">
  <ion-col [sizeXs]="sizeXS" [sizeMd]="sizeMD" [offsetMd]="offsetMD">
    <ion-card appLockIcon [isLock]="isLock" appDataTbd [lockOpacity]="lockOpacity" [isEnableTBD]="enableTBD">
      <ion-card-header *ngIf="shouldShowSelector; else normalTitle">
        <ion-grid class="ion-no-padding">
          <div class="grid-container" style="width: 100%; display: grid; grid-template-columns: auto 1fr; align-items: center">
            <div class="grid-title" style="display: flex; justify-content: start; align-items: center">
              <ion-card-title id="col-title-name">
                {{ title }}
                <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
              </ion-card-title>
            </div>
            <div id="col-selector" style="display: flex; justify-content: flex-end; align-items: center">
              <ion-select
                [multiple]="multipleSelector"
                [value]="selectedName"
                style="font-size: 16px; color: var(--ion-color-primary); text-align: justify"
                [interface]="selectInterface"
                (ionChange)="changeSelectedGraph($event)"
                #ionGraphSelector
                mode="md"
              >
                <ion-select-option *ngFor="let lineName of selectorName; index as i" [value]="lineName">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] }}</ion-select-option>
              </ion-select>
            </div>
          </div>
          <ion-row *ngIf="isShowAveragePerDayButton">
            <ion-col>
              <ion-button size="6" (click)="toggleAveragePerDayFilter()" [color]="showAverage ? 'primary' : 'secondary'"> Average Per Day </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-header>
      <ng-template #normalTitle>
        <ion-card-header>
          <ion-card-title>
            {{ title }}
          </ion-card-title>
          <ion-row *ngIf="isShowAveragePerDayButton">
            <ion-col>
              <ion-button size="6" (click)="toggleAveragePerDayFilter()" [color]="showAverage ? 'primary' : 'secondary'">
                Average Per Day
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-header>
      </ng-template>
      <ion-card-content appLockBlur [isLock]="isLock" style="margin-top: 0px; padding-top: 5px">
        <ion-row
          *ngIf="isShowCustomLegend && labelHeader$"
          [ngStyle]="isShowCustomLegend && labelHeader$ ? { display: 'flex', 'justify-content': 'center', 'flex-flow': 'row wrap' } : { display: 'none' }"
        >
          <div class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
            <label *ngIf="isShowCustomLegend && labelHeader$" class="label-header">
              {{ labelHeader$ | async }}
            </label>
          </div>
        </ion-row>
        <ion-row *ngIf="isShowCustomLegend" [ngStyle]="isShowCustomLegend ? { display: 'flex', 'justify-content': 'center', 'flex-flow': 'row wrap' } : { display: 'none' }">
          <div class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
            <label *ngIf="isShowCustomLegend" class="card-content-text-large">
              {{ labelCustomLegend$ | async }}
            </label>
          </div>
        </ion-row>
        <ion-row *ngIf="isShowCustomLegend" [ngStyle]="isShowCustomLegend ? { display: 'flex', 'justify-content': 'center', 'flex-flow': 'row wrap' } : { display: 'none' }">
          <div class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
            <label *ngIf="isShowCustomLegend" class="label-number" [ngStyle]="labelStyle">
              <ion-icon [name]="labelIcon" class="icon-vertical-center"></ion-icon>
              {{ isShowLegendOnPercent ? legendNumber(numberData$ | async) : (numberData$ | async | numberSuffix : '1.3') || 0 }}
            </label>
          </div>
        </ion-row>
        <app-generic-line-chart [chartData$]="data$" [chartLabel$]="label$" [isShowLegend]="isShowLegend"></app-generic-line-chart>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
