import { GraphComponent, GraphComponentInstance } from './GraphResolver';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { GoalDataService } from '../services/goal-data.service';
import { AuthenticationService } from '../services/authentication.service';
import { EventDataService } from '../services/event-data.service';

export abstract class GraphResolverBase {
    public readonly component: GraphComponent;
    public readonly name: string;
    public readonly dataDependency: GraphDependency | GraphDependency[];
    public state: 'ENABLE' | 'DISABLE' | 'LOCK' | 'LOCK_COND' = 'DISABLE';

    constructor(component: GraphComponent, name: string, dataDependency: GraphDependency | GraphDependency[]) {
        this.component = component;
        this.name = name;
        this.dataDependency = dataDependency;
    }

    public abstract createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService | EventDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService?: AuthenticationService,
        goalDataService?: GoalDataService,
    ): Promise<GraphComponentInstance>;

    get isLock() {
        return this.state === 'LOCK';
    }
}
