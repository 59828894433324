import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { Label } from 'ng2-charts';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
const CHART_COLOR = '#6BC26F';

export class MallFootTrafficResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
    
        const getTrafficData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [zoneName: string]: number[] } } | { [floorName: string]: { [zoneName: string]: { [storeName: string]: number[] } } } },
            floorName?: string,
            zoneName?: string,
            storeName?: string,
        ) => (data?.[buildingName]?.[floorName]?.[zoneName]?.[storeName] || Array<number>()) as number[];

        const initialEntranceData = getTrafficData(graphDataService.storeEntranceByHour$.value);
        const chartLabel$ = new BehaviorSubject<Label[]>(configDataService.TIME_LIST);
        const defaultFillData = Array.from({ length: configDataService.TIME_LIST.length }).map(() => null);

        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: graphDataService.entranceByHour$.value,
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            toolTipLabel: configDataService.TIME_LIST.map(time => `At: ${time}`),
            // toolTipLabelUnit: 'min'
        }]);

        subscription.add(graphDataService.entranceByHour$.subscribe((dataPoints) => {
            const lineChartData: GenericLineChartData[] = [{
                points: dataPoints,
                backgroundColor: `${CHART_COLOR}22`,
                color: CHART_COLOR,
                toolTipLabel: configDataService.TIME_LIST.map(time => `At: ${time}`),
            }];
            chartData$.next(lineChartData);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.data$ = chartData$;
        comInstance.label$ = chartLabel$;
        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.FOOT_TRAFFIC;
        return comInstance;
    }

}
