import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';

import { CHART_PRIMARY_COLOR, CHART_PRIMARY_COLOR_OPACITY, CHART_LIVE_COLOR, CHART_PREDICT_COLOR } from '../../../configs/color';

import { Observable, combineLatest, Subscription } from 'rxjs';
import { GenericLineChartData } from 'src/app/objects/chart';
import { hoverTooltipLineChartOptions } from 'src/app/helpers/chartJsHelper';
import { Label } from 'ng2-charts';
import { GlobalUiService } from 'src/app/services/global-ui.service';

@Component({
  selector: 'app-generic-line-chart',
  templateUrl: './generic-line-chart.component.html',
  styleUrls: ['./generic-line-chart.component.scss'],
})
export class GenericLineChartComponent implements OnInit, OnDestroy {
  private static readonly LINE_CHART_POINT_RADIUS = 0;
  private static readonly LINE_CHART_POINT_HIT_RADIUS = 40;
  private static readonly LINE_CHART_DASH_LENGTH = 5;
  private static readonly LINE_CHART_DASH_SPACE = 3;
  private static readonly LINE_CHART_POINT_HOVER_COLOR = 'rgba(148,159,177,0.8)';
  @Input() chartData$: Observable<GenericLineChartData[]>;
  @Input() chartLabel$: Observable<Label[]>;
  @Input() isShowLegend = false;
  // @ContentChild(BaseChartDirective) chartElement;

  //#region chartSetting
  readonly lineChartOptions: ChartOptions = hoverTooltipLineChartOptions;
  //#endregion

  lineChartData: ChartDataSets[]; // dataset
  lineChartLabels: Label[]; // x-axis
  subscription = new Subscription();
  // private chart: Chart;

  constructor(
    private globalUiService: GlobalUiService,
  ) { }

  ngOnInit() {
    this.subscription.add(combineLatest([this.chartData$, this.chartLabel$]).subscribe(([chartData, chartLabel]) => {
      // fix missing data
      if (!chartData || chartData.length <= 0) {
        chartData = [{ points: Array.from({ length: chartLabel.length }).fill(null) as number[] }];
      }
      this.lineChartLabels = chartLabel;
      const shadowDatasets: ChartDataSets[] = [];
      this.lineChartData = chartData.map((data) => {
        const dataSet: ChartDataSets = {
          cubicInterpolationMode: 'monotone',
          data: data.prediction || chartLabel.length === 8 ? data.points.map((d, i) => (i === data.points.length - 1) ? null : d) : data.points,
          // data: data.points,
          fill: false,
          label: data.label,
          borderColor: data.color || CHART_PRIMARY_COLOR,
          pointBorderColor: 'rgba(0, 0, 0, 1)',
          pointHoverBackgroundColor: '#fff',
          pointBackgroundColor: data.color || CHART_PRIMARY_COLOR,
          pointHoverBorderColor: GenericLineChartComponent.LINE_CHART_POINT_HOVER_COLOR,
          pointRadius: GenericLineChartComponent.LINE_CHART_POINT_RADIUS,
          pointHitRadius: GenericLineChartComponent.LINE_CHART_POINT_HIT_RADIUS,
        };
        let color: string[] = Array.from({ length: data.points.length }).map(() => data.color || CHART_PRIMARY_COLOR);
        if (data.isLivePeriod) {
          color = data.isLivePeriod.map((isLive, idx) => isLive ? CHART_LIVE_COLOR : color[idx]);
        }
        if (data.isDash) {
          dataSet.borderDash = [GenericLineChartComponent.LINE_CHART_DASH_LENGTH, GenericLineChartComponent.LINE_CHART_DASH_SPACE];
        }
        if (data.backgroundColor) {
          dataSet.backgroundColor = data.backgroundColor || CHART_PRIMARY_COLOR_OPACITY;
          dataSet.fill = true;
        }
        if (data.toolTipLabel) {
          (dataSet as any).toolTipLabel = data.toolTipLabel;
        } else {
          (dataSet as any).toolTipLabel = chartLabel;
        }
        if (data.toolTipLabelUnit) {
          (dataSet as any).toolTipLabelUnit = data.toolTipLabelUnit;
        }
        (dataSet as any).isLivePeriod = data.isLivePeriod;
        (dataSet as any).isShadow = false;
        (dataSet as any).colors = color;
        if (data.prediction) {
          const predDataset = {
            ...dataSet,
            fill: false,
            label: data.label,
            borderColor: data.color,
            isShadow: true,
            borderDashs: Array.from({ length: dataSet.data.length - 1 }).map(() => [GenericLineChartComponent.LINE_CHART_DASH_LENGTH, GenericLineChartComponent.LINE_CHART_DASH_SPACE])
          };
          // predDataset.borderDashs[predDataset.borderDashs.length - 2] = [GenericLineChartComponent.LINE_CHART_DASH_LENGTH, GenericLineChartComponent.LINE_CHART_DASH_SPACE];
          // (predDataset as any).colors = [...((predDataset as any).colors.slice(0, (predDataset as any).colors.length - 1)), CHART_PREDICT_COLOR];
          (predDataset as any).colors = Array.from({ length: dataSet.data.length }).map(() => data.color);
          // predDataset.data = [...(predDataset.data.slice(0, predDataset.data.length - 1) as number[]), data.prediction];
          predDataset.data =  [...Array.from({ length: dataSet.data.length - data.prediction.length - 1 }).map(() => Number.NaN), (predDataset.data[ predDataset.data.length - data.prediction.length - 1] as number), ...data.prediction];
          if ((predDataset as any).toolTipLabel) {
            (predDataset as any).toolTipLabel = (predDataset as any).toolTipLabel.map((label: string) => {
              const splitTxt = label.split(',');
              // const concatTxt = `${splitTxt[0]} Forecasted,${splitTxt[1]}`;
              const concatTxt = `${splitTxt[0]},${splitTxt[1]}`;
              return concatTxt;
            });
            const predTooltip = Array.from(data.prediction).map( (_, idx) => `${(predDataset as any).toolTipLabel[(predDataset as any).toolTipLabel.length - (idx + 1) ]} Forecasted`);
            (predDataset as any).toolTipLabel = [...Array.from({ length: dataSet.data.length - data.prediction.length }).map(() => null), ...predTooltip.reverse()];
          }
          shadowDatasets.push(predDataset);
        }
        return dataSet;
      });
      this.lineChartData = this.lineChartData.concat(shadowDatasets);
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get sizeXL() {
    return this.globalUiService.currentPage === 'Traffic' ? 12 : 10;
  }

  get offsetXL() {
    return this.globalUiService.currentPage === 'Traffic' ? 0 : 1;
  }
}
