import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData, assertNullUndefined, isShowGraph } from '../helpers/util';
import { DynamicGraphAdditionalInput, DynamicGraphConfig } from '../objects/config';
import { SelectableSankeyDataName } from '../objects/selectableData';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { GraphResolverBase } from './GraphResolverBase';
import { GenericHeatmapData } from '../objects/chart';
import { DynamicCustomHeatmapWrapperComponent } from '../shared-components/dynamic/dynamic-custom-heatmap-wrapper/dynamic-custom-heatmap-wrapper.component';
import { AuthenticationService } from '../services/authentication.service';
import { generateNestedData } from '../helpers/mock-data-generator';

export class CustomHeatmapChartResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService,
        graphDataService: GraphDataService, 
        popoverController: PopoverController, 
        viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
        authenicationService: AuthenticationService,
    )
    {
        const heatmapConfig = (getAdditionalInput(additionalInput, 'dynamicGraphConfig') || {}) as DynamicGraphConfig;
        const areaLevel = (getAdditionalInput(additionalInput, 'level') || 'building') as 'building' | 'floor' | 'zone';
        const useSelectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory') || false) as boolean;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        assertNullUndefined(heatmapConfig);
        const chartTitleKey = heatmapConfig.graphName;
        const chartState = (heatmapConfig.graphState || 'ENABLE');
        const chartDataConfig = heatmapConfig.graphData; 
        const chartOptionsConfig = heatmapConfig.graphOptions;

        const includeAreaList: string[] = chartOptionsConfig?.includeAreaList || [];
        const xAxisKeyLabel: string[] = chartOptionsConfig?.xAxis.label;
        const yAxisKeyLabel: string[] = chartOptionsConfig?.yAxis.label;
        const dataLabelUnit: 'percent' | 'default' | 'si' = chartOptionsConfig?.dataLabel?.unit || 'percent';
        const xAxisName: string = chartOptionsConfig?.xAxis.axisName;
        const yAxisName: string = chartOptionsConfig?.yAxis.axisName;
        const xAxisDisplayName: string = chartOptionsConfig?.xAxis?.displayLabelKey || 'DEFAULT';
        const yAxisDisplayName: string = chartOptionsConfig?.yAxis?.displayLabelKey || 'DEFAULT';
        const visualMapType: 'continuous' | 'piecewise' = chartOptionsConfig?.visualMapType || 'continuous';
        const gridSize: { width: string | number; height: string | number } = chartOptionsConfig?.gridSize || { width: '80%', height: '50%' };
        const inputLockText = chartOptionsConfig.inputLockText || 'This graph is available in Monthly View';
        const forceDisplayTBD = chartOptionsConfig.forceDisplayTBD;
        const isEnableTBD = chartOptionsConfig.isEnableTBD;
        const colorSchemeIndicator = chartOptionsConfig.colorSchemeIndicator || ['#2a3bb0', '#506edb', '#7096f4', '#95b8fc', '#b9cff1', '#d8d7d7', '#efc2ad', '#f6a285', '#ea765d', '#cf453b'];
        const excludeList = (getAdditionalInput(additionalInput, 'excludeList') || []) as string[];

        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = isMockData ? [] : Object.keys(chartDataConfig).map(key => {
          const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as SelectableSankeyDataName, graphDataService);
          graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
          return dataConfigResolved.data;
        });

        type DepthData = {
            [name: string]: number;
        } & {
            _total: number;
        };
        type EntraceExitData = { entrance: DepthData; exit: DepthData };

        // const getEntraceExitData = (isEntrance: boolean, data: { entrance: DepthData; exit: DepthData }) => isEntrance ? data.entrance : data.exit;

        const getDepthData = (
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0);
            }
            return retData;
        };
        const labelMapper = {
            DEFAULT: configDataService.DISPLAY_LANGUAGE.HEATMAP_CHART_NAME,
            BUILDING: configDataService.DISPLAY_LANGUAGE.BUILDING_NAME,
            ZONE: configDataService.DISPLAY_LANGUAGE.ZONE_NAME,
            GATE: configDataService.DISPLAY_LANGUAGE.GATE_NAME
        };

        const chartData$ = new BehaviorSubject<GenericHeatmapData>(null);
        const isLock = chartState === 'LOCK';

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicCustomHeatmapWrapperComponent;
        const allDataBehaviourSubject$: BehaviorSubject<unknown>[] = useSelectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, ...chartDataBehaviourSubject$] : chartDataBehaviourSubject$;
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        
        if (isMockData) {
            subscription.add(combineLatest([viewPeriodService.dayList, graphDataService.baseGraphData.selectedInteractable$,...allDataBehaviourSubject$]).subscribe(async ([dayList, selectedInteractable, combinedResult]) => {
                const selectedInteractableName = selectedInteractable?.name;
                if (includeAreaList.length > 0 && selectedInteractableName) {
                    comInstance.isShow = includeAreaList.includes(selectedInteractableName);
                }
                const mockChartDataList: any[] = [];
                const num_interval = 1;
                if (chartDataConfig instanceof Array) {
                  for (const dataConfig of chartDataConfig) {
                    const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, dataConfig.name, 'count', num_interval, false, true);
                    mockChartDataList.push(mockChartDataValue);
                  }
                } else {
                  Object.keys(chartDataConfig).map(async key => {
                    const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, chartDataConfig[key].name, 'count', num_interval, false, true);
                    mockChartDataList.push(mockChartDataValue);
                  });
                }
                const heatmapData = [];
                if (useSelectedDirectory) {
                  const selectDirectory = combinedResult[0] as { building: string; floor: string; zone: string };
                  if (!selectDirectory) {
                      comInstance.isShow = false;
                      return;
                  }  
                  comInstance.isShow = selectDirectory?.zone ? true : false;
                  const rawData = (selectDirectory.floor === 'ALL' ? combinedResult[1] : selectDirectory?.zone ? combinedResult[3] : combinedResult[2]) as Record<string, unknown>[];
                  const buildingName = selectDirectory.building;
                  const floorName = selectDirectory.floor === 'ALL' ? null : selectDirectory.floor;
                  const zoneName = selectDirectory.zone;
                  const flattenData = getDepthData(rawData, buildingName, floorName, zoneName);
                  for (const [zoneKey, zoneData] of Object.entries(flattenData)) {
                    for (const [toZoneKey, toZoneData] of Object.entries(zoneData)) {
                        if (!excludeList.includes(zoneKey) && !excludeList.includes(toZoneKey)) {
                            // yAxisDisplayName === 'GATE' ? heatmapData.push([toZoneKey, zoneKey, toZoneData]) : heatmapData.push([zoneKey, toZoneKey, toZoneData]);
                            heatmapData.push([toZoneKey, zoneKey, toZoneData]);
                        }
                    }
                  } 
                }
                else if (visualMapType === 'piecewise') {
                    const rawData = combinedResult[0] as Array<[number, number, number, string]>;
                    const sumAllData = rawData.reduce((a, b) => a + b[2], 0);
                    const percentageData: Array<[number, number, number, string]> = rawData.map(data => {
                        const percentageVal = (data[2] / sumAllData) * 100;
                        return [data[0], data[1], percentageVal, data[3]];
                    });
                    const selectedData = dataLabelUnit === 'percent' ? percentageData : rawData;
                    heatmapData.push(...selectedData);
                }
                else {
                    const rawDatas =  mockChartDataList;
                    const flattenData = getDepthData(rawDatas[0], chartDataConfig[0].args[0], chartDataConfig[0].args[1], chartDataConfig[0].args[2]);
                    for (const [zoneKey, zoneData] of Object.entries(flattenData)) {
                      for (const [toZoneKey, toZoneData] of Object.entries(zoneData)) {
                        if (!excludeList.includes(zoneKey) && !excludeList.includes(toZoneKey)) {
                            // yAxisDisplayName === 'GATE' ? heatmapData.push([toZoneKey, zoneKey, toZoneData]) : heatmapData.push([zoneKey, toZoneKey, toZoneData]);
                            heatmapData.push([toZoneKey, zoneKey, toZoneData]);
                        }
                      }
                    }
                }
                chartData$.next({ data: heatmapData, dataLabel: { unit: dataLabelUnit }, xAxis: { label: xAxisKeyLabel.filter(label => !excludeList.includes(label)), axisName: xAxisName }, yAxis: { label: yAxisKeyLabel.filter(label => !excludeList.includes(label)), axisName: yAxisName} });
            }));
        } else {
            subscription.add(combineLatest(allDataBehaviourSubject$).subscribe((combinedResult) => {
                const selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
                if (includeAreaList.length > 0 && selectedInteractableName) {
                    comInstance.isShow = includeAreaList.includes(selectedInteractableName);
                }
                const heatmapData = [];
                if (combinedResult.some(val => !val)) { return; }
                if (useSelectedDirectory) {
                  const selectDirectory = combinedResult[0] as { building: string; floor: string; zone: string };
                  if (!selectDirectory) {
                      comInstance.isShow = false;
                      return;
                  }  
                  comInstance.isShow = selectDirectory?.zone ? true : false;
                  const rawData = (selectDirectory.floor === 'ALL' ? combinedResult[1] : selectDirectory?.zone ? combinedResult[3] : combinedResult[2]) as Record<string, unknown>[];
                  const buildingName = selectDirectory.building;
                  const floorName = selectDirectory.floor === 'ALL' ? null : selectDirectory.floor;
                  const zoneName = selectDirectory.zone;
                  const flattenData = getDepthData(rawData, buildingName, floorName, zoneName);
                  for (const [zoneKey, zoneData] of Object.entries(flattenData)) {
                    for (const [toZoneKey, toZoneData] of Object.entries(zoneData)) {
                        if (!excludeList.includes(zoneKey) && !excludeList.includes(toZoneKey)) {
                            // yAxisDisplayName === 'GATE' ? heatmapData.push([toZoneKey, zoneKey, toZoneData]) : heatmapData.push([zoneKey, toZoneKey, toZoneData]);
                            heatmapData.push([toZoneKey, zoneKey, toZoneData]);
                        }
                    }
                  } 
                }
                else if (visualMapType === 'piecewise') {
                    const rawData = combinedResult[0] as Array<[number, number, number, string]>;
                    const sumAllData = rawData.reduce((a, b) => a + b[2], 0);
                    const percentageData: Array<[number, number, number, string]> = rawData.map(data => {
                        const percentageVal = (data[2] / sumAllData) * 100;
                        return [data[0], data[1], percentageVal, data[3]];
                    });
                    const selectedData = dataLabelUnit === 'percent' ? percentageData : rawData;
                    heatmapData.push(...selectedData);
                }
                else {
                    const rawDatas =  combinedResult as Record<string, unknown>[];
                    const flattenData = getDepthData(rawDatas[0], chartDataConfig[0].args[0], chartDataConfig[0].args[1], chartDataConfig[0].args[2]) as EntraceExitData;
                    for (const [zoneKey, zoneData] of Object.entries(flattenData)) {
                      for (const [toZoneKey, toZoneData] of Object.entries(zoneData)) {
                        if (!excludeList.includes(zoneKey) && !excludeList.includes(toZoneKey)) {
                            // yAxisDisplayName === 'GATE' ? heatmapData.push([toZoneKey, zoneKey, toZoneData]) : heatmapData.push([zoneKey, toZoneKey, toZoneData]);
                            heatmapData.push([toZoneKey, zoneKey, toZoneData]);
                        }
                      }
                    }
                }
                chartData$.next({ data: heatmapData, dataLabel: { unit: dataLabelUnit }, xAxis: { label: xAxisKeyLabel.filter(label => !excludeList.includes(label)), axisName: xAxisName }, yAxis: { label: yAxisKeyLabel.filter(label => !excludeList.includes(label)), axisName: yAxisName} });
            }));
        }

        if (heatmapConfig.graphInfoPopover) {
            const infoPopoverText = heatmapConfig.graphInfoPopover;
            comInstance.infoPopover = async (e: any) => {
                const stackBarPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE[infoPopoverText.title] || infoPopoverText.title,
                        toolTipDetails: infoPopoverText.details,
                        imageGateSourceList: configDataService.entranceExitGateImageSrc
                    },
                    cssClass:  'customer-text-popover',
                    event: e,
                });
                return await stackBarPopover.present();
            };
        }
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.gridSize = gridSize;
        comInstance.data$ = chartData$;
        comInstance.xAxisLabelDisplayName = labelMapper[xAxisDisplayName];
        comInstance.yAxisLabelDisplayName = labelMapper[yAxisDisplayName];
        comInstance.inputLockText = inputLockText;
        comInstance.isEnableTBD = isEnableTBD;
        comInstance.forceDisplayTBD = forceDisplayTBD;
        comInstance.colorSchemeIndicator = colorSchemeIndicator;
        comInstance.isLock = isLock;
        comInstance.visualMapType = visualMapType;
        return comInstance;
    }
}

