import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartTooltipModel } from 'chart.js';
import { Label } from 'ng2-charts/lib/base-chart.directive';
import { BehaviorSubject } from 'rxjs';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-dynamic-stack-bar-chart',
  templateUrl: './dynamic-stack-bar-chart.component.html',
  styleUrls: ['./dynamic-stack-bar-chart.component.scss'],
})
export class DynamicStackBarChartComponent implements OnInit {

  @Input() isShow: boolean | BehaviorSubject<boolean> = false;
  @Input() chartLabel$: BehaviorSubject<Label[]>;
  @Input() isLock: boolean | BehaviorSubject<boolean> = false;
  @Input() title: string;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isHorizontal = true;
  @Input() data$: BehaviorSubject<ChartDataSets[]>;
  @Input() chartOptions$: BehaviorSubject<ChartOptions>;
  @Input() isShowLegend: boolean;
  @Input() isShowSelector: boolean;
  @Input() selectInterface: 'action-sheet' | 'popover' | 'alert' = 'alert';
  @Input() chartSelectorOptions: string[];
  @Input() stackBarChartSelector$: BehaviorSubject<string[]>;

  selectorName: string[] = [];

  constructor(
    public configDataService: ConfigDataService,
  ) { }

  ngOnInit() {
    this.selectorName = this.chartSelectorOptions;
  }

  changeSelectedGraph(ev: any) {
    const selectedName = ev.detail.value as string[];
    this.stackBarChartSelector$.next(selectedName);
  }

  getIsShow() {
    let style = {};
    if (this.isShow instanceof BehaviorSubject) {
      this.isShow.subscribe( (isShow: boolean) => {
        style = isShow ? { display: 'flex' } : { display: 'none' };
      });
    } else {
      style = this.isShow ? { display: 'flex' } : { display: 'none' };
    }
    return style;
  }

  getDefaultValue() {
    return this.selectorName;
  }

}
