<div class="ion-text-center gauge-chart--size-listener">
  <div id="gauge-chart-container" style="text-align: center; margin: 0 auto;">
    <rg-gauge-chart *ngIf="finishUpdateSize"
      [canvasWidth]="canvasWidth"
      [needleValue]="needleValue"
      [centralLabel]="centralLabel"
      [options]="options"
      [name]="name"
      [bottomLabel]="bottomLabel"
      [bottomLabelFont]="bottomLabelFont"
    >
    </rg-gauge-chart>
  </div>
</div>