import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { HeatmapData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { DynamicHeatmapWrapperComponent } from '../shared-components/dynamic/dynamic-heatmap-wrapper/dynamic-heatmap-wrapper.component';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class TimeVisitHeatmapResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        let mockArray: number[][] = [];
        const chartData$ = new BehaviorSubject<HeatmapData[]>([])
        if(isMockData){
            (async () => {
                const promises = viewPeriodService.DAY_LIST_MOMENT.map(async (e) => {
                  const mockData = await generateNestedData(e, viewPeriodService, configDataService, 'TIME_VISIT_HEATMAP', 'count', 14);
                  return mockData.heat_map;
                });
                const results = await Promise.all(promises);
                mockArray = results;
                const initialTimeListReverse = [...configDataService.TIME_LIST].reverse();
                chartData$.next(viewPeriodService.DAY_LIST
                .filter((_dayStr, dayIndex) => !viewPeriodService.DAY_LIST_MOMENT[dayIndex].clone().isSameOrAfter(moment(), viewPeriodService.viewPeriod.toMomentString()))
                .map((dayLabel, i) => ({
                    name: dayLabel,
                    series: initialTimeListReverse.map((timeLabel, j) => ({ name: timeLabel, value: mockArray[i][mockArray[i].length - j - 1] || 0 }))
                }))
            )
            })();
            subscription.add(
                combineLatest([viewPeriodService.dayList, graphDataService.timeVisitHeatmapEntranceData$]).subscribe(
                async ([isEntranceDataMode, entranceRawData]) => {
                    const timeList = configDataService.currentOrganization === 'THENINE' ? configDataService.TIME_LIST.slice(0, configDataService.TIME_LIST.length - 1) : configDataService.TIME_LIST;
                    const timeListReverse = [...timeList].reverse();
                    const promises = viewPeriodService.DAY_LIST_MOMENT.map(async (e) => {
                        const mockData = await generateNestedData(e, viewPeriodService, configDataService, 'TIME_VISIT_HEATMAP', 'count', 14);
                        return mockData.heat_map;
                    });
                    mockArray = await Promise.all(promises);
                    const chartData: HeatmapData[] = viewPeriodService.DAY_LIST
                        .filter((_dayStr, dayIndex) => !viewPeriodService.DAY_LIST_MOMENT[dayIndex].clone().isSameOrAfter(moment(), viewPeriodService.viewPeriod.toMomentString()))
                        .map((dayLabel, i) => ({
                        name: dayLabel,
                        series: timeListReverse.map((timeLabel, j) => ({
                        name: timeLabel,
                        value: mockArray[i][mockArray[i].length - j - 1] || 0
                        }))
                        }));
                    chartData$.next(chartData);
                    }
                  )
                );
        }else{
            // await configDataService.loadAppConfig();
            const initialRawData = configDataService.isEntranceDataMode ? graphDataService.timeVisitHeatmapEntranceData$.value : graphDataService.timeVisitHeatmapExitData$.value;
            const initialTimeListReverse = [...configDataService.TIME_LIST].reverse();
            chartData$.next(viewPeriodService.DAY_LIST
                .filter((_dayStr, dayIndex) => !viewPeriodService.DAY_LIST_MOMENT[dayIndex].clone().isSameOrAfter(moment(), viewPeriodService.viewPeriod.toMomentString()))
                .map((dayLabel, i) => ({
                    name: dayLabel,
                    series: initialTimeListReverse.map((timeLabel, j) => ({ name: timeLabel, value: initialRawData[i][initialRawData[i].length - j - 1] || 0 }))
                }))
            );

            subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.timeVisitHeatmapEntranceData$, graphDataService.timeVisitHeatmapExitData$]).subscribe(
                ([isEntranceDataMode, entranceRawData, exitRawData]) => {
                    const rawData = isEntranceDataMode ? entranceRawData : exitRawData;
                    const timeList = configDataService.currentOrganization === 'THENINE' ? configDataService.TIME_LIST.slice(0, configDataService.TIME_LIST.length - 1) : configDataService.TIME_LIST;
                    const timeListReverse = [...timeList].reverse();
                    const chartData: HeatmapData[] = viewPeriodService.DAY_LIST
                        .filter((_dayStr, dayIndex) => !viewPeriodService.DAY_LIST_MOMENT[dayIndex].clone().isSameOrAfter(moment(), viewPeriodService.viewPeriod.toMomentString()))
                        .map((dayLabel, i) => ({
                            name: dayLabel,
                            series: timeListReverse.map((timeLabel, j) => ({ name: timeLabel, value: rawData[i][rawData[i].length - j - 1] || 0 }))
                        }));
                    chartData$.next(chartData);
                    // console.log('rawData timeVisitHeatmapEntranceData$',rawData)
                }
            ));
        }
        // await configDataService.loadAppConfig();
        // const initialRawData = configDataService.isEntranceDataMode ? graphDataService.timeVisitHeatmapEntranceData$.value : graphDataService.timeVisitHeatmapExitData$.value;
        // const initialTimeListReverse = [...configDataService.TIME_LIST].reverse();
        // const chartData$ = new BehaviorSubject<HeatmapData[]>(viewPeriodService.DAY_LIST
        //     .filter((_dayStr, dayIndex) => !viewPeriodService.DAY_LIST_MOMENT[dayIndex].clone().isSameOrAfter(moment(), viewPeriodService.viewPeriod.toMomentString()))
        //     .map((dayLabel, i) => ({
        //         name: dayLabel,
        //         series: initialTimeListReverse.map((timeLabel, j) => ({ name: timeLabel, value: initialRawData[i][initialRawData[i].length - j - 1] || 0 }))
        //     }))
        // );

        // subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.timeVisitHeatmapEntranceData$, graphDataService.timeVisitHeatmapExitData$]).subscribe(
        //     ([isEntranceDataMode, entranceRawData, exitRawData]) => {
        //         const rawData = isEntranceDataMode ? entranceRawData : exitRawData;
        //         const timeList = configDataService.currentOrganization === 'THENINE' ? configDataService.TIME_LIST.slice(0, configDataService.TIME_LIST.length - 1) : configDataService.TIME_LIST;
        //         const timeListReverse = [...timeList].reverse();
        //         const chartData: HeatmapData[] = viewPeriodService.DAY_LIST
        //             .filter((_dayStr, dayIndex) => !viewPeriodService.DAY_LIST_MOMENT[dayIndex].clone().isSameOrAfter(moment(), viewPeriodService.viewPeriod.toMomentString()))
        //             .map((dayLabel, i) => ({
        //                 name: dayLabel,
        //                 series: timeListReverse.map((timeLabel, j) => ({ name: timeLabel, value: rawData[i][rawData[i].length - j - 1] || 0 }))
        //             }));
        //         chartData$.next(chartData);
        //         console.log('test Heatmap',chartData$)
        //         console.log('rawData timeVisitHeatmapEntranceData$',graphDataService.timeVisitHeatmapEntranceData$.value)
        //         console.log('rawData timeVisitHeatmapEntranceData$',graphDataService.timeVisitHeatmapExitData$.value)
        //     }
        // ));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicHeatmapWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.TIME_VISIT;
        comInstance.isLock = this.isLock;
        comInstance.data$ = chartData$;
        return comInstance;
    }

}
