import { ConfigDataService } from '../services/config-data.service';
import * as moment from 'moment';
import { getRandomInt } from './util';
import { ViewPeriodService } from '../services/view-period.service';

export const isObject = (value: any) => value !== null && typeof value === 'object' && !Array.isArray(value);

export const generateTrafficFloorData = async (date: string, configDataService: ConfigDataService) => {
    if (!configDataService.isConfigReady) {
        await configDataService.loadAppConfig();
    }
    const globalSeed = moment(date).format('YYYY-MM-DD');
    return Object.entries(configDataService.MOCK_CONFIG.FLOOR_TRAFFIC).reduce((prev, [floorName, floorConfig]) => {
        prev[floorName] = getRandomInt(floorConfig.min, floorConfig.max, floorName + globalSeed);
        return prev;
    }, {} as { [floorName: string]: number });
};

export const generateNestedData = async (date: moment.Moment, viewPeriodService: ViewPeriodService, configDataService: ConfigDataService, configName: string, channelMode: 'traffic' | 'count', num_interval: number, by_hour: boolean = false, isStaticFactor?: boolean) => {
    if (!configDataService.isConfigReady) {
        await configDataService.loadAppConfig();
    }

    const globalSeed = date.format('YYYY-MM-DD');
    const periodFactor = isStaticFactor ? 1 : by_hour ? Number((configDataService.TIME_LIST.length / 100).toFixed(2)) : viewPeriodService.isDayPeriod ? 1 : viewPeriodService.isWeekPeriod ? 7 : viewPeriodService.selectedDate.clone().daysInMonth(); 

    const recursiveReducer = (configObj: any, parentSeed: string): any => Object.entries(configObj).reduce((prev, [key, value]) => {
            if (typeof value === 'object' && value !== null && !('min' in value && 'max' in value)) {
                // If value is an object and not a range object, recursively process it
                prev[key] = recursiveReducer(value, parentSeed + key);
            } else {
                const rangeObject = value as { min: number; max: number };
                const rangeWithFactor = { min: rangeObject.min * periodFactor, max: rangeObject.max * periodFactor };
                if (channelMode === 'traffic') {
                    if (num_interval > 1) {
                        prev[key] = { 
                            entrance: Array.from({ length: num_interval }, (_, i) => getRandomInt(rangeWithFactor.min, rangeWithFactor.max, parentSeed + key + i + 'entrance')), 
                            exit: Array.from({ length: num_interval }, (_, i) => getRandomInt(rangeWithFactor.min, rangeWithFactor.max, parentSeed + key + i + 'exit')) 
                        };
                    } else {
                        prev[key] = { entrance: getRandomInt(rangeWithFactor.min, rangeWithFactor.max, parentSeed + key + 'entrance'), exit: getRandomInt(rangeWithFactor.min, rangeWithFactor.max, parentSeed + key + 'exit') };
                    }
                } else {
                    if (num_interval > 1) {
                        prev[key] = Array.from({ length: num_interval }, (_, i) => getRandomInt(rangeWithFactor.min, rangeWithFactor.max, parentSeed + key + i));
                    } else {
                        prev[key] = getRandomInt(rangeWithFactor.min, rangeWithFactor.max, parentSeed + key);
                    }
                }
            }
            return prev;
        }, {} as { [key: string]: any });

    return recursiveReducer(configDataService.MOCK_CONFIG[configName], globalSeed);
};
