export enum Loading {
    GENERAL = 'GENERAL',
    APP_CONFIG = 'APP_CONFIG',
    USER_LIST = 'USER_LIST',
    EVENT_DATA = 'EVENT_DATA',
    SKU_LIST = 'SKU_LIST',
    TRAFFIC_CONGESTION_DATA = 'TRAFFIC_CONGESTION_DATA',
    STORE_DATA_LIST = 'STORE_DATA_LIST',
    INBOX_LIST = 'INBOX_LIST',
    GOAL_LIST = 'GOAL_LIST',
    TRAFFIC_VIDEO_LIST = 'TRAFFIC_VIDEO_LIST'
}
