import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CustomerSegmentDetailsComponent } from '../pages/home/general/customer-segment-details/customer-segment-details.component';
import { showEthnicityCustomTooltips } from '../helpers/ethnicityTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { isScreenSmallerThanMD } from '../helpers/util';
import { generateNestedData } from '../helpers/mock-data-generator';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class AverageWeekDayWeekEndCountResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        const isMockData = (getAdditionalInput(_additionalInput, 'isMockData')) as boolean;
        const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'AVERAGE_WEEKDAY_WEEKEND_COUNT', 'count', 2);
        // await configDataService.loadAppConfig();
        const chartLabel = ['Weekday','Weekend'];
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);
        if(isMockData){
            dualBarData$.next([{
            data: [...mockData.headcount],
            color: CHART_PRIMARY_COLOR,
            label: 'Entrance',
            calPercentFrommAllData: false
        }]);
        subscription.add((viewPeriodService.dayList,graphDataService.buildingAverageWeekDayWeekEndCount$).subscribe(avarageWeekdayWeekend => {
            dualBarData$.next([{
                data: [...mockData.headcount],
                color: CHART_PRIMARY_COLOR,
                label: 'Entrance',
                calPercentFrommAllData: false
            }]);
        }));
        }else{
            dualBarData$.next([{
                data: [],
                color: CHART_PRIMARY_COLOR,
                label: 'Entrance',
                calPercentFrommAllData: false
            }]);
            subscription.add((viewPeriodService.dayList,graphDataService.buildingAverageWeekDayWeekEndCount$).subscribe(avarageWeekdayWeekend => {
                dualBarData$.next([{
                    data: [...avarageWeekdayWeekend],
                    color: CHART_PRIMARY_COLOR,
                    label: 'Entrance',
                    calPercentFrommAllData: false
                }]);
            }));
        }
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = 'Average Traffic Last 7 Day';
        comInstance.isLock = this.isLock;
        // comInstance.infoPopover = async (e: any) => {
        //     const ethnicityDetailPopover = await popoverController.create({
        //         component: CustomerSegmentDetailsComponent,
        //         cssClass: 'customer-segment-details-popover',
        //         event: e
        //     });
        //     return ethnicityDetailPopover.present();
        // };
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.4;
        // comInstance.customToolTipFuction = function(tooltipModel) {
        //     showEthnicityCustomTooltips(tooltipModel, this, configDataService.ethnicityImageSrc, ethnicityList);
        // };
        return comInstance;
    }

}
