import { SelectableDataName } from '../objects/selectableData';
import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGaugeChartWrapperComponent } from '../shared-components/dynamic/dynamic-gauge-chart-wrapper/dynamic-gauge-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class MaleUsedCountResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {

        let currentData$ = new BehaviorSubject<{ current: number; min: number; max: number }>({
            // current: graphDataService.parkingLotOccupancyData$.value,
            current: 0,
            min: 0,
            max: 50
        });
        currentData$.next({
            current: 41,
            min: 0,
            max: 50
        })

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicGaugeChartWrapperComponent;
        comInstance.title = 'Male Toilet Used Count';
        comInstance.reverseArcColor = true;
        comInstance.isLock = this.isLock;
        comInstance.data$ = currentData$;
        comInstance.isShow$ = viewPeriodService.isLiveMode$;
        return comInstance;
    }

}
