import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { showGateCustomTooltips } from '../helpers/gateTooltips';
import { accessDepthData, isScreenSmallerThanMD, isScreenSmallerThanXL, isScreenSmallerThanXS } from '../helpers/util';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class ZoneEntranceExitAnalysisResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        // const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const zoneName = (getAdditionalInput(additionalInput, 'zone')) as string;
        // const floorName = configDataService.GRAPH_CONFIG.ENTRANCE_EXIT_ANALYSIS.FLOOR;
        // const getFloorGateData = (value: { [buildingName: string]: { [floorName: string]: { [gateName: string]: number } } }, defaultValue = {}) => (value && value[buildingName]) ? value[buildingName] : defaultValue as { [floorName: string]: { [gateName: string]: number } };
        const fillData: number[] = Array.from({ length: 7 }).map(() => null);
        const getDepthData = (
            data: number[] | { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            building?: string,
            floor?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (building === undefined && floor === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data || fillData;
            } else {
                retData = accessDepthData<unknown>(data as any, building, floor, depthZoneName, fillData);
            }
            return retData as any[];
        };

        const initialEntranceData = getDepthData(graphDataService.allPinByAllZoneEntrance$.value, zoneName);
        const initialExitData = getDepthData(graphDataService.allPinByAllZoneExit$.value, zoneName);
        const initialGateNames = Object.keys(initialEntranceData).sort();
        const chartLabel = initialGateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName);

        const entranceInitialData = initialGateNames.map(gateName => initialEntranceData[gateName] || null);
        const exitInitialData = initialGateNames.map(gateName => initialExitData[gateName] || null);
        const gateList = configDataService.DISPLAY_LANGUAGE.GATE_NAME;

        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: entranceInitialData,
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            calPercentFrommAllData: false
        }, {
            data: exitInitialData,
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.EXIT,
            calPercentFrommAllData: false
        }]);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        subscription.add(combineLatest([graphDataService.allPinByAllZoneEntrance$, graphDataService.allPinByAllZoneExit$])
            .subscribe(([allPinByAllZoneEntrance, allPinByAllZoneExit]) => {
            const entrancePinData = getDepthData(allPinByAllZoneEntrance, zoneName);
            const exitPinData = getDepthData(allPinByAllZoneExit, zoneName);
            const gateNames = Object.keys(entrancePinData).sort();
            comInstance.chartLabel = gateNames.map(gateName => configDataService.DISPLAY_LANGUAGE.GATE_NAME[gateName] || gateName);
            const entranceData = gateNames.map(gateName => entrancePinData[gateName] || null);
            const exitData = gateNames.map(gateName => exitPinData[gateName] || null);
            dualBarData$.next([{
                data: entranceData,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                calPercentFrommAllData: false
            }, {
                data: exitData,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.EXIT,
                calPercentFrommAllData: false
            }]);
        }));
        comInstance.title = configDataService.DISPLAY_LANGUAGE.ENTRANCE_EXIT_ANALYSIS;
        comInstance.isLock = this.isLock;
        // comInstance.infoPopover = async (e: any) => {
        //     const ageDetailPopover = await popoverController.create({
        //         component: CustomerAgeTooltipsComponent,
        //         cssClass: 'calendar-criteria-tooltips',
        //         event: e
        //     });
        //     return ageDetailPopover.present();
        // };
        comInstance.isShowLegend = true;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.isHorizontal = true;
        comInstance.aspectRatio = isScreenSmallerThanXL(window) ? 0.7 : 1.0;
        comInstance.valueTextAlign = 'left';
        comInstance.valueTextOffsetX = isScreenSmallerThanMD(window) ? 1 : 5;
        comInstance.valueTextOffsetY = 5;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.displayAxis = 'Y';
        comInstance.isStacked = '!XY';
        comInstance.displayGrid = false;
        comInstance.customToolTipFuction = function(tooltipModel) {
            (tooltipModel as any).floorName = 'ALL';
            showGateCustomTooltips(tooltipModel, this, configDataService.entranceExitGateImageSrc, gateList);
        };
        return comInstance;
    }

}
