import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { GenericLineChartData } from '../objects/chart';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { Label } from 'ng2-charts';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';

const CHART_COLOR = '#6BC26F';

export class FloorPopularTimeGraphResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency); // ENTRANCE_EXIT_HOUR, ENTRANCE_EXIT_FLOOR_HOUR, ENTRANCE_EXIT_ZONE_HOUR
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const zoneName = graphDataService.baseGraphData.selectedDirectory$.value?.zone || (getAdditionalInput(additionalInput, 'zone')) as string;
        const initialfloorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';
        const getFloorAccumulateHeadcountByHour = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [zoneName: string]: number[] } } },
            floorName?: string,
            depthZoneName?: string,
        ) => accessDepthData<number[]>(data, buildingName, floorName, depthZoneName, Array.from({ length: configDataService.TIME_LIST.length }).map(() => null));

        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: initialIsAll ? getFloorAccumulateHeadcountByHour(graphDataService.accumulateHeadcountByHour$.value) : (!zoneName ? getFloorAccumulateHeadcountByHour(graphDataService.floorAccumulateHeadcountByHour$.value, initialfloorName)
                : getFloorAccumulateHeadcountByHour(graphDataService.zoneAccumulateHeadcountByHour$.value, initialfloorName, zoneName)),
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            toolTipLabel: configDataService.TIME_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART}: ${time}`),
            label: configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART,
            // toolTipLabelUnit: 'min'
        }]);
        const chartLable$ = new BehaviorSubject<Label[]>(configDataService.TIME_LIST);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.accumulateHeadcountByHour$, graphDataService.floorAccumulateHeadcountByHour$, graphDataService.zoneAccumulateHeadcountByHour$]).subscribe(
            ([selectedFloorName, selectedDirectory, dataPoints, floorDataPoints, zoneDataPoints]) => {
                const floorName = selectedDirectory?.floor || selectedFloorName || 'ALL';
                const nextZoneName = selectedDirectory?.zone || zoneName;
                const isAll = floorName === 'ALL';
                const lineChartData: GenericLineChartData[] = [{
                    points: isAll ? getFloorAccumulateHeadcountByHour(dataPoints) : (!nextZoneName ? getFloorAccumulateHeadcountByHour(floorDataPoints, floorName)
                        : getFloorAccumulateHeadcountByHour(zoneDataPoints, floorName, nextZoneName)),
                    backgroundColor: `${CHART_COLOR}22`,
                    color: CHART_COLOR,
                    toolTipLabel: configDataService.TIME_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART}, ${time}`),
                    label: configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART,
                    // toolTipLabelUnit: 'min'
                }];
                chartData$.next(lineChartData);
            }));
        // comInstance.enableTBD = true;
        comInstance.data$ = chartData$;
        comInstance.label$ = chartLable$;
        // comInstance.selectedLine$ = selectedGraph$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART;
        comInstance.isLock = this.isLock;
        return comInstance;
    }

}
