import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject } from 'rxjs';
import { isScreenSmallerThanMD } from 'src/app/helpers/util';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-dynamic-custom-line-chart',
  templateUrl: './dynamic-custom-line-chart.component.html',
  styleUrls: ['./dynamic-custom-line-chart.component.scss'],
})
export class DynamicCustomLineChartComponent implements OnInit {

  @Input() isShow = true;
  @Input() data$: BehaviorSubject<ChartDataSets[]>;
  @Input() chartOptions$: BehaviorSubject<ChartOptions>;
  @Input() chartLabel$: BehaviorSubject<Label[]>;
  @Input() isLock: boolean | BehaviorSubject<boolean> = false;
  @Input() title: string;
  @Input() isShowLegend: boolean;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isShowLegendOnPercent: boolean;
  @Input() isShowOnPeakTime: boolean;
  @Input() numberData$?: BehaviorSubject<number>;
  @Input() label$?: BehaviorSubject<string>;
  @Input() labelStyle?: { fontSize: string; fontWeight: string; color: string };
  @Input() labelIcon?: string;
  @Input() isShowCustomLegend: boolean;
  @Input() labelHeader$?: BehaviorSubject<string>;
  @Input() defaultValue?: string;
  @Input() selectInterface?: 'action-sheet' | 'popover' | 'alert' = 'alert';
  @Input() isShowSelector: boolean;
  @Input() chartSelectorOptions?: string[];
  @Input() lineChartSelector$?: BehaviorSubject<string[]>;

  selectorName: string[] = []; 
  showDurationText: boolean;

  constructor(
    public configDataService: ConfigDataService,
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {
    this.selectorName = this.chartSelectorOptions;
  }

  changeSelectedGraph(ev: any) {
    const selectedName = ev.detail.value as string[];
    this.lineChartSelector$.next(selectedName);
  }

  getDefaultValue() {
    return this.lineChartSelector$.value;
  }

  legendNumber(data: number) {
    if (!data) {
      return '0';
    }
    return this.isShowLegendOnPercent ? `${data.toFixed(0)}%` : data.toLocaleString();
  }

  get displayIconOnMiddle() {
    return isScreenSmallerThanMD(window);
  }

}
