/* eslint-disable max-len */
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicEchartConfig, DynamicGraphAdditionalInput } from '../objects/config';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { GraphResolverBase } from './GraphResolverBase';
import { DynamicEchartComponent } from '../shared-components/dynamic/dynamic-echart/dynamic-echart.component';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined, isShowGraph, numberFormatter } from '../helpers/util';
import { AuthenticationService } from '../services/authentication.service';
import { EChartsOption } from 'echarts';
import { SelectableCustomDataName } from '../objects/selectableData';
import { processChartData } from '../helpers/chartUtils';

export class GenericEchartResolver extends GraphResolverBase { 
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService,
    ) {
        // define config from backend
        const echartConfig = (getAdditionalInput(additionalInput, 'echartConfig') || {}) as DynamicEchartConfig;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const graphState = (getAdditionalInput(additionalInput, 'graphState') || 'ENABLE') as 'ENABLE' | 'DISABLE' | 'LOCK' | 'LOCK_COND';
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';
        const useselectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory') || false) as boolean;
        assertNullUndefined(echartConfig);

        const getColor = (lineName: string) => (((configDataService.GRAPH_CONFIG.INTERACTABLE_ECHART || {})[echartConfig.graphTitleName] || {})[lineName] || {}) as { color: string; backgroundColor?: string };
        const parseUnit = (value: number, unit: 'hour' | 'min' | 'percent' | 'default' | 'decimal' | 'si' | 'spots', decimalPoint: number = 0, calculateHour?: boolean, ) => {
            if (!value) {
                return 'N/A';
            }
            if (unit === 'percent') {
                if (value >= 0 && value < 1) {
                    return '<1%';
                } 
                else if (isNaN(value)) {
                    return 'N/A';
                }
                return value.toFixed(decimalPoint) + '%';
            }
            else if (unit === 'min') {
                if (value < 60) {
                    return '0 mins';
                }
                return (value / 60).toFixed(0) + ' mins';
            } 
            else if (unit === 'hour') {
                if (calculateHour) {
                    return Math.round(value / 3600).toLocaleString() + ' hrs';
                }
                return value.toLocaleString() + ' hrs';
            }
            else if (unit === 'decimal') {
                return value.toFixed(decimalPoint);
            } 
            else if (unit === 'si') {
                return numberFormatter(value, 1);
            }
            else if (unit ==='spots'){
                return value +' spots';
            }
            return value.toLocaleString(); 
        };

        // initial
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicEchartComponent;

        const chartDataConfig = echartConfig.graphData;
        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = Object.keys(chartDataConfig).map(key => {
            const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as SelectableCustomDataName);
            graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
            return dataConfigResolved.data;
        });

        const allDataBehaviourSubject$: BehaviorSubject<unknown>[] = [configDataService.isEntranceDataMode$, ...chartDataBehaviourSubject$];

        // TODO: Set config by chart type
        let defaultEchartConfig: EChartsOption = echartConfig.graphOption;

        if (echartConfig.graphType === 'population_pyramid_bar') {
            defaultEchartConfig = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                legend: {
                    textStyle: {
                        color: '#fff'
                    },
                    itemWidth: 30,
                    itemHeight: 30,
                    data: [
                        {
                            name: 'Male',
                            icon: 'path://m 146.41936,238.8034 c -5.21101,-1.43402 -7.51545,-6.79358 -6.6619,-11.76943 -0.0588,-45.10952 -0.11757,-90.21905 -0.17635,-135.328563 -5.3022,-1.61412 -3.06375,4.34199 -3.52464,7.58816 -0.0576,14.697923 -0.11511,29.395843 -0.17266,44.093773 -1.72718,6.61806 -12.15586,7.45944 -14.19605,0.88682 -1.42909,-4.98857 -0.22146,-10.60033 -0.62062,-15.83232 0.10773,-15.18837 -0.21551,-30.437173 0.16059,-45.587893 1.91842,-11.228608 12.80383,-20.22421 24.26927,-18.689786 10.60777,1.558898 0.0755,-3.65768 -0.79236,-8.596161 -4.23852,-8.688715 0.80002,-20.073014 9.72708,-23.421847 8.82591,-4.162774 20.30103,1.001172 23.52581,10.108188 2.28945,5.67583 1.4368,12.853955 -2.76118,17.571486 -5.15831,4.024926 -3.94241,5.010805 1.85043,4.362909 13.58742,-1.603119 25.03585,11.840701 23.9554,24.967141 -0.0691,18.213333 -0.13818,36.426673 -0.20726,54.640013 -1.5351,4.55905 -7.30638,6.71543 -11.30858,3.96578 -4.81473,-2.8888 -2.73019,-9.20279 -3.19227,-13.88869 -0.0523,-14.05586 -0.10469,-28.11173 -0.15704,-42.167583 -4.85271,-1.54237 -3.37467,3.24601 -3.51022,6.4208 V 231.02616 c -1.3114,6.77368 -9.29063,10.3384 -15.13544,6.61747 -6.62075,-3.7866 -4.17124,-12.04397 -4.62011,-18.29166 v -70.84935 c -4.85175,-1.54283 -3.39102,3.24111 -3.53094,6.42079 -0.0578,25.5528 -0.11553,51.1056 -0.17329,76.65839 -1.7387,5.48439 -7.13811,8.77105 -12.74767,7.2216 z',
                        },
                        {
                            name: 'Female',
                            icon: 'path://m 39.7122,238.0264 c -5.604205,-1.49359 -5.822698,-7.32898 -5.431108,-11.96235 -0.05932,-18.97406 -0.118632,-37.94813 -0.177948,-56.92219 -7.401109,0.0507 -14.802279,0.16954 -22.203547,0.1438 8.050221,-26.97466 15.83106,-54.03787 24.0791,-80.948455 -6.246873,-1.537447 -5.103818,6.332986 -7.12857,10.198179 -4.203419,12.783656 -7.28462,25.995046 -12.31951,38.467156 C 6.215777,147.43407 -0.93895389,129.58252 6.2279437,121.52707 11.709639,105.71684 15.006783,88.999576 22.521999,73.9779 25.487431,65.143259 38.425956,64.174487 43.879817,63.247984 35.242261,58.307767 32.195248,46.181151 37.843175,37.985287 c 5.35176,-7.73122 16.727442,-10.988636 24.757146,-5.16531 11.321083,6.562216 10.452089,25.024381 -1.135269,30.670395 9.830628,-0.28155 20.086569,3.623662 24.845207,12.765524 3.87086,7.45858 5.12438,16.169298 8.137928,24.037484 2.906124,10.26421 6.922833,20.35157 9.297803,30.70045 1.06345,4.17564 -1.66552,9.02385 -6.181687,9.2796 -7.686885,1.11419 -8.783192,-8.80355 -10.70406,-14.18732 -3.87502,-12.5653 -7.681429,-25.15172 -11.575988,-37.711005 -8.798872,-0.113812 1.949333,13.898795 1.781574,19.941085 6.048408,20.20812 12.13493,40.40517 18.089502,60.64114 -7.392371,0.35953 -14.803078,0.14681 -22.203496,0.20388 -0.06597,21.22546 -0.131933,42.45093 -0.1979,63.67639 -2.103142,7.13406 -13.415648,7.74398 -15.969932,0.84281 -1.418088,-4.77754 -0.245017,-10.18282 -0.655178,-15.20454 l -0.156843,-49.31466 c -4.44248,-1.05339 -5.844521,0.93365 -4.913879,5.25338 -0.162881,19.18788 0.325808,38.44483 -0.244801,57.58947 -0.334387,5.03435 -6.719798,7.8699 -11.101102,6.02234 z',
                        }
                    ]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    // show: true,
                    axisLabel: {
                        formatter: (params: any) => parseUnit(Math.abs(Number(params)), 'si'),
                        color: '#fff'
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'category',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLabel: {
                        color: '#fff'
                    },
                    data: ['Children', 'Teenager', 'Young Adult', 'Adult', 'Seniors']
                },
                series: [
                    {
                        name: 'Female',
                        type: 'bar',
                        color: '#ff007f',
                        stack: 'Total',
                        label: {
                            show: true,
                            position: 'right',
                            fontWeight: 'normal',
                            fontStyle: 'normal',
                        },
                        emphasis: {
                            focus: 'none'
                        },
                        data: [320, 302, 341, 374, 390, 450, 420]
                    },
                    {
                        name: 'Male',
                        type: 'bar',
                        color: '#0000ff',
                        stack: 'Total',
                        label: {
                            show: true,
                            position: 'left',
                            fontWeight: 'normal',
                            fontStyle: 'normal',
                            formatter: (params) => parseUnit(Math.abs(Number(params.value)), 'si')
                        },
                        emphasis: {
                            focus: 'none'
                        },
                        data: [-120, -132, -101, -134, -190, -230, -210]
                    }
                ]
            };
        }

        const chartOption$ = new BehaviorSubject<EChartsOption>(defaultEchartConfig);

        subscription.add(combineLatest(allDataBehaviourSubject$).subscribe((combinedResult) => { 
            if (combinedResult.some(val => !val)) { return; }
            combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$]).subscribe(([selectedDirectory, selectedLevel]) => {
                if (excludedDirectory !== undefined) {
                  if (excludedDirectory === 'BUILDING') {
                    comInstance.isShow =  selectedDirectory?.floor !== 'ALL';
                  }
                  else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                    if (selectedDirectory?.floor === 'ALL' && excludedDirectory === 'FLOOR') {
                      comInstance.isShow = true;
                    } else {
                      comInstance.isShow = selectedLevel !== excludedDirectory;
                    }
                  }
                  // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                  else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') <excludedDirectory.length - 1) {
                    const excludeList = excludedDirectory.split('_AND_');
                    if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                      comInstance.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                    }
                    else if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                      comInstance.isShow = true;
                    } else {
                      comInstance.isShow = !excludeList.includes(selectedLevel);
                    }
                  }
                }
            });
            if (echartConfig.graphType === 'treemap') {
                const arrayObjects: { [key: string]: any }[] = Object.entries(combinedResult[1]).map(([key, value]) => ({
                    id: key,
                    name: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] || key,
                    value: processChartData(value),
                    color: getColor(key).backgroundColor || '#ccc',
                    colorMappingBy: 'id'
                }));
                defaultEchartConfig.series[0].data = arrayObjects;
                const updateEchartConfig: EChartsOption = defaultEchartConfig;
                chartOption$.next(updateEchartConfig);
            }
            if (echartConfig.graphType === 'population_pyramid_bar') {
                
            }

        }));

        comInstance.chartOption = defaultEchartConfig;
        comInstance.chartOption$ = chartOption$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE[echartConfig.graphTitleName] || echartConfig.graphTitleName;
        comInstance.isLock = graphState === 'LOCK';
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        return comInstance;
    }
}

