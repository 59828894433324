import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ChartColor, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { CHART_CLASS_COLOR, PIE_CHART_COLOR_LIST } from '../configs/color';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData, assertNullUndefined, calPercentageFromData, isScreenSmallerThanLG, isShowGraph } from '../helpers/util';
import { DynamicGraphAdditionalInput, DynamicGraphConfig, GraphDataConfig, GraphInfoPopoverByPeriod } from '../objects/config';
import { SelectableCustomDataName, SelectablePieDataName } from '../objects/selectableData';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { CustomerGenderTooltipsComponent } from '../pages/home/general/customer-gender-tooltips/customer-gender-tooltips.component';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicCustomPieChartComponent } from '../shared-components/dynamic/dynamic-custom-pie-chart/dynamic-custom-pie-chart.component';
import { VehiclePurchasingPowerTooltipsComponent } from '../shared-components/general/vehicle-purchasing-power-tooltips/vehicle-purchasing-power-tooltips.component';
import { GraphResolverBase } from './GraphResolverBase';
import { generateNestedData } from '../helpers/mock-data-generator';

export class CustomPieChartResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput, 
        configDataService: ConfigDataService,
        graphDataService: GraphDataService, 
        popoverController: PopoverController, 
        viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
        authenicationService: AuthenticationService,
    )
    {
        // await configDataService.loadAppConfig();
        const pieChartConfig = (getAdditionalInput(additionalInput, 'dynamicGraphConfig') || {}) as DynamicGraphConfig;
        const pieChartLockDate = (getAdditionalInput(additionalInput, 'useZoneDateLock') || false) as boolean;
        const useselectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory') || false) as boolean;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const buildingInstanceName = (getAdditionalInput(additionalInput, 'building')) as string;
        const floorInstanceName = (getAdditionalInput(additionalInput, 'floor')) as string;
        const zoneInstanceName = (getAdditionalInput(additionalInput, 'zone')) as string;
        const graphInfoPopoverByPin = (getAdditionalInput(additionalInput, 'graphInfoPopoverByPeriod')) as GraphInfoPopoverByPeriod;
        const dynamicGraphDataSelector = (getAdditionalInput(additionalInput, 'dynamicGraphDataSelector') || {}) as { [selectorName: string]: GraphDataConfig };
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';
        const showAveragePerDay = (getAdditionalInput(additionalInput, 'showAveragePerDay') || false) as boolean;
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        const updateTextByPeriod = (getAdditionalInput(additionalInput, 'updateTextByPeriod') || false) as boolean;
        const hidePercentData = (getAdditionalInput(additionalInput, 'hidePercentData') || false) as boolean;
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        const adjustLegendSizing = (getAdditionalInput(additionalInput, 'adjustLegendSizing')) as boolean;

        assertNullUndefined(pieChartConfig);

        const chartTitleKey = pieChartConfig.graphName;
        const chartState = (pieChartConfig.graphState || 'ENABLE');
        const chartDataConfig = pieChartConfig.graphData; 
        const chartOptionsConfig = pieChartConfig.graphOptions;
        const chartLabelTooltip = pieChartConfig.graphTooltipLabel;

        const isShowLegend = chartOptionsConfig?.isShowLegend;
        const isShowPercent = chartOptionsConfig?.isShowPercent === true;
        const isOnlyMonthPeriod = chartOptionsConfig?.isOnlyPeriod === 'MONTH';
        const useSuffixNumber = chartOptionsConfig?.useSuffixNumber === true;
        const useDepthDataLabel = chartOptionsConfig?.useDepthDataLabel === true;
        const isShowDataLabels = chartOptionsConfig?.isShowDataLabels === true;
        const inputLockText = chartOptionsConfig?.inputLockText || 'This graph is available in Monthly View';
        const unitType: 'car' = chartOptionsConfig?.unitType;
        const isCustomInfoPopover = chartOptionsConfig?.isCustomInfoPopover === true;
        const customInfoPopoverKey = chartOptionsConfig?.customInfoPopoverKey;
        const chartColor: string = chartOptionsConfig?.chartColor || 'DEFAULT';
        const areaInstanceName: string[] = [buildingInstanceName, floorInstanceName, zoneInstanceName];
        const dataConfigFromInstance: string[] = areaInstanceName.filter(d => d !== undefined);
        const includeAreaList: string[] = chartOptionsConfig?.includeAreaList || [];
        const useCalPercentageFromData: boolean = chartOptionsConfig?.useCalPercentageFromData === true;
        const isShowPercentageFromCalData: boolean = chartOptionsConfig?.isShowPercentageFromCalData === true;
        const useSelector: boolean = chartOptionsConfig?.useSelector === true;
        const isDatafromFlowChannel: boolean = chartOptionsConfig?.isDatafromFlowChannel === true;
        const depthBreakdown: boolean = chartOptionsConfig?.depthBreakdown === true;
        const chartDisplayLanguageConfig: 'DEFAULT' | 'SELECTOR'  = chartOptionsConfig?.chartDisplayLanguageConfig || 'DEFAULT';
        const isEnableTBD: boolean = chartOptionsConfig?.isEnableTBD === true;
        const toggleVehicleProfileSelector: boolean = chartOptionsConfig?.toggleVehicleProfileSelector === true;
        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = isMockData ? [] : Object.keys(chartDataConfig).map(key => {
          const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as (SelectableCustomDataName | SelectablePieDataName), graphDataService);
          graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
          return dataConfigResolved.data;
        });

        type DepthData = {
            [name: string]: number;
        } & {
            _total: number;
        };
        type EntraceExitData = { entrance: DepthData; exit: DepthData };

        const getColor = (lineName: string) => (((configDataService.GRAPH_CONFIG.INTERACTABLE_PIE_CHART || {})[chartTitleKey] || {})[lineName] || {}) as { color: string; backgroundColor?: string };
        const getEntraceExitData = (isEntrance: boolean, data: { entrance: DepthData; exit: DepthData }) => isEntrance ? data.entrance : data.exit;

        const getDepthData = (
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0);
            }
            if (Object.keys(retData).includes('entrance')) {
                retData = getEntraceExitData(true, retData as EntraceExitData);
            }
            return retData;
        };

        const compareKey = (a: any, b: any) => {
          if (a.includes('-') && b.includes('-')) {
            const numA = Number(a.split('-')[0]);
            const numB = Number(b.split('-')[0]);
            if (numA < numB) {
              return -1;
            }
            if (numA > numB) {
              return 1;
            }
            return 0;
          }
          return a - b;
        };

        const presentNumberValueTxt = (value: number, args: number, displayType: 'suffix' | 'percent' = 'suffix') => {
          if (displayType === 'percent') {
            return value > 1 ? `${value.toFixed(0)}%` : `<1%`;
          } 
          else if (useSuffixNumber) {
            const suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];
        
            if (Number.isNaN(value) || value === null) {
              return null;
            }
        
            if (value < 10000) {
              return (value || 0).toLocaleString();
            }
        
            const exp = Math.floor(Math.log(value) / Math.log(1000));
        
            return (value / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
          }
          return Math.floor(value).toLocaleString();
        };

        const mappedDisplayLanguage = {
          DEFAULT: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME,
          PIE: configDataService.DISPLAY_LANGUAGE.PIE_CHART_NAME 
        };

        let totalValue = 0;

        const chartData$ = new BehaviorSubject<ChartDataSets[]>([]);
        const chartOption$ = new BehaviorSubject<ChartOptions>({});
        const chartLabel$ = new BehaviorSubject<Label[]>([]);
        const selectedDataSource$ = new BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>(dynamicGraphDataSelector);
        const isLock$ = new BehaviorSubject<boolean>(chartState === 'LOCK');

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicCustomPieChartComponent;
        const allDataBehaviourSubject$: BehaviorSubject<unknown>[] = useselectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, ...chartDataBehaviourSubject$]
        : (isOnlyMonthPeriod ? [viewPeriodService.isMonthMode$, ...chartDataBehaviourSubject$]
        : useSelector ? 
        [selectedDataSource$, ...chartDataBehaviourSubject$] : chartDataBehaviourSubject$);
        if (isMockData) {
          subscription.add(combineLatest([graphDataService.baseGraphData.selectedInteractable$, viewPeriodService.dayList, ...allDataBehaviourSubject$]).subscribe(async ([selectedInteractable, dayList, combinedResult]) => {
            const selectedInteractableName = selectedInteractable?.name; 
            if (includeAreaList.length > 0) {
              comInstance.isShow = includeAreaList.includes(selectedInteractableName);
            }
            if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
              comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
            }
            const mockChartDataList: any[] = [];
            const num_interval = 1;
            if (chartDataConfig instanceof Array) {
              for (const dataConfig of chartDataConfig) {
                const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, dataConfig.name, 'count', num_interval);
                mockChartDataList.push(mockChartDataValue);
              }
            } else {
              Object.keys(chartDataConfig).map(async key => {
                const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, chartDataConfig[key].name, 'count', num_interval);
                mockChartDataList.push(mockChartDataValue);
              });
            }
            const rawDatas = mockChartDataList; 
            if (depthBreakdown) {
              const parseData = getDepthData(rawDatas, chartDataConfig[0].args[0], chartDataConfig[0].args[1], chartDataConfig[0].args[2]) as any;
              const chartDataset: { [dataKey: string]: number } = {};
              for (const [areaName, areaData] of Object.entries(parseData[0])) {
                chartDataset[areaName] = (areaData as any).count;
              }
              const sortableChartDataset: { [dataKey: string]: number } = Object.keys(chartDataset)
              .sort((a, b) => (configDataService.DISPLAY_LANGUAGE.PIE_CHART_NAME[a] || a).localeCompare(configDataService.DISPLAY_LANGUAGE.PIE_CHART_NAME[b] || b))
              .reduce(
                (obj, key) => { 
                  obj[key] = chartDataset[key]; 
                  return obj;
                }, 
                {}
              );
              const chartLabel: string[] = Object.keys(sortableChartDataset);
              const dataPoints: number[] = Object.values(sortableChartDataset);
              const chartDatas: ChartDataSets[] = [{
                hoverBackgroundColor: chartLabel.map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                data: useCalPercentageFromData ? calPercentageFromData(dataPoints) : dataPoints, 
                backgroundColor: chartLabel.map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                borderColor: 'rgba(0, 0, 0, 0.5)',
              }];
              chartLabel$.next(chartLabel.map(key => configDataService.DISPLAY_LANGUAGE.PIE_CHART_NAME[key] || key));
              // chartLabel$.next(chartLabel);
              chartData$.next(chartDatas);
            }
            else if (useSelector) {
              const selectedDataSource = combinedResult[0] as { [selectorName: string]: GraphDataConfig };
              const chartDataLabelFlowChannel = [];
              const chartDatas: ChartDataSets[] = Object.entries(selectedDataSource).filter(([_dataName, graphDataConfig]) => {
                if (graphDataConfig.selected) {
                  return true;
                }
                return false;
              }).map(([dataName, graphDataConfig], index) => {
                // const filterData = Object.fromEntries(Object.entries(rawDatas[index]).filter(([key, _value]) => key !== '_total'));
                const depthData = getDepthData(rawDatas[index], graphDataConfig.args[0], graphDataConfig.args[1], graphDataConfig.args[2]);
                const dataFlowChannel = Object.keys(depthData).map(key => getDepthData(depthData, key));
                const chartData = isDatafromFlowChannel ? dataFlowChannel : Object.values(depthData);
                totalValue = chartData.reduce((a, b) => a + b, 0);
                chartDataLabelFlowChannel.push(...Object.keys(depthData));
                const splitDataName = dataName.split('_').map(n => n.toLocaleLowerCase());
                return {
                  hoverBackgroundColor: 
                    Object.keys(depthData).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : chartColor === 'CHART_SELECTOR_CONFIG' ? getColor(splitDataName[0] + '_' + k).backgroundColor :  CHART_CLASS_COLOR[k]),
                  // data: useCalPercentageFromData ? calPercentageFromData(chartData) : chartData, 
                  data: chartData, 
                  backgroundColor: 
                    Object.keys(depthData).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : chartColor === 'CHART_SELECTOR_CONFIG' ? getColor(splitDataName[0] + '_' + k).backgroundColor :  CHART_CLASS_COLOR[k]),
                  borderColor: 'rgba(0, 0, 0, 0.5)',
                };
              });
              const chartLabel = isDatafromFlowChannel ? chartDataLabelFlowChannel.sort(compareKey).map(key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] || key) : chartLabelTooltip.sort(compareKey).map(key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] || key);
              chartLabel$.next(chartLabel);
              chartData$.next(chartDatas);
            } else {
              const chartDataValues: number[] = [];
              let chartDatas: { [key: string]: number } = {};
              rawDatas.forEach((rawData, idx) => {
                const dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useSelector ? Object.values(chartDataConfig)[idx].args : chartDataConfig[idx]?.args; 
                const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => key !== '_total'));
                const data = getDepthData(filterData, dataConfig[0], dataConfig[1], dataConfig[2]);
                const labels = Object.keys(useDepthDataLabel ? data : filterData).sort(compareKey).map(key => mappedDisplayLanguage[chartDisplayLanguageConfig][key] || key);
                chartLabel$.next(labels);
                chartDataValues.push(...Object.values(data));
                chartDatas = data as { [key: string]: number };
              });
              const chartData: ChartDataSets[] = [{
                  hoverBackgroundColor: Object.keys(chartDatas).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                  data: useCalPercentageFromData ? calPercentageFromData(Object.values(chartDatas)) : Object.values(chartDatas), 
                  backgroundColor: Object.keys(chartDatas).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                  borderColor: 'rgba(0, 0, 0, 0.5)',
              }];
              chartData$.next(chartData);
            }
          }));
        } else {
          subscription.add(combineLatest(allDataBehaviourSubject$).subscribe((combinedResult) => {
            if (graphInfoPopoverByPin) {
              const infoPopoverText = graphInfoPopoverByPin;
              let infoPopoverPeriod = infoPopoverText.default;
              if(updateTextByPeriod){
                if(viewPeriodService.isLiveMode){
                    infoPopoverPeriod = infoPopoverText.default;
                }else if(viewPeriodService.isDayPeriod){
                    infoPopoverPeriod = infoPopoverText.day;
                }else if(viewPeriodService.isWeekPeriod){
                    infoPopoverPeriod = infoPopoverText.week;
                }else if(viewPeriodService.isMonthPeriod){
                    infoPopoverPeriod = infoPopoverText.month;
                }
              }
                comInstance.infoPopover = async (e: any) => {
                    const pieChartPopover = await popoverController.create({
                        component: isCustomInfoPopover ? tootipsComponentMapping(customInfoPopoverKey) : CustomTextTooltipsComponent,
                        componentProps: {
                            toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                            toolTipDetails: infoPopoverPeriod.details,
                        },
                        cssClass: 'customer-segment-details-popover',
                        event: e,
                    });
                    return await pieChartPopover.present();
                };
            }
            let selectedInteractableName: string;
            graphDataService.baseGraphData.selectedInteractable$.subscribe(selectedInteractable => {
              if (selectedInteractable) {
                selectedInteractableName = selectedInteractable.name; 
                if (includeAreaList.length > 0) {
                  comInstance.isShow = includeAreaList.includes(selectedInteractableName);
                }
              }
            });
            if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
              comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
            }
            combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$]).subscribe(([selectedDirectory, selectedLevel]) => {
              if (excludedDirectory !== undefined) {
                if (excludedDirectory === 'BUILDING') {
                  comInstance.isShow =  selectedDirectory?.floor !== 'ALL';
                }
                else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                  if (selectedDirectory?.floor === 'ALL' && excludedDirectory === 'FLOOR') {
                    comInstance.isShow = true;
                  } else {
                    comInstance.isShow = selectedLevel !== excludedDirectory;
                  }
                }
                // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') <excludedDirectory.length - 1) {
                  const excludeList = excludedDirectory.split('_AND_');
                  if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                    comInstance.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                  }
                  else if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                    comInstance.isShow = true;
                  } else {
                    comInstance.isShow = !excludeList.includes(selectedLevel);
                  }
                }
              }
              else if (includeAreaList.length > 0) {
                comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && includeAreaList.includes(selectedDirectory?.zone); 
              }
            });
            const vehicleFilteredSelected = graphDataService.selectedVehicleProfile$.getValue();
            if (toggleVehicleProfileSelector) {
              comInstance.isShow = vehicleFilteredSelected?.vehicle_type === undefined && vehicleFilteredSelected?.car_brand === undefined  && vehicleFilteredSelected?.purchasing_power === undefined;            
            }
            if (combinedResult.some(val => !val)) { 
              return;
            }
            if (useselectedDirectory) {
              const selectDirectory = combinedResult[0] as { building: string; floor: string; zone: string };
              const selectLevel = combinedResult[1] as 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA';
              if (!selectDirectory) {
                  comInstance.isShow = false;
                  return;
              }
              if (pieChartLockDate) {
                if (selectDirectory?.zone === '5d') {
                  viewPeriodService.selectedDate.isBetween('2021-03-14', '2021-03-17') ? isLock$.next(true) : (viewPeriodService.selectedDate.isBefore('2021-03-08') ? isLock$.next(true)  : isLock$.next(false) );
                } else if (selectDirectory?.zone === '4d' || selectDirectory?.zone === '6d') {
                    viewPeriodService.selectedDate.isBefore('2021-03-17') ? isLock$.next(true) : isLock$.next(false);
                } else {
                    isLock$.next(true);
                }            
              }
            }
            const rawDatas = useselectedDirectory || useSelector ? combinedResult.slice(1) as Record<string, any>[] : combinedResult as Record<string, any>[];
            if (depthBreakdown) {
              const parseData = getDepthData(rawDatas, chartDataConfig[0].args[0], chartDataConfig[0].args[1], chartDataConfig[0].args[2]) as any;
              const chartDataset: { [dataKey: string]: number } = {};
              for (const [areaName, areaData] of Object.entries(parseData[0])) {
                chartDataset[areaName] = (areaData as any).count;
              }
              const sortableChartDataset: { [dataKey: string]: number } = Object.keys(chartDataset)
              .sort((a, b) => (configDataService.DISPLAY_LANGUAGE.PIE_CHART_NAME[a] || a).localeCompare(configDataService.DISPLAY_LANGUAGE.PIE_CHART_NAME[b] || b))
              .reduce(
                (obj, key) => { 
                  obj[key] = chartDataset[key]; 
                  return obj;
                }, 
                {}
              );
              const chartLabel: string[] = Object.keys(sortableChartDataset);
              const dataPoints: number[] = Object.values(sortableChartDataset);
              const chartDatas: ChartDataSets[] = [{
                hoverBackgroundColor: chartLabel.map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                data: useCalPercentageFromData ? calPercentageFromData(dataPoints) : dataPoints, 
                backgroundColor: chartLabel.map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                borderColor: 'rgba(0, 0, 0, 0.5)',
              }];
              chartLabel$.next(chartLabel.map(key => configDataService.DISPLAY_LANGUAGE.PIE_CHART_NAME[key] || key));
              // chartLabel$.next(chartLabel);
              chartData$.next(chartDatas);
            }
            else if (useSelector) {
              const selectedDataSource = combinedResult[0] as { [selectorName: string]: GraphDataConfig };
              const chartDataLabelFlowChannel = [];
              const chartDatas: ChartDataSets[] = Object.entries(selectedDataSource).filter(([_dataName, graphDataConfig]) => {
                if (graphDataConfig.selected) {
                  return true;
                }
                return false;
              }).map(([dataName, graphDataConfig], index) => {
                // const filterData = Object.fromEntries(Object.entries(rawDatas[index]).filter(([key, _value]) => key !== '_total'));
                const depthData = getDepthData(rawDatas[index], graphDataConfig.args[0], graphDataConfig.args[1], graphDataConfig.args[2]);
                const dataFlowChannel = Object.keys(depthData).map(key => getDepthData(depthData, key));
                const chartData = isDatafromFlowChannel ? dataFlowChannel : Object.values(depthData);
                totalValue = chartData.reduce((a, b) => a + b, 0);
                chartDataLabelFlowChannel.push(...Object.keys(depthData));
                const splitDataName = dataName.split('_').map(n => n.toLocaleLowerCase());
                return {
                  hoverBackgroundColor: 
                    Object.keys(depthData).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : chartColor === 'CHART_SELECTOR_CONFIG' ? getColor(splitDataName[0] + '_' + k).backgroundColor :  CHART_CLASS_COLOR[k]),
                  // data: useCalPercentageFromData ? calPercentageFromData(chartData) : chartData, 
                  data: chartData, 
                  backgroundColor: 
                    Object.keys(depthData).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : chartColor === 'CHART_SELECTOR_CONFIG' ? getColor(splitDataName[0] + '_' + k).backgroundColor :  CHART_CLASS_COLOR[k]),
                  borderColor: 'rgba(0, 0, 0, 0.5)',
                };
              });
              const chartLabel = isDatafromFlowChannel ? chartDataLabelFlowChannel.sort(compareKey).map(key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] || key) : chartLabelTooltip.sort(compareKey).map(key => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[key] || key);
              chartLabel$.next(chartLabel);
              chartData$.next(chartDatas);
            } else {
              const chartDataValues: number[] = [];
              let chartDatas: { [key: string]: number } = {};
              rawDatas.forEach((rawData, idx) => {
                const dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : useSelector ? Object.values(chartDataConfig)[idx].args : chartDataConfig[idx]?.args; 
                const filterData = Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => key !== '_total'));
                const data = getDepthData(filterData, dataConfig[0], dataConfig[1], dataConfig[2]);
                const labels = Object.keys(useDepthDataLabel ? data : filterData).sort(compareKey).map(key => mappedDisplayLanguage[chartDisplayLanguageConfig][key] || key);
                chartLabel$.next(labels);
                chartDataValues.push(...Object.values(data));
                chartDatas = data as { [key: string]: number };
              });
              const chartData: ChartDataSets[] = [{
                  hoverBackgroundColor: Object.keys(chartDatas).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                  data: useCalPercentageFromData ? calPercentageFromData(Object.values(chartDatas)) : Object.values(chartDatas), 
                  backgroundColor: Object.keys(chartDatas).map((k, idx) => chartColor === 'DEFAULT' ? PIE_CHART_COLOR_LIST[idx] : chartColor === 'CHART_CONFIG' ? getColor(k).backgroundColor : CHART_CLASS_COLOR[k]),
                  borderColor: 'rgba(0, 0, 0, 0.5)',
              }];
              chartData$.next(chartData);
            }
          }));
        }


        chartOption$.next({
            responsive: true,
            // maintainAspectRatio: false,
            aspectRatio: isScreenSmallerThanLG(window) ? 1.25 : 2,
            // aspectRatio: isScreenSmallerThanLG(window) ? 0.75 : 1.25,
            animation: {
                duration: 1000,
                easing: 'easeOutCubic',
            },
            responsiveAnimationDuration: 1000,
            layout: {
                padding: {
                  top: 0,
                  left: 5,
                  right: 5,
                  bottom: 10,
                }
            },
            tooltips: {
              // enabled: !isCustomInfoPopover,
              // custom: (tooltipModel) => {
              //   if (!isCustomInfoPopover) { return; }
              //   console.log(tooltipModel, this);
              //   showGenderCustomTooltips(tooltipModel, this, configDataService.genderImageSrc);
              //   if (tooltipModel.opacity === 0) {
              //     return;
              //   }
              //   const tooltipEl = document.getElementById('chartjs-tooltip');
              //   if (tooltipEl) {
              //     tooltipEl.classList.add('popover');
              //   }
              // },
              callbacks: {
                label: (tooltipItem, data) => {
                  const datasetIndex = tooltipItem.datasetIndex;
                  const totalLengthLabel = data.labels.length - 1;
                  const datasetLabel = data.labels[totalLengthLabel - tooltipItem.index];
                  const datasetData = data.datasets[datasetIndex].data[tooltipItem.index];
                  if (isShowPercentageFromCalData && useCalPercentageFromData) {
                    const dataFromPercent = Math.round(((datasetData as number / totalValue) * 100));
                    const formattingPercent = dataFromPercent > 1 ? `${dataFromPercent}%` : '<1%';
                    return `${datasetLabel}: ${presentNumberValueTxt(datasetData as number, 1, 'suffix')} (${formattingPercent})`;
                  }
                  return `${datasetLabel}: ${presentNumberValueTxt(datasetData as number, 1, isShowPercent ? 'percent' : 'suffix')}`;
                },
                labelColor: (tooltipItem, chart) => {
                    const chartLabelColor = chart.data.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index] as ChartColor;
                    return {
                        borderColor: chartLabelColor,
                        backgroundColor: chartLabelColor,
                    };
                }
              },
              mode: 'nearest',
              titleAlign: 'center',
              titleFontSize: isScreenSmallerThanLG(window) ? 18 : 18,
              bodyFontSize: isScreenSmallerThanLG(window) ? 14 : 16,
            },
            legend: {
              labels: {
                generateLabels: (chart) => {
                  const data = chart.data;
                  if(adjustLegendSizing){
                    chart.aspectRatio = isScreenSmallerThanLG(window) ? 1 : 1.25;
                  }else{
                    chart.aspectRatio = isScreenSmallerThanLG(window) ? 1.25 : 2;
                  }
                  chart.config.options.legend.labels.fontSize = isScreenSmallerThanLG(window) ? 12 : 14;
                  chart.config.options.legend.labels.boxWidth = isScreenSmallerThanLG(window) ? 12 : 14;
                  if (data.labels.length > 0 && data.datasets[0].data.length > 0) {
                    const totalLengthLabel = data.labels.length - 1;
                    // const datasets = data.datasets[0].data as number[];
                    const labels = data.labels;
                    const backgroundColors = (data.datasets[0].backgroundColor as ChartColor[]);
                    return labels.map((label, i) => ({
                        text: label ? label as string : '',
                        fillStyle: backgroundColors[totalLengthLabel - i] ? (backgroundColors[totalLengthLabel - i] as string) : '',
                        index: totalLengthLabel - i,
                        hidden: false 
                      })
                    );
                  }
                },
                fontColor: '#fff'
              },
            },
            plugins: {
              datalabels: {
                color: 'white',
                display: (context) => {
                  if(hidePercentData){
                    return false;
                  } else if (!isShowDataLabels) {
                    return true;
                  }
                  const avgSize = Math.round((context.chart.height + context.chart.width) / 2);
                  const size = avgSize / 8;
                  return isScreenSmallerThanLG(window) ? isShowPercent ? context.dataset.data[context.dataIndex] as number >= 10 : context.dataset.data[context.dataIndex] as number > size : context.dataset.data[context.dataIndex] as number > 1;
                },
                /*align: 'end', // default -> 'center' 
                anchor: 'end', // default -> 'center' 
                offset: 0, // default -> 4*/
                formatter: (value, context) => {
                  if (!value) {
                    return;
                  }
                  if (isShowPercent) {
                    return presentNumberValueTxt(value as number, 1, 'percent');
                  }
                  return presentNumberValueTxt(value as number, 1, 'suffix');
                },
                font: (context) => {
                  const avgSize = Math.round((context.chart.height + context.chart.width) / 2);
                  const size = Math.round(avgSize / 18);
                  const newSize = size > 14 ? 14 : size; // setting max limit to 14
                  return {
                    size: newSize,
                    weight: 'bold'
                  };
                },
                clamp: true,
              },
            }
        });

        const tootipsComponentMapping = (componentName: string) => {
          if (componentName === 'VEHICLE_PURCHASING_POWER') {
            return VehiclePurchasingPowerTooltipsComponent;
          }
          if (componentName === 'GENDER_PROFILE') {
            return CustomerGenderTooltipsComponent;
          }
        };

        if (graphInfoPopoverByPin) {
          const infoPopoverText = graphInfoPopoverByPin;
          let infoPopoverPeriod = infoPopoverText.default;
          if (updateTextByPeriod) {
            if (viewPeriodService.isLiveMode) {
              infoPopoverPeriod = infoPopoverText.default;
            } else if (viewPeriodService.isDayPeriod) {
              infoPopoverPeriod = infoPopoverText.day;
            } else if(viewPeriodService.isWeekPeriod) {
              infoPopoverPeriod = infoPopoverText.week;
            } else if (viewPeriodService.isMonthPeriod) {
              infoPopoverPeriod = infoPopoverText.month;
            }
          }
            comInstance.infoPopover = async (e: any) => {
                const pieChartPopover = await popoverController.create({
                    component: isCustomInfoPopover ? tootipsComponentMapping(customInfoPopoverKey) : CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: infoPopoverPeriod.details,
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await pieChartPopover.present();
            };
        }
        if (isShowLegend !== undefined) {
          comInstance.isShowLegend = isShowLegend;
        } else {
          comInstance.isShowLegend = true;
        }
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.chartOptions$ = chartOption$;
        comInstance.chartLabel$ = chartLabel$;
        comInstance.unitType = unitType;
        comInstance.data$ = chartData$;
        comInstance.isLock = isLock$;
        comInstance.isShowLegendOnPercent = isShowPercent;
        comInstance.inputLockText = inputLockText;
        comInstance.overrideShouldShowSelector = useSelector;
        comInstance.selector = dynamicGraphDataSelector;
        comInstance.selectedPie$ = selectedDataSource$;
        comInstance.isEnableTBD = isEnableTBD;
        comInstance.showAveragePerDay = showAveragePerDay;
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        return comInstance;
    }
    
}


