<ion-grid style="margin: 20px 20px;">
  <ion-row>
    <ion-col size-xs="12" style="margin-bottom: 10px;">
      <span style="font-weight: 700; font-size: 18px;">Age Definition</span>
    </ion-col>
  </ion-row>
  <ion-row class="table-header ion-text-center">
    <ion-col size="3" class="cell-border ion-align-self-center">
      {{ childrenText }}
    </ion-col>
    <ion-col size="2" class="cell-border ion-align-self-center">
      {{ teenagersText }}
    </ion-col>
    <ion-col size="2" class="cell-border ion-align-self-center">
      {{ youngAdultsText }}
    </ion-col>
    <ion-col size="2" class="cell-border ion-align-self-center">
      {{ adultsText }}
    </ion-col>
    <ion-col size="3" class="cell-border ion-align-self-center">
      {{ seniorsText }}
    </ion-col>
  </ion-row>
  <ion-row class="table-content ion-text-center ion-align-items-center">
    <ion-col size="3" class="cell-border ion-align-self-center">
      0-12
    </ion-col>
    <ion-col size="2" class="cell-border ion-align-self-center">
      13-22
    </ion-col>
    <ion-col size="2" class="cell-border ion-align-self-center">
      23-35
    </ion-col>
    <ion-col size="2" class="cell-border ion-align-self-center">
      36-54
    </ion-col>
    <ion-col size="3" class="cell-border ion-align-self-center">
      > 55
    </ion-col>
  </ion-row>
</ion-grid>
