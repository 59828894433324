import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CustomerAgeTooltipsComponent } from '../pages/home/general/customer-age-tooltips/customer-age-tooltips.component';
import { showAgeCustomTooltips } from '../helpers/ageTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { accessDepthData, isScreenSmallerThanMD } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class DynamicAgeProfileResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        const includeAreaList = (getAdditionalInput(additionalInput, 'includeAreaList') || []) as string[];
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE';
        const useselectedInteractable = (getAdditionalInput(additionalInput, 'useSelectedInteractable') || false) as boolean;
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        const getDepthData = (
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
            gateName?: string
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined && gateName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0, gateName);
            }
            // if (Object.keys(retData).includes('entrance')) {
            //     retData = getEntraceExitData(true, retData as EntraceExitData);
            // }
            return retData as { male: number[]; female: number[] };
        };
        
        // await configDataService.loadAppConfig();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        const chartLabel = configDataService.AGE_CLASS.map(age => configDataService.DISPLAY_LANGUAGE.AGE_CLASS[age]);
        const initialSelectedDirectory = graphDataService.baseGraphData.selectedDirectory$.getValue() || { building: configDataService.MAIN_BUILDING, floor: 'ALL', zone: '' };
        const initalSelectedLevel = graphDataService.baseGraphData.selectedLevel$.getValue();
        const initialAgeData = useselectedInteractable ? graphDataService.zoneAgeProfileByPinBreakdown$.getValue()
        : initialSelectedDirectory?.floor === 'ALL' ?  graphDataService.ageProfileData$.getValue()
        : initalSelectedLevel === 'ZONE' ? graphDataService.allZoneAgeProfileBreakdown$.getValue() : graphDataService.ageProfileData$.getValue();
        const initialFlattenData = useselectedInteractable ? getDepthData(initialAgeData) : initalSelectedLevel === 'ZONE' ? getDepthData(initialAgeData, initialSelectedDirectory.zone) : getDepthData(initialAgeData);

        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: initialFlattenData?.female || [],
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
            calPercentFrommAllData: true
        }, {
            data: initialFlattenData?.male || [],
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
            calPercentFrommAllData: true
        }]);
        subscription.add(combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, graphDataService.ageProfileData$, graphDataService.allZoneAgeProfileBreakdown$, graphDataService.zoneAgeProfileByPinBreakdown$])
        .subscribe(([selectedDirectory, selectedLevel, ageProfileBreakdown, allZoneAgeProfileBreakdown, zoneAgeProfileByPinBreakdown]) => {
            const ageData = useselectedInteractable ? zoneAgeProfileByPinBreakdown : selectedDirectory?.floor === 'ALL' ? ageProfileBreakdown : selectedLevel === 'ZONE' ? allZoneAgeProfileBreakdown : ageProfileBreakdown;
            if (!ageData) { return; }
            // const selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
            // if (includeAreaList.length > 0 && selectedInteractableName) {
            //     comInstance.isShow = includeAreaList.includes(selectedInteractableName);
            // }
            const flattenData = useselectedInteractable ? getDepthData(ageData) : selectedLevel === 'ZONE' ? getDepthData(ageData, selectedDirectory.zone) : getDepthData(ageData);
            if (excludedDirectory) {
                comInstance.isShow = selectedDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : selectedLevel !== excludedDirectory;
            }
            dualBarData$.next([{
                data: flattenData.female,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                calPercentFrommAllData: true
            }, {
                data: flattenData.male,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                calPercentFrommAllData: true
            }]);
        }));

        comInstance.title = configDataService.DISPLAY_LANGUAGE.AGE_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.infoPopover = async (e: any) => {
            const ageDetailPopover = await popoverController.create({
                component: CustomerAgeTooltipsComponent,
                cssClass: 'calendar-criteria-tooltips',
                event: e
            });
            return ageDetailPopover.present();
        };
        if (excludedDirectory) {
            comInstance.isShow = initialSelectedDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : initalSelectedLevel !== excludedDirectory;
        }
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.4;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showAgeCustomTooltips(tooltipModel, this, configDataService.ageImageSrc);
        };
        return comInstance;
    }

}
