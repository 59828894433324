<ng-container *ngIf="data$ | async as currentData">
  <ion-row>
    <ion-col *ngIf="isShow$ | async">
      <ion-card appLockIcon [isLock]="isLock" style="margin-right: 2.5px !important;">
        <ion-card-header style="padding-right: 4%;">
          <ion-card-title>
            <!-- <label class="secondary-text" style="float: right; border-radius: 2px; background: red; padding: 0.1rem;">
                  •LIVE
                </label> -->
            {{ title }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content appLockBlur [isLock]="isLock" class="card-content-text-large ion-text-center ion-no-padding">
          <app-gauge-chart
            [relativeWidth]="0.9"
            [needleValue]="(currentData.current / currentData.max) * 100"
            [centralLabel]="currentData.current.toLocaleString()"
            [options]="gagueChartOption"
            [textColor]="gagueChartOption.textColor.value"
          >
          </app-gauge-chart>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ng-container>
