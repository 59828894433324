import { Component, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-customer-messenger-group-tooltips',
  templateUrl: './customer-messenger-group-tooltips.component.html',
  styleUrls: ['./customer-messenger-group-tooltips.component.scss'],
})
export class CustomerMessengerGroupTooltipsComponent implements OnInit {

  toolTipTitle: string;
  toolTipDetails: string[];

  classList: string[];

  constructor(
    public configDataService: ConfigDataService
  ) { }

  ngOnInit() {
    this.classList = this.configDataService.MESSENGER_GROUP_CLASS_LIST.slice(1);
  }

  getImageSrc(segment, index) {
    const brandKey = this.configDataService.MESSENGER_GROUP_CLASS[segment][index - 1];
    if (brandKey === undefined || brandKey === 'kerry') {
      return;
    }
    const parseBrandName = this.configDataService.DISPLAY_LANGUAGE.MESSENGER_CLASS[brandKey];
    return this.configDataService.messengerLogoImageSrc[parseBrandName];
  }

}
