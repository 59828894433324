import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { PopoverController } from '@ionic/angular';
import { GenericPieChartData } from '../objects/chart';
import { CHART_ASIAN_COLOR, CHART_LOCAL_COLOR, CHART_NON_ASIAN_COLOR, CHART_TOURISTS_COLOR } from '../configs/color';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class EstimateTouristResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();

        const pieData$ = new BehaviorSubject<GenericPieChartData[]>([{
            data: graphDataService.localTouristData$.value.asian,
            color: CHART_ASIAN_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.asian
        }, {
            data: graphDataService.localTouristData$.value.non_asian,
            color: CHART_NON_ASIAN_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.non_asian
        }, {
            data: graphDataService.localTouristData$.value.local,
            color: CHART_LOCAL_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.local
        }]);
        subscription.add(graphDataService.localTouristData$.subscribe(localTourist => {
            pieData$.next([{
                data: localTourist.asian,
                color: CHART_ASIAN_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.asian
            }, {
                data: localTourist.non_asian,
                color: CHART_NON_ASIAN_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.non_asian
            }, {
                data: localTourist.local,
                color: CHART_LOCAL_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS.local
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.ESTIMATED_TOURISTS;
        comInstance.isLock = this.isLock;
        // comInstance.infoPopover = async (e: any) => {
        //     const touristsPopover = await popoverController.create({
        //         component: TouristsTooltipsComponent,
        //         cssClass: 'calendar-criteria-tooltips',
        //         event: e
        //     });
        //     return touristsPopover.present();
        // };
        comInstance.isShowLegend = true;
        comInstance.data$ = pieData$;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this);
        };
        return comInstance;
    }

}
