import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';

import { GaugeChartComponent } from './general/gauge-chart/gauge-chart.component';
import { TrendLineChartComponent } from './general/trend-line-chart/trend-line-chart.component';
import { MultiLineChartComponent } from './general/multi-line-chart/multi-line-chart.component';
import { HorizontalBarChartComponent } from './general/horizontal-bar-chart/horizontal-bar-chart.component';
import { PageHeaderComponent } from './general/page-header/page-header.component';
import { NoteListPopoverComponent } from './general/note-list-popover/note-list-popover.component';
import { AddNotePopoverComponent } from './general/add-note-popover/add-note-popover.component';
import { LiveWarningHeaderComponent } from './general/live-warning-header/live-warning-header.component';
import { DatePickerToolbarComponent } from './general/date-picker-toolbar/date-picker-toolbar.component';
import { StackedVerticalBarChartComponent } from './general/stacked-vertical-bar-chart/stacked-vertical-bar-chart.component';
import { StackedHorizontalBarChartComponent } from './general/stacked-horizontal-bar-chart/stacked-horizontal-bar-chart.component';
import { PurchaseBagTooltipsComponent } from './general/purchase-bag-tooltips/purchase-bag-tooltips.component';
import { MaskCustomPiechartComponent } from './general/mask-custom-piechart/mask-custom-piechart.component';
import { MinuteSecondsPipe } from '../pipes/minute-seconds.pipe';
import { SecondsPipe } from '../pipes/seconds.pipe';
import { HoursPipe } from '../pipes/hours.pipe';
import { HighlightsMallTrafficLineChartComponent } from './general/highlights-mall-traffic-line-chart/highlights-mall-traffic-line-chart.component';
import { PopularTimesLineChartComponent } from './general/popular-times-line-chart/popular-times-line-chart.component';
import { ZoneTrendLineChartComponent } from './general/zone-trend-line-chart/zone-trend-line-chart.component';
import { NoInternetWarningComponent } from './general/no-internet-warning/no-internet-warning.component';
import { FloorTrendLineChartComponent } from './general/floor-trend-line-chart/floor-trend-line-chart.component';
import { GlobalErrorhandler } from './general/global-error-handler/global-error-handler';
import { FunnelChart } from './general/funnel-chart/funnel-chart.component';

import { NumberSuffixPipe } from 'src/app/pipes/number-suffix.pipe';
import { PercentSuffixPipe } from 'src/app/pipes/percent-suffix.pipe';
import { RoundNumberPipe } from 'src/app/pipes/round-number.pipe';

import { FloorSummaryComponent } from './general/traffic-details/general/floor-summary/floor-summary.component';
import { StoreSummaryComponent } from './general/traffic-details/general/store-summary/store-summary.component';
import { ZoneTrafficTrendComponent } from './general/traffic-details/general/zone-traffic-trend/zone-traffic-trend.component';
import { ZoneAverageTimeTrendComponent } from './general/traffic-details/general/zone-average-time-trend/zone-average-time-trend.component';
import { WalkingTrafficTooltipsComponent } from './general/traffic-details/general/store-summary/walking-traffic-tooltips/walking-traffic-tooltips.component';
import { PriceRecommendationTooltipsComponent } from './general/traffic-details/general/store-summary/price-recommendation-tooltips/price-recommendation-tooltips.component';
import { ShelfSummaryComponent } from './general/traffic-details/store/shelf-summary/shelf-summary.component';
import { StoreFloorSummaryComponent } from './general/traffic-details/store/store-floor-summary/store-floor-summary.component';
import { AvgEngagementTimeComponent } from './general/traffic-details/store/shelf-summary/avg-engagement-time/avg-engagement-time.component';
import { NetShelfEngagementTrendComponent } from './general/traffic-details/store/shelf-summary/net-shelf-engagement-trend/net-shelf-engagement-trend.component';
import { UniqueEngagementComponent } from './general/traffic-details/store/shelf-summary/unique-engagement/unique-engagement.component';
import { TrafficDetailsComponent } from './general/traffic-details/traffic-details.component';
import { IndicatorDetailComponent } from './general/traffic-details/general/indicator-detail/indicator-detail.component';
import { StoreEditComponent } from './general/traffic-details/general/store-edit/store-edit.component';
import { ShelfEditComponent } from './general/traffic-details/store/shelf-edit/shelf-edit.component';
import { LockBlurDirective } from '../directives/lock-blur.directive';
import { LockIconDirective } from '../directives/lock-icon.directive';
import { DataTbdDirective } from '../directives/data-tbd.directive';
import { GaugeChartModule } from 'angular-gauge-chart';
import { GenericLineChartComponent } from './general/generic-line-chart/generic-line-chart.component';
import { DrawerComponent } from './general/drawer/drawer.component';
import { TrafficSettingComponent } from './general/traffic-setting/traffic-setting.component';
import { TrafficClickableInfoComponent } from './general/traffic-clickable-info/traffic-clickable-info.component';
import { OverviewPopularTimesComponent } from './general/overview-popular-times/overview-popular-times.component';
import { DynamicLineChartWrapperComponent } from './dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { DynamicNumberCardComponent } from './dynamic/dynamic-number-card/dynamic-number-card.component';
import { DynamicGraphContainerComponent } from './dynamic/dynamic-graph-container/dynamic-graph-container.component';
import { DynamicPieChartWrapperComponent } from './dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { GenericPieChartComponent } from './general/generic-pie-chart/generic-pie-chart.component';
import { DynamicBarChartWrapperComponent } from './dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { GenericBarChartComponent } from './general/generic-bar-chart/generic-bar-chart.component';
import { DynamicItemDividerComponent } from './dynamic/dynamic-item-divider/dynamic-item-divider.component';
import { GenericHeatmapChartComponent } from './general/generic-heatmap-chart/generic-heatmap-chart.component';
import { DynamicHeatmapWrapperComponent } from './dynamic/dynamic-heatmap-wrapper/dynamic-heatmap-wrapper.component';
import { DynamicDualNumberCardComponent } from './dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { DynamicGaugeChartWrapperComponent } from './dynamic/dynamic-gauge-chart-wrapper/dynamic-gauge-chart-wrapper.component';
import { DynamicGraphGroupDropdownComponent } from './dynamic/dynamic-graph-group-dropdown/dynamic-graph-group-dropdown.component';
import { DynamicSankeyWrapperComponent } from './dynamic/dynamic-sankey-wrapper/dynamic-sankey-wrapper.component';
import { SankeyChartComponent } from './general/sankey-chart/sankey-chart.component';
import { DynamicProgressNumberCardComponent } from './dynamic/dynamic-progress-number-card/dynamic-progress-number-card.component';
import { ReportHeaderComponent } from './general/report-header/report-header.component';
import { GenericStackBarChartComponent } from './general/generic-stack-bar-chart/generic-stack-bar-chart.component';
import { DynamicStackBarChartComponent } from './dynamic/dynamic-stack-bar-chart/dynamic-stack-bar-chart.component';
import { GenericCustomLineChartComponent } from './general/generic-custom-line-chart/generic-custom-line-chart.component';
import { DynamicCustomLineChartComponent } from './dynamic/dynamic-custom-line-chart/dynamic-custom-line-chart.component';
import { GenericCustomBarChartComponent } from './general/generic-custom-bar-chart/generic-custom-bar-chart.component';
import { DynamicCustomBarChartComponent } from './dynamic/dynamic-custom-bar-chart/dynamic-custom-bar-chart.component';
import { DataStatusWarningComponent } from './general/data-status-warning/data-status-warning.component';
import { BoldPipe } from '../pipes/bold.pipe';
import { TabsNavigatorComponent } from './general/tabs-navigator/tabs-navigator.component';
import { ExpandedCardComponent } from './general/expanded-card/expanded-card.component';
import { GenericCustomPieChartComponent } from './general/generic-custom-pie-chart/generic-custom-pie-chart.component';
import { DynamicCustomPieChartComponent } from './dynamic/dynamic-custom-pie-chart/dynamic-custom-pie-chart.component';
import { CustomTooltipPopoverComponent } from './general/custom-tooltip-popover/custom-tooltip-popover.component';
import { VerticalTrafficDetailsComponent } from './general/vertical-traffic-details/vertical-traffic-details.component';
import { UpdatedWarningHeaderComponent } from './general/updated-warning-header/updated-warning-header.component';
import { OrganizationSelectorPopoverComponent } from './general/organization-selector-popover/organization-selector-popover.component';
import { VehiclePurchasingPowerTooltipsComponent } from './general/vehicle-purchasing-power-tooltips/vehicle-purchasing-power-tooltips.component';
import { DynamicDividerSelectorComponent } from './dynamic/dynamic-divider-selector/dynamic-divider-selector.component';
import { VisualListViewComponent } from './general/visual-list-view/visual-list-view.component';
import { GenericSankeyChartComponent } from './general/generic-sankey-chart/generic-sankey-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { GenericHeatmapEchartComponent } from './general/generic-heatmap-echart/generic-heatmap-echart.component';
import { DynamicCustomHeatmapWrapperComponent } from './dynamic/dynamic-custom-heatmap-wrapper/dynamic-custom-heatmap-wrapper.component';
import { BillboardDrawerComponent } from './general/billboard-drawer/billboard-drawer.component';
import { AdsPlannerPopupComponent } from './general/ads-planner-popup/ads-planner-popup.component';
import { MediaGalleryModalComponent } from './general/media-gallery-modal/media-gallery-modal.component';
import { GenericEchartComponent } from './general/generic-echart/generic-echart.component';
import { DynamicEchartComponent } from './dynamic/dynamic-echart/dynamic-echart.component';

@NgModule({
  declarations: [
    TrendLineChartComponent,
    MultiLineChartComponent,
    HorizontalBarChartComponent,
    PageHeaderComponent,
    NoteListPopoverComponent,
    NoteListPopoverComponent,
    AddNotePopoverComponent,
    LiveWarningHeaderComponent,
    DatePickerToolbarComponent,
    StackedVerticalBarChartComponent,
    StackedHorizontalBarChartComponent,
    PurchaseBagTooltipsComponent,
    MaskCustomPiechartComponent,
    MinuteSecondsPipe,
    SecondsPipe,
    HoursPipe,
    HighlightsMallTrafficLineChartComponent,
    PopularTimesLineChartComponent,
    ZoneTrendLineChartComponent,
    NoInternetWarningComponent,
    GaugeChartComponent,
    FloorTrendLineChartComponent,
    FunnelChart,
    NumberSuffixPipe,
    PercentSuffixPipe,
    RoundNumberPipe,
    OverviewPopularTimesComponent,
    // Traffic
    FloorSummaryComponent,
    StoreSummaryComponent,
    ZoneTrafficTrendComponent,
    ZoneAverageTimeTrendComponent,
    WalkingTrafficTooltipsComponent,
    PriceRecommendationTooltipsComponent,
    ShelfSummaryComponent,
    StoreFloorSummaryComponent,
    AvgEngagementTimeComponent,
    NetShelfEngagementTrendComponent,
    UniqueEngagementComponent,
    TrafficDetailsComponent,
    IndicatorDetailComponent,
    StoreEditComponent,
    ShelfEditComponent,
    LockBlurDirective,
    LockIconDirective,
    DataTbdDirective,
    DrawerComponent,
    TrafficSettingComponent,
    TrafficClickableInfoComponent,
    GenericLineChartComponent,
    GenericPieChartComponent,
    DynamicGraphContainerComponent,
    DynamicLineChartWrapperComponent,
    DynamicNumberCardComponent,
    DynamicPieChartWrapperComponent,
    DynamicBarChartWrapperComponent,
    GenericBarChartComponent,
    DynamicItemDividerComponent,
    GenericHeatmapChartComponent,
    DynamicHeatmapWrapperComponent,
    DynamicDualNumberCardComponent,
    DynamicGaugeChartWrapperComponent,
    DynamicGraphGroupDropdownComponent,
    DynamicSankeyWrapperComponent,
    SankeyChartComponent,
    DynamicProgressNumberCardComponent,
    ReportHeaderComponent,
    GenericStackBarChartComponent,
    DynamicStackBarChartComponent,
    GenericCustomLineChartComponent,
    DynamicCustomLineChartComponent,
    GenericCustomBarChartComponent,
    DynamicCustomBarChartComponent,
    DataStatusWarningComponent,
    TabsNavigatorComponent,
    ExpandedCardComponent,
    GenericCustomPieChartComponent,
    DynamicCustomPieChartComponent,
    CustomTooltipPopoverComponent,
    VerticalTrafficDetailsComponent,
    UpdatedWarningHeaderComponent,
    OrganizationSelectorPopoverComponent,
    VehiclePurchasingPowerTooltipsComponent,
    DynamicDividerSelectorComponent,
    VisualListViewComponent,
    GenericSankeyChartComponent,
    GenericHeatmapEchartComponent,
    DynamicCustomHeatmapWrapperComponent,
    BillboardDrawerComponent,
    AdsPlannerPopupComponent,
    MediaGalleryModalComponent,
    GenericEchartComponent,
    DynamicEchartComponent,
    // pipe
    BoldPipe
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    ChartsModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    GaugeChartModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  entryComponents: [
    NoteListPopoverComponent,
    AddNotePopoverComponent,
    // traffic
    WalkingTrafficTooltipsComponent,
    PriceRecommendationTooltipsComponent
  ],
  exports: [
    TrendLineChartComponent,
    MultiLineChartComponent,
    HorizontalBarChartComponent,
    PageHeaderComponent,
    NoteListPopoverComponent,
    LiveWarningHeaderComponent,
    DatePickerToolbarComponent,
    StackedVerticalBarChartComponent,
    StackedHorizontalBarChartComponent,
    PurchaseBagTooltipsComponent,
    MaskCustomPiechartComponent,
    MinuteSecondsPipe,
    SecondsPipe,
    HoursPipe,
    HighlightsMallTrafficLineChartComponent,
    PopularTimesLineChartComponent,
    ZoneTrendLineChartComponent,
    NoInternetWarningComponent,
    GaugeChartComponent,
    FloorTrendLineChartComponent,
    FunnelChart,
    NumberSuffixPipe,
    PercentSuffixPipe,
    RoundNumberPipe,
    OverviewPopularTimesComponent,
    // Traffic
    FloorSummaryComponent,
    StoreSummaryComponent,
    ZoneTrafficTrendComponent,
    ZoneAverageTimeTrendComponent,
    WalkingTrafficTooltipsComponent,
    PriceRecommendationTooltipsComponent,
    ShelfSummaryComponent,
    StoreFloorSummaryComponent,
    AvgEngagementTimeComponent,
    NetShelfEngagementTrendComponent,
    UniqueEngagementComponent,
    TrafficDetailsComponent,
    IndicatorDetailComponent,
    StoreEditComponent,
    ShelfEditComponent,
    LockBlurDirective,
    LockIconDirective,
    DataTbdDirective,
    DrawerComponent,
    TrafficSettingComponent,
    TrafficClickableInfoComponent,
    GenericLineChartComponent,
    GenericPieChartComponent,
    DynamicGraphContainerComponent,
    DynamicLineChartWrapperComponent,
    DynamicNumberCardComponent,
    DynamicPieChartWrapperComponent,
    DynamicBarChartWrapperComponent,
    GenericBarChartComponent,
    DynamicItemDividerComponent,
    GenericHeatmapChartComponent,
    DynamicHeatmapWrapperComponent,
    DynamicDualNumberCardComponent,
    DynamicGaugeChartWrapperComponent,
    DynamicGraphGroupDropdownComponent,
    DynamicSankeyWrapperComponent,
    SankeyChartComponent,
    DynamicProgressNumberCardComponent,
    ReportHeaderComponent,
    GenericStackBarChartComponent,
    DynamicStackBarChartComponent,
    GenericCustomLineChartComponent,
    DynamicCustomLineChartComponent,
    GenericCustomBarChartComponent,
    DynamicCustomBarChartComponent,
    DataStatusWarningComponent,
    TabsNavigatorComponent,
    ExpandedCardComponent,
    GenericCustomPieChartComponent,
    DynamicCustomPieChartComponent,
    CustomTooltipPopoverComponent,
    VerticalTrafficDetailsComponent,
    UpdatedWarningHeaderComponent,
    OrganizationSelectorPopoverComponent,
    VehiclePurchasingPowerTooltipsComponent,
    DynamicDividerSelectorComponent,
    VisualListViewComponent,
    GenericSankeyChartComponent,
    GenericHeatmapEchartComponent,
    DynamicCustomHeatmapWrapperComponent,
    BillboardDrawerComponent,
    AdsPlannerPopupComponent,
    MediaGalleryModalComponent,
    GenericEchartComponent,
    DynamicEchartComponent,
    // pipe
    BoldPipe
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorhandler,
    },
  ]
})
export class SharedModule { }
