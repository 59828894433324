import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HeatmapData } from 'src/app/objects/chart';

@Component({
  selector: 'app-generic-heatmap-chart',
  templateUrl: './generic-heatmap-chart.component.html',
  styleUrls: ['./generic-heatmap-chart.component.scss'],
})
export class GenericHeatmapChartComponent implements OnInit, AfterViewInit, OnDestroy{
  @Input() data$: BehaviorSubject<HeatmapData[]>;
  // options
  @Input() showXAxis = true;
  @Input() showYAxis = true;
  @Input() gradient = false;
  @Input() showLegend = false;
  @Input() legendPosition = 'below';
  @Input() showXAxisLabel = false;
  @Input() showYAxisLabel = false;
  @Input() colorScheme = {
    name: 'custom',
    domain: ['#E7EEF9', '#D3E0F8', '#b0c8f8', '#9EBBF7', '#346DF3', '#1758f0', '#044ef8', '#013ece', '#002e99', '#00216e']
  };
  @Input() heighPerCell = 2.5;

  // @ViewChild('heatmapChart') heatmapChart: HeatMapComponent;
  @ViewChild('heatmapContainer') heatmapContainer: ElementRef;
  private subscription = new Subscription();

  constructor() { }

  ngOnInit(): void {}
  
  ngAfterViewInit(): void {
    this.subscription.add(this.data$.subscribe(heatmapData => {
      if (!heatmapData[0]) { return; }
      const heatmapContainer = (this.heatmapContainer.nativeElement as HTMLDivElement);
      heatmapContainer.style.height = `${this.heighPerCell * (heatmapData[0].series.length + 1.5)}em`;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
