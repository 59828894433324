import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { GenericBarChartData } from '../objects/chart';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput, GraphInfoPopover } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData, getSortIndice, isShowGraph } from '../helpers/util';
import { SelectableBarChartDataName } from '../objects/selectableData';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { showGenericBarChartCustomTooltips } from '../helpers/barChartTooltips';
import { AuthenticationService } from '../services/authentication.service';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { generateNestedData } from '../helpers/mock-data-generator';

export class DynamicBarChartSelectableResolver extends GraphResolverBase {
    // TODO: need refactor sorting process
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService,
    ) {
        // await configDataService.loadAppConfig();
        // const channel = (getAdditionalInput(additionalInput, 'channel') || 'auto') as 'entrance' | 'exit' | 'auto';
        const graphState = (getAdditionalInput(additionalInput, 'graphState') || 'ENABLE') as 'ENABLE' | 'DISABLE' | 'LOCK' | 'LOCK_COND';
        const useSelector = getAdditionalInput(additionalInput, 'useSelector') as boolean;
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey')) as string;
        const barChartDataSelector = (getAdditionalInput(additionalInput, 'dataSelector') || {}) as { [selectorName: string]: { name: SelectableBarChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } };
        const barChartSortedState$ = new BehaviorSubject<'Ascending' | 'Descending' | 'Default'>('Default');
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const isEnableTBD = (getAdditionalInput(additionalInput, 'isEnableTBD')) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const dataLabelTextFormatting = (getAdditionalInput(additionalInput, 'dataLabelTextFormatting') || 'numberFormatter') as 'numberFormatter' | 'toLocaleString' | 'percentFormatter';
        const graphInfoPopover = (getAdditionalInput(additionalInput, 'graphInfoPopover')) as GraphInfoPopover;
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE';
        const showAveragePerDay = (getAdditionalInput(additionalInput, 'showAveragePerDay') || false) as boolean;
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        const calPercentFromAllData = (getAdditionalInput(additionalInput, 'calPercentFromAllData')) as boolean;
        const labelUnit = (getAdditionalInput(additionalInput, 'labelUnit')) as string;

        let max_value_headcount: number;
        let max_value_avgtimespent: number;
        let dataBehaviorSubjects$: BehaviorSubject<unknown>[] = [];
        if (!isMockData) {
            // load data here
            dataBehaviorSubjects$ = (Object.values(barChartDataSelector).map((selectorDetail) => {
                const selectedResolvedDetail = graphDataService.baseGraphData.getSelectedGraph(selectorDetail.name, graphDataService);
                graphDataService.baseGraphData.addDependency(selectedResolvedDetail.dependencies);
                return selectedResolvedDetail.data;
            }));
        }

        //#region defined data access function
        type CurrentData = { diff?: number; diffPercent?: number; headCount?: number; avgTimeSpent?: number; netShoppingTime?: number } & number;
        type ZoneData = {
            [name: string]: number;
        } & {
            _total: number;
        };
        type EntraceExitData = { entrance: CurrentData | ZoneData; exit: CurrentData | ZoneData };
        const fillData = { diff: 0, diffPercent: 0 };
        const getEntraceExitData = (isEntrance: boolean, data: { entrance: CurrentData | ZoneData; exit: CurrentData | ZoneData }) => isEntrance ? data.entrance : data.exit;
        const getBuildingFloorData = (
            isEntrance: boolean,
            data: CurrentData | { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data || fillData;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, fillData);
            }
            if (Object.keys(retData).includes('entrance')) {
                retData = getEntraceExitData(isEntrance, retData as EntraceExitData);
            }
            if (!retData) {
                return {
                    diff: 0,
                    diffPercent: 0,
                    current: 0
                };
            }
            if (Object.keys(retData).includes('total')) {
                return retData as ZoneData;
            }
            const flattenRetData = retData as CurrentData;
            if (flattenRetData.headCount !== undefined || flattenRetData.avgTimeSpent !== undefined) {
                max_value_headcount = max_value_headcount > flattenRetData.headCount ? max_value_headcount : flattenRetData.headCount;
                max_value_avgtimespent = max_value_avgtimespent > Math.round(flattenRetData.avgTimeSpent / 60) ? max_value_avgtimespent : Math.round(flattenRetData.avgTimeSpent / 60);
            }
            return {
                diff: flattenRetData.diff,
                diffPercent: flattenRetData.diffPercent,
                current: flattenRetData.headCount || Math.round(flattenRetData.avgTimeSpent / 60) || flattenRetData.netShoppingTime || flattenRetData as number || 0
            };
        };
        //#endregion
        const selectedBar$ = new BehaviorSubject<{ [selectorName: string]: { name: SelectableBarChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } }>(barChartDataSelector);
        const initialColor: string[] = [];

        const graphConfig: { [groupName: string]: { type: '<' | '>' | 'sortExcludeOther'; value: number } } = (((configDataService.GRAPH_CONFIG.INTERACTABLE_BAR_CHART || {})[chartTitleKey] || {}).groupCondition || {});
        const graphSpeicalConfig: { sorting: boolean; exclude: string; excludeNullValue?: boolean } = (((configDataService.GRAPH_CONFIG.INTERACTABLE_BAR_CHART || {})[chartTitleKey] || {}).specialCondition || {sorting: false, exclude: null, excludeNullValue: false });
        const getBarConfig = (lineName: string) => ((((configDataService.GRAPH_CONFIG.INTERACTABLE_BAR_CHART || {})[chartTitleKey] || {}).barConfig || {})[lineName] || {}) as { color: string; groupName?: string };


        // init chartData$
        const initialFilteredData = Object.entries(barChartDataSelector).filter(([_lineName, selectedDetail], idx) => {
            if (selectedDetail.selected) {
                initialColor.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                return true;
            }
            return false;
        });
        const initialChartData = isMockData ? [] : initialFilteredData.map(([lineName, selectedDetail], index) => {
            const data = getBuildingFloorData(configDataService.isEntranceDataMode, dataBehaviorSubjects$[index].value, selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2]);
            if (Object.keys(data).includes('_total')) {
                data.current = data[selectedDetail.args[3]] || 0;
            }
            return {
                data: initialFilteredData.map(([nameIt, _data]) => nameIt === lineName ? data.current : null),
                color: getBarConfig(lineName).color || initialColor[index],
                label: `${data.current}`,
                calPercentFrommAllData: calPercentFromAllData,
            };
        });
        const chartData$ = new BehaviorSubject<GenericBarChartData[]>(initialChartData);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.chartLabel = initialFilteredData.map(([name, _data]) => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[name] || name);
        comInstance.data$ = chartData$;

        if (isMockData) {
            subscription.add(combineLatest([selectedBar$, configDataService.isEntranceDataMode$, barChartSortedState$, configDataService.isEntranceDataMode$ ,viewPeriodService.dayList]).subscribe(async (combinedRes) => {
                max_value_avgtimespent = 0;
                max_value_headcount = 0;
                const selDetails = combinedRes[0] as { [selectorName: string]: { name: SelectableBarChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } };
                const isEntrance = combinedRes[1] as boolean;
                const barChartSortedState = combinedRes[2] as 'Ascending' | 'Descending' | 'Default';
                const colors: string[] = [];
                const isLiveMode = viewPeriodService.isLiveMode;
                const isStaticFactor = dataLabelTextFormatting === 'percentFormatter' ? true : labelUnit === 'min';
                if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
                    comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
                }
                // generate mock data
                const mockChartDataList: any[] = [];
                const filteredChartValue: any[] = [];
                // const viewperiodName = viewPeriodService.viewPeriod.compareName as 'days' | 'weeks' | 'months';
                for (const [selName, selectedDetail] of Object.entries(selDetails)) {
                    const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, selectedDetail.name, 'count', 1, false, isStaticFactor);
                    mockChartDataList.push(mockChartDataValue);
                }

                // const chartValue = combinedRes.slice(3);
                const filteredChartData = Object.entries(selDetails).filter(([_lineName, selectedDetail], idx) => {
                    if (selectedDetail.selected) {
                        colors.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                        filteredChartValue.push(isEnableTBD && isLiveMode ? 0 : mockChartDataList[idx]);
                        return true;
                    }
                    return false;
                });
    
                let barChartData = filteredChartData.map(([lineName, selectedDetail], index) => {
                    let data: ZoneData | {
                        diff: number;
                        diffPercent: number;
                        current: number;
                    };
                    const barConfig = getBarConfig(lineName);
                    data = filteredChartValue[index] || { current: 0 };
                    if (Object.keys(data).includes('_total')) { // province data
                        data.current = data[selectedDetail.args[0]];
                    } else {
                        const arrOfDataPoints: number[] = [];
                        if (selectedDetail?.group) {
                            const graphDataGroup = selectedDetail.group.data;
                            for (const graphData of graphDataGroup) {
                                const dataPoint = getBuildingFloorData(isEntrance, filteredChartValue[index], graphData.args[0], graphData.args[1], graphData.args[2]);
                                arrOfDataPoints.push(dataPoint.current);
                            }
                            data.current = selectedDetail.group.oper === 'AVG' ? arrOfDataPoints.length === 0 ? 0 : arrOfDataPoints.reduce(((a, b) => a + b), 0) / arrOfDataPoints.length : arrOfDataPoints.reduce(((a, b) => a + b), 0);
                        }
                        else {
                            data = getBuildingFloorData(isEntrance, filteredChartValue[index], selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2]);
                            if (Object.keys(data).includes('total')) { // entrance from to
                                data.current = data[selectedDetail.args[3]] || 0;
                            }  
                        }
                    }
                    return {
                        barPercentage: filteredChartData.length > 4 ? 0.9 : 0.5,
                        categoryPercentage: filteredChartData.length > 4 ? 0.8 : 1,
                        color: barConfig.color || colors[index],
                        label: `${data.current}`,
                        calPercentFrommAllData: calPercentFromAllData,
                        lineName,
                        displayName: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] || lineName,
                        value: data.current,
                        groupName: barConfig.groupName,
                    };
                });
                //#region Grouping
                const groupCounter: { [groupName: string]: number } = Object.keys(graphConfig).reduce((acc, curr) => {
                    acc[curr] = 0;
                    return acc;
                }, {});
                barChartData = barChartData.filter(filteredDataIt => {
                    if (filteredDataIt.label === 'undefined') {
                        return false;
                    }
                    const groupConfig = graphConfig[filteredDataIt.groupName];
                    if (groupConfig) {
                        const isFillteredOut = (groupConfig.type === '<') ? (filteredDataIt.value || 0) < groupConfig.value : (filteredDataIt.value || 0) > groupConfig.value;
                        if (isFillteredOut) {
                            groupCounter[filteredDataIt.groupName] += filteredDataIt.value || 0;
                        }
                        return !isFillteredOut;
                    }
                    return true;
                });
                if (Object.keys(groupCounter).length > 0) {
                    barChartData.push(...Object.entries(groupCounter).filter(([_groupName, count]) => count > 0).map(([groupName, count], index) => {
                        const lineName = `group-${groupName}`;
                        const barConfig = getBarConfig(lineName);
                        return {
                            barPercentage: barChartData.length > 4 ? 0.9 : 0.4,
                            categoryPercentage: barChartData.length > 4 ? 0.8 : 1,
                            color: barConfig.color || colors[index],
                            label: `${count}`,
                            calPercentFrommAllData: calPercentFromAllData,
                            lineName,
                            displayName: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] || lineName,
                            value: count,
                            groupName,
                        };
                    }));
                }
                if (graphSpeicalConfig.sorting) {
                    const filteredBarChartData = barChartData.filter(data => data.lineName !== graphSpeicalConfig.exclude).sort((a, b) => Number(b.label) - Number(a.label));
                    filteredBarChartData.push(...barChartData.filter(data => data.lineName === graphSpeicalConfig.exclude));
                    barChartData = filteredBarChartData;
                    comInstance.paddingRight = 20;
                }
                if (graphSpeicalConfig.excludeNullValue) {
                    const filteredBarChartData = barChartData.filter(data => typeof data.value === 'number');
                    barChartData = filteredBarChartData;
                }
                //#endregion Grouping
    
                //#region  Sorting
                let chartLabel = barChartData.map(filteredData => filteredData.displayName);
                let sortIndices: number[] = [...Array(chartLabel.length).keys()];
                if (barChartSortedState === 'Ascending' || barChartSortedState === 'Descending') {
                    sortIndices = getSortIndice(barChartData.map(filteredData => filteredData.value));
                    if (barChartSortedState === 'Descending') {
                        sortIndices.reverse();
                    }
                }
    
                const sortedLabel: string[] = [];
                const sortedBarChartData: GenericBarChartData[] = [];
                sortIndices.forEach((indice, index) => {
                    sortedLabel.push(chartLabel[indice]);
                    const newDataIt = {
                        ...barChartData[indice],
                        data: Array.from({ length: chartLabel.length }).map(() => null) as number[]
                    };
                    newDataIt.data[index] = barChartData[indice].value;
                    sortedBarChartData.push(newDataIt);
                });
                //#endregion Sorting
                chartLabel = sortedLabel;
                comInstance.chartLabel = chartLabel;
                const round_max_val = (val: number) => val > 10000 ? Math.round(val / 5) : Math.round(val / 10); // TODO: properly handle data not just random if check
                // suggestedTickMax_X with headcount ex. headcount = 15000 -> 15000 + (15000 / 5) or time = 20 -> 20 + (20 / 10)
                if (chartTitleKey.includes('HEADCOUNT') || chartTitleKey.includes('TRAFFIC')) {
                    comInstance.suggestedTickMax_X = max_value_headcount + round_max_val(max_value_headcount);   
                } else if (chartTitleKey.includes('TIME')){
                    comInstance.suggestedTickMax_X = max_value_avgtimespent + round_max_val(max_value_avgtimespent);
                }
                comInstance.paddingRight = 30;
                // comInstance.aspectRatio = 0.5;
                chartData$.next(sortedBarChartData);
            }));
        } else {
            subscription.add(combineLatest([selectedBar$, configDataService.isEntranceDataMode$, barChartSortedState$, ...dataBehaviorSubjects$]).subscribe((combinedRes) => {
                max_value_avgtimespent = 0;
                max_value_headcount = 0;
                const selDetails = combinedRes[0] as unknown as { [selectorName: string]: { name: SelectableBarChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } };
                const isEntrance = combinedRes[1] as boolean;
                const barChartSortedState = combinedRes[2] as 'Ascending' | 'Descending' | 'Default';
                const colors: string[] = [];
                const isLiveMode = viewPeriodService.isLiveMode;
                if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
                    comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
                }
                combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$]).subscribe(([selectedDirectory, selectedLevel]) => {
                    if (excludedDirectory !== undefined) {
                      if (excludedDirectory === 'BUILDING') {
                        comInstance.isShow =  selectedDirectory?.floor !== 'ALL';
                      }
                      else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                        if (selectedDirectory?.floor === 'ALL' && excludedDirectory === 'FLOOR') {
                          comInstance.isShow = true;
                        } else {
                          comInstance.isShow = selectedLevel !== excludedDirectory;
                        }
                      }
                      // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                      else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') <excludedDirectory.length - 1) {
                        const excludeList = excludedDirectory.split('_AND_');
                        if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                            comInstance.isShow = true;
                        } else {
                            comInstance.isShow =  !excludeList.includes(selectedLevel);
                        }
                      }
                    }
                });
    
                if (combinedRes.slice(3).some(val => !val)) { return; }
    
                const chartValue = combinedRes.slice(3);
                const filteredChartValue: any[] = [];
                const filteredChartData = Object.entries(selDetails).filter(([_lineName, selectedDetail], idx) => {
                    if (selectedDetail.selected) {
                        colors.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                        filteredChartValue.push(isEnableTBD && isLiveMode ? 0 : chartValue[idx]);
                        return true;
                    }
                    return false;
                });
    
                let barChartData = filteredChartData.map(([lineName, selectedDetail], index) => {
                    let data: ZoneData | {
                        diff: number;
                        diffPercent: number;
                        current: number;
                    };
                    const barConfig = getBarConfig(lineName);
                    data = filteredChartValue[index] || { current: 0 };
                    if (Object.keys(data).includes('_total')) { // province data
                        data.current = data[selectedDetail.args[0]];
                    } else {
                        const arrOfDataPoints: number[] = [];
                        if (selectedDetail?.group) {
                            const graphDataGroup = selectedDetail.group.data;
                            for (const graphData of graphDataGroup) {
                                const dataPoint = getBuildingFloorData(isEntrance, filteredChartValue[index], graphData.args[0], graphData.args[1], graphData.args[2]);
                                arrOfDataPoints.push(dataPoint.current);
                            }
                            data.current = selectedDetail.group.oper === 'AVG' ? arrOfDataPoints.length === 0 ? 0 : arrOfDataPoints.reduce(((a, b) => a + b), 0) / arrOfDataPoints.length : arrOfDataPoints.reduce(((a, b) => a + b), 0);
                        }
                        else {
                            data = getBuildingFloorData(isEntrance, filteredChartValue[index], selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2]);
                            if (Object.keys(data).includes('total')) { // entrance from to
                                data.current = data[selectedDetail.args[3]] || 0;
                            }
                            if (labelUnit === 'min') {
                                data.current = Math.round(data.current / 60);
                            }
                        }
                    }
                    return {
                        barPercentage: filteredChartData.length > 4 ? 0.9 : 0.5,
                        categoryPercentage: filteredChartData.length > 4 ? 0.8 : 1,
                        color: barConfig.color || colors[index],
                        label: `${data.current}`,
                        calPercentFrommAllData: calPercentFromAllData,
                        lineName,
                        displayName: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] || lineName,
                        value: data.current,
                        groupName: barConfig.groupName,
                    };
                });
                //#region Grouping
                const groupCounter: { [groupName: string]: number } = Object.keys(graphConfig).reduce((acc, curr) => {
                    acc[curr] = 0;
                    return acc;
                }, {});
                barChartData = barChartData.filter(filteredDataIt => {
                    if (filteredDataIt.label === 'undefined') {
                        return false;
                    }
                    const groupConfig = graphConfig[filteredDataIt.groupName];
                    if (groupConfig) {
                        const isFillteredOut = (groupConfig.type === '<') ? (filteredDataIt.value || 0) < groupConfig.value : (filteredDataIt.value || 0) > groupConfig.value;
                        if (isFillteredOut) {
                            groupCounter[filteredDataIt.groupName] += filteredDataIt.value || 0;
                        }
                        return !isFillteredOut;
                    }
                    return true;
                });
                if (Object.keys(groupCounter).length > 0) {
                    barChartData.push(...Object.entries(groupCounter).filter(([_groupName, count]) => count > 0).map(([groupName, count], index) => {
                        const lineName = `group-${groupName}`;
                        const barConfig = getBarConfig(lineName);
                        if (labelUnit === 'min') {
                            count = Math.round(count / 60);
                        }
                        return {
                            barPercentage: barChartData.length > 4 ? 0.9 : 0.4,
                            categoryPercentage: barChartData.length > 4 ? 0.8 : 1,
                            color: barConfig.color || colors[index],
                            label: `${count}`,
                            calPercentFrommAllData: calPercentFromAllData,
                            lineName,
                            displayName: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] || lineName,
                            value: count,
                            groupName,
                        };
                    }));
                }
                if (graphSpeicalConfig.sorting) {
                    const filteredBarChartData = barChartData.filter(data => data.lineName !== graphSpeicalConfig.exclude).sort((a, b) => Number(b.label) - Number(a.label));
                    filteredBarChartData.push(...barChartData.filter(data => data.lineName === graphSpeicalConfig.exclude));
                    barChartData = filteredBarChartData;
                    comInstance.paddingRight = 20;
                }
                if (graphSpeicalConfig.excludeNullValue) {
                    const filteredBarChartData = barChartData.filter(data => typeof data.value === 'number');
                    barChartData = filteredBarChartData;
                }
                //#endregion Grouping
    
                //#region  Sorting
                let chartLabel = barChartData.map(filteredData => filteredData.displayName);
                let sortIndices: number[] = [...Array(chartLabel.length).keys()];
                if (barChartSortedState === 'Ascending' || barChartSortedState === 'Descending') {
                    sortIndices = getSortIndice(barChartData.map(filteredData => filteredData.value));
                    if (barChartSortedState === 'Descending') {
                        sortIndices.reverse();
                    }
                }
    
                const sortedLabel: string[] = [];
                const sortedBarChartData: GenericBarChartData[] = [];
                sortIndices.forEach((indice, index) => {
                    sortedLabel.push(chartLabel[indice]);
                    const newDataIt = {
                        ...barChartData[indice],
                        data: Array.from({ length: chartLabel.length }).map(() => null) as number[]
                    };
                    newDataIt.data[index] = barChartData[indice].value;
                    sortedBarChartData.push(newDataIt);
                });
                //#endregion Sorting
    
                chartLabel = sortedLabel;
                comInstance.chartLabel = chartLabel;
                const round_max_val = (val: number) => val > 10000 ? Math.round(val / 5) : Math.round(val / 10); // TODO: properly handle data not just random if check
                // suggestedTickMax_X with headcount ex. headcount = 15000 -> 15000 + (15000 / 5) or time = 20 -> 20 + (20 / 10)
                if (chartTitleKey.includes('HEADCOUNT') || chartTitleKey.includes('TRAFFIC')) {
                    comInstance.suggestedTickMax_X = max_value_headcount + round_max_val(max_value_headcount);   
                } else if (chartTitleKey.includes('TIME')){
                    comInstance.suggestedTickMax_X = max_value_avgtimespent + round_max_val(max_value_avgtimespent);
                }
                // comInstance.aspectRatio = 0.5;
                chartData$.next(sortedBarChartData);
            }));
        }

        if (graphInfoPopover) {
            const infoPopoverText = graphInfoPopover;
            comInstance.infoPopover = async (e: any) => {
                const pieChartPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: infoPopoverText.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: infoPopoverText.details,
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await pieChartPopover.present();
            };
        }

        comInstance.selectInterface = 'alert';
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        comInstance.sortedState$ = barChartSortedState$;
        comInstance.selector = barChartDataSelector;
        comInstance.selectedBar$ = selectedBar$;
        comInstance.isLock = graphState === 'LOCK';
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey];
        comInstance.isShowLegend = false;
        comInstance.isHorizontal = true;
        comInstance.valueTextAlign = 'left';
        comInstance.valueTextOffsetX = 2;
        comInstance.valueTextOffsetY = 7;
        comInstance.valueTextProcess = dataLabelTextFormatting;
        comInstance.displayAxis = 'Y';
        comInstance.isStacked = 'Y';
        comInstance.displayGrid = false;
        comInstance.overrideShouldShowSelector = useSelector;
        comInstance.isEnableTBD = isEnableTBD;
        comInstance.showAveragePerDay = showAveragePerDay;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showGenericBarChartCustomTooltips(tooltipModel, this, true, dataLabelTextFormatting);
        };
        return comInstance;
    }

}
