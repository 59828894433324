import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { PurchaseBagTooltipsComponent } from '../shared-components/general/purchase-bag-tooltips/purchase-bag-tooltips.component';
import { DynamicDualNumberCardComponent } from '../shared-components/dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class PurchaseRateAndAverageTimeSpentNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        // await configDataService.loadAppConfig();
        configDataService.loadExampleImages();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicDualNumberCardComponent;

        const purchaseRateNumberData$ = new BehaviorSubject('0 %');
        const purchaseRateLabel$ = new BehaviorSubject('0 %');
        const purchaseRateLabelIcon$ = new BehaviorSubject('trending-up');
        const purchaseRateLabelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });

        subscription.add(graphDataService.currentpurchaseRateData$.subscribe((purchaseRateData) => {
            purchaseRateNumberData$.next(`${purchaseRateData.purchase.toFixed(1)} %`);
            purchaseRateLabel$.next(`${purchaseRateData.diff.toFixed(1)} %`);
            purchaseRateLabelIcon$.next(purchaseRateData.diff > 0 ? 'trending-up' : 'trending-down');
            purchaseRateLabelStyle$.next({ fontSize: '16px', fontWeight: '500', color: purchaseRateData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const averageTimeSpentNumberData$ = new BehaviorSubject('0 min');
        const averageTimeSpentLabel$ = new BehaviorSubject('0 % (0)');
        const averageTimeSpentLabelIcon$ = new BehaviorSubject('trending-up');
        const averageTimeSpentLabelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });

        subscription.add(graphDataService.currentAverageTimeSpentData$.subscribe((averageTimeSpentData) => {
            averageTimeSpentNumberData$.next(`${(averageTimeSpentData.avgTimeSpent / 60).toFixed(1)} min`);
            averageTimeSpentLabel$.next(`${averageTimeSpentData.diffPercent.toFixed(1)} % (${(averageTimeSpentData.diff / 60).toFixed(1)} min)`);
            averageTimeSpentLabelIcon$.next((averageTimeSpentData.diff / 60) > 0 ? 'trending-up' : 'trending-down');
            averageTimeSpentLabelStyle$.next({ fontSize: '16px', fontWeight: '500', color: (averageTimeSpentData.diff / 60) >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const purchaseRateCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE,
            numberData$: purchaseRateNumberData$,
            label$: purchaseRateLabel$,
            labelIcon$: purchaseRateLabelIcon$,
            labelStyle$: purchaseRateLabelStyle$,
            isFirst: true,
            // sizeXS: '12',
            // sizeMD: '10',
            // offsetMD: '1',
            infoPopover: async (e: any) => {
                const shoppingBagPopover = await popoverController.create({
                    component: PurchaseBagTooltipsComponent,
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await shoppingBagPopover.present();
            }
        };

        const averageTimeSpentCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_NUMBER_CARD,
            numberData$: averageTimeSpentNumberData$,
            label$: averageTimeSpentLabel$,
            labelIcon$: averageTimeSpentLabelIcon$,
            labelStyle$: averageTimeSpentLabelStyle$,
            // sizeXS: '12',
            // sizeMD: '10',
            // offsetMD: '1',
        };

        comInstance.cards = [purchaseRateCard, averageTimeSpentCard];
        return comInstance;
    }

}
