<ion-row *ngIf="configDataService.isConfigReady" [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }">
  <ion-col>
    <ion-card appLockIcon [isLock]="isLock" class="ion-padding">
      <ion-card-header *ngIf="isShowSelector; else normalChartHeader">
        <ion-grid class="ion-no-padding">
          <div class="grid-container" style="width: 100%; display: grid; grid-template-columns: auto 1fr; align-items: center">
            <div class="grid-title" style="display: flex; justify-content: start; align-items: center">
              <ion-card-title id="col-title-name">
                {{ title }}
                <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
              </ion-card-title>
            </div>
            <div id="col-selector" style="display: flex; justify-content: flex-end; align-items: center">
              <ion-select
              [value]="getDefaultValue()"
              style="font-size: 16px; color: var(--ion-color-primary)"
              [interface]="selectInterface"
              (ionChange)="changeSelectedGraph($event)"
              multiple="true"
              >
                <ion-select-option *ngFor="let lineName of selectorName; index as i" [value]="lineName">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] || lineName }}</ion-select-option>
              </ion-select>
            </div>
          </div>
        </ion-grid>
      </ion-card-header>
      <div>
        <ion-grid>
          <ion-row *ngIf="isShowCustomLegend && labelHeader$" [ngStyle]="isShowCustomLegend && labelHeader$ ? {'display': 'flex', 'justify-content': 'center', 'flex-flow': 'row wrap'} : {'display': 'none'}" >
            <div class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
              <label *ngIf="isShowCustomLegend && labelHeader$" class="label-header">
                {{ labelHeader$ | async}}
              </label>
            </div>
          </ion-row>
          <ion-row *ngIf="isShowCustomLegend" [ngStyle]="isShowCustomLegend ? {'display': 'flex', 'justify-content': 'center', 'flex-flow': 'row wrap'} : {'display': 'none'}" >
            <div class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
              <label *ngIf="isShowCustomLegend" class="card-content-text-large">
                {{ label$ | async}}
              </label>
            </div>
          </ion-row>
          <ion-row *ngIf="isShowCustomLegend" [ngStyle]="isShowCustomLegend ? {'display': 'flex', 'justify-content': 'center', 'flex-flow': 'row wrap'} : {'display': 'none'}" >
            <div class="ion-align-self-center" [ngStyle]="{ 'text-align': 'center', 'padding-right': '1rem' }">
              <label *ngIf="isShowCustomLegend" class="label-number" [ngStyle]="labelStyle">
                <ion-icon [name]="labelIcon"  class="icon-vertical-center"></ion-icon>
                {{ isShowLegendOnPercent ? legendNumber(numberData$ | async) : ((numberData$ | async) | numberSuffix: "1.3") || 0}}
              </label>
            </div>
          </ion-row>
          <ion-row>
            <ion-col appLockBlur [isLock]="isLock">
              <app-generic-custom-line-chart
              [lineChartLabels$]="chartLabel$"
              [data$]="data$"
              [isShowLegend]="isShowLegend"
              [chartOptions$]="chartOptions$"
              ></app-generic-custom-line-chart>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-card>
  </ion-col>
</ion-row>

<ng-template #normalChartHeader>
  <ion-card-header>
    <ion-card-title>
      {{ title }}
      <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
    </ion-card-title>
  </ion-card-header>
</ng-template>
