import * as moment from 'moment';
import { isScreenSmallerThanMD } from './util';

let shoppingBagColorTooltipTimeout;

export const showShoppingBagColorCustomTooltip = (tooltipModel, thisChart, imageList, currentDate: moment.Moment) => {

  window.clearTimeout(shoppingBagColorTooltipTimeout);
  // Tooltip Element
  let tooltipEl = document.getElementById('shoppingBag-color-chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'shoppingBag-color-chartjs-tooltip';
      tooltipEl.innerHTML = '<table style="margin-left: 25%;margin-top: 2.5%"></table><div id="sub-container"></div><div id="img-container"></div>';
      document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    window.clearTimeout(shoppingBagColorTooltipTimeout);
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
      tooltipEl.classList.add('no-transform');
  }

  // function getBody(bodyItem) {
  //   return bodyItem.lines;
  // }

  const chartDatasets = thisChart._data.datasets;
  const chartLabels = thisChart._data.labels;

  let activeClassLabel;
  // Set Text
  if (tooltipModel.body) {
    const titleLines = /*tooltipModel.title ||*/ [];
    // var bodyLines = tooltipModel.body.map(getBody);
    const bodyLines = tooltipModel.dataPoints.map((datapoint) => {
      const rawValue = chartDatasets[datapoint.datasetIndex].data[datapoint.index] > 1 ? chartDatasets[datapoint.datasetIndex].data[datapoint.index].toLocaleString() : 'N/A';
      const label = chartLabels[datapoint.index];
      activeClassLabel = label;
      return `${label}: ${rawValue}`;
    });

    let innerHtml = '<thead>';

    titleLines.forEach((title) => {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';
    bodyLines.forEach((body, i) => {
      const idx = tooltipModel.dataPoints[i].index;
      const colors = tooltipModel.labelColors[0];
      let style = 'background:' + colors.backgroundColor[idx];
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      style += '; display: inline-block';
      style += '; width: 15px';
      style += '; height: 15px';
      style += '; margin-right: 5px';
      style += '; vertical-align: middle';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td style="display: inline-block;vertical-align: middle">' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const subContainer = tooltipEl.querySelector('div#sub-container');
  // const parseMonth = currentDate.format('MMM YYYY');
  subContainer.innerHTML = `<p style="margin-top: 5px;margin-bottom: 0">Tenants of this color (June 2021): </p>`;
  
  const listMapper = {
    Pink: `<ul style="margin: 0px 28px"><li>Kate Spade</li><li>The Clozet</li><li>EVEANDBOY</li></ul>`,
    Red: `<ul style="margin: 0px 35px"><li>Other</li></ul>`,
    Orange: `<ul style="margin: 0px 45px"><li>Nike</li><li>Other</li></ul>`,
    'Navy Blue': `<ul style="margin: 0px 35px"><li>Skechers</li></ul>`,
  };
  const tenantList = listMapper[tooltipModel.title[0]];

  subContainer.innerHTML += tenantList;
  
  const imageRoot = tooltipEl.querySelector('div#img-container');
  const imageSrc1 = imageList[activeClassLabel][0];
  const imageSrc2 = imageList[activeClassLabel][1];

  imageRoot.innerHTML = 
  `<img style="width: 75px; height: 75px; margin: 5px 15px;" src="${imageSrc1}"></img>` +
  `<img style="width: 75px; height: 75px; margin: 5px 15px;" src="${imageSrc2}"></img>`;

  // `this` will be the overall tooltip
  const position = thisChart._chart.canvas.getBoundingClientRect();
  // Display, position, and set styles for font
  tooltipEl.style.opacity = '3';
  tooltipEl.style.position = 'absolute';
  // tooltipEl.style.left = (position.left + window.pageXOffset + tooltipModel.caretX ) + 'px';
  // tooltipEl.style.top = (position.top + window.pageYOffset + tooltipModel.caretY - 100) + 'px';
    // relative to the ting
  const showingReact = tooltipEl.getBoundingClientRect();
  const calLeftPx = (position.left + window.pageXOffset + tooltipModel.caretX - (showingReact.width));
  tooltipEl.style.left = (calLeftPx < 0 ? isScreenSmallerThanMD(window) ? 0 : 400 : calLeftPx) + 'px';
  tooltipEl.style.top = (position.top + window.pageYOffset + tooltipModel.caretY - (showingReact.height)) + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding =
    tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.backgroundColor = 'rgba(0,0,0, 0.8)';
  tooltipEl.style.color = 'white';
  tooltipEl.style.borderRadius = '5px';

  shoppingBagColorTooltipTimeout = setTimeout(() => {
    tooltipEl.style.opacity = '0';
  }, 5000);

};
