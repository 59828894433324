import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-dynamic-item-divider',
  templateUrl: './dynamic-item-divider.component.html',
  styleUrls: ['./dynamic-item-divider.component.scss'],
})
export class DynamicItemDividerComponent implements OnInit {
  @Input() color = '#4a6fdd';
  @Input() title = '';
  @Input() textColor = 'white';
  @Input() sticky = true;
  @Input() fontSize = '18px';
  @Input() fontWeight = 600;

  constructor(
    private ref: ChangeDetectorRef
  ) { 
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {}

}
