/* eslint-disable max-len */
import { OverallResolverBase } from './OverallResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { GenericPieChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined } from '../helpers/util';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';

export class OverallNetShoppingTimePieResolver extends OverallResolverBase {

    public get graphDependencyMap(): { building: GraphDependency[]; floor: GraphDependency[]; zone: GraphDependency[] } {
        return {
            building: [GraphDependency.ENTRANCE_EXIT],
            floor: [GraphDependency.ENTRANCE_EXIT_FLOOR],
            zone: [GraphDependency.ENTRANCE_EXIT_ZONE]
        };
    }

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
    ) {
        // load data here
        const level = getAdditionalInput(additionalInput, 'level') as DynamicGraphAdditionalInput['level'] | undefined;
        const isEnableTBD = (getAdditionalInput(additionalInput, 'isEnableTBD')) as boolean;
        const groupData = getAdditionalInput(additionalInput, 'groupData') as DynamicGraphAdditionalInput['groupData'] | undefined;
        assertNullUndefined(level);
        this.processDynamicDependency(configDataService, graphDataService, level);
        // await configDataService.loadAppConfig();

        type NetShoppingTime = { netShoppingTime: number; diff: number; diffPercent: number };
        const getNetShoppingTimeData = (
            dataBuilding: { [buildingName: string]: NetShoppingTime },
            dataFloor: { [buildingName: string]: { [floorName: string]: NetShoppingTime } },
            dataZone: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: NetShoppingTime } } },
        ) => this.getLeveledData<NetShoppingTime>(dataBuilding, dataFloor, dataZone, level);
        const sec2Hrs = (val: number | undefined | null) => val ? Math.round(val / 60 / 60) : val;
        const initialComparingNames = level === 'building' ? this.getComparingNames(graphDataService.baseGraphData.comparingBuildingState$.value) : (level === 'floor' ? this.getComparingNames(graphDataService.baseGraphData.comparingFloorState$.value) : this.getComparingNames(graphDataService.baseGraphData.comparingZoneState$.value));
        const initialData = getNetShoppingTimeData(graphDataService.currentBuildingNetShoppingHourData$.value, graphDataService.currentNetShoppingTimeFloorData$.value, graphDataService.currentNetShoppingTimeZoneData$.value);
        const initialColor: string[] = [];
        const chartData$ = new BehaviorSubject<GenericPieChartData[]>(Object.entries(initialData).sort().filter(([name, _data], idx) => {
            if (initialComparingNames.includes(name)) {
                if (level === 'zone' && configDataService?.ZONE_LINE_CHART_COLOR_LIST) {
                    initialColor.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                } else if (level === 'floor' && configDataService?.FLOOR_PIE_CHART_COLOR_LIST) {
                    initialColor.push(configDataService.FLOOR_PIE_CHART_COLOR_LIST[name]);
                } else if (level === 'building' && configDataService?.BUILDING_PIE_CHART_COLOR_LIST) {
                    initialColor.push(configDataService.BUILDING_PIE_CHART_COLOR_LIST[name]);
                } else {
                    initialColor.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                }
                return true;
            }
            return false;
        }).map(([name, data], idx) => ({
            data: data.netShoppingTime,
            color: initialColor[idx],
            label: `${this.getDisplayName(name)}`,
        })));

        // eslint-disable-next-line max-len
        subscription.add(combineLatest([graphDataService.currentBuildingNetShoppingHourData$, graphDataService.currentNetShoppingTimeFloorData$, graphDataService.currentNetShoppingTimeZoneData$, combineLatest([graphDataService.baseGraphData.comparingBuildingState$, graphDataService.baseGraphData.comparingFloorState$, graphDataService.baseGraphData.comparingZoneState$])]).subscribe(
            ([currentNetShoppingTimeBuildingData, currentNetShoppingTimeFloorData, currentNetShoppingTimeZoneData, [comparingBuildingState, comparingFloorState, comparingZoneState]]) => {
                if ((!currentNetShoppingTimeBuildingData && level === 'building') || (!currentNetShoppingTimeFloorData && level === 'floor') || (!currentNetShoppingTimeZoneData && level === 'zone')) { return; }
                const comparingNames = level === 'building' ? this.getComparingNames(comparingBuildingState) : (level === 'floor' ? this.getComparingNames(comparingFloorState) : this.getComparingNames(comparingZoneState));
                const fullData = getNetShoppingTimeData(currentNetShoppingTimeBuildingData, currentNetShoppingTimeFloorData, currentNetShoppingTimeZoneData);
                const chartColors: string[] = [];
                const isLiveMode = viewPeriodService.isLiveMode;
                if (groupData) {
                    Object.keys(groupData).forEach(k => {
                        const arrDataPoints: number[] = [];
                        groupData[k].args.forEach(arg => {
                            arrDataPoints.push(fullData[arg].netShoppingTime);
                        });
                        fullData[k] = { netShoppingTime: groupData[k].oper === 'AVG' ? arrDataPoints.length === 0 ? 0 : arrDataPoints.reduce(((a, b) => a + b), 0) / arrDataPoints.length : arrDataPoints.reduce(((a, b) => a + b), 0), diff: 0, diffPercent: 0 };
                    });
                }
                const pieChartData: GenericPieChartData[] = Object.entries(fullData).sort().filter(([name, _data], idx) => {
                    if (comparingNames.includes(name)) {
                        if (level === 'zone' && configDataService?.ZONE_LINE_CHART_COLOR_LIST) {
                            chartColors.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                        } else if (level === 'floor' && configDataService?.FLOOR_PIE_CHART_COLOR_LIST) {
                            chartColors.push(configDataService.FLOOR_PIE_CHART_COLOR_LIST[name]);
                        } else if (level === 'building' && configDataService?.BUILDING_PIE_CHART_COLOR_LIST) {
                            chartColors.push(configDataService.BUILDING_PIE_CHART_COLOR_LIST[name]);
                        } else {
                            chartColors.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                        }
                        return true;
                    }
                    return false;
                }).map(([name, data], idx) => ({
                    data: isEnableTBD && isLiveMode ? 0 : data.netShoppingTime,
                    color: chartColors[idx],
                    label: `${this.getDisplayName(name)}`,
                }));
                chartData$.next(pieChartData);
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegendTitleValue = true; // should be false?
        comInstance.isShowLegendTitle = true;
        comInstance.data$ = chartData$;
        comInstance.isEnableTBD = isEnableTBD;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this);
        };
        return comInstance;
    }

}
