<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div *ngIf="shouldShowIcon(day.date)">
    <div>
      <div class="cal-cell-top">
        <span class="cal-day-number">
          <img *ngIf="getIconFromDate(day.date)" [src]="getIconFromDate(day.date)" class="weather-icon" style="max-width: 25px;" />
          {{ day.date | calendarDate: 'monthViewDayNumber':locale }}
        </span>
      </div>
      <div class="traffic-number traffic-number-xs ion-text-center" style="color: var(--ion-color-primary);">&nbsp;{{ getHeadcountFromDate(day.date) | numberSuffix: '1' }}&nbsp;</div>
      <div class="traffic-number traffic-number-md ion-text-center" style="color: var(--ion-color-primary);">&nbsp;{{ getHeadcountFromDate(day.date) | number: '' }}&nbsp;</div>
      <div class="cell-totals">
        <span *ngFor="let group of day.eventGroups" class="badge badge-{{ group[0] }} custom-badge">
          {{ group[1].length }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ion-card>
  <ion-card-header style="padding-bottom: 0px;">
    <ion-grid class="ion-no-padding">
      <div class="grid-container" style="width: 100%; display: grid; align-items: center" [style.grid-template-columns]="updateGridLayout(shouldShowSelector)">
        <div class="grid-title" style="display: flex; justify-content: start; align-items: center">
          <ion-card-title id="col-title-name">
            Month Performance
            <ion-icon (click)="presentCalendarCriteriaTooltips($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
          </ion-card-title>
        </div>
        <ng-container *ngIf="shouldShowSelector">
          <div id="col-selector" style="display: flex; justify-content: flex-end; align-items: center">
            <ion-select
              [multiple]="multipleSelector"
              [value]="selectedName"
              style="font-size: 16px; color: var(--ion-color-primary); text-align: justify;"
              [interface]="selectInterface"
              (ionChange)="changeSelectedGraph($event)"
              #ionGraphSelector
              mode="md"
            >
              <ion-select-option *ngFor="let lineName of selectorName; index as i" [value]="lineName">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] }}</ion-select-option>
            </ion-select>
          </div>
        </ng-container>
      </div>
    </ion-grid>
  </ion-card-header>
  <ion-card-content>
    <div class="ion-text-center" style="margin-bottom: 20px;">
      <label style="font-size: 20px; font-weight: 600;">{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</label>
    </div>
    <div style="padding: 0px 20px; padding-bottom: 10px;" appLockBlur [isLock]="isLock">
      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [cellTemplate]="customCellTemplate"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        [refresh]="refresh"
        (beforeViewRender)="beforeMonthViewRender($event)"
        [events]="calendarEvents"
      >
      </mwl-calendar-month-view>
    </div>
  </ion-card-content>
</ion-card>
