import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';

export class GateTrafficResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig(); 
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? (graphDataService.baseGraphData.selectedInteractable$.value || {}).object.userData.building : configDataService.MAIN_BUILDING) as string;
        const initialFloorName = graphDataService.selectedFloorName$.value || graphDataService.baseGraphData.selectedDirectory$.value.floor || 'ALL';
        const initialLevel = graphDataService.baseGraphData.selectedLevel$.value;
        const initialGateName = (graphDataService.baseGraphData.selectedInteractable$.value || {}).name;
        const initialZoneName = graphDataService.baseGraphData.selectedDirectory$.value.zone;
        const contribute_level = getAdditionalInput(additionalInput, 'contributeLevel') || configDataService.isFeatureEnabled('heatmap_v2', 'contribute_level') as string;
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE';

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;

        const getTrafficData = (
            data: { [buildingName: string]: number | { [floorName: string]: number } | { [floorName: string]: { [gateName: string]: number } } },
            building?: string,
            floorName?: string,
            gateName?: string,
        ) => accessDepthData<number>(data, building, floorName, gateName, 0);

        const getZonePinTrafficData = (
            data: { [buildingName: string]: number | { [floorName: string]: number } | { [floorName: string]: { [gateName: string]: number } } } | { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [gateName: string]: number } } } },
            building?: string,
            floorName?: string,
            zoneName?: string,
            gateName?: string,
        ) => {
            if (!data || !data[building]) { return 0; }
            if (floorName) {
              if (zoneName && data[building][floorName]) {
                if (gateName && data[building][floorName][zoneName]) {
                    return (data[building][floorName][zoneName][gateName] || 0) as number;
                }
                return (data[building][floorName][zoneName] || 0) as number;
              }
              return (data[building][floorName] || 0) as number;
            }
            return (data[building] || 0) as number;
        };
        let initialEntranceData: number;
        let initialExitData: number;
        if (contribute_level === 'building') {
            initialEntranceData = initialLevel === 'FLOOR' 
            ? getTrafficData({...graphDataService.entranceBuildingPin$.value, ...graphDataService.entranceFloorPin$.value}, buildingName, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.entranceZonePin$.value, initialFloorName, initialZoneName, initialGateName);
            initialExitData = initialLevel === 'FLOOR' 
            ? getTrafficData({...graphDataService.exitBuildingPin$.value, ...graphDataService.exitFloorPin$.value}, buildingName, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.exitZonePin$.value, initialFloorName, initialZoneName, initialGateName);
        } else {
            initialEntranceData = initialLevel === 'FLOOR' ? getTrafficData({...graphDataService.entranceBuildingPin$.value, ...graphDataService.entranceFloorPin$.value}, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.entranceZonePin$.value, initialFloorName, initialZoneName, initialGateName);
            initialExitData = initialLevel === 'FLOOR' ? getTrafficData({...graphDataService.exitBuildingPin$.value, ...graphDataService.exitFloorPin$.value}, initialFloorName, initialGateName)
            : getZonePinTrafficData(graphDataService.exitZonePin$.value, initialFloorName, initialZoneName, initialGateName);
        }
        comInstance.chartLabel = [configDataService.DISPLAY_LANGUAGE.ENTRANCE, configDataService.DISPLAY_LANGUAGE.EXIT];
        comInstance.isShow = (graphDataService.baseGraphData.selectedInteractable$.value || {}).type === 'gate';
        const barData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: [initialEntranceData, null],
            color: PIE_CHART_COLOR_LIST[0],
            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
        }, {
            data: [null, initialExitData],
            color: PIE_CHART_COLOR_LIST[1],
            label: configDataService.DISPLAY_LANGUAGE.EXIT,
        }]);

        // eslint-disable-next-line max-len
        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, graphDataService.baseGraphData.selectedInteractable$,
            combineLatest([graphDataService.entranceBuildingPin$, graphDataService.exitBuildingPin$, graphDataService.entranceFloorPin$, graphDataService.exitFloorPin$, graphDataService.entranceZonePin$, graphDataService.exitZonePin$,
                graphDataService.entranceBuildingFloorPin$, graphDataService.exitBuildingFloorPin$, graphDataService.spEntranceBuildingFloorPin$, graphDataService.spExitBuildingFloorPin$])]).subscribe(
            ([selectedFloorName, selectedDirectory, nextLevel, selectedInteractable, [entranceDepthBuildingData, exitDepthBuildingData, entranceDepthFloorData, exitDepthFloorData, entranceDepthZoneData, exitDepthZoneData, entranceBuildingFloorPin, exitBuildingFloorPin,
                spEntranceBuildingFloorPin, spExitBuildingFloorPin]]) => {
                comInstance.isShow = selectedInteractable?.type === 'gate';
                if (excludedDirectory) {
                    comInstance.isShow = selectedDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : nextLevel !== excludedDirectory;
                }
                if (!selectedFloorName || !selectedInteractable || !selectedDirectory || selectedInteractable.type !== 'gate') { return; }
                const floorName = selectedFloorName || selectedDirectory.floor || 'ALL';
                const zoneName = selectedDirectory.zone;
                const buildingUpdatedName = configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? selectedInteractable.object.userData.building : buildingName;
                let floorUpdatedName = configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? selectedInteractable.object.userData.floor : floorName;
                const zoneUpdatedName = configDataService.isFeatureEnabled('traffic_page', 'model_user_data') ? ((selectedInteractable.object.userData.zone as string).split(',')).find(str => str.includes(zoneName)) : zoneName;
                if (zoneUpdatedName === 'pavilion_000_g_001_2nd_reception') {
                    floorUpdatedName = 'pavilion_000_g_001_2nd';
                }
                const floorIndexMainBuilding = Object.keys(configDataService.FLOOR_OBJECTS[configDataService.MAIN_BUILDING]).findIndex(k => k === floorName);
                const floorNameByBuilding = configDataService.isFeatureEnabled('multiple_organization') && floorName === 'onesiam_plus_007_5f' ? Object.keys(configDataService.FLOOR_OBJECTS[buildingUpdatedName])[floorIndexMainBuilding] : floorUpdatedName;
                const floorEntranceData = configDataService.isFeatureEnabled('multiple_organization') && floorNameByBuilding === 'siam_paragon_5th' ? spEntranceBuildingFloorPin : entranceBuildingFloorPin;
                const floorExitData = configDataService.isFeatureEnabled('multiple_organization') && floorNameByBuilding === 'siam_paragon_5th' ? spExitBuildingFloorPin : exitBuildingFloorPin;
                let entranceData: number;
                let exitData: number;
                if (contribute_level === 'building') {
                    entranceData = nextLevel === 'FLOOR' 
                    ? getTrafficData({...entranceDepthBuildingData, ...floorEntranceData}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : configDataService.isFeatureEnabled('multiple_organization') && floorName !== 'ALL' 
                    ? getTrafficData({...entranceDepthBuildingData, ...floorEntranceData }, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(entranceDepthZoneData, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                    exitData = nextLevel === 'FLOOR'
                    ? getTrafficData({...exitDepthBuildingData, ...floorExitData}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : configDataService.isFeatureEnabled('multiple_organization') && floorName !== 'ALL' 
                    ? getTrafficData({...exitDepthBuildingData, ...floorExitData}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(exitDepthZoneData, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                } 
                else {
                    entranceData = nextLevel === 'FLOOR' 
                    ? getTrafficData({...entranceDepthBuildingData, ...floorEntranceData}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(entranceDepthZoneData, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                    exitData = nextLevel === 'FLOOR'
                    ? getTrafficData({...exitDepthBuildingData, ...floorExitData}, buildingUpdatedName, floorNameByBuilding, selectedInteractable.name)
                    : getZonePinTrafficData(exitDepthZoneData, buildingUpdatedName, floorNameByBuilding, zoneUpdatedName, selectedInteractable.name);
                }
                if (zoneUpdatedName !== '' && zoneUpdatedName !== null && zoneUpdatedName !== undefined) {
                    if (zoneUpdatedName.includes(configDataService.isFeatureEnabled('heatmap_v2', 'depth_zone'))) {
                        const buildingInstanceName = configDataService.isFeatureEnabled('heatmap_v2', 'depth_zone');
                        const floorInstanceName = zoneUpdatedName;
                        entranceData = getTrafficData(entranceDepthFloorData, buildingInstanceName, floorInstanceName, selectedInteractable.name);
                        exitData = getTrafficData(exitDepthFloorData, buildingInstanceName, floorInstanceName, selectedInteractable.name);
                    }
                }
                barData$.next([{
                    data: [entranceData, null],
                    color: PIE_CHART_COLOR_LIST[0],
                    label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
                }, {
                    data: [null, exitData],
                    color: PIE_CHART_COLOR_LIST[1],
                    label: configDataService.DISPLAY_LANGUAGE.EXIT,
                }]);
            }
        ));

        comInstance.title = configDataService.DISPLAY_LANGUAGE.TRAFFIC;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = false;
        comInstance.data$ = barData$;
        comInstance.isStacked = 'XY';
        comInstance.xAxesRotation = 90;
        return comInstance;
    }

}
