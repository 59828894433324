import { Directive, Input, ElementRef, Renderer2, AfterViewInit, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appLockIcon]',
})
export class LockIconDirective implements AfterViewInit {
  @Input() isLock: BehaviorSubject<boolean> | boolean;
  @Input() lockSize = '5em';
  @Input() lockMargin = '0.25em auto';
  @Input() lockOpacity = 0.1;
  @Input() textColor = 'var(--ion-text-color)';
  @Input() inputLockText: string;

  element: ElementRef;
  renderer: Renderer2;
  lockDivContainer: HTMLDivElement = null;
  lockDiv: HTMLImageElement = null;
  lockText: HTMLDivElement = null;
  blackDiv: HTMLDivElement = null;
  lockIconPath = `assets/icon/lock-icon-white.svg`;

  constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.element = elementRef;
    this.renderer = renderer;
  }

  ngAfterViewInit(): void {
    if (this.isLock instanceof BehaviorSubject) {
      this.isLock.subscribe( (isLock: boolean) => {
        this.applyLock(isLock);
      });
    } else {
      this.applyLock(this.isLock);
    }
  }

  @HostListener('click')
  onInteract() {
    if (this.lockDiv === null) { return; }
    this.lockDiv.animate([
      // keyframes
      { transform: 'rotate(10deg)' } as Keyframe,
      { transform: 'rotate(-20deg)' } as Keyframe,
      { transform: 'rotate(10deg)' } as Keyframe
    ], {
      // timing options
      duration: 150,
      iterations: 3
    });
  }

  @HostListener('mouseenter')
  onShowText() {
    if (this.lockText === null) { return; }
    this.lockText.animate([
      { opacity: 0.0 } as Keyframe,
      { opacity: 1.0, easing: 'ease-in' } as Keyframe
    ], {
      duration: 200,
      iterations: 1,
      fill: 'forwards'
    } as KeyframeAnimationOptions);
  }

  @HostListener('mouseleave')
  onHideText() {
    if (this.lockText === null) { return; }
    this.lockText.animate([
      { opacity: 1.0 } as Keyframe,
      { opacity: 0.0, easing: 'ease-in' } as Keyframe
    ], {
      duration: 125,
      iterations: 1,
      fill: 'forwards'
    } as KeyframeAnimationOptions);
  }

  private initLockDiv() {
    this.lockDivContainer = this.renderer.createElement('div');
    this.lockDivContainer.style.cssText = `position: absolute; z-index: 2; display: flex; flex-flow: column; text-align: center;
                                            top: 0px; width: 100%; height: 100%; justify-content: center;`;
    this.lockDiv = new Image();
    this.lockDiv.src = this.lockIconPath;
    this.lockDiv.style.setProperty('margin', this.lockMargin);
    this.lockDiv.style.setProperty('max-width', this.lockSize);
    this.lockText = this.renderer.createElement('div');
    this.lockText.style.setProperty('width', '80%');
    this.lockText.style.setProperty('margin', '0px auto');
    this.lockText.style.setProperty('opacity', '0');
    this.lockText.style.setProperty('color', this.textColor);
    if (this?.inputLockText) {
      this.lockText.appendChild(new Text(this.inputLockText));
    } else {
      this.lockText.appendChild(new Text('To unlock, please contact your assigned account manager'));
    }
    this.lockDivContainer.appendChild(this.lockDiv);
    this.lockDivContainer.appendChild(this.lockText);

    this.blackDiv = this.renderer.createElement('div');
    this.blackDiv.style.setProperty('margin', '0% 0% 0% 0%');
    this.blackDiv.style.setProperty('position', 'absolute');
    this.blackDiv.style.setProperty('z-index', '1');
    this.blackDiv.style.setProperty('top', `0%`);
    this.blackDiv.style.setProperty('left', `0%`);
    this.blackDiv.style.setProperty('width', `100%`);
    this.blackDiv.style.setProperty('height', `100%`);
    this.blackDiv.style.setProperty('background', `rgba(0,0,0,${this.lockOpacity})`);
  }

  private applyLock(willLock: boolean) {
    if (willLock === true) {
      // this.element.nativeElement.style.filter = 'blur(5px)';
      if (this.lockDivContainer === null) {
        this.initLockDiv();
      }
      // parentNode
      // this.renderer.insertBefore(this.element.nativeElement, this.lockDiv, this.element.nativeElement.childNode);
      this.renderer.appendChild(this.element.nativeElement, this.blackDiv);
      this.renderer.appendChild(this.element.nativeElement, this.lockDivContainer);
      // this.renderer.insertBefore(this.element.nativeElement, this.lockDiv, this.element.nativeElement.childNode);
    } else {
      if (this.lockDivContainer !== null) {
        this.renderer.removeChild(this.element.nativeElement, this.lockDivContainer);
        this.renderer.removeChild(this.element.nativeElement, this.blackDiv);
      }
    }
  }

}
