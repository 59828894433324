<ion-list>
  <div
    class="selector-org-container"
    *ngFor="let logoImage of logoImageList | keyvalue; index as i" 
    (click)="changeOrganization(logoImage.key)"
  >
    <div style="min-width: 208px; max-width: 208px;">
      <img (load)="onImageLoad(logoImage.key)" style="height: 60px; object-fit: scale-down;" [src]="logoImage.value"/>
      <ion-skeleton-text *ngIf="!imageIsCompleted(logoImage.key)" style="min-height: 40px; max-height: 40px;" animated></ion-skeleton-text>
    </div>
    <div [ngStyle]="mobileScreen ? {'margin-left': '20px'} : {'margin-left': '140px'}" >
      <ion-icon style="margin-top: 1rem; font-size: 32px; color: black;" *ngIf="showCheckMark(logoImage.key)" name="checkmark-outline"></ion-icon>
    </div>
  </div>
</ion-list>
