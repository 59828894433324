import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-echart',
  templateUrl: './dynamic-echart.component.html',
  styleUrls: ['./dynamic-echart.component.scss'],
})
export class DynamicEchartComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() isShow: Observable<boolean> | boolean;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isLock = false;
  @Input() chartOption: EChartsOption;
  @Input() chartOption$: Observable<EChartsOption>;

  constructor() { }

  ngOnInit() { }

  // TODO: convert observable to this method
  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartOption) {
      console.log('In Dynamic', changes.chartOption);
      this.chartOption = changes.chartOption.currentValue;
    }
  }  

  get isShowGraph() {
    let showGraph = false;
    if (this.isShow instanceof Observable) {
      this.isShow.subscribe(show => {
        showGraph = show;
      });
    } else {
      showGraph = this.isShow;
    }

    return showGraph;
  }

}
