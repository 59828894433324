import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { getDepthData, isSelectionVisitorProfileAll } from '../helpers/util';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { VisitorProfileSelection } from '../objects/visitor-profile';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

const CHART_COLOR = '#4A6FDD';

export class VisitorTrafficTrendResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        // await configDataService.loadAppConfig();
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const initSelectedVisitorProfile: VisitorProfileSelection = graphDataService.selectedVisitorProfile$.getValue();
        const initBuildingName =  initSelectedVisitorProfile.organization === 'all' ? configDataService.MAIN_BUILDING : initSelectedVisitorProfile.organization;
        const initIsEntrance = configDataService.isEntranceDataMode$.getValue();
        const fillData: number[] = Array.from({ length: 7 }).map(() => null);
        const initBuildingEntranceExitData =  graphDataService.buildingEntranceExitData$.getValue();
        const initVisitorProfileTrafficByDepth = getDepthData<unknown>(initIsEntrance, initBuildingEntranceExitData, { buildingName: initBuildingName }, fillData) as number[];
        const initVisitorProfileData = isSelectionVisitorProfileAll(initSelectedVisitorProfile) 
        ? initVisitorProfileTrafficByDepth
        : graphDataService.visitorTrafficTrendData$.getValue();

        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: initVisitorProfileData,
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST,
            label: 'Traffic'
        }]);
        const isLock$ = new BehaviorSubject(this.isLock);

        if (isMockData) {
            subscription.add(combineLatest([graphDataService.selectedVisitorProfile$, viewPeriodService.dayList]).subscribe(async ([selectedVisitorProfile, dayList]) => {
                const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'VISITOR_TRAFFIC_TREND', 'count', 8);
                const chartPoints = Object.values(mockData);
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const lineChartData: GenericLineChartData[] = [{
                    points: chartPoints[0] as number[],
                    backgroundColor: `${CHART_COLOR}22`,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `Traffic,${time}`),
                    label: 'Traffic'
                }];
                chartData$.next(lineChartData);
            }));
        } else {
            subscription.add(combineLatest([graphDataService.buildingEntranceExitData$, graphDataService.visitorTrafficTrendData$]).subscribe(([buildingEntranceExitData, dataPoints]) => {
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const selectedVisitorProfile: VisitorProfileSelection = graphDataService.selectedVisitorProfile$.getValue();
                const buildingName = selectedVisitorProfile.organization === 'all' ? configDataService.MAIN_BUILDING : selectedVisitorProfile.organization;
                const isEntrance = configDataService.isEntranceDataMode$.getValue();
                const visitorProfileTrafficByDepth = getDepthData<unknown>(isEntrance, buildingEntranceExitData, { buildingName }, fillData) as number[];
                const visitorProfileData = isSelectionVisitorProfileAll(selectedVisitorProfile) ? visitorProfileTrafficByDepth : dataPoints;
    
                const lineChartData: GenericLineChartData[] = [{
                    points: visitorProfileData,
                    backgroundColor: `${CHART_COLOR}22`,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `Traffic,${time}`),
                    label: 'Traffic'
                }];
                chartData$.next(lineChartData);
                isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(selectedVisitorProfile)));
            }));
        }

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.TRAFFIC_TREND;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        comInstance.sizeXS = '12';
        comInstance.sizeMD = '10';
        comInstance.offsetMD = '1';
        return comInstance;
    }

}
