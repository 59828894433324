import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root',
})
export class ManageUserGuard implements CanActivate {
  constructor(private route: Router, private authenticationService: AuthenticationService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<boolean> | boolean {
    try {
      if (this.authenticationService.adminSession) {
        return this.authenticationService.adminSession;
      } else {
        return this.route.navigate(['/home']);
      }
    } catch {
      return this.route.navigate(['/home']);
    }
  }
}
