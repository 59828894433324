import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericPieChartData } from 'src/app/objects/chart';
import { ChartTooltipModel } from 'chart.js';

@Component({
  selector: 'app-dynamic-pie-chart-wrapper',
  templateUrl: './dynamic-pie-chart-wrapper.component.html',
  styleUrls: ['./dynamic-pie-chart-wrapper.component.scss'],
})
export class DynamicPieChartWrapperComponent implements OnInit {
  @Input() title: string;
  @Input() data$: BehaviorSubject<GenericPieChartData[]>;
  @Input() isLock: boolean | BehaviorSubject<boolean> = false;
  @Input() isShow = true;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() customToolTipFuction: (tooltipModel: ChartTooltipModel) => void;
  @Input() isShowLegendTitle = true;
  @Input() isShowLegendTitleValue = true;
  @Input() paddingRigt = '1rem';
  @Input() isShowLegendOnPercent = false;
  @Input() inputLockText: string;
  @Input() isEnableTBD = false;

  isShowLegend = true;
  lockOpacity = 0.35;

  constructor(
    private ref: ChangeDetectorRef
  ) { 
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() { }

  legendNumber(value: number) {
    if (!value) {
      return;
    }
    return  `${value.toFixed(1)}%`;
  }

}
