import { Component, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-customer-segment-details',
  templateUrl: './customer-segment-details.component.html',
  styleUrls: ['./customer-segment-details.component.scss']
})
export class CustomerSegmentDetailsComponent implements OnInit {

  CUSTOMER_SEGMENT_LIST: Array<string> = [];

  SEGMENT_TO_ETHNICITY_DICT: any = {
    'USA-Europe': 'White',
    'Middle East': 'Arabs',
    India: 'Indian',
    Asian: 'Asian',
    Others: 'Black'
  };

  ethnicityPicGenderSelect = {};

  constructor(
    private configDataService: ConfigDataService
  ) { }

  ngOnInit() {
    const ethKey = [];
    const ethnicityList = this.configDataService.ETHNICITY_CLASS;
    ethnicityList.forEach(eth => {
      this.ethnicityPicGenderSelect[eth] = [];
      this.CUSTOMER_SEGMENT_LIST.push(eth);
    });
    Object.entries(this.ethnicityPicGenderSelect).forEach(value => ethKey.push(value[0]));
    ethKey.forEach(ethnicity => {
      for (let i = 0; i < 3; i++) {
        this.ethnicityPicGenderSelect[ethnicity].push(Math.random());
      }
    });
  }

  getImageSrc(segment, index) {
    const gender = this.ethnicityPicGenderSelect[segment][index-1] >= 0.5 ? 'Male' : 'Female';
    return this.configDataService.ethnicityImageSrc?.[gender]?.[segment]?.[index] || '../assets/image/placeholder.png';
  }

  segmentDisplayName(eth: string) {
    return this.configDataService.DISPLAY_LANGUAGE.ETHNICITY_CLASS[eth];
  }

}
