import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appLockBlur]',
})
export class LockBlurDirective implements OnInit {
  @Input() isLock: BehaviorSubject<boolean> | boolean;
  @Input() blurRadius = 1.2;

  element: ElementRef;
  renderer: Renderer2;

  constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.element = elementRef;
    this.renderer = renderer;
  }

  ngOnInit(): void {
    if (this.isLock instanceof BehaviorSubject) {
      this.isLock.subscribe( (isLock: boolean) => {
        this.applyLock(isLock);
      });
    } else {
      this.applyLock(this.isLock);
    }
  }

  private applyLock(willLock: boolean) {
    if (willLock === true) {
      // this.element.nativeElement.style.filter = 'blur(5px)';
      this.renderer.setStyle(this.element.nativeElement, 'filter', `blur(${this.blurRadius}px)`);
    } else {
      // this.element.nativeElement.style.filter = 'blur(0px)';
      this.renderer.removeStyle(this.element.nativeElement, 'filter');
    }
  }
}
