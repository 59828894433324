/* eslint-disable max-len */
import { OverallResolverBase } from './OverallResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { GenericBarChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined } from '../helpers/util';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { showGenericBarChartCustomTooltips } from '../helpers/barChartTooltips';

export class OverallHeadcountResolver extends OverallResolverBase {

    public get graphDependencyMap(): { building: GraphDependency[]; floor: GraphDependency[]; zone: GraphDependency[] } {
        return {
            building: [GraphDependency.ENTRANCE_EXIT],
            floor: [GraphDependency.ENTRANCE_EXIT_FLOOR],
            zone: [GraphDependency.ENTRANCE_EXIT_ZONE]
        };
    }

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
    ) {
        // load data here
        const level = getAdditionalInput(additionalInput, 'level') as DynamicGraphAdditionalInput['level'] | undefined;
        const groupData = getAdditionalInput(additionalInput, 'groupData') as DynamicGraphAdditionalInput['groupData'] | undefined;
        assertNullUndefined(level);
        this.processDynamicDependency(configDataService, graphDataService, level);
        // await configDataService.loadAppConfig();

        type EntraceExitData = { entrance: { headCount: number; diff: number; diffPercent: number }; exit: { headCount: number; diff: number; diffPercent: number } };
        type FlattenEntraceExitData = { [name: string]: EntraceExitData };
        const entranceExitReducer = (data: FlattenEntraceExitData, isEntrance: boolean) => {
            const channel = isEntrance ? 'entrance' : 'exit';
            return Object.entries(data || {}).reduce((prev, [name, entranceExitData]) => {
                prev[name] = entranceExitData[channel];
                return prev;
            }, {} as { [name: string]: { headCount: number; diff: number; diffPercent: number } });
        };
        const getTrafficTrendData = (
            isEntrance: boolean,
            dataBuilding: { [buildingName: string]: EntraceExitData },
            dataFloor: { [buildingName: string]: { [floorName: string]: EntraceExitData } },
            dataZone: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: EntraceExitData } } },
        ) => entranceExitReducer(this.getLeveledData<EntraceExitData>(dataBuilding, dataFloor, dataZone, level), isEntrance);
        const initialComparingNames = level === 'building' ? this.getComparingNames(graphDataService.baseGraphData.comparingBuildingState$.value) : (level === 'floor' ? this.getComparingNames(graphDataService.baseGraphData.comparingFloorState$.value) : this.getComparingNames(graphDataService.baseGraphData.comparingZoneState$.value));
        const initialData = getTrafficTrendData(configDataService.isEntranceDataMode, graphDataService.currentBuildingHeadCountData$.value, graphDataService.currentBuildingHeadCountFloorData$.value, graphDataService.currentBuildingHeadCountZoneData$.value);
        let initialColor: string[] = [];
        let initialFilteredData = Object.entries(initialData).sort().filter(([name, _data]) => {
            if (initialComparingNames.includes(name)) {
                if (level === 'zone' && configDataService?.ZONE_LINE_CHART_COLOR_LIST) {
                    initialColor.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                } else {
                    initialColor.push('#6593F5');
                }
                return true;
            }
            return false;
        });
        let xAxisSuggestedTickMax: number = Math.max.apply(Math, initialFilteredData.map((o) => (o[1].headCount)));
        if (this.configDataService.MAIN_BUILDING === `mbk` && level === `floor` || `zone`) {
            initialFilteredData = initialFilteredData.sort().reverse();
            initialColor = initialColor.reverse();
        } else {
            initialFilteredData.sort();
        }
        const chartData$ = new BehaviorSubject<GenericBarChartData[]>(initialFilteredData.map(([name, data], idx) => ({
            data: initialFilteredData.map(([nameIt, _data]) => nameIt === name ? data.headCount : null),
            color: initialColor[name],
            label: `${data.headCount}`,
            calPercentFrommAllData: false,
        })));
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.chartLabel = initialFilteredData.map(([name, _data]) => this.getDisplayName(name));
        comInstance.data$ = chartData$;

        // eslint-disable-next-line max-len
        subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.currentBuildingHeadCountData$, graphDataService.currentBuildingHeadCountFloorData$, graphDataService.currentBuildingHeadCountZoneData$, combineLatest([graphDataService.baseGraphData.comparingBuildingState$, graphDataService.baseGraphData.comparingFloorState$, graphDataService.baseGraphData.comparingZoneState$])]).subscribe(
            ([isEntranceDataMode, entranceExitBuildingData, entranceExitFloorData, entranceExitZoneData, [comparingBuildingState, comparingFloorState, comparingZoneState]]) => {
                if ((!entranceExitBuildingData && level === 'building') || (!entranceExitFloorData && level === 'floor') || (!entranceExitZoneData && level === 'zone')) { return; }
                const comparingNames = level === 'building' ? this.getComparingNames(comparingBuildingState) : (level === 'floor' ? this.getComparingNames(comparingFloorState) : this.getComparingNames(comparingZoneState));
                const fullData = getTrafficTrendData(isEntranceDataMode, entranceExitBuildingData, entranceExitFloorData, entranceExitZoneData);
                if (groupData) {
                    Object.keys(groupData).forEach(k => {
                        const arrDataPoints: number[] = [];
                        groupData[k].args.forEach(arg => {
                            arrDataPoints.push(fullData[arg].headCount);
                        });
                        fullData[k] = { headCount: groupData[k].oper === 'AVG' ? arrDataPoints.length === 0 ? 0 : arrDataPoints.reduce(((a, b) => a + b), 0) / arrDataPoints.length : arrDataPoints.reduce(((a, b) => a + b), 0), diff: 0, diffPercent: 0 };
                    });
                }
                let chartColors: string[] = [];
                let dataFiltered = Object.entries(fullData).sort().filter(([name, _data]) => {
                    if (comparingNames.includes(name)) {
                        if (level === 'zone' && configDataService?.ZONE_LINE_CHART_COLOR_LIST) {
                            chartColors.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                        } else {
                            chartColors.push('#6593F5');
                        }
                        return true;
                    }
                    return false;
                });
                xAxisSuggestedTickMax = Math.max.apply(Math, dataFiltered.map((o) => (o[1].headCount)));
                if (configDataService.MAIN_BUILDING === `mbk` && level === `floor` || `zone`) {
                    dataFiltered = dataFiltered.sort().reverse();
                    chartColors = chartColors.reverse();
                } else {
                    dataFiltered.sort();
                }
                const barChartData: GenericBarChartData[] = dataFiltered.map(([name, data], idx) => ({
                    data: dataFiltered.map(([nameIt, _data]) => nameIt === name ? data.headCount : null),
                    color: chartColors[idx],
                    label: `${data.headCount}`,
                    calPercentFrommAllData: false,
                }));
                chartData$.next(barChartData);
                comInstance.chartLabel = dataFiltered.map(([name, _data]) => this.getDisplayName(name));
                comInstance.suggestedTickMax_X = xAxisSuggestedTickMax + (xAxisSuggestedTickMax / 10);
            }));
        
        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.HEADCONT;
        comInstance.isShowLegend = false;
        comInstance.isHorizontal = true;
        comInstance.valueTextAlign = 'left';
        comInstance.valueTextOffsetX = 3;
        comInstance.valueTextOffsetY = 5;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.displayAxis = 'Y';
        comInstance.isStacked = 'Y';
        comInstance.displayGrid = false;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showGenericBarChartCustomTooltips(tooltipModel, this);
        };
        return comInstance;
    }

}
