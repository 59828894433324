<ion-row [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }">
  <ion-col appLockIcon [isLock]="isLock" appDataTbd [isEnableTBD]="isEnableTBD" [lockOpacity]="lockOpacity" >
    <ion-card>
      <ion-card-header *ngIf="shouldShowSelector || enableSorter; else normalTitle">
        <ion-grid class="ion-no-padding">
          <div class="grid-container" style="width: 100%; display: grid; align-items: center" [style.grid-template-columns]="updateGridLayout(enableSorter, shouldShowSelector)">
            <div class="grid-title" style="display: flex; justify-content: start; align-items: center">
              <ion-card-title id="col-title-name">
                {{ title }}
                <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
              </ion-card-title>
            </div>
            <ng-container *ngIf="enableSorter">
              <ion-button class="no-shadow" *ngIf="sortedState$ | async as sortedState" color="secondary" expand="block" style="font-size: 14px" (click)="handleSortButton(sortedState)"><ion-icon [name]="getSortingIcon(sortedState)"></ion-icon></ion-button>
            </ng-container>
            <ng-container *ngIf="shouldShowSelector">
              <div id="col-selector" style="display: flex; justify-content: flex-end; align-items: center">
                <ion-select
                  [multiple]="multipleSelector"
                  [value]="selectedName"
                  style="font-size: 16px; color: var(--ion-color-primary); text-align: justify;"
                  [interface]="selectInterface"
                  (ionChange)="changeSelectedGraph($event)"
                  #ionGraphSelector
                  mode="md"
                >
                  <ion-select-option *ngFor="let lineName of selectorName; index as i" [value]="lineName">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] }}</ion-select-option>
                </ion-select>
              </div>
            </ng-container>
          </div>
          <ion-row *ngIf="isShowAveragePerDayButton">
            <ion-col>
              <ion-button size="6" (click)="toggleAveragePerDayFilter()" [color]="showAverage ? 'primary' : 'secondary'">
                Average Per Day
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-header>

      <ng-template #normalTitle>
        <ion-card-header>
          <ion-card-title>
            {{ title }}
            <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
          </ion-card-title>
          <ion-row *ngIf="isShowAveragePerDayButton">
            <ion-col>
              <ion-button size="6" (click)="toggleAveragePerDayFilter()" [color]="showAverage ? 'primary' : 'secondary'">
                <!-- {{ showAverage ? 'Hide Average' : 'Show Average' }} -->
                Average Per Day
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-header>
      </ng-template>
      <ion-card-content style="margin-top: 0px; padding-top: 0px; padding-bottom: 0px" [ngClass]="isOnTrafficPage ? 'ion-no-padding' : ''">
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-no-padding">
            <ion-col class="ion-no-padding" size-xs="12" [sizeMd]="isOnTrafficPage ? 12 : 10" [offsetMd]="isOnTrafficPage ? 0 : 1" size-lg="12" offset-lg="0" appLockBlur [isLock]="isLock">
              <app-generic-bar-chart
                [data$]="data$"
                [chartLabel]="chartLabel"
                [isShowLegend]="isShowLegend"
                [isHorizontal]="isHorizontal"
                [customToolTipFuction]="customToolTipFuction"
                [aspectRatio]="aspectRatio"
                [valueTextAlign]="valueTextAlign"
                [valueTextOffsetX]="valueTextOffsetX"
                [valueTextOffsetY]="valueTextOffsetY"
                [valueTextProcess]="valueTextProcess"
                [xAxesRotation]="xAxesRotation"
                [yAxesRotation]="yAxesRotation"
                [displayAxis]="displayAxis"
                [displayGrid]="displayGrid"
                [gridColor]="gridColor"
                [isStacked]="isStacked"
                [suggestedTickMax_X]="suggestedTickMax_X"
                [displayTextValue]="displayTextValue"
                [isOnTrafficPage]="isOnTrafficPage"
                [paddingRight]="paddingRight"
              ></app-generic-bar-chart>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
