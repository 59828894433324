import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root',
})
export class RawDataGuard implements CanActivate {
  constructor(private route: Router, private authenticationService: AuthenticationService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<boolean> | boolean {
    try {
      return this.authenticationService.USER_ROLES.roles.can_export_data;
    } catch {
      return this.route.navigate(['/home']);
    }
  }
}
