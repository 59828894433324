import { SelectableDataName } from './../objects/selectableData';
import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGaugeChartWrapperComponent } from '../shared-components/dynamic/dynamic-gauge-chart-wrapper/dynamic-gauge-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class ParkingLotOccupancyResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        const occupancy = configDataService.GRAPH_CONFIG.PARKING_LOT_OCCUPANCY;
        const currentData$ = new BehaviorSubject<{ current: number; min: number; max: number }>(null);
        if ('min' in occupancy && 'max' in occupancy) {
            currentData$.next({
                current: graphDataService.parkingLotOccupancyData$.value,
                min: occupancy.min as number,
                max: occupancy.max as number,
            });
        } else {
            const areaName = 'all';
            const occupancyArea = occupancy[areaName] || { min: 0, max: 1000 };
            currentData$.next({
                current: graphDataService.parkingLotOccupancyData$.value,
                min: occupancyArea.min,
                max: occupancyArea.max,
            });
        }
        subscription.add(combineLatest([graphDataService.baseGraphData.selectedInteractable$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.parkingLotOccupancyData$])
            .subscribe(([selectedInteractable, selectedDirectory, parkingLotOccupancyData]) => {
                const { building, floor, zone } = selectedDirectory;
                let areaName = configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
                if (selectedInteractable?.name) {
                    areaName = selectedInteractable.name;
                } else if (building !== null && building !== undefined && building !== '') {
                    if (zone !== '') {
                        areaName = zone;
                    } else if (floor !== '') {
                        areaName = floor;
                    } else {
                        areaName = building;
                    }   
                }
                if (configDataService?.MOCK_CONFIG?.PARKING_LOT_OCCUPANCY && Object.keys(configDataService.MOCK_CONFIG.PARKING_LOT_OCCUPANCY).find(k => k === areaName) !== undefined) {
                    const mockDataConfig: { current: number; min: number; max: number } = configDataService.MOCK_CONFIG.PARKING_LOT_OCCUPANCY[areaName];
                    currentData$.next({
                        current: mockDataConfig.current,
                        min: mockDataConfig.min,
                        max: mockDataConfig.max,
                    });
                }
                if ('min' in occupancy && 'max' in occupancy) {
                    currentData$.next({
                        current: parkingLotOccupancyData,
                        min: occupancy.min as number,
                        max: occupancy.max as number,
                    });
                } else {
                    const occupancyArea = occupancy[areaName] || { min: 0, max: 1000 };
                    currentData$.next({
                        current: parkingLotOccupancyData,
                        min: occupancyArea.min,
                        max: occupancyArea.max,
                    });
                }
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicGaugeChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.PARKING_LOT_OCCUPANCY;
        // comInstance.reverseArcColor = true;
        comInstance.isLock = this.isLock;
        comInstance.data$ = currentData$;
        comInstance.isShow$ = viewPeriodService.isLiveMode$;
        return comInstance;
    }

}
