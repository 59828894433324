import { Component, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-customer-gender-tooltips',
  templateUrl: './customer-gender-tooltips.component.html',
  styleUrls: ['./customer-gender-tooltips.component.scss']
})
export class CustomerGenderTooltipsComponent implements OnInit {

  GENDER_LIST: Array<string> = [
    'Male',
    'Female'
  ];

  constructor(
    private configDataService: ConfigDataService
  ) { }

  ngOnInit() {

  }

  getImageSrc(segment, index) {
    return this.configDataService?.genderImageSrc[segment][index];
  }

}
