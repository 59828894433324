import { SelectableDataName } from './../objects/selectableData';
import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGaugeChartWrapperComponent } from '../shared-components/dynamic/dynamic-gauge-chart-wrapper/dynamic-gauge-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';

export class StaffOccupancyResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const currentData$ = new BehaviorSubject<{ current: number; min: number; max: number }>({
            // current: graphDataService.parkingLotOccupancyData$.value,
            current: 0,
            min: 0,
            max: 100
        });
        subscription.add(combineLatest([graphDataService.baseGraphData.selectedInteractable$])
        .subscribe(([selectableName]) => {
            const areaName = selectableName?.name;
            if (!areaName) {
                return;
            }
            // const formattedAreaName = areaName.toLocaleUpperCase();
             if (Object.keys(configDataService.MOCK_CONFIG.CURRENT_STAFF_TRAFFIC).find(k => k === areaName) !== undefined) {
                const mockDataConfig: { current: number; min: number; max: number } = configDataService.MOCK_CONFIG.CURRENT_STAFF_TRAFFIC[areaName];
                currentData$.next({
                    current: mockDataConfig.current,
                    min: mockDataConfig.min,
                    max: mockDataConfig.max,
                });
            } else {
                currentData$.next({
                    current: 0,
                    min: 0,
                    max: 100,
                });
            }
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicGaugeChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.CURRENT_STAFF_TRAFFIC;
        comInstance.isLock = this.isLock;
        comInstance.data$ = currentData$;
        comInstance.isShow$ = viewPeriodService.isLiveMode$;
        return comInstance;
    }

}
