import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericPieChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';

export class StoreEthnicityProfileResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        let notAsianSumMapData = [];
        let asianSumMapData = [];
        if (graphDataService.storeEthnicityProfileData$.value) {
            notAsianSumMapData = graphDataService.storeEthnicityProfileData$.value?.male.map((num, idx) => {
                if (idx !== graphDataService.storeEthnicityProfileData$.value?.male.length - 1){
                    return num + graphDataService.storeEthnicityProfileData$.value?.female[idx];
                }
                return 0;
            });
            asianSumMapData = graphDataService.storeEthnicityProfileData$.value?.male.map((num, idx) => {
                if (idx === graphDataService.storeEthnicityProfileData$.value?.male.length - 1){
                    return num + graphDataService.storeEthnicityProfileData$.value?.female[idx];
                }
                return 0;
            });

        }

        const pieData$ = new BehaviorSubject<GenericPieChartData[]>([{
            data: notAsianSumMapData.reduce((prev, current) => prev + current, 0),
            color: CHART_SECONDARY_COLOR,
            label: 'Not Asian'
        }, {
            data: asianSumMapData.reduce((prev, current) => prev + current, 0),
            color: CHART_PRIMARY_COLOR,
            label: 'Asian'
        }]);

        subscription.add(graphDataService.storeEthnicityProfileData$.subscribe(enthnicityData => {
            const nextNotAsianSumMapData = enthnicityData?.male.map((num, idx) => {
                if (idx !== enthnicityData.male.length - 1){
                    return num + enthnicityData.female[idx];
                }
                return 0;
            });
            const nextAsianSumMapData = enthnicityData?.male.map((num, idx) => {
                if (idx === enthnicityData.male.length - 1){
                    return num + enthnicityData.female[idx];
                }
                return 0;
            });
            pieData$.next([{
                data: nextNotAsianSumMapData.reduce((prev, current) => prev + current, 0),
                color: CHART_SECONDARY_COLOR,
                label: 'Not Asian'
            }, {
                data: nextAsianSumMapData.reduce((prev, current) => prev + current, 0),
                color: CHART_PRIMARY_COLOR,
                label: 'Asian'
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.ETHNICITY_PROFILE;
        comInstance.isLock = this.isLock;
        /*comInstance.infoPopover = async (e: any) => {
            const customerGenderPopover = await popoverController.create({
                component: CustomerGenderTooltipsComponent,
                cssClass: 'customer-segment-details-popover',
                event: e
            });
            return customerGenderPopover.present();
        };*/
        comInstance.isShowLegend = true;
        comInstance.data$ = pieData$;
        comInstance.isShowLegendOnPercent = false;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this, 'percentage');
            // showGenderCustomTooltips(tooltipModel, this, configDataService.genderImageSrc); not found image
        };
        return comInstance;
    }

}
