import { Component, OnInit, Input, OnChanges, ElementRef, Renderer2, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public relativeWidth = 0.7;
  @Input() public arcColors = ['#de5e56', '#eb9e9a', '#eaeceb', '#afd4bc', '#80be78'];
  @Input() public needleValue = 85;
  @Input() public centralLabel = '';
  @Input() public name = '';
  @Input() public bottomLabel = '';
  @Input() public bottomLabelFont;
  @Input() public textColor = ['var(--ion-text-color)', 'var(--ion-text-color)', 'var(--ion-text-color)'];
  @Input() public options = {
    hasNeedle: true,
    needleColor: 'white',
    needleUpdateSpeed: 500,
    arcColors: this.arcColors,
    arcPaddingColor: 'white',
    arcDelimiters: [20, 40, 60, 80],
    // arcLabels: ['strong decrease', 'decrease', 'increase', 'strong increase'],
    arcLabelColor: 'white',
    // rangeLabel: ['Decrease', 'Increase'],
    needleStartValue: 0,
    rangeLabelColors: ['white', 'white']
  };

  canvasWidth = 300;
  bottomLabelColor: BehaviorSubject<string> = new BehaviorSubject<string>('#80be78');
  element: ElementRef;
  renderer: Renderer2;
  guageColorSubscription = new Subscription();
  changeDetectorRef: ChangeDetectorRef;
  gaugeChartElement: HTMLDivElement;
  sizeListenerElement: HTMLDivElement;
  finishUpdateSize = false;

  constructor(elementRef: ElementRef, renderer: Renderer2, changeDetectorRef: ChangeDetectorRef) {
    this.element = elementRef;
    this.renderer = renderer;
    this.changeDetectorRef = changeDetectorRef;
  }

  private tryGrabGaugeChartElement() {
    try {
      this.grabGaugeChartElement();
    } catch (err) {
      setTimeout(() => this.tryGrabGaugeChartElement(), 10);
    }
  }

  private grabGaugeChartElement() {
    this.guageColorSubscription.unsubscribe();
    this.guageColorSubscription = new Subscription();
    this.gaugeChartElement = this.element.nativeElement.getElementsByClassName('gauge-chart')[0] as HTMLDivElement;
    this.gaugeChartElement.style.width = 'auto';
    const guageChartLabel = this.gaugeChartElement.getElementsByClassName('gauge-chart__label')[0] as HTMLElement;
    this.guageColorSubscription.add(this.bottomLabelColor.subscribe(color => {
      guageChartLabel.style.color = color;
    }));
    /**
     * <div gauge-chart>
     *   <div>
     *     <svg>
     *       <text></text>
     */
    this.gaugeChartElement.querySelectorAll('div svg text').forEach((textNode, index) => {
      (textNode as HTMLElement).style.setProperty('fill', this.textColor[index]);
    });
    this.bottomLabelColor.next(this.arcColors[Math.floor(this.needleValue / 20)]);
  }

  /**
   * Wait for initialization of sizeListenerElement which will be 0 at first,
   * according to my testing it will not be zero after 250ms
   */
  private waitForSizeListenerElement() {
    this.canvasWidth = this.sizeListenerElement.clientWidth * this.relativeWidth;
    if (this.canvasWidth !== 0) {
      this.finishUpdateSize = true;
      this.changeDetectorRef.detectChanges();
      this.tryGrabGaugeChartElement();
    } else {
      setTimeout(() => this.waitForSizeListenerElement(), 100);
    }
  }

  ngAfterViewInit(): void {
    this.waitForSizeListenerElement();
    window.addEventListener('resize', (ev) => {
      this.finishUpdateSize = false;
      this.changeDetectorRef.detectChanges();
      this.canvasWidth = this.sizeListenerElement.clientWidth * this.relativeWidth;
      this.finishUpdateSize = true;
      this.changeDetectorRef.detectChanges();
      this.tryGrabGaugeChartElement();
    }, false);
  }

  ngOnInit() {
    this.sizeListenerElement = this.element.nativeElement.getElementsByClassName('gauge-chart--size-listener')[0] as HTMLDivElement;
  }

  ngOnChanges() {
    this.bottomLabelColor.next(this.arcColors[Math.floor(this.needleValue / 20)]);
    setTimeout(() => {
      if (!this.gaugeChartElement) {
        return;
      }
      this.gaugeChartElement.querySelectorAll('div svg text').forEach((textNode, index) => {
        (textNode as HTMLElement).style.setProperty('fill', this.textColor[index]);
      });
    }, this.options.needleUpdateSpeed + 1);
  }
}
