/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { isScreenSmallerThanLG, numberFormatter, isScreenSmallerThanMD, manipulateArrays } from './util';
import { CHART_AXIS_TEXT_COLOR, BOTH_THEME_BASE_COLOR } from '../configs/color';
import { ChartOptions, ChartDataSets } from 'chart.js';
import * as Chart from 'chart.js';

interface LabelCount {
  [key: string]: number;
}

function calculatePercentageByLabel(chartDataSets: ChartDataSets[]): LabelCount[] {
  const chartDataSetsLabelCounts: LabelCount[] = [];
  for (const chartDataSet of chartDataSets) {
    // Calculate total count for each label
    const labelCounts: LabelCount = {};
    const label = chartDataSet.label;
    if (labelCounts[label]) {
      labelCounts[label] += (chartDataSet.data as number[]).reduce((a, b) => a + b, 0);
    } else {
      labelCounts[label] = (chartDataSet.data as number[]).reduce((a, b) => a + b, 0);
    }
    chartDataSetsLabelCounts.push(labelCounts);
  }
  return chartDataSetsLabelCounts;
}

export const hoverTooltipLineChartOptions: ChartOptions = {
  responsive: true,
  aspectRatio: 0.8,
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 5,
      bottom: 5,
    }
  },
  animation: {
    duration: 1000,
    easing: 'easeOutCubic',
  },
  hover: {
    animationDuration: 400,
  },
  responsiveAnimationDuration: 1000,
  tooltips: {
    mode: 'nearest',
    // customFixedLineChart defined in /app.component.ts
    position: isScreenSmallerThanMD(window) ? 'customFixedLineChart' : 'nearest',
    caretPadding: 10,
    caretSize: isScreenSmallerThanMD(window) ? 0 : 5,
    titleFontSize: isScreenSmallerThanLG(window) ? 18 : 20,
    bodyFontSize: isScreenSmallerThanLG(window) ? 14 : 16,
    multiKeyBackground: 'rgba(0, 0, 0, 0.1)',
    callbacks: {
      title(tooltipItemArray, data) {
        if (!tooltipItemArray) { return; } 
        const splitTxt = ((data.datasets[tooltipItemArray[0].datasetIndex] as any).toolTipLabel).map(label => label ? (label as string).split(',')[1] : null);
        const labelText = splitTxt;
        return labelText ? (Array.isArray(labelText) ? labelText[tooltipItemArray[0].index] : labelText) : data.labels[tooltipItemArray[0].index] as string[];
      },
      label(tooltipItem, data) {
        const toolTipLabel = (data.datasets[tooltipItem.datasetIndex] as any).toolTipLabel;
        if (!toolTipLabel || !toolTipLabel[tooltipItem.index]) { return; }
        const predTooltipLabel = Array.from(toolTipLabel);
        if (tooltipItem.index > 5 && toolTipLabel.some(label => !label)) {
          predTooltipLabel.fill(toolTipLabel[toolTipLabel.length - 1], 0, toolTipLabel.length);
        }
        const splitLabel = (toolTipLabel.some(label => !label) ? predTooltipLabel : toolTipLabel).map(label => label ? (label as string).split(',')[0] : null);
        const xValue = tooltipItem.yLabel;
        let unit = (data.datasets[tooltipItem.datasetIndex] as any).toolTipLabelUnit;
        unit = unit ? unit : '';
        return (!toolTipLabel && !toolTipLabel[tooltipItem.index]) ? null : `${splitLabel[0]}: ${xValue.toLocaleString()} ${unit}`;
      },
      labelColor(tooltipItem, chart) {
        const datasets = chart.data.datasets[tooltipItem.datasetIndex];
        const colors = (datasets as any).colors;
        return {
          borderColor: 'rgba(0, 0, 0, 0.1)',
          backgroundColor: colors ? colors[tooltipItem.index] : datasets.borderColor as any,
        };
      },
    },
  },
  scales: {
    yAxes: [
      {
        display: true,
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          maxTicksLimit: 6,
          callback(value) {
            return numberFormatter(value, 2);
          },
          fontColor: CHART_AXIS_TEXT_COLOR,
        },
        gridLines: {
          color: BOTH_THEME_BASE_COLOR,
          // lineWidth: 1
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          labelOffset: -5,
          maxTicksLimit: 24,
          maxRotation: 90,
          minRotation: 90,
          fontColor: CHART_AXIS_TEXT_COLOR,
        },
        gridLines: {
          color: BOTH_THEME_BASE_COLOR,
          // lineWidth: 1
        },
      },
    ],
  },
  legend: {
    labels: {
      generateLabels(chart) {
        const data = chart.data;
        chart.aspectRatio = isScreenSmallerThanLG(window) ? 1 : 1.3;
        chart.config.options.legend.labels.fontSize = isScreenSmallerThanLG(window) ? 12 : 14;
        chart.config.options.legend.labels.boxWidth = isScreenSmallerThanLG(window) ? 12 : 14;
        if (data.labels.length > 0 && data.datasets.length > 0) {
          return data.datasets.map(function(d, i) {
            return {
              text: d.label ? (d.label as string) : '',
              fillStyle: d.borderColor ? (d.borderColor as string) : '',
              lineCap: 'butt',
              lineDash: [],
              lineWidth: 1,
              lineDashOffset: 0,
              lineJoin: 'bevel',
              strokeStyle:  d.borderColor ? (d.borderColor as string) : '',
              index: i,
              isShadow: (d as any).isShadow,
            };
          });
        }
      },
      filter(item, _chart) {
        if (item.text === '') {
          return false;
        }
        return !(item as any).isShadow;
      },
      boxWidth: 14,
      fontColor: 'white',
      fontSize: 14,
      // usePointStyle: true,
    },
    onClick: (e) => e.stopPropagation(),
  }
};

export const hoverTooltipPieChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: isScreenSmallerThanLG(window) ? 1.25 : 1.5,
  layout: {
    padding: {
      top: 5,
      right: 0,
      bottom: 0,
      left: 0,
    }
  },
  animation: {
    duration: 1000,
    easing: 'easeOutCubic',
    onComplete(chart) {
      const displayTextValue = true;
      const ctx = this.chart.ctx;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.font = ctx.font.replace(/\d+px/, '14px');

      this.data.datasets.forEach((dataset) => {
        for (let i = 0; i < dataset.data.length; i++) {
          const metadata = dataset._meta[Object.keys(dataset._meta)[0]];
          const model = metadata.data[i]._model;
          const total = metadata.total;
          const mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2 + 10;
          const start_angle = model.startAngle;
          const end_angle = model.endAngle;
          const mid_angle = start_angle + (end_angle - start_angle) / 2;

          const x = mid_radius * Math.cos(mid_angle);
          const y = mid_radius * Math.sin(mid_angle);

          ctx.fillStyle = '#fff';
          const percentValue = (dataset.data[i] / total * 100).toFixed(1);
          const percent = percentValue + '%';
          if (displayTextValue) {
            if (dataset.data[i] !== 0 && parseFloat(percentValue) >= 4) {
              ctx.fillText(percent, model.x + x, model.y + y + 5);
            }
          }
        }
      });
    }
  },
  hover: {
    animationDuration: 400,
  },
  responsiveAnimationDuration: 1000,
  tooltips: {
    titleFontSize: isScreenSmallerThanLG(window) ? 18 : 18,
    bodyFontSize: isScreenSmallerThanLG(window) ? 14 : 16,
    // custom(tooltipModel) {
    //   showGenderCustomTooltips(tooltipModel, this, thisClass.configDataService.genderImageSrc);
    // },
    // callbacks: {
    //   label: function(tooltipItem, data) {
    //     const value: number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
    //     return data.labels[tooltipItem.index] + ': ' + value.toLocaleString()
    //   }
    // },
    enabled: true,
    // custom: function(tooltipModel) {
    //   showGenderCustomTooltips(tooltipModel, this,)
    // }
  },
  legend: {
    labels: {
      generateLabels: (chart) => {
        const data = chart.data;
        chart.aspectRatio = isScreenSmallerThanLG(window) ? 1.25 : 2;
        chart.config.options.legend.labels.fontSize = isScreenSmallerThanLG(window) ? 12 : 14;
        chart.config.options.legend.labels.boxWidth = isScreenSmallerThanLG(window) ? 12 : 14;
        if (data.labels.length > 0 && data.datasets[0].data.length > 0) {
          const totalLengthLabel = data.labels.length - 1;
          // const datasets = data.datasets[0].data as number[];
          const labels = data.labels;
          const backgroundColors = (data.datasets[0].backgroundColor as Chart.ChartColor[]);
          return labels.map((label, i) => ({
            text: label ? label as string : '',
            fillStyle: backgroundColors[i] ? (backgroundColors[i] as string) : '',
            index: i,
            hidden: false 
          })
        );
        }
      },
      fontColor: '#fff'
    },
  },
};

function newOnClickLegend(_event, legendItem) {
  const index = legendItem.index;
  const ci = this.chart as Chart;
  const meta = ci.getDatasetMeta(index);

  // See controller.isDatasetVisible comment
  meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

  if (ci.data.datasets[index].hidden) {
    ci.data.datasets[index].hidden = false;
  }

  // We hid a dataset ... rerender the chart
  ci.update();
}

/** This option draw value on top of chart base on first dataset if it has .isCombinedPercent set to true */
export function getHoverTooltipBarChartOptions(
  aspectRatio: number,
  valueTextAlign: 'center' | 'left' | 'right' = 'center',
  valueTextProcess: 'numberFormatter' | 'toLocaleString' | 'percentFormatter' = 'numberFormatter',
  valueTextOffsetX: number = 0,
  valueTextOffsetY: number = 0,
  xAxesRotation: number = 0,
  yAxesRotation: number = 0,
  displayAxis: 'X' | 'Y' | 'XY' | '!XY' = 'X',
  displayGrid: boolean = false,
  gridColor: string = '#424242',
  axisTextProcess: 'numberFormatter' | 'toLocaleString' = 'numberFormatter',
  isStack: 'X' | 'Y' | 'XY' | '!XY' = 'X',
  suggestedTickMax_X: number = 0,
  displayTextValue: boolean,
  isOnTrafficPage: boolean,
  paddingRight: number,
  ): ChartOptions {
  const paddingLayout = {
    top: isOnTrafficPage ? 5 : 15,
    left: 5, // isOnTrafficPage ? 5 : isScreenSmallerThanMD(window) ? 0 : 5,
    right: isOnTrafficPage ? 0 : paddingRight ? paddingRight : isScreenSmallerThanMD(window) && displayAxis === 'Y' ? 15 : 5,
    bottom: 0,
  };
  const fontSizeLegend = isOnTrafficPage ? 11 : 14;
  return {
    responsive: true,
    // aspectRatio: isScreenSmallerThanLG(window) ? 1.7 : 1.4,
    aspectRatio: 1.5,
    legend: {
      onClick: newOnClickLegend,
      labels: {
        generateLabels(chart) {
          const data = chart.data;
          chart.aspectRatio = isScreenSmallerThanLG(window) ? 1.5 : 1.3;
          chart.config.options.legend.labels.fontSize = isScreenSmallerThanLG(window) ? 12 : 14;
          chart.config.options.legend.labels.boxWidth = isScreenSmallerThanLG(window) ? 12 : 14;
          if (data.labels.length > 0 && data.datasets.length > 0) {
            return data.datasets.map(function(d, i) {
              return {
                text: d.label ? (d.label as string) : '',
                fillStyle: d.backgroundColor ? (d.backgroundColor as string) : '',
                // strokeStyle:  d.borderColor ? (d.borderColor as string) : '',
                index: i,
                hidden: d.hidden
              };
            });
          }
        },
        fontSize: fontSizeLegend,
        fontColor: CHART_AXIS_TEXT_COLOR,
      },
    },
    layout: {
      padding: paddingLayout,
    },
    animation: {
      duration: 1000,
      easing: 'easeOutCubic',
      onComplete() {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;
        ctx.textAlign = valueTextAlign;
        ctx.textBaseline = 'bottom';
        const fontSizeText = isScreenSmallerThanMD(window) ? '12px' : '14px';
        const sumData = (this.data.datasets as ChartDataSets[]).reduce(((total, curr) => total + (curr.data as number[]).reduce((a, b) => a + b, 0)), 0);
        const sumByLabelData = calculatePercentageByLabel(this.data.datasets);
        let sumByIndexArrayData = [];
        if ((this.data.datasets as ChartDataSets[])?.length > 1) {
          if (this.data.datasets[0].data.length > 0 && this.data.datasets[1].data.length > 0) {
            sumByIndexArrayData = manipulateArrays(this.data.datasets[0].data, this.data.datasets[1].data, 'add');
          }
        }
        this.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.controller.getDatasetMeta(i);
          if (meta.hidden) { return; }
          meta.data.forEach((bar, index) => {
            let data = '';
            if (this.data.datasets[i].calPercentFromLabelProperty) {
              data = ((dataset.data[index] / sumByLabelData[i][dataset.label]) * 100 < 1 ? '<1%' : ((dataset.data[index] / sumByLabelData[i][dataset.label]) * 100).toFixed(0) + '%');
            } else if (this.data.datasets[i].calPercentFromIndexArray) {
              data = ((dataset.data[index] / sumByIndexArrayData[index]) * 100 < 1 ? '<1%' : (dataset.data[index] / sumByIndexArrayData[index]) * 100 >= 100 ? '99%' : ((dataset.data[index] / sumByIndexArrayData[index]) * 100).toFixed(0) + '%');
            } else {
              data = this.data.datasets[i].calPercentFrommAllData ? ((dataset.data[index] / sumData) * 100 < 1 ? '<1%' : ((dataset.data[index] / sumData) * 100).toFixed(0) + '%')
                : (valueTextProcess === 'numberFormatter' ? numberFormatter(dataset.data[index], 1) : (dataset.data[index] || '').toLocaleString());
            }
            const dataFormat = (valueTextProcess === 'percentFormatter') ? dataset.data[index] < 1 ? `<1%` :`${numberFormatter(dataset.data[index], 1)}%` : data;
            ctx.fillStyle = CHART_AXIS_TEXT_COLOR;
            ctx.font = ctx.font.replace(/\d+px/, fontSizeText);
            if (displayTextValue) {
              if (dataFormat.includes('NaN')) {
                const defaultText = valueTextProcess === 'percentFormatter' ? '<1%' : '0';
                ctx.fillText(defaultText, bar._model.x + valueTextOffsetX, bar._model.y + valueTextOffsetY);
              } else {
                ctx.fillText(dataFormat, bar._model.x + valueTextOffsetX, bar._model.y + valueTextOffsetY);
              }
            }
          });
        });
      },
    },
    hover: {
      animationDuration: 0,
    },
    responsiveAnimationDuration: 1000,
    tooltips: {
      titleFontSize: isScreenSmallerThanLG(window) ? 18 : 18,
      bodyFontSize: isScreenSmallerThanLG(window) ? 14 : 16,
      // enabled: true,
      callbacks: {
        title(tooltipItem, data) {
          return data.labels[tooltipItem[0].index] as string[];
        },
        label(tooltipItem, data) {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${typeof tooltipItem.yLabel === 'string' ? tooltipItem.xLabel.toLocaleString() : tooltipItem.yLabel.toLocaleString()}`;
        },
        labelColor(tooltipItem, chart) {
          const datasets = chart.data.datasets[tooltipItem.datasetIndex];
          const colors = (datasets as any).colors;
          return {
            borderColor: 'black',
            backgroundColor: colors ? colors[tooltipItem.index] : datasets.backgroundColor as any,
          };
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: isStack === 'X' || isStack === 'XY',
          display: displayAxis === 'X' || displayAxis === 'XY',
          gridLines: {
            display: displayGrid ? displayAxis === 'X' || displayAxis === 'XY' : displayGrid,
            color: gridColor,
            // offsetGridLines: true
          },
          ticks: {
            beginAtZero: true,
            maxRotation: xAxesRotation,
            minRotation: xAxesRotation,
            suggestedMax: suggestedTickMax_X,
            callback(value) {
              return typeof value === 'number' ? (axisTextProcess === 'numberFormatter' ? numberFormatter(value, 2) : value.toLocaleString()) : value;
            },
            fontColor: CHART_AXIS_TEXT_COLOR,
          },
        },
      ],
      yAxes: [
        {
          display: displayAxis === 'Y' || displayAxis === 'XY',
          stacked: isStack === 'Y' || isStack === 'XY',
          gridLines: {
            display: displayGrid ? displayAxis === 'Y' || displayAxis === 'XY' : displayGrid,
            color: gridColor,
          },
          ticks: {
            callback(value) {
              return typeof value === 'number' ? (axisTextProcess === 'numberFormatter' ? numberFormatter(value, 2) : value.toLocaleString()) : value;
            },
            suggestedMin: yAxesRotation,
            suggestedMax: yAxesRotation,
            fontColor: CHART_AXIS_TEXT_COLOR,
          },
        },
      ],
    },
  };
}
