/* eslint-disable max-len */
import { OverallResolverBase } from './OverallResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicHeatmapWrapperComponent } from '../shared-components/dynamic/dynamic-heatmap-wrapper/dynamic-heatmap-wrapper.component';
import { HeatmapData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined } from '../helpers/util';

export class OverallTimeVisitResolver extends OverallResolverBase {

    public get graphDependencyMap(): { building: GraphDependency[]; floor: GraphDependency[]; zone: GraphDependency[] } {
        return {
            building: [GraphDependency.ENTRANCE_EXIT_HOUR],
            floor: [GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR],
            zone: [GraphDependency.ENTRANCE_EXIT_ZONE_HOUR]
        };
    }

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const level = getAdditionalInput(additionalInput, 'level') as DynamicGraphAdditionalInput['level'] | undefined;
        const groupData = getAdditionalInput(additionalInput, 'groupData') as DynamicGraphAdditionalInput['groupData'] | undefined;
        assertNullUndefined(level);
        this.processDynamicDependency(configDataService, graphDataService, level);
        // await configDataService.loadAppConfig();

        const getPopularTimeData = (
            dataBuilding: { [buildingName: string]: number[] },
            dataFloor: { [buildingName: string]: { [floorName: string]: number[] } },
            dataZone: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } },
        ) => this.getLeveledData(dataBuilding, dataFloor, dataZone, level);
        const initialComparingNames = level === 'building' ? this.getComparingNames(graphDataService.baseGraphData.comparingBuildingState$.value) : (level === 'floor' ? this.getComparingNames(graphDataService.baseGraphData.comparingFloorState$.value) : this.getComparingNames(graphDataService.baseGraphData.comparingZoneState$.value));
        const initialData = getPopularTimeData(graphDataService.accumulateHeadcountByHour$.value, graphDataService.floorAccumulateHeadcountByHour$.value, graphDataService.zoneAccumulateHeadcountByHour$.value);
        const initialTimeListReverse = [...configDataService.TIME_LIST].reverse();
        const chartData$ = new BehaviorSubject<HeatmapData[]>(Object.entries(initialData).sort().filter(([name, _data], idx) => {
            if (initialComparingNames.includes(name)) {
                return true;
            }
            return false;
        }).map(([name, data]) => ({
            name: this.getDisplayName(name),
            series: initialTimeListReverse.map((timeLabel, j) => ({ name: timeLabel, value: data[data.length - j - 1] || 0 })),
        })));

        // eslint-disable-next-line max-len
        subscription.add(combineLatest([graphDataService.accumulateHeadcountByHour$, graphDataService.floorAccumulateHeadcountByHour$, graphDataService.zoneAccumulateHeadcountByHour$, combineLatest([graphDataService.baseGraphData.comparingBuildingState$, graphDataService.baseGraphData.comparingFloorState$, graphDataService.baseGraphData.comparingZoneState$])]).subscribe(
            ([accumulateHeadcountByHour, floorAccumulateHeadcountByHour, entranceExitZoneData, [comparingBuildingState, comparingFloorState, comparingZoneState]]) => {
                if ((!accumulateHeadcountByHour && level === 'building') || (!floorAccumulateHeadcountByHour && level === 'floor') || (!entranceExitZoneData && level === 'zone')) { return; }
                const comparingNames = level === 'building' ? this.getComparingNames(comparingBuildingState) : (level === 'floor' ? this.getComparingNames(comparingFloorState) : this.getComparingNames(comparingZoneState));
                const fullData = getPopularTimeData(accumulateHeadcountByHour, floorAccumulateHeadcountByHour, entranceExitZoneData);
                const timeListReverse = [...configDataService.TIME_LIST].reverse();
                if (groupData !== undefined) {
                    Object.keys(groupData).forEach(k => {
                        const arr2DOfDataPoints: number[][] = [];
                        groupData[k].args.forEach(arg => {
                            arr2DOfDataPoints.push(fullData[arg]);
                        });
                        fullData[k] = arr2DOfDataPoints.every(arr => arr === null) ? null :
                            groupData[k].oper === 'AVG' ? arr2DOfDataPoints.reduce((r, a) => r.map((b, i) => b !== null ? (a[i] + b) / a.length - 1 : null)) : arr2DOfDataPoints.reduce((r, a) => r.map((b, i) => b !== null ? a[i] + b : null));
                    });
                }
                const heatmapChartData: HeatmapData[] = Object.entries(fullData).sort().filter(([name, _data]) => {
                    if (comparingNames.includes(name)) {
                        return true;
                    }
                    return false;
                }).map(([name, data]) => ({
                    name: this.getDisplayName(name),
                    series: timeListReverse.map((timeLabel, j) => ({ name: timeLabel, value: data[data.length - j - 1] || 0 })),
                }));
                chartData$.next(heatmapChartData);
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicHeatmapWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART;
        comInstance.isLock = this.isLock;
        comInstance.data$ = chartData$;
        return comInstance;
    }

}
