import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicItemDividerComponent } from '../shared-components/dynamic/dynamic-item-divider/dynamic-item-divider.component';
import { DynamicGraphAdditionalInput } from '../objects/config';

// TODO: make divider dynamic
// export class DynamicDividerResolver extends GraphResolverBase {

//     public async createComponent(
//         componentFactory: ComponentFactory<unknown>,
//         _additionalInput: string | DynamicGraphAdditionalInput | undefined,
//         configDataService: ConfigDataService,
//         _graphDataService: GraphDataService,
//         _popoverController: PopoverController,
//         _viewPeriodService: ViewPeriodService,
//         viewContainerRef: ViewContainerRef,
//         _subscription: Subscription
//     ) {
//         // doesn't need any data
//         // graphDataService.baseGraphData.addDependency(this.dataDependency);
//         await configDataService.loadAppConfig();
//         const componentRef = viewContainerRef.createComponent(componentFactory);
//         const comInstance = componentRef.instance as DynamicItemDividerComponent;
//         comInstance.title = configDataService.DISPLAY_LANGUAGE.HEADCONT;
//         comInstance.color = '#4a6fdd';
//         return comInstance;
//     }

// }

export class HeadcountDividerResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        _graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        _subscription: Subscription
    ) {
        // doesn't need any data
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicItemDividerComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.HEADCONT;
        comInstance.color = '#4a6fdd';
        return comInstance;
    }

}

export class PurchaseBehaviorDividerResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        _graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        _subscription: Subscription
    ) {
        // doesn't need any data
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicItemDividerComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.PURCHASE_BEHAVIOR;
        comInstance.color = '#6bc26f';
        return comInstance;
    }

}

export class StoreVisitorProfileDividerResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        _graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        _subscription: Subscription
    ) {
        // doesn't need any data
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicItemDividerComponent;
        comInstance.title = 'Visitor Profile';
        comInstance.color = '#e63739';
        return comInstance;
    }

}

export class StoreTenantDividerResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        _graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        _subscription: Subscription
    ) {
        // doesn't need any data
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicItemDividerComponent;
        comInstance.title = 'Tenant Analysis';
        comInstance.color = '#ffd800';
        return comInstance;
    }

}

export class VisitingBehaviorDividerResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        _graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        _subscription: Subscription
    ) {
        // doesn't need any data
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicItemDividerComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.VISITING_BEHAVIOR;
        comInstance.color = '#e63739';
        return comInstance;
    }

}
export class NetShoppingHourDividerResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        _graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        _subscription: Subscription
    ) {
        // doesn't need any data
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicItemDividerComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR;
        comInstance.color = '#ffd800';
        return comInstance;
    }

}
