import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { CustomerMaskTooltipsComponent } from '../pages/home/general/customer-mask-tooltips/customer-mask-tooltips.component';

export class MaskCountNumberCardResolver extends GraphResolverBase {
    
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        await configDataService.loadAppConfig();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const numberData$ = new BehaviorSubject('0 %');
        const label$ = new BehaviorSubject('0 %');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        subscription.add(graphDataService.currentMaskCount$.subscribe((maskCountData) => {
            numberData$.next(`${maskCountData.maskCount?.toFixed(1)} %`);
            label$.next(`${maskCountData.diff?.toFixed(1)} %`);
            labelIcon$.next(maskCountData.diff > 0 ? 'trending-up' : 'trending-down');
            labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: maskCountData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.MASK_COUNT;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;
        comInstance.infoPopover = async (e: any) => {
            const customerGenderPopover = await popoverController.create({
                component: CustomerMaskTooltipsComponent,
                cssClass: 'customer-segment-details-popover',
                event: e
            });
            return customerGenderPopover.present();
        };
        return comInstance;
    }

}
