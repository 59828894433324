import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { GenericLineChartData } from '../objects/chart';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { Label } from 'ng2-charts';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

const CHART_COLOR = '#6BC26F';

export class PopularTimeGraphResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const defaultFillData = Array.from({ length: configDataService.TIME_LIST.length }).map(() => null);
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: graphDataService.accumulateHeadcountByHour$.value ? graphDataService.accumulateHeadcountByHour$.value[buildingName] : defaultFillData,
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            toolTipLabel: configDataService.TIME_LIST.map(time => `Overall,${time}`),
            label: configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART,
            // toolTipLabelUnit: 'min'
        }]);
        const chartLable$ = new BehaviorSubject<Label[]>(configDataService.TIME_LIST);

        subscription.add(graphDataService.accumulateHeadcountByHour$.subscribe((dataPoints) => {
            const lineChartData: GenericLineChartData[] = [{
                points: dataPoints ? dataPoints[buildingName] : defaultFillData,
                backgroundColor: `${CHART_COLOR}22`,
                color: CHART_COLOR,
                toolTipLabel: configDataService.TIME_LIST.map(time => `Overall,${time}`),
                label: configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART,
                // toolTipLabelUnit: 'min'
            }];
            chartData$.next(lineChartData);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        // comInstance.enableTBD = true;
        comInstance.data$ = chartData$;
        comInstance.label$ = chartLable$;
        comInstance.isLock = this.isLock;
        // configDataService.MAIN_BUILDING === 'ctw' ?  comInstance.isLock = true : comInstance.isLock = this.isLock;
        // comInstance.selectedLine$ = selectedGraph$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART;
        return comInstance;
    }

}
