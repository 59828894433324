export const storeType: string[] = ['fashion_and_accessories', 'food_and_beverage', 'mobile_and_electronic', 'beauty_and_clinic', 'jewelry', 'bank_and_service', 'entertainment', 'souvenir', 'others', 'facilities', 'supermarket', 'education'];
const _storeType: string[] = [...storeType];

export type StoreType = typeof storeType[number];

export interface Store {
  id: string;
  type: StoreType;
  market_id: string;
  name: string;
  zone: string;
  area_size: number;
  rent_price: number;
  rent_expire: string;
  category: Array<string>;
  entrance_image: string;
  exit_image: string;
  tel: string;
  store_area_name?: string;
  zone_name?: string;
  floor_name?: string;
}

export function isStoreType(value: string): value is StoreType {
  return _storeType.includes(value);
}
