import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { CHART_PRIMARY_COLOR_OPACITY, CHART_PRIMARY_COLOR, LINE_CHART_COLOR_LIST } from '../configs/color';
import { DynamicGraphAdditionalInput } from '../objects/config';
import * as moment from 'moment';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { isShowGraph } from '../helpers/util';
import { AuthenticationService } from '../services/authentication.service';

export class ModeOfTransportResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;

        const initialLineChartData: GenericLineChartData[] = [{
            points: graphDataService.modeOfTransportationChartData$.value.total,
            backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
            color: CHART_PRIMARY_COLOR,
            toolTipLabel: configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total,
        }];
        let colIndex = 0;
        for (const [vehicleType, chartData] of Object.entries(graphDataService.modeOfTransportationChartData$.value)) {
            const labelText = configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS[vehicleType] ? configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS[vehicleType] : vehicleType;
            if (vehicleType === 'total') { continue; }
            initialLineChartData.push({
                points: chartData,
                color: LINE_CHART_COLOR_LIST[colIndex],
                toolTipLabel: labelText,
                isDash: true
            });
            colIndex = (colIndex + 1) % LINE_CHART_COLOR_LIST.length;
        }
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>(initialLineChartData);

        subscription.add(graphDataService.modeOfTransportationChartData$.subscribe((tranSportModeData) => {
            const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
            // const diffDate = viewPeriodService.selectedDate.diff(todayDate, 'days');
            const labelTotalText = configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total.charAt(0).toUpperCase() + configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS.total.slice(1);
            const lineChartData: GenericLineChartData[] = [{
                points: tranSportModeData.total,
                // prediction: diffDate < -1 ? null : [0],
                backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
                color: CHART_PRIMARY_COLOR,
                toolTipLabel: viewPeriodService.DAY_LIST.map(day => `${labelTotalText},${day}`),
                label: labelTotalText,
            }];
            colIndex = 0;
            for (const [vehicleType, chartData] of Object.entries(tranSportModeData)) {
                if (vehicleType === 'total') { continue; }
                const labelText = configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS[vehicleType] ? configDataService.DISPLAY_LANGUAGE.VEHICLE_CLASS[vehicleType] : vehicleType.charAt(0).toUpperCase() + vehicleType.slice(1);
                lineChartData.push({
                    points: chartData,
                    // prediction: diffDate < -1 ? null : [0],
                    color: LINE_CHART_COLOR_LIST[colIndex],
                    toolTipLabel: viewPeriodService.DAY_LIST.map(day => `${labelText},${day}`),
                    isDash: true,
                    label: labelText,
                });
                colIndex = (colIndex + 1) % LINE_CHART_COLOR_LIST.length;
            }
            chartData$.next(lineChartData);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        // comInstance.enableTBD = true;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        // comInstance.selectedLine$ = selectedGraph$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.MODE_TRANSPORTATION;
        comInstance.isLock = this.isLock;
        return comInstance;
    }

}
