import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { DynamicGraphAdditionalInput, DynamicSelectorConfig } from '../objects/config';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicDividerSelectorComponent } from '../shared-components/dynamic/dynamic-divider-selector/dynamic-divider-selector.component';
import { GraphResolverBase } from './GraphResolverBase';


export class DynamicDividerSelectorResolver extends GraphResolverBase {
  public async createComponent(
    componentFactory: ComponentFactory<unknown>,
    additionalInput: string | DynamicGraphAdditionalInput,
    _configDataService: ConfigDataService,
    graphDataService: GraphDataService,
    _popoverController: PopoverController,
    _viewPeriodService: ViewPeriodService,
    viewContainerRef: ViewContainerRef,
    subscription: Subscription,
  ) {
    // await configDataService.loadAppConfig();
    const dynamicSelectorConfig = (getAdditionalInput(additionalInput, 'dynamicSelectorConfig') || {}) as DynamicSelectorConfig;
    const color = dynamicSelectorConfig.color || '#212121';
    const backgroundColor = dynamicSelectorConfig.backgroundColor || '#2e2e2e';
    const textColor = dynamicSelectorConfig.textColor || 'white';
    const sticky = dynamicSelectorConfig.sticky || false;
    const fontSize = dynamicSelectorConfig.fontSize || '18px';
    const fontWeight = dynamicSelectorConfig.fontWeight || 600;
    const displayNameLookupKey = dynamicSelectorConfig.displayNameLookupKey || 'DEFAULT';
    const selectorConfig: [string, string[]][] = dynamicSelectorConfig.selectorConfig; // required

    // TODO: create behavior subject for selected value to subscribe to
    // TODO: Have much more time to clean code this function (for now not generic) **selectableDataBehaviourSubject$[1] is actual selector**
    // const selectedSelector$ = new BehaviorSubject({});
    const selectedSelector$ = graphDataService.selectedVehicleProfile$;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const comInstance = componentRef.instance as DynamicDividerSelectorComponent;

    // subscription.add(selectedSelector$
    // .subscribe(selectedSelector => {
    //   if (!selectedSelector) {
    //     return;
    //   }
    //   graphDataService.selectedVehicleProfile$.next(selectedSelector);
    // }));

    comInstance.isShow = true;
    comInstance.color = color;
    comInstance.backgroundColor = backgroundColor;
    comInstance.textColor = textColor;
    comInstance.sticky = sticky;
    comInstance.fontSize = fontSize;
    comInstance.fontWeight = fontWeight;
    comInstance.displayNameLookupKey = displayNameLookupKey;
    comInstance.selectorConfig = selectorConfig; // fetched from additional input
    comInstance.selectedSelector$ = selectedSelector$;// TODO: Point to the actual selector (the one graph use to subscribe), but remember that it has to be init with {}
    return comInstance;
  }

}
