let gateTooltipTimeout;

export const showGateCustomTooltips = (
  tooltipModel,
  thisChart,
  imageList: { [floorName: string]: { [gateName: string]: { entrance: string; exit: string } } } | { [pinName: string]: { entrance: string; exit: string } },
  gateList: { [gateName: string]: string },
  channel: 'entrance' | 'exit' | 'auto' = 'entrance',
  showPercentOfTotal = false,
  entranceExitTotal?: { entrance: number; exit: number },
  mockArea?: boolean
) => {
  window.clearTimeout(gateTooltipTimeout);

  // Tooltip Element
  let tooltipEl = document.getElementById('gate-chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'gate-chartjs-tooltip';
    tooltipEl.innerHTML = '<table style="margin-left: 10px;"></table><div></div>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    window.clearTimeout(gateTooltipTimeout);
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  // function getBody(bodyItem) {
  //   return bodyItem.lines;
  // }

  // const chartDatasets = thisChart._data.datasets;

  // Set Text
  if (tooltipModel.body && thisChart._data) {
    const chartDatasets = thisChart._data.datasets;
    const titleLines = tooltipModel.title.join(' ');
    // var bodyLines = tooltipModel.body.map(getBody);
    const bodyLines = tooltipModel.dataPoints.map(datapoint => {
      const rawValue = chartDatasets[datapoint.datasetIndex].data[datapoint.index].toLocaleString();
      const label = chartDatasets[datapoint.datasetIndex].label;
      if (showPercentOfTotal) {
        const total = entranceExitTotal[channel];
        const percentage = Math.round((chartDatasets[datapoint.datasetIndex].data[datapoint.index] / total) * 100);
        return `${label}: ${rawValue}</br>(${percentage}% Of Total Traffic)`;
      }
      return `${label}: ${rawValue}`;
    });

    let innerHtml = '<thead>';

    // titleLines.forEach((title) => {
    //   innerHtml += '<tr><th>' + title + '</th></tr>';
    // });
    innerHtml += '<tr><th>' + titleLines + '</th></tr>';
    innerHtml += '</thead><tbody>';

    bodyLines.forEach((body, i) => {
      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      style += '; display: inline-block';
      style += '; width: 15px';
      style += '; height: 15px';
      style += '; margin-right: 5px';
      style += '; vertical-align: middle';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td>' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const imageRoot = tooltipEl.querySelector('div');
  const gateName = (tooltipModel.title[0] as string).toLowerCase().replace('-', '_'); // TODO: remove legacy support
  let floorName = tooltipModel.floorName as string;
  const gateKey = Object.keys(gateList).find(g => gateList[g].split('\n').join(' ') === (tooltipModel.title.join(' ') as string));
  if (floorName === 'ALL') {
    // floorName = Object.entries(imageList).find(([_floorName, gatesData]: [string, object]) => gateKey in gatesData)[0];
    for (const [floor, gateObj] of Object.entries(imageList)) {
      if (Object.keys(gateObj).find(k => k === gateKey) !== undefined) {
        floorName = floor;
        break;
      }
      // floorName = Object.entries(imageList[floor]).find(([_floorName, gatesData]: [string, object]) => gateName in gatesData)[0];
      // floorName = Object.entries(image).find(([_floorName, gatesData]: [string, object]) => gateName in gatesData)[0];
    }
  }
  const gateImageSrc = imageList?.[floorName]?.[gateKey]?.[channel] || imageList?.[gateKey]?.[channel] || '../assets/image/placeholder.png';

  imageRoot.innerHTML = `<img style="width: 200px; height: 150px; margin: 10px 10px;" src="${gateImageSrc}"></img>`;

  // `this` will be the overall tooltip
  const position = thisChart._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 120 + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 200 + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.backgroundColor = 'rgba(0,0,0, 0.8)';
  tooltipEl.style.color = 'white';
  tooltipEl.style.borderRadius = '5px';

  gateTooltipTimeout = setTimeout(() => {
    tooltipEl.style.opacity = '0';
  }, 4000);
};
