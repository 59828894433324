import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartPluginsOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-generic-stack-bar-chart',
  templateUrl: './generic-stack-bar-chart.component.html',
  styleUrls: ['./generic-stack-bar-chart.component.scss'],
})
export class GenericStackBarChartComponent implements OnInit, OnDestroy{

  @Input() chartLabel$: Observable<Label[]>;
  @Input() isHorizontal = true;
  @Input() data$: Observable<ChartDataSets[]>;
  @Input() chartOptions$: Observable<ChartOptions>;
  @Input() isShowLegend = true;

  private subscription = new Subscription();
  chartPlugins: ChartPluginsOptions = [ChartDataLabels];
  chartDatas: ChartDataSets[] = [{ data: [null] }];
  chartOptions: ChartOptions;
  chartLabels: Label[];

  constructor() { }

  ngOnInit() {
    this.subscription.add(this.data$.subscribe(stackBarChartDataSets => {
      if (!stackBarChartDataSets) { return; }
      const chartData: ChartDataSets[] = [];
      stackBarChartDataSets.forEach(stackBarChartData => {
        chartData.push(stackBarChartData);
      });
      this.chartDatas = chartData.length > 0 ? chartData : [{ data: [null] }];
    }));
    this.subscription.add(this.chartOptions$.subscribe(stackBarChartOptions => {
      if (!stackBarChartOptions) { return; }
      this.chartOptions = stackBarChartOptions;
    }));
    this.subscription.add(this.chartLabel$.subscribe(stackBarChartLabel => {
      if (!stackBarChartLabel) { return; }
      this.chartLabels = stackBarChartLabel;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get chartType() {
    return this.isHorizontal ? 'horizontalBar' : 'bar';
  }

}
