import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { BehaviorSubject } from 'rxjs';
import { Loading } from '../enum/loading';
import { environment } from 'src/environments/environment';
import { BaseDataDependency } from '../objects/baseGraphData';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private currentLoading: { [loadingName: string]: boolean } = {};
  showSplash = true;
  isNetworkConnected$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  networkDisconnectSubscription: any;
  networkConnectSubscription: any;

  private isSubscribe = false;

  subscribeNetworkConnection() {
    if (this.isSubscribe) { return; }
    Network.getStatus().then(status => {
      this.isNetworkConnected$.next(status.connected);
    });

    Network.addListener('networkStatusChange', status => {
      this.isNetworkConnected$.next(status.connected);
    });

    this.isSubscribe = true;
  }

  disableSplash() {
    this.showSplash = false;
  }

  startLoading(key: Loading | BaseDataDependency) {
    this.currentLoading[key] = true;
    if (!environment.production) {
      console.debug('start loading', key);
    }
  }

  stopLoading(key: Loading | BaseDataDependency) {
    this.currentLoading[key] = false;
    if (!environment.production) { 
      console.debug('stop loading', key);
    }

  }

  get loadingKey() {
    for (const [key, isLoading] of Object.entries(this.currentLoading)) {
      if (isLoading) { return key; }
    }
    return null;
  }

  get isLoading() {
    return Object.values(this.currentLoading).some(val => val);
  }

  get isNetworkConnected() {
    return this.isNetworkConnected$.value;
  }
}
