import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';

export class EntranceExitPerHourResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        await configDataService.loadAppConfig();

        const hourDataGrouper = (prev: any[], current: any, idx: number) => {
            const currentArray = prev[prev.length - 1];
            if (currentArray.length === 2) {
                prev.push([currentArray[currentArray.length - 1]]);
                prev[prev.length - 1].push(current);
            } else {
                prev[prev.length - 1].push(current);
            }
            return prev;
        };

        const chartLabel = configDataService.TIME_LIST.reduce(hourDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`);
        const initialEntranceData = graphDataService.entranceByHour$.value;
        const initialExitData = graphDataService.exitByHour$.value;
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: initialEntranceData.filter((_, i) => i > 0),
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
        }, {
            data: initialExitData.filter((_, i) => i > 0),
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.EXIT,
        }]);
        subscription.add(combineLatest([graphDataService.entranceByHour$, graphDataService.exitByHour$]).subscribe(([entranceByHour, exitByHour]) => {
            dualBarData$.next([{
                data: entranceByHour.filter((_, i) => i > 0),
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            }, {
                data: exitByHour.filter((_ , i) => i > 0),
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.EXIT,
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.ENTRANCE_EXIT_BIHOURS;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = true;
        
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.xAxesRotation = 90;
        comInstance.infoPopover = async (e: any) => {
            const busiestHourPopover = await popoverController.create({
                component: CustomTextTooltipsComponent,
                componentProps: {
                    toolTipTitle: 'Traffic By Hour',
                    toolTipDetails: ['Based on "Headcount" by the hour.', 'You may click the "Entrance" and "Exit" legend to select or deselect the bar series.'],
                },
                cssClass: 'customer-segment-details-popover',
                event: e,
            });
            return await busiestHourPopover.present();
        };
        return comInstance;
    }

}
