import { Component, OnInit } from '@angular/core';
import { ConfigDataService } from 'src/app/services/config-data.service';

@Component({
  selector: 'app-calendar-legend-table',
  templateUrl: './calendar-legend-table.component.html',
  styleUrls: ['./calendar-legend-table.component.scss'],
})
export class CalendarLegendTableComponent implements OnInit {
  QUARTER_LIST = ['Q1', 'Q2', 'Q3', 'Q4'];
  REVERSED_HEADCOUNT_CRITERION = {};
  useSelector = false;
  selectorDataArgsName: string[];

  CRITERION_STYLE = [
    { color: 'white', backgroundColor: '#4a7d32' },
    { color: 'white', backgroundColor: '#82df44' },
    { color: 'white', backgroundColor: '#f7ff6a' },
    { color: 'white', backgroundColor: '#ea4887' },
    { color: 'white', backgroundColor: '#cf2e2b' },
  ];

  constructor(private configDataService: ConfigDataService) {}

  ngOnInit() {
    if (this.useSelector) {
      Object.entries(this.configDataService.TARGET_CONFIG[this.selectorDataArgsName[0]]).map(([key, val]: [string, number[]]) => {
        this.REVERSED_HEADCOUNT_CRITERION[key] = [...val].reverse();
      });
    } else {
      Object.entries(this.HEADCOUNT_CRITERION).map(([key, val]: [string, number[]]) => {
        this.REVERSED_HEADCOUNT_CRITERION[key] = [...val].reverse();
      });
    }

  }

  get HEADCOUNT_CRITERION() {
    return this.configDataService.HEADCOUNT_CRITERION;
  }

  getStyle(index) {
    return this.CRITERION_STYLE[4 - index];
  }
}
