/* eslint-disable prefer-arrow/prefer-arrow-functions */
import * as moment from 'moment';
import * as seedrandom from 'seedrandom';
import { User } from '../objects/user';
import { ViewPeriod } from '../objects/view-period';
import { VisitorProfileSelection } from '../objects/visitor-profile';

export function getRandomInt(min: number, max: number, seed?: string) {
  if (!seed) {
    return getRandomIntNoSeed(min, max);
  } else {
    return getRandomIntSeed(min, max, seed);
  }
}

function getRandomIntNoSeed(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
}

function getRandomIntSeed(min: number, max: number, seed: string) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const rng = seedrandom(seed);
  return Math.floor(rng() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
}

export function numberFormatter(num, digits) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i: number;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function isScreenSmallerThanXS(window) {
  return window.innerWidth <= 576;
}

export function isScreenSmallerThanMD(window) {
  return window.innerWidth <= 768;
}

export function isScreenSmallerThanLG(window) {
  return window.innerWidth <= 992;
}

export function isScreenSmallerThanXL(window) {
  return window.innerWidth <= 1200;
}

export function getDateArrayFromRange(startDate, endDate) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function filterSelectedDateData<T extends {day: number; week: number; month: number; year: number}>(dataset: T[], selected_date_moment: moment.Moment, period_type: ViewPeriod) {
  const day = selected_date_moment.date();
  const month = selected_date_moment.month() + 1;
  const year = selected_date_moment.year();
  const week = selected_date_moment.isoWeek();

  return dataset.filter((data) => {
    if (ViewPeriod.isDay(period_type)) {
      return Number(data.day) === day && Number(data.month) === month && Number(data.year) === year;
    } else if (ViewPeriod.isWeek(period_type)) {
      return Number(data.week) === week && Number(data.year) === year;
    } else if (ViewPeriod.isMonth(period_type)) {
      return Number(data.month) === month && Number(data.year) === year;
    }
  });
}

export function filterSelectedDateGroupData(dataset: any[], selected_date_moment: moment.Moment, period_type: ViewPeriod) {
  const day = selected_date_moment.date();
  const month = selected_date_moment.month() + 1;
  const year = selected_date_moment.year();
  const week = selected_date_moment.isoWeek();

  return dataset.filter((data) => {
    if (ViewPeriod.isDay(period_type)) {
      return data.group.day === day && data.group.month === month && data.group.year === year;
    } else if (ViewPeriod.isWeek(period_type)) {
      return data.group.week === week && data.group.year === year;
    } else if (ViewPeriod.isMonth(period_type)) {
      return data.group.month === month && data.group.year === year;
    }
  });
}

export function filterPurchaseData(dataset): any {

  let count = 0;
  let purchase_count = 0;

  Object.entries(dataset.count).forEach((data) => {
    const key: any = data[0];
    if (key === '_total') { return; }

    const value: any = data[1];
    const [age, gender, ethnicity, purchase, student] = key.split('_');

    count += value;
    if (purchase === 'true') { purchase_count += value; }
  });

  return {
    headcount: count,
    purchase_count
  };

}

// Get query date since we base the database timestamp using the first day of the (ISO - monday) week / the first day of the month
// For period => `days`, we want to fetch for the tomorrow as well by default (somehow)
// Return the YYYY-MM-DD format
export function getQueryDateInterval(date: moment.Moment, periodType: ViewPeriod, offset = 1) {
  let start_date = date.clone().format('YYYY-MM-DD');
  if (ViewPeriod.isWeek(periodType)) {
    start_date = date.clone().startOf('isoWeek').format('YYYY-MM-DD');
  } else if (ViewPeriod.isMonth(periodType)) {
    start_date = date.clone().startOf('month').format('YYYY-MM-DD');
  } else if (ViewPeriod.isDay(periodType)) {
    start_date = date.clone().add(offset, periodType.toMomentCompareString()).format('YYYY-MM-DD');
  }
  return start_date;
}

// index 0 mean use all keys, ageIndex 1 is student
export function filterDataByDemographics(dataset, genderIndex, ethnicityIndex, ageIndex) {

  if (dataset === undefined || dataset.length === 0) {
    return {
      headcount: 0,
      purchase_count: 0,
      purchase_rate: 0
    };
  }

  let GENDER_INDEX_TO_KEY = [
    'male',
    'female'
  ];
  let ETHNICITY_INDEX_TO_KEY = [
    'white',
    'indian',
    'middle-eastern',
    'black',
    'asian',
  ];
  let AGE_INDEX_TO_KEY = [
    'children',
    'teenagers',
    'young-adults',
    'adults',
    'seniors'
  ];

  const count_dict = {};
  const purchase_count_dict = {};

  Object.entries(dataset.count).forEach((data) => {
    const key: any = data[0];
    if (key === '_total') { return; }

    const value: any = data[1];
    const [age, gender, ethnicity, purchase, student] = key.split('_');

    if (count_dict[gender] === undefined) { count_dict[gender] = {}; }
    if (purchase_count_dict[gender] === undefined) { purchase_count_dict[gender] = {}; }
    if (count_dict[gender][ethnicity] === undefined) { count_dict[gender][ethnicity] = {}; }
    if (purchase_count_dict[gender][ethnicity] === undefined) { purchase_count_dict[gender][ethnicity] = {}; }
    if (count_dict[gender][ethnicity][age] === undefined) { count_dict[gender][ethnicity][age] = 0; }
    if (purchase_count_dict[gender][ethnicity][age] === undefined) { purchase_count_dict[gender][ethnicity][age] = 0; }
    if (count_dict[gender][ethnicity].student === undefined) { count_dict[gender][ethnicity].student = 0; }
    if (purchase_count_dict[gender][ethnicity].student === undefined) { purchase_count_dict[gender][ethnicity].student = 0; }

    count_dict[gender][ethnicity][age] = count_dict[gender][ethnicity][age] + value;
    if (purchase === 'true') { purchase_count_dict[gender][ethnicity][age] = purchase_count_dict[gender][ethnicity][age] + value; }
    if (student === 'true') {
      count_dict[gender][ethnicity].student = count_dict[gender][ethnicity].student + value;
      if (purchase === 'true') { purchase_count_dict[gender][ethnicity].student = purchase_count_dict[gender][ethnicity].student + value; }
    }
  });

  if (genderIndex !== 0) {
    GENDER_INDEX_TO_KEY = [GENDER_INDEX_TO_KEY[genderIndex - 1]];
  }
  if (ethnicityIndex !== 0) {
    ETHNICITY_INDEX_TO_KEY = [ETHNICITY_INDEX_TO_KEY[ethnicityIndex - 1]];
  }
  if (ageIndex !== 0) {
    AGE_INDEX_TO_KEY = [AGE_INDEX_TO_KEY[ageIndex - 2]];
  }

  let filteredHeadcount = 0;
  let filteredPurchase = 0;

  if (ageIndex === 1) {
    GENDER_INDEX_TO_KEY.forEach((gender) => {
      ETHNICITY_INDEX_TO_KEY.forEach((ethnicity) => {
        if (count_dict[gender] === undefined || count_dict[gender][ethnicity] === undefined) { return; }
        if (count_dict[gender][ethnicity].student !== undefined && count_dict[gender][ethnicity].student !== 0) {
          filteredHeadcount += count_dict[gender][ethnicity].student;
          filteredPurchase += purchase_count_dict[gender][ethnicity].student;
        }
      });
    });

  } else {
    GENDER_INDEX_TO_KEY.forEach((gender) => {
      ETHNICITY_INDEX_TO_KEY.forEach((ethnicity) => {
        AGE_INDEX_TO_KEY.forEach((age) => {
          if (count_dict[gender] === undefined || count_dict[gender][ethnicity] === undefined) { return; }
          if (count_dict[gender][ethnicity][age] !== undefined && count_dict[gender][ethnicity][age] !== 0) {
            filteredHeadcount += count_dict[gender][ethnicity][age];
            filteredPurchase += purchase_count_dict[gender][ethnicity][age];
          }
        });
      });
    });
  }

  if (genderIndex === 0 && ageIndex === 0 && ethnicityIndex === 0) { filteredHeadcount = dataset.count._total || dataset._total; }

  const filteredPurchaseRate = filteredHeadcount === 0 ? 0 : filteredPurchase / filteredHeadcount * 100;

  return {
    headcount: filteredHeadcount,
    purchase_count: filteredPurchase,
    purchase_rate: filteredPurchaseRate
  };
}

export function getDemographicsKey(genderIndex, ethnicityIndex, ageIndex) {

  const GENDER_INDEX_TO_KEY = [
    'male',
    'female'
  ];
  const ETHNICITY_INDEX_TO_KEY = [
    'white',
    'indian',
    'middle-eastern',
    'black',
    'asian',
  ];
  const AGE_INDEX_TO_KEY = [
    'children',
    'teenagers',
    'young-adults',
    'adults',
    'seniors'
  ];

  let demographicsKey = '';
  let classKey = '';

  if (genderIndex === 0) {
    if (ethnicityIndex > 0) {
      demographicsKey = 'ethnicity';
      classKey = ETHNICITY_INDEX_TO_KEY[ethnicityIndex - 1];
    } else if (ageIndex === 1) {
      demographicsKey = 'student';
      classKey = 'true';
    } else {
      demographicsKey = 'age';
      classKey = AGE_INDEX_TO_KEY[ageIndex - 2];
    }
  } else {
    if (ethnicityIndex > 0) {
      demographicsKey = 'gender_ethnicity';
      classKey = `${GENDER_INDEX_TO_KEY[genderIndex - 1]}_${ETHNICITY_INDEX_TO_KEY[ethnicityIndex - 1]}`;
    } else if (ageIndex === 1) {
      demographicsKey = 'gender_student';
      classKey = `${GENDER_INDEX_TO_KEY[genderIndex - 1]}_true`;
    } else if (ageIndex > 0) {
      demographicsKey = 'gender_age';
      classKey = `${GENDER_INDEX_TO_KEY[genderIndex - 1]}_${AGE_INDEX_TO_KEY[ageIndex - 2]}`;
    } else {
      demographicsKey = 'gender';
      classKey = `${GENDER_INDEX_TO_KEY[genderIndex - 1]}`;
    }
  }


  return [demographicsKey, classKey];
}

export function getHeatmapColorForCSS(normalizedValue0to1: number): string {
  const h = (1 - normalizedValue0to1) * 120;
  return `hsl(${Math.round(h)}, 100%, 50%)`;
}

export function compare1DepthObjects(o1: any, o2: any) {
  for (const p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }
  for (const p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }
  return true;
}

export function isSelectionVisitorProfileAll(profileData: VisitorProfileSelection) {
  return profileData.age === 'all' && profileData.ethnicity === 'all' && profileData.gender === 'all';
}

export function assertNullUndefined(value: unknown): asserts value {
  if (value === undefined) {
    throw new Error('value must be defined');
  } else if (value === null) {
    throw new Error('value must not be null');
  }
}

export function accessDepthData<T>(data: { [buildingName: string]: T | { [floorName: string]: T | { [zoneName: string]: T | { [gateName: string]: T } } } }, buildingName: string, floorName?: string, zoneName?: string, defaultValue: T = ({} as T), gateName?: string): T {
  if (!data || !data[buildingName]) { return defaultValue; }
  if (floorName) {
    if (zoneName && data[buildingName][floorName]) {
      if (gateName && data[buildingName][floorName][zoneName]) {
        return (data[buildingName][floorName][zoneName][gateName] || 0) as T;
      }
      return (data[buildingName][floorName][zoneName] || defaultValue) as T;
    }
    return (data[buildingName][floorName] || defaultValue) as T;
  }
  return (data[buildingName] || defaultValue) as T;
}

export function filterKeyName<T>(data: T, includeNameList: string[]) {
  const res = {} as T;
  Object.keys(data).filter(d => includeNameList.includes(d)).forEach(floorName => {
    res[floorName] = data[floorName];
  });
  return res;
}

export function isShowGraph(useOnSpecificUser: boolean, userProfile: User, spectificUID: string[], specOrganzation: string) {
  if (useOnSpecificUser) {
    if ((spectificUID.find(k => k === userProfile.uid) !== undefined) && userProfile.organization === specOrganzation) {
      return true;
    }
    return false;
  } else {
    return true;
  }
}

// TODO: remove usage of filterFloorName
/**
 * @deprecated this function exist purly for transition of floorName, so, make sure to remove it once done.
 */
export function filterFloorName<T>(data: T, includeNameList: string[]) {
  return filterKeyName<T>(data, includeNameList);
}

type EntraceExitData = { entrance: number[]; exit: number[] };
const getEntraceExitData = (isEntrance: boolean, data: EntraceExitData) => isEntrance ? data.entrance : data.exit;
export function getDepthData<T>(
    isEntrance: boolean,
    data: T | { [buildingName: string]: T | { [floorName: string]: T } | { [zoneName: string]: T } | { [gateName: string]: T } },
    directory: { buildingName?: string; floorName?: string; zoneName?: string; gateName?: string },
    fillData?: T,
    usingChannelMode: boolean = false
) {
    let retData: unknown;
    if (directory.buildingName === undefined && directory.floorName === undefined && directory.zoneName === undefined) {
      if (usingChannelMode) {
        return data;
      }
      if (Object.keys(data).includes('entrance')) {
        return getEntraceExitData(isEntrance, data as any);
      }  
      return data;
    } else {
        retData = accessDepthData<T>(data as any, directory.buildingName, directory.floorName, directory.zoneName, fillData ,directory.gateName);
    }
    if (usingChannelMode) {
      return retData;
    }
    if (Object.keys(retData).includes('entrance')) {
        return getEntraceExitData(isEntrance, retData as EntraceExitData);
    }
    return retData;
};

export function calPercentageFromData(arrData: number[]) {
  const sumAll = arrData.reduce((a, b) => a + b, 0);
  return arrData.map(d => Math.round((d / sumAll) * 100));
};

export function getSortIndice<T extends number>(toSort: T[]): number[] {
  const sortingPair: [T, number][] = toSort.map((value, index) => [value, index]);
  sortingPair.sort((left, right) => 
       (left[0] || 0) < (right[0] || 0) ? -1 : 1 // to be able to compare undefined
  );
  const sortIndices: number[] = sortingPair.map(([, indice]) => indice);
  return sortIndices;
};

export function sumArrays(...arrays: any[]) {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
}

export function manipulateArrays(arr1: number[], arr2: number[], oper: 'add' | 'subtract' | 'multiply' | 'divide'): number[] {
  if (arr1.length !== arr2.length) {
    throw new Error('Arrays must have the same length');
  }
  switch (oper) {
    case 'subtract':
      return arr1.map((value, index) => value === null && arr2[index] === null ? null : value - arr2[index]);
    case 'multiply':
      return arr1.map((value, index) => value === null && arr2[index] === null ? null : value * arr2[index]);
    case 'divide':
      return arr1.map((value, index) => value === null && arr2[index] === null ? null : value / arr2[index]);
    default:
      return arr1.map((value, index) => value === null && arr2[index] === null ? null : value + arr2[index]);
  }
}

/**
 * Calculates the progress value in percentage based on the input value.
 * The minimum value and maximum value are provided as parameters.
 *
 * @param inputValue - The input value used to calculate the progress percentage.
 * @param minValue - The minimum value for the range.
 * @param maxValue - The maximum value for the range.
 * @returns progressPercentage - The calculated progress percentage.
 */
export function calculateProgressPercentage(inputValue: number, minValue: number, maxValue: number): number {
  const progressPercentage = ((inputValue - minValue) / (maxValue - minValue)) * 100;
  if (progressPercentage >= 99) {
    return 98;
  }
  if (progressPercentage <= 0) {
    return 2;
  }
  return progressPercentage;
}

export function filterOutKey<T, K extends keyof T>(obj: T, key: K): Omit<T, K> {
  const { [key]: _, ...rest } = obj;
  return rest;
}
