<div *ngIf="selectedSelector$ | async as selectedSelector">
  <ion-item-divider class="divider-header ion-justify-content-center" [style]="'color: ' + textColor + '; --background: ' + color + ';'" [sticky]="sticky">
    <div
      class="divider-header--button ion-activatable ripple-parent"
      *ngFor="let selectorNameEntry of selectorConfig"
      [class.isActivated]="open_state_name === selectorNameEntry[0]"
      (click)="handleToggleState(selectorNameEntry[0])"
    >
      <ion-label [style]="'font-size: ' + fontSize + '; font-weight: ' + fontWeight + '; text-align: center; margin: 10px auto; margin-left: 0.5rem'">{{
        getDisplayName(selectedSelector[selectorNameEntry[0]]) || getDisplayName(selectorNameEntry[0])
      }}</ion-label>
      <ion-icon
        [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }"
        [name]="open_state_name === selectorNameEntry[0] ? 'chevron-up-outline' : 'chevron-down-outline'"
        style="margin: auto 0.5rem auto 0.25rem"
        [ngStyle]="{ color: textColor }"
      ></ion-icon>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
  </ion-item-divider>
  <div class="selectable-container" *ngFor="let selectorNameEntry of selectorConfig" [class.isOpen]="open_state_name === selectorNameEntry[0]">
    <ion-chip
      outline
      color="primary"
      style="min-height: 45px;"
      *ngFor="let selector of selectorNameEntry[1]"
      [class.isSelected]="selectedSelector[selectorNameEntry[0]] === selector"
      (click)="handleSelectorClicked(selectorNameEntry[0], selector)"
    >
      <ion-label style="margin: auto">{{ getDisplayName(selector) }}</ion-label>
    </ion-chip>
  </div>
</div>
