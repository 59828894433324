<ion-row>
  <ion-col appLockIcon [isLock]="isLock">
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ title }}</ion-card-title>
      </ion-card-header>
      <ion-card-content appLockBlur [isLock]="isLock">
        <app-generic-heatmap-chart [data$]="data$"> </app-generic-heatmap-chart>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
