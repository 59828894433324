import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicDualNumberCardComponent } from '../shared-components/dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { PurchaseBagTooltipsComponent } from '../shared-components/general/purchase-bag-tooltips/purchase-bag-tooltips.component';
import { CustomerMessengerTooltipsComponent } from '../pages/home/general/customer-messenger-tooltips/customer-messenger-tooltips.component';
import { CustomerMaskTooltipsComponent } from '../pages/home/general/customer-mask-tooltips/customer-mask-tooltips.component';
import { CustomTooltipPopoverComponent } from '../shared-components/general/custom-tooltip-popover/custom-tooltip-popover.component';
import { AuthenticationService } from '../services/authentication.service';

export class HighlightNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);

        // await configDataService.loadAppConfig();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicDualNumberCardComponent;

        const headcountNumberData$ = new BehaviorSubject('0');
        const headcountLabel$ = new BehaviorSubject('0% (0)');
        const headcountLabelIcon$ = new BehaviorSubject('trending-up');
        const headcountLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        subscription.add(combineLatest([configDataService.isEntranceDataMode$, graphDataService.currentHeadCountEntranceData$, graphDataService.currentHeadCountExitData$]).subscribe(
            ([isEntrance, headCountEntranceData, headCountExitData]) => {
                const headCountData = isEntrance ? headCountEntranceData : headCountExitData;
                headcountNumberData$.next(headCountData.headCount?.toLocaleString());
                headcountLabel$.next(`${headCountData.diffPercent?.toFixed(1)}% (${headCountData.diff?.toLocaleString()})`);
                headcountLabelIcon$.next(headCountData.diff > 0 ? 'trending-up' : 'trending-down');
                headcountLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: headCountData.diff >= 0 ? '#5BBF93' : '#E25144' });
            }
        ));

        const nonStaffNumberData$ = new BehaviorSubject('0');
        const nonStaffLabel$ = new BehaviorSubject('0% (0)');
        const nonStaffLabelIcon$ = new BehaviorSubject('trending-up');
        const nonStaffLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        subscription.add(combineLatest([graphDataService.currentStaffProfileData$, graphDataService.currentBuildingHeadCountData$, graphDataService.prevStaffProfileData$, graphDataService.prevBuildingHeadCountData$]).subscribe(
            ([staffProfileData, headCountData, prevStaffProfileData, prevHeadCountData]) => {
                if (!headCountData || !staffProfileData || !prevStaffProfileData || !prevHeadCountData) { return; }
                const mainBuilding = configDataService.MAIN_BUILDING;
                const currentNonStaffData = (headCountData[mainBuilding]?.entrance?.headCount || 0) - (staffProfileData?.staff || 0);
                const prevNonStaffData = (prevHeadCountData[mainBuilding]?.entrance?.headCount || 0) - (prevStaffProfileData?.staff || 0);
                const diffNonStaffData = Math.round(currentNonStaffData - prevNonStaffData);
                const diffPercentNonStaffData = ((currentNonStaffData - prevNonStaffData) / prevNonStaffData) * 100;
                nonStaffNumberData$.next(currentNonStaffData?.toLocaleString());
                nonStaffLabel$.next(`${diffPercentNonStaffData}% (${diffNonStaffData.toFixed(1)})`);
                nonStaffLabelIcon$.next(diffNonStaffData > 0 ? 'trending-up' : 'trending-down');
                nonStaffLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: diffNonStaffData >= 0 ? '#5BBF93' : '#E25144' });
            }
        ));

        const staffNumberData$ = new BehaviorSubject('0');
        const staffLabel$ = new BehaviorSubject('0% (0)');
        const staffLabelIcon$ = new BehaviorSubject('trending-up');
        const staffLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        subscription.add(combineLatest([graphDataService.currentStaffProfileData$]).subscribe(
            ([staffProfileData]) => {
                if (!staffProfileData) { return; }
                staffNumberData$.next((staffProfileData?.staff || 0).toLocaleString());
                staffLabel$.next(`${staffProfileData?.diffPercent.toFixed(1)}% (${staffProfileData.diff})`);
                staffLabelIcon$.next(staffProfileData.diff > 0 ? 'trending-up' : 'trending-down');
                staffLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: staffProfileData.diff >= 0 ? '#5BBF93' : '#E25144' });
            }
        ));

        const currentMessengerNumberData$ = new BehaviorSubject('0');
        const currentMessengerLabel$ = new BehaviorSubject('0% (0)');
        const currentMessengerLabelIcon$ = new BehaviorSubject('trending-up');
        const currentMessengerLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        subscription.add(graphDataService.currentTotalMessengerData$.subscribe(currentMessengerData => {
            if (!currentMessengerData) {
                return;
            }
            currentMessengerNumberData$.next(currentMessengerData.messenger.toLocaleString());
            currentMessengerLabel$.next(`${currentMessengerData.diffPercent?.toFixed(1)}% (${currentMessengerData.diff?.toLocaleString()})`);
            currentMessengerLabelIcon$.next(currentMessengerData.diff > 0 ? 'trending-up' : 'trending-down');
            currentMessengerLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: currentMessengerData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const averageTimeSpentNumberData$ = new BehaviorSubject('0 mins');
        const averageTimeSpentLabel$ = new BehaviorSubject('0 % (0)');
        const averageTimeSpentLabelIcon$ = new BehaviorSubject('trending-up');
        const averageTimeSpentLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        subscription.add(combineLatest([graphDataService.currentAverageTimeSpentData$, viewPeriodService.isLiveMode$]).subscribe(([averageTimeSpentData, isLiveMode]) => {
            averageTimeSpentNumberData$.next(isLiveMode ? `calculating...` : `${(averageTimeSpentData.avgTimeSpent / 60).toFixed(1)} mins`);
            averageTimeSpentLabel$.next(isLiveMode ? `0 % (0)` : `${averageTimeSpentData.diffPercent.toFixed(1)} % (${(averageTimeSpentData.diff / 60).toFixed(1)})`);
            averageTimeSpentLabelIcon$.next(isLiveMode ? 'trending-up' : (averageTimeSpentData.diff / 60) > 0 ? 'trending-up' : 'trending-down');
            averageTimeSpentLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: isLiveMode ? '#5BBF93' : (averageTimeSpentData.diff / 60) >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const netVisitorHourNumberData$ = new BehaviorSubject('0 hrs');
        const netVisitorHourLabel$ = new BehaviorSubject('0% (0)');
        const netVisitorHourLabelIcon$ = new BehaviorSubject('trending-up');
        const netVisitorHourLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        subscription.add(combineLatest([graphDataService.currentNetVisitorHourData$, viewPeriodService.isLiveMode$]).subscribe(
            ([currentNetVistiorTimeData, isLiveMode]) => {
                netVisitorHourNumberData$.next(isLiveMode ? `calculating...` : `${(currentNetVistiorTimeData.netVisitorTime).toLocaleString()} hrs`);
                netVisitorHourLabel$.next(isLiveMode ? `0% (0)` : `${currentNetVistiorTimeData.diffPercent.toFixed(1)}% (${(currentNetVistiorTimeData.diff).toLocaleString()})`);
                netVisitorHourLabelIcon$.next(isLiveMode ? 'trending-up' : (currentNetVistiorTimeData.diff) > 0 ? 'trending-up' : 'trending-down');
                netVisitorHourLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: isLiveMode ? '#5BBF93' : (currentNetVistiorTimeData.diff >= 0 ? '#5BBF93' : '#E25144') });
            }
        ));

        const purchaseRateNumberData$ = new BehaviorSubject('0%');
        const purchaseRateLabel$ = new BehaviorSubject('0%');
        const purchaseRateLabelIcon$ = new BehaviorSubject('trending-up');
        const purchaseRateLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });

        subscription.add(graphDataService.currentpurchaseRateData$.subscribe((purchaseRateData) => {
            purchaseRateNumberData$.next(`${purchaseRateData.purchase.toFixed(1)}%`);
            purchaseRateLabel$.next(`${purchaseRateData.diff.toFixed(1)}%`);
            purchaseRateLabelIcon$.next(purchaseRateData.diff > 0 ? 'trending-up' : 'trending-down');
            purchaseRateLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: purchaseRateData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const maskCountNumberData$ = new BehaviorSubject('0%');
        const maskCountLabel$ = new BehaviorSubject('0% (0)');
        const maskCountLabelIcon$ = new BehaviorSubject('trending-up');
        const maskCountLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
        subscription.add(graphDataService.currentMaskCount$.subscribe((maskCountData) => {
            maskCountNumberData$.next(`${maskCountData.maskCount?.toFixed(1)}%`);
            maskCountLabel$.next(`${maskCountData.diff?.toFixed(1)}%`);
            maskCountLabelIcon$.next(maskCountData.diff > 0 ? 'trending-up' : 'trending-down');
            maskCountLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: maskCountData.diff >= 0 ? '#5BBF93' : '#E25144' });
        }));

        const vehicleTrafficNumberData$ = new BehaviorSubject('0');
        const vehicleTrafficLabel$ = new BehaviorSubject('0% (0)');
        const vehicleTrafficLabelIcon$ = new BehaviorSubject('trending-up');
        const vehicleTrafficLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
        subscription.add(graphDataService.currentTotalTransportationData$.subscribe((vehicleTrafficData) => {
            if (!vehicleTrafficData) { return; }
            vehicleTrafficNumberData$.next(`${(vehicleTrafficData.traffic).toLocaleString()}`);
            vehicleTrafficLabel$.next(`${Number.isNaN(vehicleTrafficData.diffPercent) ? '0' : vehicleTrafficData.diffPercent.toFixed(1)}% (${(vehicleTrafficData.diff).toLocaleString()})`);
            vehicleTrafficLabelIcon$.next((vehicleTrafficData.diff) > 0 ? 'trending-up' : 'trending-down');
            vehicleTrafficLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: (vehicleTrafficData.diff >= 0 ? '#5BBF93' : '#E25144') });
        }));

        const headcountWeekdayNumberData$ = new BehaviorSubject('0');
        const headcountWeekdayLabel$ = new BehaviorSubject('0% (0)');
        const headcountWeekdayLabelIcon$ = new BehaviorSubject('trending-up');
        const headcountWeekdayLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
        const headcountWeekdayTitle$ = new BehaviorSubject<string>('Avg. Weekday Traffic (Last 7 Days)');

        const headcountWeekendNumberData$ = new BehaviorSubject('0');
        const headcountWeekendLabel$ = new BehaviorSubject('0% (0)');
        const headcountWeekendLabelIcon$ = new BehaviorSubject('trending-up');
        const headcountWeekendLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
        const headcountisLock$ = new BehaviorSubject<boolean>(false);
        const headcountWeekendTitle$ = new BehaviorSubject<string>('Avg. Weekend Traffic (Last 7 Days)');

        subscription.add(combineLatest([graphDataService.currentBuildingAverageWeekDayData$, graphDataService.currentBuildingAverageWeekEndData$, graphDataService.buildingAverageWeekDayLast7DaysData$, graphDataService.buildingAverageWeekEndLast7DaysData$]).subscribe(
            ([buildingAverageWeekDayData, buildingAverageWeekEndData, buildingAverageWeekDayLast7DaysData, buildingAverageWeekEndLast7DaysData]) => {
                const mainBuilding = configDataService.MAIN_BUILDING;
                let mainBuildingAverageWeekdayData: any;
                let mainBuildingAverageWeekendData: any;
                if (viewPeriodService.isDayPeriod || viewPeriodService.isLiveMode) {
                    if (!buildingAverageWeekDayLast7DaysData || !buildingAverageWeekEndLast7DaysData) {
                        return;
                    } else if (Object.keys(buildingAverageWeekDayLast7DaysData).length === 0 || Object.keys(buildingAverageWeekEndLast7DaysData).length === 0) {
                        return;
                    }
                    mainBuildingAverageWeekdayData = buildingAverageWeekDayLast7DaysData[mainBuilding];
                    mainBuildingAverageWeekendData = buildingAverageWeekEndLast7DaysData[mainBuilding];
                    headcountWeekdayTitle$.next('Avg. Weekday Traffic (Last 7 Days)');
                    headcountWeekendTitle$.next('Avg. Weekend Traffic (Last 7 Days)');
                }
                else if (viewPeriodService.isWeekPeriod || viewPeriodService.isMonthPeriod) {
                    if ((!buildingAverageWeekDayData || !buildingAverageWeekEndData)) {
                        return;                    
                    } else if (Object.keys(buildingAverageWeekDayData).length === 0 || Object.keys(buildingAverageWeekEndData).length === 0) {
                        return;
                    }
                    mainBuildingAverageWeekdayData = buildingAverageWeekDayData[mainBuilding];
                    mainBuildingAverageWeekendData = buildingAverageWeekEndData[mainBuilding];
                    headcountWeekdayTitle$.next('Avg. Weekday Traffic');
                    headcountWeekendTitle$.next('Avg. Weekend Traffic');
                }
                headcountWeekdayNumberData$.next(mainBuildingAverageWeekdayData?.headCount.toLocaleString());
                headcountWeekdayLabel$.next(`${mainBuildingAverageWeekdayData.diffPercent?.toFixed(1)}% (${mainBuildingAverageWeekdayData.diff?.toLocaleString()})`);
                headcountWeekdayLabelIcon$.next(mainBuildingAverageWeekdayData.diff > 0 ? 'trending-up' : 'trending-down');
                headcountWeekdayLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: mainBuildingAverageWeekdayData.diff >= 0 ? '#5BBF93' : '#E25144' });
            
                headcountWeekendNumberData$.next(mainBuildingAverageWeekendData?.headCount.toLocaleString());
                headcountWeekendLabel$.next(`${mainBuildingAverageWeekendData.diffPercent?.toFixed(1)}% (${mainBuildingAverageWeekendData.diff?.toLocaleString()})`);
                headcountWeekendLabelIcon$.next(mainBuildingAverageWeekendData.diff > 0 ? 'trending-up' : 'trending-down');
                headcountWeekendLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: mainBuildingAverageWeekendData.diff >= 0 ? '#5BBF93' : '#E25144' });
            
                /*const startOfWeekDate = moment().startOf('week').add(1, 'day');
                if (viewPeriodService.isDayPeriod || viewPeriodService.selectedDate.isSame(startOfWeekDate) && viewPeriodService.isWeekPeriod) {
                    headcountWeekdayLabel$.next(`N/A % (N/A)`);
                    headcountWeekendLabel$.next(`N/A % (N/A)`);
                }*/
            }
        ));
    
        const totalHeadcountCard = {
            iconStyle: 'fontSize: 22px',
            // headerStyle: 'padding: 22px',
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.HEADCONT,
            numberData$: headcountNumberData$,
            label$: headcountLabel$,
            labelIcon$: headcountLabelIcon$,
            labelStyle$: headcountLabelStyle$,
            sizeXS: configDataService.currentOrganization === 'SPO' ? '6' : '12',
            isFirst: true,
            infoPopover: async (e: any) => {
                const customPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.HEADCONT,
                        toolTipDetails: ['Total number of people passing through mall gates in the selected time period.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await customPopover.present();
            },
        };

        const nonStaffHeadcountCard = {
            iconStyle: 'fontSize: 22px',
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.NON_STAFF_TRAFFIC,
            numberData$: nonStaffNumberData$,
            label$: nonStaffLabel$,
            labelIcon$: nonStaffLabelIcon$,
            labelStyle$: nonStaffLabelStyle$,
            infoPopover: async (e: any) => {
                const nonStafftPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.NON_STAFF_TRAFFIC,
                        toolTipDetails: ['Total traffic of non-staff visitors in a given period.', 'Based on all traffic subtracted by staff traffic (Security & Janitorial uniforms)'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await nonStafftPopover.present();
            },
            sizeXS: '6',
        };

        const currentVehicleTrafficCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.VEHICLE_TRAFFIC_CARD,
            iconStyle: 'fontSize: 22px',
            // headerStyle: 'padding: 18px',
            numberData$: vehicleTrafficNumberData$,
            label$: vehicleTrafficLabel$,
            labelIcon$: vehicleTrafficLabelIcon$,
            labelStyle$: vehicleTrafficLabelStyle$,
            sizeXS: '12',
            isFirst: true,
            infoPopover: async (e: any) => {
                const customPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.VEHICLE_TRAFFIC_CARD,
                        toolTipDetails: ['Total number of vehicles passing through parking lot gates in the selected time period.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await customPopover.present();
            },
        };

        const currentMessengerCard = {
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.MESSENGER_BRAND_TRAFFIC_CARD,
            iconStyle: 'fontSize: 22px',
            // headerStyle: 'padding: 17px',
            numberData$: currentMessengerNumberData$,
            label$: currentMessengerLabel$,
            labelIcon$: currentMessengerLabelIcon$,
            labelStyle$: currentMessengerLabelStyle$,
            isFirst: true,
            infoPopover: async (e: any) => {
                const currentMessengerPopover = await popoverController.create({
                    component: CustomerMessengerTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.MESSENGER_COUNT,
                        toolTipDetails: ['Total traffic of couriers/delivery employees in a given period, based on exit demographics.', 'Please see photos for reference.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await currentMessengerPopover.present();
            },
            sizeXS: '12',
        };

        const averageTimeSpentCard = {
            isLock: this.isLock,
            iconStyle: 'fontSize: 22px',
            title: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_NUMBER_CARD,
            numberData$: averageTimeSpentNumberData$,
            label$: averageTimeSpentLabel$,
            labelIcon$: averageTimeSpentLabelIcon$,
            labelStyle$: averageTimeSpentLabelStyle$,
            infoPopover: async (e: any) => {
                const avgTimeSpentPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_NUMBER_CARD,
                        toolTipDetails: ['Based on total entrance and exit timestamps.', 'Displayed in minutes.', 'Cannot be used during "LIVE" view.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await avgTimeSpentPopover.present();
            },
            sizeXS: '12', 
            sizeLG: '6',
            isFirst: true,
        };

        const netVisitorHourCard = {
            isLock: this.isLock,
            iconStyle: 'fontSize: 22px',
            title: configDataService.DISPLAY_LANGUAGE.NET_VISITOR_HOUR,
            numberData$: netVisitorHourNumberData$,
            label$: netVisitorHourLabel$,
            labelIcon$: netVisitorHourLabelIcon$,
            labelStyle$: netVisitorHourLabelStyle$,
            infoPopover: async (e: any) => {
                const netVisitorHourPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: 'Net Visitor Hours',
                        toolTipDetails: ['Based on total amount of hours spent by visitors.', 'Calculated by "Total Headcount" * "Average Duration of Visit (mins)"'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await netVisitorHourPopover.present();
            },
            sizeXS: '12',
            sizeLG: '6',
            isFirst: true,
        };

        const purchaseRateCard = {
            isLock: this.isLock,
            iconStyle: 'fontSize: 22px',
            title: configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE,
            numberData$: purchaseRateNumberData$,
            label$: purchaseRateLabel$,
            labelIcon$: purchaseRateLabelIcon$,
            labelStyle$: purchaseRateLabelStyle$,
            sizeXS: '12',
            sizeLG: '6',
            isFirst: true,
            infoPopover: async (e: any) => {
                const shoppingBagPopover = await popoverController.create({
                    component: PurchaseBagTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.PURCHASE_RATE,
                        toolTipDetails: ['Based on percentage of visitors carrying shopping bags.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await shoppingBagPopover.present();
            }
        };

        const maskCountCard = {
            isLock: this.isLock,
            iconStyle: 'fontSize: 22px',
            // headerStyle: 'padding: 25px',
            title: configDataService.DISPLAY_LANGUAGE.MASK_COUNT,
            numberData$: maskCountNumberData$,
            label$: maskCountLabel$,
            labelIcon$: maskCountLabelIcon$,
            labelStyle$: maskCountLabelStyle$,
            isFirst: true,
            infoPopover: async (e: any) => {
                const maskCountPopover = await popoverController.create({
                    component: CustomerMaskTooltipsComponent,
                    componentProps: {
                        toolTipTitle: 'Mask Use',
                        toolTipDetails: ['Based on % of entering visitors with mask on.', '±2% error rate.']
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await maskCountPopover.present();
            },
            sizeLG: '6',
            sizeXS: '12',
        };

        const headcountAverageWeekdayCard = {
            isLock: headcountisLock$,
            iconStyle: 'fontSize: 22px',
            title: headcountWeekdayTitle$,
            numberData$: headcountWeekdayNumberData$,
            label$: headcountWeekdayLabel$,
            labelIcon$: headcountWeekdayLabelIcon$,
            labelStyle$: headcountWeekdayLabelStyle$,
            sizeXS: '6',
            isFirst: true,
            infoPopover: async (e: any) => {
                const popover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: headcountWeekdayTitle$,
                        toolTipDetails: ['Based on the average daily traffic of weekdays during the week of the selected date.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await popover.present();
            },
        };

        const headcountAverageWeekendCard = {
            isLock: headcountisLock$,
            iconStyle: 'fontSize: 22px',
            title: headcountWeekendTitle$,
            numberData$: headcountWeekendNumberData$,
            label$: headcountWeekendLabel$,
            labelIcon$: headcountWeekendLabelIcon$,
            labelStyle$: headcountWeekendLabelStyle$,
            sizeXS: '6',
            infoPopover: async (e: any) => {
                const popover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: headcountWeekendTitle$,
                        toolTipDetails: ['Based on the average daily traffic of weekends during the week of the selected date.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await popover.present();
            },
        };

        const staffTrafficCard = {
            iconStyle: 'fontSize: 22px',
            isLock: this.isLock,
            title: configDataService.DISPLAY_LANGUAGE.STAFF_TRAFFIC_CARD,
            numberData$: staffNumberData$,
            label$: staffLabel$,
            labelIcon$: staffLabelIcon$,
            labelStyle$: staffLabelStyle$,
            infoPopover: async (e: any) => {
                const staffPopover = await popoverController.create({
                    component: CustomTooltipPopoverComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE.STAFF_TRAFFIC_CARD,
                        toolTipDetails: ['Total number of uniformed staff passing through mall gates in the selected time period.'],
                        classList: ['cleaner', 'guard'],
                        classType: 'staff',
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await staffPopover.present();
            },
            sizeXS: '6',
        };

        // To-Do: Change this part into config
        const spoCards = [totalHeadcountCard, currentVehicleTrafficCard, headcountAverageWeekdayCard, headcountAverageWeekendCard, staffTrafficCard, currentMessengerCard, averageTimeSpentCard, netVisitorHourCard, purchaseRateCard, maskCountCard];
        const mbkCards = [totalHeadcountCard, headcountAverageWeekdayCard, headcountAverageWeekendCard, currentVehicleTrafficCard, averageTimeSpentCard, netVisitorHourCard, purchaseRateCard, maskCountCard, currentMessengerCard];
        comInstance.cards = configDataService.currentOrganization === 'SPO' ? spoCards : mbkCards;
        return comInstance;
    }

}
