<ion-row [ngStyle]="isShowGraph ? { display: 'flex' } : { display: 'none' }">
  <ion-col>
    <ion-card appLockIcon [isLock]="isLock" class="ion-padding">
      <ion-card-header>
          <ion-grid>
              <ion-row class="ion-align-items-center">
                  <ion-col>
                      <ion-card-title>
                        {{ title }}
                        <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
                      </ion-card-title>
                  </ion-col>
              </ion-row>
          </ion-grid>
      </ion-card-header>
      <ion-card-content class="ion-no-margin">
        <ion-row>
          <ion-col appLockBlur [isLock]="isLock">
            <!-- <app-sankey-chart 
              [data$]="data$"
              [ngStyle]="overAllStyle(title)"
            >
            </app-sankey-chart> -->
            <app-generic-sankey-chart [filterFirstLevel]="filterFirstLevel" [sankeyRawData$]="sankeyRawData$" [sankeyDisplayMode]="sankeyDisplayMode" [chartData$]="data$"></app-generic-sankey-chart>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>



