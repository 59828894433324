import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartPluginsOptions } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Observable, Subscription } from 'rxjs';
import { DARK_THEME_BASE_COLOR } from 'src/app/configs/color';

@Component({
  selector: 'app-generic-custom-pie-chart',
  templateUrl: './generic-custom-pie-chart.component.html',
  styleUrls: ['./generic-custom-pie-chart.component.scss'],
})
export class GenericCustomPieChartComponent implements OnInit, OnDestroy {

  @Input() chartLabels$: Observable<Label[]>;
  @Input() data$: Observable<ChartDataSets[]>;
  @Input() chartOptions$: Observable<ChartOptions>;
  // @Input() isShowLegend = false;
  isShowLegend = true;

  private subscription = new Subscription();
  chartPlugins: ChartPluginsOptions = [ChartDataLabels];
  chartDatas: SingleDataSet[] = [];
  chartColor: Color[];
  chartOptions: ChartOptions;
  chartLabels: Label[];
  
  constructor() { }

  ngOnInit() {
    this.subscription.add(this.data$.subscribe(pieChartDataSets => {
      if (!pieChartDataSets) { return; }
      const chartData: SingleDataSet[] = [];
      const chartColor: Color[] = [{ backgroundColor: [], borderColor: [] }];
      pieChartDataSets.forEach(pieChartData => {
        pieChartData.data.slice().reverse().forEach((data, idx) => {
          chartData.push(data);
          (chartColor[0].backgroundColor as string[]).push(pieChartData.backgroundColor[pieChartData.data.length - 1 - idx]);
          (chartColor[0].borderColor as string[]).push(DARK_THEME_BASE_COLOR);
        });
      });
      this.chartColor = chartColor;
      this.chartDatas = chartData;
    }));
    this.subscription.add(this.chartOptions$.subscribe(pieChartOptions => {
      if (!pieChartOptions) { return; }
      this.chartOptions = pieChartOptions;
    }));
    this.subscription.add(this.chartLabels$.subscribe(pieChartLabels => {
      if (!pieChartLabels) { return; }
      this.chartLabels = pieChartLabels;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
