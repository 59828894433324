import { Injectable } from '@angular/core';
import { of, BehaviorSubject, lastValueFrom } from 'rxjs';

import { Event } from '../objects/event';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKENDV2_URL, BACKEND_URL } from '../configs/api';
import { AuthenticationService } from './authentication.service';

import * as moment from 'moment';
import { LoadingService } from './loading.service';
import { Loading } from '../enum/loading';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  EventList: Event[] = [];
  EventList$: BehaviorSubject<Event[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService,
    private dataService: DataService
  ) { }

  async fetchEventList(): Promise<void> {
    this.loadingService.startLoading(Loading.EVENT_DATA);
    const requestURL = '/retail_customer_api_v2/api/v2/event/list';
    return this.dataService.fetchData(requestURL).then((eventList: Event[]) => {
      this.EventList = eventList;
      this.EventList$.next(this.EventList);
    }).finally(() => this.loadingService.stopLoading(Loading.EVENT_DATA));
  }

  getEventById(eventId: string): Event {
    return this.EventList.find(event => event.event_id === eventId);
  }

  getEventByDate(startDate: string, endDate: string) {
    const searchStartDate = new Date(startDate).setHours(0, 0, 0, 0);
    const searchEndDate = new Date(endDate).setHours(0, 0, 0, 0);

    const searchEventResult = this.EventList.filter(event => {
      const eventStartDate = new Date(event.start_date).setHours(0, 0, 0, 0);
      const eventEndDate = new Date(event.end_date).setHours(0, 0, 0, 0);
      return !(
        searchEndDate < eventStartDate || searchStartDate > eventEndDate
      );
    });

    return of(searchEventResult);
  }

  async addEvent(event: any): Promise<any> {
    const newEvent: Event = {
      event_id: '_' + Math.random().toString(36).substr(2, 19),
      name: event.name,
      budget: event.budget,
      start_date: event.start_date,
      end_date: event.end_date,
      location_id: event.location_id,
      location: event.location,
      event_type: event.event_type,
      event_acquisition_cost: null,
      event_to_mall_acquisition_cost: null,
      created_date: new Date(),
      event_count_data: {
        event_count: 'event_count',
        event_count_raw: 'event_count_raw'
      },
      is_mock_event_count: event?.is_mock_event_count
    };
    const requestURL = `${BACKENDV2_URL}/api/v2/event/create`;
    const requestURLwithIdToken = await this.authenticationService.requestURLwithIdToken(requestURL, false);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const eventBody = {
      event: newEvent
    };
    return lastValueFrom(this.http.post(requestURLwithIdToken, eventBody, httpOptions)).then(() => {
      this.EventList.push(newEvent);
      this.EventList$.next(this.EventList);
      return Promise.resolve({ success: true });
    });
  }

  async updateEvent(event: any): Promise<any> {
    const requestURL = `${BACKENDV2_URL}/api/v2/event/update`;
    const requestURLwithIdToken = await this.authenticationService.requestURLwithIdToken(requestURL, false);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const eventBody = {
      event
    };
    return lastValueFrom(this.http.post(requestURLwithIdToken, eventBody, httpOptions)).then(() => {
      // TODO remove update list
      const newEventIndex = this.EventList.findIndex((obj => obj.event_id === event.event_id));
      this.EventList[newEventIndex] = event;
      this.EventList$.next(this.EventList);
      return Promise.resolve({ success: true });
    });
  }

  checkEventStatus(start_date: Date, end_date: Date): number {
    const today_date_moment = moment();
    const start_date_moment = moment(start_date);
    const end_date_moment = moment(end_date);

    if (today_date_moment > end_date_moment) {
      return 1;
    } else if (today_date_moment < start_date_moment) {
      return 2;
    } else {
      return 0;
    }
  }

  async deleteEvent(event_id: string): Promise<any> {
    const requestURL = `${BACKENDV2_URL}/api/v2/event/delete`;
    const requestURLwithIdToken = await this.authenticationService.requestURLwithIdToken(requestURL, false);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const eventBody = {
      event: {
        event_id
      }
    };
    return lastValueFrom(this.http.post(requestURLwithIdToken, eventBody, httpOptions));
  }
}
