import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData } from '../helpers/util';
import { showAgeCustomTooltips } from '../helpers/ageTooltips';
import { showCleanerCustomTooltips } from '../helpers/cleanerTooltips';
import { generateNestedData } from '../helpers/mock-data-generator';

export class StaffTrafficDurationResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        // graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;

        const defaultVALUE = Array(configDataService.TIME_LIST.length).fill(null);
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
    
        const getZonePinTrafficData = (
            data: { [buildingName: string]: number[] | { [floorName: string]: number[] } | { [floorName: string]: { [gateName: string]: number[] } } } | { [buildingName: string]: { [floorName: string]: { [zoneName: string]: { [gateName: string]: number[] } } } },
            building?: string,
            floorName?: string,
            zoneName?: string,
            gateName?: string,
        ) => {
            if (!data || !data[building]) { return defaultVALUE; }
            if (floorName) {
              if (zoneName && data[building][floorName]) {
                if (gateName && data[building][floorName][zoneName]) {
                    return (data[building][floorName][zoneName][gateName] || defaultVALUE) as number[];
                }
                return (data[building][floorName][zoneName] || defaultVALUE) as number[];
              }
              return (data[building][floorName] || defaultVALUE) as number[];
            }
            return (data[building] || defaultVALUE) as number[];
        };

        const biDataGrouper = (prev: any[], current: any, idx: number) => {
            const currentArray = prev[prev.length - 1];
            if (currentArray.length === 3) {
                prev.push([currentArray[currentArray.length - 1]]);
                prev[prev.length - 1].push(current);
            } else {
                prev[prev.length - 1].push(current);
            }
            return prev;
        };
        const calBiDataSumShiftOne = (prev: number[], current: any, idx: number) => {
            if (idx === 0) { return prev; } // shift by one value
            if (idx % 2 === 0) {
                prev.push(prev.pop() + current);
            } else {
                prev.push(current);
            }
            return prev;
        };
        const calBiDataSum = (prev: number[], current: any, idx: number) => {
            if (idx % 2 === 1) {
                prev.push(prev.pop() + current);
            } else {
                prev.push(current);
            }
            return prev;
        };

        const chartLabel = configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 3).map(([t1, _, t2]) => `${t1}-${t2}`);
        const busiest_time_by_entrance = configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance');
        const shift_first_hour = configDataService.isFeatureEnabled('graph_data', 'gate_time_visit')?.shift_first_hour;

        const initialEntranceData: any[] = [];
        const initialExitData: any[] = [];
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);

        if (isMockData) {
            const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'STAFF_TRAFFIC_DURATION', 'count', 6);
            dualBarData$.next([{
                data: mockData.staff,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            }]);
            subscription.add(combineLatest([viewPeriodService.dayList, graphDataService.baseGraphData.selectedInteractable$, graphDataService.staffTrafficCountTwoHour$]).subscribe(
                ([selectedInteractable, staffTraffic]) => {
                    comInstance.isShow = selectedInteractable?.type === 'toilet';
                    dualBarData$.next([{
                        data: [...mockData.staff],
                        color: '#346DF3',
                        label: 'Staff visit',
                    }]);
                }));
        } else {
            dualBarData$.next([{
                data: busiest_time_by_entrance ? shift_first_hour ? initialEntranceData.reduce(calBiDataSumShiftOne, []) : initialEntranceData.reduce(calBiDataSum, []) : initialEntranceData.reduce(calBiDataSumShiftOne, []),
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.ENTRANCE,
            }]);
            subscription.add(combineLatest([graphDataService.baseGraphData.selectedInteractable$,graphDataService.staffTrafficCountTwoHour$]).subscribe(
                ([selectedInteractable,staffTraffic]) => {
                    comInstance.isShow = selectedInteractable?.type === 'toilet';
                    console.log('StaffTraffic',staffTraffic);
                    dualBarData$.next([{
                        data: [...staffTraffic],
                        color: '#346DF3',
                        label: 'Staff visit',
                    }]);
                }));
        }
        comInstance.title = 'Cleaning Staff Visit';
        comInstance.isLock = this.isLock;
        // comInstance.infoPopover = async (e: any) => {
        //     const ageDetailPopover = await popoverController.create({
        //         component: CustomerAgeTooltipsComponent,
        //         cssClass: 'calendar-criteria-tooltips',
        //         event: e
        //     });
        //     return ageDetailPopover.present();
        // };
        comInstance.isShowLegend = true;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.valueTextProcess = 'numberFormatter';
        comInstance.xAxesRotation = 90;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showCleanerCustomTooltips(tooltipModel, this, configDataService.cleanerImageSrc, ['cleaner-mbk']);
        };
        return comInstance;
    }

}
