import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import { isSelectionVisitorProfileAll } from '../helpers/util';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';

const CHART_COLOR = '#E63739';

export class StoreVisitorDurationOfVisitTrendResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        _additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();

        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: graphDataService.storeVisitorAvgTimespentData$.value.map(val => val ? val / 60 : val),
            backgroundColor: `${CHART_COLOR}22`,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND},${time}`),
            label: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND,
        }]);
        const isLock$ = new BehaviorSubject(this.isLock);

        subscription.add(graphDataService.storeVisitorAvgTimespentData$.subscribe((storeVisitorAvgTimespentData) => {
            const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
            const lineChartData: GenericLineChartData[] = [{
                points: storeVisitorAvgTimespentData.map(val => val ? val / 60 : val),
                backgroundColor: `${CHART_COLOR}22`,
                color: CHART_COLOR,
                isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND},${time}`),
                label: configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND,
            }];
            chartData$.next(lineChartData);
            isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(graphDataService.selectedStoreVisitorProfile$.value)));
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.STORE_DURATION_OF_VISIT;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        // comInstance.sizeXS = '12';
        // comInstance.sizeMD = '10';
        // comInstance.offsetMD = '1';
        return comInstance;
    }

}
