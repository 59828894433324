/* eslint-disable max-len */
import { OverallResolverBase } from './OverallResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined } from '../helpers/util';

export class OverallAvgTimeSpentResolver extends OverallResolverBase {

    public get graphDependencyMap(): { building: GraphDependency[]; floor: GraphDependency[]; zone: GraphDependency[] } {
        return {
            building: [GraphDependency.ENTRANCE_EXIT],
            floor: [GraphDependency.ENTRANCE_EXIT_FLOOR],
            zone: [GraphDependency.ENTRANCE_EXIT_ZONE]
        };
    }

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
    ) {
        // load data here
        const level = getAdditionalInput(additionalInput, 'level') as DynamicGraphAdditionalInput['level'] | undefined;
        const groupData = getAdditionalInput(additionalInput, 'groupData') as DynamicGraphAdditionalInput['groupData'] | undefined;
        assertNullUndefined(level);
        this.processDynamicDependency(configDataService, graphDataService, level);
        // await configDataService.loadAppConfig();

        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const getAvgTimeSpentData = (
            dataBuilding: { [buildingName: string]: number[] },
            dataFloor: { [buildingName: string]: { [floorName: string]: number[] } },
            dataZone: { [buildingName: string]: { [floorName: string]: { [zoneName: string]: number[] } } },
        ) => this.getLeveledData<number[]>(dataBuilding, dataFloor, dataZone, level);
        const sec2Min = (val: number | undefined | null) => val ? Math.round(val / 60) : val;
        const initialComparingNames = level === 'building' ? this.getComparingNames(graphDataService.baseGraphData.comparingBuildingState$.value) : (level === 'floor' ? this.getComparingNames(graphDataService.baseGraphData.comparingFloorState$.value) : this.getComparingNames(graphDataService.baseGraphData.comparingZoneState$.value));
        const initialData = getAvgTimeSpentData(graphDataService.averageTimeSpentChartData$.value, graphDataService.avgTimeSpentFloorData$.value, graphDataService.avgTimeSpentZoneData$.value);
        const initialColor: string[] = [];
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>(Object.entries(initialData).sort().filter(([name, _data], idx) => {
            if (initialComparingNames.includes(name)) {
                if (level === 'floor' && configDataService?.FLOOR_LINE_CHART_COLOR_LIST){
                    initialColor.push(configDataService.FLOOR_LINE_CHART_COLOR_LIST[name]);   
                } else if (level === 'zone' && configDataService?.ZONE_LINE_CHART_COLOR_LIST) {
                    initialColor.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                } else {
                    initialColor.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                }
                return true;
            }
            return false;
        }).map(([name, data], idx) => ({
            points: data.map(sec2Min),
            color: initialColor[idx],
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST.map(dayText => `${this.getDisplayName(name)}, ${dayText}`),
            label: this.getDisplayName(name),
        })));

        // eslint-disable-next-line max-len
        subscription.add(combineLatest([graphDataService.averageTimeSpentChartData$, graphDataService.avgTimeSpentFloorData$, graphDataService.avgTimeSpentZoneData$, combineLatest([graphDataService.baseGraphData.comparingBuildingState$, graphDataService.baseGraphData.comparingFloorState$, graphDataService.baseGraphData.comparingZoneState$])]).subscribe(
            ([avgTimeSpentBuildingData, avgTimeSpentFloorData, avgTimeSpentZoneData, [comparingBuildingState, comparingFloorState, comparingZoneState]]) => {
                if ((!avgTimeSpentBuildingData && level === 'building') || (!avgTimeSpentFloorData && level === 'floor') || (!avgTimeSpentZoneData && level === 'zone')) { return; }
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const comparingNames = level === 'building' ? this.getComparingNames(comparingBuildingState) : (level === 'floor' ? this.getComparingNames(comparingFloorState) : this.getComparingNames(comparingZoneState));
                const fullData = getAvgTimeSpentData(avgTimeSpentBuildingData, avgTimeSpentFloorData, avgTimeSpentZoneData);
                const chartColors: string[] = [];
                if (groupData) {
                    Object.keys(groupData).forEach(k => {
                        const arr2DOfDataPoints: number[][] = [];
                        groupData[k].args.forEach(arg => {
                            arr2DOfDataPoints.push(fullData[arg] || null);
                        });
                        fullData[k] = arr2DOfDataPoints.every(arr => arr === null) ? null :
                            groupData[k].oper === 'AVG' ? arr2DOfDataPoints.reduce((r, a) => r.map((b, i) => b !== null ? (a[i] + b) / a.length - 1 : null)) : arr2DOfDataPoints.reduce((r, a) => r.map((b, i) => b !== null ? a[i] + b : null));
                    });
                }
                const lineChartData: GenericLineChartData[] = Object.entries(fullData).sort().filter(([name, _data], idx) => {
                    if (comparingNames.includes(name)) {
                        if (level === 'floor' && configDataService?.FLOOR_LINE_CHART_COLOR_LIST){
                            chartColors.push(configDataService.FLOOR_LINE_CHART_COLOR_LIST[name]);   
                        } else if (level === 'zone' && configDataService?.ZONE_LINE_CHART_COLOR_LIST) {
                            chartColors.push(configDataService.ZONE_LINE_CHART_COLOR_LIST[name]); 
                        } else {
                            chartColors.push(PIE_CHART_COLOR_LIST[idx % PIE_CHART_COLOR_LIST.length]);
                        }
                        return true;
                    }
                    return false;
                }).map(([name, data], idx) => ({
                    points: data.map(sec2Min),
                    color: chartColors[idx],
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(dayText => `${this.getDisplayName(name)}, ${dayText}`),
                    label: this.getDisplayName(name),
                }));
                chartData$.next(lineChartData);
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.isLock = this.isLock;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.DURATION_VISIT_TREND;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        return comInstance;
    }

}
