/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ChartColor, ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { BOTH_THEME_BASE_COLOR, CHART_PRIMARY_COLOR, CHART_PRIMARY_COLOR_OPACITY, LINE_CHART_COLOR_LIST, PIE_CHART_COLOR_LIST } from '../configs/color';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { assertNullUndefined, getDepthData, isScreenSmallerThanLG, isShowGraph } from '../helpers/util';
import { DynamicGraphAdditionalInput, DynamicGraphConfig } from '../objects/config';
import { SelectableCustomDataName } from '../objects/selectableData';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { CustomerMessengerGroupTooltipsComponent } from '../pages/home/general/customer-messenger-group-tooltips/customer-messenger-group-tooltips.component';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigDataService } from '../services/config-data.service';
import { EventDataService } from '../services/event-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicCustomLineChartComponent } from '../shared-components/dynamic/dynamic-custom-line-chart/dynamic-custom-line-chart.component';
import { VehiclePurchasingPowerTooltipsComponent } from '../shared-components/general/vehicle-purchasing-power-tooltips/vehicle-purchasing-power-tooltips.component';
import { GraphResolverBase } from './GraphResolverBase';
import { generateNestedData } from '../helpers/mock-data-generator';


export class CustomLineChartResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>, 
        additionalInput: string | DynamicGraphAdditionalInput,
        configDataService: ConfigDataService, 
        graphDataService: GraphDataService | EventDataService, 
        popoverController: PopoverController, 
        viewPeriodService: ViewPeriodService, 
        viewContainerRef: ViewContainerRef, 
        subscription: Subscription,
        authenicationService: AuthenticationService,
        ){
        // await configDataService.loadAppConfig();
        const lineChartConfig = (getAdditionalInput(additionalInput, 'dynamicGraphConfig') || {}) as DynamicGraphConfig;
        const lineChartLockDate = (getAdditionalInput(additionalInput, 'useZoneDateLock') || false) as boolean;
        const useselectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory') || false) as boolean;
        const lineChartLabel = (getAdditionalInput(additionalInput, 'lineChartLebel') || 'DAY_LIST') as string;
        const useProfileData = (getAdditionalInput(additionalInput, 'useProfileData') || false) as boolean;
        const buildingInstanceName = (getAdditionalInput(additionalInput, 'building')) as string;
        const floorInstanceName = (getAdditionalInput(additionalInput, 'floor')) as string;
        const zoneInstanceName = (getAdditionalInput(additionalInput, 'zone')) as string;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        const showDurationText = (getAdditionalInput(additionalInput, 'showDurationText')) as boolean;

        assertNullUndefined(lineChartConfig);

        const chartTitleKey = lineChartConfig.graphName;
        const chartState = (lineChartConfig.graphState || 'ENABLE');
        const chartDataConfig = lineChartConfig.graphData;
        const chartOptionsConfig = lineChartConfig.graphOptions;
        const chartTooltipLabel = lineChartConfig.graphTooltipLabel;
        const chartSelectOptions = lineChartConfig?.graphSelectOptions || [];

        const useSelector = chartOptionsConfig?.useSelector === true;
        const initialSelected: string[] = chartOptionsConfig?.initialSelected;
        const areaInstanceName: string[] = [buildingInstanceName, floorInstanceName, zoneInstanceName];
        const dataConfigFromInstance: string[] = areaInstanceName.filter(d => d !== undefined);

        const isShowLegend = chartOptionsConfig?.isShowLegend === true;
        const isShowCustomLegend = chartOptionsConfig?.isShowCustomLegend === true;
        const isShowPeakTime = chartOptionsConfig?.isShowPeakTime === true;
        const isShowPercent = chartOptionsConfig?.isShowPercent === true;
        const isDecimalPoint = chartOptionsConfig?.isDecimalPoint === true;
        const isOnTrafficPage = chartOptionsConfig?.isOnTrafficPage === true;
        const useVehicleData = chartOptionsConfig?.useVehicleData === true;
        const isShowPrediction = chartOptionsConfig?.isShowPrediction === true;
        const fillLineBackgroundColor =  chartOptionsConfig?.fillLineBackgroundColor === true;
        const labelHeaderCustomLegend = chartOptionsConfig?.labelHeaderCustomLegend || 'Mall Peak Hour';
        const displayLabelKey = chartOptionsConfig?.displayLabelKey;
        const legendLabelsConfig: { boxWidth: number; fontColor: string; fontSize: number } = chartOptionsConfig?.legendLabelsConfig || { boxWidth: 20, fontColor: 'white', fontSize: 14 };
        const layoutPaddingConfig: { top: number; left: number; right: number; bottom: number } = chartOptionsConfig?.layoutPaddingConfig || { top: 0, left: 5, right: 5, bottom: 10 };
        const tooltipsConfig: { titleAlign?: 'left' | 'center' | 'right'; titleFontSize?: number; bodyFontSize?: number; position?: string } = chartOptionsConfig?.tooltipsConfig || { titleAlign: 'center', titleFontSize: 18, bodyFontSize: 16, position: 'nearest' };
        const includeAreaList: string[] = chartOptionsConfig?.includeAreaList || [];
        const usingMappingStoreKey: boolean = chartOptionsConfig?.usingMappingStoreKey === true;
        const mappingStoreKey: { [key: string]: string[] } = chartOptionsConfig?.mappingStoreKey;
        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = isMockData ? [] : Object.keys(chartDataConfig).map(key => {
          const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as SelectableCustomDataName);
          graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
          return dataConfigResolved.data;
        });

        const chartData$ = new BehaviorSubject<ChartDataSets[]>([]);
        const chartOption$ = new BehaviorSubject<ChartOptions>({});
        const chartLabel$ = new BehaviorSubject<Label[]>([]);
        const isLock$ = new BehaviorSubject<boolean>(chartState === 'LOCK');
        const lineChartSelector$ = new BehaviorSubject<string[]>(initialSelected);

        const numberData$ = new BehaviorSubject<number>(0);
        const label$ = new BehaviorSubject<string>(null);
        const labelHeader$ = new BehaviorSubject<string>(null);
        const labelStyle: { fontSize: string; fontWeight: string; color: string } = { fontSize: '1rem', fontWeight: '500', color: '#5BBF93' };
        const labelIcon = 'people';

        //#region defined data access function
        const fillData: number[] = Array.from({ length: 7 }).map(() => null);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicCustomLineChartComponent;
        const allDataBehaviourSubject$: BehaviorSubject<unknown>[] = useselectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, configDataService.isEntranceDataMode$, graphDataService.baseGraphData.selectedLevel$, ...chartDataBehaviourSubject$] 
          : useSelector ? [lineChartSelector$, configDataService.isEntranceDataMode$, ...chartDataBehaviourSubject$] 
          : [configDataService.isEntranceDataMode$, ...chartDataBehaviourSubject$];

        if (isMockData) {
          subscription.add(combineLatest([viewPeriodService.dayList, graphDataService.baseGraphData.selectedInteractable$, ...allDataBehaviourSubject$]).subscribe(async ([dayList, selectedInteractable, ...allData]) => {
            if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
              comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
            }
            const selectedInteractableName = selectedInteractable?.name;
            if (includeAreaList.length > 0) {
              comInstance.isShow = includeAreaList.includes(selectedInteractableName);
            }
            const chartLabel = {
              DAY_LIST: viewPeriodService.DAY_LIST,
              TIME_LIST: configDataService.TIME_LIST,
              VEHICLE_TIME_LIST: configDataService.VEHICLE_TIME_LIST
            } [lineChartLabel];
            if (lineChartLabel !== 'DAY_LIST' && configDataService.isFeatureEnabled('time_display_name')) {
              chartLabel$.next(chartLabel.map(time => configDataService.DISPLAY_LANGUAGE.TIME_NAME[time]));
            } else {
              chartLabel$.next(chartLabel);
            }
            const mockChartDataList: any[] = [];
            const predMockChartDataList: any[] = [];

            const num_interval = lineChartLabel === 'DAY_LIST' ? 7 : configDataService.TIME_LIST.length;
            const viewperiodName = viewPeriodService.viewPeriod.compareName as 'days' | 'weeks' | 'months';
            if (chartDataConfig instanceof Array) {
              for (const dataConfig of chartDataConfig) {
                const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, dataConfig.name, 'count', num_interval);
                if (lineChartLabel === 'DAY_LIST') {
                  const predMockChartDataValue = await generateNestedData(viewPeriodService.selectedDate.add(1, viewperiodName), viewPeriodService, configDataService, dataConfig.name, 'count', 2);
                  predMockChartDataList.push(predMockChartDataValue);
                }
                mockChartDataList.push(mockChartDataValue);
              }
            } else {
              Object.keys(chartDataConfig).map(async key => {
                const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, chartDataConfig[key].name, 'count', num_interval);
                if (lineChartLabel === 'DAY_LIST') {
                    const predMockChartDataValue = await generateNestedData(viewPeriodService.selectedDate.add(1, viewperiodName), viewPeriodService, configDataService, chartDataConfig[key].name, 'count', 2);
                    predMockChartDataList.push(predMockChartDataValue);
                }
                mockChartDataList.push(mockChartDataValue);
              });
            }
            const chartData: ChartDataSets[] = [];
            let idx = 0;
            if (selectedInteractableName) {
              const [areaKey, mockChartData] = Object.entries(mockChartDataList[0]).find(([key, value]) => selectedInteractableName === key) !== undefined ? Object.entries(mockChartDataList[0]).find(([key, value]) => selectedInteractableName === key) : Object.entries(mockChartDataList[0]).shift();
              if (isShowPeakTime) {
                const trafficData = Object.values(mockChartData)[0] as number[];
                const peakTotalTraffic = trafficData.reduce((max: number, cur_val: number) => cur_val > max ? cur_val : max, 0);
                const findIndex = trafficData.findIndex((val: number) => val === peakTotalTraffic);
                const timeMapped = configDataService.TIME_LIST[findIndex];
                const busiestTime = new Date(2020, 1, 1, parseInt(timeMapped, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
                labelHeader$.next(labelHeaderCustomLegend);
                numberData$.next(peakTotalTraffic);
                label$.next(busiestTime);
              }
              for (const [label, chartValue] of Object.entries(mockChartData)) {
                chartData.push({
                  fill: fillLineBackgroundColor, 
                  data: chartValue as number[], 
                  pointRadius: 0,
                  pointHitRadius: 40,
                  backgroundColor: isShowPeakTime ? '#6BC26F22' : CHART_PRIMARY_COLOR_OPACITY,
                  borderDash: useVehicleData && idx > 0 ? [5, 3] : [],
                  borderColor: isShowPeakTime ? '#6BC26F' : configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[label] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                  pointBackgroundColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[label] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                  pointBorderColor: 'rgba(0, 0, 0, 1)',
                  pointHoverBackgroundColor: isShowPeakTime ? '#6BC26F' : configDataService.HOVER_BG_COLOR_LIST?.[chartTitleKey]?.[label] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                  pointHoverBorderColor: 'rgba(0, 0, 0, 1)',
                  label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[label] || label,
                });
                idx++;
              }
            } else {
              for (const [label, chartValue] of Object.entries(mockChartDataList[0])) {
                chartData.push({
                  fill: fillLineBackgroundColor, 
                  data: chartValue as number[], 
                  pointRadius: 0,
                  pointHitRadius: 40,
                  backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
                  borderDash: useVehicleData && idx > 0 ? [5, 3] : [],
                  borderColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[label] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                  pointBackgroundColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[label] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                  pointBorderColor: 'rgba(0, 0, 0, 1)',
                  pointHoverBackgroundColor: configDataService.HOVER_BG_COLOR_LIST?.[chartTitleKey]?.[label] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                  pointHoverBorderColor: 'rgba(0, 0, 0, 1)',
                  label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[label] || label,
                });
                idx++;
              }
            }
            chartData$.next(chartData);
          }));
        } else {
          subscription.add(combineLatest(allDataBehaviourSubject$).subscribe((combinedResult) => {
            let selectedInteractableName: string;
            if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
              comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName]; 
            }
            graphDataService.baseGraphData.selectedInteractable$.subscribe(selectedInteractable => {
              if (selectedInteractable) {
                selectedInteractableName = selectedInteractable.name;
                if (includeAreaList.length > 0) {
                  comInstance.isShow = includeAreaList.includes(selectedInteractableName);
                }
              }
            });
            combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$]).subscribe(([selectedDirectory, selectedLevel]) => {
              if (excludedDirectory !== undefined) {
                if (excludedDirectory === 'BUILDING') {
                  comInstance.isShow =  selectedDirectory?.floor !== 'ALL';
                }
                else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                  comInstance.isShow =  selectedLevel !== excludedDirectory;
                }
                // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') <excludedDirectory.length - 1) {
                  const excludeList = excludedDirectory.split('_AND_');
                  if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                    comInstance.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                  }
                  else if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                    comInstance.isShow = true;
                  } else {
                    comInstance.isShow = !excludeList.includes(selectedLevel);
                  }
                }
              }
              else if (includeAreaList.length > 0) {
                comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && includeAreaList.includes(selectedDirectory?.zone); 
              }
            });
            if (!useselectedDirectory && combinedResult.some(val => !val)) { return; }
              const selectDirectory = useselectedDirectory ? combinedResult[0] as { building: string; floor: string; zone: string } : null;
              if (useselectedDirectory) {
                if (!selectDirectory) {
                    comInstance.isShow = false;
                    return;
                }  
                if (lineChartLockDate) {
                  if (selectDirectory?.zone === '5d') {
                    viewPeriodService.selectedDate.isBetween('2021-03-14', '2021-03-17') ? isLock$.next(true) : (viewPeriodService.selectedDate.isBefore('2021-03-08') ? isLock$.next(true)  : isLock$.next(false) );
                  } else if (selectDirectory?.zone === '4d' || selectDirectory?.zone === '6d') {
                      viewPeriodService.selectedDate.isBefore('2021-03-17') ? isLock$.next(true) : isLock$.next(false);
                  } else {
                      isLock$.next(true);
                  }            
                }
              }
              // const chartLabel = lineChartLabel === 'DAY_LIST' ? viewPeriodService.DAY_LIST : configDataService.TIME_LIST;
              const chartLabel = {
                DAY_LIST: viewPeriodService.DAY_LIST,
                TIME_LIST: configDataService.TIME_LIST,
                VEHICLE_TIME_LIST: configDataService.VEHICLE_TIME_LIST
              } [lineChartLabel];
              if (lineChartLabel !== 'DAY_LIST' && configDataService.isFeatureEnabled('time_display_name')) {
                chartLabel$.next(chartLabel.map(time => configDataService.DISPLAY_LANGUAGE.TIME_NAME[time]));
              } else {
                chartLabel$.next(chartLabel);
              }
              const rawDatas = useselectedDirectory ? combinedResult.slice(3) as Record<string, any>[] 
              :  useSelector ? combinedResult.slice(2) as Record<string, any>[] 
              : isShowPeakTime ? combinedResult.slice(1, combinedResult.length - 1) as Record<string, any>[] 
              : combinedResult.slice(1) as Record<string, any>[];
              const isEntrance = combinedResult[1] as boolean;
              const selected = useSelector ? combinedResult[0] as string[] : [];
              const timeUTC = chartLabel.map(time => parseInt(time, 10) - configDataService.TIME_DIFF_FROM_UTC);
              if (useProfileData || chartOptionsConfig?.useClassData) {
                rawDatas.forEach((rawData) => {
                  if (!rawData) {
                    return;
                  }
                  let dataConfig: string[] = [];
                  let flattenData: any;
                  let filterData: any;
                  if (selectedInteractableName) {
                    const keys = Object.keys(rawData);
                    if (keys.length > 1) {
                      const selectedNameArray = selectedInteractableName ? [selectedInteractableName] : [];
                      dataConfig = [...selectedNameArray];
                      filterData = useSelector ? Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => selected.includes(key))) : rawData;
                      // flattenData = getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as Record<string, any>;
                      // flattenData = filterData
                      flattenData = useSelector ? Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => selected.includes(key))) : rawData;
                    } else {
                      const selectedNameArray = selectedInteractableName ? [selectedInteractableName] : [];
                      dataConfig = [...selectedNameArray];
                      filterData = useSelector ? Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => selected.includes(key))) : rawData;
                      flattenData = getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as Record<string, any>;
                    }
                  } else {
                    if(rawData.mbk_parking){
                      filterData = useSelector ? Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => selected.includes(key))) : rawData;
                      dataConfig.push('mbk_parking');
                      flattenData = getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as Record<string, any>;
                    } else{
                      filterData = useSelector ? Object.fromEntries(Object.entries(rawData).filter(([key, _value]) => selected.includes(key))) : rawData;
                      dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : chartDataConfig[0]?.args;
                      flattenData = getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as Record<string, any>;
                    }
                  }
  
                  if (fillData) {
                    const chartData: ChartDataSets[] = Object.entries(flattenData).map(([className, val], idx) => ({
                      // cubicInterpolationMode: 'monotone',
                      fill: idx === 0,
                      label: displayLabelKey === 'VEHICLE_MODE_TRANSPORTATION_CLASS' ? configDataService.DISPLAY_LANGUAGE.VEHICLE_MODE_TRANSPORTATION_CLASS[className] : configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[className] || className.charAt(0).toUpperCase() + className.slice(1), 
                      data: lineChartLabel === 'DAY_LIST' ? (val as any[]).slice(0, (val as any[]).length - 1) : lineChartLabel === 'VEHICLE_TIME_LIST' ? timeUTC.map(time => (val as Record<string, any>)[time] || 0) : val as (number | number[])[],
                      pointRadius: 0,
                      pointHitRadius: 40,
                      backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
                      borderDash: useVehicleData && idx > 0 ? [5, 3] : [],
                      borderColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[className] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                      pointBackgroundColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[className] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                      pointBorderColor: 'rgba(0, 0, 0, 1)',
                      pointHoverBackgroundColor: configDataService.HOVER_BG_COLOR_LIST?.[chartTitleKey]?.[className] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                      pointHoverBorderColor: 'rgba(0, 0, 0, 1)',
                      tension: 0.1,
                    }));
                    const chartPredData: ChartDataSets[] = !isShowPrediction ? [] : Object.entries(filterData).map(([className, val], idx) => ({
                          // cubicInterpolationMode: 'monotone',
                          fill: false,
                          label: (configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[className] || className.charAt(0).toUpperCase() + className.slice(1)) + ' Regression Forecasted', 
                          data: lineChartLabel === 'DAY_LIST' ? Array.from({ length: 6 }).map(() => null).concat([(val as any[])[(val as any[]).length - 2], (val as any[])[(val as any[]).length - 1]]) : val as (number | number[])[],
                          pointRadius: 0,
                          pointHitRadius: 40,
                          backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
                          borderDash: [5, 3],
                          borderColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[className] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                          pointBackgroundColor: configDataService.BG_COLOR_LIST?.[chartTitleKey]?.[className] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                          pointBorderColor: 'rgba(0, 0, 0, 1)',
                          pointHoverBackgroundColor: configDataService.HOVER_BG_COLOR_LIST?.[chartTitleKey]?.[className] || (idx > 0 ? LINE_CHART_COLOR_LIST[idx] : CHART_PRIMARY_COLOR),
                          pointHoverBorderColor: 'rgba(0, 0, 0, 1)',
                          tension: 0.1,
                    }));
                    chartData$.next(chartData.concat(chartPredData));
                  }
                });
              } else if (useselectedDirectory) {
                const selectedLevel = combinedResult[2] as 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA';
                if (excludedDirectory) {
                  comInstance.isShow = selectDirectory?.floor === 'ALL' ? true : excludedDirectory === 'FLOOR_AND_ZONE' ? false : selectedLevel !== excludedDirectory;
                }
                const selectedData = selectDirectory.floor === 'ALL' ? rawDatas[0] : selectedLevel === 'FLOOR' ? rawDatas[1] : rawDatas[2];
                const chartPoints = selectedLevel === 'ZONE' 
                ? getDepthData(isEntrance, selectedData, { buildingName: selectDirectory?.zone }) as number[]
                // : getDepthData(isEntrance, selectedData, selectDirectory?.building, selectDirectory?.floor === 'ALL' ? undefined : selectDirectory.floor, selectDirectory?.zone);
                : getDepthData(isEntrance, selectedData, { buildingName: selectDirectory?.building, floorName: selectDirectory?.floor === 'ALL' ? undefined : selectDirectory.floor, zoneName: selectDirectory?.zone });
                const chartData: ChartDataSets[] = [{
                      fill: fillLineBackgroundColor, 
                      data: chartPoints as number[], 
                      pointRadius: 0,
                      pointHitRadius: 40,
                      borderColor: isShowPeakTime ? '#6BC26F' : PIE_CHART_COLOR_LIST[0],
                      backgroundColor: fillLineBackgroundColor ? isShowPeakTime ? '#6BC26F22' : `${PIE_CHART_COLOR_LIST[0]}22` : undefined,
                      pointBackgroundColor: PIE_CHART_COLOR_LIST[0],
                      pointBorderColor: isShowPeakTime ? 'rgba(0, 0, 0, 1)' : 'rgba(255,255,255, 0.8)',
                      pointHoverBackgroundColor: isShowPeakTime ? '#6BC26F' : PIE_CHART_COLOR_LIST[0],
                      pointHoverBorderColor: 'rgba(255,255,255, 1)',
                      label: isShowPeakTime ? configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART : chartTooltipLabel[0]
                }];
                if (isShowPeakTime) {
                  const selectedBusiestTimeData = selectDirectory.floor === 'ALL' ? rawDatas[3] : selectedLevel === 'FLOOR' ? rawDatas[4] : rawDatas[5];
                  const busiestTimeData = selectedLevel === 'ZONE' 
                  ? getDepthData(isEntrance, selectedBusiestTimeData, { buildingName: selectDirectory?.zone }) as { headcount: number; hour: string }
                  : selectDirectory.floor === 'ALL'
                  ? getDepthData(isEntrance, selectedBusiestTimeData, { buildingName: selectDirectory?.building}) as { headcount: number; hour: string }
                  : getDepthData(isEntrance, selectedBusiestTimeData, { buildingName: selectDirectory?.building, floorName: selectDirectory?.floor === 'ALL' ? undefined : selectDirectory.floor, zoneName: selectDirectory?.zone }) as { headcount: number; hour: string };
                  const peakTotalTraffic = busiestTimeData.headcount;
                  const busiestTime = busiestTimeData.hour;
                  labelHeader$.next(labelHeaderCustomLegend);
                  numberData$.next(peakTotalTraffic);
                  label$.next(busiestTime);
                }
                chartData$.next(chartData);
              } else if (isShowPeakTime) {
                const name = 'OVERALL_POPULAR_TIME';
                // const peakTotalTraffic = Object.values(combinedResult[combinedResult.length - 1])[0];
                // const busiestTime = Object.values(combinedResult[combinedResult.length - 1])[1];
                const dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : chartDataConfig[name]?.args ? chartDataConfig[name].args : []; 
                // const busiestTimeData = dataConfigFromInstance.length > 0 ? getDepthData(isEntrance, combinedResult[combinedResult.length - 1], dataConfig[0], dataConfig[1], dataConfig[2]) : combinedResult[combinedResult.length - 1];
                const busiestTimeData = dataConfigFromInstance.length > 0 ? getDepthData(isEntrance, combinedResult[combinedResult.length - 1], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as number[] : combinedResult[combinedResult.length - 1];
                const peakTotalTraffic = Object.values(busiestTimeData)[0];
                const busiestTime = Object.values(busiestTimeData)[1];
                const chartData: ChartDataSets[] = [{
                  fill: true, 
                  data: getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as number[], 
                  borderColor: '#6BC26F',
                  backgroundColor: '#6BC26F22',
                  pointBackgroundColor: '#6BC26F',
                  pointBorderColor: 'rgba(0, 0, 0, 1)',
                  pointHoverBackgroundColor: '#6BC26F',
                  pointHoverBorderColor: 'rgba(255,255,255, 1)',
                  label: configDataService.DISPLAY_LANGUAGE.POPULAR_TIME_CHART,
                  pointRadius: 0,
                  pointHitRadius: 40,
                  // pointRadius: isOnTrafficPage ? 3 : 2,
                }];
                labelHeader$.next(labelHeaderCustomLegend);
                numberData$.next(peakTotalTraffic);
                label$.next(busiestTime);
                chartData$.next(chartData);
              } else if (isShowPrediction) {
                const dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : chartDataConfig[0]?.args; 
                const getData = getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as number[];
                const chartData: ChartDataSets[] = [{
                  fill: false, 
                  data: getData.slice(0, getData.length - 1), 
                  pointRadius: 0,
                  pointHitRadius: 40,
                  borderColor: PIE_CHART_COLOR_LIST[0],
                  pointBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  pointBorderColor: 'rgba(255,255,255, 0.8)',
                  pointHoverBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  pointHoverBorderColor: 'rgba(255,255,255, 1)',
                  label: chartTooltipLabel[0]
                }, {
                  fill: false, 
                  data: Array.from({ length: 6 }).map(() => null).concat([getData[getData.length - 2], getData[getData.length - 1]]), 
                  pointRadius: 0,
                  pointHitRadius: 40,
                  borderColor: PIE_CHART_COLOR_LIST[0],
                  pointBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  pointBorderColor: 'rgba(255,255,255, 0.8)',
                  pointHoverBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  borderDash: [5, 3],
                  pointHoverBorderColor: 'rgba(255,255,255, 1)',
                  label: `${chartTooltipLabel[0]} Forecasted`
                }
              ];
                chartData$.next(chartData);
              } else if (usingMappingStoreKey) {
                // const dataConfig = Object.keys(mappingStoreKey).find(k => k === selectedInteractableName) !== undefined ? mappingStoreKey[selectedInteractableName] : [selectDirectory?.building, selectDirectory?.floor, selectedInteractableName]; 
                const dataConfig = Object.keys(mappingStoreKey).find(k => k === selectedInteractableName.toLocaleLowerCase()) !== undefined ? mappingStoreKey[selectedInteractableName.toLocaleLowerCase()] : []; 
                const flattenData = getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2], gateName: dataConfig[3] }) as number[];
                const chartData: ChartDataSets[] = [{
                  fill: fillLineBackgroundColor, 
                  data: lineChartLabel === 'DAY_LIST' ? flattenData.map((d, i) => (i === flattenData.length - 1) ? null : d) : flattenData, 
                  pointRadius: 0,
                  pointHitRadius: 40,
                  borderColor: PIE_CHART_COLOR_LIST[0],
                  backgroundColor: fillLineBackgroundColor ? `${PIE_CHART_COLOR_LIST[0]}22` : undefined,
                  pointBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  pointBorderColor: 'rgba(255,255,255, 0.8)',
                  pointHoverBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  pointHoverBorderColor: 'rgba(255,255,255, 1)',
                  label: chartTooltipLabel[0]
                }];
                chartData$.next(chartData);
              }
              else {
                const dataConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : chartDataConfig[0]?.args; 
                const flattenData = getDepthData(isEntrance, rawDatas[0], { buildingName: dataConfig[0], floorName: dataConfig[1], zoneName: dataConfig[2] }) as number[];
                const chartData: ChartDataSets[] = [{
                  fill: fillLineBackgroundColor, 
                  data: lineChartLabel === 'DAY_LIST' ? flattenData.map((d, i) => (i === flattenData.length - 1) ? null : d) : flattenData, 
                  pointRadius: 0,
                  pointHitRadius: 40,
                  borderColor: PIE_CHART_COLOR_LIST[0],
                  backgroundColor: fillLineBackgroundColor ? `${PIE_CHART_COLOR_LIST[0]}22` : undefined,
                  pointBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  pointBorderColor: 'rgba(255,255,255, 0.8)',
                  pointHoverBackgroundColor: PIE_CHART_COLOR_LIST[0],
                  pointHoverBorderColor: 'rgba(255,255,255, 1)',
                  label: chartTooltipLabel[0]
                }];
                chartData$.next(chartData);
              }
          }));
        }

        chartOption$.next({
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: useselectedDirectory ? 1.1 : isScreenSmallerThanLG(window) ? 0.9 : (chartOptionsConfig?.aspectRatio ? Number(chartOptionsConfig.aspectRatio) : 1.1),
            animation: {
                duration: 1000,
                easing: 'easeOutCubic',
            },
            responsiveAnimationDuration: 1000,
            layout: {
                padding: {
                  top: isOnTrafficPage ? 5 : layoutPaddingConfig.top,
                  left: isOnTrafficPage ? 0 : layoutPaddingConfig.left,
                  right: isOnTrafficPage ? 0 : layoutPaddingConfig.right,
                  bottom: isOnTrafficPage ? 0 : layoutPaddingConfig.bottom,
                }
            },
            scales: {
              yAxes: [{
                  ticks: {
                    min: 0,
                    callback: (value) => value < 1000 ? value : `${Math.round((value as number) / 1000)}K`,
                    maxTicksLimit: 6,
                    fontColor: '#efefef',
                    stepSize: 1
                    // fontSize: isScreenSmallerThanLG(window) ? 12 : 16,
                  },
                  gridLines: {
                    color: BOTH_THEME_BASE_COLOR,
                  },
              }],
              xAxes: [{
                ticks: {
                  min: 0,
                  callback: (value, idx) => 
                    // condition for new popular time with peak time
                    lineChartLabel === 'TIME_LIST' && configDataService.TIME_LIST.length > 14 ? (idx % 2 === 0 ? value : null) : value
                  ,
                  stepSize: 1,
                  fontColor: '#efefef',
                  // fontSize: isScreenSmallerThanLG(window) ? 12 : 16,
                  minRotation: 90,
                  maxRotation: 90,
                },
                gridLines: {
                  color: BOTH_THEME_BASE_COLOR,
                },
              }],
            },
            legend: {
              display: isShowLegend,
              labels: {
                generateLabels(chart) {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.datasets.map(function(d, i) {
                      return {
                        text: d.label as string,
                        fillStyle: (d.borderColor as string),
                        lineCap: 'butt',
                        lineDash: [],
                        lineWidth: 1,
                        lineDashOffset: 0,
                        lineJoin: 'bevel',
                        strokeStyle: (d.borderColor as string),
                        index: i,
                        isShadow: d.label && d.label.endsWith('Forecasted') ? true : (d as any).isShadow,
                      };
                    });
                  }
                },
                filter(item, _chart) {
                  return !(item as any).isShadow;
                },
                boxWidth: legendLabelsConfig.boxWidth,
                fontColor: legendLabelsConfig.fontColor,
                fontSize: legendLabelsConfig.fontSize,
                usePointStyle: false,
              },
              onClick: (e) => e.stopPropagation(),
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => {
                  const datasetIndex = tooltipItem.datasetIndex;
                  const datasetLabel = data.datasets[datasetIndex].label;
                  const datasetData = data.datasets[datasetIndex].data[tooltipItem.index];
                  if (isShowPrediction && tooltipItem.index === 6 && datasetLabel.endsWith('Forecasted')) {
                    return;
                  }
                  if (isShowPeakTime) {
                    return `${chartTooltipLabel[0]}: ${(datasetData as number).toLocaleString()}`;
                  } else if (isShowPercent) {
                    return isDecimalPoint ? `${datasetLabel}: ${(datasetData as number).toFixed(2)}%` : `${datasetLabel}: ${(datasetData as number).toFixed(0)}%`;
                  }
                  return `${datasetLabel}: ${(datasetData as number).toLocaleString()}`;
                },
                labelColor: (tooltipItem, chart) => {
                    const chartColor = chart.data.datasets[tooltipItem.datasetIndex].borderColor as ChartColor;
                    return {
                        borderColor: 'black',
                        backgroundColor: chartColor,
                    };
                }
              },
              intersect: true,//!isShowPeakTime,
              position: tooltipsConfig.position || 'nearest',
              caretSize: tooltipsConfig.position === 'nearest' ? 5 : 0,
              // mode: 'index',//'label',
              mode: 'nearest',
              titleAlign: tooltipsConfig.titleAlign || 'center',
              titleFontSize: tooltipsConfig.titleFontSize || 18,
              bodyFontSize: tooltipsConfig.bodyFontSize || 16,
              
            },
            hover: {
              intersect: !isShowPeakTime,
            },
            plugins: {
              datalabels: {
                display: false,
              }
            }
        });

        if (lineChartConfig.graphInfoPopover) {
            const infoPopoverText = lineChartConfig.graphInfoPopover;
            comInstance.infoPopover = async (e: any) => {
                const stackBarPopover = await popoverController.create({
                    component: lineChartConfig.graphInfoPopover?.customPopover?.componentName === 'messengerGroup' ? CustomerMessengerGroupTooltipsComponent 
                    : lineChartConfig.graphInfoPopover?.customPopover?.componentName === 'purchasingPower' ? VehiclePurchasingPowerTooltipsComponent : CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE[chartTitleKey] || infoPopoverText.title,
                        toolTipDetails: infoPopoverText.details,
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await stackBarPopover.present();
            };
        }
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.chartOptions$ = chartOption$;
        comInstance.chartLabel$ = chartLabel$;
        comInstance.data$ = chartData$;
        comInstance.isLock = isLock$;
        comInstance.isShowLegend = isShowLegend;
        comInstance.isShowLegendOnPercent = isShowPercent;
        comInstance.isShowOnPeakTime = isShowPeakTime;
        comInstance.isShowCustomLegend = isShowCustomLegend;
        comInstance.labelIcon = labelIcon;
        comInstance.labelStyle = labelStyle;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelHeader$ = labelHeader$;
        comInstance.isShowSelector = useSelector;
        comInstance.lineChartSelector$ = lineChartSelector$;
        comInstance.chartSelectorOptions = chartSelectOptions;
        comInstance.showDurationText = showDurationText
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        return comInstance;
    }
}
