import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { getDepthData, isSelectionVisitorProfileAll } from '../helpers/util';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { VisitorProfileSelection } from '../objects/visitor-profile';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class VisitorTrafficHeadcountNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const fillData: { headCount: number; diff: number; diffPercent: number } = { headCount: 0, diff: 0, diffPercent: 0 };
        const numberData$ = new BehaviorSubject('0');
        const label$ = new BehaviorSubject('0 % (0)');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        const isLock$ = new BehaviorSubject(this.isLock);

        if (isMockData) {
            subscription.add(combineLatest([graphDataService.selectedVisitorProfile$, viewPeriodService.dayList]).subscribe(async ([selectedVisitorProfile, dayList]) => {
                const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'VISITOR_TRAFFIC_TREND', 'count', 2);
                const headCounts = Object.values(mockData) as any;
                const headCount = headCounts[0][1];
                const diff = headCounts[0][1] - headCounts[0][0];
                const diffPercent = diff / headCounts[0][0] * 100;
                numberData$.next(headCount.toLocaleString());
                label$.next(`${diffPercent?.toFixed(1)} % (${diff?.toLocaleString()})`);
                labelIcon$.next(diff > 0 ? 'trending-up' : 'trending-down');
                labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: diff >= 0 ? '#5BBF93' : '#E25144' });
                isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(selectedVisitorProfile)));
            }));
        } else {
            subscription.add(combineLatest([graphDataService.currentBuildingHeadCountData$, graphDataService.currentVisitorTrafficTrendtData$]).subscribe(
                ([buildingEntranceExitData, currentVisitorTrafficTrendtData]) => {
                    const selectedVisitorProfile: VisitorProfileSelection = graphDataService.selectedVisitorProfile$.getValue();
                    const buildingName = selectedVisitorProfile.organization === 'all' ? configDataService.MAIN_BUILDING : selectedVisitorProfile.organization;
                    const isEntrance = configDataService.isEntranceDataMode$.getValue();
                    const visitorProfileTrafficByDepth = getDepthData<unknown>(isEntrance, buildingEntranceExitData, { buildingName }, fillData) as { headCount: number; diff: number; diffPercent: number };
                    const visitorProfileData = selectedVisitorProfile.gender === 'all' && selectedVisitorProfile.ethnicity === 'all' && selectedVisitorProfile.age === 'all' ? visitorProfileTrafficByDepth : currentVisitorTrafficTrendtData;
                    numberData$.next(visitorProfileData.headCount?.toLocaleString());
                    label$.next(`${visitorProfileData.diffPercent?.toFixed(1)} % (${visitorProfileData.diff?.toLocaleString()})`);
                    labelIcon$.next(visitorProfileData.diff > 0 ? 'trending-up' : 'trending-down');
                    labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: visitorProfileData.diff >= 0 ? '#5BBF93' : '#E25144' });
                    isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(selectedVisitorProfile)));
                }
            ));
        }



        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.TRAFFIC_VOLUME;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;
        comInstance.sizeXS = '12';
        comInstance.sizeMD = '10';
        comInstance.offsetMD = '1';
        return comInstance;
    }

}
