import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { LoadingService } from './loading.service';
import { BACKENDV2_URL } from '../configs/api';
import { DynamicGoalCardConfig } from '../objects/config';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Loading } from '../enum/loading';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})

export class GoalDataService {

  goalList$ = new BehaviorSubject<DynamicGoalCardConfig[]>(null);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private loadingService: LoadingService,
    private dataService: DataService
  ) { }

  async loadGoalList() {
    this.loadingService.startLoading(Loading.GOAL_LIST);
    return this.fetchGoalList().then(data => this.deriveGoalList(data)).catch(err => console.log(err, 'loading goal list error'));
  }

  deriveGoalList(goalListDatas: any[]) {
    const parseGoalList: DynamicGoalCardConfig[] = [];
    if (!goalListDatas) { 
      return this.loadingService.stopLoading(Loading.GOAL_LIST); 
    }
    goalListDatas.forEach(goalList => {
      const goal: DynamicGoalCardConfig = {
        titleName: goalList?.title_name,
        dataConfig: goalList?.data_config,
        durationTime: {
          startDate: goalList?.duration_time?.start_date,
          endDate: goalList?.duration_time?.end_date,
          excludeDate: goalList?.duration_time?.exclude_date
        },
        goalSetting: {
          target: goalList?.goal_setting?.target,
          targetList: goalList?.goal_setting?.target_list,
          prediction: goalList?.goal_setting?.prediction,
          showForecast: goalList?.goal_setting?.showForecast,
          minValue: goalList?.goal_setting?.minValue || 1,
          maxValue: goalList?.goal_setting?.maxValue,
          progressBarColor: goalList?.goal_setting?.progressBarColor || '#7C99EB',
          is_mock_data: goalList?.goal_setting?.is_mock_data,
          display_all_time: goalList?.goal_setting?.display_all_time,
          sep_sub_value: goalList?.goal_setting?.sep_sub_value,
        },
        textConfig: {
          areaName: goalList?.text_config?.area_name,
          displayMode: goalList?.text_config?.display_mode,
          unit: goalList?.text_config?.unit,
          decimalPoint: goalList?.text_config?.decimal_point,
          displayLabel: {
            currentValue: goalList?.text_config?.displayLabel?.currentValue !== undefined ? goalList.text_config.displayLabel.currentValue : true,
            trendValue: goalList?.text_config?.displayLabel?.trendValue !== undefined ? goalList.text_config.displayLabel.trendValue : true,
            location: goalList?.text_config?.displayLabel?.location !== undefined ? goalList.text_config.displayLabel.location : true,
          },
        },
        infoPopover: {
          titleName: goalList?.info_popover?.title_name,
          details: goalList?.info_popover?.details
        }
      };
      parseGoalList.push(goal);
    });
    this.goalList$.next(parseGoalList);
    return this.loadingService.stopLoading(Loading.GOAL_LIST);
  }

  async fetchGoalList() {
    const requestURL = '/retail_customer_api_v2/api/v2/goal/goal-list';
    return this.dataService.fetchData(requestURL);
  }

}
