export const CHART_AXIS_TEXT_COLOR = '#e3e3e3';
export const CHART_PRIMARY_COLOR = '#346DF3';
export const CHART_PRIMARY_COLOR_OPACITY = '#346DF322';
export const CHART_SECONDARY_COLOR = '#abbee1';
export const CHART_SECONDARY_COLOR_OPACITY = '#abbee122';
export const CHART_TERTIARY_COLOR = '#809dfd';
export const CHART_TERTIARY_COLOR_OPACITY = '#809dfd22';
export const CHART_QUATERNARY_COLOR = '#6A5ACD';
export const CHART_QUATERNARY_COLOR_OPACITY = '#6A5ACD22';
export const CHART_PREDICT_COLOR = '#aaa';
export const CHART_LIVE_COLOR = '#ff1134';
export const CHART_LIVE_COLOR_OPACITY = '#ff113422';
export const CHART_TOURISTS_COLOR = '#e6bf45';
export const CHART_LOCAL_COLOR = '#dcceaf';
export const CHART_ASIAN_COLOR = '#bde645';
export const CHART_NON_ASIAN_COLOR = '#e6bf45';
export const CHART_CLASS_COLOR = {
  GENDER: {
    male: CHART_PRIMARY_COLOR,
    female: CHART_SECONDARY_COLOR
  }
};
export const PIE_CHART_COLOR_LIST = [
  '#346DF3',
  '#aabde1',
  '#9dc9d4',
  '#195480',
  '#f6bd0b',
  // '#111E6C',
  // '#1D2951',
  // '#95C8D8',
  // '#0E4D92',
  // '#4C516D',
  '#6593F5',
  '#73C2FB',
  '#4682B4',
  '#89CFF0',
  '#000080',
  '#0080FF',
];


export const LINE_CHART_COLOR_LIST = [
  '#DBC954',
  '#E59252',
  '#57B9C4',
  '#58BE65',
  '#1ec94c',
  '#c91ec9',
  '#e31494',
  '#a7db54',
  '#54dbac',
  '#54b5db',
  '#8f54db',
  '#7036cf',
  '#aabde1',
  '#346DF3',
  '#9dc9d4',
  '#195480',
  '#f6bd0b',
  '#111E6C',
  '#1D2951',
  '#95C8D8',
  '#0E4D92',
  '#4C516D',
  '#6593F5',
  '#73C2FB',
  '#4682B4',
  '#89CFF0',
  '#000080',
  '#0080FF',
];
export const DARK_THEME_BASE_COLOR = '#212121';
export const LIGHT_THEME_PRIMARY_COLOR = '#012147';
export const BOTH_THEME_BASE_COLOR = '#424242';
export const THREEJS_HUMAN_WALKER_COLOR_DEFAULT = 0x1a2296;
export const THREEJS_HUMAN_WALKER_COLOR_WHITE = 0xdbdbdb;
