import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CustomerAgeTooltipsComponent } from '../pages/home/general/customer-age-tooltips/customer-age-tooltips.component';
import { showAgeCustomTooltips } from '../helpers/ageTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { accessDepthData, isScreenSmallerThanMD } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class AgeStoreProfileResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here

        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
        }
        let mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'STORE_AGE_PROFILE', 'count', 5);

        // await configDataService.loadAppConfig();
        const chartLabel = configDataService.AGE_CLASS.map(age => configDataService.DISPLAY_LANGUAGE.AGE_CLASS[age]);
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);

        if (isMockData) {
            let selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
            dualBarData$.next([{
                data: mockData?.[selectedInteractableName]?.male || mockData?.default?.male,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                calPercentFrommAllData: true
            }, {
                data: mockData?.[selectedInteractableName]?.female || mockData?.default?.female,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                calPercentFrommAllData: true
            }]);
            subscription.add(combineLatest([graphDataService.storeAgeProfileDataByNumber$,graphDataService.baseGraphData.selectedInteractable$,viewPeriodService.dayList]).subscribe(async ([ageData]) => {
                if (graphDataService.baseGraphData.selectedInteractable$.getValue()?.type !== 'store') {
                    return;
                }
                mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'STORE_AGE_PROFILE', 'count', 5);
                selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
                // const ageData = graphDataService.baseGraphData.selectedInteractable$?.value?.type === 'gate' ? zoneInstanceName ? flattenData : ageDataByPin : ageBuildingData;
                dualBarData$.next([{
                    data: mockData?.[selectedInteractableName]?.male || mockData?.default?.male,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                    calPercentFrommAllData: true
                }, {
                    data: mockData?.[selectedInteractableName]?.female || mockData?.default?.female,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                    calPercentFrommAllData: true
                }]);
            }));
        } else {
            dualBarData$.next([{
                data: graphDataService.storeAgeProfileDataByNumber$.value.female,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                calPercentFrommAllData: true
            }, {
                data: graphDataService.storeAgeProfileDataByNumber$.value.male,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                calPercentFrommAllData: true
            }]);
            subscription.add(combineLatest([graphDataService.storeAgeProfileDataByNumber$,graphDataService.baseGraphData.selectedInteractable$,viewPeriodService.dayList]).subscribe(([ageData]) => {
                const selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
                // const ageData = graphDataService.baseGraphData.selectedInteractable$?.value?.type === 'gate' ? zoneInstanceName ? flattenData : ageDataByPin : ageBuildingData;
                if (!ageData) { return; }
                dualBarData$.next([{
                    data: ageData.female,
                    color: CHART_SECONDARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                    calPercentFrommAllData: true
                }, {
                    data: ageData.male,
                    color: CHART_PRIMARY_COLOR,
                    label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                    calPercentFrommAllData: true
                }]);
            }));
        }

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.AGE_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.infoPopover = async (e: any) => {
            const ageDetailPopover = await popoverController.create({
                component: CustomerAgeTooltipsComponent,
                cssClass: 'calendar-criteria-tooltips',
                event: e
            });
            return ageDetailPopover.present();
        };
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.3;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showAgeCustomTooltips(tooltipModel, this, configDataService.ageImageSrc);
        };
        return comInstance;
    }
}
