import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CustomerAgeTooltipsComponent } from '../pages/home/general/customer-age-tooltips/customer-age-tooltips.component';
import { showAgeCustomTooltips } from '../helpers/ageTooltips';
import { DynamicGraphAdditionalInput, DynamicGraphConfig } from '../objects/config';
import { assertNullUndefined, isScreenSmallerThanMD } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { SelectableCustomDataName } from '../objects/selectableData';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { showEthnicityCustomTooltips } from '../helpers/ethnicityTooltips';
import { CustomerSegmentDetailsComponent } from '../pages/home/general/customer-segment-details/customer-segment-details.component';
import { PurchaseBagTooltipsComponent } from '../shared-components/general/purchase-bag-tooltips/purchase-bag-tooltips.component';
import { generateNestedData } from '../helpers/mock-data-generator';

export class DemographicProfileBarResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        const barChartConfig = (getAdditionalInput(additionalInput, 'dynamicGraphConfig') || {}) as DynamicGraphConfig;
        const includeAreaList = (getAdditionalInput(additionalInput, 'includeAreaList') || []) as string[];
        const useselectedInteractable = (getAdditionalInput(additionalInput, 'useSelectedInteractable') || false) as boolean;
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;

        assertNullUndefined(barChartConfig);
        const chartDataConfig = barChartConfig.graphData;
        const chartOptionsConfig = barChartConfig.graphOptions;

        const demographicDisplayNameKey = { 
            ...configDataService.DISPLAY_LANGUAGE.VISITOR_PROFILE.AGE,
            ...configDataService.DISPLAY_LANGUAGE.VISITOR_PROFILE.ETHNICITY,
            ...configDataService.DISPLAY_LANGUAGE.VISITOR_PROFILE.GENDER,
            ...configDataService.DISPLAY_LANGUAGE.VISITOR_PROFILE.PURCHASE_BEHAVIOR,
            ...configDataService.DISPLAY_LANGUAGE.TOURIST_CLASS,
            local_young_social_strollers: 'Local Young\nShoppers',
            local_adult_shoppers: 'Local Adult\nShoppers',
        };

        // load options here
        const customTooltipName = chartOptionsConfig?.customTooltip?.name;
        const calPercentage: 'calPercentFromLabelProperty' | 'calPercentFrommAllData' | 'calPercentFromIndexArray' = chartOptionsConfig?.calPercentage;
        // load data here
        const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = isMockData ? [] : Object.keys(chartDataConfig).map(key => {
            const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(chartDataConfig[key].name as SelectableCustomDataName, graphDataService);
            graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
            return dataConfigResolved.data;
        });
        let chartLabel = [];
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([]);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        if (isMockData) {
            chartLabel = barChartConfig.graphTooltipLabel.map(label => demographicDisplayNameKey[label] || label).map(label => label.split('\n'));
            subscription.add(combineLatest([graphDataService.baseGraphData.selectedInteractable$, viewPeriodService.dayList, ...chartDataBehaviourSubject$])
            .subscribe(async ([selectedInteractable, dayList, chartDataBehaviour]) => {
                const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, chartDataConfig[0].name, 'count', 5);
                // check if chartData is empty or have null value
                const selectedInteractableName = selectedInteractable?.name;
                if (includeAreaList.length > 0 && useselectedInteractable) {
                    comInstance.isShow = includeAreaList.includes(selectedInteractableName);
                }
                const chartData = mockData[selectedInteractableName] || Object.values(mockData)[0];
                dualBarData$.next(Object.entries(chartData).map(([key, value], idx) => ({
                    data: value as number[],
                    color: PIE_CHART_COLOR_LIST[idx],
                    label: demographicDisplayNameKey[key] || key,
                    calPercentFromLabelProperty: calPercentage === 'calPercentFromLabelProperty',
                    calPercentFrommAllData: calPercentage === 'calPercentFrommAllData',
                    calPercentFromIndexArray: calPercentage === 'calPercentFromIndexArray',
                })));
            }));
        } else {
            chartLabel = barChartConfig.graphTooltipLabel.map(label => demographicDisplayNameKey[label] || label).map(label => label.split('\n'));
            subscription.add(combineLatest(chartDataBehaviourSubject$).subscribe((chartData) => {
                // check if chartData is empty or have null value
                if (chartData.some(data => !data)) { return; }
                const selectedInteractableName = graphDataService.baseGraphData.selectedInteractable$.getValue()?.name;
                if (includeAreaList.length > 0 && useselectedInteractable) {
                    comInstance.isShow = includeAreaList.includes(selectedInteractableName);
                }
                // load data from chartData and update dualBarData$
                const demographicData = chartData.reduce((acc, cur) => {
                    const classData = cur as { [key: string]: number[] };
                    // handle case when data is not object
                    if (typeof cur !== 'object') {
                        return acc;
                    }
                    Object.keys(classData).forEach(key => {
                        acc[key] = acc[key] || [];
                        acc[key].push(...classData[key]);
                    });
                    return acc;
                }, {} as { [key: string]: number[] });
                dualBarData$.next(Object.keys(demographicData).map((key, idx) => ({
                    data: demographicData[key],
                    color: PIE_CHART_COLOR_LIST[idx],
                    label: demographicDisplayNameKey[key] || key,
                    calPercentFromLabelProperty: calPercentage === 'calPercentFromLabelProperty',
                    calPercentFrommAllData: calPercentage === 'calPercentFrommAllData',
                    calPercentFromIndexArray: calPercentage === 'calPercentFromIndexArray',
                })));
            }));
        }


        if (customTooltipName) {
            if (customTooltipName === 'age_detail') {
                comInstance.infoPopover = async (e: any) => {
                    const ageDetailPopover = await popoverController.create({
                        component: CustomerAgeTooltipsComponent,
                        cssClass: 'calendar-criteria-tooltips',
                        event: e
                    });
                    return ageDetailPopover.present();
                };
                comInstance.customToolTipFuction = function(tooltipModel) {
                    showAgeCustomTooltips(tooltipModel, this, configDataService.ageImageSrc);
                };
            } else if (customTooltipName === 'ethnicty_detail') {
                const ethnicityList = configDataService.DISPLAY_LANGUAGE.ETHNICITY_CLASS;
                comInstance.infoPopover = async (e: any) => {
                    const ethnicityDetailPopover = await popoverController.create({
                        component: CustomerSegmentDetailsComponent,
                        cssClass: 'customer-segment-details-popover',
                        event: e
                    });
                    return ethnicityDetailPopover.present();
                };
                comInstance.customToolTipFuction = function(tooltipModel) {
                    showEthnicityCustomTooltips(tooltipModel, this, configDataService.ethnicityImageSrc, ethnicityList);
                };
            } else if (customTooltipName === 'purchase_bag_detail') {
                comInstance.infoPopover = async (e: any) => {
                    const purchaseBagDetailPopover = await popoverController.create({
                        component: PurchaseBagTooltipsComponent,
                        cssClass: 'customer-segment-details-popover',
                        event: e
                    });
                    return purchaseBagDetailPopover.present();
                };
            }
        }
        comInstance.title = configDataService.DISPLAY_LANGUAGE[barChartConfig.graphName] || barChartConfig.graphName;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.4;
        return comInstance;
    }

}
