import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Subscription } from 'rxjs';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData, isShowGraph, numberFormatter } from '../helpers/util';
import { DynamicGraphAdditionalInput, DynamicNumberCardConfig, GraphDataConfig } from '../objects/config';
import { SelectableNumberCardDataName } from '../objects/selectableData';
import { AreaInteractable, CampaignInteractable, GateInteractable, StoreInteractable } from '../objects/trafficInteractable';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { CustomerMaskTooltipsComponent } from '../pages/home/general/customer-mask-tooltips/customer-mask-tooltips.component';
import { CustomerMessengerTooltipsComponent } from '../pages/home/general/customer-messenger-tooltips/customer-messenger-tooltips.component';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { DynamicDualNumberCardComponent } from '../shared-components/dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { CustomTooltipPopoverComponent } from '../shared-components/general/custom-tooltip-popover/custom-tooltip-popover.component';
import { PurchaseBagTooltipsComponent } from '../shared-components/general/purchase-bag-tooltips/purchase-bag-tooltips.component';
import { GraphResolverBase } from './GraphResolverBase';
import { generateNestedData } from '../helpers/mock-data-generator';
import { processChartData } from '../helpers/chartUtils';

export class CustomNumberCardResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService,
    ) {
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicDualNumberCardComponent;
        const numberCardConfigs = (getAdditionalInput(additionalInput, 'dynamicConfig') || {}) as DynamicNumberCardConfig[];
        const graphState = (getAdditionalInput(additionalInput, 'graphState') || 'ENABLE') as 'ENABLE' | 'LOCK' | 'LOCK_COND';
        const buildingInstanceName = (getAdditionalInput(additionalInput, 'building')) as string;
        const floorInstanceName = (getAdditionalInput(additionalInput, 'floor')) as string;
        const zoneInstanceName = (getAdditionalInput(additionalInput, 'zone')) as string;
        const includeAreaList = (getAdditionalInput(additionalInput, 'includeAreaList') || []) as string[];
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';
        const useselectedDirectory = (getAdditionalInput(additionalInput, 'useSelectedDirectory') || false) as boolean;
        const useselectedInteractable = (getAdditionalInput(additionalInput, 'useSelectedInteractable') || false) as boolean;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const usingMappingStoreKey = (getAdditionalInput(additionalInput, 'usingMappingStoreKey') || false) as boolean;
        const mappingStoreKey = (getAdditionalInput(additionalInput, 'mappingStoreKey')) as { [key: string]: string[] };
        const dynamicGraphDataSelector = (getAdditionalInput(additionalInput, 'dynamicGraphDataSelector') || {}) as { [selectorName: string]: GraphDataConfig };
        const useSelector = (getAdditionalInput(additionalInput, 'useSelector') || false) as boolean;
        const showAveragePerDay = (getAdditionalInput(additionalInput, 'showAveragePerDay') || false) as boolean;
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;

        if (numberCardConfigs.length < 1) {
            throw new Error('Number Card Config must not empty');
        }

        const parseUnit = (value: number, unit: 'hour' | 'min' | 'percent' | 'default' | 'decimal' | 'si' | 'spots', decimalPoint: number = 0, calculateHour?: boolean,) => {
            if (unit === 'default') {
                if (value >= 0 && value < 1) {
                    return '<1';
                }
            }
            if (value === undefined || value === null) {
                return 'N/A';
            }
            if (unit === 'percent') {
                if (isNaN(value)) {
                    return 'N/A';
                }
                if (value > -1 && value < 1) {
                    return '<1%';
                }
                return value.toFixed(decimalPoint) + '%';
            }
            else if (unit === 'min') {
                if (value < 60) {
                    return '<1 mins';
                }
                return (value / 60).toFixed(0) + ' mins';
            }
            else if (unit === 'hour') {
                if (calculateHour) {
                    return Math.round(value / 3600).toLocaleString() + ' hrs';
                }
                return value.toLocaleString() + ' hrs';
            }
            else if (unit === 'decimal') {
                return value.toFixed(decimalPoint);
            }
            else if (unit === 'si') {
                return numberFormatter(value, 1);
            }
            else if (unit === 'spots') {
                return value + ' spots';
            }
            return value.toLocaleString();
        };

        const parseUnitDiff = (value: number, unit: 'hour' | 'min' | 'percent' | 'default' | 'decimal' | 'si', decimalPoint?: number, calculateHour?: boolean) => {
            if (unit === 'default') {
                if (value >= 0 && value < 1) {
                    return value;
                }
            }
            if (value === undefined || value === null) {
                return 'N/A';
            }
            if (unit === 'percent') {
                if (isNaN(value)) {
                    return 'N/A';
                }
                if (value > -1 || value < 1) {
                    return '<1%';
                }
                return value.toFixed(1) + '%';
            }
            else if (unit === 'min') {
                const minuteVal = (value / 60);
                return minuteVal.toFixed(0);
            }
            else if (unit === 'hour') {
                if (calculateHour) {
                    return Math.round(value / 3600).toLocaleString() + ' hrs';
                }
                return value.toLocaleString();
            }
            else if (unit === 'decimal') {
                if (!decimalPoint) {
                    return value.toFixed(0);
                }
                return value.toFixed(decimalPoint);
            }
            else if (unit === 'si') {
                return numberFormatter(value, 1);
            }
            return value.toLocaleString();
        };

        type DepthData = {
            [name: string]: number;
        } & {
            _total: number;
        };
        type EntraceExitData = { entrance: DepthData; exit: DepthData };

        const getEntraceExitData = (isEntrance: boolean, data: { entrance: DepthData; exit: DepthData }) => isEntrance ? data.entrance : data.exit;

        const getDepthData = (
            isEntrance: boolean,
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
            gateName?: string
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined && gateName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0, gateName);
            }
            if (retData instanceof Object && Object.keys(retData).includes('entrance')) {
                retData = getEntraceExitData(isEntrance, retData as EntraceExitData);
            }
            return retData;
        };

        const areaInstanceName: string[] = [buildingInstanceName, floorInstanceName, zoneInstanceName];
        const dataConfigFromInstance: string[] = areaInstanceName.filter(d => d !== undefined);
        const filledCard = [];
        const selectedDataSource$ = new BehaviorSubject<{ [selectorName: string]: GraphDataConfig }>(dynamicGraphDataSelector);
        if (configDataService.isFeatureEnabled('reid_timespent')) {
            graphDataService.baseGraphData.addDependency(GraphDependency.BUILDING_TIMESPENT);
            graphDataService.baseGraphData.addDependency(GraphDependency.ENTRANCE_EXIT);
        }
        numberCardConfigs.forEach(cardConfig => {
            const dataConfig = cardConfig.dataConfig;
            const textConfig = cardConfig.textConfig;
            const displayText = cardConfig.textConfig.displayMode || 'default';
            const decimalPoint = cardConfig.textConfig?.decimalPoint;
            const initParseNumberData = displayText === 'range' ? 'N/A - N/A' : decimalPoint ? parseUnit(0, textConfig.unit, decimalPoint, textConfig.calculateHour) : parseUnit(0, textConfig.unit, undefined, textConfig.calculateHour);

            const chartDataBehaviourSubject$: BehaviorSubject<unknown>[] = isMockData ? [] : Object.keys(dataConfig).map(key => {
                const dataConfigResolved = graphDataService.baseGraphData.getSelectedGraph(dataConfig[key].name as SelectableNumberCardDataName, graphDataService);
                graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
                return dataConfigResolved.data;
            });
            const isReidTimespent = cardConfig?.options?.reid_timespent_data || dataConfig[0]?.options?.reid_timespent_data || false;
            const containEntranceMode = cardConfig?.options?.containEntranceMode || dataConfig[0]?.options?.containEntranceMode || false;
            // const dataConfigResolved = graphDataService.getSelectedGraph(dataConfig.name as SelectableNumberCardDataName);
            // graphDataService.baseGraphData.addDependency(dataConfigResolved.dependencies);
            const dataBehaviourSubject$: BehaviorSubject<unknown>[] = textConfig.notShowLiveMode ?
                isReidTimespent ? [viewPeriodService.isLiveMode$, graphDataService.buildingEntranceExitPairData$, graphDataService.avgTimespentTimePairData$]
                    : useselectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, viewPeriodService.isLiveMode$, ...chartDataBehaviourSubject$]
                        : [viewPeriodService.isLiveMode$, ...chartDataBehaviourSubject$]
                : containEntranceMode ? useselectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, configDataService.isEntranceDataMode$, ...chartDataBehaviourSubject$]
                    : [configDataService.isEntranceDataMode$, ...chartDataBehaviourSubject$]
                    : useselectedDirectory ? [graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, ...chartDataBehaviourSubject$]
                        : useselectedInteractable ? [graphDataService.baseGraphData.selectedInteractable$, ...chartDataBehaviourSubject$]
                            : useSelector ? [selectedDataSource$, ...chartDataBehaviourSubject$]
                                : chartDataBehaviourSubject$;
            const thisNumberData$ = new BehaviorSubject(initParseNumberData);
            const thisLabel$ = new BehaviorSubject(textConfig.displayOnlyBigValue || displayText === 'range' ? null : '0 % (0)');
            const thisLabelHeaderDiff$ = new BehaviorSubject(null);
            const thisLabelIcon$ = new BehaviorSubject(textConfig.displayOnlyBigValue || displayText === 'range' ? null : 'trending-up');
            const thisLabelStyle$ = new BehaviorSubject({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
            const titleNameConfig = configDataService.DISPLAY_LANGUAGE[cardConfig.titleName] || cardConfig.titleName;
            const thisTitle$ = new BehaviorSubject<string>(titleNameConfig);

            const initNumberCard = {
                isShow: isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization),
                iconStyle: 'fontSize: 22px',
                isLock: graphState === 'LOCK',
                title: thisTitle$,
                numberData$: thisNumberData$,
                label$: thisLabel$,
                labelIcon$: thisLabelIcon$,
                labelStyle$: thisLabelStyle$,
                sizeXS: cardConfig.layoutConfig?.sizeBreakpoint?.xs || 12,
                sizeLG: cardConfig.layoutConfig.sizeBreakpoint.lg || 6,
                isFirst: cardConfig.layoutConfig.isFirst,
                height: cardConfig.layoutConfig.height,
                isShowInfoIcon: cardConfig?.infoPopover,
                infoPopover: async (e: any) => {
                    const infoPopoverText = cardConfig?.infoPopover;
                    if (infoPopoverText) {
                        const customPopover = await popoverController.create({
                            component: cardConfig?.infoPopover.useCustom ?
                                cardConfig?.infoPopover?.customPopover?.componentName === 'custom' ? CustomTooltipPopoverComponent :
                                    cardConfig?.infoPopover?.customPopover?.componentName === 'maskUse' ? CustomerMaskTooltipsComponent :
                                        cardConfig?.infoPopover?.customPopover?.componentName === 'messenger' ? CustomerMessengerTooltipsComponent :
                                            PurchaseBagTooltipsComponent
                                : CustomTextTooltipsComponent,
                            componentProps: {
                                toolTipTitle: textConfig.containWeektype ? thisTitle$ : configDataService.DISPLAY_LANGUAGE[infoPopoverText.titleName] || infoPopoverText.titleName,
                                toolTipDetails: infoPopoverText.details,
                                classList: cardConfig?.infoPopover.useCustom ? cardConfig?.infoPopover.customPopover?.classList : [],
                                classType: cardConfig?.infoPopover.useCustom ? cardConfig?.infoPopover.customPopover?.classType : '',
                            },
                            cssClass: 'customer-segment-details-popover',
                            event: e,
                        });
                        return await customPopover.present();
                    }

                },
                labelHeaderDiff$: thisLabelHeaderDiff$
            };

            if (isMockData) {
                subscription.add(combineLatest([viewPeriodService.dayList, graphDataService.baseGraphData.selectedInteractable$, ...dataBehaviourSubject$]).subscribe(async ([dayList, selectedInteractable, combineResult]) => {
                    const mockChartDataList: Array<{ [key: string]: any }> = [];
                    const selectedInteractableName = selectedInteractable?.name;
                    const num_interval = displayText === 'time' ? configDataService.TIME_LIST.length : 2;
                    const isStaticFactor = textConfig.unit === 'percent' || textConfig.unit === 'min';
                    if (dataConfig instanceof Array) {
                        for (const dc of dataConfig) {
                            const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, dc.name, 'count', num_interval, false, isStaticFactor);
                            mockChartDataList.push(mockChartDataValue);
                        }
                    } else {
                        Object.keys(dataConfig).map(async key => {
                            const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, dataConfig[key].name, 'count', num_interval, false, isStaticFactor);
                            mockChartDataList.push(mockChartDataValue);
                        });
                    }
                    const mockChartDataListFiltered = selectedInteractableName ? [mockChartDataList[0]?.[selectedInteractableName] || mockChartDataList[0]?.default] : mockChartDataList;
                    const rawDatas: { [key: string]: number }[] = mockChartDataListFiltered.reduce((acc, cur) => {
                        if (displayText === 'time') {
                            // find max value of object
                            const trafficData = Object.values(cur)[0] as number[];
                            const current = trafficData.reduce((max: number, cur_val: number) => cur_val > max ? cur_val : max, 0);
                            const findIndex = trafficData.findIndex((val: number) => val === current);
                            const lowerTimeMapped = configDataService.TIME_LIST[findIndex];
                            const upperTimeMapped = configDataService.TIME_LIST[findIndex + 1];
                            const timeDisplay = `${new Date(2020, 1, 1, parseInt(lowerTimeMapped, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })} - ${new Date(2020, 1, 1, parseInt(upperTimeMapped, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' })}`;
                            acc.push({ timeDisplay, current });
                        } else {
                            const currentData = Object.values(cur)[0];
                            const current = currentData[0];
                            const diff = processChartData(currentData[1] - currentData[0], true, false);
                            const diffPercent = processChartData((diff / currentData[0]) * 100, true, true);
                            acc.push({ current, diff, diffPercent });
                        }
                        return acc;
                    }, []);
                    const openMode = (textConfig?.notShowLiveMode || cardConfig?.options?.containEntranceMode ? combineResult[0] : false) as boolean;
                    const isEntrance = cardConfig?.options?.containEntranceMode || dataConfig[0]?.options?.containEntranceMode ? combineResult[0] as boolean : true;
                    // const isReidTimespent = cardConfig?.options?.reid_timespent_data || dataConfig[0]?.options?.reid_timespent_data;
                    if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
                        initNumberCard.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                    }
                    let parseData: number[] = [];
                    if (displayText === 'range') {
                        let parseTimeData: any[] = [];
                        rawDatas.forEach(rawData => {
                            const data = useselectedInteractable ? getDepthData(isEntrance, rawData, selectedInteractableName) : getDepthData(isEntrance, rawData, dataConfig[0].args[0], dataConfig[0].args[1], dataConfig[0].args[2]);
                            parseTimeData = Object.values(data);
                        });
                        thisNumberData$.next(`${parseUnit(parseTimeData[0], textConfig.unit, undefined, textConfig.calculateHour)} - ${parseUnit(parseTimeData[1], textConfig.unit, undefined, textConfig.calculateHour)}`);
                        thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
                    }
                    else if (displayText === 'time') {
                        let parseTimeData: any[] = [];
                        rawDatas.forEach(rawData => {
                            const data = getDepthData(isEntrance, rawData, dataConfig[0].args[0], dataConfig[0].args[1], dataConfig[0].args[2]);
                            parseTimeData = Object.values(data);
                        });
                        thisLabel$.next(!parseTimeData[1] ? '0' : parseUnit((parseTimeData[1] as number), textConfig.unit, undefined, textConfig.calculateHour));
                        const displayTime = parseTimeData[0] === 'N/A' ? 'N/A' : (parseTimeData[0] as string).includes('-') ? parseTimeData[0] : new Date(2020, 1, 1, parseInt((parseTimeData[0] as string), 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
                        thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : displayTime);
                        thisLabelIcon$.next('people');
                        thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
                    }
                    //TO-DO: FIXED MANAGE DATA OF AVG. WEEKDAY / AVG. WEEKEND
                    else if (textConfig?.containWeektype) {
                        viewPeriodService.subscribeSelectedDate(_date => {
                            if (configDataService.currentOrganization === 'GLOBAL') {
                                thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig}` : `Avg. Weekend ${titleNameConfig}`);
                            }
                            else if (viewPeriodService.isDayPeriod || viewPeriodService.isLiveMode) {
                                if (configDataService.currentOrganization === 'PLANB') {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? 'Avg. Weekday Eyeball' : 'Avg. Weekend Eyeball');
                                } else {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig} (Last 7 Days)` : `Avg. Weekend ${titleNameConfig} (Last 7 Days)`);
                                }
                            }
                            else if (viewPeriodService.isWeekPeriod || viewPeriodService.isMonthPeriod) {
                                if (configDataService.currentOrganization === 'PLANB') {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? 'Avg. Weekday Eyeball' : 'Avg. Weekend Eyeball');
                                } else {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig}` : `Avg. Weekend ${titleNameConfig}`);
                                }
                            }
                        });
                        if (rawDatas[0] instanceof BehaviorSubject) {
                            rawDatas[0].subscribe(data => {
                                if (!data || Object.keys(data).length === 0) {
                                    return;
                                }
                                parseData = Object.values(getDepthData(isEntrance, data, dataConfig[0].args[0], dataConfig[0].args[1], dataConfig[0].args[2]));
                                // TODO: condition doesn't make sense
                                thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                                thisLabel$.next(textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                                thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 ? 'trending-up' : 'trending-down');
                                thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] >= 0 ? '#5BBF93' : '#E25144') });
                            });
                        } else {
                            const dataArgsConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : dataConfig[0].args;
                            rawDatas.forEach(rawData => {
                                const data = getDepthData(isEntrance, rawData, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                parseData = Object.values(data);
                            });
                            thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                            thisLabel$.next(textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                            thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 ? 'trending-up' : 'trending-down');
                            thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] >= 0 ? '#5BBF93' : '#E25144') });
                        };
                    }
                    else {
                        if (selectedInteractableName) {
                            const rawDatasSelectedInteractableName = rawDatas[selectedInteractableName] ? Object.values(rawDatas[selectedInteractableName]) : rawDatas;
                            const dataArgsConfig = usingMappingStoreKey ?
                                Object.keys(mappingStoreKey).find(k => k === selectedInteractableName.toLocaleLowerCase()) !== undefined
                                    ? mappingStoreKey[selectedInteractableName.toLocaleLowerCase()] : []
                                : dataConfigFromInstance.length > 0 ? dataConfigFromInstance : dataConfig[0].args;
                            rawDatasSelectedInteractableName.forEach(rawData => {
                                const data = getDepthData(isEntrance, rawData, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                parseData = Object.values(data[selectedInteractableName] ? Object.values(data[selectedInteractableName]) : data);
                            });
                        }
                        else {
                            const dataArgsConfig = usingMappingStoreKey ?
                                Object.keys(mappingStoreKey).find(k => k === selectedInteractableName.toLocaleLowerCase()) !== undefined
                                    ? mappingStoreKey[selectedInteractableName.toLocaleLowerCase()] : []
                                : dataConfigFromInstance.length > 0 ? dataConfigFromInstance : dataConfig[0].args;
                            rawDatas.forEach(rawData => {
                                if ((rawData as any).mbk_parking) {
                                    const data = getDepthData(isEntrance, (rawData as any).mbk_parking, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                    parseData = Object.values(data);
                                } else {
                                    const data = getDepthData(isEntrance, rawData, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                    parseData = Object.values(data);
                                }
                            });
                        }
                        if (!textConfig.displayOnlyBigValue) {
                            thisLabel$.next(textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                            thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 && parseData[2] >= 0 ? 'trending-up' : 'trending-down');
                            thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] > 0 && parseData[2] >= 0 ? '#5BBF93' : '#E25144') });
                        }
                        thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                    }
                }));
            } else {
                subscription.add(combineLatest(dataBehaviourSubject$).subscribe((combineResult) => {
                    const rawDatas = useselectedInteractable ? combineResult.slice(1) : useselectedDirectory ? combineResult.slice(2) : textConfig?.notShowLiveMode || cardConfig?.options?.containEntranceMode ? combineResult.slice(1) : combineResult;
                    const openMode = (textConfig?.notShowLiveMode || cardConfig?.options?.containEntranceMode ? combineResult[0] : false) as boolean;
                    const isEntrance = cardConfig?.options?.containEntranceMode || dataConfig[0]?.options?.containEntranceMode ? combineResult[0] as boolean : true;
                    // const isReidTimespent = cardConfig?.options?.reid_timespent_data || dataConfig[0]?.options?.reid_timespent_data;
                    let selectedInteractableName: string;
                    if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
                        initNumberCard.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                    }
                    if (useselectedInteractable) {
                        const selectedInteractable = combineResult[0] as AreaInteractable | GateInteractable | StoreInteractable | CampaignInteractable;
                        if (selectedInteractable) {
                            selectedInteractableName = selectedInteractable.name;
                        }
                    }
                    graphDataService.baseGraphData.selectedInteractable$.subscribe(selectedInteractable => {
                        if (selectedInteractable) {
                            selectedInteractableName = selectedInteractable.name;
                            if (includeAreaList.length > 0) {
                                initNumberCard.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && includeAreaList.includes(selectedInteractableName);
                                // initNumberCard.isShow = false;
                            }
                        }
                    });
                    combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$]).subscribe(([selectedDirectory, selectedLevel]) => {
                        if (excludedDirectory !== undefined) {
                            if (excludedDirectory === 'BUILDING') {
                                initNumberCard.isShow = selectedDirectory?.floor === 'ALL';
                            }
                            else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                                if (selectedDirectory?.floor === 'ALL' && excludedDirectory === 'FLOOR') {
                                    initNumberCard.isShow = true;
                                } else {
                                    initNumberCard.isShow = selectedLevel !== excludedDirectory;
                                }
                            }
                            // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                            else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') < excludedDirectory.length - 1) {
                                const excludeList = excludedDirectory.split('_AND_');
                                if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                                    initNumberCard.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                                }
                                else if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                                    initNumberCard.isShow = true;
                                } else {
                                    initNumberCard.isShow = !excludeList.includes(selectedLevel);
                                }
                            }
                        }
                        else if (includeAreaList.length > 0) {
                            initNumberCard.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && includeAreaList.includes(selectedDirectory?.zone);
                        }
                    });
                    let parseData: number[] = [];
                    if (useselectedDirectory) {
                        const selectedLevel = combineResult[1] as 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA';
                        const selectDirectory = combineResult[0] as { building: string; floor: string; zone: string };
                        const rawDirectoryDatas = useselectedDirectory && (textConfig?.notShowLiveMode || cardConfig?.options?.containEntranceMode) ? combineResult.slice(3) : combineResult.slice(2);
                        const openDirectoryMode = (textConfig?.notShowLiveMode || cardConfig?.options?.containEntranceMode ? combineResult[2] : false) as boolean;
                        const isEntranceDirectory = cardConfig?.options?.containEntranceMode || dataConfig[3]?.options?.containEntranceMode ? combineResult[0] as boolean : true;
                        const selectedData = selectDirectory?.floor === 'ALL' ? rawDirectoryDatas[0] : selectedLevel === 'FLOOR' ? rawDirectoryDatas[1] : rawDirectoryDatas[2];
                        if (useselectedInteractable) {
                            const data = selectedLevel === 'ZONE' ? getDepthData(isEntranceDirectory, selectedData)
                                : getDepthData(isEntranceDirectory, selectedData, selectDirectory?.building, selectDirectory?.floor === 'ALL' ? undefined : selectDirectory?.floor, selectDirectory?.zone, selectedInteractableName);
                            parseData = data ? Object.values(data) : [0, 0, 0];
                        } else {
                            const data = selectedLevel === 'ZONE' ? getDepthData(isEntranceDirectory, selectedData, selectDirectory?.zone)
                                : getDepthData(isEntranceDirectory, selectedData, selectDirectory?.building, selectDirectory?.floor === 'ALL' ? undefined : selectDirectory?.floor, selectDirectory?.zone);
                            parseData = Object.values(data);
                        }
                        if (textConfig.containWeektype) {
                            // TODO: condition doesn't make sense
                            if (configDataService.currentOrganization === 'GLOBAL') {
                                thisTitle$.next(cardConfig.options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig}` : `Avg. Weekend ${titleNameConfig}`);
                            }
                            else if (viewPeriodService.isDayPeriod || viewPeriodService.isLiveMode) {
                                if (configDataService.currentOrganization === 'PLANB') {
                                    thisTitle$.next(cardConfig.options.weekType === 'weekday' ? 'Avg. Weekday Eyeball' : 'Avg. Weekend Eyeball');
                                } else {
                                    thisTitle$.next(cardConfig.options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig} (Last 7 Days)` : `Avg. Weekend ${titleNameConfig} (Last 7 Days)`);
                                }
                            } else if (viewPeriodService.isWeekPeriod || viewPeriodService.isMonthPeriod) {
                                if (configDataService.currentOrganization === 'PLANB') {
                                    thisTitle$.next(cardConfig.options.weekType === 'weekday' ? 'Avg. Weekday Eyeball' : 'Avg. Weekend Eyeball');
                                } else {
                                    thisTitle$.next(cardConfig.options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig}` : `Avg. Weekend ${titleNameConfig}`);
                                }
                            }
                        }
                        if (displayText === 'time') {
                            thisNumberData$.next(textConfig?.notShowLiveMode && openDirectoryMode ? `calculating...` : parseData[1].toLocaleString());
                            thisLabel$.next(!parseData[0] ? '0' : (parseData[0] as number).toLocaleString());
                            thisLabelIcon$.next('people');
                            thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
                        } else {
                            thisNumberData$.next(textConfig?.notShowLiveMode && openDirectoryMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                            thisLabel$.next(textConfig?.notShowLiveMode && openDirectoryMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                            thisLabelIcon$.next(textConfig?.notShowLiveMode && openDirectoryMode ? 'trending-up' : parseData[1] > 0 && parseData[2] >= 0 ? 'trending-up' : 'trending-down');
                            thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openDirectoryMode ? '#5BBF93' : (parseData[1] > 0 && parseData[2] >= 0 ? '#5BBF93' : '#E25144') });
                        }
                    }
                    else if (displayText === 'range') {
                        let parseTimeData: any[] = [];
                        if (rawDatas.some(val => !val)) {
                            return;
                        }
                        rawDatas.forEach(rawData => {
                            const data = useselectedInteractable ? getDepthData(isEntrance, rawData, selectedInteractableName) : getDepthData(isEntrance, rawData, dataConfig[0].args[0], dataConfig[0].args[1], dataConfig[0].args[2]);
                            parseTimeData = Object.values(data);
                        });
                        thisNumberData$.next(`${parseUnit(parseTimeData[0], textConfig.unit, undefined, textConfig.calculateHour)} - ${parseUnit(parseTimeData[1], textConfig.unit, undefined, textConfig.calculateHour)}`);
                        // thisLabel$.next(!parseTimeData[0] ? '0' : (parseTimeData[0] as number).toLocaleString());
                        // thisLabelIcon$.next('people');
                        thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
                    }
                    else if (displayText === 'time') {
                        let parseTimeData: any[] = [];
                        if (rawDatas.some(val => !val)) {
                            return;
                        }
                        if (useSelector) {
                            const depthData = rawDatas[1];
                            const buildingEntranceByHour = comInstance.showAverage ? graphDataService.buildingEntranceByHourAvgPerDay$.getValue() : graphDataService.buildingEntranceByHour$.getValue();
                            if (!buildingEntranceByHour) {
                                return;
                            }
                            const [graphKey, graphDataConfig] = Object.entries(rawDatas[0]).find(([key, graphData]) => graphData.selected) || [];
                            const totalTraffic = buildingEntranceByHour[graphDataConfig.args[0]].reduce((a, b) => a + b, 0);
                            const data = getDepthData(isEntrance, depthData, graphDataConfig.args[0], graphDataConfig.args[1], graphDataConfig.args[2]);
                            const percentageOfTotal = Math.round((Object.values(data)[0] / totalTraffic) * 100);
                            parseTimeData = [Object.values(data)[1], Object.values(data)[0]];
                            const trafficDisplayText = `${parseUnit((parseTimeData[1] as number), textConfig.unit, undefined, textConfig.calculateHour)} (${parseUnit(percentageOfTotal, 'percent', 0)} of Total Traffic)`;
                            if (!textConfig.displayOnlyBigValue) {
                                thisLabel$.next(trafficDisplayText);
                            }
                            // const displayTime = parseTimeData[0] === 'N/A' ? 'N/A' : (parseTimeData[0] as string).includes('-') ? parseTimeData[0] : new Date(2020, 1, 1, parseInt((parseTimeData[0] as string), 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
                            const displayTime = parseTimeData[0] === 'N/A' ? 'N/A' : parseTimeData[0];
                            thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : displayTime);
                        } else {
                            rawDatas.forEach(rawData => {
                                const data = getDepthData(isEntrance, rawData, dataConfig[0].args[0], dataConfig[0].args[1], dataConfig[0].args[2]);
                                parseTimeData = Object.values(data);
                            });
                            thisLabel$.next(!parseTimeData[1] ? '0' : parseUnit((parseTimeData[1] as number), textConfig.unit, undefined, textConfig.calculateHour));
                            const displayTime = parseTimeData[0] === 'N/A' ? 'N/A' : (parseTimeData[0] as string).includes('-') ? parseTimeData[0] : new Date(2020, 1, 1, parseInt((parseTimeData[0] as string), 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
                            thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : displayTime);
                        }
                        thisLabelIcon$.next('people');
                        thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: '#5BBF93' });
                    }
                    //TO-DO: FIXED MANAGE DATA OF AVG. WEEKDAY / AVG. WEEKEND
                    else if (textConfig?.containWeektype) {
                        viewPeriodService.subscribeSelectedDate(_date => {
                            if (configDataService.currentOrganization === 'GLOBAL') {
                                thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig}` : `Avg. Weekend ${titleNameConfig}`);
                            }
                            else if (viewPeriodService.isDayPeriod || viewPeriodService.isLiveMode) {
                                if (configDataService.currentOrganization === 'PLANB') {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? 'Avg. Weekday Eyeball' : 'Avg. Weekend Eyeball');
                                } else {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig} (Last 7 Days)` : `Avg. Weekend ${titleNameConfig} (Last 7 Days)`);
                                }
                            }
                            else if (viewPeriodService.isWeekPeriod || viewPeriodService.isMonthPeriod) {
                                if (configDataService.currentOrganization === 'PLANB') {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? 'Avg. Weekday Eyeball' : 'Avg. Weekend Eyeball');
                                } else {
                                    thisTitle$.next(dataConfig[0].options.weekType === 'weekday' ? `Avg. Weekday ${titleNameConfig}` : `Avg. Weekend ${titleNameConfig}`);
                                }
                            }
                        });
                        if (rawDatas[0] instanceof BehaviorSubject) {
                            rawDatas[0].subscribe(data => {
                                if (!data || Object.keys(data).length === 0) {
                                    return;
                                }
                                parseData = Object.values(getDepthData(isEntrance, data, dataConfig[0].args[0], dataConfig[0].args[1], dataConfig[0].args[2]));
                                // TODO: condition doesn't make sense
                                thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                                thisLabel$.next(textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                                thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 ? 'trending-up' : 'trending-down');
                                thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] >= 0 ? '#5BBF93' : '#E25144') });
                            });
                        } else {
                            if (rawDatas.some(val => !val)) {
                                return;
                            }
                            const dataArgsConfig = dataConfigFromInstance.length > 0 ? dataConfigFromInstance : dataConfig[0].args;
                            rawDatas.forEach(rawData => {
                                const data = getDepthData(isEntrance, rawData, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                parseData = Object.values(data);
                            });
                            thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                            thisLabel$.next(textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                            thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 ? 'trending-up' : 'trending-down');
                            thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] >= 0 ? '#5BBF93' : '#E25144') });
                        };
                    }
                    else if (isReidTimespent) {
                        if (rawDatas.some(val => !val)) {
                            return;
                        }
                        const buildingEntranceExitData = getDepthData(isEntrance, rawDatas[0], configDataService.MAIN_BUILDING) as number[];
                        const avgTimespentTimePairData = rawDatas[1] as number[];
                        const buildingNetShoppingData = buildingEntranceExitData.map((d, i) => (((avgTimespentTimePairData[i] / 60) * d) / 60));
                        parseData = [Math.round(buildingNetShoppingData[1]), Math.round(buildingNetShoppingData[1] - buildingNetShoppingData[0]), ((buildingNetShoppingData[1] - buildingNetShoppingData[0]) / buildingEntranceExitData[0]) * 100];
                        thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                        thisLabel$.next(textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                        thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 && parseData[2] >= 0 ? 'trending-up' : 'trending-down');
                        thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] > 0 && parseData[2] >= 0 ? '#5BBF93' : '#E25144') });

                    }
                    else {
                        if (rawDatas.some(val => !val)) {
                            return;
                        }
                        if (useSelector) {
                            if (cardConfig?.options?.displayChangeLastWeek) {
                                subscription.add(graphDataService.currentBuildingHeadCountLastWeekData$.subscribe(currentBuildingHeadCountLastWeekData => {
                                    if (currentBuildingHeadCountLastWeekData) {
                                        const depthData = cardConfig?.options?.displayChangeLastWeek && (viewPeriodService.isDayPeriod || viewPeriodService.isLiveMode) ? currentBuildingHeadCountLastWeekData : rawDatas[1];
                                        const [graphKey, graphDataConfig] = Object.entries(rawDatas[0]).find(([key, graphData]) => graphData.selected) || [];
                                        const data = getDepthData(true, depthData, graphDataConfig.args[0], graphDataConfig.args[1], graphDataConfig.args[2]);
                                        parseData = Object.values(data);
                                        let labelDiff: string;
                                        let labelHeaderDiff: string;
                                        if (textConfig.labelDiff) {
                                            if (viewPeriodService.isLiveMode && textConfig.labelDiff.display.live) {
                                                if (textConfig.labelDiff.overrideText.live) {
                                                    labelDiff = textConfig.labelDiff.overrideText.live
                                                        ? textConfig.labelDiff.overrideText.live
                                                        : textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`;

                                                }
                                                if (textConfig.labelDiff.description.live) {
                                                    labelHeaderDiff = textConfig.labelDiff.description.live;
                                                }
                                            }
                                            else {
                                                const viewPeriod = viewPeriodService.viewPeriod;
                                                if (textConfig.labelDiff.display[viewPeriod.backendName]) {
                                                    labelDiff = textConfig.labelDiff.overrideText[viewPeriod.backendName]
                                                        ? textConfig.labelDiff.overrideText[viewPeriod.backendName]
                                                        : textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`;
                                                    if (textConfig.labelDiff.description[viewPeriod.backendName]) {
                                                        labelHeaderDiff = textConfig.labelDiff.description[viewPeriod.backendName];
                                                    }
                                                }
                                            }
                                        } else {
                                            labelDiff = textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`;
                                        }
                                        if (textConfig.displayOnlyBigValue) {
                                            thisLabelHeaderDiff$.next(null);
                                            thisLabel$.next(null);
                                            thisLabelIcon$.next(null);
                                        } else {
                                            thisLabelHeaderDiff$.next(labelHeaderDiff);
                                            thisLabel$.next(labelDiff);
                                            thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 && parseData[2] >= 0 ? 'trending-up' : 'trending-down');
                                        }
                                        thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                                        thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] > 0 && parseData[2] >= 0 ? '#5BBF93' : '#E25144') });
                                    }
                                }));
                            } else {
                                let labelDiff: string;
                                let labelHeaderDiff: string;
                                viewPeriodService.subscribeSelectedDate(selectedDate => {
                                    if (textConfig.labelDiff) {
                                        if (viewPeriodService.isLiveMode && textConfig.labelDiff.display.live) {
                                            if (textConfig.labelDiff.overrideText.live) {
                                                labelDiff = textConfig.labelDiff.overrideText.live
                                                    ? textConfig.labelDiff.overrideText.live
                                                    : textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`;

                                            }
                                            if (textConfig.labelDiff.description.live) {
                                                labelHeaderDiff = textConfig.labelDiff.description.live;
                                            }
                                        }
                                        else {
                                            const viewPeriod = selectedDate.periodType;
                                            if (textConfig.labelDiff.display[viewPeriod.backendName]) {
                                                labelDiff = textConfig.labelDiff.overrideText[viewPeriod.backendName]
                                                    ? textConfig.labelDiff.overrideText[viewPeriod.backendName]
                                                    : textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`;
                                                if (textConfig.labelDiff.description[viewPeriod.backendName]) {
                                                    labelHeaderDiff = textConfig.labelDiff.description[viewPeriod.backendName];
                                                }
                                            }
                                        }
                                    } else {
                                        labelDiff = textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`;
                                    }
                                    if (textConfig.displayOnlyBigValue) {
                                        thisLabelHeaderDiff$.next(null);
                                        thisLabel$.next(null);
                                        thisLabelIcon$.next(null);
                                    } else {
                                        thisLabelHeaderDiff$.next(labelHeaderDiff);
                                    }
                                });
                                const depthData = rawDatas[1];
                                const [graphKey, graphDataConfig] = Object.entries(rawDatas[0]).find(([key, graphData]) => graphData.selected) || [];
                                const data = getDepthData(true, depthData, graphDataConfig.args[0], graphDataConfig.args[1], graphDataConfig.args[2]);
                                parseData = Object.values(data);
                                if (textConfig.displayOnlyBigValue) {
                                    thisLabel$.next(null);
                                    thisLabelIcon$.next(null);
                                }
                            }
                        } else {
                            if (selectedInteractableName) {
                                const rawDatasSelectedInteractableName = rawDatas[selectedInteractableName] ? Object.values(rawDatas[selectedInteractableName]) : rawDatas;
                                const dataArgsConfig = usingMappingStoreKey ?
                                    Object.keys(mappingStoreKey).find(k => k === selectedInteractableName.toLocaleLowerCase()) !== undefined
                                        ? mappingStoreKey[selectedInteractableName.toLocaleLowerCase()] : []
                                    : dataConfigFromInstance.length > 0 ? dataConfigFromInstance : dataConfig[0].args;
                                rawDatasSelectedInteractableName.forEach(rawData => {
                                    const data = getDepthData(isEntrance, rawData, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                    parseData = Object.values(data[selectedInteractableName] ? Object.values(data[selectedInteractableName]) : data);
                                });
                            }
                            else {
                                const dataArgsConfig = usingMappingStoreKey ?
                                    Object.keys(mappingStoreKey).find(k => k === selectedInteractableName.toLocaleLowerCase()) !== undefined
                                        ? mappingStoreKey[selectedInteractableName.toLocaleLowerCase()] : []
                                    : dataConfigFromInstance.length > 0 ? dataConfigFromInstance : dataConfig[0].args;
                                rawDatas.forEach(rawData => {
                                    if ((rawData as any).mbk_parking) {
                                        const data = getDepthData(isEntrance, (rawData as any).mbk_parking, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                        parseData = Object.values(data);
                                    } else {
                                        const data = getDepthData(isEntrance, rawData, dataArgsConfig[0], dataArgsConfig[1], dataArgsConfig[2]);
                                        parseData = Object.values(data);
                                    }
                                });
                            }
                        }
                        if (!textConfig.displayOnlyBigValue) {
                            thisLabel$.next(textConfig?.notShowLiveMode && openMode ? `0% (0)` : `${parseUnit(parseData[2], 'percent')} (${parseUnitDiff(parseData[1], textConfig?.unitDiff || textConfig.unit, decimalPoint, textConfig.calculateHour)})`);
                            thisLabelIcon$.next(textConfig?.notShowLiveMode && openMode ? 'trending-up' : parseData[1] > 0 && parseData[2] >= 0 ? 'trending-up' : 'trending-down');
                            thisLabelStyle$.next({ fontSize: '1rem', fontWeight: '500', color: textConfig?.notShowLiveMode && openMode ? '#5BBF93' : (parseData[1] > 0 && parseData[2] >= 0 ? '#5BBF93' : '#E25144') });
                        }
                        thisNumberData$.next(textConfig?.notShowLiveMode && openMode ? `calculating...` : parseUnit(parseData[0], textConfig.unit, decimalPoint, textConfig.calculateHour));
                    }
                }));
            }

            filledCard.push(initNumberCard);
        });
        comInstance.overrideShouldShowSelector = useSelector;
        comInstance.selector = dynamicGraphDataSelector;
        comInstance.selectedNumberCard$ = selectedDataSource$;
        comInstance.cards = filledCard;
        comInstance.showAveragePerDay = showAveragePerDay;
        return comInstance;
    }
}
