import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-generic-echart',
  templateUrl: './generic-echart.component.html',
  styleUrls: ['./generic-echart.component.scss'],
})
export class GenericEchartComponent implements OnInit, OnChanges, AfterViewInit {

  chartInstance: any;
  // chartOption: EChartsOption;
  @Input() chartOption: any;
  @Input() chartOption$: Observable<EChartsOption>;

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.initializeChart();
    if (this.chartInstance) {
      this.chartInstance.setOption(this.chartOption);
    }
    this.chartOption$.subscribe(chartOption => {
      if (this.chartInstance) {
        if (chartOption) {
          this.chartInstance.setOption(chartOption);
        } else {
          this.chartInstance.setOption(this.chartOption);
        }
      }
    });
  }

  // TODO: convert observable to this method
  ngOnChanges(changes: SimpleChanges) {
    if (this.chartInstance) {
      if (changes.chartOption) {
        console.log('In generic', changes.chartOption);
        this.chartInstance.setOption(changes.chartOption.currentValue);
      } else {
        this.chartInstance.setOption(this.chartOption);
      }
    }
  }  

  initializeChart() {
    // this.chartOption = {
    //   tooltip: {
    //     trigger: 'item',
    //     formatter: '{b}: {c}'
    //   },
    //   series: [
    //     {
    //       type: 'treemap',
    //       name: 'Zoning Areas',
    //       label: {
    //         show: true,
    //         formatter: '{b}\n{c}'
    //       },
    //       itemStyle: {
    //         borderColor: '#FFF',
    //         borderWidth: 1
    //       },
    //       breadcrumb: {
    //         show: false
    //       },
    //       roam: false,
    //       nodeClick: 'link',
    //       data: [
    //         { name: 'Residential', value: 335 },
    //         { name: 'Commercial', value: 310 },
    //         { name: 'Industrial', value: 234 },
    //         { name: 'Recreational', value: 135 },
    //         { name: 'Other', value: 1548 },
    //         { name: 'Zone 6', value: 125 },
    //         { name: 'Zone 7', value: 215 },
    //         { name: 'Zone 8', value: 325 },
    //         { name: 'Zone 9', value: 435 },
    //         { name: 'Zone 10', value: 545 },
    //         { name: 'Zone 11', value: 655 },
    //         { name: 'Zone 12', value: 765 },
    //       ]
    //     }
    //   ]
    // };
  }

  onChartInit(event: any) {
    this.chartInstance = event;
  }

}
