import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageName } from '../objects/pages';

@Injectable({
  providedIn: 'root'
})
export class GlobalUiService {

  private currentPage$ = new BehaviorSubject<PageName>(null);

  constructor() {
    // this.currentPage$.subscribe(page => console.log('currentPage$', page));
  }

  get currentPage(): PageName {
    return this.currentPage$.value;
  }

  set currentPage(page: PageName) {
    this.currentPage$.next(page);
  }
}
