import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, ObservableInput } from 'rxjs';

import * as moment from 'moment';
import { ViewPeriod, getMomentDate, SelectedDatePeriod } from '../objects/view-period';

@Injectable({
  providedIn: 'root',
})
export class ViewPeriodService {
  /**
   * storing date in local aware format as moment along side viewperiod, the reason it store 3 date is, user can witch back to the date of each mode  
   *
   * @summary `date` will depend on `periodType`
   * @example  
   * • ViewPeriod.DAYS → date will be start of day
   * • ViewPeriod.WEEKS → date will be monday
   * • ViewPeriod.MONTHS → date wiill be first day of month
   */
  private readonly currentSelectedDate$: BehaviorSubject<SelectedDatePeriod>;
  isLiveMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  DAY_LIST$ = new BehaviorSubject<string[]>(null);
  DAY_LIST_MOMENT$ = new BehaviorSubject<moment.Moment[]>([]);
  isMonthMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  DATE_FORMAT = ['ddd/DD', '[W]ww[-]DD/MMM', 'MMM/YY'];

  readonly MIN_DATE = '2019-04-11';
  readonly MAX_DATE: string = moment().subtract(1, 'days').format('YYYY-MM-DD');

  liveRefetchInterval: any;

  constructor() {
    const yesterdayDate = moment().startOf(ViewPeriod.DAYS.toMomentString()).subtract(1, 'days');
    this.currentSelectedDate$ = new BehaviorSubject({
      dateDAYS: yesterdayDate.clone(),
      dateWEEKS: yesterdayDate.clone().startOf(ViewPeriod.WEEKS.toMomentString()),
      dateMONTHS: yesterdayDate.clone().startOf(ViewPeriod.MONTHS.toMomentString()),
      periodType: ViewPeriod.DAYS
    });
    this.changeDayList(this.viewPeriod);

    let lastIsLiveMode = false;
    this.currentSelectedDate$.subscribe(() => {
      if (!lastIsLiveMode && this.isLiveMode) {
        this.liveRefetchInterval = setInterval(() => {
          this.currentSelectedDate$.next(this.currentSelectedDate$.value);
        }, 1000 * 60 * 5);
      } else if (lastIsLiveMode && !this.isLiveMode) {
        clearInterval(this.liveRefetchInterval);
      }

      lastIsLiveMode = this.isLiveMode;
    });
  }

  get viewPeriod() {
    return this.currentSelectedDate$.value.periodType;
  }

  /**
   * get a **cloned** moment date of latest currentSelectedDate$
   */
  get selectedDate() {
    return getMomentDate(this.currentSelectedDate$.value);
  }

  /**
   * this is used for prevent access to currentSelectedDate$.next
   *
   * @param observer save callback with normal subscribe
   */
  subscribeSelectedDate(next?: (value: { date: moment.Moment; periodType: ViewPeriod }) => void) {
    return this.currentSelectedDate$.subscribe((selectedDate) => {
      next({ date: this.selectedDate, periodType: selectedDate.periodType });
    });
  }

  /**
   * this is used for prevent access to currentSelectedDate$.next while make it possible to use combinelatest
   *
   * @param observer save callback with normal subscribe
   */
  subscribeSelectedDateWith<O extends ObservableInput<any>>(sources: O[]) {
    return combineLatest([this.currentSelectedDate$, ...sources]);
  }

  setSelectedDate(date: moment.Moment, periodType?: ViewPeriod) {
    const nextperiodType = periodType || this.viewPeriod;
    const currentSelectedDate = this.currentSelectedDate$.value;
    this.currentSelectedDate$.next({
      dateDAYS: ViewPeriod.isDay(nextperiodType) ? date.startOf(nextperiodType.toMomentString()) : currentSelectedDate.dateDAYS,
      dateWEEKS: ViewPeriod.isWeek(nextperiodType) ? date.startOf(nextperiodType.toMomentString()) : currentSelectedDate.dateWEEKS,
      dateMONTHS: ViewPeriod.isMonth(nextperiodType) ? date.startOf(nextperiodType.toMomentString()) : currentSelectedDate.dateMONTHS,
      periodType: nextperiodType
    });
    this.changeDayList(this.viewPeriod);
    this.isLiveMode$.next(this.isLiveMode);
  }

  getWeekRange(startDate: moment.Moment): { start: moment.Moment; end: moment.Moment } {
    const start = startDate.clone().startOf('isoWeek');
    const end = startDate.clone().endOf('isoWeek');
    return { start, end };
  }

  changeViewPeriod(periodType: ViewPeriod | 'LIVE') {
    const nextPeriodType = periodType === 'LIVE' ? ViewPeriod.DAYS : periodType;
    const currentSelectedDate = this.currentSelectedDate$.value;
    if (periodType === 'LIVE') {
      currentSelectedDate.dateDAYS = moment().startOf('days');
    } else if (this.isLiveMode) {
      // when switch from live mode to any other mode, we need to reset the daily mode
      currentSelectedDate.dateDAYS = moment().subtract(1, 'days').startOf('days');
    }
    this.currentSelectedDate$.next({ ...currentSelectedDate, periodType: nextPeriodType });
    this.changeDayList(nextPeriodType);
    this.isLiveMode$.next(this.isLiveMode);
    this.isMonthMode$.next(this.isMonthPeriod);
  }

  changeDayList(viewPeriod: ViewPeriod): void {
    const newDayList: string[] = [];
    const newDayListMoment: moment.Moment[] = [];
    let period_count = 6;
    if (ViewPeriod.isDay(viewPeriod)) { period_count = 6; }
    else if (ViewPeriod.isWeek(viewPeriod)) { period_count = 6; }

    const dateFormat = this.DATE_FORMAT[this.viewPeriod.value];
    const periodType = this.viewPeriod;

    const todayDate = moment(new Date());
    const selectedDate = this.selectedDate;

    const isSelectedPeriod = this.isLivePeriod;
    const isCurrentPeriod = todayDate.isSame(selectedDate.add(1, periodType.toMomentCompareString()), periodType.toMomentString());

    for (period_count; period_count >= 0; period_count--) {
      let dateString: string;
      let momentPoint: moment.Moment;
      if (period_count === 0) {
        // if (periodType === 'days') dateString = 'Selected Day'
        // else if (periodType === 'weeks') dateString = 'Selected Week'
        // else dateString = 'Selected Month'
        dateString = '► Selected';
        momentPoint = this.selectedDate.subtract(period_count, periodType.toMomentCompareString());
      } else {
        momentPoint = this.selectedDate.subtract(period_count, periodType.toMomentCompareString());
        if (periodType === ViewPeriod.WEEKS) {
          const { start, end } = this.getWeekRange(momentPoint);
          dateString = `${start.format('DD')}-${end.format('DD')} ${end.format('MMM')}`;
        } else {
          dateString = momentPoint.format(dateFormat);
        }
      }
      newDayListMoment.push(momentPoint.clone().startOf('days'));
      newDayList.push(dateString);
    }

    // add prediction day
    const predictionDateString = () => {
      if (isCurrentPeriod) {
        if (this.isDayPeriod) { return 'Today'; }
        else if (this.isWeekPeriod) { return 'This Week'; }
        else { return 'This Month'; }
      } else if (isSelectedPeriod) {
        if (this.isDayPeriod) { return 'Tomorrow'; }
        else if (this.isWeekPeriod) { return 'Next Week'; }
        else { return 'Next Month'; }
      } else {
        const predictionMoment = this.selectedDate.add(1, periodType.toMomentCompareString());
        if (periodType === ViewPeriod.WEEKS) {
          const { start, end } = this.getWeekRange(predictionMoment);
          return `${start.format('DD')}-${end.format('DD')} ${end.format('MMM')}`;
        } else {
          return predictionMoment.format(dateFormat);
        }
      }
    };
    newDayList.push(predictionDateString());
    newDayListMoment.push(this.selectedDate.add(1, periodType.toMomentCompareString()).startOf('days'));
    this.DAY_LIST$.next(newDayList);
    this.DAY_LIST_MOMENT$.next(newDayListMoment);
  }

  get DAY_LIST() {
    return this.DAY_LIST$.value;
  }

  /**
   * get DAY_LIST_MOMENT  
   * *If you want to modify its value don't forgot to **clone moment** first*
   */
  get DAY_LIST_MOMENT() {
    return this.DAY_LIST_MOMENT$.value;
  }

  get dayList(): Observable<any> {
    return this.DAY_LIST$;
  }

  /**
   * Check if selected date is yesterday which is the date that app default to
   */
  get isCurrentPeriod() {
    return moment(new Date()).isSame(this.selectedDate.add(1, this.viewPeriod.toMomentCompareString()), this.viewPeriod.toMomentString());
  }

  /**
   * isLivePeriod - is legacy code which was used for checking live warning header
   */
  get isLivePeriod() {
    return moment(new Date()).isSame(this.selectedDate, this.viewPeriod.toMomentString());
  }

  get isLiveMode() {
    return this.isLivePeriod && this.isDayPeriod;
  }

  get isDayPeriod() {
    return ViewPeriod.isDay(this.viewPeriod);
  }

  get isWeekPeriod() {
    return ViewPeriod.isWeek(this.viewPeriod);
  }

  get isMonthPeriod() {
    return ViewPeriod.isMonth(this.viewPeriod);
  }

}
