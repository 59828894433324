import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { GenericPieChartData } from '../objects/chart';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData, filterFloorName } from '../helpers/util';

export class FloorNetShoppingHourPieResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        await configDataService.loadAppConfig(); // ENTRANCE_EXIT_FLOOR, ENTRANCE_EXIT_ZONE
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const isEnableTBD = (getAdditionalInput(additionalInput, 'isEnableTBD')) as boolean;
        const initialfloorName = graphDataService.selectedFloorName$.value || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';

        type NetShoppingTimeZoneData = { [name: string]: { netShoppingTime: number; diff: number; diffPercent: number } };
        const getCurrentNetShoppingTimeZoneData = (
            data: { [buildingName: string]: NetShoppingTimeZoneData | { [floorName: string]: NetShoppingTimeZoneData } },
            floorName?: string
        ) => accessDepthData<NetShoppingTimeZoneData>(data, buildingName, floorName);

        const initialCurrentNetShopingTimeData = initialIsAll ? filterFloorName(getCurrentNetShoppingTimeZoneData(graphDataService.currentNetShoppingTimeFloorData$.value), Object.keys(configDataService.DISPLAY_LANGUAGE.FLOOR_NAME))
            : filterFloorName(getCurrentNetShoppingTimeZoneData(graphDataService.currentNetShoppingTimeZoneData$.value, initialfloorName), Object.keys(configDataService.DISPLAY_LANGUAGE.ZONE_NAME));

        const pieData$ = new BehaviorSubject<GenericPieChartData[]>(Object.entries(initialCurrentNetShopingTimeData).sort().map(([zoneName, zoneData], index) => ({
            data: zoneData.netShoppingTime / (60 * 60),
            color: PIE_CHART_COLOR_LIST[index % PIE_CHART_COLOR_LIST.length],
            label: configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[zoneName] || configDataService.DISPLAY_LANGUAGE.ZONE_NAME[zoneName] || zoneName.toUpperCase()
        })));
        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.currentNetShoppingTimeFloorData$, graphDataService.currentNetShoppingTimeZoneData$]).subscribe(
            ([selectedFloorName, allData, floorData]) => {
                if (!allData || !floorData) { return; }
                const floorName = selectedFloorName || 'ALL';
                const isAll = floorName === 'ALL';
                const currentNetShoppingTimeData = isAll ? filterFloorName(getCurrentNetShoppingTimeZoneData(allData), Object.keys(configDataService.DISPLAY_LANGUAGE.FLOOR_NAME))
                    : filterFloorName(getCurrentNetShoppingTimeZoneData(floorData, floorName), Object.keys(configDataService.DISPLAY_LANGUAGE.ZONE_NAME));

                pieData$.next(Object.entries(currentNetShoppingTimeData).sort().map(([zoneName, zoneData], index) => ({
                    data: zoneData.netShoppingTime / (60 * 60),
                    color: PIE_CHART_COLOR_LIST[index % PIE_CHART_COLOR_LIST.length],
                    label: configDataService.DISPLAY_LANGUAGE.FLOOR_NAME[zoneName] || configDataService.DISPLAY_LANGUAGE.ZONE_NAME[zoneName] || zoneName.toUpperCase()
                })));
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR;
        comInstance.isEnableTBD = isEnableTBD;
        comInstance.isLock = this.isLock;
        comInstance.isShowLegendTitleValue = true; // should be false?
        comInstance.isShowLegend = false;
        comInstance.data$ = pieData$;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this);
        };
        return comInstance;
    }

}
