import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { GenericBarChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, CHART_SECONDARY_COLOR } from '../configs/color';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CustomerAgeTooltipsComponent } from '../pages/home/general/customer-age-tooltips/customer-age-tooltips.component';
import { showAgeCustomTooltips } from '../helpers/ageTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { accessDepthData, isScreenSmallerThanMD } from '../helpers/util';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

export class ZoneAgeProfileByPinResolver extends GraphResolverBase {
    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        _viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        const zoneInstanceName = (getAdditionalInput(additionalInput, 'zone')) as string;
        
        type DepthData = {
            [name: string]: number;
        } & {
            _total: number;
        };
        type EntraceExitData = { entrance: DepthData; exit: DepthData };

        const getEntraceExitData = (isEntrance: boolean, data: { entrance: DepthData; exit: DepthData }) => isEntrance ? data.entrance : data.exit;

        const getDepthData = (
            isEntrance: boolean,
            data: { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, 0);
            }
            if (retData instanceof Object && Object.keys(retData).includes('entrance')) {
                retData = getEntraceExitData(isEntrance, retData as EntraceExitData);
            }
            return retData as { [gender: string]: number[] };
        };
        
        // await configDataService.loadAppConfig();
        const chartLabel = configDataService.AGE_CLASS.map(age => configDataService.DISPLAY_LANGUAGE.AGE_CLASS[age]);
        const dualBarData$ = new BehaviorSubject<GenericBarChartData[]>([{
            data: graphDataService.ageProfileData$.value.female,
            color: CHART_SECONDARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
            calPercentFrommAllData: true
        }, {
            data: graphDataService.ageProfileData$.value.male,
            color: CHART_PRIMARY_COLOR,
            label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
            calPercentFrommAllData: true
        }]);
        subscription.add(combineLatest([graphDataService.allZoneAgeProfileBreakdown$]).subscribe(([allZoneAgeProfileBreakdown]) => {
            const ageData = getDepthData(true, allZoneAgeProfileBreakdown, zoneInstanceName);
            if (Object.keys(ageData).length === 0) { return; }
            dualBarData$.next([{
                data: ageData.female,
                color: CHART_SECONDARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.female,
                calPercentFrommAllData: true
            }, {
                data: ageData.male,
                color: CHART_PRIMARY_COLOR,
                label: configDataService.DISPLAY_LANGUAGE.GENDER_CLASS.male,
                calPercentFrommAllData: true
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.AGE_PROFILE;
        comInstance.isLock = this.isLock;
        comInstance.infoPopover = async (e: any) => {
            const ageDetailPopover = await popoverController.create({
                component: CustomerAgeTooltipsComponent,
                cssClass: 'calendar-criteria-tooltips',
                event: e
            });
            return ageDetailPopover.present();
        };
        comInstance.isShowLegend = false;
        comInstance.data$ = dualBarData$;
        comInstance.chartLabel = chartLabel;
        comInstance.displayGrid = false;
        comInstance.displayAxis = 'X';
        comInstance.aspectRatio = isScreenSmallerThanMD(window) ? 1.1 : 1.4;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showAgeCustomTooltips(tooltipModel, this, configDataService.ageImageSrc);
        };
        return comInstance;
    }

}
