import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericHeatmapData } from 'src/app/objects/chart';

@Component({
  selector: 'app-dynamic-custom-heatmap-wrapper',
  templateUrl: './dynamic-custom-heatmap-wrapper.component.html',
  styleUrls: ['./dynamic-custom-heatmap-wrapper.component.scss'],
})
export class DynamicCustomHeatmapWrapperComponent implements OnInit {

  @Input() title: string;
  @Input() isLock: boolean | BehaviorSubject<boolean> = false;
  @Input() isShow: boolean | BehaviorSubject<boolean> = false;
  @Input() data$: BehaviorSubject<GenericHeatmapData>;
  @Input() xAxisLabelDisplayName: { [labelKey: string]: string };
  @Input() yAxisLabelDisplayName: { [labelKey: string]: string };
  @Input() gridSize: { width: string | number; height: string | number };
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() visualMapType: 'continuous' | 'piecewise' = 'continuous';
  @Input() isEnableTBD: boolean;
  @Input() forceDisplayTBD: boolean;
  @Input() inputLockText: string;
  @Input() colorSchemeIndicator: string[];

  lockOpacity = 0.35;

  constructor(
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() {}

}
