const DEFAULT_DISPLAY_LANGUAGE = {
    HIGHTLIGHT: {
        MALL_TRAFFIC: 'Mall Traffic',
        MALL_TRAFFIC_EST_TOURIST: 'Est. tourist',
        MALL_TRAFFIC_PARKING_CUSTONER: 'Parking customer',
        MALL_TRAFFIC_MBK_SEVEN_TH: '7th floor',
        MALL_TRAFFIC_SPO_LUXTUARY_ZONE: 'Luxtuary zone',
        MALL_TRAFFIC_SPO_SPORTS_ZONE: 'Sports zone',
        MALL_TRAFFIC_SPO_FOOD_ZONE: 'Food zone'
    },
    MALL_TRAFFIC: 'Mall Traffic',
    HEADCONT: 'Headcount',
    ENTRANCE: 'Entrance',
    EXIT: 'Exit',
    TRAFFIC: 'Traffic',
    DURATION_VISIT_TREND: 'Duration of Visit Trend (min)',
    DURATION_VISIT_NUMBER_CARD: 'Avg. Duration of Visit',
    POPULAR_TIME_CHART: 'Popular Times',
    POPULAR_TIME_BUSIEST_NUMBER_CARD: 'Busiest Time',
    GENDER_PROFILE: 'Gender Profile',
    GENDER_CLASS: {
        male: 'Male',
        female: 'Female'
    },
    PROFESSION_CLASS: {
        student: 'Student Uniform'
    },
    ETHNICITY_PROFILE: 'Ethnicity Profile',
    ETHNICITY_CLASS: {
        asian: 'Asian',
        white: 'White',
        middle_eastern: 'Arabs',
        indian: 'Indian',
        black: 'Black',
    },
    NON_ASIAN_ETHNICITY_PROFILE: 'Non Asian Ethnicity Profile',
    NON_ASIAN_ETHNICITY_CLASS: 'Non Asian',
    ASIAN_ETHNICITY_PROFILE: 'Asian Ethnicity Profile',
    CUSTOMER_ESTIMATE_SEGMENTATION: 'Custom Est. Segmentation',
    CUSTOMER_SEGREGATION_CLASS: {
        white_tourist: 'US/EU Tourists',
        indian_tourist: 'Indian Tourists',
        middle_eastern_tourist: 'Middle-East Tourist',
        asian_tourist: 'Asian Tourists',
        other_tourist: 'Other Tourists',
        local_young_social_strollers: 'Local Young Strollers',
        local_adult_shoppers: 'Local Adult Shoppers',
    },
    AGE_PROFILE: 'Age Profile',
    AGE_CLASS: {
        children: 'Children',
        teenagers: 'Teenagers',
        young_adults: 'Young Adults',
        adults: 'Adults',
        seniors: 'Seniors'
    },
    ESTIMATED_TOURISTS: 'Est. Tourists',
    TOURIST_CLASS: {
        tourist: 'Tourist',
        local: 'Local',
        asian: 'Asian',
        non_asian: 'Non-asian',
    },
    REPETATED_VISITORS: 'Repeated Visitor',
    VISITORS_CLASS: {
        repeated: 'Repeated',
        new: 'New'
    },
    FREQUENCY_VISIT: 'Frequency of Visit',
    FREQUENCY_VISIT_CLASS: {
        one: '1 day',
        two_five: '2-5 days',
        six_ten: '6-10 days',
        eleven_twenty: '11-20 days',
        twenty_up: '20+ days'
    },
    MODE_TRANSPORTATION: 'Mode of Transportation',
    VEHICLE_MODE_TRANSPORTATION_CLASS: {
        total: 'total', // TODO: add more type here
    },
    VEHICLE_PURCHASING_POWER_CLASS: {
        all: 'All'
    },
    VEHICLE_PLATE_DEFININTON_CLASS: {
        buyer: 'Buyer',
        seller: 'Seller',
    },
    VEHICLE_CLASS: {
        total: 'total', // TODO: add more type here
    },
    VEHICLE_PROVINCE: 'Vehicle by Province',
    VEHICLE_PROVINCE_CLASS: {
        total: 'total',
    },
    ENTRANCE_EXIT_ANALYSIS: 'Entrance-Exit Analysis',
    ENTRANCE_EXIT_BIHOURS: 'Entrance & Exit',
    ENTRANCE_EXIT_FLOW: 'Flow Analysis',
    VISITOR_PROFILE: {
        ORGANIZATION: {
            all: 'One Siam',
            siam_paragon: 'Siam Paragon',
            siam_discovery: 'Siam Discovery',
            siam_center: 'Siam Center',
        },
        GENDER: {
            all: 'All Genders',
            male: 'Male',
            female: 'Female',
        },
        ETHNICITY: {
            all: 'All Ethnicities',
            asian: 'Asian',
            white: 'White',
            middle_eastern: 'Arabs',
            indian: 'Indian',
            black: 'Black',
        },
        AGE: {
            all: 'All Ages',
            student: 'Student Uniform',
            children: 'Children',
            teenagers: 'Teenagers',
            young_adults: 'Young Adults',
            adults: 'Adults',
            seniors: 'Seniors',
        },
        PURCHASE_BEHAVIOR: {
            purchase: 'Purchase',
            non_purchase: 'Non-Purchase',
        }
    },
    FOOT_TRAFFIC: 'Foot Traffic',
    TRAFFIC_TREND: 'Traffic Trend',
    TRAFFIC_VOLUME: 'Traffic Volume',
    STORE_DURATION_OF_VISIT: 'Average Minutes Spent Trend',
    STORE_NET_SHOPPING_HOUR: 'Net Shopping Hour Trend',
    STORE_TRAFFIC_VOLUME: 'Headcount',
    STORE_TRAFFIC_TREND: 'Traffic Trend',
    STORE_FOOT_TRAFFIC: 'Foot Traffic',
    PURCHASE_BEHAVIOR: 'Purchase Behavior',
    VISITING_BEHAVIOR: 'Visiting Behavior',
    NET_SHOPPING_HOUR: 'Net Shopping Hour',
    PURCHASE_RATE_TREND: 'Purchase Rate Trend',
    PURCHASE_RATE: 'Purchase Rate',
    TIME_VISIT: 'Time Visit',
    AVERAGE_CONGESTION: 'Avg. Congestion',
    EXTRA_TRAVEL_TIME: 'Extra Travel Time',
    MASK_COUNT: 'Mask Use (%)',
    HEADCOUNT_MALL_FLOW: 'Headcount Flow Sources',
    NET_VISITOR_HOUR: 'Net Visitor Hours',
    MESSENGER_COUNT: 'Courier Traffic',
    TOP_TEN_CAR_BRAND: 'Top 10 Brands',
    VEHICLE_PURCHASING_POWER: 'Implied Purchasing Power',
    MODE_TRANSPORTATION_BY_HOUR: 'Mode of Transportation By Hour',
    NON_STAFF_TRAFFIC: 'Non-Staff Headcount',
    STAFF_TRAFFIC_CARD: 'Staff Traffic',
    VEHICLE_TRAFFIC_CARD: 'Vehicle Traffic',
    MESSENGER_BRAND_TRAFFIC: 'Deliveryman Traffic',
    MESSENGER_BRAND_TRAFFIC_CARD: 'Deliveryman Traffic',
    SHOPPING_BAG_COLOR_GRAPH: 'Colored Shopping Bag',
    MESSENGER_CLASS: {
        lalamove: 'Lalamove',
        grab: 'Grab',
        foodpanda: 'Foodpanda',
        j_t: 'J&T',
        lineman: 'Lineman',
        thailand_post: 'Thailand Post',
        kerry: 'Kerry',
        dhl: 'DHL',
        motorbike_taxi: 'Motorbike Taxi',
        robinhood: 'Robinhood',
        '7-11': '7-11',
        pizza: 'Pizza',
        gojek: 'Gojek',
        skootar: 'Skootar',
        shopee: 'Shopee',
        get: 'Get',
        flash: 'Flash'
    },
    MESSENGER_GROUP_CLASS: {
        total: 'Total',
        general: 'Generic',
        food: 'Food',
        package: 'Package',
        special: 'Special'
    },
    SHOPPING_BAG_COLOR_CLASS: {
        pink: 'Pink',
        red: 'Red',
        orange: 'Orange',
        'navy-blue': 'Navy Blue',
    },
    CURRENT_CUSTOMER: {
        default: 'No. Current Customer',
    } as { [zoneName: string]: string },
    PARKING_LOT_OCCUPANCY: 'Parking Lot Availability',
    FROM_TO: {
        entrance: 'Entrace from',
        exit: 'Exit to'
    },
    FLOOR_SUMMARY: 'Floor Summary',
    ZONE_SUMMARY: 'Zone Summary',
    EVENT_LOCATIONS: {
        'Zone A': 'Zone A',
        'Zone B': 'Zone B',
        'Zone A-B': 'Zone A & Zone B',
    } as { [location: string]: string },
    EVENT_CAMPAIN_TYPE: {
        'Store Wide Promotion': 'Store Wide Promotion',
        'Category Promotion': 'Category Promotion',
        Event: 'Event',
    } as { [eventType: string]: string },
    BUILDING_NAME: {} as { [buildingName: string]: string },
    FLOOR_NAME: {
        '0_ground': 'Ground'
    } as { [floorName: string]: string },
    ZONE_NAME: {} as { [zoneName: string]: string },
    GATE_NAME: {} as { [gateName: string]: string },
    BILLBOARD_PACKAGE_NAME: {} as { [packageName: string]: string },
    BILLBOARD_LOCATION_NAME: {} as { [locationName: string]: string },
    BILLBOARD_TYPE_NAME: {} as { [typeName: string]: string },
    FREQUENCY_VISIT_NAME: {} as { [freqName: string]: string },
    DYNAMIC_GRAPH_GROUP_NAME: {
        mbk: 'MBK Center',
        tokyu: 'Tokyu',
        tower: 'MBK Tower',
        a_la_art: 'A La Art',
    } as { [groupName: string]: string },
    DYNAMIC_GRAPH_GROUP_DYNAMIC_NAME: {} as { [groupName: string]: { [dynamicName: string]: string } },
    DYNAMIC_SELECTOR_NAME: {} as { [name: string]: string },
    REPORT_SELECTOR_NAME: {} as { [name: string]: string },
    SANKEY_NODE_NAME: {} as { [name: string]: string }, 
    BRAND_CLASS: {} as { [name: string]: string }, 
    BIN_NAME: {} as { [name: string]: string },
    PIE_CHART_NAME: {} as { [name: string]: string },
    HEATMAP_CHART_NAME: {} as { [name: string]: string },
    TIME_NAME: {} as { [name: string]: string }, 
    STORE_NAME: {} as { [name: string]: string }, 
};

export default DEFAULT_DISPLAY_LANGUAGE;
