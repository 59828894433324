import { Component, Input, ComponentFactoryResolver, ViewContainerRef, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { GraphResolver } from 'src/app/graph-resolver/GraphResolver';
import { GraphResolverBase } from 'src/app/graph-resolver/GraphResolverBase';
import { GraphDataService } from 'src/app/services/graph-data-service.service';
import { ViewPeriodService } from 'src/app/services/view-period.service';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphGroupDropdownComponent } from '../dynamic-graph-group-dropdown/dynamic-graph-group-dropdown.component';
import { DynamicGraphInput, DynamicGraphAdditionalInput, DynamicName } from 'src/app/objects/config';
import { GoalDataService } from 'src/app/services/goal-data.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-dynamic-graph-container',
  templateUrl: './dynamic-graph-container.component.html',
  styleUrls: ['./dynamic-graph-container.component.scss'],
})
export class DynamicGraphContainerComponent implements OnDestroy, AfterViewInit {
  @ViewChild('target', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  @Input() componentName: string | DynamicGraphInput;
  @Input() additionalInput: string | DynamicGraphAdditionalInput | undefined;
  graphResolver: GraphResolverBase;
  protected subscription = new Subscription();
  protected changeDetectorRefs: ChangeDetectorRef[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    // private viewContainerRef: ViewContainerRef,
    private graphDataService: GraphDataService,
    private viewPeriodService: ViewPeriodService,
    private configDataService: ConfigDataService,
    private popoverController: PopoverController,
    private authenicationService: AuthenticationService,
    private goalDataService: GoalDataService,
  ) { }

  ngAfterViewInit(): void {
    if (typeof this.componentName === 'string') {
      let resolvedAdditionalInput: string | DynamicGraphAdditionalInput | undefined;
      [this.graphResolver, resolvedAdditionalInput] = GraphResolver.fromValue(this.componentName);
      // if resolver says it has input, use that instead of current input which is given by the group
      this.additionalInput = resolvedAdditionalInput || this.additionalInput;
      // console.log('resolved to', this.graphResolver, 'with state', this.graphResolver.state, 'and input', this.additionalInput);
      this.loadComponent();
    } else {
      this.loadGroupComponent(this.componentName.type, this.componentName.name, this.componentName.children, this.componentName.groupInput, this.componentName.dynamicName, this.componentName.backgroundColor, this.componentName.defaultState);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.changeDetectorRefs.forEach(changeDetectorRef => changeDetectorRef.detach());
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.graphResolver.component as any);
    // this.viewContainerRef.clear();

    return this.graphResolver.createComponent(componentFactory, this.additionalInput, this.configDataService, this.graphDataService, this.popoverController, this.viewPeriodService, this.viewContainerRef, this.subscription, this.authenicationService, this.goalDataService);
  }

  loadGroupComponent(type: 'DROP_DOWN', name: string, children: string[], groupInput: string | undefined, dynamicName: DynamicName | undefined, backgroundColor?: string, defaultState?: 'OPEN' | 'CLOSE') {
    if (type === 'DROP_DOWN') {
      const dynamicContainerComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicGraphGroupDropdownComponent);
      // this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(dynamicContainerComponentFactory);
      const comInstance = componentRef.instance;
      comInstance.name = name;
      comInstance.children = children;
      comInstance.groupInput = GraphResolver.parseAdditionalInput(groupInput);
      comInstance.dynamicName = dynamicName;
      comInstance.color = backgroundColor || '#4a6fdd';
      comInstance.defaultState = defaultState;
      componentRef.changeDetectorRef.detectChanges();
      this.changeDetectorRefs.push(componentRef.changeDetectorRef);
      return;
    }
    throw new RangeError(`${type} is not supported`);
  }

}
