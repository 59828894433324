import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { PopoverController } from '@ionic/angular';
import { GenericPieChartData } from '../objects/chart';
import { PIE_CHART_COLOR_LIST } from '../configs/color';
import { showPieChartCustomTooltips } from '../helpers/pieChartTooltips';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { isShowGraph } from '../helpers/util';
import { AuthenticationService } from '../services/authentication.service';

export class FrequencyVisitResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency);
        // await configDataService.loadAppConfig();
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;

        const isLock$ = new BehaviorSubject<boolean>(false);

        const pieData$ = new BehaviorSubject<GenericPieChartData[]>([{
            data: graphDataService.frequencyOfVisitData$.value.one,
            color: PIE_CHART_COLOR_LIST[0],
            label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.one
        }, {
            data: graphDataService.frequencyOfVisitData$.value.two_five,
            color: PIE_CHART_COLOR_LIST[1],
            label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.two_five
        }, {
            data: graphDataService.frequencyOfVisitData$.value.six_ten,
            color: PIE_CHART_COLOR_LIST[2],
            label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.six_ten
        }, {
            data: graphDataService.frequencyOfVisitData$.value.eleven_twenty,
            color: PIE_CHART_COLOR_LIST[3],
            label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.eleven_twenty
        }, {
            data: graphDataService.frequencyOfVisitData$.value.twenty_up,
            color: PIE_CHART_COLOR_LIST[4],
            label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.twenty_up
        }]);
        subscription.add(combineLatest([graphDataService.frequencyOfVisitData$, viewPeriodService.isMonthMode$]).subscribe(([repeatedVisitorData, isMonthMode]) => {
            // isLock$.next(!isMonthMode);
            pieData$.next([{
                data: repeatedVisitorData.one,
                color: PIE_CHART_COLOR_LIST[0],
                label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.one
            }, {
                data: repeatedVisitorData.two_five,
                color: PIE_CHART_COLOR_LIST[1],
                label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.two_five
            }, {
                data: repeatedVisitorData.six_ten,
                color: PIE_CHART_COLOR_LIST[2],
                label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.six_ten
            }, {
                data: repeatedVisitorData.eleven_twenty,
                color: PIE_CHART_COLOR_LIST[3],
                label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.eleven_twenty
            }, {
                data: repeatedVisitorData.twenty_up,
                color: PIE_CHART_COLOR_LIST[4],
                label: configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT_CLASS.twenty_up
            }]);
        }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.FREQUENCY_VISIT;
        comInstance.isLock = isLock$;
        comInstance.inputLockText = 'This graph is available in Monthly View';
        comInstance.isShowLegendTitleValue = true;
        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        // comInstance.paddingRigt = '3.5rem';
        // comInstance.infoPopover = async (e: any) => {
        //     const touristsPopover = await popoverController.create({
        //         component: TouristsTooltipsComponent,
        //         cssClass: 'calendar-criteria-tooltips',
        //         event: e
        //     });
        //     return touristsPopover.present();
        // };
        comInstance.isShowLegend = false;
        comInstance.data$ = pieData$;
        comInstance.customToolTipFuction = function(tooltipModel) {
            showPieChartCustomTooltips(tooltipModel, this);
        };
        return comInstance;
    }

}
