import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericBarChartData } from 'src/app/objects/chart';
import { ChartTooltipModel } from 'chart.js';
import { Label } from 'ng2-charts';
import { isScreenSmallerThanLG, isScreenSmallerThanMD } from 'src/app/helpers/util';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { SelectableBarChartDataName, SelectableDataName } from 'src/app/objects/selectableData';
import { GraphDataConfig } from 'src/app/objects/config';
import { BaseDataDependency } from 'src/app/objects/baseGraphData';
import { GraphDataService } from 'src/app/services/graph-data-service.service';
import { ViewPeriodService } from 'src/app/services/view-period.service';

@Component({
  selector: 'app-dynamic-bar-chart-wrapper',
  templateUrl: './dynamic-bar-chart-wrapper.component.html',
  styleUrls: ['./dynamic-bar-chart-wrapper.component.scss'],
})
export class DynamicBarChartWrapperComponent implements OnInit, OnChanges {
  private static readonly STATE_MAPPER: { [key in 'Ascending' | 'Descending' | 'Default']: 'Ascending' | 'Descending' | 'Default' } = {
    Default: 'Descending',
    Descending: 'Ascending',
    Ascending: 'Default',
  };

  private static readonly STATE_ICON: { [key in 'Ascending' | 'Descending' | 'Default']: 'funnel-outline' | 'trending-up-outline' | 'trending-down-outline' } = {
    Default: 'funnel-outline',
    Descending: 'trending-down-outline',
    Ascending: 'trending-up-outline',
  };

  @Input() isShow = true;
  @Input() title: string;
  @Input() data$: BehaviorSubject<GenericBarChartData[]>;
  @Input() chartLabel: Label[];
  @Input() isLock = false;
  @Input() isShowLegend = false;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() customToolTipFuction: (tooltipModel: ChartTooltipModel) => void;
  @Input() isHorizontal = false;
  @Input() aspectRatio = isScreenSmallerThanLG(window) ? 0.9 : 1.4;
  @Input() valueTextAlign: 'center' | 'left' | 'right' = 'center';
  @Input() valueTextOffsetX = 0;
  @Input() valueTextOffsetY = 0;
  @Input() valueTextProcess: 'numberFormatter' | 'toLocaleString' | 'percentFormatter' = 'toLocaleString';
  @Input() xAxesRotation = 0;
  @Input() yAxesRotation = 0;
  @Input() displayAxis: 'X' | 'Y' | 'XY' = 'X';
  @Input() displayGrid = false;
  @Input() gridColor = '#424242';
  @Input() isStacked: 'X' | 'Y' | 'XY' | '!XY' = '!XY';
  @Input() sortedState$: BehaviorSubject<'Ascending' | 'Descending' | 'Default'>;
  @Input() selectedBar$: BehaviorSubject<{ [selectorName: string]: { name: SelectableBarChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } } | { [selectorName: string]: GraphDataConfig }>;
  @Input() selector: { [selectorName: string]: { name: SelectableBarChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } } | { [selectorName: string]: GraphDataConfig } = {};
  @Input() selectInterface: 'action-sheet' | 'popover' | 'alert' = 'popover';
  @Input() overrideShouldShowSelector: boolean;
  @Input() suggestedTickMax_X = 0;
  @Input() displayTextValue = true;
  @Input() isOnTrafficPage = false;
  @Input() paddingRight: number;
  @Input() isEnableTBD = false;
  @Input() multipleSelector = true;
  @Input() showAveragePerDay = false;

  showAverage = false;
  uniqueGraphIds = new Set<BaseDataDependency>();

  lockOpacity = 0.35;
  enableSorter = false;
  selectedName: string[] = [];
  selectorName: string[] = [];
  shouldShowSelector = false;

  // Initial grid template columns
  gridTemplateColumns = 'auto 1fr 1fr';

  constructor(
    public configDataService: ConfigDataService,
    private graphDataService: GraphDataService,
    private viewPeriodService: ViewPeriodService,
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }


  private update() {
    this.enableSorter = this.sortedState$ ? true : false;
    this.selectorName = Object.keys(this.selector);
    if (this.overrideShouldShowSelector !== undefined) {
      this.shouldShowSelector = this.overrideShouldShowSelector;
    } else {
      this.shouldShowSelector = this.selectorName && this.selectorName.length > 1;
    }
    this.selectedName = Object.entries(this.selector).filter(([_, selectorDetail]) => selectorDetail.selected).map(([name, _]) => name);
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.update();
  }

  handleSortButton(state: 'Ascending' | 'Descending' | 'Default') {
    const nextState = DynamicBarChartWrapperComponent.STATE_MAPPER[state];
    this.sortedState$.next(nextState);
  }

  changeSelectedGraph(event: any) {
    const selectedName = event.detail.value as string[];
    this.selectedName = selectedName;
    // TODO: instead of next one value we need to add more here
    const currentSelectedBar = this.selectedBar$.value;
    Object.keys(currentSelectedBar).forEach(selectorName => {
      currentSelectedBar[selectorName].selected = selectedName.includes(selectorName);
    });
    this.selectedBar$.next(currentSelectedBar);
  }

  getSortingIcon(state: 'Ascending' | 'Descending' | 'Default') {
    return DynamicBarChartWrapperComponent.STATE_ICON[state];
  }

  get isShowAveragePerDayButton() {
    return this.showAveragePerDay && (this.viewPeriodService.isWeekPeriod || this.viewPeriodService.isMonthPeriod);
  }

  toggleAveragePerDayFilter(): void {
    this.showAverage = !this.showAverage;
    const currentSelectedBar = this.selectedBar$.value;
    // Add logic to update the data based on the showAverage state.
    Object.keys(currentSelectedBar).forEach(selectorName => {
      const graphId = currentSelectedBar[selectorName].name as SelectableDataName;
      const graphData = this.graphDataService.baseGraphData.getSelectedGraph(graphId, this.graphDataService);
      this.uniqueGraphIds.add(graphData.dependencies as BaseDataDependency);
    });
    this.uniqueGraphIds.forEach(graphId => {
      const currentValue = this.graphDataService.baseGraphData.getIsDisplayAverageValueForGraph(graphId).value;
      this.graphDataService.baseGraphData.setIsDisplayAverageValueForGraph(graphId, !currentValue);
    });
  }

  updateGridLayout(enableSorter: boolean, isSelectorVisible: boolean) {
    if (!enableSorter && !isSelectorVisible) {
      this.gridTemplateColumns = '1fr';
    } else if (!enableSorter || !isSelectorVisible) {
      this.gridTemplateColumns = 'auto 1fr';
    } else {
      this.gridTemplateColumns = '1fr 1fr 2fr';
    }
    return this.gridTemplateColumns;
  }

  get displayIconOnMiddle() {
    return isScreenSmallerThanMD(window);
  }

}
