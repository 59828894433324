import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-purchasing-power-tooltips',
  templateUrl: './vehicle-purchasing-power-tooltips.component.html',
  styleUrls: ['./vehicle-purchasing-power-tooltips.component.scss'],
})
export class VehiclePurchasingPowerTooltipsComponent implements OnInit {

  rowPrice = ['Price', '< 1 million THB', '1 - 2.5 million THB', '2.5+ million THB'];
  rowDes = ['Description', 'Designed for low-cost purchase and ownership.', 'Designed with high product quality and features, but not as well perceived with status.', 'Designed with the highest levels of comfort, quality, and status at a higher price.'];

  constructor() { }

  ngOnInit() {}

}
