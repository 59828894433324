import { ChartTooltipModel } from 'chart.js';

let barChartTooltipTimeout: any;

export const showGenericBarChartCustomTooltips = (
    tooltipModel: ChartTooltipModel,
    thisChart,
    isHorizontal = true,
    valueTextProcess: 'numberFormatter' | 'toLocaleString' | 'percentFormatter' = 'toLocaleString',
    useLabel: 'chartDataLabel' | 'chartLabel' = 'chartLabel',
    opacity: number = 1,
) => {

    window.clearTimeout(barChartTooltipTimeout);

    // Tooltip Element
    let tooltipEl = document.getElementById('bar-chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'bar-chartjs-tooltip';
        tooltipEl.innerHTML = '<table style="margin-left: 10px;"></table><div></div>';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        window.clearTimeout(barChartTooltipTimeout);
        tooltipEl.style.opacity = '0';
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }
    const chartDatasets = thisChart._data.datasets;
    const chartLabels = thisChart._data.labels;
    // Set Text
    if (tooltipModel.body) {
        const titleLines = tooltipModel.title || [];
        // var bodyLines = tooltipModel.body.map(getBody);
        let colors: { backgroundColor: string; borderColor: string };
        // TODO: imeplement isHorizontal
        /*console.log("tooltipModel", tooltipModel)
        console.dir(tooltipModel, { depth: null });*/
        const bodyLines = tooltipModel.dataPoints.filter(datapoint => {
            if (datapoint.datasetIndex === datapoint.index) {
                colors = tooltipModel.labelColors[datapoint.datasetIndex] as any || tooltipModel.labelColors[0];
                return true;
            } else if (tooltipModel.dataPoints.length === 1) {
                colors = tooltipModel.labelColors[datapoint.datasetIndex] as any || tooltipModel.labelColors[0];
                return true;
            }
            return false;
        }).map((datapoint) => {
            const rawValue = (chartDatasets[datapoint.datasetIndex].data[datapoint.index] || 0) as number;
            const valueProcess = valueTextProcess === 'percentFormatter' ? `${rawValue.toFixed(1)}%` : rawValue.toLocaleString();
            const label = (useLabel === 'chartLabel') ? chartLabels[datapoint.index] : chartDatasets[datapoint.datasetIndex].label;
            return `${label}: ${valueProcess}`;
        });

        let innerHtml = '<thead>';

        titleLines.forEach((title) => {
            innerHtml += '<tr><th>' + title + '</th></tr>';
        });
        innerHtml += '</thead><tbody>';

        bodyLines.forEach((body, i) => {
            let style = 'background:' + (colors.backgroundColor);
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            style += '; display: inline-block';
            style += '; width: 15px';
            style += '; height: 15px';
            style += '; margin-right: 5px';
            style += '; vertical-align: middle';
            const span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
        });
        innerHtml += '</tbody>';

        const tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }

    /** No need for images here */

    // `this` will be the overall tooltip
    const position = thisChart._chart.canvas.getBoundingClientRect();
    // Display, position, and set styles for font
    tooltipEl.style.opacity = '3';
    tooltipEl.style.position = 'absolute';
    // relative to the ting
    const showingReact = tooltipEl.getBoundingClientRect();
    const calLeftPx = (position.left + window.pageXOffset + tooltipModel.caretX - (showingReact.width));
    tooltipEl.style.left = (calLeftPx < 0 ? 0 : calLeftPx) + 'px';
    tooltipEl.style.top = (position.top + window.pageYOffset + tooltipModel.caretY - (showingReact.height)) + 'px';
    // tooltipEl.style.left = (position.left + 100) + 'px';
    // tooltipEl.style.top = (position.top + window.pageYOffset) + 'px';
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.backgroundColor = 'rgba(0,0,0, 0.8)';
    tooltipEl.style.color = 'white';
    tooltipEl.style.borderRadius = '5px';

    barChartTooltipTimeout = setTimeout(() => {
        tooltipEl.style.opacity = '0';
    }, 2500);

};
