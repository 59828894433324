<ion-row *ngIf="configDataService.isConfigReady" [ngStyle]="isShowGraph ? { display: 'flex' } : { display: 'none' }">
  <ion-col>
    <ion-card appLockIcon [isLock]="isLock" [inputLockText]="inputLockText" class="ion-padding">
      <ion-card-header *ngIf="isShowSelector; else normalChartHeader">
        <ion-grid class="ion-no-padding">
          <div class="grid-container" style="width: 100%; display: grid; grid-template-columns: auto 1fr; align-items: center">
            <div class="grid-title" style="display: flex; justify-content: start; align-items: center">
              <ion-card-title id="col-title-name">
                {{ title }}
                <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
              </ion-card-title>
            </div>
            <div style="display: flex; justify-content: flex-end; align-items: center">
              <ion-select
              [value]="getDefaultValue()"
              style="font-size: 16px; color: var(--ion-color-primary)"
              [interface]="selectInterface"
              (ionChange)="changeSelectedGraph($event)"
              [multiple]="multipleSelectedDropdown"
              >
                <ion-select-option *ngFor="let lineName of selectorName; index as i" [value]="lineName">{{ configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName] || lineName }}</ion-select-option>
              </ion-select>
            </div>
          </div>
        </ion-grid>
      </ion-card-header>
      <div>
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col appLockBlur [isLock]="isLock">
              <app-generic-custom-bar-chart
                [data$]="data$"
                [chartLabels$]="chartLabel$"
                [isShowLegend]="isShowLegend"
                [isHorizontal]="isHorizontal"
                [chartOptions$]="chartOptions$"
                [customToolTipFuction]="customToolTipFuction"
                [labelOption]="labelOption"
                [labelImages]="labelImages"
                [labelDisplayList]="labelDisplayList"
              ></app-generic-custom-bar-chart>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-card>
  </ion-col>
</ion-row>

<ng-template #normalChartHeader>
  <ion-card-header>
    <ion-card-title>
      {{ title }}
      <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
    </ion-card-title>
  </ion-card-header>
</ng-template>
