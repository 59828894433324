import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { PopoverController } from '@ionic/angular';
import { getDepthData, isSelectionVisitorProfileAll } from '../helpers/util';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { VisitorProfileSelection } from '../objects/visitor-profile';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { generateNestedData } from '../helpers/mock-data-generator';

export class VisitorNetShoppingHourNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        if (!isMockData) {
            graphDataService.baseGraphData.addDependency(this.dataDependency);
            if (configDataService.isFeatureEnabled('reid_timespent')) {
                graphDataService.baseGraphData.addDependency(GraphDependency.BUILDING_TIMESPENT);
                graphDataService.baseGraphData.addDependency(GraphDependency.ENTRANCE_EXIT);
            }
        }

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicNumberCardComponent;

        const fillData: { netShoppingTime: number; diff: number; diffPercent: number } = { netShoppingTime: 0, diff: 0, diffPercent: 0 };
        const numberData$ = new BehaviorSubject('0 hr');
        const label$ = new BehaviorSubject('0% (0)');
        const labelIcon$ = new BehaviorSubject('trending-up');
        const labelStyle$ = new BehaviorSubject({ fontSize: '16px', fontWeight: '500', color: '#5BBF93' });
        const isLock$ = new BehaviorSubject(this.isLock);

        if (isMockData) {
            subscription.add(combineLatest([graphDataService.selectedVisitorProfile$, viewPeriodService.dayList]).subscribe(async ([selectedVisitorProfile, dayList]) => {
                const mockData = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, 'VISITOR_TRAFFIC_TREND', 'count', 2);
                const headCounts = Object.values(mockData) as any;
                const headCount = headCounts[0][1];
                const diff = headCounts[0][1] - headCounts[0][0];
                const diffPercent = diff / headCounts[0][0] * 100;
                numberData$.next(headCount.toLocaleString() + ' hrs');
                label$.next(`${diffPercent?.toFixed(1)} % (${diff?.toLocaleString()}) hrs`);
                labelIcon$.next(diff > 0 ? 'trending-up' : 'trending-down');
                labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: diff >= 0 ? '#5BBF93' : '#E25144' });
                isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(selectedVisitorProfile)));
            }));
        } else {
            subscription.add(combineLatest([graphDataService.currentBuildingNetShoppingHourData$, graphDataService.currentNetShoppingTimeProfileData$, graphDataService.buildingEntranceExitPairData$, graphDataService.avgTimespentTimePairData$]).subscribe(
                ([currentNetShoppingTimeData, currentNetShoppingTimeProfileData, buildingEntranceExitPairData, avgTimespentTimePairData]) => {
                    // const selectedProfile = graphDataService.selectedVisitorProfile$.value;
                    // const buildingName = configDataService.MAIN_BUILDING;
                    if (configDataService.isFeatureEnabled('reid_timespent') && (!buildingEntranceExitPairData || !avgTimespentTimePairData)) {
                        return;
                    }
                    const selectedVisitorProfile: VisitorProfileSelection = graphDataService.selectedVisitorProfile$.getValue();
                    const buildingName = selectedVisitorProfile.organization === 'all' ? configDataService.MAIN_BUILDING : selectedVisitorProfile.organization;
                    const isEntrance = configDataService.isEntranceDataMode$.getValue();
                    const visitorProfileTrafficByDepth = getDepthData<unknown>(isEntrance, currentNetShoppingTimeData, { buildingName }, fillData) as { netShoppingTime: number; diff: number; diffPercent: number };
                    const visitorProfileData = isSelectionVisitorProfileAll(selectedVisitorProfile) ? visitorProfileTrafficByDepth : currentNetShoppingTimeProfileData;
    
                    const buildingData = getDepthData<unknown>(isEntrance, buildingEntranceExitPairData, { buildingName }, [0, 0]) as [number, number];
                    const buildingAvgTimepsentTrendREIDData = getDepthData<unknown>(isEntrance, buildingData, { buildingName }, [0, 0]) as [number, number];
                    const netShoppingTimeChartREIDPairData = configDataService.isFeatureEnabled('reid_timespent') ? buildingData.map((d, i) => (((buildingAvgTimepsentTrendREIDData[i] / 60) * d) / 60) * 60 * 60) : [0, 0];
        
                    // const netShoppingTimePairData = configDataService.isFeatureEnabled('reid_timespent') ? buildingEntranceExitPairData?.[buildingName].entrance.map((d, i) => (((avgTimespentTimePairData[i] / 60) * d) / 60) * 60 * 60) : [];
                    const currentNetShoppingTimePairData = configDataService.isFeatureEnabled('reid_timespent') ? {
                        netShoppingTime: netShoppingTimeChartREIDPairData[1],
                        diff: netShoppingTimeChartREIDPairData[1] - netShoppingTimeChartREIDPairData[0],
                        diffPercent: ((netShoppingTimeChartREIDPairData[1] - netShoppingTimeChartREIDPairData[0])  / netShoppingTimeChartREIDPairData[0]) * 100
                    } : fillData;
                    const netShoppingTimeData = configDataService.isFeatureEnabled('reid_timespent') ? currentNetShoppingTimePairData : visitorProfileData;
                    numberData$.next(`${Math.floor(netShoppingTimeData.netShoppingTime / (60 * 60)).toLocaleString()} hrs`);
                    label$.next(`${netShoppingTimeData.diffPercent.toFixed(1)}% (${Math.floor(netShoppingTimeData.diff / (60 * 60)).toLocaleString()} hrs)`);
                    labelIcon$.next((netShoppingTimeData.diff / (60 * 60)) > 0 ? 'trending-up' : 'trending-down');
                    labelStyle$.next({ fontSize: '16px', fontWeight: '500', color: (netShoppingTimeData.diff / (60 * 60)) >= 0 ? '#5BBF93' : '#E25144' });
                    isLock$.next(this.state === 'LOCK' || (this.state === 'LOCK_COND' && !isSelectionVisitorProfileAll(selectedVisitorProfile)));
                }
            ));
        }

        comInstance.isLock = isLock$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR;
        comInstance.numberData$ = numberData$;
        comInstance.label$ = label$;
        comInstance.labelIcon$ = labelIcon$;
        comInstance.labelStyle$ = labelStyle$;
        comInstance.sizeXS = '12';
        comInstance.sizeMD = '10';
        comInstance.offsetMD = '1';
        // comInstance.infoPopover = async (e: any) => {
        //     const shoppingBagPopover = await popoverController.create({
        //         component: PurchaseBagTooltipsComponent,
        //         cssClass: 'customer-segment-details-popover',
        //         event: e,
        //     });
        //     return await shoppingBagPopover.present();
        // };

        return comInstance;
    }

}
