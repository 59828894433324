import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';

const CHART_COLOR = '#ffd800';
const CHART_BACKGROUND_COLOR = '#917b0022';

export class FloorNetShoppingHourResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        graphDataService.baseGraphData.addDependency(this.dataDependency); // ENTRANCE_EXIT, ENTRANCE_EXIT_FLOOR
        // await configDataService.loadAppConfig();
        const buildingName = (getAdditionalInput(additionalInput, 'building') || configDataService.MAIN_BUILDING) as string;
        const initialfloorName = graphDataService.baseGraphData.selectedDirectory$.value?.floor || graphDataService.selectedFloorName$.value || 'ALL';
        const initialIsAll = initialfloorName === 'ALL';
        const initialZoneName = graphDataService.baseGraphData.selectedDirectory$.value?.zone;
        const initialSelectLevel = graphDataService.baseGraphData.selectedLevel$.value;
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const fillData: number[] = Array.from({ length: 7 }).map(() => null);
        const getBuildingFloorData = (data: { [buildingName: string]: ({ [floorName: string]: ({ [zoneName: string]: number[] } | number[]) } | number[]) }, bldName: string, floorName?: string, zoneName?: string, defaultValue = fillData) => {
            if (!(data && data[bldName])) {
                return defaultValue;
            }
            if (zoneName) {
                return (data[bldName][floorName][zoneName] || defaultValue) as number[];
            } else if (floorName) {
                return (data[bldName][floorName] || defaultValue) as number[];
            } else {
                return (data[bldName] || defaultValue) as number[];
            }
        };
        const initialAllData = getBuildingFloorData(graphDataService.netShoppingTimeChartData$.value, buildingName);
        const initialFloorData = getBuildingFloorData(graphDataService.netShoppingTimeFloorData$.value, buildingName, initialfloorName);
        const initialZoneData = getBuildingFloorData(graphDataService.netShoppingTimeZoneData$.value, buildingName, initialfloorName, initialZoneName);

        const initialChartData = (initialIsAll ? initialAllData : ( initialSelectLevel === 'FLOOR' ? initialFloorData : initialZoneData)).map(val => val ? Math.round(val / (60 * 60)) : val);

        const chartData$ = new BehaviorSubject<GenericLineChartData[]>([{
            points: initialChartData,
            backgroundColor: CHART_BACKGROUND_COLOR,
            color: CHART_COLOR,
            isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
            toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR},${time}`),
            label: configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR,
        }]);

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        subscription.add(combineLatest([graphDataService.selectedFloorName$, graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$, graphDataService.netShoppingTimeChartData$, graphDataService.netShoppingTimeFloorData$, graphDataService.netShoppingTimeZoneData$]).subscribe(
            ([selectedFloorName, selectedDirectory, selectedLevel, allData, floorData, zoneData]) => {
                if (!allData || !floorData) { return; }
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const floorName = selectedDirectory?.floor || selectedFloorName || 'ALL';
                const zoneName = selectedDirectory?.zone;
                const isAll = floorName === 'ALL';
                const chartData = isAll ? getBuildingFloorData(allData, buildingName) : ( selectedLevel === 'FLOOR' ? getBuildingFloorData(floorData, buildingName, floorName)
                : getBuildingFloorData(zoneData, buildingName, floorName, zoneName).map(val => val ? Math.round(val / (60 * 60)) : val));
                const lineChartData: GenericLineChartData[] = [{
                    points: chartData.map(val => val ? Math.round(val / (60 * 60)) : val),
                    backgroundColor: CHART_BACKGROUND_COLOR,
                    color: CHART_COLOR,
                    isLivePeriod: viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())),
                    toolTipLabel: viewPeriodService.DAY_LIST.map(time => `${configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR},${time}`),
                    label: configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR,
                }];
                chartData$.next(lineChartData);
                comInstance.isShow = (isAll) || selectedLevel === 'FLOOR' || selectedLevel === 'ZONE' ? true : false;
            }));

        comInstance.isLock = this.isLock;
        comInstance.isShow = initialIsAll || graphDataService.baseGraphData.selectedLevel$.value === 'FLOOR' || graphDataService.baseGraphData.selectedLevel$.value === 'ZONE' ? true : false;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.NET_SHOPPING_HOUR;
        comInstance.data$ = chartData$;
        comInstance.label$ = viewPeriodService.DAY_LIST$;
        // comInstance.sizeXS = '12';
        // comInstance.sizeMD = '10';
        // comInstance.offsetMD = '1';
        return comInstance;
    }

}
